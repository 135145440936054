import {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {Input} from '@/components/common-components/v2';

interface Props {
  removeTopic: Dispatch<SetStateAction <number>>;
  index: number;
  currentItemState: {
    value: string;
    label: string;
    placeholder: string;
    fields: [
    ];
  };
  updateItemTopic: (e, itemIndex: number) => void;
  onSingleFieldDragEnd: (index: number, newState) => void;
  totalItems: number;
  totalTerms: number;
  totalQuestions: number;
  showError: boolean;
}

export const SingleQuestionSection = ({
  removeTopic,
  index,
  currentItemState,
  updateItemTopic,
  totalItems,
  showError,
}: Props) => {
  return <TopSectionWrapper>
    <div style={{width: '100%'}}>
      <Input
        type='text'
        variant='light'
        prefix={`#${index + 1}`}
        suffix={ <div style={{display: 'flex'}}>
          {totalItems > 1 && <div className='trash-icon' onClick={() => removeTopic(index)}><FontAwesomeIcon icon={faTrash}/></div>}
        </div>}
        value={currentItemState.value}
        onChange={e => updateItemTopic(e, index)}
        className={'customLargeInput'} />
      {(showError && currentItemState.value === '') && <ErrorMsg>This field is required.</ErrorMsg>}
    </div>
  </TopSectionWrapper>;
};

const TopSectionWrapper = styled.div`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 38px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
  }

  .trash-icon {
    color: #a3a4a4;

    &:hover {
      color: #ff6262;
    }
  }

`;

const ErrorMsg = styled.div`
  font-size: 10px;
  color: #ff6262;
`;
