import React from 'react';
// import {useState, useEffect} from 'react';
import ImagePicker from './imagePicker';
import styled from 'styled-components';
import {Button,
  // Tooltip
} from 'antd';
import {useStore} from '@/store/root-store';
import {notification} from '@/utils/notification-v2';

export const ImagesModal = ({images, setImages, updateImages, setModalLoader}) => {
  const types = ['COVER', 'PROFILE', 'ADDITIONAL'];
  // const [youtubeUrlError, setYoutubeUrlError] = useState([]);

  const {
    ottoV2Store: {ottoGBPUploadImage},
  } = useStore('');


  // useEffect(() => {
  //   !images?.ADDITIONAL?.filter(image => image?.format=='VIDEO')?.length && setYoutubeUrlError([]);
  // }, [images]);

  const handleImagesUpdate = async () => {
    const errorUrls = [];
    const processImage = async (key, item) => {
      if (Array.isArray(item)) {
        const updatedItemsArray = [];
        for (const [index, subItem] of Object.entries(item)) {
          if (subItem?.url && typeof subItem.url !== 'string') {
            const formData = new FormData();
            formData.append('image', subItem.url);
            formData.append('format', 'JPEG');
            key=='COVER' && formData.append('aspect_ratio', '1.777778');
            try {
              const response = await ottoGBPUploadImage(formData);
              subItem.url = response;
            } catch (error) {
              notification.error('Failed to upload image:', error.message);
            }
          } else if (!subItem?.url && subItem?.format === 'VIDEO') {
            errorUrls?.push(+index);
          }
          updatedItemsArray.push(subItem);
        }
        images[key] = updatedItemsArray;
      } else if (item?.url && typeof item.url !== 'string') {
        const formData = new FormData();
        formData.append('image', item.url);
        formData.append('format', 'JPEG');
        key=='COVER' && formData.append('aspect_ratio', '1.777778');
        try {
          const response = await ottoGBPUploadImage(formData);
          images[key].url = response;
        } catch (error) {
          notification.error('Failed to upload image:', error.message);
        }
      }
    };

    const processAllImages = async () => {
      setModalLoader(true);
      const entries = Object.entries(images);

      for (const [key, item] of entries) {
        await processImage(key, item);
      }
      if (errorUrls?.length) {
        // setYoutubeUrlError(errorUrls);
        setModalLoader(false);
        return;
      } else {
        await updateImages(images);
      }
    };
    processAllImages();
  };

  return (
    <>
      <StyledContainer>
        <ImagePicker
          // setYoutubeUrlError={setYoutubeUrlError} youtubeUrlError={youtubeUrlError}
          setImages={setImages} images={images} types={types} />
      </StyledContainer>
      <ButtonContainer>
        {/* <Tooltip title={`${youtubeUrlError?.length ? 'Invalid URL' : ''}`}> */}
        <StyledButton
          // disabled={youtubeUrlError?.length ? true : false}
          onClick={handleImagesUpdate}>Save</StyledButton>
        {/* </Tooltip> */}
      </ButtonContainer>
    </>
  );
};

const StyledContainer = styled.div`
  max-height: 650px;
  overflow: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0px 20px;
`;

const StyledButton = styled(Button)`
  background-color: #2D6CCA;
  color: white;
  padding: 14px 25px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;

  &:hover, &:focus {
    background-color: #2D6CCA;
    border: none;
    color: white;
  }

  &:disabled,
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus {
    background-color: #2D6CCA;
    border: none;
    color: white;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

