import {BaseApi} from '@/api/base-api';
import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';

class EmailNotificationsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2/customer');

  public async unsubscribeUser(userEmails: Array<string>, sitepropertyIds: Array<string>, emailType: Array<string>) {
    try {
      const response = await this.axios.patch(`${EmailNotificationsApi.baseUrl}/email-settings/remove/`, {
        user_emails: userEmails,
        siteproperty_ids: sitepropertyIds,
        email_types: emailType,
      }, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async subscribeUser(userEmails: Array<string>, sitepropertyIds: Array<string>, emailType: Array<string>) {
    try {
      const response = await this.axios.patch(`${EmailNotificationsApi.baseUrl}/email-settings/add/`, {
        user_emails: userEmails,
        siteproperty_ids: sitepropertyIds,
        email_types: emailType,
      }, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default EmailNotificationsApi;
