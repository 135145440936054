import {notification} from '../notification-v2';

const convertStringToReadable = (input: string): string => {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase and lowercase
    .toLowerCase() // Convert the whole string to lowercase
    .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter of the first word
};

// Structure of error should be like this {key1: errorValue, key2: errorValue}
export const showFormattedNonFieldError = (error: any) => {
  const errors = error.response.data;

  // Check if errors is an array
  if (Array.isArray(errors)) {
    // If errors is an array, just display a simple message
    notification.error(
      '',
      <div>Errors: {errors.join(', ')}</div>,
      false,
      'OK',
    );
  } else {
    // If errors is an object, proceed with the existing logic
    const notificationMessages = Object.entries(errors).map(([field, messages]) => {
      const fieldName = convertStringToReadable(field);
      // @ts-ignore
      return (<div key={field}><strong>{fieldName}</strong>: {messages.join(', ')}</div>);
    });

    notification.error(
      '',
      <div>{notificationMessages}</div>,
      false,
      'OK',
    );
  }
};
