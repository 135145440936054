import React, {useState, Fragment, useEffect} from 'react';
import styles from './styles.module.scss';
import {Empty, Input, Select} from 'antd';
import {Search} from 'react-feather';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import {RingLoaderSmall} from '../skeleton/ring-loader-small';
import {SkeletonHorizontalLoaderGray} from '../skeleton/horizontal-loader';
import classnames from 'classnames';

const {Option} = Select;

interface IOption {
  name: string;
  propertyUrl: string;
  raw: any;
  id: number;
  search: boolean;
  settingIcon?: boolean;
  selectedValue?: string;
  processingStatus?: string;
  handleSearch?: (value) => void;
}

const SiteAudit = ({optionRender, selectedValue, handleSearch, ...props}: any) => {
  const {navBar: {isDockedSider}, siteAuditorV2: {setFilterParam}} = useStore('');
  const {
    siteAuditorV2: {
      setParamsBasic,
    },
  } = useStore('');
  const [options, setOptions] = useState(props.data || []);
  const [value, setValue] = useState('');
  const {t} = useTranslation('common');
  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target?.value);
    setValue(e.target?.value);
  };

  const onOptionChange = (selectedOptionValue: string) => {
    setFilterParam('');
    props.onOptionChange && props.onOptionChange(selectedOptionValue);
  };

  useEffect(()=>{
    setValue('');
    setParamsBasic({search: ''});
  }, [props.open]);

  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);
  return (
    <><Select
      dropdownClassName={classnames(styles.PropertyDropDown, !isDockedSider ? styles.leftSpacing : '')}
      className={styles.SelectWithSearch}
      value={selectedValue}
      onChange={onOptionChange}
      {...props}
      dropdownRender={menu => (
        <Fragment>
          <div onClick={e => e.stopPropagation()}
            className={styles.topDiv}>
            <Input className={styles.SearchField}
              placeholder={t('Search...')}
              value={value}
              onChange={onSearchHandler}
              prefix={<Search width={15} />}
              bordered={false}
            />
          </div>
          {menu}
        </Fragment>
      )}
    >
      {props.loading ?
        <>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}><div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center'}}>
              <span ><RingLoaderSmall width='35px' height='35px' /></span><div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}><span><SkeletonHorizontalLoaderGray height={15} width={260} /></span>
                <span><SkeletonHorizontalLoaderGray height={15} width={130} /></span></div></div></div>
            <div style={{display: 'flex', flexDirection: 'row'}}><div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center'}}>
              <span ><RingLoaderSmall width='35px' height='35px' /></span><div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}><span><SkeletonHorizontalLoaderGray height={15} width={260} /></span>
                <span><SkeletonHorizontalLoaderGray height={15} width={130} /></span></div></div></div>
            <div style={{display: 'flex', flexDirection: 'row'}}><div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center'}}>
              <span ><RingLoaderSmall width='35px' height='35px' /></span><div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}><span><SkeletonHorizontalLoaderGray height={15} width={260} /></span>
                <span><SkeletonHorizontalLoaderGray height={15} width={130} /></span></div></div></div>
            <div style={{display: 'flex', flexDirection: 'row'}}><div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'row', alignItems: 'center'}}>
              <span ><RingLoaderSmall width='35px' height='35px' /></span><div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}><span><SkeletonHorizontalLoaderGray height={15} width={260} /></span>
                <span><SkeletonHorizontalLoaderGray height={15} width={130} /></span></div></div></div>

          </div>
        </> :
        (options && options?.length > 0) ? options?.map((option: IOption, idx: number) => {
          return (
            <Option
              className={styles.Option}
              key={option?.id}
              value={option?.id}
            >
              {optionRender(option, idx)}
            </Option>
          );
        }) : <div>{options?.length < 1 && <Empty />}</div>}
    </Select>
    </>
  );
};

export default SiteAudit;
