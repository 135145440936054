import {Tooltip, Spin, Dropdown, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {faPen, faSortDown, faCheckCircle, faRotate} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {CloseButton, DescriptionWrapper, MissingDescriptionWrapper, PaginationStyled, PurpleCheckbox, StatusWrapper, StyledInput, StyledIssuesCollapse, StyledIssuesTable, UrlWrapper} from '../../../../style';
import {Button} from '@/components/common-components';
import {canDeploy, getDeployStatus, getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {getStatusText, showNotificationMess} from '../../../../Constants/functions';
import {StyledMenu} from '../../../../Utils/styledMenu';
import {TableTopBar} from '../../../tableTopBar';
import {debounce} from 'lodash';
import {newNotification} from '@/utils/notification-v3';
import {BulkActionBar} from '../../../bulkActionBar';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
}

const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
export const OgDescription = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    generatingAiProposedFix,
    generateAiProposedFix,
    deployingProposedFix,
    deployProposedFix,
    isActiveKeys,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    selectedIssue,
  }, settings: {customer: {getCustomerQuota, profile: {whitelabelOtto}}},
  } = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [editDescription, setEditDescription] = useState<any>(-1);
  const [proposedFix, setProposedFix] = useState('');
  const [proposedFixId, setProposedFixId] = useState([]);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));


  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (filteredData.length && selectAll) {
      const pageArray = filteredData.map(data => data?.id);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoSection = async (toDeploy: boolean) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueType: url?.issueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(url?.issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('Changes Deployed Successfully', 2, 'deploy');
      } else {
        newNotification('Changes Rolled Back', 2, 'rollback');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
  };


  const deployOttoUrl = async (id, toDeploy, issueType) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }

    setUrlId(id);
    try {
      const data = {toDeploy, issueType, uuid};
      data['ottoUrls'] = [id];
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
    }
  };


  const generateNewProposedFix = async (id, issueType) => {
    const fixId = id;
    try {
      setProposedFixId(prev => [...prev, fixId]);
      await generateAiProposedFix({id, issueType, uuid});
      getCustomerQuota();
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    }
  };

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  const descriptionContainer = record => {
    return (
      <DescriptionWrapper>
        {record?.recommendedValue}
        {
          record?.recommendedValue ?
            <span className='icons-wrapper'>
              <FreezeWrapper removeTooltip={record?.isActive} placement='topRight'>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={record?.isGenerating ? 'Wait to complete Ai generating' : 'Edit'}>
                  <FontAwesomeIcon style={{cursor: (urlId !== -1 || record?.isGenerating) ? 'not-allowed' : 'pointer'}} icon={faPen} fontSize={12} color='#4E5156' onClick={() => {
                    if (urlId !== -1 || record?.isGenerating) {
                      return;
                    }
                    setEditDescription(`${record?.id}-${record?.issueType}`);
                    setProposedFix(record?.recommendedValue);
                  }}/>
                </Tooltip>
              </FreezeWrapper>
              <FreezeWrapper placement='topRight'>
                <Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                  <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' className={(generatingAiProposedFix && proposedFixId.includes(record?.id) || record?.isGenerating) ? 'fa-spin' : ''} onClick={() => {
                    if (!proposedFixId.includes(record?.id) && !record?.isGenerating) generateNewProposedFix(record?.id, record?.issueType);
                  }} />
                </Tooltip>
              </FreezeWrapper>
            </span> :
            <span className='icons-wrapper' style={{display: 'flex', gap: 10}}>
              <FreezeWrapper removeTooltip={record?.isActive}>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={record?.isGenerating ? 'Wait to complete Ai generating' : 'Edit'}>
                  <div className='content-wrapper' onClick={() => {
                    if (record?.isGenerating) {
                      return;
                    }
                    setEditDescription(`${record?.id}-${record?.issueType}`);
                    setProposedFix(record?.recommendedValue);
                  }}>
                    <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />Write
                  </div>
                </Tooltip>
              </FreezeWrapper>
              <FreezeWrapper>
                <Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                  <div className='content-wrapper' onClick={() => {
                    if (!proposedFixId.includes(record?.id) && !record?.isGenerating) generateNewProposedFix(record?.id, record?.issueType);
                  }}>
                    <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' className={(generatingAiProposedFix && proposedFixId.includes(record?.id) || record?.isGenerating) ? 'fa-spin' : ''} />AI Generate
                  </div>
                </Tooltip>
              </FreezeWrapper>
            </span>
        }
      </DescriptionWrapper>
    );
  };

  const onChangeProposedFix = async (id, issueType) => {
    try {
      await deployProposedFix({id, issueType, proposedFix, uuid});
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setEditDescription(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
      setEditDescription(-1);
    }
  };

  const inputDescriptionContainer = record => {
    return (
      <div style={{minWidth: 'auto'}}>
        <div style={{
          display: 'flex',
          gap: '10px',
          width: '100%',
          alignItems: 'center',
          flexWrap: 'wrap',
          minWidth: 150,
        }}>
          <StyledInput rows={6} value={proposedFix} onChange={e => setProposedFix(e.target.value)}/>
          <Button
            buttonType={'green'}
            loading={deployingProposedFix}
            disabled={!proposedFix}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              fontSize: '14px',
              padding: '0px 15px',
            }}
            onClick={() => onChangeProposedFix(record?.id, record?.issueType)}
          >
            {!deployingProposedFix ? 'Save' : ''}
          </Button>
          <CloseButton>
            <FontAwesomeIcon icon={faTimes} fontSize={20} color='white' onClick={() => {
              setEditDescription(-1);
              setProposedFix('');
            }} />
          </CloseButton>
        </div>
      </div>
    );
  };

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (searchText) {
          params['search'] = searchText;
          setOttoSearchTerm(searchText);
        } else {
          setOttoSearchTerm('');
        }
        if (searchText) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };
  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };

  const columns = [
    {
      title: (<div className='column-title'>
        <Dropdown overlay={
          <StyledMenu disabled={filteredData?.length == 0} selectedIssueType={url?.issueType} deployOttoSection={deployOttoSection} currentProject={currentProject}/>
        } trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
          <div>
            <span>STATUS</span>
            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12}/>
          </div>
        </Dropdown>
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (_, record) => {
        return (
          <FreezeWrapper removeTooltip={record?.isActive}>
            <Tooltip title={canDeploy(record, record?.issueType) || record?.isActive ? '' : 'Empty fields cannot be deployed.'}>
              <StatusWrapper
                status={record?.isActive}
                onClick={() => ((canDeploy(record, record?.issueType) || record?.isActive)) && deployOttoUrl(record?.id, !record?.isActive, record?.issueType)}
                isDisabled={(!canDeploy(record, record?.issueType) && !record?.isActive)}
              >
                {
                  urlId === record?.id ? <Spin indicator={antUrlIcon} /> :
                    <>
                      <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isActive ? `#2AC155` : '#A3A4A4'} />
                      <span>{getStatusText(record?.isActive, currentProject)}</span>
                    </>
                }
              </StatusWrapper>
            </Tooltip>
          </FreezeWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      width: '280px',
      render: (_, record) => {
        const path = (record?.path?.length && record?.path[0] === '/') ? record?.path.slice(1) : record?.path;
        return ( <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          {showBulkBanner && <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=> selectUnselectPages(record?.id)}/>}
          <UrlWrapper onClick={() => openUrl(`${addProtocolToDomain(domainName)}/${path}`, '_blank')}>{record?.path}</UrlWrapper>
        </div>);
      },
    },
    {
      title: <div className='column-title'>ORIGINAL</div>,
      dataIndex: 'currentTitle',
      key: 'currentTitle',
      className: 'radish-column',
      render: (_, record) => {
        return (
          record?.currentValue ? (
            <DescriptionWrapper style={{
              minWidth: 'auto',
              maxWidth: 'auto',
              color: record?.isActive && record?.recommendedValue ? '#A3A4A4' : 'black',
            }}>
              <div>{record?.currentValue}</div>
            </DescriptionWrapper>
          ) : (
            <MissingDescriptionWrapper>Missing</MissingDescriptionWrapper>
          )
        );
      },
    },
    {
      title: <div className='column-title'>{`${whitelabelOtto} SUGGESTED FIX`} </div>,
      dataIndex: 'proposedFix',
      key: 'proposedFix',
      className: 'greenish-column',
      render: (id, record) => {
        return (
          <>
            {
              editDescription !== `${record?.id}-${record?.issueType}` ?
                <>
                  {
                    descriptionContainer(record)
                  }
                </> :
                <>
                  {
                    inputDescriptionContainer(record)
                  }
                </>
            }
          </>
        );
      },
    },
  ];
  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map(data => data?.id));
  };


  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter}
        setShowBulkBanner={setShowBulkBanner}
      />
      {showBulkBanner ? <BulkActionBar
        count={url?.issueTable?.count}
        setShowBulkBanner={setShowBulkBanner}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectAllOnPage={selectAllOnPage}
        issueType={url?.issueType}
        currentProject={currentProject}
        setPageChanged={setPageChanged}
      /> : <></>}
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns.filter(column => showBulkBanner ? column.key !== 'status' : true)}
          dataSource={filteredData}
          pagination={false}
          scroll={{x: 850}}
        />
        <PaginationStyled
          onChange={(page, pageSize) => {
            setPageChanged(true);
            setIssueTable(url?.issueType);
            handlePaginationChange([url?.issueType], page, pageSize);
            saveOttoTablePageSize(componentIssueType, pageSize);
            setPageSize(pageSize);
          }}
          total={url?.issueTable?.count}
          pageSize={pageSize}
          current={url?.page ?? 1}
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50', '100']}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
