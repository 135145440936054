import {PagesApi} from '@/api/content-optimizer/pages-api';
import {FocusTermsApi} from '@/api/content-optimizer/focus-terms';
import {getApiUrl} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {CreateTasksApi} from '@/api/content-optimizer/create-tasks';
import {ContentQualityApi} from './content-quality';
import {ContentAuditApi} from '@/api/content-audit';

export enum ContentStatus {
  Pending = 'PENDING',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export const PAGES_API = new PagesApi(getApiUrl(BaseApi.CA_ENDPOINT, '/api'));
export const FOCUS_TERMS_API = new FocusTermsApi(getApiUrl(BaseApi.CA_ENDPOINT, '/api'));
export const CREATE_TASKS_API = new CreateTasksApi(getApiUrl(BaseApi.CA_ENDPOINT, '/api'));
export const CONTENT_AUDIT_API = new ContentAuditApi(getApiUrl(BaseApi.CA_ENDPOINT, '/api'));
export const CONTENT_QUALITY_API = new ContentQualityApi(getApiUrl(BaseApi.CA_ENDPOINT, '/api'));
