import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Collapse, Empty, notification, Dropdown} from 'antd';
import styled from 'styled-components';
import styles from './styles.module.scss';
import modalStyles from '@/components/dashboard/settings/styles.module.scss';
import {GSCSettingsV2} from '../GSCIntegration/gscSettingsV2';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {MixPanel} from '@/utils/mixpanel';
import {GaSettingsData} from './GaSettingsData';
import {useTranslation} from 'next-i18next';
import {Button} from '@/components/common-components/v2/Button';
import {integrationApi} from '@/api/gsc';
import {
  Typography,
  Button as ButtonV1,
  Modal,
  SkeletonRingLoader,
} from '@/components/common-components/components/index';
import {NextImg} from '@/utils/nextImg';

import {SETTINGS_DISCONNECT_GA_ACCOUNT} from '@/constants/events';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import {GmbSettingsData} from './GmbSettingsData';
import {Pill} from '@/components/layoutV2/user-menu/styles';
import {getSingleUrlParam} from '@/utils/url';
import {FBSettingsData} from './FBSettingsData';
interface Props{
  profile?: any;
}
const GASettings: React.FC<Props> = observer(({profile}) => {
  const {Panel} = Collapse;
  const [isGaDetail, setIsGaDetail] = useState(false);
  const [isGmbDetail, setIsGmbDetail] = useState(false);
  const [isFBAdsDetail, setIsFBAdsDetail] = useState(false);
  const [gaDetailData, setGaDetailData] = useState(null);
  const [gmbDetailData, setGmbDetailData] = useState(null);
  const [fbAdsDetailData, setFBAdsDetailData] = useState(null);
  const [deleteEmail, setDeleteEmail] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const {t} = useTranslation('common');
  const [deleteGMBModal, setGMBDeleteModal] = useState(null);
  const [fbAdsDeleteModal, setFbAdsDeleteModal] = useState(null);

  const {settings: {
    integration,
  }} = useStore('');
  const {gsc: {
    googleAnalytics: {
      // getAccountsV2,
      // getAccountsDataV2,
      getGAGlobalSetting,
      getGMBSetting,
      loadFBAdsSetting,
      gsGlobalSettingLoading,
      gmbDataLoading,
      fbAdsDataLoading,
      getGlobalSettingsData,
      getGMBData,
      disconnectGMB,
      getFBAdsData,
    },
  },
  } = useStore('');
  useEffect(()=>{
    // getAccountsV2();
    getGAGlobalSetting();
    getGMBSetting();
    loadFBAdsSetting();
  }, []);

  const openDeleteModal = (profile: string) => {
    setDeleteModal(true);
    setDeleteEmail(profile);
  };

  const deleteAccount = () => {
    setDeleteModal(false);
    notification.success({
      placement: 'bottomRight',
      bottom: 70,
      duration: 3,
      style: {
        zIndex: 2,
      },
      message: t('account-disconnected-successfully'),
    });
  };

  useEffect(() => {
    if (getSingleUrlParam('google-search-console')) {
      setActiveKey(1);
    } else if (getSingleUrlParam('google-analytics')) {
      setActiveKey(2);
    } else if (getSingleUrlParam('google-business')) {
      setActiveKey(3);
    } else if (getSingleUrlParam('facebook-ads')) {
      setActiveKey(4);
    }
  }, []);

  const {reportBuilder: {details: {
    facebookAdsDisconnects,
    fbLoader,
  }}} = useStore('');

  const onDisconnectEmail = async email => {
    await facebookAdsDisconnects(email);
  };

  const onDisconnectConfirm = async () => {
    await onDisconnectEmail(fbAdsDeleteModal?.email);
    await loadFBAdsSetting();
    setFbAdsDeleteModal(null);
  };

  return (
    <div>
      <CollapseStyled
        ghost
        expandIconPosition='right'
        bordered={false}
        activeKey={activeKey}
        onChange={key => setActiveKey(key)}
      >
        <Panel key={1} header={<PanelWrapper>
          <div style={{display: 'flex', gap: '10px'}}>
            <NextImg height={19} width={19} className={styles.gaIconColor} src='/img/gscicon.svg' />
            <div>
              <div>Google Search Console</div>
              <div>{toJS(integration?.googleProfiles)?.length } {toJS(integration?.googleProfiles)?.length > 1 ? 'accounts' : 'account'} linked</div>
            </div>
          </div>
          <Connectwrapper>View & Connect Accounts</Connectwrapper>
        </PanelWrapper>}>
          <GSCSettingsV2 profiles={profile}/>
        </Panel>
        <Panel key={2} header={<PanelWrapper>
          <div style={{display: 'flex', gap: '10px'}}>
            <NextImg height={19} width={19} className={styles.gaIconColor} src='/img/icon/analytics_icon.svg' />
            <div>
              <div> Google Analytics</div>
              <div>{getGlobalSettingsData?.length || 0} {getGlobalSettingsData?.length > 1 ? 'accounts' : 'account'} linked</div>
            </div>
          </div>
          <SecondConnectwrapper>View & Connect Accounts</SecondConnectwrapper>
        </PanelWrapper>}>
          {isGaDetail ? <GaSettingsData
            setGaDetail={setIsGaDetail}
            onDeleteAccount={email => openDeleteModal(email)}
            gaDetailData={gaDetailData}/> :
            <div style = {{overflow: 'auto'}}>
              <GaSettingWrapper>{!getGlobalSettingsData?.length ? (gsGlobalSettingLoading ? <SkeletonRingLoader /> : <Empty description='No Data' style={{color: '#a3a4a4'}}/>) : getGlobalSettingsData?.map((item, idx)=>{
                return <GaAccountWrapper key={`gaAccount-${idx}`} >
                  <NameWrapper>{item?.email}</NameWrapper>
                  <PropertiesWrapper>
                    {item?.availableAccounts?.length === 0 ?
                      <ViewProperties style={{color: '#A3A4A4', cursor: 'auto'}}>No accounts found</ViewProperties> :
                      <ViewProperties onClick={()=>{
                        setIsGaDetail(true);
                        setGaDetailData(item);
                      }}> View {item?.availableAccounts?.length} {item?.availableAccounts?.length === 1 ? t('account') : t('accounts')}</ViewProperties>}
                    <span style={{paddingLeft: '8px', cursor: 'pointer'}}>
                      <Dropdown placement={'bottomRight'} overlayStyle={{background: '#fff', border: '1px solid #e8e8e8', padding: '5px 8px', borderRadius: '8px'}}
                        overlay={<div style={{color: '#F44343', cursor: 'pointer'}} onClick={() => openDeleteModal(item?.email)}>Disconnect</div>}>
                        <MoreOutlined style={{color: '#121212'}}/>
                      </Dropdown>
                    </span>
                  </PropertiesWrapper>
                </GaAccountWrapper>;
              })}</GaSettingWrapper> </div>}
          <Button style={{marginTop: '15px'}}
            variant='ghost'
            onClick={() => window.open(integrationApi.getGoogleAnalyticsConnectUrl(), '_self')}
            color='blue'>{'+ Connect new GA account'}</Button>
        </Panel>
        <Panel key={3} header={<PanelWrapper>
          <div style={{display: 'flex', gap: '10px'}}>
            <NextImg height={19} width={19} className={styles.gaIconColor} src='/img/gmbIcon.svg' />
            <div>
              <div>Google Business Profile <Pill>Beta</Pill></div>
              <div>{getGMBData?.length } account{getGMBData?.length>1 ? 's' : ''} linked</div>
            </div>
          </div>
          <ThirdConnectwrapper>View & Connect Accounts</ThirdConnectwrapper>
        </PanelWrapper>}>
          {isGmbDetail ? <GmbSettingsData
            setIsGmbDetail={setIsGmbDetail}
            onDeleteAccount={value => setGMBDeleteModal(value)}
            gmbDetailData={gmbDetailData}/> :
            <div style = {{overflow: 'auto'}}>
              <GaSettingWrapper>{!getGMBData?.length ? (gmbDataLoading ? <SkeletonRingLoader /> : <Empty description='No Data' style={{color: '#a3a4a4'}}/>) : getGMBData?.map((item, idx)=>{
                return <GaAccountWrapper key={`gmbAccount-${idx}`} >
                  <NameWrapper>{item?.email}</NameWrapper>
                  <PropertiesWrapper>
                    {item?.businessAccounts?.length === 0 ?
                      <ViewProperties style={{color: '#A3A4A4', cursor: 'auto'}}> No accounts found</ViewProperties> :
                      <ViewProperties onClick={()=>{
                        setIsGmbDetail(true);
                        setGmbDetailData(item);
                      }}> View {item?.businessAccounts?.length} account{item?.businessAccounts?.length > 1 ? 's' : ''}</ViewProperties>}
                    <span style={{paddingLeft: '8px', cursor: 'pointer'}}>
                      <Dropdown placement={'bottomRight'} overlayStyle={{background: '#fff', border: '1px solid #e8e8e8', padding: '5px 8px', borderRadius: '8px'}}
                        overlay={<div style={{color: '#F44343', cursor: 'pointer'}} onClick={() => setGMBDeleteModal(item)} >Disconnect</div>}>
                        <MoreOutlined style={{color: '#121212'}}/>
                      </Dropdown>
                    </span>
                  </PropertiesWrapper>
                </GaAccountWrapper>;
              })}</GaSettingWrapper></div>}
          {!isGmbDetail && <Button style={{marginTop: '15px'}}
            variant='ghost'
            onClick={() => window.open(integrationApi.getGoogleMyBusinessConnectUrl(), 'myLogin', 'height=600,width=600,left=650,top=200')}
            color='blue'>{'+ Connect new GBP account'}</Button>}
        </Panel>
        <Panel key={4} header={<PanelWrapper>
          <div style={{display: 'flex', gap: '10px'}}>
            <img className={styles.gaIconColor} src='/img/icon/fb.png' />
            <div>
              <div>Facebook Ads</div>
              <div>{getFBAdsData?.length } account{getFBAdsData?.length>1 ? 's' : ''} linked</div>
            </div>
          </div>
          <FourthConnectwrapper>View & Connect Accounts</FourthConnectwrapper>
        </PanelWrapper>}>
          {isFBAdsDetail ? <FBSettingsData
            setIsFBAdsDetail={setIsFBAdsDetail}
            onDisconnect={onDisconnectEmail}
            fbAdsData={fbAdsDetailData}/> :
            <div style = {{overflow: 'auto'}}>
              <FBSettingWrapper>{!getFBAdsData?.length ? (fbAdsDataLoading ? <SkeletonRingLoader /> : <Empty description='No Data' style={{color: '#a3a4a4'}}/>) : getFBAdsData?.map((item, idx)=>{
                return <FBAccountWrapper key={`gmbAccount-${idx}`} >
                  <NameWrapper>{item?.email}</NameWrapper>
                  <PropertiesWrapper>
                    {item?.facebookAdAccounts?.length === 0 ?
                      <ViewProperties style={{color: '#A3A4A4', cursor: 'auto'}}> No accounts found</ViewProperties> :
                      <ViewProperties onClick={()=>{
                        setIsFBAdsDetail(true);
                        setFBAdsDetailData(item);
                      }}> View {item?.facebookAdAccounts?.length} account{item?.facebookAdAccounts?.length > 1 ? 's' : ''}</ViewProperties>}
                    <span style={{paddingLeft: '8px', cursor: 'pointer'}}>
                      <Dropdown placement={'bottomRight'} overlayStyle={{background: '#fff', border: '1px solid #e8e8e8', padding: '5px 8px', borderRadius: '8px'}}
                        overlay={<div style={{color: '#F44343', cursor: 'pointer'}} onClick={() => setFbAdsDeleteModal(item)} >Disconnect</div>}>
                        <MoreOutlined style={{color: '#121212'}}/>
                      </Dropdown>
                    </span>
                  </PropertiesWrapper>
                </FBAccountWrapper>;
              })}</FBSettingWrapper></div>}
          {!isFBAdsDetail && <Button style={{marginTop: '15px'}}
            variant='ghost'
            onClick={() => window.open(integrationApi.getFacebookConnectUrl(), 'myLogin', 'height=600,width=600,left=650,top=200')}
            color='blue'>{'+ Connect new Facebook account'}</Button>}
        </Panel>
      </CollapseStyled>
      <Modal className={modalStyles.Modal} isDark visible={deleteModal} onClose={() => setDeleteModal(false)}>
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>{t('all-gsc-projects-imported')} <span style={{fontWeight: 600}}>{deleteEmail}</span> will be disconnect.</span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1 className={modalStyles.Back} onClick={() => setDeleteModal(false)}>{t('cancel')}</ButtonV1>
          <ButtonV1 className={modalStyles.Danger} onClick={async () => {
            try {
              await integration.deleteGoogleAnalyticsProfile(deleteEmail);
              getGAGlobalSetting();
              deleteAccount();
              const detail = {
                profile: deleteEmail,
              };

              MixPanel.track(SETTINGS_DISCONNECT_GA_ACCOUNT, {
                'disconnectingAccount': `${detail}`,
              });
            } catch (e) {
              return Promise.reject(e);
            }
          }}>
            {t('disconnect-account')}
          </ButtonV1>
        </div>
      </Modal>
      <Modal className={modalStyles.Modal} isDark visible={deleteGMBModal?.email} onClose={() => setDeleteModal(false)}>
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>Are you sure, you want to disconnect <span style={{fontWeight: 600}}>{deleteGMBModal?.email}?</span></span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1 className={modalStyles.Back} onClick={() => setGMBDeleteModal(null)}>{t('cancel')}</ButtonV1>
          <ButtonV1 className={modalStyles.Danger} onClick={() => disconnectGMB({googleprofile_id: deleteGMBModal.id}, () => setGMBDeleteModal(null))}>{t('disconnect-account')}</ButtonV1>
        </div>
      </Modal>
      <Modal className={modalStyles.Modal} isDark visible={fbAdsDeleteModal?.email} onClose={() => setFbAdsDeleteModal(null)}>
        <div className={modalStyles.ConfirmationModal}>
          <Typography tag='h2'>{t('disconnect-account-question')}</Typography>
          <span>Are you sure, you want to disconnect <span style={{fontWeight: 600}}>{fbAdsDeleteModal?.email}?</span></span>
        </div>
        <div className={modalStyles.ButtonDisplay}>
          <ButtonV1 className={modalStyles.Back} onClick={() => setFbAdsDeleteModal(null)}>{t('cancel')}</ButtonV1>
          <ButtonV1 className={modalStyles.Danger} onClick={() => onDisconnectConfirm()}>{t('disconnect-account')}{fbLoader && <LoadingOutlined style={{color: '#4e5156', fontSize: '15px'}} spin/>}</ButtonV1>
        </div>
      </Modal>
    </div>
  );
});
export default GASettings;
const PropertiesWrapper = styled.div`
  display: flex;
`;

const ViewProperties = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #2D6CCA;
  cursor: pointer;
`;
const GaSettingWrapper = styled.div`
  background: #fff;
  border: 1px solid #E8E8E8;
  padding: 10px 15px;
  border-radius: 7px;
  @media only screen and (max-width: 800px) {
    width: 580px !important;
    overflow: auto;
  }
`;
const FBSettingWrapper = styled.div`
  background: #fff;
  border: 1px solid #E8E8E8;
  padding: 10px 15px;
  border-radius: 7px;
  @media only screen and (max-width: 800px) {
    width: 580px !important;
    overflow: auto;
  }
`;

const GaAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
`;
const FBAccountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
`;
const NameWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;
const PanelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
 `;
const Connectwrapper = styled.div`
  color: #2D6CCA;
  font-weight: 400;
  font-size: 14px;
  margin-top: 13px;
  line-height: 22px;
  @media only screen and (max-width: 569px){
    margin-left: 28px;
  }
 `;
const SecondConnectwrapper = styled.div`
 color: #2D6CCA;
 font-weight: 400;
 font-size: 14px;
 margin-top: 13px;
 line-height: 22px;
 @media only screen and (max-width: 493px){
   margin-left: 28px;
 }
`;
const ThirdConnectwrapper = styled.div`
 color: #2D6CCA;
 font-weight: 400;
 font-size: 14px;
 margin-top: 13px;
 line-height: 22px;
 @media only screen and (max-width: 592px){
   margin-left: 28px;
 }
`;
const FourthConnectwrapper = styled.div`
 color: #2D6CCA;
 font-weight: 400;
 font-size: 14px;
 margin-top: 13px;
 line-height: 22px;
 @media only screen and (max-width: 592px){
   margin-left: 28px;
 }
`;
const CollapseStyled = styled(Collapse)`
  width: 100%;

  .ant-collapse-item {
    background: #F9F9FB !important;
    border: 1px solid #E8E8E8;
    margin: 10px 0 !important;
    border-radius: 10px !important;
  }
  .childCollapseStyle {
    border: none;
    background: transparent;
    margin: 20px 0 0 0 !important;
    .ant-collapse-header {
      padding: 0 !important;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      div {
        padding-top: 0 !important;
        .ant-collapse-arrow {
          position: unset !important;
          margin-left: 10px;
        }
      }
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
`;
