import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Row, Tabs} from 'antd';
import {
  Label,
  GenerateContentContainer,
  StyledInput,
  KGTab,
  StyledTextArea,
  Desc,
  KnowledgeGraphContainer,
} from './pressReleaseStyles';
import {getSingleUrlParam} from '@/utils/url';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import ImagesForm from '../../../imagesForm';
import {BusinessInfoForm} from '../../../KnowledgeGraph/businessInfoForm';
import {isArray} from 'lodash';

interface Props {
  url: string;
  setUrl: (value: string) => void;
  form: any;
  generating: boolean;
  images: any;
  setImages: any;
  youtubeUrl: any;
  setYoutubeUrl: any;
  youtubeUrlError: any;
  setYoutubeUrlError: any;
  logo: any;
  setLogo: any;
  serpLocation: any;
  setSerpLocation: any;
  setSerpLocationId: any;
  errorTabs?: string[];
  imagesError?: string;
  languageCode?: string;
  setLanguageCode?: any;
  isStandalone?: boolean;
}

const GenerateContent = ({
  url,
  setUrl,
  form,
  generating,
  images,
  setImages,
  youtubeUrl,
  setYoutubeUrl,
  youtubeUrlError,
  setYoutubeUrlError,
  logo,
  setLogo,
  serpLocation,
  setSerpLocation,
  setSerpLocationId,
  errorTabs,
  imagesError,
  languageCode,
  setLanguageCode,
  isStandalone,
}: Props) => {
  const {
    ottoV2Store: {getOttoV2Project, knowledgeGraph, getOttoKnowledgeGraphFieldsMeta},
  } = useStore('');
  const [tabKeys, setTabKeys] = useState('business_section');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [knowledgeGraphMetaData, setKnowledgeGraphMetaData] = useState([]);
  const baseFields = [
    'business_name', 'address_administrative_area', 'email_address',
    'phone_number', 'authorship_first_name', 'authorship_last_name',
    'street_address', 'postal_code', 'website',
  ];
  const fieldsValidators = {
    phone_number: {
      validator: (_, value) => {
        if (value === '+') {
          return Promise.reject(new Error('This field is required to generate content'));
        }
        return Promise.resolve();
      },
    },
  };

  const requiredMetaFields = isStandalone ? [...baseFields, 'description', 'website'] : baseFields;

  useEffect(() => {
    if (getOttoKnowledgeGraphFieldsMeta?.value) {
      const updatedData = getOttoKnowledgeGraphFieldsMeta.value.map(i => {
        if (i?.name === 'business_section' || i?.name === 'authorship') {
          const updatedValue = i['value'].map(meta => {
            if (requiredMetaFields?.includes(meta?.name)) {
              return {
                ...meta,
                ...(meta.name === 'website' && {disabled: true}),
                validation: {
                  required: true,
                  message: 'This field is required to generate content',
                },
                validator: fieldsValidators[meta?.name],
              };
            }
            return meta;
          });
          return {...i, value: updatedValue};
        }
        return i;
      });
      setKnowledgeGraphMetaData(updatedData);
    }
  }, [getOttoKnowledgeGraphFieldsMeta?.value]);

  const validateKeywords = (_, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) {
        reject(new Error('Please enter keywords'));
      }
      const keywords = value.split(',').map(keyword => keyword.trim()).filter(keyword => keyword !== '');
      if (keywords.length > 3) {
        reject(new Error('Up to 3 keywords are allowed'));
      }
      resolve();
    });
  };

  const getKnowledgeGraphCompletion = () => {
    // keys that are not used in form
    const removeFromTotal = ['backgroundInfo', 'email', 'gbpData', 'linkUrls', 'productFeatures', 'type', 'logo'];
    const filterFormat = {};
    for (const key in knowledgeGraph) {
      if (!removeFromTotal.includes(key)) {
        filterFormat[key] = knowledgeGraph[key];
      }
    }
    const completedCount = Object.values(filterFormat || {})?.filter(el => {
      if (el && Object.keys(el).length > 0) {
        return el;
      } else if (typeof el == 'number') {
        return el;
      } else if (isArray(el) && el.length > 0) {
        return el;
      } else if (el && el != '') {
        return el;
      }
    })?.length;
    const totalCount = Object.values(filterFormat || {})?.length;
    return Math.round((completedCount / totalCount) * 100);
  };


  return (
    <GenerateContentContainer>
      <Row className='row' align='middle' gutter={40}>
        <Col span={6}>
          <Label>Target Page URL</Label>
        </Col>
        <Col span={18}>
          <Form.Item
            name={'target_url'}
            className='formItem'
          >
            <div className='body' style={generating ? {background: '#f5f5f5', borderColor: '', color: 'rgba(0, 0, 0, 0.25)', cursor: 'not-allowed'} : {}}>
              <div className='domain-input-wrapper'>
                {domainName && <div className='domain'>{domainName}/</div>}
                <Input className={`slug ${domainName ? '' : 'side-gutter'}`} value={url} disabled={generating} onChange={e => {
                  const value = e?.target?.value;
                  setUrl(value);
                }} bordered={false}></Input>
              </div>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row className='row' align='middle' gutter={40}>
        <Col span={6}>
          <Label>Target Keyword(s)</Label>
        </Col>
        <Col span={18}>
          <Form.Item
            name={'target_keywords'}
            rules={[
              {validator: validateKeywords},
            ]}
            className='formItem'
          >
            <StyledInput disabled={generating} placeholder='Up to 3 keywords, divided by commas'/>
          </Form.Item>
        </Col>
      </Row>
      <Row className='row' align='top' gutter={40}>
        <Col span={6}>
          <Label>AI Writing Instructions</Label>
        </Col>
        <Col span={18}>
          <Form.Item
            name={'input_prompt'}
            className='formItem'
            rules={[
              {required: true, message: 'Please enter AI writing instructions'},
            ]}
          >
            <StyledTextArea disabled={generating} placeholder='Provide writing instructions and describe the subject of the written content. Your guidance ensures the content meets your needs.'/>
          </Form.Item>
        </Col>
      </Row>
      {/* <KnowledgeGraphBanner backgroundColor='rgba(244, 67, 67, 0.14)'>
        <div className='left'>
          <FontAwesomeIcon color='#F44343' icon={faCircleInfo} />
          3 required fields for the knowledge graph must be completed.
        </div>
        <div className='right'>
          <HideandShowButton>
            Hide
            <FontAwesomeIcon icon={faAngleUp} />
          </HideandShowButton>
        </div>
      </KnowledgeGraphBanner> */}
      <Row className='row' align='top' gutter={40}>
        <Col span={6}>
          <Label>Knowledge Graph </Label>
          <Desc>{getKnowledgeGraphCompletion() || 0}% Completed</Desc>
        </Col>
        <Col span={18}>
          <KnowledgeGraphContainer>
            <Tabs className='modal-tabs kg-tabs' activeKey={tabKeys} onChange={keys => setTabKeys(keys)}>
              {knowledgeGraphMetaData?.filter(i => i?.name !== 'top_pages')?.map(item => {
                return (
                  <Tabs.TabPane forceRender tab={<KGTab showErrorIndicator={errorTabs?.includes(item?.name)}>{item?.label}</KGTab>} key={item?.name} className={'first-item'}>
                    {item?.name == 'images' ? (
                      <ImagesForm
                        images={images}
                        setImages={setImages}
                        youtubeUrl={youtubeUrl}
                        setYoutubeUrl={setYoutubeUrl}
                        youtubeUrlError={youtubeUrlError}
                        setYoutubeUrlError={setYoutubeUrlError}
                        logo={logo}
                        setLogo={setLogo}
                        style={{marginLeft: '13px', maxHeight: '294px', overflowY: 'auto'}}
                        imagesError={imagesError}
                      />
                    ) : (
                      <BusinessInfoForm
                        dropDownBottomValue={1}
                        data={item['value']}
                        setSerpLocation={setSerpLocation}
                        serpLocation={serpLocation}
                        setSerpLocationId={setSerpLocationId}
                        form={form}
                        style={{marginLeft: '13px'}}
                        labelSpan={7}
                        span={17}
                        maxHeight='294px'
                        formErrorMargin='29%'
                        languageCode={languageCode}
                        setLanguageCode={setLanguageCode}
                      />
                    )}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </KnowledgeGraphContainer>
        </Col>
      </Row>
    </GenerateContentContainer>
  );
};

export default observer(GenerateContent);
