import {Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {StyledIssuesCollapse} from '../../../../style';
import {TableTopBar} from '../../../tableTopBar';
import {GbpQAndAnswersTable} from './gbpQAndAnswersTable';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
  setOpenQAModal: (value: boolean) => void;
  setOpenGenerateQAModal: (value: any) => void;
  setOpenAutomateSettingsModal?: (value: boolean) => void;
}

export const GbpQAndAnswers = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, setOpenQAModal, setOpenGenerateQAModal, setOpenAutomateSettingsModal}: Props) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [toggleActive, setToggleActive] = useState(false);

  const {ottoV2Store: {updateQandALocationSettings, getQandALocationSettings, selectedLocation, getQAndASettings}} = useStore('');

  useEffect(() => {
    if (selectedLocation?.id) getQandALocationSettings(selectedLocation?.id);
  }, [selectedLocation?.id]);

  useEffect(() => {
    setToggleActive(toJS(getQAndASettings)?.isActive);
  }, [getQAndASettings]);

  const handleActive = async (val: boolean) => {
    try {
      setToggleActive(val);
      await updateQandALocationSettings(selectedLocation?.id, {is_active: val});
      notification.success('', `Automated Q&A successfully turned ${val ? 'On' : 'Off'}`);
      await getQandALocationSettings(selectedLocation?.id, false);
    } catch (error) {
      const errorMessage = apiError(error);
      notification.error('', errorMessage);
    }
  };

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        setOpenQAModal={setOpenQAModal}
        setOpenGenerateQAModal={setOpenGenerateQAModal}
        isActiveFilter={isActiveFilter}
        inputPlaceholderText={'Search by Q&A content'}
        isResponsiveHeader
        handleActive={handleActive}
        toggleActive={toggleActive}
        setOpenAutomateSettingsModal={setOpenAutomateSettingsModal}
      />
      <Collapse.Panel key='open' header={<></>}>
        <GbpQAndAnswersTable
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable} />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
