import {REPORTER_API} from '@/api/ReporterApi';
import {formatSorters} from '@/utils/filters';
import {useErrorNotification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {flow, types} from 'mobx-state-tree';

const PitchQueryModel = types.model({
  country: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  industryNames: types.maybeNull(types.array(types.maybeNull(types.string))),
  hasResponded: types.maybeNull(types.boolean),
  // metroarea: null
  queryDetail: types.maybeNull(types.string),
  requirements: types.maybeNull(types.string),
  secondline: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  userTitle: types.maybeNull(types.string),
  aiGeneratedReply: types.optional(types.string, ''),
  loadingWordsArray: types.optional(types.boolean, false),
});

const haroPitchListModel = types.model({
  createdAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  response: types.maybeNull(types.string),
  createdByUse: types.maybeNull(types.string),
  haroQuery: PitchQueryModel,
});
const haroResultsListModel = types.model({
  country: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  industryNames: types.maybeNull(types.array(types.maybeNull(types.string))),
  hasResponded: types.maybeNull(types.boolean),
  // metroarea: null
  queryDetail: types.maybeNull(types.string),
  requirements: types.maybeNull(types.string),
  secondline: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  userTitle: types.maybeNull(types.string),
  aiGeneratedReply: types.optional(types.string, ''),
  loadingWordsArray: types.optional(types.boolean, false),
  pitchResponses: types.maybeNull(types.array(types.string)),
  pitchTimestamps: types.maybeNull(types.array(types.string)),
});

const haroPitchModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(haroPitchListModel)),
  totalPages: types.maybeNull(types.number),
});
const haroQueriesModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(haroResultsListModel)),
  totalPages: types.maybeNull(types.number),
});

export const HaroStore = types.model({
  showCreateHaroModal: types.boolean,
  reportModal: types.boolean,
  loadingList: types.maybeNull(types.boolean),
  initQueryLoading: types.optional(types.boolean, true),
  initPitchLoading: types.optional(types.boolean, true),
  haroQueriesList: types.maybeNull(haroQueriesModel),
  haroPitchesList: types.maybeNull(haroPitchModel),
  selectedProject: types.maybeNull(types.string),
  queriesPageNumber: types.maybeNull(types.number),
  queriesPageSize: types.maybeNull(types.number),
  pitchesPageNumber: types.maybeNull(types.number),
  pitchesPageSize: types.maybeNull(types.number),
  submittingReplay: types.maybeNull(types.boolean),
  queriesSortKey: types.maybeNull(types.string),
  queriesSortDirection: types.maybeNull(types.string),
})
  .views(self => ({
    get getHaroQueriesList() {
      return toJS(self.haroQueriesList);
    },
    get getHaroPitchesList() {
      return toJS(self.haroPitchesList);
    },
  }))
  .actions(self => {
    const setShowCreateHaroModal = value => {
      self.showCreateHaroModal = value;
    };
    const setReportModal = value => {
      self.reportModal = value;
    };

    const loadHaroPitches = flow(function* (noLoading?: boolean) {
      if (!noLoading) {
        self.loadingList = true;
      }
      try {
        const response = yield REPORTER_API.getHaroPitches( self.pitchesPageNumber, self.pitchesPageSize);
        if (response.isCancel) return;
        if (response) {
          self.haroPitchesList = response;
          self.loadingList = false;
          self.initPitchLoading = false;
        }
      } catch (err) {
        useErrorNotification({
          e: err,
          msg: 'Could not load HARO pitches.',
          desc: 'Please try again later.',
          permanent: false,
        });
        return Promise.reject(err);
      } finally {
        self.loadingList = false;
      }
    });
    const loadHaroQueries = flow(function* (queryParams, noLoading?: boolean) {
      if (!noLoading) {
        self.loadingList = true;
      }

      const sorters = {
        dueDate: 'due_date',
      };

      const formtatedSorters = formatSorters(sorters, self.queriesSortKey, self.queriesSortDirection);

      try {
        const response = yield REPORTER_API.getHaroQueries(self.queriesPageNumber, self.queriesPageSize, {...(queryParams ?? {}), ...(formtatedSorters && {'ordering': formtatedSorters})});
        if (response.isCancel) return;
        if (response) {
          self.haroQueriesList = response;
          self.loadingList = false;
          self.initQueryLoading = false;
        }
      } catch (err) {
        useErrorNotification({
          e: err,
          msg: 'Could not load HARO queries.',
          desc: 'Please try again later.',
          permanent: false,
        });

        return Promise.reject(err);
      } finally {
        self.loadingList = false;
      }
    });

    const handleGenerateReply = flow(function* (id, guidelines?: string) {
      // self.loadingList = true;
      const findIndex = self.haroQueriesList?.results?.findIndex(item => item?.id == id);
      self.haroQueriesList.results[findIndex].loadingWordsArray = true;
      try {
        const response = yield REPORTER_API.generateAiReplay(id, guidelines);
        if (response) {
          if (findIndex != -1) {
            self.haroQueriesList.results[findIndex].aiGeneratedReply = response;
          }
          return response;
        }
      } catch (err) {
        useErrorNotification({
          e: err,
          msg: 'Could not generate the reply.',
          desc: 'Please try again later.',
          permanent: false,
        });

        return Promise.reject(err);
      } finally {
        self.haroQueriesList.results[findIndex].loadingWordsArray = false;
      }
    });
    const submitReplay = flow(function* (id, msg?: string) {
      self.submittingReplay = true;
      try {
        const response = yield REPORTER_API.submitReplay(id, msg);
        if (response) {
          return response;
        }

        return response;
      } catch (err) {
        useErrorNotification({
          e: err,
          msg: 'Could not submit the reply.',
          desc: 'Please try again later.',
          permanent: false,
        });
        return Promise.reject(err);
      } finally {
        self.submittingReplay = false;
      }
    });

    const setQueriesPageNumber = (value: number) => self.queriesPageNumber = value;
    const setQueriesPageSize = (value: number) => self.queriesPageSize = value;
    const setPitchesPageNumber = (value: number) => self.pitchesPageNumber = value;
    const setPitchesPageSize = (value: number) => self.pitchesPageSize = value;
    const setQueriesSortKey = (value: string) => self.queriesSortKey = value;
    const setQueriesSortDirection = (value: string) => self.queriesSortDirection = value;

    return {
      setShowCreateHaroModal,
      setReportModal,
      loadHaroQueries,
      loadHaroPitches,
      handleGenerateReply,
      setQueriesPageNumber,
      setQueriesPageSize,
      setPitchesPageNumber,
      setPitchesPageSize,
      submitReplay,
      setQueriesSortKey,
      setQueriesSortDirection,
    };
  });

export const initHaroStore = () => {
  return {
    showCreateHaroModal: false,
    reportModal: false,
    loadingList: true,
    selectedProject: '',
    queriesPageNumber: 1,
    queriesPageSize: 100,
    pitchesPageNumber: 1,
    pitchesPageSize: 100,
    submittingReplay: false,
    initQueryLoading: true,
    initPitchLoading: true,
    queriesSortKey: '',
    queriesSortDirection: 'descend',
  };
};
