import {observer} from 'mobx-react';
import {ContentSectionWrapper, ModalStyled, StyledInputNumber} from '../styledComponents';
import {useStore} from '@/store/root-store';
import {ButtonStyled, ButtonsWrapper, Description, Heading} from '../../styledComponents';
import {Checkbox, Col, Divider, Form, Row, TimePicker} from 'antd';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {toJS} from 'mobx';

export const ConfigureModal = observer(() => {
  const {campaigns: {
    showConfigureModal,
    setShowConfigureModal,
    updateCampaignOpportunity,
    updateSettingsLoading,
    chartData,
    campaignSettings}} = useStore('');
  const [days, setDays] = useState([]);
  const [form] = Form.useForm();
  const [eNumbers, setNumbers] = useState('');
  const router = useRouter();
  const [time, setTime] = useState({send_from: '', send_to: ''});
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setDays(toJS(campaignSettings)?.daysOfWeek || []);
    setNumbers(String(toJS(campaignSettings)?.dailyLimit) || '');
    setTime({send_from: toJS(campaignSettings)?.sendFrom, send_to: toJS(campaignSettings)?.sendTo});
  }, [campaignSettings, showConfigureModal]);

  const daysNumber = [
    {name: 'Mo', value: 1},
    {name: 'Tu', value: 2},
    {name: 'We', value: 3},
    {name: 'Th', value: 4},
    {name: 'Fr', value: 5},
    {name: 'Sa', value: 6},
    {name: 'Su', value: 7},
  ];

  const updateDay = val => {
    if (days.includes(val.value)) {
      setDays(prevDays => prevDays.filter(day => day !== val.value));
    } else {
      setDays(prevDays => [...prevDays, val.value]);
    }
  };

  const resetFields = () => {
    setNumbers(String(toJS(campaignSettings)?.dailyLimit));
    setDays(toJS(campaignSettings)?.daysOfWeek || []);
    setTime({send_from: toJS(campaignSettings)?.sendFrom, send_to: toJS(campaignSettings)?.sendTo});
    setShowError(false);
    form.resetFields();
    setShowConfigureModal(false);
  };

  const updateConfig = async () => {
    if (!days?.length || !time?.send_from || !time?.send_to || !eNumbers) {
      setShowError(true);
      return;
    }
    await updateCampaignOpportunity({days_of_week: days?.sort(), send_from: time.send_from, send_to: time.send_to, daily_limit: eNumbers, campaign: (router.query.id[0])});
    resetFields();
  };

  return <ModalStyled
    width={735}
    closable={true}
    closeIcon={<FontAwesomeIcon icon={faXmark} />}
    mask={true}
    zIndex={100}
    visible={showConfigureModal}
    maskClosable={false}
    onClose={() => {
      resetFields();
    }}>
    <Heading>Configure outreach</Heading>
    <Description>{chartData?.opportunities ?? '-'} opportunities ({chartData?.sentEmails ?? '-'} email addresses) added to the outreach list for this campaign.</Description>
    <Content>
      <Form form={form}>
        <Row>
          <Col span={4}>
            <div style={{color: '#4E5156'}}>Send on</div>
          </Col>
          <Col span={18}>
            {daysNumber.map((val, index) => {
              const isChecked = days.includes(val.value);
              return (
                <Checkbox
                  checked={isChecked}
                  key={index}
                  onChange={() => updateDay(val)}
                >
                  {val.name}
                </Checkbox>
              );
            })}
            {showError && !days?.length && <div style={{color: 'red'}}>Please select atleast one day</div>}
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <div style={{color: '#4E5156', marginTop: '15px'}}>Send between</div>
          </Col>
          <Col className='timeWrapper' style={{display: 'flex', alignItems: 'center'}} span={18}>
            <TimePicker
              defaultValue={moment(time?.send_from, 'HH:mm')}
              value={time?.send_from ? moment(time?.send_from, 'HH:mm') : null}
              status={showError && !time?.send_from && 'error'}
              use12Hours={false}
              format='h:mm A'
              onSelect={e => setTime({...time, send_from: moment(e, 'hh:mm A').format('HH:mm')})}
              onChange={e => setTime({...time, send_from: moment(e, 'hh:mm A').format('HH:mm')})}
              onBlur={e => {
                const enteredTime = e.target.value.trim();
                const timeFormat = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/i;
                if (enteredTime && timeFormat.test(enteredTime)) {
                  setTime({...time, send_from: moment(enteredTime, 'h:mm A').format('HH:mm')});
                } else if (!enteredTime) {
                  setTime({...time, send_from: null});
                }
              }}
            />

          &nbsp;&nbsp;<div style={{color: '#4E5156'}}>and</div>&nbsp;&nbsp;
            <TimePicker
              defaultValue={moment(time?.send_to, 'HH:mm')}
              value={moment(time?.send_to, 'HH:mm')}
              status={showError && !time?.send_to && 'error'}
              use12Hours={false} format='h:mm A'
              onSelect={e => setTime({...time, send_to: moment(e, 'hh:mm A').format('HH:mm')})}
              onChange={e => setTime({...time, send_to: moment(e, 'hh:mm A').format('HH:mm')})}
              onBlur={e => {
                const enteredTime = e.target.value.trim();
                const timeFormat = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/i;
                if (enteredTime && timeFormat.test(enteredTime)) {
                  setTime({...time, send_to: moment(enteredTime, 'h:mm A').format('HH:mm')});
                } else if (!enteredTime) {
                  setTime({...time, send_to: null});
                }
              }}/>
            <Utc>UTC Time Zone</Utc>
          </Col>
          {(!time?.send_from || !time?.send_to) && showError && <div style={{color: 'red', marginLeft: 112}}>Please select time</div>}
        </Row>
        <Row style={{marginBottom: '30px'}}>
          <Col span={4}>
            <div style={{color: '#4E5156'}}>Upper limit</div>
          </Col>
          <Col style={{display: 'flex', alignItems: 'center'}} span={18}>
            <StyledInputNumber isError={showError && !eNumbers} value={eNumbers} type='number' onChange={e => {
              const input = e.target.value;

              const sanitizedInput = input.replace(/^0+|[^0-9]/g, '');

              setNumbers(sanitizedInput);
            }} /><div style={{color: '#4E5156'}}>&nbsp;&nbsp;emails per day</div>
          </Col>
          {showError && !eNumbers && <div style={{color: 'red', marginLeft: 112}}>Please enter a limit</div>}
        </Row>
        <Divider />
        <ButtonsWrapper key='wrapper'>
          <ButtonStyled isCancel={true} onClick={() => {
            resetFields();
          }}>
          Cancel
          </ButtonStyled>
          <ButtonStyled loading={updateSettingsLoading} type='primary' onClick={() => updateConfig()}>
          Update configuration
          </ButtonStyled>
        </ButtonsWrapper>
      </Form>
    </Content>
  </ModalStyled>;
});

const Content = styled(ContentSectionWrapper)`
.ant-picker{
  border-radius: 8px !important;
    width: 122px !important;
}
.ant-form-item{
  height: fit-content !important;
    margin: 0 !important;
}
.timeWrapper{
  align-items: center;
  margin: 15px 0 15px 0;
}
`;

const Utc = styled.div`
  margin-left: 11px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #4E5156;
`;
