import React, {ReactNode, useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {Menu as AntdMenu, Tooltip} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import styles from './style.module.scss';
import classnames from 'classnames';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {routes} from '@/utils/const';
import {faLocationDot} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import {Button} from '@/components/common-components/v2';
import {isNavbarRemoveOnPublicPages} from '@/utils/url';
import {LargeBadge} from '@/components/common-components/components/badge';
import {NextImg} from '@/utils/nextImg';

type BaseMenuItem = {
  path?: string;
  key: string;
};

type PlainMenuItem = BaseMenuItem & {
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  color?: string;
  commingSoon?: boolean;
};

export type MenuItem = PlainMenuItem & {
  items?: PlainMenuItem[];
};

export type ItemsGroup = {
  title: string;
  key: string;
  items?: MenuItem[];
  hidden?: boolean;
  isShow?: boolean;
  isBeta?: boolean;
  isAlpha?: boolean;
  disabled?: boolean;
  color?: string;
  path?: string;
  icon?: ReactNode;
};

type MenuProps = {
  groups: ItemsGroup[];
  handleSideBarItems?: (items) => void;
};

const Menu: React.FC<MenuProps> = observer(({handleSideBarItems, groups, ...props}: MenuProps) => {
  const [selectedKey, setSelectedKey] = useState([]);
  const router = useRouter();
  const {
    navBar: {isDockedSider},
    settings: {
      customer: {setAuthModal},
    },
    competitorResearcherV2: {getCompetitorResearcherId},
    siteAuditorV2: {siteAuditSpacificData},
  } = useStore('');
  function renderPlainItem({key, disabled, icon, title, path, isAlpha=false, color='', isBeta=false, items, hidden=false}: ItemsGroup) {
    return !hidden && <AntdMenuItemStyled itemColor={color} onClick={() => {
      if (!disabled) {
        if (path?.includes('gsc')) {
          handleSideBarItems(items);
        }
      }
    }} key={key} className={classnames( (disabled ? styles.disabled : (key == '/tutorials' || key == '/otto-page' ? styles.firstItemStyle : '')), `${key}-submenu`)}>
      {disabled ? <Tooltip
        trigger={'hover'}
        title={disabled ? <PopupStyled>
          <NextImg src='/img/icon/linkIconCircle.svg'/>
          <PopupTitle>Discover the backlinks of any site.</PopupTitle>
          <PopupSubTitle>The Backlinks tool is available in the Growth, Pro and Custom plans.</PopupSubTitle>
          <a href={!router.pathname?.includes('public') && `${location.origin}/pricing`}><Button style={{marginTop: '20px'}} color={'green'}>View Pricing</Button></a>
        </PopupStyled> : ''}
        overlayClassName={styles.popupCustomStlye}
        placement={'bottom'}><AntdMenuItemStyled itemColor={color} key={key} className={classnames( (disabled ? styles.disabled : (key == '/tutorials' || key == '/otto-page' ? styles.firstItemStyle : '')), `${key}-submenu`)}>
          <div style={{display: 'flex', alignItems: 'center'}}><span className={styles.icon}>{icon}</span> {title} {disabled && <LockOutlined/>}{isAlpha && <LargeBadge alpha /> }{isBeta && <LargeBadge beta /> }</div>
        </AntdMenuItemStyled>;
      </Tooltip> :
        <Link legacyBehavior href={disabled ? '' : `${items?.length ? items[0]?.path : (path ? path : '')}`} passHref>
          <a onClick={() => {
            if (!`${items?.length ? items[0]?.path : (path ? path : '')}`?.includes('public')) {
              setAuthModal(true, true);
            }
          }}>
            <div style={{display: 'flex', alignItems: 'center'}}><span className={styles.icon}>{icon}</span> {title} {disabled && <LockOutlined/>}{isAlpha && <LargeBadge alpha /> }{isBeta && <LargeBadge beta /> }</div>
          </a>
        </Link>
      }
    </AntdMenuItemStyled>;
  }

  useEffect(() => {
    const activeKey = router.asPath?.split('?')[0];
    const mainTool = groups?.find(item => item?.path?.split('?')[0] == activeKey || (router?.pathname?.includes(item?.path?.split('?')[0]) && router?.pathname?.includes('site-explorer')));
    const checkItemPath = groups?.find(item => item?.items?.find(z => z?.path?.split('?')[0] == activeKey || router?.pathname?.includes(z?.path?.split('?')[0])));
    const acitiveMenu = mainTool?.key ? mainTool.key : (checkItemPath?.key ? checkItemPath?.key : (activeKey == '/research/local-seo' || activeKey == '/otto-page' ? activeKey : ''));
    setSelectedKey([acitiveMenu]);
    handleSideBarItems(mainTool?.key ? mainTool?.items : checkItemPath?.key ? checkItemPath?.items : []);
  }, [router, getCompetitorResearcherId, siteAuditSpacificData]);

  return (
    <>
      {isNavbarRemoveOnPublicPages() ? <></> : <><AntdMenu
        className={styles.menu}
        mode='horizontal'
        style={{marginLeft: isDockedSider ? '25px' : '30px', maxWidth: '780px'}}
        rootClassName={styles.rootMenuStyle}
        {...props}
        selectedKeys={selectedKey}
      >
        {groups?.map(item => {
          return <>{renderPlainItem(item)}</>;
        })}
      </AntdMenu><AntdMenu
        className={classnames(styles.menu, styles.singleItemMenu)}
        mode='horizontal'
        rootClassName={styles.rootMenuStyle}
        {...props}
        selectedKeys={selectedKey}
      >
        {renderPlainItem({
          title: 'Local SEO',
          key: `${routes.localSeo}`,
          path: `${routes.localSeo}`,
          color: '#FF8536',
          icon: <Icon onClick={() => { } } icon={faLocationDot} color='#A3A4A4' />,
        })}
      </AntdMenu></>}
    </>
  );
});

export default Menu;

const AntdMenuItemStyled = styled(AntdMenu.Item)<{itemColor: string}>`
  &.ant-menu-item {
    color: #E8E8E8 !important;
    font-weight: 500 !important;
    background: transparent !important;
    &::after {
      border-bottom: 0px solid !important;
      transition: unset  !important;
    }
    &:hover {
      border-bottom: 0px solid !important;
      &::after {
        border-bottom: 0px solid !important;
        transition: unset !important;
      }

      .ant-menu-title-content {
        color: #fff;
        svg {
          color: ${p => p.itemColor} !important;
          fill: ${p => p.itemColor} !important;
        }
      }
      a {
        color: #fff !important;
        font-weight: 500 !important;
        background: transparent !important;
      }
    }
    a {
      color: #E8E8E8 !important;
      font-weight: 500 !important;
      background: transparent !important;
    }
    .ant-menu-title-content > span {
          height: inherit;
        }
  }
  &.ant-menu-item-selected {
   
      .ant-menu-title-content {
        color: #fff;
        font-weight: 500 !important;
        display: block;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          top: 90%;
          bottom: 0;
          left: 1%;
          right: 1%;
          height: 5px;
          width: 100%;
          background-color: ${p => p.itemColor};
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
      }

        svg {
          color: ${p => p.itemColor} !important;
          fill: ${p => p.itemColor} !important;
        }
      }
      a {
        color: #fff !important;
        font-weight: 500 !important;
        background: transparent !important;
      }
    }
`;

const Icon = styled(FontAwesomeIcon)`
  height: 16px !important;
  width: 18px !important; 
`;

const PopupStyled = styled.div`
  height: 185px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const PopupTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;

`;

const PopupSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #E8E8E8;
  margin-top: 6px;
`;
