import {Table as TableAntd} from 'antd';
import {SkeletonHorizontalLoaderGray, SkeletonHorizontalLoader} from '@/components/common-components';
import React from 'react';

export interface TableSkeletonProps {
  style: React.CSSProperties;
  tableProps: any;
  loadingRows: number;
  isGrayLoader: boolean;
  loaderClassName?: string;
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({
  style,
  tableProps,
  loadingRows,
  isGrayLoader,
  loaderClassName,
}) => {
  const columns = tableProps?.columns;

  if (!columns) return <></>;

  const dataSource = [];
  for (let i=0; i<loadingRows; i++) {
    dataSource.push(columns?.reduce( (result, column) => {
      result[column.key] =
      isGrayLoader ? (
        column?.numberOfLoader?.length ? <div style={{display: 'grid', gap: '4px'}}>
          {column?.numberOfLoader?.map((z, idx) => {
            return <SkeletonHorizontalLoaderGray key={`${i}_${idx}`} containerClassName={loaderClassName} lightLoader width={i % 2 == 0 ? `${z?.width-30}px` : `${z?.width+30}px`} height='10px' skeletonHeight='10px' align={column?.skeletonAlign ? column?.skeletonAlign : 'left' }/>;
          })}
        </div> :
          <SkeletonHorizontalLoaderGray containerClassName={loaderClassName} lightLoader width={i % 2 == 0 ? '45px' : '55px'} height='10px' skeletonHeight='10px' align={column?.skeletonAlign ? column?.skeletonAlign : 'left' }/>) :
        <SkeletonHorizontalLoader width='45px' height='10px' align={column?.skeletonAlign ? column?.skeletonAlign : 'left' }/>
      ;
      return result;
    }, {}));
  }

  const newColumns = columns?.map( col => {
    return {title: col.title, dataIndex: col.dataIndex, key: col.key, width: col.width};
  });

  const newTableProps = Object.assign({}, tableProps, {dataSource, loading: false});
  newTableProps.columns = newColumns;

  return (
    <TableAntd style={style} rowKey='key' {...newTableProps} />
  );
};
