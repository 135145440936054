import classname from 'classnames';
import React from 'react';
import styles from './index.module.scss';

const highlightColor = (datum: number) => {
  return datum >= 70 ? styles.red : datum >= 40 ? styles.orange : styles.green;
};

export interface NumberBlockColumnProps {
  datum: number;
}

export const NumberBlockColumn: React.FC<NumberBlockColumnProps> = ({datum}) => {
  return (
    <div className={classname(styles.numberBlock, highlightColor(datum))}>
      {datum ? datum : '-'}
    </div>
  );
};
