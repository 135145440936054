import {LoadingOutlined} from '@ant-design/icons';

import {
  Button,
  Typography,
  Modal,
} from '@/components/common-components/components';

import {Spin,
} from 'antd';


import styles from './styles.module.scss';
import commonStyles from '../../common-styles.module.scss';
import React from 'react';
import styled from 'styled-components';

const loaderIcon = <LoadingOutlined className={commonStyles.LoaderIcon} spin />;

type DeleteConfirmModalProps = {
    isVisible: boolean;
    warningText: string;
    isDeleting: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    confirmButtonColor?: string;
};

export const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  isVisible,
  warningText,
  isDeleting,
  onConfirm,
  onCancel,
  confirmButtonColor,
}) => {
  return (
    <StyledModal
      zIndex={1211}
      visible={isVisible}
      onClose={onCancel}>
      <div className={styles.ConfirmationModal}>
        <Typography tag='h4' className={styles.ConfirmationGroupName}>{warningText}</Typography>
        <p>This action cannot be undone.</p>
        <Button disabled={isDeleting} onClick={onConfirm} className={styles.Danger} style={{backgroundColor: confirmButtonColor}}>{isDeleting ? <Spin indicator={loaderIcon} /> : 'Delete'}</Button>
        <Button onClick={onCancel}className={styles.Back}>Go Back</Button>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  @media screen and (max-width: 500px) {
    .rc-dialog-close {
      top: 7px;
      right: 7px;
    }
  }
`;

