import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {Tooltip} from 'antd';
import {useRouter} from 'next/router';

const ReviewQAField = ({field, unanswered, isUnanswered, isInLocalSeo}) => {
  const {ottoV2Store: {
    setSelectedCategory,
    setActiveReviewsTab,
    setActiveReviewsKey,
    setNavigatedFromGbpOptimizations,
    setActiveQuestionsKey,
    setActiveQuestionsTab,
  }} = useStore('');

  const [iconDetails, setIconDetails] = useState<any>({
    color: '#FF8536',
    icon: faTriangleExclamation,
  });

  const router = useRouter();

  useEffect(() => {
    if (!isUnanswered) {
      setIconDetails({
        color: '#2AC155',
        icon: faCircleCheck,
      });
    } else {
      setIconDetails({
        color: '#FF8536',
        icon: faTriangleExclamation,
      });
    }
  }, [isUnanswered]);

  const handleClick = () => {
    setNavigatedFromGbpOptimizations(true);
    if (unanswered) {
      if (isInLocalSeo) {
        router.push('/research/gbp-automations/reviews');
      } else {
        setSelectedCategory('unanswered_reviews');
      }
      if (!isUnanswered) {
        setActiveReviewsTab('all');
        setActiveReviewsKey(Math.floor(Math.random() * 1000));
      } else {
        setActiveReviewsTab('not_deployed');
        setActiveReviewsKey(Math.floor(Math.random() * 1000));
      }
    } else {
      if (isInLocalSeo) {
        router.push('/research/gbp-automations/questions-and-answers');
      } else {
        setSelectedCategory('qanda_suggestions');
      }
      if (!isUnanswered) {
        setActiveQuestionsTab('all');
        setActiveQuestionsKey(Math.floor(Math.random() * 1000));
      } else {
        setActiveQuestionsTab('not_deployed');
        setActiveQuestionsKey(Math.floor(Math.random() * 1000));
      }
    }
  };

  const getData = () => {
    if (isUnanswered) {
      if (field.fieldName == 'reviews') {
        return `${isUnanswered} Unanswered review${isUnanswered > 1 ? 's': ''}`;
      } else {
        return `${isUnanswered} Draft question${isUnanswered > 1 ? 's': ''}`;
      }
    } else {
      return 'All answered';
    }
  };

  return (
    <>
      <div onClick={handleClick} style={{cursor: 'pointer'}}>
        <Tooltip title={getData()}
          overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212'}}>
          <FontAwesomeIcon
            style={{marginRight: 6}}
            color={iconDetails.color}
            icon={iconDetails.icon}
          />
        </Tooltip>
        <span style={{color: !isUnanswered ? 'black' : '#2D6CCA'}}>
          {getData()}
        </span>
      </div>
    </>
  );
};

export default ReviewQAField;
