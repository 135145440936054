import {Tabs} from '@/components/common-components/components';
import styled from 'styled-components';

export const TooltipTitle = styled.div<{showGuide?: boolean; calledFrom?:string}>`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 14px;
color: #121212;
margin: 22px 12px;
margin-bottom: 0;
`;

export const StyledTabs = styled(Tabs)`
margin-top: 4px;
margin-left: 8px !important;
.rc-tabs-nav-operations{
  display: none !important;
}

.rc-tabs-nav{
  height: 50px !important;
  margin-left: 0px !important;
  .rc-tabs-tab {
    
  margin-right: 0px !important;
  width: 91px;
  display: flex;
  align-items: center;
  justify-content: center;

  .rc-tabs-tab-btn{
    padding: 5px 18px !important;
  }
}
}
`;

export const StyledLoaderDiv = styled.div`
  .cardBackground {
    background-color: transparent !important;
  }
  
`;

export const UncategoriezedProject = styled.span`
  font-size: 14px;
  color: #a3a4a4;
  font-family: inter;
  font-style: italic;
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: end;
`;
