import {AxiosResponse} from 'axios';
import {BaseApi} from '@/api/base-api';
import {getAuthorizationHeader} from '@/api/common-utils';
import snakeCase from 'lodash/snakeCase';
import map from 'lodash/map';

import type {
  AllProjectsApiResponse,
  GetRefdomainResponse,
  RefdomainApiResponse,
  RefdomainWithId,
  GetDistributionResponse,
  ProjectDistributionResponse,
  OutreachTargetItemResponse,
  GetCompetitorRefdomainResponse,
  GetTopicalRelevanceResponse,
  CompetitorRefdomainApiResponse,
  OutreachTargetItemData,
  GetTopicalRelevanceData,
  ToxicBacklinksResponse,
  ToxicBacklinksData,
  DisavowResponseItem,
  WhitelistResponseItem,
  UpdateProjectAPI,
} from './backlink-analyzer.model';

/**
 * Creates a new Backlink Analyzer API client.
 *
 * @class
 */
export class BacklinkAnalyzerApi extends BaseApi {
  /**
   * Returns the refdomain of the given project.
   *
   * @param  {number} id Project id
   * @param  {string} ordering Ordering
   * @param  {number} page Page
   * @param  {string} keyword keyword
   * @param  {any} filters filters
   * @param  {any} categories categories
   * @method getRefdomains
   */

  public async getRefdomains(id: number, ordering?:string, page?:number, keyword?:string, filters?:any, categories?:string[]): Promise<GetRefdomainResponse> {
    /*     console.log('FILTERS IN API',filters) */
    try {
      const {data}: AxiosResponse<RefdomainApiResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${id}/refdomains/`,
        params: {
          ordering,
          page,
          search: keyword,
          categories: categories && categories.join(','),
          domain_rating: filters && filters[0].from && (filters[0].from ==='' ? `0to${String(filters[0].to)}` : `${String(filters[0].from)}to${String(filters[0].to)}`),
          ahrefs_rank: filters && filters[1].from && (filters[1].from ==='' ? `0to${String(filters[1].to)}` : `${String(filters[1].from)}to${String(filters[1].to)}`),
          trust_flow: filters && filters[2]?.from && (filters[2].from ==='' ? `0to${String(filters[2].to)}` : `${String(filters[2].from)}to${String(filters[2].to)}`),
          citation_flow: filters && filters[3]?.from && (filters[3].from ==='' ? `0to${String(filters[3].to)}` : `${String(filters[3].from)}to${String(filters[3].to)}`),
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      // TODO: Remove this after they add id to the Refdomain;
      const content: RefdomainWithId[] = data?.refdomains?.map((domain, idx) => ({id: idx, ...domain}));

      return {
        primaryWebsite: data.primaryWebsite,
        content,
        refdomainsCount: data.refdomainsCount,
        ...data.taskStatus,
        ...data,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Returns a competitor's ref domains
   *
   * @param  {number} projectPk Project pk
   * @param  {number} competitorPk Competitor pk
   * @param  {string} ordering Ordering
   * @param  {number} page Page
   * @param  {string} keyword keyword
   * @param  {any} filters filters
   * @param  {any} categories categories
   */
  public async getCompetitorRefdomains(projectPk: number, competitorPk:number, ordering?:string, page?:number, keyword?:string, filters?:any, categories?:string[]): Promise<GetCompetitorRefdomainResponse> {
    try {
      const {data}: AxiosResponse<CompetitorRefdomainApiResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${projectPk}/${competitorPk}/refdomains/`,
        params: {
          ordering,
          page,
          search: keyword,
          categories: categories && categories.join(','),
          domain_rating: filters && filters[0].from && (filters[0].from ==='' ? `0to${String(filters[0].to)}` : `${String(filters[0].from)}to${String(filters[0].to)}`),
          ahrefs_rank: filters && filters[1].from && (filters[1].from ==='' ? `0to${String(filters[1].to)}` : `${String(filters[1].from)}to${String(filters[1].to)}`),
          trust_flow: filters && filters[2]?.from && (filters[2].from ==='' ? `0to${String(filters[2].to)}` : `${String(filters[2].from)}to${String(filters[2].to)}`),
          citation_flow: filters && filters[3]?.from && (filters[3].from ==='' ? `0to${String(filters[3].to)}` : `${String(filters[3].from)}to${String(filters[3].to)}`),
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      const content: RefdomainWithId[] = data?.refdomains?.map((domain, idx) => ({id: idx, ...domain}));

      return {
        primaryWebsite: data.primaryWebsite,
        content,
        refdomainsCount: data.refdomainsCount,
        competitorWebsite: data.competitorWebsite,
        ...data.taskStatus,
        ...data,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Returns a topicalRelevance categoires for a project
   *
   * @param  {number} projectPk Project pk
   */
  public async getTopicalRelevance(projectPk: number): Promise<GetTopicalRelevanceData> {
    try {
      const {data}: AxiosResponse<GetTopicalRelevanceResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${projectPk}/category-comparison/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return {category: data?.results?.category, website: data?.results?.website, status: data?.taskStatus?.status};
    } catch (e) {
      return Promise.reject(e);
    }
  }


  /**
   * Returns all of the projects associated with account.
   *
   * @method getAllProjects
   */
  public async getAllProjects(): Promise<AllProjectsApiResponse[]> {
    try {
      const {data}: AxiosResponse<AllProjectsApiResponse[]> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Returns a specific project.
   *
   * @method getProject
   * @param  {number} projectPk Project pk
   */
  public async getProject(projectPk: number): Promise<AllProjectsApiResponse> {
    try {
      const {data}: AxiosResponse<AllProjectsApiResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${projectPk}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Creates the project
   *
   * @param {CreateProjectApiRequest} payload Creation payload
   * @method createProject
   */
  public async createProject(payload): Promise<AllProjectsApiResponse> {
    const parsedPaylaod = Object.keys(payload).reduce((acc, curr) => {
      const snakeCaseKey = snakeCase(curr);
      acc[snakeCaseKey] = payload[curr];

      return acc;
    }, {});

    try {
      const {data}: AxiosResponse<AllProjectsApiResponse> = await this.axios({
        method: 'POST',
        url: `/backlink/projects/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: parsedPaylaod,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Edits the project
   *
   * @param {number} id Project id
   * @param {UpdateProjectAPI} payload Creation payload
   * @method createProject
   */
  public async editProject(id: number, payload: UpdateProjectAPI): Promise<AllProjectsApiResponse> {
    const parsedPayload = Object.keys(payload).reduce((acc, curr) => {
      const snakeCaseKey = snakeCase(curr);
      acc[snakeCaseKey] = payload[curr];

      return acc;
    }, {});

    try {
      const {data}: AxiosResponse<AllProjectsApiResponse> = await this.axios({
        method: 'PATCH',
        url: `/backlink/projects/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          pk: id,
          ...parsedPayload,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Gets competitors quota
   * @param {number} id
   * @param {string[]} competitors
   * @method getCompetitorsQuota
   */

  public async getCompetitorsQuota(id: number, competitors: string[]): Promise<any> {
    let competitorsList = '';
    competitors.map((competitor, id) => {
      competitorsList += `competitor=${competitor}${id !== competitors.length - 1 ? '&' : ''}`;
    });

    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${id}/competitors-quota/?${competitorsList}`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getRefdomainsQuota(competitors: string[]): Promise<any> {
    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'POST',
        url: '/backlink/refdomains-quota/',
        data: {
          urls: competitors,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Deletes the project
   *
   * @param {number} id Project id
   */
  public async deleteProject(id: number): Promise<any> {
    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'DELETE',
        url: `/backlink/projects/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Returns distribution related to project
   *
   * @param  {number} id Project id
   * @param  {string} hostname Host Name
   * @method getDistribution
   */
  public async getDistribution(id: number, hostname: string): Promise<GetDistributionResponse> {
    try {
      const {data}: AxiosResponse<ProjectDistributionResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${id}/distribution/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      const primaryProject = data?.results?.find( project => project.domainName === hostname);

      const domainRating = data?.results?.map(domain => {
        const data = map(domain.domainRating, value => {
          if (value === 0) {
            return 1;
          }
          return value;
        });
        const hostname = domain.domainName;

        return {
          data,
          hostname,
        };
      });

      if (!primaryProject) {
        throw new Error(`Not found in distribution api`);
      }

      return {
        backlinkTaskStatus: data.taskStatus.backlinkTaskStatus,
        primaryProject: primaryProject,
        domainRating: domainRating,
        projects: data.results,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Returns outreach targets
   *
   * @param  {number} pk Project id
   * @method getOutreachTargets
   */
  public async getOutreachTargets(pk: number): Promise<OutreachTargetItemData> {
    try {
      const {data}: AxiosResponse<OutreachTargetItemResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${pk}/outreachtargets/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return {results: data.results, status: data.taskStatus?.status};
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Returns backlink velocity data
   *
   * @param  {number} pk Project id
   * @method getBacklinkVelocity
   */
  public async getBacklinkVelocity(pk: number) {
    try {
      const {data}: any = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${pk}/velocity/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Returns toxic backlinks
 *
 * @param  {number} pk Project id
 * @method getToxicBacklinks
 */
  public async getToxicBacklinks(pk: number): Promise<ToxicBacklinksData> {
    try {
      const {data}: AxiosResponse<ToxicBacklinksResponse> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${pk}/toxicity/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return {toxicBacklinks: data?.results, aggregated: data?.aggregated, toxicity: data?.taskStatus?.toxicity, ahrefs: data?.taskStatus?.ahrefs};
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Returns disavow list
 *
 * @param  {number} pk Project id
 * @method getDisavow
 */
  public async getDisavow(pk: number): Promise<DisavowResponseItem[]> {
    try {
      const {data}: AxiosResponse<DisavowResponseItem[]> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${pk}/disavow/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Returns whitelist
 *
 * @param  {number} pk Project id
 * @method getWhitelist
 */
  public async getWhitelist(pk: number): Promise<WhitelistResponseItem[]> {
    try {
      const {data}: AxiosResponse<WhitelistResponseItem[]> = await this.axios({
        method: 'GET',
        url: `/backlink/projects/${pk}/whitelist/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Posts toxic backlinks to disavow list
 *
 * @param  {number} pk Project id
 * @param  {string[]} urls
 * @method postToDisavow
 */
  public async postToDisavow(pk: number, urls: string[]): Promise<DisavowResponseItem[]> {
    try {
      const {data}: AxiosResponse<DisavowResponseItem[]> = await this.axios({
        method: 'POST',
        url: `/backlink/projects/${pk}/disavow/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          raw: urls.join('\n'),
        },
      });


      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Posts toxic backlinks to whitelist
 *
 * @param  {number} pk Project id
 * @param  {string[]} urls
 * @method postToWhitelist
 */
  public async postToWhitelist(pk: number, urls: string[]): Promise<WhitelistResponseItem[]> {
    try {
      const {data}: AxiosResponse<WhitelistResponseItem[]> = await this.axios({
        method: 'POST',
        url: `/backlink/projects/${pk}/whitelist/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          raw: urls.join('\n'),
        },
      });


      return data;
    } catch (e) {
      return e;
    }
  }

  /**
 * Returns toxic backlinks csv
 *
 * @param  {number} pk Project id
 * @param  {string[]} urls URLS to export (empty to export all)
 * @method getToxicBacklinksCSV
 */
  public async getToxicBacklinksCSV(pk: number, urls?: string[]): Promise<string> {
    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'POST',
        url: `/backlink/exportdata/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          data: urls || [],
          data_type: 'toxicity',
          format_type: 'csv',
          project_id: pk,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Returns disavow csv
 *
 * @param  {number} pk Project id
 * @param  {string[]} urls URLS to export (empty to export all)
 * @method getDisavowCSV
 */
  public async getDisavowCSV(pk: number, urls?: any[]): Promise<string> {
    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'POST',
        url: `/backlink/exportdata/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          data: urls || [],
          data_type: 'disavow',
          format_type: 'csv',
          project_id: pk,
        },
      });

      // const blob = new Blob([data], {type: 'text/csv'});
      // let fileOfBlob = new File([blob], 'aFileName.csv');
      // const downloadUrl = URL.createObjectURL(blob);

      // window.location = downloadUrl;

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Returns toxic backlinks csv
 *
 * @param  {number} pk Project id
 * @param  {string[]} urls URLS to export (empty to export all)
 * @method getWhitelistCSV
 */
  public async getWhitelistCSV(pk: number, urls?: any[]): Promise<string> {
    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'POST',
        url: `/backlink/exportdata/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          data: urls || [],
          data_type: 'whitelist',
          format_type: 'csv',
          project_id: pk,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Returns toxic backlinks
 *
 * @param  {number} pk Project id
 * @param  {string[]} urls URLS to export (empty to export all)
 * @method getTopicalCSV
 */
  public async getTopicalCSV(pk: number, urls?: string[]): Promise<string> {
    try {
      const {data}: AxiosResponse<any> = await this.axios({
        method: 'POST',
        url: `/backlink/exportdata/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          data: urls || [],
          data_type: 'refdomains',
          format_type: 'csv',
          project_id: pk,
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Returns a notification settings.
   *
   * @method getNotificationSettings
   */
  public async getNotificationSettings() {
    try {
      const data = await this.axios({
        method: 'GET',
        url: `/backlink/notification-settings/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postNotificationSettings(projectsIds: number[], mailType: string, activate: boolean, userId?: number) {
    try {
      const data = await this.axios({
        method: 'POST',
        url: `/backlink/notification-settings/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          user_id: userId,
          project_ids: projectsIds,
          mail_type: mailType,
          activate: activate,
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}


