import {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {Input} from '@/components/common-components/v2';
import {Divider, Select} from 'antd';

interface Props {
  removeTopic: Dispatch<SetStateAction <number>>;
  index: number;
  currentItemState: {
    value: string;
    anchorText: string;
    label: string;
    placeholder: string;
    fields: [
    ];
    required: boolean;
  };
  addField: (value?: string) => void;
  updateLink: (e, itemIndex: number) => void;
  updateAnchorText: (e, itemIndex: number) => void;
  onSingleFieldDragEnd: (index: number, newState) => void;
  totalItems: number;
  totalTerms: number;
  totalQuestions: number;
  calledFromHero?: boolean;
  setInternalLinkOptions?:any;
  setExternalLinkOptions?:any;
  onOptionChange?:any;
  calledFromCa?: boolean;
}

export const SingleLinkSection = ({
  removeTopic,
  index,
  currentItemState,
  updateLink,
  updateAnchorText,
  totalItems,
  calledFromHero,
  onOptionChange,
  calledFromCa,
}: Props) => {
  return <TopSectionWrapper calledFromCa={calledFromCa} calledFromHero={calledFromHero}>
    <InputsWrapper calledFromCa={calledFromCa} topBorderRadius={index == 0 && calledFromCa} bottomBorderRadius={index == totalItems - 1 && calledFromCa} calledFromHero={calledFromHero}>
      {calledFromHero && <StyledSelect
        calledFromCa={calledFromCa}
        suffixIcon={<img src='/img/icon/arrow_down.svg'/>}
        onChange={value => onOptionChange(value, index)}
        options={[
          {value: 'optional', label: 'Optional'},
          {value: 'required', label: 'Required'},
        ]}
        defaultValue={currentItemState?.required ? 'required' : 'optional'} />}

      <Input
        style={{width: calledFromCa ? '100%' : '65%'}}
        type='text'
        variant='light'
        prefix={!calledFromHero && `#${index + 1}`}
        value={currentItemState.value}
        placeholder={calledFromHero && 'Link'}
        onChange={e => updateLink(e, index)}
        className={'customLargeInput'} />

      <Divider type='vertical' style={{margin: calledFromHero ? '7px 0px' : 5, height: 20, display: calledFromCa && 'none'}}/>
      <Input
        style={{width: '35%', display: calledFromCa && 'none'}}
        type='text'
        variant='light'
        placeholder={`Anchor text ${calledFromHero ? '(optional)' : ''}`}
        suffix={ <div style={{display: 'flex'}}>
          {totalItems > 1 && <div className='trash-icon' onClick={() => removeTopic(index)}><FontAwesomeIcon icon={faTrash}/></div>}
        </div>}
        value={currentItemState.anchorText}
        onChange={e => updateAnchorText(e, index)}
        className={'customLargeInput'}
      />
      {calledFromCa && (
        <div style={{display: 'flex', alignItems: 'center', marginRight: 8}} className='trash-icon' onClick={() => removeTopic(index)}><FontAwesomeIcon icon={faTrash}/></div>
      )}
    </InputsWrapper>
  </TopSectionWrapper>;
};

const TopSectionWrapper = styled.div<{calledFromHero:boolean; calledFromCa?: boolean}>`
  display: ${props => props.calledFromHero ? 'block' : 'flex'};
  width: 100%;
  height: 37px;

  .customLargeInput {
    margin: 0 !important;
    padding: 7px 15px !important;
    height: 35px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    border:  ${props => props.calledFromHero ? 'none' : '1px solid transparent'} !important;
    background-color: ${props => props.calledFromHero ? '#F9F9FB' : ''} !important;
    background-color: ${props => props.calledFromCa ? '#fff' : ''} !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    background-color: ${props => props.calledFromHero ? '#F9F9FB' : ''} !important;
    background-color: ${props => props.calledFromCa ? '#fff' : ''} !important;
    }

    &::placeholder {
      font-weight: 400 !important;
    }
    .ant-input{
      background-color: ${props => props.calledFromHero ? '#F9F9FB' : ''} !important;
      background-color: ${props => props.calledFromCa ? '#fff' : ''} !important;
    }
  }

  .trash-icon {
    color: #a3a4a4;

    &:hover {
      color: #ff6262;
    }
  }

`;

const InputsWrapper = styled.div<{calledFromHero:boolean; calledFromCa?: boolean; topBorderRadius?: boolean; bottomBorderRadius?: boolean}>`
  display: flex;
  width: 100%;
  height: 37px;
  background-color: ${props => props.calledFromHero ? '#F9F9FB' : '#fff'};
  background-color: ${props => props.calledFromCa ? '#fff' : ''};
  border-radius: 4px !important;
  ${p => p?.calledFromCa ? 'border-radius: 0 !important;' : ''}
  ${p => p?.topBorderRadius ? `
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    overflow: hidden;
  ` : ''}
  ${p => p?.bottomBorderRadius ? `
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    overflow: hidden;
  ` : ''}
  border: 1px solid #E8E8E8 !important;
  .ant-select {
    ${p => p?.calledFromCa ? 'min-width: 100px !important' : ''}
   }
  .ant-select{
    height: 36px !important;
    .ant-select-selector{
      height: 34px !important;
      .ant-select-selection-search{
        height: 37px !important;
      }
      .ant-select-selection-item{
        height: 37px !important;
      }
    }
  }
`;

const StyledSelect = styled(Select)<{calledFromCa?: boolean}>`
    display: flex !important;
    align-items: center !important;
   .ant-select-selector{
    border-top: 0px !important;
    border-bottom: 0px !important;
    border-left: 0px !important;
    border-right: 1px solid #D9D9D9 !important;
    margin: 6px 0px !important;
    background-color: #F9F9FB !important;
    background-color: ${props => props.calledFromCa ? '#fff' : ''} !important;
   }
   &.ant-select-focused .ant-select-selector, 
   .ant-select-focused .ant-select-selector:active, 
   .ant-select-focused .ant-select-selector:hover {
    box-shadow: none !important;
    outline: 0;
   }
`;
