import React, {useEffect, useState} from 'react';
import {StarOutlined, StarFilled} from '@ant-design/icons';

export interface StarCheckboxProps {
  onClick?: () => void;
  defaultValue?: boolean;
  disabled?: boolean;
}

export const StarCheckbox: React.FC<StarCheckboxProps> = ({
  onClick = () => {},
  defaultValue = false,
  disabled = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);


  const callback = (e, value: boolean) => {
    e.stopPropagation();
    if (disabled) return;
    onClick();
    setValue(value);
  };

  return value ? (
    <StarFilled style={{color: '#F1AA3E', fontSize: '14px'}} onClick={e => callback(e, false)} />
  ) : (
    <StarOutlined style={{color: disabled ? '#C8C8C8' : '#A3A4A4', fontSize: '14px', cursor: disabled ? 'not-allowed' : 'pointer'}} onClick={e => callback(e, true)} />
  );
};
