import {CommonInput} from '@/components/common-components';
import {Button, GrayButton} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {faArrowLeft, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Dropdown, Form, Spin} from 'antd';
import {isNil} from 'lodash';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {NextImg} from '@/utils/nextImg';

export const DynamicAiForm = observer(() => {
  const {contentOptimizer: {
    aiOutline: {
      aiTemplatesController,
      setAiTemplate,
      loading,
      clearTemplateOutlines,
      loadAiOutlineFromTemplate,
      userAiSettings,
      loadAiTemplateOutlinesHistory,
      setIsOpenAiContentToolsDrawer,
    },
  }, settings: {
    customer: {getCustomerQuota},
  }} = useStore('');

  const [resultsCounter, setResultsCounter] = useState(3);
  const [fieldNames, setFieldNames] = useState([]);

  const antIcon = <LoadingOutlined style={{fontSize: 24, marginLeft: '10px', color: '#fff', marginTop: '5px'}} spin />;

  const [form] = Form.useForm();

  useEffect(() => {
    const prepArr = [];
    aiTemplatesController?.fieldsSetup?.forEach(input => {
      prepArr.push(input.fieldName);
    });
    setFieldNames(prepArr);

    // get templates history
    loadAiTemplateOutlinesHistory(aiTemplatesController?.templateType);
  }, []);

  const onFormSubmit = async (values: {[key: string]: string}) => {
    // REMOVE THIS AFTER COMPLETELY RELAYING ON BE VALIDATION
    fieldNames.forEach(field => {
      if (!values[field]) {
        return;
      }
    });

    const inputsPayload = [];
    fieldNames.forEach(field => {
      if (values[field]) {
        inputsPayload.push({
          'field_name': field,
          'field_value': values[field],
        });
      }
    });

    // we are fixing results counter to 1 for image generator
    const counterToUse = aiTemplatesController?.templateType == 'ai_image_generation' ? 1 : resultsCounter;

    try {
      await loadAiOutlineFromTemplate(aiTemplatesController.templateType, inputsPayload, `${counterToUse}`);
      getCustomerQuota();
      // calling history api every time we successfully generate new results
      loadAiTemplateOutlinesHistory(aiTemplatesController?.templateType);
    } catch (e) {
      Promise.reject(e);
    }

    // MixPanel.track(SCA_PAGE_AI_OUTLINE_GENERATE, {
    //   'pageId': pageId,
    //   'description': 'Generate headings and paragraphs for related subtopics.',
    // });
  };

  const fieldTypeConverter = (fieldType?: string) => {
    switch (fieldType) {
      case 'text':
        return 'text';
      case 'text_area':
        return 'textarea';
      default:
        return 'text';
    }
  };

  const setCounterInputValue = e => {
    if (e.target?.value) {
      setResultsCounter(e.target?.value);
    }
  };


  const getInitialValues = () => {
    const initialValues = {};
    aiTemplatesController?.fieldsSetup?.forEach(input => {
      if (input.fieldName == 'tone_of_voice') {
        initialValues[input.fieldName] = userAiSettings?.toneOfVoice ?? '';
      } else {
        initialValues[input.fieldName] = '';
      }
    });
    initialValues['resultsCounter'] = resultsCounter;

    return initialValues;
  };

  const resetFormHandler = () => {
    form.resetFields();
    setResultsCounter(5);
  };

  const generateResultsCunterErrorMsg = () => {
    let msg = '';
    if (isNil(resultsCounter)) {
      msg = 'Number of desired outlines is required.';
    }

    if (resultsCounter < 1 || resultsCounter > 20) {
      msg = 'Only values from 1 to 20 are allowed.';
    }

    return msg;
  };

  const standardTovs = ['Casual', 'Professional', 'Funny', 'Angry'];
  const dropdownTovs = ['Excited', 'Witty', 'Sarcastic', 'Feminine', 'Masculine', 'Bold', 'Dramatic', 'Grumpy', 'Secretive'];
  const advancedTovs = [{
    label: 'Yoda',
    color: '#499636',
    icon: <NextImg src={'/img/logo/yoda-logo.svg'} style={{marginRight: 5, height: 15, width: 18}}/>,
  },
  {
    label: 'Tony Stark',
    color: '#d13234',
    icon: <NextImg src={'/img/logo/avengers-logo.svg'} style={{marginRight: 5, height: 15, width: 18}}/>,
  },
  {
    label: 'Shakespeare',
    color: '#46609C',
    icon: <NextImg src={'/img/logo/shakespear-logo.svg'} style={{marginRight: 5, height: 15, width: 18}}/>,
  }];


  const tovsDropdownRender = () => {
    return <DropdownMenuContainer>
      {
        dropdownTovs.map((tov, idx) => {
          return <>
            <SingleDropdownValue key={idx} onClick={() => {
              form.setFieldsValue({'tone_of_voice': tov});
            }}>{tov}</SingleDropdownValue>
            {idx < dropdownTovs.length - 1 ? <Divider style={{marginTop: 0, marginBottom: 0}}/> : null}
          </>;
        })
      }
    </DropdownMenuContainer>;
  };

  return <Wrapper>
    <Header>
      <Left>
        <BackArrowContainer onClick={() => {
          if (aiTemplatesController?.isStandaloneTool) {
            setIsOpenAiContentToolsDrawer(false);
          }
          setAiTemplate({
            ...toJS(aiTemplatesController ?? []),
            showForm: false,
            templateType: '',
            templateIcon: '',
            templateDesc: '',
            templateName: '',
            fieldsSetup: [],
            defaultForm: false,
            defaultFormType: null,
            isStandaloneTool: false,
          });

          if (!aiTemplatesController?.isStandaloneTool) {
            clearTemplateOutlines();
          }
        }}>
          <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 10}}/>
        </BackArrowContainer>
        <ImgContainer>
          <NextImg src={aiTemplatesController?.templateIcon} style={{height: 25, width: 25, margin: 'auto'}}/>
          {/* <FontAwesomeIcon icon={faImage} style={{margin: 'auto'}} fontSize={22}/> */}
        </ImgContainer>


      </Left>
      <DescContainer>
        <TemplateName>{aiTemplatesController?.templateName}</TemplateName>
        <TemplateDesc>{aiTemplatesController?.templateDesc}</TemplateDesc>
      </DescContainer>
    </Header>
    <Divider />
    <Form form={form} onFinish={onFormSubmit} initialValues={(getInitialValues())}>
      {aiTemplatesController?.fieldsSetup?.map((field, idx) => {
        return <>
          <Label>{field.fieldLabel}</Label>
          <Form.Item key={idx} name={field.fieldName} shouldUpdate={true}
            rules={[{
              validator: (_, value) => {
                if (!field.isMandatory) {
                  if (value?.length && value?.length > field.maxLength) {
                    return Promise.reject(new Error(`Max number of characters (${field.maxLength}) exceeded.`));
                  }
                  return Promise.resolve();
                }


                if (value) {
                  if (value?.length && value?.length > field.maxLength) {
                    return Promise.reject(new Error(`Max number of characters (${field.maxLength}) exceeded.`));
                  }
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error(`Missing ${field.fieldLabel}.`));
                }
              },
            }]}
            style={{width: '100%', marginBottom: 10, minHeight: 66}}>

            <CommonInputStyled
              inputType={fieldTypeConverter(field.fieldType)}
              variant='white'
              placeholder={field.fieldPlaceholder ?? ''}/>

          </Form.Item>
          {field.fieldName == 'tone_of_voice' ?
            <SuggestionsContainer>
              <div style={{display: 'flex'}}>
                {advancedTovs.map((tov, idx) => {
                  return <AdvancedTov key={idx} color={tov.color} onClick={() => {
                    form.setFieldsValue({'tone_of_voice': tov.label});
                  }}>
                    {tov.icon}
                    <span style={{color: '#4E5156'}}>{tov.label}</span>
                  </AdvancedTov>;
                })}
              </div>
              <div style={{display: 'flex', marginTop: 8}}>
                {standardTovs.map((tov, idx) => {
                  return <StandardTov key={idx} onClick={() => {
                    form.setFieldsValue({'tone_of_voice': tov});
                  }}>{tov}</StandardTov>;
                })}
                <DropdownStyled overlay={tovsDropdownRender()} placement='bottomLeft' trigger={['click']}>
                  <Button>{`+ ${dropdownTovs.length}`}</Button>
                </DropdownStyled>
              </div>
            </SuggestionsContainer> :
            null}
        </>;
      })}


      <Footer>
        <GrayButton style={{marginLeft: 0}} onClick={() => resetFormHandler()}>
          <FontAwesomeIcon style={{marginRight: 10}} icon={faXmark} />
              Clear inputs
        </GrayButton>
        <div style={{display: 'flex'}}>
          {(!resultsCounter || resultsCounter < 1 || resultsCounter > 20) ? <span style={{fontSize: 12, color: '#ff5959'}}>{generateResultsCunterErrorMsg()}</span> : null}
          {aiTemplatesController?.templateType != 'ai_image_generation' ?
            <NumberInput type='number' value={resultsCounter} defaultValue={3} min={1} max={20} pattern='[0-9]' style={{width: '50px', marginBottom: 0}} onChange={e => setCounterInputValue(e)}/> :
            null}
          <GenerateIdeasBtn htmlType='submit' color='purple' variant='solid' disabled={loading||(!resultsCounter || resultsCounter < 1 || resultsCounter > 20)}>
              Generate AI Content
            {loading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon style={{marginLeft: 10}} icon={faArrowRight} />}
          </GenerateIdeasBtn>
        </div>

      </Footer>
    </Form>
  </Wrapper>;
});

const Wrapper = styled.div`

`;
const Header = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NumberInput = styled.input`
  background-color: #fff !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 6px;
`;

const GenerateIdeasBtn = styled(Button)`
  height: 38px !important;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const ImgContainer = styled.div`
  background: #F2F2F5;
  border-radius: 10px;
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 13px;
`;
const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%
`;
const TemplateName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 4px;
`;
const TemplateDesc = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;
const BackArrowContainer = styled.div`
  width: 30px;
  cursor: pointer;
`;

const CommonInputStyled = styled(CommonInput)`
  border-radius: 6px !important;
  border: 1px solid #E8E8E8 !important;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02) !important;
  color: #121212 !important;
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #4E5156;
  margin-bottom: 5px;
`;

const SuggestionsContainer = styled.div`
    margin-top: -20px;
    margin-bottom: 10px;
`;
const StandardTov = styled.div`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 38px;
  padding: 3px 10px;
  margin-right: 5px;
  color: #4E5156;
  cursor: pointer;
  font-size: 12px;
`;
const AdvancedTov = styled.div<{color: string}>`
  padding: 3px 10px;
  background: ${p => `${p.color}1F`};
  border: ${p => `1px solid ${p.color}0A`};
  border-radius: 38px;
  margin-right: 5px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
`;
const DropdownStyled = styled(Dropdown)`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 38px;
  padding: 3px 10px;
  margin-left: 0;
  color: #4E5156;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: #FFFFFF !important;
    color: #4E5156;
    border: 1px solid #E8E8E8;
  }
  &:focus {
    background: #FFFFFF !important;
    color: #4E5156;
    border: 1px solid #E8E8E8;
  }
`;
const DropdownMenuContainer = styled.div`
  background: #FFFFFF;
  box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 160px;
  padding-top: 2px;
  padding-bottom: 2px;
`;
const SingleDropdownValue = styled.div`
  margin: 5px 14px;
  cursor: pointer;
`;
