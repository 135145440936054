import {useState, useEffect} from 'react';
import styles from './style.module.scss';
import {faDownload} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Button, GrayButton,
} from '@/components/common-components/v2/Button';
import {CopyOutlined} from '@ant-design/icons';

import {Divider, Tooltip} from 'antd';
import {faCheck, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {SCA_PAGE_CONTENT_IDEA_COPY, SCA_PAGE_AI_OUTLINE_DOWNLOAD, SCA_CREATE_PAGE} from '@/constants/events';

import {MixPanel} from '@/utils/mixpanel';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import parse from 'html-react-parser';
import {routes} from '@/utils/const';
import {CONTENT_ASSISTANT_API} from '@/api/ContentAssistant';
// import {faArrowsRotate} from '@fortawesome/pro-solid-svg-icons';
import {useRouter} from 'next/router';
import {copyToClipboardWithHtmlAndText} from '@/utils/clipboard';

interface Props {
  aiOutline: any;
  insertToEditor: any;
  outlineId?: number;
  // rewriteOutline?: (value: string, idx: number) => any;
  // rewritingOutline?: boolean;
  // rewritingOutlineId?: number;
}

export const SingleAiOutline = ({
  aiOutline,
  insertToEditor,
  outlineId,
  // rewriteOutline,
  // rewritingOutline,
  // rewritingOutlineId
}: Props) => {
  const router = useRouter();
  const {contentOptimizer: {aiOutline: {aiTemplatesController, setIsOpenAiContentToolsDrawer}}} = useStore('');
  const {contentOptimizer: {currentPage}} = useStore('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [convertedText, setConvertedText] = useState(null);
  const [textForInjection, setTextForInjection] = useState(null);

  const {t} = useTranslation('landing-page-optimizer');


  useEffect(() => {
    // Removing the leading space from string
    // const newLineIndex = aiOutline.paragraph?.search('\n');
    // const textWithoutLeadingSpace = aiOutline.paragraph?.slice(0, newLineIndex + 1) + aiOutline.paragraph?.slice(newLineIndex + 2);
    // const firstTitle = aiOutline.paragraph?.slice(0, newLineIndex + 1);
    // const result = textWithoutLeadingSpace.replace(firstTitle, '<span>' + firstTitle + '</span>');

    const newText = aiOutline?.split('\n').map((str, id) => <p key={id}>{str}</p>);
    let textToInject = '';


    aiOutline?.split('\n\n').forEach(str => {
      textToInject += `<p>${str}</p>`;
    });

    setConvertedText(newText);
    setTextForInjection(textToInject);
  }, [aiOutline]);


  const downloadFileToTxt = () => {
    const element = document.createElement('a');
    const file = new Blob([aiOutline], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    const breakFileName = currentPage.content?.title?.split(' ')?.length;
    const fileName = breakFileName && breakFileName > 4 ? currentPage.content?.title?.split(' ').slice(0, 4).join('-') : currentPage.content?.title?.split(' ').slice(0, breakFileName).join('-');
    element.download = `${fileName}`;
    document.body.appendChild(element);
    element.click();
  };

  const countWords = s => {
    if (!s) return;

    // return;
    s = s.replace(/(^\s*)|(\s*$)/gi, '');// exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' ');// 2 or more space to 1
    s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
    return s.split(' ').filter(function(str) {
      return str != '';
    }).length;
    // return s.split(' ').filter(String).length; - this can also be used
  };


  const handleCreateContent = async () => {
    const pagePayload = {};
    pagePayload['title'] = aiOutline.heading ?? '';
    pagePayload['cleaned_html'] = aiOutline;

    const {uuid} = await CONTENT_ASSISTANT_API.createPage(pagePayload);

    MixPanel.track(SCA_CREATE_PAGE, {
      'pageId': uuid,
    });

    setIsOpenAiContentToolsDrawer(false);

    router.push(`/${routes.landingPageOptimizer}/${uuid}`, null, {shallow: true});
  };

  return (
    <div className={styles.ContentIdeaContainer}>
      <p className={styles.ContentIdea}>
        {/* {convertedText} */}
        {convertedText && parse(aiOutline)}

      </p>
      <div className={styles.ideaOrder}>{outlineId + 1}</div>
      <Divider className={styles.popoverDivider} />
      <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'default'}}>

        <div className={styles.controlButtons}>
          {!aiTemplatesController?.isStandaloneTool ? <Button
            color={aiOutline?.isAdded ? 'green' : 'purple'}
            variant='ghost'
            className={styles.addToArticleBtn}
            onClick={() => {
              insertToEditor(`<p></p>` + textForInjection, outlineId);
            }}>
            {aiOutline?.isAdded ? <span>
              <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}}/>
                Added
            </span> :
              <span>
                <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>
                Add to article
              </span>}
          </Button> : null}
          <Tooltip
            title={copySuccess ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{` Copied to clipboard`}</span> : t('copy-to-clipboard')}
            trigger='hover'
            placement='bottom'
            overlayClassName={styles.copyTooltip}
            onVisibleChange={visible => {
              if (!visible) {
                setTimeout(() => {
                  setCopySuccess(false);
                }, 300);
              }
            }}>
            <Button
              style={{marginLeft: 5}}
              color='white'
              className={styles.copyButton}
              onClick={() => {
                copyToClipboardWithHtmlAndText(`<p>${aiOutline}</p>`, `${aiOutline}`.replace(/<[^>]*>/g, ''));
                setCopySuccess(true);

                MixPanel.track(SCA_PAGE_CONTENT_IDEA_COPY, {
                  'description': 'Content idea copied',
                });
              }}>
              <span>
                <CopyOutlined style={{marginRight: '5px'}} />
                Copy
              </span>
            </Button>

          </Tooltip>
          {/* <Button
            style={{marginLeft: 5}}
            disabled={rewritingOutline}
            color='white'
            className={styles.copyButton}
            onClick={() => rewriteOutline(aiOutline, outlineId)}>
            <span>
              <FontAwesomeIcon icon={faArrowsRotate} style={{marginRight: 5}} spin={rewritingOutlineId == outlineId && rewritingOutline}/>
              {rewritingOutlineId == outlineId && rewritingOutline ? 'Rewriting' : 'Rewrite'}
            </span>
          </Button> */}
          <Tooltip
            title={'Download'}
            trigger='hover'
            placement='bottom'
            overlayClassName={styles.copyTooltip}
          >
            <FontAwesomeIcon icon={faDownload} className={styles.downloadButton} onClick={() => {
              downloadFileToTxt();

              MixPanel.track(SCA_PAGE_AI_OUTLINE_DOWNLOAD, {
                'description': 'Content idea downloaded',
              });
            }} />
          </Tooltip>
          {aiTemplatesController?.isStandaloneTool ?
            <GrayButton onClick={() => handleCreateContent()}>
              <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>
            Create article
            </GrayButton> :
            null}
          <span>{`${countWords(aiOutline)} words`}</span>
        </div>
      </div>
    </div>
  );
};
