import {BaseApi} from '@/api/base-api';
import {getAuthorizationHeader} from '@/api/common-utils';

export class CreateTasksApi extends BaseApi {
  public async getTasks() {
    try {
      const {data} = await this.axios({
        method: 'GET',
        url: '/customer-task/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getTask(id: number) {
    try {
      const {data} = await this.axios({
        method: 'GET',
        url: `/customer-task/${id}`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateTask(id: number, taskData: {
    taskName?: string;
    pageUrl?: string;
    dueDate?: string;
    currentStatus?: string;
    priority?: string;
    assignedTo?: string;
    comments?: string;
  }) {
    try {
      const {data} = await this.axios({
        method: 'PATCH',
        url: `/customer-task/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: {
          task_name: taskData.taskName,
          page_url: taskData.pageUrl,
          due_date: taskData.dueDate,
          current_status: taskData.currentStatus,
          priority: taskData.priority,
          assigned_to: taskData.assignedTo,
          comments: taskData.comments,
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteTask(id: number) {
    try {
      const {data} = await this.axios({
        method: 'DELETE',
        url: `/customer-task/${id}`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createTask(taskData: {
    taskName?: string;
    pageUrl?: string;
    dueDate?: string;
    currentStatus?: string;
    priority?: string;
    assignedTo?: string;
    comments?: string;
  }) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: '/customer-task/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: {
          task_name: taskData.taskName,
          page_url: taskData.pageUrl,
          due_date: taskData.dueDate,
          current_status: taskData.currentStatus,
          priority: taskData.priority,
          assigned_to: taskData.assignedTo,
          comments: taskData.comments,
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
