export const circleChartData= {
  mobile_search: {
    'label': 'Google Search - Mobile',
    'color': '#F1AA3E',
  },
  desktop_search: {
    'label': 'Google Search - Desktop',
    'color': '#2D6CCA',
  },
  mobile_maps: {
    'label': 'Google Maps - Mobile',
    'color': '#F44343',
  },
  desktop_maps: {
    'label': 'Google Maps - Desktop',
    'color': '#1FAC47',
  },
};
