import React, {DetailedHTMLProps, ButtonHTMLAttributes, useState} from 'react';
import {Button} from '@/components/common-components/components/index';
import {Tooltip} from 'antd';
import {useGoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
import {GOOGLE_OAUTH_CLIENT_ID} from '@/constants';
import styles from './style.module.scss';
import {NextImg} from '@/utils/nextImg';

export interface GoogleLoginButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  clientId: string;
  onSuccess?: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  onFailure?: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  buttonText?: string;
  responseType?: 'id_token' | 'permission' | 'code';
}

export const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  onSuccess,
  onFailure,
  buttonText = 'Sign in with Google',
  responseType = 'id_token',
  ...props
}) => {
  const {signIn, loaded} = useGoogleLogin({
    onSuccess: onSuccess,
    onFailure: handleGoogleFailure,
    clientId: GOOGLE_OAUTH_CLIENT_ID,
    responseType: responseType,
  });
  const [googleFail, setGoogleFail] = useState(false);

  function handleGoogleFailure(error) {
    onFailure(error);
    setGoogleFail(true);
  }

  return (
    <Tooltip title={buttonText} placement='top'>
      <Button
        className={styles.buttonGoogle}
        buttonType='primary'
        size='lg'
        onClick={signIn}
        disabled={!loaded}
        {...props}
        hidden={googleFail}
      >
        <span className={styles.loginGoogleButton}>
          <NextImg
            className={styles.loginGoogleIcon}
            src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
            alt='google button'
          />
        </span>
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default GoogleLoginButton;
