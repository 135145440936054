import {ChangeEvent} from 'react';
import {observer} from 'mobx-react';
import {InputWithButton, KeywordPopulateWraper, KeywordSectionWrapper, KeywordSuggestions, KeywordSuggestionsWrapper, SuggestedKeyword, KeywordsInputWrapper, PopulateButton, StyledInput, TextAreaStyled, TypeRadioContainer} from '../../styledComponents';
import {Radio, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {useRef} from 'react';
interface Props {
  keywordsValue?: string;
  setKeywordsValue?: any;
  type?: string;
  setType?: (value: string) => void;
  seedValue?: string;
  setSeedValue?: (value: string) => void;
  keywordsLengthIsNotValid?: any;
  domainIsNotValid?: (value: string) => boolean;
  isEdit?: boolean;
  setMaxPage?: (value: number | '') => void;
  maxPage?: number | '';
}

export const KeywordsSection = observer(({
  keywordsValue,
  setKeywordsValue,
  seedValue,
  setSeedValue,
  keywordsLengthIsNotValid,
  type,
  setType,
  domainIsNotValid,
  isEdit,
  setMaxPage,
  maxPage,
}: Props) => {
  const {campaigns: {getKeywordSuggestions, showErrors, keywordSuggestions, keywordSuggestionsLoading, chartData}} = useStore('');
  const hiddenFileInput = useRef(null);
  const handleAddSuggestedKeyword = keyword => {
    const valueToAdd = keywordsValue ? keywordsValue += `, ${keyword}` : keyword;
    setKeywordsValue(valueToAdd);
  };

  const handleChange = async e =>{
    e?.preventDefault();
    if (e?.target?.files[0]?.type == 'text/csv') {
      const reader = new FileReader();
      reader.onload = async e => {
        const text = (e.target.result);
        const formattedText = text?.toString()?.split(/\r?\n/)?.filter(it => it?.trim())?.join(', ');
        setKeywordsValue(prev => {
          let formatted = prev?.split(',')?.filter(val => val?.trim())?.join(',');
          formatted = formatted?.length ? formatted + ', ' : formatted;
          return `${formatted}${formattedText}`;
        });
      };
      reader?.readAsText(e?.target?.files[0]);
    }
  };

  const onChangeMaxPage = (e: ChangeEvent<HTMLInputElement>) =>{
    if (!isNaN(e?.target?.valueAsNumber)) {
      if (!(e?.target?.valueAsNumber < 1) && !(e?.target?.valueAsNumber > 5)) {
        setMaxPage(e?.target?.valueAsNumber);
      }
    } else {
      setMaxPage('');
    }
  };

  const isError = showErrors && !keywordsValue || (type === 'keywords' ? keywordsLengthIsNotValid(keywordsValue) : domainIsNotValid(keywordsValue));
  return <KeywordSectionWrapper>
    <div style={{color: '#121212', fontSize: '12px', marginRight: 52}}>Type</div>
    <div>
      <KeywordsInputWrapper isError={isError ? true : false}>
        <div style={{borderRadius: '8px 8px 0px 0px', border: '1px solid rgba(232, 232, 232, 1)', overflow: 'hidden'}}>
          <TypeRadioContainer>
            <Radio.Group onChange={e => {
              if (e.target.value === 'keywords' && isEdit && !chartData?.domains?.length) {
                setKeywordsValue(chartData?.targetKeywords?.join(', ') || chartData?.domains?.join(', ') || '');
              } else if (isEdit && !chartData?.targetKeywords?.length && e.target.value === 'domains') {
                setKeywordsValue(chartData?.domains?.join(', ') || '');
              } else {
                setKeywordsValue('');
              }
              setType(e.target.value);
            }} style={{display: 'flex', alignItems: 'center', gap: 17}} value={type}>
              <Radio value={'keywords'}>Keywords</Radio>
              <Radio value={'domains'}>Domains</Radio>
            </Radio.Group>
            {type === 'domains' && <div onClick={() => hiddenFileInput.current?.click()} style={{width: 'fit-content', cursor: 'pointer', lineHeight: 14.52, color: 'rgb(45, 108, 202)', fontSize: 12}}>Import domains from CSV</div>}
            <input type='file'
              ref={hiddenFileInput}
              accept='.csv'
              onClick={ (event:any) => {
                event.target.value = null;
              }}
              onChange={e => handleChange(e)}
              style={{display: 'none'}}
            />
          </TypeRadioContainer>
          <TextAreaStyled
            value={keywordsValue}
            isError={isError ? true : false}
            placeholder={type == 'keywords' ? 'Separate multiple keywords by commas' : 'Divide URL\'s by commas. E.g google.com, youtube.com, instagram.com'}
            onChange={e => setKeywordsValue(e?.target?.value)}
            style={{height: 80, resize: 'none', width: 600, border: 'none'}}
          />
        </div>
        {type == 'keywords' && <KeywordSuggestionsWrapper>
          <KeywordPopulateWraper>
            Looking for suggestions?
            <InputWithButton>
              <StyledInput
                value={seedValue}
                placeholder='Seed keyword...'
                onChange={e => setSeedValue(e?.target?.value)}
                width='198px'
                height='28px'
                borderRadius='6px'
                isSeed={true}
              />
              <Tooltip title={`${seedValue ? '' : 'Kindly enter any keyword first'}`}>
                <PopulateButton onClick={() => seedValue ? getKeywordSuggestions(seedValue) : {}}>{keywordSuggestionsLoading ? <Spin indicator={<LoadingOutlined style={{fontSize: 13}} spin />} /> : 'Populate'}</PopulateButton>
              </Tooltip>
            </InputWithButton>
          </KeywordPopulateWraper>
          <KeywordSuggestions>
            {keywordSuggestions &&
              keywordSuggestions?.map((item, idx) =>
                <SuggestedKeyword key={idx} onClick={() => handleAddSuggestedKeyword(item)}><FontAwesomeIcon icon={faPlus} />&nbsp;{item}</SuggestedKeyword>)}
          </KeywordSuggestions>
        </KeywordSuggestionsWrapper>}
      </KeywordsInputWrapper>
      {showErrors && type !== 'domains' && (!keywordsValue || keywordsLengthIsNotValid(keywordsValue)) ? <div style={{color: 'red'}}>{keywordsLengthIsNotValid(keywordsValue) ? 'Maximum 5 keywords are allowed' : 'Please enter some keywords'}</div> : null}
      {showErrors && (type === 'domains' && (!keywordsValue || domainIsNotValid(keywordsValue))) ? <div style={{color: 'red'}}>{domainIsNotValid(keywordsValue) ? 'Please enter valid domains.' : 'Please enter at least 1 domain'}</div> : null}
      <div style={{display: 'flex', marginTop: 12, marginBottom: 8, position: 'relative', right: 80}}>
        <div style={{color: '#121212', fontSize: '12px', marginRight: 32, marginTop: 8}}>Max Pages</div>
        <div>
          <StyledInput
            value={maxPage}
            isError={showErrors && !maxPage ? true : false}
            width='60px'
            maxLength={5}
            type='number'
            height='38px'
            borderRadius='8px'
            min='1'
            max='5'
            onChange={onChangeMaxPage}
          />
        </div>
      </div>
    </div>
  </KeywordSectionWrapper>;
});
