import {flow, types} from 'mobx-state-tree';
import {PageModel} from './page';
import {DOMAIN_ANALYZER_API} from '@/api/domain-analyzer';
import {TechnicalsModel} from './technicals';

export const DomainAnalyzerStore = types.model({
  loading: types.boolean,
  pollingActive: types.boolean,
  pollingToken: types.maybeNull(types.string),
  domainRating: types.maybeNull(types.number),
  keywords: types.maybeNull(types.number),
  siteValue: types.maybeNull(types.number),
  refDomains: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  pageSpeedIndex: types.maybeNull(types.number),
  pageSpeedIndexTaskStatus: types.maybeNull(types.string),
  indexedByGoogle: types.maybeNull(types.number),
  page: types.maybeNull(PageModel),
  technicals: types.maybeNull(TechnicalsModel),
  competitors: types.array(types.string),
}).actions(self => {
  const getAndPopulateData = flow(function* (website: string, captcha: string, pollingToken?: string) {
    try {
      self.loading = true;
      const response = yield DOMAIN_ANALYZER_API.getData(website, captcha, pollingToken);
      if (response) {
        self.domainRating = response.domainRating;
        self.keywords = response.keywords;
        self.siteValue = response.siteValue;
        self.refDomains = response.refDomains;
        self.organicTraffic = response.organicTraffic;
        self.pageSpeedIndex = response.pageSpeedIndex;
        self.pageSpeedIndexTaskStatus = response.pageSpeedIndexTaskStatus;
        self.indexedByGoogle = response.indexedByGoogle;
        self.pollingToken = response.token;
        self.page.url = response.page?.url;
        self.page.uuid = response.page?.uuid;
        self.page.targetKeywords = response.page?.targetKeywords;
        self.page.title = response.page?.title;
        self.page.importedFromUrl = response.page?.importedFromUrl;
        self.page.requiredWordCount = response.page?.requiredWordCount;
        self.page.version = response.page?.version;
        self.page.maxSerpPosition = response.page?.maxSerpPosition;
        self.page.textPreview = response.page?.textPreview;
        self.page.createdAt = response.page?.createdAt;
        self.page.updatedAt = response.page?.updatedAt;
        self.page.wordCount = response.page?.wordCount;
        self.page.readability = response.page?.readability;
        self.page.score = response.page?.score;
        self.technicals.technicalsScore = response.technicals?.technicalsScore;
        self.competitors = response.competitors;
        self.loading = false;
        if (self.pageSpeedIndexTaskStatus != 'SUCCESS' && self.pageSpeedIndexTaskStatus != 'FAILURE') {
          self.pollingActive = true;
          yield new Promise(r => setTimeout(r, 2000));
          pollPageSpeedIndex(website, self.pollingToken);
        }
        self.pollingActive = false;
      }
    } catch (e) {
      self.loading = true;
    }
  });

  const refetchAll = () => {
    return self;
  };

  const pollPageSpeedIndex = flow(function* (website, pollingToken?) {
    const response = yield DOMAIN_ANALYZER_API.getPageSpeedIndex(website, pollingToken);
    if (response) {
      self.pageSpeedIndex = response.pageSpeedIndex;
    }
  });

  return {getAndPopulateData, refetchAll};
});

export const initDomainAnalyzer = () => {
  return DomainAnalyzerStore.create({
    loading: true,
    pollingActive: true,
    domainRating: null,
    keywords: null,
    siteValue: null,
    refDomains: null,
    organicTraffic: null,
    pageSpeedIndex: null,
    indexedByGoogle: null,
    competitors: [],
    page: {
      url: '',
      uuid: '',
      targetKeywords: [],
      title: '',
      importedFromUrl: '',
      requiredWordCount: null,
      version: '',
      maxSerpPosition: null,
      textPreview: '',
      createdAt: '',
      updatedAt: '',
      wordCount: null,
      readability: '',
      score: null,
    },
    technicals: {
      technicalsScore: null,
    },
  });
};
