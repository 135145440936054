import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class StripeApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');

  public async createPaymentMethod(paymentData) {
    try {
      const response = await this.axios.post(`${StripeApi.baseUrl}/payment/methods/create/`, paymentData,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updatePaymentMethod(paymentMethodId, paymentData) {
    try {
      const response = await this.axios.patch(`${StripeApi.baseUrl}/payment/methods/${paymentMethodId}/update/`, paymentData,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async deleteInvoice(slug, type) {
    try {
      const response = await this.axios.delete(`${StripeApi.baseUrl}/invoices/cancel/?type=${type}&invoice=${slug}`,
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default StripeApi;
