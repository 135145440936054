import numeral from 'numeral';
import {types, Instance} from 'mobx-state-tree';

export const KeywordSerpDetails = types
  .model({
    title: types.string,
    page: types.string,
    position: types.maybeNull(types.number),
    dr: types.maybeNull(types.number),
    ur: types.maybeNull(types.number),
    domains: types.maybeNull(types.number),
    traffic: types.maybeNull(types.number),
    keywords: types.maybeNull(types.number),
    cs: types.maybeNull(types.number),
    cl: types.maybeNull(types.number),
    psi: types.maybeNull(types.number),
  })
  .views(self => ({
    get formattedDomains() {
      return self.domains ? numeral(self.domains).format('0,0') : '-';
    },
    get formattedTraffic() {
      return self.traffic ? numeral(self.traffic).format('0,0') : '-';
    },
    get fomattedKeywords() {
      return self.keywords ? numeral(self.keywords).format('0,0') : '-';
    },
    get formattedCl() {
      return self.cl ? numeral(self.cl).format('0,0') : '-';
    },
  }));

export type KeywordSerpDetailsInstance = Instance<typeof KeywordSerpDetails>;
