import {observer} from 'mobx-react';
import {ProjectsSelector} from '../../projects-selector';
import {ProjectSectionWrapper, StyledInput} from '../../styledComponents';
import {useStore} from '@/store/root-store';

interface Props {
  nameValue?: string;
  setNameValue?: (value: string) => void;
  modalSelectedItemV2?: number;
  setModalSelectedItemV2?: (value: number) => void;
}

export const ProjectSection = observer(({
  nameValue,
  setNameValue,
  modalSelectedItemV2,
  setModalSelectedItemV2,
}: Props) => {
  const {campaigns: {showErrors}} = useStore('');

  return <ProjectSectionWrapper>
    <div style={{color: '#121212', fontSize: '12px', marginRight: '47px', marginBottom: showErrors && !nameValue ? '25px' : ''}}>Name</div>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div>
          <StyledInput
            value={nameValue}
            isError={showErrors && !nameValue ? true : false}
            width='350px'
            height='38px'
            borderRadius='8px'
            onChange={e => setNameValue(e?.target?.value)}
          />
        </div>
        <div style={{color: '#121212', fontSize: '12px', marginLeft: '20px', marginRight: '10px'}}>Folder</div>
        <ProjectsSelector
          projectId={''}
          whiteLarge={true}
          modalSelectedItemV2={modalSelectedItemV2}
          setModalSelectedItemV2={setModalSelectedItemV2}/>
      </div>
      {showErrors && !nameValue && <div style={{color: 'red'}}>Please enter a name</div>}
    </div>
  </ProjectSectionWrapper>;
});
