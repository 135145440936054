import React, {useState} from 'react';
import {Form, Input, Select} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import ImagePicker from './imagePicker';
import {LocationSelect} from '../../../keyword-rank-tracker/add-new-website/location-select';

const {Option} = Select;
interface OttoKnowledgeGraphFieldsProps {
  placeholder?: string;
  defaultValue?: any;
  fieldType?: string;
  name?: string;
  options?: any;
  form?: any;
  serpLocation?: string;
  setSerpLocation?: (value) => void;
  setSerpLocationId?: (value) => void;
  style?: React.CSSProperties;
  setLanguageCode?: any;
  languageCode?: any;
  dropDownBottomValue?: number;
  disabled?:boolean;
}
const OttoKnowledgeGraphFields = ({placeholder, defaultValue, fieldType, name, options, form, serpLocation, setSerpLocation, setSerpLocationId, style, setLanguageCode, languageCode, dropDownBottomValue, disabled=false}: OttoKnowledgeGraphFieldsProps) => {
  const [locationError, setLocationError] = useState<boolean>(false);
  const onLocationChange = value => {
    setSerpLocation(value.value);
    if (value.value) locationError && setLocationError(false);
  };

  const fields = (placeholder, defaultValue, fieldType, fieldName, options, form, style, setLanguageCode, languageCode, disabled) => {
    const fieldsObj = {
      'text': <StyledInput placeholder={placeholder} defaultValue={defaultValue} style={style ? style : {}} spellCheck/>,
      'text_area': <TextArea defaultValue={defaultValue} className='text-area' placeholder={placeholder} style={style ? style : {}} spellCheck/>,
      'number': <StyledInput placeholder={placeholder} defaultValue={defaultValue} type='number' min={fieldName === 'number_of_employees' ? 0 : null} style={style ? style : {}}/>,
      'phone': <StyledPhoneInput inputClass='support-phone-input' country='us' value={defaultValue} width={style?.width ? style.width : ''}/>,
      'email': <Input defaultValue={defaultValue} className='email-input' placeholder={placeholder} type='email' style={style ? style : {}}/>,
      'url': <Input defaultValue={defaultValue} className='email-input' placeholder={placeholder} style={style ? style : {}} disabled={disabled}/>,
      'dropdown': <StyledSelect
        onChange={value => {
          const obj = {};
          obj[fieldName] = value;
          form.setFieldsValue(obj);
        }}
        getPopupContainer={trigger => trigger.parentNode}
        defaultValue={defaultValue}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
        placeholder={placeholder}
        showSearch
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
      'geolocation-search': <LocationSelect
        variant='grey'
        placeholderTextColorChange={true}
        width={'100%'}
        height='44px'
        dropDownBottomValue={dropDownBottomValue}
        getPopupContainer={trigger => trigger.parentNode}
        borderRadius='5px'
        style={{border: locationError ? '1px solid red' : ''}}
        marginLeft='0px'
        suffixIcon={<FontAwesomeIcon color='#fff' icon={faCaretDown}/>}
        showSearch
        isKrtLocationSelect
        value={serpLocation}
        onChange={onLocationChange}
        excludeWorldwide
        setSerpLocationId={setSerpLocationId}
        setSelectedCountryCode={() => ('')}
        isKnowledgeGraph
      />,
      'language_code': <StyledSelect
        onChange={value => {
          setLanguageCode(value);
        }}
        defaultValue={defaultValue}
        value={languageCode}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
        placeholder={placeholder}
        getPopupContainer={trigger => trigger.parentNode}
        showSearch
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
    };
    return fieldsObj[fieldType];
  };

  return fields(placeholder, defaultValue, fieldType, name, options, form, style, setLanguageCode, languageCode, disabled);
};

export default OttoKnowledgeGraphFields;


export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  .ant-form-item-explain-error {
    margin-left: 25%;
    margin-top: -8px;
    font-size: 12px;
  }
`;

const StyledPhoneInput = styled(PhoneInput)<{width?: string}>`
  .form-control {
    width: ${p => p?.width ? p.width : ''};
  }
  .country-list {
    max-height: 145px !important;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px !important;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;

`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
  }
  .ant-select-dropdown{
    top:32px !important;
  }
`;
