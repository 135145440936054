import {observer} from 'mobx-react';
import {Tabs} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import {CustomTextareaV2} from '@/components/common-components/components/form/input/customTextareaV2';
import {faDesktop, faMobile, faTabletScreenButton} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
import {EditWrapper, RightSection, UpdateButton, RebuildButton} from '../styles';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import FreezeWrapper from '../../../freezTooltip';
import {ProblemBanner} from '../ProblemBanner';

interface Props {
  isEdit?: boolean;
  markdown?: string;
  setMarkdown?: (val: string) => void;
  percentage: number;
  markdownId?: string;
  generating?: boolean;
  rebuildPage?: () => void | Promise<void>;
}

enum DEVICE {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

export const PreviewSection = observer(({markdown, setMarkdown, markdownId, rebuildPage, generating}: Props) => {
  const [activeTab, setActiveTab] = useState('preview');
  const [updating, setUpdating] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [iframeSrc, setIframeSrc] = useState(markdown || '');
  const [device, setDevice] = useState<DEVICE>(DEVICE.desktop);
  const {ottoV2Store: {
    editLandingPage,
  }} = useStore('');

  const updateMarkdown = async () => {
    try {
      setUpdating(true);
      await editLandingPage(
        markdownId,
        {cleaned_html: markdown},
      );
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  };

  useEffect(() => {
    const src = URL.createObjectURL(new Blob([markdown], {type: 'text/html'}));
    setIframeSrc(src);
    setIframeLoading(true);
    return () => URL.revokeObjectURL(src);
  }, [markdown]);

  useEffect(() => {
    const iframeDoc = (document.getElementById('iframeDoc') as HTMLIFrameElement)?.contentDocument;
    if (iframeDoc && !iframeLoading) {
      const forms = iframeDoc?.querySelectorAll('form');
      // to prevent the forms inside the iframe from reloading the iframe on submitting
      forms?.forEach(form => {
        form?.addEventListener('submit', event => {
          event?.preventDefault();
        });
      });
    }
  }, [iframeSrc, iframeLoading]);

  const tabPanes = [
    {
      key: 'preview',
      name: 'Preview',
      element: <div style={{width: '100%', height: 'calc(90vh - 67px)', display: 'flex', justifyContent: 'center'}}>
        {iframeLoading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: 'calc(90vh - 67px)'}}><RingLoaderV2 color='#7F4EAD' fontSize={50} /></div> : ''}
        <iframe className={device} onLoad={() => setIframeLoading(false)} id='iframeDoc' src={iframeSrc} style={{width: '100%', height: '100%', border: 'none', display: iframeLoading && 'none'}} />
      </div>,
    },
    {
      key: 'edit',
      name: 'Edit as HTML',
      element: <EditWrapper className='edit-div'>
        <CustomTextareaV2 rows={20} minHeight='100%' variant='light' value={markdown} onChange={e => setMarkdown(e?.target?.value)} />
      </EditWrapper>,
    },
  ];

  const deviceIcons = [{icon: faDesktop, device: DEVICE.desktop}, {icon: faTabletScreenButton, device: DEVICE.tablet}, {icon: faMobile, device: DEVICE.mobile}];
  const tabBarExtraContents = {
    edit: (
      <div style={{display: 'flex', alignItems: 'center', gap: 10, marginRight: 56}}>
        <FreezeWrapper placement='bottom'><RebuildButton loading={generating} disabled={generating} onClick={rebuildPage}>Regenerate page</RebuildButton></FreezeWrapper>
        <FreezeWrapper placement='bottom'><UpdateButton loading={updating} disabled={updating} onClick={updateMarkdown}>Update file</UpdateButton></FreezeWrapper>
      </div>
    ),
    preview: <div style={{display: 'flex', gap: 16, alignItems: 'center', marginRight: 56}}>
      <FreezeWrapper placement='bottom'><RebuildButton loading={generating} disabled={generating} onClick={rebuildPage}>Regenerate page</RebuildButton></FreezeWrapper>
      <div style={{display: 'flex', gap: 22, alignItems: 'center'}}>
        {deviceIcons.map(item => (
          <FontAwesomeIcon onClick={() => setDevice(item.device)} key={item.device} style={{cursor: 'pointer'}} icon={item.icon} color={device === item.device ? '#121212' : '#A3A4A4'} fontSize={18} />
        ))}
      </div>
    </div>,
  };

  return (
    <RightSection>
      <div style={{width: '100%', height: '100%'}}>
        {markdown?.length ? <Tabs
          tabBarExtraContent={{right: tabBarExtraContents[activeTab]}} activeKey={activeTab} onChange={key => setActiveTab(key)}>
          {tabPanes.map(tab => (
            <Tabs.TabPane forceRender tab={tab.name} key={tab.key}>
              {tab.element}
            </Tabs.TabPane>
          ))}
        </Tabs> : (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
            <ProblemBanner rebuildPage={rebuildPage} />
          </div>
        )}
      </div>
    </RightSection>
  );
});
