import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationPin} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import {faCircleDot} from '@fortawesome/pro-duotone-svg-icons';
import {getPositionColor} from './map-tooltip';
import {meters2ScreenPixels} from 'google-map-react';

export const ModalMapTooltip = observer(({lat, lng, data, yourAddress=false, blueDot=false, zoom, radiusMiles, gridShape, gridDimensions}: any) => {
  const {w, h} = meters2ScreenPixels(gridDimensions == 4 && gridShape == 'square' ? (2*Math.PI*radiusMiles)*604.672 : (gridShape == 'square' ? (2*Math.PI*radiusMiles)*804.672 : (2*Math.PI*radiusMiles)*604.672), {lat, lng}, zoom);

  return <Wrapper yourAddress={yourAddress} blueDot={blueDot}>
    {blueDot ? <FontAwesomeIcon icon={faCircleDot} fontSize={40} color='#2D6CCA' /> : <>
      <FontAwesomeIcon
        icon={faLocationPin}
        fontSize={50}
        color={yourAddress ? getPositionColor(data) : '#4E5156'} />
      <PinPointValue>{data}</PinPointValue>
      {yourAddress && <YourAddressRadiusStyle style={{width: w, height: h}}></YourAddressRadiusStyle>}
    </>
    }
  </Wrapper>;
});

const Wrapper = styled.div<{yourAddress: boolean; blueDot: boolean}>`
  position: relative;
  display: inline-block;
  z-index: ${p => p?.yourAddress || p?.blueDot ? '999' : '100'};
`;

const PinPointValue = styled.div`
  position: absolute;
  color: white;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const YourAddressRadiusStyle = styled.div`
  position: absolute;
  width: 368px;
  height: 368px;
  background: #121212;
  opacity: 0.1;
  border-radius: 50% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
