import styled from 'styled-components';
import {QuestionsTable} from './QuestionsTable';

interface Props {
  addItem: (value?: string) => void;
  bulkAdd: (total?: number) => void;
  usedQuestions?: string[];
}

export const SuggestionsPopup = ({addItem, usedQuestions, bulkAdd}: Props) => {
  return <Wrapper>
    <QuestionsTable addItem={addItem} usedQuestions={usedQuestions} bulkAdd={bulkAdd}/>
  </Wrapper>;
};

const Wrapper = styled.div`
  width: 420px;
`;
