import React, {useState} from 'react';
import {SwitchWrapper} from '../../../../style';
import {Switch, Tooltip} from 'antd';
import classNames from 'classnames';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {getSingleUrlParam} from '@/utils/url';

interface AutoIndexingSwitchProps {
  record: any;
  componentIssueType: string;
  hasAccess: boolean;
  indexing: boolean;
  isSiteMapModal?: boolean;
  getSiteMapUrls?: (value?: any) => void;
}

const AutoIndexingSwitch: React.FC<AutoIndexingSwitchProps> = ({record, componentIssueType, hasAccess, indexing, isSiteMapModal=false, getSiteMapUrls}) => {
  const {ottoV2Store: {
    enableSitemap,
    loadIssueTableData,
    getOttoV2Project,
    getOttoUrls,
    enableUrlIndexing,
  }} = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const [loading, setLoading] = useState(false);

  const handleChange = async () => {
    setLoading(true);
    const filter = getOttoUrls.filter(d => d.issueType === componentIssueType);
    await enableSitemap(uuid, {sitemap_url: record?.url});
    await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: getOttoUrls[0]?.issueType, otto_project: getOttoV2Project?.id});
    setLoading(false);
  };

  const handleIndex = async record => {
    setLoading(record?.id);
    const filter = getOttoUrls.filter(d => d.issueType === componentIssueType);
    await enableUrlIndexing({otto_project_uuid: uuid, indexing_url_ids: [record?.id], is_selected: !record?.isSelected});
    if (isSiteMapModal) {
      getSiteMapUrls(false);
    } else {
      await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: componentIssueType, otto_project: getOttoV2Project?.id});
    }
    setLoading(null);
  };

  return (
    <Tooltip title={hasAccess ? '' : `Service account doesn't have access to GSC`}>
      <SwitchWrapper>
        <Switch loading={loading} className='switch-button' checked={record?.isSelected} onChange={() => indexing ? handleIndex(record) : handleChange()} disabled={!hasAccess} />
        <div className={classNames('enabled-disabled-txt', record?.isSelected ? 'add-green-color' : '')}>{record?.isSelected ? 'Enabled' : 'Disabled'}</div>
      </SwitchWrapper>
    </Tooltip>
  );
};

export default observer(AutoIndexingSwitch);
