import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ButtonStyled, StyledInput} from '../../styledComponents';
import {faPlus, faUserPlus} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {Col, Row, Upload} from 'antd';
import {faImage} from '@fortawesome/pro-duotone-svg-icons';
import {notification} from '@/utils/notification-v2';
import {emailRegex} from '@/constants/regex';
import {SocialMediaInput} from '../components/mediaInput';
import {AddContact, ColStyled, ImageIcon, InputFieldsWrapper} from '../styledComponents';
import styled from 'styled-components';

const {Dragger} = Upload;
export const AddContactSection = observer(({rowSelect}) => {
  const {campaigns: {
    saveContact,
    saveContactLoading,
    showDragger,
    setShowDragger,
    file,
    setFile,
    websiteUrl,
    setWebsiteUrl,
    linkedinUrl,
    setLinkedinUrl,
    facebookUrl,
    setFacebookUrl,
    twitterUrl,
    setTwitterUrl,
    gitlabUrl,
    setGitlabUrl,
    emailAddress,
    setEmailAddress,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    title,
    setTitle,
    showError,
    setShowError,
    setAddContactStatesToDefault}} = useStore('');
  function validateUrl(url, socialMediaName = null, updatedSocialMediaName?: string) {
    const urlRegex = /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z]{2,6})([/\w .-]*)*(\?.*)?$/;
    if (socialMediaName === null) {
      return urlRegex.test(url);
    } else {
      return urlRegex.test(url) && (url.includes(`${socialMediaName?.toLowerCase()}.com`) || url.includes(`${updatedSocialMediaName?.toLowerCase()}.com`));
    }
  }

  const handleFileUpload = file => {
    setFile(file);
  };

  const acceptImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

  const handleSaveContact = () => {
    if (
      linkedinUrl && !validateUrl(linkedinUrl, 'LinkedIn') ||
      twitterUrl && !validateUrl(twitterUrl, 'Twitter', 'X') ||
      gitlabUrl && !validateUrl(gitlabUrl, 'GitLab') ||
      facebookUrl && !validateUrl(facebookUrl, 'Facebook') || !emailAddress) {
      setShowError(true);
      return;
    }
    const formData = new FormData();
    file && formData.append('file', file);
    formData.append('email_address', emailAddress);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('title', title);
    formData.append('website_url', websiteUrl);
    formData.append('linkedin_url', linkedinUrl);
    formData.append('twitter_url', twitterUrl);
    formData.append('github_url', gitlabUrl);
    formData.append('facebook_url', facebookUrl);
    formData.append('opportunity', rowSelect?.id);
    saveContact(formData, rowSelect?.id);
  };

  return showDragger ?
    <Row style={{backgroundColor: 'white', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px'}}>
      <StyledDragger
        multiple={false}
        showUploadList={false}
        accept={acceptImageTypes.join(',')}
        style={{border: 'none', height: '40px'}}
        className={'dragFile'}
        customRequest={(data:any) => {
          if (acceptImageTypes.includes(data?.file?.type)) {
            handleFileUpload(data?.file as any);
          } else {
            notification.error('', 'Acceptable file formats: JPEG, PNG, GIF. Please try again.', false, 'OK');
          }
        }}
        onDrop={ e => {
          const getFile = e.dataTransfer.files?.length ? e.dataTransfer.files[0] : null;
          if (acceptImageTypes.includes(getFile?.type)) {
            handleFileUpload(getFile as any);
          } else {
            notification.error('', 'Acceptable file formats: JPEG, PNG, GIF. Please try again.', false, 'OK');
          }
        }}
      >
        <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '6px'}}>
          {file ?
            <img style={{width: '44px', height: '34px', borderRadius: '8px', border: '1px solid #E8E8E8'}} src={URL.createObjectURL(file)} /> :
            <ImageIcon>
              <FontAwesomeIcon icon={faImage} fontSize={24} color='#A3A4A4'/>
            </ImageIcon>}
          {file ? file?.name : <>Drag & Drop image or<span style={{color: '#2D6CCA', cursor: 'pointer'}}>click here to browse</span></>}
        </div>
      </StyledDragger>
      <ColStyled span={16}>
        <InputFieldsWrapper>
          <StyledInput
            value={emailAddress}
            onChange={e => {
              setEmailAddress(e?.target?.value);
            }}
            placeholder='Email address (required)'
            width='100%'
            height='32px'
            borderRadius='8px'
            style={{background: '#F9F9FB'}}/>
          {showError && !emailAddress || emailAddress && !emailRegex?.test(emailAddress) ? <div style={{color: 'red', fontSize: '12px'}}>{showError && !emailAddress ? 'Email is required' : 'Invalid Email'}</div> : null}
          <StyledInput
            value={firstName}
            onChange={e => setFirstName(e?.target?.value)}
            placeholder='First Name'
            width='100%'
            height='32px'
            borderRadius='8px'
            style={{background: '#F9F9FB'}}/>
          <StyledInput
            value={lastName}
            onChange={e => setLastName(e?.target?.value)}
            placeholder='Last Name'
            width='100%'
            height='32px'
            borderRadius='8px'
            style={{background: '#F9F9FB'}}/>
          <StyledInput
            value={title}
            onChange={e => setTitle(e?.target?.value)}
            placeholder='Title'
            width='100%'
            height='32px'
            borderRadius='8px'
            style={{background: '#F9F9FB'}}/>
        </InputFieldsWrapper>
        <div style={{display: 'flex', alignItems: 'center', gap: '25px'}}>
          <ButtonStyled loading={saveContactLoading} onClick={() => handleSaveContact()} width='fit-content' height='32px' background='#2AC155'>
            <FontAwesomeIcon icon={faUserPlus} color='white'/>
            Save contact
          </ButtonStyled>
          <ButtonStyled onClick={() => setAddContactStatesToDefault()} style={{padding: 0}} width='fit-content' isCancel={true}>
            Cancel
          </ButtonStyled>
        </div>
      </ColStyled>
      <Col style={{padding: '0 20px 20px 0'}} span={8}>
        <InputFieldsWrapper>
          <SocialMediaInput
            value={websiteUrl}
            onChange={setWebsiteUrl}
            placeholder='Website URL'
            validateUrl={(url: string) => validateUrl(url)}
            socialMediaName='Website'
          />
          <SocialMediaInput
            value={linkedinUrl}
            onChange={setLinkedinUrl}
            placeholder='LinkedIn URL'
            validateUrl={url => validateUrl(url, 'LinkedIn')}
            socialMediaName='LinkedIn'
          />
          <SocialMediaInput
            value={facebookUrl}
            onChange={setFacebookUrl}
            placeholder='Facebook URL'
            validateUrl={url => validateUrl(url, 'Facebook')}
            socialMediaName='Facebook'
          />
          <SocialMediaInput
            value={twitterUrl}
            onChange={setTwitterUrl}
            placeholder='Twitter URL'
            validateUrl={url => validateUrl(url, 'Twitter', 'X')}
            socialMediaName={'Twitter'}
          />
          <SocialMediaInput
            value={gitlabUrl}
            onChange={setGitlabUrl}
            placeholder='Gitlab URL'
            validateUrl={url => validateUrl(url, 'GitLab')}
            socialMediaName='GitLab'
          />
        </InputFieldsWrapper>
      </Col>
    </Row> : <AddContact onClick={()=> setShowDragger(true)}> <FontAwesomeIcon icon={faPlus}/> Add contact</AddContact>;
});

const StyledDragger = styled(Dragger)`
  padding: 0 20px 0 20px;
  .ant-upload {
    height: fit-content !important;
    background: white !important;
    padding: 0 !important;
    border: none;
  }
`;
