import {types, Instance, flow, cast} from 'mobx-state-tree';
import {reportsApi} from '@/api/gsc/index';
import {filterDataInMemory} from '@/utils/filter-data';
import {toJS} from 'mobx';

export const keywordsData = types.model({
  cpc: types.maybeNull(types.number),
  currentPosition: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  previousPosition: types.maybeNull(types.number),
  trafficDelta: types.maybeNull(types.number),
  volume: types.maybeNull(types.number),
  topPage: types.maybeNull(types.string),

});

export const FilterDataModel = types.model({
  cpc: types.maybeNull(types.number),
  currentPosition: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  previousPosition: types.maybeNull(types.number),
  trafficDelta: types.maybeNull(types.number),
  volume: types.maybeNull(types.number),
  topPage: types.maybeNull(types.string),

});

export type KeywordsDataType = Instance<typeof keywordsData>;

export const keywordMovementsData = types.model({
  date: types.maybeNull(types.string),
  keywords: types.array(keywordsData),
});

export type KeywordsMovementType = Instance<typeof keywordMovementsData>;

const FiltersModel = types.model({
  from: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
});
export const updateKeywordMovement=types.model({
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
});
export const keywordMovementsTable = types.model({
  keywordMovements: types.array(keywordMovementsData),
  updateKeywordMovements: types.array(updateKeywordMovement),
  selectedKeyword: types.array(keywordsData),
  selectedKeywordLoading: types.boolean,
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  filterKeywordTerm: types.maybeNull(types.string),
  sortField: types.optional(types.string, ''),
  sortDirection: types.optional(types.string, ''),
  filterList: types.optional(types.array(FiltersModel), []),
  filterTableDataSize: types.maybeNull(types.number),
  filteredRenderData: types.optional(types.array(FilterDataModel), []),
  tableSize: types.maybeNull(types.number),
  filterKeywordColumns: types.maybeNull(types.array(types.string)),

  keywordMovementsCount: types.maybeNull(types.number),
  keywordMovementsLoading: types.boolean,
  iskeywordMovementsInit: types.boolean,
  keywordMovementsPageNumber: types.number,
  keywordMovementsPageSize: types.number,
  keywordMovementsFilterList: types.array(FiltersModel),
  keywordMovementsSortField: types.maybeNull(types.string),
  keywordMovementsSortDirection: types.maybeNull(types.string),
  keywordMovementsSearchTerm: types.maybeNull(types.string),
  openKeywordSidedrawer: types.boolean,

}).views(self => ({
  get getFilteredData() {
    return self.filteredRenderData;
  },
  get keywordMovementsData() {
    return self.keywordMovements;
  },
  get getUpdateKeywordMovementPages() {
    return toJS(self.updateKeywordMovements);
  },
  get keywordSidedrawer() {
    return self.openKeywordSidedrawer;
  },
  get getSelectedKeyword() {
    return self.selectedKeyword;
  },
})).actions(self => {
  const onKeywordMovementsPaginationChange = (keywordMovementsPageNumber: number, keywordMovementsPageSize: number) => {
    self.keywordMovementsPageNumber = keywordMovementsPageNumber;
    self.keywordMovementsPageSize = keywordMovementsPageSize;
  };
  const updateKeywordMovementColumnsList =properties=>{
    // console.log('properties', properties);
    self.updateKeywordMovements=properties;
    // self.pages = properties;
  };
  const onKeywordMovementsFilterChange = (filters: any) => {
    self.keywordMovementsPageNumber = 1;
    self.keywordMovementsFilterList = cast(filters);
  };

  const onKeywordMovementsTableChange = (sortFields: string, order: string) => {
    self.keywordMovementsSortField = sortFields;
    self.keywordMovementsSortDirection = order;
  };

  const onKeywordMovementsUpdateSearchTerm = (searchTerm: string) => {
    self.keywordMovementsSearchTerm = searchTerm;
  };

  // const loadKeywordMovements = flow(function* ({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, pageNumber, pageSize, filters, filterKeywordTerm}) {
  // const sorters = {
  //   posCur: 'current_position',
  //   clicksCur: 'current_clicks',
  //   impCur: 'current_impressions',
  //   cpc: 'cpc',
  //   vol: 'volume',
  //   page: 'page',
  //   opportunity_score: 'opportunity_score',
  //   watchlist: 'watchlist',
  // };

  // const formtatedSorters = formatSorters(sorters, self.keywordSortField, self.keywordSortDirection);
  const loadKeywordMovements = flow(function* ({property, countryCode, currentPeriodStart, currentPeriodEnd, keywordMovementsPageNumber}) {
    const params = {
      selected_property: property,
      page_number: keywordMovementsPageNumber,
      period_start: currentPeriodStart,
      period_end: currentPeriodEnd,
      country_code: countryCode,
      // position_from: getFilter(filters, 'posCur')?.from,
      // position_to: getFilter(filters, 'posCur')?.to,
      // clicks_from: getFilter(filters, 'clicksCur')?.from,
      // clicks_to: getFilter(filters, 'clicksCur')?.to,
      // impressions_from: getFilter(filters, 'impCur')?.from,
      // impressions_to: getFilter(filters, 'impCur')?.to,
      // cpc_from: getFilter(filters, 'cpc')?.from,
      // cpc_to: getFilter(filters, 'cpc')?.to,
      // volume_from: getFilter(filters, 'vol')?.from,
      // volume_to: getFilter(filters, 'vol')?.to,
      // opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      // opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      // sort_by: formtatedSorters,
    };

    try {
      self.keywordMovementsLoading = true;
      const response = yield reportsApi.getPaKeywordMovementsReports(params);
      if (response) {
        self.keywordMovements = cast(response.results);
        self.iskeywordMovementsInit = false;
        self.keywordMovementsCount = response.count;
      }
      self.keywordMovementsLoading = false;
    } catch (e) {
      self.keywordMovementsLoading = true;
      throw e;
    }
  });

  const setLoading = () => {
    self.keywordMovementsLoading = true;
  };

  const setOpenKeywordSidedrawer = (open: boolean) => {
    self.openKeywordSidedrawer = open;
  };

  const setSelectedKeyword = (selctedKeyword: KeywordsDataType[]) => {
    const newArr = selctedKeyword.map(item => {
      return {
        ...item,
      };
    });

    self.selectedKeyword = cast(newArr);
    self.tableSize = self.selectedKeyword.length;
  };

  const handleFilterKeywordsRenderData = (filterKeywordTerm, filters, pageNumber: number, pageSize: number, sortField, sortDirection) => {
    self.selectedKeywordLoading = true;
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.selectedKeyword, self.filterKeywordTerm, self.filterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection);
    self.filterTableDataSize = dataFilteredSize;
    self.filteredRenderData.length = 0;
    const newArray = dataFiltered.map(item => {
      return {
        ...item,
      };
    });
    self.filteredRenderData = cast(newArray);
    self.selectedKeywordLoading = false;
  };

  const handleKeywordsTableChange = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortField} = column || {};
    self.sortField = sortField;
    self.sortDirection = order;
    handleFilterKeywordsRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };

  const onKeywordsPaginationChange = async (pageNumber: number, pageSize: number) => {
    self.pageSize = pageSize;
    self.pageNumber = pageNumber;
    handleFilterKeywordsRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };

  const setFilterKeywordTerm = (searchTerm: string) => {
    if (self.filterKeywordTerm !== searchTerm) {
      self.filterKeywordTerm = searchTerm;
      self.pageNumber = 1;
      handleFilterKeywordsRenderData(self.filterKeywordTerm, self.filterList, 1, self.pageSize, self.sortField, self.sortDirection);
    }
  };

  const handleFilterChange = filters => {
    self.pageNumber = 1;
    self.filterList = filters;
    handleFilterKeywordsRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };


  return {
    updateKeywordMovementColumnsList,
    loadKeywordMovements,
    handleFilterChange,
    setFilterKeywordTerm,
    handleKeywordsTableChange,
    onKeywordsPaginationChange,
    handleFilterKeywordsRenderData,
    onKeywordMovementsPaginationChange,
    setOpenKeywordSidedrawer,
    setSelectedKeyword,
    onKeywordMovementsFilterChange,
    onKeywordMovementsTableChange,
    onKeywordMovementsUpdateSearchTerm,
    setLoading,
  };
});

export function initKeywordMovementsTable() {
  return keywordMovementsTable.create({
    keywordMovements: [],
    updateKeywordMovements: [],
    keywordMovementsLoading: true,
    iskeywordMovementsInit: true,
    openKeywordSidedrawer: false,
    keywordMovementsPageNumber: 1,
    keywordMovementsPageSize: 2,
    keywordMovementsFilterList: [],
    keywordMovementsSortField: '',
    keywordMovementsSortDirection: '',
    keywordMovementsSearchTerm: '',

    selectedKeywordLoading: true,
    sortField: '',
    sortDirection: '',
    filterList: [],
    pageSize: 10,
    pageNumber: 1,
    filterKeywordColumns: ['keyword'],
    filterKeywordTerm: '',
    filteredRenderData: [],
    tableSize: 0,
  });
}

