export enum TaskStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

export enum ToxicityType {
  NON_TOXIC = 'Non Toxic',
  POSSIBLY_TOXIC = 'Possibly Toxic',
  TOXIC = 'Toxic',
}

export interface Refdomain {
  ahrefsRank: number;
  ahrefsTop: number;
  alt: string;
  anchor: string;
  citationFlow: number;
  domainFrom: string;
  domainRating: number;
  domainTo: string;
  encoding: string;
  firstSeen: string;
  httpCode: number;
  isNofollow: number;
  isOriginal: number;
  isSponsored: number;
  isUgc: number;
  language: string;
  lastVisited: string;
  linkType: string;
  linksExternal: number;
  linksInternal: number;
  pageSize: number;
  prevVisited: string;
  redirectCode: number;
  textPost: string;
  textPre: string;
  title: string;
  topicaltrustflowTopic0: string;
  topicaltrustflowTopic1: string;
  topicaltrustflowTopic2: string;
  topicaltrustflowTopic3: string;
  topicaltrustflowTopic4: string;
  topicaltrustflowTopic5: string;
  topicaltrustflowTopic6: string;
  topicaltrustflowTopic7: string;
  topicaltrustflowTopic8: string;
  topicaltrustflowTopic9: string;
  topicaltrustflowValue0: number;
  topicaltrustflowValue1: number;
  topicaltrustflowValue2: number;
  topicaltrustflowValue3: number;
  topicaltrustflowValue4: number;
  topicaltrustflowValue5: number;
  topicaltrustflowValue6: number;
  topicaltrustflowValue7: number;
  topicaltrustflowValue8: number;
  topicaltrustflowValue9: number;
  totalBacklinks: number;
  trustFlow: number;
  urlFrom: string;
  urlFromFirstSeen: string;
  urlTo: string;
}

// TODO: Remove after they add ID to the refdomain.
export interface RefdomainWithId extends Refdomain {
  id: number;
}

export interface RefdomainApiResponse {
  next:string;
  previous: string;
  pageSize: number;
  totalPages: number;
  refdomains: Refdomain[];
  refdomainsCount: number;
  primaryWebsite: string;
  taskStatus: {
    backlinks: TaskStatus;
    urlclassify: TaskStatus;
    urldownload: TaskStatus;
  };
}

export interface Category {
  Adult: CategoryData[];
  Arts: CategoryData[];
  Business: CategoryData[];
  Computer: CategoryData[];
  Games: CategoryData[];
  Health: CategoryData[];
  Home: CategoryData[];
  News: CategoryData[];
  Recreation: CategoryData[];
  Reference: CategoryData[];
  Regional: CategoryData[];
  Science: CategoryData[];
  Shopping: CategoryData[];
  Society: CategoryData[];
  Sports: CategoryData[];
  World: CategoryData[];
}

export interface CategoryData {
  percentage: number;
  score: number;
  website: string;
}


export interface GetTopicalRelevanceResponse {
  results: {
    category: Category[];
    website: any;
  };
  taskStatus: {
    status: TaskStatus;
  };
}

export interface GetTopicalRelevanceData {
  category: Category[];
  website: any;
  status: TaskStatus;
}


export interface CompetitorRefdomainApiResponse extends RefdomainApiResponse {
  competitorWebsite: string;
}

export interface HighFreq {
  anchor: string;
  count: number;
}
export interface AnchorText {
  label: string;
  total: number;
  color: string;
  percentage: number;
  target: string;
  highFreq: HighFreq[];
}

export interface AnchorTextDistribution {
  data: AnchorText[];
  status: string;
}

export interface TopCategory {
  name: string;
  score: number;
  percentage: number;
}

export interface Categories {
  name: string;
  score: number;
  percentage: number;
}

export interface CategoryFields {
  categories: Categories[];
  topCategory: TopCategory;
}

export interface CategoryDataSet {
  data: CategoryFields;
  status: string;
}

export interface DetailedWebsite {
  category: CategoryDataSet;
  anchorTextDistribution: AnchorTextDistribution;
  hostname: string;
  refdomainCount?: number;
  keywordCount?: number;
  trafficvalue?: number;
  ot?: number;
}
export interface AllProjectsApiResponse {
  pk: number;
  primaryWebsite: DetailedWebsite;
  competitorWebsites: DetailedWebsite[];
}

export interface CreateProjectApiResponse {
  pk: number;
  primaryWebsite: DetailedWebsite;
  competitorWebsites: DetailedWebsite[];
}

export interface GetRefdomainResponse {
  backlinks: TaskStatus;
  urlclassify: TaskStatus;
  urldownload: TaskStatus;
  primaryWebsite: string;
  content: RefdomainWithId[];
  refdomainsCount: number;
  next: string;
  previous:string;
  pageSize: number;
  totalPages:number;
}

export interface StatusObj {
  backlinks: TaskStatus;
  urlclassify: TaskStatus;
  urldownload: TaskStatus;
}

export interface GetCompetitorRefdomainResponse extends GetRefdomainResponse {
  competitorWebsite: string;
  backlinks: TaskStatus;
}

export interface TopicalRequest{
  domains: string[];
  type: string;
}

export interface CreateProjectApiRequest {
  primaryWebsite: {
    hostname: string;
  };
  competitorWebsites?: Partial<DetailedWebsite>[];
}

export interface UpdateProjectAPI {
  primaryWebsite: string;
  competitorWebsites?: string[];
}

export interface UrltoDistributionSummaryData {
  homepage: {
    label: string;
    value: number;
  };
  nonhomepage: {
    label: string;
    value: number;
  };
  topPages: {
    path: string;
    count: number;
    pct: number;
  }[];
}

export interface UrltoDistributionData {
  summary: UrltoDistributionSummaryData;
}


export interface AnchorDistributionStatsData {
  name: string;
  total: number;
  highFreq: {
    url: string;
    value: number;
  }[];
  label: string;
  target: string;
  color: string;
}

export interface AnchorDistributionData {
  stats: AnchorDistributionStatsData[];
}


export interface ProjectDistributionResponse {
  results: ProjectDistributionData[];
  taskStatus: {
    backlinkTaskStatus: TaskStatus;
  };
}

export interface ProjectDistributionData {
  domainName: string;
  taskStatus: {
    backlinkTaskStatus: TaskStatus;
    urlcategoryTaskStatus: TaskStatus;
  };
  urltoDistribution: UrltoDistributionData;
  anchorDistribution: AnchorDistributionData;
  domainRating: {
    '0-10': number;
    '10-20': number;
    '20-30': number;
    '30-40': number;
    '40-50': number;
    '50-60': number;
    '60-70': number;
    '70-80': number;
    '80-90': number;
    '90-100': number;
  };
}

export interface DomainRating {
  hostname: string;
  data: number[];
}

export interface OutreachTargetItem {
  ahrefsRank: number;
  domainRating: number;
  ahrefsTop: number;
  domainFrom: string;
  citationFlow: number;
  trustFlow: number;
  topicaltrustflowTopic0: string;
  topicaltrustflowTopic1: string;
  topicaltrustflowTopic2: string;
  topicaltrustflowTopic3: string;
  topicaltrustflowTopic4: string;
  topicaltrustflowTopic5: string;
  topicaltrustflowTopic6: string;
  topicaltrustflowTopic7: string;
  topicaltrustflowTopic8: string;
  topicaltrustflowValue0: number;
  topicaltrustflowValue1: number;
  topicaltrustflowValue2: number;
  topicaltrustflowValue3: number;
  topicaltrustflowValue4: number;
  topicaltrustflowValue5: number;
  topicaltrustflowValue6: number;
  topicaltrustflowValue7: number;
  topicaltrustflowValue8: number;
  competitorsCount: number;
  competitors: string[];
}

export interface OutreachTargetItemResponse {
  results: OutreachTargetItem[];
  taskStatus: {
    status: TaskStatus;
  };
  status: TaskStatus;
}

export interface OutreachTargetItemData {
  results: OutreachTargetItem[];
  status: TaskStatus;
}

export interface GetDistributionResponse {
  backlinkTaskStatus: TaskStatus;
  projects: ProjectDistributionData[];
  primaryProject: ProjectDistributionData;
  domainRating: DomainRating[];
}

export interface ToxicBacklinksResultsItem {
  toxicity: ToxicityType;
  color: string;
  url: string;
  urlTo: string;
  domain: string;
  domainTo: string;
  anchorText: string;
  score: number;
  confidence: number;
  toxicityReason: string;
}

export interface ToxicBacklinksAggregatedItem {
  nonToxic: {
    amount: number;
    percentage: string;
  };
  possiblyToxic: {
    amount: number;
    percentage: string;
  };
  toxic: {
    amount: number;
    percentage: string;
  };
}

export interface ToxicBacklinksResponse {
  results: ToxicBacklinksResultsItem[];
  aggregated: ToxicBacklinksAggregatedItem[];
  taskStatus: {
    ahrefs: TaskStatus;
    majestic: TaskStatus;
    toxicity: TaskStatus;
  };
}

export interface ToxicBacklinksData {
  toxicBacklinks: ToxicBacklinksResultsItem[];
  aggregated: ToxicBacklinksAggregatedItem[];
  toxicity: TaskStatus;
  ahrefs: TaskStatus;
}

export interface DisavowResponseItem {
  url: string;
  type: string;
}

export interface WhitelistResponseItem {
  url: string;
  type: string;
}
export interface TopicalCSVResponseItem {
  url: string;
  type: string;
}


