
import styles from '../AiFirstDraftGenerator/style.module.scss';
import {
  Table,
} from '@/components/common-components/components';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {formatLargeNumber} from '@/utils/number';
import {Button} from '@/components/common-components/v2';
import styled from 'styled-components';

interface Props {
  addItem: (value?: string) => void;
  removeItem: (value?: string) => void;
  usedQuestions: string[];
}

export const QuestionsTable = ({addItem, removeItem, usedQuestions}: Props) => {
  const {
    contentOptimizer: {
      currentPage,
    },
  } = useStore('');

  const colums = [
    {
      title: 'Question',
      dataIndex: 'keyword',
      key: 'keyword',
      sortFieldName: 'keyword',
      align: 'left' as 'left',
      // sortDirections: ['descend', 'ascend'],
      width: 200,
      render: (_, record) => {
        return (
          <div style={{maxWidth: 170, overflow: 'hidden', whiteSpace: 'pre-wrap', fontSize: 12, color: usedQuestions?.includes(record.keyword) ? '#a3a4a4' : '#121212'}}>
            {record.keyword}
          </div>
        );
      },
    },
    {
      title: 'SV',
      dataIndex: 'sv',
      key: 'sv',
      sortFieldName: 'sv',
      align: 'center' as 'center',
      // sortDirections: ['descend', 'ascend'],
      width: 50,
      render: (_, record) => {
        return (
          <div style={{fontSize: 12, color: '#121212'}}>
            {`${formatLargeNumber(record.sv)}`}
          </div>
        );
      },
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      sortFieldName: 'cpc',
      align: 'center' as 'center',
      // sortDirections: ['descend', 'ascend'],
      width: 50,
      render: (_, record) => {
        return (
          <div style={{fontSize: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#121212'}}>
            {`$${formatLargeNumber(record.cpc)}`}
            <VerticalProgress>
              <ProgressFill></ProgressFill>
            </VerticalProgress>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      sortFieldName: '',
      align: 'center' as 'center',
      // sortDirections: ['descend', 'ascend'],
      width: 80,
      render: (_, record) => {
        return (
          <div>
            <Button
              variant='ghost'
              color={usedQuestions?.includes(record.keyword) ? 'red' : 'blue'}
              style={{fontSize: 12, padding: '5px 10px'}}
              onClick={() => {
                if (usedQuestions?.includes(record.keyword)) {
                  removeItem(record.keyword);
                } else {
                  addItem(record.keyword);
                }
              }}>
              {usedQuestions?.includes(record.keyword) ? 'Remove' : '+ Add'}
            </Button>
          </div>
        );
      },
    },
  ];

  return <div className={styles.tableOuterTerms}>
    <Table
      pagination={false}
      columns={colums}
      scroll={{x: 350, y: 300}}
      dataSource={toJS(currentPage?.analytics?.questions)}
      // loading={initLoading}
      // loadingRows={pageSize}
      // onChange={handleTableChange}
    />
  </div>;
};

const VerticalProgress = styled.div`
  height: 12px;
  width: 3px;
  background-color: #A3A4A4;
  border-radius: 5px;
  position: relative;
  margin-bottom: 2px;
  margin-left: 2px;
`;

const ProgressFill = styled.div`
  height: 5px;
  width: 3px;
  background-color: #34AEF3;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
