import {types,
  flow, cast} from 'mobx-state-tree';
import {siteAuditorApi} from '@/api/account';
import {toJS} from 'mobx';


const headers = types.model({
  filter: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const nonIndexabilityCauses = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const indexabilityDistribution = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});
const canonicalTagDistribution = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const robotDirect = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  filter: types.maybeNull(types.string),
});

const Chart = types.model({
  colors: types.maybeNull(types.array(types.string)),
  xAxisLabels: types.maybeNull(types.array(types.string)),
  series: types.maybeNull(types.array(
    types.model({
      data: types.maybeNull(types.array(types.number)),
      filters: types.maybeNull(types.array(types.string)),
      name: types.maybeNull(types.string),
      type: types.maybeNull(types.string),
    }))),
});

const robotsTxtConfiguration = types.model({
  baidu: types.maybeNull(types.boolean),
  bing: types.maybeNull(types.boolean),
  duckDuckGo: types.maybeNull(types.boolean),
  google: types.maybeNull(types.boolean),
  yahoo: types.maybeNull(types.boolean),
  yandex: types.maybeNull(types.boolean),
});


export const indexibilityData = types.model({
  headers: types.maybeNull(types.array(headers)),
  indexabilityDistribution: types.maybeNull(types.array(indexabilityDistribution)),
  nonIndexabilityCauses: types.maybeNull(types.array(nonIndexabilityCauses)),
  robotsTxtConfiguration: types.maybeNull(robotsTxtConfiguration),
  robotsDirectivesDistribution: types.maybeNull(types.array(robotDirect)),
  canonicalTagDistribution: types.maybeNull(types.array(canonicalTagDistribution)),
  canonicalizationByDepth: types.maybeNull(Chart),
  indexabilityByDepth: types.maybeNull(Chart),
});
export const IndexibilityReportStore = types.model({
  indexibilityData: types.maybeNull(indexibilityData),
  loading: types.boolean,
}).views( self => ({
  get getIndexibilityData() {
    return toJS(self.indexibilityData);
  },
})).actions(self => {
  const loadIndexibilityData = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield siteAuditorApi.indexibilityData(domain);
      self.indexibilityData =cast(response);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  return {
    loadIndexibilityData,
  };
});

export function initIndexibilityReportStore() {
  return IndexibilityReportStore.create({
    loading: true,
  });
}
