import styles from './styles.module.scss';
import {Row, Col} from 'antd';
import {Button} from '@/components/common-components/v2';
import {integrationApi} from '@/api/gsc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import classnames from 'classnames';

interface Props {
  emailList?: string[];
  showButton?:boolean;
  className?:string;
  title?: string;
  showCancelButton?: boolean;
}

export const AccountsHaveNoGscConnectedBanner = observer(({emailList, className, showButton=true, title, showCancelButton = false}: Props) => {
  const {settings: {
    integration: {
      setShowNoGscConnectedOnPageBanner,
      showNoGscConnectedOnPageBanner,
    },
  },
  } = useStore('');

  const renderEmailListSeparator = (idx, totalLength) => {
    if (totalLength > 2) {
      if (idx === totalLength - 2) {
        return <span style={{marginLeft: 5, marginRight: 5}}>and</span>;
      } else if (idx < totalLength - 2 && idx !== totalLength - 1) {
        return <span style={{marginRight: 5}}>,</span>;
      } else {
        return '.';
      }
    } else if (totalLength == 2) {
      if (idx === 0) {
        return <span style={{marginLeft: 5, marginRight: 5}}>and</span>;
      } else {
        return '.';
      }
    } else if (totalLength < 2) {
      return '.';
    }
  };


  return showNoGscConnectedOnPageBanner ? (<div className={classnames(styles.container, className)}>
    <Row gutter={24} className={styles.notificationBarRow}>
      <Col span={24}>
        <div>
          <div className={styles.notificationBarOrange}>
            <div className={showCancelButton ? styles.titleNCancelContainer : ''}>
              <div>{title?.length ? title : 'No GSC Sites found.'}</div>
              {showCancelButton && <button onClick={() => {
                setShowNoGscConnectedOnPageBanner(false);
                localStorage.setItem('hideGscBanner', 'hide');
              }} className={styles.cancelBtn}><FontAwesomeIcon icon={faXmark} fontSize={18} /></button>}
            </div>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', flexWrap: 'wrap', fontSize: 12, fontWeight: 400}}>
                <span style={{marginRight: 5}}>
                    Following accounts have no site properties linked:
                </span>
                {emailList?.map((email, idx) => (<>
                  <span key={idx} style={{textDecoration: 'underline'}}>
                    {`${email}`}
                  </span>
                  {renderEmailListSeparator(idx, emailList.length)}
                </>
                ))}
              </div>

              { showButton && <div style={{marginLeft: 'auto', marginTop: -20, display: 'flex', alignItems: 'center'}}>
                <Button color='blue' variant='solid' mode='dark' onClick={() => {
                  location.href = integrationApi.getGoogleConnectUrl();
                }}><FontAwesomeIcon icon={faGoogle} style={{fontSize: 14, marginRight: 10}}/> Repeat Authorization</Button>
                <div style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => setShowNoGscConnectedOnPageBanner(false)}><FontAwesomeIcon icon={faXmark} fontSize={20} /></div>
              </div>}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>) : <></>;
});
