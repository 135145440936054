
import {types} from 'mobx-state-tree';

export const KeywordLists = types
  .model({
    id: types.number,
    name: types.maybeNull(types.string),
    keyword: types.array(types.string),
    count: types.maybeNull(types.number),
    countryCode: types.maybeNull(types.string),
    isRecentSearch: types.maybeNull(types.boolean),
  });


