import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class BulkUrlAnalyzerApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v1');

  public async getBulkUrlMetrics(payload: any) {
    try {
      const response = await this.axios.post(`${BulkUrlAnalyzerApi.baseUrl}/bulk-url-metrics/`,
        payload,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const BULK_URL_ANALYZER_API = new BulkUrlAnalyzerApi();
