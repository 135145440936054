import {cast, flow, Instance, types, getRoot} from 'mobx-state-tree';
import {KeywordDetails, KeywordDetailsInstance} from './keyword-details';
import isEmpty from 'lodash/isEmpty';
import {KeywordCreatePayload, KeywordUpdatePayload, keywordListPayload, TaskStatus} from '@/api/keyword-explorer/overview/overview-api';
import {OVERVIEW_DETAIL_API} from '@/api/keyword-explorer';
import {KeywordLists} from './keyword-lists';
import {stringToArray} from '@/utils/arrays';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';

const FilterValue = types.model({
  min: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
});

interface FilterPayload {
  min?: number;
  max?: number;
}

export type Identifier = 'kd' | 'sv' | 'position' | 'traffic' | 'cpc' | 'ppcd' | '';

export const OverviewFilters = types
  .model({
    active: types.union(
      types.literal('kd'),
      types.literal('sv'),
      types.literal('position'),
      types.literal('traffic'),
      types.literal('cpc'),
      types.literal('ppcd'),
      types.literal(''),
    ),
    kd: types.maybeNull(FilterValue),
    sv: types.maybeNull(FilterValue),
    traffic: types.maybeNull(FilterValue),
    cpc: types.maybeNull(FilterValue),
    ppcd: types.maybeNull(FilterValue),
    query: types.string,
  })
  .views(self => ({
    isOpen(identifier: string) {
      return self.active === identifier;
    },
    getFilters(identifier: Identifier) {
      const data = self[identifier];

      if (data) {
        return {
          min: data.min,
          max: data.max,
        };
      } else {
        return null;
      }
    },
  }))
  .actions(self => {
    const setActive = (identifier: Identifier) => {
      if (self.active === identifier) {
        self.active = '';
      } else {
        self.active = identifier;
      }
    };

    const setFilter = (identifier: Identifier, payload: FilterPayload) => {
      self[identifier] = cast(payload);
    };

    const clearAllFilters = () => {
      self.active= '';
      self.kd= null;
      self.sv= null;
      self.traffic= null;
      self.cpc= null;
      self.ppcd= null;
      self.query= '';
    };

    const setQuery = (query: string) => {
      self.query = query;
    };

    return {
      setActive,
      setQuery,
      setFilter,
      clearAllFilters,
    };
  });

export const OverviewStore = types
  .model({
    loading: types.optional(types.boolean, false),
    open: types.optional(types.boolean, false),
    modal: types.optional(types.boolean, false),
    isKeywordsModal: types.boolean,
    data: types.array(KeywordDetails),
    keywords: types.maybeNull(types.string),
    keywordsList: types.array(KeywordLists),
    filters: OverviewFilters,
    deleteModal: types.boolean,
    maxLookupReached: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get getDataCount() {
      return self.data.length;
    },
    filterKeywords(query: string) {
      const formattedQuery = query.toLowerCase();

      return self.keywordsList.filter(record => record?.name?.toLowerCase().includes(formattedQuery));
    },
    getKeywordListForDetailPage() {
      return self.keywordsList.map(item => ({
        id: item.id,
        name: item.name,
        count: item.keyword.length,
        countryCode: item.countryCode? item.countryCode : '',
        keyword: item.keyword.slice(),
        isRecentSearch: item.isRecentSearch ? item.isRecentSearch : false,
      }));
    },
    get getData() {
      const filteredData: KeywordDetailsInstance[] = self.data.reduce((acc, curr) => {
        const keys: Identifier[] = ['kd', 'cpc', 'traffic', 'sv', 'ppcd'];

        const conditions = keys.map(item => {
          const filter = self.filters.getFilters(item);
          if (!isEmpty(filter)) {
            return curr[item] >= filter.min && curr[item] <= filter.max;
          }
          return true;
        });

        const query = self.filters.query;


        if (query) {
          if (curr.keyword.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
            conditions.push(true);
          } else {
            conditions.push(false);
          }
        } else {
          conditions.push(true);
        }


        const isConditionMet = conditions.every(condition => condition === true);

        if (isConditionMet) {
          acc.push(curr);
        }

        return acc;
      }, []);

      return filteredData.map(item => ({
        ...item,
        trend: item.trendSeries,
        cpc: item.formattedCpc,
        sv: item.formattedSv,
        ppcd: item.formattedPpcd,
        traffic: item.formattedTraffic,
        svCloned: item.sv || 0,
        trafficCloned: item.traffic || 0,
        cpcCloned: item.cpc || 0,
      }));
    },
  }))
  .actions(self => {
    const toggleOverview = () => (self.open = !self.open);
    const getKeywordList = flow(function* (payload: keywordListPayload) {
      try {
        const updatedPayload = {
          countryCode: payload.countryCode,
          keywordlist_id: payload.keywordlistId,
          keywords: payload.keywords.map(keyword =>{
            return keyword.replaceAll('+', ' ');
          }),
        };
        return yield OVERVIEW_DETAIL_API.findKeywordList(updatedPayload);
      } catch (e) {
        if (e?.response?.status && e?.response?.status === 429) {
          const rootStore = getRoot(self) as any;
          rootStore.plans.showSidebarPaymentDrawer();
        } else {
          return Promise.reject(e);
        }
      } finally {
        self.loading = false;
      }
    });
    const getKeywordListForPublic = flow(function* (payload) {
      try {
        const updatedPayload = {
          countryCode: payload.countryCode,
          id: payload.keywordlistId,
          publicHash: payload.publicHash,
        };
        return yield OVERVIEW_DETAIL_API.findKeywordListForPublic(updatedPayload);
      } catch (e) {
        if (e?.response?.status && e?.response?.status === 429) {
          const rootStore = getRoot(self) as any;
          rootStore.plans.showSidebarPaymentDrawer();
        } else {
          return Promise.reject(e);
        }
      } finally {
        self.loading = false;
      }
    });
    const createKeywordList = flow(function* (payload: KeywordCreatePayload) {
      try {
        self.loading = true;
        const updatedPayload = {
          name: payload.name,
          keywords: payload.keywords,
          count: payload.count,
          country_code: payload.countryCode,
          is_recent_search: payload.isRecentSearch,
        };
        return yield OVERVIEW_DETAIL_API.postKeywordlist(updatedPayload);
      } catch (e) {
        if (e?.response?.status && e?.response?.status === 429) {
          const rootStore = getRoot(self) as any;
          rootStore.plans.showSidebarPaymentDrawer();
        } else {
          return Promise.reject(e);
        }
      } finally {
        self.loading = false;
      }
    });

    const createKeywordListPublic = flow(function* (payload: KeywordCreatePayload) {
      try {
        if (payload?.keywords?.length > 1) {
          const updatedPayload = {
            name: payload.name,
            keywords: payload.keywords,
            count: payload.count,
            country_code: payload.countryCode,
            is_recent_search: payload.isRecentSearch,
          };
          const response = yield OVERVIEW_DETAIL_API.postKeywordlist(updatedPayload);
          if (response) {
            self.maxLookupReached = false;
            return response;
          }
        } else {
          const updatedPayload = {
            name: payload.name,
            query: payload.keywords?.length ? payload.keywords[0] : '',
            count: payload.count,
            country_code: payload.countryCode,
            is_recent_search: payload.isRecentSearch,
          };
          const response = yield OVERVIEW_DETAIL_API.postSingleKeyword(updatedPayload);
          if (response) {
            self.maxLookupReached = false;
            return response;
          }
        }
      } catch (e) {
        if (e?.response?.status && e?.response?.status === 429) {
          const rootStore = getRoot(self) as any;
          rootStore.plans.showSidebarPaymentDrawer();
        }
        if (e.response.status === 400) {
          notification?.error('Error Submitting On-page audit', e.response.data.nonFieldErrors[0]);
          return false;
        }
        if (e.response.status === 406) {
          notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
          self.maxLookupReached = true;
          return false;
        }
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const updateKeywordList = flow(function* (payload: KeywordUpdatePayload) {
      try {
        const updatedPayload = {
          id: payload.id,
          name: payload.name,
          keywords: payload.keywords,
          count: payload.count,
          country_code: payload.countryCode,
          is_recent_search: payload.isRecentSearch,
        };
        return yield OVERVIEW_DETAIL_API.updateKeywordlist(updatedPayload);
      } catch (e) {
        notification.warning('Keyword List not updated', 'Something went wrong. Please try again to update the Keyword List.');
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const deleteKeywordList = flow(function* (id: number) {
      try {
        return yield OVERVIEW_DETAIL_API.deleteKeywordlist(id);
      } catch (e) {
        notification.warning('Keyword List not deleted', 'Something went wrong. Please try again to delete the Keyword List.');
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const getKeyWordList = flow(function* () {
      try {
        return yield OVERVIEW_DETAIL_API.getKeywordlist();
      } catch (e) {
        notification.error('Error loading Keyword Details', 'The Keyword Details data did not load properly. To fix the issue:', true);
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const mapKeywords = (keywords: any) => {
      const ignoredTaskStatusKeys = ['keyword', 'serpsTaskStatus', 'questionsTaskStatus'];
      return JSON.parse(JSON.stringify(keywords))?.map(item => ({
        keyword: item?.keyword,
        histSv: item.histSv || [],
        sv: item.sv ? item.sv : null,
        kd: item.kd ? item.kd : null,
        traffic: item.ppcd ? item.cpc : null,
        cpc: item.cpc ? item.cpc : null,
        ppc: item.ppcd ? item.ppcd : null,
        ppcd: item.ppcd ? item.ppcd : null,
        publicShareHash: item?.publicShareHash ? item?.publicShareHash : null,
        tasksComplete: Object.keys(item.taskStatus).filter(key => !ignoredTaskStatusKeys.includes(key)).every(key => item.taskStatus[key] === TaskStatus.SUCCESS || item.taskStatus[key] === TaskStatus.FAILURE),
      }));
    };

    const changeKeyword = flow(function* (keywords: string, selectedCounty: string, keywordlistId?: string, toggle: boolean = true) {
      toggle && toggleOverview();
      const data = {'keywords': stringToArray(keywords), 'countryCode': selectedCounty, 'keywordlistId': keywordlistId};
      try {
        const response = yield getKeywordList(data);
        const mappedKeywordsData = mapKeywords(response?.results);
        self.keywords = keywords;
        self.data = mappedKeywordsData;
        self.modal = false;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const loadKeywordsPublcList = flow(function* (keywords: string, selectedCounty: string, keywordlistId?: string, toggle: boolean = true) {
      const publicHash = getSingleUrlParam('public_hash');
      toggle && toggleOverview();
      const data = {'countryCode': selectedCounty, 'keywordlistId': keywordlistId, 'publicHash': publicHash};
      try {
        const response = yield getKeywordListForPublic(data);
        const mappedKeywordsData = mapKeywords(response?.results);
        self.keywords = keywords;
        self.data = mappedKeywordsData;
        self.modal = false;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const mapGetKeywords = (keywords: any) => {
      return keywords?.map(item => ({
        id: item?.id,
        name: item?.name,
        keyword: item?.keywords,
        count: item ? item.keywords.length : 0,
        countryCode: item? item.countryCode : '',
        isRecentSearch: item? item.isRecentSearch : false,
      }));
    };
    const resetOverviewData = () => {
      self.data = mapKeywords([]);
      // @ts-ignore
      self.filters = {
        active: '' as Identifier,
        kd: null,
        sv: null,
        traffic: null,
        cpc: null,
        query: '',
      };
    };
    const openModal = () => {
      self.modal = true;
    };
    const closeModal = () => {
      self.modal = false;
    };
    const isDeleteModal = (value: boolean) => {
      self.deleteModal = value;
    };
    const startLoading = () => {
      self.loading = true;
    };
    const stopLoading = () => {
      self.loading = false;
    };
    const setKeywordListEmpty = () => {
      self.keywordsList = mapGetKeywords([]);
    };
    const setIsKeywordsModal = (value: boolean) => {
      self.isKeywordsModal = value;
    };
    const getKeywords = flow(function* () {
      if (self.keywordsList.length == 0) {
        const response = yield getKeyWordList();
        const result = Array.isArray(response) && mapGetKeywords(response);
        self.keywordsList = result || [];
      }
    });

    const createKeywords = flow(function* (data: KeywordCreatePayload) {
      self.loading = true;
      try {
        const response = yield createKeywordList(data);
        if (response?.id) {
          const temp = {
            id: response?.id,
            name: response?.name,
            keyword: response?.keywords,
            count: response && response?.keywords ? response?.keywords?.length : 0,
            countryCode: response ? response.countryCode : '',
            isRecentSearch: response ? response.isRecentSearch : '',
          };
          self.keywordsList.unshift(temp);
        }

        self.modal = false;
        return response;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const updateKeyword = flow(function* (data: KeywordUpdatePayload) {
      self.loading = true;
      try {
        const response = yield updateKeywordList(data);
        const temp = {
          id: response?.id,
          name: response?.name,
          keyword: response?.keywords,
          count: response && response?.keywords ? response?.keywords?.length : 0,
          countryCode: response ? response?.countryCode : '',
          isRecentSearch: response && response?.isRecentSearch,
        };
        const index = self.keywordsList.findIndex(item => item.id === response.id);
        self.keywordsList[index] = temp;
        self.modal = false;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const deleteKeyword = flow(function* (id) {
      self.loading = true;
      try {
        yield deleteKeywordList(id);
        const index = self.keywordsList.findIndex(item => item.id === id);
        self.keywordsList.splice(index, 1);
        self.modal = false;
      } catch (e) {
        notification.error('Something went wrong.', 'Please try again later.');
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    return {
      changeKeyword, loadKeywordsPublcList, setIsKeywordsModal, toggleOverview, createKeywords, createKeywordListPublic, updateKeyword, deleteKeyword, isDeleteModal, getKeywords, openModal, closeModal, resetOverviewData, startLoading, stopLoading, setKeywordListEmpty,
    };
  });

export type OverviewStoreInstance = Instance<typeof OverviewStore>;

export const initOverviewStore = () => {
  return {
    filters: {
      active: '' as Identifier,
      kd: null,
      sv: null,
      traffic: null,
      cpc: null,
      ppcd: null,
      query: '',
    },
    loading: true,
    open: false,
    modal: false,
    data: [],
    keywordsList: [],
    deleteModal: false,
    isKeywordsModal: false,
    maxLookupReached: false,
  };
};
