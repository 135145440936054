import React, {
  ReactNode,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
} from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'xs' | 'sm' | 'lg';
  loading?: boolean;
  outline?: boolean;
  buttonType?:
    | 'default'
    | 'primary'
    | 'success'
    | 'error'
    | 'purple'
    | 'pink'
    | 'transparent'
    | 'transparent-with-border'
    | 'light-gray'
    | 'super-light-gray'
    | 'blue-button'
    | 'blue-opaque'
    | 'purple-opaque'
    | 'green'
    | 'light-purple'
    | 'green-opaque'
    | 'purple-light'
    | 'green-dark'
    | 'gray';
  children?: ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  size,
  loading,
  className,
  buttonType = 'default',
  disabled = false,
  children,
  ...props
}) => {
  const classes = classnames(
    className,
    styles.button,
    'common-component-button',
    'button',
    {
      [styles.disabled]: disabled,
      [styles.buttonSizeLg]: size === 'lg',
      [styles.buttonSizeSm]: size === 'sm',
      [styles.buttonSizeXs]: size === 'xs',
      [styles.buttonTypePrimary]: buttonType === 'primary',
      [styles.buttonTypeSuccess]: buttonType === 'success',
      [styles.buttonTypeError]: buttonType === 'error',
      [styles.buttonTypePurple]: buttonType === 'purple',
      [styles.buttonTypePink]: buttonType === 'pink',
      [styles.buttonTypeTransparent]: buttonType === 'transparent',
      [styles.buttonTypeTransparentWithBorder]: buttonType === 'transparent-with-border',
      [styles.buttonTypeLightGray]: buttonType === 'light-gray',
      [styles.buttonTypeLightPurple]: buttonType === 'light-purple',
      [styles.buttonTypeBlue]: buttonType === 'blue-button',
      [styles.buttonTypeBlueOpaque]: buttonType === 'blue-opaque',
      [styles.buttonTypePurpleOpaque]: buttonType === 'purple-opaque',
      [styles.buttonTypePurpleLight]: buttonType === 'purple-light',
      [styles.buttonTypeSuperLightgray]: buttonType === 'super-light-gray',
      [styles.buttonTypeGreen]: buttonType === 'green',
      [styles.buttonTypeGreenOpaque]: buttonType === 'green-opaque',
      [styles.buttonTypeGreenDark]: buttonType === 'green-dark',
    },
  );

  return (
    <button disabled={disabled} className={classes} {...props}>
      {loading && (
        <span className={styles.buttonLoading}>
          <div className={styles.loaderSpinner} />
        </span>
      )}
      <span>{children}</span>
    </button>
  );
};

export interface ButtonListProps {
  children: ReactNode;
}

export const ButtonList: React.FC<ButtonListProps> = ({children}) => {
  return <div className={styles.buttonList}>{children}</div>;
};

export default Button;
