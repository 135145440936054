export const DETAIL_FILTER = [
  {id: 1, name: 'exclude', header: 'Search', filterField: 'search', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Contains', operator: 'contains'}]},
  {id: 2, name: 'contacts', header: 'Contacts', from: undefined, to: undefined, type: undefined, active: false},
  {id: 3, name: 'rating', header: 'DR', from: undefined, to: undefined, type: undefined, active: false},
  {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
  {id: 5, name: 'keywords', header: 'Keywords', from: undefined, to: undefined, type: undefined, active: false},
  {id: 6, name: 'ref-domains', header: 'Ref. Domains', from: undefined, to: undefined, type: undefined, active: false},
];

export const campaignFilters = (folder:any=[
]) => ([
  {id: 1, name: 'exclude', header: 'Campaign', filterField: 'search', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Contains', operator: 'contains'}]},
  {id: 2, name: 'radioButtonsFilter', header: 'Status', customCategoryType: 'status', filterTypes: [
    {label: 'Active', value: 'active'},
    {label: 'Paused', value: 'paused'},
  ], type: undefined, active: false},
  // {id: 3, name: 'page', header: '# of Page', from: undefined, to: undefined, type: undefined, active: false},
  {id: 3, name: 'resp-rate', header: 'Resp. Rate', from: undefined, to: undefined, type: undefined, active: false},
  {id: 4, name: 'radioButtonsFilter', header: 'Folder', customCategoryType: 'folder', filterTypes: folder, type: undefined, active: false},
  {id: 5, name: 'opportunities', header: 'Opportunities', from: undefined, to: undefined, type: undefined, active: false},
  {id: 6, name: 'emailed', header: 'Emailed', from: undefined, to: undefined, type: undefined, active: false},
  {id: 7, name: 'replies', header: 'Replies', from: undefined, to: undefined, type: undefined, active: false},
]);

export const DEFAULT_PARAMS = {
  page_size: 100,
  page: 1,
  ordering: '',
  search: '',
  type: '',
};
