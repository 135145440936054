import {Collapse, Spin, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {addProtocolToDomainHttps} from '@/utils/url';
import {StatusWrapper, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon, UrlWrapper, LandingPageButton} from '../../../style';
// import {getFilteredDataForOtto} from '../../tableIssuesCollapse';
// import {debounce} from 'lodash';
import {TableTopBar} from '../../tableTopBar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faCheckCircle, faCircleExclamation, faPen} from '@fortawesome/pro-solid-svg-icons';
import {openUrl} from '@/utils/router';
import {LandingPageGeneratorModal} from './LandingPageGeneratorModal';
import {getFilteredDataForOtto} from '../../tableIssuesCollapse';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../freezTooltip';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  setAddRecord: (value: any) => void;
  showConfirmationPopup: (value: any) => void;
  addRecord: any;
  deleteItem: any;
  issueTable: string;
}

const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#A3A4A4'}} spin />;

const statusIcons = {
  'Published': <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color='#2AC155' />,
  'Generated': <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color='#A3A4A4' />,
  'Publish stuck': <FontAwesomeIcon icon={faCircleExclamation} fontSize={20} color='#FF8536' />,
  'Publish failed': <FontAwesomeIcon icon={faCircleExclamation} fontSize={20} color='#FF8536' />,
  'Generation failed': <FontAwesomeIcon icon={faCircleExclamation} fontSize={20} color='#F44343' />,
  'Generating': <Spin indicator={antUrlIcon} />,
  'Pending': <Spin indicator={antUrlIcon} />,
  'Publishing': <Spin indicator={antUrlIcon} />,
};

export const LandingPageGenerator = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, showConfirmationPopup, deleteItem}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    ottoUrlLoader,
    ottoIssueType,
    ottoSearchTerm,
    setLandingPageDetail,
  }} = useStore('');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [openLandingPageModal, setOpenLandingPageModal] = useState(false);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [currentRecord, setCurrentRecord] = useState({});
  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => [componentIssueType].includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
  const filteredDataV2 = filteredData?.filter(item => item?.uuid !== deleteItem);
  const filteredFormattedData = ottoSearchTerm.trim() ? filteredDataV2?.filter(it => it?.pageName?.toLowerCase()?.includes(ottoSearchTerm?.toLowerCase()) || it?.city?.toLowerCase()?.includes(ottoSearchTerm?.toLowerCase())) : filteredDataV2;
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));


  const changeModalVisibility = (open, record) => {
    setOpenLandingPageModal(open);
    setCurrentRecord(record);
    setLandingPageDetail(record);
  };

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (!openLandingPageModal) {
      setLandingPageDetail({});
    }
  }, [openLandingPageModal]);

  const columns = [
    {
      title: <div className='column-title'>STATUS</div>,
      dataIndex: 'targetPage',
      key: 'targetPage',
      width: 125,
      render: (_, record) => {
        const status = record?.status === 'Generated' && !record?.cleanedHtml ? 'Generation failed' : record?.status;
        const isPublished = status === 'Published';
        return (
          <StatusWrapper
            status={isPublished}
            onClick={() => {
              if (['Generated', 'Published', 'Publish stuck', 'Publish failed'].includes(status)) {
                changeModalVisibility(true, record);
              }
            }}
          >
            {statusIcons[status]}
            <span style={{whiteSpace: 'nowrap'}}>{status === 'Generated' ? 'Publish' : status}</span>
          </StatusWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>PAGE TITLE</div>,
      dataIndex: 'name',
      key: 'name',
      width: 190,
      render: (_, record) => {
        return (
          <Tooltip overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title={record?.pageName || '-'}>
            <div style={{color: '#121212', fontSize: 13, fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 250, textOverflow: 'ellipsis', width: 'fit-content'}}>
              {record?.pageName}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <div className='column-title'>CITY</div>,
      dataIndex: 'city',
      key: 'city',
      width: 200,
      render: (_, record) => {
        return (
          <Tooltip overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title={record?.city || '-'}>
            <div style={{color: '#121212', fontSize: 13, fontWeight: 400, whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 250, textOverflow: 'ellipsis', width: 'fit-content'}}>
              {record?.city}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: <div className='column-title'>WORDPRESS URL</div>,
      dataIndex: 'wp-url',
      key: 'wp-url',
      width: 600,
      render: (_, record) => {
        return (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            {record?.wpPublishedAtUrl ? <UrlWrapper onClick={() =>openUrl(addProtocolToDomainHttps(record?.wpPublishedAtUrl), '_blank')} style={{maxWidth: '85%', textOverflow: 'ellipsis', overflow: 'hidden'}}>{record?.wpPublishedAtUrl}</UrlWrapper> : '-'}
            <div style={{display: 'flex', gap: 17, alignItems: 'center'}}>
              {(record?.status == 'Generating' || true) && <LandingPageButton type='button' onClick={() => changeModalVisibility(true, record)}>
                <FontAwesomeIcon icon={faPen} color='#4E5156' fontSize={14} />
                {['Generating', 'Pending'].includes(record?.status) ? 'View' : 'Edit'}
              </LandingPageButton>}
              <FreezeWrapper placement='topRight' removeTooltip={record?.status === 'Published' && record?.wpPublishedAtUrl}>
                <Tooltip overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title={'Delete'}>
                  <StyledTrashIcon icon={faTrash} onClick={()=> showConfirmationPopup(record)} fontSize={14} color='#A3A4A4'/>
                </Tooltip>
              </FreezeWrapper>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          // if (isTableOpen === 'close') {
          //   setIsTableOpen('open');
          //   setIsOpenSearch(false);
          // } else {
          //   setIsTableOpen('close');
          //   setIsOpenSearch(false);
          // }
        }}
      >
        <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
          inputPlaceholderText='Search by page title or city'
        />
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns}
            dataSource={filteredFormattedData}
            scroll={{x: 850}}
            pagination={{
              onChange: (page, pageSize) => {
                saveOttoTablePageSize(componentIssueType, pageSize);
                setPageSize(pageSize);
              },
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', '20', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} results shown`,
            }}
          />

        </Collapse.Panel>
      </StyledIssuesCollapse>
      {
        openLandingPageModal && <LandingPageGeneratorModal recordObj={currentRecord} openedFromTable openModal={openLandingPageModal} setOpenModal={setOpenLandingPageModal}/>
      }
    </>
  );
});
