import React, {useState, ReactNode} from 'react';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import styles from './index.module.scss';
import {useTranslation} from 'next-i18next';
import {Row, Col, Modal, Divider, Form} from 'antd';
import {CommonInput} from '@/components/common-components/components/index';
import {Typography, Button} from '@/components/common-components';
import {profileApi} from '@/api/account';
import {notification} from '@/utils/notifications';
import {getTokenFromCookies} from '@/api/common-utils';
import {ConfirmEmailModal} from '@/components/common-components/v2/confirmEmailModal';
import {useRouter} from 'next/router';
import {NextImg} from '@/utils/nextImg';

export const RegistrationModalSection: React.FC = observer(() => {
  const {settings: {customer: {loadProfile, profile: {isVerified, emailAddress}}}} = useStore('');
  const {t} = useTranslation('common');
  const router = useRouter();
  const [isEditEmailActive, setIsEditEmailActive] = useState(false);
  const [newEmailWasSent, setNewEmailWasSent] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [notVerified, setNotVerified] = useState(false);

  const onBadRequestError = (error: any) => {
    const errorData = error.response.data;
    const errorKeys = [
      'email', 'password',
      'confirmPassword', 'contactName',
      'phoneNumber', 'nonFieldErrors', 'detail',
    ];
    errorKeys.forEach(errorKey => {
      const errors = errorData[errorKey];
      if (errors) {
        let errorMessage = Array.isArray(errors) ? errors[0] : errors;
        if (!['nonFieldErrors', 'detail'].includes(errorKey)) {
          errorMessage = `${errorKey}: ${errorMessage}`;
        }
        notification.error(errorMessage);
      }
    });
  };

  const checkVerifiedStatus = async () =>{
    const token = getTokenFromCookies();
    if (token) {
      const data = await loadProfile();
      if (data?.isVerified) {
        localStorage.setItem('isEmailVerified', 'TRUE');
        setShowModal(false);
        if (router?.pathname?.includes('public')) {
          router?.push(`${router?.asPath?.replace('-public', '')?.replace('/public', '')}`);
        }
      } else {
        setNotVerified(true);
      }
    }
  };

  const [emailForm] = Form.useForm();

  const updateEmail = async ({updatedEmail}) => {
    try {
      const {success} = await profileApi.updateRegisterEmail(emailAddress, updatedEmail);
      if (success) {
        notification.success('Success! Check your inbox.');
        setIsEditEmailActive(false);
        setNewEmailWasSent(true);
        setNewEmail(updatedEmail);
      }
    } catch (error) {
      const status = error.response?.status || 200;
      if (status === 400 || status === 409) {
        onBadRequestError(error);
      } else {
        notification.error('Something went wrong. Try again later.');
      }
    }
  };

  const sendActivationCode = async () => {
    try {
      const {success} = await profileApi.resendVerificationEmail(emailAddress);
      if (success) {
        notification.success(t('check-inbox-verification'));
        setIsEditEmailActive(false);
      }
    } catch (error) {
      const status = error.response?.status || 200;
      if (status === 400 || status === 409) {
        onBadRequestError(error);
      } else {
        notification.error('Something went wrong. Try again later.');
      }
    }
  };

  const emailVerificationModalFooter: ReactNode = (
    <Row gutter={24} className={styles.footerContainer}>
      <Col span={24}>
        <Divider />
      </Col>
      <Col span={24}>
        {!isEditEmailActive ?
          <div style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}>
            <div>Didn’t receive? Check spam folder or&nbsp;</div>
            <Button buttonType={'transparent'} style={{color: '#2D6CCA', border: 'none'}} className={styles.next} onClick={() => sendActivationCode()}>
                Send email again
            </Button>
          </div> :
          <Button buttonType={'primary'} className={styles.next} onClick={() => emailForm.submit()}>
            Update & Verify Email
          </Button>
        }
      </Col>
      <Col span={24} className={styles.back}>
        {!isEditEmailActive ?
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span>Wrong email? </span>
            <Button buttonType={'transparent'} style={{color: '#2D6CCA', border: 'none', backgroundColor: 'transparent', paddingLeft: '5px'}} onClick={() => setIsEditEmailActive(true)}>
                        Update the email address
            </Button>
          </div> :
          <Button buttonType={'transparent-with-border'} style={{color: '#4E5156', borderColor: '#e8e8e8'}} onClick={() => {
            setIsEditEmailActive(false);
          }}>
                    Go back
          </Button>
        }
      </Col>

    </Row>
  );

  const emailVerificationModal = (
    <Modal
      centered
      visible={showModal}
      closable={false}
      className={styles.emailVerificationModal}
      footer={emailVerificationModalFooter}
    >
      {/* <LogoutButton /> */}
      <div className={styles.emailVerificationModalHeader}>
        <Row gutter={24}>
          <Col span={24}>
            <NextImg src='/img/icon/email.svg' />
          </Col>
        </Row>
        {!isEditEmailActive ?
          <ConfirmEmailModal email={!newEmailWasSent ? emailAddress : newEmail} onBadRequestError={onBadRequestError} checkVerifiedStatus={checkVerifiedStatus} notVerified={notVerified}/> :
          <Row gutter={24} className={styles.welcomeText}>
            <Col span={24}>
              <Typography tag={'h1'}>Update email address</Typography>
              <Form form={emailForm} onFinish={updateEmail}>
                <Form.Item
                  name={'updatedEmail'}
                  required
                >
                  <CommonInput
                    inputType='email'
                    isRequired={true}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        }
      </div>
    </Modal>
  );

  return (
    <div className={styles.contentContainer}>
      {!isVerified && emailAddress ? emailVerificationModal : null}
    </div>
  );
});

export default RegistrationModalSection;
