import {
  Typography,
} from '@/components/common-components/components/index';

import styles from './styles.module.scss';
import React from 'react';

export type TabPanelProps = {
    icon?: React.ReactNode;
    heading?: string;
    description?: string;
    selected?: boolean;
    selectionClass?: string;
};

export const TabPanel: React.FC<TabPanelProps> = ({
  icon,
  heading,
  description,
  selected,
  selectionClass,
}) => {
  return (
    <div className={styles.TabList}>
      <div className={selected ? selectionClass : styles.icon}>{icon}</div>
      <div>
        <Typography style={{fontWeight: 400}} tag='p' className={selected && styles.selectedTab}>
          {heading}
        </Typography>
        <span>{description}</span>
      </div>
    </div>
  );
}
;
