import {Modal} from '@/components/common-components/components/modal';
import {Button, GrayButton} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {faXmark, faLinkSlash} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {integrationApi} from '@/api/gsc';
import React, {useEffect, useState} from 'react';
import {Divider, Tooltip, notification as antdNotification} from 'antd';
import {notification} from '@/utils/notification-v2';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {ProjectSelector} from '../../../dashboard/pages/report-builder/components/projectSelector/ProjectSelector';

import {getDomain} from '@/utils/url';
import {LoadingOutlined} from '@ant-design/icons';
import {apiError} from '@/utils/api';

interface Props {
  saData?: any;
}

export const ManageGoogleAnalyticsData:React.FC<Props> = observer(({saData}) => {
  const {gsc: {
    googleAnalytics: {
      setGaDataModal,
      dataCleanup,
      gaDataModal,
      accountsDataV2,
      postAllSettings,
      setGaLoading,
      gaLoading,
    },
  }, navBar: {loadSitePropertiesNoLoaders},
  settings: {
    integration: {
      getGoogleProfiles,
    },
  },
  reportBuilder: {details: {loadReportBuilderGAList, getReportsGAList, disconnectGa}},
  siteAuditorV2: {setGscOpenModalValue, isSiteAudit, saIdForGscSettings, siteAuditConnectGsc, setSpecificIDResponse, loadAuditedSitesList, loadAuditedSitesListBasic},
  } = useStore('');

  const findConnectedEmail = getGoogleProfiles?.find(z => z?.isAuthorizedForAnalytics);

  // email selected in dropdown
  const [selectedEmail, setSelectedEmail] = useState<string>(accountsDataV2?.length > 0 ? accountsDataV2[0]?.email : findConnectedEmail?.email);

  // account selected in dropdown
  const [selectedAccount, setSelectedAccount] = useState<{name?: string; id?: number}>({
    name: null,
    id: null,
  });

  // property selected in dropdown
  const [selectedProperty, setSelectedProperty] = useState<any>({
    name: null,
    id: null,
    siteAuditId: null,
  });

  const antIcon = <LoadingOutlined style={{fontSize: 16, marginLeft: '70px'}} spin />;

  const [gaStatusChanged] = useState(false);
  const [loadingGaDisconnect, setLoadingGaDisconnect] = useState(false);

  const domainSiteUrl = getDomain(gaDataModal.siteUrl);
  const formatSiteUrl = domainSiteUrl?.startsWith('http://') || domainSiteUrl?.startsWith('https://') ? domainSiteUrl : `http://${domainSiteUrl}`;

  const getInitialSetupHandler = async () => {
    setGaLoading(true);
    const res = await loadReportBuilderGAList();
    if (res) {
      const selectedProp = res?.find(item => item.propertyId == gaDataModal?.activatedGaPropertyId || item.hostname == gaDataModal?.siteUrl?.split(':')[1]);
      if (gaDataModal?.settingId) {
        setSelectedProperty({
          name: selectedProp?.hostname,
          id: selectedProp?.propertyId || 'None',
          siteAuditId: gaDataModal?.settingId || null,
        });
      }
    }
    setGaLoading(false);
  };

  // Calling initial setup handler on GA modal visibility change
  useEffect(() => {
    if (gaDataModal.visible) {
      getInitialSetupHandler();
    }
  }, [gaDataModal.visible]);

  // On Property select, show views selection from that account
  const selectPropertiesHandler = async value => {
    if (value) {
      const selectedProp = gaSitesList?.find(item => item.propertyId === value);
      setSelectedProperty({
        name: selectedProp?.hostname,
        id: value,
      });
    }
  };

  // Doing full cleanup on modal close
  const onCloseAction = (refresh?: boolean) => {
    dataCleanup();
    setSelectedAccount({
      name: null,
      id: null,
    });
    setSelectedProperty({
      name: null,
      id: null,
      siteAuditId: null,
    });
    setSelectedEmail(null);
    setGscOpenModalValue(false);
    setGaDataModal({
      ...gaDataModal,
      visible: false,
      siteUrl: '',
      gaConnected: false,
      settingId: null,
      settingsEnabled: false,
      hasSiteGaCredentials: null,
      activatedGaPropertyId: null,
    });
    if (gaStatusChanged || refresh) loadSitePropertiesNoLoaders();
  };
  const [gaSitesList, setGASitesList] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const onGASearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value?.toLowerCase();
    setInputValue(value);
    if (value) {
      setGASitesList(getReportsGAList.filter(option => option?.hostname?.toLowerCase()?.includes(value) || option?.name?.toLowerCase()?.includes(value)));
    } else {
      setGASitesList(getReportsGAList);
    }
  };
  useEffect(()=> {
    setGASitesList(getReportsGAList);
  }, [getReportsGAList]);

  const handleDisconnect = async () => {
    try {
      if (gaDataModal?.settingId) {
        setLoadingGaDisconnect(true);
        await disconnectGa(gaDataModal?.settingId);
        notification.success('', `Successfully disconnected GA4 from ${gaDataModal.siteUrl}`);
        onCloseAction(true);
        setLoadingGaDisconnect(false);
      } else {
        antdNotification.error({placement: 'topRight', message: 'Required setting id is not found'});
      }
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage || 'Failed to disconnect GA account', false, 'OK');
    }
  };

  const gaSettingsFields = (<>
    <SingleSection>
      <SectionLabel>Property</SectionLabel>
      <SectionAction>
        {!gaLoading ?
          <ProjectSelector
            property={'GA'}
            keyValue={'propertyId'}
            searchValue={inputValue}
            selectedId={selectedProperty?.id}
            onProjectChange={selectPropertiesHandler}
            dropDownWidth={`${gaDataModal.gaConnected ? '260px' : '300px'}`}
            isReportBuilder={true}
            sitePropertiesList={gaSitesList}
            onSearch={onGASearchHandler}
          />:
          <SkeletonHorizontalLoaderGray
            lightLoader
            skeletonHeight={'12px'}
            width={200}
          />
        }
      </SectionAction>
      {loadingGaDisconnect ? antIcon : gaDataModal?.settingId &&
      <Tooltip title={`Disconnect GA4 from ${gaDataModal?.siteUrl}`}>
        <DisconnectGa4 onClick={handleDisconnect}>
          <FontAwesomeIcon icon={faLinkSlash} color='#4E5156' fontSize={14}/>
        </DisconnectGa4>
      </Tooltip>
      }
    </SingleSection>
    {!accountsDataV2?.length ? <Button variant={'solid'}
      color='blue'
      style={{margin: '10px 0 0 auto', width: 300, display: 'flex', justifyContent: 'center'}}
      onClick={() => window.open(integrationApi.getGoogleAnalyticsConnectUrl(), '_self')}>
          + Connect new GA 4 account
    </Button> : <GrayButtonStyled color={'transparent'}
      variant='noBorder'
      style={{margin: '10px 0 0 auto', width: 200, display: 'flex', justifyContent: 'center', color: '#2D6CCA', fontSize: 12}}
      onClick={() => window.open(integrationApi.getGoogleAnalyticsConnectUrl(), '_self')}>
          + Connect new GA 4 account
    </GrayButtonStyled>}

    <Divider style={{margin: '20px 0'}}/>
    <ButtonsContainer>
      <GrayButton style={{margin: '0px 0px 0px 10px'}} onClick={() => {
        onCloseAction();
      }}>Cancel</GrayButton>
      <Button style={{margin: '0px 0px 0px 10px'}}
        disabled={!selectedProperty.name}
        onClick={async () => {
          if (isSiteAudit) {
            const payloadData = {
              ga_property_id: selectedProperty?.id,
              property_url: selectedProperty?.name,
              country_code: saData?.country,
            };
            await siteAuditConnectGsc(saIdForGscSettings, payloadData);
            onCloseAction();
            setSpecificIDResponse(saIdForGscSettings);
            loadAuditedSitesList(true);
            loadAuditedSitesListBasic();
            antdNotification.success({placement: 'bottomRight', message: 'GSC Settings Updated successfully.'});
          } else {
            postAllSettings({
              accountId: selectedAccount.id,
              propertyId: selectedProperty.id,
              selectedEmail: selectedEmail,
            });
          }
          loadSitePropertiesNoLoaders();
          onCloseAction();
        }}>Update Settings</Button>
    </ButtonsContainer>
  </>);

  return <ModalStyled visible={gaDataModal.visible}
    destroyOnClose={true}
    afterClose={() => onCloseAction()}
    onClose={() => onCloseAction()}
    closeIcon={<FontAwesomeIcon icon={faXmark} color='#fff' fontSize={20}/>}>
    <Title>Google Analytics 4 Settings</Title>
    <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
      <Url onClick={() => window.open(formatSiteUrl, '_blank')}>{gaDataModal.siteUrl}</Url>
      <div style={{marginLeft: 10, fontSize: 12, color: '#4e5156', marginTop: 4}}>{gaDataModal?.gaConnected ? <div>
        {gaDataModal?.hasSiteGaCredentials ?
          <span>GA 4 is connected</span> :
          // if hasSiteGaCredentials is false, that means that GA permissions were revoked and user needs to fix it so we show diferent view
          <Tooltip title='Connected GA account does not have permissions to access the selected site.'>
            <div style={{whiteSpace: 'nowrap'}}>Permissions revoked</div>
          </Tooltip>}
      </div> : 'GA 4 is not connected'}</div>
    </div>
    {gaSettingsFields}
  </ModalStyled>;
});

const ModalStyled = styled(Modal)`
  margin-top: auto;
  margin-bottom: auto;
  width: 450px;

  .rc-dialog-close {
    right: -30px;
    top: 0;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #121212;
`;

const DisconnectGa4 = styled.span`
  margin-left: 70px;
  cursor: pointer;
`;

const Url = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #2D6CCA;
  cursor: pointer;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;
const GrayButtonStyled = styled(GrayButton)`
  &:hover {
    background: transparent !important;
  }
`;

const SingleSection = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
`;
const SectionLabel = styled.div`
  color: #4E5156;
  display: flex;
  align-items: center;
  margin-right: 31px;
`;
const SectionAction = styled.div`
  width: 200px;
  height: 38px;
  display: flex;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
