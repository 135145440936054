import classnames from 'classnames';
import CsvDownloader, {ICsvDownloadProps} from 'react-csv-downloader';
import {SelectOutlined} from '@ant-design/icons';

import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import React from 'react';

export interface DataExporterProps extends ICsvDownloadProps {
  label?: string;
  className?: string;
}

export const DataExporter: React.FC<DataExporterProps> = ({
  label,
  className,
  ...props
}) => {
  const {t} = useTranslation('common');


  return (
    <div className={classnames(styles.dataExporter, className)}>
      <SelectOutlined/>
      <CsvDownloader {...props} className={styles.button}>{label ? label : t('data-exporter-export-data')}</CsvDownloader>
    </div>
  );
};
