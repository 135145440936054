import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {AiFirstDraftGenerator} from './AiFirstDraftGenerator/AiFirstDraftGenerator';
import {ContentIdeaForm} from './contentIdeaForm';

interface Props {
  showPageTitle?: boolean;
  setShowPageTitle?: (value: boolean) => void;
}


/**
 * Controller that switches between default Content Idea form and First draft generator
 *
 * @param {boolean} showPageTitle used for default forms
 * @param setShowPageTitle method to set show page title boolean from defualt forms
 */
export const DefaultForms = observer(({showPageTitle, setShowPageTitle}: Props) => {
  const {contentOptimizer: {
    aiOutline: {
      aiTemplatesController,
      isOpenAiOutlineDrawer,
    }, currentPage,

  }} = useStore('');

  return aiTemplatesController?.defaultFormType == 'ContentIdea' ?
    <ContentIdeaForm
      drawerVisible={isOpenAiOutlineDrawer}
      pageId={currentPage.content.uuid}
      pageTitle={currentPage.content.title}
      showPageTitle={showPageTitle}
      setShowPageTitle={setShowPageTitle}/> :
    <AiFirstDraftGenerator />;
});
