import {getAuthHeader, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';

export interface keywordListPayload {
  keywordlistId: string;
  keywords: string[];
  countryCode: string;}

export interface KeywordCreatePayload {
  name: string;
  keywords: string[];
  count: number;
  countryCode: string;
  isRecentSearch: boolean;
}
export interface KeywordUpdatePayload {
  id: number;
  name: string;
  keywords: string[];
  count: number;
  countryCode: string;
  isRecentSearch: boolean;
}

export interface keywordDetailPayload {
  keyword: string;
}

export enum TaskStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

class OverviewApi extends BaseApi {
  public async findKeywordList(keywords) {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/find_keywords/`, keywords, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async findKeywordListForPublic(data) {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/v1/keywordslist/${data?.id}/?hash=${data?.publicHash}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async postKeywordlist(keywords) {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/`, keywords, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postSingleKeyword(keywords) {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/`, keywords, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getKeywordlist() {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/v1/keywordslist/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateKeywordlist(data) {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.put(`/v1/keywordslist/${data.id}/`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteKeywordlist(id) {
    try {
      const response: AxiosResponse<keywordDetailPayload> = await this.axios.delete(`/v1/keywordslist/${id}/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postKeywordDetail(keyword, countryCode, locationId, excludeKd, siteProperty?, publicShareHash?, refetchSerps?) {
    // if we have locationId use that, if not use countryCode as param
    const locationProp = locationId ? {'location_id': locationId} : {'countrycode': countryCode};
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    const params = {
      'query': keyword,
      'exclude_kd': excludeKd ? excludeKd : 0,
      'site_property': siteProperty,
      'exclude_groupings': 0,
      'exclude_similarideas_kd': 0,
      'exclude_sca': 0,
      ...locationProp,
    };
    if (publicShareHash) {
      params['hash'] = publicShareHash;
    }
    if (refetchSerps) {
      params['serp_freshness_days'] = 0;
    }
    try {
      const response = await this.axios.get('/v1/keyword_details', {
        headers: headers,
        cancelToken: this.cancelToken,
        params: params,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async refreshSerpsRecords() {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
    }
    const params = {
      'serp_freshness_days': 0,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        headers: headers,
        cancelToken: this.cancelToken,
        method: 'GET',
        url: '/v1/keyword_details',
        params: params,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCountriesList(queryParam?: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        method: 'GET',
        url: '/v1/geolocation_suggestions',
        params: {
          q: queryParam,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getCountryData(id) {
    try {
      const {data}: AxiosResponse = await this.axios({
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        method: 'GET',
        url: `/v2/keyword-research/hyperlocation/?location_id=${id}`,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default OverviewApi;
