import {useStore} from '@/store/root-store';
import {CaretDownOutlined} from '@ant-design/icons';
import {Dropdown, Form, Select} from 'antd';
import {observer} from 'mobx-react-lite';
import styles from '../style.module.scss';
import moment from 'moment';
import React, {useState, useEffect} from 'react';
import Flag from '@/components/common-components/components/flag';
import {Globe} from 'react-feather';
import {FilterTag, DropdownOverlay, PopupItemStyled} from '../styledComponents';
import {NextImg} from '@/utils/nextImg';

interface Props {
  onDateChange?: (startDate, endDate, countryCode) => void;
}

export const TableDateRangePickerV2: React.FC<Props> = observer(({onDateChange}) => {
  const {
    countries: {getSpecificCountryByCode},
    gsc: {criteria}, navBar,
  } = useStore('');
  const currentProperty = navBar.getSiteProperty(criteria?.property);
  const countriesData = currentProperty?.activeCountryCodes.map(item => ({
    code: item,
    ...getSpecificCountryByCode(item),
  }));
  const [countryForm] = Form.useForm();
  const {Option} = Select;

  const [startDatePopup, setStartDatePopup] = useState(false);
  const [endDatePopup, setEndDatePopup] = useState(false);
  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');
  useEffect(() => {
    if (criteria.property) {
      setStartDateString(criteria.currentPeriodStart);
      setEndDateString(criteria.currentPeriodEnd);
      countryForm.setFieldsValue({selectedCountry: criteria.countryCode});
    }
  }, [criteria.property, criteria.countryCode, criteria.previousPeriodEnd, criteria.previousPeriodStart, criteria.currentPeriodEnd, criteria.currentPeriodStart]);

  // const getMonthFirstDate = value =>{
  //   const selectedValue = new Date(value);
  //   return moment(new Date(selectedValue.getFullYear(), selectedValue.getMonth(), 1)).format('YYYY-MM-DD');
  // };
  // const getMonthEndDate = value =>{
  //   const selectedValue = new Date(value);
  //   return moment(new Date(selectedValue.getFullYear(), selectedValue.getMonth() + 1, 0)).format('YYYY-MM-DD');
  // };

  const onLatestDateChange = e => {
    const value = e.target.dataset?.value;
    setEndDatePopup(false);
    const isValidDate = moment(value).isAfter(startDateString);
    setEndDateString(value);

    if (!isValidDate) {
      const updatedDate = value;
      setStartDateString(updatedDate);
      onDateChange(updatedDate, value, null);
    } else {
      onDateChange(null, value, null);
    }
  };

  const onEndDateChange = e => {
    const value = e.target.dataset?.value;
    setStartDatePopup(false);
    setStartDateString(value == '' ? criteria?.currentPeriodEnd : value);
    const isValidDate = moment(endDateString).isAfter(value);
    if (!isValidDate) {
      const updatedDate = value;
      setEndDateString(updatedDate);
      onDateChange(value, updatedDate, null);
    } else {
      onDateChange(value, null, null);
    }
  };

  const getMonths = () => {
    const months = [];
    const monthsRequired = 12;

    for (let i = monthsRequired; i >= 1; i--) {
      months.push( moment().subtract(i, 'months').format('YYYY-MM-DD') );
    }
    return months?.reverse();
  };

  const currentMonth = new Date().getMonth() + 1 == 12;
  const formatStartDate = moment(endDateString)?.format('MM-DD-YYYY');
  const formatedStartDate = new Date(formatStartDate);

  const daysInterval = [
    {name: 'Previous day', value: moment(formatedStartDate).subtract(1, 'days').format('YYYY-MM-DD')},
    {name: 'Previous week', value: moment(formatedStartDate).subtract(6, 'days').format('YYYY-MM-DD')},
  ];
  const intervals = [
    {name: 'Previous month', value: moment(formatedStartDate).subtract(1, 'months').format('YYYY-MM-DD')},
    {name: 'Previous 3 months', value: moment(formatedStartDate).subtract(3, 'months').format('YYYY-MM-DD')},
    {name: 'Previous 6 months', value: moment(formatedStartDate).subtract(6, 'months').format('YYYY-MM-DD')},
    {name: 'Previous Year', value: moment(formatedStartDate).subtract(1, 'years').format('YYYY-MM-DD')},
  ];

  const onCountryChange = value => {
    onDateChange(null, null, value);
  };

  return (
    <div style={{display: 'flex', gap: '10px'}} >
      <div>
        <Form form={countryForm}>
          <Form.Item name={'selectedCountry'} style={{marginBottom: 0}} className={styles.countrySelectStyles}>
            <Select onSelect={onCountryChange} dropdownMatchSelectWidth={false} suffixIcon={<CaretDownOutlined />} defaultValue={criteria?.countryCode} bordered={false}>
              {countriesData?.map((item, index) => {
                return <Option key={`country-index-${index}`} value={item?.code} >
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {item?.code == '' ? <Globe style={{width: '15px'}}/> :
                      <Flag width={18} code={item?.code}/>}
                    <span style={{marginLeft: '5px', lineHeight: '23px'}}>{!item?.name ? 'Worldwide' : item?.name}</span>
                  </div>
                </Option>;
              })}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <Dropdown
        trigger={['click']}
        overlay={<DropdownOverlay>
          {daysInterval.map(item=>{
            return <PopupItemStyled key={`day-interval-${item.value}`} data-value={item.value}
              onClick={onEndDateChange}>{item.name}</PopupItemStyled>;
          })}
          {intervals.map((item, idx)=>{
            const date = new Date(item.value);
            return <PopupItemStyled key={idx} data-value={date}
              onClick={onEndDateChange}>{item.name}</PopupItemStyled>;
          })}
          {!currentMonth && <div style={{borderTop: '1px solid #e8e8e8', fontWeight: 'bold', marginTop: 10, padding: '6px 0 4px 15px'}} >{new Date().getFullYear()}</div>}
          {getMonths()?.map(item => {
            const lastMonth = new Date(item).getMonth() + 1 == 12;
            const date = new Date(item);
            const monthFirstDate = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
            const month = date.toLocaleString('default', {month: 'long'});
            if (lastMonth) {
              return <><div style={{borderTop: '1px solid #e8e8e8', fontWeight: 'bold', marginTop: 10, padding: '6px 0 4px 15px'}} key={`first-${monthFirstDate}`} data-value={monthFirstDate}>{monthFirstDate?.split('-')[0]}</div>
                <PopupItemStyled key={`second-${monthFirstDate}`} data-value={monthFirstDate} onClick={onEndDateChange}>{month}</PopupItemStyled></>;
            } else {
              return <PopupItemStyled key={`third-${monthFirstDate}`} data-value={monthFirstDate} onClick={onEndDateChange}>{month}</PopupItemStyled>;
            }
          })}

        </DropdownOverlay>}
        onVisibleChange={val => setStartDatePopup(val)}
        visible={startDatePopup}
      >
        <FilterTag onClick={e => e.preventDefault()}>
          <NextImg style={{margin: '0 5px 2px 0', height: 25, width: 25}} src='/icons/calender-icon-dark.svg' /> <div>
          Date Range: {moment(startDateString)?.format('DD MMM YYYY')}
          </div>&nbsp;<CaretDownOutlined />
        </FilterTag>
      </Dropdown>
      <Dropdown
        trigger={['click']}
        overlay={<DropdownOverlay>
          <PopupItemStyled data-value={moment().format('YYYY-MM-DD')} onClick={onLatestDateChange}>Today</PopupItemStyled>
          {!currentMonth && <div style={{borderTop: '1px solid #e8e8e8', fontWeight: 'bold', marginTop: 10, padding: '6px 0 4px 15px'}} >{new Date().getFullYear()}</div>}
          {getMonths()?.map(item => {
            const date = new Date(item);
            const lastDate = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');
            const lastMonth = new Date(lastDate).getMonth() + 1 == 12;
            const month = date.toLocaleString('default', {month: 'long'});
            if (lastMonth) {
              return <><div style={{borderTop: '1px solid #e8e8e8', fontWeight: 'bold', marginTop: 6, padding: '6px 0 0 15px'}} key={`last-month-day-${lastDate}`} data-value={lastDate}>{lastDate?.split('-')[0]}</div>
                <PopupItemStyled key={`last-month-${lastDate}`} data-value={lastDate} onClick={onLatestDateChange}>{month}</PopupItemStyled></>;
            } else {
              return <PopupItemStyled key={lastDate} data-value={lastDate} onClick={onLatestDateChange}>{month}</PopupItemStyled>;
            }
          })}

        </DropdownOverlay>}
        onVisibleChange={val => setEndDatePopup(val)}
        visible={endDatePopup}
      >
        <FilterTag onClick={e => e.preventDefault()}>
          <div>{moment(endDateString)?.format('DD MMM YYYY')}</div>&nbsp;<CaretDownOutlined />
        </FilterTag>
      </Dropdown>
    </div>
  );
});
