import {KeywordsApi} from '@/api/content-researcher/keywords-api';
import {getApiUrl} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

export enum ContentStatus {
  Pending = 'PENDING',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export const CONTENT_RESEARCHER_API = new KeywordsApi(getApiUrl(BaseApi.CA_ENDPOINT, '/api'));
