import {Checkbox, Collapse, Form, Input, Radio, Select} from 'antd';
import styled from 'styled-components';
import {LandingPageButton} from '../../../style';
import {Button} from '@/components/common-components/v2';
import PhoneInput from 'react-phone-input-2';

export const Label = styled.div`
  margin-bottom: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #121212;
`;

export const DownloadButton = styled(LandingPageButton)`
  width: 152px;
  height: 32px;
  border-radius: 6px;
  font-size: 13px;
  color: #121212;
  background-color: #fff;
  transition: background-color 700ms;
  &:hover {
    background-color: #f7f3f3;
  }
`;

export const StyledForm = styled(Form)`
  margin-top: 19px;
  flex-grow: 1;
  .ant-select-selector {
    border: 1px solid #D2D2D2 !important;
  }
  .ant-form-item {
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .ant-form-item-label {
      text-align: left;
      & > label {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        color: #121212;
      }
    }
  }
  overflow-y: hidden;
  .wrapper {
    padding-right: 11px;
    height: 100%;
    overflow-y: auto;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    padding-left: 4px;
  }
            
  &::-webkit-scrollbar-thumb {
    background: rgba(78, 81, 86, 0.4);
    border-radius: 12px;
    height: 120px;
    width: 6px;
  }
            
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px
`;

export const StyledInput = styled(Input)<{height?: string; br?: string}>`
  width: 100%;
  height: ${p => p?.height || '44px'};
  border: 1px solid #D2D2D2;
  border-radius: ${p => p?.br || '8px'};
`;

export const LeftSection = styled.div`
  width: 36%;
  max-width: 500px;
  min-width: 400px;
  padding: 35px 22px 23px 30px;
  background: #FCFCFC;
  z-index: 10;
  border-right: 1px solid #E8E8E8;
  box-shadow: 2px 0px 34px 0px #00000012;
  display: flex;
  flex-direction: column;
  .add-btn {
    cursor: pointer;
    color: #2D6CCA;
    font-size: 12px;
  }
  .wp-wrapper {
    overflow-y: auto;
    padding-right: 10px;
    flex-grow: 1;
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      padding-left: 4px;
    }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(78, 81, 86, 0.4);
      border-radius: 12px;
      height: 120px;
      width: 6px;
    }
            
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;

export const Heading = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  color: #121212;
`;
export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #4E5156;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 44px;
    border-radius: 8px !important;
  }
`;

export const PublishButton = styled(Button)<{width?: string; height?: string; br?: string}>`
  width: ${p => p?.width || '178px'};
  height: ${p => p?.height || '38px'};
  border-radius: ${p => p?.br || '6px'};
  font-size: 14px;
  color: #fff;
  background-color: #7F4EAD;
  border: none !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
`;


export const StyledSelectV2 = styled(Select)`
  .ant-select-selector {
    border-radius: 4px !important;
    border: 1px solid #D2D2D2 !important;
    height: 32px !important;
  }

  .ant-select-dropdown{
    top: 34px !important;

    &.max-h-200 {
      max-height: 200px !important;
    }
  }
`;

export const HiddenColorPicker = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  border: none;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  white-space: nowrap;
`;

export const ColorButton = styled.button<{color: string}>`
  background-color: ${props => props.color || '#000'};
  border: 2px solid #ccc;
  width: 22px;
  height: 22px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  &:hover {
    border-color: #999;
  }
`;

export const SurpriseColor = styled.div<{selected?: boolean}>`
  width: 125px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 8px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  ${p => p?.selected ? `
    border: 1px solid #D2D2D2;
    box-shadow: 0px 3px 2px 0px #00000005;
  ` : ''}
`;

export const ColorPickerWrapper = styled.div<{selected?: boolean}>`
  ${p => p?.selected ? `
    border: 1px solid #D2D2D2;
    box-shadow: 0px 3px 2px 0px #00000005;
    min-width: 125px;
    height: 32px !important;
    padding-right: 6px;
    background: #FFFFFF;
    border-radius: 8px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    & > div {
      height: 22px !important;
    }
  ` : ''}
  .color {
    font-weight: 700;
  }
`;

export const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  button {
    border: none !important;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 130px;
  border-radius: 8px;
  border: 1px solid #D2D2D2 !important;
  outline: none !important;
  padding: 8px 22px 8px 15px;
  resize: none !important;
  &::placeholder {
    line-height: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.30);
  }
`;

export const WordpressSection = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 12px;
  padding: 0;
  .wp-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #D2D2D2 !important;
    box-shadow: none !important;
  }
  .ant-form-item.wp-field {
    margin-bottom: 0 !important;
    input, .ant-select-selector {
      border: 1px solid #D2D2D2;
      border-radius: 0 !important;
    }
    &:first-child {
      input, .ant-select-selector {
        border-top-right-radius: 8px !important;
        border-top-left-radius: 8px !important;
        border-bottom: none !important;
      }
    }
    &:last-child {
      input, .ant-select-selector {
        border-bottom-right-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }
    }
  }
`;

export const RadioGroup = styled(Radio.Group)`
  .ant-radio-inner {
    background-color: transparent !important;

    &:hover {
      border-color: #7F4EAD !important;
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #7F4EAD !important;
      border-color: #7F4EAD !important;

      &::after {
        background-color: #fff;
      }
    }
  }
`;

export const WpForm = styled(Form)`
  margin-top: 0;
  .ant-form-item {
    margin-bottom: 10px !important;
    .ant-form-item-label > label {
      color: #4E5156;
      font-size: 12px;
      &::before, &::after {
        display: none !important;
      }
    }
    justify-content: space-between;
    .ant-form-item-control {
      flex-grow: 1;
      max-width: 300px;
    }
  }
  .ant-form-item-label {
    & > label {
      min-width: 100px;
    }
  }
`;

export const KGExpand = styled.span`
  color: #2D6CCA;
  font-size: 12px;
  cursor: pointer;
`;

export const CollapseWrapper = styled.div`
  .ant-collapse {
    border: none !important;
  }
  .support-phone-input {
    width: 100% !important;
    height: 30px !important;
    border-radius: 8px;
  }
`;

export const StyledPhoneInput = styled(PhoneInput)<{width?: string}>`
  .form-control {
    width: ${p => p?.width ? p.width : ''};
  }
  .country-list {
    max-height: 145px !important;
  }
`;

export const KGBanner = styled.div<{completed?: boolean; rounded?: boolean}>`
  min-height: 35px;
  width: 100%;
  background: ${p => p?.completed ? 'rgba(42, 193, 85, 0.2)' : 'rgba(244, 67, 67, 0.2)'} !important;
  border-radius: ${p => p?.rounded ? '12px' : '0px'};
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  .inner {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export const KGFields = styled.div`
  border-radius: 12px;
  border: 1px solid #D2D2D2;
  overflow: hidden;
  .ant-form-item-explain {
    display: none !important;
  }
  #kg-phone {
    &:has(.ant-form-item-explain-connected) {
      input {
        border: 1px solid #ff4d4f !important;
      }
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  .ant-collapse-item, .ant-collapse-content {
    border: none !important;
  }
  .ant-collapse-header {
    background: #FCFCFC !important;
    padding-inline: 0 !important;
    padding-bottom: 10px !important;
  }
  .ant-collapse-content-box {
    background: #FCFCFC !important;
    padding: 0 !important;
  }
  .ant-collapse-arrow {
    right: 0 !important;
  }
`;

export const RightSection = styled.div`
  flex-grow: 1;
  background-color: #F9F9FB;
  @media screen and (max-width: 1340px) {
    max-width: 64%;
  }
  .tablet {
    max-width: 600px !important;
    margin-inline: auto !important;
  }
  .mobile {
    max-width: 360px !important;
    margin-inline: auto !important;
  }
  .ant-skeleton {
    line-height: 0 !important;
  }
  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-nav {
      margin: 0 0 8px 0 !important;
      background: #fff;
      min-height: 60px;
      &::before {
        border-bottom: 1px solid #D9D9D9 !important;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab:nth-child(1) {
            margin-left: 13px;
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-size: 14px !important;
              font-weight: 500;
              line-height: 16.94px;
              color: #121212;
            }
          }
          .ant-tabs-ink-bar {
            background-color: #7F4EAD !important;
            height: 6px !important;
            border-radius: 5px 5px 0px 0px !important;
          }
          .ant-tabs-tab {
            margin: 0 0 0 20px !important;
            padding-right: 12px;
            padding-left: 12px;
            .ant-tabs-tab-btn {
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 14.52px;
              color: #4E5156;
            }
            &:hover {
              color: #4E5156 !important;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      .ant-tabs-content {
        height: 100% !important;
        .ant-tabs-tabpane {
          .input-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .label {
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-weight: 400;
              color: #4E5156;
            }
            .ant-col {
              .text-area {
                height: 75px;
                border: 1px solid #D2D2D2;
                border-radius: 8px;
                resize: none;
                &:hover {
                  border: 1px solid #D2D2D2 !important;
                }
              }
              .email-input {
                height: 38px;
                border: 1px solid #D2D2D2;
                border-radius: 8px;
              }
              .react-tel-input {
                .support-phone-input {
                  width: 100% !important;
                  height: 38px !important;
                  border-radius: 8px;

                }
                .flag-dropdown {
                  border-radius: 8px 0 0 8px !important;
                }
              }  
            }
          }
        }
      }
    }
`;

export const UpdateButton = styled(Button)`
  color: #fff;
  height: 32px;
  width: 93px;
  background: #2AC155 !important;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 22px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  &:hover, &:focus {
    background: #2AC155 !important;
    border: none !important;
  }
`;

export const RebuildButton = styled(Button)`
  color: #fff;
  height: 32px;
  width: 123px;
  background: #2AC155 !important;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 22px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 0 !important;
  &:hover, &:focus {
    background: #2AC155 !important;
    border: none !important;
  }
`;

export const EditWrapper = styled.div`
  width: 100%;
  height: 100%;
  &.edit-div > div {
    min-height: 100% !important;
    max-height: 100% !important;
    border: none !important;
    span {
      background: #F9F9FB !important;
      & > textarea {
        min-height: calc(min(90vh, 920px) - 68px) !important;
      }
    }
    textarea {
      background: #F9F9FB !important;
      &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
            
    &::-webkit-scrollbar-thumb {
      background: rgba(78, 81, 86, 0.5);
      border-radius: 14px;
    }
            
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: transparent;
    background-image: url('/img/gradient.png');
    background-size: cover;
    background-position: center;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border-radius: 6px;
  }
`;

export const StyledPurpleCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7F4EAD !important;
    border-color: #7F4EAD !important;
    border-radius: 4px !important;
    overflow: hidden;
  }
  .ant-checkbox-inner {
    border-radius: 4px !important;
  }
`;

export const PurpleCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7F4EAD !important;
    border-color: #7F4EAD !important;
    border-radius: 4px !important;
    overflow: hidden;
  }
  .ant-checkbox-inner {
    border-radius: 4px !important;
  }
`;

export const StyledRadio = styled(Radio)`
  .ant-radio-inner {
    background-color: transparent !important;

    &:hover {
      border-color: #7F4EAD !important;
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #7F4EAD !important;
      border-color: #7F4EAD !important;

      &::after {
        background-color: #fff;
      }
    }
  }
`;

export const PublishCollapse = styled(Collapse)`
  border-radius: 12px !important;
  margin-top: 8px !important;
  border: none !important;
  .ant-collapse-header {
    min-height: 38px;
    max-height: 38px;
    display: flex;
    align-items: center !important;
    padding: 0 12px 0 !important;
    gap: 8px !important;
    label {
      margin-right: 0 !important;
      margin-top: -3px !important;
    }
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  .ant-collapse-item {
    border: 1px solid #d9d9d9 !important;
    &:not(:first-child) {
      border-top: none !important;
    }
    &:first-child {
      border-top-right-radius: 12px !important;
      border-top-left-radius: 12px !important;
    }
    &:last-child {
      border-bottom-right-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
    }
  }
  .ant-collapse-item-active {
    &:last-child {
      .ant-collapse-content {
        border-bottom-left-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
      }
    }
    &:first-child {
      .ant-collapse-header {
        border-top-left-radius: 12px !important;
        border-top-right-radius: 12px !important;
      }
    }
    .ant-collapse-header {
      background: #fff !important;
    }
    .ant-collapse-content {
      border-top: none !important;
    }
    .ant-collapse-content-box {
      padding: 6px 12px 14px 12px;
    }
  }
`;

export const ImagesBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.88px;
  margin-bottom: 12px;
  width: 100%;
  min-height: 38px;
  border-radius: 8px;
  background: #F1AA3E26;
  box-shadow: 0px 3px 2px 0px #00000005;
  padding: 8px 12px;
  .txt {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #000000;
    span {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
