// List of valid country codes supported by our Backend

const COUNTRY_CODES =
[
  'AF', 'AL', 'AQ', 'DZ', 'AS', 'AD', 'AO', 'AG', 'AZ', 'AR', 'AU', 'AT', 'BS',
  'BH', 'BD', 'AM', 'BB', 'BE', 'BT', 'BO', 'BA', 'BW', 'BR', 'BZ', 'SB', 'BN',
  'BG', 'MM', 'BI', 'BY', 'KH', 'CM', 'CA', 'CV', 'CF', 'LK', 'TD', 'CL', 'CN',
  'CX', 'CC', 'CO', 'KM', 'CG', 'CD', 'CK', 'CR', 'HR', 'CY', 'CZ', 'BJ', 'DK',
  'DM', 'DO', 'EC', 'SV', 'GQ', 'ET', 'ER', 'EE', 'GS', 'FJ', 'FI', 'FR', 'PF',
  'TF', 'DJ', 'GA', 'GE', 'GM', 'DE', 'GH', 'KI', 'GR', 'GD', 'GU', 'GT', 'GN',
  'GY', 'HT', 'HM', 'VA', 'HN', 'HU', 'IS', 'IN', 'ID', 'IQ', 'IE', 'IL', 'IT',
  'CI', 'JM', 'JP', 'KZ', 'JO', 'KE', 'KR', 'KW', 'KG', 'LA', 'LB', 'LS', 'LV',
  'LR', 'LY', 'LI', 'LT', 'LU', 'MG', 'MW', 'MY', 'MV', 'ML', 'MT', 'MR', 'MU',
  'MX', 'MC', 'MN', 'MD', 'ME', 'MA', 'MZ', 'OM', 'NR', 'NP', 'NL', 'CW', 'SX',
  'BQ', 'NC', 'VU', 'NZ', 'NI', 'NE', 'NG', 'NU', 'NF', 'NO', 'MP', 'UM', 'FM',
  'MH', 'PW', 'PK', 'PA', 'PG', 'PY', 'PE', 'PH', 'PN', 'PL', 'PT', 'GW', 'TL',
  'QA', 'RO', 'RW', 'SH', 'KN', 'LC', 'PM', 'VC', 'SM', 'ST', 'SA', 'SN', 'RS',
  'SC', 'SL', 'SG', 'SK', 'VN', 'SI', 'SO', 'ZA', 'ZW', 'ES', 'SR', 'SZ', 'SE',
  'CH', 'TJ', 'TH', 'TG', 'TK', 'TO', 'TT', 'AE', 'TN', 'TR', 'TM', 'TV', 'UG',
  'UA', 'MK', 'EG', 'GB', 'GG', 'JE', 'TZ', 'US', 'BF', 'UY', 'UZ', 'VE', 'WF',
  'WS', 'YE', 'ZM',
];

export default COUNTRY_CODES;
// this is sorted country codes on the base of their names
export const SORTED_COUNTRY_CODES_LIST =
[
  {
    'countryName': 'Afghanistan',
    'countryCode': 'AF',
  },
  {
    'countryName': 'Albania',
    'countryCode': 'AL',
  },
  {
    'countryName': 'Algeria',
    'countryCode': 'DZ',
  },
  {
    'countryName': 'American Samoa',
    'countryCode': 'AS',
  },
  {
    'countryName': 'Andorra',
    'countryCode': 'AD',
  },
  {
    'countryName': 'Angola',
    'countryCode': 'AO',
  },
  {
    'countryName': 'Antarctica',
    'countryCode': 'AQ',
  },
  {
    'countryName': 'Antigua and Barbuda',
    'countryCode': 'AG',
  },
  {
    'countryName': 'Argentina',
    'countryCode': 'AR',
  },
  {
    'countryName': 'Armenia',
    'countryCode': 'AM',
  },
  {
    'countryName': 'Australia',
    'countryCode': 'AU',
  },
  {
    'countryName': 'Austria',
    'countryCode': 'AT',
  },
  {
    'countryName': 'Azerbaijan',
    'countryCode': 'AZ',
  },
  {
    'countryName': 'Bahamas',
    'countryCode': 'BS',
  },
  {
    'countryName': 'Bahrain',
    'countryCode': 'BH',
  },
  {
    'countryName': 'Bangladesh',
    'countryCode': 'BD',
  },
  {
    'countryName': 'Barbados',
    'countryCode': 'BB',
  },
  {
    'countryName': 'Belarus',
    'countryCode': 'BY',
  },
  {
    'countryName': 'Belgium',
    'countryCode': 'BE',
  },
  {
    'countryName': 'Belize',
    'countryCode': 'BZ',
  },
  {
    'countryName': 'Benin',
    'countryCode': 'BJ',
  },
  {
    'countryName': 'Bhutan',
    'countryCode': 'BT',
  },
  {
    'countryName': 'Bolivia',
    'countryCode': 'BO',
  },
  {
    'countryName': 'Bonaire',
    'countryCode': 'BQ',
  },
  {
    'countryName': 'Bosnia and Herzegovina',
    'countryCode': 'BA',
  },
  {
    'countryName': 'Botswana',
    'countryCode': 'BW',
  },
  {
    'countryName': 'Brazil',
    'countryCode': 'BR',
  },
  {
    'countryName': 'Brunei',
    'countryCode': 'BN',
  },
  {
    'countryName': 'Bulgaria',
    'countryCode': 'BG',
  },
  {
    'countryName': 'Burkina Faso',
    'countryCode': 'BF',
  },
  {
    'countryName': 'Burundi',
    'countryCode': 'BI',
  },
  {
    'countryName': 'Cambodia',
    'countryCode': 'KH',
  },
  {
    'countryName': 'Cameroon',
    'countryCode': 'CM',
  },
  {
    'countryName': 'Canada',
    'countryCode': 'CA',
  },
  {
    'countryName': 'Cape Verde',
    'countryCode': 'CV',
  },
  {
    'countryName': 'Central African Republic',
    'countryCode': 'CF',
  },
  {
    'countryName': 'Chad',
    'countryCode': 'TD',
  },
  {
    'countryName': 'Chile',
    'countryCode': 'CL',
  },
  {
    'countryName': 'China',
    'countryCode': 'CN',
  },
  {
    'countryName': 'Christmas Island',
    'countryCode': 'CX',
  },
  {
    'countryName': 'Cocos [Keeling] Islands',
    'countryCode': 'CC',
  },
  {
    'countryName': 'Colombia',
    'countryCode': 'CO',
  },
  {
    'countryName': 'Comoros',
    'countryCode': 'KM',
  },
  {
    'countryName': 'Cook Islands',
    'countryCode': 'CK',
  },
  {
    'countryName': 'Costa Rica',
    'countryCode': 'CR',
  },
  {
    'countryName': 'Croatia',
    'countryCode': 'HR',
  },
  {
    'countryName': 'Curacao',
    'countryCode': 'CW',
  },
  {
    'countryName': 'Cyprus',
    'countryCode': 'CY',
  },
  {
    'countryName': 'Czech Republic',
    'countryCode': 'CZ',
  },
  {
    'countryName': 'Democratic Republic of the Congo',
    'countryCode': 'CD',
  },
  {
    'countryName': 'Denmark',
    'countryCode': 'DK',
  },
  {
    'countryName': 'Djibouti',
    'countryCode': 'DJ',
  },
  {
    'countryName': 'Dominica',
    'countryCode': 'DM',
  },
  {
    'countryName': 'Dominican Republic',
    'countryCode': 'DO',
  },
  {
    'countryName': 'East Timor',
    'countryCode': 'TL',
  },
  {
    'countryName': 'Ecuador',
    'countryCode': 'EC',
  },
  {
    'countryName': 'Egypt',
    'countryCode': 'EG',
  },
  {
    'countryName': 'El Salvador',
    'countryCode': 'SV',
  },
  {
    'countryName': 'Equatorial Guinea',
    'countryCode': 'GQ',
  },
  {
    'countryName': 'Eritrea',
    'countryCode': 'ER',
  },
  {
    'countryName': 'Estonia',
    'countryCode': 'EE',
  },
  {
    'countryName': 'Ethiopia',
    'countryCode': 'ET',
  },
  {
    'countryName': 'Fiji',
    'countryCode': 'FJ',
  },
  {
    'countryName': 'Finland',
    'countryCode': 'FI',
  },
  {
    'countryName': 'France',
    'countryCode': 'FR',
  },
  {
    'countryName': 'French Polynesia',
    'countryCode': 'PF',
  },
  {
    'countryName': 'French Southern Territories',
    'countryCode': 'TF',
  },
  {
    'countryName': 'Gabon',
    'countryCode': 'GA',
  },
  {
    'countryName': 'Gambia',
    'countryCode': 'GM',
  },
  {
    'countryName': 'Georgia',
    'countryCode': 'GE',
  },
  {
    'countryName': 'Germany',
    'countryCode': 'DE',
  },
  {
    'countryName': 'Ghana',
    'countryCode': 'GH',
  },
  {
    'countryName': 'Greece',
    'countryCode': 'GR',
  },
  {
    'countryName': 'Grenada',
    'countryCode': 'GD',
  },
  {
    'countryName': 'Guam',
    'countryCode': 'GU',
  },
  {
    'countryName': 'Guatemala',
    'countryCode': 'GT',
  },
  {
    'countryName': 'Guernsey',
    'countryCode': 'GG',
  },
  {
    'countryName': 'Guinea',
    'countryCode': 'GN',
  },
  {
    'countryName': 'Guinea-Bissau',
    'countryCode': 'GW',
  },
  {
    'countryName': 'Guyana',
    'countryCode': 'GY',
  },
  {
    'countryName': 'Haiti',
    'countryCode': 'HT',
  },
  {
    'countryName': 'Heard Island and McDonald Islands',
    'countryCode': 'HM',
  },
  {
    'countryName': 'Honduras',
    'countryCode': 'HN',
  },
  {
    'countryName': 'Hungary',
    'countryCode': 'HU',
  },
  {
    'countryName': 'Iceland',
    'countryCode': 'IS',
  },
  {
    'countryName': 'India',
    'countryCode': 'IN',
  },
  {
    'countryName': 'Indonesia',
    'countryCode': 'ID',
  },
  {
    'countryName': 'Iraq',
    'countryCode': 'IQ',
  },
  {
    'countryName': 'Ireland',
    'countryCode': 'IE',
  },
  {
    'countryName': 'Israel',
    'countryCode': 'IL',
  },
  {
    'countryName': 'Italy',
    'countryCode': 'IT',
  },
  {
    'countryName': 'Ivory Coast',
    'countryCode': 'CI',
  },
  {
    'countryName': 'Jamaica',
    'countryCode': 'JM',
  },
  {
    'countryName': 'Japan',
    'countryCode': 'JP',
  },
  {
    'countryName': 'Jersey',
    'countryCode': 'JE',
  },
  {
    'countryName': 'Jordan',
    'countryCode': 'JO',
  },
  {
    'countryName': 'Kazakhstan',
    'countryCode': 'KZ',
  },
  {
    'countryName': 'Kenya',
    'countryCode': 'KE',
  },
  {
    'countryName': 'Kiribati',
    'countryCode': 'KI',
  },
  {
    'countryName': 'Kuwait',
    'countryCode': 'KW',
  },
  {
    'countryName': 'Kyrgyzstan',
    'countryCode': 'KG',
  },
  {
    'countryName': 'Laos',
    'countryCode': 'LA',
  },
  {
    'countryName': 'Latvia',
    'countryCode': 'LV',
  },
  {
    'countryName': 'Lebanon',
    'countryCode': 'LB',
  },
  {
    'countryName': 'Lesotho',
    'countryCode': 'LS',
  },
  {
    'countryName': 'Liberia',
    'countryCode': 'LR',
  },
  {
    'countryName': 'Libya',
    'countryCode': 'LY',
  },
  {
    'countryName': 'Liechtenstein',
    'countryCode': 'LI',
  },
  {
    'countryName': 'Lithuania',
    'countryCode': 'LT',
  },
  {
    'countryName': 'Luxembourg',
    'countryCode': 'LU',
  },
  {
    'countryName': 'Madagascar',
    'countryCode': 'MG',
  },
  {
    'countryName': 'Malawi',
    'countryCode': 'MW',
  },
  {
    'countryName': 'Malaysia',
    'countryCode': 'MY',
  },
  {
    'countryName': 'Maldives',
    'countryCode': 'MV',
  },
  {
    'countryName': 'Mali',
    'countryCode': 'ML',
  },
  {
    'countryName': 'Malta',
    'countryCode': 'MT',
  },
  {
    'countryName': 'Marshall Islands',
    'countryCode': 'MH',
  },
  {
    'countryName': 'Mauritania',
    'countryCode': 'MR',
  },
  {
    'countryName': 'Mauritius',
    'countryCode': 'MU',
  },
  {
    'countryName': 'Mexico',
    'countryCode': 'MX',
  },
  {
    'countryName': 'Micronesia',
    'countryCode': 'FM',
  },
  {
    'countryName': 'Moldova',
    'countryCode': 'MD',
  },
  {
    'countryName': 'Monaco',
    'countryCode': 'MC',
  },
  {
    'countryName': 'Mongolia',
    'countryCode': 'MN',
  },
  {
    'countryName': 'Montenegro',
    'countryCode': 'ME',
  },
  {
    'countryName': 'Morocco',
    'countryCode': 'MA',
  },
  {
    'countryName': 'Mozambique',
    'countryCode': 'MZ',
  },
  {
    'countryName': 'Myanmar [Burma]',
    'countryCode': 'MM',
  },
  {
    'countryName': 'Nauru',
    'countryCode': 'NR',
  },
  {
    'countryName': 'Nepal',
    'countryCode': 'NP',
  },
  {
    'countryName': 'Netherlands',
    'countryCode': 'NL',
  },
  {
    'countryName': 'New Caledonia',
    'countryCode': 'NC',
  },
  {
    'countryName': 'New Zealand',
    'countryCode': 'NZ',
  },
  {
    'countryName': 'Nicaragua',
    'countryCode': 'NI',
  },
  {
    'countryName': 'Niger',
    'countryCode': 'NE',
  },
  {
    'countryName': 'Nigeria',
    'countryCode': 'NG',
  },
  {
    'countryName': 'Niue',
    'countryCode': 'NU',
  },
  {
    'countryName': 'Norfolk Island',
    'countryCode': 'NF',
  },
  {
    'countryName': 'North Macedonia',
    'countryCode': 'MK',
  },
  {
    'countryName': 'Northern Mariana Islands',
    'countryCode': 'MP',
  },
  {
    'countryName': 'Norway',
    'countryCode': 'NO',
  },
  {
    'countryName': 'Oman',
    'countryCode': 'OM',
  },
  {
    'countryName': 'Pakistan',
    'countryCode': 'PK',
  },
  {
    'countryName': 'Palau',
    'countryCode': 'PW',
  },
  {
    'countryName': 'Panama',
    'countryCode': 'PA',
  },
  {
    'countryName': 'Papua New Guinea',
    'countryCode': 'PG',
  },
  {
    'countryName': 'Paraguay',
    'countryCode': 'PY',
  },
  {
    'countryName': 'Peru',
    'countryCode': 'PE',
  },
  {
    'countryName': 'Philippines',
    'countryCode': 'PH',
  },
  {
    'countryName': 'Pitcairn Islands',
    'countryCode': 'PN',
  },
  {
    'countryName': 'Poland',
    'countryCode': 'PL',
  },
  {
    'countryName': 'Portugal',
    'countryCode': 'PT',
  },
  {
    'countryName': 'Qatar',
    'countryCode': 'QA',
  },
  {
    'countryName': 'Republic of the Congo',
    'countryCode': 'CG',
  },
  {
    'countryName': 'Romania',
    'countryCode': 'RO',
  },
  {
    'countryName': 'Rwanda',
    'countryCode': 'RW',
  },
  {
    'countryName': 'Saint Helena',
    'countryCode': 'SH',
  },
  {
    'countryName': 'Saint Kitts and Nevis',
    'countryCode': 'KN',
  },
  {
    'countryName': 'Saint Lucia',
    'countryCode': 'LC',
  },
  {
    'countryName': 'Saint Pierre and Miquelon',
    'countryCode': 'PM',
  },
  {
    'countryName': 'Saint Vincent and the Grenadines',
    'countryCode': 'VC',
  },
  {
    'countryName': 'Samoa',
    'countryCode': 'WS',
  },
  {
    'countryName': 'San Marino',
    'countryCode': 'SM',
  },
  {
    'countryName': 'Saudi Arabia',
    'countryCode': 'SA',
  },
  {
    'countryName': 'Senegal',
    'countryCode': 'SN',
  },
  {
    'countryName': 'Serbia',
    'countryCode': 'RS',
  },
  {
    'countryName': 'Seychelles',
    'countryCode': 'SC',
  },
  {
    'countryName': 'Sierra Leone',
    'countryCode': 'SL',
  },
  {
    'countryName': 'Singapore',
    'countryCode': 'SG',
  },
  {
    'countryName': 'Sint Maarten',
    'countryCode': 'SX',
  },
  {
    'countryName': 'Slovakia',
    'countryCode': 'SK',
  },
  {
    'countryName': 'Slovenia',
    'countryCode': 'SI',
  },
  {
    'countryName': 'Solomon Islands',
    'countryCode': 'SB',
  },
  {
    'countryName': 'Somalia',
    'countryCode': 'SO',
  },
  {
    'countryName': 'South Africa',
    'countryCode': 'ZA',
  },
  {
    'countryName': 'South Georgia and the South Sandwich Islands',
    'countryCode': 'GS',
  },
  {
    'countryName': 'South Korea',
    'countryCode': 'KR',
  },
  {
    'countryName': 'Spain',
    'countryCode': 'ES',
  },
  {
    'countryName': 'Sri Lanka',
    'countryCode': 'LK',
  },
  {
    'countryName': 'Suriname',
    'countryCode': 'SR',
  },
  {
    'countryName': 'Swaziland',
    'countryCode': 'SZ',
  },
  {
    'countryName': 'Sweden',
    'countryCode': 'SE',
  },
  {
    'countryName': 'Switzerland',
    'countryCode': 'CH',
  },
  {
    'countryName': 'São Tomé and Príncipe',
    'countryCode': 'ST',
  },
  {
    'countryName': 'Tajikistan',
    'countryCode': 'TJ',
  },
  {
    'countryName': 'Tanzania',
    'countryCode': 'TZ',
  },
  {
    'countryName': 'Thailand',
    'countryCode': 'TH',
  },
  {
    'countryName': 'Togo',
    'countryCode': 'TG',
  },
  {
    'countryName': 'Tokelau',
    'countryCode': 'TK',
  },
  {
    'countryName': 'Tonga',
    'countryCode': 'TO',
  },
  {
    'countryName': 'Trinidad and Tobago',
    'countryCode': 'TT',
  },
  {
    'countryName': 'Tunisia',
    'countryCode': 'TN',
  },
  {
    'countryName': 'Turkey',
    'countryCode': 'TR',
  },
  {
    'countryName': 'Turkmenistan',
    'countryCode': 'TM',
  },
  {
    'countryName': 'Tuvalu',
    'countryCode': 'TV',
  },
  {
    'countryName': 'U.S. Minor Outlying Islands',
    'countryCode': 'UM',
  },
  {
    'countryName': 'Uganda',
    'countryCode': 'UG',
  },
  {
    'countryName': 'Ukraine',
    'countryCode': 'UA',
  },
  {
    'countryName': 'United Arab Emirates',
    'countryCode': 'AE',
  },
  {
    'countryName': 'United Kingdom',
    'countryCode': 'GB',
  },
  {
    'countryName': 'United States',
    'countryCode': 'US',
  },
  {
    'countryName': 'Uruguay',
    'countryCode': 'UY',
  },
  {
    'countryName': 'Uzbekistan',
    'countryCode': 'UZ',
  },
  {
    'countryName': 'Vanuatu',
    'countryCode': 'VU',
  },
  {
    'countryName': 'Vatican City',
    'countryCode': 'VA',
  },
  {
    'countryName': 'Venezuela',
    'countryCode': 'VE',
  },
  {
    'countryName': 'Vietnam',
    'countryCode': 'VN',
  },
  {
    'countryName': 'Wallis and Futuna',
    'countryCode': 'WF',
  },
  {
    'countryName': 'Yemen',
    'countryCode': 'YE',
  },
  {
    'countryName': 'Zambia',
    'countryCode': 'ZM',
  },
  {
    'countryName': 'Zimbabwe',
    'countryCode': 'ZW',
  },
];


