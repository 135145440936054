import ProfileApi from '@/api/account/customer/profile-api';
import AuthApi from '@/api/account/auth/auth-api';
import OrdersApi from '@/api/account/orders/orders-api';
import SiteAuditorApi from '@/api/account/site-auditor/site_auditor_api';
import IssueAnalyzerApi from '@/api/account/issue-analyzer/issue_anlyzer_api';
import InvoicesApi from '@/api/account/invoices/invoices-api';
import PlansApi from '@/api/account/plans/plans-api';
import StripeApi from '@/api/account//stripe/stripe-api';
import ContentReWritingApi from '../content-rewriting';
import WhiteLabelOrderBuilderApi from './customer/white-label-order-builder-api';


export const profileApi = new ProfileApi();
export const authApi = new AuthApi();
export const ordersApi = new OrdersApi();
export const invoicesApi = new InvoicesApi();
export const planApi = new PlansApi();
export const stripeApi = new StripeApi();
export const siteAuditorApi = new SiteAuditorApi();
export const issueAnalyzerApi = new IssueAnalyzerApi();
export const contentReWritingApi = new ContentReWritingApi();
export const whiteLabelOrderBuilderApi = new WhiteLabelOrderBuilderApi();
