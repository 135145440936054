import {types, Instance} from 'mobx-state-tree';
import {setKrtPeriodIntoLocalStorage} from '@/api/common-utils';


export const KrtCriteria = types.model({
  currentPeriodStart: types.string,
  currentPeriodEnd: types.string,
}).actions(self => {
  const updateKrtCriteria = ({currentPeriodStart, currentPeriodEnd}: {
    currentPeriodStart: string;
    currentPeriodEnd: string;
  }) => {
    self.currentPeriodStart = currentPeriodStart;
    self.currentPeriodEnd = currentPeriodEnd;

    setKrtPeriodIntoLocalStorage(currentPeriodStart, currentPeriodEnd);
  };

  const clearKrtCriteria = () => {
    self.currentPeriodStart = '';
    self.currentPeriodEnd = '';
  };

  return {
    updateKrtCriteria,
    clearKrtCriteria,
  };
});

export type KrtCriteriaType = Instance<typeof KrtCriteria>;

export function initKrtCriteriaStore() {
  return KrtCriteria.create({
    currentPeriodEnd: '',
    currentPeriodStart: '',
  });
}
