import React, {useState} from 'react';
import {useStore} from '@/store/root-store';
import {countryFlag} from '@/utils/countries';
import {faMagnifyingGlass, faCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Input} from 'antd';
import {getDomain} from '@/utils/url';
import {faBan} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {useRouter} from 'next/router';
import {NextImg} from '@/utils/nextImg';

const GoogleAnalyticsProjectModalContent = (
  {sitePropertiesList, countryCode, setModalValues, modalValues, onProjectChange, selectedGSCProject, useIsWorldWide=false, isOtto=false, title}: any) => {
  const {
    countries: {getCountriesData},
    navBar: {setIsActivatePropertiesDrawerOpen},
    reportBuilder: {setManageDomainName},
  } = useStore('');
  const [search, setSearch] = useState('');
  const router = useRouter();
  const getImgUrl = url => <NextImg
    // onError={e => addDefaultSrc(e)}
    style={{
      height: '14px',
      width: '14px',
      marginRight: '5px',
    }}
    src={`https://www.google.com/s2/favicons?sz=64&domain_url=https://www.${getDomain(url)}`}
    alt={url}
  />;

  const availableInUsProjects = sitePropertiesList.filter(item => {
    if (search) {
      return item?.url?.includes(search) && item?.countryCodes.some(code => code.toLowerCase() === countryCode?.toLowerCase());
    } else return item?.countryCodes?.some(code => code.toLowerCase() === countryCode?.toLowerCase());
  });
  const notAvailableInUsProjects = sitePropertiesList.filter(item => {
    if (search) {
      return item?.url?.includes(search) && !item?.countryCodes.some(code => code.toLowerCase() === countryCode?.toLowerCase());
    } else return !item?.countryCodes?.some(code => code.toLowerCase() === countryCode?.toLowerCase());
  });

  const findCountryNameByCode = countryCode => {
    try {
      if (countryCode) {
        if (countryCode === 'none') {
          return 'None';
        } else {
          const country = getCountriesData.find(country => country.code.toLowerCase() == countryCode.toLowerCase());
          if (country) return country?.name;
          else return 'United States';
        }
      } else {
        return 'Worldwide';
      }
    } catch (error) {
      return 'Worldwide';
    }
  };

  const onProjectClick = value => {
    onProjectChange(value);
    !isOtto && setModalValues({...modalValues, isOpenAnalyticsProjectModal: false});
  };

  return (
    <div style={{boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'}}>
      <FirstSection>
        <Title>{title ? title : 'Google Search Console Project'}</Title>
        <StyledInput
          placeholder='Search...'
          onChange={e => setSearch(e.target.value)}
          prefix={<FontAwesomeIcon icon={faMagnifyingGlass} color='#A3A4A4' />}
        />
        <div>
          <AvailableForCountryBanner availableBanner>
            <FontAwesomeIcon icon={faCheck} color='#1FAC47' />
            <BannerTxt>Available for&nbsp;{countryFlag(countryCode, '16px', '1px', false, useIsWorldWide)}&nbsp;{findCountryNameByCode(countryCode)}</BannerTxt>
          </AvailableForCountryBanner>
          <ProjectContainer hasBorderAndShadow={availableInUsProjects?.length > 5}>
            {[...availableInUsProjects]?.map((item, index) => {
              return (
                <SingleProject isFocused={selectedGSCProject?.toLowerCase() == item?.url?.toLowerCase()} onClick={() => onProjectClick(item?.url)} key={index}>
                  <WebsiteContainer>
                    {item?.url === 'none' ? '' : getImgUrl(item?.url)}
                    <WebsiteDomain
                      className={`${selectedGSCProject?.toLowerCase() == item?.url?.toLowerCase() ? 'focused' : ''}`}
                    >
                      {item?.url === 'none' ? '' : item?.url?.toString()?.replace('sc-domain', 'Domain')} {selectedGSCProject?.toLowerCase() == item?.url?.toLowerCase() && <FontAwesomeIcon icon={faCheck} color='#1FAC47' />}
                    </WebsiteDomain>
                  </WebsiteContainer>
                </SingleProject>
              );
            })}
          </ProjectContainer>
        </div>
      </FirstSection>
      <SecondSection>
        <AvailableForCountryBanner availableBanner={false}>
          <FontAwesomeIcon icon={faBan} color='rgba(0, 0, 0, 1)' />
          <BannerTxt>Sites unavailable or disabled for&nbsp;{countryFlag(countryCode, '16px', '1px', false, useIsWorldWide)}&nbsp;{findCountryNameByCode(countryCode)}</BannerTxt>
        </AvailableForCountryBanner>
        <ProjectContainer hasBorderAndShadow={notAvailableInUsProjects?.length > 5}>
          {notAvailableInUsProjects.map((item, index) => {
            return (
              <SingleProject style={{cursor: 'default'}} key={index}>
                <WebsiteContainer>
                  {getImgUrl(item?.url)}
                  <WebsiteDomain>{item?.url?.toString()?.replace('sc-domain', 'Domain')}</WebsiteDomain>
                </WebsiteContainer>
                <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
                  <ManageButton onClick={() => {
                    setManageDomainName({manageDomainName: getDomain(item?.url), countryCode});
                    // actionBeforeDrawerOpen();
                    setIsActivatePropertiesDrawerOpen();
                    !isOtto && setModalValues({...modalValues, isOpenAnalyticsProjectModal: false});
                  }}>Manage</ManageButton>
                </FreezeWrapper>
              </SingleProject>
            );
          })}
        </ProjectContainer>
      </SecondSection>
    </div>
  );
};

export default GoogleAnalyticsProjectModalContent;

const Title = styled.div`
  margin-left: 10px;
  color: var(--Text-black, var(--Black, #121212));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const StyledInput = styled(Input)`
  margin-top: 12px;
  width: 360px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--Text-gray-light, #E8E8E8);
  background: var(--Table-row-stripe, #F9F9FB);
  .ant-input{
   background: var(--Table-row-stripe, #F9F9FB);
  }
`;

const AvailableForCountryBanner = styled.div<{availableBanner: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 360px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 28px;
  background-color: ${p => p.availableBanner ? 'rgba(31, 172, 71, 0.1)' : 'rgba(78, 81, 86, 0.1)'};
`;

const FirstSection = styled.div`
  padding: 18px 15px 6px !important;
`;

const BannerTxt = styled.span`
  margin-left: 5px;
  color: var(--Text-black, var(--Black, #121212));
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
`;

const ProjectContainer = styled.div<{hasBorderAndShadow?: boolean}>`
  margin-top: 2px;
  max-height: 194px;
  overflow: auto;
  margin-bottom: 8px;
  ${p => p?.hasBorderAndShadow ? `
    overflow-x: hidden;
    overflow-y: auto;
    padding: 6px 0;
    border: 1px solid #e4e0e0;
    border-radius: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
    box-shadow: inset 0px -5px 10px rgba(18, 18, 18, 0.12);
  ` : ''}

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #ededf1;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;

const WebsiteContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SingleProject = styled.div<{isFocused?: boolean}>`
  width: 360px;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 5px 10px;
  align-items: center;
  gap: 15px;
  border-radius: 6px;
  cursor: pointer;
  background: ${p => p.isFocused ? 'rgba(45, 108, 202, 0.1)' : '' };
  .focused{
    color: rgba(45, 108, 202, 1);
  }
  &:hover{
    background-color: rgba(78, 81, 86, 0.1);
  }
`;

const WebsiteDomain = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // max-width: 232px;
  margin-left: 6px;
  color: var(--Text-black, var(--Black, #121212));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
`;

const SecondSection = styled.div`
  padding: 0px 15px 15px 15px;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid var(--Text-gray-light, #E8E8E8);
  background: var(--Table-row-stripe, #F9F9FB);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.05);
`;

const ManageButton = styled.button`
  color: var(--Main-blue, var(--Blue, #2D6CCA));
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
