import {decoratedState} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/LeftSide/EditorSection/content-editor/decorators';
import {useStore} from '@/store/root-store';
import {AtomicBlockUtils, EditorState} from 'draft-js';
import {debounce} from 'lodash';
import {observer} from 'mobx-react';
import {useCallback} from 'react';
import {SingleAiOutline} from './SingleAiOutline';

interface Props {
  outlines?: string[];
}

export const TemplatesImageResults = observer(({outlines}: Props) => {
  const {contentOptimizer: {currentPage}} = useStore('');
  const {
    focusTermsClassNames,
    currentEditorState,
    setShowAiBanner,
    showAiBanner,
  } = currentPage;

  const debouncedChange = debounce((state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations = false) => {
    // we are then calling the edit content method which will fire the API call and send new state to the BE
    currentPage.editContent(state, isNewDecorations);
  }, 2000);


  const debouncedUpdate = useCallback(
    (state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations) => debouncedChange(state, focusTerms, focusTermsClassNames, isNewDecorations),
    // eslint-disable-next-line
          []
  );

  const debouncedHighlightingChange = debounce((state: EditorState, focusTermsClassNames, changeType = null) => {
    let decorated;
    const acceptedTypes = ['insert-fragment', 'insert-characters', 'apply-entity'];
    if (acceptedTypes.includes(changeType)) {
      // we are using the passed editor state (updated state) and doing the decoration
      decorated = decoratedState(state, focusTermsClassNames);
      // we then set that decorated state as current local state
      currentPage.setCurrentEditorState(EditorState.forceSelection(decorated, state.getSelection()));
    }
  }, 500);

  const debouncedHighlighting = useCallback(
    (state: EditorState, focusTermsClassNames, changeType) => debouncedHighlightingChange(state, focusTermsClassNames, changeType),
    // eslint-disable-next-line
        []
  );

  // This method is triggered by updating the DraftJs editor
  const onChange = (editorState: EditorState, isNewDecorations: boolean = false) => {
    const currentContent = currentPage.currentEditorState.getCurrentContent();
    const newContent = editorState.getCurrentContent();
    const changeType = editorState.getLastChangeType();
    currentPage.setCurrentEditorState(editorState);

    const lastChangedBlockKey = editorState.getSelection()?.getAnchorKey();
    const lastChangedBlock = currentContent.getBlockForKey(lastChangedBlockKey);
    const lastChangedBlockType = lastChangedBlock?.getType();

    // We are comparing current editor state with udpated one to make sure the flow only triggers once there is an actual content change (adding removing of words, formatting, linkjng etc.)
    if (currentContent !== newContent || lastChangedBlockType === 'atomic') {
      debouncedHighlighting(editorState, focusTermsClassNames, changeType);
      debouncedUpdate(editorState, currentPage.analytics?.focusTerms, focusTermsClassNames, isNewDecorations);

      if (!newContent?.hasText() && !showAiBanner) {
        setShowAiBanner(true);
      } else {
        if (showAiBanner) {
          setShowAiBanner(false);
        }
      }
    }
  };


  const insertImage = (editorState, base64) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      {src: base64},
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const onImageUpload = imageUrl => {
    const newEditorState = insertImage(currentEditorState, imageUrl);
    onChange(newEditorState);
  };

  return <>
    {outlines?.map((singleOutline, idx) => {
      return <SingleAiOutline key={idx} outlineId={idx} aiOutline={singleOutline} insertToEditor={onImageUpload}/>;
    })}
  </>;
});
