import {SelectionState, Modifier, EditorState, CharacterMetadata, convertToRaw} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
// import {getParent} from 'mobx-state-tree';
import {editAltIcon, reGenerateIcon, svg} from '../icons';
import {stateFromHtmlOptions} from '../renderConfig';

const removeFirstBlock = editorState => {
  const contentState = editorState?.getCurrentContent();
  const block = contentState.getBlockMap().first();
  const next = contentState.getBlockAfter(block.getKey());

  if (block && next) {
    const removeSelection = new SelectionState({
      anchorKey: block.getKey(),
      anchorOffset: 0,
      focusKey: next.getKey(),
      focusOffset: 0,
    });

    const newData = Modifier.setBlockType(
      contentState,
      removeSelection,
      next.getType(),
    );

    const newContentState = Modifier.removeRange(
      newData,
      removeSelection,
      'forward',
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-range',
    );
    return newEditorState;
  }
};
export const convertHtmlToEditorState = (newHtml: any, self: any = null) => {
  // html conversion normally ignores <hr> tags, but using this character substitution it can be configured to preserve them.
  const blockTags = [
    'div',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'table',
    'img',
    'li',
    'ol',
    'ul',
    'hr',
    'pre',
    'section',
    'header',
    'nav',
    'main',
    'blockquote',
    'figure',
    'body',
    'span',
  ];
  const domParser = new DOMParser();
  const tempDoc = domParser.parseFromString(newHtml, 'text/html');
  const parsedHTML = tempDoc.querySelector('body') as any;
  const length = parsedHTML.childNodes.length;
  for (let index = 0; index < length; index++) {
    if (parsedHTML.childNodes[index]?.tagName === 'IMG') {
      const createElement = document.createElement('figure');
      const imgTag = document.createElement('img');
      imgTag.src = parsedHTML.childNodes[index].src;
      imgTag.alt = parsedHTML.childNodes[index].alt;
      createElement.append(imgTag);
      parsedHTML.childNodes[index].append(createElement);
    }
  }
  let child = parsedHTML.firstChild as any;
  if (parsedHTML.children.length === 1 && child.tagName === 'TABLE') {
    child = document.createElement('br');
    parsedHTML.insertBefore(child, parsedHTML.firstChild);
    parsedHTML.appendChild(document.createElement('br'));
  }
  while (child) {
    // remove Style tags
    if (child.tagName === 'STYLE' || child.tagName === 'BR') {
      const nextChild = child.nextSibling;
      parsedHTML.removeChild(child);
      child = nextChild;
      continue;
    }

    if (!blockTags.includes(child.tagName?.toLowerCase())) {
      const wrapper = tempDoc.createElement('div');
      let nextChild = child.nextSibling;
      wrapper.appendChild(child);
      while (nextChild && !blockTags.includes(nextChild.tagName?.toLowerCase())) {
        const currentChild = nextChild;
        nextChild = currentChild.nextSibling;
        wrapper.appendChild(currentChild);
      }
      parsedHTML.insertBefore(wrapper, nextChild);
      child = nextChild;
    }

    child = child?.nextSibling;
  }

  // recursive function to walk the full DOM tree, making modifications as needed
  // to preserve formatting during conversion to internal state for draft.js
  const traverse = (node, isNestedBlock = null) => {
    if (!node) return;
    // elements formatted with spacing and soft line-breaks
    if (/white-space:\s*(pre|pre-wrap);?/.test(node.getAttribute('style'))) {
      node.innerHTML = node.innerHTML
        .replace(/\n/g, '<br>')
        .replace(/\s{2}/g, '&nbsp;&nbsp;')
        .replace(/&nbsp;\s/g, '&nbsp;&nbsp;');
      let style = node.getAttribute('style');
      style = style.replace(/white-space:\s*(pre|pre-wrap);?/, '');
      node.setAttribute('style', style);
    }
    // replace block elements inside lists with inline <span> elements
    if (isNestedBlock && ['DIV', 'P', 'INPUT'].includes(node.tagName)) {
      const newNode = changeTag(node, 'span');
      node.replaceWith(newNode);
      node = newNode;
    }
    // If a nested table has a single row and cell, switch it to a span as a single cell's contents of the outer table
    if (isNestedBlock && node.tagName === 'TABLE') {
      if (node.firstElementChild.tagName === 'TBODY') {
        const numRows = node.firstElementChild.children.length;
        if (numRows === 1) {
          const numCells = node.firstElementChild.firstElementChild.children.length;
          if (numCells === 1) {
            let cell = node.firstElementChild.firstElementChild.firstElementChild;
            if (['DIV', 'P'].includes(cell.firstElementChild.tagName)) {
              cell = cell.firstElementChild;
            }
            const newNode = changeTag(cell, 'span');
            node.replaceWith(newNode);
          }
        }
      }
    }
    traverse(node.nextElementSibling, isNestedBlock);
    isNestedBlock = isNestedBlock || node.tagName === 'LI' || node.tagName === 'TD' || node.tagName === 'TH';
    traverse(node.firstElementChild, isNestedBlock);
  };

  traverse(parsedHTML.firstElementChild);

  // function used within traverse() for converting block elements to inline span elements
  function changeTag(element, tag) {
    // prepare the elements
    const newElem = document.createElement(tag);
    const clone = element.cloneNode(true);
    // move the children from the clone to the new element
    while (clone.firstChild) {
      newElem.appendChild(clone.firstChild);
    }
    // copy the attributes
    for (const attr of clone.attributes) {
      if (attr.name === 'value') {
        newElem.textContent = attr.value;
      } else {
        newElem.setAttribute(attr.name, attr.value);
      }
    }
    return newElem;
  }
  const s = new XMLSerializer();
  const newContent = s.serializeToString(parsedHTML);
  // end special parsing
  let newEditorState = EditorState.createWithContent(stateFromHTML(newContent, stateFromHtmlOptions));
  newEditorState = EditorState.moveSelectionToEnd(newEditorState);
  return supplementalCustomBlockStyleFn(newEditorState, self);
};

const supplementalCustomBlockStyleFn = (newEditorState: any, self: any = null) => {
  const newHtml = newEditorState.getCurrentContent();
  const domParser = new DOMParser();
  const tempDoc = domParser.parseFromString(newHtml, 'text/html');
  const parsedHTML = tempDoc.querySelector('body') as any;
  if (parsedHTML.firstChild.tagName === 'P' && parsedHTML.firstChild?.nextElementSibling) {
    newEditorState = removeFirstBlock(newEditorState);
  }
  const contentState = newEditorState.getCurrentContent();
  const selectionState = newEditorState.getSelection();
  let blockMap = contentState.getBlockMap();
  const blocks = blockMap.map(block => {
    let depth = block.getData().get('depth');
    const margin = block.getData().get('margin-left');
    if ((margin && /em$/.test(margin)) || depth) {
      const value = margin.replace(/rem|em/, '') / 2.5 as any;
      depth = depth !== undefined ? depth : parseInt(value, 10);
      block = block.set('depth', depth);
    }

    block.findEntityRanges(
      v => {
        const key = v.getEntity();
        return key !== null && contentState.getEntity(key).getType() === 'LINK';
      },
      (start, end) => {
        const characterList = block.getCharacterList().map((v, k) => {
          if (start <= k && k < end) {
            v = CharacterMetadata.applyStyle(v, 'UNDERLINE');
            v = CharacterMetadata.applyStyle(v, 'color.#0088FF');
            return v;
          }
          return v;
        });
        block = block.set('characterList', characterList);
      },
    );
    return block;
  });
  blockMap = blockMap.merge(blocks);
  const newContentState = contentState.merge({
    blockMap,
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });
  newEditorState = EditorState.push(newEditorState, newContentState, 'adjust-depth');
  if (self) {
    // const parent = getParent(self) as any;
    // parent?.currentPage.setCurrentEditorState(newEditorState);
    // newEditorState = removeFirstBlock(self);
    return {rawData: JSON.stringify(convertToRaw(contentState)), editState: newEditorState};
  }
  return newEditorState;
};


const removeImage = (e, editorStates, currentPage, onChange) => {
  const tagkey = e.attributes['data-offset-key'].value.split('-')[0];
  const blockList = editorStates.getCurrentContent().getBlockMap();
  const block = blockList.find(key => key.getKey() == tagkey);
  if (block.getType() == 'atomic') {
    const next = currentPage.currentEditorState.getCurrentContent().getBlockAfter(block.getKey());

    if (block && next) {
      const removeSelection = new SelectionState({
        anchorKey: block.getKey(),
        anchorOffset: 0,
        focusKey: next.getKey(),
        focusOffset: 0,
      });

      const newData = Modifier.setBlockType(
        currentPage.currentEditorState.getCurrentContent(),
        removeSelection,
        'paragraph',
      );

      const newContentState = Modifier.removeRange(
        newData,
        removeSelection,
        'forward',
      );

      const newEditorState = EditorState.push(
        currentPage?.getCurrentEditorState,
        newContentState,
        'remove-range',
      );

      onChange(newEditorState);
    }
  }
};

const regenrateImg = async (e, editorStates, currentPage, onChange) => {
  e.children[2].classList.add('rotateIcon');
  e.children[1].style.cursor = 'not-allowed';
  const result = await currentPage?.regenerateImg({image_url: e?.firstChild?.src});
  const contentState = editorStates.getCurrentContent();
  const tagkey = e.attributes['data-offset-key'].value.split('-')[0];
  const blockList = editorStates.getCurrentContent().getBlockMap();
  const block = blockList.find(key => key.getKey() == tagkey);
  if (block.getType() == 'atomic') {
    const next = currentPage?.currentEditorState?.getCurrentContent()?.getBlockAfter(block.getKey());

    if (block && next) {
      const entityKey = block.getEntityAt(0);
      const entity = contentState.getEntity(entityKey);
      const newEntity = entity.set('data', {src: result});
      const newContentState = currentPage?.currentEditorState?.getCurrentContent()?.mergeEntityData(entityKey, newEntity.getData());
      const newED = EditorState.push(editorStates, newContentState, 'apply-entity');
      e.children[2].classList.remove('rotateIcon');
      e.children[1].style.cursor = '';
      onChange(newED);
    }
  }
};

export const addCrossIcon = (editorState, currentPage, onChange, handleModalOpen, isLinkgraph) => {
  let tooltip = document.getElementById('imageTooltip');
  let altTooltip = document.getElementById('imageAltTooltip');
  if (!tooltip) {
    tooltip = document.createElement('div');
    tooltip.id = 'imageTooltip';
    tooltip.classList.add('tooltiptext');
    tooltip.innerHTML = `Remove image and Generate another image`;
    document.body.appendChild(tooltip);
  }
  if (!altTooltip && isLinkgraph) {
    altTooltip = document.createElement('div');
    altTooltip.id = 'imageAltTooltip';
    altTooltip.classList.add('alttooltiptext');
    altTooltip.innerHTML = `Edit Alt Tag`;
    document.body.appendChild(altTooltip);
  }

  const newTextElems = document.querySelectorAll('[data-block="true"]');
  if (newTextElems?.length) {
    for (let i = 0; i <= newTextElems.length; i++) {
      if (newTextElems[i]?.tagName=='FIGURE' && newTextElems[i]?.lastElementChild?.tagName !== 'svg' && newTextElems[i]?.lastElementChild?.tagName !== 'SVG') {
        newTextElems[i].classList.add('customFigure');
        const svgElm = document.createElement('div');
        svgElm.innerHTML = isLinkgraph ? `${svg.trim()} ${reGenerateIcon.trim()} ${editAltIcon?.trim()}` : `${svg.trim()} ${reGenerateIcon.trim()}`;
        svgElm.firstChild.addEventListener('click', e => {
          e.stopPropagation();
          if (!newTextElems[i]?.lastChild['attributes']?.class?.value.includes('rotateIcon')) {
            removeImage(newTextElems[i], editorState, currentPage, onChange);
          }
        });
        if (newTextElems[i]?.firstChild) {
          newTextElems[i]?.firstChild['attributes']['alt']?.value && svgElm?.children[1].addEventListener('click', (e:any) => {
            e.stopPropagation();
            e.currentTarget.classList.length < 2 && regenrateImg(newTextElems[i], editorState, currentPage, onChange);
          });
        }
        if (newTextElems[i]?.firstChild && isLinkgraph) {
          svgElm?.lastChild.addEventListener('click', (e:any) => {
            e.stopPropagation();
            handleModalOpen(newTextElems[i], editorState, newTextElems[i]?.firstChild['attributes']['alt']?.value);
          });
        }
        svgElm?.children[1]?.addEventListener('mouseover', (e: any) => {
          e.stopPropagation();
          const rect = e.target.getBoundingClientRect();
          const imageTooltip = document.getElementById('imageTooltip') as HTMLElement;
          if (imageTooltip) {
            imageTooltip.style.display = 'block';
            imageTooltip.style.left = rect.left - 93 + 'px';
            imageTooltip.style.top = rect.top - 60 + 'px';
          }
        });
        svgElm?.children[1]?.addEventListener('mouseout', e => {
          e.stopPropagation();
          const imageTooltip = document.getElementById('imageTooltip');
          if (imageTooltip) {
            imageTooltip.style.display = 'none';
            imageTooltip.style.left = '0px';
            imageTooltip.style.top = '0px';
          }
        });
        isLinkgraph && svgElm?.lastChild?.addEventListener('mouseover', (e: any) => {
          e.stopPropagation();
          const rect = e.target.getBoundingClientRect();
          const imageAltTooltip = document.getElementById('imageAltTooltip') as HTMLElement;
          if (imageAltTooltip) {
            imageAltTooltip.style.display = 'block';
            imageAltTooltip.style.left = rect.left - 35 + 'px';
            imageAltTooltip.style.top = rect.top - 40 + 'px';
          }
        });
        isLinkgraph && svgElm?.lastChild?.addEventListener('mouseout', e => {
          e.stopPropagation();
          const imageAltTooltip = document.getElementById('imageAltTooltip');
          if (imageAltTooltip) {
            imageAltTooltip.style.display = 'none';
            imageAltTooltip.style.left = '0px';
            imageAltTooltip.style.top = '0px';
          }
        });
        const elem = svgElm?.firstChild as HTMLElement;
        const elemTwo = svgElm?.children[1] as HTMLElement;
        const elemThree = svgElm?.lastChild as HTMLElement;
        elem.classList.add(`crossIcon`);
        elemTwo.classList.add(`reLoadImg`);
        isLinkgraph && elemThree.classList.add(`editAltImg`);
        if (newTextElems[i]?.firstChild && !newTextElems[i]?.firstChild['attributes']['alt']?.value) elemTwo.style.cursor = 'not-allowed';
        newTextElems[i]?.appendChild(elem);
        newTextElems[i]?.firstChild['attributes']['alt'] && newTextElems[i]?.appendChild(elemTwo);
        isLinkgraph && newTextElems[i]?.appendChild(elemThree);
      }
    }
  }
};
