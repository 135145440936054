import {CommonInput} from '@/components/common-components';
import {observer} from 'mobx-react';
import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import styles from '../../style.module.scss';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  setExcludeTermsArray: (value?: string[]) => void;
  excludeTermsArray: string[];
  clearTermsToExclude: () => void;
  excludeTerm: string;
  setExcludeTerm: (value: string) => void;
  containerBorder?: string;
  inputBorder?: string;
  wrapperBackgroundColor?: string;
  inputBackgroundColor?: string;
  inputPlaceholder?: string;
}

export const TermsToExclude = observer(({
  setExcludeTermsArray,
  excludeTermsArray,
  clearTermsToExclude,
  excludeTerm,
  setExcludeTerm,
  containerBorder='',
  inputBorder='',
  wrapperBackgroundColor='',
  inputBackgroundColor='',
  inputPlaceholder='',
}: Props) => {
  const [showRemainingTerms, setShowRemainingTerms] = useState(false);

  const termsRef = useRef(null);

  // chekcs if focus terms input is in focus or not
  const myFunction = () => {
    if (document.activeElement === termsRef?.current?.input) {
      if (!excludeTerm || excludeTermsArray?.includes(excludeTerm.trim())) return;
      const updatedTerms = [...(excludeTerm?.split(',') ?? []), ...excludeTermsArray];
      setExcludeTermsArray(updatedTerms);
      setExcludeTerm('');
    }
  };

  // adds event hendler on enter key press
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        myFunction();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [excludeTerm]);

  const onTermRemove = (term: string) => {
    const updatedTerms = [...excludeTermsArray].filter(item => item !== term);
    setExcludeTermsArray(updatedTerms);
  };


  return <div className={styles.termsToIncludeContainer}>
    <div style={{background: wrapperBackgroundColor ? wrapperBackgroundColor : '#fff', borderRadius: 8, marginBottom: 20, border: containerBorder ? containerBorder : '1px solid #E8E8E8', overflow: 'hidden'}}>
      <CommonInputStyled
        border={inputBorder}
        backgroundColor={inputBackgroundColor}
        inputRef={termsRef}
        type='text'
        variant='white'
        value={excludeTerm}
        placeholder={inputPlaceholder}
        onChange={e => setExcludeTerm(e.target.value)}
        className={styles.input}/>
      <AddedTermsContainer>
        {excludeTermsArray?.slice(0, 8).map((item, idx) => {
          return <SingleAddedTerm key={idx}>{item}
            <FontAwesomeIcon
              icon={faXmark}
              style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
              onClick={() => onTermRemove(item)}
            />
          </SingleAddedTerm>;
        })}
        {(excludeTermsArray?.length > 9 && !showRemainingTerms) && <SingleAddedTerm style={{cursor: 'pointer'}} onClick={() => setShowRemainingTerms(true)}>
          {`+${excludeTermsArray.length - 9} related terms`}
          <FontAwesomeIcon
            icon={faCaretDown}
            style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
          />
        </SingleAddedTerm>}
        {showRemainingTerms && excludeTermsArray?.slice(9).map((item, idx) => {
          return <SingleAddedTerm key={idx}>{item}
            <FontAwesomeIcon
              icon={faXmark}
              style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
              onClick={() => onTermRemove(item)}
            />
          </SingleAddedTerm>;
        })}
        {excludeTermsArray?.length > 0 && <SingleAddedTerm style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => {
          setExcludeTermsArray([]);
          setShowRemainingTerms(false);
          clearTermsToExclude();
        }}>
        Clear All
        </SingleAddedTerm>}
      </AddedTermsContainer>
    </div>
  </div>;
});


const CommonInputStyled = styled(CommonInput)<{border?: string; boxShadow?: boolean; backgroundColor?: string}>`
  color: #121212 !important;
  border-radius: 6px !important;
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  border: ${p => p?.border ? p?.border : ''} !important;
  padding-right: 8px !important;
  background-color:${p => p?.backgroundColor ? p?.backgroundColor : ''} !important;

  &:focus {
    box-shadow: none !important;
  }
 
`;
const AddedTermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`;
const SingleAddedTerm = styled.div`
  background-color: #F9F9FB;
  padding: 3px 10px;
  border-radius: 38px;
  border: 1px solid  #e8e8e8;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
`;
