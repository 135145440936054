import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import {SingleAiTemplate} from './singleTemplate';
import {toJS} from 'mobx';
import {OpenAllTemplates} from './openAllTemplates';

export const TemplatesSection = observer(() => {
  const {contentOptimizer: {
    aiOutline: {
      setIsOpenAiWriterDrawer,
      setIsOpenAiContentToolsDrawer,
      aiTemplates,
      setAiTemplate,
      aiTemplatesController,
    },
  }} = useStore('');
  const {settings: {customer: {profile}}} = useStore('');

  const hardCodedData = require('@/constants/hardCodedData.json');
  // eslint-disable-next-line
  const [bookmarkedTemplates, setBookmarkedTemplates] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [templatesCount, setTemplatesCount] = useState(0);

  const defaultTempaltesNames = {
    aiContentGenerator: 'AI Content Generator',
    aiFirstDraftGenerator: 'First Draft Generator',
    aiImageGenerator: 'AI Image Generation',
  };

  const [defaultTemplates] = useState([
    {
      label: defaultTempaltesNames.aiContentGenerator,
      type: '',
      description: 'Generate content based on topics and keywords',
      inputs: null,
      isDefault: true,
      defaultType: 'ContentIdea',
      imageUrl: 'https://storage.googleapis.com/sagroup-static/searchatlas/writing_hand.png',
    },
    {
      label: defaultTempaltesNames.aiFirstDraftGenerator,
      type: '',
      description: '',
      inputs: null,
      isDefault: true,
      defaultType: 'Turn your content outline into a first draft in seconds',
      imageUrl: 'https://storage.googleapis.com/sagroup-static/searchatlas/writing_hand.png',
    },
  ]);

  // after templates list is loaded, if there is data use api response
  // if there is no data use hardcoded JSON
  useEffect(() => {
    // Gets bookmarked templates from local storage
    const localStorageBookmarks = localStorage.getItem('bookmarked-templates');
    const prepBookmarkList = [];
    if (localStorageBookmarks) {
      const parsedData = JSON.parse(localStorageBookmarks);

      if (parsedData[profile.id]) {
        prepBookmarkList.push(...(parsedData[profile.id] ?? []));
      }
    } else {
      // if there are no user bookmarks saved at all, we are making one bookmark prop for this user and setting default templates bookmarked
      prepBookmarkList.push(...[defaultTempaltesNames.aiContentGenerator, defaultTempaltesNames.aiFirstDraftGenerator, defaultTempaltesNames.aiImageGenerator]);
      localStorage.setItem('bookmarked-templates', JSON.stringify({[profile.id]: prepBookmarkList}));
    }
    setBookmarkedTemplates(prepBookmarkList);

    // Gets templates data from API and prepares templates list for use
    const nonDefaultTemplatesToUse = [];

    if (aiTemplates?.templates?.length) {
      nonDefaultTemplatesToUse.push(...(aiTemplates?.templates ?? []));
    } else {
      nonDefaultTemplatesToUse.push(...(hardCodedData ?? []));
    }


    const fullTemplatesList = [...defaultTemplates, ...nonDefaultTemplatesToUse ?? []];
    setTemplatesCount(fullTemplatesList?.length);
    // separating bookmarked from non bukmarked templates so we can order list properly
    // default templates go first!

    const bookmarkedList = fullTemplatesList.filter(template => prepBookmarkList?.includes(template.label));
    const finalPrepArr = [...(bookmarkedList ?? [])];
    setTemplatesList(finalPrepArr);
  }, [aiTemplates, aiTemplates?.categories?.length, aiTemplates?.templates?.length]);


  const switchDrawers = () => {
    setIsOpenAiWriterDrawer(false);
    setIsOpenAiContentToolsDrawer(true);
  };

  const onDefaultTemplateClickHandler = (template: any) => {
    setAiTemplate({
      ...toJS(aiTemplatesController ?? []),
      showForm: true,
      templateType: template.type ?? '',
      templateIcon: template.imageUrl ?? '',
      templateName: template.label ?? '',
      templateDesc: template.description ?? '',
      fieldsSetup: template.inputs ?? [],
      defaultForm: template.isDefault,
      defaultFormType: template.defaultType,
      isStandaloneTool: false,
      isUseMask: true,
    });
    switchDrawers();
  };


  // On click on one of the templates that came in from API
  const onTemplateClickHandler = (template: any) => {
    setAiTemplate({
      ...toJS(aiTemplatesController ?? []),
      showForm: true,
      templateType: template.type ?? '',
      templateIcon: template.imageUrl ?? '',
      templateName: template.label ?? '',
      templateDesc: template.description ?? '',
      fieldsSetup: toJS(template)?.inputs ?? [],
      defaultForm: false,
      defaultFormType: null,
      isStandaloneTool: false,
      isUseMask: true,
    });
    switchDrawers();
  };

  return <div style={{marginTop: '80px'}}>
    <div style={{fontSize: 16, fontWeight: 500, color: '#121212'}}>But wait, there’s more!</div>
    <div style={{color: '#4E5156'}}>Dozens of AI Writing Templates that fit your specific need.
Save your favorites for quick access</div>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {templatesList?.map((template, idx) => {
        return <SingleAiTemplate template={template}
          key={idx}
          onTemplateClick={template?.isDefault ? onDefaultTemplateClickHandler : onTemplateClickHandler }/>;
      })}
      <OpenAllTemplates onTemplateClick={() => switchDrawers()} templatesCount={templatesCount}/>
    </div>

  </div>;
});
