import {observer} from 'mobx-react';
import {
  ApplyBtnWrapper,
  ApplyButton,
  CustomRangeContainer,
  FilterTag,
  FilterTagText,
  FiltersSectionWrapper,
  FiltersWrapper,
  RadioGropContainer,
  CheckboxGroupStyled,
  RangeInputContainer,
  TextBlack} from '../../styledComponents';
import styles from '../../style.module.scss';
import {Menu, Dropdown, Tooltip, Checkbox} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {CountryDropdown} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {useTranslation} from 'next-i18next';
import {useEffect, useRef} from 'react';

interface Props {
  initialFiltersList?: any;
  setInitialFiltersList?: (value: any) => void;
  selectedCountry?: string;
  setSelectedCountry?: (value: string) => void;
  tldValue?: string[];
  setTldValue?: (value: string[]) => void;
  drFrom?: string;
  setdrFrom?: (value: string | ((prev: any) => string)) => void;
  drTo?: string;
  setdrTo?: (value: string) => void;
  trafficFrom?: string;
  settrafficFrom?: (value: string | ((prev: any) => string)) => void;
  trafficTo?: string;
  settrafficTo?: (value: string) => void;
  kcFrom?: string;
  setkcFrom?: (value: string | ((prev: any) => string)) => void;
  kcTo?: string;
  setkcTo?: (value: string) => void;
  refDoFrom?: string;
  setrefDoFrom?: (value: string | ((prev: any) => string)) => void;
  refDoTo?: string;
  setrefDoTo?: (value: string) => void;
  showWorldWide?: boolean;
}

export const FiltersSection = observer(({
  initialFiltersList,
  setInitialFiltersList,
  selectedCountry,
  setSelectedCountry,
  tldValue,
  setTldValue,
  drFrom,
  setdrFrom,
  drTo,
  setdrTo,
  trafficFrom,
  settrafficFrom,
  trafficTo,
  settrafficTo,
  kcFrom,
  setkcFrom,
  kcTo,
  setkcTo,
  refDoFrom,
  setrefDoFrom,
  refDoTo,
  setrefDoTo,
  showWorldWide =true,
}: Props) => {
  const {campaigns: {showErrors},
    settings: {customer: {profile: {address}}}} = useStore('');
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (address?.defaultLocation) {
      onChange(address?.defaultLocation);
    }
  }, [address?.defaultLocation]);

  const onChange = value => {
    if (!initialRenderRef.current) {
      setSelectedCountry(value);
    } else {
      initialRenderRef.current = false;
    }
  };

  const {t} = useTranslation('common');

  const tldValueOnChange = value => {
    setTldValue(value);
  };

  const applyFilterOnClickHandler = () => {
    const updatedFiltersList = initialFiltersList.map(filter => {
      let isActive;
      switch (filter.id) {
        case 0:
          return {...filter, value: tldValue, active: !!tldValue};
        case 1:
          isActive = (Number(drFrom) >= 0 && drFrom?.toString()) || Number(drTo) > 0;
          if (isActive) setdrFrom(prev => prev || '0');
          return {...filter, from: isActive ? drFrom || '0' : drFrom, to: drTo, active: isActive};
        case 2:
          isActive = (Number(trafficFrom) >= 0 && trafficFrom?.toString()) || Number(trafficTo) > 0;
          if (isActive) settrafficFrom(prev => prev || '0');
          return {...filter, from: isActive ? trafficFrom || '0' : trafficFrom, to: trafficTo, active: isActive};
        case 3:
          isActive = (Number(kcFrom) >= 0 && kcFrom?.toString()) || Number(kcTo) > 0;
          if (isActive) setkcFrom(prev => prev || '0');
          return {...filter, from: isActive ? kcFrom || '0' : kcFrom, to: kcTo, active: isActive};
        case 4:
          isActive = (Number(refDoFrom) >= 0 && refDoFrom?.toString()) || Number(refDoTo) > 0;
          if (isActive) setrefDoFrom(prev => prev || '0');
          return {...filter, from: isActive ? refDoFrom || '0' : refDoFrom, to: refDoTo, active: isActive};
        default:
          return filter;
      }
    });

    setInitialFiltersList(updatedFiltersList);
  };

  const setInitialTldValues = (type, visibility) => {
    if (type === 'checkboxesFilter' && !visibility) {
      setTldValue(initialFiltersList?.find(filter => filter?.name === 'checkboxesFilter')?.value || ['all']);
    }
  };

  const getTldLabel = val => val === 'all' ? 'All' : val;
  const getTldDropdownLabel = radioFilters => {
    if (radioFilters?.value?.length === radioFilters?.filterTypes?.length) return 'All';
    const label = radioFilters?.value?.length > 1 ? `${getTldLabel(radioFilters?.value[0])}, +${radioFilters?.value?.length - 1}` : getTldLabel(radioFilters?.value[0]);
    return label || 'All';
  };
  const selectAllTlds = (item, checked) => {
    const values = checked ? item?.filterTypes?.map(obj => obj?.value) : [];
    setTldValue(values);
  };

  return <FiltersSectionWrapper>
    <div style={{color: '#121212', fontSize: '12px', marginRight: '45px'}}>Filters</div>
    <FiltersWrapper>
      <CountryDropdown
        showSearch
        aligned={true}
        variant={'light'}
        suffixIcon={<FontAwesomeIcon color='#fff' icon={faCaretDown}/>}
        className={styles.selectInput}
        dropdownStyle={{backgroundColor: '#2D2F34', color: '#fff'}}
        value={selectedCountry}
        onChange={onChange}
        smallDropdown={true}
        showWorldWide={showWorldWide}
      />
      {initialFiltersList?.map((item, idx) => <Dropdown key={idx} trigger={['click']} placement='bottomLeft' onVisibleChange={v => setInitialTldValues(item?.name, v)}
        overlay={
          <Menu style={{width: item?.name == 'checkboxesFilter' ? 136 : 200, borderRadius: '10px'}}>
            {item?.name == 'checkboxesFilter' &&
              <CustomRangeContainer>
                <RadioGropContainer>
                  <Checkbox onChange={e => selectAllTlds(item, e?.target?.checked)} checked={tldValue?.length === item?.filterTypes?.length}>
                    All TLDs
                  </Checkbox>
                  <CheckboxGroupStyled options={item?.filterTypes} value={tldValue} onChange={e => tldValueOnChange(e)} />
                </RadioGropContainer>
                <Tooltip title={!tldValue?.length ? 'Please select at least one option.' : ''}>
                  <ApplyBtnWrapper>
                    <ApplyButton
                      style={{width: '104px'}}
                      onClick={() => applyFilterOnClickHandler()}
                      disabled={tldValue?.length <= 0}
                    >
                      {t('apply-filter')}
                    </ApplyButton>
                  </ApplyBtnWrapper>
                </Tooltip>
              </CustomRangeContainer>}
            {item?.type == 'range' &&
            <CustomRangeContainer>
              <TextBlack>Custom range</TextBlack>
              <RangeInputContainer>
                <input
                  value={
                    item?.name == 'domain-rating' && drFrom?.toString() ||
                    item?.name == 'traffic' && trafficFrom?.toString() ||
                    item?.name == 'keyword-count' && kcFrom?.toString() ||
                    item?.name == 'ref-domains' && refDoFrom?.toString()
                  }
                  placeholder='From'
                  type={'number'}
                  onChange={e=> {
                    const input = e.target.value;

                    const sanitizedInput = input == '0' ? input : input.replace(/^0+|[^0-9]/g, '');

                    item?.name == 'domain-rating' && setdrFrom(sanitizedInput);
                    item?.name == 'traffic' && settrafficFrom(sanitizedInput);
                    item?.name == 'keyword-count' && setkcFrom(sanitizedInput);
                    item?.name == 'ref-domains' && setrefDoFrom(sanitizedInput);
                  }}
                />
                <input
                  value={
                    item?.name == 'domain-rating' && drTo ||
                    item?.name == 'traffic' && trafficTo ||
                    item?.name == 'keyword-count' && kcTo ||
                    item?.name == 'ref-domains' && refDoTo
                  }
                  type={'number'}
                  placeholder= {item?.active && !item?.to ? 'Max' : 'To'}
                  onChange={e=> {
                    const input = e.target.value;

                    const sanitizedInput = input.replace(/^0+|[^0-9]/g, '');

                    item?.name == 'domain-rating' && setdrTo(sanitizedInput);
                    item?.name == 'traffic' && settrafficTo(sanitizedInput);
                    item?.name == 'keyword-count' && setkcTo(sanitizedInput);
                    item?.name == 'ref-domains' && setrefDoTo(sanitizedInput);
                  }}
                />
              </RangeInputContainer>
              <ApplyBtnWrapper>
                <ApplyButton
                  onClick={() => applyFilterOnClickHandler()}
                >
                  Apply Filter
                </ApplyButton>
              </ApplyBtnWrapper>
            </CustomRangeContainer>}
          </Menu>
        }>
        <FilterTag isError={showErrors && item?.name == 'checkboxesFilter' && !tldValue} isDarkTheme={false} selected={false} onClick={()=> {}}>
          <FilterTagText isDarkTheme={false} selected={false}>
            <span style={{marginRight: '4px'}}>{item?.header}: {!item?.active ? item?.name == 'checkboxesFilter' ? getTldDropdownLabel(item) : 'Any' : item?.name == 'checkboxesFilter' ? getTldDropdownLabel(item) : `${item?.from}-${item?.to || 'Max'}`}&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown}/></span>
          </FilterTagText>
        </FilterTag>
      </Dropdown>)}
    </FiltersWrapper>
  </FiltersSectionWrapper>;
});
