import {useState} from 'react';
import {StyledInput} from '../../styledComponents';

export const SocialMediaInput = ({value, onChange, placeholder, validateUrl, socialMediaName}) => {
  const [error, setError] = useState(false);

  const handleInputChange = e => {
    onChange(e.target.value);
    setError(!validateUrl(e.target.value) && e.target.value);
  };

  return (
    <>
      <StyledInput
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        width='100%'
        height='32px'
        borderRadius='8px'
        style={{background: '#F9F9FB'}}
      />
      {error && <div style={{color: 'red', fontSize: '12px'}}>Invalid {socialMediaName} URL</div>}
    </>
  );
};
