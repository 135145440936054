import {types, Instance, cast} from 'mobx-state-tree';
import html2canvas from 'html2canvas';
import {flow} from 'mobx-state-tree';

let JSpdf = null;
if (typeof window !== 'undefined') {
  import('jspdf').then(module => {
    JSpdf = module.default;
  });
}

export const GscExportStore = types
  .model({
    isSelecting: types.boolean,
    isExporting: types.boolean,
    isPruningPage: types.boolean,
    selectedSections: types.array(types.string),
    exportType: types.string,
    totalSections: 12,
    exportToCSVLoader: types.boolean,
  })
  .actions(self => {
    const toggleIsSelecting = () => {
      self.isSelecting = !self.isSelecting;
      clearSelected();
    };

    const clearSelected = () => {
      self.selectedSections = cast([]);
    };

    const setIsExporting = (state: boolean) => {
      self.isExporting = state;
    };

    const selectSection = (checked, section) => {
      if (checked) {
        self.selectedSections = cast([...self.selectedSections, section]);
      } else {
        self.selectedSections = cast(self.selectedSections.filter(x => x !== section));
      }
    };

    const selectAllSections = () => {
      self.selectedSections = cast([
        'serp',
        'stats',
        'topPages',
        'searchAppearance',
        'traffic',
        'googleChange',
        'pageGroup',
        'statsSerp',
        'devicePerformance',
        'countryBreakdown',
        'siteMapInsight',
        'keywordSearch',
      ]);
    };

    const selectAllSectionsPruningPage = () => {
      self.selectedSections = cast([
        'graph',
        'pageOverview',
        'pruningPagesTable',
      ]);
    };

    const setIsPruningPage = (value: boolean) => {
      self.isPruningPage = value;
    };

    const setExportType = (type: string) => {
      self.exportType = type;
    };

    const exportSingleNode = (node, siteProperty = '') => {
      const today = new Date();
      const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      html2canvas(node)
        .then(canvas => {
          canvas.style.display = 'none';
          const image = canvas.toDataURL();
          if (self.exportType === 'Image') {
            const a = document.createElement('a');
            a.setAttribute('download', `google-search-console-${siteProperty}-${date}.png`);
            a.setAttribute('href', image);
            a.click();
          } else {
            const pdf = new JSpdf({
              orientation: 'p',
              unit: 'pt',
              format: [canvas.width, canvas.height],
            });
            pdf.addImage(image, 'JPEG', 0, 0);
            pdf.save('google-search-console.pdf');
          }
        });
    };

    const exportMultiNodes = flow(function* (nodes) {
      self.isSelecting = false;
      setIsExporting(true);
      const exportNode = document.createElement('div');
      exportNode.setAttribute('id', 'export');
      window.scrollTo(0, 0);

      // Node clone added so nodes don't get removed from the page
      nodes.forEach(node => {
        exportNode.appendChild(node);
      });

      document.body.appendChild(exportNode);
      yield html2canvas(exportNode)
        .then(canvas => {
          canvas.style.display = 'none';
          const image = canvas.toDataURL();
          if (self.exportType === 'Image') {
            const a = document.createElement('a');
            a.setAttribute('download', 'google-search-console.png');
            a.setAttribute('href', image);
            a.click();
          } else {
            const pdf = new JSpdf({
              orientation: 'l',
              unit: 'pt',
              format: [canvas.width, canvas.height],
            });
            pdf.addImage(image, 'JPEG', 0, 0);
            pdf.save('google-search-console.pdf');
          }
          exportNode.remove();
        });
      setIsExporting(false);
    });

    return {
      toggleIsSelecting,
      selectSection,
      setIsPruningPage,
      setIsExporting,
      setExportType,
      exportSingleNode,
      clearSelected,
      selectAllSections,
      selectAllSectionsPruningPage,
      exportMultiNodes,
    };
  });

export type GscExportStoreInstance = Instance<typeof GscExportStore>;

export const initGscExportStore = () => {
  return GscExportStore.create({
    selectedSections: [],
    isSelecting: false,
    isExporting: false,
    exportType: 'Image',
    isPruningPage: false,
    exportToCSVLoader: false,
  });
};
