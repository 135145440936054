import {types, Instance} from 'mobx-state-tree';
import {setPeriodIntoLocalStorage} from '@/api/common-utils';


export const Criteria = types.model({
  property: types.maybeNull(types.string),
  previousPeriodStart: types.string,
  previousPeriodEnd: types.string,
  currentPeriodStart: types.string,
  currentPeriodEnd: types.string,
  periodStatus: types.string,
  countryCode: types.maybeNull(types.string),
  pageNumber: types.number,
  pageSize: types.number,
  keywordPositionType: types.string,
}).actions(self => {
  const updateCriteria = ({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, periodStatus, countryCode, keywordPositionType}: {
    property: string | null;
    previousPeriodStart: string;
    previousPeriodEnd: string;
    currentPeriodStart: string;
    currentPeriodEnd: string;
    periodStatus: string;
    countryCode: string | null;
    keywordPositionType?: string;
  }) => {
    self.property = property;
    self.previousPeriodStart = previousPeriodStart;
    self.previousPeriodEnd = previousPeriodEnd;
    self.currentPeriodStart = currentPeriodStart;
    self.currentPeriodEnd = currentPeriodEnd;
    self.periodStatus = periodStatus == 'Invalid date' ? '' : periodStatus;
    self.countryCode = countryCode ? countryCode : '';
    self.keywordPositionType = keywordPositionType ?? 'new';

    setPeriodIntoLocalStorage(previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, periodStatus == 'Invalid date' ? '' : periodStatus);
  };

  const setKeywordPositionType = type => {
    self.keywordPositionType = type;
  };

  const updatePagination = ({pageNumber, pageSize}: {
    pageNumber: number;
    pageSize: number;
  }) => {
    self.pageNumber = pageNumber;
    self.pageSize = pageSize;
  };

  const clearCriteria = () => {
    self.property = '';
    self.previousPeriodStart = '';
    self.previousPeriodEnd = '';
    self.currentPeriodStart = '';
    self.currentPeriodEnd = '';
    self.pageNumber = 1;
    self.pageSize = 30;
    self.keywordPositionType = 'new';
  };

  return {
    updateCriteria,
    clearCriteria,
    updatePagination,
    setKeywordPositionType,
  };
});

export type CriteriaType = Instance<typeof Criteria>;

export function initCriteriaStore() {
  return Criteria.create({
    property: '',
    previousPeriodEnd: '',
    previousPeriodStart: '',
    currentPeriodEnd: '',
    currentPeriodStart: '',
    periodStatus: '',
    countryCode: '',
    pageNumber: 1,
    pageSize: 30,
    keywordPositionType: 'new',
  });
}
