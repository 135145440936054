import {observer} from 'mobx-react';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Tooltip} from 'antd';
import {RecipientsTooltip} from './recipients-tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/pro-regular-svg-icons';

interface Props{
data?: any;
teamEmail?: any;
handleAddRecipients?: (value)=> void;
isNewEmailSettings?: boolean;
}
export const Recipents: React.FC<Props> = observer(({
  data,
  teamEmail,
  handleAddRecipients,
  isNewEmailSettings,
}) => {
  const [visibleTooltip, setVisibleTooltip] = useState(false);

  return (
    <div>
      {data?.recipients?.length ?
        <SmallTagContainer style={{display: 'flex'}}>
          {data?.recipients?.length ? <>{data?.recipients?.slice(0, 2)?.map((item, idx) => {
            return <div key={`recipient_${idx}`}>
              <SmallTag>{item?.slice(0, 2)?.toUpperCase()}</SmallTag>
            </div>;
          })}<div className='pencilIcon'>
            <Tooltip
              placement={'bottom'}
              color='#fff'
              visible={visibleTooltip}
              onVisibleChange={open => setVisibleTooltip(open)}
              overlayInnerStyle={{width: '280px', height: 'auto'}}
              title={<RecipientsTooltip teamEamail={teamEmail} findData={data} setVisibleTooltip={setVisibleTooltip} handleAddRecipients={handleAddRecipients} isNewEmailSettings={isNewEmailSettings} />} trigger={['click']}>
              <FontAwesomeIcon icon={faPencil} />
            </Tooltip>
          </div></>: <Tooltip
            placement={'bottom'}
            color='#fff'
            visible={visibleTooltip}
            onVisibleChange={open => setVisibleTooltip(open)}
            overlayInnerStyle={{width: '280px', height: 'auto'}}
            title={<RecipientsTooltip teamEamail={teamEmail} findData={data} setVisibleTooltip={setVisibleTooltip} handleAddRecipients={handleAddRecipients} isNewEmailSettings={isNewEmailSettings}/>} trigger={['click']}>
            <FontAwesomeIcon icon={faPencil} style={{cursor: 'pointer'}}/>
          </Tooltip>}
          {data?.recipients?.length > 2 ? <SmallTag>+{data?.recipients?.length && data?.recipients?.length - 2}</SmallTag> : ''}
        </SmallTagContainer> :
        <>
          <Tooltip
            placement={'bottom'}
            color='#fff'
            visible={visibleTooltip}
            onVisibleChange={open => setVisibleTooltip(open)}
            overlayInnerStyle={{width: '280px', height: 'auto'}}
            title={<RecipientsTooltip teamEamail={teamEmail} findData={data} isNewRecipient={true} setVisibleTooltip={setVisibleTooltip} handleAddRecipients={handleAddRecipients} isNewEmailSettings={isNewEmailSettings}/>} trigger={['click']}>
            <FontAwesomeIcon icon={faPencil} style={{cursor: 'pointer'}} />
          </Tooltip>
        </>
      }
    </div>
  );
});
const SmallTag = styled.div`
  width: 30px;
  height: 30px;
  background: #EFEFF5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #4E5156;
  margin-right: 4px;
  cursor: pointer;
  
`;
const SmallTagContainer = styled.div`
  position: relative;
  width: fit-content;
  padding-right: 2px;
  .pencilIcon {
  display: none;
  }
  &:hover {
    .pencilIcon {
      display: block;
      position: absolute;
      right: -12px;
      top: 5px;
      cursor: pointer;
    }
  }
`;
