import {Button, Pagination, SkeletonHorizontalLoader} from '@/components/common-components/components';
import {Table} from '@/components/common-components/components/table';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
import React, {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import styles from '../style.module.scss';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {faCircleArrowRight} from '@fortawesome/pro-duotone-svg-icons';
import {faCircle, faFolderGear} from '@fortawesome/pro-solid-svg-icons';
import {Popover, Tooltip} from 'antd';
import {dateFormatMonth} from '@/utils/string';
import {ProjectsSelector} from '../projects-selector';
import {CaretDownOutlined} from '@ant-design/icons';
import Link from 'next/link';
import {StatusSelector} from '../status-selector-v2';
import {faEllipsisVertical} from '@fortawesome/pro-regular-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {handleNumberFormat} from '@/utils/number';
import {campaignFilters} from '@/store/campaign/data';
import {SortOrder} from 'antd/lib/table/interface';
import {getFilterValues, getKey, getValue} from '@/components/dashboard/pages/site-explorer-page/detail-page/components/functions';
import {copyAndModifyQueryParams} from '@/utils/url';
import {QuotaTracker} from '@/components/common-components/v2/quotaTracker';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';

import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';

interface CampaignTableProps {
  isInOTTO?: boolean;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
  componentIssueType: string;
}

export const CampaignTable: React.FC<CampaignTableProps> = observer(({isInOTTO, setCampaignsId, componentIssueType}) => {
  const {campaigns: {
    campaigns,
    campaignsLoader,
    deleteCampaign,
    updateCampaignFilter,
    setCampaignOrdering,
    allCampaignParams,
    setFoldersModalVisible,
    getCampaignFilter,
    foldersData,
    deleteCampaignLoading,
    campaignRepollingLoading,
    setCampaignPagination,
  },
  ottoV2Store: {
    getOttoV2Project,
  },
  settings: {customer: {profile: {quotaUtilization}}}} = useStore('');
  const router = useRouter();
  const [activeSort, setActiveSort] = useState('');
  const [folders, setFolders] = useState([]);
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));

  const getCampaignColumns = (loading: boolean) => {
    return (
      [
        {
          title: <div>CAMPAIGN /<br />START DATE</div>,
          dataIndex: 'startDate',
          key: 'startDate',
          align: 'left' as 'left',
          fixed: 'left' as 'left',
          sorter: false,
          width: '382px',
          render: (_, record) => {
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='left' margin= '0 10px'/>;
            if (record.name) {
              return (
                <FirstColumnWrapper>
                  {isInOTTO ? (
                    <CampaignName style={{color: '#1890FF', cursor: 'pointer'}} onClick={() => setCampaignsId(record?.id)}>
                      <div className='name'>{record.name}</div> <FontAwesomeIcon icon={faCircleArrowRight} color='#2d6cca'/>
                    </CampaignName>
                  ) : <Link legacyBehavior href={`/outreach/campaigns/${record?.id}`} passHref>
                    <a>
                      <CampaignName>
                        <div className='name'>{record.name}</div> <FontAwesomeIcon icon={faCircleArrowRight} color='#2d6cca'/>
                      </CampaignName>
                    </a>
                  </Link>}
                  <DateWrapper>{dateFormatMonth(record.createdAt)}</DateWrapper>
                </FirstColumnWrapper>
              );
            }
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          align: 'center' as 'center',
          sorter: false,
          width: '127px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='50px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <span style={{display: 'flex', justifyContent: 'flex-end'}}>
                <StatusSelector campaignId={record?.id} campaignStatus={record?.isActive ? 'ACTIVE' : 'PAUSED'} isInOTTO={isInOTTO}/>
              </span>
            );
          },
        },
        {
          title: <div>OPPORTUNITIES<br />DISCOVERED</div>,
          dataIndex: 'OpportunitiesDiscovered',
          key: 'OpportunitiesDiscovered',
          align: 'right' as 'right',
          sortFieldName: 'opportunities_total',
          sortOrder: (activeSort?.includes('opportunities_total') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
          sorter: true,
          width: '127px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <Tooltip title={record?.opportunities}>
                {record?.opportunities}
              </Tooltip>
            );
          },
        },
        {
          title: 'EMAILED',
          dataIndex: 'emailed',
          key: 'emailed',
          align: 'right' as 'right',
          sortFieldName: 'sent_emails_total',
          sortOrder: (activeSort?.includes('sent_emails_total') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
          sorter: true,
          width: '102px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <Tooltip title={record?.sentEmails}>
                {record?.sentEmails}
              </Tooltip>
            );
          },
        },
        {
          title: <div>RECEIVED<br/>REPLIES</div>,
          dataIndex: 'receivedReplies',
          key: 'receivedReplies',
          align: 'right' as 'right',
          sortFieldName: 'sent_emails_replied',
          sortOrder: (activeSort?.includes('sent_emails_replied') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
          sorter: true,
          width: '102px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <Tooltip title={record?.repliedEmails}>
                {record?.repliedEmails}
              </Tooltip>
            );
          },
        },
        {
          title: <div>RESPONSE<br />RATE</div>,
          dataIndex: 'emailResponseRate',
          key: 'emailResponseRate',
          sortFieldName: 'response_rate',
          sortOrder: (activeSort?.includes('response_rate') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
          sorter: true,
          width: '127px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <ResponseRateColumn
                responseRateBg={(record?.emailResponseRate >= 0 && record.emailResponseRate <= 10 ) ? '#f4434312' :
                  (record.emailResponseRate >= 11 && record.emailResponseRate <= 30) ? '#f1aa3e12' :
                    (record.emailResponseRate >= 31 && '#1fac4712')}
              >
                <FontAwesomeIcon icon={faCircle} color={(record.emailResponseRate >= 0 && record.emailResponseRate <= 10 ) ? '#f44343' :
                  (record.emailResponseRate >= 11 && record.emailResponseRate <= 30) ? '#f1aa3e' :
                    (record.emailResponseRate >= 31 && '#2ac155')}/>
                <Value>{handleNumberFormat(record?.emailResponseRate ? record?.emailResponseRate : 0)}%</Value>
              </ResponseRateColumn>
            );
          },
        },
        {
          title: 'KEYWORDS',
          dataIndex: 'keywords',
          key: 'keywords',
          align: 'center' as 'center',
          sortFieldName: 'keywords',
          sortOrder: (activeSort?.includes('keywords') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
          sorter: true,
          width: '127px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            const uniqueArray = record?.targetKeywords?.filter((v, i, a)=>a.findIndex(t=>(t===v))===i);
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              uniqueArray?.length > 0 ? <div style={{display: 'flex'}}>

                {uniqueArray?.length > 0 ? <Popover overlayClassName={styles.keywordsPopover} placement='bottomRight' content={
                  uniqueArray?.map((item, index) =>
                    <div key={index}>
                      {item}
                    </div>,
                  )} trigger='click'>
                  <div style={{marginLeft: '45px', padding: '7px 12px'}} className={styles.keywordItemTag}>
                    {uniqueArray.length} <CaretDownOutlined style={{fontSize: '12px'}} />
                  </div>
                </Popover> : null}
              </div> : <span style={{paddingLeft: '20px'}}>-</span>
            );
          },
        },
        {
          title: 'Domains',
          dataIndex: 'domains',
          key: 'domains',
          align: 'center' as 'center',
          sortFieldName: 'domains',
          sortOrder: (activeSort?.includes('domains') ? activeSort?.startsWith('-') ? 'descend' : 'ascend' : false) as SortOrder,
          sorter: true,
          width: '127px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            const uniqueArray = record?.domains?.filter((v, i, a)=>a.findIndex(t=>(t===v))===i);
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              uniqueArray?.length > 0 ? <div style={{display: 'flex'}}>
                {uniqueArray?.length > 0 ? <Popover overlayClassName={styles.keywordsPopover} placement='bottomRight' content={
                  uniqueArray?.map((item, index) =>
                    <div key={index}>
                      {item}
                    </div>,
                  )} trigger='click'>
                  <div style={{marginLeft: (uniqueArray?.length)?.toString()?.length > 2 ? 30 : 45, padding: '7px 12px'}} className={styles.keywordItemTag}>
                    {uniqueArray?.length} <CaretDownOutlined style={{fontSize: '12px'}} />
                  </div>
                </Popover> : null}
              </div> : <span style={{paddingLeft: '20px'}}>-</span>
            );
          },
        },
        {
          title: 'FOLDER',
          dataIndex: 'folder',
          key: 'folder',
          align: 'center' as 'center',
          sorter: false,
          width: '255px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) return <></>;
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <FreezeWrapper removeTooltip={record?.isActive || !isInOTTO}>
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: 'fit-content'}} className={styles.projectSelectorContainer}>
                  <ProjectsSelector
                    isFolderUpdate={true}
                    campaignId={record?.id}
                    projectId={record?.project ? record?.project : ''}
                    disabled={record?.aiInProgress}
                    graySmall={true}
                    isInOTTO={isInOTTO}/>
                </div>
              </FreezeWrapper>
            );
          },
        },
        {
          title: '',
          dataIndex: '',
          align: 'center' as 'center',
          sorter: false,
          width: '90px',
          skeletonAlign: 'flex-end',
          render: (_, record) => {
            if (campaignRepollingLoading == record?.id) {
              return <div style={{position: 'absolute', right: 28, top: '-10px'}}>
                <div style={{textAlign: 'start', margin: '12px 0px'}}>
                  <SkeletonHorizontalLoader height={'15px'} width={'300px'}/>
                  <StatusText style={{color: '#4E5156'}}>Creating new campaign...</StatusText>
                </div> </div>;
            }
            if (loading) return <SkeletonHorizontalLoaderGray lightLoader width='116px' height= '15px' skeletonHeight='10px' align='center' margin= '0 10px'/>;
            return (
              <ActionWrapper>
                {
                  isInOTTO ? <div onClick={()=>setCampaignsId(record?.id)}>
                    <ViewButton buttonType={'transparent'} size='sm'>View</ViewButton>
                  </div> :
                    <Link legacyBehavior href={`campaigns/${record?.id}`} passHref>
                      <a>
                        <ViewButton buttonType={'transparent'} size='sm'>View</ViewButton>
                      </a>
                    </Link>
                }
                <FreezeWrapper placement='topRight' removeTooltip={record?.isActive || !isInOTTO}>
                  <Popover
                    trigger='hover'
                    overlayStyle={{width: 150}}
                    placement='bottom'
                    content={
                      <div style={{display: 'flex'}}>
                        {deleteCampaignLoading && <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: 'red'}} spin />} />}
                      &nbsp;&nbsp;
                        <div style={{color: 'red', cursor: 'pointer'}} onClick={()=> isInOTTO ? deleteCampaign(record?.id, getOttoV2Project?.id) : deleteCampaign(record?.id)}>Delete</div>
                      </div>
                    }>
                    <FontAwesomeIcon icon={faEllipsisVertical}/>
                  </Popover>
                </FreezeWrapper>
              </ActionWrapper>
            );
          },
        },
      ]
    );
  };

  useEffect(() => {
    if (foldersData?.results?.length>0 && folders?.length<1) {
      setFolders(foldersData?.results.map(result => ({label: result?.name || '', value: `${result?.id}`})));
    }
  }, [foldersData?.results]);

  useEffect(() => {
    if (router?.query) {
      const newArr = [...campaignFilters(folders)];
      for (const [key, value] of Object.entries(router.query)) {
        const idx = newArr?.findIndex(item => [item?.name, item?.filterField, item?.customCategoryType].includes(key));
        if (newArr[idx] && value) {
          newArr[idx] = getFilterValues(newArr[idx], value) as any;
        }
      }
      if (isInOTTO) {
        updateCampaignFilter(newArr, getOttoV2Project?.id);
      } else {
        updateCampaignFilter(newArr);
      }
    }
  }, [router?.query, folders]);

  const handleFilterChange = filters => {
    const idx = filters?.findIndex(item=> item?.filterField == 'targetPage');
    filters[idx] = {
      ...filters[idx],
      type: 'targetPage',
    } as any;
    const filterParams = filters?.map(z => {
      return {
        key: getKey(z),
        value: getValue(z),
      };
    });
    router.push(`${copyAndModifyQueryParams(filterParams)}`, undefined, {shallow: true});
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
    setActiveSort(sorterKey);
    setCampaignOrdering({...allCampaignParams, ordering: sorterKey});
  };

  return (
    <div>
      <TableHeaderSeparated>
        <FiltersV2
          onChange={handleFilterChange}
          width='fit-content'
          mainFilters={getCampaignFilter}
          hideTopSection={true}
          inMoreBtnIds={[5, 6, 7]}
          simpleMoreBtn={isInOTTO ? false : true}
          isShowSorters={true}
        />
        {
          isInOTTO ? <QuotaContainer>
            <QuotaTracker consumedData={quotaUtilization?.ke?.allowedOutreachCampaigns?.consumed}
              totalData= {quotaUtilization?.ca?.allowedOttoAiSuggestions?.total} heading={'Campaigns'}
              fontSize={12} />
            <QuotaTracker consumedData={quotaUtilization?.ke?.allowedOutreachSentEmails?.consumed}
              totalData= {quotaUtilization?.ca?.allowedOttoAiSuggestions?.total} heading={'Emails sent'}
              fontSize={12} />
          </QuotaContainer> :
            <ManageFolderBtn onClick={() => setFoldersModalVisible(true)}>
              <FontAwesomeIcon icon={faFolderGear} color='#4E5156'/>
                Manage folders
            </ManageFolderBtn>
        }
      </TableHeaderSeparated>
      <TableWrapper>
        <Table
          className={styles.tableStyles}
          pagination={false}
          columns={getCampaignColumns(campaignsLoader)}
          scroll={{x: isInOTTO ? 1500 : 600, y: 600}}
          dataSource={campaigns?.results}
          onChange={handleTableChange}
          loading={campaignsLoader}
          loadingRows={10}
          rowClass={''}
        />
        <PaginationWrapper>
          <Pagination
            showSizeChanger
            style={{zIndex: 10}}
            showTotal= {(total, [from, to])=> {
              return (
                <PaginationSpan>
                  {from}-{to} {'of'} {total} {'results shown'}
                </PaginationSpan>
              );
            }}
            pageSizeOptions={['10', '20', '50', '100']}
            showQuickJumper={false}
            disabled={false}
            onChange={(page: number, pageSize: number) => {
              saveOttoTablePageSize(componentIssueType, pageSize);
              setPageSize(pageSize);
              setCampaignPagination(page, pageSize, getOttoV2Project?.id);
            }}
            total={campaigns?.count}
            pageSize={pageSize}
            current={allCampaignParams?.page}
            defaultPageSize={10}
            defaultCurrent={1}
          />
        </PaginationWrapper>
      </TableWrapper>
    </div>
  );
});

const PaginationWrapper = styled.div`
  padding: 20px 25px;
  display: flex;
  justify-content: flex-end;
`;
const TableHeaderSeparated = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  border-radius: 12px 12px 0px 0px;
`;
const TableWrapper = styled.div`
  background: #fff;
  border-radius: 0 0 12px 12px;
  padding: 10px 10px 0 10px;
  margin-bottom: 20px;
  .projectSelectorWrapper {
    margin-right: 0 !important;
  }

  table {
    .ant-table-column-sorter {
      display: flex;
      padding-left: 3px !important;
    }

    .ant-table-column-sorters {
      display: inline-flex !important;
      padding: 5px !important;
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }
        }
      }

      tr:nth-child(odd) {
        td {
          background: #fff !important;
          border-bottom: 1px solid #f4f4f4 !important;
          box-shadow: none;
          font-weight: 400;
          white-space: nowrap;
        }

        &:first-child {
          td {
            border-bottom: 0 solid transparent !important;
          }
        }

        &:hover {
          td {
            background: #eef4fe !important;
          }
        }
      }
      tr td {
        padding: 5px 10px;
      }

    }

    .ant-table-thead {

      .ant-table-column-has-sorters {
        text-align: center ;
  
        .ant-table-column-title {
          text-align: center;
          z-index: 0 !important;
        }
      }

      th {
        background: #f7f7f7 !important;
        color: #121212 !important;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        padding: 0 !important;
      }

      .ant-table-cell-fix-left {
        text-align: left ;
      }
    }

    tr th {
      height: 60px !important;
      line-height: 22px;
      white-space: nowrap;
    }

    tr th:first-child,
    tr td:first-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }

    tr th:last-child,
    tr td:last-child {
      padding-left: 25px !important;
      padding-right: 25px !important;
      text-align: center;
    }
  }
`;

const PaginationSpan = styled.span`
color: #a3a4a4;
padding: 40px 40px 0;
width: 100%;
`;

const CampaignName = styled.div`
  .name {
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const DateWrapper = styled.div``;
const FirstColumnWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  margin-left: 20px;

  ${CampaignName} {
  display: flex;
  gap: 10px;
  font-size: 14px;
  span{
  color: #2d6cca;
  cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
}

${DateWrapper} {
  font-size: 12px;
  color: #4e5156;
}
`;
const Value = styled.span``;
const ResponseRateColumn = styled.div<{ responseRateBg: string }>`
display: flex;
align-items: center;
background-color:${props => props.responseRateBg ? props.responseRateBg : ''};
border-radius: 6px;
padding: 8px 12px;
margin-left: 20px;
max-width: fit-content;
height: 35px;
gap: 6px;
svg {
  width: 10px;
  height: 10px;
}
${Value} {
color:#121212;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
}
`;
const ViewButton = styled(Button)``;
const ActionWrapper = styled.div`
display: flex;
align-items: center;
gap: 10px;
${ViewButton} {
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9fB;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  color: #121212;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
svg {
width: 6px;
height: 30px;
color: #4E5156;
cursor: pointer;
}
`;

const ManageFolderBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 145px;
  height: 28px;
  background: #F9F9FB;
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  cursor: pointer;
  color: #121212;
`;

const StatusText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;

const QuotaContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
`;
