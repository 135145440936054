import {types, Instance, cast} from 'mobx-state-tree';
import {flow} from 'mobx-state-tree';
import {CriteriaType} from '../criteria';
import {gscReportsApi} from '@/api/gsc';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {apiError} from '@/utils/api';
const results = types.model({
  avgPosCur: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  kw: types.maybeNull(types.string),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  topKeyword: types.maybeNull(types.string),
  serp13: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
});
const tokenBySearchIntent = types.model({
  informational: types.maybeNull(types.array(types.string)),
  transactional: types.maybeNull(types.array(types.string)),
  commercial: types.maybeNull(types.array(types.string)),


});
const searchIntentResults = types.model({
  avgPosCur: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  kw: types.maybeNull(types.string),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  highlightedKw: types.maybeNull(types.string),
  searchIntent: types.maybeNull(types.array(types.string)),
  tokensBySearchIntent: types.maybeNull(tokenBySearchIntent),
});

const longTailKeywordsTabledata = types.model({
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  results: types.maybeNull(types.array(results)),
});

const searchIntentTableData = types.model({
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  results: types.maybeNull(types.array(searchIntentResults)),

});

const keywordsMetaReport = types.model({
  page: types.maybeNull(types.string),
  topQuery: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  avgCtr: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  title: types.maybeNull(types.boolean),
  metaDesc: types.maybeNull(types.boolean),
});

const topQueryPerPage = types.model({
  page: types.maybeNull(types.string),
  topQuery: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  avgCtr: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  title: types.maybeNull(types.boolean),
  metaDesc: types.maybeNull(types.boolean),
  h1: types.maybeNull(types.boolean),
  h2: types.maybeNull(types.boolean),
  h3: types.maybeNull(types.boolean),
  p: types.maybeNull(types.boolean),
});

const cannibalizationReports = types.model({
  clicks: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  ctr: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  noOfPages: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  volume: types.maybeNull(types.number),
});

const contentThinTable = types.model({
  page: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  avgCtr: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

const newPagesResults = types.model({
  url: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  topKeywordByImpressions: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  serp13: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  impressionDelta: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  keywordsCountDelta: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  ctrDelta: types.maybeNull(types.number),
});

const WinnersQueryData = types.model({
  kw: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  opportunity_score: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  avgPosCur: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  ctrDelta: types.maybeNull(types.number),
});

const newPagesTable = types.model({
  results: types.maybeNull(types.array(newPagesResults)),
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
});
const daily = types.model({
  date: types.maybeNull(types.string),
  pos: types.maybeNull(types.number),
  averagePos: types.maybeNull(types.number),
  topPage: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
});
const dailyDatapoints = types.model({
  date: types.maybeNull(types.union(types.string, types.number)),
  //  posCur: null,
  //   posPrev: null,
  //   clicksCur: null,
  //   clicksPrev: null,
  //   impCur: null,
  //   impPrev: null
});
const data = types.model({
  daily: types.maybeNull(types.array(daily)),
  monthly: types.maybeNull(types.array(daily)),
  dailyDatapoints: types.maybeNull(types.array(dailyDatapoints)),
  latestPosition: types.maybeNull(types.number),
  latestTraffic: types.maybeNull(types.number),
  latestImpressions: types.maybeNull(types.number),
  latestCpc: types.maybeNull(types.number),
  latestSv: types.maybeNull(types.number),
  previousPosition: types.maybeNull(types.number),
  previousTraffic: types.maybeNull(types.number),
  previousImpressions: types.maybeNull(types.number),
});
const winnersKeywordsTable = types.model({
  results: types.maybeNull(types.array(WinnersQueryData)),
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
});
const winnersQueriesData = types.model({
  winnersKeywordsTable: types.maybeNull(winnersKeywordsTable),
  losersKeywordsTable: types.maybeNull(winnersKeywordsTable),


});

const newKeywordsTable = types.model({
  kw: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPre: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  searchVol: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentiaTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  avgPosCur: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  dateAdded: types.maybeNull(types.string),
});
const winnerPagesResults = types.model({
  url: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  topKeywordByImpressions: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  serp13: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  impressionDelta: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  keywordsCountDelta: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  ctrDelta: types.maybeNull(types.number),
});

const losersPagesTable = types.model({
  results: types.maybeNull(types.array(winnerPagesResults)),
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
});

const winnersPagesTable = types.model({
  results: types.maybeNull(types.array(winnerPagesResults)),
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
});

const winnerLoserPageData = types.model({
  winnersPagesTable: types.maybeNull(winnersPagesTable),
  losersPagesTable: types.maybeNull(losersPagesTable),
});

const keywords = types.model({
  keyword: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  avgCtr: types.maybeNull(types.number),
  title: types.maybeNull(types.boolean),
  metaDesc: types.maybeNull(types.boolean),
  h1: types.maybeNull(types.boolean),
  h2: types.maybeNull(types.boolean),
  h3: types.maybeNull(types.boolean),
  p: types.maybeNull(types.boolean),
});

const dailyHistorical = types.model({
  date: types.maybeNull(types.string),
  averagePos: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  kwCount: types.maybeNull(types.number),
});

const historical = types.model({
  daily: types.maybeNull(types.array(dailyHistorical)),
  monthly: types.maybeNull(types.array(dailyHistorical)),
  latestPosition: types.maybeNull(types.number),
  latestTraffic: types.maybeNull(types.number),
  latestImpressions: types.maybeNull(types.number),
  previousPosition: types.maybeNull(types.number),
  previousTraffic: types.maybeNull(types.number),
  previousImpressions: types.maybeNull(types.number),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
});

const topQueryPerPageDrawer = types.model({
  historical: types.maybeNull(historical),
  title: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  screenshotUrl: types.maybeNull(types.string),
  keywords: types.maybeNull(types.array(keywords)),
});

const allKeywordsTableData = types.model({
  kw: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  opportunity_score: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  avgPosCur: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
});
const allKeywordsTable = types.model({
  results: types.maybeNull(types.array(allKeywordsTableData)),
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
});

const DrawerResults = types.model({
  avgPosCur: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  impressionsDelta: types.maybeNull(types.number),
  kw: types.maybeNull(types.string),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
});

const keywordsData = types.model({
  results: types.maybeNull(types.array(DrawerResults)),
});

const pageData = types.model({
  data: types.maybeNull(data),
  keywords: types.maybeNull(keywordsData),
});


export const GscReportsStore = types

  .model({
    longTailKeywordsTable: types.maybeNull(longTailKeywordsTabledata),
    longTailTableLoading: types.maybeNull(types.boolean),
    strikingDistanceKeywordsTable: types.maybeNull(longTailKeywordsTabledata),
    strikingTableLoading: types.maybeNull(types.boolean),
    ctrOpportunitiesTable: types.maybeNull(longTailKeywordsTabledata),
    ctrOpportunitiesByPageTable: types.maybeNull(longTailKeywordsTabledata),
    ctrOpportunitiesTableLoading: types.maybeNull(types.boolean),
    ctrOpportunitiesByTableTableLoading: types.maybeNull(types.boolean),
    questionsTable: types.maybeNull(longTailKeywordsTabledata),
    questionsTableLoading: types.maybeNull(types.boolean),
    searchIntentTableLoading: types.maybeNull(types.boolean),
    keywordMetaReportTableLoading: types.maybeNull(types.boolean),
    searchIntentTable: types.maybeNull(searchIntentTableData),
    activeTab: types.maybeNull(types.string),
    topQueryPerPageTable: types.maybeNull(types.array(topQueryPerPage)),
    topQueryPerPageLoading: types.maybeNull(types.boolean),
    keywordsMetaReport: types.maybeNull(types.array(keywordsMetaReport)),
    cannibalizationReportsLoading: types.maybeNull(types.boolean),
    cannibalizationReports: types.maybeNull(types.array(cannibalizationReports)),
    contentThinTable: types.maybeNull(types.array(contentThinTable)),
    contentThinLoading: types.maybeNull(types.boolean),
    newPagesTable: types.maybeNull(newPagesTable),
    newPagesLoading: types.maybeNull(types.boolean),
    lowCtrByQueryDrawer: types.maybeNull(data),
    lowCtrByPageDrawer: types.maybeNull(pageData),
    lowCtrByQueryDrawerLoading: types.maybeNull(types.boolean),
    lowCtrByPageDrawerLoading: types.maybeNull(types.boolean),
    winnersQueriesData: types.maybeNull(winnersQueriesData),
    winnersQueriesDataLoading: types.maybeNull(types.boolean),
    newKeywordsTable: types.maybeNull(types.array(newKeywordsTable)),
    newKeywordsLoading: types.maybeNull(types.boolean),
    winnerLoserPagesReport: types.maybeNull(winnerLoserPageData),
    winnerLoserPagesLoading: types.maybeNull(types.boolean),
    topQueryPerPageDrawer: types.maybeNull(topQueryPerPageDrawer),
    topQueryPerPageDrawerLoading: types.maybeNull(types.boolean),
    allKeywordsTableLoading: types.maybeNull(types.boolean),
    allKeywordsTable: types.maybeNull(allKeywordsTable),
    allPagesTableLoading: types.maybeNull(types.boolean),
    allPagesTable: types.maybeNull(allKeywordsTable),
    cannibalizationReportsCount: types.maybeNull(types.number),
    newKeywordsTableCount: types.maybeNull(types.number),
  }).views(self => ({
    get getLowCtrByPageDrawerData() {
      return toJS(self.lowCtrByPageDrawer);
    },
  })).actions(self => {
    const setActiveTab = (value: string) => {
      self.activeTab = value;
    };
    const getLowCtrByQueryDrawer = flow(function* (criteria: CriteriaType, page) {
      self.lowCtrByQueryDrawerLoading = true;
      self.lowCtrByQueryDrawer = null;
      try {
        const response = yield gscReportsApi.getLowCtrByQueryDrawer(criteria, page);
        if (response) {
          self.lowCtrByQueryDrawer = cast(response?.data);
          return response;
        }
      } catch (e) {
        const status = e?.response?.status;
        if (status == 404) {
          self.lowCtrByQueryDrawer = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.lowCtrByQueryDrawerLoading = false;
      }
    });
    const getLowCtrByPageDrawer = flow(function* (criteria: CriteriaType, page) {
      self.lowCtrByPageDrawer = null;
      self.lowCtrByPageDrawerLoading = true;
      try {
        const response = yield gscReportsApi.getLowCtrByPageDrawer(criteria, page);
        if (response) {
          self.lowCtrByPageDrawer = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.lowCtrByPageDrawer = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.lowCtrByPageDrawerLoading = false;
      }
    });

    const getWinnerLoserPagesReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.lowCtrByPageDrawer = null;
      self.winnerLoserPagesLoading = true;
      try {
        const response = yield gscReportsApi.getWinnerLoserPagesReport(criteria, pageNumber);
        if (response) {
          self.winnerLoserPagesReport = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.winnerLoserPagesReport = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.winnerLoserPagesLoading = false;
      }
    });

    const getNewPagesReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.lowCtrByPageDrawer = null;
      self.newPagesLoading = true;
      try {
        const response = yield gscReportsApi.getNewPagesReport(criteria, pageNumber);
        if (response) {
          self.newPagesTable = response?.newPagesTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.newPagesTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.newPagesLoading = false;
      }
    });

    const getAllKeywordsReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.allKeywordsTable = null;
      self.allKeywordsTableLoading = true;
      try {
        const response = yield gscReportsApi.getAllKeywordsReport(criteria, pageNumber);
        if (response) {
          self.allKeywordsTable = response?.keywordsTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.allKeywordsTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.allKeywordsTableLoading = false;
      }
    });

    const getAllPagesReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.allPagesTable = null;
      self.allPagesTableLoading = true;
      try {
        const response = yield gscReportsApi.getAllPagesReport(criteria, pageNumber);
        if (response) {
          self.allPagesTable = response?.pagesTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.allPagesTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.allPagesTableLoading = false;
      }
    });

    const getNewKeywordsReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.newKeywordsLoading = true;
      self.newKeywordsTable = null;
      try {
        const response = yield gscReportsApi.getNewKeywordsReport(criteria, pageNumber);
        if (response) {
          self.newKeywordsTable = response?.keywordPositionChangesTable?.newKeywords;
          self.newKeywordsTableCount = response?.keywordPositionChangesTable?.totalNewKeywords;

          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.newKeywordsTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.newKeywordsLoading = false;
      }
    });
    const getContentThinReport = flow(function* (criteria: CriteriaType) {
      self.contentThinTable = null;
      self.contentThinLoading = true;
      try {
        const response = yield gscReportsApi.getContentThinReport(criteria);
        if (response) {
          self.contentThinTable = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.contentThinTable = null;
        }
        if (status == 400) {
          self.contentThinTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.contentThinLoading = false;
      }
    });
    const getLongTailKeywordsData = flow(function* (criteria: CriteriaType, pageNumber) {
      self.longTailTableLoading = true;
      self.longTailKeywordsTable = null;
      try {
        const response = yield gscReportsApi.getLongTailKeywordsReport(criteria, pageNumber);
        if (response) {
          self.longTailKeywordsTable = response?.longTailKeywordsTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.longTailKeywordsTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.longTailTableLoading = false;
      }
    });
    const getStrikingKeywordsReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.strikingTableLoading = true;
      self.strikingDistanceKeywordsTable = null;
      try {
        const response = yield gscReportsApi.getStrikingKeywordsReport(criteria, pageNumber);
        if (response) {
          self.strikingDistanceKeywordsTable = response?.strikingDistanceKeywordsTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.strikingDistanceKeywordsTable = null;
        }
        if (status == 400) {
          self.strikingDistanceKeywordsTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.strikingTableLoading = false;
      }
    });
    const getTopQueryPerPage = flow(function* (criteria: CriteriaType) {
      self.topQueryPerPageLoading = true;
      self.topQueryPerPageTable = null;
      try {
        const response = yield gscReportsApi.getTopQueryPerPage(criteria);
        if (response?.isCancel) return;
        if (response) {
          self.topQueryPerPageTable = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.topQueryPerPageTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.topQueryPerPageLoading = false;
      }
    });
    const getTopQueryPerPageDrawer = flow(function* (criteria: CriteriaType, pageUrl) {
      self.topQueryPerPageDrawerLoading = true;
      self.topQueryPerPageDrawer = null;
      try {
        const response = yield gscReportsApi.getTopQueryPerPageDrawer(criteria, pageUrl);
        if (response) {
          self.topQueryPerPageDrawer = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.topQueryPerPageDrawer = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.topQueryPerPageDrawerLoading = false;
      }
    });
    const getLowCtrQueryReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.ctrOpportunitiesTableLoading = true;
      self.ctrOpportunitiesTable = null;
      try {
        const response = yield gscReportsApi.getLowCtrQueryReport(criteria, pageNumber);
        if (response) {
          self.ctrOpportunitiesTable = response?.ctrOpportunitiesTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.ctrOpportunitiesTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.ctrOpportunitiesTableLoading = false;
      }
    });
    const getKeywordMetaReport = flow(function* (criteria: CriteriaType) {
      self.keywordMetaReportTableLoading = true;
      self.keywordsMetaReport = null;
      try {
        const response = yield gscReportsApi.getkeywordMetaReport(criteria);
        if (response) {
          self.keywordsMetaReport = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.keywordsMetaReport = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.keywordMetaReportTableLoading = false;
      }
    });

    const getLowCtrPageReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.ctrOpportunitiesByTableTableLoading = true;
      self.ctrOpportunitiesByPageTable = null;
      try {
        const response = yield gscReportsApi.getLowCtrPageReport(criteria, pageNumber);
        if (response) {
          self.ctrOpportunitiesByPageTable = response?.ctrOpportunitiesByPageTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.ctrOpportunitiesByPageTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.ctrOpportunitiesByTableTableLoading = false;
      }
    });

    const getWinnerQueriesDataReport = flow(function* (criteria: CriteriaType, pageNumber) {
      self.winnersQueriesDataLoading = true;
      self.winnersQueriesData = null;
      try {
        const response = yield gscReportsApi.getWinnerQueriesDataReport(criteria, pageNumber);
        if (response) {
          self.winnersQueriesData = response;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.winnersQueriesData = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.winnersQueriesDataLoading = false;
      }
    });

    const getKeywordCannibalizationReport = flow(function* (criteria: CriteriaType, pageSize, pageNumber) {
      self.cannibalizationReportsLoading = true;
      self.cannibalizationReports = null;
      try {
        const response = yield gscReportsApi.getKeywordCannibalizationReport(criteria, pageSize, pageNumber);
        if (response) {
          self.cannibalizationReports = response?.keywords;
          self.cannibalizationReportsCount = response?.count;

          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.cannibalizationReports = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.cannibalizationReportsLoading = false;
      }
    });

    const getKeywordQuestions = flow(function* (criteria: CriteriaType, pageNumber) {
      self.questionsTableLoading = true;
      self.questionsTable = null;
      try {
        const response = yield gscReportsApi.getKeywordQuestions(criteria, pageNumber);
        if (response) {
          self.questionsTable = response?.questionsTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.questionsTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.questionsTableLoading = false;
      }
    });
    const getSearchIntent = flow(function* (criteria: CriteriaType, pageNumber, searchIntent?: string) {
      self.searchIntentTableLoading = true;
      self.searchIntentTable = null;
      try {
        const response = yield gscReportsApi.getSearchIntent(criteria, pageNumber, searchIntent);
        if (response) {
          self.searchIntentTable = response?.questionsTable;
          return response;
        }
      } catch (e) {
        const {status} = e.response;
        if (status == 404) {
          self.searchIntentTable = null;
        }
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      } finally {
        self.searchIntentTableLoading = false;
      }
    });

    return {
      getLowCtrByPageDrawer,
      getNewKeywordsReport,
      getKeywordMetaReport,
      getKeywordCannibalizationReport,
      getKeywordQuestions,
      getStrikingKeywordsReport,
      getLongTailKeywordsData,
      getLowCtrQueryReport,
      getSearchIntent,
      setActiveTab,
      getTopQueryPerPage,
      getLowCtrPageReport,
      getContentThinReport,
      getNewPagesReport,
      getLowCtrByQueryDrawer,
      getWinnerLoserPagesReport,
      getWinnerQueriesDataReport,
      getTopQueryPerPageDrawer,
      getAllKeywordsReport,
      getAllPagesReport,
    };
  });

export type GscExportStoreInstance = Instance<typeof GscReportsStore>;

export const initGscReportsStore = () => {
  return GscReportsStore.create({
    longTailTableLoading: false,
    ctrOpportunitiesTableLoading: false,
    strikingTableLoading: false,
    questionsTableLoading: false,
    topQueryPerPageLoading: false,
    keywordMetaReportTableLoading: false,
    lowCtrByQueryDrawerLoading: false,
    topQueryPerPageDrawerLoading: false,
    activeTab: 'All',

  });
};
