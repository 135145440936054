import React, {useState, useEffect, useCallback, useRef} from 'react';
import RadioButton from '../radio-button';
import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import Button from './../button-component/';
import {Checkbox} from 'antd';

interface Props {
  type?: string;
  add?:boolean;
  filtersLength?:number;
  filterId?: number;
  filterLabel?: string;
  filterName?: string;
  onClose?: ()=>void;
  onChange?: (filter:any)=>void;
  className?: string;
  filterField?: string;
  customFields?: any[];
  headerField?: any;
  isDisable?:boolean;
  isClear?: boolean;
  onClear?: (data: any)=>void;
  absoluteUi?: boolean;
  isPageExplorer?: boolean;
}

const FilterKeywordPopUp: React.FC<Props> = ({absoluteUi=false, type, filterField, headerField, filterId, customFields, filterLabel, filterName, onClose, onChange, className, isClear, onClear, isPageExplorer}) => {
  const [value, setValue] = useState(headerField?.type || 'contains');
  const [operator, setOperator] = useState(headerField?.operator ||'contains');
  const [query, setQuery] = useState('');
  const [checked, setChecked] = useState(false);
  const popupRef = useRef<HTMLInputElement>();
  const {t} = useTranslation('common');
  useEffect(() => {
    if (type) {
      setValue(type);
    }
  }, []);
  useEffect(() => {
    if (headerField) {
      setValue(headerField?.type);
      setOperator(headerField?.operator);
      setQuery(headerField?.query);
      setChecked(headerField?.checked);
    } else {
      setValue('contains');
      setOperator('contains');
    }
  }, []);

  const onChangeValue = useCallback(

    e => {
      if (headerField?.type == value) {
        setQuery('');
      }
      setValue(e.target.value);
    },
    [],
  );


  const createOrUpdateFilter = e =>{
    e.preventDefault();
    emitOnChanges(operator, query);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };
  //
  const emitOnChanges = (operator: string, query: string) => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        type: value,
        operator: operator,
        query: query,
        filterField: filterField,
        active: true,
        customFields: customFields,
        checked: checked,
        isUrl: filterLabel == 'url' && isPageExplorer ? true : false,
      });
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const disablingButton = () =>{
    if (query=='') {
      return true;
    } else {
      return false;
    }
  };

  const filterPopupOptions = customFields?.length ? customFields : [
    {label: 'Is', operator: 'equals'},
    {label: 'Is not', operator: 'does_not_equal'},
    {label: 'Contains', operator: 'contains'},
    {label: 'Doesn’t contain', operator: 'does_not_contain'},
    {label: 'Starts With', operator: 'starts_with'},
    {label: 'Ends With', operator: 'ends_with'},
  ];
  const checkBoxChange = ()=>{
    setChecked(!checked);
  };

  const clearFilter = () => {
    onClear(
      {
        header: filterLabel,
        operator: operator,
        query: '',
      },
    );
    setQuery('');
  };

  return (
    <div
      ref={popupRef}
      onSubmit={createOrUpdateFilter}
      style={absoluteUi ? {position: 'absolute'} : {}}
      onClick={e=> e.stopPropagation() }
      // style={((add && filtersLength < 4 )|| width< 1000) ? {right: '-145%', left: 'unset'}:{}}
      className={`${styles.filterPopup} ${className}`}>
      {isPageExplorer && (filterLabel == 'title' || filterLabel == 'meta_description' ) ?<>
        <Checkbox checked={checked} onChange={checkBoxChange}>Case Insensitive Filtering </Checkbox>
        <hr color=' #E8E8E8'/></>: ''}
      <div className={styles.filterPopupOptions}>
        {isClear ? <div className={styles.clearBtn}
          onClick={clearFilter}>Clear</div> : ''}
        {filterPopupOptions.map(item=>{
          return (

            <div key={item.operator}>
              <RadioButton
                isTransform = {true}
                label={item.label}
                radioValue={item.operator}
                radioChecked={value === item.operator}
                handleChange={e=>[onChangeValue(e), setOperator(item.operator)]}
              />
              {value === item.operator && (
                <div className={styles.filterPopupOptionsOther}>
                  <input
                    style={{width: '100%'}}
                    value={query}
                    onChange={e=>setQuery(e.target.value)}
                  />
                </div>
              )}
            </div>

          );
        }) }

      </div>

      <div className={styles.filterPopupButtons}>
        <Button
          color='gray'
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          style={disablingButton() ? 'light-transparent' : 'solid'}
          disabled={disablingButton()}
          onClick={createOrUpdateFilter}
        >
          {t('apply-filter')}
        </Button>
      </div>
    </div>
  );
};

export default FilterKeywordPopUp;
