import {notification as antdNotification} from 'antd';

export const notification = {
  error(message: string, position?: any, duration?: number) {
    return antdNotification.error({
      message,
      placement: position || 'bottomRight',
      key: 'error',
      duration: duration || 4.5,
    });
  },

  success(message: string, position?: any, duration?: number) {
    return antdNotification.success({
      message,
      placement: position || 'bottomRight',
      duration: duration || 4.5,
    });
  },

  info(message: string, duration?: number) {
    return antdNotification.info({
      message,
      placement: 'bottomRight',
      key: 'info',
      duration: duration || 4.5,
    });
  },
};

export const isExtensionInstalled = () => {
  const isActiveExtension = document.getElementById('contentGscRoot');
  return !!isActiveExtension;
};
