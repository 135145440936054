import {PropsWithChildren} from 'react';
import classnames from 'classnames';
import {ProfitContainer} from './profit-container';
import {ArrowUpOutlined} from '@ant-design/icons';

export type GainProps = PropsWithChildren<{
  useChevron?: boolean;
  useCaret?: boolean;
  className?: string;
  currentValue?: number;
  previousValue?: number;
  showTooltip?: boolean;
  secondTooltip?: any;
  tooltipPlacement?:any;
  valueWithoutFormate?:boolean;
  isLost?: boolean;
}>;

export const Gain = ({valueWithoutFormate, useChevron, useCaret, className, children, currentValue, previousValue, showTooltip, tooltipPlacement='bottom', secondTooltip, isLost}: GainProps) => {
  return (
    <ProfitContainer valueWithoutFormate={valueWithoutFormate} isLost={isLost} isGain={true} showTooltip={showTooltip} tooltipPlacement={tooltipPlacement} currentValue={currentValue} previousValue={previousValue} secondTooltip={secondTooltip} >
      {isLost ? <div style={{color: 'red'}}>lost</div> : children == 0 ?
        <div style={{paddingTop: '5px', paddingBottom: '5px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#F7F7F8'}}>
          <span style={{color: 'transparent', fontSize: '12px'}} className='text'>{children}</span>
        </div> :
        <span className={classnames('gain', className)}>
          <span className='icon' style={{paddingLeft: '3px', marginTop: 3, marginRight: 3}}>
            {useChevron ? (
              <ArrowUpOutlined color='#2AC155' style={{fontSize: 10, marginBottom: 10}}/>
            ) : useCaret ?
              <><ArrowUpOutlined color='#2AC155' style={{fontSize: 10, marginBottom: 10}}/>&nbsp;&nbsp;</> :
              <ArrowUpOutlined color='#2AC155' style={{fontSize: 10, marginBottom: 10}}/>
            }
          </span>
          {children === '∞' ? <span className='textInfinity'>{children}</span> : <span style={{color: '#2AC155', fontSize: '12px', marginTop: '3px'}} className='text'>{children}</span>}
        </span>}
    </ProfitContainer>
  );
};
