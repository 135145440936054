import {getApiUrl, getAuthorizationHeader, getMessagesFromErrorResponse} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';
import {notification} from '@/utils/notification-v2';
class SiteAuditorApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.SA_ENDPOINT, '/api');

  public async getSiteAuditorList(siteProperty) {
    try {
      const response = await this.axios.get(`${SiteAuditorApi.baseUrl}/site-auditor/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async getSiteAuditorInternalLink(params) {
    try {
      const response = await this.axios.get(`${SiteAuditorApi.baseUrl}/site-auditor/internal-linking/`, {
        params: params,
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async getSiteAuditorSummaryStats(siteProperty) {
    try {
      const response = await this.axios.get(`${SiteAuditorApi.baseUrl}/site-auditor/summary-stats/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async getSiteAuditorPage(uuid: string) {
    const response = await this.axios.get(`${SiteAuditorApi.baseUrl}/site-auditor/${uuid}/`, {
      headers: {Authorization: await getAuthorizationHeader()},
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async createSiteAuditPage(url: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `${SiteAuditorApi.baseUrl}/create-from-url/`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          url,
        },
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }


  public async indexibilityData(siteProperty) {
    try {
      const response = await this.axios.get(`${SiteAuditorApi.baseUrl}/indexability-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }


  public async copyPage(uuid: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `${SiteAuditorApi.baseUrl}/site-auditor/${uuid}/copy/`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }


  public async updateKeywords(pk: number, keywords: any, countryCode: string, location: string, locationId: number) {
    const targetKwPayload = keywords.map(kw => {
      // if we have location, it must go together with location ID
      if (kw.location && kw.locationId) {
        return {
          keyword: kw.keyword,
          // country_code: kw.countryCode,
          location: kw.location,
          location_id: kw.locationId,
        };
      // if we don't have location, check for kw country code
      } else {
        return {
          keyword: kw.keyword,
          country_code: kw.countryCode || '',
        };
      }
    });

    const defaultLocationPayload = location && locationId ? {
      location: location,
      location_id: locationId,
    } : {
      country_code: countryCode || '',
    };

    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `${SiteAuditorApi.baseUrl}/site-auditor/${pk}/update-target-keywords/`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        data: {
          target_keywords: targetKwPayload,
          ...defaultLocationPayload,
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getUpdateKeywordsStatus(pk: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `${SiteAuditorApi.baseUrl}/site-auditor/${pk}/update-target-keywords/`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data.shouldRepoll;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async checkIfProcessed(siteProperty: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `${SiteAuditorApi.baseUrl}/site-auditor/customer-site-audit/`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        params: {
          siteproperty: siteProperty,
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async processSiteProperty(siteProperty: string, shouldReprocess: boolean) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `${SiteAuditorApi.baseUrl}/site-auditor/customer-site-audit/`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        params: {
          siteproperty: siteProperty,
          should_reprocess: shouldReprocess ? 'True' : 'False',
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async recrawlSiteAuditPage(uuid: string | number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `${SiteAuditorApi.baseUrl}/site-auditor/${uuid}/recrawl`,
        headers: {
          Authorization: await getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getDepthNodes(audit, type = '', sizeParam = '') {
    try {
      const response = await this.axios.get(`${SiteAuditorApi.baseUrl}/v2/node-visualization/depth-nodes/`, {
        params: {
          audit: audit,
          type,
          size_param: sizeParam,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getPruningPage(audit) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `${SiteAuditorApi.baseUrl}/v2/pruning/`,
        params: {
          audit: audit,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getPruningPageOverview(auditId) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `${SiteAuditorApi.baseUrl}/v2/pruning/overview/`,
        params: {
          audit: auditId,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error('Error', errorMessage);
      return {success: false, response: errorMessage};
      // return Promise.reject(e);
    }
  }
}

export default SiteAuditorApi;
