import React, {useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {TopicalMapModalContent} from './topicalMapModalContent';
import {useStore} from '@/store/root-store';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {observer} from 'mobx-react-lite';

interface TopicalMapModalProps {
  openTopicalMapModal: boolean;
  setOpenTopicalMapModal: (value: boolean) => void;
  topicalMapName: string;
  componentIssueType: string;
  topicId?: number;
}

const skeletonsLeft = Array(8).fill({
  width: '100%',
  height: '25px',
  skeletonHeight: '15px',
  align: 'start',
});

const skeletonsRight = [
  '100%', '97%', '94%', '100%', '98%', '100%', '100%', '100%',
].map(width => ({
  width,
  height: '25px',
  skeletonHeight: '15px',
  align: 'start',
}));

export const TopicalMapModal: React.FC<TopicalMapModalProps> = observer(({openTopicalMapModal, setOpenTopicalMapModal, topicalMapName, componentIssueType, topicId}) => {
  const {contentOptimizer: {contentIdeas: {loadingSingleTopicalMap, getSingleTopicalMapData}}} = useStore('');
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [activeCategories, setActiveCategories] = useState(categoriesOptions?.map(item => item?.value) || []);
  const [selectedTab, setSelectedTab] = useState('diagram');

  const Loader = ({skeletons, flexBasis}) => (
    <div style={{flexBasis}}>
      {skeletons.map((props, index) => (
        <SkeletonHorizontalLoaderGray
          key={index}
          lightLoader
          width={props.width}
          height={props.height}
          skeletonHeight={props.skeletonHeight}
          align={props.align}
        />
      ))}
    </div>
  );
  const OptimizedComponent = () => (
    <div style={{marginTop: '20px', display: 'flex', gap: '10px'}}>
      <Loader skeletons={skeletonsLeft} flexBasis='20%' />
      <Loader skeletons={skeletonsRight} flexBasis='78%' />
    </div>
  );

  return (
    <StyledTopicalMapsModal
      width={'95vw'}
      height={'98vh'}
      zIndex={900}
      style={{top: '10px'}}
      closable={true}
      onClose={() => setOpenTopicalMapModal(false)}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark} /></div>}
      visible={openTopicalMapModal}
      showChart={!!activeCategories?.length}
      selectedTab={selectedTab}
    >
      {loadingSingleTopicalMap ? (
        <ContentLoader>
          <div>
            <SkeletonHorizontalLoaderGray lightLoader width='100%' height= '35px' skeletonHeight='20px' align='start'/>
            <SkeletonHorizontalLoaderGray lightLoader width='96%' height= '35px' skeletonHeight='20px' align='start'/>
          </div>
          <OptimizedComponent/>
        </ContentLoader>
      ) : (!loadingSingleTopicalMap && getSingleTopicalMapData) && (
        <TopicalMapModalContent
          openTopicalMapModal={openTopicalMapModal}
          setOpenTopicalMapModal={setOpenTopicalMapModal}
          topicalMapName={topicalMapName}
          componentIssueType={componentIssueType}
          activeCategories={activeCategories}
          setActiveCategories={setActiveCategories}
          categoriesOptions={categoriesOptions}
          setCategoriesOptions={setCategoriesOptions}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          topicId={topicId}
        />)}
    </StyledTopicalMapsModal>
  );
});

const StyledTopicalMapsModal = styled(Modal)<{showChart?: boolean; selectedTab: string}>`
    margin: 0px auto !important;
    max-width: 1714px;
   .rc-dialog-content {
    height: 100%;
    .rc-dialog-body {
      height: ${p => p?.selectedTab === 'table' ? '95%' : '100%'};
      padding: 25px !important;
      display: flex;
      flex-direction: column;
      ${p => p?.selectedTab === 'table' ? `
      @media screen and (max-height: 1720px) {
        height: 94% !important;
      }
      @media screen and (max-height: 1490px) {
        height: 93% !important;
      }
      @media screen and (max-height: 1310px) {
        height: 92% !important;
      }
      @media screen and (max-height: 1180px) {
        height: 91% !important;
      }
      @media screen and (max-height: 1080px) {
        height: 90% !important;
      }
      @media screen and (max-height: 980px) {
        height: 89% !important;
      }
      @media screen and (max-height: 890px) {
        height: 87.5% !important;
      }
      @media screen and (max-height: 810px) {
        height: 87% !important;
      }
      @media screen and (max-height: 790px) {
        height: 86% !important;
      }
      @media screen and (max-height: 740px) {
        height: 85% !important;
      }
      @media screen and (max-height: 690px) {
        height: 84% !important;
      }
      @media screen and (max-height: 660px) {
        height: 83% !important;
      }
      @media screen and (max-height: 610px) {
        height: 81% !important;
      }
      @media screen and (max-height: 554px) {
        height: 79% !important;
      }
      @media screen and (max-height: 510px) {
        height: 76% !important;
      }
  ` : `
     @media screen and (max-height: 1770px) {
        height: 98% !important;
      }
     @media screen and (max-height: 1710px) {
        height: 96% !important;
      }
     @media screen and (max-height: 1644px) {
        height: 95% !important;
      }
     @media screen and (max-height: 1470px) {
        height: 94% !important;
      }
     @media screen and (max-height: 1290px) {
        height: 92% !important;
      }
     @media screen and (max-height: 1180px) {
        height: 90% !important;
      }
     @media screen and (max-height: 990px) {
        height: 89% !important;
      }
     @media screen and (max-height: 840px) {
        height: 87% !important;
      }
     @media screen and (max-height: 780px) {
        height: 86% !important;
      }
     @media screen and (max-height: 734px) {
        height: 85% !important;
      }
     @media screen and (max-height: 680px) {
        height: 83% !important;
      }
     @media screen and (max-height: 614px) {
        height: 81% !important;
      }
     @media screen and (max-height: 554px) {
        height: 79% !important;
      }
     @media screen and (max-height: 504px) {
        height: 77% !important;
      }
     @media screen and (max-height: 470px) {
        height: 74% !important;
      } 
  `}
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -24px;
    top: -2px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8 !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
  canvas {
    display: ${p => p.showChart ? 'block' : 'none'};
  }
`;
const ContentLoader = styled.div`
  background: #FFFFFF;
  border-radius: 12px;
  padding: 15px 20px 0px 20px;
  height: 322px;
`;
