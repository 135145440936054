import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Form} from 'antd';
// import {Button} from '@/components/common-components/v2/Button';
import {faArrowRight, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {StyledWidgetModal} from '../../../styleComponents';
import {GenerateContent, ReviewContent, DistributionChannels} from '.';
import {CancelButton, ContinueButton, PublishingBanner} from './pressReleaseStyles';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import {snakeToCamel} from '@/utils/string';
import {StyledDropdownTable} from '../../../../style';
import {toJS} from 'mobx';
import {debounce} from 'lodash';
import {faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import FreezeWrapper from '../../../freezTooltip';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';
import {FreezeBanner} from '../../../ottoHeaderV2';
import axios from 'axios';
import {showFormattedNonFieldError} from '@/utils/errorHandler';

const tabsTitle = [
  'AI Write Content',
  'Review AI Content',
  'Distribution Channels',
];
let id;
const AddPressReleaseModal = ({visible, activeTab, setActiveTab, componentIssueType, isEdit, record, setAddRecord, setIsEdit, pressReleaseDropdownColumns, setCreatingCloudContent, isStandalone = false}) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    buildOrDeployPressRelease,
    loadIssueTableData,
    createPressRelease,
    setLoadingDetail,
    issueTypeArray,
    creatingPRContent,
    setCreatingPRContent,
    getOttoKnowledgeGraphFieldsMeta,
    knowledgeGraph,
    ottoGBPUploadImage,
    createKnowledgeGraph,
    updateKnowledgeGraph,
    getKnowledgeGraphData,
    getDistributionAgainstUrl,
    getTaskResult,
    distributionChannels,
    distributionAgainstUrl,
    resetDistributionAgainstUrl,
    setIsAddPrModalVisible,
    getPressReleaseDistributionFilters,
  }, settings: {customer: {getCustomerQuota}},
  } = useStore('');

  const [url, setUrl] = useState('');
  const [selectedDistributionChannels, setSelectedDistributionChannels] = useState([]);
  const [selectedPressCategory, setSelectedPressCategory] = useState<any>(1);
  const [prId, setPrId] = useState(null);
  const [form] = Form.useForm();
  const [ottoUrls, setOttoUrls] = useState([]);
  const [errorTabs, setErrorTabs] = useState([]);
  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const urlData = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = urlData?.issueTable?.results ?? [];
  const filteredPr = data?.find(i => i?.id === (isEdit ? record?.id : prId));
  const generating = data?.find(i => i?.id === prId)?.status === 'Generating';
  const failedPr = toJS(distributionAgainstUrl)?.results?.filter(item => item?.variation?.status === 'Publish failed')?.length;
  const contentLink = addProtocolToDomainHttps(data?.find(i => i?.id === (isEdit ? record?.id : prId))?.editableUrl || '');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [acceptContent, setAcceptContent] = useState(false);
  const [images, setImages] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState([]);
  const [youtubeUrlError, setYoutubeUrlError] = useState(null);
  const [logo, setLogo] = useState(null);
  const [serpLocation, setSerpLocation] = useState();
  const [serpLocationId, setSerpLocationId] = useState();
  const [publishingCloudContent, setPublishingCloudContent] = useState(false);
  const selectedChannels = selectedDistributionChannels?.filter(item => item?.pressId == filteredPr?.id);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [imagesError, setImagesError] = useState('');
  const [dynamicPr, setDynamicPr] = useState(false);
  const [languageCode, setLanguageCode] = useState<string | null>(null);

  const onClose = () => {
    setAddRecord(false);
    setIsAddPrModalVisible(false);
    setIsEdit(false);
    setActiveTab(0);
    setUrl('');
    form.resetFields();
    setAcceptContent(false);
    setSelectedDistributionChannels([]);
    setErrorTabs([]);
    setPrId(null);
    id = null;
    resetDistributionAgainstUrl();
    setDynamicPr(false);
    setImages([]);
  };

  useEffect(() => {
    const loadSelectedChannels = async () => {
      if (record?.status === 'Published' || record?.status === 'Publishing') {
        setLoadingChannels(true);
        await getDistributionAgainstUrl(record?.id);
        setLoadingChannels(false);
      }
    };
    if (visible) {
      loadSelectedChannels();
    }
  }, [visible]);

  useEffect(() => {
    if (images?.length) {
      setImagesError('');
      setErrorTabs(prev => prev?.filter(item => item !== 'images'));
    } else if (errorTabs?.length) {
      setErrorTabs(prev => [...prev, 'images']);
      setImagesError('At least one image is required.');
    }
  }, [images?.length]);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (knowledgeGraph) {
      if (knowledgeGraph['youtubeVideos']) {
        setYoutubeUrl(
          (knowledgeGraph['youtubeVideos'] || []).map((item, index) => ({
            id: index + 1,
            value: item?.url || '',
          })),
        );
      }

      if (knowledgeGraph['images']) {
        setImages(
          (knowledgeGraph['images'] || []).map((item, index) => ({
            id: index,
            url: item?.url || '',
            types: 'photos',
          })),
        );
      }
      setYoutubeUrlError(null);
      if (knowledgeGraph['languageCode']) {
        setLanguageCode(knowledgeGraph['languageCode']);
      } else {
        setLanguageCode(null);
      }
      if (knowledgeGraph['logo']) {
        setLogo(knowledgeGraph['logo']);
      }
      setSerpLocation(knowledgeGraph['serpLocationText']);
    }
  }, [knowledgeGraph, visible]);


  const createCloudContent = async recordId => {
    setCreatingCloudContent(recordId);
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    try {
      await buildOrDeployPressRelease(recordId, true, {}, false);
      setLoadingDetail(true);
      await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: ottoUrls[0]?.issueType, otto_project: getOttoV2Project?.id}, false, false, getCustomerQuota);
      await getCustomerQuota();
      setLoadingDetail(false);
    } catch (error) {
      setCreatingPRContent(false);
      return Promise.reject(error);
    } finally {
      setCreatingCloudContent(null);
    }
  };

  const onSubmitKnowledgeGraph = async values => {
    try {
      // images tab
      const imagesArr = [];
      for (const item of images) {
        if (item?.url && typeof item?.url !== 'string') {
          const formData = new FormData();
          formData.append('image', item?.url);
          formData.append('format', 'JPEG');
          const response = await ottoGBPUploadImage(formData);
          imagesArr.push({url: response});
        } else {
          imagesArr.push({url: item?.url});
        }
      }
      const youtubeUrls = youtubeUrl.map(item => ({url: item.value}))?.filter(i => i?.url?.length);
      let response;
      if (logo && typeof logo !== 'string') {
        const formData = new FormData();
        formData.append('image', logo);
        formData.append('format', 'JPEG');
        response = await ottoGBPUploadImage(formData);
      } else {
        response = logo;
      }
      // images

      // other all
      Object.entries(values)?.forEach(([key, value]) => {
        if ((key === 'lat' || key === 'long' || key === 'number_of_employees') && value === '') {
          values[key] = null;
        }
        if (key === 'language_code' && !value) {
          delete values[key];
        }
        values.serp_location_text = serpLocation;
        values.serp_location_id = serpLocationId;
        values.images = imagesArr;
        values.youtube_videos = youtubeUrls;
        values.logo = response || '';
      });
      if (languageCode) values.language_code = languageCode;
      if (isStandalone) {
        const res = await createKnowledgeGraph(values);
        return res;
      } else {
        await updateKnowledgeGraph(getOttoV2Project?.knowledgeGraphId, values);
        await getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
      }
    } catch (error) {
      setCreatingPRContent(false);
      return Promise.reject(error);
    }
  };

  const createPRAndGenerateContent = async values => {
    try {
      if (!images?.length) {
        setErrorTabs(prev => [...prev, 'images']);
        setImagesError('At least one image is required.');
        return;
      } else {
        setErrorTabs(prev => prev?.filter(item => item !== 'images'));
        setImagesError('');
      }
      setCreatingPRContent(true);
      const response = await onSubmitKnowledgeGraph(values);
      if (!id) {
        const payload = {
          target_url: values?.target_url?.length ? `${addProtocolToDomainHttps(domainName) ? `${addProtocolToDomainHttps(domainName)}/` : ''}${values.target_url}` : `${addProtocolToDomainHttps(domainName)}/`,
          target_keywords: values?.target_keywords?.split(', '),
          input_prompt: values?.input_prompt,
          ...(isStandalone ? {knowledge_graph: response?.id} : {otto_project: getOttoV2Project?.id}),
        };

        const res = await createPressRelease(ottoUrls[0]?.issueType, payload, false);
        id = res?.response?.id;

        if (id) {
          await createCloudContent(id);
          setPrId(id);
        }
        notification.success('Success', id ? 'Press release added successfully' : 'Failed to create press release');
      } else {
        await createCloudContent(id);
        setPrId(id);
        notification.success('Success', 'Content generated successfully');
      }
      setActiveTab(prev => prev + 1);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        showFormattedNonFieldError(error);
      } else {
        setCreatingPRContent(false);
        const errorMessage = apiError(error);
        notification.error('', errorMessage);
      }
    } finally {
      setCreatingPRContent(false);
    }
  };

  const publishCloudStack = async () => {
    setPublishingCloudContent(true);
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    const distributions = selectedChannels?.map(i => i?.channelId);
    try {
      const response = await buildOrDeployPressRelease(filteredPr?.id || prId || id, false, {
        mock_code: 200,
        genesys_category_id: selectedPressCategory?.toString(),
        distribution_ids: distributions?.filter(slug => !filteredPr?.signalGenesys?.distributionChannels?.includes(slug)),
        variations: dynamicPr,
      });
      const taskResults = await getTaskResult('press_release', response?.response?.taskId, false);
      setLoadingDetail(true);
      await getDistributionAgainstUrl(filteredPr?.id || prId || id);
      if (isStandalone) {
        const activeFilters = getPressReleaseDistributionFilters?.filter(item => item?.active === true);
        const params = {
          issue_type: 'press_release_distribution',
          page: filter?.length ? filter[0].page : 1,
          page_size: filter?.length ? filter[0].pageSize : 10,
        };
        activeFilters?.forEach(item => {
          if (item?.name === 'exclude') {
            params['search'] = item.query;
          }
          if (item?.name == 'radioButtonsFilter') {
            params['status__in'] = item.type;
          }
        });
        setTimeout(async () => {
          await loadIssueTableData(params);
        }, 1000);
        if (taskResults?.displayableStatus === 'SUCCESS') {
          notification.success('', 'Press release Content Published successfully');
        }
        onClose();
      } else {
        await loadIssueTableData({uuid: getOttoV2Project?.uuid, page: filter?.length ? filter[0].page : 1, page_size: filter?.length ? filter[0].pageSize : 10, issue_type: ottoUrls[0]?.issueType, otto_project: getOttoV2Project?.id});
      }
      await getCustomerQuota();
      setLoadingDetail(false);
      setPublishingCloudContent(false);
    } catch (error) {
      const errorMessage = apiError(error);
      notification.error(errorMessage, `There might be some issues related to content not covered on this page. Please review the content or check other relevant sections to resolve the issue.`);
      setPublishingCloudContent(false);
    }
  };

  const buttonActions = {
    0: {
      onClick: values => createPRAndGenerateContent(values),
      loading: creatingPRContent || generating,
      label: 'Continue',
      icon: faArrowRight,
      disabled: false,
      cancelButton: {
        label: 'Cancel',
        click: () => onClose(),
      },
    },
    1: {
      onClick: () => setActiveTab(prev => prev + 1),
      loading: false,
      label: 'Continue',
      icon: faArrowRight,
      disabled: !acceptContent,
      cancelButton: {
        label: 'Cancel',
        click: () => onClose(),
      },
    },
    2: {
      onClick: () => publishCloudStack(),
      loading: publishingCloudContent || filteredPr?.status === 'Publishing',
      label: (publishingCloudContent || filteredPr?.status === 'Publishing') ? `Publishing to ${selectedChannels?.length || filteredPr?.channelsCountByStatus?.publishing} channel${(selectedChannels?.length || filteredPr?.channelsCountByStatus?.publishing) > 1 ? 's' : ''}` :
        `Publish to ${selectedChannels?.length} channel${selectedChannels?.length > 1 ? 's' : ''}`,
      icon: <img src='/img/icon/turned-arrows.svg' alt='' color='#fff' style={{cursor: 'pointer', width: 14, height: 14, marginRight: '6px'}}/>,
      disabled: (!selectedChannels?.length && !filteredPr?.channelsCountByStatus?.publishing),
      style: {
        width: '250px',
        height: '44px',
      },
      cancelButton: {
        label: 'Back',
        click: () => {
          setActiveTab(prev => prev - 1);
        },
      },
    },
  };

  const nextStep = async values => {
    buttonActions[activeTab].onClick(values);
  };

  const updateStackProviders = (site: any, checked: boolean, recordId: string) => {
    if (checked) {
      setSelectedDistributionChannels(prev => [...prev, {name: site?.displayName, channelId: site?.id, pressId: recordId, creditsCost: site?.creditsCost}]);
    } else {
      setSelectedDistributionChannels(prev => prev?.filter(i => i?.channelId !== site?.id));
    }
  };

  const steps = [
    <GenerateContent
      key={'step-1'}
      url={url}
      setUrl={setUrl}
      form={form}
      generating={generating}
      images={images}
      setImages={setImages}
      youtubeUrl={youtubeUrl}
      setYoutubeUrl={setYoutubeUrl}
      youtubeUrlError={youtubeUrlError}
      setYoutubeUrlError={setYoutubeUrlError}
      logo={logo}
      setLogo={setLogo}
      serpLocation={serpLocation}
      setSerpLocation={setSerpLocation}
      setSerpLocationId={setSerpLocationId}
      errorTabs={errorTabs}
      imagesError={imagesError}
      languageCode={languageCode}
      setLanguageCode={setLanguageCode}
      isStandalone={isStandalone}
    />,
    <ReviewContent
      key={'step-2'}
      contentLink={contentLink}
      acceptContent={acceptContent}
      setAcceptContent={setAcceptContent}
      generating={generating}
      id={prId || isEdit && record?.id}
      activeTab={activeTab}
      visible={visible}
      isStandalone={isStandalone}
    />,
    <DistributionChannels
      key={'step-3'}
      selectedDistributionChannels={selectedDistributionChannels}
      record={isEdit ? record : filteredPr}
      selectedPressCategory={selectedPressCategory}
      setSelectedPressCategory={setSelectedPressCategory}
      updateStackProviders={updateStackProviders}
      publishing={filteredPr?.status === 'Publishing'}
      setDynamicPr={setDynamicPr}
      dynamicPr={dynamicPr}
    />,
  ];

  const onFieldsChange = useCallback((submitFailed?: boolean) => {
    if (activeTab == 0 && (errorTabs?.length || submitFailed)) {
      const errors = form.getFieldsError();
      const fieldsWithErrors = errors.filter(({errors}) => errors.length > 0)?.map(item => item?.name?.[0]);
      if (fieldsWithErrors?.length || !images?.length) {
        const metaFields: any[] = getOttoKnowledgeGraphFieldsMeta?.value;
        const errorTabList = metaFields?.filter(item => item?.value?.some(it => fieldsWithErrors?.includes(it?.name)))?.map(item => item?.name) || [];
        const imagesErrorArr = images?.length ? [] : ['images'];
        setImagesError(images?.length ? '' : 'At least one image is required.');
        setErrorTabs([...errorTabList, ...imagesErrorArr]);
      } else setErrorTabs([]);
    }
  }, [form, errorTabs, images, activeTab, getOttoKnowledgeGraphFieldsMeta?.value]);

  const debouncedFieldChange = debounce((submitFailed?: boolean) => {
    onFieldsChange(submitFailed);
  }, 300);

  const allValues = getOttoKnowledgeGraphFieldsMeta?.value?.reduce((acc, curr) => acc.concat(curr['value']), []);
  const getInitialValues = () => {
    const obj = {};
    allValues?.forEach(item => {
      obj[item?.name] = knowledgeGraph[snakeToCamel(item.name)];
    });
    return obj;
  };

  const sortedChannels = toJS(distributionChannels?.results)
    ?.filter(site => !site?.isComingSoon) ?? [];

  const comingSoon = toJS(distributionChannels?.results)
    ?.filter(site => site?.isComingSoon) ?? [];

  const commingSoonObj = {displayName: 'Coming soon'};

  const tableData = [...sortedChannels, ...[commingSoonObj, ...comingSoon]];
  const showInProgressBanner = distributionAgainstUrl?.results?.some(item => item?.variation?.status !== 'Published');

  return (
    <>
      <StyledWidgetModal
        width={'700px'}
        closable={true}
        closeIcon={<div onClick={onClose}><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
        visible={visible}
        style={{top: 50}}
        zIndex={10}
        removePadding={activeTab === 2}
      >
        {getOttoV2Project?.isFrozen ? <FreezeBanner className='freeze-banner' style={{width: '95%', marginTop: 10, marginInline: 'auto', paddingInline: 10}}>
          <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
          <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
          </div>
        </FreezeBanner> : ''}
        {(filteredPr?.status === 'Published' || filteredPr?.status === 'Publishing') ?
          <>
            {showInProgressBanner ? <PublishingBanner background='#e1e9f6' style={{margin: '5px', marginBottom: 0, marginTop: 6, borderRadius: 12, color: '#000'}}>
              <FontAwesomeIcon fontSize={16} style={{minWidth: 16}} icon={faCircleInfo} color='#3e6bc3' />
              Publication typically takes up to 7 days, but {`it's`} often much quicker.
            </PublishingBanner> : ''}
            {failedPr ? <PublishingBanner background='rgba(244, 67, 67, 0.15)' style={{margin: '5px'}}>
              <FontAwesomeIcon fontSize={16} style={{minWidth: 16}} icon={faCircleInfo} color='#F44343' />
              {failedPr} Publication was Rejected. Improve the content and submit again.
            </PublishingBanner> : ''}
            <StyledDropdownTable
              columns={pressReleaseDropdownColumns()}
              dataSource={tableData}
              pagination={false}
              style={{whiteSpace: 'nowrap'}}
              paddingInCells
              marginTop={showInProgressBanner ? '5px' : '10px'}
              scroll={{x: 'auto', y: 'auto'}}
              loading={loadingChannels}
              left='50%'
            />
          </> : <div className={'content-wrapper'}>
            <div className={'body-heading'} style={activeTab === 2 ? {padding: '25px 25px 0px 25px'} : {}}>Add Press Release</div>
            <div className='steps-wrapper' style={activeTab === 2 ? {padding: '0px 25px'} : {}}>
              <div className='steps'>
                {tabsTitle.map((_, index) => (
                  <div
                    key={index}
                    className={`section ${index <= activeTab ? 'active-section ' : ''}`}
                  >
                    <div className='step'>{`Step ${index + 1}`}</div>
                    <div className={`step ${index <= activeTab ? 'active' : ''}`}>
                      {tabsTitle[index]}
                    </div>
                  </div>
                ))}
              </div>
              <div className='border-line' />
            </div>
            <Form
              form={form}
              onFinish={nextStep}
              onFieldsChange={() => debouncedFieldChange()}
              onFinishFailed={() => onFieldsChange(true)}
              initialValues={{
                ...getInitialValues(),
                target_url: '',
                target_keywords: '',
                input_prompt: '',
              }}
            >
              {steps[activeTab]}
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: activeTab === 2 ? '' : 25, alignItems: 'center', gap: '30px', marginRight: activeTab === 2 ? '25px' : '', marginBottom: activeTab === 2 ? '20px' : ''}}>
                <CancelButton
                  onClick={e => {
                    e.preventDefault();
                    buttonActions[activeTab]?.cancelButton?.click();
                  }}>
                  {buttonActions[activeTab]?.cancelButton?.label}
                </CancelButton>
                <FreezeWrapper removeTooltip={activeTab === 1}>
                  <ContinueButton
                    style={{...(buttonActions[activeTab]?.style || {}), marginRight: 0}}
                    htmlType={'submit'}
                    loading={buttonActions[activeTab]?.loading}
                    disabled={buttonActions[activeTab]?.disabled}
                  >
                    {activeTab == 2 ? buttonActions[activeTab]?.icon : ''} {buttonActions[activeTab]?.label} {activeTab !== 2 ? <FontAwesomeIcon icon={buttonActions[activeTab]?.icon} fontSize={12} style={{marginLeft: '15px'}} /> : ''}
                  </ContinueButton>
                </FreezeWrapper>
              </div>
            </Form>
          </div>}
      </StyledWidgetModal>
    </>
  );
};

export default observer(AddPressReleaseModal);
