import {faStar} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {NextImg} from '@/utils/nextImg';

interface SingleAiTemplateProps {
  template: any;
  onTemplateClick: (templates: any[]) => void;
}
/**
 * Single Ai Template list item
 *
 * @param {AiTemplateType} template single ai template data
 * @param {any} onBookmark bookmarks/unbookmarks the template into localstorage
 * @param {string[]} bookmarkedTemplates list of bookmarked templates stored in localstorage
 * @return {any} - single template for list
 */
export const SingleAiTemplate = ({template, onTemplateClick}: SingleAiTemplateProps) => {
  // this can be used to temp disable some templates
  const isDisabled = () => {
    switch (template?.type) {
      case 'ai_image_generation':
        return false;
      default:
        return false;
    }
  };

  return <Wrapper onClick={() => {
    if (isDisabled()) return;
    onTemplateClick(template);
  }} disabled={isDisabled()}>
    <Container>
      <ImgContainer>
        <NextImg src={template?.imageUrl} style={{height: 25, margin: 'auto'}}/>
        {/* <FontAwesomeIcon icon={faImage} style={{margin: 'auto'}} fontSize={22}/> */}
      </ImgContainer>
      <TemplateName>{template?.label} {isDisabled() ? <span style={{fontSize: 12, fontWeight: 400}}>- coming soon</span> : null}</TemplateName>

    </Container>
    <BookmarkIcon>
      <FontAwesomeIcon
        icon={faStar}
        color={'#F1AA3E'}
        style={{margin: 'auto', width: 13}}/>
    </BookmarkIcon>
  </Wrapper>;
};

const Wrapper = styled.div<{disabled?: boolean}>`
  background: #EFEFF4;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: ${p => p.disabled ? 'default' : 'pointer'};
  transition: all .2s ease;
  min-height: 70px;
  position: relative;
  min-width: 75px;
  max-width: 85px;
  width: fit-content;
  margin: 5px;
  
  &:hover {
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const BookmarkIcon = styled.div`
  width: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 5px;
  right: 5px;
`;
const ImgContainer = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
`;

const TemplateName = styled.div`
  font-size: 12px;
  color: #4E5156;
  margin-bottom: 4px;
  text-align: center;
  line-height: 13px;
  max-width: 75px;
`;
