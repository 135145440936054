import {Loading3QuartersOutlined} from '@ant-design/icons';
import {faRotateExclamation, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip, Spin, Button} from 'antd';
import {useEffect, useState} from 'react';
import FreezeWrapper from '../../../freezTooltip';
import {FieldStatus, DeployTooltipContainer} from '../styles';
import {humanize} from '@/utils/string';
import {isArray} from 'lodash';

export const DeployColumn = ({optimizationData, field, socialMediaAttributes, deployField, tooltipLoader, attributes}) => {
  const [showDeploy, setShowDeploy] = useState(false);
  const [deployStatus, setDeployStatus] = useState([]);

  useEffect(() => {
    if (field.fieldName == 'reviews' || field.fieldName == 'q_a') {
      setShowDeploy(false);
    } else {
      setShowDeploy(true);
      if (field.label !== 'Attributes' && field.label !== 'Images') {
        const fieldData = optimizationData?.find(item => item.fieldName == field.fieldName && ((item.source == 'OTTO' || item.source == 'GBP')));
        setDeployStatus([{
          field: field,
          fields: null,
          event: fieldData?.eventType,
          count: null,
        }]);
      } else {
        let fields = [];
        if (field.label == 'Images') {
          ['PROFILE', 'COVER', 'ADDITIONAL'].forEach(type => {
            const field = optimizationData?.find(item => item.fieldName == type && (item.source == 'OTTO' || item.source == 'GBP'));
            fields.push(field);
          });
        } else {
          fields = optimizationData?.filter(item => (
            (item.fieldType == 'ATTRIBUTE' && !socialMediaAttributes.includes(item.fieldName))) && attributes.includes(item.fieldName) &&
            ((item.source == 'OTTO')));
          optimizationData?.forEach(data => {
            if (data.source == 'GBP' && !fields?.some(val => val.fieldName == data.fieldName) && data.fieldType == 'ATTRIBUTE' && !socialMediaAttributes.includes(data.fieldName)) {
              fields.push(data);
            }
          });
        }
        if (fields?.every(item => item?.eventType == fields[0]?.eventType)) {
          setDeployStatus([{
            field: null,
            fields: fields,
            event: fields[0]?.eventType,
            count: 'All',
          }]);
        } else {
          const multiStatus = ['PUBLISH', 'PUBLISH_FAILED', 'CHANGE', 'LOCK_INCIDENT', 'PUBLISHING'].map(item => {
            let value;
            if (item == 'PUBLISH' && field.label == 'Attributes') {
              value = fields.filter(data => (data?.eventType == item && !isNull(data.value)));
            } else {
              value = fields.filter(data => (data?.eventType == item));
            }
            if (value?.length) {
              return {
                event: item,
                fields: value,
                field: null,
                count: value?.length,
                name: value.map(item => field.label == 'Images' ? humanize(item.fieldName) + ' Images' : humanize(item.fieldName.replace('attributes/', ''))),
              };
            }
          }).filter(item => item);
          setDeployStatus(multiStatus);
        }
      }
    }
  }, [JSON.stringify(optimizationData)]);

  const isNull = val => {
    if (!val) {
      return true;
    } else {
      if (typeof(val) == 'string' || isArray(val)) {
        return val?.length == 0;
      } else if (typeof(val) == 'object') {
        return Object.keys(val)?.length == 0;
      } else if (typeof(val) == 'boolean') {
        return !val;
      }
    }
  };

  return <>
    {showDeploy ? deployStatus?.map(status => (
      <>
        {status.event == 'PUBLISH' && <FieldStatus isDefault={true} style={{color: '#2AC155'}}><FontAwesomeIcon icon={faCheckCircle} className='icon' />{status.count ? status.count + ' ' : ''}Deployed</FieldStatus>}
        {!status.event && <FieldStatus isDefault={true} style={{color: '#A3A4A4'}}><FontAwesomeIcon icon={faCheckCircle} className='icon'/>Deploy</FieldStatus>}
        {status.event == 'PUBLISHING' && <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: 300}} title={
          <DeployTooltipContainer>
            <Spin style={{marginTop: -3, marginRight: 5}} indicator={<Loading3QuartersOutlined className='icon' style={{color: '#A3A4A4', fontSize: 13}} spin/>}/>
            <div>
              <p className='heading'>Deploying modifications.</p>
              <p className='text'>The change is currently being deployed and will be visible on your GBP profile shortly.</p>
              {status?.name ? <p className='fields-list'>Pending Fields: {status?.name?.map(item => <div key={item}>. {item}</div>)}</p> : null}
            </div>
          </DeployTooltipContainer>
        }><FieldStatus isDefault={false} style={{color: '#A3A4A4'}}><Spin indicator={<Loading3QuartersOutlined className='icon' style={{color: '#A3A4A4', fontSize: 13}} spin/>}/>{status.count ? status.count + ' ' : ''}Deploying</FieldStatus></Tooltip>}
        {status.event == 'PUBLISH_FAILED' && <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: 294}} title={
          <DeployTooltipContainer>
            <FontAwesomeIcon icon={faTimes} style={{marginRight: 5, color: '#F44343'}}/>
            <div>
              <p className='heading'>The deployment has failed.</p>
              <p className='text'>Please verify the accuracy of the information and try deploying again.</p>
              {status?.name ? <p className='fields-list'>Failed Fields: {status?.name?.map(item => <div key={item}>. {item}</div>)}</p> : null}
              <FreezeWrapper><Button loading={tooltipLoader} className='deploy-button'
                onClick={() => deployField(status.field, status.fields)}>Deploy</Button></FreezeWrapper>
            </div>
          </DeployTooltipContainer>
        }><FieldStatus isDefault={false} style={{color: '#F44343'}}><FontAwesomeIcon icon={faTimes} className='icon'/>{status.count ? status.count + ' ' : ''}Failed</FieldStatus></Tooltip>}
        {status.event == 'CHANGE' && <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: 350}} title={
          <DeployTooltipContainer>
            <FontAwesomeIcon icon={faRotateExclamation} style={{marginRight: 5, color: '#F1AA3E'}}/>
            <div>
              <p className='heading'>Modification not deployed.</p>
              <p className='text'>This modification has not yet been deployed live and is currently stored only in OTTO. It will not be visible on your GBP profile until it is deployed.</p>
              {status?.name ? <p className='fields-list'>Not Synced Fields: {status?.name?.map(item => <div key={item}>. {item}</div>)}</p> : null}
              <FreezeWrapper><Button loading={tooltipLoader} className='deploy-button'
                onClick={() => deployField(status.field, status.fields)}>Deploy</Button></FreezeWrapper>
            </div>
          </DeployTooltipContainer>
        }><FieldStatus isDefault={false} style={{color: '#F1AA3E'}}><FontAwesomeIcon icon={faRotateExclamation} className='icon'/>{status.count ? status.count + ' ' : ''}Not synced</FieldStatus></Tooltip>}
        {}
      </>
    )) : null} </>;
};
