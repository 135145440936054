import {CommonInput} from '@/components/common-components';
import {GrayButton} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {faGears} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover, Spin} from 'antd';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {SettingsPopup} from '../../SettingsPopup/SettingsPopup';
import {SingleAiTemplate} from './singleTemplate';
import styles from '../../style.module.scss';

interface Props {
  setSettingsModalVisible: (value: boolean) => any;
  settingsModalVisible: boolean;
  templatesList: any[];
  setTemplatesList: (value: any[]) => any;
  initialTemplatesList: any[];
  setInitialTemplatesList: (value: any[]) => any;
  searchQuery: string;
  setSearchQuery: (value: string) => any;
  selectedCategories: string[];
  setSelectedCategories: (value: string[]) => any;
}

/**
 * List of all templates
 *
 * @param settingsModalVisible boolean that shows/hides settings modal
 * @param setSettingsModalVisible sets settings modal visible on click
 * @param templatesList list of template
 * @param setTemplatesList sets slist of templates
 * @param initialTemplatesList initial list of templates that only updates once
 * @param setInitialTemplatesList sets initial list of templates
 * @param {string} searchQuery value of the search input
 * @param setSearchQuery sets value of the search input
 * @param {string[]} selectedCategories list of selected categories
 * @param setSelectedCategories sets list of selected categories
 *
 * @returns {any} - Templates list
 */
export const TemplatesList = observer((
  {
    settingsModalVisible,
    setSettingsModalVisible,
    templatesList,
    setTemplatesList,
    initialTemplatesList,
    setInitialTemplatesList,
    searchQuery,
    setSearchQuery,
    selectedCategories,
    setSelectedCategories,
  }: Props) => {
  // will contain list of all bookmarked items for this user
  const [bookmarkedTemplates, setBookmarkedTemplates] = useState([]);

  const defaultTempaltesNames = {
    aiContentGenerator: 'AI Content Generator',
    aiFirstDraftGenerator: 'First Draft Generator',
    aiImageGenerator: 'AI Image Generation',
  };

  // default and always visible templates

  const [defaultTemplates] = useState([
    {
      label: defaultTempaltesNames.aiContentGenerator,
      type: '',
      description: 'Generate content based on topics and keywords',
      inputs: null,
      isDefault: true,
      defaultType: 'ContentIdea',
      imageUrl: 'https://storage.googleapis.com/sagroup-static/searchatlas/writing_hand.png',
    },
    {
      label: defaultTempaltesNames.aiFirstDraftGenerator,
      type: '',
      description: '',
      inputs: null,
      isDefault: true,
      defaultType: 'Turn your content outline into a first draft in seconds',
      imageUrl: 'https://storage.googleapis.com/sagroup-static/searchatlas/writing_hand.png',
    },
  ]);
  const {contentOptimizer: {
    aiOutline: {
      aiTemplates,
      setAiTemplate,
      aiTemplatesController,
      loadingTemplates,
    },
  }} = useStore('');

  const {settings: {customer: {profile}}} = useStore('');

  // hard coded default BE templates setup, in case API fails
  const hardCodedData = require('@/constants/hardCodedData.json');

  // On click on one of the templates that came in from API
  const onTemplateClickHandler = (template: any) => {
    setAiTemplate({
      ...toJS(aiTemplatesController ?? []),
      showForm: true,
      templateType: template.type ?? '',
      templateIcon: template.imageUrl ?? '',
      templateName: template.label ?? '',
      templateDesc: template.description ?? '',
      fieldsSetup: toJS(template)?.inputs ?? [],
      defaultForm: false,
      defaultFormType: null,
      isStandaloneTool: false,
      isUseMask: true,
    });
  };

  // On click on one of the default templates
  const onDefaultTemplateClickHandler = (template: any) => {
    setAiTemplate({
      ...toJS(aiTemplatesController ?? []),
      showForm: true,
      templateType: template.type ?? '',
      templateIcon: template.imageUrl ?? '',
      templateName: template.label ?? '',
      templateDesc: template.description ?? '',
      fieldsSetup: template.inputs ?? [],
      defaultForm: template.isDefault,
      defaultFormType: template.defaultType,
      isStandaloneTool: false,
      isUseMask: true,
    });
  };

  // on template bookmark click sets/unsets that item from localstorage
  const onBookmarkClickHandler = (templateName: string) => {
    // fetching any existing data from localstorage
    const localStorageBookmarks = localStorage.getItem('bookmarked-templates');
    let updatedList = [];

    // if there is any data in localstorage
    if (localStorageBookmarks) {
      // we need to parse fetched data as we are stringifying it before sending
      const parsedData = JSON.parse(localStorageBookmarks);

      if (parsedData[profile.id]) {
        // getting all bookmarked templates for that user by his id
        updatedList = parsedData[profile.id];
      }

      if (updatedList?.includes(templateName)) {
        updatedList = updatedList.filter(item => item !== templateName);
      } else {
        updatedList.push(templateName);
      }
      // its mandatory to immediatly also update local state so components will update and show bookmark star on check/uncheck
      setBookmarkedTemplates(updatedList);
      parsedData[profile.id] = updatedList;

      // setting updated data into localstorage
      localStorage.setItem('bookmarked-templates', JSON.stringify(parsedData));
    } else {
      // if there is no saved data we are making one for this user
      const newParsedData = {[profile.id]: [templateName]};
      setBookmarkedTemplates([templateName]);
      localStorage.setItem('bookmarked-templates', JSON.stringify(newParsedData));
    }

    // separating bookmarked from non bukmarked templates so we can order list properly
    // default templates go first!
    const notBookmarkedList = templatesList.filter(template => !updatedList?.includes(template.label));
    const bookmarkedList = templatesList.filter(template => updatedList?.includes(template.label));
    const finalPrepArr = [...(bookmarkedList ?? []), ...(notBookmarkedList ?? [])];

    setTemplatesList(finalPrepArr);
  };


  // after templates list is loaded, if there is data use api response
  // if there is no data use hardcoded JSON
  useEffect(() => {
    // Gets bookmarked templates from local storage
    const localStorageBookmarks = localStorage.getItem('bookmarked-templates');
    const prepBookmarkList = [];
    if (localStorageBookmarks) {
      const parsedData = JSON.parse(localStorageBookmarks);

      if (parsedData[profile.id]) {
        prepBookmarkList.push(...(parsedData[profile.id] ?? []));
      } else {
        // this is very important
        // if there is no user id in bookmark prop in localstorage, that indicates that this is first time user has opened the AI tool or he still wasnt active
        // that allows us to set initial bookamrk of 2 default templates only once
        prepBookmarkList.push([defaultTempaltesNames.aiContentGenerator, defaultTempaltesNames.aiFirstDraftGenerator, defaultTempaltesNames.aiImageGenerator]);
      }
    } else {
      // if there are no user bookmarks saved at all, we are making one bookmark prop for this user and setting default templates bookmarked
      prepBookmarkList.push(...[defaultTempaltesNames.aiContentGenerator, defaultTempaltesNames.aiFirstDraftGenerator, defaultTempaltesNames.aiImageGenerator]);
      localStorage.setItem('bookmarked-templates', JSON.stringify({[profile.id]: prepBookmarkList}));
    }
    setBookmarkedTemplates(prepBookmarkList);

    // Gets templates data from API and prepares templates list for use
    const nonDefaultTemplatesToUse = [];

    if (aiTemplates?.templates?.length) {
      nonDefaultTemplatesToUse.push(...(aiTemplates?.templates ?? []));
    } else {
      nonDefaultTemplatesToUse.push(...(hardCodedData ?? []));
    }


    const fullTemplatesList = [...defaultTemplates, ...nonDefaultTemplatesToUse ?? []];

    // separating bookmarked from non bukmarked templates so we can order list properly
    // default templates go first!
    const notBookmarkedList = fullTemplatesList.filter(template => !prepBookmarkList?.includes(template.label));
    const bookmarkedList = fullTemplatesList.filter(template => prepBookmarkList?.includes(template.label));
    const finalPrepArr = [...(bookmarkedList ?? []), ...(notBookmarkedList ?? [])];

    setTemplatesList(finalPrepArr);


    // We only set initial templates list once, and never change it later
    // it is used as a ref point for sortings and searcihg
    setInitialTemplatesList(finalPrepArr);
  }, [aiTemplates, aiTemplates?.categories?.length, aiTemplates?.templates?.length]);


  // uses search input and categories to filter out results
  const updateTemplatesArrOnFilter = (query: string, cats: string[]) => {
    let prepList = toJS(initialTemplatesList ?? []);
    prepList = prepList.filter(template => template?.label?.toLowerCase()?.includes(query.toLowerCase()));

    if (cats?.length) {
      const updatedList = [];

      cats?.forEach(cat => {
        if (prepList.filter(template => template.category?.includes(cat))?.length) {
          const templatesWithCat = prepList.filter(template => template.category?.includes(cat));

          templatesWithCat.forEach(templ => {
            if (!updatedList.filter(item => item.label == templ.label)?.length) {
              updatedList.push(templ);
            }
          });
        }
      });
      prepList = updatedList;
    }

    const notBookmarkedList = prepList.filter(template => !bookmarkedTemplates?.includes(template.label));
    const bookmarkedList = prepList.filter(template => bookmarkedTemplates?.includes(template.label));
    const finalPrepArr = [...(bookmarkedList ?? []), ...(notBookmarkedList ?? [])];

    setTemplatesList(finalPrepArr);
  };

  const onTemplatesSearchHandler = (e: any) => {
    setSearchQuery(e?.target?.value ?? '');
    const searchStringToUse = e?.target?.value ?? '';
    updateTemplatesArrOnFilter(searchStringToUse, selectedCategories);
  };

  const antIcon = <LoadingOutlined style={{fontSize: 40, marginTop: 100, color: '#7f4ead'}} spin />;

  const onCategoryClickHandler = (category: string) => {
    let prepCatArr = [...selectedCategories];
    if (prepCatArr.includes(category)) {
      prepCatArr = prepCatArr.filter(cat => cat !== category);
    } else {
      prepCatArr.push(category);
    }
    setSelectedCategories(prepCatArr);

    updateTemplatesArrOnFilter(searchQuery, prepCatArr);
  };

  const onSelectAllCategoriesHandler = () => {
    setSelectedCategories([]);
    updateTemplatesArrOnFilter(searchQuery, []);
  };

  return <Wrapper>
    {loadingTemplates ? <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}><Spin indicator={antIcon} /></div> : <>
      <ListViewHeader>
        <SearchAndSettings>
          <CommonInputStyled placeholder='Search' onChange={e => onTemplatesSearchHandler(e)}/>
          <Popover
            autoAdjustOverflow={true}
            visible={settingsModalVisible}
            placement='bottomLeft'
            content={<SettingsPopup onCancelClick={() => setSettingsModalVisible(false)}/>}
            trigger='click'
            destroyTooltipOnHide={true}
            overlayClassName={styles.settingsPopover}>
            <GrayButton onClick={() => setSettingsModalVisible(!settingsModalVisible)} style={{marginRight: 0, paddingLeft: 10, paddingRight: 10}}>
              <FontAwesomeIcon icon={faGears} style={{marginRight: 10}}/>AI Writer Settings
            </GrayButton>
          </Popover>

        </SearchAndSettings>
        <CategoriesContainer>
          <SingleCategory selected={selectedCategories?.length == 0} onClick={() => onSelectAllCategoriesHandler()}>
              All
          </SingleCategory>
          {aiTemplates?.categories?.map((category, idx) => {
            return <SingleCategory key={idx} selected={selectedCategories?.includes(category)} onClick={() => onCategoryClickHandler(category)}>
              {category}
            </SingleCategory>;
          })}
        </CategoriesContainer>

      </ListViewHeader>
      {/* list out all BE templates joined by all default templates */}
      {templatesList?.map((template, idx) => {
        return <SingleAiTemplate template={template}
          key={idx}
          onTemplateClick={template?.isDefault ? onDefaultTemplateClickHandler : onTemplateClickHandler }
          onBookmark={onBookmarkClickHandler}
          bookmarkedTemplates={bookmarkedTemplates}/>;
      })}
    </>}
  </Wrapper>;
});

const Wrapper = styled.div`

`;
const ListViewHeader = styled.div`

`;
const SearchAndSettings = styled.div`
  display: flex;  
  margin-bottom: 20px;
`;
const CommonInputStyled = styled(CommonInput)`
  &input {
    border-radius: 5px;
    border: 1px solid #E8E8E8;
  }
`;
const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const SingleCategory = styled.div<{selected?: boolean}>`
  padding: 6px 12px 7px;
  background: ${p => p.selected ? '#7F4EAD': '#fff'};
  color: ${p => p.selected ? '#fff': '#4E5156'};
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 27px;
  height: 28px;
  display: flex;
  align-items: center;
  margin-right: 4px;
  width: max-content;
  margin-bottom: 4px;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    background: #7F4EAD;
    color: #fff;
  }
`;
