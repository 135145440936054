import {AtomicBlockUtils, ContentState, EditorState, Modifier, SelectionState, convertFromRaw, convertToRaw} from 'draft-js';

export const insertText = async (outlineParam: any, id: number, currentPage: any, aiOutline: any, debouncedUpdate: any) => {
  // NEW FLOW start
  let htmlToDraft = null;
  if (typeof window === 'object') {
    htmlToDraft = require('html-to-draftjs').default;
  }

  const {contentBlocks, entityMap} = htmlToDraft(outlineParam);

  const selection = currentPage.currentEditorState.getSelection();

  const newSelection = new SelectionState({
    anchorKey: selection.getAnchorKey(),
    anchorOffset: selection.getEndOffset(),
    focusKey: selection.getAnchorKey(),
    focusOffset: selection.getEndOffset(),
  });


  const fragment = ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap();

  const nonStyledState = Modifier.replaceWithFragment(
    currentPage.currentEditorState.getCurrentContent(),
    newSelection,
    fragment,
  );

  // WE are calculating nodes to toggle blue background on new Ai generated nodes
  let firstNewBlockId: number;
  const currentBlock = selection.getAnchorKey();
  const nextBlock = currentPage.currentEditorState.getCurrentContent().getBlockAfter(selection.getAnchorKey())?.getKey();

  const existingNodes = document.querySelectorAll('[data-block="true"]');
  existingNodes?.forEach((elem, id) => {
    if (elem.getAttribute('data-offset-key') && elem.getAttribute('data-offset-key') == `${currentBlock}-0-0`) {
      firstNewBlockId = id + 1;
    }
  });


  const updatedState = EditorState.push(currentPage.currentEditorState, nonStyledState, 'insert-fragment');
  currentPage.setCurrentEditorState(updatedState);

  setTimeout(() => {
    const newTextElems = document.querySelectorAll('[data-block="true"]');
    if (newTextElems.length) {
      for (let i = firstNewBlockId; i <= newTextElems.length; i++) {
        if (newTextElems[i]?.getAttribute('data-offset-key') && newTextElems[i]?.getAttribute('data-offset-key') != `${nextBlock}-0-0` && newTextElems[i]?.getAttribute('data-offset-key') != `${currentBlock}-0-0`) {
          newTextElems[i].classList.add('newTextBlock');
        } else {
          return;
        }
      }
    }
  }, 500);

  debouncedUpdate(updatedState, currentPage.analytics?.focusTerms, currentPage.focusTermsClassNames, false);

  setTimeout(() => {
    const newTextElems = document.querySelectorAll('.newTextBlock');
    if (newTextElems.length) {
      newTextElems.forEach(elem => {
        elem.classList.remove('newTextBlock');
      });
    }
  }, 3500);
  // NEW FLOW END
  aiOutline?.updateSingleOutline(true, id);
};


export const insertImage = (editorState, base64, alt='') => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    'customImage',
    'IMMUTABLE',
    {src: base64, alt: alt},
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};

export const moveSelectionToStart = editorState => {
  const content = editorState.getCurrentContent();
  const firstBlock = content.getFirstBlock();
  const firstKey = firstBlock.getKey();

  return EditorState.acceptSelection(
    editorState,
    new SelectionState({
      anchorKey: firstKey,
      anchorOffset: 0,
      focusKey: firstKey,
      focusOffset: 0,
      hasFocus: true,
      isBackward: false,
    }),
  );
};

export const changeTag = newEditorState => {
  const contentState = newEditorState?.getCurrentContent();
  const first = contentState.getBlockMap().first();
  const second = contentState.getBlockAfter(first.getKey());
  const third = contentState.getBlockAfter(second.getKey());
  if (first && second && third) {
    const removeSelection = new SelectionState({
      anchorKey: third.getKey(),
      focusKey: third.getKey(),
    });

    const newData = Modifier.setBlockType(
      contentState,
      removeSelection,
      first.getType(),
    );

    const newContentState = EditorState.push(
      newEditorState,
      newData,
      'remove-range',
    );
    return newContentState;
  }
  return contentState;
};


export const getEditorState = (editorState: any, currentPage:any) => {
  const selection = currentPage?.currentEditorState?.getSelection();

  const newSelection = selection.merge({
    anchorKey: currentPage?.currentEditorState?.getCurrentContent()?.getFirstBlock()?.getKey(),
    anchorOffset: 0,
    focusOffset: currentPage?.currentEditorState?.getCurrentContent()?.getLastBlock()?.getText()?.length,
    focusKey: currentPage?.currentEditorState?.getCurrentContent()?.getLastBlock()?.getKey(),
  });


  const fragment = convertFromRaw(JSON.parse(editorState))?.getBlockMap();
  const nonStyledState = Modifier?.replaceWithFragment(
    currentPage.currentEditorState.getCurrentContent(),
    newSelection,
    fragment,
  );
  return EditorState.createWithContent(nonStyledState);
};

export const convertInlineStyles = (content, block) => {
  let text = block.text;

  block.inlineStyleRanges.forEach(range => {
    switch (range.style) {
      case 'BOLD':
        text = `<strong>${text.slice(range.offset, range.offset + range.length)}</strong>${text.slice(
          range.offset + range.length,
        )}`;
        break;
      // Add more cases for other inline styles as needed
      default:
        break;
    }
  });

  block.entityRanges.forEach(range => {
    const entity = content.entityMap[range.key];
    if (entity.type === 'LINK') {
      text = `<a href="${entity.data.url}">${text.slice(range.offset, range.offset + range.length)}</a>${text.slice(
        range.offset + range.length,
      )}`;
    }
    // Add more cases for other entities as needed
  });

  return text;
};

export const convertToHtml = (currentEditorState: any) => {
  const contentState = (currentEditorState as EditorState).getCurrentContent();
  const rawContentState = convertToRaw(contentState);

  let html = '';

  rawContentState.blocks.forEach(block => {
    switch (block.type) {
      case 'header-one':
        html += `<h1>${block.text}</h1>`;
        break;
      case 'header-two':
        html += `<h2>${block.text}</h2>`;
        break;
      case 'unordered-list-item':
        html += `<ul><li>${block.text}</li></ul>`;
        break;
      case 'ordered-list-item':
        html += `<ol><li>${block.text}</li></ol>`;
        break;
      default:
        html += `${convertInlineStyles(rawContentState, block)}`;
    }
  });

  return html;
};
