import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';


export const GoogleAdsTab = observer(() => {
  const {reportBuilder: {details: {setGoogleAdSelect, isConfig, setActiveTabKey, activeTabKey}}} = useStore('');

  const selectTab = key => {
    setActiveTabKey(key);
    key == 'conversion' ? setGoogleAdSelect({ip: 'all_conversions', key: 'allConversions', name: 'All Conversions', value: 1}) : key == 'call' ? setGoogleAdSelect({ip: 'calls', key: 'calls', name: 'Calls', value: 1}) :setGoogleAdSelect({ip: 'impressions', key: 'impressions', name: 'Impressions', value: 1});
  };

  useEffect(()=> {
    selectTab('campaign');
  }, [isConfig]);

  return (<>
    <Wapper className={`tabs`} id='scrollHeader'>
      <TabTitle border={activeTabKey == 'campaign'} onClick={()=> selectTab('campaign')} >Campaigns</TabTitle>
      <TabTitle border={activeTabKey == 'ads_group'} onClick={()=> selectTab('ads_group')} >Ad Groups</TabTitle>
      <TabTitle border={activeTabKey == 'search_term'} onClick={()=> selectTab('search_term')} >Search Term</TabTitle>
      <TabTitle border={activeTabKey == 'search_keyword'} onClick={()=> selectTab('search_keyword')} >Search Keyword</TabTitle>
      <TabTitle border={activeTabKey == 'demographic_device'} onClick={()=> selectTab('demographic_device')} >Device</TabTitle>
      <TabTitle border={activeTabKey == 'demographic_age'} onClick={()=> selectTab('demographic_age')} >Age</TabTitle>
      <TabTitle border={activeTabKey == 'demographic_gender'} onClick={()=> selectTab('demographic_gender')} >Gender</TabTitle>
      <TabTitle border={activeTabKey == 'conversion'} onClick={()=> selectTab('conversion')} >Conversions</TabTitle>
      <TabTitle border={activeTabKey == 'demographic_location'} onClick={()=> selectTab('demographic_location')} >Location</TabTitle>
      <TabTitle border={activeTabKey == 'call'} onClick={()=> selectTab('call')} >Call</TabTitle>
      {/* <TabTitle onClick={()=> selectTab('ads')} style={{borderBottom: activeTabKey == 'ads' ? '3px solid #2d6cca' : ''}}>Ads</TabTitle>
      <TabTitle onClick={()=> !isConfig && selectTab('placements')} style={{borderBottom: activeTabKey == 'placements' ? '3px solid #2d6cca' : ''}}>Placements</TabTitle>
      <TabTitle onClick={()=> selectTab('video')} style={{borderBottom: activeTabKey == 'video' ? '3px solid #2d6cca' : ''}}>Video</TabTitle> */}
    </Wapper>
  </>
  );
});

const TabTitle = styled.p<{isSelected?: string; border?: boolean}>`
border-bottom: ${props=> props.border ? '3px solid #4285f4' : ''};
color: ${props=> props.border ? 'black': 'gray'};
cursor: pointer;
`;


const Wapper = styled.div`

  display: flex;
  width: 1100px !important;
  margin: 0px 0 20px 0;
  border-bottom: 1px solid lightgray;
  p{
    margin: 0;
    padding-bottom: 15px;
    width: fit-content;
    margin-right: 22px;
    // margin-right: 45px;
    font-size: 14px;
    font-weight: 500;
  }
`;
