import {observer} from 'mobx-react';
import {SingleAiOutline} from './SingleAiOutline';
import {useStore} from '@/store/root-store';

interface Props {
  insertText: (outlineParam: any, id: number, shouldReplace?: boolean) => void;
}

export const AiWritterHistoryResults = observer(({insertText}: Props) => {
  const {contentOptimizer: {currentPage, aiOutline}} = useStore('');

  return <>
    {aiOutline?.aiWritterHistory?.find(item => item.uuid == currentPage?.content?.uuid)?.outlines?.map((singleOutline, idx) => {
      return <SingleAiOutline key={idx} outlineId={idx} aiOutline={singleOutline} insertToEditor={insertText}/>;
    })}
  </>;
});
