import {faFaceSurprise} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';

interface Props {
  rebuildPage?: () => void;
}

export const ProblemBanner = observer(({rebuildPage}: Props) => {
  return (
    <FloatingDiv style={{padding: 0, border: '1px solid #FF8536'}}>
      <div className='txt'>
        <FontAwesomeIcon icon={faFaceSurprise} fontSize={28} color='#FF8536' />
        <div style={{lineHeight: '18px'}}>
                AI Generation encountered a problem.{' '}
          <span onClick={rebuildPage} style={{textDecoration: 'underline', color: '#2D6CCA', cursor: 'pointer'}}>Click here to try again.</span>
        </div>
      </div>
    </FloatingDiv>
  );
});

const FloatingDiv = styled.div`
  border: 1px solid #D2D2D2;
  border-radius: 12px;
  width: 320px;
  height: 72px;
  overflow: hidden;

  .txt {
    display: flex;
    align-items: center;
    padding-right: 30px;
    padding-left: 24px;
    gap: 17px;
    color: #4E5156;
    height: 99%;
    background-color: #fff;
    border-radius: 12px;
  }
`;
