import {observer} from 'mobx-react';
import styled from 'styled-components';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useState} from 'react';
import {SingleQuestionSection} from './singleQuestionSection';
import {RadioButton} from '@/components/common-components/v2/radioButton';
import {Popover, Select} from 'antd';
import {SuggestionsPopup} from './SuggestionsPopup/SuggestionsPopup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import styles from '../../style.module.scss';
interface Props {
  state: any;
  setState: (state: any) => void;
  usedQuestions: string[];
  selectedFaq: any;
  setSelectedFaq: (state: any) => void;
  isAiQuestions: any;
  setIsAiQuestions: (state: any) => void;
}

export const Questions = observer(({state, setState, usedQuestions, selectedFaq, setSelectedFaq, isAiQuestions, setIsAiQuestions}: Props) => {
  const {contentOptimizer: {currentPage: {analytics}}} = useStore('');
  const [showError] = useState(false);

  const selectSufixIcon = <FontAwesomeIcon icon={faCaretDown} color={'#121212'}/>;

  // Reorders entire Focus Topic sections
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Trigers reorder after entire Focus Topic section drag finishes
  const onSectionDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index,
    );

    setState({
      ...state,
      items: items,
    });
  };
  const getListStyle = () => ({
    width: '100%',
  });

  const getItemStyle = (isDragging, draggableStyle) => {
    return ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '0px',
      margin: `0 0 0 0`,

      // styles we need to apply on draggables
      ...draggableStyle,
    });
  };

  // Updates state inside single Focus Topic section after reordering fields inside
  const onSingleFieldDragEnd = (index, newState) => {
    const curState = {...state};
    const items = curState['items'];
    items[index]['fields'] = newState;

    setState({
      ...state,
      items: items,
    });
  };

  const isEmptyState = (currentInputValue?: string) => {
    let isEmpty = true;
    if (currentInputValue) {
      isEmpty = false;
      return false;
    }
    for (let i = 0; i < state?.items?.length; i++) {
      if (state.items[i].value) {
        isEmpty = false;
        return false;
      } else {
        for (let n = 0; n < state.items[i].fields.length; n++) {
          if (state.items[i].fields[n].value) {
            isEmpty = false;
            return false;
          }
        }
      }
    }

    return isEmpty;
  };

  // Updates value for single focus topic on field edit
  const updateFocusTopic = (e, index: number) => {
    const curState = {...state};
    const items = curState['items'];
    const topic = items[index];

    topic['value'] = e.target.value;

    items[index] = topic;

    setState({
      ...state,
      items: items,
    });
    setState({
      ...state,
      isEmpty: isEmptyState(e.target.value),
    });
  };


  // Removes single Focus Topic section
  const removeTopicHandler = (index: number) => {
    const curState = {...state};
    const items = [...curState.items];

    items.splice(index, 1);

    setState({
      ...state,
      items: items,
    });
  };

  // Generates new Focus Topic section
  const addNewTopicField = () => {
    const curState = {...state};
    const items = [...curState.items, {
      value: '',
      label: 'question',
      placeholder: 'Question',
      fields: [],
    }];

    setState({
      ...state,
      items: items,
    });
  };


  const addBulkQuestionsFields = (total?: number) => {
    const curState = {...state};
    const items = curState['items']?.filter(item => item.value);
    const questionsList = toJS(analytics?.questions);
    for (let i = 0; i < total; i++) {
      if (questionsList[i]?.keyword) {
        items.push({
          label: 'Question',
          placeholder: 'Question',
          value: questionsList[i]?.keyword,
          fields: [],
        });
      }
    }

    const newState = {
      ...curState,
      items: items,
    };

    setState(newState);
  };

  // Adds single field into single Focus Topic section
  const addNewQuestionsField = (value?: string) => {
    const curState = {...state};
    const items = curState['items']?.filter(item => item.value);

    items.push({
      label: 'Question',
      placeholder: 'Question',
      value: value ? value : '',
      fields: [],
    });

    const newState = {
      ...curState,
      items: items,
    };

    setState(newState);
  };
  const {Option} = Select;
  const faqOptions = ['Short', 'Long'];

  return (
    <div>
      <RadioContainer>
        <RadioButton checked={isAiQuestions} onChange={() => setIsAiQuestions(true)} label='AI generate questions'/>
        <RadioButton checked={!isAiQuestions} onChange={() => setIsAiQuestions(false)} label='Write your own' />
      </RadioContainer>
      <SelectContainer>
        <OptionLabel>Answer length:</OptionLabel>
        <SelectStyled
          suffixIcon={selectSufixIcon}
          value={selectedFaq == 'long' ? 'Long' : 'Short'}
          onChange={value => setSelectedFaq(value)}>
          {faqOptions.map((item, idx) => {
            return <Option style={{fontSize: 12}} key={idx} value={item.toLowerCase()}>{item}</Option>;
          })}
        </SelectStyled>
      </SelectContainer>
      <Wrapper >
        {isAiQuestions ? <SectionLabel>AI will generate 5 questions related to your topic.</SectionLabel> : <>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
            <div>Questions:</div>
            <Popover
              autoAdjustOverflow={true}
              placement='topLeft'
              content={<SuggestionsPopup addItem={addNewQuestionsField} bulkAdd={addBulkQuestionsFields} usedQuestions={usedQuestions}/>}
              trigger='click'
              overlayClassName={styles.suggestionsPopover}>
              <GetSuggestionsBtn style={{marginLeft: 'auto', textDecoration: 'underline'}}>Get Suggestions <FontAwesomeIcon icon={faCaretDown}/></GetSuggestionsBtn>
            </Popover>
          </div>
          <DragDropContext onDragEnd={onSectionDragEnd}>

            <Droppable droppableId='droppable'>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle()}
                >
                  {state.items.map((item, index) => (
                    <Draggable key={index} draggableId={`${item.label}-${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <SingleQuestionSection
                            totalItems={state?.items?.length || 0}
                            totalTerms={item?.fields?.filter(item => item?.type == 'term').length}
                            totalQuestions={item?.fields?.filter(item => item?.type == 'question').length}
                            onSingleFieldDragEnd={onSingleFieldDragEnd}
                            updateItemTopic={updateFocusTopic}
                            currentItemState={item}
                            removeTopic={removeTopicHandler}
                            index={index}
                            showError={showError}/>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <AddMoreTopics onClick={() => addNewTopicField()}>+ Add Question</AddMoreTopics>
          </div>
        </>}
      </Wrapper>
    </div>);
});

const Wrapper = styled.div`
  // padding: 18px 30px;
  margin-top: 10px;
`;

const AddMoreTopics = styled.div`
    color: #2D6CCA;
    font-size: 12px;
    cursor: pointer;
`;

const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  .ant-radio-inner {
    border-color:#D2D2D2 !important;
  }
`;

const GetSuggestionsBtn = styled.div`
  color:  #2D6CCA;
  cursor: pointer;
  margin-right: 20px;
  font-size: 13px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 14px;
`;

const OptionLabel = styled.div`
  min-width: 110px;
  font-size: 14px;
`;

const SelectStyled = styled(Select)`
  .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;
    background-color:'#F9F9FB' !important;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02) !important;
    min-width: 160px;
    font-size: 12px;
  }
  .ant-select-item {
    font-size: 13px !important;
  }`
  ;

const SectionLabel = styled.div`
  color: #121212;
  font-weight: 600;
`;
