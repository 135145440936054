import {maxIndex} from '@/utils/arrays';
import sum from 'lodash/sum';

export function clap(value: number, min: number, max: number) {
  if (value < min) {
    value = min;
  }
  if (value > max) {
    value = max;
  }
  return value;
}

export const formatBytes=(bytes, decimals = 2)=> {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function getPercentage(value: number, min: number, max: number) {
  value = clap(value, min, max);
  return (value - min) * 100 / (max - min);
}

export function getPercentageValue(start: number, end: number, percentage: number) {
  percentage = clap(percentage, 0, 100);
  return (end - start) / 100 * percentage + start;
}

export function normalizePercentageRow(values: number[]): number[] {
  const total = sum(values);
  if (total == 0) {
    return values;
  }
  if (total > 100) {
    const copy = values.slice();
    const maxIdx = maxIndex(copy);
    copy[maxIdx] -= (total - 100);
    return copy;
  }
  return values;
}

export function formatFloat(value: number, precision: number): string {
  return parseFloat(value.toFixed(precision)).toString();
}

export function msToSec(value: number) {
  return value / 1000;
}

export const formatNumberWithoutPrecision = (num: number) => {
  if (num >= 1000000) {
    const million = (num / 1000000).toFixed(1);
    return million.replace(/\.0$/, '') + 'M';
  } else if (num >= 1000) {
    const thousand = (num / 1000).toFixed(1);
    return thousand.replace(/\.0$/, '') + 'K';
  } else {
    return num.toString();
  }
};

export function formatNumber(num: number, precision: number = 0) {
  if (Math.abs(num) > 999 && Math.abs(num) < 1000000) {
    return (num / 1000).toFixed(precision) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (Math.abs(num) >= 1000000) {
    return (num / 1000000).toFixed(precision) + 'M'; // convert to M for number from > 1 million
  } else if (Math.abs(num) < 1000) {
    if (typeof num == 'number') {
      return num.toFixed(precision); // if value < 1000, nothing to do
    }
    return num; // if value < 1000, nothing to do
  } else {
    return num; // Anything else should be return as is.
  }
}

export function formatLargeNumber(num: number, precision: number = 0) {
  if (Math.abs(num) > 999 && Math.abs(num) < 1000000) {
    return (num / 1000).toFixed(precision) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (Math.abs(num) >= 1000000000) {
    return (num / 1000000000).toFixed(precision) + 'B'; // convert to B for number from > 1 billion
  } else if (Math.abs(num) >= 1000000) {
    return (num / 1000000).toFixed(precision) + 'M'; // convert to M for number from > 1 million
  } else if (Math.abs(num) < 1000) {
    return num?.toFixed(precision); // if value < 1000, nothing to do
  } else {
    return num; // Anything else should be return as is.
  }
}

export function formattNumber(num: string) {
  const a = num.split('').filter(x=> x ===',').length;
  const m = num.split(',');
  if (a===2) return +m[1][0]===0 ? +m[0] + 'M' : +m[0] + '.' +m[1][0] + 'M';

  if (a===1) return +m[1][0]===0 ? +m[0] + 'K' : +m[0] + '.' +m[1][0] + 'K';

  if (a===0) return num;
}

export const numberWithCommas = (x: number):string => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const customNumberWithCommas = (x: any) => {
  try {
    return [null, undefined, 'null', 'undefined', NaN, 'NaN'].includes(x) ? '-' : x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch (error) {
    return x;
  }
};

export const handleNumberFormat = number => {
  if (Number.isInteger(number)) {
    return number;
  } else {
    const roundedNumber = number?.toFixed(2);
    return parseFloat(roundedNumber);
  }
};

export function isNumberString(str) {
  return !isNaN(parseFloat(str)) && isFinite(str);
}
