import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import styled from 'styled-components';

interface RingLoaderPropsV2 {
  fontSize?: number | string;
  color?: string;
  align?: string;
  width?: string;
  height?: string;
  style?: any;
}

export const RingLoaderV2 = ({
  fontSize=24,
  color='#2D6CCA',
  align='center',
  width='100%',
  height='100%',
  ...props
}: RingLoaderPropsV2) => {
  return (
    <LoaderContainer align={align} width={width} height={height} {...props}>
      <Spin indicator={<LoadingOutlined style={{fontSize: fontSize, color: color}} spin />} />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div<{align?: string; width?: string; height: string}>`
  display: flex;
  justify-content: ${p => p?.align};
  align-items: ${p => p?.align};
  width: ${p => p?.width};
  height: ${p => p?.height};
`;
