import React from 'react';
import EChartsReact from 'echarts-for-react';
import {observer} from 'mobx-react-lite';
import {renderToString} from 'react-dom/server';
import {numberWithCommas} from '@/utils/number';
import {NoDataWrapper} from '../StyledComponent';

interface Props {
  data?: any;
  loading?: boolean;
  dataLoader?: boolean;
}
export const CircleChart:React.FC<Props> = observer(({data, loading, dataLoader}) => {
  const tooltipFormatter = function(series: any) {
    const element = (
      <div style={{fontWeight: 500, color: '#121212'}}>{series?.data?.name}: {numberWithCommas(series?.value)}</div>
    );
    return renderToString(element);
  };
  const option={
    legend: {
      show: false,
    },
    tooltip: {
      formatter: tooltipFormatter,
    },

    backgroundColor: '#fff',
    color: ['#6f613f',
      '#8e7741',
      '#d5b453',
      '#cdb95f',
      '#d4cb95',
      '#b9c1ad'],
    series: [
      {
        name: 'chart',
        type: 'pie',
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1.5,
        },
        radius: ['40%', '60%'],
        data: loading ? [] : data,
        label: {
          show: true,
          color: '#4E5156',
          formatter: value=> {
            return value?.data?.name + '\n' + value?.data?.value + ' {value1|(' + value?.percent + '%)}';
          },
          rich: {
            value1: {
              color: '#a3a4a4',
            },
          },
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: '#4E5156',
          },
          length: 30,
          length2: 14,
        },
      },
    ],
    grid: {
      top: 70,
      left: 100,
      right: 30,
      bottom: 40,
    },
  };
  return (
    (loading || dataLoader || data?.length) ? <div>
      <EChartsReact
        style={{
          height: '360px',
          justifyContent: 'center',
          display: 'flex',
        }}
        option={option}/>
    </div> : <NoDataWrapper>No Data</NoDataWrapper>
  );
});
