import {types} from 'mobx-state-tree';
import {TargetKeywordsModel} from '../content-optimizer/TargetKeywordsModel';

export const PageModel = types.model({
  url: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  targetKeywords: types.maybeNull(types.array(TargetKeywordsModel)),
  title: types.maybeNull(types.string),
  importedFromUrl: types.maybeNull(types.string),
  requiredWordCount: types.maybeNull(types.number),
  version: types.maybeNull(types.string),
  maxSerpPosition: types.maybeNull(types.number),
  textPreview: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  readability: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
});
