export const filterNullValues = obj => {
  // Create a new object to store filtered data
  const filteredData = {};

  // Iterate through the object's entries
  Object.entries(obj).forEach(([key, value]) => {
    // Check if the value is not null
    if (key == 'gsc') {
      if (value?.['gscPropertyUrl']) {
        filteredData[key] = value;
      }
    } else if (key == 'gmb') {
      if (value?.['gmbIds']?.length) {
        filteredData[key] = value;
      }
    } else if (value !== null) {
      filteredData[key] = value;
    }
  });

  // Return the filtered data
  return filteredData;
};


export const heatmapHeightLogic = heatmapData => {
  const totalHeatmapLength = heatmapData?.length;
  const mapCount = heatmapData?.map(data => Math.ceil(data?.maps?.length ? (data?.maps?.length / 3) : 1)).reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return (totalHeatmapLength > 1 ? (totalHeatmapLength - 1) * 5.62 : 0) + ((mapCount - heatmapData?.length) * 4.3);
};

export const openInNewTab = async (url, redirectUrl) => {
  return new Promise((resolve, reject) => {
    const newWindow = window.open(url, '_blank');
    let successful = false;
    if (newWindow) {
      const interval = setInterval(() => {
        try {
          if (newWindow.closed) {
            clearInterval(interval);
            resolve(successful);
          }
          if (newWindow?.location?.href?.includes(redirectUrl)) {
            successful = true;
            newWindow.close();
          }
        } catch (error) {
          console.error(error);
        }
      }, 5000);
    } else {
      reject(new Error('Failed to open new tab'));
    }
  });
};
