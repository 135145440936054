import {Tabs, TabPane} from '@/components/common-components/components';
import {observer} from 'mobx-react';
import {useCallback, useEffect, useState} from 'react';
import styles from './style.module.scss';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {EmptyStateComponentV2} from '../../../../AiContentToolsDrawer/AiTemplates/Results/emptyStateComponentV2';
import {EmptyStateComponentV3} from '../../../../AiContentToolsDrawer/AiTemplates/Results/emptyStateComponentV3';
import {opaqueColor} from '@/utils/colors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-duotone-svg-icons';
import {AiWritterResults} from './AiWritterResults';
import {AiWritterHistoryResults} from './AiWritterHistoryResults';
import {debounce} from 'lodash';
import {EditorState, Modifier, SelectionState} from 'draft-js';
import {NextImg} from '@/utils/nextImg';

interface Props {
  htmlToDraft: any;
  selectedContentLength: 'short' | 'medium' | 'long';
  isContentOverWritten?: boolean;
  setIsContentOverwritten: (value: boolean) => void;
  elapsedTime?: number;
}

/**
 * Shows tabs with results and historical resutls
 *
 * @param {any} htmlToDraft library used for injecting any of the results into the editor
 */
export const Results = observer(({selectedContentLength = 'medium', isContentOverWritten, elapsedTime}: Props) => {
  const {
    contentOptimizer: {
      currentPage: {content},
      currentPage,
      aiOutline: {
        loading,
        isHTMLLength,
        creatingAiArticleSuccessStatus,
        creatingAiArticleFailureStatus,
        aiWriterController,
        oneClickOutline,
        setIsOpenAiWriterDrawer,
        loadingStatus,
        aiWriterOutlines,
        loadAiWriterHistory,
        updateSingleOutline,
      },
    },
  } = useStore('');
  const [activeKey, setActiveKey] = useState(content.aiInProgress ? '2' : '1');

  useEffect(() => {
    if (content.uuid) {
      loadAiWriterHistory(content.uuid);
    }
  }, [content.uuid]);


  useEffect(() => {
    if (loading) {
      setActiveKey('1');
    }
  }, [loading]);


  const onChangeTab = key => {
    setActiveKey(key);

    if (key == '2') {
      if (content.uuid) {
        loadAiWriterHistory(content.uuid);
      }
    }
  };


  const debouncedChange = debounce((state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations = false) => {
    // we are then calling the edit content method which will fire the API call and send new state to the BE
    currentPage.editContent(state, isNewDecorations);
  }, 2000);


  const debouncedUpdate = useCallback(
    (state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations) => debouncedChange(state, focusTerms, focusTermsClassNames, isNewDecorations),
    // eslint-disable-next-line
        []
  );

  const insertText = async (editorState: any, id: number, shouldReplace?: boolean) => {
    // NEW FLOW start
    // const {contentBlocks, entityMap} = htmlToDraft(outlineParam);

    const selection = currentPage.currentEditorState.getSelection();


    const newSelection = !shouldReplace ?
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getEndOffset(),
        focusKey: selection.getAnchorKey(),
        focusOffset: selection.getEndOffset(),
      }) :
      selection.merge({
        anchorKey: currentPage.currentEditorState.getCurrentContent().getFirstBlock().getKey(),
        anchorOffset: 0,
        focusOffset: currentPage.currentEditorState.getCurrentContent().getLastBlock().getText().length,
        focusKey: currentPage.currentEditorState.getCurrentContent().getLastBlock().getKey(),
      });

    const fragment = editorState.getCurrentContent().getBlockMap();

    const nonStyledState = Modifier.replaceWithFragment(
      currentPage.currentEditorState.getCurrentContent(),
      newSelection,
      fragment,
    );

    // WE are calculating nodes to toggle blue background on new Ai generated nodes
    let firstNewBlockId: number;
    const currentBlock = selection.getAnchorKey();
    const nextBlock = currentPage.currentEditorState.getCurrentContent().getBlockAfter(selection.getAnchorKey())?.getKey();

    const existingNodes = document.querySelectorAll('[data-block="true"]');
    existingNodes?.forEach((elem, id) => {
      if (elem.getAttribute('data-offset-key') && elem.getAttribute('data-offset-key') == `${currentBlock}-0-0`) {
        firstNewBlockId = id + 1;
      }
    });


    const updatedState = shouldReplace ?
      EditorState.createWithContent(nonStyledState) :
      EditorState.push(currentPage.currentEditorState, nonStyledState, 'insert-fragment');

    currentPage.setCurrentEditorState(updatedState);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('[data-block="true"]');
      if (newTextElems.length) {
        for (let i = firstNewBlockId; i <= newTextElems.length; i++) {
          if (newTextElems[i]?.getAttribute('data-offset-key') && newTextElems[i]?.getAttribute('data-offset-key') != `${nextBlock}-0-0` && newTextElems[i]?.getAttribute('data-offset-key') != `${currentBlock}-0-0`) {
            newTextElems[i].classList.add('newTextBlock');
          } else {
            return;
          }
        }
      }
    }, 500);

    debouncedUpdate(updatedState, currentPage.analytics?.focusTerms, currentPage.focusTermsClassNames, false);
    currentPage.setIsEditorFocus(true);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('.newTextBlock');
      if (newTextElems.length) {
        newTextElems.forEach(elem => {
          elem.classList.remove('newTextBlock');
        });
      }
    }, 3500);
    // NEW FLOW END
    updateSingleOutline(true, id);
  };

  // property 'lastGeneratedOutput' is set in mobx methods that generate content
  // there are 3 methods for 3 types of results,so we print diferent results page
  const renderResultsType = () => {
    let componentToUse = <></>;
    if (aiWriterController?.showForm) {
      componentToUse = aiWriterOutlines?.find(item => item.uuid == content.uuid)?.outlines?.length && isHTMLLength ?
        <AiWritterResults insertText={insertText}/> :
        (!content.aiInProgress && isHTMLLength) ? <EmptyStateComponentV3
          loading={loading && content.aiInProgress}
        /> : <HTMLResponseEmptyWrapper isTop={false}>
          <NextImg style={{width: '18px', height: '24px', marginRight: 10}} src='/img/boltAnimated.gif' alt='boltAnimated' />
          {/* <FontAwesomeIcon icon={faBolt} color={'#7F4EAD'} fontSize={25} style={{marginRight: 10}}/> */}
          <div>
            <div style={{fontSize: '14px', fontWeight: '500'}}>Request sent to the AI Writer</div>
            <Description isTop={false}>Your request has been added to the queue and will get started in a few moments ...</Description>
          </div>
        </HTMLResponseEmptyWrapper>;
    }

    return <ResultsWrapper>
      {componentToUse}
    </ResultsWrapper>;
  };

  // const getNumberOfResults = () => {
  //   let output = null;

  //   if (aiTemplatesController?.defaultForm) {
  //     if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
  //       output = aiOutline?.length ? aiOutline?.length : null;
  //     } else {
  //       output = v2AiOutline?.length ? v2AiOutline?.length : null;
  //     }
  //   } else {
  //     output = tempalteOutlines?.length ? tempalteOutlines?.length : null;
  //   }

  //   return output ? <NumResultsWrapper>{output}</NumResultsWrapper> : <></>;
  // };

  // const getNumberOfHistResults = () => {
  //   let output = null;

  //   if (aiTemplatesController?.defaultForm) {
  //     if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
  //       output = aiOutlineHistory?.length ? aiOutlineHistory?.length : null;
  //     } else {
  //       output = firstDraftHistoryOutlines?.length ? firstDraftHistoryOutlines?.length : null;
  //     }
  //   }

  //   if (!aiTemplatesController?.defaultForm) {
  //     output = templatesHistory?.length ? templatesHistory?.length : null;
  //   }

  //   return output ? <NumResultsWrapper>{output}</NumResultsWrapper> : <></>;
  // };


  return <Wrapper creatingAiArticleSuccessStatus={creatingAiArticleSuccessStatus || creatingAiArticleFailureStatus}>
    {(creatingAiArticleSuccessStatus || creatingAiArticleFailureStatus || loading) || content.aiInProgress ?
      <EmptyStateComponentV2 contentLength={selectedContentLength}
        loading={content.aiInProgress}
        useBeStatuses={true}
        beStatus={loadingStatus} elapsedTime={elapsedTime}
        variant='top'/> : null}
    <TabsWrapper creatingAiArticleSuccessStatus={creatingAiArticleSuccessStatus || creatingAiArticleFailureStatus}>
      <Tabs className={styles.tabsContainer}
        style={{marginTop: (loading && content.aiInProgress) ? 40 : '-35px'}}
        tabBarGutter={30}
        activeKey={activeKey}
        tabBarStyle={{'padding': '0 !important'}}
        defaultActiveKey={content.aiInProgress ? '2' : '1'}
        onChange={key => onChangeTab(key)}>
        <TabPane tab={<div style={{display: 'flex'}}>
          <span style={{marginRight: 5}}>New outputs</span>
        </div>}
        key='1'
        style={{'outline': 'none'}}>

          {(!loading && isContentOverWritten && oneClickOutline?.length) ? <ContentOverWrittenBanner>
            <FontAwesomeIcon icon={faCheckCircle} style={{marginRight: 5, fontSize: 15, marginBottom: 3}}/>
            Article content was replaced with the AI Generated Content.
            <span style={{color: '#2D6CCA', cursor: 'pointer', marginLeft: 3}} onClick={() => setIsOpenAiWriterDrawer(false)}>Close drawer.</span>
          </ContentOverWrittenBanner> : null}
          {content.aiInProgress ? <div style={{marginTop: 20}}></div> : null}
          {renderResultsType()}
        </TabPane>
        <TabPane tab={<div style={{display: 'flex'}}>
          <span style={{marginRight: 5}}>History</span>
          {/* {!isContentGeneratorPublic() && historyLoading ? <Spin indicator={antIcon} /> : getNumberOfHistResults()} */}
        </div>}
        key='2' style={{'outline': 'none'}}>
          <AiWritterHistoryResults insertText={insertText}/>
        </TabPane>
      </Tabs>
    </TabsWrapper>
  </Wrapper>;
});

const Wrapper = styled.div<{creatingAiArticleSuccessStatus?: boolean}>`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 1px -2px 20px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 20px 15px;
  height: 100%;
  overflow-y: ${p => p?.creatingAiArticleSuccessStatus ? '' : 'auto'};
  width: 575px;
  padding-bottom: 52px;
`;

const TabsWrapper = styled.div<{creatingAiArticleSuccessStatus?: boolean}>`
  margin-top: ${p => p?.creatingAiArticleSuccessStatus ? '52px' : ''};
  overflow-y: ${p => p?.creatingAiArticleSuccessStatus ? 'auto' : ''};
  height: ${p => p?.creatingAiArticleSuccessStatus ? '100%' : ''};
  width: ${p => p?.creatingAiArticleSuccessStatus ? '103%' : ''};
`;

const HTMLResponseEmptyWrapper = styled.div<{isTop?: boolean}>`
  width: ${p => p.isTop ? 'calc(100% + 40px)' : `360px`};
  height: 92px;
  border-radius: ${p => p.isTop ? '16px 16px 0 0' : `16px`};
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: ${p => p.isTop ? '30px' : `20px`};
  padding-right: 20px;
  margin: ${p => p.isTop ? '-40px -20px 0 -20px' : `60% auto`};
`;

const Description = styled.div<{isTop?: boolean}>`
  color: #4E5156;
  font-size: 12px;
  font-weight: 400;
`;

const ResultsWrapper = styled.div`

`;

const ContentOverWrittenBanner = styled.div`
  background-color: ${opaqueColor('#1FAC47', 15)};
  border-radius: 8px;
  width: 100%;
  padding: 9px 37px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;
