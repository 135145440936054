import {getApiUrl, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class InvoicesApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');

  public async loadOrderInvoices(type:any) {
    const url = type ? `${InvoicesApi.baseUrl}/invoices/?type=${type}` : `${InvoicesApi.baseUrl}/invoices/`;
    try {
      const response = await this.axios.get(url, {
        headers: {Authorization: getAuthorizationHeader()},
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async loadCurrentPlan() {
    try {
      const response = await this.axios.get(`${InvoicesApi.baseUrl}/payment/plan/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async fetchInvoiceDetail(invoiceSlug: string) {
    try {
      const response = await this.axios.get(`${InvoicesApi.baseUrl}/invoices/details/?invoice=${invoiceSlug}&type=customer_plan`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async fetchInvoicePDF(invoiceSlug: string) {
    try {
      const response = await this.axios.get(`${InvoicesApi.baseUrl}/invoices/pdf/?invoice=${invoiceSlug}&type=customer_plan`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async loadCurrentPayment() {
    const headers = {};
    const token = getTokenFromCookies();
    if (token) {
      headers['Authorization'] = getAuthorizationHeader();
      try {
        const response = await this.axios.get(`${InvoicesApi.baseUrl}/payment/methods/`, {
          headers: headers,
          cancelToken: this.cancelToken,
        });
        return response.data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
}

export default InvoicesApi;
