import Dialog from 'rc-dialog';
import classnames from 'classnames';
import {IDialogPropTypes} from 'rc-dialog/lib/IDialogPropTypes';

import styles from './styles.module.scss';
import 'rc-dialog/assets/index.css';
import React from 'react';


export interface ModalProps extends IDialogPropTypes {
  isDark?: boolean;
  ref?: React.RefObject<HTMLElement>;
}

export const Modal: React.FC<ModalProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Dialog {...props} bodyStyle={props.isDark ? {backgroundColor: styles.backgroundColor} : {}} className={classnames(styles.modal, className)}>
      {children}
    </Dialog>
  );
};
