import {InternalLinkType} from '@/store/content-optimizer/CurrentPageModel';
import {Tooltip} from 'antd';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {Button} from '@/components/common-components/v2';

interface Props {
  link: InternalLinkType;
  addItem: (value: {link: string; anchorText?: string}) => void;
}
export const SingleSuggestion = observer(({link, addItem}: Props) => {
  return <SingleLinkContainer>
    <Tooltip title={link?.title} placement='left' overlayInnerStyle={{background: '#000', borderRadius: 8}}>
      <Right>
        <TitleAndStar>
          <Title>
            {link?.title ? link.title : ''}
          </Title>
        </TitleAndStar>

        <Link href={link?.url} target='_blank' rel='noreferrer'>
          {link?.path}
        </Link>

      </Right>
    </Tooltip>
    <Button variant='ghost' color='blue' style={{fontSize: 12, padding: '5px 10px', marginRight: 0, marginLeft: 'auto', height: 30}} onClick={() => addItem({link: link?.url, anchorText: link?.title ?? ''})}>+ Add</Button>

  </SingleLinkContainer>;
});

const SingleLinkContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
`;
const Right = styled.div`
`;
const TitleAndStar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.div`
  cursor: pointer;
  color: #121212 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
`;
const Link = styled.a`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #34AEF3;
  width: 300px;

  &:hover {
    text-decoration: underline;
  }
`;


