import {types, Instance, flow, cast, getParent, getRoot} from 'mobx-state-tree';
import {reportsApi} from '@/api/gsc/index';
import {CriteriaType} from '../criteria';
import {formatSorters} from '@/utils/filters';
import {filterDataInMemory} from '@/utils/filter-data';
import {toJS} from 'mobx';
import {notification} from '@/utils/notification-v2';

const Pages = types.model({
  currentClicks: types.maybeNull(types.number),
  currentImpressions: types.maybeNull(types.number),
  currentPosition: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
});

const KeywordCannibalization = types.model({
  cpc: types.maybeNull(types.number),
  ctr: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  noOfPages: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  pages: types.optional(types.array(Pages), []),
  position: types.maybeNull(types.number),
  volume: types.maybeNull(types.number),
});
export const updateKeywordCannibalizationTable=types.model({
  name: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
});
export const filterList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.boolean,
});

export type KeywordCannibalizationType = Instance<typeof KeywordCannibalization>;

export type KeywordCannibalizationPagesType = Instance<typeof Pages>;

const CannibalizationPagesModel = types.model({
  page: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  avgCtr: types.maybeNull(types.number),
});

export type CannibalizationPagesType = Instance<typeof CannibalizationPagesModel>;

export const keywordCannibalization = types.model({
  keywordCannibalizationPageNumber: types.maybeNull(types.number),
  keywordCannibalizationPageSize: types.maybeNull(types.number),
  keywordCannibalizationCount: types.maybeNull(types.number),
  keywordCannibalizationTableData: types.optional(types.array(KeywordCannibalization), []),
  updateKeywordCannibalizationTableData: types.array(updateKeywordCannibalizationTable),
  keywordCannibalizationLoading: types.maybeNull(types.boolean),
  keywordCannibalizationSortField: types.maybeNull(types.string),
  keywordCannibalizationSortDirection: types.maybeNull(types.string),
  keywordCannibalizationSearchTerm: types.maybeNull(types.string),
  totalDataShow: types.maybeNull(types.boolean),
  initLoading: types.optional(types.boolean, true),
  isCannibalizationInit: types.boolean,
  isDrawerOpen: types.boolean,
  isAssignTaskDrawerOpen: types.boolean,
  selectedKeyword: types.maybeNull(types.string),
  selectedPage: types.maybeNull(types.string),
  drawerPagesData: types.maybeNull(types.array(CannibalizationPagesModel)),
  displayDrawerPagesData: types.maybeNull(types.array(CannibalizationPagesModel)),
  cannibalizationDrawerPagesLoading: types.optional(types.boolean, true),
  initCannibalizationDrawerPagesLoading: types.optional(types.boolean, true),
  cannibalizationDrawerSortField: types.maybeNull(types.string),
  cannibalizationDrawerSortDirection: types.maybeNull(types.string),
  cannibalizationDrawerSearchTerm: types.maybeNull(types.string),
  cannibalizationDrawerFilterList: types.array(filterList),
  cannibalizationDrawerCannibalizationPageNumber: types.maybeNull(types.number),
  cannibalizationDrawerCannibalizationPageSize: types.maybeNull(types.number),
  cannibalizationDrawerCannibalizationDataSize: types.maybeNull(types.number),
  isProjectChanged: types.maybeNull(types.string),
  cannibalizationPagesLoading: types.boolean,
  cannibalizationPagesData: types.maybeNull(types.array(CannibalizationPagesModel)),
}).views(self => ({
  get getKeywordCanibalizationData() {
    return toJS(self.keywordCannibalizationTableData);
  },
  get getUpdateKeywordCanibalizationData() {
    return toJS(self.updateKeywordCannibalizationTableData);
  },

})).actions(self => {
  const loadKeywordCannibalization = flow(function* (params, filterParam) {
    self.keywordCannibalizationLoading = true;
    yield doLoadKeywordCannibalization(params, filterParam);
    self.keywordCannibalizationLoading = false;
  });
  const updateKeywordCanibalizationDataColumnsList =properties=>{
    // console.log('properties', properties);
    self.updateKeywordCannibalizationTableData=properties;
    // self.pages = properties;
  };

  const doLoadKeywordCannibalization = flow(function* ({property, countryCode, currentPeriodStart, currentPeriodEnd, pageNumber, pageSize}, filterParam) {
    self.isCannibalizationInit = true;
    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      noOfPages: 'no_of_pages',
      cpc: 'cpc',
      volume: 'volume',
    };

    const formattedSorters = formatSorters(sorters, self.keywordCannibalizationSortField, self.keywordCannibalizationSortDirection);

    let params = {
      selected_property: property,
      page_number: pageNumber,
      page_size: pageSize,
      period_start: currentPeriodStart,
      period_end: currentPeriodEnd,
      country_code: countryCode,
      sort_by: formattedSorters,
    };
    if (Object.keys(filterParam)?.length) {
      params = {...params, ...filterParam};
    }
    try {
      const response = yield reportsApi.getKeywordCannibalizationReports(params);
      if (response.keywords) {
        self.keywordCannibalizationTableData = cast(response.keywords);
        self.keywordCannibalizationCount = cast(response.count);
        self.initLoading = false;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.isCannibalizationInit = false;
    }
  });

  const loadCannibalizationPagesData = flow(function* ({property, countryCode, keyword, currentPeriodStart, currentPeriodEnd}) {
    try {
      self.cannibalizationPagesLoading = true;
      const response = yield reportsApi.getMorePagesForKeyword(property, countryCode, keyword, currentPeriodStart, currentPeriodEnd);
      if (response) {
        self.cannibalizationPagesData = cast(response);
        loadInitialCannibalizationDrawerData();
        self.cannibalizationPagesLoading = false;
      }
    } catch (e) {
      self.cannibalizationPagesLoading = true;
      if (e.response?.status == 400) {
        notification.error('Error loading data', e.response?.data?.nonFieldErrors?.length ? e.response?.data?.nonFieldErrors[0] : '');
      }
      return Promise.reject(e);
    } finally {
      self.cannibalizationPagesLoading = false;
    }
  });

  const handleFilterRenderData = flow(function* (criteria: CriteriaType, pageNumber: number, pageSize: number, filterParam={}) {
    if (criteria.property) {
      yield loadKeywordCannibalization({...criteria, pageNumber, pageSize}, filterParam);
    }
  });

  const handleOnTableChange = async (pagination, filters, sorter, appliedFilterParams) => {
    const parent = getParent(self) as any;
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    onKeywordCannibalizationTableChange(sortFieldName, order);
    handleFilterRenderData(parent.criteria, self.keywordCannibalizationPageNumber, self.keywordCannibalizationPageSize, appliedFilterParams);
  };

  const onKeywordCannibalizationTableChange = (sortFields: string, order: string) => {
    self.keywordCannibalizationSortField = sortFields;
    self.keywordCannibalizationSortDirection = order;
  };

  const onKeywordCannibalizationUpdateSearchTerm = (searchTerm: string) => {
    self.cannibalizationDrawerSearchTerm = searchTerm;
  };

  const handleKeywordTermChange = async (searchedTerm: string) => {
    if (self.keywordCannibalizationSearchTerm !== searchedTerm) {
      onKeywordCannibalizationUpdateSearchTerm(searchedTerm);
      searchedTerm.length === 0 ? loadInitialCannibalizationDrawerData() :handleFilterRenderCannibalizationDrawerData();
    }
  };

  const setTotalDataShowed = (value: boolean) => {
    self.totalDataShow = value;
  };

  const onKeywordCannibalizationPaginationChange = (pageNumber: number, pageSize: number) => {
    self.keywordCannibalizationPageNumber = pageNumber;
    self.keywordCannibalizationPageSize = pageSize;
  };

  const onFilterCannibalizationChange = (filters: any) => {
    self.cannibalizationDrawerCannibalizationPageNumber = 1;
    self.cannibalizationDrawerFilterList = cast(filters);
    handleFilterRenderCannibalizationDrawerData();
  };


  const handlePaginationChange = (pageNumber: number, pageSize: number, appliedFilterParams) => {
    const totalRows = pageSize * pageNumber;
    const parent = getParent(self) as any;
    const rootStore = getRoot(self) as any;

    if (pageNumber == Math.ceil(self.keywordCannibalizationCount % self.keywordCannibalizationPageSize) || !rootStore.settings.customer.profile.plan) {
      setTotalDataShowed(true);
      return false;
    }
    setTotalDataShowed(totalRows >= self.keywordCannibalizationCount ? true : false);
    onKeywordCannibalizationPaginationChange(pageNumber, pageSize);
    handleFilterRenderData(parent.criteria, self.keywordCannibalizationPageNumber, self.keywordCannibalizationPageSize, appliedFilterParams);
  };

  const handleOpenDrawer = (kw: string) => {
    self.isDrawerOpen = true;
    self.selectedKeyword = kw;
  };

  const handleOpenAssignTaskDrawer = (page: string) => {
    self.isAssignTaskDrawerOpen = true;
    self.selectedPage = page;
  };

  const handleCloseDrawer = () => {
    self.isDrawerOpen = false;
  };

  const handleCloseAssignTaskDrawer = () => {
    self.isAssignTaskDrawerOpen = false;
  };

  const clearFilterList = () => {
    self.keywordCannibalizationPageNumber = 1;
    self.cannibalizationDrawerFilterList = cast([
      {id: 3, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'impressions', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
    ]);
  };
  const onProjectChange = value => {
    self.isProjectChanged = value;
  };


  const loadInitialCannibalizationDrawerData = () => {
    self.cannibalizationDrawerPagesLoading = true;
    const dataArray = self.cannibalizationPagesData;
    self.drawerPagesData.length = 0;
    if (dataArray && dataArray.length > 0) {
      const newArray = dataArray.map(item => {
        return {
          traffic: item?.traffic,
          impressions: item?.impressions,
          position: item.position,
          page: item.page,
          avgCtr: item.avgCtr,
        };
      });
      self.drawerPagesData = cast(newArray);
    }
    self.cannibalizationDrawerPagesLoading = false;
    self.initCannibalizationDrawerPagesLoading = false;
  };

  const handleFilterRenderCannibalizationDrawerData = () => {
    doHandleFilterRenderCannibalizationDrawerData(
      self.cannibalizationDrawerSearchTerm,
      self.cannibalizationDrawerFilterList,
      self.cannibalizationDrawerCannibalizationPageNumber,
      self.cannibalizationDrawerCannibalizationPageSize,
      self.cannibalizationDrawerSortField,
      self.cannibalizationDrawerSortDirection,
    );
  };

  const doHandleFilterRenderCannibalizationDrawerData = (cannibalizationDrawerSearchTerm, filters, pageNumber, pageSize, sortField, sortDirection) => {
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.drawerPagesData, cannibalizationDrawerSearchTerm, ['page'], filters, pageNumber, pageSize, sortField, sortDirection);
    self.cannibalizationDrawerCannibalizationDataSize = dataFilteredSize;
    self.displayDrawerPagesData.length = 0;
    const newArray = dataFiltered.map(item => {
      return {
        traffic: item.traffic,
        impressions: item.impressions,
        position: item.position,
        page: item.page,
        avgCtr: item.avgCtr,
      };
    });
    self.displayDrawerPagesData = cast(newArray);
    self.cannibalizationDrawerSearchTerm='';
  };

  const handleOnCannibalizationDrawerTableChange = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortField} = column || {};
    self.cannibalizationDrawerSortField = sortField;
    self.cannibalizationDrawerSortDirection = order;
    doHandleFilterRenderCannibalizationDrawerData('',
      self.cannibalizationDrawerFilterList,
      self.cannibalizationDrawerCannibalizationPageNumber,
      self.cannibalizationDrawerCannibalizationPageSize,
      self.cannibalizationDrawerSortField,
      self.cannibalizationDrawerSortDirection,
    );
  };

  const handleOnCannibalizationDrawerPaginationChange = (pageNumber: number, pageSize: number) => {
    self.cannibalizationDrawerCannibalizationPageNumber = pageNumber;
    self.cannibalizationDrawerCannibalizationPageSize = pageSize;
    doHandleFilterRenderCannibalizationDrawerData('',
      self.cannibalizationDrawerFilterList,
      pageNumber,
      pageSize,
      self.cannibalizationDrawerSortField,
      self.cannibalizationDrawerSortDirection,
    );
  };

  return {
    loadKeywordCannibalization,
    handleOnTableChange,
    handleKeywordTermChange,
    handleFilterRenderData,
    onFilterCannibalizationChange,
    clearFilterList,
    handleOpenAssignTaskDrawer,
    handlePaginationChange,
    handleCloseDrawer,
    handleCloseAssignTaskDrawer,
    handleOpenDrawer,
    loadInitialCannibalizationDrawerData,
    handleOnCannibalizationDrawerTableChange,
    handleFilterRenderCannibalizationDrawerData,
    handleOnCannibalizationDrawerPaginationChange,
    updateKeywordCanibalizationDataColumnsList,
    onProjectChange,
    loadCannibalizationPagesData,
  };
});


export function initKeywordCannibalization() {
  return keywordCannibalization.create({
    keywordCannibalizationPageNumber: 1,
    keywordCannibalizationPageSize: 20,
    keywordCannibalizationTableData: [],
    updateKeywordCannibalizationTableData: [],
    keywordCannibalizationLoading: true,
    cannibalizationPagesLoading: false,
    keywordCannibalizationSortField: '',
    keywordCannibalizationSortDirection: 'descend',
    keywordCannibalizationSearchTerm: '',
    totalDataShow: false,
    initLoading: true,
    isCannibalizationInit: true,
    isDrawerOpen: false,
    isAssignTaskDrawerOpen: false,
    selectedKeyword: '',
    selectedPage: '',
    drawerPagesData: [],
    displayDrawerPagesData: [],
    cannibalizationDrawerPagesLoading: true,
    initCannibalizationDrawerPagesLoading: true,
    cannibalizationDrawerSortField: '',
    cannibalizationDrawerSortDirection: 'descend',
    cannibalizationDrawerSearchTerm: '',
    cannibalizationDrawerFilterList: [
      {id: 3, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'impressions', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
    ],
    cannibalizationDrawerCannibalizationPageNumber: 1,
    cannibalizationDrawerCannibalizationPageSize: 10,
    cannibalizationDrawerCannibalizationDataSize: 0,
  });
}
