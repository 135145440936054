import {observer} from 'mobx-react';
import React from 'react';
import {COLORS} from '@/components/common-components/v2/colors';
import {formatNumber, getPercentage} from '@/utils/number';
import {Progress} from 'antd';
import styled from 'styled-components';
import {getInversePercentageColor} from '@/utils/colors';
import {AntSpinIcon} from '@/components/dashboard/pages/otto-page-detail/Utils/styledSpin';

interface Props {
  consumedData: number;
  totalData: number;
  heading?: string;
  isCenter?: boolean;
  fontSize?: number;
  loadingQuota?: boolean;
}

export const QuotaTracker: React.FC<Props> = observer(({
  consumedData,
  totalData,
  heading,
  isCenter,
  fontSize = 14,
  loadingQuota=false,
}) => {
  return (
    <div>
      <QuotaContainer isCenter={isCenter}><QuotaDivSA fontSize={fontSize}>
        <>
          <span style={{display: 'flex'}} >
            {heading}:
            <QuotaValues style={{marginRight: '5px'}}>{loadingQuota ? <AntSpinIcon color='#2d2f34' fontSize={12}/> : consumedData ? `${formatNumber(consumedData, 1)}`.replace('.0', '') : 0}</QuotaValues>
            of<QuotaSubValue style={{marginLeft: '5px', fontSize: 12}}>{loadingQuota ? <AntSpinIcon color='#2d2f34' fontSize={12}/> : totalData ? `${formatNumber(totalData, 1)}`.replace('.0', '') : '-'}</QuotaSubValue></span>
          <ProgressStyled
            trailColor={'#4E5156'}
            strokeColor={getInversePercentageColor(getPercentage(Number(consumedData), 0, Number(totalData)))}
            percent={getPercentage(Number(consumedData), 0, Number(totalData))}
            showInfo={false} />
        </>
      </QuotaDivSA> </QuotaContainer>
    </div>
  );
});

const QuotaValues = styled.div`
  margin-left:5px;
`;
const ProgressStyled = styled(Progress)`
font-size: 2px;
  .ant-progress-inner {
    height: 2px;
  }
`;
const QuotaSubValue = styled.div`
  font-size:12px;
  display: flex;
  align-items: center;
`;
const QuotaDivSA = styled.div<{fontSize?: number}>`
  display:flex;
  flex-direction:column;
  font-size: 12px;
  font-weight:400;
  color: ${COLORS.grayDark};
  @media screen and (max-width: 425px){
    font-size: ${p => p.fontSize !== null ? 12 : 14}px;
  }
  `;
const QuotaContainer = styled.div<{isCenter}>`
display:flex;
gap: 25px;
justify-content:${p=>p.isCenter ? '' : 'center'};
`;
