import classnames from 'classnames';

import styles from './style.module.scss';
import {Col, Row} from 'antd';
import Button from '../button';
import {useTranslation} from 'next-i18next';
import React from 'react';
import {NextImg} from '@/utils/nextImg';

export enum BannerVariant {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  CLOSE = 'CLOSE'
}

export interface BannerProps {
  show: boolean;
  title?: string;
  subtitle?: string;
  variant?: BannerVariant;
  className?: string;
}

export const Banner: React.FC<BannerProps> = ({
  show,
  title,
  subtitle,
  variant = BannerVariant.ERROR,
  className,
  ...props
}) => {
  const {t} = useTranslation('common');
  const classes = classnames(
    className,
    styles.banner,
    {
      [styles.error]: variant === BannerVariant.ERROR,
      [styles.warning]: variant == BannerVariant.WARNING,
      [styles.warning]: variant == BannerVariant.CLOSE,
    },
  );


  if (!show) return null;

  return (
    <div className={classes} {...props}>
      <Row align='middle' justify='space-between'>
        <Col>
          <Row gutter={24}>
            <Col>
              {variant == 'ERROR' && <NextImg src='/img/banner-error.svg' />}
              {variant == 'WARNING' && <NextImg src='/img/banner-warning.svg' />}
              {variant == 'CLOSE' && <NextImg src='' />}
            </Col>
            <Col>
              <div className={styles.title}>{title}</div>
              <div className={styles.subtitle}>{subtitle}</div>
            </Col>
          </Row>
        </Col>
        <Col>
          {/* TODO: Add logic to render this block depending on variant when required. This could potentially be updated to also support rendering a child if the required functionality is large */}
          {variant == 'ERROR' &&
          <Button size='lg' buttonType='transparent-with-border' onClick={() => location.reload()}>
            <Row gutter={8} align='middle'>
              <Col>
                <NextImg src='/img/reload.svg'/>
              </Col>
              <Col>
                {t('banner-refresh-page')}
              </Col>
            </Row>
          </Button>}
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
