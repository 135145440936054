// import dynamic from 'next/dynamic';
import {useRef} from 'react';
// const ReactQuill = dynamic(
//   () => import('react-quill'),
//   {ssr: false},
// );

const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import {ButtonStyled} from './styledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/pro-solid-svg-icons';
import FreezeWrapper from '@/components/dashboard/pages/otto-page-detail/Component/freezTooltip';
import {useRouter} from 'next/router';

interface props {
  value: string;
  onChange: (value: any) => void;
  editorError?: boolean;
  style?: any;
  showButton?: boolean;
  onBtnClick?: () => void;
  placeholder?: string;
  enableAllModules?: boolean;
  readOnly?: boolean;
  bounds?: string;
  isEmailTemplate?: boolean;
}

const variables = [
  {
    name: 'Website URL',
    value: '{{ cnt_website_url }}',
  },
  {
    name: 'First name',
    value: '{{ cnt_first_name }}',
  },
  {
    name: 'Last name',
    value: '{{ cnt_last_name }}',
  },
  {
    name: 'Contact title',
    value: '{{ cnt_title }}',
  },
  {
    name: 'Photo URL',
    value: '{{ cnt_photo_url }}',
  },
  {
    name: 'Facebook URL',
    value: '{{ cnt_facebook_url }}',
  },
  {
    name: 'Twitter URL',
    value: '{{ cnt_twitter_url }}',
  },
  {
    name: 'LinkedIn URL',
    value: '{{ cnt_linkedin_url }}',
  },
  {
    name: 'GitHub URL',
    value: '{{ cnt_github_url }}',
  },
];

const EditorComponentV2 = ({value, onChange, editorError, style, showButton, onBtnClick, readOnly, bounds, enableAllModules = false, placeholder='Email content', isEmailTemplate=false}: props) => {
  const fullToolbar = [
    [{'header': '1'}, {'header': '2'}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'bullet'}, {'list': 'ordered'}],
    ['link', 'image', 'video'],
    ['clean'],
  ];
  const modules = {
    toolbar: enableAllModules ? fullToolbar : [
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link', 'image'],
    ],
  };

  const quillRef = useRef(null);
  const router = useRouter();

  const handleInsertTextAtCursor = text => {
    const quill = quillRef.current?.editor;
    if (quill) {
      const cursorPosition = quill?.selection?.savedRange?.index;
      quill?.clipboard.dangerouslyPasteHTML(cursorPosition, text);
    }
  };

  return (
    <>
      <StyledReactQuill
        ref={quillRef}
        preserveWhitespace
        theme='snow'
        value={value}
        onChange={onChange}
        modules={modules}
        style={style}
        editorError={editorError}
        placeholder={placeholder}
        readOnly={readOnly}
        isEmailTemplate={isEmailTemplate}
        {...(bounds ? {bounds} : {})}
      />
      {isEmailTemplate && <VariablesContainer>
        Use these variables when referencing the website and the individual you are contacting.
        <div style={{marginTop: 8, gap: 5, marginBottom: 10}}>{variables?.map((variable, index) => index < 5 && <VariableContainer key={index} onClick={() => handleInsertTextAtCursor(variable?.value)}>+ {variable?.name}</VariableContainer>)}</div>
        <div style={{gap: 5}}>{variables?.map((variable, index) => index > 5 && <VariableContainer key={index} onClick={() => handleInsertTextAtCursor(variable?.value)}>+ {variable?.name}</VariableContainer>)}</div>
      </VariablesContainer>}
      {showButton && <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}><ButtonStyled onClick={() => onBtnClick()}><FontAwesomeIcon icon={faPaperPlane}/>&nbsp;&nbsp;<div>Send Email</div></ButtonStyled></FreezeWrapper>}
    </>
  );
};

export default EditorComponentV2;

const StyledReactQuill = styled(ReactQuill)<{editorError: boolean; isEmailTemplate: boolean}>`
  height: ${p => p.isEmailTemplate ? '320px !important' : ''};
  .ql-container {
    border-bottom-left-radius: ${p => p.isEmailTemplate ? '' : '16px !important'};
    border-bottom-right-radius: ${p => p.isEmailTemplate ? '' : '16px !important'};
    border-color: ${p => p.editorError ? '#ff4d4f' : '#E0E0E0'};
    overflow: visible;
  }
  .ql-toolbar {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    border-color: ${p => p.editorError ? '#ff4d4f' : '#E0E0E0'};
  }

  .ql-tooltip {
    z-index: 1;
  }
`;

const VariablesContainer = styled.div`
  height: 98px;
  background-color: rgba(242, 242, 245, 1);
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 11px 11px 16px 16px;
  color: rgba(78, 81, 86, 1);
  font-size: 12px;
  font-weight: 400px;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;`;

const VariableContainer = styled.span`
  background-color: rgba(255, 255, 255, 1);
  padding: 5px 10px 5px 10px;
  border-radius: 38px;
  margin-right: 6px;
  cursor: pointer;
`;
