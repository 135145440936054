import {BaseApi} from '@/api/base-api';
import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';

interface CompetitorTrendsProps {
  url: string;
  criteria?: string;
  periodStart: string;
  periodEnd: string;
  dateChoice: string;
  competitorsArr?: string[];
}


interface CompetitorReportProps {
  url: string;
  orderBy?: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
  p1Start: string;
  p1End: string;
  p2Start: string;
  p2End: string;
}

interface TopOrganicCompetitorsKeywordProps {
  property: string;
  orderBy?: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface HistoricaCompetitorDataProps {
  property: string;
  orderBy?: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
  p1Start: string;
  p1End: string;
  p2Start: string;
  p2End: string;
  competitor: string;
}

interface CompetitorKeywordUsageProps {
  url: string;
  orderBy?: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
  periodStart: string;
  periodEnd: string;
  competitors?: string[];
  getTrackedCompetitors?: boolean;
}

interface TrackedCompetitorsProps {
  url: string;
  orderBy?: string;
  search?: string;
}

interface AddTrackedCompetitorsProps {
  url: string;
  competitor: string;
}


class CompetitorDescoveryApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v1');


  public async getCompetitorTrends({url, criteria, periodStart, periodEnd, dateChoice, competitorsArr}: CompetitorTrendsProps) {
    try {
      const response = await this.axios.get(`${CompetitorDescoveryApi.baseUrl}/website-competitors-trends`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'site_property': url,
          criteria,
          'period_start': periodStart,
          'period_end': periodEnd,
          'date_choice': dateChoice,
          ...(competitorsArr?.length && {'competitors': competitorsArr.join(',')}),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getCompetitorsReport({url, orderBy, search, pageNumber, pageSize, p1End, p1Start, p2End, p2Start}: CompetitorReportProps) {
    try {
      const response = await this.axios.get(`${CompetitorDescoveryApi.baseUrl}/website-competitors-report`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'site_property': url,
          'period1_start': p1Start,
          'period1_end': p1End,
          'period2_start': p2Start,
          'period2_end': p2End,
          ...(orderBy && {'order_by': orderBy}),
          ...(search && {'search_by': search}),
          ...(pageNumber && {'page': pageNumber}),
          ...(pageSize && {'page_size': pageSize}),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCompetitorsKeywordUsage({url, orderBy, search, pageNumber, pageSize, periodStart, periodEnd, competitors, getTrackedCompetitors}: CompetitorKeywordUsageProps) {
    try {
      const response = await this.axios.get(`${CompetitorDescoveryApi.baseUrl}/website-competitors-keywords-usage`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'site_property': url,
          'period_start': periodStart,
          'period_end': periodEnd,
          ...(orderBy && {'order_by': orderBy}),
          ...(search && {'search': search}),
          ...(pageNumber && {'page': pageNumber}),
          ...(pageSize && {'page_size': pageSize}),
          ...(competitors && {'competitors': competitors.join(',')}),
          ...(getTrackedCompetitors && {'get_tracked_competitors': getTrackedCompetitors}),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getTopOrganicCompetitorKeyword({property, orderBy, search, pageNumber, pageSize}: TopOrganicCompetitorsKeywordProps) {
    try {
      const response = await this.axios.get(`${CompetitorDescoveryApi.baseUrl}/website-competitors-organic-keywords`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'site_property': property,
          ...(orderBy && {'order_by': orderBy}),
          ...(search && {'search_by': search}),
          ...(pageNumber && {'page': pageNumber}),
          ...(pageSize && {'page_size': pageSize}),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async getHistoricaCompetitorData({
    property,
    orderBy,
    search,
    pageNumber,
    pageSize,
    p1Start,
    p1End,
    p2Start,
    p2End,
    competitor,
  }: HistoricaCompetitorDataProps) {
    try {
      const response = await this.axios.get(`${CompetitorDescoveryApi.baseUrl}/website-competitor-detail`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'site_property': property,
          'period1_start': p1Start,
          'period1_end': p1End,
          'period2_start': p2Start,
          'period2_end': p2End,
          'competitor': competitor,
          ...(orderBy && {'order_by': orderBy}),
          ...(search && {'search': search}),
          ...(pageNumber && {'page': pageNumber}),
          ...(pageSize && {'page_size': pageSize}),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getTrackedCompetitors({url, orderBy, search}: TrackedCompetitorsProps) {
    try {
      const response = await this.axios.get(`${CompetitorDescoveryApi.baseUrl}/tracked-competitors/view-all/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          'site_property': url,
          ...(orderBy && {'order_by': orderBy}),
          ...(search && {'search': search}),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async addTrackedCompetitor({url, competitor}: AddTrackedCompetitorsProps) {
    try {
      const response = await this.axios.post(`${CompetitorDescoveryApi.baseUrl}/tracked-competitors/add/`,
        {
          'site_property': url,
          'url': competitor,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeTrackedCompetitor({url, competitor}: AddTrackedCompetitorsProps) {
    try {
      const response = await this.axios.post(`${CompetitorDescoveryApi.baseUrl}/tracked-competitors/remove/`,
        {
          'site_property': url,
          'url': competitor,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default CompetitorDescoveryApi;
