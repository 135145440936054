import React from 'react';
import {observer} from 'mobx-react-lite';
import styles from '@/components/dashboard/home/content/index.module.scss';
import styled from 'styled-components';
import {CampaignDetailTable} from '../campaignTable/campaignDetailTable';

interface CampaignsDetailProps {
  isInOTTO?: boolean;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
}

export const PageDetailContent: React.FC<CampaignsDetailProps> = observer(({isInOTTO, campaignsId, setCampaignsId}) => {
  return (
    <ContentWrapper className={styles.contentContainer} style={{background: 'transparent'}}>
      <CampaignDetailTable isInOTTO={isInOTTO} campaignsId={campaignsId} setCampaignsId={setCampaignsId}/>
    </ContentWrapper>
  );
});
const ContentWrapper = styled.div`
margin-top: -70px;
`;
