
import {faClose} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {notification} from 'antd';
import classnames from 'classnames';
import React, {Fragment} from 'react';
import styled from 'styled-components';
import styles from './styles.module.scss';

export const timeNotification = (barColor = '#1FAC47', icon, delay, message, undoAction, iconColor = '#1FAC47', onDelayEnd = null, crossAction = null) => {
  const key = `key${Date.now()}`;
  let delayTimeout;
  if (onDelayEnd) {
    delayTimeout = setTimeout(() => {
      onDelayEnd();
    }, (delay*1000));
  }
  return notification.open({
    message:
      <Fragment>
        <div className={styles.notificationContent}>
          <span className={styles.title}>{message}</span>
          <div
            className={styles.buttons}
            onClick={()=>{
              undoAction();
              clearInterval(delayTimeout);
              notification.close(key);
            }}
          > Undo</div>
        </div>
        <div style={{background: barColor}} className={styles.box2}></div>
        <Bar delay={delay}/>
      </Fragment>,
    placement: 'topRight',
    duration: delay,
    key,
    className: classnames(styles.notification, styles.errorNotification),
    icon: <div><FontAwesomeIcon color={iconColor} icon={icon} /></div>,
    closeIcon: <div onClick={()=> crossAction && crossAction()} className={styles.icon}><FontAwesomeIcon fontSize={16} color='white' icon={faClose} /></div>,
  });
};


const Bar = styled.div<{ delay: number }>`
  width: 376px;
  height: 5px;
  background: #000000;
  
  position: absolute;
  top: 63px;
  right: -1px;
  animation: ${p => (p.delay * 3)}s in-out backwards;

@keyframes in-out {
  0% {
    transform:scaleX(0);
    transform-origin:right;
  }
  45% {
    transform:scaleX(1);
    transform-origin:right;
  }
  55% { 
    transform:scaleX(1);
    transform-origin:left;
  }
}
`;


