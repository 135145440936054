import {observer} from 'mobx-react-lite';
import React from 'react';
import EChartsReact from 'echarts-for-react';
import {formatLargeNumber, numberWithCommas} from '@/utils/number';
import moment from 'moment';
import {LoadingOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {NoDataWrapper} from '../StyledComponent';
import {renderToString} from 'react-dom/server';
import {getFormattedPreviousDate} from '@/utils/moment';

const dateRangeObj = {
  last_1_month: '30 days',
  last_3_months: '3 months',
  last_6_months: '6 months',
  last_year: '1 year',
};

interface Props {
  data?: any;
  loading?: boolean;
  isBacklinks?: boolean;
  legends?: any [];
  dataLoader?: boolean;
  cardType?: string;
  dateRange?: any;
}
export const DubbleLineChart:React.FC<Props> = observer(({data, loading, legends, isBacklinks, dataLoader, cardType='newUsers', dateRange}) => {
  const toolTipFormatterLineChart = data => {
    const element = (<TooltipContent>
      <TooltipTitle>{moment(data[0]?.axisValue).format(isBacklinks ? 'MMM YYYY' : 'DD MMM YYYY')}</TooltipTitle>
      <TooltipItems>
        {data?.map((item, idx) => {
          return <TooltipItem key={idx}>
            <div dangerouslySetInnerHTML={{__html: item?.marker}} />
            {item?.seriesName}:
            <span style={{marginLeft: 5, color: '#121212'}}>{numberWithCommas(item?.data)}</span>
          </TooltipItem>;
        })}
      </TooltipItems>
    </TooltipContent>
    );
    return renderToString(element);
  };
  const legendsData = legends ? legends : ['New Users ', 'New Users'];
  const isDarkTheme = false;
  const option ={
    legend: {
      show: true,
      icon: 'circle',
      left: 0,
      top: 10,
    },
    textStyle: {
      color: isDarkTheme ? '#E8E8E8' : '#4E5156',
    },
    grid: {
      left: 18,
      right: 20,
      bottom: 20,
      top: 65,
      containLabel: true,
    },
    tooltip: {
      formatter: toolTipFormatterLineChart,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: data?.map(z => z?.date),
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [0, 10, 0, 0],
        formatter: function(value) {
          return value ? moment(value)?.format(isBacklinks ? 'MMM YYYY' : 'MMM DD') : '';
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          type: 'dashed',
          color: isDarkTheme ? '#4E5156' : '#E8E8E8',
        },
      },
    },
    yAxis: {
      minInterval: 1,
      type: 'value',
      axisLine: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: isDarkTheme ? '#4E5156' : '#E8E8E8',
        },
      },
      axisLabel: {
        formatter: function(value: number) {
          return formatLargeNumber(value || 0);
        },
      },
    },
    series: [
      {
        name: cardType == 'newUsers' ?
          `New Users (last ${dateRange?.dateRangeStart ? `${moment(dateRange?.dateRangeStart).format('ll')} - ${moment(dateRange?.dateRangeEnd).format('ll')}` :
            `${dateRangeObj[dateRange]}`})` : `Total Users (last ${dateRange?.dateRangeStart ? `${moment(dateRange?.dateRangeStart).format('ll')} - ${moment(dateRange?.dateRangeEnd).format('ll')}` :
            `${dateRangeObj[dateRange]}`})`,
        data: isBacklinks ? data?.map(z=> z?.backlinks) : data?.map(z => (cardType == 'newUsers' ? z?.newUsers : z?.totalUsers)),
        type: 'line',
        symbol: 'none',
        itemStyle: {
          color: isBacklinks ? '#144198' : '#F1AA3E',
        },
      },
      {
        name: cardType == 'newUsers' ?
          `New Users (previous ${dateRange?.dateRangeStart ? getFormattedPreviousDate(dateRange?.dateRangeStart, dateRange?.dateRangeEnd) :
            `${dateRangeObj[dateRange]}`})` : `Total Users (previous ${dateRange?.dateRangeStart ? getFormattedPreviousDate(dateRange?.dateRangeStart, dateRange?.dateRangeEnd) :
            `${dateRangeObj[dateRange]}`})`,
        data: isBacklinks ? data?.map(z=> z?.refdomains) : data?.map(z => (cardType == 'newUsers' ? (z?.newUsers - z?.newUsersDelta) : (z?.totalUsers - z?.totalUsersDelta))),
        type: 'line',
        symbol: 'none',
        itemStyle: {
          color: isBacklinks ? '#34AEF3' : '#845D22',
        },
        lineStyle: {
          type: isBacklinks ? 'solid' : 'dotted',
        },
      },
    ],
  };
  const loadingOptions = {
    grid: {
      top: 25,
      bottom: 25,
      left: 50,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr'],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: isDarkTheme ? '#4E5156' : '#4E5156',
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 3000,
        axisLabel: {
          color: isDarkTheme ? '#4E5156' : '#4E5156',
        },
        splitLine: {
          lineStyle: {
            color: isDarkTheme ? '#4E5156' : '#4E5156',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        min: 0,
        max: function(value) {
          return value.max;
        },
        axisLabel: {
          color: '#4E5156',
        },
        splitLine: {
          lineStyle: {
            color: '#e8e8e8',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        min: 10,
        max: function(value) {
          return value.max;
        },
        axisLabel: {
          color: '#4E5156',
        },
        splitLine: {
          lineStyle: {
            color: '#4E5156',
            type: 'dashed',
          },
        },
      },
    ],
  };
  const antIcon=<LoadingOutlined style={{color: '#4e5156', fontSize: '30px'}} spin/>;
  return (
    loading || dataLoader ? <div style={{position: 'relative', top: '10px'}}>
      <div style={{position: 'absolute', left: '45%', top: '50%'}}>{antIcon}</div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {legendsData?.map((item, index) => {
          return <div key={index}>
            <div>
              <div style={{lineHeight: '22px', whiteSpace: 'nowrap', marginLeft: '20px', color: '#4e5156', display: 'flex'}}><CheckBox/>&nbsp;&nbsp;{item}</div>
            </div>
          </div>;
        })}
      </div>
      <EChartsReact
        style={{height: '360px'}}
        option={loadingOptions}
      />
    </div> : !data?.length ? <NoDataWrapper>No Data</NoDataWrapper> :
      <div>
        <EChartsReact
          option={option}
          style={{
            height: '360px',
          }}
        />
      </div>
  );
});

const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 100%;
  background: #e8e8e8;
`;
export const TooltipContent = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
export const TooltipItem = styled.div`
 display: flex;
 align-items: center;
 font-size: 12px;
`;
export const TooltipItems = styled.div`
 display: flex;
 flex-direction: column;
`;
export const TooltipTitle = styled.div`
  font-size: 12px;
  color: #121212;
  font-weight: 600;
`;
