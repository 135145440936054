import {useStore} from '@/store/root-store';
import {countryFlag} from '@/utils/countries';
import {formatNumber} from '@/utils/number';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import {Checkbox, Input, Select} from 'antd';
import {observer} from 'mobx-react';
import {useState} from 'react';
import {Globe, Search} from 'react-feather';
import styles from './styles.module.scss';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';

interface LocationSelectorProps {
  id: number;
  availableCountryCodes: any[];
  activeCountryCodes: string[];
  locationCountryChange: (id: number, value: string, check: boolean) => void;
}

const {Option} = Select;


const LocationSelector = observer(({id, availableCountryCodes, activeCountryCodes, locationCountryChange}: LocationSelectorProps) => {
  const {navBar} = useStore('');
  const [searchValue, setSearchValue] = useState('');
  const [showBanner, setShowBanner] = useState(false);

  const onDropdownVisibleChange = () => {
    setSearchValue('');
  };

  const openLiveChat = () => {
    const chatBtn: HTMLElement = document.querySelector('.cc-157aw') as HTMLElement;
    if (chatBtn) {
      chatBtn.click();
    }
  };
  const handleCountryCheckboxChange = (value, check) => {
    locationCountryChange(id, value, check);
  };


  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {(!navBar.loadingSearchPerformance) ? activeCountryCodes?.length > 0 ?
        <span style={{marginLeft: '5px', pointerEvents: 'none'}} className={styles.selection}>
          <div style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}> {activeCountryCodes.slice(0, 5)?.map((flg:any, index: number)=>{
            return <>
              {flg == '' ? <Globe height={'18'} style={{width: '16px'}} />:
                countryFlag(flg, '18px')}
              <p style={{margin: '0 3px 0 2px', fontSize: '12px'}}>{flg == '' ? 'World' : flg}{activeCountryCodes?.length-1 > index ? ',' : ''}</p>
            </>;
          }) }
          </div>
          <span>{activeCountryCodes?.length > 5 ? '...' : ''}</span>
        </span> :
        <span style={{marginLeft: '10px', pointerEvents: 'none'}} className={styles.selection}>
                  Select location(s)
        </span>: null}
      <div className={styles.locationWrapper} style={{justifyContent: 'center', width: '270px'}}>
        {!navBar.loadingSearchPerformance ?
          <Select
            onDropdownVisibleChange={() => onDropdownVisibleChange()}
            showSearch={false}
            className={styles.selectInput}
            dropdownClassName={styles.dropdown}
            dropdownStyle={{minWidth: '293px'}}
            mode='multiple'
            labelInValue
            getPopupContainer={trigger => trigger.parentNode.parentNode}
            filterSort={(optionA, optionB) =>
              (optionA?.sort ?? '').toLowerCase().localeCompare((optionB?.sort ?? '').toLowerCase())
            }
            dropdownRender={menu => (
              <>
                {
                  setShowBanner(availableCountryCodes?.every(e => e?.value < 10))
                }
                <div className={styles.dropdownTopDiv}>
                  <div>LOCATION</div>
                  <div>TRAFFIC</div>
                </div>
                <div onClick={e=> e.stopPropagation()}
                  className={''}>
                  <Input
                    placeholder={'Search...'}
                    value={searchValue}
                    onChange={e => setSearchValue(e?.target?.value)}
                    prefix={<Search width={15}/>}
                    bordered={false}
                  />
                </div>
                {menu}
                {showBanner &&
                <StyledBlueBanner>
                  <FontAwesomeIcon icon={faCircleInfo} fontSize={16} color='#2d6cca' style={{paddingTop: 2}}/>
                  <div>Locations might not be visible because your website is new or lacks traffic.<br/>If you suspect an error, please contact us through <span onClick={openLiveChat}>live chat</span>.</div>
                </StyledBlueBanner>}
              </>
            )}
          >
            {
              (searchValue == '' ? availableCountryCodes : availableCountryCodes.filter(item => item?.name?.toLowerCase().startsWith(searchValue?.toLowerCase())))?.map(country => {
                const isCheckted = activeCountryCodes.includes(country.countryCode);
                return (
                  <Option key={country.countryCode} country={country?.name} value={country?.name} sort={country.countryCode == '' ? '0' : isCheckted ? '1': '2'}>
                    <Checkbox className={styles.checkbox} onChange={e => {
                      handleCountryCheckboxChange(country.countryCode, e.target.checked);
                    }} checked={isCheckted}>
                      <div style={{display: 'flex', justifyContent: 'space-between', width: '240px'}}>
                        {country.countryCode == '' ?
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <span><Globe className={styles.globeIconDisabled} /></span>
                            <span style={{fontWeight: 600, marginBottom: '4px'}} className={styles.locationTextDisabled}>Worldwide</span>
                          </div> :
                          <span>{countryFlag(country?.countryCode)} {country?.name}</span>
                        }
                        <div className={styles.organicTrafficValue}>{country?.value ? formatNumber(country?.value) : 0}</div>
                      </div>
                    </Checkbox>
                  </Option>
                );
              })
            }
          </Select> :
          <LoadingOutlined style={{fontSize: '24px', marginRight: 'auto'}}/>}
        <CaretDownOutlined style={{color: '#121212', marginRight: 5}} />
      </div>
    </div>
  );
});

export default LocationSelector;
const StyledBlueBanner = styled.div`
display: flex;
gap: 4px;
padding: 8px 8px;
margin: 5px 12px;
border-radius: 12px;
background: rgba(45, 108, 202, 0.15);
  div {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    span {
      color: #2d6cca;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
`;
