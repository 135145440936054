import {BaseApi} from '@/api/base-api';
import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';

class SiteStructureApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');

  public async loadSiteStructure(params, parentUrl) {
    const data = params;
    if (parentUrl) {
      data['parent_page'] = parentUrl;
    }
    try {
      const response = await this.axios.get(`${SiteStructureApi.baseUrl}/site-structure/`, {
        params: data,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
export default SiteStructureApi;
