import {
  DatePicker,
  Dropdown,
} from 'antd';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight, faSortDown} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import {Button} from '@/components/common-components/v2';
import {gbpPostStatus, gbpPostTypes, gbpDatesList} from '../../../../Constants';
import {StyledMenu} from '../../../../style';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import styles from './style.module.scss';
interface DateRange {
  startDate: string;
  endDate: string;
}
interface PostCreatedAt {
  created: DateRange;
  scheduled: DateRange;
  published: DateRange;
  unpublished: DateRange;
  updated: DateRange;
  rejected: DateRange;
}
interface Props {
  data: any;
  isActiveFilter?: string;
  onStatusChange: any;
  isSitewide: boolean;
  postCreatedAt: PostCreatedAt;
  setPostCreatedAt: React.Dispatch<React.SetStateAction<PostCreatedAt>>;
  onDateFilter: any;
  onTypeChange: any;
  selectedPostType: string;
  onDateClearAll: any;
  appliedDateFilters: string[];
  setAppliedDateFilters: Dispatch<SetStateAction<string[]>>;
}
const OPEN_KEYS = [];
const status = ['All', 'Draft', 'Scheduled', 'Published'];
export const GBPPostFilters: React.FC<Props> = observer(({
  data,
  onStatusChange,
  isSitewide,
  isActiveFilter,
  postCreatedAt,
  setPostCreatedAt,
  onDateFilter,
  onTypeChange,
  selectedPostType,
  onDateClearAll,
  appliedDateFilters,
  setAppliedDateFilters,
}) => {
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [openKeys, setOpenKeys] = useState(OPEN_KEYS);
  useEffect(() => {
    if (!openDateFilter) {
      setOpenKeys(OPEN_KEYS);
    }
  }, [openDateFilter]);

  const onOpenChange = openKeys => {
    setOpenKeys(openKeys);
  };
  const handleDatePickerChange = (date: moment.Moment | null, dateString: string, key: string, parent: string) => {
    setPostCreatedAt(prev => ({
      ...prev,
      [parent]: {
        ...prev[parent],
        [key]: dateString,
      },
    }));
  };
  useEffect(() => {
    if (data) {
      onDateFilter(data?.issueType, data?.pageSize, isSitewide);
    }
  }, [appliedDateFilters?.length]);

  return (
    <StyledWrapper>
      <Dropdown
        trigger={['click']}
        overlay={<StyledMenu style={{width: '90px'}} selectedKeys={[selectedPostType]} onClick={e => onTypeChange(e.key, data?.issueType, data?.pageSize, isSitewide)}>
          {gbpPostTypes.map(type => (
            <StyledMenu.Item key={type.key}>{type.label}</StyledMenu.Item>
          ))}
        </StyledMenu>}
      >
        <StyledClicker isApplied={selectedPostType !== 'ALL'}>
          {selectedPostType === 'UPDATE' ? 'Update' : selectedPostType === 'EVENT' ? 'Event' : selectedPostType === 'OFFER' ? 'Offer' : 'Type'}
          <FontAwesomeIcon icon={faSortDown} fontSize={14} color='#121212'/></StyledClicker>
      </Dropdown>
      <Dropdown
        trigger={['click']}
        visible={openDateFilter}
        onVisibleChange={setOpenDateFilter}
        overlay={<StyledDateMenu
          expandIcon={<></>}
          openKeys={openKeys}
        >
          {gbpDatesList.map(item => {
            const isApplied = (postCreatedAt[item['key']]?.startDate || postCreatedAt[item['key']]?.endDate);
            return (item.children ? (
              <StyledDateMenu.SubMenu
                key={item.key}
                popupClassName={styles.datePopover}
                title={<div className='filter-item' onMouseOver={() => onOpenChange([item.key])}>
                  <div className='label'>
                    {item.label}{isApplied && appliedDateFilters.includes(item['key']) ? <AppliedCount isCurrentlyApplied>1</AppliedCount> : isApplied ? <AppliedCount>1</AppliedCount> : <></>}
                  </div>
                  <div className='icon-wrapper'>
                    {isApplied ? <FontAwesomeIcon icon={faXmark} fontSize={14} color='#A3A4A4' onClick={() => {
                      setAppliedDateFilters(prevState => {
                        const updatedState = prevState?.filter(str => str !== item['key']);
                        return updatedState;
                      });
                      setPostCreatedAt({...postCreatedAt, [item['key']]: {...postCreatedAt[item['key']], startDate: '', endDate: ''}});
                    }}/> : <></>}
                    <FontAwesomeIcon icon={faCaretRight} fontSize={14} color='#121212'/>
                  </div>
                </div>}
              >
                <DateFilterWrapper
                  key={item.key}
                >
                  {item.children.map(child => (
                    <React.Fragment key={child.key}>
                      {child.label}
                      <div style={{padding: '5px 0px'}}>
                        <StyledDatePicker
                          disabled={child.key === 'endDate' && !postCreatedAt[child.parent]['startDate']}
                          value={postCreatedAt[child.parent][child.key] ? moment(postCreatedAt[child.parent][child.key]) : null}
                          format={'DD MMM, YYYY'}
                          disabledDate={currentDate => {
                            const isBeforeStartDate = postCreatedAt[child.parent]['startDate'] && currentDate.isBefore(postCreatedAt[child.parent]['startDate'], 'day');
                            return isBeforeStartDate;
                          }}
                          onChange={(date, dateString) => {
                            if (!dateString) {
                              setAppliedDateFilters(prevState => {
                                const updatedState = prevState?.filter(str => str !== item['key']);
                                return updatedState;
                              });
                              handleDatePickerChange(date, dateString, child.key, child.parent);
                            } else {
                              onOpenChange([item.key]);
                              handleDatePickerChange(date, dateString, child.key, child.parent);
                            }
                          }}
                        />
                      </div>
                    </React.Fragment>))}
                  <div style={{padding: '5px 0px'}}>
                    <ApplyButton
                      disabled={!item.children.every(child => postCreatedAt[child.parent]?.startDate || postCreatedAt[child.parent]?.endDate)}
                      onClick={() => {
                        onOpenChange([]);
                        onDateFilter(data?.issueType, data?.pageSize, isSitewide);
                      }}
                    >
                        Apply Filter
                    </ApplyButton>
                  </div>
                </DateFilterWrapper>
              </StyledDateMenu.SubMenu>
            ) : <StyledMenu.Item
              key={item.key}
              style={{color: '#2D6CCA', fontWeight: 400, fontSize: '12x'}}
              onClick={() => item?.key !== 'clearAll' ? {} : onDateClearAll(data?.issueType, data?.pageSize, isSitewide)}
            >{item.label}</StyledMenu.Item>
            );
          })}
        </StyledDateMenu>}
      >
        <StyledClicker onClick={() => setOpenDateFilter(true)} isApplied={appliedDateFilters.length ? true : false}>
          Date {appliedDateFilters?.length ? <AppliedCount isCurrentlyApplied>{appliedDateFilters.length}</AppliedCount> : <></>}
          <FontAwesomeIcon icon={faSortDown} fontSize={14} color='#121212'/>
        </StyledClicker>
      </Dropdown>
      <Dropdown
        trigger={['click']}
        overlay={<StyledMenu selectedKeys={isActiveFilter === 'all' ? ['All']: [isActiveFilter]} onClick={e => onStatusChange(e.key, data?.issueType, data?.pageSize, isSitewide)}>
          {status.map(status => (
            <StyledMenu.Item key={gbpPostStatus[status]}>{status}</StyledMenu.Item>
          ))}
        </StyledMenu>}
      >
        <StyledClicker isApplied={isActiveFilter !== 'all' && isActiveFilter !== 'All'}>
          {(isActiveFilter === 'all' || isActiveFilter === 'All') ? 'Status' : isActiveFilter} <FontAwesomeIcon icon={faSortDown} fontSize={14} color='#121212'/></StyledClicker>
      </Dropdown>
    </StyledWrapper>
  );
});
const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const StyledDatePicker = styled(DatePicker)`
  border-radius: 6px;
`;
const ApplyButton = styled(Button)`
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
`;
const StyledClicker = styled.div<{isApplied?: boolean}>`
  cursor: pointer;
  min-width: 68px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  color: #A3A4A4;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  gap: 6px;
  border-radius: 6px;
  background-color: ${p => p?.isApplied ? 'rgba(127, 78, 173, 0.17)' : '#ffffff'};
  border: ${p => p?.isApplied ? '1px solid rgba(180, 152, 208, 1)' : '1px solid rgba(232, 232, 232, 1)'};
  svg {
    margin-top: -6px;
  }
`;
const StyledDateMenu = styled(StyledMenu)`
  width: 220px;
  border: 1px solid rgba(205, 205, 205, 1);
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.15);
  .ant-dropdown-menu-submenu {
    .ant-dropdown-menu-submenu-title {
      padding: 0px;
      .ant-dropdown-menu-title-content {
        .filter-item {
          padding: 5px 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .label {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #121212;
            display: flex;
            align-items: center;
            gap: 6px;
          }
          &:hover {
            background-color: rgba(127, 78, 173, 0.17);
          }
          .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
          }
        }
      }
    }
  }
`;
const AppliedCount = styled.div<{isCurrentlyApplied?: boolean}>`
  width: 20px;
  height: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: 600;
  background-color:${p => p?.isCurrentlyApplied ? '#7F4EAD' : 'rgba(163, 164, 164, 0.25)'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 20px;
`;
const DateFilterWrapper = styled(StyledMenu.Item)`
  padding: 0px 15px;
  &:hover {
    background-color: #fff;
  }
`;
