import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class PageGroupsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');

  public async getPageGroups({property, countryCode}, publicHash?: string) {
    try {
      const response = await this.axios.get(`${PageGroupsApi.baseUrl}/page-groups/`, {
        params: {
          site_property: property,
          country_code: countryCode,
          ...(publicHash && {site_share_hash: publicHash}),
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createPageGroup({property, name, pages}) {
    try {
      const response = await this.axios.post(`${PageGroupsApi.baseUrl}/page-groups/`,
        {
          site_property: property,
          name: name,
          pages: pages,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updatePageGroup({id, name, pages}) {
    try {
      const response = await this.axios.patch(`${PageGroupsApi.baseUrl}/page-groups/${id}/`,
        {
          name: name,
          pages: pages,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deletePageGroup(id: number) {
    try {
      const response = await this.axios.delete(`${PageGroupsApi.baseUrl}/page-groups/${id}/`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default PageGroupsApi;
