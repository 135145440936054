import {observer} from 'mobx-react';
import {PageDetailContent} from '../page-content/pageContentDetail';
import {Wrapper} from './styledComponents';
import {OpportunityHeader} from './sections/header';
import {ConfigureModal} from './sections/configureModal';
import {useStore} from '@/store/root-store';
import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import styles from '@/components/dashboard/home/project/create.module.scss';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

interface CampaignsDetailProps {
  isInOTTO?: boolean;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
}

export const CampaignsDetail: React.FC<CampaignsDetailProps> = observer(({isInOTTO, campaignsId, setCampaignsId}) => {
  const {campaigns: {getChartData, getCampaignSettings, getCampaignsCustomerEmails}} = useStore('');
  const router = useRouter();
  const id = isInOTTO ? campaignsId : router?.query?.id;

  useEffect(() => {
    if (id) {
      getChartData(Number(id));
      getCampaignSettings(Number(id));
      getCampaignsCustomerEmails();
    }
  }, [router.query.id]);

  if (!id) {
    return <div className={styles.campaignsLoader}>
      <Spin indicator={<LoadingOutlined style={{fontSize: 50, color: '#7f4ead'}} spin />} />
    </div>;
  }

  return (
    <>
      <OpportunityHeader isInOTTO={isInOTTO} campaignsId={campaignsId} setCampaignsId={setCampaignsId}/>
      <Wrapper>
        <PageDetailContent isInOTTO={isInOTTO} campaignsId={campaignsId} setCampaignsId={setCampaignsId}/>
      </Wrapper>
      <ConfigureModal />
    </>
  );
});
