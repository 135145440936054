import styled from 'styled-components';

export const GrayCircle = styled.div<{width?: any; height?: any; positionTop?: any; positionLeft?: any}>`
  position: absolute;
  left: ${props => props.positionLeft ? props.positionLeft : '50%'};
  top: ${props => props.positionTop ? props.positionTop : '50%'};
  transform: translate(-50%, -50%);
  width: ${props => props.width ? props.width : 'min(50vw, 50vh)'};
  height: ${props => props.height ? props.height : 'min(50vw, 50vh)'};
  background: rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    width: 250px !important;
    height: 250px !important;
  }
`;

export const PinPointWrapper = styled.div`
  position: relative;
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: 16%;
  left: 38%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  z-index: 3;
`;
