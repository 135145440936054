import numeral from 'numeral';
import {Loss} from './loss';
import {Gain} from './gain';
import {formatNumber} from '@/utils/number';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp} from '@fortawesome/pro-regular-svg-icons';
export interface DeltaProps {
  currentValue: number;
  previousValue: number;
  isPercentage: boolean;
  invertResult?: boolean;
  showTooltip?: boolean;
  secondTooltip?: any;
  showCustomValue?: boolean;
  isPosition?: boolean;
  tooltipPlacement?:any;
  replaceDiffWithPrevValue?:boolean;
  customDeltaValue?:number;
  valueWithoutFormate?:boolean;
  isLost?: boolean;
}
interface DeltaForSingleProps {
  format: number;
  isTwoDecimals?: boolean;
  isPercent?: boolean;
}


export const Delta = ({currentValue, showCustomValue=false, tooltipPlacement='bottom', previousValue, isPercentage, invertResult, showTooltip = false, secondTooltip, isPosition = false, customDeltaValue, valueWithoutFormate, isLost=false}: DeltaProps) => {
  // If we are using delta to show position, then we need to pass isPosition flag, because only then value of 0 means that previous value doesnt even exist
  if (isPosition && (previousValue === 0 || !previousValue)) return <span style={{color: '#2AC155', fontSize: 12, marginLeft: 3, minWidth: 31, textAlign: 'right'}}>new</span>;
  if (isPosition && (currentValue === 0 || !currentValue)) return <span style={{color: '#ff4434', fontSize: 12, marginLeft: 3, minWidth: 31, textAlign: 'right'}}>lost</span>;
  if (currentValue >= 0 || showCustomValue) {
    const deltaValue = currentValue - Math.max(previousValue, 0);
    const isGain = invertResult ? deltaValue * -1 > 0 : deltaValue > 0;
    if (deltaValue === 0) {
      return (
        <div style={{paddingTop: '5px', paddingBottom: '5px', paddingLeft: '10px', paddingRight: '10px'}}>
          <span style={{color: 'transparent'}} className='text'>0</span>
        </div>
      );
    }
    let deltaFormated = `${formatNumber(Math.ceil(Math.abs(deltaValue)))}`;
    if (isPercentage) {
      deltaFormated = (previousValue === 0 || previousValue) && ((deltaValue/previousValue == Infinity || deltaValue/previousValue == -Infinity) ? '∞': `${numeral(Math.abs(deltaValue/previousValue||0)*100).format('0')}%`);
    }
    return (isGain ?
      <Gain isLost={isLost} valueWithoutFormate={valueWithoutFormate} showTooltip={showTooltip} tooltipPlacement={tooltipPlacement} currentValue={currentValue || 0} previousValue={previousValue || 0} secondTooltip={secondTooltip}>
        {!showCustomValue ? (Math.abs(deltaValue / previousValue || 0) * 100 > 10000) && !showCustomValue ?
          <span>∞</span> : deltaFormated: customDeltaValue ? formatNumber(customDeltaValue, 2)?.toString()?.replace('.00', '') : deltaFormated}
      </Gain> :
      <Loss valueWithoutFormate={valueWithoutFormate} showTooltip={showTooltip} currentValue={currentValue || 0} tooltipPlacement={tooltipPlacement} previousValue={previousValue || 0} secondTooltip={secondTooltip}>
        {!showCustomValue ? (Math.abs(deltaValue / previousValue || 0) * 100 > 10000) && !showCustomValue ?
          <span>∞</span> : deltaFormated: customDeltaValue ? formatNumber(customDeltaValue, 2)?.toString()?.replace('.00', '') : deltaFormated}
      </Loss>);
  }
  // default if empty values (-1 is empty)
  return <>-</>;
};

export const DeltaWithBackground = ({currentValue, showCustomValue=false, customDeltaValue, previousValue, isPercentage, invertResult, showTooltip = false, secondTooltip, isPosition = false, replaceDiffWithPrevValue = true}: DeltaProps) => {
  // If we are using delta to show position, then we need to pass isPosition flag, because only then value of 0 means that previous value doesnt even exist
  if (isPosition && (previousValue === 0 || !previousValue)) return <span style={{color: '#2AC155', fontSize: 12, marginLeft: 3, minWidth: 31, textAlign: 'right'}}>new</span>;
  if (currentValue >= 0 || showCustomValue) {
    const deltaValue = currentValue - Math.max(previousValue, 0);
    const isGain = invertResult ? deltaValue * -1 > 0 : deltaValue > 0;
    if (deltaValue === 0) {
      return (
        <div style={{paddingTop: '5px', paddingBottom: '5px', paddingLeft: '10px', paddingRight: '10px'}}>
          <span style={{color: '#A3A4A4'}} className='text'>0</span>
        </div>
      );
    }
    let deltaFormated = `${formatNumber(Math.ceil(Math.abs(deltaValue)))}`;
    if (isPercentage) {
      deltaFormated = (previousValue === 0 || previousValue) && ((deltaValue/previousValue == Infinity || deltaValue/previousValue == -Infinity) ? '∞': `${numeral(Math.abs(deltaValue/previousValue||0)*100).format('0')}%`);
    }

    return (isGain ?
      <div style={{backgroundColor: '#2AC1551A', width: 'auto', height: 22, alignItems: 'center', display: 'flex', paddingRight: 5, borderRadius: 5}}>
        <Gain showTooltip={showTooltip} currentValue={currentValue || 0} previousValue={previousValue || 0} secondTooltip={secondTooltip} >
          {(customDeltaValue) ? formatNumber(customDeltaValue) : !replaceDiffWithPrevValue ? Math.abs(deltaValue/previousValue||0)*100 > 10000 && !showCustomValue ? <span>∞</span> : deltaFormated : formatNumber(previousValue)}
        </Gain>
      </div> :
      <div style={{backgroundColor: '#F443431A', width: 'auto', height: 22, alignItems: 'center', display: 'flex', paddingRight: 5, borderRadius: 5}}>
        <Loss showTooltip={showTooltip} currentValue={currentValue || 0} previousValue={previousValue || 0} secondTooltip={secondTooltip}>
          {(customDeltaValue < 0) ? formatNumber(customDeltaValue* -1) : !replaceDiffWithPrevValue ? Math.abs(deltaValue/previousValue||0)*100 > 10000 && !showCustomValue ? <span>∞</span> : deltaFormated : formatNumber(previousValue)}
        </Loss>
      </div> );
  }
  // default if empty values (-1 is empty)
  return <>-</>;
};

export const CustomVolatilityDelta = ({currentValue, previousValue}: DeltaProps) => {
  const isGain = currentValue > previousValue;
  const deltaValue = isGain ? currentValue - previousValue : previousValue - currentValue;
  if (deltaValue == 0) return <>-</>;
  if (deltaValue > 10000) {
    return isGain ? <Gain>∞</Gain> : <Loss>∞</Loss>;
  }
  return isGain ? <Gain>{`${numeral(deltaValue).format('0.[0]')}%`}</Gain> : <Loss>{`${numeral(deltaValue).format('0.[0]')}%`}</Loss>;
};


export const DeltaForSingleValue = ({format, isTwoDecimals, isPercent}: DeltaForSingleProps) => {
  let obj = {color: '', arrow: <></>, number: ''};
  if (format > 0) {
    obj = {
      color: '#1FAC47',
      arrow: <FontAwesomeIcon icon={faArrowUp} color='#1FAC47'/>,
      number: isTwoDecimals ? formatNumber(format, 2)?.toString()?.replace('.00', '') : `${formatNumber(format, 1)?.toString()?.replace('.0', '')}`,
    };
  } else if (format < 0) {
    obj = {
      color: '#F44343',
      arrow: <FontAwesomeIcon icon={faArrowDown} color='#F44343'/>,
      number: isTwoDecimals ? formatNumber(format, 2).toString().replace('-', '')?.replace('.00', '') : formatNumber(format, 1).toString().replace('-', '')?.replace('.0', ''),
    };
  } else {
    obj = {
      color: '#a3a4a4',
      arrow: <></>,
      number: '',
    };
  }
  return (
    <div style={{color: obj?.color, display: 'flex', alignItems: 'center', fontSize: '12px'}}>&nbsp;<div>{obj?.arrow}</div>&nbsp;{obj?.number}{isPercent ? '%' : ''}</div>
  );
};
