import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class TopKeywordsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');

  public async getKeywordPositionChanges(params) {
    try {
      const response = await this.axios.get(`${TopKeywordsApi.baseUrl}/position-keyword-changes/`, {
        params: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getKeywordPositionChangesChart(params) {
    try {
      const response = await this.axios.get(`${TopKeywordsApi.baseUrl}/position-keyword-changes-chart/`, {
        params: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default TopKeywordsApi;
