import {getApiUrl} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class DomainAnalyzerApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.BACKLINK_ENDPOINT, '/backlink');

  public async getData(website, captcha?, pollingToken?) {
    try {
      const response = await this.axiosWithoutAuth.post(`${DomainAnalyzerApi.baseUrl}/domain-analyzer/`, {
        website: website,
        captcha: captcha,
      }, {headers: {Authorization: pollingToken || ''}, cancelToken: this.cancelToken});
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // If polling is active use this request
  public async getPageSpeedIndex(website, pollingToken?) {
    try {
      const response = await this.axiosWithoutAuth.post(`${DomainAnalyzerApi.baseUrl}/domain-analyzer/`, {
        website: website,
      }, {headers: {Authorization: pollingToken || ''}, cancelToken: this.cancelToken});
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default DomainAnalyzerApi;
