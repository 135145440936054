import {useEffect, useState} from 'react';
import {Divider, Form, Popover, Spin} from 'antd';
import {
  CommonInput,
} from '@/components/common-components/components';
import {Button as V2Button, GrayButton} from '@/components/common-components/v2/Button';
import styles from './style.module.scss';
import {observer} from 'mobx-react';
import {LoadingOutlined} from '@ant-design/icons';
import {MixPanel} from '@/utils/mixpanel';
import {SCA_PAGE_AI_OUTLINE_GENERATE} from '@/constants/events';
import {useStore} from '@/store/root-store';
import {faArrowLeft, faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faXmark} from '@fortawesome/pro-solid-svg-icons';
import {isNil} from 'lodash';
import styled from 'styled-components';
import {toJS} from 'mobx';
import {SuggestionsPopup} from './SuggestionsPopup/SuggestionsPopup';
import {isContentGeneratorPublic} from '@/utils/url';
import {NextImg} from '@/utils/nextImg';

interface Props {
  pageId: any;
  drawerVisible: boolean;
  pageTitle?: string;
  showPageTitle?: boolean;
  setShowPageTitle?: (value: boolean) => void;
}

export const ContentIdeaForm = observer(({pageId, drawerVisible, pageTitle, showPageTitle, setShowPageTitle}: Props) => {
  const {contentOptimizer: {currentPage, aiOutline}} = useStore('');
  const {settings: {customer: {getCustomerQuota}}} = useStore('');
  const [resultsCounter, setResultsCounter] = useState(5);
  const [showTermsToInclude, setShowTermsToInclude] = useState(false);
  const [usedTerms, setUsedTerms] = useState('');


  const antIcon = <LoadingOutlined style={{fontSize: 24, marginLeft: '10px', color: '#fff', marginTop: '5px'}} spin />;

  const checkPageTitle = (title: string) => {
    if (title) {
      const regexp = new RegExp('^New Document');
      return regexp.test(title);
    } else {
      return false;
    }
  };


  const [form] = Form.useForm();
  useEffect(() => {
    if (!drawerVisible) form.resetFields();
  }, [drawerVisible]);

  useEffect(() => {
    if (!isContentGeneratorPublic()) {
      if (!aiOutline?.aiTemplatesController?.defaultForm) return;

      // methods for standalone tool and regular tool are different
      // in regular editor drawer we send page uuid, not the case with stanalone tool
      if (aiOutline?.aiTemplatesController?.isStandaloneTool) aiOutline.loadAiOutlineHistoryStandalone();

      if (!aiOutline?.aiTemplatesController?.isStandaloneTool) aiOutline.loadAiOutlineHistory(currentPage?.content?.uuid);
    }
  }, []);


  const onFormSubmit = async (values: {[key: string]: string}) => {
    if (!values['title']) {
      setShowPageTitle(true);
      checkPageTitle(pageTitle);
      return;
    }

    try {
      if (!aiOutline?.aiTemplatesController?.defaultForm) return;

      // methods for standalone tool and regular tool are different
      // in regular editor drawer we send page uuid, not the case with stanalone tool
      if (aiOutline?.aiTemplatesController?.isStandaloneTool) {
        await aiOutline.loadAiOutlineStandalone(values['title'], values['termsToInclude'], resultsCounter);
        aiOutline.loadAiOutlineHistoryStandalone();
      }

      if (!aiOutline?.aiTemplatesController?.isStandaloneTool) {
        await aiOutline.loadAiOutline(currentPage.content?.uuid, values['title'], values['termsToInclude'], resultsCounter);
        aiOutline.loadAiOutlineHistory(currentPage?.content?.uuid);
      }
    } catch (e) {
      Promise.reject(e);
    } finally {
      getCustomerQuota();
    }

    // aiOutline.loadAiOutline(pageId);

    MixPanel.track(SCA_PAGE_AI_OUTLINE_GENERATE, {
      'pageId': pageId,
      'description': 'Generate headings and paragraphs for related subtopics.',
    });
  };

  const generateNewOutline = (value: string) => {
    form.setFieldsValue({title: value});
    setShowPageTitle(false);
  };


  const resetFormHandler = () => {
    form?.setFieldsValue({
      title: '',
      termsToInclude: '',
    });
    setResultsCounter(5);
  };

  const setCounterInputValue = e => {
    if (e.target?.value) {
      setResultsCounter(e.target?.value);
    }
  };

  const generateResultsCunterErrorMsg = () => {
    let msg = '';
    if (isNil(resultsCounter)) {
      msg = 'Number of desired outlines is required.';
    }

    if (resultsCounter < 1 || resultsCounter > 20) {
      msg = 'Only values from 1 to 20 are allowed.';
    }

    return msg;
  };
  const validationRegex = /^[A-Za-z0-9 ._-]*[A-Za-z0-9][A-Za-z0-9 ._-]*$/;

  const addSuggestedTermHandler = (value: string) => {
    const currentlyUsedTerms = [];
    usedTerms.split(',').forEach(item => {
      if (item) {
        currentlyUsedTerms.push(item);
      }
    });
    if (currentlyUsedTerms.includes(value)) return;

    currentlyUsedTerms.push(value);
    const stringifiedData = currentlyUsedTerms?.length > 0 ? currentlyUsedTerms.join(',') : currentlyUsedTerms[0];
    setUsedTerms(stringifiedData);
    form.setFieldsValue({termsToInclude: stringifiedData});
  };

  const removeSuggestedTermHandler = (value: string) => {
    const currentlyUsedTerms = [];
    usedTerms.split(',').forEach(item => {
      if (item !== value) {
        currentlyUsedTerms.push(item);
      }
    });
    const stringifiedData = currentlyUsedTerms?.length > 0 ? currentlyUsedTerms.join(',') : currentlyUsedTerms[0] ?? '';
    setUsedTerms(stringifiedData ?? []);
    form.setFieldsValue({termsToInclude: stringifiedData});
  };

  return (
    <>
      <Header>
        <Left>
          <BackArrowContainer onClick={() => {
            if (aiOutline?.aiTemplatesController?.isStandaloneTool) {
              aiOutline?.setIsOpenAiContentToolsDrawer(false);
            }
            aiOutline?.setAiTemplate({
              ...toJS(aiOutline?.aiTemplatesController ?? []),
              showForm: false,
              templateType: '',
              templateIcon: '',
              templateDesc: '',
              templateName: '',
              fieldsSetup: [],
              defaultForm: false,
              defaultFormType: null,
              isStandaloneTool: false,
            });
          }}>
            <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 10}}/>
          </BackArrowContainer>
          <ImgContainer>
            <NextImg src={aiOutline?.aiTemplatesController?.templateIcon} style={{height: 25, margin: 'auto'}}/>
          </ImgContainer>


        </Left>
        <DescContainer>
          <TemplateName>{aiOutline.aiTemplatesController?.templateName}</TemplateName>
          <TemplateDesc>{aiOutline.aiTemplatesController?.templateDesc}</TemplateDesc>
        </DescContainer>
      </Header>
      <Divider />
      <Form form={form} onFinish={onFormSubmit} initialValues={{
        title: '',
        termsToInclude: '',
        resultsCounter: 5,
      }}>
        <div>
          <p style={{marginBottom: 5, color: '#4E5156'}}>Content Topic</p>
          <div style={{display: 'flex'}}>
            <Form.Item name='title'
              rules={[{
                validator: (_, value) => {
                  if (value?.match(validationRegex)) {
                    return Promise.resolve();
                  } else {
                    setShowPageTitle(true);
                    return Promise.reject(new Error('Missing Content Topic.'));
                  }
                },
              }]}
              style={{width: '100%', marginBottom: 0}}>
              <CommonInputStyled
                type='text'
                variant='white'
                placeholder='Enter a title like “Bordeaux Wine Tours”'
                className={styles.input} />
            </Form.Item>
          </div>
          {!checkPageTitle(pageTitle) && showPageTitle && !aiOutline?.aiTemplatesController?.isStandaloneTool ? <div style={{marginBottom: 10}}>You can generate ideas for SEO For
            <span style={{marginLeft: 5, color: '#2D6CCA', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => generateNewOutline(pageTitle)}>{pageTitle}</span>
          </div> : null}
          {showTermsToInclude ? <div style={{marginTop: 20}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <p style={{color: '#4E5156', marginTop: 0, marginBottom: 'auto'}}>{`Terms to Include (optional)`}</p>
              <p style={{color: '#A3A4A4', marginTop: 0, marginBottom: 'auto', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                setShowTermsToInclude(false);
                form.setFieldsValue({
                  termsToInclude: '',
                });
              }}>
                Hide field
              </p>
            </div>
            <Form.Item name='termsToInclude'
              style={{width: '100%', marginBottom: 0}}>
              <CommonInputStyled
                type='text'
                variant='white'
                placeholder='Divide multiple terms by commas'
                onChange={e => setUsedTerms(e.target.value)}
                className={styles.input} />
            </Form.Item>
            {aiOutline?.aiTemplatesController?.isStandaloneTool ? null : <Popover
              destroyTooltipOnHide={true}
              autoAdjustOverflow={true}
              placement='bottomLeft'
              content={<SuggestionsPopup
                addItem={addSuggestedTermHandler}
                removeItem={removeSuggestedTermHandler}
                usedTerms={usedTerms?.split(',')?.map(item => item.trim())}/>}
              trigger='click'
              overlayClassName={styles.suggestionsPopover}>
              <AddMoreTermsButton style={{marginLeft: 'auto', textDecoration: 'underline'}}>Get Suggestions <FontAwesomeIcon icon={faCaretDown}/></AddMoreTermsButton>
            </Popover>}
          </div> : <div style={{cursor: 'pointer', color: '#2D6CCA', marginTop: 10}} onClick={() => setShowTermsToInclude(true)}>+ Add terms to include</div>}
          <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25}}>
            <GrayButton style={{marginLeft: 0}} onClick={() => resetFormHandler()}>
              <FontAwesomeIcon style={{marginRight: 10}} icon={faXmark} />
              Clear inputs
            </GrayButton>

            <div style={{display: 'flex'}}>
              {(!resultsCounter || resultsCounter < 1 || resultsCounter > 20) ? <span style={{fontSize: 12, color: '#ff5959'}}>{generateResultsCunterErrorMsg()}</span> : null}
              <input type='number' value={resultsCounter} className={styles.numberInput} defaultValue={5} min={1} max={20} pattern='[0-9]' style={{width: '50px', marginBottom: 0}} onChange={e => setCounterInputValue(e)}/>
              <V2Button htmlType='submit' color='purple' variant='solid' className={styles.generateIdeasButton} disabled={(!resultsCounter || resultsCounter < 1 || resultsCounter > 20)}>
              Generate AI Content
                {aiOutline.loading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon style={{marginLeft: 10}} icon={faArrowRight} />}
              </V2Button>
            </div>
          </div>

        </div>
      </Form>
    </>
  );
});

const Header = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;

const ImgContainer = styled.div`
  background: #F2F2F5;
  border-radius: 10px;
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 13px;
`;
const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%
`;
const TemplateName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 4px;
`;
const TemplateDesc = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;
const BackArrowContainer = styled.div`
  width: 30px;
  cursor: pointer;
`;
const CommonInputStyled = styled(CommonInput)`
  color: #121212 !important;
`;
const AddMoreTermsButton = styled.div`
  color:  #2D6CCA;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
`;
