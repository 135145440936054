import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styles from '@/components/dashboard/home/content/index.module.scss';
import styled from 'styled-components';
import {Col, Dropdown, Input, Menu, Row, Skeleton} from 'antd';
import {Search} from 'react-feather';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisVertical} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
import FreezeWrapper from '../../../otto-page-detail/Component/freezTooltip';
import {useRouter} from 'next/router';
import {ottoWhitelabelName} from '@/utils/functions';

interface ListingSectionProps {
  isInOTTO?: boolean;
}

export const ListingSection: React.FC<ListingSectionProps> = observer(({isInOTTO}) => {
  const [search, setSearch] = useState('');
  const [showDropDown, setShowDropDown] = useState(false);
  const {campaigns: {getExistingTemplates, existingTemplates, setUpdateModalValues, setCreateTempModalVisible, deleteTemplate, existingTemplatesLoading},
    settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');
  const router = useRouter();

  useEffect(() => {
    getExistingTemplates();
  }, []);

  const handleSearch = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    getExistingTemplates(value || '');
  };

  const templateDetails = item => {
    setUpdateModalValues({...item, isUpdate: true});
    setCreateTempModalVisible(true);
  };

  const cardSkeleton = (
    <SkeletonWrapper>
      <CardContentWrapperSkeleton>
        <CardContentSkeleton>
          <Skeleton active paragraph={{rows: 2}} />
        </CardContentSkeleton>
      </CardContentWrapperSkeleton>
      <CardFooterSkeleton></CardFooterSkeleton>
    </SkeletonWrapper>
  );

  return (
    <ContentWrapper className={`${styles.contentContainer} ${isInOTTO ? 'otto': ''}`} style={{background: 'transparent'}}>
      {
        !isInOTTO ? <StyledInput
          prefix={<Search style={{width: '100%'}} color={search ? '#B98CE4' : '#A3A4A4'} height={15} width={15}/>}
          placeholder={'Search template'}
          value={search}
          onChange={handleSearch}
        /> : <></>
      }
      <Row style={{marginBottom: '20px'}}>
        {existingTemplatesLoading ? [1, 2, 3, 4, 5, 6].map(() => cardSkeleton) :
          existingTemplates?.results?.map((item, idx) => <Col
            span={8}
            key={idx}
            style={{paddingRight: idx % 3 === 0 || idx % 3 === 1 ? '35px' : '0'}}>
            <CardWrapper>
              <CardContentWrapper onClick={() => templateDetails(item)}>
                <CardContent>
                  <Subject>{item?.subject}</Subject>
                  <Body dangerouslySetInnerHTML={{__html: item?.body}} />
                </CardContent>
              </CardContentWrapper>
              <CardFooter>
                <CardTitle>
                  {item?.name.replace('OTTO', ottoWhitelabelName(whitelabelOtto))}
                </CardTitle>
                <FreezeWrapper placement='topRight' removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
                  <Dropdown
                    trigger={['click']}
                    overlayClassName='overlayPass'
                    overlay={
                      <Menu>
                        <Menu.Item style={{display: showDropDown ? 'block' : 'none'}} key={'delete'}>
                          <span style={{color: 'red'}} onClick={async () => {
                            deleteTemplate(item.id);
                          }}>Delete</span>
                        </Menu.Item>
                      </Menu>
                    }
                    placement='bottomRight'>
                    <FontAwesomeIcon onClick={() => setShowDropDown(true)} icon={faEllipsisVertical} color='#A3A4A4' cursor='pointer' />
                  </Dropdown>
                </FreezeWrapper>
              </CardFooter>
            </CardWrapper>
          </Col>)
        }
      </Row>
    </ContentWrapper>
  );
});

const ContentWrapper = styled.div`
  margin-top: -25px !important;
  padding: 0 80px !important;
  &.otto {
    padding: 0 !important;
  }
`;

const StyledInput = styled(Input)`
  height: 50px;
  border-radius: 12px;
  border: 1px solid #DDD;
`;

const CardWrapper = styled.div`
  margin-top: 20px;
`;

const CardContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 174px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #DDDDDD;
  background-color: #F9F9FB;
  border-bottom: none;
  cursor: pointer;
`;

const CardContent = styled.div`
  width: 85%;
  height: 150px;
  background-color: white;
  margin-top: 22px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #DDDDDD;
  border-bottom: none;
  padding: 18px 12px 5px 18px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 25px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  height: 54px;
  background-color: white;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #DDD;
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.07);
`;

const CardTitle = styled.div`
  width: 280px;
  font-size: 15px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Subject = styled.div`
  color: #121212;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Body = styled.div`
  color: #4E5156;
  font-size: 10px;
  font-weight: 400;
  white-space: pre-line;
  overflow-wrap: anywhere;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SkeletonWrapper = styled.div`
  margin-top: 20px;
  margin-right: 35px;
`;

const CardContentWrapperSkeleton = styled.div`
  display: flex;
  justify-content: center;
  width: 270px;
  height: 174px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #DDDDDD;
  background-color: white;
  border-bottom: none;
  cursor: pointer;
`;

const CardContentSkeleton = styled.div`
  width: 85%;
  height: 150px;
  background-color: white;
  margin-top: 22px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #DDDDDD;
  border-bottom: none;
  padding: 18px 12px 5px 18px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const CardFooterSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 25px;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  height: 54px;
  background-color: #ececec;
  border-radius: 0px 0px 12px 12px;
  border: 1px solid #DDD;
  box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.07);
`;
