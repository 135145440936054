/* eslint-disable max-len */
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotate} from '@fortawesome/pro-regular-svg-icons';
import {Tooltip} from 'antd';
import {getSingleUrlParam} from '@/utils/url';

export const Summary = observer(()=> {
  const getPublicHash = getSingleUrlParam('public_hash');
  const {reportBuilder: {details: {
    isConfig,
    singleProjectData,
    loadingAiSummary,
    loadingAiSummaryOverview,
    summaryOverview,
    loadAISummaries,

  }}} = useStore('');

  return <WrapperMain style={{height: '100%', width: '100%', marginTop: isConfig ? '' : '-20px'}}>
    {loadingAiSummary ? <LoadingOutlined style={{color: 'white', fontSize: '45px', marginLeft: '1%', marginTop: '1%'}} spin/> : <Wrapper isConfig={isConfig} loadingAiSummary={loadingAiSummary || loadingAiSummaryOverview}>
      <><img src='/img/summaryStar.svg' alt='' />
        <div style={{marginLeft: '20px', marginTop: '-8px', width: '-webkit-fill-available'}} id='ottoSummary'>
          <h1>AI Summary Overview {!isConfig && !getPublicHash && <Tooltip overlayStyle={{width: '210px'}} title={`AI Summary feels outdated? \n Click to generate again!`}><FontAwesomeIcon onClick={()=> loadAISummaries(singleProjectData?.id)} spin={loadingAiSummary || loadingAiSummaryOverview} className='iconSummary' color='white' icon={faRotate} /></Tooltip>}</h1>
          {summaryOverview || singleProjectData?.aiSummary['reportSummary']?.length ? <div dangerouslySetInnerHTML={{__html: summaryOverview || singleProjectData?.aiSummary['reportSummary']}}></div> : <i>No AI summary is available for this project.</i>}
        </div>
      </>
    </Wrapper>}
  </WrapperMain>;
});

const Wrapper = styled.div<{isConfig?: boolean; loadingAiSummary?: any}>`
    position: absolute;
    color: white;
    width: -webkit-fill-available;
    top: 0;
    display: flex;
    padding: 20px;
    align-items: flex-start;
    .iconSummary{
    margin-right: 3px;
    height: 17px;
    position: absolute;
    right: 0px;
    cursor: pointer;
}
    margin-right: 13px;
    margin-top: -19px;
    height: 17px;
    position: absolute;
    right: 0px;
    cursor: pointer;
    }
    div{
    h1{margin: 0; color: white; font-size: 24px;};
    p{margin: 0;
    span{font-weight: 700;
    text-decoration: underline;}
    };
    }
    h1{
    display: flex;
    justify-content: space-between;
    }
`;

const WrapperMain = styled.div`
background-image: url(/img/summaryotto.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px
`;

