import {opaqueColor} from '@/utils/colors';
import {faBolt} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {greenCheckMarkFilled} from '@/utils/icons';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {faCircleXmark} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  contentLength: 'short' | 'medium' | 'long';
  loading: boolean;
  variant?: 'top' | 'center';
  useBeStatuses?: boolean;
  beStatus?: string;
  elapsedTime?:number;
}

export const EmptyStateComponentV2 = observer(({
  loading,
  useBeStatuses,
  beStatus, elapsedTime}: Props) => {
  const {contentOptimizer: {aiOutline: {
    creatingAiArticleSuccessStatus,
    creatingAiArticleFailureStatus,
    shouldOverwriteContentSuccessMessage,
    setIsOpenAiWriterDrawer,
    setCreatingAiArticleSuccessStatus,
    setCreatingAiArticleFailureStatus,
    setShouldOverwriteContent,
    setShouldOverwriteContentSuccessMessage,
    setAiWriter,
    loadingStatus,
    aiWriterController,
    clearOneClickOutlines,
    setAiArticleAlreadyReplaced,
  }}} = useStore('');

  const titleDef = [
    {
      start: 0,
      end: 10,
      title: 'Generating H1',
    },
    {
      start: 11,
      end: 20,
      title: 'Generating H2s',
    },
    {
      start: 21,
      end: 40,
      title: 'Generating intro',
    },
    {
      start: 41,
      end: 80,
      title: 'Generating content sections',
    },
    {
      start: 81,
      end: 95,
      title: 'Generating conclusion',
    },
  ];

  const getDefTitle = (elapsedTime: number): string => {
    let labelToPrint = '';
    const foundElem = titleDef.find(item => item.start <= elapsedTime && item.end >= elapsedTime);
    if (foundElem) {
      labelToPrint = foundElem.title;
    } else {
      labelToPrint = 'Analyzing and improving content. Can take up to 3 minutes.';
    }

    return labelToPrint;
  };


  // useEffect(() => {
  //   let totalTime = 100 * 1000;

  //   switch (contentLength) {
  //     case 'short':
  //       totalTime = 100 * 1000;
  //       break;
  //     case 'medium':
  //       totalTime = 180 * 1000;
  //       break;
  //     case 'long':
  //       totalTime = 300 * 1000;
  //       break;
  //     default:
  //       break;
  //   }

  //   if (!loading) return;

  //   const intervalId = setInterval(() => {
  //     if (elapsedTime < totalTime) {
  //       setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId); // Clean up the interval on component unmount
  //   };
  // }, [elapsedTime, loading]);

  // useEffect(() => {
  //   if (creatingAiArticleSuccessStatus) {
  //     const timer = setTimeout(() => {
  //       setCreatingAiArticleSuccessStatus(false);
  //     }, 10000);

  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }
  // }, [creatingAiArticleSuccessStatus, setCreatingAiArticleSuccessStatus]);

  const handleCloseDrawerClick = () => {
    setAiWriter({
      ...toJS(aiWriterController ?? []),
      showForm: false,
      templateIcon: '',
      templateDesc: '',
      templateName: '',
      isStandaloneTool: false,
    });
    clearOneClickOutlines();
    setIsOpenAiWriterDrawer(false);
    setAiArticleAlreadyReplaced(false);
    setCreatingAiArticleSuccessStatus(false);
    setCreatingAiArticleFailureStatus(false);
    setShouldOverwriteContent(true);
    setShouldOverwriteContentSuccessMessage(true);
  };
  return <>{!loading && creatingAiArticleSuccessStatus && !shouldOverwriteContentSuccessMessage && <SuccessWrapper isTop={true}>
    {greenCheckMarkFilled()}
    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
      AI Content was successfully generated.
      <div style={{fontSize: 12, color: '#4E5156'}}>Review the content and add it to article.</div>
    </div>
  </SuccessWrapper>}
  {!loading && creatingAiArticleSuccessStatus && shouldOverwriteContentSuccessMessage && <SuccessWrapper isTop={true}>
    {greenCheckMarkFilled()}
    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
      AI Content was successfully generated.
      <div style={{fontSize: 12, color: '#4E5156', display: 'flex'}}>Generated content was added to your article.<div style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => handleCloseDrawerClick()}>&nbsp;Close drawer</div></div>
    </div>
  </SuccessWrapper>}
  {!loading && creatingAiArticleFailureStatus && <SuccessWrapper style={{backgroundColor: 'rgba(255, 77, 79, 0.2)'}} isTop={true}>
    <FontAwesomeIcon icon={faCircleXmark} color={'#eb5555'} fontSize={30} style={{marginRight: 10}}/>
    <div style={{display: 'flex', marginLeft: '10px'}}>
      Content generation failed. <div style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => handleCloseDrawerClick()}>&nbsp;Close drawer</div>
    </div>
  </SuccessWrapper>}
  {loading && <WrapperWithLoader isTop={true} currentProgress={elapsedTime / 1000}>
    <FontAwesomeIcon icon={faBolt} color={'#7F4EAD'} fontSize={30} style={{marginRight: 10}}/>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {loadingStatus? loadingStatus : 'Generating content'}
      <div style={{fontSize: 12, color: '#4E5156'}}>{!useBeStatuses ? (beStatus ?? 'The existing AI Generated content below will be replaced.') : getDefTitle(elapsedTime / 1000)}</div>
    </div>
  </WrapperWithLoader>}</>;
});

const WrapperWithLoader = styled.div<{currentProgress?: number; isTop?: boolean}>`
  width: ${p => p.isTop ? 'calc(100% + 40px)' : `320px`};
  height: 72px;
  border-radius: ${p => p.isTop ? '16px 16px 0 0' : `16px`};
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: ${p => p.isTop ? '30px' : `20px`};
  padding-right: 20px;
  margin: ${p => p.isTop ? '-20px -20px 0 -20px' : `60% auto`};
  position: relative;

  position: fixed;
  margin: ${p => p.isTop ? '-20px 0px 0px -15px' : `60% auto`};
  border-radius: ${p => p.isTop ? '10px 10px 0 0' : `16px`};
  width: 575px;
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: ${p => p.currentProgress < 95 ? `${p.currentProgress}%` : '95%'};
    background-color: ${opaqueColor('#7F4EAD', 15)};
  }
`;

const SuccessWrapper = styled.div<{currentProgress?: number; isTop?: boolean}>`
  width: ${p => p.isTop ? 'calc(100% + 30px)' : `320px`};
  height: 72px;
  border-radius: ${p => p.isTop ? '16px 16px 0 0' : `16px`};
  border: 1px solid #e8e8e8;
  background: #fff;
  /* box-shadow: 0px 3px 2px 0px rgba(42, 193, 85, 0.2); */
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: ${p => p.isTop ? '30px' : `20px`};
  padding-right: 20px;
  margin: ${p => p.isTop ? '-20px -20px 0 -20px' : `60% auto`};
  position: relative;
  position: fixed;
  margin: ${p => p.isTop ? '-20px -20px 0px -15px' : `60% auto`};
  border-radius: ${p => p.isTop ? '10px 10px 0 0' : `16px`};
  width: 575px;
  z-index: 1;
  background-color: rgba(42, 193, 85, 0.15);
`;
