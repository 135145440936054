import {useState} from 'react';
import {routes} from '@/utils/const';
import {Menu, Modal, Divider, Checkbox} from 'antd';
import router from 'next/router';
import ButtonComponent from '../button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileExport} from '@fortawesome/pro-regular-svg-icons';
import styles from './styles.module.scss';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';

export const SecondaryNavbar = observer(() => {
  const {siteAuditor: {export: {reportsToExport, toggleReport, setIsExporting}}} = useStore('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <div className={styles.navBar}>
      <Menu mode='inline' style={{maxWidth: 260, width: 260, color: ' #A3A4A4', backgroundColor: '#24262A', marginRight: '0', height: '100vh', position: 'inherit', overflowX: 'hidden', overflowY: 'hidden', zIndex: 2}}>
        <div style={{textAlign: 'center'}}><ButtonComponentStyled onClick={() => setIsModalVisible(true)} style='transparent'><FontAwesomeIcon style={{marginRight: 5}} icon={faFileExport}/>Bulk Export</ButtonComponentStyled></div>
        <DividerStyled></DividerStyled>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/internal-pages-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.internalPagesReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/internal-pages'>Internal Pages</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/indexability-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.indexabilityReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/indexability'>Indexability</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/links-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.linksReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/links-report'>Links</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/redirects-broken-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.redirectsBrokenReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/redirects-broken'>Redirects/Broken</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/xml-sitemaps-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.xmlSitemapsReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/xml-sitemaps'>XML Sitemaps</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/content-duplicate-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.contentDuplicatesReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/content-duplicate-report'
        >Content/Duplicates
        </Menu.Item>

        <Menu.Item className={location.pathname == '/site-audit-old/reports/page-speed-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.pageSpeedReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/page-speed-report'>Page Speed</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/social-tags-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.socialTagsReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/social-tags'>Social Tags</Menu.Item>
        <Menu.Item className={location.pathname == '/site-audit-old/reports/localization-report' ? styles.activeItem : styles.navBarItem}
          onClick={()=> {
            router.push(`/${routes.localizationReport}`, undefined, {shallow: true});
          }}
          key='/site-audit-old/reports/localization-report'>Localization</Menu.Item>
      </Menu>
      <ModalStyled
        width={410}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          setIsExporting(true);
          setIsModalVisible(false);
        }}
        okText={`Export ${reportsToExport?.filter(x => x === true)?.length} to PNG`}
        title='Bulk Export Reports'
      >
        <div style={{display: 'flex', flexDirection: 'column', height: 160, flexWrap: 'wrap'}}>
          <CheckboxStyled onChange={() => toggleReport(0)} checked={reportsToExport[0]}>Internal Pages</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(1)} checked={reportsToExport[1]}>Indexability</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(2)} checked={reportsToExport[2]}>Links</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(3)} checked={reportsToExport[3]}>Redirects/Broken</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(4)} checked={reportsToExport[4]}>XML Sitemaps</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(5)} checked={reportsToExport[5]}>Content/Duplicates</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(6)} checked={reportsToExport[6]}>Page Speed</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(7)} checked={reportsToExport[7]}>Social Tags</CheckboxStyled>
          <CheckboxStyled onChange={() => toggleReport(8)} checked={reportsToExport[8]}>Localization</CheckboxStyled>
        </div>
      </ModalStyled>
    </div>
  );
});

const DividerStyled = styled(Divider)`
  background-color: #A3A4A4;
  margin: 24px auto;
  min-width: unset;
  width: calc(70% + 1px) !important;
`;

const ButtonComponentStyled = styled(ButtonComponent)`
  margin-top: 10px;
  background-color: transparent;
  width: 260px;
  border-color: #A3A4A4;
  border-radius: 4px;
  margin-bottom: 0 !important;
  margin-left: 18px !important;
  margin-right: 15px;
  max-width: calc(70% + 1px) !important;
  padding-left: 24px !important;
  width: calc(70% + 1px) !important;
`;

const ModalStyled = styled(Modal)`
  border-radius: 5px;

  .ant-btn {
    border-radius: 5px;
    height: 38px;
  }

  .ant-btn-primary {
    background: #7F4EAD;
    border-color: #7F4EAD;
  }
`;

const CheckboxStyled = styled(Checkbox)`
  margin: 5px 0 !important;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7f4ead !important;
    border-color: #7f4ead !important;
  }
`;

