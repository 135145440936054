import React, {PropsWithChildren, useEffect, useState, useRef} from 'react';
import withAuthentication from '@/middleware/authentication';
import {Layout as AntdLayout} from 'antd';
import {observer} from 'mobx-react-lite';
import Header from '@/components/layoutV2/header';
import {useRouter} from 'next/router';
import {RootStoreType, useStore} from '@/store/root-store';
import styles from './style.module.scss';
import {publicPages} from '@/utils/const';
import CheckoutHeader from '@/components/layoutV2/header/checkoutHeader';
import {getTokenFromCookies} from '@/api/common-utils';
import {CommonSidebar} from './commonSidebar';
import {NoSsr} from '../common-components';
import {MainLoader} from '../common-components/v2/MainLoader';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {SitePropertiesDrawer} from '../dashboard/gsc/gsc/sitePropertiesDrawer';
import PublicHeader from './publicHeader';
import {PublicSidebar} from './publicSidebar';
import {isSidebarRemoveOnPublicPages, isCaPublicPage, isNavbarRemoveOnPublicPages} from '@/utils/url';
import moment from 'moment/moment';
import LoginModal from '../common-components/components/authentication-modal';

export const languages = (locale:string) =>{
  const languageObj = {
    'en': ' English(US)',
    'es': 'Español',
  };

  return languageObj[locale];
};

type PublicLayoutProps = {
  children: React.ReactNode;
  store: RootStoreType;
};

const PublicLayout: React.FC<PublicLayoutProps> = observer(({children, store}) => {
  const {width} = useWindowSize();
  const router = useRouter();
  const path = router.pathname;
  const {Embed} = router.query;
  const contentRef = useRef(null);
  const orderForm = ['/order-builder', '/billing', '/pricing', '/orders'].includes(router.pathname);
  const [isHiddenBar, setIsHiddenBar] = useState(false);
  const [sideBarItems, setSideBarItems] = useState([]);
  useEffect(() => {
    if (Embed && Embed == 'True') {
      setIsHiddenBar(true);
    }
  }, [router.query]);

  useEffect(() => {
    contentRef?.current?.scrollTo(0, 0);
  }, [path]);

  return router?.isReady ? <div style={{overflowX: 'hidden', minHeight: '100vh'}}>
    {orderForm ? <CheckoutHeader store={store} isOrderBuilder={true} />: ((path?.includes('public') && width > 768 ) ? <PublicHeader store={store} handleSideBarItems={items => setSideBarItems(isSidebarRemoveOnPublicPages() ? items : items.filter(d => d.title !== 'Projects List'))}/> : '')}

    <div style={{display: 'flex'}}>
      {isCaPublicPage() || isSidebarRemoveOnPublicPages() ? '' :
        <div style={{height: 'auto', background: '#24262A', borderRight: path == '/home' ? '1px solid #4E515680' : '0'}}>
          {typeof window !== 'undefined' && !isHiddenBar && path?.includes('public') && !orderForm && width > 768 && <PublicSidebar sideBarItems={sideBarItems} publicPage={true}/> }
        </div>}
      <div style={{flex: 1, transition: 'all 0.2s ease', maxWidth: orderForm || !path?.includes('public') || width <= 768 || isCaPublicPage() || isSidebarRemoveOnPublicPages() ? '100%' : store.navBar.isDockedSider ? 'calc(100% - 230px)' : 'calc(100% - 65px)'}} ref={contentRef}>{children}</div>
      <LoginModal />
    </div>
  </div> : <MainLoader />;
});

type PrivateLayoutProps = PropsWithChildren<{
  store: RootStoreType;
}>;

const PrivateLayout: React.FC<PrivateLayoutProps> = withAuthentication(
  observer<PrivateLayoutProps>(({store, children}) => {
    const {settings: {customer: {loading, profile: {hasOnboardingCompleted, hasCompletedRegistration, isSubscriber, isVerified, planName, paymentFailed, trialExpiresAt}}}} = store;
    const router = useRouter();
    const path = router.pathname;
    const asPath = router.asPath;
    const checkoutPages = ['/order-checkout', '/checkout', '/order-builder'];
    const [isHiddenBar, setIsHiddenBar] = useState(false);
    const [sideBarItems, setSideBarItems] = useState([]);
    const [isCheckoutPage, setIsCheckoutPage] = useState(checkoutPages.includes(path));
    const isOnBoarding = (path === '/onboarding');
    const contentRef = useRef(null);
    const {Embed} = router.query;

    useEffect(()=>{
      if (!loading && !hasCompletedRegistration && asPath !== '/login' && asPath !== '/register' ) {
        const token = getTokenFromCookies();
        if (token && !isSubscriber && location.hostname !== 'localhost' && !trialExpiresAt) {
          router.push('/onboarding');
        }
      }
    }, [hasCompletedRegistration, isVerified, path, asPath]);

    useEffect(() => {
      if (Embed && Embed == 'True') {
        setIsHiddenBar(true);
      }
      setIsCheckoutPage(checkoutPages.includes(path));
    }, [router.query]);

    const checkUserPlan = () => {
      const trialExpiresAtDateCheck = !moment(trialExpiresAt)?.isValid();
      return !isNavbarRemoveOnPublicPages() && ((paymentFailed || planName?.toLowerCase().trim() == 'free' || (trialExpiresAtDateCheck && !isSubscriber)) && !router.asPath?.match( /order|pricing|billing|checkout/g ));
    };

    useEffect(() => {
      if (checkUserPlan() && hasOnboardingCompleted == true) {
        router.push('/billing');
      }
      contentRef?.current?.scrollTo(0, 0);
    }, [path, planName, paymentFailed, trialExpiresAt]);

    useEffect(() => {
      if (hasOnboardingCompleted == false && router?.pathname != '/onboarding') {
        router.push('/onboarding');
      }
    }, [hasOnboardingCompleted, router.query]);

    return (
      <>
        {isOnBoarding ? (
          <AntdLayout className={styles.layout}>{children}</AntdLayout>
        ) : (
          <div style={{overflow: 'hidden', minHeight: '100vh'}}>
            {!isHiddenBar && (isCheckoutPage ? <CheckoutHeader store={store} />: <Header store={store} handleSideBarItems={items => setSideBarItems(items)}/> ) }
            {checkUserPlan() ? <MainLoader /> :
              <div style={{display: 'flex'}}>
                <div id='SIDEBAR_MAIN' style={{height: 'auto', background: '#24262A', borderRight: path == '/home' ? '1px solid #4E515680' : '0', position: 'relative'}}>
                  {typeof window !== 'undefined' && !isHiddenBar && !isCheckoutPage && <CommonSidebar sideBarItems={sideBarItems} publicPage={false}/> }
                </div>
                <div style={{flexGrow: '1', transition: 'all 0.2s ease', overflow: 'hidden'}} ref={contentRef}>
                  {/* {(store.settings.customer?.profile?.paymentFailed && !router?.asPath?.includes('content/seo-content-assistant')) ? <div style={{background: '#24262A', padding: '20px'}}>
                    <PaymentFailedBanner />
                  </div> : ''} */}
                  <div>{children}</div>
                </div>
              </div>}
          </div>
        )}
        <SitePropertiesDrawer />
      </>
    );
  }),
);

type LayoutProps = PropsWithChildren<{
  pathname: string;
  store: RootStoreType;
}>;

const Layout = observer(({pathname, store, children}: LayoutProps) => {
  const {settings: {customer: {loadProfile}}} = useStore('');
  let path = pathname;

  useEffect(() => {
    if (!store?.settings?.customer?.profile?.id && path == '/pricing') {
      loadProfile();
    }
  }, []);

  if (!pathname) {
    const router = useRouter();
    path = router.pathname;
  }

  const isToken = getTokenFromCookies();

  if ((store?.settings?.customer?.profile?.id || isToken) && path == '/pricing') {
    return <NoSsr><PrivateLayout store={store}>{children}</PrivateLayout></NoSsr>
    ;
  }

  return publicPages.includes(path) ? (
    <NoSsr>
      <PublicLayout store={store}>{children}</PublicLayout>
    </NoSsr>
  ) : (
    <NoSsr>
      <PrivateLayout store={store}>{children}</PrivateLayout>
    </NoSsr>
  );
});

export default Layout;
