import {Modal} from '@/components/common-components';
import {Button} from '@/components/common-components/v2';
import {keywordNewLineReg, urlValidationRegex} from '@/constants/regex';
import {useStore} from '@/store/root-store';
import {stringToArray} from '@/utils/arrays';
import {LoadingOutlined} from '@ant-design/icons';
import {faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import {faArrowRight, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown, faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Spin, Popover, Table, Space} from 'antd';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {StepOne} from './step-one';
import {StepTwo} from './step-two';
import {integrationApi} from '@/api/gsc';
import {notification} from '@/utils/notification-v2';
import styles from './style.module.scss';

interface Props {
  useCustomTrigger?: boolean;
  customTrigger?: boolean;
  customCallBack?: () => any;
  defaultData?: any;
  setShowAllKwMain?: (value) => void;
  fromHomePage?: boolean;
}

export const PostOnboardingModal = observer(({
  useCustomTrigger,
  customTrigger,
  customCallBack,
  defaultData,
  setShowAllKwMain,
  fromHomePage = false,
}: Props) => {
  const {
    settings: {
      customer: {
        profile: {
          getPostOnboardingSuggestions,
          postOnboarding,
          postOnboardingLoading,
          submitPostOnboardingData,
          showPostOnboardingModal,
          setShowPostOnboardingModal,
          setLoadHomePage,
          getStatusForHomePage,
        },
      },
    },
    competitorResearcherV2: {setRefreshList, domain, setDomain, isValidUrl, setIsValidUrl},
  } = useStore('');
  // const {
  //   competitorResearcherV2: {
  //     // saveCompetitor,
  //     loadCompetitorResearcherList,
  //     setParams,
  //     postNewCompetitor,
  //   },
  // } = useStore('');
  const {settings: {customer: {profile: {whitelabelOtto, quotaUtilization, getQuotaUtilization, address}}}} = useStore('');
  // const router = useRouter();
  // modal behaviour states
  const [curStep, setCurStep] = useState<1 | 2>(1);
  const [quotaUtilizationModal, setQuotaUtilizationModal] = useState<boolean>(false);

  //  step one states
  const [useAiSelected, setUseAiSelected] = useState<boolean>(true);

  // step two states
  const [rawKwInputValue, setRawKwInputValue] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>(defaultData?.countryCode ?? 'US');
  const [showAllKw, setShowAllKw] = useState<boolean>(false);
  const [stepTwoData, setStepTwoData] = useState(null);
  const [gscToggled, setGscToggled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  // main values states
  const [kwInputValue, setKwInputValue] = useState<string[]>([]);
  const [selectedCompetitors, setSelectedCompetitors] = useState<string[]>([]);

  const [quotaBtnColor, setQuotaButtonColor] = useState<boolean>(true);
  const [utilizedQuota, setUtilizedQuota] = useState<object>({});

  const antIcon = <LoadingOutlined style={{fontSize: 15, marginLeft: 10, color: '#ffff'}} spin />;

  const allowedBacklinkResearchesBL = quotaUtilization?.bl?.allowedBacklinkResearches?.consumed == quotaUtilization?.bl?.allowedBacklinkResearches?.total;
  const allowedSiteAuditorPagesCA = quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed == quotaUtilization?.ca?.allowedSiteAuditorPages?.total;
  const allowedSiteAuditorProjectsCA = quotaUtilization?.ca?.allowedSiteAuditorProjects?.consumed == quotaUtilization?.ca?.allowedSiteAuditorProjects?.total;
  const allowedFocusTermsCA = quotaUtilization?.ca?.allowedFocusTerms?.consumed == quotaUtilization?.ca?.allowedFocusTerms?.total;
  const allowedOnpageAuditsCA = quotaUtilization?.ca?.allowedOnpageAudits?.consumed == quotaUtilization?.ca?.allowedOnpageAudits?.total;
  const allowedCompetitorResearchesKE = quotaUtilization?.ke?.allowedCompetitorResearches?.consumed == quotaUtilization?.ke?.allowedCompetitorResearches?.total;
  const maxKeywordLookupsKE = quotaUtilization?.ke?.maxKeywordLookups?.consumed == quotaUtilization?.ke?.maxKeywordLookups?.total;
  const allowedContentPlansKE = quotaUtilization?.ke?.allowedContentPlans?.consumed == quotaUtilization?.ke?.allowedContentPlans?.total;

  const isQuotaCompleted = allowedBacklinkResearchesBL && allowedSiteAuditorPagesCA && allowedSiteAuditorProjectsCA && allowedFocusTermsCA && allowedOnpageAuditsCA && allowedCompetitorResearchesKE && maxKeywordLookupsKE && allowedContentPlansKE;
  const consumedQuotas = `${allowedBacklinkResearchesBL ? 'Allowed Backlink Researches quota is consumed,' : ''} ${allowedSiteAuditorPagesCA ? 'Allowed SiteAuditor Pages quota is consumed,' : ''} 
  ${allowedSiteAuditorProjectsCA ? 'Allowed Site Auditor Projects quota is consumed,' : ''} ${allowedFocusTermsCA ? 'Allowed Focus Terms quota is consumed,' : ''} 
  ${allowedOnpageAuditsCA ? 'Allowed Onpage Audits quota is consumed,' : ''} ${allowedCompetitorResearchesKE ? 'Allowed Competitor Researches quota is consumed,' : ''} 
  ${maxKeywordLookupsKE ? 'Max Keyword Lookups quota is consumed,' : ''} ${allowedContentPlansKE ? 'Allowed Content Plans quota is consumed,' : ''}`;

  useEffect(() => {
    if (address?.defaultLocation) {
      setSelectedCountry(address?.defaultLocation);
    }
  }, [address?.defaultLocation]);

  useEffect(() => {
    const keCaquota = {...getQuotaUtilization?.ca, ...getQuotaUtilization?.ke, ...getQuotaUtilization.gsc};
    const quotaCompareKeys = [
      'allowedSiteAuditorProjects', 'allowedSiteAuditorPages', 'allowedPages', 'allowedFocusTerms',
      'allowedOnpageAudits', 'allowedTrackedProjects', 'allowedTrackedKeywords',
      'allowedCompetitorResearchProjects', 'allowedOttoProjects', 'allowedContentPlans', 'allowedTrackedKeywords',
      'allowedSiteKeywords', 'allowedOttoV2Projects',
    ];
    const utilizedQuotaTemp = {
      total: null,
      consumed: null,
      textColor: null,
    };
    quotaCompareKeys.forEach(key => {
      const total = keCaquota[key]?.total || 0;
      // const total = 5394;
      const consumed = keCaquota[key]?.consumed || 0;
      let textColor = 'black';
      if (consumed >= total) {
        textColor = 'red';
      }
      utilizedQuotaTemp[key] = {total, consumed, textColor};
    });
    if (Object.entries(utilizedQuotaTemp).length) {
      setUtilizedQuota(utilizedQuotaTemp);
      const utilized = Object.values(utilizedQuotaTemp).find(item => item?.textColor === 'red');
      if (utilized) {
        setQuotaButtonColor(false);
      } else {
        setQuotaButtonColor(true);
      }
    }
  }, [getQuotaUtilization]);

  const continueHandler = async () => {
    if (isQuotaCompleted) {
      notification.error('Insufficient quota:', 'Backlink Researcher, Site Audit, Keyword Explorer');
    } else {
      if (consumedQuotas?.replaceAll(' ', '').trim().length > 1) {
        notification.error('Insufficient quota:', `${consumedQuotas?.replace(/,\s*$/, '')}.`);
      }
      try {
        await getPostOnboardingSuggestions(domain);
        if (useAiSelected) {
          setRawKwInputValue(postOnboarding?.keywords?.slice(0, 3).join('\n'));
          setKwInputValue(stringToArray(postOnboarding?.keywords?.slice(0, 3).join('\n'), keywordNewLineReg, ','));
          const formatSelectCompetitors = [];
          if (postOnboarding?.competitors[0]) {
            formatSelectCompetitors.push(postOnboarding?.competitors[0]);
          }
          if (postOnboarding?.competitors[1]) {
            formatSelectCompetitors.push(postOnboarding?.competitors[1]);
          }

          setSelectedCompetitors([...formatSelectCompetitors]);
        }
        if (!showAllKw) {
          const slicedKwArray = toJS(postOnboarding.keywords).slice(0, 7);
          const currentData = {
            ...toJS(postOnboarding),
            keywords: slicedKwArray,
          };
          setStepTwoData(currentData);
        }
        setCurStep(2);
      } catch (error) {
        setCurStep(2);
      }
    }
  };

  const inputChangeHandler = async (value: string) => {
    setDomain(value);
    // const resp = await profileApi.validatePostOnboardingUrl(value);
    if (value?.match(urlValidationRegex)) {
      setIsValidUrl(true);
    } else {
      setIsValidUrl(false);
    }
  };

  const keywordsChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRawKwInputValue(e.target.value);
    const newValue = e.target.value?.trim();
    setKwInputValue(stringToArray(newValue, keywordNewLineReg, ','));
  };

  const onKwClickHandler = (kw: string) => {
    if (kwInputValue?.filter(item => item === kw).length) {
      const filteredData = kwInputValue?.filter(keyword => keyword !== kw);
      const newKwArray = stringToArray(filteredData?.join('\n'), keywordNewLineReg, ',');
      setRawKwInputValue(filteredData?.join('\n'));
      setKwInputValue(newKwArray);
    } else {
      const newKwArray = kwInputValue;
      newKwArray.push(kw);
      setRawKwInputValue(newKwArray?.join('\n'));
      setKwInputValue(newKwArray);
    }
  };

  const suggestMoreKwHandler = (value: boolean) => {
    setShowAllKw(value);
    setShowAllKwMain && setShowAllKwMain(value);
    let currentData;
    if (!value) {
      const slicedKwArray = toJS(postOnboarding.keywords).slice(0, 7);
      currentData = {
        ...toJS(postOnboarding),
        keywords: slicedKwArray,
      };
    } else {
      currentData = toJS(postOnboarding);
    }
    setStepTwoData(currentData);
  };

  const competitorClickHandler = (competitor: string) => {
    const curSelectedComp = selectedCompetitors;
    let formatArr;
    if (curSelectedComp.filter(item => item === competitor).length) {
      formatArr = curSelectedComp.filter(item => item !== competitor);
      setSelectedCompetitors(formatArr);
    } else {
      curSelectedComp.push(competitor);
      formatArr = curSelectedComp;
    }

    setSelectedCompetitors([...formatArr]);
  };

  const addCompetitorHandler = (competitor: string) => {
    if (stepTwoData.competitors?.includes(competitor)) {
      const filteredCompetitors = selectedCompetitors.filter(com => com === competitor);
      if (!filteredCompetitors.length) {
        setSelectedCompetitors([...selectedCompetitors, competitor]);
      }
      return;
    }
    const competitorsList = stepTwoData.competitors;
    const curSelectedComp = selectedCompetitors;
    competitorsList.push(competitor);
    curSelectedComp.push(competitor);
    const formatArr = competitorsList;

    const currentData = {
      ...toJS(stepTwoData),
      competitors: formatArr,
    };

    setStepTwoData(currentData);

    setSelectedCompetitors(curSelectedComp);
  };

  const gscToggleHandler = (checked: boolean) => {
    setGscToggled(checked);
  };

  const submitData = async () => {
    // setLoadStatusLoading(true);
    setIsLoading(true);
    // const payload = {url: domain, mode: 'domain', country_code: selectedCountry};
    // await postNewCompetitor(payload);

    // await COMPETITOR_RESEARCHER_API.getCompetitorResearchlist({page: 1, page_size: 10, ordering: '', search: domain});
    // const filteredHistoryData = response?.results?.filter(item => item?.metrics?.url == domain && item?.metrics?.countryCode == selectedCountry?.toLowerCase());
    // if (Array.isArray(filteredHistoryData) && filteredHistoryData?.length == 1 && filteredHistoryData[0]?.isSaved == false) {
    //   await saveCompetitor(filteredHistoryData[0]?.id);
    // }
    // setParams({page: 1, page_size: 5, ordering: '', search: ''}, true);
    // await loadCompetitorResearcherList(true);
    await submitPostOnboardingData(domain, kwInputValue, selectedCompetitors, selectedCountry);
    if (gscToggled) {
      window.open(integrationApi.getGoogleConnectUrl());
    }
    setSelectedCompetitors([]);
    setSelectedCountry(address?.defaultLocation ?? 'US');
    setDomain('');
    if (typeof customCallBack === 'function') {
      customCallBack();
    } else {
      await getStatusForHomePage(true);
      setLoadHomePage(false);
    }
    if (!postOnboardingLoading) {
      setCurStep(1);
    }
    setIsLoading(false);
    setShowPostOnboardingModal(false);
  };

  const stepsMap = {
    1: <StepOne selectAi={setUseAiSelected}
      aiSelected={useAiSelected}
      domain={domain}
      setDomain={inputChangeHandler}
      selectedCountry={selectedCountry}
      setSelectedCountry={setSelectedCountry}
      isValidUrl={isValidUrl} />,
    2: <StepTwo domain={domain}
      selectedLocation={selectedCountry}
      selectedCountry={selectedCountry}
      setSelectedCountry={setSelectedCountry}
      onEditUrlClick={() => {
        setCurStep(1);
        setShowAllKw(false);
        setShowAllKwMain && setShowAllKwMain(false);
        // if (fromHomePage) {
        //   setShowPostOnboardingModal(false);
        // }
      }}
      onKeywordUpdate={keywordsChangeHandler}
      data={stepTwoData}
      fullData={toJS(postOnboarding)}
      kwInputValue={kwInputValue}
      rawKwInputValue={rawKwInputValue}
      onSuggestmoreKw={suggestMoreKwHandler}
      showAllKw={showAllKw}
      onKwClick={onKwClickHandler}
      selectedCompetitors={selectedCompetitors}
      onCompetitorClick={competitorClickHandler}
      addCompetitorHandler={addCompetitorHandler}
      onGscToggle={gscToggleHandler} />,
  };

  const btnSetup = {
    1: {
      label: 'Continue',
      icon: postOnboardingLoading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 20, height: 14}} />,
      callback: continueHandler,
      disabled: postOnboardingLoading || !isValidUrl || !domain,
      isLargeBtn: false,
    },
    2: {
      label: 'Create Project',
      icon: postOnboardingLoading || isLoading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 20, height: 14}} />,
      callback: () => submitData(),
      disabled: postOnboardingLoading || isLoading,
      isLargeBtn: true,
    },
  };

  useEffect(() => {
    if (showPostOnboardingModal && fromHomePage) {
      setDomain(defaultData?.domain);
      setRawKwInputValue(defaultData?.rawKwInputValue);
      setKwInputValue(defaultData?.kwInputValue);
      setSelectedCompetitors(defaultData?.selectedCompetitors);
      setStepTwoData(defaultData?.stepTwoData);
      setCurStep(defaultData?.curStep);
      setSelectedCountry(defaultData?.countryCode);
      setShowAllKw(false);
    }
  }, [showPostOnboardingModal]);

  const onCloseModal = () => {
    setSelectedCompetitors([]);
    setCurStep(1);
    setDomain('');
    setRefreshList(false);
    setShowAllKw(false);
    if (typeof customCallBack === 'function') {
      customCallBack();
    } else {
      setShowPostOnboardingModal(false);
      localStorage.setItem('showPostOnboarding', 'false');
    }
  };

  const quotaUtilizationContent = utilizedQuotaProp => {
    const quotaRow = () => {
      return ({data, title}) => {
        return (
          <span style={{color: data?.textColor, width: '50%'}}>
            {title}: {`${data?.consumed}/${data?.total}`}
          </span>
        );
      };
    };

    const WrappedDynamicColoredDiv = quotaRow();

    const columns = [
      {
        title: <QuotaUtilizationHeader>TOOL</QuotaUtilizationHeader>,
        dataIndex: 'tool',
        key: 'tool',
      },
      {
        title: <QuotaUtilizationHeader>QUOTA UTILIZATION</QuotaUtilizationHeader>,
        dataIndex: 'quotaUtilization',
        key: 'quota utilization',
      },
    ];

    const dataSource = [
      {
        key: 1,
        tool: <QuotaUtilizationColumn>Site Audit</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Sites'} data={utilizedQuotaProp?.allowedSiteAuditorProjects} />
              <WrappedDynamicColoredDiv title={'Pages'} data={utilizedQuotaProp?.allowedSiteAuditorPages} />
            </QuotaUtilizationRow>,
      },
      {
        key: 2,
        tool: <QuotaUtilizationColumn>Content Genius</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Articles'} data={utilizedQuotaProp?.allowedPages} />
              <WrappedDynamicColoredDiv title={'Keywords'} data={utilizedQuotaProp?.allowedFocusTerms} />
            </QuotaUtilizationRow>,
      },
      {
        key: 3,
        tool: <QuotaUtilizationColumn>Onpage Audit</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Pages'} data={utilizedQuotaProp?.allowedOnpageAudits} />
            </QuotaUtilizationRow>,
      },
      {
        key: 4,
        tool: <QuotaUtilizationColumn>Keyword Rank Tracker</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Sites'} data={utilizedQuotaProp?.allowedTrackedProjects} />
              <WrappedDynamicColoredDiv title={'Keywords'} data={utilizedQuotaProp?.allowedTrackedKeywords} />
            </QuotaUtilizationRow>,
      },
      {
        key: 5,
        tool: <QuotaUtilizationColumn>Site Explorer</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Sites'} data={utilizedQuotaProp?.allowedCompetitorResearchProjects} />
            </QuotaUtilizationRow>,
      },
      {
        key: 6,
        tool: <QuotaUtilizationColumn>{whitelabelOtto} Version 2</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Sites'} data={utilizedQuotaProp?.allowedOttoV2Projects} />
            </QuotaUtilizationRow>,
      },
      {
        key: 7,
        tool: <QuotaUtilizationColumn>Content Planner</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Clusters'} data={utilizedQuotaProp?.allowedContentPlans} />
            </QuotaUtilizationRow>,
      },
      {
        key: 8,
        tool: <QuotaUtilizationColumn>Keyword Research</QuotaUtilizationColumn>,
        quotaUtilization:
            <QuotaUtilizationRow>
              <WrappedDynamicColoredDiv title={'Keywords'} data={utilizedQuotaProp?.allowedSiteKeywords} />
            </QuotaUtilizationRow>,
      },
    ];

    return (
      <>
        <QuotaUtilizationContent >
          <Space size={40} style={{display: 'flex', justifyContent: 'space-between', margin: '10px 5px'}}>
            <QuotaUtilizationContentTitle>
              Creating a new project will consume 1 quota point of each type mentioned in the table below
            </QuotaUtilizationContentTitle>
            <div style={{marginTop: -25}}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{cursor: 'pointer'}}
                onClick={() => setQuotaUtilizationModal(false)}
              />
            </div>
          </Space>
        </QuotaUtilizationContent>
        <Table
          className={styles.tableHeaderStyle}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          size='small'
        />
      </>
    );
  };

  return (
    <>
      <ModalStyled
        visible={useCustomTrigger ? customTrigger : showPostOnboardingModal}
        activeStep={curStep}
        onClose={onCloseModal}
        zIndex={1}
      >
        <ContentContainer>
          {stepsMap[curStep]}
        </ContentContainer>
        <Divider style={{margin: '20px 0'}} />
        <ModalFooter>
          {curStep == 2 ? (
            <>
              <Popover
                overlayClassName={styles.popoverOverlay}
                content={quotaUtilizationContent(utilizedQuota)}
                trigger='click'
                visible={quotaUtilizationModal}
                onVisibleChange={val => setQuotaUtilizationModal(val)}
              >
                <QuotaUtillizationButton
                  color={quotaBtnColor ? 'green' : 'red'}
                  textColor='black'
                  mode='light'
                  variant='ghost'
                  onClick={() => {
                    setQuotaUtilizationModal(!quotaUtilizationModal);
                  }}
                >
                  <FontAwesomeIcon
                    icon={quotaBtnColor ? faCircleCheck : faCircleExclamation}
                    style={{marginRight: 10, height: 14, color: quotaBtnColor ? 'green' : 'red'}}
                  />
                  {`Quota Utilization`}
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{marginLeft: 10, height: 14, color: 'gray'}}
                  />
                </QuotaUtillizationButton>
              </Popover>
            </>

          ) : <CancelButton variant='ghost' background='transparent' color='black' onClick={onCloseModal}>{`I'll do this later`}</CancelButton>}
          <ButtonsDiv>
            {curStep == 2 ? <CancelButton variant='ghost' background='transparent' color='black' onClick={onCloseModal}>{`Cancel`}</CancelButton> : ''}
            <ButtonStyled onClick={btnSetup[curStep].callback} disabled={btnSetup[curStep].disabled} isLarge={btnSetup[curStep].isLargeBtn}>{btnSetup[curStep].label}{btnSetup[curStep].icon}</ButtonStyled>
          </ButtonsDiv>
        </ModalFooter>
      </ModalStyled>
    </>
  );
});

const ModalStyled = styled(Modal) <{ activeStep: 1 | 2 }>`
   margin-top: auto;
   margin-bottom: auto;
   width: ${p => p.activeStep === 1 ? '480px' : '600px'};
   padding-bottom: 20px;
`;

const QuotaUtillizationButton = styled(Button)`
  border-radius: 30px;
`;
const ButtonsDiv = styled.div`
  display: flex;

`;
const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CancelButton = styled(Button)`
  margin-right: 8px;
  padding: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
`;

const ButtonStyled = styled(Button) <{ isLarge?: boolean }>`
  height: ${p => p.isLarge ? '44px' : 'auto'};
  margin: 0;
`;

const ContentContainer = styled.div``;

const QuotaUtilizationContent = styled.div`
  font-size: 16px;
  margin-right: 60px;
  margin-bottom: 16px;
  width: 500px;
`;

const QuotaUtilizationContentTitle = styled.div`
  width:500px;
  
`;

const QuotaUtilizationHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
  //border-radius: 6px;
  //background: #F7F7F8;
`;

const QuotaUtilizationColumn = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

const QuotaUtilizationRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
