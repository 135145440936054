import {observer} from 'mobx-react-lite';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationPin, faStar} from '@fortawesome/pro-solid-svg-icons';
import {faCircle, faCircleDot, faShuffle} from '@fortawesome/pro-duotone-svg-icons';
import {
  ButtonStyled,
  CardContainer,
  CardKeyword,
  CardSubTitle,
  CardTitle,
  ContentSectionWrapper,
  Description,
  DescriptionPoint,
  DescriptionWrapper,
  MapWrapper,
  ModalStyled,
  Numbering,
  Pill,
  ResultsWrapper,
  SpaceDiv,
  ValuesDiv,
  AnalysisHeading,
  ViewOnGoogleBtn} from './styledComponents';
import {useStore} from '@/store/root-store';
import {Divider} from 'antd';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {getPositionColor} from './map-tooltip';
import {openUrl} from '@/utils/router';
import GoogleMapReact from 'google-map-react';
import {ModalMapTooltip} from './modalMapTooltip';
import {useEffect, useState} from 'react';
import {ComparisonModal} from './comparison';
import {getSingleUrlParam} from '@/utils/url';

interface Props {
  showPinDetailModal: boolean;
  setShowPinDetailModal: (value: boolean) => void;
  resultsData: any;
  currentBusinessCid: string;
  isLoading: boolean;
  latitude: any;
  longitude: any;
  radiusMiles?: number;
  gridDimensions?: any;
  gridShape?: string;
  selectedDate?: any;
  setShowComparison?: (value:boolean) => void;
  showRBComparison?: any;
  newCenter?: any;
}

export const LocationAnalysisModal = observer(({
  latitude,
  longitude,
  showPinDetailModal,
  setShowPinDetailModal,
  resultsData,
  currentBusinessCid,
  isLoading,
  radiusMiles,
  gridDimensions,
  gridShape,
  selectedDate,
  showRBComparison=false,
  setShowComparison,
  newCenter,
}: Props) => {
  const [compare, setCompare] = useState(JSON.parse(getSingleUrlParam('showCompare')) || false);
  const [comparePublic] = useState(JSON.parse(getSingleUrlParam('showCompare')) || false);
  const {
    localSeoScan: {localSeoScanDetail: {loadingDetail}},
  } = useStore('');
  const [zoom, setZoom] = useState(13);
  const [dataCId, setDataCId] = useState('');
  const [map, setMap] = useState(null);

  const closeModal = () => {
    setShowPinDetailModal(false);
    setCompare(false);
    setShowComparison(false);
  };

  const defaultProps = {
    center: {
      lat: latitude || 40.73061,
      lng: longitude || -73.935242,
    },
    zoom: 13,
  };

  const matchBusinessCid = () => {
    const filteredData = resultsData?.serps?.filter(item => item?.dataCid == currentBusinessCid);
    return getPositionColor(filteredData ? filteredData[0]?.position : 0);
  };

  function calculateZoomLevel(map, bounds, width, height) {
    const ZOOM_OFFSET = 2; // Adjust as needed

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const lngDiff = Math.abs(ne.lng() - sw.lng());
    const latDiff = Math.abs(ne.lat() - sw.lat());

    // Ensure the denominator is positive and greater than zero
    const zoomWidth = lngDiff > 0 ? Math.log2((width - ZOOM_OFFSET) / lngDiff) : 0;
    const zoomHeight = latDiff > 0 ? Math.log2((height - ZOOM_OFFSET) / latDiff) : 0;

    return Math.min(zoomWidth, zoomHeight);
  }

  const handleTilesLoaded = () => {
    if (map && resultsData?.serps?.length) {
      // @ts-ignore
      const bounds = new window.google.maps.LatLngBounds();

      resultsData?.serps.forEach(marker => {
        const lat = marker?.gpsCoordinates?.latitude;
        const lng = marker?.gpsCoordinates?.longitude;

        if (!isNaN(lat) && !isNaN(lng)) {
          // @ts-ignore
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        }
      });

      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        map.setCenter(bounds.getCenter());
        map.setZoom(13);
      } else {
        const center = bounds.getCenter();
        const zoom = calculateZoomLevel(map, bounds, map.getDiv().offsetWidth, map.getDiv().offsetHeight);

        map.setCenter(center);
        map.setZoom(parseInt(`${(!zoom || isNaN(zoom)) ? 13 : zoom}`));
      }
    }
  };

  useEffect(() => {
    if (showPinDetailModal) {
      if (map) {
        // @ts-ignore
        window.google.maps.event.addListenerOnce(map, 'tilesloaded', handleTilesLoaded);
      }
    }
  }, [map, resultsData?.serps, longitude, showPinDetailModal]);

  return <ModalStyled
    width={1200}
    closable={!comparePublic && true}
    closeIcon={<FontAwesomeIcon icon={faXmark} />}
    mask={true}
    style={{margin: '10px 0 0 0'}}
    visible={showPinDetailModal}
    onClose={() => !comparePublic && closeModal()}
    zIndex={1000}
    compare={compare}
  >
    {compare ? <ComparisonModal newCenter={newCenter} showPinDetailModal={showPinDetailModal} currentBusinessCid={currentBusinessCid} selectedDate={selectedDate} name={resultsData?.keyword} dataCId={dataCId} compare={compare} setCompare={setCompare} /> : <div>
      <div style={{display: 'flex', gap: '65px'}}>
        <AnalysisHeading style={{fontSize: '24px', fontWeight: 600}}>Competitors&apos; positions for {`“${resultsData?.keyword}”`}</AnalysisHeading>
      </div>
      <DescriptionWrapper>
        <Description>{`This list of businesses reflect the results of a Google search conducted from the chosen location at coordinates`}&nbsp;
          <div style={{display: 'flex'}}>
            <div style={{color: '#2D6CCA'}}>
              <a
                style={{color: '#2D6CCA'}}
                href={`https://www.google.com/maps/search/?api=1&query=${resultsData?.location?.lat?.toFixed(4) || ''},${resultsData?.location?.lon?.toFixed(4) || ''}`}
                target='_blank'
                rel='noopener noreferrer'>
                {`${resultsData?.location?.lat?.toFixed(4)}, ${resultsData?.location?.lon?.toFixed(4)}`}
              </a>
            </div>
          &nbsp;(<FontAwesomeIcon icon={faCircleDot} fontSize={16} style={{marginTop: '3px'}} color='#2D6CCA' />&nbsp;blue dot).
          </div>
        </Description>
        <DescriptionPoint>The&nbsp;<FontAwesomeIcon icon={faLocationPin} fontSize={20} color='#4E5156' />&nbsp;gray pins represent the physical locations of these competitors. Each pin is numbered according to the respective Google search rankings.</DescriptionPoint>
        <DescriptionPoint>{currentBusinessCid && matchBusinessCid() == '#B62222' ?
          null :
          <>The&nbsp;<FontAwesomeIcon icon={faLocationPin} fontSize={20} color={currentBusinessCid ? matchBusinessCid() : '#F1AA3E'} />&nbsp;colored pin is your business and</>}
        {currentBusinessCid && matchBusinessCid() == '#B62222' ? 'The' : ' the'} gray circle is the selected&nbsp;<FontAwesomeIcon icon={faCircle} fontSize={22} color='#121212' />&nbsp;search area.</DescriptionPoint>
      </DescriptionWrapper>
      <ContentSectionWrapper>
        <ResultsWrapper id={'map_results_div'}>
          {loadingDetail || isLoading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(z => {
            return <CardContainer className='business-card' key={`loader_${z}`}>
              <Numbering selectedColor={getPositionColor(z)} selected={false}>{z}</Numbering>
              <Divider type='vertical' style={{height: '130%', margin: '0 10px'}}/>
              <SpaceDiv>
                <div>
                  <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='150px'/>
                  <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='250px'/>
                  <SkeletonHorizontalLoaderGray height={'30px'} skeletonHeight='10px' lightLoader width='100px'/>
                </div>
                <div>
                  <SkeletonHorizontalLoaderGray height={'20px'} skeletonHeight='10px' lightLoader width='50px'/>
                  <SkeletonHorizontalLoaderGray height={'20px'} margin='0 0 0 auto' skeletonHeight='10px' lightLoader width='30px'/>
                </div>
              </SpaceDiv>
            </CardContainer>;
          }) : resultsData?.serps?.map((item, idx) => {
            return <CardContainer className='business-card' key={`google_business_${idx}`} id={`map_card_position_${item?.position}`} selected={`${item?.dataCid}` == currentBusinessCid} selectedColor={getPositionColor(item?.position)}>
              <Numbering selectedColor={getPositionColor(item?.position)} selected={`${item?.dataCid}` == currentBusinessCid}>{item?.position}</Numbering>
              <Divider type='vertical' style={{height: '130%', margin: '0 10px'}}/>
              <div className='cardInner' style={{width: '72%', display: 'flex', flexWrap: 'wrap'}}>
                <SpaceDiv>
                  <CardTitle>{item?.title}</CardTitle>
                  <ViewOnGoogleBtn onClick={() => {
                    if (item?.title && item?.gpsCoordinates?.latitude && item?.dataCid) {
                      openUrl(item?.googleUrl, '_blank');
                    }
                  }}>View on google</ViewOnGoogleBtn>{`${item?.dataCid}` == currentBusinessCid && <Pill background={getPositionColor(item?.position)}>You</Pill>}
                </SpaceDiv>
                <SpaceDiv>
                  <CardSubTitle>{item?.address}</CardSubTitle>
                </SpaceDiv>
                <SpaceDiv>
                  <ValuesDiv>
                    <CardKeyword style={{color: '#F1AA3E'}}>{item?.rating}</CardKeyword>
                    <div style={{lineHeight: '12px', display: 'flex', alignItems: 'center'}}>
                      {[1, 2, 3, 4, 5].map(z => {
                        return <FontAwesomeIcon icon={faStar} key={z} style={{fontSize: '11px'}} color={z <= item?.rating ? '#F1AA3E' : '#A3A4A4'}/>;
                      })}
                    </div>
                    <CardSubTitle style={{color: '#A3A4A4'}}>({item?.reviews || '-'})</CardSubTitle>
                  </ValuesDiv>
                </SpaceDiv>
                {(item?.dataCid !== currentBusinessCid) && <ButtonStyled className='bottom-btn' onClick={() => {
                  setDataCId(item?.dataCid);
                  setCompare(true);
                }}><FontAwesomeIcon icon={faShuffle} fontSize={14} />Compare</ButtonStyled>}
              </div>
              {(item?.dataCid !== currentBusinessCid) && !showRBComparison && <ButtonStyled className='right-btn' onClick={() => {
                setDataCId(item?.dataCid);
                setCompare(true);
              }}><FontAwesomeIcon icon={faShuffle} fontSize={14} />Compare</ButtonStyled>}
            </CardContainer>;
          })}
        </ResultsWrapper>
        <MapWrapper>
          <GoogleMapReact
            bootstrapURLKeys={{key: process.env.GOOGLE_MAP_KEY}}
            center={defaultProps.center}
            onGoogleApiLoaded={({map}) => setMap(map)}
            defaultZoom={(!zoom || isNaN(zoom)) ? 13 : zoom}
            yesIWantToUseGoogleMapApiInternals
            onChange={e => setZoom(e.zoom)}
            options={{fullscreenControl: false, zoomControl: false}}>
            <ModalMapTooltip
              key={-1}
              lat={resultsData?.location?.lat}
              lng={resultsData?.location?.lon}
              blueDot={true}
              gridDimensions={gridDimensions}
              gridShape={gridShape}
              text={'blue dot'}/>
            {resultsData?.serps?.map((item, idx) => <ModalMapTooltip
              key={idx}
              yourAddress={`${item?.dataCid}` == currentBusinessCid}
              lat={item?.gpsCoordinates?.latitude}
              lng={item?.gpsCoordinates?.longitude}
              data={item?.position}
              zoom={(!zoom || isNaN(zoom)) ? 13 : zoom}
              gridDimensions={gridDimensions}
              gridShape={gridShape}
              radiusMiles={radiusMiles}
              text={item?.title}/>)}
          </GoogleMapReact>
        </MapWrapper>
      </ContentSectionWrapper>
    </div>}
  </ModalStyled>;
});
