import {Form, Slider} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const marks = Array.from({length: 10}, (_, i) => ({
  [i + 1]: `${i + 1}`,
})).reduce((acc, curr) => ({...acc, ...curr}), {});


const CustomSlider = ({defaultValue, form, disabled}) => {
  const [frequency, setFrequency] = useState(0);

  useEffect(() => {
    const obj = {};
    obj['frequency'] = defaultValue;
    form.setFieldsValue(obj);
    setFrequency(defaultValue);
  }, [defaultValue]);

  const tipFormatter = value => {
    return <CustomTooltip>{`Post every ${value} days`}</CustomTooltip>;
  };

  return (
    <SliderContainer>
      <img src={'/img/chart_svg.svg'}/>
      <Form.Item name='frequency'>
        <StyledSlider
          disabled={disabled}
          marks={marks}
          min={1}
          max={10}
          value={frequency}
          onChange={value => setFrequency(Number(value))}
          tipFormatter={tipFormatter}
          getTooltipPopupContainer={node => node}
        />
      </Form.Item>
    </SliderContainer>
  );
};

export default CustomSlider;

const SliderContainer = styled.div`
  margin-top: 13px;
  height: 100px;
  img {
    width: 260px;
    height: 46.98px;
  }
`;

const StyledSlider = styled(Slider)`
margin: 5px 0px 10px;

.ant-slider-rail {
  background: #FFFFFF !important;
  opacity: 30%;
  height: 10px !important;
  border-radius: 23px !important;
}
.ant-slider-step {
  background: rgba(59, 61, 70, 0.3) !important;
  height: 10px !important;
  border-radius: 23px !important;
}
.ant-slider-track {
  height: 10px !important;
  background: #FFFFFF !important;
  opacity: 30%;
  border-radius: 23px !important;
}
.ant-slider-handle {
  width: 22px;
  height: 22px;
  background: #2D6CCA;
  box-shadow: 0 0 0 5px #FFFFFF !important;
  border: 0px !important;
  margin-top: -4px !important;
  position: relative;
  background-image: url('/icons/sliderArrows.png');
  background-repeat: no-repeat;
  background-position: center;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  top: 19px;
  width: 2px;
  height: 9px;
  background-color: #A3A4A4;
  border: 1px solid #A3A4A4;
  cursor: pointer;
}
.ant-slider-dot-active {
  top: 19px;
  border: 0px;
  width: 2px;
  height: 9px;
  background-color: #A3A4A4;
  border-radius: 0px;
}
.ant-slider-mark-text {
top: 19px;

}
.ant-slider-mark-text-active {
top: 19px;
}
.ant-tooltip-inner {
  border-radius: 6px;
  background-color: #2D6CCA;
}
.ant-tooltip-arrow-content {
  display: none;
}
`;

const CustomTooltip = styled.div`
  color: #FFFFFF;
`;
