import {useState, useEffect, Fragment} from 'react';
import styles from './styles.module.scss';
import {Select} from 'antd';
import {Button} from '../button';
import {useStore} from '@/store/root-store';
import classnames from 'classnames';

const {Option} = Select;

interface IOption {
  name: string;
  value: string;
  raw: any;
  search: boolean;
}

const Backlink = ({optionRender, ...props}: any) => {
  const [options, setOptions] = useState(props.data || []);
  const onOptionChange = (selectedOptionValue: string) => {
    props.onOptionChange && props.onOptionChange(selectedOptionValue);
  };

  const {plans: {showSidebarPaymentDrawer}} = useStore('');
  const {navBar: {isDockedSider},
    settings: {customer: {profile: {plan}}}} = useStore('');
  // TODO: Please review what this does.
  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);

  return (
    <Select
      dropdownClassName={classnames(styles.PropertyDropDownBacklink, !isDockedSider ? styles.leftSpacing : '')}
      className={styles.SelectWithSearch}
      onChange={onOptionChange}
      {...props}
      dropdownRender={menu => (
        <Fragment>
          {menu}
        </Fragment>
      )}
    >
      {options && options.map((option: IOption, idx: number) => {
        const {name, value, raw} = option;
        return (
          <>
            <Option
              display={name}
              className={styles.Option}
              key={idx}
              value={value}
            >
              {optionRender(raw)}
            </Option>
            {!plan && (
              <div className={styles.noHover}>
                <div style={{fontWeight: 400, color: '#fff', paddingBottom: '15px'}}>
                  <span style={{fontWeight: 500}}>Analyze your website now</span>
                  <span style={{display: 'block', color: '#E8E8E8'}}>It’s time to see how your website performs</span>
                  <span style={{display: 'block', color: '#E8E8E8'}}>against competitors of different ranks.</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '15px'}}>
                  <Button onClick={() => showSidebarPaymentDrawer()} style={{background: '#7F4EAD', borderRadius: '5px'}}>Upgrade to Pro</Button>
                </div>
              </div>
            )}
            )
          </>
        );
      })}

    </Select>
  );
};

export default Backlink;
