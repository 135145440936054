import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import {faCircleCheck} from '@fortawesome/pro-duotone-svg-icons';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';

interface visibleProps {
  visible: boolean;
  data: any;
}

interface GenerateGbpPostsModalProps {
  visible: visibleProps;
  setVisible: (value: visibleProps) => void;
  submittedBusinessData: any;
  setSubmittedBusinessData: (any) => void;
}

export const CompletionModal: React.FC<GenerateGbpPostsModalProps> = observer(({visible, setVisible, submittedBusinessData, setSubmittedBusinessData}) => {
  return (
    <StyledModal
      width={'680px'}
      height={'44vh'}
      style={{margin: 'auto'}}
      visible={visible.visible}
      // closeIcon={<></>}
      onClose={() => {
        setSubmittedBusinessData({});
        setVisible({
          visible: false,
          data: null,
        });
      }}>
      <div className='wrapper'>
        <FontAwesomeIcon color={'#1FAC47'} fontSize={80} icon={faCircleCheck} />
        <div className='header'>
          <div className='heading'>
            Business submitted successfully!
          </div>
          <div className='description'>
            You’ve submitted to <span>{submittedBusinessData?.aggregators?.length ??''} Data Aggregators</span> for a total of <span style={{color: '#1FAC47'}}>{submittedBusinessData?.total??''} credits</span>.
          </div>
          <div className='view-citation'>
            <button className='view-citation-btn' onClick={() => {
              setSubmittedBusinessData({});
              setVisible({
                visible: false,
                data: null,
              });
            }}>
              <FontAwesomeIcon icon={faArrowLeft} /> Close
            </button>
          </div>
        </div>
      </div>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
   .rc-dialog-content {
    border-radius: 64px;
    .rc-dialog-body {
      background-color: #511d81;
      background-image: url('/img/businessSubmittedBg.svg');
      padding: 25px !important;
      border-radius: 60px;
      .wrapper {
        display: flex;
        flex-direction: column;
        padding: 40px;
        height: 310px !important;
        .header {
          margin: 10px;
          text-align: center;
          .heading {
            font-family: Inter;
            font-size: 26px;
            font-weight: 700;
            line-height: 38px;
            color: #FFFFFF;
          }
          .description {
            margin: 5px 0px;
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            span {
              font-weight: 600;
            }
          }
          .button-wrap {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            gap: 20px;
            .button {
              display: flex;
              justify-content: center;
              gap: 10px;
              align-items: center;
              width: 180px;
              padding: 8px 20px;
              border-radius: 5px;
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              color: #FFFFFF;
              background-color: #603886;
              cursor: pointer;
            }
          }
        }
      }
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.70 !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
  .view-citation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 39px;
    .view-citation-btn {
      width: 180px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: #FFFFFF1F;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #FFFFFF;
      border: 0;
      cursor: pointer;
    }
  }
`;
