import {getApiUrl, getAuthHeader} from '../common-utils';
import {BaseApi} from '@/api/base-api';


class DigitalPrApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');

  public async loadJournalists(payload, publicHash) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${DigitalPrApi.baseUrl}/customer/journalist/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadMediaList(payload, publicHash) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${DigitalPrApi.baseUrl}/customer/media/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadJournalistDetails(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${DigitalPrApi.baseUrl}/customer/journalist/${id}/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadTwitterFeeds(id, payload, publicHash) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${DigitalPrApi.baseUrl}/customer/journalist/${id}/tweets/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadMediaDetails(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${DigitalPrApi.baseUrl}/customer/media/${id}/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
export const DIGITAL_PR_API = new DigitalPrApi();
