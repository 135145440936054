import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {
  templatesCount: number;
  onTemplateClick: () => void;
}
/**
 * Single Ai Template list item
 *
 * @param {AiTemplateType} template single ai template data
 * @param {string[]} bookmarkedTemplates list of bookmarked templates stored in localstorage
 * @return {any} - single template for list
 */
export const OpenAllTemplates = ({templatesCount, onTemplateClick}: Props) => {
  // this can be used to temp disable some templates


  return <Wrapper onClick={() => {
    onTemplateClick();
  }}>
    <Container>
      <ImgContainer>
        <FontAwesomeIcon icon={faArrowRight} color={'#B0BACE'} style={{margin: 'auto', fontSize: 18}}/>
      </ImgContainer>
      <TemplateName>{`All ${templatesCount} templates`}</TemplateName>

    </Container>
  </Wrapper>;
};

const Wrapper = styled.div<{disabled?: boolean}>`
  background: #EFEFF4;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: ${p => p.disabled ? 'default' : 'pointer'};
  transition: all .2s ease;
  min-height: 70px;
  position: relative;
  min-width: 75px;
  max-width: 90px;
  width: fit-content;
  margin: 5px;
  
  &:hover {
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.15);
  }
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
`;

const TemplateName = styled.div`
  font-size: 12px;
  color: #4E5156;
  margin-bottom: 4px;
  text-align: center;
  line-height: 13px;
  max-width: 75px;
`;
