import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';


interface GscBacklinksProps {
  property: string;
  type: 'new' | 'lost' | 'default';
  pageNumber: number;
  pageSize: number;
  groupedBy?: string;
  sortBy?: string;
  search?: string;
}
interface GscGroupedBacklinksProps {
  property: string;
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  search?: string;
  refdomain?: string;
}
interface GscAnchorTextProps {
  property: string;
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  search?: string;
  anchorText?: string;
}

interface GscMainDrawerBacklinksProps {
  property: string;
  type: 'new' | 'lost' | 'default';
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  search?: string;
  targetPage?: string;
  grouped?:boolean;
}
class BacklinksApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');
  private static readonly backlinksUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v2/competitor-research');


  /**
  * Fetch data for GSC Backlinks page
  * @param property main site property
  * @param type used for table type we are showing
  * @param pageNumber current pagination page
  * @param pageSize total items per pagination page
  * @param groupedBy if true switches to different set of tables
  * @param sortBy name of the sorting column, should not be sent for default sorting
  * @param search search string query
  */


  public async getGscBacklinks({property, type, pageNumber, pageSize, groupedBy, sortBy, search}: GscBacklinksProps) {
    try {
      const response = await this.axios.get(`${BacklinksApi.baseUrl}/backlinks/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          site_property: property,
          type: type,
          page: pageNumber,
          page_size: pageSize,
          ...(groupedBy && {group_by: groupedBy}),
          ...(sortBy && {order_by: sortBy}),
          ...(search && {search: search}),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*
  * Fetching gsc backlinks for Linking Pages SideDrawer
  */
  public async getGscBacklinksLinkingPages({property, refdomain, pageNumber, pageSize, sortBy, search}: GscGroupedBacklinksProps) {
    try {
      const response = await this.axios.get(`${BacklinksApi.baseUrl}/backlinks/refdomain-analysis/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          site_property: property,
          refdomain: refdomain,
          page: pageNumber,
          page_size: pageSize,
          ...(sortBy && {order_by: sortBy}),
          ...(search && {search: search}),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*
  * Fetching gsc backlinks for Anchor Text
  */
  public async getGscAnchorTextAnalysis({property, anchorText, pageNumber, pageSize, sortBy, search}: GscAnchorTextProps) {
    try {
      const response = await this.axios.get(`${BacklinksApi.baseUrl}/backlinks/anchor-text-analysis/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          site_property: property,
          anchor_text: anchorText,
          page: pageNumber,
          page_size: pageSize,
          ...(sortBy && {order_by: sortBy}),
          ...(search && {search: search}),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
  * Fetch data for TopPages Sidedrawer
  * @param {string} property main site property
  * @param {string} type used for table type we are showing
  * @param {number} pageNumber current pagination page
  * @param {number} pageSize total items per pagination page
  * @param {number} grouped if true switches to different set of tables
  * @param {string} sortBy name of the sorting column, should not be sent for default sorting
  * @param {string} search search string query
  */
  public async getGscBacklinksForPage({property, targetPage, type, pageNumber, pageSize, grouped, sortBy, search}: GscMainDrawerBacklinksProps) {
    try {
      const response = await this.axios.get(`${BacklinksApi.baseUrl}/backlinks/target-page-analysis/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          site_property: property,
          target_page: targetPage,
          type: type,
          page: pageNumber,
          page_size: pageSize,
          ...(grouped && {group_by: 'refdomains'}),
          ...(sortBy && {order_by: sortBy}),
          ...(search && {search: search}),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*
  * Fetching gsc backlinks for main GSC Backlinks page - sidedrawer
  */
  public async getGscBacklinksForPageMainDrawer({property, targetPage, type, pageNumber, pageSize, sortBy, search}: GscMainDrawerBacklinksProps) {
    try {
      const response = await this.axios.get(`${BacklinksApi.baseUrl}/backlinks/target-page-analysis/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          site_property: property,
          target_page: targetPage,
          type: type,
          page: pageNumber,
          page_size: pageSize,
          ...(sortBy && {order_by: sortBy}),
          ...(search && {search: search}),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*
  * Starts backlinks processing for that site property
  */
  public async processGscBacklinks(property: string) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: `${BacklinksApi.baseUrl}/backlinks/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: {
          site_property: property,
        },
        // cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  /*
  * Checks for backlinks connection status
  */
  public async checkBacklinksConnectionStatus(property: string) {
    try {
      const response = await this.axios.get(`${BacklinksApi.baseUrl}/backlinks/connection-status/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          site_property: property,
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadBacklinksDetailsData(property: string) {
    try {
      const response = await this.axios.get(`${BacklinksApi.backlinksUrl}/backlinks/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          target: property,
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default BacklinksApi;
