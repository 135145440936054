import {observer} from 'mobx-react';
import {Collapse} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {CustomContentHTML, PaginationStyled, StyledIssuesTable, UrlWrapper, CustomContentHTMLKey, StyledIssuesCollapse} from '../../../../style';
import {getDeployStatus, getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {debounce} from 'lodash';
import {CustomHtmlPopover} from '../../../customHtmlPopover';
import {TableTopBar} from '../../../tableTopBar';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
}

export const CustomContent = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    isActiveKeys,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    selectedIssue,
    editCustomHtml,
  },
  } = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');

  const [ottoUrls, setOttoUrls] = useState([]);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));


  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const onConfirm = async (id, headerContent, bodyContent) => {
    await editCustomHtml(id, uuid, {custom_html_head: headerContent, custom_html_body: bodyContent});
    const activeKey = isActiveKeys?.find(item => item?.issue == 'custom_content')?.key || 'all';
    loadIssueTables(['custom_content'], 'new_content', 'custom_content', url?.page, url?.pageSize, false, '', '', activeKey);
  };

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };

  const columns = [
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      width: '280px',
      render: (_, record) => {
        const path = (record?.path?.length && record?.path[0] === '/') ? record?.path.slice(1) : record?.path;
        return <UrlWrapper onClick={() => openUrl(`${addProtocolToDomain(domainName)}/${path}`, '_blank')}>{record?.path}</UrlWrapper>;
      },
    },
    {
      title: <div className='column-title'>CUSTOM HTML</div>,
      dataIndex: 'custom_html',
      key: 'custom_html',
      render: (_, record) => {
        return (
          <div style={{maxWidth: 800, width: '100%'}}>
            {record?.customHtmlHead && <CustomContentHTML>
              <CustomContentHTMLKey>Header:</CustomContentHTMLKey>
              &nbsp;{record?.customHtmlHead}
            </CustomContentHTML>}
            {record?.customHtmlBody && <CustomContentHTML>
              <CustomContentHTMLKey>Body:</CustomContentHTMLKey>
              &nbsp;{record?.customHtmlBody}
            </CustomContentHTML>}
            <CustomHtmlPopover data={record} isEdit={record?.customHtmlHead || record?.customHtmlBody} onConfirm={onConfirm} />
          </div>
        );
      },
    },
  ];

  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter} />
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          scroll={{x: 'auto'}}
        />
        <PaginationStyled
          onChange={(page, pageSize) => {
            setPageChanged(true);
            setIssueTable(url?.issueType);
            handlePaginationChange([url?.issueType], page, pageSize);
            saveOttoTablePageSize(componentIssueType, pageSize);
            setPageSize(pageSize);
          }}
          total={url?.issueTable?.count}
          pageSize={pageSize}
          current={url?.page ?? 1}
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50', '100']}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
