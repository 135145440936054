import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Collapse, Dropdown, Switch, Divider, Row, Col, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {faChartLine} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import {Recipents} from '@/components/dashboard/pages/site-audit-v2/email-alerts-page/Recipents';
import {TableStyled} from '@/components/dashboard/pages/home-page-main/components/table-styled';
import {CountryDropdown} from '@/components/common-components/v2';
import {ProjectSelector} from '../components/ProjectSelector';
import {Button} from 'antd';
import {toJS} from 'mobx';
import {titleCase} from '@/utils/string';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';


export const GscEmailUpdatesV2 = observer(() => {
  const {home: {loadEmailSettingV2, getInialCountryCode, selectedCountryCode, tabelLoading, getCountyCode, getEmailSettingsV2, loadUpdateEmailSettingsV2, updateListEmailSetting}} = useStore('');
  const {
    navBar,
  } = useStore('');
  const {navBar: {getSiteProperties, loadSiteProperties, loading}} = useStore('');
  const {settings: {customer: {profile: {team}}}} = useStore('');
  const {siteAuditorV2: {emailSettings: {loadListUserSettings}}} = useStore('');
  const [screenWidth, setScreenWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const getInitialId = () => {
    if (navBar?.getSiteProperties?.length) {
      return navBar?.getSiteProperties?.sort((a, b) => Number(b?.activeForCurrentCustomer) - Number(a?.activeForCurrentCustomer))[0]?.id;
    }
  };
  const [id, setId]= useState(getInitialId() || '');
  const getCountriesList = navBar?.getSiteProperties?.find(z => z?.id == id)?.activeCountryCodes || [];
  const [teamEmail, setTeamEmail]= useState([]);
  const [btnLoading, setBtnLoading]= useState(false);

  useEffect(() => {
    if (!getSiteProperties?.length) {
      loadSiteProperties();
    }
  }, [getSiteProperties]);

  const loadData = async id=> {
    setId(id);
    getInialCountryCode(getCountriesList?.length ? getCountriesList[0] : '');
    await loadEmailSettingV2(id);
    await loadListUserSettings();
  };
  useEffect(()=> {
    if (navBar?.getSiteProperties?.length) {
      loadData(getInitialId());
    }
  }, [navBar?.getSiteProperties]);
  useEffect(()=>{
    const newTeam = [];
    toJS(team)?.map(item => {
      newTeam?.push({
        recipient: item?.email,
        isActive: false,
      });
    });
    setTeamEmail(newTeam);
  }, [toJS(team)?.length]);

  const updateEmail = async ()=> {
    setBtnLoading(true);
    await loadUpdateEmailSettingsV2(id);
    setBtnLoading(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bulkActionsDisabled = getEmailSettingsV2?.length && !getEmailSettingsV2?.some(item => item?.recipients?.length);


  const handleChange = (checked, record) => {
    const data = [...getEmailSettingsV2];
    const index = data?.findIndex(z => z?.id == record?.id);
    data[index].emailEnabled = checked;
    updateListEmailSetting(data);
  };

  const handleChangeAll = enabled => {
    if (!getEmailSettingsV2?.length|| bulkActionsDisabled) return;
    const data = getEmailSettingsV2?.map(z => {
      return {
        ...z,
        emailEnabled: z?.recipients?.length ? enabled : false,
      };
    });
    updateListEmailSetting(data);
  };
  const handleAddRecipients = (value, record)=> {
    const data = [...getEmailSettingsV2];
    const index = data?.findIndex(z => z?.id == record?.id);
    data[index].recipients = value;
    data[index].emailEnabled = !!value?.length;
    updateListEmailSetting(data);
  };
  const onCountryChange = value => {
    getCountyCode(id, value);
  };

  const onProjectChange = async val => {
    setId(val);
    const countriesId = navBar?.getSiteProperties?.find(z => z?.id == val)?.activeCountryCodes || [];
    const checkCountry = countriesId?.includes(selectedCountryCode) ? selectedCountryCode : (countriesId?.length ? countriesId[0] : '');
    getInialCountryCode(checkCountry);
    await loadEmailSettingV2(val);
  };

  const frequencyText = value => {
    switch (value) {
      case 1:
        return 'Daily';
      case 7:
        return 'Weekly';
      case 30:
        return 'Monthly';
      default:
        return `${value} days`;
    }
  };

  const emailTableColumns: any = [
    {
      title: <ColumnTitle>EMAIL ALERT</ColumnTitle>,
      key: 'emailType',
      dataIndex: 'emailType',
      width: 400,
      align: 'left' as 'left',
      render: (_, data) => {
        return <FlexWrapper><Switch
          checked={data?.emailEnabled}
          onChange={checked => handleChange(checked, data)}
          loading={data?.loading}
          disabled={!data?.recipients?.length}
        />
        <TableStr>{data?.emailType ? titleCase(data?.emailType) : '-'}</TableStr>&nbsp;&nbsp;&nbsp;
        {/* <FontAwesomeIcon icon={faCircleInfo} color='#DADADA' cursor={'pointer'}/> */}
        </FlexWrapper>;
      },
    },
    {
      title: <ColumnTitle>FREQUENCY</ColumnTitle>,
      key: 'freq',
      dataIndex: 'freq',
      width: 120,
      align: 'left' as 'left',
      render: (_, data) => {
        return <div>
          {data?.freq ? frequencyText(data?.freq) : '-'}
        </div>;
      },
    },
    {
      title: <ColumnTitle>RECIPIENTS</ColumnTitle>,
      key: 'recipients',
      dataIndex: 'recipients',
      width: 150,
      render: (_, data) => {
        return <Recipents teamEmail={teamEmail} data={data} handleAddRecipients={value => handleAddRecipients(value, data)} isNewEmailSettings={true}/>;
      },
    },
    {
      title: <ColumnTitle>LAST SENT</ColumnTitle>,
      key: 'lastSent',
      dataIndex: 'lastSent',
      width: 100,
      align: 'center',
      render: (_, data) => {
        return <div>{data?.lastSent || '-'}</div>;
      },
    },
  ];

  return (
    <CollapseWrapper
      ghost
      expandIcon={({isActive}) => isActive ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown} /> }
      bordered={false}
      defaultActiveKey={1}
      className='site-collapse-custom-collapse'
      expandIconPosition='right'
    >
      <Collapse.Panel header={<HeadingWrapper><FontAwesomeIcon icon={faChartLine} color='#2D6CCA' />&nbsp;&nbsp;GSC Insights</HeadingWrapper>} key={'1'} showArrow={true}>
        {loading && !getSiteProperties?.length ? <RingLoaderV2 height='300px'/> : <>
          <Row gutter={24} style={{marginBottom: '7px'}}>
            <Col xl={6} md={12} xs={24}>
              <ProjectSelector selectedId={getInitialId()} onProjectChange={onProjectChange} />
            </Col>
            <Col xl={6} md={12} xs={24}>
              <StyledCountryDropdown getPopupContainer={trigger => trigger.parentNode} value={selectedCountryCode} countriesToInclude={getCountriesList} isNewEmailSettings={true} onChange={onCountryChange} />
            </Col>
            <Col xl={4} md={8} xs={24}>
                Active emails: {tabelLoading ? '-' : getEmailSettingsV2?.filter(z => z?.emailEnabled)?.length}
            </Col>
            <Col xl={4} md={8} xs={24}>
                Inactive: {tabelLoading ? '-' : getEmailSettingsV2?.filter(z => !z?.emailEnabled)?.length}
            </Col>
            <Dropdown placement='bottomLeft' trigger={['click']} overlayStyle={{borderRadius: '8px', background: '#ffff', padding: '5px 0 5px 0',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            }} overlay={
              <>
                <Tooltip placement='left' overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title={!getEmailSettingsV2?.length ? 'No email alerts found' : bulkActionsDisabled ? 'You cannot enable or disable alerts because there are no active email recipients.' : ''}>
                  <DisableBtnStyled isDisabled={!getEmailSettingsV2?.length|| bulkActionsDisabled} onClick={() => handleChangeAll(true)}>Enable all alerts for all projects</DisableBtnStyled>
                </Tooltip>
                <Divider/>
                <Tooltip placement='left' overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title={!getEmailSettingsV2?.length ? 'No email alerts found' : bulkActionsDisabled ? 'You cannot enable or disable alerts because there are no active email recipients.' : ''}>
                  <RemoveBtnStyled isDisabled={!getEmailSettingsV2?.length|| bulkActionsDisabled} onClick={() => handleChangeAll(false)}>Disable all alerts for all projects</RemoveBtnStyled>
                </Tooltip>
              </>
            } >
              <Col xl={4} md={8} xs={24} style={{color: '#2D6CCA', cursor: 'pointer'}}>Bulk actions <FontAwesomeIcon icon={faCaretDown} color='#2D6CCA'/></Col>
            </Dropdown>
          </Row>
          <TableStyled
            headingSpaceFromRight={screenWidth > 760 ? '10px' : '0'}
            loading={tabelLoading || loading}
            columns={emailTableColumns}
            isNewEmailSetting={true}
            dataSource={getEmailSettingsV2}
            pagination={false}
            bordered={false}
          />
          <ButtonStyled loading={btnLoading} onClick={()=> updateEmail()}>Update GSC settings</ButtonStyled>
        </>}
      </Collapse.Panel>

    </CollapseWrapper>
  );
});

const CollapseWrapper = styled(Collapse)`
  background: linear-gradient(148.29deg, rgba(45, 108, 202, 0.09) 6.67%, rgba(45, 108, 202, 0) 27.09%), #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  margin-top: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
`;
const HeadingWrapper = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin: 20px 10px 14px 3px;
`;

const ColumnTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
`;

const TableStr = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
  text-transform: capitalize;
  margin-left: 17px;
`;
const DisableBtnStyled = styled.div<{isDisabled?: boolean}>`
color: #121212;
margin-bottom: -22px;
line-height: 22px;
width: fit-content;
display: flex;
justify-content: center;
cursor: pointer;
padding: 8px 26px 8px 14px;
&:hover {
  background: #E8E8E8;
}
${p => p?.isDisabled ? `
  cursor: not-allowed;
  opacity: 70%;
` : ''}
`;
const RemoveBtnStyled = styled.div<{isDisabled?: boolean}>`
color: #F44343;
margin-top: -22px;
line-height: 22px;
display: flex;
padding: 8px 20px 8px 14px;
justify-content: flex-start;
cursor: pointer;
&:hover {
  background: #E8E8E8;
}
${p => p?.isDisabled ? `
  cursor: not-allowed;
  opacity: 70%;
` : ''}
`;
const FlexWrapper = styled.div`
display: flex;
align-items: center;
`;
const ButtonStyled = styled(Button)`
width: 171px;
height: 32px;
background: #2D6CCA;
border-radius: 5px;
color: #fff;
margin-left: 10px;
margin-top: 30px;
border: 1px solid #2D6CCA;
&:hover {
background: #3066b7;
color: #fff;
}
&:focus {
background: #3066b7;
color: #fff;
}
`;

const StyledCountryDropdown = styled(CountryDropdown)`
  width: 100%;
.ant-select-selector {
  width: 100% !important;
}
@media only screen and (max-width: 767px) and (min-width: 559px)  {
  padding-left: 12px;
  padding-top: 12px;
}
`;
