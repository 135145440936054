import {IFocusTermToClassNameMap} from '@/store/content-optimizer/currentPage';
import {CompositeDecorator, EditorState} from 'draft-js';
import {createHighlightDecorator} from './highlight-decorator';
import {createLinkDecorator} from './link-decorator';

export const createCompositeDecorators = (focusTermClassNames: IFocusTermToClassNameMap): CompositeDecorator =>
  new CompositeDecorator([createLinkDecorator(focusTermClassNames), createHighlightDecorator(focusTermClassNames)]);

export const decoratedState = (state: EditorState, focusTermClassNames: IFocusTermToClassNameMap): EditorState => {
  return EditorState.set(state, {decorator: createCompositeDecorators(focusTermClassNames)});
};
