import React from 'react';
import styled from 'styled-components';
import {Modal, Table} from 'antd';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {observer} from 'mobx-react';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  visible: boolean;
  setVisible: (val: boolean) => void;
  isWidgetModalVisible?: boolean;
  isInstructionModalVisible?: boolean;
  archivedOttoProjects: any;
  loadingArchivedOttoProjects?: boolean;
  tableColumn: (removeDeleteOption?: boolean) => any[];
  gbpModalVisible?: boolean;
}

export const ArchivedSitesModal = observer(({
  visible,
  setVisible,
  isWidgetModalVisible,
  isInstructionModalVisible,
  archivedOttoProjects,
  loadingArchivedOttoProjects,
  tableColumn,
  gbpModalVisible = false,
}: Props) => {
  const {
    siteAuditorV2: {
      openGscModal,
    },
  } = useStore('');

  return (
    <StyledModal
      width={1230}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={false}
      closable={false}
      zIndex={(openGscModal || isWidgetModalVisible || isInstructionModalVisible || gbpModalVisible) ? 5 : null}
    >
      <FontAwesomeIcon className='close-icon' onClick={() => setVisible(false)} icon={faXmark} color='#fff' />
      <FontAwesomeIcon className='icon' icon={faBoxArchive} color='#4E5156' />
      <div className='heading'>Archived (Deep Frozen) Websites</div>
      <StyledTable
        style={{width: '1180px', overflow: 'hidden'}}
        columns={tableColumn(true)?.filter(i => i?.key !== 'pagesWithIssues' && i?.key !== 'lastCrawl')}
        dataSource={archivedOttoProjects?.results}
        pagination={false}
        loading={loadingArchivedOttoProjects}
        scroll={{x: 'auto'}}
      />
      <button onClick={() => setVisible(false)} className='close-button'>Close</button>
    </StyledModal>
  );
});

const StyledModal = styled(Modal)`
.ant-modal-content {
  border-radius: 12px;
}
.icon {
  width: 30px;
  height: 30px;
}
.heading {
  margin-top: 12px;
  margin-bottom: 21px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: left;
  color: #121212;
}
.close-button {
  margin-top: 20px;
  width: 88px;
  height: 44px;
  border-radius: 8px;
  background: #F2F2F5;
  border: 0px;
  cursor: pointer;
}
.close-icon {
  position: absolute;
  right: -35px;
  top: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
`;

const StyledTable = styled(Table)`
  border: 1px solid #E8E8E8;
  border-radius: 12px;
  width: 1180px;
  .ant-table-content{
    overflow: auto !important;
  }
  .site-name {
    max-width: 320px !important;
  }
  .ant-table-container {
    border-top-right-radius: 12px !important;
    border-top-left-radius: 12px !important;
  }
  .column-title {
    line-height: 12px !important;
  }
  .time-saved {
    max-width: 84px !important;
  }
  .option-btn {
    padding: 0px !important;
  }
  .otto-score {
    width: 54px !important;
  }
  .ant-table-thead > tr > th {
    background-color: #F7F7F7 !important;
    padding: 7px 12px !important;
    &::before {
      content: '';
      display: none !important;
    }
  }
  .ant-table-tbody {
    td {
      padding: 2.5px 12px !important;
      &:nth-last-child(2) {
        padding-right: 4px !important;
        padding-left: 4px !important;
      }
      &:last-child {
        padding-right: 4px !important;
        padding-left: 4px !important;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
  .ant-table-body, .ant-table-content{
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #F9F9FB;
    }

    ::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 5px;
    }
  }
`;
