import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {CaretDownOutlined, DeleteOutlined, LoadingOutlined} from '@ant-design/icons';
import classnames from 'classnames';
import numeral from 'numeral';
import {Tooltip as AntdCommonTooltip, Popover, Spin} from 'antd';
import Button from './../button-component/';
import {Input} from './../form/input/input';
import styles from './style.module.scss';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import Filters from '../filters';
import FilterPopUp from '../filter-popup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import FilterKeywordPopUp from '../filter-keyword-popup';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import FilterMultipleCheckboxPopUp from '../filter-multiple-checkbox-popup';
import {titleCase} from '@/utils/string';
import FilterMultipleRadioPopUp from '../filter-multiple-radio-popup';
import {NextImg} from '@/utils/nextImg';


interface IFilterInputItem {
  type?: string;
  header: string;
  name: string;
}

export interface FilterInputProps {
  filters?: Array<IFilterInputItem>;
  searchPlaceHolder?: string;
  onChange?: Function;
  clearFilters?: Function;
  onKeywordChange?: Function;
  tooltip?: ReactNode;
  notKeywordTerm?:string;
  searchBarWidth?: number;
  filterClasses?: string;
  disabled?: boolean;
  open?: boolean;
  NoDeleteIcon?:boolean;
  mainFilters?: any;
  extraFilters?: any;
  dateAndCountryPicker?: any;
  newFilterUI?:boolean;
  initialList?:any;
  quickSortOpen?:boolean;
  clearFilterList?: Function;
  quickSortMenu?: Function;
  renderManageColumn?: Function;
  handleSort?: ()=> void;
  onlyFilters?:boolean;
  onlySorters?:boolean;
  onFilter?: Function;
  keyWordTooltip?: boolean;
  keywordEmpty?:boolean;
  openFilterContainerByDefault?: boolean;
  openFilterContainerByDefaultIds?: number[];
  showDelete?:boolean;
  value?: string;
  showCheckboxIcons?: boolean;
  isIssuePage?: boolean;
  krtAddKwButton?: boolean;
  krtAddKwBtnLoad?: boolean;
  krtAddKwCallback?: () => void;
  drawerState?: boolean;
}

// FIXME: types
export const FilterInputItem = (props: any) => {
  const {t} = useTranslation('common');
  const onChange = props.onChange;
  const FilterInputItemDom = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const {gsc: {filterModel: {setFilterFrom, setFilterTo, filterTo, filterFrom, setIsClear, isClear}}} = useStore('');
  const [to, setTo] = useState('');
  const [from, setFrom] = useState('');

  const disabled = props.disabled || false;
  useEffect(()=> {
    if (!isClear) {
      setTo('');
      setFrom('');
    }
  }, [isClear]);

  const escapePress = function(e) {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const clickHandler = function(e) {
    const path = e?.path || (e?.composedPath && e?.composedPath());
    const isInPath = path?.includes(FilterInputItemDom.current);
    if (!isInPath) {
      setIsOpen(false);
    }
  };

  const closeForm = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  const emitOnChanges = (from: string, to: string) => {
    if (typeof onChange === 'function') {
      onChange({
        ...props.filter,
        from: !from ? '0' : from,
        to,
      });
    }
  };

  const emitOnChangesCancel = () => {
    if (typeof onChange === 'function') {
      onChange({
        ...props.filter,
        query: '',
        from: undefined,
        to: undefined,
        active: false,
      });
    }
  };

  const setInitialFilters = (from: any, to: any) => {
    if (typeof onChange === 'function') {
      onChange({
        ...props.filter,
        from,
        to,
      });
    }
  };

  const submitForm = () => {
    emitOnChanges(from, to);
    setIsClear(true);
    closeForm();
  };

  const clearValues = () => {
    if (!filterTo && !filterFrom) {
      closeForm();
      return;
    }
    setFilterFrom('');
    setFilterTo('');
    setTo('');
    setFrom('');
    emitOnChangesCancel();
    closeForm();
  };

  const headerLabel = () => {
    if (from !== '' && to == '') {
      return `${numeral(from).format('0')}+`;
    }
    if (from === '' && to !== '') {
      return `0-${numeral(to).format('0')}`;
    }
    if (from !== '' && to !== '') {
      // added space between separating line, useful for better visibility when second value is negative
      return `${numeral(from).format('0')} - ${numeral(to).format('0')}`;
    }
    return 'Any';
  };

  const isApplyDisabled = () => {
    return (from === '' && to === '');
  };

  useEffect(() => {
    setInitialFilters(null, null);
    window.addEventListener('click', clickHandler);
    document.addEventListener('keyup', escapePress);
    return () => {
      window.removeEventListener('click', clickHandler);
      document.removeEventListener('keyup', escapePress);
    };
  }, []);

  return (
    <div
      ref={FilterInputItemDom}
      style={{zIndex: props.filterLength + 3 - props.index}}
      className={classnames({
        [styles.isBodyVisible]: isOpen,
        [styles.filterInputItem]: true,
      })}
    >
      <AntdCommonTooltip
        title={ disabled ? t('filter-input-tooltip-title') : ''}
        overlayClassName={styles.customTooltip}
        placement='top'>
        <div
          className='header'
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span className={isOpen ?styles.filterActive: 'label' }>
            <span className='filter-title'>{props.filter.header}:</span>
            <span className={styles.any}>{headerLabel()}</span>
            <span className='icon'>
              <CaretDownOutlined />
            </span>
          </span>
        </div>
        <div
          className={classnames('body', {
            isVisible: isOpen === true,
          })}
        >
          <div style={{display: 'flex', justifyContent: 'center', height: '50%', borderBottom: '1px solid rgba(0, 0, 0, 0.1)'}}>
            <div className='bodyElement'>
              <label style={{display: 'block', fontSize: '12px', color: '#4E5156'}} >{t('filter-input-label-from')}</label>
              <Input
                className={styles.in}
                placeholder='-'
                value={from}
                disabled={disabled}
                type='number'
                onChange={e => {
                  setFrom(e.target.value);
                  setFilterFrom(e.target.value);
                }}
              />
            </div>
            <div className='bodyElement'>
              <label style={{display: 'block', fontSize: '12px', color: '#4E5156'}} >{t('filter-input-label-to')}</label>
              <Input
                className={styles.in}
                placeholder='-'
                value={to}
                disabled={disabled}
                type='number'
                onChange={e => {
                  setTo(e.target.value);
                  setFilterTo(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{display: 'flex', height: '50%', float: 'right', paddingTop: '15px', paddingRight: '10px'}} >
            <div className='bodyElement'>
              <Button
                color= 'gray'
                onClick={() => {
                  clearValues();
                }}>
                {t('filter-input-button-cancel')}
              </Button>
            </div>
            <div className='bodyElement'>
              <Button
                // disabled={disabled || from.length == 0 || to.length == 0}
                color='primary'
                onClick={() => {
                  if (!isApplyDisabled()) {
                    submitForm();
                  }
                }}
              >
                {t('filter-input-button-apply')}
              </Button>
            </div>
          </div>
        </div>
      </AntdCommonTooltip>
    </div>
  );
};

export const FilterInput: React.FC<FilterInputProps> = props => {
  const {t} = useTranslation('common');
  const filters = props.filters || [];
  // const NoDeleteIcon = props.NoDeleteIcon || false;
  const propsOnChange = props.onChange || function() {};
  const onlyFilters = props.onlyFilters || false;
  const onlySorters = props.onlySorters || false;
  // eslint-disable-next-line
  const onKeywordChange = props.onKeywordChange || function() {};
  const searchPlaceHolder = props.searchPlaceHolder || t('filter-input-search-placeholder');
  const newFilterUI = props.newFilterUI || false;
  const notKeywordTerm = props.notKeywordTerm || '';
  const filterClasses = props.filterClasses || '';
  const disabled = props.disabled || false;
  const showDelete = props?.showDelete || true;
  const keyWordTooltip = props.keyWordTooltip || false;
  const keyWordValue = props.value || null;
  const {gsc: {filterModel: {setFilterFrom, setFilterTo, setIsClear}}} = useStore('');
  const mainFilters = props.mainFilters || [];
  const [keywordTerm, setKeywordTerm] = useState(null);
  const initialList = props.initialList || [];
  const extraFiltersLength = props?.extraFilters?.length ?? 0;
  const openContainerByDefault = !!(props.extraFilters?.length || props.openFilterContainerByDefault);
  const [openFiltersContainer, setOpenFiltersContainer] = useState(openContainerByDefault);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [selectedId, setSelectedId] = useState(undefined);
  const [iconColor, setIconColor] = useState(false);
  const handleSort = props.handleSort || function() {};
  const quickSortOpen = props.quickSortOpen || false;
  const quickSortMenu = props.quickSortMenu || function() {};
  const renderManageColumn = props.renderManageColumn || function() {};
  const activeFilters = () => mainFilters.filter(filter => filter.active);
  const [deleteAll, setDeleteAll] = useState(false);
  const clearFilters = () => {
    setIsClear(false);
    setFilterTo('');
    setFilterFrom('');
    setDeleteAll(false);
    propsOnChange(filters.map(item => ({
      ...item,
      from: null,
      to: null,
      query: '',
    })));
  };


  const antIcon = <LoadingOutlined style={{fontSize: 15, marginLeft: 10, color: '#7f4ead'}} spin />;

  useEffect(() => {
    setDeleteAll(false);
  }, []);

  const openFilters = () =>{
    setOpenFiltersContainer(!openFiltersContainer);
  };
  useEffect(() => {
    if (props?.keywordEmpty == true) {
      setKeywordTerm('');
    }
  }, [props?.keywordEmpty]);

  useEffect(() => {
    if (keyWordValue) {
      setKeywordTerm(keyWordValue);
    }
  }, [keyWordValue]);


  useEffect(() => {
    if (onKeywordChange && keywordTerm != null) {
      const timeOutId = setTimeout(() => onKeywordChange(keywordTerm), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [keywordTerm]);

  const onChange = w => {
    if (newFilterUI) {
      const currentFilters = mainFilters.map(filter =>{
        if ( filter.name === w.name && filter?.id === w?.id) {
          return w;
        } else {
          return filter;
        }
      });
      propsOnChange(currentFilters);
    } else {
      const find = filters.findIndex(c => c.name === w.name);
      if (find > -1) {
        filters[find] = w;
      } else {
        filters.push(w);
      }
      setDeleteAll(true);
      propsOnChange(filters);
    }
  };

  const handleDeleteAll = () => {
    propsOnChange(initialList);
    setOpenFiltersContainer(false);
  };

  const handleDelete = (name, id) => {
    propsOnChange(mainFilters.map(item => {
      if (item.name === name && item?.id === id) {
        if (name==='exclude') {
          return ({
            ...item,
            text: '',
            query: '',
            type: undefined,
            category: undefined,
            from: undefined,
            to: undefined,
            active: false,
          });
        } else {
          return ({
            ...item,
            type: undefined,
            category: undefined,
            from: undefined,
            to: undefined,
            active: false,
          });
        }
      } else {
        return item;
      }
    }));
  };

  const handleClick = (value, id) =>{
    if (id == selectedId) {
      setSelectedId('');
    } else {
      setSelectedId(id);
    }
    setSelectedFilter(value);
    if (value == 'options' || (value == 'checkboxes' && !props.openFilterContainerByDefault)) {
      setOpenFilterPopup(false);
    } else {
      if (id == selectedId) {
        setTimeout(() => {
          setOpenFilterPopup(!openFilterPopup);
        }, 170);
      } else {
        setOpenFilterPopup(false);
        setTimeout(() => {
          setOpenFilterPopup(true);
        }, 200);
      }
    }
  };
  const sortRef = useRef<HTMLInputElement>();

  useEffect(() => {
    function handleClickOutside(event) {
      if (sortRef.current && (!sortRef.current.contains(event.target) && quickSortOpen )) {
        handleSort();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sortRef, quickSortOpen]);


  const changeInput = event =>{
    setKeywordTerm(event.target.value);
    setIconColor(!!event.target.value);
  };

  const getFiltersLength = () => {
    return activeFilters().length + extraFiltersLength > 0 ? activeFilters().length + extraFiltersLength : 0;
  };

  const filterLength = activeFilters()?.length;

  useEffect(() => {
    if (!getFiltersLength() && !props.openFilterContainerByDefault) {
      setOpenFiltersContainer(false);
    }
  }, [filterLength]);

  const makeOptions = filterTypes => {
    const type = filterTypes?.map(item => {
      return {label: titleCase(item), value: item};
    });
    return type;
  };

  function uniqByKeepFirst(a, key) {
    const seen = new Set();
    return a.filter(item => {
      const k = key(item);
      return seen?.has(k) ? false : seen?.add(k);
    });
  }

  const allFiltersNewList = mainFilters?.filter(z => props?.openFilterContainerByDefaultIds?.length ? props?.openFilterContainerByDefaultIds?.includes(z?.id) : z?.id)?.concat(activeFilters());
  return (
    <>
      <div className={classnames(styles.filterInput, 'main-filter')}>
        <AntdCommonTooltip
          title={ disabled ? keyWordTooltip ? 'Feature disabled when keyword filter is active' : t('filter-input-tooltip-title') : ''}
          overlayClassName={styles.customTooltip}
          placement='top'>
          {!onlyFilters && !onlySorters && <div className='main-input'>
            <FontAwesomeIcon icon={faSearch} className={iconColor ? styles.changeIconColor: styles.menuIcon } />
            <Input value={keywordTerm} placeholder={searchPlaceHolder} onChange={changeInput} disabled={disabled}/>
          </div>}
        </AntdCommonTooltip>
        { !newFilterUI ? <div style={{display: 'flex', justifyContent: 'center'}} className={styles.filtersContainer}>
          <div className={`filters ${filterClasses}`}>
            {filters.map((filter: IFilterInputItem, index) => {
              return (

                <FilterInputItem
                  onChange={onChange}
                  key={index}
                  filterLength={filters.length - 1}
                  filter={filter}
                  index={index}
                  disabled={disabled}
                ></FilterInputItem>
              );
            })}
          </div>
          {deleteAll ? <div className={styles.BtnDiv}>
            <DeleteOutlined onClick={()=> clearFilters()} className={styles.GarbageIcon}/>
          </div> : null}
          {props?.tooltip ?? null}
        </div> :
          <div className={styles.actions} style={{display: 'flex'}} >
            {mainFilters.length > 0 && (!onlySorters || onlyFilters) &&
            <Filters
              onFilter={props.onFilter}
              onChange={onChange}
              mainFilters={mainFilters}
              filtersLength={getFiltersLength()}
              openFilters={openFilters}
              openFiltersContainer={openFiltersContainer}
              horizontalFiltersList={props.openFilterContainerByDefault}
              showCheckboxIcons ={props.showCheckboxIcons}
              isIssuePage={props?.isIssuePage}
              drawerState={props?.drawerState}
            />
            }
            {props?.krtAddKwButton ? <Button size={'sm'} className={styles.sortByButton} style={'light-transparent'} onClick={() => {
              if (typeof props?.krtAddKwCallback == 'function') {
                props?.krtAddKwCallback();
              }
            }}>+ Add keywords {props?.krtAddKwBtnLoad ? <Spin indicator={antIcon}/> : null}</Button> : null}
            {(!onlyFilters || onlySorters) && !!quickSortMenu() && <div className={styles.BtnDivSorter}>
              <div ref={sortRef}>
                <Button
                  size={'sm'}
                  className={styles.sortByButton}
                  onClick={handleSort}
                  style={'light-transparent'}><NextImg style={{marginRight: '5px'}} src='/icons/up-down.svg' height={14} width={14} />{t('sort-by')}</Button>

                {quickSortOpen ? quickSortMenu() : null}
              </div>
            </div>}
            {renderManageColumn()}
          </div>
        }


      </div>
      <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '0px', minHeight: openFiltersContainer ? '60px':'0', paddingLeft: 10}}>
        {
          openFiltersContainer && (
            <div className={styles.filtersList}>
              {props.openFilterContainerByDefault ? <>
                <div>{props?.dateAndCountryPicker}</div>
                {mainFilters?.length && allFiltersNewList?.length && uniqByKeepFirst(allFiltersNewList, it => it?.id)?.map(item => {
                  return <Popover key={`popupId_${item?.id}`} onVisibleChange={open => {
                    setOpenFilterPopup(open);
                    if (!open) {
                      setSelectedId('');
                    }
                  }} overlayClassName={styles.OptionContainer} trigger={['click']} placement='bottomLeft' visible={openFilterPopup && item.id === selectedId} content={
                    openFilterPopup && <div>{
                      (selectedFilter?.includes('exclude') && item.name.includes('exclude')) ? (
                        <FilterKeywordPopUp
                          filtersLength={getFiltersLength()}
                          filterId={item.id}
                          // absoluteUi={true}
                          onChange={onChange}
                          onClose={()=>setOpenFilterPopup(false)}
                          filterLabel={item.header}
                          filterName={item.name}
                          customFields={item?.customFields}
                          className={styles.popupClasses}
                          filterField={item?.filterField}
                        />) : (
                        ( item.name !== 'exclude' && selectedFilter === item.name) && (
                          item?.name == 'checkboxes' && typeof item?.filterTypes !== 'undefined' ? <FilterMultipleCheckboxPopUp
                            categories={item?.filterTypes}
                            options={makeOptions(item?.filterTypes)}
                            // absoluteUi={true}
                            add={false}
                            checkedValues={item?.category}
                            className={styles.checkBoxFiltersStyled}
                            onChange={onChange}
                            onClose={() => setOpenFilterPopup(false)}
                            filterId={item.id}
                            filterLabel={item.header}
                            filterName={item.name}
                          /> : item?.name == 'radioButtonsFilter' ? <FilterMultipleRadioPopUp
                            options={item?.filterTypes}
                            className={styles.checkBoxFiltersStyled}
                            onChange={onChange}
                            onClose={() => setOpenFilterPopup(false)}
                            filterId={item.id}
                            // absoluteUi={true}
                            filterLabel={item.header}
                            filterName={item.name}
                            key={item.header}
                          /> : <FilterPopUp
                            filtersLength={getFiltersLength()}
                            // absoluteUi={true}
                            removeItem={()=> {
                              handleDelete(item.header, item?.id);
                            }}
                            type={item.type}
                            initialFrom={item.from}
                            initialTo={item.to}
                            onChange={onChange}
                            filterId={item?.id}
                            onClose={()=>{
                              setOpenFilterPopup(false);
                              // handleDelete(item.name, item?.id);
                              // if (props.onFilter) {
                              //   const onFilter = props.onFilter;
                              //   onFilter('', '');
                              // }
                            }}
                            filterLabel={item.header}
                            filterName={item.name}
                            className={styles.popupClasses}
                          />)
                      )
                    }</div>
                  }>
                    <div
                      className={styles.filterTag} key={item.id} onClick={()=> handleClick(item.name, item.id)}>
                      <div
                        style={{display: 'flex', justifyContent: 'center'}}>
                        <strong style={{marginRight: '4px'}}>{`${item.header} `}</strong>
                        {item.name == 'options' ? <span>{item.type}</span> :
                          <span>
                            {` `}
                            {item.type === 'higher' && 'greater than '}
                            {item.type === 'lower' && 'less than '}
                            {item.type === 'between' && 'between '}
                            {` `}

                            <strong style={{marginLeft: '4px'}}>
                              {item.type === 'higher' && numeral(item.from).format('0.[0][a]').toUpperCase()}
                              {item.type === 'lower' && numeral(item.to).format('0.[0][a]').toUpperCase()}
                              {item.type === 'between' && `${numeral(item.from).format('0.[0][a]').toUpperCase()} and ${numeral(item.to).format('0.[0][a]').toUpperCase()}`}
                            </strong>
                          </span>
                        }
                        {item.text ? notKeywordTerm :''}
                      </div>
                      {item?.active ? <div
                        onClick={e=>{
                          e.stopPropagation();
                          handleDelete(item.name, item?.id);
                          // if (props.onFilter) {
                          //   const onFilter = props.onFilter;
                          //   onFilter('', '');
                          // }
                        }}
                        style={{marginLeft: '12px'}}>
                        <NextImg src='/img/icon/delete-icon.svg' />
                      </div> : <div style={{marginLeft: '12px'}} onClick={e => e.preventDefault()}>
                        <FontAwesomeIcon icon={faCaretDown} />
                      </div>}
                    </div>
                  </Popover>;
                })}

              </> : <>
                <div>{props?.dateAndCountryPicker}</div>

                {mainFilters.some(item => item.active ) && activeFilters().map(item => {
                  return <Popover key={`popup_${item?.id}`} onVisibleChange={open => {
                    setOpenFilterPopup(open);
                    if (!open) {
                      setSelectedId('');
                    }
                  }} trigger={['click']} overlayClassName={styles.OptionContainer} placement='bottomLeft' content={
                    openFilterPopup && <div>{
                      (selectedFilter?.includes('exclude') && item.name.includes('exclude')) && item.id === selectedId ? (
                        <FilterKeywordPopUp
                          filtersLength={getFiltersLength()}
                          filterId={item.id}
                          onChange={onChange}
                          onClose={()=>setOpenFilterPopup(false)}
                          filterLabel={item.header}
                          customFields={item?.customFields}
                          filterName={item.name}
                          className={styles.popupClasses}
                          filterField={item?.filterField}
                        />) : (
                        ( item.name !== 'exclude' && selectedFilter === item.name) && (
                          <FilterPopUp
                            filtersLength={getFiltersLength()}
                            removeItem={()=> {
                              handleDelete(item.header, item?.id);
                            }}
                            type={item.type}
                            initialFrom={item.from}
                            initialTo={item.to}
                            onChange={onChange}
                            onClose={()=>{
                              setOpenFilterPopup(false);
                              handleDelete(item.name, item?.id);
                              if (props.onFilter) {
                                const onFilter = props.onFilter;
                                onFilter('', '');
                              }
                            }}
                            filterLabel={item.header}
                            filterName={item.name}
                            className={styles.popupClasses}
                          />)
                      )
                    }</div>
                  }>
                    <div
                      className={styles.filterTag} key={item.id}>
                      <div
                        onClick={()=> handleClick(item.name, item.id)}
                        style={{display: 'flex', justifyContent: 'center'}}>
                        <strong style={{marginRight: '4px'}}>{`${item.header}${item.name == 'checkboxes' ? ':' : ''} `}</strong>
                        {item.name == 'options' ? <span>{item.type}</span> : item.name == 'checkboxes' ?
                          <div>{item.category.join(', ')}</div> :
                          <span>
                            {` `}
                            {item.type === 'higher' && 'greater than '}
                            {item.type === 'lower' && 'less than '}
                            {item.type === 'between' && 'between '}
                            {item.type === 'equal' && 'equal '}
                            {` `}

                            <strong style={{marginLeft: '4px'}}>
                              {item.type === 'higher' && numeral(item.from).format('0.[0][a]').toUpperCase()}
                              {item.type === 'lower' && numeral(item.to).format('0.[0][a]').toUpperCase()}
                              {item.type === 'equal' && numeral(item.equal).format('0.[0][a]').toUpperCase()}
                              {item.type === 'between' && `${numeral(item.from).format('0.[0][a]').toUpperCase()} and ${numeral(item.to).format('0.[0][a]').toUpperCase()}`}
                            </strong>
                          </span>
                        }
                        {item.text ? notKeywordTerm :''}
                      </div>
                      <div
                        onClick={()=>{
                          handleDelete(item.name, item?.id);
                          if (props.onFilter) {
                            const onFilter = props.onFilter;
                            onFilter('', '');
                          }
                        }}
                        style={{marginLeft: '12px'}}>
                        <NextImg src='/img/icon/delete-icon.svg' />
                      </div>
                    </div>
                  </Popover>;
                })}
              </>}
              { activeFilters().length >=1 || props.extraFilters?.length > 0 ? (
                <Filters
                  onFilter={props.onFilter}
                  filtersLength={getFiltersLength()}
                  isIssuePage={props?.isIssuePage}
                  mainFilters={mainFilters?.filter(z => !props?.openFilterContainerByDefaultIds?.includes(z?.id))}
                  add
                  onChange={onChange}
                />
              ) : ''}
            </div>
          )
        }
        {openFiltersContainer && !showDelete && activeFilters().length >= 3 &&
              <div
                onClick={handleDeleteAll}
                style={{cursor: 'pointer'}}>
                <NextImg src='/img/icon/trash.svg' />
              </div>}
      </div>
    </>
  );
};

export default FilterInput;
