import {Tooltip, Spin, Dropdown, Menu} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes, faRotate, faTrash, faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {faPen, faSortDown, faTrashXmark} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {openUrl} from '@/utils/router';
import {CloseButton, DescriptionWrapper, MissingDescriptionWrapper, PaginationStyled, StatusDropdown, StyledInput, StyledIssuesTable, StyledTrashIcon, StylesScheduledFor} from '../../../../style';
import {Button} from '@/components/common-components';
import {notification as notificationV2} from '@/utils/notification-v2';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {debounce} from 'lodash';
import moment from 'moment';
import {qAndAStatusStyles} from '../../../../Constants';
import {DeleteConfirmationModal} from '@/components/common-components/components/delete-confirmation-modal';
import {timeNotification} from '@/utils/notification-timer';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';

interface Props {
  componentIssueType?: string;
  setPageChanged?: (value: boolean) => void;
  setIssueTable?: (value: string) => void;
  searchText?: string | null;
  standaloneTool?: boolean;
}

export const GbpQAndAnswersTable = observer(({componentIssueType, setPageChanged, setIssueTable, searchText, standaloneTool}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    deployingProposedFix,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    ottoSearchTerm,
    selectedIssue,
    generatingAiProposedFix,
    unPublishGPBQuestion,
    setDeployingProposedFix,
    updateGBPQuestion,
    publishGPBQuestion,
    updateGBPOwnersAnswer,
    aiEnhanceGPBQuestion,
    aiGenerateGPBOwnersAnswer,
    setGeneratingAiProposedFix,
    ownersAnswerAiGenerate,
    deleteQASuggestion,
    selectedLocation,
    activeQuestionsTab,
  }, settings: {customer: {getCustomerQuota, profile: {whitelabelOtto}}}} = useStore('');
  const uuid = getSingleUrlParam('uuid');

  const [ottoUrls, setOttoUrls] = useState([]);
  const [urlId, setUrlId] = useState<any>(-1);
  const [editDescription, setEditDescription] = useState<any>(-1);
  const [proposedFix, setProposedFix] = useState('');
  const [proposedFixId, setProposedFixId] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [deleteItem, setDeleteItem] = useState<any>(-1);
  const [pageSize, setPageSize] = useState(!standaloneTool ? getOttoTablePageSize(componentIssueType) : 10);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    setTableData(filteredData);
  }, [ottoUrls]);

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  useEffect(() => {
    if (filteredData && deleteItem == -1) {
      setTableData(filteredData);
    } else {
      const newData = filteredData.filter(val=> val.id !== deleteItem);
      setTableData(newData);
    }
  }, [deleteItem]);


  const loadingIdForReview = (issueType, type, id) => {
    if (type==='answer') {
      return `${id}-answer`;
    } else {
      return `${id}-question`;
    }
  };

  const generateNewProposedFix = async (id, issueType, record, type = '') => {
    const fixId = id;
    try {
      setProposedFixId(prev => [...prev, fixId]);
      if (type === 'answer') {
        const payload = {content: record?.ownersAnswer?.content};
        record?.ownersAnswer ? await aiGenerateGPBOwnersAnswer(record.id, payload, record?.ownersAnswer?.status === 'Published' ? true : false) : await ownersAnswerAiGenerate(record?.id, true);
      } else {
        await aiEnhanceGPBQuestion(record.id, {
          content: record?.content,
          owners_answer: record?.ownersAnswer,
        }, record?.ownersAnswer?.status === 'Published' ? true : false);
      }
      if (record?.ownersAnswer?.status === 'Published') {
        await publishGPBQuestion(record?.id, record);
        setGeneratingAiProposedFix(false);
      }
      getCustomerQuota();
      if (standaloneTool) await loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, url?.page, url?.pageSize, true, searchText);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setProposedFixId(prev => prev.filter(id => id != fixId));
    }
  };

  // const generateTooltip = (issueType, type='') => {
  //   if (issueType === 'qanda_suggestions' && type) {
  //     return `Enhance ${type} with AI`;
  //   } else {
  //     return `AI Generate a new one`;
  //   }
  // };

  const descriptionContainer = (record, type = '') => {
    return (
      <DescriptionWrapper style={{width: 400, fontWeight: type=='question'? 600: 400}}>
        {record?.recommendedValue}
        {
          record?.recommendedValue ?
            <>
              {
                (type === 'question' && record?.createdBy === 'User') ? null :
                  <span className='icons-wrapper'>
                    <FreezeWrapper removeTooltip={record?.ownersAnswer?.status === 'Published' || standaloneTool}>
                      <Tooltip
                        overlayInnerStyle={{background: '#121212'}} title={'Edit'}><FontAwesomeIcon style={{cursor: (urlId !== -1) ? 'not-allowed' : 'pointer'}} icon={faPen} fontSize={12} color='#4E5156' onClick={() => {
                          if (urlId !== -1) {
                            return;
                          }
                          if (type === 'question') {
                            setEditDescription(`question-${record?.id}`);
                          } else {
                            setEditDescription(`answer-${record?.ownersAnswer?.questionId}`);
                          }
                          setProposedFix(record?.recommendedValue);
                        }} />
                      </Tooltip>
                    </FreezeWrapper>
                    <FreezeWrapper removeTooltip={standaloneTool}>
                      <Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                        <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' className={generatingAiProposedFix && proposedFixId.includes(loadingIdForReview(record?.issueType, type, record?.id)) ? 'fa-spin' : ''} onClick={() => {
                          if (!proposedFixId.includes(loadingIdForReview(record?.issueType, type, record?.id))) generateNewProposedFix(loadingIdForReview(record?.issueType, type, record?.id), record?.issueType, record, type);
                        }} /></Tooltip>
                    </FreezeWrapper>
                  </span>
              }
            </> :
            <span className='icons-wrapper' style={{display: 'flex', gap: 10}}>
              <FreezeWrapper removeTooltip={record?.ownersAnswer?.status === 'Published' || standaloneTool}>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}>
                  <div className='content-wrapper' onClick={() => {
                    if (type === 'question') {
                      setEditDescription(`question-${record?.id}`);
                    } else {
                      setEditDescription(`answer-${record?.id}`);
                    }
                    setProposedFix(record?.recommendedValue);
                  }}>
                    <FontAwesomeIcon icon={faPen} fontSize={12} color='#4E5156' />Write
                  </div>
                </Tooltip>
              </FreezeWrapper>
              {<FreezeWrapper removeTooltip={standaloneTool}>
                <Tooltip title={<><span style={{fontWeight: '600', fontSize: 12}}>AI Generate a new suggestion.</span><br></br><span style={{fontSize: 12}}>Cost: 1 AI Generation Point</span></>} placement='bottom' overlayInnerStyle={{borderRadius: '6px', background: 'black'}}>
                  <div className='content-wrapper' onClick={() => {
                    if (!proposedFixId.includes(loadingIdForReview(record?.issueType, type, record?.id))) generateNewProposedFix(loadingIdForReview(record?.issueType, type, record?.id), record?.issueType, record, type);
                  }}>
                    <FontAwesomeIcon icon={faRotate} fontSize={12} color='#4E5156' className={generatingAiProposedFix && proposedFixId.includes(loadingIdForReview(record?.issueType, type, record?.id)) ? 'fa-spin' : ''} />
                  AI Generate
                  </div>
                </Tooltip></FreezeWrapper>}
            </span>
        }
      </DescriptionWrapper>
    );
  };

  const onChangeGBPQuestion = async (id, content, record, issueType) => {
    try {
      const data = {
        content: content,
        owners_answer: record.ownersAnswer,
      };
      await updateGBPQuestion(id, data, record?.ownersAnswer?.status === 'Published' ? true : false);
      if (record?.ownersAnswer?.status === 'Published') {
        await publishGPBQuestion(record?.id, record);
      }
      notificationV2.success('Success', 'Question updated successfully');
      setIssueTypeSelected(issueType);
      setDeployingProposedFix(false);
      setEditDescription(-1);
      if (standaloneTool) {
        loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, url?.page, url?.pageSize, true, searchText);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setEditDescription(-1);
      setUrlId(-1);
    }
  };

  const onChangeGBPOwnersAnswer = async (record, content, issueType) => {
    try {
      const data = {
        content: content,
      };
      await updateGBPOwnersAnswer(record?.id, data, record?.ownersStatus?.status === 'Published' ? true : false);
      if (record?.ownersAnswer?.status === 'Published') {
        await publishGPBQuestion(record?.id, record);
      }
      notificationV2.success('Success', 'Answer updated successfully');
      setIssueTypeSelected(issueType);
      setDeployingProposedFix(false);
      setEditDescription(-1);
      if (standaloneTool) {
        loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, url?.page, url?.pageSize, true, searchText);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setEditDescription(-1);
      setUrlId(-1);
    }
  };

  const inputDescriptionContainer = (record, type = '') => {
    return (
      <div style={{minWidth: 'auto'}}>
        <div style={{
          display: 'flex',
          gap: '10px',
          width: '100%',
          alignItems: 'center',
          flexWrap: 'wrap',
          minWidth: 150,
        }}>
          <StyledInput rows={6} value={proposedFix} onChange={e => setProposedFix(e.target.value)}/>
          <Button
            buttonType={'green'}
            loading={deployingProposedFix}
            disabled={!proposedFix}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              fontSize: '14px',
              padding: '0px 15px',
            }}
            onClick={() => {
              if (type === 'question') {
                onChangeGBPQuestion(record?.id, proposedFix, record, record?.issueType);
              } else {
                onChangeGBPOwnersAnswer(record, proposedFix, record?.issueType);
              }
            }}
          >
            {!deployingProposedFix ? 'Save' : ''}
          </Button>
          <CloseButton>
            <FontAwesomeIcon icon={faTimes} fontSize={20} color='white' onClick={() => {
              setEditDescription(-1);
              setProposedFix('');
            }} />
          </CloseButton>
        </div>
      </div>
    );
  };

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
        };
        // if (activeKey !== 'all') {
        //   params['status__in'] = getDeployStatusForGbp(activeKey, issueType);
        // }
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, [activeQuestionsTab]);

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, standaloneTool ? ottoSearchTerm : searchText);
  };

  useEffect(() => {
    if (standaloneTool && issueTypeArray?.length && selectedIssue == 'gbp_audit' && selectedCategory == 'qanda_suggestions' && selectedLocation?.id) {
      loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, 1, 10, true, '');
    }
  }, [standaloneTool, issueTypeArray, selectedIssue, selectedCategory, JSON.stringify(selectedLocation)]);

  const publishGBPQues = async (id, issueType, payload) => {
    setUrlId(id);
    try {
      if (payload?.ownersAnswer?.status === 'Published') {
        await unPublishGPBQuestion(payload?.id, payload);
      } else {
        await publishGPBQuestion(payload?.id, payload);
      }
      if (standaloneTool) {
        loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, 1, 10, true, '');
      }
      if (payload?.ownersAnswer?.status === 'Published') {
        notification.success('Question unpublished', '', 2);
      } else {
        notification.success('Question published', '', 2);
      }
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
    setUrlId(-1);
  };

  const onDeleteQASuggestion = async record => {
    try {
      setDeleteItem(record.id);
      const onDelayEnd = async () => {
        await deleteQASuggestion(record?.id);
        const filter = ottoUrls.filter(d => d.issueType === 'qanda_suggestions');
        await loadIssueTableData({
          uuid: getOttoV2Project?.uuid,
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          issue_type: 'qanda_suggestions',
          otto_project: getOttoV2Project?.id,
          search: searchText,
        });
        setDeleteItem(-1);
      };
      const handleUndo = () => {
        setDeleteItem(-1);
      };
      timeNotification('#FF0000', faTrashXmark, 4, `Item deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
    } catch (error) {
      setDeleteItem(-1);
      return Promise.reject(error);
    }
  };

  const onDelete = async () => {
    try {
      await onDeleteQASuggestion(currentRecord);
      setOpenModal(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setDeleteItem(-1);
      console.error('Error: ', error);
    }
  };

  const columns = [
    {...(standaloneTool ? {
      title: <div className='column-title'>BUSINESS</div>,
      dataIndex: 'business',
      key: 'business',
      fixed: 'left' as 'left',
      render: () => {
        return <div style={{width: '300px'}}>
          <div style={{width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 600, color: '#121212'}}>
            {selectedLocation?.businessName}
          </div>
          <div style={{width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', color: '#4E5156'}}>
            <Tooltip title={selectedLocation?.businessAddress}>
              {selectedLocation?.businessAddress}
            </Tooltip>
          </div>
        </div>;
      },
    } : [])},
    {
      title: <div className='column-title'>QUESTION</div>,
      dataIndex: 'content',
      key: 'content_qa',
      fixed: 'left' as 'left',
      render: (_, record) => {
        return (
          record?.content ? (
            <>
              {
                editDescription !== `question-${record?.id}` ?
                  <>
                    {
                      descriptionContainer({...record, recommendedValue: record?.content}, 'question')
                    }
                  </> :
                  <>
                    {
                      inputDescriptionContainer({...record, recommendedValue: record?.content}, 'question')
                    }
                  </>
              }</>
          ) : (
            <MissingDescriptionWrapper>Missing</MissingDescriptionWrapper>
          )
        );
      },
    },
    {
      title: <div className='column-title'>ANSWER</div>,
      dataIndex: 'ownersAnswer',
      key: 'owners_answer',
      render: (_, record) => {
        return (
          editDescription !== `answer-${record?.ownersAnswer?.questionId}` && editDescription !== `answer-${record?.id}` ?
            <>
              {
                descriptionContainer({...record, recommendedValue: record?.ownersAnswer?.content}, 'answer')
              }
            </> :
            <>
              {
                inputDescriptionContainer({...record, recommendedValue: record?.ownersAnswer?.content}, 'answer')
              }
            </>
        );
      },
    },
    {
      title: <div className='column-title' style={{whiteSpace: 'nowrap'}}>CREATED BY</div>,
      dataIndex: 'createdBy',
      key: 'user',
      render: (_, record) => {
        const updatedCreatedBy = record?.createdBy?.includes('OTTO') ? record?.createdBy.replace('OTTO', whitelabelOtto) : record?.createdBy;
        return (
          record?.createdBy ? (
            <DescriptionWrapper>
              {updatedCreatedBy}
            </DescriptionWrapper>
          ) : (
            <MissingDescriptionWrapper>Missing</MissingDescriptionWrapper>
          )
        );
      },
    },
    {
      title: <div className='column-title' style={{whiteSpace: 'nowrap'}}>DATE</div>,
      dataIndex: 'scheduled',
      key: 'scheduled_date',
      width: 198,
      render: (_, record) => {
        return <>
          <div style={{width: '196px', position: 'relative'}}>
            <StylesScheduledFor style={{display: 'flex', alignItems: 'center'}}>
              {
                record?.ownersAnswer?.status === 'Scheduled' ?
                  <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Scheduled for&nbsp;{moment(record?.ownersAnswer?.schedulePublishingAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.ownersAnswer?.status === 'Unpublished' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Unpublished on&nbsp;{moment(record?.ownersAnswer?.unpublishedAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.ownersAnswer?.status === 'Publishing' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Published on&nbsp;{moment(record?.ownersAnswer?.publishedAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.ownersAnswer?.status === 'Pending Review' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Created on&nbsp;{moment(record?.ownersAnswer?.createdAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.ownersAnswer?.status === 'Published' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Published on&nbsp;{moment(record?.ownersAnswer?.publishedAt).format('DD MMM, YYYY')}</div>
                    {
                      (record?.searchUrl && record?.ownersAnswer?.status === 'Published') ?
                        <p
                          onClick={() => openUrl(record?.searchUrl, '_blank')}
                          style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            color: '#2D6CCA',
                            cursor: 'pointer',
                          }}
                        >View&nbsp;
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={'#2D6CCA'} fontSize={12} />
                        </p> : <></>
                    }
                  </div> : <></>
              }
            </StylesScheduledFor>
          </div>
        </>;
      },
    },
    {
      title: (<div className='column-title'>
        <span>STATUS</span>
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (_, record, index) => {
        const defaultStyle = {backgroundColor: '', textColor: '', icon: null, label: ''};
        const {backgroundColor, textColor, icon, label} = qAndAStatusStyles[record?.ownersAnswer?.status || 'Unpublished'] || defaultStyle;
        return (
          <FreezeWrapper removeTooltip={record?.ownersAnswer?.status === 'Published' || standaloneTool}>
            <Dropdown
              trigger={['click']}
              disabled={!record?.ownersAnswer?.content}
              overlay={
                <Menu style={{borderRadius: 8}}>
                  <Menu.Item style={{textAlign: 'center', padding: 0}} onClick={()=>{
                    publishGBPQues(index, record?.issueType, record);
                  // scheduledAndDraftPost(record?.id, record?.imageUrl, record?.schedulePublishingAt, record?.content, record?.issueType, record?.status);
                  }}>
                    <StatusDropdown backgroundColor='' textColor={qAndAStatusStyles[record?.ownersAnswer?.status === 'Published' ? 'Unpublished': 'Published']?.textColor} style={{width: '100%'}}>
                      <FontAwesomeIcon icon={qAndAStatusStyles[record?.ownersAnswer?.status === 'Published' ? 'Unpublished': 'Published']?.icon} color={qAndAStatusStyles[record?.ownersAnswer?.status === 'Published' ? 'Unpublished': 'Published']?.textColor} fontSize={14} style={{marginRight: '5px'}}/>
                      {record?.ownersAnswer?.status === 'Published' ? 'Unpublish' : 'Publish'}
                    </StatusDropdown>
                  </Menu.Item>
                </Menu>
              }
            >
              <Tooltip title={!record?.ownersAnswer?.content ? 'Please write an answer first' : ''}>
                <StatusDropdown backgroundColor={backgroundColor} textColor={textColor}>
                  <div className='status-text'>
                    {icon && (
                      <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: (!record?.ownersAnswer?.content) ? 'not-allowed' : 'pointer'}}>
                        {
                          urlId === index ? <Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} />:
                            <FontAwesomeIcon icon={icon} color={textColor} fontSize={14} />
                        }
                        {label}
                        <FontAwesomeIcon icon={faSortDown} fontSize={12} color={textColor} />
                      </div>
                    )}
                  </div>
                </StatusDropdown>
              </Tooltip>
            </Dropdown>
          </FreezeWrapper>
        );
      },
    },
    {
      title: '',
      dataIndex: 'trashIcon',
      key: 'trashIcon',
      render: (_, record) => {
        return (
          <Tooltip title={record?.createdBy === 'User' ? 'User question can not be deleted' : deleteItem === record?.itemIndex ? 'Deleting' : 'Delete'}>
            <FreezeWrapper placement='topRight' removeTooltip={record?.ownersAnswer?.status === 'Published' || standaloneTool}>
              {
                deleteItem === record?.itemIndex ?
                  <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                  <StyledTrashIcon icon={faTrash} style={{cursor: (record?.createdBy === 'User') ? 'not-allowed': 'pointer'}} onClick={()=> {
                    if (record?.createdBy !== 'User') {
                      setCurrentRecord(record);
                      setOpenModal(true);
                    }
                  }} fontSize={14} color='#A3A4A4'/>
              }
            </FreezeWrapper>
          </Tooltip>
        );
      },
    },

  ];

  return (<>
    <StyledIssuesTable
      loading={ottoUrlLoader && ottoIssueType === componentIssueType}
      columns={columns}
      dataSource={tableData}
      pagination={false}
      scroll={{x: 'auto'}}
    />
    <PaginationStyled
      onChange={(page, pageSize) => {
        if (!standaloneTool) {
          setPageChanged(true);
          setIssueTable(url?.issueType);
        }
        !standaloneTool && saveOttoTablePageSize(componentIssueType, pageSize);
        setPageSize(pageSize);
        handlePaginationChange([url?.issueType], page, pageSize);
      }}
      total={url?.issueTable?.count}
      pageSize={pageSize}
      current={url?.page ?? 1}
      showSizeChanger
      pageSizeOptions={['5', '10', '20', '50', '100']}
    />
    <DeleteConfirmationModal openModal={openModal} setOpenModal={setOpenModal} onDelete={onDelete} setDeleteItem={setDeleteItem} deleteItem={deleteItem} />
  </>
  );
});
