import React, {useRef, useEffect} from 'react';
import ReactEcharts from 'echarts-for-react';
import {EChartOption} from 'echarts';

export interface VerticalBarChartProps {
  series: Array<any>;
  tooltipFormatter?: string | EChartOption.Tooltip.Formatter;
  xAxis: EChartOption.XAxis;
  yAxis: EChartOption.YAxis;
  grid?: EChartOption.Grid;
  emojiData?: Array<any>;
  emojiDataWithXPositionsCallback?: (positions: any) => void;
  showLegend?: boolean;
  style?: any;
  showTooltip?: boolean;
  legendTopOffset?: string;
  isDiagnosticOpen?: boolean;
  color?: string[];
  chartClickCallback?: (event: any) => void;
}

export const VerticalBarChart: React.FC<VerticalBarChartProps> = ({
  xAxis,
  yAxis = {type: 'value', show: false},
  series = [],
  tooltipFormatter,
  grid = {},
  emojiData,
  emojiDataWithXPositionsCallback,
  showLegend = true,
  style,
  showTooltip = true,
  legendTopOffset = 'top',
  isDiagnosticOpen = false,
  color,
  chartClickCallback,
}) => {
  const echartsRef = useRef(null) as any;
  const defaultGrid = {
    left: '2%',
    right: '1%',
    bottom: '8%',
    containLabel: false,
  };

  const getSeries = () => {
    return series.map((seriesItem: any) => {
      return {
        id: seriesItem.id,
        name: seriesItem.name,
        type: 'bar',
        barWidth: '40%',
        stack: 'stack',
        barGap: '2px',
        label: {
          show: false,
          position: 'insideRight',
        },
        data: seriesItem.data,
        markPoint: seriesItem.markPoint,
        itemStyle: {
          color: seriesItem.color,
        },
        emphasis: seriesItem.emphasis,
      };
    });
  };

  const getEmojiDataXPositions = () => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    const positions = echartsInstance?._chartsViews[0]?._data?._itemLayouts;
    const dataPoints = echartsInstance?._chartsViews[0]?._data?._idList;
    const emojiDataWithXPositions = emojiData?.map(emoji => {
      const date = emoji.occuredAt?.slice(0, 10);
      const index = dataPoints ? dataPoints.indexOf(date) : -1;
      return ({
        ...emoji,
        tags: emoji?.tags?.filter(item => item !== undefined && item !== ''),
        posX: index === -1 ? null : positions[index].x,
      });
    });
    emojiDataWithXPositionsCallback(emojiDataWithXPositions);
  };

  useEffect(() => {
    return () => window.removeEventListener('resize', () => getEmojiDataXPositions());
  }, []);

  useEffect(() => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    echartsInstance.on('finished', () => {
      if (emojiData.length) {
        getEmojiDataXPositions();
        window.addEventListener('resize', () => setTimeout(() => getEmojiDataXPositions(), 500));
      }
      echartsInstance.off('finished');
    });
    if (echartsInstance && chartClickCallback) {
      echartsInstance?.on('click', event => {
        chartClickCallback(event);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiData.length]);

  return (
    <ReactEcharts
      ref={echartsRef}
      option={{
        tooltip: {
          show: showTooltip,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          backgroundColor: '#222026',
          textStyle: {
            color: '#FFF',
          },
          formatter: tooltipFormatter,
        },
        color,
        grid: {...defaultGrid, ...grid},
        yAxis,
        xAxis,
        series: getSeries(),
        legend: {
          show: showLegend,
          orient: 'horizontal',
          top: legendTopOffset,
          right: 'top',
          type: 'scroll',
          pageIconColor: '#EDEDED',
          inactiveColor: '#484646',
          data: isDiagnosticOpen ? series.map(item => (
            {
              name: item.name,
              icon: 'none',
              textStyle: {
                color: '#FFFFFF',
                fontSize: '14px',
              },
            })) : series.map(item => ( {
            name: item.name,
            icon: 'circle',
            textStyle: {
              color: '#FFFFFF',
            },
          })),
          padding: [5, 10, 20, 0],
          itemHeight: 9,
          itemWidth: 12,
          itemGap: 50,
          textStyle: {
            fontSize: 12,
            fontFamily: 'Inter',
          },
        },
      } as EChartOption}
      notMerge={true}
      lazyUpdate={true}
      style={style}
    />
  );
};

export default VerticalBarChart;
