import React from 'react';
import styles from '../index.module.scss';
import {Button as V2Button} from '@/components/common-components/v2';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'antd/lib/modal/Modal';
import {
  faPlug,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {getLocation} from '@/utils/string';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {openLiveChat} from '@/utils/intercom';
/*
* Wordpress Instructions modal - opens when user doesn't have wordpress plugin installed
*/
export const WpInstructionsModal = observer(() => {
  const {contentOptimizer: {
    wpPublish: {
      showInstructionsModal,
      setShowInstructionsModal,
    },
  }} = useStore('');
  const {settings: {customer: {profile: {isWhitelabel}}}}= useStore('');


  return <Modal
    centered
    visible={showInstructionsModal}
    closable={true}
    className={styles.noWpSitesModal}
    closeIcon={<FontAwesomeIcon icon={faXmark} onClick={() => setShowInstructionsModal(false)}/>}
    footer={null}
  >
    <PlugIconWrapper>
      <FontAwesomeIcon style={{fontSize: 24, color: '#fff', marginTop: '13px'}} icon={faPlug} />
    </PlugIconWrapper>
    <ModalTitle>1-Click Publish to WordPress</ModalTitle>
    <ModalDesc>{`Install the ${isWhitelabel ? getLocation(location) : 'LinkGraph'} plugin to your WordPress site and Start Publishing content directly from the Content Genius editor.`}</ModalDesc>
    <div style={{marginTop: 40, marginBottom: 25}}>
      <iframe
        width='100%'
        height='240px'
        src='https://www.youtube.com/embed/71vTcFMkphw'
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        style={{borderRadius: '8px'}}>
      </iframe>
    </div>
    <ButtonNote>Click the button below to install the WordPress Plugin:</ButtonNote>
    <div style={{textAlign: 'center'}}>
      <StyledPluginButton
        variant='solid'
        color='green'
        onClick={() => window.open('https://wordpress.org/plugins/metasync/', '_blank')}
      >Install Plugin<span><FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize={14}/></span>
      </StyledPluginButton>
    </div>
    <div style={{marginTop: 20, textAlign: 'center', color: '#e8e8e8', fontSize: 12, fontWeight: 400}}>Need help installing the plugin?</div>
    <div style={{textDecoration: 'underline', cursor: 'pointer', textAlign: 'center', color: '#34aef3', marginBottom: 20, fontWeight: 500}} onClick={() => {
      openLiveChat();
      setShowInstructionsModal(false);
    }}>Contact Support</div>
  </Modal>;
});

const PlugIconWrapper = styled.div<{blueIcon?: boolean}>`
  background-color: ${p => p.blueIcon ? '#375da7' : 'rgba(255, 255, 255, 0.15)'};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  text-align: center;
  margin: 30px auto 20px auto;
`;
const ModalTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;
const ModalDesc = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;
const ButtonNote = styled.div`
  text-align: center;
  color: #e8e8e8;
  margin-bottom: 15px;
`;
const StyledPluginButton = styled(V2Button)`
  margin: 0px;
  width: 100%;
  padding: 14px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
