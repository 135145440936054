import {openUrl} from '@/utils/router';
import {Button} from '@/components/common-components/components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {notification} from '@/utils/notification-v2';
import {faTable} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {Tooltip} from 'antd';

export const ExportButton = ({modalStyle, topicalMap, disabled = false, noGoogleSpreadsheetUrl = false}) => {
  const onExportTopicMap = () => {
    if (!noGoogleSpreadsheetUrl) {
      if (topicalMap && topicalMap?.googleSpreadsheetUrl) {
        openUrl(topicalMap?.googleSpreadsheetUrl, '_blank');
      } else if (topicalMap && !topicalMap?.googleSpreadsheetUrl) {
        notification.info('', 'Topical map data not available', '', '', 4);
      }
    }
  };

  return (
    <Tooltip title={disabled ? 'No idea available for export Topic Map' : noGoogleSpreadsheetUrl ? 'Google spreadsheet link not available' : ''}>
      <div style={{cursor: disabled || noGoogleSpreadsheetUrl ? 'not-allowed': ''}}>
        <StyledButton disabled={disabled || noGoogleSpreadsheetUrl} buttonType='transparent-with-border' className={modalStyle} onClick={onExportTopicMap}>
          <FontAwesomeIcon icon={faTable} fontSize={14} style={{marginRight: '6px'}}/>Export Topic Map
        </StyledButton>
      </div>
    </Tooltip>
  );
};

const StyledButton = styled(Button)`
  &.ottoModalTable {
    color: #121212;
    border: 1px solid #CDCDCD; 
    padding: 2px 10px; 
    border-radius: 6px;
  }
  &.ottoModalChart {
    color: #121212;
    border: 1px solid #CDCDCD; 
    padding: 4px 10px; 
    border-radius: 6px;
  }
  &.caTopicalMap {
    color: #ffffff;
    border: none; 
    background-color: #4E5156B2; 
    padding: 0px 10px; 
    border-radius: 6px; 
    line-height: 28px;
  }
`;
