import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {ConversationHeader} from './sections/header';
import {ThreadSection} from './sections/threadSection';
import {Divider} from 'antd';
import {EditorState} from 'draft-js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {StyledModal} from './styledComponents';

export const EmailConversationModal = observer(() => {
  const {inbox: {
    conversationModalVisible,
    setConversationModalVisible}} = useStore('');

  const {campaigns: {setCurrentEditorState}} = useStore('');

  const closeModal = () => {
    setCurrentEditorState(EditorState.createEmpty());
    setConversationModalVisible(false);
  };

  return <StyledModal
    width={1240}
    mask={true}
    style={{marginTop: '10px'}}
    visible={conversationModalVisible}
    onClose={closeModal}
    background='#F9F9FB'
    closeIcon={<div><FontAwesomeIcon color={'#A3A4A4'} fontSize={28} icon={faXmark}/></div>}
    zIndex={20}>
    <ConversationHeader />
    <Divider/>
    <ThreadSection/>
  </StyledModal>;
});
