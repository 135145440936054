import React, {useState, Fragment, useEffect} from 'react';
import styles from './styles.module.scss';
import {Empty, Input, Select} from 'antd';
import {Search} from 'react-feather';
import {useTranslation} from 'next-i18next';
import {SettingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {Button} from '../../v2/Button';
import classnames from 'classnames';

const {Option} = Select;

interface IOption {
  name: string;
  value: string;
  raw: any;
  search: boolean;
  settingIcon?: boolean;
  open?:boolean;
}
const Index = ({optionRender, renderInputButton, settingIcon = true, ...props}: any) => {
  const [options, setOptions] = useState(props.data || []);
  const [inactive, setInactive] = useState(false);
  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const {t} = useTranslation('common');
  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      setActive(true);
      setOptions(props.data.filter((option: IOption) => option.value.includes(e.target.value)));
    } else {
      setActive(false);
      setOptions(props.data?.slice());
    }
  };
  const {navBar: {setIsActivatePropertiesDrawerOpen, propertiesData, isDockedSider}} = useStore('');
  const {gsc: {pagesSearchTable, keywordSearchTable, keywordCannibalization}} = useStore('');

  const onOptionChange = (selectedOptionValue: string) => {
    clearAllFilterOnProjectChnage();
    keywordCannibalization.onProjectChange(selectedOptionValue);
    props.onOptionChange && props.onOptionChange(selectedOptionValue);
  };
  // clear all filter on project chnage
  const clearAllFilterOnProjectChnage = () => {
    pagesSearchTable.clearFilterList();
    keywordSearchTable.clearFilterList();
  };

  // TODO: Please review what this does.
  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);

  useEffect(() => {
    setSearchValue('');
  }, [props.open]);


  return (
    <><Select
      dropdownClassName={classnames(styles.PropertyDropDown, !isDockedSider ? styles.leftSpacing : '')}
      className={styles.SelectWithSearch}
      onChange={onOptionChange}
      {...props}
      dropdownRender={menu => (
        <Fragment>
          <div onClick={e=> e.stopPropagation()}
            className={styles.topDiv}>
            <Input className={styles.SearchField}
              placeholder={t('Search...')}
              value={searchValue}
              onChange={onSearchHandler}
              prefix={<Search width={15}/>}
              bordered={false}
            />
            {renderInputButton ? renderInputButton() : null}
            {settingIcon && <Button
              variant='ghost'
              textColor='white'
              background='rgba(78, 81, 86, 0.1)'
              className ={styles.settingBtn} onClick={()=> setIsActivatePropertiesDrawerOpen()}><SettingOutlined style={{marginRight: 6}} /> Manage Sites</Button>}
          </div>
          {menu}
          <div style={{color: '#fff', fontSize: 14, padding: 20, cursor: 'pointer', paddingTop: 15}} onClick={e => {
            e.stopPropagation();
            setInactive(!inactive);
          }}>
            {options?.filter(item=> item?.raw?.activeForCurrentCustomer==false).length > 0 && `${inactive ? 'Hide' : 'Show'} ${options?.filter(item=> item?.raw?.activeForCurrentCustomer==false).length} inactives`}</div>
        </Fragment>
      )}
    >
      {active ? <div key={'activeSites'} style={{color: '#fff', pointerEvents: 'none'}}>Active</div> : ''}
      {options?.length ? options?.filter(item=> item?.raw?.activeForCurrentCustomer).map((option: IOption, idx: number) => {
        const {name, value, raw} = option;
        return (
          <Option
            display={name}
            className={styles.Option}
            key={value}
            value={value}
            disabled={!propertiesData.filter(item => item.activeForCurrentCustomer).map(item => item.id).includes(raw.id)}
          >
            { optionRender(raw, idx)}
          </Option>
        );
      }):<Empty />}
      {inactive ? <div key={'inactiveSites'} style={{color: '#fff', pointerEvents: 'none'}}>Inactive</div> : ''}
      {inactive && options?.length ? options?.filter(item => !item?.raw?.activeForCurrentCustomer).map((option: IOption, idx: number) => {
        const {value, raw} = option;
        return (
          <Option
            className={styles.Option}
            key={value}
            value={value}
            disabled={!propertiesData.filter(item => item.activeForCurrentCustomer).map(item => item.id).includes(raw.id)}
          >
            {optionRender(raw, idx)}
          </Option>
        );
      }):<Empty />}
    </Select>
    </>
  );
};

export default Index;
