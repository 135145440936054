import styled from 'styled-components';
import {Button} from '@/components/common-components/v2/Button';

export const MatchCountWapper = styled.div<{background?: string; color?: string}>`
  display: flex;
  padding: 3px 10px 4px 6px;
  justify-content: center;
  align-items: center;
  width: max-content;
  gap: 5px;
  border-radius: 16px;
  background: ${p=> p?.background ? p?.background : 'rgba(31, 172, 71, 0.15)'};
  position: absolute;
  color: ${p=> p?.color ? p?.color : '#0C872F'};
  top: 6px;
  left: 300px;
  z-index: 1;
`;

export const ProjectDataBanner = styled.div<{background?: string}>`
  width: 100%;
  height: 32px;
  border-radius: 8px;
  background: ${p=> p?.background ? p?.background : '#E8E8E8'};
  display: flex;
  align-items: center;
  padding-left: 14px;
  margin: 3px 0 19px 0;
  gap: 8px;
`;

export const FlexWrapperV1 = styled.div<{align?: string; justifyContent?: string; flexDirection?: string}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};
  flex-direction: ${p=> p?.flexDirection};
`;

export const FlexWrapper = styled.div<{align?: string; justifyContent?: string; gap?: string}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};
  gap: ${p=> p?.gap ? p?.gap : '10px'};
  .dateRange{
    .ant-dropdown-trigger{
      div{
        div{
          width: 200px !important;
          justify-content: space-between;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .datePickerPopoverTriggerClass{
    height: 37px !important;
    width: 255px;
    background-color: white;
    color: #121212 !important;
    border: 1px solid rgba(232,232,232,1) !important;
  }
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #a3a4a4;
`;

export const TabContentWrapper = styled.div`
  display: flex;
  gap: 25px;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
  .config{
    background: #F9F9FB;
    border-radius: 8px;
    padding: 10px;
  }
`;

export const BannerStyled = styled.div<{background?: string}>`
  height: 80px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  background: ${p=> p?.background};
  display: flex;
  align-items: center;
  color: #fff !important;
  justify-content: space-between;
`;

export const IconStyled = styled.div`
  height: 54px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  margin: 13px 16px 13px 17px;
`;

export const WhiteCard = styled.div`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  padding: 10px;
`;

export const TextWhite = styled.div<{fontSize?: string}>`
  font-weight: 500;
  font-size: ${p=> p?.fontSize ? p?.fontSize : '16px'};
  line-height: 22px;
  color: #fff;
`;

export const TitleBlack = styled.div<{fontSize?: string}>`
  font-weight: 600;
  font-size: ${p=> p?.fontSize ? p?.fontSize : '32px'};
  line-height: 22px;
  color: #121212;
`;

export const TitleWhite = styled.div<{fontSize?: string}>`
  font-weight: 600;
  font-size: ${p=> p?.fontSize ? p?.fontSize : '20px'};
  line-height: 22px;
  color: #FFFFFF;
`;

export const GrayText = styled.div`
  color: #E8E8E8;
  margin-left: 12px;
  margin: 30px 0 -10px 0;
`;

export const ButtonStyled = styled(Button)<{metricsColor?: string}>`
  margin: 10px 0;
  border-color: ${p => p.metricsColor || '#7f4ead'} !important;
  background-color: ${p => p.metricsColor || '#7f4ead'} !important;
  :hover {
    border-color: ${p => p.metricsColor || '#7f4ead'} !important;
    background-color: ${p => p.metricsColor || '#7f4ead'} !important;
  }
  :focus {
    border-color: ${p => p.metricsColor || '#7f4ead'} !important;
    background-color: ${p => p.metricsColor || '#7f4ead'} !important;
  }
`;

export const LeftCardStyled = styled.div<{width: string}>`
  width: ${p => p.width};
  margin: 20px 0;
  padding: 20px;
  background: #F9F9FB;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
`;

export const MetricsCheckboxes = styled.div<{metricsColor?: string}>`
  margin-top: 10px;
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${p => p.metricsColor || '#7f4ead'} !important;
    border-radius: 3px;
    background-color: ${p => p.metricsColor || '#7f4ead'} !important;
  }
`;

export const Img = styled.img`
  width: 26x;
  height: 26px;
  border-radius: 5px;
`;

export const ImgStyled = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background: #F2F2F5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
`;

export const BlueText = styled.div`
  color: #2D6CCA;
  line-height: 17px;
  font-size: 14px;
  cursor: pointer;
`;

export const PositionWrapper = styled.div<{background}>`
 display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px 8px;
  gap: 6px;
  height: 32px;
  background: ${p=> p?.background};
  border-radius: 6px;
  width: max-content;
`;
