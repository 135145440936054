import {Tooltip} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {faRotate} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {debounce} from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import {QuotaTracker} from '@/components/common-components/v2/quotaTracker';
import {gbpPostInitialDateFilter, refreshKeyMapping, showAddButton, showRefreshButton, showToolTipTracker} from '../../../../Constants';
import {ComingSoonTag, RefreshWrapper, UrlFilterInput} from '../../../../style';
import {GBPPostFilters} from './gbpPostFilters';
import FreezeWrapper from '../../../freezTooltip';

interface DateRange {
  startDate: string;
  endDate: string;
}
interface PostCreatedAt {
  created: DateRange;
  scheduled: DateRange;
  published: DateRange;
  unpublished: DateRange;
  updated: DateRange;
  rejected: DateRange;
}
interface GBPPostTableHeaderProps {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: any;
  setSearchText: (value: string) => void;
  searchText: string;
  setIsActiveFilter: (value: string) => void;
  isActiveFilter: string;
  postCreatedAt: PostCreatedAt;
  setPostCreatedAt: React.Dispatch<React.SetStateAction<PostCreatedAt>>;
  selectedPostType: string;
  setSelectedPostType: React.Dispatch<React.SetStateAction<string>>;
  isResponsiveHeader?: boolean;
}

export const GBPPostTableHeader: React.FC<GBPPostTableHeaderProps> = observer(({
  componentIssueType,
  setPageChanged,
  setIssueTable,
  issueTable,
  setSearchText,
  searchText,
  setIsActiveFilter,
  isActiveFilter,
  postCreatedAt,
  setPostCreatedAt,
  selectedPostType,
  setSelectedPostType,
  isResponsiveHeader,
}) => {
  const {ottoV2Store: {
    getOttoUrls,
    setStatusKeyValue,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    selectedIssue,
    ottoSearchTerm,
    // reviewRatings,
    gbpLocationDetail,
    ottoBulkImportPostOrQuestion,
    setIssueTypeSelected,
    refreshingBulkGBPData,
    // setIsKnowledgeModalVisible,
  },
  settings: {
    customer: {
      profile: {
        quotaUtilization,
      },
    },
  },
  } = useStore('');
  const [appliedDateFilters, setAppliedDateFilters] = useState<string[]>([]);

  const refreshDateMapping = {
    'gbp_posts': gbpLocationDetail?.lastPostFetchAt ? moment(gbpLocationDetail?.lastPostFetchAt).fromNow() : null,
  };

  const uuid = getSingleUrlParam('uuid');
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2.map(i => {
    if (i?.group === 'indexing') {
      const subGroups = i.subGroups.map(value => {
        if (value?.group === 'gsc_instant_indexing') {
          return {...value, subGroups: [
            {
              group: 'sitemap_indexing',
              label: 'Sitemap Indexing',
              requiredIntegration: null,
              isComingSoon: false,
              isSitewide: false,
            },
            {
              group: 'custom_url_based_indexing',
              label: 'Custom URL Based Indexing',
              requiredIntegration: null,
              isComingSoon: false,
              isSitewide: false,
            }]};
        }
        return value;
      });
      return {...i, subGroups: subGroups};
    }
    return i;
  });

  const category = selectedIssue === 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : selectedIssue;
  const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === category || issueObj.label === category)?.subGroups?.find(categoryObj => categoryObj?.group === selectedCategory);

  // @ts-ignore: Ignoring type error because the type of subGroups is uncertain at runtime
  const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === componentIssueType);

  const [ottoUrls, setOttoUrls] = useState([]);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);


  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);

  const onRefresh = async issueType => {
    await ottoBulkImportPostOrQuestion(refreshKeyMapping[issueType]);
    setPageChanged(true);
    setIssueTypeSelected(issueType);
  };


  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);
  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, postType?: string, activeFilter?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          search: search,
          page,
          is_loading: false,
          ...(postType !== 'ALL') && {topic_type__in: postType === 'UPDATE' ? 'STANDARD' : postType},
          ...(activeFilter &&!['All', 'all'].includes(activeFilter)) && {status__in: activeFilter === 'Draft' ? 'Pending Review, Unpublished' : activeFilter},
        };
        if (search) {
          params['search'] = search;
          setOttoSearchTerm(search);
        } else {
          params['search'] = '';
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const debouncedChange = debounce((issueArray: string[], pageSize, page, searchText, category, postType, activeFilter) => {
    const value = searchText.trim();
    if (value) {
      loadIssueTables(issueArray, selectedIssue, category, 1, pageSize, true, value?.toLowerCase(), postType, activeFilter);
      setIssueTable(issueArray[0]);
      setPageChanged(true);
    } else {
      loadIssueTables(issueArray, selectedIssue, category, page ?? 1, pageSize, true, '', postType, activeFilter);
    }
  }, 1000);

  const handleSearch = useCallback(
    (issueArray: string[], pageSize, page, searchText, category, postType, activeFilter) => {
      if (searchText && searchText.length < 3) {
        return;
      }
      debouncedChange(issueArray, pageSize, page, searchText, category, postType, activeFilter);
    }, [selectedCategory]);

  const onStatusChange = async (activeKey: string, issueType: string, pageSize: number, isSitewide: boolean) => {
    setIsActiveFilter(activeKey);
    const dateObjectsArray = Object.entries(postCreatedAt).map(([key, value]) => {
      if (value?.startDate || value?.endDate) {
        if (key === 'scheduled') {
          return {
            ...(value?.startDate && {[`schedule_publishing_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
            ...(value?.endDate && {[`schedule_publishing_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
          };
        }
        return {
          ...(value?.startDate && {[`${key}_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
          ...(value?.endDate && {[`${key}_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
        };
      } else {
        return null;
      }
    }).filter(item => item !== null);
    const dateFilters = dateObjectsArray.reduce((acc, obj) => {
      return {...acc, ...obj};
    }, {});
    const filters = {
      search: searchText,
      ...(selectedPostType !== 'ALL') && {topic_type__in: selectedPostType === 'UPDATE' ? 'STANDARD' : selectedPostType},
      ...(!['All', 'all'].includes(activeKey)) && {status__in: activeKey === 'Draft' ? 'Pending Review, Unpublished' : activeKey},
      ...dateFilters,
    };
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      is_loading: false,
      ...filters,
    };
    setStatusKeyValue(activeKey);
    await loadIssueTableData(params, isSitewide);
  };
  const onDateClearAll = async (issueType: string, pageSize: number, isSitewide: boolean) => {
    setPostCreatedAt(gbpPostInitialDateFilter);
    setAppliedDateFilters([]);
    const filters = {
      search: searchText,
      ...(selectedPostType !== 'ALL') && {topic_type__in: selectedPostType === 'UPDATE' ? 'STANDARD' : selectedPostType},
      ...(!['All', 'all'].includes(isActiveFilter)) && {status__in: isActiveFilter === 'Draft' ? 'Pending Review, Unpublished' : isActiveFilter},
    };
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      search: searchText,
      is_loading: false,
      ...filters,
    };
    await loadIssueTableData(params, isSitewide);
  };
  const onDateFilter = async (issueType: string, pageSize: number, isSitewide: boolean) => {
    const dateObjectsArray = Object.entries(postCreatedAt).map(([key, value]) => {
      if (value?.startDate || value?.endDate) {
        if (key === 'scheduled') {
          return {
            ...(value?.startDate && {[`schedule_publishing_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
            ...(value?.endDate && {[`schedule_publishing_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
          };
        }
        return {
          ...(value?.startDate && {[`${key}_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
          ...(value?.endDate && {[`${key}_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
        };
      } else {
        return null;
      }
    }).filter(item => item !== null);
    const appliedFilters = Object.entries(postCreatedAt).map(([key, value]) => {
      if (value?.startDate || value?.endDate) {
        return key;
      } else {
        return null;
      }
    }).filter(item => item !== null);
    setAppliedDateFilters(appliedFilters);
    const dateFilters = dateObjectsArray.reduce((acc, obj) => {
      return {...acc, ...obj};
    }, {});

    const filters = {
      search: searchText,
      ...(selectedPostType !== 'ALL') && {topic_type__in: selectedPostType === 'UPDATE' ? 'STANDARD' : selectedPostType},
      ...(!['All', 'all'].includes(isActiveFilter)) && {status__in: isActiveFilter === 'Draft' ? 'Pending Review, Unpublished' : isActiveFilter},
      ...dateFilters,
    };
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      search: searchText,
      is_loading: false,
      ...filters,
    };
    await loadIssueTableData(params, isSitewide);
  };
  const onTypeChange = async (postType: string, issueType: string, pageSize: number, isSitewide: boolean) => {
    setSelectedPostType(postType);
    const dateObjectsArray = Object.entries(postCreatedAt).map(([key, value]) => {
      if (value?.startDate || value?.endDate) {
        if (key === 'scheduled') {
          return {
            ...(value?.startDate && {[`schedule_publishing_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
            ...(value?.endDate && {[`schedule_publishing_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
          };
        }
        return {
          ...(value?.startDate && {[`${key}_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
          ...(value?.endDate && {[`${key}_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
        };
      } else {
        return null;
      }
    }).filter(item => item !== null);
    const dateFilters = dateObjectsArray.reduce((acc, obj) => {
      return {...acc, ...obj};
    }, {});
    const filters = {
      search: searchText,
      ...(postType !== 'ALL') && {topic_type__in: postType === 'UPDATE' ? 'STANDARD' : postType},
      ...(!['All', 'all'].includes(isActiveFilter)) && {status__in: isActiveFilter === 'Draft' ? 'Pending Review, Unpublished' : isActiveFilter},
      ...dateFilters,
    };
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      is_loading: false,
      ...filters,
    };
    await loadIssueTableData(params, isSitewide);
  };

  const render = () => {
    return (
      <>
        <HeaderContainer className={isResponsiveHeader ? 'flex-wrap' : ''}>
          <div className='issues-collapse-header'>
            <div>
              <Tooltip title={issueTable?.length > 0 && !ottoSearchTerm ? 'Loading. Please wait' : ''}>
                <UrlFilterInput
                  style={{maxWidth: '140px', border: '1px solid rgba(232, 232, 232, 1)'}}
                  placeholder={'Post Content'}
                  prefix={<FontAwesomeIcon icon={faMagnifyingGlass}
                    color='#A3A4A4'/>}
                  onChange={e => {
                    setSearchText(e.target.value);
                    handleSearch([url?.issueType], url?.pageSize ?? 10, 1, e?.target?.value, selectedCategory, selectedPostType, isActiveFilter);
                  }}
                  disabled={issueTable?.length > 0 && !ottoSearchTerm}
                  value={searchText}
                />
              </Tooltip>
            </div>
            <GBPPostFilters
              data={url}
              isActiveFilter={isActiveFilter}
              isSitewide={itemObject?.isSitewide}
              postCreatedAt={postCreatedAt}
              setPostCreatedAt={setPostCreatedAt}
              selectedPostType={selectedPostType}
              onStatusChange={onStatusChange}
              onDateFilter={onDateFilter}
              onTypeChange={onTypeChange}
              onDateClearAll={onDateClearAll}
              appliedDateFilters={appliedDateFilters}
              setAppliedDateFilters={setAppliedDateFilters}
            />
            <RecordCount>Posts: <div>{url?.issueTable?.count}</div></RecordCount>
          </div>
          <div className={`issues-btn-wrapper ${isResponsiveHeader ? 'inner-fields-wrap' : ''}`} style={{display: 'flex', alignItems: 'center', gap: 10}}>
            {showToolTipTracker.includes(url?.issueType) ?
              <QuotaTracker consumedData={quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed}
                totalData= {quotaUtilization?.ca?.allowedOttoAiSuggestions?.total} heading={'AI Generation'}
                fontSize={12} />: <></>}
            {(showRefreshButton.includes(url?.issueType) || showAddButton.includes(url?.issueType)) && <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
              {showRefreshButton.includes(url?.issueType) ? (
                <FreezeWrapper placement='topRight'>
                  <RefreshWrapper>
                    <div className={`date ${isResponsiveHeader ? 'text-nowrap': ''}`}>{refreshDateMapping[url?.issueType] ? `Last refreshed: ${refreshDateMapping[url?.issueType]}`: ''}</div>
                    <div className={`refresh-button ${isResponsiveHeader ? 'text-nowrap': ''}`}
                      onClick={() => !refreshingBulkGBPData && onRefresh(url?.issueType)}><FontAwesomeIcon icon={faRotate} style={{margin: '0 5px'}} fontSize={12} color='#2D6CCA' className={refreshingBulkGBPData? 'fa-spin': ''}/>Refresh</div>
                  </RefreshWrapper>
                </FreezeWrapper>
              ) : (
                <></>
              )}
            </div>}
          </div>
        </HeaderContainer>
        <div style={{color: '#A3A4A4', fontSize: 12, fontStyle: 'italic', marginTop: '5px', marginLeft: 15}}>Search term must be at least 3 characters long.</div>
      </>
    );
  };
  return (
    <>{!itemObject?.isComingSoon ? render() : <ComingSoonTag>Coming soon</ComingSoonTag>}</>
  );
});


export const HeaderContainer = styled.div`
  padding: 0px 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.flex-wrap {
    flex-wrap: wrap;
  }
  .inner-fields-wrap {
    flex-wrap: wrap;
    justify-content: end;
    margin-left: auto;
  }
  @media screen and (max-width: 1260px) {
    flex-wrap: wrap;
    row-gap: 8px;
    .issues-collapse-header {
      min-height: 46px;
    }
    .issues-btn-wrapper {
      margin-left: auto;
    }
  }

  .issues-collapse-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  div:nth-child(1) {
    .anticon-right {
      display: none;
    }
  }
  .issues-collapse-header {
    .chevron-up-icon {
      display: block;
    }
  }
`;

const RecordCount = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Inter', sans-serif;
  color: #4E5156;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  div {
    font-family: 'Inter', sans-serif;
    color: #121212;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;
