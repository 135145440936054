import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {Table, Pagination, Select} from 'antd';
import {ColumnType} from 'antd/lib/table';
import {Loader} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/RightSidebar/v2-sidebar/Utils/loader';
import moment from 'moment';
import {StyledLoaderDiv, UncategoriezedProject, PaginationContainer} from './style';
import styled from 'styled-components';
import {routes} from '@/utils/const';
import {NextImg} from '@/utils/nextImg';

interface Props {
  visible?: boolean;
}

type CustomColumnType = ColumnType<any>;

export const AIContentStatus: React.FC<Props> = observer(({visible}) => {
  const {contentOptimizer: {aiOutline}} = useStore('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState<string>('24');
  const [apiContentStatus, setApiContentStatus] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStatus() {
      await loadApiContentStatus();
    }
    visible && loadStatus();
  }, [activeTab, currentPage, visible]);

  function formatDate(dateString: string): string {
    const date = moment(dateString).format('MMM DD, YYYY');
    const time = moment(dateString).format('hh:mm A');
    return `${date} at ${time}`;
  }

  const parseArticleDetails = (item: any) => ({title: item.title, projectName: item.projectName, status: item.status, uuid: item.pageUuid});

  const loadApiContentStatus = async () => {
    setApiContentStatus([]);
    setLoading(true);
    const response = await aiOutline.getAiGeneratedContentStatusData(activeTab, 20, currentPage);
    response && setLoading(false);
    setTotalCount(response?.count || 0);

    if (response?.results?.length > 0) {
      const groupedStatus = {};
      response.results.forEach(item => {
        const date = formatDate(item.createdAt);
        if (date in groupedStatus) {
          groupedStatus[date] = [...groupedStatus[date], parseArticleDetails(item)];
        } else {
          groupedStatus[date] = [parseArticleDetails(item)];
        }
      });
      setApiContentStatus(Object.keys(groupedStatus).map(key => ({date: key, articles: groupedStatus[key]})));
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const columns = (): CustomColumnType[] => [
    {
      title: () =>
        <div style={{width: '116px'}}>
          {'ARTICLE'}
        </div>,
      key: 'score',
      fixed: 'left' as 'left',
      sorter: false,
      align: 'left' as 'left',
      onCell: () => ({colSpan: 3}),
      width: 40,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_:any, record) => {
        return (
          <div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>
                <p style={{fontSize: '12px', color: '#A3A4A4', fontFamily: 'inter'}}>{record?.date}</p>
              </div>
              {record.articles.map(article => (
                <div style={{display: 'flex'}} key={article.title}>
                  <div style={{display: 'flex', width: '90%'}}>
                    <div style={{marginRight: '10px'}}>
                      { ['SUCCESS', 'PENDING', 'FAILURE'].includes(article?.status) ?
                        <NextImg width={12} height={12} src={`/img/icon/${article?.status == 'SUCCESS' ? 'success_icon.svg' : article?.status == 'PENDING'? 'pending_icon.svg' : 'failed_icon.svg' }`} /> :
                        <LoaderProgress><Loader /></LoaderProgress>
                      }
                    </div>
                    <a className='articleName' href={`/${routes.landingPageOptimizer}/${article.uuid}`} target='_blank' style={{color: 'black'}} rel='noreferrer'>
                      <p style={{textDecoration: 'underline', margin: '0'}}>{article.title}</p>
                    </a>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center', width: '50%', marginLeft: '10px'}}>
                    {article.projectName ? article.projectName : <UncategoriezedProject>Uncategorized</UncategoriezedProject>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      title: () =>
        <div>
          {'PROJECT'}
        </div>,
      key: 'score',
      fixed: 'left' as 'left',
      sorter: false,
      align: 'left' as 'left',
      width: 10,
      onCell: () => ({colSpan: 0}),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    },
  ];

  const TAB_PANE_DATA = [
    {
      'title': 'Last 24 hours',
      'key': '24',
    },
    {
      'title': 'Last 72 hours',
      'key': '72',
    },
    {
      'title': 'All time',
      'key': '0',
    },
  ];

  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', gap: 8, margin: '10px 0 8px'}}>
        <span style={{fontWeight: 500, color: '#121212'}}>TimeSpan:</span>
        <StyledSelect style={{width: 150, borderRadius: 8}} value={activeTab} dropdownRender={menu => <MenuWrapper>{menu}</MenuWrapper>} onChange={(value: string) => setActiveTab(value)}>
          {TAB_PANE_DATA.map(item => (
            <Select.Option key={item.key} value={item.key} label={item.title}>
              <div style={{display: 'flex', color: '#121212'}}>
                <span>{item.title}</span>
              </div>
            </Select.Option>
          ))}
        </StyledSelect>
      </div>
      <TableWrapper>
        <StyledTable
          style={{maxHeight: '315px', minHeight: '315px', overflow: 'hidden', overflowY: 'auto', border: '1px solid #E8E8E8', borderRadius: '8px'}}
          dataSource={apiContentStatus}
          columns={columns()}
          pagination={false}
          locale={{emptyText: loading ? <StyledLoaderDiv> <Loader centered /></StyledLoaderDiv> : 'No Data'}}
        />
        {!loading && (totalCount > 20) && <PaginationContainer>
          <Pagination
            onChange={handlePageChange}
            total={totalCount}
            pageSize={20}
            current={currentPage}
            defaultPageSize={20}
            defaultCurrent={1}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        </PaginationContainer>}
      </TableWrapper>
    </div>
  );
});

const LoaderProgress = styled.div`
div{
  margin-top: 5px !important;
 div{
  height: 12px !important;
  width: 12px !important;
  border-top: 2px solid orange !important;
 }
}
`;

const StyledTable = styled(Table)`
  &::-webkit-scrollbar {
    width: 5px;
    height: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(78, 81, 86, 0.4);
    border-radius: 10px;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 4px !important;
    box-shadow: none !important;
    &:hover {
      border-color: #7F4EAD !important;
      box-shadow: none !important;
    }
    &:focus {
      border-color: #7F4EAD !important;
      box-shadow: none !important;
    }
    &:active {
      border-color: #7F4EAD !important;
      box-shadow: none !important;
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:hover,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #7F4EAD !important;
    box-shadow: none !important;
  }
`;

const MenuWrapper = styled.div`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(127, 78, 173, 0.13) !important;
  }
`;

const TableWrapper = styled.div`
.ant-table-thead{
  th{
    padding: 8px 16px !important;
    div{
      font-size: 12px;
    }
  }
}
.articleName{
  &:hover{
    color: #2D6CCA !important;
  }
}
`;
