import React, {useState} from 'react';
import {Modal, Typography} from '@/components/common-components/components';
import styles from './style.module.scss';
import commonStyles from '../style.module.scss';
import {Row, Col} from 'antd';
import moment from 'moment';
import {routes} from '@/utils/const';
import {PaymentMethodModal} from '../payment-method-modal';
import {UnpaidInvoiceModal} from '../unpaid-invoice';
import {useStore} from '@/store/root-store';
import {useTranslation} from 'next-i18next';
import ButtonComponent from '@/components/common-components/components/button-component';
import {LoadingOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';

export interface PendingInvoiceProps {
    visible?: boolean;
    closable?: boolean;
    content?: Array<{order: number; status: string; slug: string; createdAt: string; amount: string; type: string}>;
    account?: {firstName?: string; lastName?: string};
    onClose?: () => void;
}
export const PendingInvoiceModal: React.FC<PendingInvoiceProps> = observer(({
  visible,
  closable,
  content,
  account,
  onClose,
}) => {
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [unpaidInvoice, setUnpaidInvoice] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const {t} = useTranslation('common');
  const {invoices: {loadOrderInvoices, deleteInvoice, deleteLoading}} = useStore('');
  const closeModal = () => {
    setChangePaymentMethod(false);
    setUnpaidInvoice(false);
  };

  const handleUnpaidInvoice = val => {
    setUnpaidInvoice(true);
    setSelectedInvoice(val);
  };

  const loadUpdatedInvoice = () => {
    loadOrderInvoices('');
  };
  const deletInvoice = item => {
    if (!deleteLoading) {
      deleteInvoice(item?.slug, item?.type);
    }
  };
  const antIcon = <LoadingOutlined style={{fontSize: 14, color: '#7f4ead'}} spin />;

  const modalTemplate = (
    <div>
      <div className={styles.SummaryContainer}>
        {content.map(invoice => ['failed', 'pending'].includes(invoice.status?.toLowerCase()) && !['order', 'ai_quota_top_up'].includes(invoice.type?.toLowerCase()) ? (
          <Row key={invoice.slug} className={styles.PendingInvoice}>
            <Col className={styles.PendingUserInfo}>
              <p className={styles.RowTitle}>{t('invoice')}</p>
              <h3>{`${account.firstName} ${account.lastName}`}</h3>
              <p className={styles.blueButton} onClick={()=> deletInvoice(invoice)}>{deleteLoading ? antIcon : 'Delete Invoice'}</p>
              <p className={styles.DueDateTitle}>{t('invoice-due-on')}:</p>
              <p>{moment(invoice.createdAt).format('ll')}</p>
            </Col>
            <Col className={styles.PendingAmount}>
              <p className={styles.RowTitle}>{t('amount-to-be-paid')}</p>
              <h3>{`$${invoice.amount}`}</h3>
              <ButtonComponent
                color='primary'
                style='solid'
                onClick={()=> handleUnpaidInvoice({...invoice, type: 'order', name: `${account.firstName} ${account.lastName}`})} >
                {t('pay-invoice2')}
              </ButtonComponent>
            </Col>
          </Row>
        ) : null)}
      </div>
      <Typography tag='p' className={styles.ModalFooter}>{t('need-more-details-orders')} <a href={`/${routes.billing}`}><u>{t('orders-history')}</u></a></Typography>

      {changePaymentMethod && changePaymentMethod && <PaymentMethodModal isOpen={changePaymentMethod} setIsOpen={closeModal} />}
      {unpaidInvoice && unpaidInvoice && <UnpaidInvoiceModal isOpen={unpaidInvoice} loadUpdatedInvoice={loadUpdatedInvoice} setIsOpen={closeModal} invoice={selectedInvoice}/>}
    </div>
  );

  const invoiceCount = content => content.filter(item=> ['failed', 'pending'].includes(item.status?.toLowerCase()) && !['order', 'ai_quota_top_up'].includes(item.type?.toLowerCase())).length;

  const modalTitle = (
    <div className={styles.ModalHeader}>
      <Typography tag='h3'><span style={{color: '#000'}}>{t('you-have')}</span> {invoiceCount(content)} {invoiceCount(content) > 1 ? t('unpaid-invoices') : t('unpaid-invoice')}</Typography>
      <p>{t('finalize-order-proceed-checkout')}</p>
    </div>
  );

  return (
    <Modal
      onClose={onClose}
      className={commonStyles.Modal}
      title={modalTitle}
      visible={visible}
      closable={closable} >
      {modalTemplate}
    </Modal>
  );
});
