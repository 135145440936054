import {types, flow, cast, getRoot} from 'mobx-state-tree';
// import {AUDITED_SITES_API_V2} from '@/api/SiteAuditorV2';
// import {notification} from '@/utils/notification-v2';
import {KeywordDetails, initKeywordDetails} from './keyword-details';
import {toJS} from 'mobx';
// import moment from 'moment';
// import {getSingleUrlParam, isPublicAuditOverview, isPublicAuditPageExplorer} from '@/utils/url';
// import {getTokenFromCookies} from '@/api/common-utils';
// import Router from 'next/router';
import {KEYWORD_RESEARCH_API_V2} from '@/api/KeywordResearcherV2';
import {initKeywordRankTracker, KeywordRankTracker} from './keyword-rank-tracker';
import {initKrtCriteriaStore, KrtCriteria} from './krt-criteria';
import {apiError, isNetworkError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
export const keywordListModel = types.model({
  countryCode: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  isCreatedFromCx: types.maybeNull(types.boolean),
  isRecentSearch: types.maybeNull(types.boolean),
  keywords: types.maybeNull(types.array(types.string)),
  locationId: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  user: types.maybeNull(types.number),
  locationFlag: types.maybeNull(types.string),
});

export const keywordHistoryModel = types.model({
  countryCode: types.maybeNull(types.string),
  locationFlag: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  searchedOn: types.maybeNull(types.string),
  term: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
});

const keywordListParamsModel = types.model({
  page: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
});
export const keywordResearcherV2Store = types.model({
  keywordDetails: KeywordDetails,
  krtCriteria: KrtCriteria,
  keywordRankTracker: KeywordRankTracker,
  keyWordList: types.maybeNull(types.array(keywordListModel)),
  keyWordHistory: types.maybeNull(types.array(keywordHistoryModel)),
  sideBarKEList: types.maybeNull(types.array(keywordListModel)),
  keyWordListDetail: types.maybeNull(types.array(keywordListModel)),
  keyWordListDetailCount: types.maybeNull(types.number),
  sideBarKeywordListPages: types.maybeNull(types.number),
  loadingKeywordList: types.maybeNull(types.boolean),
  isOpenModal: types.maybeNull(types.boolean),
  keywordListCount: types.maybeNull(types.number),
  kmtListParams: types.maybeNull(keywordListParamsModel),
  keywordListParams: types.maybeNull(keywordListParamsModel),
  sideBarKEListParams: types.maybeNull(keywordListParamsModel),
  keywordListDetailParams: types.maybeNull(keywordListParamsModel),
  loadingAddKeywordList: types.maybeNull(types.boolean),
  loadingSidebarKEList: types.maybeNull(types.boolean),
  openKeywordDeleteModal: types.maybeNull(types.boolean),
  keywordDeleteId: types.maybeNull(types.union(types.string, types.number)),
  deleteKeywordLoading: types.maybeNull(types.boolean),
  deleteKMTKeywordLoading: types.maybeNull(types.boolean),
}).views(self => ({
  get getKeywordListDetail() {
    return toJS(self.keyWordList);
  },
  get getKeywordHistoryDetail() {
    return toJS(self.keyWordHistory);
  },
  get getKeywordListDetailData() {
    return toJS(self.keyWordListDetail);
  },
  get getSideBarKEList() {
    return toJS(self.sideBarKEList);
  },
})).actions(self => {
  const loadKeyWordsList = flow(function* (noLoading: boolean) {
    if (!noLoading) {
      self.loadingKeywordList = true;
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordDetail(toJS(self.keywordListParams));
      if (response) {
        self.keywordListCount = response?.count;
        self.keyWordList = cast(response?.results);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loadingKeywordList = false;
    }
  });

  const loadKeyWordsHistory = flow(function* (noLoading: boolean) {
    if (!noLoading) {
      self.loadingKeywordList = true;
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordHistory(toJS(self.kmtListParams));
      if (response) {
        self.keywordListCount = response?.count;
        self.keyWordHistory = cast(response?.results);
      }
    } catch (err) {
      const errorMessage = apiError(err) as string;
      notification.error('', errorMessage);
    } finally {
      self.loadingKeywordList = false;
    }
  });

  const deleteKMTKeyword = flow(function* (id) {
    self.deleteKMTKeywordLoading = true;
    try {
      yield KEYWORD_RESEARCH_API_V2.deleteMagicToolKeyword(id);
      loadKeyWordsHistory(false);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.deleteKMTKeywordLoading = false;
    }
  });

  const loadAddKeywordsList = flow(function* (noLoading: boolean, search?: string) {
    if (!noLoading) {
      self.loadingAddKeywordList = true;
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordDetail({page: 1, pageSize: 100}, search);
      if (response) {
        self.keywordListCount = response?.count;
        self.keyWordList = cast(response?.results);
      }
    } catch (err) {
      return isNetworkError(err, 'Failed to load keyword detail', true);
    } finally {
      self.loadingAddKeywordList = false;
    }
  });

  const setKeyWordListDetail = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingKeywordList = true;
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordListDetail(toJS(self.keywordListDetailParams));
      if (response) {
        self.keyWordListDetailCount = response?.count;
        self.keyWordListDetail = cast(response?.results);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loadingKeywordList = false;
    }
  });
  const updatedKeywordListData = flow(function* (payload, id, fromDropdown?: boolean) {
    // self.loadingKeywordList = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.updateKeyword(payload, id);
      if (response) {
        if (!fromDropdown) {
          loadKeyWordsList(false);
        }
      }
      return response;
    } catch (err) {
      const errorMessage = apiError(err) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      // self.loadingKeywordList = false;
    }
  });
  const deletedKeywordListData = flow(function* (id, isHistory, isLastRecord?: boolean) {
    // self.loadingKeywordList = true;
    self.deleteKeywordLoading = true;
    if (isLastRecord) {
      if (isHistory ) {
        updateListDetailParams({
          ...self.keywordListDetailParams,
          page: self.keywordListDetailParams.page !== 1 ? self.keywordListDetailParams.page - 1 : self.keywordListDetailParams.page,
        }, true);
      } else {
        updateListParams({
          ...self.keywordListParams,
          page: self.keywordListParams.page !== 1 ? self.keywordListParams.page - 1 : self.keywordListParams.page,
        }, true);
      }
    }
    try {
      yield KEYWORD_RESEARCH_API_V2.deleteKeyword(id);
      if (isHistory ) {
        return setKeyWordListDetail(true);
      } else {
        return loadKeyWordsList(true);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.deleteKeywordLoading = false;
    }
  });
  const setModaValue = (modalValue, recordId?: number)=>{
    self.openKeywordDeleteModal= modalValue;
    if (recordId) {
      self.keywordDeleteId =recordId;
    }
  };
  const updateListParams = (data, noApiCall?: boolean) => {
    self.keywordListParams.search = data?.search || '';
    self.keywordListParams.page = data?.page;
    self.keywordListParams.pageSize = data?.pageSize;
    self.keywordListParams.ordering = data?.ordering;
    if (!noApiCall) {
      loadKeyWordsList(false);
    }
  };
  const updateKMTListParams = (data, noApiCall?: boolean) => {
    self.kmtListParams.search = data?.search || '';
    self.kmtListParams.page = data?.page;
    self.kmtListParams.pageSize = data?.pageSize;
    self.kmtListParams.ordering = data?.ordering;
    if (!noApiCall) {
      loadKeyWordsHistory(false);
    }
  };
  const updateListDetailParams = (data, noApiCall?: boolean) => {
    self.keywordListDetailParams.search = data?.search || '';
    self.keywordListDetailParams.page = data?.page;
    self.keywordListDetailParams.pageSize = data?.pageSize;
    if (!noApiCall) {
      try {
        setKeyWordListDetail();
      } catch (error) {
        notification.error('', 'Failed to load keywords');
      }
    }
  };

  const postNewKeywords = flow(function* (payload) {
    self.keywordDetails.loadingKeywordDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.postNewKeyword(payload);
      if (response?.success !== false) {
        return response;
      }
      const rootStore = getRoot(self) as any;
      if (response?.response?.response?.status == 406) {
        rootStore?.settings?.customer?.setAuthModal(true, false, null, true);
      }
      return null;
    } catch (err) {
      return Promise.reject(err);
    }
  });

  const postNewHistory = flow(function* (payload) {
    self.keywordDetails.loadingKeywordDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.postNewHistory(payload);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  });

  const setSideBarParam = value => {
    self.sideBarKEListParams.page = value;
  };

  const loadSideBarKEList = flow(function* () {
    self.loadingSidebarKEList = true;
    const data = toJS(self.sideBarKEList)?.length ? toJS(self.sideBarKEList) : [];
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordDetail(toJS(self.sideBarKEListParams));
      if (response) {
        const list = response?.results?.length ? response?.results : [];
        list?.map(item => {
          const siteIndex = data?.findIndex(e => e?.id == item?.id);
          if (siteIndex !== -1) {
            data[siteIndex] = item;
          } else {
            data.push(item);
          }
        });
        self.sideBarKeywordListPages = response?.totalPages;
        self.sideBarKEList = cast(data);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loadingSidebarKEList = false;
    }
  });

  const setIsOpenModal = value => {
    self.isOpenModal = value;
  };

  return {
    loadKeyWordsList,
    loadKeyWordsHistory,
    loadSideBarKEList,
    postNewKeywords,
    postNewHistory,
    updatedKeywordListData,
    deletedKeywordListData,
    setIsOpenModal,
    updateListParams,
    setKeyWordListDetail,
    updateListDetailParams,
    setSideBarParam,
    updateKMTListParams,
    loadAddKeywordsList,
    setModaValue,
    deleteKMTKeyword,
  };
});
export function initKeywordResearcherV2Store() {
  return keywordResearcherV2Store.create({
    keywordDetails: initKeywordDetails(),
    keywordRankTracker: initKeywordRankTracker(),
    krtCriteria: initKrtCriteriaStore(),
    keyWordList: [],
    keyWordListDetail: [],
    loadingKeywordList: true,
    loadingSidebarKEList: false,
    loadingAddKeywordList: true,
    deleteKeywordLoading: false,
    deleteKMTKeywordLoading: false,
    isOpenModal: false,
    keywordListDetailParams: {
      page: 1,
      pageSize: 20,
      search: '',
    },
    keywordListParams: {
      page: 1,
      pageSize: 20,
      search: '',
    },
    sideBarKEListParams: {
      page: 1,
      pageSize: 20,
      search: '',
    },
    kmtListParams: {
      page: 1,
      pageSize: 20,
      search: '',
    },
  });
}
