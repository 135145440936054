import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LoadingOutlined} from '@ant-design/icons';
import {Button, Rate, Tooltip, Spin} from 'antd';
import {faPen, faRotate, faCheck} from '@fortawesome/pro-regular-svg-icons';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {useEffect, useRef, useState} from 'react';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import moment from 'moment';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {notification} from '@/utils/notification-v2';
import TextArea from 'antd/lib/input/TextArea';
import {NextImg} from '@/utils/nextImg';

export const buttonOptions = [{value: null, label: 'None'}, {value: 'BOOK', label: 'Book'}, {value: 'ORDER', label: 'Order online'}, {value: 'SHOP', label: 'Buy'}, {value: 'LEARN_MORE', label: 'Learn more'}, {value: 'SIGN_UP', label: 'Sign up'}, {value: 'CALL', label: 'Call now'}];
export const ApproveModal = observer(({modalType, modalData, setIsModalOpen, setRefresh, edit, setEdit, phoneNumbers}) => {
  const {settings: {customer: {getCustomerQuota}}, ottoV2Store: {myTasksLocations, aiGenerateGBPReview, ownersAnswerAiGenerate, deployOttoGBPPosts, publishOttoGBPPost,
    publishGPBQuestion, publishReview, deleteGbpPost, deleteQASuggestion, editOttoGBPReview, updateGBPOwnersAnswer,
  }} = useStore('');
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(null);
  const [updating, setUpdating] = useState(false);
  const answerRef = useRef<HTMLTextAreaElement | null>(null);
  const replyRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrl, setImageUrl] = useState(null);
  const buttonOptions = [{value: null, label: 'None'}, {value: 'BOOK', label: 'Book'}, {value: 'ORDER', label: 'Order online'}, {value: 'SHOP', label: 'Buy'}, {value: 'LEARN_MORE', label: 'Learn more'}, {value: 'SIGN_UP', label: 'Sign up'}, {value: 'CALL', label: 'Call now'}];

  const [isCopied, setIsCopied]=useState(false);
  useEffect(() => {
    setLoader(data ? null : 'data');
  }, [data]);

  useEffect(() => {
    if (answerRef && edit === 'answer' && answerRef?.current) {
      const textarea = answerRef?.current;
      if (textarea instanceof HTMLTextAreaElement) {
        const length = textarea?.value?.length;
        textarea?.setSelectionRange(length, length);
        textarea.focus();
      }
    } else if (replyRef && edit === 'reply' && replyRef.current) {
      const textarea = replyRef?.current;
      if (textarea instanceof HTMLTextAreaElement) {
        const length = textarea?.value?.length;
        textarea?.setSelectionRange(length, length);
        textarea.focus();
      }
    }
  }, [edit]);

  useEffect(() => {
    if (data?.medias?.[0]?.url) {
      setImageUrl(data?.medias?.[0]?.url);
    } else {
      setImageUrl('/img/no-image-available.png');
    }
  }, [JSON.stringify(modalData), data?.medias?.[0]?.url]);

  useEffect(() => {
    if (modalData) {
      setLoader(null);
      setData(JSON.parse(JSON.stringify(modalData)));
    }
    return () => {
      setData(null);
    };
  }, [JSON.stringify(modalData), modalData]);

  const aiGenerateReply = async () => {
    try {
      setLoader('ai');
      const response = await aiGenerateGBPReview(data?.id);
      await editOttoGBPReview(data?.id, {content: response?.content});
      await setRefresh(true);
      await setData(prevData => ({...prevData, reply: {...data?.reply, content: response?.content}}));
      getCustomerQuota();
    } finally {
      setLoader(null);
    }
  };

  const aiGenerateAnswer = async () => {
    try {
      setLoader('ai');
      const response = await ownersAnswerAiGenerate(data?.id);
      await setData(prevData => ({...prevData, ownersAnswer: {...data?.ownersAnswer, content: response?.content}}));
      await updateGBPOwnersAnswer(data?.id, {content: response?.content});
      await setRefresh(true);
      getCustomerQuota();
    } finally {
      setLoader(null);
    }
  };

  const approve = async () => {
    try {
      setLoader('update');
      if (modalType == 'POST') {
        await deployOttoGBPPosts({id: data?.id, imageUrl: data?.imageUrl, schedulePublishingAt: data?.schedulePublishingAt, content: data?.content, location: data?.location});
        await publishOttoGBPPost({id: data?.id, imageUrl: data?.imageUrl, schedulePublishingAt: data?.schedulePublishingAt, content: data?.content, location: data?.location});
        notification.success('Success', 'Post approved successfully!');
      } else if (modalType == 'QUESTION') {
        await publishGPBQuestion(data?.id, data);
        notification.success('Success', 'Q&A approved successfully!');
      } else {
        await publishReview(data?.id);
        notification.success('Success', 'Reply approved successfully!');
      }
      await setRefresh(true);
      await setIsModalOpen(false);
    } finally {
      setLoader(null);
    }
  };

  const deleteTask = async () => {
    try {
      setLoader('delete');
      if (modalType == 'POST') {
        await deleteGbpPost(data?.id);
        notification.success('Success', 'Post deleted successfully!');
      } else if (modalType == 'QUESTION') {
        await deleteQASuggestion(data?.id);
        notification.success('Success', 'Q&A deleted successfully!');
      } else {
        await editOttoGBPReview(data?.id, {content: ''});
      }
      await setRefresh(true);
      await setIsModalOpen(false);
    } finally {
      setLoader(null);
    }
  };
  const onChangeInput = (value: string, method: string) => {
    if (method === 'reply') {
      setData(prevData => ({...prevData, reply: {...data?.reply, content: value}}));
    } else if (method === 'answer') {
      setData(prevData => ({...prevData, ownersAnswer: {...data?.ownersAnswer, content: value}}));
    }
  };

  const onEdit = (editing: string) => {
    setEdit(editing);
  };
  const onUpdate = async () => {
    setUpdating(true);
    if (edit === 'reply') {
      await editOttoGBPReview(data?.id, {content: data?.reply?.content});
      await setRefresh(true);
      notification.success('Success', 'Reply updated successfully!');
    } else if (edit === 'answer') {
      await updateGBPOwnersAnswer(data?.id, {content: data?.ownersAnswer?.content});
      await setRefresh(true);
      notification.success('Success', 'Answer updated successfully!');
    }
    setEdit('');
    setUpdating(false);
  };

  return (<>
    {(loader == 'data') ? <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}><RingLoaderV2 width='500px' height='500px' fontSize={50} /></div> : <>
      {modalType == 'POST' && <PostPreviewContainer>
        <div className='post-header'>
          <div className='logo-and-title'>
            <NextImg style={{borderRadius: '50%'}} src={data?.imageUrl ?? '/img/no-image-available.png'} width={42} height={42} />
            <div className='title-date'>
              <div className='title-text'>{myTasksLocations?.find(item => item.id == data?.location)?.businessName}</div>
              <div className='date-text'>{data?.schedulePublishingAt ? moment(data?.schedulePublishingAt).format('MMM DD, YYYY') : null}</div>
            </div>
          </div>
        </div>
        <div className='post-image-container'>
          {!imageUrl ? <Spin indicator={<LoadingOutlined style={{fontSize: 100, color: '#a8a8a8'}} spin />} /> : <NextImg
            key={imageUrl}
            className={'post-image'}
            src={imageUrl}
            alt='Post Image'
            loading='lazy'
          />}
        </div>
        <div>
          <div className='post-text'>
            {data?.content}
          </div>
          <div className='footer'>
            {((data?.callToActionType === 'CALL' && phoneNumbers?.length > 0) || (data?.callToActionType !== 'CALL' && data?.callToActionUrl)) && (
              <Tooltip
                title={data?.callToActionType == 'CALL' && (isCopied ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}}/>{` Phone number copied`}</span> : 'Copy phone number')}
                placement='bottom'
                overlayInnerStyle={{backgroundColor: 'black'}}
                onVisibleChange={visible => {
                  if (!visible) {
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 300);
                  }
                }}><div className='learn-more'>
                  <a
                    href={data?.callToActionType !== 'CALL' && data?.callToActionUrl}
                    onClick={() => {
                      if (data?.callToActionType === 'CALL') {
                        navigator.clipboard.writeText(phoneNumbers[0]);
                        setIsCopied(true);
                      }
                    }}
                    target={data?.callToActionType === 'CALL' ? '_self' : '_blank'}
                    rel='noreferrer'
                  >
                    {buttonOptions?.find(item => item.value === data?.callToActionType)?.label}
                  </a>
                </div></Tooltip>
            )}
          </div>
        </div>
      </PostPreviewContainer>}
      {modalType == 'QUESTION' && <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <FieldLabel>Question</FieldLabel>
          {data?.createdAt ? <ReviewDate>
            <FieldLabel className='created-date'>Created at {moment(data?.createdAt).format('MMM DD, YYYY')}</FieldLabel>
          </ReviewDate> : <></>}
        </div>
        <ContentContainer height={80}>
          {data?.content}
        </ContentContainer>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <FieldLabel>Answer</FieldLabel>
          <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            {!['reply', 'answer'].includes(edit) && <AIGenerateSection onClick={aiGenerateAnswer}><FontAwesomeIcon icon={faRotate} spin={loader == 'ai'}/> AI Generate another answer</AIGenerateSection>}
            {edit !== 'answer' && <AIGenerateSection onClick={() => onEdit('answer')}>
              <FontAwesomeIcon icon={faPen}/> Edit
            </AIGenerateSection>}
          </div>
        </div>
        {edit === 'answer' ? (
          <ContentContainer height={175} style={{padding: '2px'}}>
            <ReplyInput autoFocus ref={answerRef} placeholder='Answer...' value={data?.ownersAnswer?.content} onChange={e => onChangeInput(e?.target?.value, 'answer')}/>
          </ContentContainer>
        ) : (
          <ContentContainer height={175}>
            {data?.ownersAnswer?.content}
          </ContentContainer>
        )}
      </div>}
      {modalType == 'REVIEW' && <div>
        <FieldLabel>Review</FieldLabel>
        <div>
          <div style={{display: 'flex'}}>
            <img src={data?.reviewerPhotoUrl} width={28} height={28}/>
            <div style={{marginLeft: 5}}>
              <div style={{fontSize: 13}}>{data?.reviewerName}</div>
              <Rate style={{fontSize: 12, marginRight: 'unset'}} disabled value={data?.starRating} />
            </div>
          </div>
        </div>
        <div style={{textAlign: 'justify'}}>{data?.content}</div>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 28}}>
          <FieldLabel>Reply</FieldLabel>
          <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            {!['reply', 'answer'].includes(edit) && <AIGenerateSection onClick={aiGenerateReply}><FontAwesomeIcon icon={faRotate} spin={loader == 'ai'}/> AI Generate another reply</AIGenerateSection>}
            {edit !== 'reply' && <AIGenerateSection onClick={() => onEdit('reply')}>
              <FontAwesomeIcon icon={faPen}/> Edit
            </AIGenerateSection>}
          </div>
        </div>
        {edit === 'reply' ? <ContentContainer height={175} style={{padding: '2px'}}>
          <ReplyInput autoFocus ref={replyRef} placeholder='Reply...' value={data?.reply?.content} onChange={e => onChangeInput(e?.target?.value, 'reply')}/>
        </ContentContainer> : (
          <ContentContainer height={175}>
            {data?.reply?.content}
          </ContentContainer>
        )}
      </div>}
      <ModalFooter>
        {['reply', 'answer'].includes(edit) ? (
          <StyledButton
            loading={updating}
            disabled={updating}
            onClick={onUpdate}
            color='#18923B'
            padding='13px 50px 14px 50px'
            icon={<CheckOutlined/>}
          >Update {modalType == 'QUESTION' ? 'Answer' : 'Reply'}</StyledButton>
        ) : (
          <StyledButton loading={loader == 'update'} disabled={(loader && loader !== 'data')} onClick={approve} color='#18923B' padding='13px 50px 14px 50px' icon={<CheckOutlined />} >Approve {modalType == 'POST' ? 'Post' : modalType == 'QUESTION' ? 'Q&A' : 'Reply'}</StyledButton>
        )}
        {['reply', 'answer'].includes(edit) ? <StyledButton onClick={()=>{
          setData(JSON.parse(JSON.stringify(modalData)));
          setEdit('');
        }} color='#F44343' padding='13px 20px 14px 20px' icon={<CloseOutlined />} >Cancel</StyledButton> :
          <StyledButton loading={loader == 'delete'} disabled={(loader && loader !== 'data')} onClick={deleteTask}color='#F44343' padding='13px 20px 14px 20px' icon={<CloseOutlined />} >Delete</StyledButton>}
      </ModalFooter>
    </>
    }
  </>);
});

const PostPreviewContainer = styled.div`
  border: 1px solid #E8E8E8;
  background: white;
  border-radius: 12px;
  .post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }
  .logo-and-title {
    display: flex;
  }
  .title-date {
    margin-left: 10px;
  }
  .title-text {
    font-size: 14px;
    font-weight: 500;
  }
  .date-text {
    font-size: 13px;
    color: #4E5156;
  }
  .action {
    cursor: pointer;
  }
  .post-text {
    padding: 15px 22px;
    text-align: justify;
  }
  .footer {
    padding: 0 22px 15px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .learn-more {
    color: #2D6CCA;
    cursor: pointer;
    }
  .share {
    cursor: pointer;
    color: #4E5156;
    font-size: 16px;
  }
  .post-image-container {
    width: 100%;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .post-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; 
  }
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 11px;
  margin-top: 15px;
`;

const StyledButton = styled(Button)<{color: string; padding: string}>`
  height: 48px;
  border-radius: 8px;
  font-weight: 500;
  color: white;
  background-color: ${p => p.color};
  box-shadow: none;
  padding: ${p => p.padding};
  border: unset;
  
  &:hover, &:focus, &:active {
    color: white;
    padding: ${p => p.padding};
    background-color: ${p => p.color};
    border: unset;
    box-shadow: none;
  }
  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    color: white;
    padding: ${p => p.padding};
    box-shadow: none;
    background-color: ${p => p.color};
    border: unset;
  }
`;

const FieldLabel = styled.div`
  font-weight: 500;
  margin-bottom: 5px;
`;

const ContentContainer = styled.div<{height: number}>`
  height: ${p => p.height}px;
  max-height: ${p => p.height}px;
  overflow-y: auto;
  border-radius: 12px;
  background: white;
  border: 1px solid #D2D2D2;
  padding: 13px 15px;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const AIGenerateSection = styled.div`
  color: #2D6CCA;
  font-size: 12px;
  cursor: pointer;
`;
const ReviewDate = styled.div`
  display: flex;
  align-items: center;
  .created-date {
    font-size: 14px;
    font-weight: 400;
    color: #121212;
  }
`;
const ReplyInput = styled(TextArea)`
    height: 168px !important;
    resize: none;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
    padding: 13px 15px 13px 15px;
    &:active {
      border: none;
      box-shadow: none;
    }
    &:hover {
      border: none;
      box-shadow: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-track {
      background: #F9F9FB;
      border-radius: 10px;
    }
`;
