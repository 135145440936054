import {CommonInput} from '@/components/common-components';
import {observer} from 'mobx-react';
import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import styles from '../../style.module.scss';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  setTermsToInclude: (value?: string[]) => void;
  termsToInclude: string[];
  clearTermsToIncludeFromForm: () => void;
  usedTerms: string;
  setUsedTerms: (value: string) => void;
  contentType: number;
}

export const TermsToInclude = observer(({
  setTermsToInclude,
  termsToInclude,
  clearTermsToIncludeFromForm,
  usedTerms,
  setUsedTerms,
  contentType,
}: Props) => {
  const [showRemainingTerms, setShowRemainingTerms] = useState(false);

  const termsRef = useRef(null);

  // chekcs if focus terms input is in focus or not
  const myFunction = () => {
    if (document.activeElement === termsRef?.current?.input) {
      if (!usedTerms || termsToInclude?.includes(usedTerms.trim())) return;
      const updatedTerms = [...(usedTerms?.split(',') ?? []), ...termsToInclude];
      setTermsToInclude(updatedTerms);
      setUsedTerms('');
      clearTermsToIncludeFromForm();
    }
  };

  // adds event hendler on enter key press
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();

        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [usedTerms]);

  const onTermRemove = (term: string) => {
    const updatedTerms = [...termsToInclude].filter(item => item !== term);
    setTermsToInclude(updatedTerms);
  };


  return <div className={styles.termsToIncludeContainer}>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      {/* <p style={{marginBottom: 0, color: '#4E5156', marginTop: 0, fontSize: 12}}>{`Terms to Include`}</p> */}
      {/* <p style={{color: '#A3A4A4', marginTop: 0, marginBottom: 0, textDecoration: 'underline', cursor: 'pointer', fontSize: 12}} onClick={() => {
        setTermsToInclude([]);
        setShowRemainingTerms(false);
        clearTermsToIncludeFromForm();
      }}>
        Cancel 3
      </p> */}
    </div>
    <div style={{background: '#fff', borderRadius: 8, marginBottom: 20, border: '1px solid #E8E8E8'}}>
      <CommonInputStyled
        border={false}
        inputRef={termsRef}
        type='text'
        variant='white'
        value={usedTerms}
        placeholder='Enter term(s) or question(s), separated by commas, and hit enter'
        onChange={e => setUsedTerms(e.target.value)}
        className={styles.input} />
      <AddedTermsContainer>
        {termsToInclude?.slice(0, 8).map((item, idx) => {
          return <SingleAddedTerm key={idx}>{item}
            <FontAwesomeIcon
              icon={faXmark}
              style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
              onClick={() => onTermRemove(item)}
            />
          </SingleAddedTerm>;
        })}
        {(termsToInclude?.length > 9 && !showRemainingTerms) && <SingleAddedTerm style={{cursor: 'pointer'}} onClick={() => setShowRemainingTerms(true)}>
          {`+${termsToInclude.length - 9} related terms`}
          <FontAwesomeIcon
            icon={faCaretDown}
            style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
          />
        </SingleAddedTerm>}
        {showRemainingTerms && termsToInclude?.slice(9).map((item, idx) => {
          return <SingleAddedTerm key={idx}>{item}
            <FontAwesomeIcon
              icon={faXmark}
              style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
              onClick={() => onTermRemove(item)}
            />
          </SingleAddedTerm>;
        })}
        {termsToInclude?.length > 0 && <SingleAddedTerm style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => {
          setTermsToInclude([]);
          setShowRemainingTerms(false);
          clearTermsToIncludeFromForm();
        }}>
        Clear All
        </SingleAddedTerm>}
      </AddedTermsContainer>
    </div>
    {
      contentType === 2 && <p style={{fontSize: '12px'}}><span style={{fontWeight: '600'}}>Note: </span>When optimizing the length of landing page content, not all provided terms may be incorporated in order to maintain brevity and effectiveness.</p>
    }
  </div>;
});


const CommonInputStyled = styled(CommonInput)<{border?: boolean; boxShadow?: boolean}>`
  color: #121212 !important;
  border-radius: 6px !important;
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  ${p => !p.border && 'border: 1px solid transparent !important'};
  padding-right: 8px !important;

  &:focus {
    box-shadow: none !important;
  }
 
`;
const AddedTermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`;
const SingleAddedTerm = styled.div`
  background-color: #F9F9FB;
  padding: 3px 10px;
  border-radius: 38px;
  border: 1px solid  #e8e8e8;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
`;
