import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import styles from './style.module.scss';
import {NextImg} from '@/utils/nextImg';

interface Props {
  fullPage?: boolean;
}

export const MainLoader = observer(({fullPage}: Props) => {
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');
  const antIcon = <LoadingOutlined style={{fontSize: 50, color: '#7f4ead'}} spin />;

  return <div className={classNames(styles.masterLoaderWrapper, fullPage && styles.isFullPageMasterLoader)}>
    {!isWhitelabel ?
      <NextImg src='/img/mainLoader.gif' style={{width: 93, height: 150, margin: 'auto'}}/> :
      <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Spin indicator={antIcon}/></div>}
  </div>;
});
