import React, {useState} from 'react';
import {AddServiceAccountModal, EnableIndexingAccessCont, VerifyButton} from './styleComponents';
import {faArrowUpRightFromSquare, faCheck, faCopy} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {openUrl} from '@/utils/router';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {notification} from '@/utils/notification-v2';

const AddGscServiceAccModal = ({visible, setVisible, email}) => {
  const [urlCopySuccess, setUrlCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const {ottoV2Store: {
    getGscEmailStatus,
    getOttoV2Project,
  }, settings: {customer: {profile: {customerId}}}} = useStore('');

  const checkStatus = async () => {
    setLoading(true);
    const res = await getGscEmailStatus(customerId, getOttoV2Project?.connectedData?.gscDetails?.propertyUrl);
    if (res?.hasAccess) {
      notification.success('', 'Service account have access to GSC.');
      setVisible(false);
    } else {
      notification.error('', 'Service account do not have access to GSC.');
    }
    setLoading(false);
  };

  const onClose = () => {
    setVisible(false);
  };

  const videoUrl = 'https://www.loom.com/embed/99e1007cb8c74040a14463eb06dc5ea6?sid=92a020b5-aea4-4efc-b89f-c2e56f722c85&hideEmbedTopBar=true&t=0s';

  return (
    <AddServiceAccountModal
      visible={visible}
      onCancel={onClose}
      footer={false}
      closable={false}
      width={550}
      destroyOnClose
    >
      <div>
        <iframe
          width='100%'
          height='250'
          src={videoUrl}
          frameBorder='0'
          allowFullScreen
          style={{borderRadius: '8px'}}
        />
      </div>
      <EnableIndexingAccessCont>
        <div className='heading'>
          Enable indexing access
        </div>
        <div className='desc'>
          To be able to send index requests on your behalf, we need you to grant us “owner” access to your project’s Google Search Console.
        </div>
        <div className='copy-address-container'>
          <div className='label'>1. Copy your unique address</div>
          <div className='address-input'>
            <div className='txt'>{email?.serviceAccountEmail}</div>
            <div className='copy' onClick={() => {
              navigator.clipboard.writeText(`${email?.serviceAccountEmail}`);
              setUrlCopySuccess(true);
              setTimeout(() => {
                setUrlCopySuccess(false);
              }, 1000);
            }}>
              <Tooltip
                title={<>
                  {urlCopySuccess && <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{` Email copied`}</span>}
                </>}
                placement='bottom'
                visible={urlCopySuccess}
              >
                <FontAwesomeIcon icon={faCopy} /> Copy
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='add-to-gsc-container'>
          <div className='left-sec'>
            <div className='label'>2. Add it to your Google Search Console</div>
            <div className='point-2-desc'>
              In the permissions menu, click on <span className='bold'>Add user </span>, and add the copied email address with <span className='bold'>Owner </span> permissions.
            </div>
          </div>
          <div>
            <button className='open-gsc-button' onClick={() => openUrl('https://search.google.com/search-console/about', '_blank')}>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Open GSC
            </button>
          </div>
        </div>
        <div className='footer'>
          <button onClick={onClose} className='cancel-button'>Cancel</button>
          <VerifyButton onClick={checkStatus} loading={loading}>
            Verify & Enable access
          </VerifyButton>
        </div>
      </EnableIndexingAccessCont>
    </AddServiceAccountModal>
  );
};

export default observer(AddGscServiceAccModal);
