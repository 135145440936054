import React, {useState, Fragment, useEffect} from 'react';
import styles from './styles.module.scss';
import {Input, Select, Spin} from 'antd';
import {Search} from 'react-feather';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import styled from 'styled-components';

const {Option} = Select;

interface IOption {
  id: number;
  key: number;
  name: string;
  hostname: string;
  articlesCount: number;
  currentProject: number;
  syncedCount: number;
  settingIcon?: boolean;
  isLoading?: boolean;
}

const SelectWithSearchKrt = observer(({optionRender, currentProject, renderInputButton, ...props}: any) => {
  const [options, setOptions] = useState(props.data || []);
  const {navBar: {isDockedSider}} = useStore('');
  const antIcon = <LoadingOutlined style={{fontSize: 24, color: '#F1AA3E'}} spin />;
  const {t} = useTranslation('common');
  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setOptions(props.data.filter((option: IOption) => option.hostname.includes(e.target.value)));
    } else {
      setOptions(props.data?.slice());
    }
  };

  const onOptionChange = (selectedOptionValue: string, optionsArr: IOption) => {
    props.onOptionChange && props.onOptionChange(optionsArr.key);
  };

  // TODO: Please review what this does.
  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);


  return (
    <>
      <Select
        dropdownClassName={classNames(styles.PropertyDropDown, styles.KrtPropertyDropDown, !isDockedSider ? styles.leftSpacing : '')}
        className={classNames(styles.SelectWithSearch, styles.KrtSelectWithSearch)}
        onChange={onOptionChange}
        value={props.selectedValue}
        placeholder={currentProject ? currentProject : 'All Projects'}
        {...props}
        dropdownRender={menu => (
          <Fragment>
            <div onClick={e=> e.stopPropagation()}
              className={styles.topDiv}>
              <Input className={styles.SearchField}
                placeholder={t('Search...')}
                onChange={onSearchHandler}
                prefix={<Search width={15}/>}
                bordered={false}
              />
              {renderInputButton ? renderInputButton() : null}
            </div>
            {menu}
          </Fragment>
        )}
      >
        {/* <Option
          className={styles.Option}
          onClick={e => e.stopPropagation()}
        >
          {defaultOption()}
        </Option> */}
        {options?.map((option: IOption) => {
          return (
            <Option
              className={styles.KrtOption}
              key={option.id}
              value={option.id}
              // disabled={!option.articlesCount}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {optionRender(option)}
            </Option>
          );
        })}
        {props.isLoading ? <span style={{paddingTop: '5px'}}> <LoaderSpan indicator={antIcon} /></span> : ''}
      </Select>
    </>
  );
});

export default SelectWithSearchKrt;

const LoaderSpan = styled(Spin)`
  display: flex;
  justify-content: center;
`;

