import React from 'react';
import styles from './index.module.scss';

export interface SerpUrlColumnProps {
  title: string;
  page: string;
}

export const SerpUrlColumn: React.FC<SerpUrlColumnProps> = ({
  title,
  page,
}) => {
  return (
    <div className={styles.serpUrl}>
      <div className={styles.url}>
        <div className={styles.title}>{title}</div>
        <a
          href={page}
          rel='noopener noreferrer'
          target='_blank'
          className={styles.page}
        >
          {page}
        </a>
      </div>
    </div>
  );
};
