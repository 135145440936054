import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {OneClickDraftForm} from './1-click-draft/form';
import {useState} from 'react';
import styled from 'styled-components';
import {Results} from './new-ai-writer/Results';
import {CompleteSeoArticleForm} from './complete-seo-article/form';
import {NewAiWriter} from './new-ai-writer/form';

interface Props {
  htmlToDraft: any;
  elapsedTime:number;
  setElapsedTime?: (value: number) => void;
}

export const FormsView = observer(({htmlToDraft, elapsedTime, setElapsedTime}: Props) => {
  const {contentOptimizer: {
    currentPage,
    aiOutline: {
      isOpenAiWriterDrawer,
      aiWriterController,
    },
  },
  } = useStore('');

  const [selectedContentLength, setSelectedContentLength] = useState<'short' | 'medium' | 'long'>('medium');
  const [selectedToneOfVoice, setSelectedToneOfVoice] = useState('Professional');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedPointOfView, setSelectedPointOfView] = useState('third person');
  const [isContentOverWritten, setIsContentOverwritten] = useState(false);

  const viewSwitcher = () => {
    let output = <OneClickDraftForm
      drawerVisible={isOpenAiWriterDrawer}
      pageId={currentPage?.content?.uuid}
      selectedContentLength={selectedContentLength}
      setSelectedContentLength={setSelectedContentLength}
      selectedToneOfVoice={selectedToneOfVoice}
      setSelectedToneOfVoice={setSelectedToneOfVoice}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      selectedPointOfView={selectedPointOfView}
      setSelectedPointOfView={setSelectedPointOfView}
      htmlToDraft={htmlToDraft}
      setIsContentOverwritten={setIsContentOverwritten}
    />;
    switch (aiWriterController?.templateUsed) {
      case 'complete-seo-article':
        output = <CompleteSeoArticleForm
          drawerVisible={isOpenAiWriterDrawer}
          pageId={currentPage?.content?.uuid}
          selectedContentLength={selectedContentLength}
          setSelectedContentLength={setSelectedContentLength}
          selectedToneOfVoice={selectedToneOfVoice}
          setSelectedToneOfVoice={setSelectedToneOfVoice}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedPointOfView={selectedPointOfView}
          setSelectedPointOfView={setSelectedPointOfView}
          htmlToDraft={htmlToDraft}
          setIsContentOverwritten={setIsContentOverwritten}
        />;
        break;
      case 'new-ai-writer':
        output = <NewAiWriter
          drawerVisible={isOpenAiWriterDrawer}
          pageId={currentPage?.content?.uuid}
          selectedContentLength={selectedContentLength}
          setSelectedContentLength={setSelectedContentLength}
          selectedToneOfVoice={selectedToneOfVoice}
          setSelectedToneOfVoice={setSelectedToneOfVoice}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedPointOfView={selectedPointOfView}
          setSelectedPointOfView={setSelectedPointOfView}
          htmlToDraft={htmlToDraft}
          setIsContentOverwritten={setIsContentOverwritten}
          setElapsedTime={setElapsedTime}
        />;
        break;
      default:
        output = <OneClickDraftForm
          drawerVisible={isOpenAiWriterDrawer}
          pageId={currentPage?.content?.uuid}
          selectedContentLength={selectedContentLength}
          setSelectedContentLength={setSelectedContentLength}
          selectedToneOfVoice={selectedToneOfVoice}
          setSelectedToneOfVoice={setSelectedToneOfVoice}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedPointOfView={selectedPointOfView}
          setSelectedPointOfView={setSelectedPointOfView}
          htmlToDraft={htmlToDraft}
          setIsContentOverwritten={setIsContentOverwritten}
        />;
        break;
    }

    return output;
  };


  return <Wrapper>
    <FormsContainer>
      {viewSwitcher()}
    </FormsContainer>

    <ResultsContainer>
      {/* renders results and history tabs */}
      <Results htmlToDraft={htmlToDraft} selectedContentLength={selectedContentLength} isContentOverWritten={isContentOverWritten} setIsContentOverwritten={setIsContentOverwritten} elapsedTime={elapsedTime} />
    </ResultsContainer>
  </Wrapper>;
});

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;
const ResultsContainer = styled.div`
  padding: 25px 25px 25px 25px;
  width: 6000px;
`;
const FormsContainer = styled.div`
  width: 6000px;
  height: 100%;
  overflow-y: auto;
  padding: 0px 4px 0px 0px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
            
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(138,138,138,0.32);
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: rgba(138,138,138,0.12);
    }
`;
