import styles from './index.module.scss';

interface Props {
  width?: number;
  centered?: boolean;
}

export const Loader = ({width, centered}: Props) => {
  return (
    <div className={styles.sidebarLoader} style={{width: width ? `${width}px` : 'auto', margin: centered && 'auto'}}>
      <div className={styles.loaderSpinner} />
    </div>
  );
};
