import {types,
  Instance,
  flow, cast} from 'mobx-state-tree';
import {issueAnalyzerApi} from '@/api/account';
import {toJS} from 'mobx';

const sitemapsModel = types.model({
  count: types.maybeNull(types.number),
  values: types.maybeNull(types.array(types.string)),
});

const sitemapUrlsValuesModel = types.model({
  pk: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  statusCode: types.maybeNull(types.number),
  isGoogleIndexableInRobotstxt: types.maybeNull(types.boolean),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  depth: types.maybeNull(types.number),
  avgPosition: types.maybeNull(types.number),
  crawled: types.maybeNull(types.boolean),
});

const sitemapUrlsModel = types.model({
  count: types.maybeNull(types.number),
  values: types.maybeNull(types.array(sitemapUrlsValuesModel)),
});

export type sitemapUrlsType = Instance<typeof sitemapUrlsModel>;


const headerModel = types.model({
  sitemaps: types.maybeNull(sitemapsModel),
  sitemapUrls: types.maybeNull(sitemapUrlsModel),
  nonIndexable: types.maybeNull(sitemapsModel),
  onlyInSitemaps: types.maybeNull(sitemapsModel),
  urlsNotInSitemaps: types.maybeNull(sitemapUrlsModel),
});

export type HeaderType = Instance<typeof headerModel>;

const urlsModel = types.model({
  sitemapUrls: types.maybeNull(sitemapUrlsModel),
  nonIndexable: types.maybeNull(sitemapsModel),
  onlyInSitemaps: types.maybeNull(sitemapsModel),
  urlsNotInSitemaps: types.maybeNull(sitemapUrlsModel),
  indexable: types.maybeNull(sitemapUrlsModel),
});

const xmlSitemapsModel = types.model({
  sitemapUrl: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  successful: types.maybeNull(types.number),
  broken: types.maybeNull(types.number),
  nonIndexed: types.maybeNull(types.number),
  urls: types.maybeNull(types.array(sitemapUrlsValuesModel)),

});

export const XmlSitemapsReportStore = types.model({
  headers: types.maybeNull(headerModel),
  urls: types.maybeNull(urlsModel),
  xmlSitemaps: types.maybeNull(types.array(xmlSitemapsModel)),
  loading: types.boolean,
}).views( self => ({
  get getHeaders() {
    return toJS(self.headers);
  },
  get getUrls() {
    return toJS(self.urls);
  },
  get getXmlSitemaps() {
    return toJS(self.xmlSitemaps);
  },
})).actions(self => {
  const loadXmlSitemapsReport = flow(function* (domain: string) {
    self.loading = true;
    try {
      const response = yield issueAnalyzerApi.xmlSitemaps(domain);
      if (response) {
        self.headers = response.headers;
        self.urls = response.urls;
        self.xmlSitemaps = cast(response.xmlSitemaps);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  return {
    loadXmlSitemapsReport,
  };
});

export function initXmlSitemapsReportStore() {
  return XmlSitemapsReportStore.create({
    loading: true,
  });
}
