import React, {useState, useEffect, useCallback, useRef} from 'react';
import RadioButton from '../radio-button';
import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import Button from './../button-component/';

interface Props {
  type?: string;
  add?:boolean;
  filtersLength?:number;
  filterId?: number;
  filterLabel?: string;
  filterName?: string;
  onClose?: ()=>void;
  removeItem?: ()=>void;
  onChange?: (filter:any)=>void;
  className?: string;
  initialFrom?: string;
  initialTo?: string;
  isClear?: boolean;
  onClear?: ()=>void;
  absoluteUi?: boolean;
  isPageExplorer?: boolean;
  initialEqual?: string;
  isIssuePage?: boolean;

}

const FilterPopUp: React.FC<Props> = ({removeItem, type, absoluteUi=false, filterId, filterLabel, filterName, onClose, onChange, className, initialFrom, initialTo, isClear=false, onClear, isPageExplorer, initialEqual, isIssuePage}) => {
  const [value, setValue] = useState(type || 'between');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [eq, setEq] = useState('');
  const popupRef = useRef<HTMLInputElement>();
  const {t} = useTranslation('common');
  useEffect(() => {
    if (initialFrom) {
      setFrom(initialFrom);
    }
    if (initialTo) {
      setTo(initialTo);
    }
    if (type) {
      setValue(type);
    }
    if (initialEqual || (filterLabel== 'depth' && isPageExplorer)) {
      setFrom('');
      setEq(initialEqual);
    }
  }, []);

  const onChangeValue = useCallback(
    e => setValue(e.target.value),
    [],
  );

  const createOrUpdateFilter = e =>{
    e.preventDefault();
    emitOnChanges(from, to, eq);
    onClose();
  };

  const onCancel = () => {
    if (initialFrom || initialTo) {
      removeItem();
    }
    onClose();
  };

  const emitOnChanges = (from: string, to: string, eq: string) => {
    if (typeof onChange === 'function') {
      if (['orphaned', 'equal'].includes(value)) {
        const makeObj = {
          id: filterId,
          header: filterLabel,
          name: filterName,
          type: value,
          equal: eq ? eq : null,
          active: true,
        };
        onChange(makeObj);
      } else {
        const makeObj = {
          id: filterId,
          header: filterLabel,
          name: filterName,
          type: value,
          from: !from ? '0' : from,
          to,
          active: true,
        };
        onChange(makeObj);
      }
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const disablingButton = () =>{
    if (value === 'between' && (+from > +to) ) return true;
    if (value === 'between' && (!from || !to) ) return true;
    if (value ==='higher' && !from) return true;
    if (value === 'lower' && !to) return true;
    if (value === 'equal'&&filterLabel=='depth'&& !eq) return true;
    // if (filterLabel=='depth'&& eq) return false;
    if ((initialFrom || initialTo) && (to ===initialTo && from ===initialFrom)) return true;
    return false;
  };

  useEffect(() => {
    if (!type) {
      setTo('');
      setFrom('');
      setEq('');
    }
  }, [value]);

  return (
    <div
      ref={popupRef}
      style={absoluteUi ? {position: 'absolute'} : {}}
      onSubmit={createOrUpdateFilter}
      onClick={e=> e.stopPropagation() }
      // style={((add && filtersLength < 4 )|| width< 1000) ? {right: '-145%', left: 'unset'}:{}}
      className={`${styles.filterPopup} ${className}`}>
      <div className={styles.filterPopupOptions}>
        {isClear ? <div className={styles.clearBtn} onClick={()=>{
          setEq('');
          onClear();
        }}>Clear</div> : ''}
        <div className={styles.filterPopupOptionsOption}>
          <RadioButton
            label='between'
            radioValue='between'
            radioChecked={value ==='between'}
            handleChange={onChangeValue}
          />
          {value === 'between' && (
            <div className={styles.filterPopupOptionsBetween}>
              <input
                value={from}
                onChange={e=> setFrom(e.target.value.replace(/\D/g, ''))}
              /> and <input
                value={to}
                onChange={e=> setTo(e.target.value.replace(/\D/g, ''))}
              />
            </div>
          )}
        </div>
        <div>
          <RadioButton
            label='greater than'
            radioValue='higher'
            radioChecked={value ==='higher'}
            handleChange={onChangeValue}
          />
          {value === 'higher' && (
            <div className={styles.filterPopupOptionsOther}>
              <input
                value={from}
                onChange={e=> setFrom(e.target.value.replace(/\D/g, ''))}
              />
            </div>
          )}
        </div>
        <div>
          <RadioButton
            label='less than'
            radioValue='lower'
            radioChecked={value ==='lower'}
            handleChange={onChangeValue}
          />
          {value === 'lower' && (
            <div className={styles.filterPopupOptionsOther}>
              <input
                value={to}
                onChange={e=> setTo(e.target.value.replace(/\D/g, ''))}
              />
            </div>
          )}
        </div>
        {
          ((filterLabel == 'depth') || (isIssuePage &&(filterLabel == 'Page Health' || filterLabel == 'Page Rank'))) && <div>
            <RadioButton
              label='equals to'
              radioValue='equal'
              radioChecked={value ==='equal'}
              handleChange={onChangeValue}
            />
            {value === 'equal' && (isPageExplorer || isIssuePage) &&(
              <div className={styles.filterPopupOptionsOther}>
                <input
                  value={eq}
                  onChange={e=> setEq(e.target.value.replace(/\D/g, ''))}
                />
              </div>
            )}
          </div>
        }
        {filterLabel == 'depth' && isPageExplorer ? <div>
          <RadioButton
            label='Orphaned pages'
            radioValue='orphaned'
            radioChecked={value ==='orphaned'}
            handleChange={onChangeValue}
          />
        </div> : ''}
      </div>

      <div className={styles.filterPopupButtons}>
        <Button
          color='gray'
          onClick={onCancel}
        >
          { (initialFrom || initialTo)?t('remove'): t('cancel')}
        </Button>
        <Button
          style={disablingButton() ? 'light-transparent' : 'solid'}
          disabled={disablingButton()}
          onClick={createOrUpdateFilter}
        >
          {t('apply-filter')}
        </Button>
      </div>
    </div>
  );
};

export default FilterPopUp;
