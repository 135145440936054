import {Button} from '@/components/common-components/v2/Button';
import {faTrash, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Input} from 'antd';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
interface Props {
  findData?: any;
  isNewRecipient?: boolean;
  teamEamail?: any[];
  setVisibleTooltip?: (value) => void;
  handleAddRecipients?: (value) => any;
  isNewEmailSettings?: boolean;
}

export const RecipientsTooltip: React.FC<Props> = observer(({findData, isNewRecipient = false, teamEamail, setVisibleTooltip, handleAddRecipients=()=>{}, isNewEmailSettings}) => {
  const {siteAuditorV2: {
    siteAuditSelectedProperty,
    emailSettings: {
      updateEmailAlert,
      setUpdatedList,
      updatedList,
    },
  }} = useStore('');
  const router = useRouter();
  const projectId = (siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || router.query?.id))?.toString();

  const [emailInputValue, setEmailInputValue] = useState('');
  const [emailError, setEmailError] = useState('');
  const [recipientsList, setRecipientsList] = useState([]);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  useEffect(() => {
    setUpdatedList(JSON.stringify(findData?.recipients?.map(email => {
      return {
        recipient: email,
        isActive: true,
      };
    })));
  }, [findData]);

  useEffect(() => {
    const filterArray = findData?.recipients?.map(email => {
      return {
        recipient: email,
        isActive: true,
      };
    });
    const newTeamEmail = teamEamail?.filter(i => !findData?.recipients?.includes(i?.recipient));
    const newReceipientList = findData?.recipients?.length ? filterArray?.concat(newTeamEmail) : newTeamEmail;
    setRecipientsList(newReceipientList);
  }, [findData?.recipients?.length, teamEamail]);

  const handleAddRecipientsList = value => {
    if (value) {
      setRecipientsList(recipientsList => [...recipientsList, {isActive: true, recipient: value}]);
      setEmailInputValue('');
    }
  };
  function areEqual(array1, array2) {
    if (array1?.isActive == array2?.isActive) {
      return true;
    } else {
      return false;
    }
  }

  function getDiffList(a, b) {
    const matches = [];
    const notMatch = [];

    for (let i = 0; i < a?.length; i++) {
      for (let e = 0; e < b?.length; e++) {
        if (a[i]?.recipient == b[e]?.recipient) {
          const testArr = areEqual(a[i], b[e]);
          if (testArr) {
            matches?.push(b[e]?.recipient);
          } else {
            notMatch?.push(b[e]?.recipient);
          }
        }
      }
    }
    return notMatch;
  }

  function getDiffRecipientList(a) {
    const matches = [];

    for (let i = 0; i < a?.length; i++) {
      if (a[i]?.isActive ) {
        matches?.push(a[i]?.recipient);
      }
    }
    return matches;
  }


  useEffect(()=>{
    if (getDiffRecipientList(recipientsList)?.length > 0) {
      setDisableAdd(false);
    } else {
      setDisableAdd(true);
    }
  }, [JSON.stringify(recipientsList)]);

  useEffect(() => {
    if (recipientsList?.length !== findData?.recipients?.length || (updatedList && getDiffList(recipientsList, JSON.parse(updatedList))?.length)) {
      setDisableUpdate(false);
    } else {
      setDisableUpdate(true);
    }
  }, [recipientsList?.length, findData?.recipients?.length, JSON.stringify(recipientsList)]);
  const handleRemoveRecipientsList = value => {
    const filterList = recipientsList?.filter(item => item?.recipient != value);
    setRecipientsList(filterList);
  };

  useEffect(() => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const checkEmail = emailInputValue?.match(emailRegex);
    if (emailInputValue && !checkEmail) {
      setEmailError('Enter a valid Email.');
    } else {
      setEmailError('');
    }
  }, [emailInputValue]);

  const addRecepient = async () => {
    if (findData) {
      const listData = recipientsList?.filter(z => z?.isActive)?.map(item => item?.recipient);
      const formateData = {
        id: findData?.id,
        audit: projectId,
        alert_type: findData?.alertType,
        alert_subtype: findData?.alertSubtype,
        recipients: listData,
      };
      if (isNewEmailSettings) {
        handleAddRecipients(listData);
      } else {
        await updateEmailAlert(formateData);
      }
      setVisibleTooltip(false);
      setEmailInputValue('');
    }
  };

  const handleCheckBox = (checked, value) => {
    const newArr = [...recipientsList];
    const obj = newArr?.find(item => item?.recipient == value);
    obj.isActive = checked;
    setRecipientsList(newArr);
  };
  return <div>
    <MainWrapper style={{maxHeight: '250px'}}>
      {recipientsList?.map((z, i) => {
        return <WrappercheckBox key={`popover_${i}`} style={{display: 'flex'}}>
          <Checkbox style={{width: '100%'}} checked={z?.isActive} onChange={e => handleCheckBox(e.target?.checked, z?.recipient)}>
            <div>
              <TableStr>{z?.recipient?.split('@')[0]}</TableStr>
              <EmailSubTitle>{z?.recipient}</EmailSubTitle>
            </div>
          </Checkbox>
          {findData?.recipients?.find(item => item?.recipient == z?.recipient) ? '' :
            <TrashIcon className='icon' onClick={() => handleRemoveRecipientsList(z?.recipient)} icon={faTrash} />}
        </WrappercheckBox>;
      })}
    </MainWrapper>
    <div style={{padding: '8px 8px 8px 15px'}}>
      <div>
        <EmailSubTitle style={{marginBottom: 5}}>New recipient</EmailSubTitle>
        <div style={{display: 'flex'}}>

          <Input value={emailInputValue} onChange={e => setEmailInputValue(e.target?.value)} placeholder='example@mail.com' style={{borderRadius: '5px', border: '1px solid #D2D2D2'}} />
          <AddButton disabled={!emailInputValue || !!emailError} onClick={() => handleAddRecipientsList(emailInputValue)} size='small'><FontAwesomeIcon icon={faPlus} /></AddButton>
        </div>
        {emailError ? <div style={{color: '#f44343', fontSize: 12, fontWeight: 500, marginTop: '2px'}}>{emailError}</div> : ''}
        <Footer>
          <CancelButton size='small' background='#fff' onClick={() => {
            setVisibleTooltip(false);
            // setRecipientsList(findData?.emailRecipients?.slice() ?? []);
          }}>Cancel</CancelButton>
          {isNewRecipient ? <UpdateButton onClick={() => addRecepient()} size='small' disabled={recipientsList?.length < 1 || disableAdd}>Update List</UpdateButton> :
            <UpdateButton onClick={() => addRecepient()} disabled={disableUpdate}>Update List </UpdateButton>}
        </Footer>
      </div>
    </div>
  </div>;
});

const TrashIcon = styled(FontAwesomeIcon)`
  color: #A3A4A4;
  font-size: 14px; 
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;
`;

const AddButton = styled(Button)`
  height: 32px;
  padding: 2px 10px;
  width: fit-content;
  color: #7F4EAD;
  background-color: #7F4EAD26;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
  padding-top: 10px;
`;

const WrappercheckBox = styled.div`
  width: '100%';
  padding: '5px 0 5px 8px';

  &:hover {
    .icon {
      display: inline;
    }}
    .icon{
      display: none;
    }
`;

const UpdateButton = styled(Button)`
  width: fit-content;
  height: 32px;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
`;

const CancelButton = styled(Button)`
  width: fit-content;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  color: #4E5156;
  margin-right: 5px;
  padding: 3px 10px;
`;

const TableStr = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
  text-transform: capitalize;
`;

const EmailSubTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4E5156;
  margin-top: 4px;
`;

const MainWrapper = styled.div`
  overflow: auto;
  &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
`;
