import {types, Instance, cast, flow, getParent} from 'mobx-state-tree';
import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import type {GetDistributionResponse} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {BannerVariant} from '@/components/common-components/components/banner';
import {BackLinkAnalyzerStoreType} from '.';
import {TaskStatus} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {
  BACKLINK_API_API_POLL_INTERVAL,
  BACKLINK_API_TIME_INTERVAL,
} from '@/constants';

export interface DistributionChartItem {
  name: string;
  value: number;
  valueFormat: string;
  color: string;
}

export type TargetValueType = {
  target: string;
  value: string;
};

export const AnchorDistributionItem = types.model({
  category: types.maybeNull(types.string),
  result: types.maybeNull(types.number),
  targetValue: types.maybeNull(types.string),
  targetLabel: types.maybeNull(types.string),
});
export type AnchorDistributionItemType = Instance<typeof AnchorDistributionItem>;

export const AnchorDistributionChartItem = types.model({
  name: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  valueFormat: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
});
export type AnchorDistributionChartItemType = Instance<typeof AnchorDistributionChartItem>;

export const AnchorDistributionPage = types.model({
  path: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  pct: types.maybeNull(types.number),
});
export type AnchorDistributionPageType = Instance<typeof AnchorDistributionPage>;

export const UrlToDistributionSummary = types.model({
  homepage: types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }),
  nonhomepage: types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }),
  topPages: types.array(AnchorDistributionPage),
});
export type UrlToDistributionSummaryType = Instance<typeof UrlToDistributionSummary>;

export const UrlToDistribution = types.model({
  summary: UrlToDistributionSummary,
});
export type UrlToDistributionType = Instance<typeof UrlToDistribution>;

export const AnchorDistributionStat = types.model({
  name: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  percentage: types.maybeNull(types.number),
  highFreq: types.maybeNull(types.array(types.model({
    anchor: types.maybeNull(types.string),
    count: types.maybeNull(types.number),
  }))),
  label: types.maybeNull(types.string),
  target: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
});
export type AnchorDistributionStatType = Instance<typeof AnchorDistributionStat>;


export const AnchorDistribution = types.model({
  stats: types.array(AnchorDistributionStat),
});
export type AnchorDistributionType = Instance<typeof AnchorDistribution>;

export const ProjectDistribution = types.model({
  domainName: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  urltoDistribution: UrlToDistribution,
  anchorDistribution: AnchorDistribution,
});

export type ProjectDistributionType = Instance<typeof ProjectDistribution>;

export const DomainAuthorityChart = types.model({
  hostname: types.maybeNull(types.string),
  data: types.array(types.number),
});

export const AnchorTextDistributionStore = types.model({
  loading: types.boolean,
  projectsDistribution: types.array(ProjectDistribution),
  selectedProjectDistribution: types.maybeNull(types.late(() => types.reference(ProjectDistribution, {
    set(value) {
      return value.domainName;
    },
    get(identifier, parent) {
      return parent.projectsDistribution.find(project => project.domainName === identifier) || null;
    },
  }))),
  daChart: types.array(DomainAuthorityChart),
  tasksComplete: types.optional(types.boolean, false),
  drawer: types.boolean,
  drawerTab: types.maybeNull(types.string),
  reTry: types.optional(types.number, 0),
}).actions(self => {
  const loadDistribution = flow(function* (id: number, hostname: string) {
    const parent = getParent(self) as any;
    const parentBL = getParent<BackLinkAnalyzerStoreType>(self);
    self.loading = true;
    try {
      const data = <GetDistributionResponse>(yield BacklinkAnalyzerClient.getDistribution(id, hostname));
      self.tasksComplete = data.backlinkTaskStatus == TaskStatus.SUCCESS;
      self.reTry = cast(self.reTry + 1);
      if (!self.tasksComplete && self.reTry >= BACKLINK_API_TIME_INTERVAL) {
        parentBL.setBanner(true, 'Anchor Text Distribution not actualized.', 'The Anchor Text data did not load properly. To fix the issue:', BannerVariant.ERROR);
        self.reTry = 0;
        return data;
      } else if (!self.tasksComplete) {
        yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
        return loadDistribution(id, hostname);
      } else {
        self.reTry = 0;
      }
      const richProjects = data?.projects?.map(project => ({...project, color: parent.projectStore.currentProjectColorScheme[project.domainName]}));
      const primaryProject = richProjects?.find(project => project.domainName === hostname);
      self.projectsDistribution = cast(richProjects?.filter(project => project.anchorDistribution?.stats));
      if (primaryProject?.anchorDistribution?.stats) {
        self.selectedProjectDistribution = ProjectDistribution.create(primaryProject);
      }
      self.daChart = cast(data?.domainRating);
    } catch (e) {
      parentBL.setBanner(true, 'Anchor Text Distribution not actualized.', 'The Anchor Text data did not load properly. To fix the issue:', BannerVariant.ERROR);
      Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  const changeSelectedProjectDistribution = (hostname: string) => {
    const project = self.projectsDistribution.find(project => hostname === project.domainName);
    if (project) {
      self.selectedProjectDistribution = project;
    }
  };

  const openDrawer = tab => {
    self.drawerTab = tab;
    self.drawer = true;
  };

  const closeDrawer = () => {
    self.drawer = false;
    self.drawerTab = '';
  };


  return {
    loadDistribution,
    changeSelectedProjectDistribution,
    openDrawer,
    closeDrawer,
  };
});

export function initAnchorTextDistributionStore() {
  return {
    loading: true,
    drawer: false,
    projectsDistribution: [],
    selectedProjectDistribution: null,
  };
}
