import {Instance, types, flow, cast} from 'mobx-state-tree';
import {FOCUS_TERMS_API} from '@/api/content-optimizer';
import {filterDataInMemory} from '@/utils/filter-data';
import {toJS} from 'mobx';


export const sitesModel = types.model({
  url: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
});

export const competitorsModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(sitesModel)),
});

export const KeywordMetric = types.model({
  name: types.maybeNull(types.string),
  totalScore: types.maybeNull(types.number),
  normalizedScore: types.maybeNull(types.number),
  idealCountMin: types.maybeNull(types.number),
  idealCountMax: types.maybeNull(types.number),
  isBranded: types.maybeNull(types.boolean),
  variations: types.maybeNull(types.array(types.string)),
  competitors: types.maybeNull(competitorsModel),
});
export type KeywordMetricType = Instance<typeof KeywordMetric>;


export const SerpMetric = types.model({
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  contentScore: types.maybeNull(types.number),
});
export type SerpMetricType = Instance<typeof SerpMetric>;

const filtersModel = types.model({
  from: types.maybeNull(types.number),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.number),
});

export const FocusTermsPage = types.model({
  loading: types.optional(types.boolean, true),
  error: types.optional(types.boolean, false),
  serpStatus: types.maybeNull(types.string),
  exportQueryTerm: types.maybeNull(types.string),
  keywordStatus: types.maybeNull(types.string),
  keywordMetrics: types.array(KeywordMetric),
  serpMetrics: types.array(SerpMetric),
  pollingActive: types.maybeNull(types.boolean),

  queryTerm: types.string,
  countrySelected: types.string,
  languageSelected: types.string,

  keywordPageNumber: types.number,
  keywordPageSize: types.number,
  keywordSortField: types.maybeNull(types.string),
  keywordSortDirection: types.maybeNull(types.string),
  keywordFilteredDataSize: types.maybeNull(types.number),
  keywordTerrm: types.maybeNull(types.string),
  keywordFiltersList: types.maybeNull(types.array(filtersModel)),
  keywordFilterColumn: types.maybeNull(types.array(types.string)),
  keywordDisplatedTableData: types.maybeNull(types.array(KeywordMetric)),


  serpPageNumber: types.number,
  serpPageSize: types.number,
  serpSortField: types.maybeNull(types.string),
  serpSortDirection: types.maybeNull(types.string),
  serpFilteredDataSize: types.maybeNull(types.number),
  serpTerrm: types.maybeNull(types.string),
  serpFiltersList: types.maybeNull(types.array(filtersModel)),
  serpFilterColumn: types.maybeNull(types.array(types.string)),
  serpDisplatedTableData: types.maybeNull(types.array(SerpMetric)),
  searchTerm: types.array(types.string),

}).views(self => ({
  get getTableData() {
    return self.keywordDisplatedTableData;
  },

  get getSerpTableData() {
    return self.serpDisplatedTableData;
  },
  get getSearchTerm() {
    return self.searchTerm.slice();
  },
}))
  .actions(self => {
    const setFromResponse = data => {
      self.serpStatus = data.serps.status;
      self.keywordStatus = data.focusTerms.status;
      self.serpMetrics = data.serps.results;
      self.keywordMetrics = data.focusTerms.results;
    };
    const retrieveFocusTerms = flow(function* () {
      try {
        const response = yield FOCUS_TERMS_API.queryTerms(self.queryTerm.trim(), `country${self.countrySelected}`, `lang_${self.languageSelected}`);
        if (response?.serps?.status == null || response?.serps?.status == 'PENDING' || response?.focusTerms?.status == null || response?.focusTerms?.status == 'PENDING') {
          self.pollingActive = true;
          yield new Promise(r => setTimeout(r, 2000));
          return retrieveFocusTerms();
        } else {
          self.loading = true;
          self.pollingActive = false;
          self.error = false;
          setFromResponse(response);
          handleSerpFilterRenderData(self.serpTerrm, self.serpFiltersList, self.serpPageNumber, self.serpPageSize, self.serpSortField, self.serpSortDirection);
          handleFilterRenderData(self.keywordTerrm, self.keywordFiltersList, self.keywordPageNumber, self.keywordPageSize, self.keywordSortField, self.keywordSortDirection);
          self.loading = false;
          self.exportQueryTerm = self.queryTerm.trim();
          setqueryTerm('');
        }
      } catch (e) {
        self.exportQueryTerm = self.queryTerm.trim();
        setqueryTerm('');
        if (e?.response?.status == 429) {
          self.error = true;
        }
        self.loading = false;
        self.pollingActive = false;
        return Promise.reject(e);
      }
    });
    const refetchFocusTerms = flow(function* () {
      try {
        const response = yield FOCUS_TERMS_API.queryTerms(self.queryTerm, `country${self.countrySelected}`, `lang_${self.languageSelected}`);
        setFromResponse(response);
      } catch (e) {
        self.error = true;
        self.loading = false;
      }
    });

    const searchFocusTerms = flow(function* () {
      try {
        const response = yield FOCUS_TERMS_API.searchTerms();
        self.searchTerm = cast(response.searches);
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const setqueryTerm = (queryTerm: string) => {
      self.queryTerm = queryTerm;
    };
    const setCountrySelected = (countryCode: string) => {
      self.countrySelected = countryCode;
    };
    const setLanguageSelected= (languageCode: string) => {
      self.languageSelected = languageCode;
    };

    const handleFilterRenderData = (filterKeywordTerm, filters, pageNumber, pageSize, sortField, sortDirection) => {
      const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.keywordMetrics, self.keywordTerrm, self.keywordFilterColumn, filters, pageNumber, pageSize, sortField, sortDirection);

      self.keywordFilteredDataSize = dataFilteredSize;
      self.keywordDisplatedTableData.length = 0;
      const newArray = dataFiltered.map(item => {
        return {
          name: item.name,
          totalScore: item.totalScore,
          normalizedScore: item.normalizedScore,
          idealCountMin: item.idealCountMin,
          idealCountMax: item.idealCountMax,
          isBranded: item.isBranded,
          variations: toJS(item.variations),
          competitors: toJS(item.competitors),
        };
      });
      self.keywordDisplatedTableData = cast([...newArray]);
    };

    const handleKeywordPaginationChange = (pageNumber: number, pageSize: number) => {
      self.keywordPageNumber = pageNumber;
      self.keywordPageSize = pageSize;
      handleFilterRenderData(self.keywordTerrm, self.keywordFiltersList, self.keywordPageNumber, self.keywordPageSize, self.keywordSortField, self.keywordSortDirection);
    };

    const handleOnTableChange = (pagination, filters, sorter) => {
      const {column, order} = sorter;
      const {sortFieldName} = column || {};
      self.keywordSortField = sortFieldName;
      self.keywordSortDirection = order;
      handleFilterRenderData(self.keywordTerrm, self.keywordFiltersList, self.keywordPageNumber, self.keywordPageSize, self.keywordSortField, order);
    };

    const handleSerpFilterRenderData = (filterKeywordTerm, filters, pageNumber, pageSize, sortField, sortDirection) => {
      const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.serpMetrics, self.serpTerrm, self.serpFilterColumn, filters, pageNumber, pageSize, sortField, sortDirection);

      self.serpFilteredDataSize = dataFilteredSize;
      self.serpDisplatedTableData.length = 0;
      const newArray = dataFiltered.map(item => {
        return {
          url: item.url,
          wordCount: item.wordCount,
          contentScore: item.contentScore,
        };
      });
      self.serpDisplatedTableData = cast(newArray);
    };

    const handleSerpPaginationChange = (pageNumber: number, pageSize: number) => {
      self.serpPageNumber = pageNumber;
      self.serpPageSize = pageSize;
      handleSerpFilterRenderData(self.serpTerrm, self.serpFiltersList, self.serpPageNumber, self.serpPageSize, self.serpSortField, self.serpSortDirection);
    };

    const handleSerpOnTableChange = (pagination, filters, sorter) => {
      const {column, order} = sorter;
      const {sortFieldName} = column || {};
      self.serpSortField = sortFieldName;
      self.serpSortDirection = order;
      handleSerpFilterRenderData(self.serpTerrm, self.serpFiltersList, self.serpPageNumber, self.serpPageSize, self.serpSortField, order);
    };

    const getData = () => {

    };

    return {
      handleOnTableChange,
      setFromResponse,
      retrieveFocusTerms,
      searchFocusTerms,
      setqueryTerm,
      setCountrySelected,
      setLanguageSelected,
      handleFilterRenderData,
      handleKeywordPaginationChange,
      handleSerpFilterRenderData,
      handleSerpPaginationChange,
      handleSerpOnTableChange,
      getData,
      refetchFocusTerms,
    };
  });

export type FocusTermsPageType = Instance<typeof FocusTermsPage>;

export const initFocusTermsStore = () => {
  return FocusTermsPage.create({
    loading: true,
    error: false,
    keywordMetrics: [],
    serpMetrics: [],
    countrySelected: 'US',
    languageSelected: 'en',
    queryTerm: 'electric vehicle',
    keywordPageNumber: 1,
    keywordPageSize: 10,
    keywordSortField: '',
    keywordSortDirection: 'descend',
    keywordFilteredDataSize: 0,
    keywordTerrm: '',
    keywordFiltersList: [],
    keywordFilterColumn: ['kw'],
    keywordDisplatedTableData: [],
    serpPageNumber: 1,
    serpPageSize: 10,
    serpSortField: '',
    serpSortDirection: 'descend',
    serpFilteredDataSize: 0,
    serpTerrm: '',
    serpFiltersList: [],
    serpFilterColumn: ['kw'],
    serpDisplatedTableData: [],
    serpStatus: null,
    keywordStatus: null,
    pollingActive: true,
    exportQueryTerm: 'electric vehicle',
  });
};
