import DomainAnalyzerApi from '@/api/domain-analyzer/domain-analyzer';
import {BaseApi} from '@/api/base-api';
import {getApiUrl} from '@/api/common-utils';

// const errorList: ApiErrorType[] = [{
//   errorCode: 'free_attempt.invalid',
//   statusCode: 400,
//   message: 'There is Please sign up for an account or try again tomorrow.',
// }];

export const DOMAIN_ANALYZER_API = new DomainAnalyzerApi(getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api'));
