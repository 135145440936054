import {BaseApi} from '@/api/base-api';
import {getAuthHeader, getAuthorizationHeader} from '@/api/common-utils';
import {isNil} from 'lodash';

interface ContentAuditLocation {
  countryCode?: string;
  location?: string;
  locationId?: number;
}

export class ContentAuditApi extends BaseApi {
  public async getTasks(params) {
    try {
      const {data} = await this.axios({
        method: 'GET',
        url: '/content-audit/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: params,
        // cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteAuditData(id: number) {
    try {
      const {data} = await this.axios({
        method: 'DELETE',
        url: `/content-audit/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteInternalLinkSuggestion(id: number, payload: any) {
    try {
      const {data} = await this.axios({
        method: 'PATCH',
        url: `/content-audit/${id}/remove-internal-link-suggestion/`,
        data: payload,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async deleteInLinkSuggestion(id: number, payload: any) {
    try {
      const {data} = await this.axios({
        method: 'PATCH',
        url: `/content-audit/${id}/remove-internal-inlink-suggestion/`,
        data: payload,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateAuditStatus(id: number, value: boolean) {
    try {
      const {data} = await this.axios({
        method: 'PATCH',
        url: `/content-audit/${id}/completion/`,
        data: {complete: value},
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async reLoadData(id: number) {
    try {
      const {data} = await this.axios({
        method: 'GET',
        url: `/content-audit/${id}/recrawl/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getData(id: number, publicHash?: string) {
    try {
      const params = {};
      if (publicHash) {
        params['hash'] = publicHash;
      }
      const {data} = await this.axios({
        method: 'GET',
        url: `/content-audit/${id}/`,
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async loadData(
    url?: string,
    targetKeywords?: string[],
    location?: ContentAuditLocation,
    captchaToken?:string,
    renderWithBrowser?: boolean,
  ) {
    const payload = {
      // sending location id is first priority
      ...(location?.locationId && {'location_id': location.locationId}),
      // only send location if locationId is present as well
      ...(location?.location && location?.locationId && {'location': location.location}),
      // Only send country code if locationId is missing
      ...(!location?.locationId && !isNil(location?.countryCode) && {'country_code': location.countryCode}),
      // send both if we have country code and location as well
      ...(location?.locationId && !isNil(location?.countryCode) && {'country_code': location.countryCode, 'location': location.location}),
      url: url,
      target_keywords: targetKeywords,
      ...(renderWithBrowser && {'should_use_headless_browser': true}),
    };
    if (captchaToken) {
      payload['captcha'] = captchaToken;
    }
    let apiUrl = '';
    if (captchaToken) {
      apiUrl = `/content-audit/?use_captcha`;
    } else {
      apiUrl = '/content-audit/';
    }
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: apiUrl,
        headers: getAuthHeader(),
        data: payload,
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async loadDataPublic(
    url?: string,
    targetKeywords?: string[],
    countryCode?:string,
    captchaToken?:string,
  ) {
    const payload = {
      country_code: countryCode,
      url: url,
      target_keywords: targetKeywords,
    };
    if (captchaToken) {
      payload['captcha'] = captchaToken;
    }
    let apiUrl = '';
    if (captchaToken) {
      apiUrl = `/content-audit/?use_captcha`;
    } else {
      apiUrl = '/content-audit/';
    }
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: apiUrl,
        // headers: getAuthHeader(),
        data: payload,
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
