import {BulkDaCheckerApi} from '@/api/bulk-da-checker/api';
import {ApiErrorType, BaseApi} from '@/api/base-api';
import {getApiUrl} from '@/api/common-utils';

const errorList: ApiErrorType[] = [{
  errorCode: 'free_attempt.invalid',
  statusCode: 400,
  message: 'You’re out of free lookups for today. Please sign up for an account or try again tomorrow.',
}];

export const BULK_DA_CHECKER_API = new BulkDaCheckerApi(getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api'), errorList);
