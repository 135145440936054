export function getGoogleConnectUrl() {
  return process.env.GOOGLE_CONNECT_URL;
}

export function getGoogleAnalyticsConnectUrl() {
  return process.env.GOOGLE_ANALYTICS_CONNECT_URL;
}

export function getGoogleMyBusinessConnectUrl() {
  return process.env.GOOGLE_MY_BUSINESS_CONNECT_URL;
}
