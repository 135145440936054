import styled from 'styled-components';
import {LinksTable} from './LinksTable';

interface Props {
  addItem: (value: {link: string; anchorText?: string}) => void;
  bulkAddExternalLink: (value: number)=> void;
  bulkAddInternalLink: (value: number)=> void;
}

export const LinkSuggestionsPopup = ({addItem, bulkAddExternalLink, bulkAddInternalLink}: Props) => {
  return <Wrapper>
    <LinksTable addItem={addItem} bulkAddExternalLink={bulkAddExternalLink} bulkAddInternalLink={bulkAddInternalLink}/>
  </Wrapper>;
};

const Wrapper = styled.div`
  width: 420px;
`;
