import {types, Instance} from 'mobx-state-tree';

export type SuggestedKeywordType = Instance<typeof SuggestedKeywordsModel>;

export const SuggestedKeywordsModel = types.model({
  keyword: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  shouldRepoll: types.maybeNull(types.boolean),
});
