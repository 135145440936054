import {Checkbox, CountryDropdown, Input} from '@/components/common-components/v2';
import {seAvailableCountries} from '@/utils/countries';
import {faLayerPlus} from '@fortawesome/pro-duotone-svg-icons';
import {faCheck, faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from 'antd';
import {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';

interface Props {
  aiSelected: boolean;
  selectAi: Dispatch<SetStateAction<boolean>>;
  domain: string;
  setDomain: (value: string) => void;
  isValidUrl: boolean;
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
}

export const StepOne = ({
  selectAi,
  aiSelected,
  domain,
  setDomain,
  isValidUrl,
  selectedCountry,
  setSelectedCountry,
}: Props) => {
  return <Container>
    <FontAwesomeIcon icon={faLayerPlus} color='#7F4EAD' fontSize={34}/>
    <Title>Create a new Project</Title>
    <InputContainer>
      <Label>Enter a domain</Label>
      <div style={{display: 'flex'}}>
        <InputStyled variant='gray' onChange={e => setDomain(e.target.value)} value={domain} suffix={<Tooltip title={isValidUrl ? 'URL is valid.' : 'URL is invalid.'}>
          {domain ? isValidUrl ? <FontAwesomeIcon icon={faCheck} color={'#1FAC47'}/> : <FontAwesomeIcon icon={faXmark} color={'#ff5959'}/> : null}
        </Tooltip>}/>
        <CountryDropdownStyled
          showSearch
          aligned={true}
          isProjectCreation={true}
          variant={'light'}
          value={selectedCountry}
          onChange={setSelectedCountry}
          showWorldWide={true}
          countriesToInclude={seAvailableCountries}/>
      </div>

      <CheckboxStyled checked={aiSelected} onChange={e => selectAi(e.target.checked)}>AI select top keywords and competitors</CheckboxStyled>
    </InputContainer>

  </Container>;
};
const CountryDropdownStyled = styled(CountryDropdown)`
  .ant-select-selector {
    border: 1px solid #E8E8E8 !important;
    border-color: #E8E8E8 !important;
  }
`;
const Container = styled.div`

`;
const Title = styled.p`
  font-size: 20px;
  color: #121212;
  font-weight: 700;
`;

const InputContainer = styled.div`

`;
const Label = styled.p`
  font-size: 14px;
  color: #121212;
  margin-bottom: 0;
`;
const InputStyled = styled(Input)`
 border-radius: 5px !important;
 border: 1px solid #E8E8E8 !important;
 margin-bottom: 5px;
 background-color: #F9F9FB !important;

 .ant-input {
  background-color: #F9F9FB !important;
 }

 &:focus {
  // border-color: transparent !important;
  box-shadow: none !important;
 }
`;

const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;
