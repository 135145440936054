import {CONTENT_ASSISTANT_API} from '@/api/ContentAssistant';
import {apiError} from '@/utils/api';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {flow, types, cast} from 'mobx-state-tree';

const WpCategoriesModel = types.model({
  id: types.maybeNull(types.union(types.number, types.string)),
  name: types.maybeNull(types.string),
  parent: types.maybeNull(types.union(types.number, types.string)),
  children: types.maybeNull(types.late(() => (types.array(WpCategoriesModel)))),
});

const WpUsersModel = types.model({
  id: types.maybeNull(types.union(types.number, types.string)),
  role: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
  userLogin: types.maybeNull(types.string),
});

const ParentsModal = types.model({
  id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  parent: types.maybeNull(types.number),
});

const WpPublishItemModel = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  categories: types.maybeNull(types.array(WpCategoriesModel)),
  articles: types.maybeNull(types.array(ParentsModal)),
  metaDescription: types.maybeNull(types.string),
  users: types.maybeNull(types.array(WpUsersModel)),
});

const blogsModel = types.model({
  id: types.maybeNull(types.number),
  handle: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  commentable: types.maybeNull(types.string),
  feedburner: types.maybeNull(types.string),
  feedburnerLocation: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  templateSuffix: types.maybeNull(types.string),
  tags: types.maybeNull(types.string),
  adminGraphqlApiId: types.maybeNull(types.string),
});

export const WpPublishStore = types.model({
  wpArray: types.maybeNull(types.array(WpPublishItemModel)),
  loadingData: types.boolean,
  postingData: types.boolean,
  showDataModal: types.boolean,
  showInstructionsModal: types.boolean,
  showAddSiteModal: types.boolean,
  showQuickSyncModal: types.boolean,
  isApiError: types.boolean,
  isShopifyApiError: types.boolean,
  loadingBlogs: types.boolean,
  creatingPage: types.boolean,
  creatingBlog: types.boolean,
  showIndividualPopover: types.string,
  blogs: types.maybeNull(types.array(blogsModel)),
}).actions(self => {
  const fetchWpPublishData = flow(function* (openDrawer = false) {
    // self.isApiError = false;
    if (self.showDataModal) self.showDataModal = false;
    if (self.showIndividualPopover) self.showIndividualPopover = '';
    if (self.showInstructionsModal) self.showInstructionsModal = false;
    self.loadingData = true;
    try {
      const response = yield CONTENT_ASSISTANT_API.getWpPublishingData();
      if (response.isCancel) return;
      // formatting response not to include localhost site
      const formatRes = [];
      response?.forEach(item => {
        if (!item?.url?.includes('localhost')) formatRes.push(item);
      });
      self.wpArray = cast(formatRes);
      self.loadingData = false;

      // if there is no dataArray, show instructions modal
      if (openDrawer) {
        if (response.length) {
          self.showDataModal = true;
        } else {
          self.showInstructionsModal = true;
        }
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingData = false;
    }
  });

  const publishWpPage = flow(function* (payload: any, stopIsAPIError?: boolean, formType?: string) {
    self.postingData = true;
    try {
      yield CONTENT_ASSISTANT_API.publishWpData(payload);
      self.postingData = false;
      self.isApiError = false;
      notification.success('Success', `${payload?.postType == 'post' ? 'Blog' : 'Page'} ${formType == 'sync' ? 'synced' : 'created'} Successfully`);
    } catch (e) {
      if (!stopIsAPIError) {
        self.isApiError = true;
      }
      useErrorNotification({
        e,
        msg: e?.response?.data?.error || 'Could not publish to WP page.',
        permanent: false,
        handleStatuses: [{
          statuses: [401, 403],
          msg: 'Please sign up to edit this article.',
          permanent: false,
          showDetails: false,
        }],
      });
      return Promise.reject(e);
    } finally {
      self.postingData = false;
    }
  });

  const loadShopifyBlogs = flow(function* () {
    self.loadingBlogs = true;
    try {
      const response = yield CONTENT_ASSISTANT_API.getShopifyBlogs();
      if (response?.isCancel) return;
      self.blogs = response;
    } catch (e) {
      useErrorNotification({
        e,
        msg: e?.response?.data?.error || 'Could not publish to WP page.',
        permanent: false,
        handleStatuses: [
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
      return Promise.reject(e);
    } finally {
      self.loadingBlogs = false;
    }
  });

  const createNewShopifyPage = flow(function* (payload: any, stopIsAPIError?: boolean, syncShopify?: boolean) {
    self.creatingPage = true;
    try {
      const response = yield CONTENT_ASSISTANT_API.createNewShopifyPage(payload);
      if (response?.isCancel) return;
      self.isShopifyApiError = false;
      notification.success('Success', `Shopify page ${syncShopify ? 'synced' : 'created'} successfully`);
      return response;
    } catch (e) {
      if (!stopIsAPIError) {
        self.isShopifyApiError = true;
      }
      useErrorNotification({
        e,
        msg: e?.response?.data?.error,
        permanent: false,
        handleStatuses: [
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
      return Promise.reject(e);
    } finally {
      self.creatingPage = false;
    }
  });

  const createNewShopifyBlog = flow(function* (payload: any, stopIsAPIError?: boolean, syncShopify?: boolean) {
    self.creatingBlog = true;
    try {
      const response = yield CONTENT_ASSISTANT_API.createNewShopifyBlog(payload);
      if (response?.isCancel) return;
      self.isShopifyApiError = false;
      notification.success('Success', `Shopify blog ${syncShopify ? 'synced' : 'created'} successfully`);
      return response;
    } catch (e) {
      if (!stopIsAPIError) {
        self.isShopifyApiError = true;
      }
      useErrorNotification({
        e,
        msg: e?.response?.data?.error,
        permanent: false,
        handleStatuses: [
          {
            statuses: [400],
            msg: apiError(e) as string,
            ...(e.response?.data?.nonFieldErrors && {desc: e.response?.data?.nonFieldErrors.join('\n\n')}),
            showDetails: false,
          },
        ],
      });
      return Promise.reject(e);
    } finally {
      self.creatingBlog = false;
    }
  });

  const setShowIndividualPopover = (value: string) => self.showIndividualPopover = value;
  const setShowDataModal = (value: boolean) => self.showDataModal = value;
  const setShowInstructionsModal = (value: boolean) => self.showInstructionsModal = value;
  const setShowAddSiteModal = (value: boolean) => self.showAddSiteModal = value;
  const setShowQuickSyncModal = (value: boolean) => self.showQuickSyncModal = value;
  const updateApiError = (value: boolean) => self.isApiError = value;
  const setLoadingData = (value: boolean) => self.loadingData = value;

  return {
    fetchWpPublishData,
    setShowDataModal,
    setShowInstructionsModal,
    publishWpPage,
    setShowAddSiteModal,
    setShowQuickSyncModal,
    updateApiError,
    setShowIndividualPopover,
    setLoadingData,
    loadShopifyBlogs,
    createNewShopifyPage,
    createNewShopifyBlog,
  };
});


export const initWpPublishStore = () => {
  return WpPublishStore.create({
    wpArray: [],
    loadingData: false,
    postingData: false,
    showDataModal: false,
    showInstructionsModal: false,
    showQuickSyncModal: false,
    showAddSiteModal: false,
    isApiError: false,
    isShopifyApiError: false,
    loadingBlogs: false,
    creatingPage: false,
    creatingBlog: false,
    showIndividualPopover: '',
  });
};
