import {
  Skeleton as AntdSkeleton,
  SkeletonProps,
} from 'antd';


export const Skeleton = ({...props}: SkeletonProps) => {
  return (
    <AntdSkeleton title={false} paragraph={{rows: 1}} {...props}></AntdSkeleton>
  );
};
