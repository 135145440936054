import {faSquareInfo} from '@fortawesome/pro-duotone-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Divider, Dropdown, Menu, ProgressProps, Row, Tooltip} from 'antd';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {languages} from '../../landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer/languages';
import {numberWithCommas} from '@/utils/number';
import {
  AIQuotaAllowanceBanner,
  AIQuotaAllowanceBannerText,
  AutoDeployTitle,
  DropdownContainer,
  Footer,
  GradientProgressContainer,
  GradientProgressWrapper,
  MenuStyled,
  OTTOSettingsWrapper,
  ProgressStyled,
  RoundInput,
  SectionDescription,
  SectionTitle,
  SliderStyled,
  SwitchStyled,
  Title,
  TitleWrapper,
  UpdateAiSettings,
} from './styledComponents';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import moment from 'moment';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
// import {LargeBadge} from '@/components/common-components/components/badge';
// import {UrlExclusionSection} from '../../site-audit-v2/common/CrawlSettingModal';
// import {parseCrawlRules} from '@/components/common-components/v2/crawlSettingsModal';
// import {formatCrawlRules} from '../../site-audit-v2/audited-sites/siteCard';
// const {Option} = Select;
interface Props {
  setModalVisible: any;
  instructionsDataUuid?: string;
}

const aiModelOptions = [{key: 'openai', value: 'OpenAI (GPT)'}, {key: 'anthropic', value: 'Anthropic (Claude)'}];

const userAgentOptions = [
  {key: 'google_chrome_desktop', value: 'Google Chrome Desktop'},
  {key: 'google_chrome_mobile', value: 'Google Chrome Mobile'},
  {key: 'googlebot_desktop', value: 'Googlebot Desktop'},
  {key: 'googlebot_mobile', value: 'Googlebot Mobile'},
  {key: 'bingbot_desktop', value: 'Bingbot Desktop'},
  {key: 'bingbot_mobile', value: 'Bingbot Mobile'},
  {key: 'slurp', value: 'Slurp'},
  {key: 'yandexbot', value: 'Yandexbot'},
  {key: 'baiduspider', value: 'Baiduspider'},
  {key: 'screaming_frog', value: 'Screaming frog'},
  {key: 'duckduckgo', value: 'Duckduckgo'},
  {key: 'searchatlas', value: 'Searchatlas'},
];

const crawlFrequencyOptions = [
  {key: 'daily', value: 'Daily'},
  {key: 'weekly', value: 'Weekly'},
  {key: 'monthly', value: 'Monthly'},
  {key: 'custom', value: 'Custom'},
  {key: 'never', value: 'Never'},
];

const getFrequencyDays = frequency => {
  switch (frequency) {
    case 'daily':
      return '1';
    case 'weekly':
      return '7';
    case 'monthly':
      return '30';
    case 'never':
      return '0';
  }
};

const getFrequencyOptionsKey = frequency => {
  switch (frequency) {
    case 1:
      return 'daily';
    case 7:
      return 'weekly';
    case 30:
      return 'monthly';
    case 0:
      return 'never';
    case null:
      return 'never';
    default:
      return 'custom';
  }
};

function convertToSnakeCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase();
}

const autopilotDataKeyMap = {
  autopilotMainSettings: {
    autopilotIsActive: 'enabled',
    autopilotFrequencyDays: 'days',
  },
  autopilotOtherSettings: {
    isAuto: 'enabled',
    limit: 'limit',
  },
};

const OTTOSettings = observer(({setModalVisible, instructionsDataUuid}: Props) => {
  const {
    settings: {customer: {profile: {quotaUtilization}}},
    siteAuditorV2: {
      onLoadAuditedSiteDetail,
      // getAuditSitesList,
    },
    ottoV2Store: {
      updatingOTTOSettings,
      updateOTTOSettings,
      getOttoV2Project,
      loadOttoV2Project,
      loadingProject,
    }} = useStore('');
  const uuid = getSingleUrlParam('uuid') || instructionsDataUuid;
  const [aiModel, setAIModel] = useState({key: 'openai', value: 'OpenAI (GPT)'});
  const defaultLanguageOption = languages?.find(item => item?.code == getOttoV2Project?.languageCode);
  const [language, setLanguage] = useState({key: 'en', value: 'English (US)'});
  const [userAgent, setUserAgent] = useState({key: 'google_chrome_desktop', value: 'Google Chrome Desktop'});
  const [crawlFrequency, setCrawlFrequency] = useState({key: 'weekly', value: 'Weekly'});
  const [customCrawlFrequency, setCustomCrawlFrequency] = useState('30');
  const [crawlBudgetValue, setCrawlBudgetValue] = useState(100);
  const [crawlSpeedValue, setCrawlSpeedValue] = useState(5);
  const [aiAllowancePointsLimitReached, setAIAllowancePointsLimitReached] = useState({limitReached: false, value: 0});
  const [autopilotSettings, setAutopilotSettings] = useState([]);
  const [resultDate, setResultDate] = useState('');
  // const [conditions, setConditions] = useState([{action: 'Exclude', rule: 'Contain', value: ''}]);
  const threeColors: ProgressProps['strokeColor'] = {
    '10%': '#2AC155',
    '90%': '#F1AA3E',
    '100%': '#F44343',
  };
  const totalAIQuota = quotaUtilization?.ca?.allowedOttoAiSuggestions?.total;
  // const excludeCondition = (conditions?.filter(condition => condition?.action === 'Exclude')?.length > 1);

  useEffect(() => {
    const statesToSum = autopilotSettings
      ?.filter(setting => setting?.autopilotAISettings)
      ?.map(item => item?.limit || 0) || [];

    const flattenedValues = Array.isArray(statesToSum) ? statesToSum.flat() : [];

    const sumOfValuesInArray = flattenedValues.reduce((acc, value) => {
      return acc + (typeof value === 'number' ? value : 0);
    }, 0);

    if (sumOfValuesInArray < (totalAIQuota + 1)) {
      setAIAllowancePointsLimitReached({limitReached: false, value: sumOfValuesInArray});
    } else {
      setAIAllowancePointsLimitReached({limitReached: true, value: sumOfValuesInArray});
    }
  }, [autopilotSettings, totalAIQuota]);

  const updateAiSettings = async () => {
    const formattedAutopilotAISettings = autopilotSettings
      ?.filter(item => item?.autopilotAISettings && !item?.isComingSoon)
      ?.reduce((acc, item) => {
        acc[convertToSnakeCase(item?.key)] = {
          limit: item?.limit,
          auto_deploy: item?.autoDeploy,
        };
        return acc;
      }, {});

    const formattedAutopilotOutsideSettings = autopilotSettings
      ?.filter(item => item?.autopilotMainSettings && !item?.isComingSoon)
      ?.reduce((acc, item) => {
        acc[convertToSnakeCase(item?.key)] = item?.key == 'autopilotIsActive' ? item?.enabled : item?.days;
        return acc;
      }, {});

    const formattedAutopilotOtherSettings = autopilotSettings
      ?.filter(item => item?.autopilotOtherSettings && !item?.isComingSoon)
      ?.reduce((acc, item) => {
        const {key, childKey} = item;

        const formattedKey = convertToSnakeCase(key);
        const formattedChildKey = convertToSnakeCase(childKey);

        if (!acc[formattedKey]) {
          acc[formattedKey] = {};
        }

        if (formattedChildKey === 'is_auto') {
          acc[formattedKey][formattedChildKey] = item.enabled;
        } else if (formattedChildKey === 'limit') {
          acc[formattedKey][formattedChildKey] = item.limit;
        }

        return acc;
      }, {});

    const payload = {
      ...(isAutopilotActive ? formattedAutopilotOutsideSettings : {autopilot_is_active: false}),
      ...(isAutopilotActive && Object.keys(formattedAutopilotOtherSettings)?.length ? {autopilot_other_settings: formattedAutopilotOtherSettings} : []),
      'llm_provider': aiModel?.key,
      'knowledge_graph': {'language_code': language?.key},
      ...(isAutopilotActive ? {'autopilot_ai_settings': formattedAutopilotAISettings} : []),
      'crawl_budget': Number(crawlBudgetValue),
      'crawl_concurrency': Number(crawlSpeedValue),
      'selected_user_agent': userAgent?.key,
      'next_processing_delta_days': crawlFrequency?.key == 'custom' ? customCrawlFrequency : getFrequencyDays(crawlFrequency?.key),
      'knowledge_graph_id': getOttoV2Project?.knowledgeGraphId,
      // 'crawl_rules': formatCrawlRules(conditions),
    };

    await updateOTTOSettings(uuid, payload);

    await loadOttoV2Project(uuid);

    setModalVisible(false);

    await onLoadAuditedSiteDetail(getOttoV2Project?.siteAudit);
  };

  const aiQuotaAllowanceRows = [
    {
      autopilotMainSettings: true,
      title: 'Autopilot',
      key: 'autopilotIsActive',
      showOnlyEnabledSwitch: true,
      noTitleMarginTop: true,
    },
    {
      autopilotMainSettings: true,
      title: 'Autopilot Schedule',
      key: 'autopilotFrequencyDays',
      showOnlySchedule: true,
      borderRadius: '6px',
    },
    {
      title: 'AI Quota Allowance',
      key: 'ai_quota_allowance',
      value: (aiAllowancePointsLimitReached?.value / totalAIQuota) * 100,
      titleWeight: 600,
    },
    {
      title: 'Technical Fixes',
      type: 'sectionTitle',
      titleWeight: 600,
    },
    {
      autopilotAISettings: true,
      title: 'Title Tags',
      key: 'pageTitle',
      borderRadius: '8px 8px 0px 0px',
      noBorderTop: false,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Meta Descriptions',
      key: 'metaDescription',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Meta Keywords',
      key: 'metaKeywords',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Missing Headings',
      key: 'missingHeadings',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Headings Length',
      key: 'headingsLength',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Open Graphs',
      key: 'ogMeta',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Twitter Cards',
      key: 'twitterMeta',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 10000,
    },
    {
      autopilotAISettings: true,
      title: 'Missing Image Alt',
      key: 'images',
      borderRadius: '0px 0px 8px 8px',
      noBorderTop: true,
      infoAfterTitle: true,
      tooltipWidth: '202px',
      tooltipTitle: '5 Images per 1 Quota Point',
      maxValue: 10000,
    },
    {
      title: 'Content Optimizations',
      type: 'sectionTitle',
      titleWeight: 600,
    },
    {
      autopilotAISettings: true,
      title: 'Internal Link Suggestions',
      key: 'internalLinkSuggestions',
      borderRadius: '8px 8px 0px 0px',
      noBorderTop: false,
      infoAfterTitle: true,
      tooltipWidth: '202px',
      tooltipTitle: '20 Links per 1 Quota Point',
      maxValue: 1000,
    },
    {
      autopilotAISettings: true,
      title: 'Semantic Analysis and Knowledge Based Trust',
      key: 'nlp',
      borderRadius: '0px 0px 8px 8px',
      noBorderTop: true,
      height: '43px',
      maxValue: 100,
    },
    {
      title: 'Topical Maps',
      type: 'sectionTitle',
      titleWeight: 600,
    },
    {
      autopilotAISettings: true,
      title: 'AI Generated Articles',
      key: 'topicalMaps',
      borderRadius: '8px',
      noBorderTop: false,
      maxValue: 100,
      isComingSoon: true,
    },
    {
      title: 'GBP Optimization',
      type: 'sectionTitle',
      titleWeight: 600,
    },
    {
      autopilotAISettings: true,
      title: 'Posts',
      key: 'gbpPosts',
      borderRadius: '8px 8px 0px 0px',
      noBorderTop: false,
      rowMarginTop: '4px',
      maxValue: 20,
      isComingSoon: true,
    },
    {
      autopilotAISettings: true,
      title: 'Question & Answer Suggestions',
      key: 'qandaSuggestions',
      borderRadius: '0px',
      noBorderTop: true,
      maxValue: 20,
      isComingSoon: true,
    },
    {
      autopilotAISettings: true,
      title: 'Unanswered Reviews',
      key: 'unansweredReviews',
      borderRadius: '0px 0px 8px 8px',
      noBorderTop: true,
      maxValue: 20,
      isComingSoon: true,
    },
    {
      title: 'Authority Building (Hyperdrive)',
      type: 'sectionTitle',
      titleWeight: 600,
    },
    {
      autopilotAISettings: true,
      title: 'Cloud Stacks',
      key: 'cloudStacks',
      borderRadius: '8px 8px 0px 0px',
      noBorderTop: false,
      rowMarginTop: '4px',
      maxValue: 50,
      isComingSoon: true,
    },
    {
      autopilotAISettings: true,
      title: 'Press Release Distribution',
      key: 'pressReleaseDistribution',
      borderRadius: '0px 0px 8px 8px',
      noBorderTop: true,
      maxValue: 10,
      isComingSoon: true,
    },
    {
      title: 'Dynamic Indexing',
      type: 'sectionTitle',
      titleWeight: 600,
    },
    {
      title: 'Automatically Index Links',
      autopilotOtherSettings: true,
      key: 'dynamicIndexing',
      childKey: 'isAuto',
      showOnlyEnabledSwitch: true,
      isComingSoon: true,
    },
    {
      title: 'Max Auto-Deployed Links',
      autopilotOtherSettings: true,
      key: 'dynamicIndexing',
      childKey: 'limit',
      borderRadius: '8px',
      noBorderTop: false,
      dontShowAutoDeploySwitch: true,
      isComingSoon: true,
    },
  ];

  useEffect(() => {
    const formattedCrawlSettings = aiQuotaAllowanceRows
      ?.filter(item => item?.key !== 'ai_quota_allowance' && item?.type !== 'sectionTitle')
      .map(row => ({
        key: row?.key,
        ...(row?.autopilotMainSettings ? {autopilotMainSettings: true} : []),
        ...(row?.autopilotAISettings ? {autopilotAISettings: true} : []),
        ...(row?.autopilotOtherSettings ? {autopilotOtherSettings: true} : []),
        ...(row?.childKey ? {childKey: row?.childKey} : []),
        ...(row?.showOnlyEnabledSwitch ? {enabled: true} : []),
        ...(row?.showOnlySchedule ? {days: 7} : []),
        ...(row?.autopilotAISettings ? {autoDeploy: false} : []),
        ...(row?.autopilotAISettings || (row?.autopilotOtherSettings && row?.childKey == 'limit') ? {limit: 0} : []),
        isComingSoon: !!row?.isComingSoon,
      }));
    setAutopilotSettings(formattedCrawlSettings);
  }, []);

  const getAutopilotDefaultData = (payloadType: string, key: string, childKey?: string) => {
    const defaultValues = {
      autopilotAISettings: (childKey === 'autoDeploy') ? false : 0,
      autopilotOtherSettings: (childKey === 'isAuto') ? false : 0,
      autopilotMainSettings: undefined,
    };

    const dataMappings = {
      autopilotAISettings: () => getOttoV2Project?.autopilotAiSettings?.[key]?.[childKey] ?? defaultValues[payloadType],
      autopilotOtherSettings: () => getOttoV2Project?.autopilotOtherSettings?.[key]?.[childKey] ?? defaultValues[payloadType],
      autopilotMainSettings: () => getOttoV2Project?.[key],
    };

    return dataMappings[payloadType]?.();
  };

  const setAutoPilotSettings = (key, valueKey, value) => {
    setAutopilotSettings(prevAutoDeploy => {
      const itemExists = prevAutoDeploy.some(item => item.key === key);

      if (itemExists) {
        return prevAutoDeploy.map(item =>
          item.key === key ? {...item, [valueKey]: value} : item,
        );
      }
      return prevAutoDeploy;
    });
  };

  useEffect(() => {
    aiQuotaAllowanceRows?.forEach(item => {
      const {key, childKey, autopilotAISettings, autopilotMainSettings, autopilotOtherSettings} = item;

      if (autopilotAISettings) {
        setAutoPilotSettings(
          key,
          'autoDeploy',
          getAutopilotDefaultData('autopilotAISettings', key, 'autoDeploy'),
        );
        setAutoPilotSettings(
          key,
          'limit',
          getAutopilotDefaultData('autopilotAISettings', key, 'limit'),
        );
      } else if (autopilotMainSettings) {
        const valueKey = autopilotDataKeyMap.autopilotMainSettings[key];
        setAutoPilotSettings(
          key,
          valueKey,
          getAutopilotDefaultData('autopilotMainSettings', key),
        );
      } else if (autopilotOtherSettings) {
        const valueKey = autopilotDataKeyMap.autopilotOtherSettings[childKey];
        setAutoPilotSettings(
          key,
          valueKey,
          getAutopilotDefaultData('autopilotOtherSettings', key, childKey),
        );
      }
    });
    // if (getAuditSitesList?.length) {
    //   const currentProject = getAuditSitesList?.find(obj => obj?.ottoProjectUuid === getOttoV2Project?.uuid);
    //   setConditions(parseCrawlRules(currentProject?.crawlRules));
    // }
    setAIModel(aiModelOptions?.find(item => item?.key == getOttoV2Project?.llmProvider));
    setLanguage({key: ((defaultLanguageOption?.code === null || defaultLanguageOption?.code) ? defaultLanguageOption?.code : 'en'), value: (defaultLanguageOption?.language || 'English (US)')});
    setUserAgent(userAgentOptions?.find(item => item?.key == getOttoV2Project?.siteAuditCrawlSettings?.selectedUserAgent));
    setCrawlFrequency(crawlFrequencyOptions?.find(item => item?.key == getFrequencyOptionsKey(getOttoV2Project?.siteAuditCrawlSettings?.nextProcessingDeltaDays)));
    if (getFrequencyOptionsKey(getOttoV2Project?.siteAuditCrawlSettings?.nextProcessingDeltaDays) == 'custom') {
      setCustomCrawlFrequency(String(getOttoV2Project?.siteAuditCrawlSettings?.nextProcessingDeltaDays));
    }
    setCrawlBudgetValue(getOttoV2Project?.siteAuditCrawlSettings?.crawlBudget || 100);
    setCrawlSpeedValue(getOttoV2Project?.siteAuditCrawlSettings?.crawlConcurrency || 5);
  }, [getOttoV2Project]);

  const isAutopilotActive = autopilotSettings?.find(data => data?.key === 'autopilotIsActive')?.enabled;
  const filteredRows = isAutopilotActive ? aiQuotaAllowanceRows : aiQuotaAllowanceRows?.filter(row => row?.key === 'autopilotIsActive');

  const getAIQuotaAllowanceRows = filteredRows?.map((item, idx) => <Row key={idx} style={{marginTop: item?.rowMarginTop}}>
    <Col span={12}>
      <TitleWrapper>
        <Tooltip title={item?.isComingSoon ? 'Coming soon' : ''}>
          <Title sectionTitle={item?.type == 'sectionTitle' || item?.key == 'ai_quota_allowance'} disabled={item?.isComingSoon} style={{display: 'flex', alignItems: 'center', marginTop: item?.noTitleMarginTop ? '' : '6px', fontWeight: item?.titleWeight || 400}}>{item?.title}</Title>
        </Tooltip>
        {item?.infoAfterTitle && <Tooltip
          overlayInnerStyle={{width: item?.tooltipWidth, padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
          title={item?.tooltipTitle}>
          <FontAwesomeIcon icon={faSquareInfo} color='#A3A4A4' />
        </Tooltip>}
      </TitleWrapper>
    </Col>
    <Col span={12}>
      {item?.title == 'Technical Fixes' && item?.type == 'sectionTitle' && <AutoDeployTitle>Auto<br/>deploy</AutoDeployTitle>}
      {item?.type !== 'sectionTitle' && (item?.title == 'AI Quota Allowance' ? <GradientProgressContainer>
        <GradientProgressWrapper>
          <span>0</span>
          <ProgressStyled showInfo={false} percent={item?.value} strokeColor={threeColors} />
          <span style={{width: '35px'}}>{numberWithCommas(totalAIQuota)}</span>
        </GradientProgressWrapper>
      </GradientProgressContainer> : (item?.showOnlyEnabledSwitch ?
        <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
          <SwitchStyled
            disabled={item?.isComingSoon}
            checked={autopilotSettings?.find(data => data?.key == item?.key)?.enabled}
            onChange={checked => setAutoPilotSettings(item?.key, 'enabled', checked)} />
          <div style={{fontSize: '12px', color: '#4E5156'}}>
            {autopilotSettings?.find(data => data?.key == item?.key)?.enabled ? 'Enabled' : 'Disabled'}
          </div>
        </div> : (item?.showOnlySchedule ? <>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px'}}>
            <div style={{fontSize: '12px', color: '#4E5156'}}>Run every</div>
            <RoundInput
              width={'50px'}
              type='number'
              onChange={e => {
                setAutoPilotSettings(item?.key, 'days', Number(e?.target?.value));
              }}
              value={autopilotSettings?.find(data => data?.key == item?.key)?.days}
              borderRadius={item?.borderRadius}
              tabIndex={-1}
              min={7}
            />
            <div style={{fontSize: '12px', color: '#4E5156'}}>days</div>
          </div>
          {autopilotSettings?.find(data => data?.key == item?.key)?.days < 7 && <div style={{color: 'red', fontSize: '12px'}}>7 is minimum value</div>}</> :
          <div style={{display: 'flex', alignItems: 'center', width: '315px'}}>
            <Tooltip
              placement='right'
              overlayInnerStyle={{width: '390px', padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
              title={aiAllowancePointsLimitReached?.limitReached ? <div>
                <div>You’ve hit the limit of {numberWithCommas(totalAIQuota)} AI Quota Points Allowance.</div>
                <div style={{marginTop: '5px'}}>To increase the quota allowance for this issue category, lower the allowance of other categories.</div>
              </div> : ''}>
              <div>
                <SliderStyled
                  disabled={item?.isComingSoon}
                  style={{width: '188px'}}
                  value={autopilotSettings?.find(data => data?.key == item?.key)?.limit || 0}
                  max={item?.maxValue || totalAIQuota}
                  onChange={sliderValue => {
                    setAutoPilotSettings(item?.key, 'limit', sliderValue);
                  }} />
              </div>
            </Tooltip>
            <div style={{display: 'flex', marginLeft: '15px'}}>
              <span>
                <div style={{width: '60px', marginRight: 10}}>
                  <RoundInput
                    disabled={item?.isComingSoon}
                    height={item?.height}
                    type='number'
                    onChange={e => {
                      if (Number(e?.target?.value) < ((item?.maxValue || totalAIQuota) + 1)) {
                        setAutoPilotSettings(item?.key, 'limit', Number(e?.target?.value));
                      }
                    }}
                    value={autopilotSettings?.find(data => data?.key == item?.key)?.limit || 0}
                    noBorderTop={item?.noBorderTop}
                    borderRadius={item?.borderRadius}
                    tabIndex={-1}
                    min={0}
                    max={item?.maxValue || totalAIQuota}
                  />
                </div>
              </span>
            </div>
            {!item?.dontShowAutoDeploySwitch && <SwitchStyled disabled={item?.isComingSoon} checked={autopilotSettings?.find(data => data?.key == item?.key)?.autoDeploy} onChange={checked => setAutoPilotSettings(item?.key, 'autoDeploy', checked)} />}
          </div>)))}
    </Col>
  </Row>,
  );

  const calculateDate = option => {
    const today = moment();
    let futureDate;

    switch (option) {
      case 'daily':
        futureDate = today.add(1, 'day');
        break;
      case 'weekly':
        futureDate = today.add(1, 'week');
        break;
      case 'monthly':
        futureDate = today.add(1, 'month');
        break;
      case 'custom':
        futureDate = today.add(parseInt(customCrawlFrequency, 10), 'days');
        break;
      default:
        futureDate = today;
    }

    setResultDate(futureDate.format('MMMM D, YYYY'));
  };

  useEffect(() => {
    if (crawlFrequency) {
      calculateDate(crawlFrequency?.key);
    }
  }, [crawlFrequency, customCrawlFrequency]);

  // const handleConditionChange = (index: number, key: string, value: string) => {
  //   const updatedConditions = [...conditions];
  //   updatedConditions[index][key] = value;
  //   setConditions(updatedConditions);
  // };
  // const handleDeleteCondition = (index: number) => {
  //   const updatedConditions = conditions.filter((_, i) => i !== index);
  //   const excludeCondition = (conditions?.filter(condition => condition?.action === 'Exclude')?.length > 1);
  //   if (updatedConditions?.length && !excludeCondition) {
  //     const excludeUpdatedCondition = [...updatedConditions];
  //     excludeUpdatedCondition[0] = {...excludeUpdatedCondition[0], action: 'Exclude'};
  //     setConditions(excludeUpdatedCondition);
  //   } else {
  //     setConditions(updatedConditions);
  //   }
  // };
  // const handleAddCondition = () => {
  //   if (conditions?.length) {
  //     setConditions([...conditions, {action: 'Exclude', rule: 'Contain', value: ''}]);
  //   } else {
  //     setConditions([{action: 'Exclude', rule: 'Contain', value: ''}]);
  //   }
  // };
  return (
    <OTTOSettingsWrapper>

      {loadingProject ? <RingLoaderV2 height='84vh'/> : <>

        {/* -------------AI Writing Section------------- */}
        <Row style={{marginTop: '26px'}}>
          <Col span={11}>
            <SectionTitle>AI Writing</SectionTitle>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={12}>
                <Title>AI Model</Title>
              </Col>
              <Col span={12}>
                <Dropdown getPopupContainer={trigger => trigger} trigger={['click']} overlay={
                  <MenuStyled>
                    {
                      aiModelOptions?.map(item => {
                        return <Menu.Item
                          onClick={() => setAIModel(item)}
                          style={{backgroundColor: item?.key == aiModel?.key ? 'rgba(45, 108, 202, 0.2)' : ''}}
                          key={item.key}>
                          {item.value}
                        </Menu.Item>;
                      })
                    }
                  </MenuStyled>
                }>
                  <DropdownContainer noBorderBottom={true} borderRadius='8px 8px 0px 0px'>
                    <div>{aiModel?.value}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </DropdownContainer>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Title>Language</Title>
              </Col>
              <Col span={12}>
                <Dropdown getPopupContainer={trigger => trigger} trigger={['click']} overlay={
                  <MenuStyled>
                    {
                      languages?.map(item => {
                        return <Menu.Item
                          onClick={() => setLanguage({key: item.code, value: item.language})}
                          style={{backgroundColor: item.code == language?.key ? 'rgba(45, 108, 202, 0.2)' : ''}}
                          key={item.code}>
                          {item.language}
                        </Menu.Item>;
                      })
                    }
                  </MenuStyled>
                }>
                  <DropdownContainer borderRadius='0px 0px 8px 8px'>
                    <div>{language?.value}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </DropdownContainer>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='gradient-divider-wrapper'>
          <Divider orientation={'left'} className='gradient-divider'/>
        </div>

        {/* -------------AI Quota Allowance Section------------- */}
        <Row style={{marginTop: '26px'}}>
          <Col span={11}>
            <SectionTitle>Autopilot & Quota Allowance</SectionTitle>
            <SectionDescription>Adjust how much AI Generation quota you’d like to allocate for each issue type in your upcoming recrawls.</SectionDescription>
          </Col>
          <Col span={10}>
            {getAIQuotaAllowanceRows}
            {autopilotSettings?.find(data => data?.key == 'autopilotIsActive')?.enabled && <AIQuotaAllowanceBanner>
              <FontAwesomeIcon icon={faCircleInfo} color='#2D6CCA' />
              <AIQuotaAllowanceBannerText>Up to {numberWithCommas(aiAllowancePointsLimitReached?.value)} AI Quota points can be consumed during the next crawl scheduled for {resultDate}.</AIQuotaAllowanceBannerText>
            </AIQuotaAllowanceBanner>}
          </Col>
        </Row>
        <div className='gradient-divider-wrapper'>
          <Divider orientation={'left'} className='gradient-divider'/>
        </div>

        {/* -------------Crawl Parameters Section------------- */}
        <Row style={{marginTop: '26px'}}>
          <Col span={11}>
            <SectionTitle>Crawl Parameters</SectionTitle>
            <SectionDescription>Manage future site crawls effectively.</SectionDescription>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={12}>
                <Title>User Agent</Title>
              </Col>
              <Col span={12}>
                <Dropdown getPopupContainer={trigger => trigger} trigger={['click']} overlay={
                  <MenuStyled>
                    {
                      userAgentOptions?.map(item => {
                        return <Menu.Item
                          onClick={() => setUserAgent(item)}
                          style={{backgroundColor: item?.key == userAgent?.key ? 'rgba(45, 108, 202, 0.2)' : ''}}
                          key={item.key}>
                          {item.value}
                        </Menu.Item>;
                      })
                    }
                  </MenuStyled>
                }>
                  <DropdownContainer noBorderBottom={true} borderRadius='8px 8px 0px 0px'>
                    <div>{userAgent?.value}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </DropdownContainer>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Title>Crawl Frequency</Title>
              </Col>
              <Col span={12}>
                <Dropdown getPopupContainer={trigger => trigger} trigger={['click']} overlay={
                  <MenuStyled>
                    {
                      crawlFrequencyOptions?.map(item => {
                        return <Menu.Item
                          onClick={() => setCrawlFrequency(item)}
                          style={{backgroundColor: item?.key == crawlFrequency?.key ? 'rgba(45, 108, 202, 0.2)' : ''}}
                          key={item.key}>
                          {item.value}
                        </Menu.Item>;
                      })
                    }
                  </MenuStyled>
                }>
                  <DropdownContainer borderRadius='0px 0px 8px 8px'>
                    <div>{crawlFrequency?.value}</div>
                    <FontAwesomeIcon icon={faCaretDown} />
                  </DropdownContainer>
                </Dropdown>
              </Col>
              {crawlFrequency?.key == 'custom' && <div style={{display: 'flex', marginLeft: 195, marginTop: 10}}>
                <span style={{marginTop: 4, fontSize: 12, color: '#121212'}}>Every  </span>
                <span>
                  <div style={{width: '65px', marginLeft: 10, marginRight: 10}}>
                    <RoundInput
                      borderRadius='6px'
                      crawlFrequency={String(customCrawlFrequency)}
                      value={customCrawlFrequency}
                      onChange={e => setCustomCrawlFrequency(String(e?.target?.value))}
                    />
                  </div>
                </span>
                <span style={{fontSize: 12, marginTop: 4, color: '#121212'}}>Days</span>
              </div>}
              <Col span={12}>
                <Title style={{display: 'flex', alignItems: 'center', marginTop: '6px'}}>Pages to Crawl</Title>
              </Col>
              <Col span={12}>
                <Row style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                  <Col span={18}>
                    <SliderStyled style={{width: '127px'}} value={crawlBudgetValue} max={10000} onChange={e => setCrawlBudgetValue(Number(e))} />
                  </Col>
                  <Col span={6}>
                    <div style={{display: 'flex', marginLeft: '15px'}}>
                      <span style={{marginTop: 4, fontSize: 12, color: '#4E5156'}}>Top </span>
                      <span>
                        <div style={{width: '65px', marginLeft: 10, marginRight: 10}}>
                          <RoundInput
                            type='number'
                            onChange={e => {
                              setCrawlBudgetValue(Number(e?.target?.value));
                            }}
                            value={crawlBudgetValue}
                            borderRadius='6px'
                            tabIndex={-1}
                            min={1}
                            max={10000}
                          />
                        </div>
                      </span>
                      <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>pages</span>
                    </div>
                  </Col>
                  {crawlBudgetValue == 0 && <div style={{color: 'red', fontSize: '12px'}}>This field is required</div>}
                </Row>
              </Col>
              <Col span={12}>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginTop: '8px'}}>
                  <Title style={{display: 'flex', alignItems: 'center', marginTop: '6px'}}>Crawl Speed</Title>
                  <Tooltip
                    overlayInnerStyle={{padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
                    title={'Increase speed if you have large site for quick page crawls'}>
                    <FontAwesomeIcon icon={faSquareInfo} color='#A3A4A4' />
                  </Tooltip>
                </div>
              </Col>
              <Col span={12}>
                <Row style={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                  <Col span={18}>
                    <SliderStyled style={{width: '127px'}} max={50} value={crawlSpeedValue} onChange={e => setCrawlSpeedValue(Number(e))} />
                  </Col>
                  <Col span={6}>
                    <div style={{display: 'flex', marginLeft: '15px'}}>
                      <span>
                        <div style={{width: '42px', marginRight: 10}}>
                          <RoundInput
                            type='number'
                            onChange={e => {
                              setCrawlSpeedValue(Number(e?.target?.value));
                            }}
                            value={crawlSpeedValue}
                            borderRadius='6px'
                            tabIndex={-1}
                            min={1}
                            max={50}
                          />
                        </div>
                      </span>
                      <span style={{fontSize: 12, marginTop: 4, color: '#4E5156'}}>pages&nbsp;/&nbsp;second</span>
                    </div>
                  </Col>
                  {crawlSpeedValue < 1 && <div style={{color: 'red', fontSize: '12px'}}>1 is minimum value</div>}
                  {crawlSpeedValue > 50 && <div style={{color: 'red', fontSize: '12px'}}>50 is maximum value</div>}
                </Row>
              </Col>
              {/* <Col span={24}>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginTop: '8px'}}>
                  <Title style={{display: 'flex', alignItems: 'center', marginTop: '6px'}}>URL Exclusion conditions<LargeBadge beta/></Title>
                </div>
              </Col>
              <Col span={24}>
                {conditions?.map((condition, index) => (
                  <UrlExclusionSection key={index} style={{display: 'flex', gap: 8}}>
                    <div key={index} className='condition-row'>
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#4E5156', gap: 8}}>
                        <div style={{display: 'flex', color: '#4E5156', gap: 8}}>
                          <div>#{index+1}</div>
                          <div>
                            <Select dropdownMatchSelectWidth={false}
                              getPopupContainer={node=> node}
                              value={condition.action}
                              className='condition-dropdown'
                              onChange={value => handleConditionChange(index, 'action', value)}
                              suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                            >
                              <Option id='testm' value='Exclude'>Exclude</Option>
                              <Option id='testm' value='Include' disabled={!excludeCondition}>
                                <Tooltip title={!excludeCondition && 'Please an Exclude condition first'}>
                                    Include
                                </Tooltip>
                              </Option>
                            </Select>
                            <span style={{marginRight: 5}}>all URLs that</span>
                            <Select
                              getPopupContainer={node=> node}
                              dropdownMatchSelectWidth={false}
                              value={condition.rule}
                              className='condition-dropdown'
                              onChange={value => handleConditionChange(index, 'rule', value)}
                              suffixIcon={<FontAwesomeIcon color='#4E5156' icon={faCaretDown}/>}
                            >
                              <Option value='Contain'>contain</Option>
                              <Option value='Start with'>start with</Option>
                            </Select>
                            <Input
                              width={470}
                              placeholder='Type here e.g /blog/ ....'
                              className='condition-input'
                              value={condition.value}
                              onChange={e => handleConditionChange(index, 'value', (e.target.value)?.trim())}
                            />
                          </div>
                          <FontAwesomeIcon icon={faTrash} style={{fontSize: 14, color: '#A3A4A4', marginLeft: -25, marginTop: 2, cursor: 'pointer'}} onClick={() => handleDeleteCondition(index)}/>
                        </div>
                      </div>
                    </div>
                  </UrlExclusionSection>
                ))}
                <div onClick={handleAddCondition} style={{color: '#2D6CCA', fontSize: 12, cursor: 'pointer'}}>
                    + Add condition
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>

        {/* -------------Footer Section------------- */}
        <Footer>
          <button className='cancel-button' type='button' onClick={() => {
            setModalVisible(false);
          }}>Cancel</button>
          <Tooltip
            placement='topRight'
            overlayInnerStyle={{width: '390px', padding: '10px', backgroundColor: '#121212', borderRadius: '12px'}}
            title={aiAllowancePointsLimitReached?.limitReached && autopilotSettings?.find(data => data?.key == 'autopilotIsActive')?.enabled ? <div>
              <div>You’ve hit the limit of {numberWithCommas(totalAIQuota)} AI Quota Points Allowance.</div>
              <div style={{marginTop: '5px'}}>To increase the quota allowance for this issue category, lower the allowance of other categories.</div>
            </div> : ''}>
            <FreezeWrapper>
              <div>
                <UpdateAiSettings
                  loading={updatingOTTOSettings}
                  onClick={() => updateAiSettings()}
                  disabled={
                    ((
                      aiAllowancePointsLimitReached?.limitReached ||
                    autopilotSettings?.find(data => data?.key == 'autopilotFrequencyDays')?.days < 7 ||
                    updatingOTTOSettings) &&
                    autopilotSettings?.find(data => data?.key == 'autopilotIsActive')?.enabled) ||
                    updatingOTTOSettings || crawlBudgetValue == 0 || crawlSpeedValue < 1 || crawlSpeedValue > 50}>
                  Update Settings
                </UpdateAiSettings>
              </div>
            </FreezeWrapper>
          </Tooltip>
        </Footer>
      </>
      }

    </OTTOSettingsWrapper>
  );
});

export default OTTOSettings;
