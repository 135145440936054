import {Pagination} from '@/components/common-components/components/pagination';
import {Button} from '@/components/common-components/v2/Button';
import {Popover, Select} from 'antd';
import styled from 'styled-components';

const {Option} = Select;
export const MainContainer = styled.div`
  background: #24262A;
  padding-top: 40px;
`;
export const TitleWrapper = styled.div`
width: 213px;
height: 32px;
font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #FFFFFF;
`;
export const HeadWrapper = styled.div`
  width: 960px;
  color: #FFFFFF;
  margin: auto;
  height: 320px;
`;
export const DescriptionWrapper = styled.div`
height: 20px;
font-weight: 400;
line-height: 20px;
color: #E8E8E8;
margin-top: 13px;
margin-left: 24px;
`;
export const LocalScanWrapper = styled.div`
width: 100%;
height: 220px;
background: #e8e8e8;
border-radius: 12px;
margin-top: 27px;
position: relative;
`;
export const StyledInput = styled(Select)`
.ant-select-selector {
height: 44px !important;
outline: none;
color: #121212;
border: 1px solid #E8E8E8 !important;
box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02) !important;
background: #fff;
border-radius: 6px !important;
padding-left: 16px;
input {
  height: 100% !important;
}
.ant-select-selection-placeholder {
  color: #A3A4A4 !important;
  margin: auto;
  margin-top: 7px;
}
.ant-select-selection-item {
  margin: auto;
}
}
`;
export const InputWrapper = styled.div`
top: 0;
left: 0;
position: absolute;
height: 100%;
width: 55%;
padding: 20px;
`;
export const KeywordInput = styled.input`
width: 240px;
height: 44px;
color: #121212;
outline: none;
background: #FFFFFF;
border: 1px solid #E8E8E8;
box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
border-radius: 6px;
padding-left: 16px;
&::placeholder {
  color: #A3A4A4 !important;
}
`;
export const RunLocalSeoScanBtn = styled(Button)`
margin-top: 15px;
margin-left: 0;
gap: 8px;
`;
export const SecondContainer = styled.div`
width: 960px;
margin: 100px auto 40px;
`;
export const InnerContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #E8E8E8;
box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
border-radius: 12px;
.ant-table-tbody {
  cursor: pointer;
}
`;
export const ValuesWrapperStyled = styled.div<{background}>`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 6px;
width: 53px;
height: 32px;
background: ${p=> `${p?.background}1A`};
border-radius: 6px;
`;
export const StyledSelect = styled(Select)<{background?: string}>`
 width: -webkit-fill-available;
 height: fit-content;
 .ant-select-selector {
   background: ${p=> p?.background} !important;
    max-width:180px !important;
    border-radius: 6px !important;
    border-color: 1px solid #E8E8E8 !important;
    padding: 6px 15px !important;
    height: 44px !important;
    cursor: pointer !important;
    line-height: unset !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }
`;

export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  contain: content;
  border-radius: 10px;
`;
export const BoldText = styled.div`
font-weight: 600;
`;

export const StyledOption = styled(Option)`
background: red;
`;

export const PaginationStyled = styled(Pagination)`
  padding: 20px 25px;  
  text-align: right;
  margin-top: 10px;
  border-top: 1px solid #E8E8E8!important;


.ant-pagination-item{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-prev {
  width:25px !important;
 height:25px !important;
}
.ant-pagination-next{
  width:25px !important;
 height:25px !important;
}
.ant-pagination-disabled{
  min-width: 25px !important;
 height:25px !important;
}
.ant-pagination-item-link  span  svg {
  display:flex;
  margin-bottom:2px;
  align-items:center;
  justify-content:center;
}
.ant-pagination-prev{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-next{
  min-width:25px !important;
  max-height:25px !important;
}
.ant-pagination-options{
  height:25px;
}
.ant-pagination{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top:10px;
  margin-right:15px;
  
}
`;
export const LoadingStyled = styled.div`
margin-left: 10px;
font-size: 15px;
`;
export const PopoverWrapper = styled(Popover)`
.customPopover {
z-index: 3 !important;

  .ant-popover-content {
    .ant-popover-arrow {
      border-color: #25242b;
    }

    .ant-popover-inner {
      .ant-popover-inner-content {
        background-color: #fff;
        border-radius: 10px;
        color: #77757d;
        padding: 8px 15px;

        p {
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 0;
          padding: 4px 0;
        }

        span {
          color: #77757d;
          // margin-right: 4px;
        }
      }
    }
  }
}
`;
export const SpanWrapper = styled.span`
    border-radius: 5px;
    height: 30px;
    padding: 5px;
    width: 30px;
    cursor: pointer;
    &:hover{
    background-color: #dedede;
  }
  @media screen and (max-width: 800px) {
    padding-right: 10px !important;
  }
`;
export const EllipsisWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
`;
