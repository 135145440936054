import {getApiUrl, getAuthHeader, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';

export interface keywordListPayload {
  url: string;
  countryCode: string;
  page:number;
}
export interface KeywordCreatePayload {
  name: string;
  keywords: string[];
  count: number;
  countryCode: string;
}
export interface KeywordUpdatePayload {
  id: number;
  name: string;
  keywords: string[];
  count: number;
  countryCode: string;
}

export interface keywordDetailPayload {
  keyword: string;
}

export enum TaskStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
}

// Todo: will add this url later in the prod.env
const baseUrlPublicLinkgraph = 'https://public.linkgraph.com/api';
class CompetitorResearcherApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');

  public async findKeywordList(keywords) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/find_keywords/`, keywords, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async postKeywordlist(keywords) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.post(`/v1/keywordslist/`, keywords, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async postWebsitelist(data) {
    const response: any = await this.axios.post(`/v1/website_list/`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async postCompetitorResearcher(payload, publicHash) {
    const param = {};
    if (publicHash) {
      param['hash'] = publicHash;
    }
    const response = await this.axios.post(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/`, payload, {
      headers: getAuthHeader(),
      params: param,
      // cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async postDomainAnalyzer(url) {
    try {
      const response = await this.axios.get(`${baseUrlPublicLinkgraph}/signup-analytic-flow/domain-analyzer/?domain=${url}`);
      return response.data;
    } catch (e) {
      return e;
    }
  }

  public async postSignUpDomainAnalyzerWhiteLabel(payload) {
    try {
      const response = await this.axios.post(`${baseUrlPublicLinkgraph}/signup-analytic-flow/whitelabel-domain-analyzer-register/`, payload, {
        headers: {
          Accept: 'application/json',
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSiteExplorerUptime() {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/uptime/`, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  }

  public async getnetworkGraphReport(target) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/backlinks-report/?target=${target}`, {
        headers: getAuthHeader(),
      });
      return response;
    } catch (e) {
      return e.response;
    }
  }

  public async getnetworkGraphReportUpdate(target, countryCode?: string) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/site-overview/?target=${target}&country_code=${countryCode}`, {
        headers: getAuthHeader(),
      });
      return response;
    } catch (e) {
      return e.response;
    }
  }

  public async getCompetitorResearchlist(params) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }
  public async saveProject(id) {
    const response = await this.axios.post(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/toggle-save-project/`, {}, {
      headers: getAuthHeader(),
      // cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async createBulk(payload) {
    try {
      const response = await this.axios.post(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/bulk-create/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteCompetitorResearcher(id) {
    try {
      const response = await this.axios.delete(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async getCompetitorResearchDetail(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/`, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async recrawlCompetitorResearchDetail(id) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/recrawl/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async getRankingKeywordsDetail(id, payLoad, publicHash, filters?: string[], positionTypeFilter?: any) {
    const params = new URLSearchParams(payLoad);
    if (publicHash) {
      params.append('hash', publicHash);
    }
    if (positionTypeFilter?.header == 'Positions Type') {
      params?.append('positions_type', positionTypeFilter?.type);
    }
    if (filters?.length) {
      filters?.map(z => {
        const arr = z?.split(',');
        arr[1] !== 'targetPage' ? params.append('filter', z) : params.append('url', arr[2]);
      });
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/view-more/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }
  public async getRankingKeywordsDetailV2(id, payLoad, publicHash, filters?: string[], positionTypeFilter?: any) {
    const params = new URLSearchParams(payLoad);
    if (publicHash) {
      params.append('hash', publicHash);
    }
    if (positionTypeFilter?.header == 'Positions Type') {
      params?.append('positions_type', positionTypeFilter?.type);
    }
    if (filters?.length) {
      filters?.map(z => {
        const arr = z?.split(',');
        arr[1] !== 'targetPage' ? params.append('filter', z) : params.append('url', arr[2]);
      });
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/view-more/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }
  public async getOutLinksDetail(id, payLoad, domain) {
    const params = new URLSearchParams(payLoad);
    params.append('filter', `domain,equals,${domain}`);
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/view-more/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }
  public async getTrendData(id, context, keyword) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/view-more?context=${context}&keyword=${keyword}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }
  public async getAdsCopyKeywordDetail(id, payLoad, publicHash) {
    const params = new URLSearchParams(payLoad);
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/view-more/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }
  public async getExtendedData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/data-extended/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async getSubDomainDetailData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/subdomains/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async getRelevantPageDetailData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/relevant-pages/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async getRankingKeywordsFilteredDetail(id, payload, publicHash) {
    const params = payload;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.post(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/filter-ranking-keywords/`, params, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async recrawlCompetitorResearcher(id) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/recrawl/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }

  public async individualCategoryData(id, payLoad, publicHash) {
    const params = payLoad;
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/categories/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    }
  }
  public async getKeywordlist() {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/v1/website_list/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }
  public async getKeywordStats(website:string) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/backlink/website-stats/?url=${website}`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async getKeywordOverview(url:string, countryCode: string, page: number) {
    const params ={
      countryCode,
      page,
    };
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.get(`/v1/website_targetedkeywords?url=${url}`, {
      params,
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async updateKeywordlist(data) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.put(`/v1/keywordslist/${data.id}/`, data, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async deleteKeywordlist(id) {
    const response: AxiosResponse<keywordDetailPayload> = await this.axios.delete(`/v1/keywordslist/${id}/`, {
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });

    return response.data;
  }

  public async postKeywordDetail(keyword, countryCode, excludeKd) {
    const response = await this.axios.get('/v1/keyword_details', {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
      params: {
        'query': keyword,
        'countrycode': countryCode,
        'exclude_kd': excludeKd ? excludeKd : 0,
        'exclude_sca': 0,
      },
    });

    return response.data;
  }

  public async getKrtObjectsList(params) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v1/rank-tracker/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }
  public async getFacebooksAdsData(id, params) {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/${id}/facebook-ads/`, {
        params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCompetitorResearchUsers() {
    try {
      const response = await this.axios.get(`${CompetitorResearcherApi.baseUrl}/v2/competitor-research/get-users/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async postTopBottomHtml(uuid, payload) {
    try {
      const response = await this.axios.patch(`https://public.linkgraph.com/domain-analysis/api/history/update/${uuid}/`, payload, {
        headers: {
          Accept: 'application/json',
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const COMPETITOR_RESEARCHER_API = new CompetitorResearcherApi();
