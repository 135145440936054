import {faMagnifyingGlass, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Form, Input, Modal, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {TableStyled} from '../../home-page-main/components/table-styled';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
// import {Pagination} from '@/components/common-components/components/index';
import {Button} from '@/components/common-components/v2';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import {addProtocolToDomainHttps, getSiteName, removeProtocol} from '@/utils/url';
import FreezeWrapper from './freezTooltip';
import {stringToArray} from '@/utils/arrays';
import {keywordNewLineReg} from '@/constants/regex';
import {StyledModalFooter} from './ottoHeaderV2';
import {StyledButton} from './KnowledgeGraph/businessInfoForm';

interface props {
  tableData: any;
  setTableData: (value: any) => void;
  searchResults: any;
  setSearchResults: (value: any) => void;
  searchValue: string;
  setSearchValue: (value: any) => void;
  uuid: string;
  showAddPage: boolean;
  setShowAddPage: (value: boolean) => void;
  form: any;
  domainName?: string;
}

const TopPages = observer(({tableData, setTableData, searchResults, setSearchResults, searchValue, setSearchValue, uuid, showAddPage, setShowAddPage, form, domainName}: props) => {
  const {
    ottoV2Store: {loadingProject, updateOttoV2Project, loadOttoV2Project},
  } = useStore('');
  const {settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');
  const [updateOttoProjectLoading, setUpdateOttoProjectLoading] = useState(false);
  const [keywords, setKeywords] = useState('');
  const [url, setPageUrl] = useState('');
  const [keywordError, setKeywordError] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState<{url?: string; isSelected?: boolean; keywords?: string[]}>({});
  const [showError, setShowError] = useState(false);
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /^(https?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))?$/;
  useEffect(() => {
    if (keywords?.length || !showAddPage) {
      setKeywordError(false);
    }
  }, [keywords, showAddPage]);

  useEffect(() => {
    if (!showEditModal) {
      setShowError(false);
    }
  }, [showEditModal]);
  const handleCheckboxChange = (event, page) => {
    const isChecked = event.target.checked;
    const updatedData = prevTableData => {
      return prevTableData.map(value => {
        if (value.url === page.url) {
          return {...value, isSelected: isChecked};
        }
        return value;
      });
    };
    setTableData(prevState => updatedData(prevState));
    setSearchResults(prevState => updatedData(prevState));
  };

  const handleSearch = e => {
    const value = e.target.value;
    setSearchValue(value);
    setSearchResults(tableData?.filter(item => {
      const term = value?.toLowerCase();
      return item?.url?.toLowerCase()?.includes(term) || item?.keywords?.some(keyword => keyword?.toLowerCase()?.includes(term));
    }));
  };

  const columns: any = [
    {
      title: 'PAGE URL',
      dataIndex: 'country',
      key: 'country',
      align: 'left' as 'left',
      render: function(_, record) {
        // const isChecked = tableData?.filter(value => value?.isSelected);
        return (
          <FirstCol>
            <StyledCheckbox checked={record?.isSelected} onChange={e => handleCheckboxChange(e, record)} />
            <Tooltip title={record?.url}><PageUrl>{removeProtocol(record?.url)}</PageUrl></Tooltip>
          </FirstCol>
        );
      },
    },
    {
      title: 'KEYWORD',
      dataIndex: 'keyword',
      key: 'keyword',
      // width: 50,
      render: function(_, record) {
        const keywordsLength = <Tooltip title={record?.keywords?.join(' ,')}> {record?.keywords?.length ? <>
          <Keyword>
            {record?.keywords[0]}
            {record?.keywords?.length > 1 ? <span>, +{record?.keywords?.length - 1} </span> : ''}
          </Keyword>
        </> : '-'}
        </Tooltip>;
        return <div style={{display: 'flex', alignItems: 'center'}}>
          {keywordsLength}
          <div style={{cursor: record?.url?.includes(getSiteName(domainName)) ? 'pointer' : 'not-allowed', marginLeft: '7px'}} onClick={() => {
            if (record?.url?.includes(getSiteName(domainName))) {
              setShowEditModal(true);
              setEditData({...record, keywords: record?.keywords?.join('\n')});
            }
          }}>
            <FontAwesomeIcon icon={faPen} color='#A3A4A4' />
          </div>
        </div>;
      },
    },
    {
      title: 'SOURCE',
      dataIndex: 'source',
      key: 'source',
      width: 70,
      render: function(_, record) {
        return (
          <Source style={{textAlign: 'left'}}>{record?.source === 'gsc' ?
            record?.source?.toUpperCase() :
            (record?.source?.charAt(0).toUpperCase() + record?.source?.slice(1))
          }</Source>
        );
      },
    },
  ];

  const handleChange = event => {
    let {value} = event.target;
    value = value.replace(/,{2,}/g, ',');
    const parts = value.split(',');
    if (parts.length > 3) {
      parts.splice(3);
      value = parts.join(',');
    }
    setKeywords(value);
  };

  const handlePageUrlChange = e => setPageUrl(e.target.value);

  useEffect(() => {
    if (!showAddPage) {
      form.resetFields();
      setKeywords('');
      setPageUrl('');
    }
  }, [showAddPage]);

  const addPages = async () => {
    setUpdateOttoProjectLoading(true);
    try {
      if (!keywords?.length) {
        setKeywordError(true);
        return;
      }
      const prePayload = {user_top_pages: tableData?.filter(i => i?.url?.includes(getSiteName(domainName)))?.map(i => ({url: i?.url, keywords: i?.keywords, is_selected: i?.isSelected}))};
      prePayload.user_top_pages.push({url: addProtocolToDomainHttps(url), keywords: keywords?.split(','), is_selected: true});
      await updateOttoV2Project(uuid, prePayload);
      await loadOttoV2Project(uuid);
      form.resetFields();
      setKeywords('');
      setPageUrl('');
      setShowAddPage(false);
      notification.success('', 'Page added successfully.');
    } catch (error) {
      const errorMessage = apiError(error, `Failed to update ${whitelabelOtto} project details`) as string;
      notification.error('', errorMessage);
    } finally {
      setUpdateOttoProjectLoading(false);
    }
  };

  const handleKeywordsChange = event => {
    let value = event.target.value;
    if (value) {
      setShowError(false);
    }
    const lines = value.split('\n');

    if (lines.length > 3) {
      lines.pop();
      value = lines.join('\n');
    }

    setEditData({...editData, keywords: value});
  };

  const handleKeyDown = event => {
    const value = event.target.value;
    const lines = value.split('\n');

    if (lines.length >= 3 && event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleUpdateKeywords = () => {
    if (!(editData?.keywords?.toString()?.trim())) {
      setShowError(true);
      return;
    }
    const updatedTableData = tableData.map(data => {
      if (data?.url === editData?.url) {
        return {...data, keywords: stringToArray(editData.keywords.toString(), keywordNewLineReg, ',') || []};
      } else {
        return data;
      }
    });
    setTableData(updatedTableData);
    setSearchResults(updatedTableData.filter(value => searchResults.some(i => i.url === value.url)));
    setShowEditModal(false);
  };


  const modalContent = (
    <StyledModal
      width={460}
      visible={showEditModal}
      onCancel={() => setShowEditModal(false)}
      footer={null}
      closable={false}
      centered
    >
      <ModalTxt>Add up to 3 keywords per URL, divided by a new line.</ModalTxt>
      <TextArea>
        <CustomTextarea variant='light' minHeight={125} value={editData.keywords} onChange={handleKeywordsChange} onKeyDown={handleKeyDown} isHorizontalScroll />
        {showError && <div style={{color: 'red'}}>Enter at least 1 keyword.</div>}
      </TextArea>
      <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          setShowEditModal(false);
        }}>
          Cancel
        </div>
        <StyledButton
          color='green'
          className='update-button'
          onClick={handleUpdateKeywords}
        >
           Update
        </StyledButton>
      </StyledModalFooter>
    </StyledModal>
  );

  return (
    <div>
      <Title>Select Top Pages</Title>
      <Desc>`Top Pages will trigger processing for all Advanced {whitelabelOtto} suggestions (subject to your quotas).`</Desc>
      <TableSection>
        <FilterSection>
          <div className='left-section'>
            <SearchInput
              onChange={handleSearch}
              value={searchValue}
              placeholder='Search'
              prefix={<FontAwesomeIcon icon={faMagnifyingGlass} color='#A3A4A4' />}
            />
            <Pages>Pages: <span>{tableData?.length}</span></Pages>
          </div>
          <FreezeWrapper>
            <div className='right-section' onClick={() => setShowAddPage(true)}>
            +Add page
            </div>
          </FreezeWrapper>
        </FilterSection>
        {showAddPage ? <AddPage>
          <Form.Item
            style={{marginBottom: '0px'}}
            name='url'
            rules={[
              {required: true, message: 'Please enter a URL'},
              () => ({
                validator(_, value) {
                  if (urlRegex.test(value) && value) {
                    const filterDuplicate = tableData.filter(item => item.url.replace(/\/$/, '') === addProtocolToDomainHttps(value).replace(/\/$/, ''));
                    if (filterDuplicate?.length) {
                      return Promise.reject(new Error('Page URL must be unique, given URL is already added'));
                    }
                    return Promise.resolve();
                  } else if (urlRegex.test(value) || !value) {
                    return Promise.resolve();
                  } else if (!urlRegex.test(value) || value) {
                    return Promise.reject(new Error('Please enter a valid URL'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <StyledInput
              placeholder='Enter the Page URL...'
              onChange={handlePageUrlChange}
            />
          </Form.Item>
          {/* <Form.Item
              style={{marginBottom: '0px'}}
              name='keywords'
              rules={[
                {required: true, message: 'Please enter a keyword'},
              ]}
            > */}
          <StyledInput
            placeholder='Add up to 3 keywords, divide by commas...'
            onChange={handleChange}
            value={keywords}
            keywordError={keywordError}
          />
          {keywordError && <ErrorMessage>Please enter a keyword</ErrorMessage>}
          {/* </Form.Item> */}
          <AddPageFooter>
            <Button
              color='blue'
              className='add-button'
              loading={updateOttoProjectLoading}
              onClick={addPages}
              style={{marginTop: '0px'}}
              disabled={tableData.filter(item => item.url.replace(/\/$/, '') === addProtocolToDomainHttps(url).replace(/\/$/, ''))?.length}
            >
                  Add page
            </Button>
            <button className='cancel' onClick={() => setShowAddPage(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </AddPageFooter>
        </AddPage> : ''}
        <StyledTable
          columns={columns}
          dataSource={searchValue?.length ? searchResults : tableData}
          pagination={false}
          loading={loadingProject}
        />

        {/* {searchResults?.length > 10 ? null : <StyledPaginationWrapper> <Pagination
          // onChange={handlePagination}
          // total={count}
          // pageSize={pageSize}
          // current={currentPage}
          defaultPageSize={10}
          defaultCurrent={1}
          showSizeChanger={true}
          showQuickJumper={false}
          showTotal={(total, [from, to]) => {
            return (
              <span style={{color: '#A3A4A4'}}>
                {from}-{to} {'of'} {total} {'results shown'}
              </span>
            );
          }}
          pageSizeOptions={['10', '20', '50', '100']}
        /> </StyledPaginationWrapper>} */}
      </TableSection>
      {/* <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          initialValues();
          setTabKeys('business_section');
          setIsKnowledgeModalVisible(false);
        }}>
          Cancel
        </div>
        <StyledButton
          color='green'
          className='update-button'
          onClick={handleUpdateTopPages}
          loading={loading}
        >
           Update
        </StyledButton>
      </StyledModalFooter> */}
      {modalContent}
    </div>
  );
});

export default TopPages;

const StyledModal = styled(Modal)`
  .ant-modal-content{
    border-radius: 12px;
    background: var(--White, #FFF);
  }
`;

const Title = styled.div`
  margin-top: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #121212;
`;

const Desc = styled.div`
  margin-top: 5px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #4E5156;
`;

const TableSection = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  width: 700px;
  min-height: 377px;
  gap: 0px;
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  background-color: #FFFFFF;
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  .left-section {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .right-section {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #2D6CCA;
    cursor: pointer;
  }
`;

const SearchInput = styled(Input)`
  border-radius: 6px;
  max-width: 181px;
`;

const Pages = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: rgba(18, 18, 18, 1);
  }
`;

const StyledTable = styled(TableStyled)`
  .ant-table-content {
    height: 325px;
    ::-webkit-scrollbar {
    width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(78, 81, 86, 0.5);
        border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

  }
  .checked-row .ant-table-tbody tr:nth-child(odd) td {
  background-color: rgba(42, 193, 85, 0.1) !important;
}


`;

const PageUrl = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #2D6CCA;
  width: 250px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis;
`;

const FirstCol = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const StyledCheckbox = styled(Checkbox)`  
  .ant-checkbox-inner {
    border-radius: 5px !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2AC155 !important;
    border-color: #2AC155;
  }
`;

// const StyledPaginationWrapper = styled.div`
//   border-radius: 0 0 8px 8px;
//   border-top: 1px solid $color-white-secondary;
//   box-shadow: 0 -1px 0 $color-white-secondary, 0 -3px 10px rgba(0, 0, 0, 0.02);
//   display: flex;
//   justify-content: flex-end;
//   padding: 20px 25px;
//   white-space: nowrap;
//   @media screen and (max-width: 700px) {
//     padding: 8px 0;
//   }

//   :global(.ant-select-selector) {
//     color: #121212;
//   }

//   p {
//     color: $color-secondary-text;
//     font-size: $font-size-14;
//     line-height: 28px;
//     margin-bottom: 0;
//     padding-left: 20px;
//     width: 40%;
//   }

//   :global(.ant-select) {
//     z-index: 2 !important;
//   }
// `;

const AddPage = styled.div`
  margin: 10px 0px;
`;

const StyledInput = styled(Input)<{keywordError?: boolean}>`
  border-radius: 6px;
  margin-bottom: 5px;
  ${p => p.keywordError ? `
    border-color: #ff4d4f;
    &:hover {
      border-color: #ff4d4f;
    }
    &:focus {
      border-color: #ff4d4f;
    }
  ` : ''}
`;

const AddPageFooter = styled.div`
  display: flex;
  align-items: center;
  .add-button {
    height: 24px;
    padding: 7px 12px;
    margin-left: 0px;
    margin-top: 2px;
  }
  .cancel {
    width: 26px;
    height: 24px;
    border-radius: 4px;
    background-color: #4E5156;
    border: 0px;
    color: #fff;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1.5715;

`;

const Keyword = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #121212;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextArea = styled.div`
  margin-top: 11px;
`;

const ModalTxt = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
  margin-top: 0;
`;
const Source = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: normal;
  color: #121212;
  font-weight: 400;
`;
