import {Dispatch, SetStateAction, useEffect} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {Input} from '@/components/common-components/v2';
import {Input as AntdInput} from 'antd';

interface Props {
  removeTopic: Dispatch<SetStateAction <number>>;
  index: number;
  currentItemState: {
    value: string;
    label: string;
    placeholder: string;
    fields: [
      {
        type: string;
        label: string;
        placeholder: string;
        value: string;
      }
    ];
  };
  addField: (type: 'h3', index: number, value?: string) => void;
  removeExistingField: (itemIndex: number, fieldIndex: number) => void;
  updateItemValue: (e, itemIndex: number, fieldIndex: number) => void;
  updateItemTopic: (e, itemIndex: number) => void;
  onSingleFieldDragEnd: (index: number, newState) => void;
  totalItems: number;
  totalTerms: number;
  totalQuestions: number;
  showError: boolean;
  clearItemValues: (itemIndex: number) => void;
  contentType: number;
  removeAllFields: (itemIndex: number) => void;
  calledFromEditor?: boolean;
}

export const H2Section = ({
  removeTopic,
  index,
  currentItemState,
  addField,
  removeExistingField,
  updateItemValue,
  updateItemTopic,
  onSingleFieldDragEnd,
  totalItems,
  showError,
  clearItemValues,
  contentType,
  removeAllFields,
  calledFromEditor = false,
}: Props) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const {TextArea, Group} = AntdInput;

  useEffect(() => {
    if (contentType !== 1 && !calledFromEditor) {
      removeAllFields(index);
    }
  }, [contentType]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newState = reorder(
      currentItemState.fields,
      result.source.index,
      result.destination.index,
    );

    onSingleFieldDragEnd(index, newState);
  };


  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: '5px 10px',
    margin: `0`,
    // background: '#fff',

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = () => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    // padding: '5px 0',
    width: '100%',
  });

  return <Wrapper>
    <DragDropContext onDragEnd={onDragEnd}>
      <TopSectionWrapper itemIndex={index} totalItems={totalItems} subHeading={false}>
        {/* <div><FontAwesomeIcon icon={faGripDotsVertical} style={{marginRight: 10}}/></div> */}
        <div style={{width: '100%'}}>
          {/* <div style={{fontSize: 12}}>Focus Topic (H2)</div> */}
          <Input
            type='text'
            variant='light'
            prefix={currentItemState.placeholder}
            suffix={ <div style={{display: 'flex'}}>
              <AddMoreItemsContainer>
                {/* <AddMoreTermsButton onClick={() => addField('h3', index)}>+ Add H3</AddMoreTermsButton> */}
              </AddMoreItemsContainer>
              {totalItems > 1 && <div className='trash-icon' onClick={() => removeTopic(index)}><FontAwesomeIcon icon={faTrash}/></div>}
            </div>}
            value={currentItemState.value}
            onChange={e => updateItemTopic(e, index)}
            className={'customLargeInput'} />
          {(showError && currentItemState.value === '' && currentItemState.fields?.filter(field => field.value)?.length > 0) && <ErrorMsg>This field is required.</ErrorMsg>}

        </div>
        {/* Show remove icon only if there is more than 1 topic */}

      </TopSectionWrapper>
      <Droppable droppableId='droppable'>
        {provided => (
          <>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle()}>
              {currentItemState.fields.map((item, idx) => (
                <>
                  <H3SectionWrapper itemIndex={idx} totalItems={currentItemState?.fields?.length || 0}>
                    <div style={{width: '1px'}}>
                      <div className='customH3Connection'/>
                    </div>
                    <Draggable key={idx} draggableId={`${item.type}-${idx}`} index={idx}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}>

                          <SingleFieldWrapper itemIndex={idx} totalItems={currentItemState?.fields?.length || 0}>
                            <div className='wrapper'>
                              <div className='connection'style={{marginTop: !idx? '10px': '0px'}}/>
                              <Group className={'customLargeInput'}>
                                <div className='suffix'>H3</div>
                                <TextArea
                                  autoSize
                                  className='custom-input'
                                  value={item.value}
                                  onChange={e => updateItemValue(e, index, idx)}
                                />
                                <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>
                              </Group>
                              {/* <CommonInput
                                type='text'
                                variant='transparent'
                                placeholder={item.placeholder}
                                value={item.value}
                                onChange={e => updateItemValue(e, index, idx)}
                                className={'customSmallInput'} /> */}
                              {/* {(showError && item.value === '') && <ErrorMsg>This field can not be empty</ErrorMsg>} */}

                            </div>
                            {/* Show remove button only if there are at least 2 fields of same type */}
                            {/* {(totalTerms > 1 && item.type == 'term') && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>}
                                {(totalQuestions > 1 && item.type == 'question') && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>} */}
                          </SingleFieldWrapper>

                        </div>
                      )}
                    </Draggable>
                  </H3SectionWrapper>
                </>
              ))}
              {provided.placeholder}
            </div>
            <div style={{display: 'flex'}}>
              {((contentType ===1 && currentItemState.fields.length <= 15) || (calledFromEditor && currentItemState.fields.length <= 15)) &&
              <AddMoreTopics onClick={() => addField('h3', index)}>+ Add H3</AddMoreTopics>
              }
              {((contentType === 1 && currentItemState.fields.length) || (calledFromEditor && currentItemState.fields.length <= 15)) &&
              <ClearTopics onClick={() => clearItemValues(index)}>Clear all</ClearTopics>
              }
            </div>
          </>
        )}
      </Droppable>
    </DragDropContext>

  </Wrapper>;
};

const generateH2BorderRadius = (index, total, subHeading) => {
  let output = '0px';

  if (subHeading) {
    output = '6px';
  } else {
    if (index == 0 ) {
      output = '6px 6px 0px 0px';
    }
    if (index == total - 1) {
      output = '0px 0px 6px 6px';
    }
    if (total == 1) {
      output = '6px';
    }
  }
  return output;
};

const generateH3Margin = index => {
  let output = '0px';

  if (index == 0 ) {
    output = '10px 0 0 0';
  }

  return output;
};


const generateH3ConnectionHeight = (index, total) => {
  let output = '50%';

  if (total>1) {
    output = '100%';
  }

  if (index == total - 1) {
    output = '52%';
  }
  if (index == 0 && index == total - 1) {
    output = '60%';
  }

  return output;
};

const generateH3Border = (index, total, subHeading) => {
  const borderLeft = '1px solid #E8E8E8';
  const borderRight = '1px solid #E8E8E8';
  let borderTop = '1px solid transparent';
  const borderBottom = '1px solid #E8E8E8';

  if (!subHeading) {
    if (index == 0) {
      borderTop = '1px solid #E8E8E8';
    }

    if (index == total - 1 && total > 2) {
      borderTop = '1px solid transparent';
    }
  }

  return {borderTop, borderRight, borderBottom, borderLeft};
};

const Wrapper = styled.div`
  // padding: 13px 16px;
  // background-color: #FAFAFA;
  // border: 1px solid #E8E8E8;
  // box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  // border-radius: 8px;
`;

const TopSectionWrapper = styled.div<{ itemIndex: number; totalItems: number; subHeading: boolean}>`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 38px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: ${p => generateH2BorderRadius(p.itemIndex, p.totalItems, p.subHeading)} !important;
    border: 1px solid #E8E8E8 !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
  }

  .trash-icon {
    color: #a3a4a4;

    &:hover {
      color: #ff6262;
    }
  }

`;
const AddMoreItemsContainer = styled.div`
  display: flex;
`;

const SingleFieldWrapper = styled.div<{itemIndex: number; totalItems: number}>`

  .wrapper {
    display: flex;
    align-items: center;

    .connection {
      width: 10px;
      height: 1px;
      background: #D9D9D9;
    }
  }

  .trash-icon {
    padding-right: 15px;
    color: #a3a4a4;
    margin-top: 7px;

    &:hover {
      color: #ff6262;
    }
  }

  .customLargeInput {
    display: flex;
    justify-content: space-between;
    background: white;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 11px !important;
    width: 327px;
    margin: ${p => generateH3Margin(p.itemIndex)};
    border-radius: ${p => generateH2BorderRadius(p.itemIndex, p.totalItems, false)} !important;    
    border-top: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderTop} !important;
    border-right: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderRight} !important;
    border-bottom: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderBottom} !important;
    border-left: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderLeft} !important;

    margin-left: 10px !important;

    .suffix {
      font-weight: 500 !important;
      color: #a3a4a4;
      font-size: 11px;
      line-height: 1.5715;
      margin-top: 7px;
    }

    .custom-input {
      font-size: 11px !important;
      font-weight: 500 !important;
      line-height: 2.08 !important;
      border: none;

      :focus {
        box-shadow: unset;
      }
    }
    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
  }
`;

const ErrorMsg = styled.div`
  font-size: 10px;
  color: #ff6262;
`;

const H3SectionWrapper = styled.div<{itemIndex: number; totalItems: number}>`
  display: flex;
  justify-content: end;

  .customH3Connection {
    width: 1px;
    background: #D9D9D9 !important;
    color: transparent;
    height: ${p => generateH3ConnectionHeight(p.itemIndex, p.totalItems)} !important;
  }

`;

const AddMoreTopics = styled.div`
    margin: 0 0 10px 0;
    color: #2D6CCA;
    font-size: 11px;
    cursor: pointer;
`;

const ClearTopics = styled.div`
    margin: 0 0 10px 10px;
    color: #a3a4a4;
    font-size: 11px;
    cursor: pointer;
`;
