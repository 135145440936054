import {Input, Divider, Switch, Space, Button} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import {Modal} from '@/components/common-components';
import {CloseOutlined, CheckOutlined} from '@ant-design/icons';


export const Header = styled.div`
  display: flex;
  background-color: #F2F2F5;
  font-weight: 600 !important;
  height: 38px;
  border-radius: 8px;
  align-items: center;
  padding: 10px 16px; 
  width: 100%;
  .metric-cell {
    width: 130px;
    min-width: 130px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
}

.deploy-cell { 
 width: 130px;
    min-width: 130px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
}

.ant-switch-handle::before {
  content: url('public/icons/gbp_check.svg') !important;
}

.status-cell {  
  width: calc(100% - 350px);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
  padding-right: 30px;
  flex-grow: 1;
}

.preview-cell {
  width: 30%;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
}

`;

export const StyledContainer = styled.div`
width: 100%;
display: flex;
padding: 10px 16px 10px 0px;
.ant-col .ant-col-24 .ant-form-item-label {
  display: none;
}
.form{
padding-right: 16px;
padding-left: 5px;
width: 70%;
flex-grow: 1;
flex-grow: 1;
}
.header-row {
  display: flex;  padding: 0px 5px;

  background-color: #F2F2F5;
  font-weight: 600;
  height: 38px;
  border-radius: 8px;
  align-items: center;
  padding: 0px 5px;
}
.ant-form-item {
  margin-bottom: unset !important;
}

.metrics {
   width: 130px;
   min-width: 130px;
   font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #121212;
}

.deploy-status {
   width: 130px;
   min-width: 130px;
   font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #121212;
}

.status {
  display: flex;
  position: relative;
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  padding: 5px;
  width: calc(100% - 350px);
}

.record-row {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  margin: 0px;
  max-width: 875px;
  width: 100%;
  min-width: 100%;
}

.edit-icon {
  margin-left: 8px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  color: #2D6CCA;
  z-index: 3;
  opacity: 0;
}

.record-row:hover {
  background-color: #F2F2F5;
}

.record-row:hover .edit-icon {
  opacity: 1;
}

.input-actions {
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid gray;
  width: 100%;
  // min-width: 220px;
}

.input-actions .ant-input {
  margin-right: 8px;
}
.input-actions .ant-input-affix-wrapper:focus, .input-actions .ant-input-affix-wrapper-focused {
  box-shadow: unset;
}
.preview {
width: 30%;
border: 1px solid #dadce0;
border-radius: 12px;
height: fit-content;
}
.ant-tabs-nav-list {
  width: 100% !important;
  justify-content: space-evenly !important;
}

.photos-container {
  display: flex;
  align-items: center;
  margin-top: 19px;
  .photos {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
.image-status {
  flex-grow: 1;
  align-items: center;
  position: relative;
}
  .ant-btn-submit {
  background-color:  #2D6CCA !important;
  padding: 13px 25px 14px 25px;
  border-radius: 8px;
  height: 44px;
  border: none;
  color: white;
    &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color:  '#7f4ead2E';
    border: 1px solid transparent !important;
    color: #fff;
  }
  }
`;

export const StyledInput = styled(Input)`
border: none;
&:focus {
box-shadow: none;
}
`;
export const StyledTextArea = styled(TextArea)`
border: none;
&:focus {
box-shadow: none;
}
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

export const StyledDivider = styled(Divider)`
margin: 0px auto !important;
min-width: unset;
width: 100%
`;

export const AttributeValue = styled.div`
background-color: #F9F9FB;
border-radius: 38px;
padding: 3px 8px 3px 10px;
border: 1px solid #D2D2D2;
width: fit-content;
`;


export const ModalStyled = styled(Modal)`
  .rc-dialog-content {
    padding: 10px;
  }
  .rc-dialog-title {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .rc-dialog-wrap {
    align-items: center !important;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: 20px 30px 20px 30px !important;
    border-radius: 12px !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
    .ant-btn-submit {
      background-color:  #2D6CCA !important;
      color: white;
      padding: 13px 25px 14px 25px;
      border-radius: 8px;
      height: 44px;
      border: none;
      margin-top: 15px;
      &:hover {
        background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
        background-color:  '#7f4ead2E';
        border: 1px solid transparent !important;
        color: #fff;
       }

    }
    form{
      .modal-height{
         max-height: calc(100vh - 230px);
         overflow: auto;
         padding-right: 30px;
         margin-right: -25px;
         .day-title{
          font-weight: 600;
          }
            &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
        }
      .category-modal-height {
        max-height: calc(100vh - 556px) !important;

      }
      .btn-div{
          margin-bottom: 0;
        }
      .form-group.field.field-object{
        max-height: calc(100vh - 230px);
        overflow: auto;
        padding-right: 30px;
        margin-right: -25px;
        .ant-row-end{
          justify-content: flex-start;
        }
        .ant-row-top{
          align-items: flex-end;
          .ant-btn-group{
            margin-bottom: 24px;
          }
        }
        button {
          &.ant-btn{
            &.ant-btn-dangerous{
              background: transparent;
              border-color: #00000033;
              svg{
                fill: #ff4d4f;
              }
            }
            &.array-item-add{
            background: transparent;
            box-shadow: unset !important;
            padding: 0 !important;
            border: 0px solid transparent !important;
            height: 16px;
            width: 120px;
            &:before{
              content: '+ Add another Item';
              display: block;
              color: #2D6CCA;
              opacity: 1;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
            }
            span.anticon.anticon-plus-circle{
              display: none;
            }
          }
        }
      }
      }
    }
  .rc-dialog-header {
  background: var(--Table-row-stripe, #F9F9FB);
  }
  .rc-dialog-content {
  background: var(--Table-row-stripe, #F9F9FB);
  }
`;

export const DeployContainer = styled.div<{marginTop?: string}>`
  margin-top: ${p => p?.marginTop ? p?.marginTop : '10px'} !important;
  display: flex;
  justify-content: space-between;
  padding: 0px 14px 16px 13px;
  .buttons-container {
    width: 76%;
    justify-content: flex-end;
    display: flex;
    gap: 14px;
    position: relative;
  }
  .button-container {
    padding: 5px 10px;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
  }
  .ant-switch {
    margin-right: 5px;
    width: 37px;
    min-width: 37px;
    height: 24px;
  }
  .ant-switch-checked {
    background: #18923B !important;
  }
  .ant-switch-unchecked {
    background: #4E515626;
  }
`;

export const NoDataDiv = styled.div`
  width: 100%;
  text-align: center;
  height: 75vh;
  font-size: x-large;
  color: gray;
  padding: 100px;
`;

export const PopupContainer = styled.div`
  position: relative;
  display: inline-block;

  .popup {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    cursor: pointer;
    display: none; // Initially hidden
  }
`;

export const IntervalLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const DayLabel = styled.span`
  min-width: 90px;
`;

export const IntervalsContainer = styled.div`
  width: 220px;
`;

export const InputContainer = styled.div`
  display: flex;
  border: 1px solid #A3A4A4;
  border-radius: 6px;
  padding: 1px;
`;

export const CheckIcon = styled(CheckOutlined)`
  background-color: #2D6CCA;
  color: white;
  font-size: 17px;
  border-radius: 6px;
  padding: 6px;
  margin: 2px;
`;

export const StyledLoader = styled.div`
  background-color: #2D6CCA;
  color: white;
  font-size: 17px;
  border-radius: 6px;
  padding: 6px;
  margin: 2px;
`;

export const CrossIcon = styled(CloseOutlined)`
  background-color: #4E5156;
  color: white;
  font-size: 17px;
  border-radius: 6px;
  padding: 6px;
  margin: 2px;
`;

export const ValueDiv = styled.div<{isURL: boolean}>`
  width: calc(100% - 60px);
  overflow-x: ${p => p.isURL ? 'clip' : ''};
  text-overflow: ${p => p.isURL ? 'ellipsis' : ''};
  white-space: ${p => p.isURL ? 'nowrap' : ''};
  .click-link {
  cursor: pointer;
  color: #2D6CCA;
  }
`;

export const AttributeContainer = styled.div `
  max-width: fit-content;
  overflow-wrap: break-word;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const Attribute = styled.div<{isURL:boolean}>`
  border: 1px solid #D2D2D2;
  padding: 3px 15px 3px 15px;
  border-radius: 38px;
  background: #F9F9FB;
  color: ${p => p.isURL && '#2D6CCA'};
  cursor: ${p => p.isURL && 'pointer'};
  word-break: break-all;
  line-height: 20px;
  text-wrap: wrap;
  width: fit-content;
  }
`;
export const AttributeOverflow = styled.div `
  width: 100%;
  height: fit-content;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 15px;
`;
export const ButtonStyled = styled(Button)`
  background-color:  #2D6CCA !important;
  padding: 13px 25px;
  border-radius: 8px;
  height: 44px;
  border: none;
    &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color:  '#7f4ead2E';
    border: 1px solid transparent !important;
    color: #fff;
  }
`;
export const CloseButton = styled(Button)`
  background-color:  transparent;
  padding: 13px 25px 14px 25px;
  border-radius: 8px;
  height: 44px;
  border: none;
    &:hover {
    background-color:  transparent;
    color: #000;
  }
  box-shadow: unset;
`;
export const FormStyledContainer = styled.div`
  .ant-space-item {
    width: 100% !important;
    display: flex;
    justify-content: flex-end;
  }
  .ant-form-item {
    margin-bottom: unset;
  }
  .suggestion-box {
    width: 100%;
    height: fit-content;
    border-radius: 8px;
    background: #2AC15526;
    padding: 12px 30px 12px 17px;
    display: flex;
    margin-bottom: 20px;
  }
  .category-list {
    font-weight: 600;
    line-height: 16.94px;
    margin-top: 7px;
    margin-bottom: 15px;

  }
  .accept-button {
    width: fit-content;
    height: 32px;
    border-radius: 8px;
    background: var(--Green, #2AC155);
    color: white; 
    }
  .accept-button:hover,
  .accept-button:active,
  .accept-button:focus {
     border-color: #2AC155 !important;
   }
  .refuse-button {
    background: transparent;
    border: unset;
    box-shadow: unset;
    color: black
    }
  .refuse-button:hover,
  .refuse-button:active,
  .refuse-button:focus {
    color: black !important;
  }
  .icon-div{
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 3px;
    background: #2AC15566;
    text-align: center;
    padding: 4px 8px;
    height: fit-content;
  }
  .ant-form-item-label label {
  color: #4E5156 !important;
  }
  .ant-select-selector,
  .ant-picker,
  .ant-input {
    height: 38px !important;
    border-radius: 8px !important;
    padding: 2px 11px !important;
  }
  .ant-picker {
    width: 90%;
  }
  .add-button {
    background: transparent;
    border: unset;
    color: #1890ff;
    box-shadow: unset;
    padding: unset;
    width: fit-content;
  }
  .buttons-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  .delete-icon {
    height: 14px;
    margin-top: 42px;
    margin-left: 10px;
    &:hover {
    color: red
    }
  }
  .ant-row {
    margin-bottom: 10px;
  }
  .ant-form-item-required::before {
    content: unset !important;
  }
  .ant-select-arrow .anticon > svg {
  color: black;
  }
`;
export const StyledSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  gap: unset;
  width: 100%;
`;

export const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10000;
  width: calc(100% - 60px);
  height: calc(100% - 93px);
`;

export const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
img{
  border-radius: 8px;
  width: 60px;
  height: 50px;
}
`;

export const StyledModal = styled(Modal)`
  width: fit-content !important;
  .ant-modal-close-x {
    position: absolute;
    top: -19px;
    left: 0px;
    right: -70px;
  }
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;

  .icon-and-text {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    .icon {
      margin-right: 10px;
    }
  }

  p {
    margin: 0;
  }

  .clickable-text {
    color: #34AEF3;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledSwitch = styled(Switch)<{lockOn?: boolean; isDeployButton?:boolean}>`
  background: ${p => !p?.isDeployButton && '#F44343 !important'};
  background-image: ${p => !p?.isDeployButton && 'none'};
  .ant-switch-handle {
    z-index: 1;
  }
  .ant-switch-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    margin-left: ${p => p?.lockOn ? p?.isDeployButton ? '8px' : '7px' : p?.isDeployButton ? '-8px' : '-6px'};
  }
`;

export const Badge = styled.div<{lockOn: boolean}>`
  position: absolute;
  top: -10px;
  right: -12px;
  background-color: ${p => p?.lockOn ? '#18923B' : '#ff4d4f'};
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  z-index: 2;
  padding: 12px;
`;

export const SuggestionButton = styled(Button)`
  height: 26px;
  border-radius: 6px;
  background: #7F4AED;
  color: white;
  padding: 0px 10px;
  border: 1px solid #7F4AED;
  font-size: 12px;
  
  &:hover {
    background: #7F4AED !important;
    color: white !important;
    border: 1px solid #7F4AED !important;
  }
}`;


export const DeployTooltipContainer = styled.div`
display: flex;
padding: 10px;
.deploy-button {
  background: #2AC155 !important;
  color: white;
  border: 1px solid #2AC155;
  border-radius: 6px;
}
  .deploy-button:hover {
    background: #2AC155;
  color: white;
  border: 1px solid #2AC155;
  }

  .heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  }
  .text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  }
  .fields-list {
    font-size: 10px;
  }
`;

export const FieldStatus = styled.div<{isDefault: boolean}>`
font-size: 13px;
cursor: ${p => p.isDefault ? 'default' : 'pointer'};

.icon {
margin-right: 5px;
}
`;

export const TopSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  &.refresh-section {
    justify-content: space-between;
    width: 100%;
  }
`;
export const GBPDropdownWrapper = styled.div`
  width: fit-content;
  padding: 7px 15px 8px 15px;
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
`;
export const GBPDropdownContainer = styled.div`
  width: 480px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: rgba(138,138,138,0.32);
  }
          
  &::-webkit-scrollbar-track {
    border-radius: 12px;
    background: rgba(138,138,138,0.12);
  }
`;
export const GbpSearchInput = styled(Input)`
  width: 460px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;
export const BusinessName = styled.div`
  font-weight: 500;
  color: #121212;
`;
export const BusinessAddress = styled.div`
  font-size: 12px;
  color: #4E5156;
`;
