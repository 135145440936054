import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

export interface ReportCardProps {
  panelOneContent?: React.ReactElement<any>;
  panelTwoContent?: React.ReactElement<any>;
  className?: string;
  pannelTwoClassName?: string;
  flexGrow?: string;
}

export const ReportCard: React.FC<ReportCardProps> = ({
  panelOneContent,
  panelTwoContent,
  className,
  pannelTwoClassName,
  flexGrow,
}) => {
  return (
    <div className={classnames(styles.reportCard, className)}>
      <div style={{flexGrow: `${flexGrow}` as any}} className={styles.pannelOne}>
        {panelOneContent}
      </div>
      <div className={`${styles.pannelTwo} ${pannelTwoClassName ?? ''}`}>{panelTwoContent}</div>
    </div>
  );
};
