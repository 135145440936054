import {observer} from 'mobx-react-lite';
import React from 'react';
import EChartsReact from 'echarts-for-react';
import {NoDataWrapper} from '../StyledComponent';
import {numberWithCommas} from '@/utils/number';

interface Props {
  showViewColum?: boolean;
  data?: any[];
  loading?: boolean;
  dataLoader?: boolean;
  firstSeriesName?: string;
  secondSeriesName?: string;
}
export const BarChart: React.FC<Props> = observer(({showViewColum=true, data, loading, dataLoader, firstSeriesName='New Users', secondSeriesName='Views'}) => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      show: showViewColum,
      icon: 'circle',
      left: 0,
      top: 10,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: showViewColum ? '15%' : '10%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: '#E8E8E8',
        },
      },
      max: function(value) {
        return value.max + (value.max/8);
      },
    },
    yAxis: {
      type: 'category',
      data: loading ? [200, 324, 32, 654, 467] : data?.map(z => showViewColum ? z?.sessionDefaultChannelGroup : z?.source),
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        name: firstSeriesName,
        type: 'bar',
        data: loading ? [65, 567, 878, 45, 765] : data?.map(z => showViewColum ? z?.newUsers : z?.totalRevenue),
        itemStyle: {
          color: loading ? '#a3a4a4' : '#F1AA3E',
          borderRadius: [0, 3, 3, 0],
        },
        label: {
          show: true,
          position: 'right',
          color: loading ? '#a3a4a4' : '#4E5156',
          formatter: value=> {
            return numberWithCommas(value?.value);
          },
        },
      },
      showViewColum && {
        name: secondSeriesName,
        type: 'bar',
        data: loading ? [78, 457, 458, 440, 757] : data?.map(z => z?.totalUsers),
        itemStyle: {
          color: loading ? '#a3a4a4': '#E8D8C0',
          borderRadius: [0, 3, 3, 0],
        },
        label: {
          show: true,
          position: 'right',
          color: loading ? '#a3a4a4': '#4E5156',
          formatter: value=> {
            return numberWithCommas(value?.value);
          },
        },
      },
    ],
  };
  return (
    (loading || data?.length || dataLoader) ? <div>
      <EChartsReact
        style={{height: '360px'}}
        option={option}
      />
    </div> : <NoDataWrapper>No Data</NoDataWrapper>
  );
});
