import {Input, InputNumber, InputRef} from 'antd';
import {Label} from '../label';
import styles from './styles.module.scss';
import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const {TextArea} = Input;

export enum InputSize {
  Small,
  Medium,
  MediumV2,
  Large,
}
const renderInputSize = (size: InputSize) => {
  switch (size) {
    case InputSize.Small:
      return {
        height: 30,
        fontSize: 12,
      };
    case InputSize.Medium:
      return {
        height: 40,
        fontSize: 12,
      };
    case InputSize.MediumV2:
      return {
        height: 48,
        fontSize: 12,
      };
    case InputSize.Large:
      return {
        height: 60,
        fontSize: 14,
      };
  }
};
const renderTextAreaSize = (size: InputSize) => {
  switch (size) {
    case InputSize.Small:
      return {
        height: 55,
        fontSize: 12,
      };
    case InputSize.Medium:
      return {
        height: 70,
        fontSize: 12,
      };
    case InputSize.MediumV2:
      return {
        height: 70,
        fontSize: 12,
      };
    case InputSize.Large:
      return {
        height: 90,
        fontSize: 14,
      };
  }
};

export interface CommonInputProps {
  placeholder?: string;
  size?: InputSize;
  inputType?: 'text' | 'password' | 'textarea' | 'number' | 'tel' | 'email';
  variant?: 'light' | 'dark' | 'transparent' | 'white' | 'lightGrey' | 'gray';
  value?: string;
  disabled?: boolean;
  isRequired?: boolean;
  isValid?: boolean;
  readOnly?: boolean;
  onChange?: (event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onInput?: (event) => void;
  onBlur?: (event) => void;
  onFocus?: (event) => void;
  label?: string;
  autoSizeCheck?: boolean;
  name?: string;
  id?: any;
  autoFocus?: boolean;
  round?: boolean;
  maxlength?: number;
  min?: number;
  max?:number;
  type?: string;
  defaultValue?: number | string;
  className?: string;
  containerClassName?: string;
  icon?: React.ReactNode;
  formatter?: (value: string) => string;
  parser?: (value: string) => string;
  iconClasses?: string;
  onPressEnter?: (event) => void;
  onKeyDown?: (event) => void;
  cyId?:string;
  inputRef?: React.Ref<any>;
  allowClear?: boolean | {
    clearIcon?: React.ReactNode;
  };
  controls?: boolean | {
    upIcon?: React.ReactNode;
    downIcon?: React.ReactNode;
};
suffix?: any;
padding?: string;
}


// TODO: Refactor
export const CommonInput: React.FC<CommonInputProps> = (props: CommonInputProps, {...rest}) => {
  const inputSize = props.size || InputSize.Medium;
  const label = props.label || null;
  const autoSizeCheck = props.autoSizeCheck || false;
  return (
    <div className={classnames(styles.customInput, props.containerClassName)}>
      {label && (
        <Label htmlFor={props.name} id={props.id}>
          {label}
        </Label>
      )}
      {props.inputType === 'textarea' ? (
        <StyledTextArea
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          autoSize={autoSizeCheck}
          onChange={props.onChange ? props.onChange : null}
          onInput={props.onInput ? props.onInput: null}
          style={renderTextAreaSize(inputSize)}
          autoFocus={props.autoFocus}
          maxLength={props.maxlength}
          className={classnames(
            {
              [styles.light]: props.variant === 'light',
              [styles.dark]: props.variant === 'dark',
              [styles.transparent]: props.variant === 'transparent',
              [styles.white]: props.variant === 'white',
              [styles.lightGrey]: props.variant === 'lightGrey',
              [styles.gray]: props.variant === 'gray',
            },
            styles.roundInput,
            props.className,
          )}
          {...rest}
        />
      ) : props.inputType === 'number' ? (
        <InputNumber
          controls={props.controls ?? true}
          onKeyDown={props.onKeyDown ? props.onKeyDown : null}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          min={props.min}
          max={props.max}
          formatter={props.formatter ? props.formatter: null}
          parser={props.parser ? props.parser: null}
          onChange={props.onChange ? props.onChange : null}
          style={renderInputSize(inputSize)}
          autoFocus={props.autoFocus}
          className={classnames(
            {
              [styles.light]: props.variant === 'light',
              [styles.dark]: props.variant === 'dark',
              [styles.transparent]: props.variant === 'transparent',
              [styles.white]: props.variant === 'white',
              [styles.lightGrey]: props.variant === 'lightGrey',
              [styles.gray]: props.variant === 'gray',
            },
            styles.roundInput,
            styles.inputNumber,
            props.className,
          )}
          disabled={props.disabled}
          required={props.isRequired}
          type={props.type}
          {...rest}
        />
      ) :
        (
          <Input
            ref={props.inputRef as React.Ref<InputRef>}
            data-cy={props.cyId}
            onPressEnter={props.onPressEnter}
            onInput={props.onInput ? props.onInput: null}
            onBlur={props.onBlur ? props.onBlur: null}
            onFocus={props.onFocus ? props.onFocus: null}
            type={props.inputType || 'text'}
            name={props.name}
            id={props?.id}
            defaultValue={props.defaultValue}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange ? props.onChange : null}
            style={{padding: props?.padding, ...renderInputSize(inputSize)}}
            autoFocus={props.autoFocus}
            maxLength={props.maxlength}
            allowClear={props.allowClear ?? false}
            className={classnames(
              {
                [styles.light]: props.variant === 'light',
                [styles.dark]: props.variant === 'dark',
                [styles.transparent]: props.variant === 'transparent',
                [styles.white]: props.variant === 'white',
                [styles.lightGrey]: props.variant === 'lightGrey',
                [styles.gray]: props.variant === 'gray',
              },
              styles.roundInput,
              props.className,
            )}
            disabled={props.disabled}
            required={props.isRequired}
            suffix={props.suffix}
            {...rest}
          />
        )}
      <span className={props.iconClasses}>
        {props.icon && props.icon}
      </span>
    </div>
  );
};

export const StyledTextArea = styled(TextArea)`
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;
