import React from 'react';
import {Col, Form, Row} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FieldsContainer, StyledTextArea} from '../styleComponents';
import RadioField from './radioField';

const Fields = ({fieldsArr, form, disabled=false}) => {
  const fieldsConf = {
    radio: item => <RadioField form={form} data={item} disabled={disabled}/>,
    textarea: props => <StyledTextArea {...props} defaultValue={props?.defaultValue} disabled={disabled}/>,
  };

  const renderFields= item => {
    switch (item?.fieldType) {
      case 'radio':
        return fieldsConf['radio'](item);
      case 'textarea':
        return fieldsConf[item?.fieldType](item?.props);
      default:
        break;
    }
  };

  return (
    <FieldsContainer>
      {fieldsArr?.map((item, index) => (
        <Row key={index} justify='space-between'>
          <Col>
            <div className='field-label'>
              <FontAwesomeIcon icon={item?.icon} color='#2D6CCA'/>
              <div className='text'>{item?.label}</div>
            </div>
          </Col>
          <Col>
            <Form.Item name={item?.key} initialValue={item?.defaultValue || item?.props?.defaultValue}>{renderFields(item)}</Form.Item>
          </Col>
        </Row>
      ))}
    </FieldsContainer>
  );
};

export default Fields;
