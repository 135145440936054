import {observer} from 'mobx-react';
import {Address, CheckboxStyled, DataContainer, DataWrapper, LoadMoreBtn, LoadMoreContainer, Name} from '../styledComponents';
import {Divider} from 'antd';
import {useStore} from '@/store/root-store';

export const FilterWithData = observer((props: any) => {
  const {localSeoScan: {
    paramsV3,
    setParamsV3,
    getLocalSeoprojectsListV3,
    projectListParamsV3,
    loadingSeoprojectsListV3,
    setProjectListParamsV3,
    projectList,
    setAddMore,
  }} = useStore('');

  const loadMore = async () => {
    setAddMore(true);
    await setProjectListParamsV3({page_size: 10, page: projectListParamsV3?.page+1}, false, false);
  };

  const disableSelectAll = projectList?.length == props?.selectedIds?.length;
  const disableDeselectAll = props?.selectedIds?.length == 0;

  return <div style={{width: '100%'}}>
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <div style={{fontSize: '12px', color: '#4E5156'}}>Saved Businesses: {getLocalSeoprojectsListV3?.count}</div>
      <div style={{display: 'flex'}}>
        <div style={{fontSize: '12px', color: '#2D6CCA', marginRight: '20px', cursor: disableSelectAll ? 'not-allowed' : 'pointer'}} onClick={() => {
          !disableSelectAll && setParamsV3({business_ids: projectList?.map(item => item.id)}, true);
          !disableSelectAll && props?.setSelectedIds(projectList?.map(item => item.id));
        }}>Select all</div>
        <div style={{fontSize: '12px', color: '#2D6CCA', cursor: disableDeselectAll ? 'not-allowed' : 'pointer'}} onClick={() => {
          !disableDeselectAll && setParamsV3({business_ids: []}, true);
          !disableDeselectAll && props?.setSelectedIds([]);
        }}>Deselect all</div>
      </div>
    </div>
    {projectList?.map((item, idx) => <>
      <DataContainer key={idx}>
        <CheckboxStyled checked={paramsV3?.business_ids?.includes(item?.id)} value={item?.id} onChange={props?.dataOnChange}></CheckboxStyled>
        <div>
          <DataWrapper>
            <Name>{item?.businessName || '-'}</Name>
            <Address>{item?.address || '-'}</Address>
          </DataWrapper>
        </div>
      </DataContainer>
      <Divider style={{color: '#F9F9FB', margin: '0'}}/>
    </>,
    )}
    {getLocalSeoprojectsListV3?.totalPages !== projectListParamsV3?.page && <LoadMoreContainer>
      <LoadMoreBtn marginTop='10px' style={{margin: 'auto'}}
        disabled={getLocalSeoprojectsListV3?.totalPages == projectListParamsV3?.page}
        loading={loadingSeoprojectsListV3}
        onClick={() => loadMore()}
      >Load more</LoadMoreBtn>
    </LoadMoreContainer>}
  </div>;
});
