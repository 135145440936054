import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

export class ReporterApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');

  public async getHaroQueries(page, pageSize, queryParams) {
    const params = {
      'page': page,
      'page_size': pageSize,
      ...queryParams,
    };
    try {
      const response = await this.axios.get(`${ReporterApi.baseUrl}/customer/haro-query/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getHaroPitches(page, pageSize) {
    const params = {
      'page': page,
      'page_size': pageSize,
    };
    try {
      const response = await this.axios.get(`${ReporterApi.baseUrl}/customer/haro-pitch/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getReplyString(id) {
    try {
      const response = await this.axios.get(`${ReporterApi.baseUrl}/customer/haro-query/${id}/generate-response/`, {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async submitReplay(id, msg?: string) {
    const params = {
      ...(msg && {'response': msg}),
    };
    try {
      const response = await this.axios.post(`${ReporterApi.baseUrl}/customer/haro-query/${id}/reply/`,
        {
          ...params,
        },
        {
          headers: {Authorization: getAuthorizationHeader()},
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async generateAiReplay(id, guidelines?: string) {
    const params = {
      ...(guidelines && {'guidelines': guidelines}),
    };
    try {
      const response = await this.axios.post(`${ReporterApi.baseUrl}/customer/haro-query/${id}/generate-response/`, {
        ...params,
      },
      {
        headers: {Authorization: getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const REPORTER_API = new ReporterApi();
