import React from 'react';
import {Input, Select, Tooltip} from 'antd';
import styled from 'styled-components';
import {getDomain} from '@/utils/url';
import {observer} from 'mobx-react-lite';
import {Search} from 'react-feather';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFlag} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';

interface Props {
  onProjectChange?: (value)=> void;
  onSearch?: (value)=> void;
  selectedId?: any;
  sitePropertiesList?: any[];
  isReportBuilder?: boolean;
  keyValue?: string;
  height?: string;
  searchValue?: string;
  property?: string;
  redBorder?: boolean;
  loading?: boolean;
  hideImage?: boolean;
  dropDownWidth?: string;
  hideId?: boolean;
}
export const ProjectSelector: React.FC<Props> = observer(({dropDownWidth='330px', keyValue='propertyId', property, onProjectChange, loading, sitePropertiesList, selectedId, isReportBuilder, hideImage, height, searchValue, onSearch, redBorder, hideId = false}) => {
  const {navBar: {setIsActivatePropertiesDrawerOpen}} = useStore('');
  const {Option} = Select;
  const addDefaultSrc = e => {
    e.target.src = '/img/project-no-screenshot.png';
  };
  const getImgUrl = url => {
    return <ImgStyled onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=64&domain_url=https://www.${getDomain(url)}`} alt={url}/>;
  };
  const getNewProjectData = id => {
    onProjectChange(id);
  };

  const projectData = items => {
    const obj = {
      'Google Business': items?.businessName,
      'GA': items?.name,
      'KRT': items?.hostname?.replace('sc-domain', 'Domain'),
      'Backlinks': items?.metrics?.url,
      'GMB': items?.title,
      'Facebook': items.name,
      'Google': items.descriptiveName,
    };
    return obj[`${property}`];
  };

  return (
    <StyledSelect dropDownWidth={dropDownWidth} notFoundContent={<div>
      {property == 'GSC' ? <DivContainer>
        <FontAwesomeIcon icon={faFlag} fontSize='30px'/>
        <EmptyContent>No GSC Properties found for selected location.</EmptyContent>
        <BlueText onClick={()=> setIsActivatePropertiesDrawerOpen()}>Click here to activate new locations.</BlueText>
      </DivContainer> :
        <EmptyContent>No keywords found with selected Project.</EmptyContent>};
    </div>} loading={loading} disabled={loading} redBorder={loading ? false : redBorder} height={height} isReportBuilder={isReportBuilder} dropdownMatchSelectWidth={250} allowClear={true}
    getPopupContainer={trigger => trigger.parentNode} value={`${[null, undefined, 'null', 'undefined', ''].includes(selectedId) ? 'Select the GA property' : selectedId}`} onChange={getNewProjectData}
    dropdownRender={menu => (
      <>
        <div onClick={e=> e.stopPropagation()}
          className={''}>
          <Input
            placeholder={'Search...'}
            value={searchValue}
            onChange={onSearch}
            prefix={<Search width={15}/>}
            bordered={false}
          />
        </div>
        {menu}
      </>
    )}
    >
      {sitePropertiesList?.map((items, index)=> {
        return <Option key={`project-index-${index}`} value={property == 'Backlinks' ? items?.metrics?.url : `${items[`${keyValue}`]}`}>
          <Tooltip placement='left' color='#121212'
            title={items?.activeForCurrentCustomer ? '' : ''
            // <div>Inactive project. You can manage project status <span style={{color: '#2D6CCA', cursor: 'pointer'}}>here</span></div>
            }>
            <EllipsisWrapper>
              <span style={{marginLeft: '5px'}}>
                {!hideImage && getImgUrl(property == 'Backlinks' ? items?.metrics?.url : property == 'KRT' ? items?.hostname : property === 'GMB' ? items?.websiteUrl : items?.url)}
                {projectData(items) || items[keyValue]?.toString()?.replace('sc-domain', 'Domain')}</span>&nbsp;
              {(items?.hostname || items?.trackedKeywordsCount) && <span style={{marginTop: '2px'}}>{`${property == 'KRT' ? ([null, 'null', undefined, 'undefined'].includes(items?.trackedKeywordsCount) ? '' : `(${items?.trackedKeywordsCount} keywords)`) : `(${items?.hostname})`}` || ''}</span>}
              <span style={{color: '#A3A4A4', marginTop: '2px', marginLeft: '5px'}}>{hideId ? '' : items?.id ? items?.id : items?.propertyId || ''}</span>
            </EllipsisWrapper>
          </Tooltip>
        </Option>;
      })}
    </StyledSelect>
  );
});


const StyledSelect = styled(Select)<{loading?: boolean; dropDownWidth: string; isReportBuilder?: boolean; height?: string; redBorder?: boolean}>`
  .ant-select-selector {
    width: ${p=> p?.isReportBuilder ? p?.dropDownWidth : ''} !important;
    border-radius: 5px !important;
    background: #CDCDCD;
    cursor: pointer !important;
    height: ${p=> p?.height ? p?.height : p?.isReportBuilder ? '38px' : '28px'} !important;
    line-height: unset !important;
    border: ${p=> (p?.redBorder && !p?.loading) ? '1px solid #F44343' : '1px solid #CDCDCD'} !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }
  .ant-select-selection-item {
    display: flex;
  }
  .anticon-loading {
    color: #2D6CCA !important;
    font-size: 15px !important;
  }
`;

const ImgStyled = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;
const EllipsisWrapper = styled.div`
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media screen and (max-width: 860px) {
    width: 96%;
  }
`;
const EmptyContent = styled.div`
 margin: 20px 0 0 0;
 color: #4e5156 !important;
 text-align: center;
`;
const DivContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const BlueText = styled.div`
  color: #2D6CCA !important;
  text-decoration: underline;
`;
