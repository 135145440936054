import {toJS} from 'mobx';
import {flow, types, cast, getRoot} from 'mobx-state-tree';
import {FocusedKeywordStore, initFocusedKeywordStore} from './focused-keyword';
import {initOverviewStore, OverviewStore} from './overview';
import {BannerVariant} from '@/components/common-components/components/banner';
import {OVERVIEW_DETAIL_API} from '@/api/competitor-explorer';
import {COMPETITOR_RESEARCHER_API_POLL_INTERVAL} from '@/constants';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';


export const MetricsModel = types.model({
  countryCode: types.maybeNull(types.string),
  initialProcessingStatus: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  mode: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  domainRating: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

export const ResearcherListModel = types.model({
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  metrics: types.maybeNull(MetricsModel),
  searchedOn: types.maybeNull(types.string),
});

export const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});


export const competitorsArrayModal = types.model({
  rank: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  commonTerms: types.maybeNull(types.number),
});

export const competitorsModal = types.model({
  ppcCompetitors: types.maybeNull(types.array(competitorsArrayModal)),
  seoCompetitors: types.maybeNull(types.array(competitorsArrayModal)),
});

export const topKeywordsModal = types.model({
  keyword: types.maybeNull(types.string),
  seoClicks: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  topRankedurl: types.maybeNull(types.string),
  rankingDifficulty: types.maybeNull(types.number),
  totalMonthlyclicks: types.maybeNull(types.number),
  rank: types.maybeNull(types.number),

});

export const organicDomainOverviewKeywordStatusModal = types.model({
  lostranks: types.maybeNull(types.number),
  gainedranks: types.maybeNull(types.number),
  newlyranked: types.maybeNull(types.number),
});

export const htmlLabelsModal = types.model({
  hasData: types.maybeNull(types.boolean),
  hasTableData: types.maybeNull(types.union(types.boolean, types.string)),
  heading: types.maybeNull(types.string),
  nextCheck: types.maybeNull(types.string),
  subtext: types.maybeNull(types.string),
  dataFieldsUsed: types.maybeNull(types.array(types.string)),
});

export const daPublicModel = types.model({
  organicTraffic: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  refdomainCount: types.maybeNull(types.number),
  backlinksCount: types.maybeNull(types.number),
  domainRating: types.maybeNull(types.number),
  competitors: types.maybeNull(competitorsModal),
  totalSeoClicks: types.maybeNull(types.number),
  totalAdwordsClicks: types.maybeNull(types.number),
  topOrganicKeywords: types.maybeNull(types.array(topKeywordsModal)),
  htmlLabels: types.maybeNull(types.array(htmlLabelsModal)),
  // topPaidKeywords: types.maybeNull(types.array(topKeywordsModal)),
  // ppcKeywords: types.maybeNull(types.number),
  ppcBudget: types.maybeNull(types.number),
  // organicDomainOverviewKeywordStatus: types.maybeNull(organicDomainOverviewKeywordStatusModal),
});
const widgetUsesTableListModel = types.model({
  id: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  analyzedDomain: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
});

export const CompetitorExplorerStore = types.model({
  loadingList: types.maybeNull(types.boolean),
  overview: OverviewStore,
  listApiCallCheck: types.boolean,
  params: types.maybeNull(paramsModel),
  total: types.maybeNull(types.number),
  maxLookupReached: types.maybeNull(types.boolean),
  competitorResearcherList: types.maybeNull(types.array(ResearcherListModel)),
  focusedKeyword: FocusedKeywordStore,
  banner: types.model({
    show: types.boolean,
    title: types.string,
    subtitle: types.string,
    variant: types.enumeration<BannerVariant>('BannerVariant', Object.values(BannerVariant)),
  }),
  daPublic: types.maybeNull((daPublicModel)),
  daNonNullList: types.maybeNull(types.string),
  daPublicLoading: types.maybeNull(types.boolean),
  isError: false,
  widgetUsesTableList: types.maybeNull(types.array(widgetUsesTableListModel)),
  loadingGetTableDataList: types.boolean,
  widgetUsesTableListCount: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  widgetListpageSize: types.number,
  widgetListpage: types.number,
}).views(self => ({
  get getDaPublicData() {
    return toJS(self.daPublic);
  },
  get getWidgetUsesTableList() {
    return self.widgetUsesTableList;
  },
})).actions(self => {
  const setBanner = (show: boolean, title: string, subtitle: string, variant: BannerVariant) => {
    self.banner = {
      show,
      title,
      subtitle,
      variant,
    };
  };
  const sethostname = value => self.hostname = value;

  const postNewCompetitor = flow(function* (payload) {
    try {
      const response = yield OVERVIEW_DETAIL_API.postCompetitorResearcher(payload);
      if (response) {
        return response;
      }
    } catch (e) {
      const rootStore = getRoot(self) as any;

      if (e?.response?.status === 429) {
        notification.warning('Competitor Researcher Quota Exceeded', e?.response?.data?.message, 'Update Plan', rootStore?.plans?.showSidebarPaymentDrawer);
        return false;
      }

      if (e?.response?.status == 500) {
        notification?.error('Error', 'Server Error 500.');
        return false;
      }

      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });

  const postNewCompetitorPublic = flow(function* (payload) {
    try {
      const response = yield OVERVIEW_DETAIL_API.postCompetitorResearcher(payload);
      if (response) {
        self.maxLookupReached = false;
        return response;
      }
    } catch (e) {
      if (e.response.status === 406) {
        self.maxLookupReached = true;
        return e;
      }
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });

  const objectsEqual = (o1, o2) =>
    typeof o1 === 'object' && o1 !== null && Object?.keys(o1)?.length > 0 ?
      Object?.keys(o1)?.length === Object?.keys(o2)?.length &&
      Object?.keys(o1)?.every(p => objectsEqual(o1[p], o2[p])) :
      o1 === o2;

  const arraysEqual = (a1, a2) =>
    a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]));

  const getCompetitorResearcherList = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingList = true;
    }
    try {
      const response = yield OVERVIEW_DETAIL_API.getCompetitorResearchlist(self.params);
      const check = arraysEqual(response?.results, toJS(self.competitorResearcherList));
      if (!check) {
        updateList(response?.results);
      }
      self.total = response?.count;
      const metricsStatus = response?.results?.filter(item => !['FAILURE', 'SUCCESS'].includes(item?.metrics?.initialProcessingStatus));
      if (metricsStatus?.length && self.listApiCallCheck) {
        yield new Promise(r => setTimeout(r, COMPETITOR_RESEARCHER_API_POLL_INTERVAL));
        return getCompetitorResearcherList();
      } else {
        updateList(response?.results);
        self.loadingList = false;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });

  const postNewDomainAnalyzerPublic = flow(function* (url) {
    localStorage.setItem('showPostOnboarding', 'true');
    self.daPublicLoading = true;
    self.isError = false;
    try {
      const response = yield OVERVIEW_DETAIL_API.postDomainAnalyzer(url);
      if (response?.response?.status == 406) {
        self.isError = true;
        self.daPublicLoading = false;
        return;
      }
      if (response?.htmlLabels) {
        self.daPublic = response;
        return response;
      } else {
        self.isError = true;
      }
    } catch (e) {
      self.daPublicLoading = false;
      self.isError = true;
      return Promise.reject(e);
    } finally {
      self.daPublicLoading = false;
    }
  });
  const loadWidgetUsesTableData = flow(function* (hostname: string) {
    self.loadingGetTableDataList = true;
    try {
      const response = yield OVERVIEW_DETAIL_API.getWidgetUsesTableData({hostname, page_size: self.widgetListpageSize, page: self.widgetListpage});
      self.widgetUsesTableList = response?.results;
      self.widgetUsesTableListCount = response?.count;
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.loadingGetTableDataList = false;
    }
  });

  const removeCompetitor = flow(function* (id) {
    try {
      yield OVERVIEW_DETAIL_API.deleteCompetitorResearcher(id);
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const updateList = data => {
    if (!data?.isCancel) {
      self.competitorResearcherList = cast(data);
    }
  };

  const setListApiCall = value => {
    self.listApiCallCheck = value;
  };

  const handlePagination = (page: number, pageSize: number) => {
    if (pageSize !== self.widgetListpageSize) {
      self.widgetListpageSize = pageSize;
      self.widgetListpage = 1;
      loadWidgetUsesTableData(self.hostname);
    } else {
      self.widgetListpage = page;
      loadWidgetUsesTableData(self.hostname);
    }
  };

  const setParams = (value, noApiCall?: boolean) => {
    if (value?.search !== undefined) {
      self.params.search = value?.search;
    }
    self.params.ordering = value?.ordering;

    if (value?.type) {
      self.params.type = value?.type;
    }
    if (value?.page) {
      self.params.page = value?.page;
    }
    if (value?.pageSize) {
      self.params.page_size = value?.pageSize;
    }
    if (!noApiCall) {
      getCompetitorResearcherList();
    }
  };

  return {
    setBanner,
    setParams,
    setListApiCall,
    updateList,
    removeCompetitor,
    postNewCompetitor,
    postNewDomainAnalyzerPublic,
    postNewCompetitorPublic,
    getCompetitorResearcherList,
    loadWidgetUsesTableData,
    sethostname,
    handlePagination,
  };
});

export const initCompetitorExplorerStore = () => {
  return CompetitorExplorerStore.create({
    loadingList: false,
    overview: initOverviewStore(),
    listApiCallCheck: false,
    competitorResearcherList: [],
    maxLookupReached: false,
    daPublicLoading: false,
    isError: false,
    focusedKeyword: initFocusedKeywordStore(),
    banner: {
      show: false,
      title: '',
      subtitle: '',
      variant: BannerVariant.ERROR,
    },
    params: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    loadingGetTableDataList: false,
    widgetListpageSize: 10,
    widgetListpage: 1,
  });
};
