import {Button, Typography} from '@/components/common-components/components';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import stylesLocal from './styles.module.scss';
import {CommonInput} from '@/components/common-components/components/input';
import {Modal} from 'antd';
import {useState} from 'react';

interface Props {
  setCreatingBoard?: (value: boolean) => void;
}

export const CreateBoard = observer(({setCreatingBoard}: Props) => {
  const {reportBuilder: {details: {loadBoardsList, addTaskBoard, createNotionBoard, setAddTaskBoard, creatingNotionBoard, singleProjectData, setModalData, modalData}}} = useStore('');
  const [boardName, setBoardName] = useState('');
  const [error, setError] = useState(false);

  const getBoardsList = async () => {
    const response = await loadBoardsList(singleProjectData?.customerClientId);
    if (response?.length) {
      setModalData({...modalData, boardId: response[0]?.id, boardName: response[0]?.name});
    }
  };

  const onBtnClick = async () => {
    if (boardName.trim() == '') {
      setError(true);
      return;
    }
    try {
      const payload = {
        'customer_client_id': singleProjectData?.customerClientId,
        'board_name': boardName,
      };
      await createNotionBoard(payload);
      await getBoardsList();
      setModalData({...modalData, modalType: '', openModal: false});
      if (typeof setCreatingBoard === 'function') {
        setCreatingBoard(false);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setAddTaskBoard(false);
    }
  };

  const onClose = () => {
    setBoardName('');
    setAddTaskBoard(false);
  };

  return <StyledModal
    visible={addTaskBoard}
    maskStyle={{zIndex: 9}}
    width={560}
    closable={false}
    className={stylesLocal.Modal}
    destroyOnClose
    zIndex={11}
    footer={null}
  >
    <div style={{padding: modalData?.modalType == 'create-board' ? '30px' : '', background: '#F9F9FB', borderRadius: '12px'}}>
      <div style={{paddingBottom: '15px'}}>
        <span style={{fontSize: '20px', fontWeight: 600}}>Add Task Board</span>
      </div>
      <div className={stylesLocal.ContentDiv}>
        <Typography className={stylesLocal.Title} tag='h5'>Name</Typography>
        <CommonInput className={stylesLocal.CommonInput} value={boardName} onChange={e => setBoardName(e.target.value)} iconClasses={stylesLocal.InputIcon}/>
        {error && <p style={{marginTop: '5px', color: 'red', marginBottom: '0', fontSize: '12px'}}>Name is required!</p>}
      </div>
      <ButtonsWrapper>
        <div
          onClick={() => {
            onClose();
            setAddTaskBoard(false);
          }} className={stylesLocal.BtnCancel}>
          <a style={{color: '#4E5156'}}>Cancel</a>
        </div>

        <ButtonStyled loading={creatingNotionBoard} disabled={creatingNotionBoard} style={{fontSize: '14px'}} buttonType='blue-button' onClick={() => onBtnClick()}>Add Task Board</ButtonStyled>
      </ButtonsWrapper>
    </div>
  </StyledModal>;
});

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

const StyledModal = styled(Modal)`
  border-radius: 12px;
  box-shadow: 0px 3px 2px 0px #0000000D;

  .ant-modal-body {
    background: #F9F9FB !important;
  }
`;

const ButtonStyled = styled(Button)`
  width: fit-content !important;
  height: 44px !important;
  display: flex !important;
  align-items: center !important;
  padding: 13px 25px 14px 25px !important;
  border-radius: 8px !important;
  background: #2D6CCA !important;

  &:hover {
    color: #ffff !important;
    border-color: #2D6CCA !important;
  }
`;
