import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {COLORS} from './colors';

interface ButtonProps extends AntdButtonProps {
  color?: 'purple' | 'green';
  label?: string;
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

}

// Figma:
// https://www.figma.com/file/tdvnkn6V30KvcYXqs0lJ6Y/Tools-Dashboard?node-id=7575%3A2564

export const FloatingActionButton = ({color = 'purple', label, icon, onClick, ...props}: ButtonProps) => {
  return (
    <FloatingActionButtonStyled color={color} {...props} icon={icon} onClick={onClick} ><div className='label'>{label}</div></FloatingActionButtonStyled>
  );
};

const FloatingActionButtonStyled = styled(AntdButton)`
  .label {
    margin-left: 10px;
    visibility: hidden;
  }
  background: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
  border-color: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
  border-radius: 50px;
  padding: 18px;
  color: ${p => COLORS[p.color]};
  height: auto;
  max-width: 56px;
  z-index: 1;
  -webkit-transition: max-width 1s;
  transition: max-width 1s;
  overflow:hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
    border-color: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
    color: ${p => COLORS[p.color]};
    max-width:300px;
    width: fit-content;

    .label {
      visibility: visible;
    }
  }

  :active {
    background: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
    border-color: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
    color: ${p => COLORS[p.color]};
  }

  :focus {
    background: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
    border-color: ${p => p.color === 'purple' ? '#E5DCEF' : '#D2EEDA'};
    color: ${p => COLORS[p.color]};
  }
`;

