import Image from 'next/image';
import React from 'react';

export const NextImg: React.FC<any> = ({...props}) => {
  const height = typeof props?.style?.height === 'string' ? props?.style?.height.replace(/px|%/i, '') : props?.style?.height || 0;
  const width = typeof props?.style?.width === 'string' ? props?.style?.width.replace(/px|%/i, '') : props?.style?.width || 0;

  const src = props?.src?.startsWith('img') ? `/${props?.src}` : props?.src;

  return (
    <Image
      unoptimized
      {...props}
      className={props?.className}
      src={src || '/img/icon/se-no-screenshot-placeholder.svg'}
      alt={props?.alt || ''}
      width={typeof props?.width === 'number' ? props?.width : width || 0}
      height={typeof props?.height === 'number' ? props?.height : height || 0}
      style={{...props?.style}}
    />
  );
};
