import React, {useEffect, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {Input, Spin} from 'antd';
import {getSingleUrlParam} from '@/utils/url';
import {LoadingOutlined} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {useStore} from '@/store/root-store';
import {notification as notificationV2} from '@/utils/notification-v2';
import {urlValidationRegexSingle} from '@/constants/regex';

interface AddPageModalProps {
  openAddPageModal: boolean;
  setOpenAddPageModal: (value: boolean) => void;
  ottoProject: number;
  setOttoProject: (value: number) => void;
  currentIssueType?: string;
  onAddSourcePage?: (value:any, addingTargetPage?: boolean) => void | Promise<void>;
  addPageLoader?: any;
  setAddPageloader?: any;
  setCurrentIssueType?: (issue: string) => void;
}
const initialErrorState = {
  'keyword': {
    message: 'Keyword is required.',
    state: false,
  },
  'targetPageUrl': {
    message: 'Please provide valid url.',
    state: false,
  },
  'sourceUrl': {
    message: 'Please provide valid url',
    state: false,
  },
};

export const AddPageModal: React.FC<AddPageModalProps> = ({setAddPageloader, openAddPageModal, setOpenAddPageModal, ottoProject, setOttoProject, currentIssueType='', onAddSourcePage, addPageLoader, setCurrentIssueType}) => {
  const {ottoV2Store: {
    loadIssueTableData,
    getOttoV2Project,
    addPage,
    loadOttoV2Project,
    setIsDeploying,
  }} = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [url, setUrl] = useState('');
  const [keywords, setKeywords] = useState('');
  const [errorStates, setErrorStates] = useState(initialErrorState);
  const [addingPage, setAddingPage] = useState(false);
  const [sourcePageUrl, setSourcePageUrl] = useState('');


  useEffect(() => {
    if (url) {
      setErrorStates({...errorStates, targetPageUrl: {...errorStates['targetPageUrl'], state: false}});
    }
    if (keywords) {
      setErrorStates({...errorStates, keyword: {...errorStates['keyword'], state: false}});
    }
  }, [keywords, url]);

  const resetStates = () => {
    setKeywords('');
    setErrorStates(initialErrorState);
    setUrl('');
    setAddingPage(false);
    setOttoProject(-1);
  };
  const onAddPage = async () => {
    const removeLineBreak = keywords.replace(/(\r\n|\n|\r)/gm, '');
    const keywordsArray = removeLineBreak.split(',');
    // eslint-disable-next-line
    const targetPageRegex = /^(?![0-9-_.]+$)(?:[a-z\/._-]{1,}-?|[0-9\/._-]-?)+(?<![._]\/|[._]-|[._]\/|[._])$/;
    const fullUrl = `/${url}`;
    const validate = !url ? true : url?.startsWith('/') ? false : targetPageRegex.test(fullUrl);
    if (!keywords || !validate) {
      setErrorStates({...errorStates,
        keyword: {...errorStates['keyword'], state: !keywords ? true : false},
        targetPageUrl: {...errorStates['targetPageUrl'], state: !validate ? true : false},
      });
      return;
    } else if (keywordsArray.length > 3) {
      setErrorStates({...errorStates, keyword: {message: 'Maximum number of keywords you can select is 3', state: true}});
      return;
    }
    try {
      setAddingPage(true);
      const payload = {
        path: fullUrl,
        target_keywords: keywords,
      };

      await addPage(uuid, payload);
      setIsDeploying(true);
      if (uuid) {
        await loadOttoV2Project(uuid, true);
      }
      notificationV2.success('Success', 'Record added successfully.', 'Close');
      setAddingPage(false);
      setOpenAddPageModal(false);
      resetStates();
      setCurrentIssueType('');
      const params = {
        uuid,
        issue_type: currentIssueType,
        page_size: 10,
        page: 1,
        is_loading: false,
        otto_project: ottoProject,
      };
      await loadIssueTableData(params, false);
    } catch (err) {
      setAddingPage(false);
    }
  };

  const addInternalLinking = async () => {
    const fullUrl = `https://${domainName}/${url}`;
    const sourceUrl = `https://${domainName}/${sourcePageUrl}`;
    const validateUrl = urlValidationRegexSingle.test(fullUrl);
    const validateSourcePage = urlValidationRegexSingle.test(sourceUrl);
    if (!validateUrl || !validateSourcePage) {
      setErrorStates({...errorStates,
        targetPageUrl: {...errorStates['targetPageUrl'],
          state: !validateUrl ? true : false},
        sourceUrl: {...errorStates['sourceUrl'],
          state: !validateSourcePage ? true : false},
      });
      return;
    }
    try {
      setAddingPage(true);
      const payload = {
        issue_type: 'internal_link_suggestions',
        object_key: fullUrl,
        object_value: sourceUrl,
      };
      await onAddSourcePage(payload, true);
    } catch (err) {
      setAddingPage(false);
    }
  };

  useEffect(()=> {
    if (addPageLoader) {
      setAddingPage(false);
      resetStates();
      setAddPageloader(false);
    }
  }, [addPageLoader]);


  return (
    <StyledTopicalMapsModal
      width={'560px'}
      closable={true}
      onClose={() => {
        setOpenAddPageModal(false);
        resetStates();
      }}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
      visible={openAddPageModal}
    >
      <StyledModalHeader>
        <div className='modal-heading'>Add {currentIssueType == 'internal_link_suggestions' ? 'Target Page' : 'Page'}</div>
      </StyledModalHeader>
      <FieldWrapper style={{display: 'block', marginTop: 0}}>
        <div className='label' style={{marginTop: 12, marginBottom: 10, width: '100%'}}>Target Page URL (enter only the slug)</div>
        <div className='field-container'>
          <div className='body' style={{padding: 0}}>
            <div className='domain-input-wrapper'>
              <div className='domain'>{domainName}/</div><Input className='slug' value={url} onChange={e => {
                setUrl(e?.target?.value);
                setErrorStates({...errorStates,
                  targetPageUrl: {...errorStates['targetPageUrl'], state: false},
                });
              }} bordered={false}></Input>
            </div>
          </div>
          {errorStates['targetPageUrl'].state ? <div className='error-message'>{errorStates['targetPageUrl'].message}</div> : <></>}
        </div>
        {currentIssueType === 'internal_link_suggestions' && (<>
          <div className='label' style={{marginTop: 12, marginBottom: 10, width: '100%'}}>Source Page URL (enter only the slug)</div>
          <div className='field-container'>
            <div className='body' style={{padding: 0}}>
              <div className='domain-input-wrapper'>
                <div className='domain'>{domainName}/</div><Input className='slug' value={sourcePageUrl} onChange={e => {
                  setSourcePageUrl(e?.target?.value);
                }} bordered={false}></Input>
              </div>
            </div>
            {errorStates['sourceUrl'].state ? <div className='error-message'>{errorStates['sourceUrl'].message}</div> : <></>}
          </div>
        </>)}
      </FieldWrapper>
      {currentIssueType !== 'internal_link_suggestions' && <FieldWrapper style={{display: 'block', marginTop: 0}}>
        <div className='label' style={{marginTop: 22, marginBottom: 10, width: '100%'}}>Target Keywords (separated by comma), max is 3</div>
        <div className='field-container'>
          <div className='body' style={{borderColor: errorStates['keyword']?.state ? '#f96262': '', padding: 0}}>
            <TextArea
              className='keywords-input'
              value={keywords}
              onChange={e => setKeywords(e?.target?.value)}
              bordered={false}
              placeholder={currentIssueType !== 'internal_link_suggestions' && 'Target Keywords (separated by comma), max is 3'}
            ></TextArea>
          </div>
          {errorStates['keyword'].state ? <div className='error-message'>{errorStates['keyword'].message}</div> : <></>}
        </div>
      </FieldWrapper>}
      <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          setOpenAddPageModal(false);
          resetStates();
        }}>
          Cancel
        </div>
        <ButtonComponent
          color='blue'
          style='solid'
          type='button'
          className='update-button'
          onClick={()=> {
            if (currentIssueType == 'internal_link_suggestions') {
              addInternalLinking();
            } else {
              onAddPage();
            }
          }}
          disabled={addingPage}
        >
          Add Page
          {
            addingPage ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>
          }
        </ButtonComponent>
      </StyledModalFooter>
    </StyledTopicalMapsModal>
  );
};

const StyledTopicalMapsModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 8px;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const StyledModalFooter = styled.div<{backFooter?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .cancel-btn {
    width: ${p => p?.backFooter ? '' : '47px'};
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  margin: 10px 0;
  .label {
    width: 70px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #121212;
    margin-top: 8px;
  }
  .field-container {
    flex: 1;
    .header {
      display: flex;
      justify-content: space-between;
      .right-section {
        align-items: center;
        display: flex;
        gap: 10px;
        .button {
          align-items: center;
          display: flex;
          gap: 10px;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          color:#2D6CCA;
          cursor: pointer;
        }
      }
    }
    .body {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
      margin: 5px 0;
      background-color: #ffffff;

      .date {
        width: 105px;
      }
      .time {
        width: 85px;
      }
      .ant-picker-small {
        padding: 0px 0px 0px !important;
      }
      .replace-image {
        padding: 6px;
        background: #4E5156;
        border-radius: 6px;
        position: absolute;
        right: 33px;
        top: 131px;
      }

      .domain-input-wrapper {
        display: flex;
        align-items: center;
        .domain {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #A3A4A4;
          padding-left: 10px;
        }
        .slug {
          padding: 5px 0px;
        }
      }
      .keywords-input {
        resize: none;
        height: 78px;
      }
    }
    .error-message {
      color: red;
      font-size: 12px;
      margin-left: 2px;
    }

  }
`;
