import {Menu} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {faClockRotateLeft} from '@fortawesome/pro-regular-svg-icons';
import {gbpList} from '../Constants';
import {StatusMenu} from '../style';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import FreezeWrapper from '../Component/freezTooltip';
import {getSingleUrlParam} from '@/utils/url';

export const StyledMenu = observer(({selectedIssueType, deployOttoSection, currentProject, disabled = false}) => {
  const {ottoV2Store: {getOttoV2Project}} = useStore('');
  const isPublicPage = !!getSingleUrlParam('public_hash');
  return (<StatusMenu>
    <Menu.Item disabled={gbpList.includes(selectedIssueType) || disabled || getOttoV2Project?.isFrozen || isPublicPage} onClick={()=> deployOttoSection(true)}>
      <FreezeWrapper>
        <FontAwesomeIcon icon={faCheckCircle} fontSize={16} color={disabled ? 'lightgrey' : '#4E5156'}/>&nbsp;
        <span>{currentProject?.pixelTagState == 'installed' && currentProject.isEngaged ? 'Deploy' : 'Enable'} all in this section</span>
      </FreezeWrapper>
    </Menu.Item>
    <Menu.Item disabled={gbpList.includes(selectedIssueType) || disabled || getOttoV2Project?.isFrozen || isPublicPage} onClick={()=>deployOttoSection(false)}>
      <FreezeWrapper>
        <FontAwesomeIcon icon={faClockRotateLeft} fontSize={16} color={disabled ? 'lightgrey' : '#4E5156'}/>&nbsp;
        <span>Roll back all deploys in this section</span>
      </FreezeWrapper>
    </Menu.Item>
  </StatusMenu>);
});
