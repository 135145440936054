import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {DefaultForms} from './DefaultForms';
import {DynamicAiForm} from './dynamicForm';

interface Props {
  showPageTitle?: boolean;
  setShowPageTitle?: (value: boolean) => void;
}

/**
 * Controller that switches between default forms and template forms
 *
 * @param {boolean} showPageTitle used for default forms
 * @param setShowPageTitle method to set show page title boolean from defualt forms
 */
export const AiForm = observer(({showPageTitle, setShowPageTitle}: Props) => {
  const {contentOptimizer: {
    aiOutline: {
      aiTemplatesController,
    },
  }} = useStore('');

  return aiTemplatesController?.defaultForm ?
    <DefaultForms showPageTitle={showPageTitle} setShowPageTitle={setShowPageTitle}/> :
    <DynamicAiForm />
  ;
});
