import React, {useEffect, useState} from 'react';
import {Col, Form, Row, Select, TimePicker} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {StylesError} from './BusinessInfoModal';
import styles from './style.module.scss';
import moment, {Moment} from 'moment';

const options = [{
  value: 'open',
  label: 'Open',
},
{
  value: 'closed',
  label: 'Closed',
},
{
  value: 'split',
  label: 'Split',
},
{
  value: '24hrs',
  label: '24 Hours',
},
];

interface ItemType {
  label?: string;
  name?: string;
  type?: string;
  from?: string;
  to?: string;
}

interface PropTypes {
  title?: string;
  daysTime?: any;
  setDaysTime?: any;
  formData?: any;
  item: ItemType;
}

const SelectDate = ({
  item, formData,
}: PropTypes) => {
  const [display, setDisplay] = useState(['open', 'split'].includes(item?.type));
  const [DisplaySecondRow, setDisplaySecondRow] = useState(false);
  const [time, setTime] = useState<[Moment, Moment] | null>(null);

  useEffect(() => {
    const from = item?.from ? moment(item.from, 'hh:mm A') : null;
    const to = item?.to ? moment(item.to, 'hh:mm A') : null;
    if (from && to) {
      setTime([from, to]);
    } else {
      setTime(null);
    }
  }, [item]);

  useEffect(() => {
    if (formData[item.name]) {
      if (formData[item.name] === 'open') {
        setDisplay(true);
        setDisplaySecondRow(false);
      } else if (formData[item.name] === 'split') {
        setDisplay(true);
        setDisplaySecondRow(true);
      } else {
        setDisplay(false);
        setDisplaySecondRow(false);
      }
    } else {
      if (item.type === 'open') {
        setDisplay(true);
        setDisplaySecondRow(false);
      } else if (item.type === 'split') {
        setDisplay(true);
        setDisplaySecondRow(true);
      } else {
        setDisplay(false);
        setDisplaySecondRow(false);
      }
    }
  }, []);

  const handleChange = value => {
    if (value === 'open') {
      setDisplay(true);
      setDisplaySecondRow(false);
    } else if (value === 'split') {
      setDisplay(true);
      setDisplaySecondRow(true);
    } else {
      setDisplay(false);
      setDisplaySecondRow(false);
    }
  };

  return (
    <>
      <Col md={24} style={{marginBottom: '6px'}}>
        <Row gutter={[5, 0]} style={{alignItems: 'start'}}>
          <Col span={3}>
            <div className={styles.label} style={{width: '100%', marginTop: '7px'}}>
              {item.label}
            </div>
          </Col>
          <Col span={display ? 7 : 21} style={{width: '80px'}}>
            <Form.Item name={item.name}>
              <Select
                defaultValue={item.type || 'closed'}
                dropdownClassName={styles.customSelect}
                className={styles.selectItem}
                options={options}
                suffixIcon={
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    color='#000'
                    fill='#000'
                  />
                }
                onSelect={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            {display && (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <div style={{width: '100%'}}>
                  <Form.Item
                    name={`${item.name}_start`}
                    rules={[{
                      message: <StylesError>From and to values are required</StylesError>,
                      validator: (_, value) => {
                        if (item.type === 'open' || item.type === 'split') {
                          if (value && value.length === 2) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('From and to values are required'));
                        }
                        return Promise.resolve();
                      },
                    }]}
                  >
                    <TimePicker.RangePicker
                      use12Hours={false}
                      format={'hh:mm A'}
                      className={styles.timeField}
                      placeholder={['From', 'To']}
                      defaultValue={time || undefined}
                      value={time}
                    />
                  </Form.Item>
                </div>
                {DisplaySecondRow && (
                  <div style={{width: '100%', marginTop: '6px'}}>
                    <Form.Item
                      name={`${item.name}_end`}
                      rules={[{
                        message: <StylesError>From and to values are required</StylesError>,
                        validator: (_, value) => {
                          if (item.type === 'open' || item.type === 'split') {
                            if (value && value.length === 2) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('From and to values are required'));
                          }
                          return Promise.resolve();
                        },
                      }]}
                    >
                      <TimePicker.RangePicker
                        use12Hours={true}
                        format={'HH:mm A'}
                        className={styles.timeField}
                        placeholder={['From', 'To']}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SelectDate;
