import {Switch} from 'antd';
import {observer} from 'mobx-react';
import styles from './styles.module.scss';

interface StatusSwitchProps {
  id: number;
  activeForCurrentCustomer: boolean;
  locationStatusChange: (id: number, checked: boolean) => void;
}


const StatusSwitch = observer(({id, activeForCurrentCustomer, locationStatusChange}: StatusSwitchProps) => {
  const handleLocationStatusChange = (checked: boolean) => {
    locationStatusChange(id, checked);
  };

  return (
    <div className={`${styles.firstItem} ${activeForCurrentCustomer ? styles.greenBorder : styles.greyBorder}`}>
      <Switch checked={activeForCurrentCustomer} className={styles.switch} onChange={checked => handleLocationStatusChange(checked)} />
      <span style={{marginLeft: '10px'}}>{activeForCurrentCustomer ? 'Active' : 'Disabled'}</span>
    </div>
  );
});

export default StatusSwitch;
