import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {CircleGmbChart, GMBChart} from '../charts/gmbChart';
import {customNumberWithCommas, formatLargeNumber} from '@/utils/number';
import {LoadingOutlined} from '@ant-design/icons';
import {Empty, Spin} from 'antd';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {circleChartData} from '../charts/chartsDummyData';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import styles from '../styles.module.scss';
import _ from 'lodash';
import {NextImg} from '@/utils/nextImg';

export const LocalSeoCardImpr = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`}>
    {loadingGMBReportData ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{background: 'white'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><NextImg style={{width: 16, height: 16}} src='/img/GMB.svg' alt='' />Impressions</p>
        <Values>{customNumberWithCommas(Number(getGMBReportDataValue(props.index)?.topMetrics?.impressions)) ?? '-'}</Values>
      </div>}
  </div>;
});

export const WebClick = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`}>
    {loadingGMBReportData ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{background: 'white'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><NextImg style={{width: 16, height: 16}} src='/img/GMB.svg' alt='' />Website Clicks</p>
        <Values>{customNumberWithCommas(Number(getGMBReportDataValue(props.index)?.topMetrics?.websiteClicks)) || '-'}</Values>
      </div>}
  </div>;
});

export const CallClick = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`}>
    {loadingGMBReportData ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{background: 'white'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><NextImg style={{width: 16, height: 16}} src='/img/GMB.svg' alt='' />Call Clicks</p>
        <Values>{customNumberWithCommas(Number(getGMBReportDataValue(props.index)?.topMetrics?.callClicks)) || '-'}</Values>
      </div>}
  </div>;
});

export const LocalSeoCardDR = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');


  return <div className={`${styles.mainInner} mainInner`}>
    {loadingGMBReportData ? <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></> :
      <div style={{background: 'white'}} className='gmbStyle'>
        <p style={{fontSize: '16px', fontWeight: '600'}}><img style={{width: '16px'}} src='/img/GMB.svg' alt='' />Direction Request</p>
        <Values>{customNumberWithCommas(Number(getGMBReportDataValue(props.index)?.topMetrics?.directionRequests)) || '-'}</Values>
      </div>}
  </div>;
});

export const HistoricalTrend = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');


  return <ChartsContainer className='HTGmb' >
    <HistoricalTrendTitle><NextImg style={{width: 16, height: 16}} src='/img/GMB.svg' alt='' />&nbsp;&nbsp;Historical Trend</HistoricalTrendTitle>
    {loadingGMBReportData ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px'}}><Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin />} /></div> : getGMBReportDataValue(props.index)?.metricsByDay?.length ?
      <>
        <GMBChart data={getGMBReportDataValue(props.index)?.metricsByDay} conf={{hideXAxis: true, FKey: {name: 'impressions', optName: 'Impressions', color: '#144198'}, SKey: {name: 'websiteClicks', optName: 'Website Clicks', color: '#34AEF3'}}}/>
        <GMBChart data={getGMBReportDataValue(props.index)?.metricsByDay} conf={{hideXAxis: false, FKey: {name: 'callClicks', optName: 'Call clicks', color: '#1FAC47'}, SKey: {name: 'businessDirectionRequests', optName: 'Direction Requests', color: '#AF8600'}}}/>
      </> :
      <div style={{marginTop: '70px'}}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    }
  </ChartsContainer>;
});

export const DeviceBreakdown = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');
  const [chartData, setChartData] = useState([]);


  useEffect(() => {
    if (getGMBReportDataValue(props.index)?.platformDeviceBreakdown?.breakdown && !chartData?.length) {
      const data = getGMBReportDataValue(props.index)?.platformDeviceBreakdown?.breakdown.map(item => {
        return {
          ...item,
          ...circleChartData[item.device],
        };
      });
      setChartData(data);
    }
  }, [getGMBReportDataValue(props.index)?.platformDeviceBreakdown?.breakdown]);

  return <PlatformBreakdownCard className='devBreakdown' width={!!getGMBReportDataValue(props.index)?.searchkeywordsBreakdown}>
    <Title><NextImg style={{width: 16, height: 16}} src='/img/GMB.svg' alt='' />&nbsp;&nbsp;Platform and devices</Title>
    <Desc>Platform and devices that people used to find your business.</Desc>
    <div className='chartSection'>
      {chartData?.length > 0 && <TotalViewsContainer>
        <TotalViewTitle>Total Views</TotalViewTitle>
        <TotalViewsValue>{customNumberWithCommas(getGMBReportDataValue(props.index)?.platformDeviceBreakdown?.total) || '-'}</TotalViewsValue>
      </TotalViewsContainer>}
      <CircleGmbChart CardData={getGMBReportDataValue(props.index)?.platformDeviceBreakdown?.total ? chartData : []} total={getGMBReportDataValue(props.index)?.platformDeviceBreakdown?.total} loading={loadingGMBReportData}/>
    </div>
  </PlatformBreakdownCard>;
});

export const SearchBreakdown = observer((props:any) => {
  const {reportBuilder: {details: {loadingGMBReportData, getGMBReportDataValue}}} = useStore('');
  const [params, setParams] = useState(10);


  const columnsConfig = [
    {
      title: 'TERM',
      dataIndex: 'TERM',
      key: 'TERM',
      align: 'left' as 'left',
      width: 100,
      render: (data, record) => {
        return <SearchKeyword>{_.capitalize(record?.searchKeyword) || '-'}</SearchKeyword>;
      },
    },
    {
      title: 'IMPRESSIONS',
      dataIndex: 'IMPRESSIONS',
      key: 'IMPRESSIONS',
      align: 'right' as 'right',
      width: 100,
      sorter: (a, b) => parseInt(a?.insightsValue?.value?.length ? a?.insightsValue?.value : a?.insightsValue?.threshold) - parseInt(b?.insightsValue?.value?.length ? b?.insightsValue?.value : b?.insightsValue?.threshold),
      render: (_, record) => {
        return <span style={{marginRight: '20px'}}>{formatLargeNumber(parseInt(record?.insightsValue?.value?.length ? record?.insightsValue?.value : record?.insightsValue?.threshold) || 0, 1)
          ?.toString()
          ?.replace('.0', '') || '-'}</span>;
      },
    },
  ];

  return <SearchesBreakdownCard className='searchBreakdown' width={!!getGMBReportDataValue(props.index)?.platformDeviceBreakdown}>
    <SearchesBreakdownTitle><img style={{width: 16, height: 16}} src='/img/GMB.svg' alt='' />&nbsp;&nbsp;Searches breakdown</SearchesBreakdownTitle>
    <SearchesBreakdownDesc>Search terms that showed your Business Profile in the search results.</SearchesBreakdownDesc>
    <TermsTableStyled
      columns={columnsConfig}
      loadingRows={10}
      isPadding={false}
      dataSource={getGMBReportDataValue(props.index)?.searchkeywordsBreakdown?.searchKeywordsCounts?.slice(0, params) || []}
      pagination={false}
      loading={loadingGMBReportData}
    />
    <ShowMoreContainer>
      {getGMBReportDataValue(props.index)?.searchkeywordsBreakdown?.searchKeywordsCounts?.length > 10 && params <= getGMBReportDataValue(props.index)?.searchkeywordsBreakdown?.searchKeywordsCounts?.length ? <ShowMore onClick={() => {
        setParams(params + 10);
      }}>Show more</ShowMore> : ''}
    </ShowMoreContainer>
  </SearchesBreakdownCard>;
});


const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;

const ShowMore = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(45, 108, 202, 1);
  cursor: pointer;
`;

const TermsTableStyled = styled(TableStyled)`
  margin-top: 10px;
  height: auto !important;
  overflow: hidden !important;
  .ant-table-content {
    max-height: 32.5em !important;
  }
  .ant-table-tbody {
    background: transparent !important;
  }
`;

const ChartsContainer = styled.div`
  min-height: 400px;
  max-height: 460px;
  // margin-top: 18px;
  border: 1px solid #E8E8E8;
  border-radius: 16px;
  padding: 12px;
  box-shadow: 0px 3px 2px 0px #00000005;
  background-color: white;
`;

const PlatformBreakdownCard = styled.div<{width: boolean}>`
width: 775px;
margin-right: 10px;

  height: 421px;
  padding: 18px;
  border: 1px solid #E8E8E8;
  border-radius: 12px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  background-color: rgba(255, 255, 255, 1);
  margin-right: ${p => p.width ? '28px' : ''};
  .chartSection{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: ${p => p.width ? '' : 'center'};
  }
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 18, 18, 1);
`;

const Desc = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(78, 81, 86, 1);
  margin-left: 25px;
`;

const TotalViewsContainer = styled.div`
  margin-top: 24px;
`;

const TotalViewTitle = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(78, 81, 86, 1);
`;

const TotalViewsValue = styled.div`
  margin-top: 4px;
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
`;

const SearchesBreakdownCard = styled.div<{width: boolean}>`
  width: ${p => p.width ? '' : '100%'};
  height: 576px;
  border: 1px solid rgba(232, 232, 232, 1);
  border-radius: 12px;
  padding: 18px;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 16px;
`;

const SearchesBreakdownTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(18, 18, 18, 1);
`;

const SearchesBreakdownDesc = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(78, 81, 86, 1);
  margin-left: 25px;
`;

const HistoricalTrendTitle = styled.div`
display: flex;
align-items: center;
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: rgba(18, 18, 18, 1);
margin-left: 16px;
margin-top: 6px;
`;

const SearchKeyword= styled.div`
  white-space: break-spaces;
  width: 420px;
`;

const Values = styled.div`
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: justify;
`;
