import styled from 'styled-components';
import {ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  label: string;
  icon?: ReactNode;
}

export const WarningMiniBar = ({label, icon}: Props) => {
  return <WarningContainer>
    {!icon ?
      <>
        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#F44343'}} />
        <WarningLabel>{label}</WarningLabel>
      </> :
      icon}

  </WarningContainer>;
};


const WarningContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #4B3236;
  border-radius: 3px;
  padding: 4px 10px;
  cursor: default;
`;

const WarningLabel = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: #E8E8E8;
`;
