import React from 'react';
import {Form, Radio} from 'antd';
import styles from './style.module.scss';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import classNames from 'classnames';
import {useStore} from '@/store/root-store';
import {useTranslation} from 'next-i18next';
import {NextImg} from '@/utils/nextImg';

interface Props {
  newCard: boolean;
  createOptions: any;
  setNewCard: any;
  previousCard: any;
  setPreviousCard: any;
  setStripe: any;
  setElements: any;
}

const CheckoutOptions = ({newCard, createOptions, setNewCard, previousCard, setPreviousCard, setElements, setStripe}: Props) => {
  const {t} = useTranslation('common');
  const {invoices: {getPaymentOptions},
  } = useStore('');
  const onChange = e => {
    setPreviousCard(e.target.value);
    e.target.value == -1 ? setNewCard(true) : setNewCard(false);
  };

  setStripe(useStripe());
  setElements(useElements());

  return (
    <div>
      <Radio.Group onChange={onChange} value={previousCard} style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        { getPaymentOptions.length > 0 && (
          getPaymentOptions.map((item, index) =>{
            return <div style={{marginLeft: 0, marginRight: 0}} key={index} className={classNames(styles.paymentCard, previousCard== item.id ? styles.paymentCardActive : '' )}>
              <Radio value={item.id}>
                <div className={styles.paymentDetail}>
                  <div className={styles.paymentInfo}>
                    <span>•••• •••• •••• {item.last4}</span>
                    <p>
                      <span>{ item.name && item.name.length > 10 ? item.name.substring(0, 10) + '...' : item.name }</span>
                      <span>{item.expMonth}/{item.expYear}</span>
                    </p>
                  </div>
                  <NextImg src={previousCard== item.id ? '/img/icon/master-card.svg' : '/img/icon/master-card-inactive.svg'}/>
                </div>
              </Radio>
            </div>;
          })
        )}
        { getPaymentOptions.length > 0 && (
          <div style={{marginLeft: 0, marginRight: 0}} className={classNames(styles.paymentCard, styles.newPaymentCard, previousCard== -1 ? styles.paymentCardActive : '' )}>
            <Radio value={-1}>
              <div className={styles.paymentDetail}>
                <h2>{t('pay-another-card')}</h2>
              </div>
            </Radio>
          </div>

        )}

      </Radio.Group>
      {newCard && <div className={styles.cardStyle}>
        <div className={styles.cardNumber}>
          <Form.Item name='cardNumber'>
            <CardNumberElement
              options={{style: createOptions(), showIcon: true}}
              className={styles.cardNumberStyle}
            />
          </Form.Item>
        </div>
        <div className={styles.cardExpiry}>
          <CardExpiryElement
            options={{style: createOptions()}}
            className={styles.cardInputStyle}
          />
        </div>
        <div className={styles.cardCvc}>
          <CardCvcElement
            options={{style: createOptions()}}
            className={styles.cardInputStyle}
          />
        </div>
      </div>}
    </div>
  );
};

export default CheckoutOptions;
