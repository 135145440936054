import {
  Checkbox as AntdCheckbox,
  CheckboxProps,
} from 'antd';
import styled from 'styled-components';
import {COLORS} from './colors';

interface Props extends CheckboxProps {
  customBackground?: string;
}

export const Checkbox = ({customBackground, ...props}: Props) => {
  return (
    <CheckboxStyled {...props} customBackground={customBackground}></CheckboxStyled>
  );
};

const CheckboxStyled = styled(AntdCheckbox)<{customBackground?: string}>`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${p => p.customBackground ? p.customBackground : COLORS.purple} !important;
    border-color: ${p => p.customBackground ? p.customBackground : COLORS.purple} !important;
  }
`;

