export const getKey = item => {
  if (item?.name == 'exclude' || item?.name == 'wordsInclude' || item?.name == 'wordsExclude') {
    return `${item?.filterField}`;
  } else if (item?.name == 'checkboxes' || item?.name == 'radioButtonsFilter') {
    return `${item?.customCategoryType}`;
  } else if (item?.name) {
    return `${item?.name}`;
  }
};

export const getValue = item => {
  if (item?.name == 'exclude' && item?.query) {
    return `${item?.type},${item?.query}`;
  } else if (item?.name == 'wordsInclude' && item?.query) {
    return `${item?.type},${item?.query}`;
  } else if (item?.name == 'wordsExclude' && item?.query) {
    return `${item?.type},${item?.query}`;
  } else if (item?.name == 'checkboxes' && item?.category?.length) {
    let str = 'equals,';
    item?.category?.forEach((c, i) => {
      str = str+`${c}${i !== item?.category?.length-1 ? '-' : ''}`;
    });
    return str;
  } else if (item?.name == 'radioButtonsFilter' && item?.type) {
    return `equals,${item?.type}`;
  } else if (item?.equal) {
    return `equals,${item?.equal}`;
  } else if (+item?.from || item?.to) {
    return `range,${+item?.from ? item?.from : '0'}-${item?.to ? item?.to : 'max'}`;
  } else {
    return null;
  }
};

export const getFilterValues = (item, value: any) => {
  const firstVal = value?.split(',')[0];
  let secondVal = value?.split(',')[1];
  if (value?.split(',').length > 2) {
    secondVal = value?.split(',')?.slice(1).join(',');
  }
  if (item?.name == 'exclude') {
    return {
      ...item,
      active: true,
      operator: firstVal,
      type: firstVal,
      query: secondVal,
    };
  } else if (item?.name == 'wordsInclude') {
    return {
      ...item,
      active: true,
      operator: firstVal,
      type: firstVal,
      query: secondVal,
    };
  } else if (item?.name == 'wordsExclude') {
    return {
      ...item,
      active: true,
      operator: firstVal,
      type: firstVal,
      query: secondVal,
    };
  } else if (item?.name == 'checkboxes') {
    const categories = secondVal?.split('-');
    return {
      ...item,
      active: true,
      category: categories || [],
    };
  } else if (item?.name == 'radioButtonsFilter') {
    return {
      ...item,
      active: true,
      type: secondVal,
    };
  } else if (item?.name) {
    const from = secondVal?.split('-')[0];
    const to = secondVal?.split('-')[1];
    return {
      ...item,
      active: true,
      from: `${firstVal == 'range' ? from : '0'}`,
      to: `${firstVal == 'range' ? (to == 'max' ? '' : to) : ''}`,
      equal: `${firstVal == 'equals' ? secondVal : ''}`,
    };
  } else {
    return item;
  }
};
