import {useStore} from '@/store/root-store';
import {faLocationDot} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Rate} from 'antd';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {MultiMaps} from './MultiMaps';
import {getSingleUrlParam} from '@/utils/url';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {heatmapHeightLogic} from '../../../utils';
import {useRouter} from 'next/router';

export const LocaSEO = observer((props:any) => {
  const {reportBuilder: {updateReportBuilderProjectLayout, details: {setBackupLayout, isConfig, setLocalSeoLoading, loadingLocalSeoDetailRb, localSeoDetailsData, isSeoUpdate, setIsSeoUpdate, updateSingleProjectData, singleProjectData, getReportsLocalSeoList}}} = useStore('');
  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [multiKeywords, setMultiKeywords] = useState([]);
  const [addKeywordLoader, setAddKeywordLoader] = useState(false);
  const getReportId = getSingleUrlParam('report_id');
  const getPublicHash = getSingleUrlParam('public_hash');
  const [isLoading, setIsLoading] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const router = useRouter();
  const isPublicPage = router?.pathname?.includes('full-report-public');
  const singleProjectKeywordBreakdown = isPublicPage ? multiKeywords?.find(item => Number(item.seoId) == Number(props?.index))?.keywordBreakdown : getReportsLocalSeoList?.find(item => Number(item.id) == Number(props?.index))?.keywordBreakdown;
  const [isThrottled, setIsThrottled] = useState(false);

  useEffect(()=> {
    if (!loadingLocalSeoDetailRb && !getReportsLocalSeoList?.find(item=> item.id == Number(props?.index))) {
      setIsBusiness(true);
    } else {
      setIsBusiness(false);
    }
  }, [props?.index, localSeoDetailsData, loadingLocalSeoDetailRb]);

  useEffect(() => {
    async function load() {
      const getStoreMap = singleProjectData?.layout?.find(map => map.index == props.index && map.name == 'Map');
      const isSeoPublic = getStoreMap?.seoData?.length && isPublicPage;
      let localSeoObj = null;
      if (isSeoPublic) {
        localSeoObj = getStoreMap?.seoData[0];
        localSeoObj['keywordBreakdown'] = isSeoPublic && getStoreMap?.seoData[0]?.keywordList;
      }
      const selectedObj = isSeoPublic ? localSeoObj : getReportsLocalSeoList?.find(item => item.id == props.index);
      selectedObj?.keywordBreakdown && !isPublicPage ? setSelectedProjectData({...selectedObj}) : setSelectedProjectData(null);
      const keywordList = selectedObj?.keywordBreakdown?.filter(item => item.keyword != '');
      if (getStoreMap?.seoData?.length) {
        if (selectedObj?.keywordBreakdown) {
          const addMap = {
            keywordList: keywordList,
            seoId: selectedObj?.id,
            groupId: new Date().getTime(),
          };
          if (getStoreMap?.seoData?.length) {
            const mapsList = [];
            getStoreMap?.seoData?.forEach(item => {
              mapsList.push({...addMap, groupId: item?.groupId, ...item});
            });
            setMultiKeywords([...mapsList]);
          } else {
            setMultiKeywords([{...addMap}]);
          }
        } else {
          setMultiKeywords([]);
        }
      } else {
        setMultiKeywords([{
          keywordList: keywordList,
          seoId: selectedObj?.id,
          groupId: new Date().getTime(),
        }]);
      }
    }
    const seo = singleProjectData?.reportsData?.localSeo;
    if (seo?.localSeoIds.length && (getReportsLocalSeoList || isPublicPage)) {
      load();
    } else {
      setMultiKeywords([]);
      setSelectedProjectData(null);
    }
  }, [loadingLocalSeoDetailRb, getReportsLocalSeoList, JSON.stringify(singleProjectData), isSeoUpdate, singleProjectData?.reportsData?.localSeo?.localSeoIds, props?.index]);

  const addMultiKeyword = async () => {
    const businessDetail = getReportsLocalSeoList.find(item => item.id == props.index);
    const dates = JSON.parse(JSON.stringify(businessDetail?.keywordBreakdown[0].availableDates ?? []));
    const newMap = [
      {
        dataCid: props.index,
        groupId: new Date().getTime(),
        availableDates: businessDetail?.keywordBreakdown[0].availableDates,
        selectedDate: dates.sort().reverse()[0],
        isLatestScan: '',
        keyword: businessDetail?.keywordBreakdown[0].keyword,
        id: new Date().getTime(),
      },
    ];
    setMultiKeywords(prevItems => prevItems?.concat({
      keywordList: selectedProjectData?.keywordBreakdown?.filter(item => item?.keyword != ''),
      id: selectedProjectData?.id,
      groupId: newMap[0].groupId,
      hide: true,
      seoId: selectedProjectData?.id,
      maps: newMap,
    }));

    const updateLayout = singleProjectData?.layout?.map(items => {
      if (items?.name == 'Map' && items?.index == props?.index) {
        const value = items?.seoData?.concat({...items?.seoData[0], newMap: new Date().getTime(), maps: newMap, groupId: newMap[0].groupId});
        return {...items, h: value ? items?.h + heatmapHeightLogic(value) : items?.h, groupId: newMap[0].groupId, seoData: value};
      } else return {...items};
    });
    const layoutData = {...singleProjectData, layout: updateLayout};
    updateSingleProjectData(layoutData);
    const response = await updateReportBuilderProjectLayout(layoutData, Number(getReportId), 'rb');
    setBackupLayout({customLayout: response.customLayout, layout: response.layout, layoutType: response.layoutType});
    setIsSeoUpdate(!isSeoUpdate);
  };

  const handleMultiKeywordDelete =async id => {
    if (!isThrottled) {
      setIsThrottled(true);
      setMultiKeywords(prevItems => prevItems?.filter(item => item.groupId !== id));
      const updateLayout = singleProjectData?.layout?.map(items => {
        if (items?.name == 'Map' && items?.index == props?.index) {
          return {...items, seoData: multiKeywords?.filter(item => item?.groupId !== id)};
        } else return {...items};
      });
      const layoutData = {...singleProjectData, layout: updateLayout};
      const response = await updateReportBuilderProjectLayout(layoutData, Number(getReportId), 'rb');
      setBackupLayout({customLayout: response.customLayout, layout: response.layout, layoutType: response.layoutType});
      updateSingleProjectData(layoutData);
      setTimeout(() => {
        setIsThrottled(false);
        setIsSeoUpdate(!isSeoUpdate);
      }, 1000);
    }
  };

  const localSeoLoading = loadingLocalSeoDetailRb || (getReportsLocalSeoList?.find(item=> item.id == Number(props?.index))?.keywordBreakdown?.length > 0 && !localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.id);
  const isPublic = getPublicHash ? multiKeywords.filter(item=> item.keywordList)?.length == 0 : multiKeywords[0]?.keywordList?.length == 0;
  if ((selectedProjectData == undefined || selectedProjectData?.keywordBreakdown?.length == 0) && isPublic) {
    setLocalSeoLoading(false);
    return <div className='nodata'><p>No keyword found {selectedProjectData?.businessName ? `for ${selectedProjectData?.businessName}` : null}</p></div>;
  } else {
    setLocalSeoLoading(localSeoLoading);
  }

  return localSeoLoading ? <LoaderDivRb>
    <RingLoaderV2 height='15px' width='15px' />
  </LoaderDivRb> : isBusiness && !isPublicPage ? <LoaderDivRb>Business is not available!</LoaderDivRb> : <div style={{pointerEvents: isConfig ? 'none' : 'auto'}} id={props?.index} className='wrapper heatmapWrapper'>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div className='cardWrapperseo' style={{display: 'flex', gap: '16px'}}>
        <FontAwesomeIcon style={{marginTop: '5px'}} fontSize={33} className='icon rb-seo-icon' icon={faLocationDot} />
        <div>
          <div style={{display: 'flex', alignItems: 'center', gap: '8px', paddingLeft: '5px'}}>
            <div style={{fontSize: '12px'}}>{localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.rbSeoData?.snapshotData?.feedback?.reviews || '0'}</div>
            <Rate allowHalf disabled value={localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.rbSeoData?.snapshotData?.feedback?.rating} style={{color: '#F1AA3E', fontSize: '12px'}} />
            <div style={{fontSize: '12px', color: '#F1AA3E'}}>({localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.rbSeoData?.snapshotData?.feedback?.rating})</div>
          </div>
          <h2>{localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.rbSeoData?.businessName || '-'}</h2>
          <p>{localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.rbSeoData?.businessAddress || '-'}</p>
        </div>
      </div>
      <div className='cardWrapperseo'>
      </div>
    </div>
    <div id='local-seo-map'>
      {
        multiKeywords?.map((item, index) => {
          return (
            <MultiMaps
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              seoId={localSeoDetailsData?.find(item=> item.id == Number(props?.index))?.id}
              key={index}
              data={item}
              keywordBreakdown={singleProjectKeywordBreakdown}
              multiKeywords={multiKeywords}
              setAddKeywordLoader={setAddKeywordLoader}
              setMultiKeywords={setMultiKeywords}
              isDelete={multiKeywords.length > 1}
              handleMultiKeywordDelete={handleMultiKeywordDelete}/>
          );
        })
      }
    </div>
    {
      addKeywordLoader ? <LoaderDiv>
        <RingLoaderV2 height='15px' width='15px' />
      </LoaderDiv> : singleProjectKeywordBreakdown?.filter(kwd=> kwd.keyword != '').length > 1 && <p style={{fontSize: '14px', color: '#2D6CCA', cursor: 'pointer', marginTop: '15px'}} onClick={() => !getPublicHash && addMultiKeyword()}>+ Add keyword</p>
    }
  </div>;
});

const LoaderDiv = styled.div`
  width: 15px;
  height: 15px;
  margin-top: 25px;
  margin-left: 10px;
`;

const LoaderDivRb = styled.div`
height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WhiteOverlay = styled.div`
  position: absolute;
  width: calc(100% - 3px);
  height: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: linear-gradient(172deg, rgba(255, 255, 255, 0.90) 15.81%, rgba(255, 255, 255, 0.00) 26.68%);
  top: 2px;
  left: 2px;
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4E5156;
  gap: 5px;
  overflow: auto;
  pointer-events: none;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: #4E5156;
    }
  }
`;
