import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class InboxApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v1/customer-outreach');

  public async emailInbox(params: any) {
    try {
      const response = await this.axios.get(`${InboxApi.baseUrl}/campaigns/email-inbox/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        params: params,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async viewConversationOpportunity(contactId: number, opportunityId: number) {
    try {
      const response = await this.axios.get(`${InboxApi.baseUrl}/contacts/${contactId}/view-conversation/?opportunity_id=${opportunityId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getInboxEmailThread(emailId: number) {
    try {
      const response = await this.axios.get(`${InboxApi.baseUrl}/campaigns/email-thread/?email_id=${emailId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async replyInThread(emailId: number, payload: any) {
    try {
      const response = await this.axios.post(`${InboxApi.baseUrl}/campaigns/reply-in-thread/?email_id=${emailId}`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async sendSingleOutreachEmail(contactId: number, opportunityId: number, payload: any) {
    try {
      const response = await this.axios.post(`${InboxApi.baseUrl}/contacts/${contactId}/send-outreach-email/?opportunity_id=${opportunityId}`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateMilestone(payload: any) {
    try {
      const response = await this.axios.post(`${InboxApi.baseUrl}/campaigns/update-milestone/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getUnreadEmailCount() {
    try {
      const response = await this.axios.get(`${InboxApi.baseUrl}/campaigns/unread-emails/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const INBOX_API = new InboxApi();
