import {Table} from '@/components/common-components/components/table';
import {faCircleArrowRight} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import styled from 'styled-components';

export const TableStyled = styled(Table)<{isNewEmailSetting?: boolean; isMiniTable?: boolean; spaceFromRight?: string; paddingInCells?: boolean; rowTopBorder?: boolean; fixedTheader?: boolean; hideSorters?: boolean; isPadding?: boolean; headingSpaceFromRight?: string; dynamicTableHeight?: boolean; isWLWidgetUses?: boolean}>`
 padding: ${p => p.isPadding ? '1px 10px 0 10px' : ''} !important;
 table {
    .ant-table-tbody {
      position: relative;
    }
  }
  .ant-table-content {
    ${p => p.dynamicTableHeight ? 'max-height: none !important;' : ''}
  }
 
 .ant-table-body {
  table {
      position: relative;
    }
  &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
}

table tr th {
  background-color: #fff;
  }

.ant-table-content {
  border-radius: 0 !important;
  ${p=> p?.isMiniTable ? 'height: 260px  !important; overflow-y: scroll  !important' : p?.isWLWidgetUses ? 'max-height: 480px  !important; overflow-y: scroll  !important;' : ''};
}

.ant-table-column-sorters-with-tooltip:not(:first-child) {
  text-align: center;
}

table {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 5px;

  th::before {
    width: 0 !important;
  }

  .ant-table-column-sorter {
    display: flex;
    padding-left: 3px !important;
  }

  .ant-table-column-sorters {
    display: inline-flex !important;
    padding: 5px !important;
  }

  tr td {
    &:first-child {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
          }
      }

  .ant-table-tbody {
        tr:nth-child(even) {
      td {
        background: #fff !important;
        ${p => p.rowTopBorder ? `border-top: 1px solid #f4f4f4 !important;` : `border-bottom: 1px solid #f4f4f4 !important;`}
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }
      &.removeBottomBorder {
        td {
          border-bottom: 0 !important;
        }
        td:nth-child(1) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
        td:nth-child(2) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
        td:nth-child(3) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
        td:nth-child(4) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
      }

      &:hover {
        td {
          background: #eef4fe !important;
        }

        button {
          ${p => !p.isNewEmailSetting ? `background: #eef4fe !important;
          border: 0 !important;
          color: #2d6cca;
          font-weight: 500;` : ''}
        }
      }
    }

    tr:nth-child(odd) {
      td {
        background: #fff;
        ${p => p.rowTopBorder ? `border-top: 1px solid #f4f4f4 !important;` : `border-bottom: 1px solid #f4f4f4 !important;`}
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }
      &.removeBottomBorder {
        td {
          border-bottom: 0 !important;
        }
        td:nth-child(1) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
        td:nth-child(2) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
        td:nth-child(3) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
        td:nth-child(4) {
          border-top: 0 !important;
          border-bottom: 0 !important;
        }
      }

      &:hover {
        td {
          background: #eef4fe !important;
        }

        button {
          ${p => !p.isNewEmailSetting ? `background: #eef4fe !important;
          border: 0 !important;
          color: #2d6cca;
          font-weight: 500;` : ''}
        }
      }
    }
  }

  .ant-table-thead {
    background: #f7f7f7 !important;
    ${p => p.fixedTheader ? `position: sticky; top: 0px; z-index: 1;`:''}

    th {
      background: #f7f7f7 !important;
      color: #121212 !important;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: ${p => p.headingSpaceFromRight ?? '0'} !important;
    }

    .ant-table-cell-fix-left {
      text-align: left !important;

      &:first-child {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
      }
    }

    th:first-child {
      background: #f7f7f7 !important;
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:nth-child(2) {
      background: #f7f7f7 !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:last-child {
      background-color: #f7f7f7 !important;
      border-bottom-right-radius: 8px !important;
      border-top-right-radius: 8px !important;
      /* padding-left: 25px !important; */
      ${p => p?.spaceFromRight ? `padding-right: ${p?.spaceFromRight} !important;` : ''}
    }
  }

  .ant-table-cell-fix-left-last {
    text-align: left !important;
  }

  tr:first-child td {
    background: #fff !important;
  }

  tr:nth-child(even) td {
    background: #f9f9fb !important;
  }

  tr:nth-child(odd) td {
    background-color: #fff !important;
  }

  tr th {
    height: 50px !important;
    line-height: 22px;
    white-space: nowrap;
    ${p => p?.paddingInCells ? 'padding-left: 6px !important; padding-right: 6px !important;' : ''}
  }

  .ant-column-sorters {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  tr td {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
    ${p => p?.paddingInCells ? 'padding-left: 6px !important; padding-right: 6px !important;' : ''}
  }

  tr th:first-child,
  tr td:first-child {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  tr th:last-child,
  tr td:last-child {
    padding-left: 0 !important;
    padding-right: ${p => p?.spaceFromRight ?? '0'} !important;
  }
}
${p => p.hideSorters ? `
.ant-table-column-sorter-inner {
  display: none;
}
` : ''}
.cursorPointer {
  cursor: pointer;
}
.bluredRowStyle {
  filter: blur(12px);
  pointer-events: none;
 }
 .column-width-sm {
  @media screen and (max-width: 768px) {
    max-width: 360px;
    text-overflow: ellipsis;
    min-width: 160px;
  }
 }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
  text-transform: uppercase;
`;

export const LinkText = ({text='', href, width='', isTarget='', onClick=()=>{}}) => {
  return <>
    <Link legacyBehavior href={href} passHref>
      <LinkStyled width={width} rel='noopener noreferrer' target={isTarget} onClick={onClick}><div>{text}</div>&nbsp;
        <FontAwesomeIcon icon={faCircleArrowRight} color='#2D6CCA'/></LinkStyled>
    </Link>
  </>;
};

const LinkStyled = styled.a<{width: string}>`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2D6CCA !important;
  display: flex;
  align-items: center;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    width: fit-content;
    max-width: ${p => p?.width};
  }
  &:hover {
    color: #2D6CCA !important;
    text-decoration: underline !important;
  }
`;

export const SubText = styled.div<{width?: string}>`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;;
  max-width: ${p => p.width ? p.width : 'auto'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Text = styled.div<{fontSize?: string}>`
  font-weight: 400;
  font-size: ${p => p.fontSize ? p.fontSize : '12px'};
  line-height: 15px;
  color: #121212;
`;
