import {getApiUrl, getMessagesFromErrorResponse} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {cast} from 'mobx-state-tree';
import {notification} from '@/utils/notifications';
import {getAuthHeader} from '@/api/common-utils';
// import {notification} from '@/utils/notifications';

class ContentReWritingApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');


  public async getContentRewritingResponse(payLoad) {
    try {
      const response = await this.axios.post(`${ContentReWritingApi.baseUrl}/content-fragment/`, payLoad, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return cast(response?.data);
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
      // throw Promise.reject(error);
    }
  }
}


export default ContentReWritingApi;
