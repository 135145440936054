import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
class IssueAnalyzerApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.SA_ENDPOINT, '/api');

  public async issueList(siteProperty, pageSize=60, pageNumber=1, ordering='', type='') {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/issue-list/`, {
        params: {
          siteproperty: siteProperty,
          page_size: pageSize,
          page: pageNumber,
          ordering: ordering,
          type: type,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async issueSummary(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/issues-summary-chart/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async reportList(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/core-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async internalReportList(siteProperty, filter = '') {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/internal-pages-report/`, {
        params: {
          siteproperty: siteProperty,
          filter,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }
  public async localizationReportList(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/localization-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }
  public async pageSpeedReport(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/page-speed-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async redirectsBrokenReport(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/redirects-broken-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async xmlSitemaps(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/xml-sitemaps-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async contentDataList(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/content-duplicate-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  public async getSocialTagsData(siteProperty) {
    try {
      const response = await this.axios.get(`${IssueAnalyzerApi.baseUrl}/social-tags-report/`, {
        params: {
          siteproperty: siteProperty,
        },
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      throw Promise.reject(error);
    }
  }
}

export default IssueAnalyzerApi;
