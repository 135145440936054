import React, {useEffect, useRef} from 'react';
import {BlueTextButton, Container, Label, SectionTitle, Select, StyledFormItem, StyledRadio, SwitchStyled} from './styles';
import {Col, Radio, Row, Spin, Tooltip} from 'antd';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {getDomain} from '@/utils/url';
import {observer} from 'mobx-react';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useIntersectionObserver from './useIntersectionObserver';
import {LoadingOutlined} from '@ant-design/icons';

const WpSection = ({form, wpId, setWpId, selectedSection, setActiveOption}) => {
  const {contentOptimizer: {wpPublish: {
    wpArray,
    setShowAddSiteModal,
  }}} = useStore('');

  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });

  const isVisible = !!blockEntry?.isIntersecting;
  useEffect(() => {
    if (isVisible) {
      setActiveOption('wordPress');
    }
  }, [isVisible]);

  return (
    <Container id='wordPress' ref={blockref}>
      <div className={selectedSection === 'wordPress' ? 'selected-bg' : ''} id='container'>
        <SectionTitle style={{marginBottom: '0px'}}>WORDPRESS INTEGRATION</SectionTitle>
        <Row style={{alignItems: 'center', flexWrap: 'nowrap'}}>
          <Col style={{marginRight: '42px'}}>
            <Label>Website</Label>
          </Col>
          <Col span={16} style={{marginRight: '11px', marginTop: '9px'}}>
            <StyledFormItem
              name='wp_website_id'
            >
              <Select
                onChange={value => {
                  const obj = {};
                  obj['wp_website_id'] = value;
                  form.setFieldsValue(obj);
                  setWpId(value);
                }}
                placeholder='Website'
                disabled={!wpArray?.length}
                suffixIcon={!wpArray?.length ? <Spin indicator={<LoadingOutlined />} /> : <FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
              >
                {toJS(wpArray).map((wpSite, idx) => {
                  return <Select.Option on value={wpSite.id} key={idx}>{getDomain(wpSite.url)}</Select.Option>;
                })}
              </Select>
            </StyledFormItem>
          </Col>
          <Col>
            <BlueTextButton onClick={() => setShowAddSiteModal(true)}>+Add site</BlueTextButton>
          </Col>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <Col style={{marginRight: '42px'}}>
            <Label>Post status</Label>
          </Col>
          <Col>
            <StyledFormItem name='wp_post_status' initialValue={'draft'}>
              <Radio.Group>
                <StyledRadio value='draft'>Draft</StyledRadio>
                <StyledRadio style={{marginLeft: '30px'}} value='publish'>Published</StyledRadio>
              </Radio.Group>
            </StyledFormItem>
          </Col>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <Col style={{marginRight: '51px'}}>
            <Label>Post type</Label>
          </Col>
          <Col>
            <StyledFormItem name='wp_post_type' initialValue={'post'}>
              <Radio.Group>
                <StyledRadio value='post'>Blog post</StyledRadio>
                <StyledRadio style={{marginLeft: '1px'}} value='page'>Page</StyledRadio>
              </Radio.Group>
            </StyledFormItem>
          </Col>
        </Row>
        <Row style={{alignItems: 'center'}}>
          <Col style={{marginRight: '39px'}}>
            <Label>Category</Label>
          </Col>
          <Col span={16} style={{marginRight: '11px', marginTop: '9px'}}>
            <StyledFormItem name='wp_post_categories'>
              <Select
                mode='multiple'
                maxTagCount='responsive'
                onChange={value => {
                  const obj = {};
                  obj['wp_post_categories'] = value;
                  form.setFieldsValue(obj);
                }}
                placeholder='Category'
                maxTagPlaceholder={values => {
                  return (
                    <Tooltip title={values?.map((item, index) => <div key={index}>{item?.label}{index < values?.length - 1 ? ', ' : ''}</div>)}>
                      <div>+{values.length}</div>
                    </Tooltip>
                  );
                }}
                suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#4E5156' />}
                defaultValue={form.getFieldValue('wp_post_categories') ? form.getFieldValue('wp_post_categories') : []}
                disabled={!wpArray?.length}
              >
                {wpId && wpArray?.find(item => item?.id === wpId)?.categories?.map((category, id) => {
                  return <Select.Option key={id} value={category.name}>{category.name}</Select.Option>;
                })}
              </Select>
              {!wpArray?.length ? <Spin indicator={<LoadingOutlined />} style={{position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)'}} /> : <FontAwesomeIcon icon={faCaretDown} color='#4E5156' style={{position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)'}} />}
            </StyledFormItem>
          </Col>
          <StyledFormItem name='wp_auto_upload'>
            <Row style={{flexWrap: 'nowrap', marginTop: '4px'}}>
              <SwitchStyled
                onChange={value => {
                  const obj = {};
                  obj['wp_auto_upload'] = value;
                  form.setFieldsValue(obj);
                }}
                defaultChecked={form.getFieldValue('wp_auto_upload')}
              />
              <Label>Auto-upload to WordPress the articles created with “Write with AI in Bulk” </Label>
            </Row>
          </StyledFormItem>
        </Row>
      </div>
    </Container>
  );
};

export default observer(WpSection);
