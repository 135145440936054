import {types, Instance, flow, cast} from 'mobx-state-tree';
import {siteAuditorApi} from '@/api/account';
import {API_POLL_INTERNAL_LINKING_INTERVAL, API_POLL_INTERVAL} from '@/constants';
import {notification} from 'antd';
import {notification as notify} from '@/utils/notification-v2';
import {isEmpty} from 'lodash';

const SiteAuditExport = types.model({
  isExporting: types.boolean,
  reportsToExport: types.array(types.boolean),
}).actions(self => {
  const setIsExporting = (isExporting: boolean) => {
    self.isExporting = isExporting;
  };

  const toggleReport = (reportIndex: number) => {
    const temp = [...self.reportsToExport];
    temp[reportIndex] = !temp[reportIndex];
    self.reportsToExport = cast([...temp]);
  };

  return {
    setIsExporting,
    toggleReport,
  };
});

const CustomerSiteAudit = types.model({
  allowedQuota: types.maybeNull(types.number),
  analysisStatus: types.maybeNull(types.string),
  cleaningStatus: types.maybeNull(types.string),
  crawlingStatus: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  domainStatus: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  issuesStatus: types.maybeNull(types.string),
  robotsStatus: types.maybeNull(types.string),
  lastCrawledAt: types.maybeNull(types.string),
  approximation: types.maybeNull(types.string),
  numOfBatchesTotal: types.maybeNull(types.number),
  numOfBatchesProcessed: types.maybeNull(types.number),
  postprocessingStatus: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
}).views(self => ({
  get isNotComplete() {
    return self.processingStatus !== 'completed';
  },

  get statusPercentage() {
    const statuses = [self.domainStatus, self.robotsStatus, self.postprocessingStatus];
    const completeCount = statuses.filter(x => x === 'completed').length + self.numOfBatchesProcessed;
    return completeCount ? Math.floor((completeCount/(statuses.length + self.numOfBatchesTotal)) * 100) : 0;
  },
}));

export const TargetKeyword = types.model({
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
});
export const UserTargetKeywords = types.model({
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
});

export type TargetKeywordInstance = Instance<typeof TargetKeyword>;

export const Status = types.model({
  displayValue: types.maybeNull(types.string),
  numericUnit: types.maybeNull(types.string),
  numericValue: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
});

export const PsiMetrics = types.model({
  avgSpeedIndex: types.maybeNull(Status),
  desktopFirstContentfulPaint: types.maybeNull(Status),
  desktopLargestContentfulPaint: types.maybeNull(Status),
  desktopSpeedIndex: types.maybeNull(Status),
  desktopCumulativeLayoutShift: types.maybeNull(Status),
  mobileCumulativeLayoutShift: types.maybeNull(Status),
  desktopFirstInputDelay: types.maybeNull(Status),
  mobileFirstInputDelay: types.maybeNull(Status),
  mobileFirstContentfulPaint: types.maybeNull(Status),
  mobileLargestContentfulPaint: types.maybeNull(Status),
  mobileSpeedIndex: types.maybeNull(Status),
  psiTaskStatus: types.maybeNull(types.string),
});
export const TechnicalsItem = types.model({
  count: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
});
export const TechnicalsHeaderArray = types.model({
  count: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  headers: types.array(types.string),
  status: types.maybeNull(types.string),
});
export const TechnicalsHeader = types.model({
  header: types.array(TechnicalsHeaderArray),
});

// after updates to site-auditor are done, this can be removed
export const ContentScoreMetrics = types.model({
  contentScore: types.maybeNull(types.number),
  focusTermsTotal: types.maybeNull(types.number),
  focusTermsUsed: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
});

export const TechnicalSummaryStats = types.model({
  contentScoreMetrics: types.maybeNull(ContentScoreMetrics),
  internalLinkCount: types.maybeNull(types.number),
  externalLinkCount: types.maybeNull(types.number),
  technicalsScore: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  totalImpressions: types.maybeNull(types.number),
});
export const IssueSummaryStats = types.model({
  issueCount: types.maybeNull(types.number),
  issueStatus: types.maybeNull(types.string),
  brokenLinksCount: types.maybeNull(types.number),
  brokenLinksStatus: types.maybeNull(types.string),
  isIssueProcessed: types.maybeNull(types.boolean),
  redirectingLinksCount: types.maybeNull(types.number),
  redirectingLinksStatus: types.maybeNull(types.string),
});
export const TechnicalLinksStats = types.model({
  externalCount: types.maybeNull(types.number),
  internalCount: types.maybeNull(types.number),
  externalStatus: types.maybeNull(types.string),
  internalStatus: types.maybeNull(types.string),
});
export const Technicals = types.model({
  images: types.maybeNull(TechnicalsItem),
  videos: types.maybeNull(TechnicalsItem),
  social: types.maybeNull(TechnicalsItem),
  iframes: types.maybeNull(TechnicalsItem),
  js: types.maybeNull(TechnicalsItem),
  headers: types.maybeNull(TechnicalsHeader),
  links: types.maybeNull(TechnicalLinksStats),
  summaryStats: types.maybeNull(TechnicalSummaryStats),
  contentScore: types.maybeNull(types.number),
  focusTermsTotal: types.maybeNull(types.number),
  focusTermsUsed: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
});
export type TechnicalType = Instance<typeof Technicals>;

export const AffectedResources = types.model({
  anchor: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.string),
  isRedirect: types.maybeNull(types.boolean),
  ordinal: types.maybeNull(types.number),
  redirectsTo: types.maybeNull(types.string),
  rel: types.maybeNull(types.array(types.string)),
  statusCode: types.maybeNull(types.number),
  url: types.maybeNull(types.string),

  length: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
  size: types.maybeNull(types.number),
  domSize: types.maybeNull(types.number),
  numberOfOutlinks: types.maybeNull(types.number),
  numberOfExtLinks: types.maybeNull(types.number),
  responseTime: types.maybeNull(types.number),
  ttfb: types.maybeNull(types.number),

  header: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  path: types.maybeNull(types.string),
  redirectUrl: types.maybeNull(types.string),
  js: types.maybeNull(types.string),
  css: types.maybeNull(types.string),
  img: types.maybeNull(types.string),
  canonical: types.maybeNull(types.string),
  robotsContent: types.maybeNull(types.string),

});

export type AffectedResourcesType = Instance<typeof AffectedResources>;

export const Issue = types.model({
  affectedResources: types.maybeNull(types.array(types.union(types.frozen({}), types.string, types.number))),
  description: types.maybeNull(types.string),
  datatype: types.maybeNull(types.string),
  isIssue: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  severity: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

export type IssueType = Instance<typeof Issue>;

export const IssuesData = types.model({
  // TODO add complaint count and array
  issueCount: types.maybeNull(types.number),
  issues: types.maybeNull(types.array(Issue)),
});

export const Issues = types.model({
  isProcessed: types.maybeNull(types.boolean),
  updatedAt: types.maybeNull(types.string),
  summaryStats: types.maybeNull(IssueSummaryStats),
  issuesData: types.maybeNull(IssuesData),
});

export const PageScoreModel = types.model({
  contentScore: types.maybeNull(types.number),
  importedFromUrl: types.maybeNull(types.string),
  issueCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  technicalScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
});

export const PageScore = types.model({
  contentScore: types.maybeNull(types.array(PageScoreModel)),
  technicalScore: types.maybeNull(types.array(PageScoreModel)),
  totalImpressions: types.maybeNull(types.array(PageScoreModel)),
});

export const SiteMap = types.model({
  numberOfPages: types.maybeNull(types.number),
  numberOfSitemaps: types.maybeNull(types.number),
});

export const MetaSummary = types.model({
  field: types.maybeNull(types.string),
  isCompliant: types.maybeNull(types.boolean),
  status: types.maybeNull(types.string),
  statusColor: types.maybeNull(types.string),
  suggestion: types.maybeNull(types.string),
  suggestionColor: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  sitemapValues: types.maybeNull(SiteMap),
});

export const UserPageDetauls = types.model({
  contentScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  targetKeywords: types.maybeNull(types.array(UserTargetKeywords)),
});

export const InlinkingTabData = types.model({
  anchorText: types.maybeNull(types.string),
  rel: types.maybeNull(types.array(types.string)),
  statusCode: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

export const SiteAuditorPage = types.model({
  psiMetrics: types.maybeNull(PsiMetrics),
  targetKeywords: types.maybeNull(types.array(TargetKeyword)),
  focustermsTotalCount: types.maybeNull(types.number),
  focustermsUsedCount: types.maybeNull(types.number),
  inlinkingPages: types.maybeNull(types.array(InlinkingTabData)),
  outlinkedPages: types.maybeNull(types.array(InlinkingTabData)),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  reach: types.maybeNull(types.number),
  technicals: types.maybeNull(Technicals),
  technicalScore: types.maybeNull(types.number),
  issues: types.maybeNull(Issues),
  metaSummary: types.maybeNull(types.array(MetaSummary)),
  createdAt: types.maybeNull(types.string),
  dashboardUrl: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  opportunityScore: types.maybeNull(types.number),
  contentScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  pk: types.maybeNull(types.number),
  statusCode: types.maybeNull(types.number),
});

export const GraphDataModel = types.model({
  avgPosition: types.maybeNull(types.number),
  compliantCount: types.maybeNull(types.number),
  contentScore: types.maybeNull(types.number),
  indexability: types.maybeNull(types.boolean),
  issueCount: types.maybeNull(types.number),
  maxSeverity: types.maybeNull(types.number),
  speedIndex: types.maybeNull(types.number),
  statusCode: types.maybeNull(types.number),
  technicalScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  warningCount: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
});

export const SiteAuditorSummaryStats = types.model({
  averageContentScore: types.maybeNull(types.number),
  averageTechnicalScore: types.maybeNull(types.number),
  averageSpeedIndexScore: types.maybeNull(types.number),
  totalIssues: types.maybeNull(types.number),
  pagesWithIssues: types.maybeNull(types.number),
  siteHealth: types.maybeNull(types.number),
  pageScoreData: types.maybeNull(PageScore),
  crawledPages: types.maybeNull(types.number),
  healthyPages: types.maybeNull(types.number),
  broken: types.maybeNull(types.number),
  redirects: types.maybeNull(types.number),
  totalGscPages: types.maybeNull(types.number),
  graphData: types.maybeNull(types.array(GraphDataModel)),
});

export const SiteAuditorListItem = types.model({
  psiMetrics: types.maybeNull(PsiMetrics),
  brokenLinksCount: types.maybeNull(types.number),
  contentScore: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  dashboardUrl: types.maybeNull(types.string),
  externalLinksCount: types.maybeNull(types.number),
  focusTermsTotalCount: types.maybeNull(types.number),
  focusTermsUsedCount: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  internalLinksCount: types.maybeNull(types.number),
  issueCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  technicalScore: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  pk: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
  statusCode: types.maybeNull(types.string),
  depth: types.maybeNull(types.number),
  pagerank: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  redirectsTo: types.maybeNull(types.string),
  normalizedPagerank: types.maybeNull(types.number),
});

export type SiteAuditorListItemType = Instance<typeof SiteAuditorListItem>;

export const SiteAuditorList = types.model({
  results: types.maybeNull(types.array(SiteAuditorListItem)),
});

const FiltersModel = types.model({
  from: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
});


export const internalLinkingLinks = types.model({
  source: types.maybeNull(types.number),
  target: types.maybeNull(types.number),
});

export const internalLinkingNode = types.model({
  anchorText: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.string),
  rel: types.maybeNull(types.array(types.string)),
  isRedirect: types.maybeNull(types.boolean),
  avgPosition: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  serp13: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),

  category: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  itemStyle: types.maybeNull(types.model({
    color: types.maybeNull(types.string),
  })),
  nodeColor: types.maybeNull(types.string),
  normalizedPagerank: types.maybeNull(types.number),
  pk: types.maybeNull(types.number),
  statusCode: types.maybeNull(types.number),
  symbolSizes: types.maybeNull(types.model({
    impressions: types.maybeNull(types.number),
    noOfKeywords: types.maybeNull(types.number),
    pageRank: types.maybeNull(types.number),
    traffic: types.maybeNull(types.number),
    wordCount: types.maybeNull(types.number),
  })),
  title: types.maybeNull(types.string),
  totalKeywords: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),

});

export const internalLinkingData = types.model({
  categories: types.maybeNull(types.array(
    types.model({
      name: types.maybeNull(types.string),
    }),
  )),
  links: types.maybeNull(types.array(internalLinkingLinks)),
  nodes: types.maybeNull(types.array(internalLinkingNode)),
});

export const internalLinkingSection = types.model({
  inlinking: types.maybeNull(internalLinkingData),
  outlinking: types.maybeNull(internalLinkingData),
});

export const SiteAuditorStore = types.model({
  showProcessBanner: types.boolean,
  loadingList: types.boolean,
  loadingSummaryStats: types.boolean,
  copyingPage: types.boolean,
  updatingKeywords: types.boolean,
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  filterKeywordTerm: types.maybeNull(types.string),
  filterKeywordColumns: types.maybeNull(types.array(types.string)),
  filterTableDataSize: types.maybeNull(types.number),
  sortField: types.optional(types.string, ''),
  sortDirection: types.optional(types.string, ''),
  filterList: types.optional(types.array(FiltersModel), []),
  tableSize: types.maybeNull(types.number),
  siteAuditorDetailDrawerOpen: types.boolean,
  issueDetailsDrawerOpen: types.boolean,
  currentViewdIssue: types.maybeNull(Issue),
  backFromDrawer: types.boolean,
  siteAuditorList: types.maybeNull(SiteAuditorList),

  drawerUrl: types.maybeNull(types.string),
  drawerTitle: types.maybeNull(types.string),
  drawerUuid: types.maybeNull(types.union(types.string, types.number)),

  issuePageNumber: types.maybeNull(types.number),
  passUuid: types.optional(types.string, ''),
  popoverVisible: types.boolean,
  loadingProjectPages: types.boolean,
  isGridView: types.boolean,
  activeKey: types.optional(types.string, ''),

  loadSiteAuditPage: types.boolean,
  siteAuditorPage: types.maybeNull(SiteAuditorPage),
  siteAuditorSummaryStats: SiteAuditorSummaryStats,
  pollingIssues: types.boolean,
  pollingPsi: types.boolean,
  pollingTechnicals: types.boolean,
  customerSiteAudit: types.maybeNull(CustomerSiteAudit),
  internalLinking: types.maybeNull(internalLinkingSection),
  userPageDetails: types.maybeNull(UserPageDetauls),

  pageExplorerCurTab: types.maybeNull(types.string),
  recrawlLoading: types.boolean,
  export: SiteAuditExport,
  loadingDepthNodes: types.maybeNull(types.boolean),
  depthNodes: types.maybeNull(types.frozen({})),
  depthNodesGraph: types.maybeNull(types.frozen({})),

  pageUrl: types.maybeNull(types.string),
  pageUuid: types.maybeNull(types.union(types.string, types.number)),
})
  .views(self => ({
    get getSiteAuditorPage() {
      return self.siteAuditorPage;
    },
    get getPopoverVisible() {
      return self.popoverVisible;
    },
    get getIsGridView() {
      return self.isGridView;
    },
    get getActiveKey() {
      return self.activeKey;
    },
    get getGraphData() {
      return self.siteAuditorSummaryStats.graphData;
    },
    get getOutLinkingTable() {
      return self.internalLinking.outlinking.nodes;
    },
    get getInLinkingTable() {
      return self.internalLinking.inlinking.nodes;
    },
    get getInLinkingGraph() {
      return self.internalLinking.inlinking;
    },
    get getOutLinkingGraph() {
      return self.internalLinking.outlinking;
    },
    get getPageURL() {
      return self.pageUrl;
    },
    get getPageUUID() {
      return self.pageUuid;
    },

  }))
  .actions(self => {
    const loadSiteAuditList = flow(function* (domain: string) {
      self.loadingList = true;
      try {
        const data = yield siteAuditorApi.getSiteAuditorList(domain);
        if (data.isCancel) return;
        self.siteAuditorList = data;
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.loadingList = false;
      }
    });

    const loadSiteAuditInternalLink = flow(function* (property: string, url) {
      self.loadingList = true;
      try {
        const params = {
          site_property: property,
          url: url,
        };
        const data = yield siteAuditorApi.getSiteAuditorInternalLink(params);
        if (data.isCancel) return;
        self.internalLinking = data;

        if (data?.shouldRepoll) {
          let shouldRepoll = true;
          while (shouldRepoll) {
            yield new Promise(r => setTimeout(r, API_POLL_INTERNAL_LINKING_INTERVAL));
            const response = yield siteAuditorApi.getSiteAuditorInternalLink(params);
            shouldRepoll = response.shouldRepoll;
            self.internalLinking = response;
          }
        }
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.loadingList = false;
      }
    });
    const setPageExlorerCurTab = (activeTab: string) => {
      self.pageExplorerCurTab = activeTab;
    };

    const clearUserPageDetails = () => {
      self.userPageDetails = null;
    };

    const loadSiteAuditSummaryStats = flow(function* (domain: string) {
      self.loadingSummaryStats = true;
      try {
        const data = yield siteAuditorApi.getSiteAuditorSummaryStats(domain);
        if (data.isCancel) return;
        self.siteAuditorSummaryStats = data;
        self.loadingSummaryStats = false;
      } catch (err) {
        return Promise.reject(err);
      }
    });


    const updatelocationData = (countryCode: string, location: string, locationId: number, reach: number) => {
      self.siteAuditorPage.countryCode = countryCode;
      self.siteAuditorPage.location = location;
      self.siteAuditorPage.locationId = locationId;
      self.siteAuditorPage.reach = reach;
    };

    const setIssuePageNumber = (pageNumber: number) => {
      self.issuePageNumber = pageNumber;
    };

    const setSiteAuditorDetailDrawerOpen = (url: string, title: string, uuid: string | number) => {
      setIssuePageNumber(1);
      self.siteAuditorDetailDrawerOpen = true;
      self.drawerUrl = url;
      self.drawerUuid = uuid;
      self.drawerTitle = title;
    };

    const setDrawerUrl = (url: string) => self.drawerUrl = url;
    const setDrawerTitle = (title: string) => self.drawerTitle = title;

    const setSiteAuditorDetailDrawerClose = () => {
      self.siteAuditorDetailDrawerOpen = false;
    };

    const setIssueDetailsDrawerOpen = (value: boolean, issue?: IssueType) => {
      self.issueDetailsDrawerOpen = value;

      if (value) {
        if (issue) {
          self.currentViewdIssue = issue;
        }
      }

      if (!issue) {
        self.currentViewdIssue = null;
        self.backFromDrawer = true;
      }
    };


    const setPopoverVisible = (visible: boolean) => {
      self.popoverVisible = visible;
    };

    const setBackFromDrawer = (value: boolean) => {
      self.backFromDrawer = value;
    };

    const setIsGridView = (visible: boolean) => {
      self.isGridView = visible;
    };

    const setActiveKey = (key: string) => {
      self.activeKey = key;
    };

    const createAndLoadSiteAuditSinglePage = flow(function* (url: string) {
      try {
        self.loadSiteAuditPage = true;
        const newPage = yield siteAuditorApi.createSiteAuditPage(url);
        if (newPage?.id) {
          yield loadSiteAuditSinglePage(newPage?.id);
        }
        self.pageUrl = url;
        self.pageUuid = newPage?.id;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const loadSiteAuditSinglePage = flow(function* (uuid: string) {
      self.loadSiteAuditPage = true;
      self.siteAuditorPage.issues.issuesData.issues = cast([]);
      try {
        const response = yield siteAuditorApi.getSiteAuditorPage(uuid);
        if (response.isCancel) return;

        self.siteAuditorPage = cast(response);

        if (response.userPageDetails) {
          self.userPageDetails = response.userPageDetails;
        }

        if (!response.issues.isProcessed && !response.technicals.isProcessed && isEmpty(response.psiMetrics)) {
          self.pollingIssues = true;
          self.pollingPsi = true;
          self.pollingTechnicals = true;
          yield new Promise(r => setTimeout(r, 2000));
          return loadSiteAuditSinglePage(uuid);
        }

        if (!response.issues.isProcessed) {
          self.pollingIssues = true;
          yield new Promise(r => setTimeout(r, 2000));
          return loadSiteAuditSinglePage(uuid);
        }

        if (!response.technicals?.isProcessed) {
          self.pollingTechnicals = true;
          yield new Promise(r => setTimeout(r, 2000));
          return loadSiteAuditSinglePage(uuid);
        }

        if (isEmpty(response.psiMetrics)) {
          self.pollingPsi = true;
          yield new Promise(r => setTimeout(r, 2000));
          return loadSiteAuditSinglePage(uuid);
        }
        self.loadSiteAuditPage = false;
        self.pollingIssues = false;
        self.pollingPsi = false;
        self.pollingTechnicals = false;
      } catch (err) {
        return Promise.reject(err);
      }
    });

    const copyPage = flow(function* (uuid: number) {
      self.copyingPage = true;
      try {
        const response = yield siteAuditorApi.copyPage(uuid);
        return response.uuid;
      } catch (err) {
        if (err?.response?.status && err?.response?.status === 429) {
          notify.warning('', err?.response?.data?.message);
        } else {
          return Promise.reject(err);
        }
      } finally {
        self.copyingPage = false;
      }
    });

    const updateKeywords = flow(function* (pk: number, keywords: any[]) {
      self.updatingKeywords = true;
      try {
        const response = yield siteAuditorApi.updateKeywords(pk, keywords, self.siteAuditorPage.countryCode, self.siteAuditorPage.location, self.siteAuditorPage.locationId);
        if (response?.isCancel) return Promise.reject(response);
        if (response?.shouldRepoll) {
          let shouldRepoll = true;
          while (shouldRepoll) {
            yield new Promise(r => setTimeout(r, API_POLL_INTERVAL));
            const response = yield siteAuditorApi.getUpdateKeywordsStatus(pk);
            shouldRepoll = response;
          }
        }

        if (response) {
          if (response.targetKeywords) {
            self.siteAuditorPage.targetKeywords = cast(response.targetKeywords);
          }
          notification.success({
            message: 'Keywords updated successfully!',
            placement: 'bottomRight',
          });
          loadSiteAuditSinglePage(pk);
        }
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.updatingKeywords = false;
      }
    });

    const checkIfProcessed = flow(function* (siteProperty: string) {
      self.showProcessBanner = false;
      try {
        const response = yield siteAuditorApi.checkIfProcessed(siteProperty);
        if (response.isCancel) return;
        self.customerSiteAudit = response;

        if (self.customerSiteAudit?.isNotComplete) {
          yield new Promise(r => setTimeout(r, 2000));
          return checkIfProcessed(siteProperty);
        }
      } catch (err) {
        self.showProcessBanner = true;
      }
    });

    const processSiteProperty = flow(function* (siteProperty: string, shouldReprocess = false) {
      try {
        const response = yield siteAuditorApi.processSiteProperty(siteProperty, shouldReprocess);
        if (response.isCancel) return;
        checkIfProcessed(siteProperty);
      } catch (err) {
        self.showProcessBanner = true;
      }
    });

    const recrawlSinglePage = flow(function* (uuid: string | number) {
      self.recrawlLoading = true;
      try {
        const response = yield siteAuditorApi.recrawlSiteAuditPage(uuid);
        if (response.isCancel) return;
        loadSiteAuditSinglePage(uuid);
      } catch (err) {
        self.recrawlLoading = false;
      } finally {
        self.recrawlLoading = false;
      }
    });

    const loadDepthNodes = flow(function* (siteProperty: string, nodeSizeBy: string) {
      self.depthNodesGraph = {};
      self.loadingDepthNodes = true;
      try {
        const response = yield siteAuditorApi.getDepthNodes(siteProperty);
        // @ts-ignore
        self.depthNodes = {name: 'homepage', children: response};
        const graph = yield siteAuditorApi.getDepthNodes(siteProperty, 'circle', nodeSizeBy);
        if (graph) {
          self.depthNodesGraph = graph;
        }
      } catch (err) {
        const {response} = err;
        const {data} = response;
        notify.error('Sorry', data.siteproperty[0]);
        self.loadingDepthNodes = false;
      } finally {
        self.loadingDepthNodes = false;
      }
    });

    return {
      setPageExlorerCurTab,
      setDrawerUrl,
      setDrawerTitle,
      setBackFromDrawer,
      setIssueDetailsDrawerOpen,
      loadSiteAuditList,
      loadSiteAuditSummaryStats,
      setPopoverVisible,
      setActiveKey,
      loadSiteAuditSinglePage,
      loadSiteAuditInternalLink,
      // loadIndexibilityRobotData,
      setIsGridView,
      setSiteAuditorDetailDrawerOpen,
      setSiteAuditorDetailDrawerClose,
      setIssuePageNumber,
      createAndLoadSiteAuditSinglePage,
      copyPage,
      updateKeywords,
      checkIfProcessed,
      processSiteProperty,
      updatelocationData,
      clearUserPageDetails,
      recrawlSinglePage,
      loadDepthNodes,
    };
  });

export type SiteAuditorType = Instance<typeof SiteAuditorStore>;

export function initSiteAuditStore() {
  return SiteAuditorStore.create({
    customerSiteAudit: null,
    showProcessBanner: false,
    recrawlLoading: false,
    loadingList: true,
    loadingProjectPages: true,
    loadingSummaryStats: true,
    pollingIssues: false,
    pollingPsi: false,
    pollingTechnicals: false,
    updatingKeywords: false,
    popoverVisible: false,
    isGridView: false,
    loadSiteAuditPage: true,
    copyingPage: false,
    activeKey: '2',
    siteAuditorList: {
      results: [],
    },
    backFromDrawer: false,
    siteAuditorSummaryStats: {
      averageContentScore: null,
      averageTechnicalScore: null,
      averageSpeedIndexScore: null,
      totalIssues: null,
      siteHealth: null,
      pageScoreData: {
        contentScore: [],
        technicalScore: [],
        totalImpressions: [],
      },
      crawledPages: null,
      healthyPages: null,
      broken: null,
      redirects: null,
      totalGscPages: null,
    },
    internalLinking: {
      inlinking: {
        categories: [],
        links: [],
        nodes: [],
      },
      outlinking: {
        links: [],
        nodes: [],
        categories: [],
      },
    },
    sortField: '',
    sortDirection: '',
    filterList: [],
    pageSize: 10,
    pageNumber: 1,
    filterKeywordColumns: ['title'],
    filterKeywordTerm: '',
    tableSize: 0,
    siteAuditorDetailDrawerOpen: false,
    issueDetailsDrawerOpen: false,
    drawerUrl: '',
    drawerTitle: '',
    drawerUuid: '',
    issuePageNumber: 1,
    siteAuditorPage: {
      psiMetrics: {},
      technicals: {
        images: {
          count: null,
          status: '',
        },
        videos: {
          count: null,
          status: '',
        },
        social: {
          count: null,
          status: '',
        },
        iframes: {
          count: null,
          status: '',
        },
        js: {
          count: null,
          status: '',
        },
        headers: {
          header: [],
        },
        summaryStats: {
          contentScoreMetrics: {
            contentScore: null,
            focusTermsTotal: null,
            focusTermsUsed: null,
            wordCount: null,
            countryCode: null,
          },
          internalLinkCount: null,
          externalLinkCount: null,
          technicalsScore: null,
          opportunityScore: null,
          totalImpressions: null,
        },
      },
      metaSummary: [],
      issues: {
        isProcessed: null,
        updatedAt: '',
        summaryStats: {
          issueCount: null,
          issueStatus: '',
          brokenLinksCount: null,
          brokenLinksStatus: '',
          isIssueProcessed: null,
          redirectingLinksCount: null,
          redirectingLinksStatus: '',
        },
        issuesData: {
          issues: [],
          issueCount: null,
        },
      },
      createdAt: '',
      dashboardUrl: '',
      url: '',
      opportunityScore: null,
      title: '',
      totalImpressions: null,
      updatedAt: '',
      uuid: '',
      pk: null,
      countryCode: '',
      location: '',
      locationId: null,
      reach: null,
    },
    depthNodes: null,
    depthNodesGraph: null,
    loadingDepthNodes: true,

    export: {
      isExporting: false,
      reportsToExport: [true, true, true, true, true, true, true, true, true],
    },
  });
}
