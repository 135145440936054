import ReactEcharts from 'echarts-for-react';
import {EChartOption} from 'echarts';
import React from 'react';
import {renderToString} from 'react-dom/server';
import numeral from 'numeral';
import moment from 'moment';
import {Badge} from 'antd';

export interface BarChartColumnProps extends EChartOption {
  series: any;
  xaxixData?:any;
  isAdsPosition?: boolean;
}
export const BarChartColumn: React.FC<BarChartColumnProps> = ({
  series,
  xaxixData,
  isAdsPosition,
  ...props
}) => {
  const tooltipFormatter = function(series: any) {
    const element = (
      <div>
        <span style={{fontWeight: 'bold'}}>
          {moment(series[0].axisValue).format('MMM DD, YYYY')}
        </span>
        {series?.reverse()?.map((item, index: number) => {
          return (
            <div key={index}>
              <div >
                <span >
                  {/* {moment(item.axisValue).format('MMM DD, YYYY')} */}
                </span>
                <span>
                  <Badge color={item.color} dot size='small' />
                 Position: {numeral(item.data).format('0.[0][a]').toUpperCase()}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
    return renderToString(element);
  };
  return (
    <ReactEcharts
      style={{width: '100px', height: '38px'}}
      option={{
        series,

        xAxis: {
          type: 'category',
          data: xaxixData || [],
          show: false,
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        grid: {
          height: '50%',
          left: '-40%',
          top: '10px',
        },
        tooltip: {
          show: isAdsPosition ? true : false,
          trigger: 'axis',
          confine: true,
          formatter: tooltipFormatter,
        },
        ...props,
      }}

    />
  );
};
