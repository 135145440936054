import React, {useState, useEffect} from 'react';
import styles from '../style.module.scss';
import {useTranslation} from 'next-i18next';
import {Checkbox} from 'antd';
import {ApplyBtnWrapper, ApplyButton, CheckboxGropContainer, CustomRangeContainer} from '../styledComponents';

interface Props {
  styleClass?: string;
  options: any;
  filterLabel?: string;
  categories?: any[];
  checkedValues?: any[];
  filterId?: number;
  filterName?: string;
  removeItem?: ()=>void;
  onChange?: (filter:any)=>void;
  className?: string;
  absoluteUi?: boolean;
  isClear?: boolean;
  allowSingleBox?: boolean;
  selectBetween?: boolean;
  onClear?: ()=>void;
  customCategoryType?: string;
}

const FilterMultipleCheckboxPopUp: React.FC<Props> = ({allowSingleBox=false, selectBetween=false, checkedValues, categories, filterId, filterLabel, options, filterName, onChange, customCategoryType}) => {
  const selectedFilters = categories || [];
  const [filtersSelected, setFiltersSelected] = useState(checkedValues);
  const {t} = useTranslation('common');

  useEffect(() => {
    setFiltersSelected(checkedValues);
  }, [checkedValues?.length]);

  function checkedOnChange(checkedValues) {
    if (allowSingleBox) {
      const checkPrev = filtersSelected?.find(item => filtersSelected?.length ? item == filtersSelected[0] : false);
      setFiltersSelected(checkedValues?.length ? checkedValues?.filter(z => z !== checkPrev) : []);
    } else if (selectBetween) {
      const firstIndex = categories?.findIndex(z => z?.value == checkedValues[0]);
      const lastIndex = categories?.findIndex(z => z?.value == checkedValues[checkedValues?.length - 1]);
      if (firstIndex != -1 && lastIndex != -1) {
        setFiltersSelected(categories?.slice(firstIndex, lastIndex+1)?.map(z => z?.value));
      } else {
        setFiltersSelected(checkedValues);
      }
    } else {
      setFiltersSelected(checkedValues);
    }
  }
  const createOrUpdateFilter = (e, filterSelect) =>{
    e.preventDefault();
    emitOnChanges(filterSelect);
    if (!checkedValues) {
      setFiltersSelected([]);
    }
  };

  const emitOnChanges = selected => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        active: true,
        filterTypes: categories,
        category: selected,
        customCategoryType: customCategoryType,
      });
    }
  };

  const disablingButton = () =>{
    if (filtersSelected?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (selectedFilters?.length ===0) {
      setFiltersSelected(['']);
    }
  }, [selectedFilters?.length]);

  return (
    <CustomRangeContainer>
      <CheckboxGropContainer>
        <Checkbox.Group className={styles.checkboxesStyle} options={options} value={filtersSelected} onChange={checkedOnChange} />
      </CheckboxGropContainer>
      <ApplyBtnWrapper>
        <ApplyButton
          disabled={disablingButton()}
          onClick={e => createOrUpdateFilter(e, filtersSelected)}
        >
          {t('apply-filter')}
        </ApplyButton>
      </ApplyBtnWrapper>
    </CustomRangeContainer>
  );
};

export default FilterMultipleCheckboxPopUp;
