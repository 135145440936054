import styles from './index.module.scss';
import {observer} from 'mobx-react-lite';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import {Menu, Dropdown, Select, Tooltip, Progress} from 'antd';
import styled from 'styled-components';
import Link from 'next/link';
import {Button} from '@/components/common-components/v2/Button';
import React, {useEffect, useState} from 'react';
import {getDomain, isSAPublicPage} from '@/utils/url';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faImageSlash, faPause} from '@fortawesome/pro-solid-svg-icons';
import {titleCase, truncate} from '@/utils/string';
import {useRouter} from 'next/router';
import {NewGscModal} from '@/components/common-components/v2/newGscModal';
import {routes} from '@/utils/const';
import {NextImg} from '@/utils/nextImg';
import {DeleteConfirmModal} from '@/components/dashboard/gsc/gsc/PageGroupings/blocks/Groups/DeleteConfirm';
import {notification as notify} from '@/utils/notification-v2';
import {CrawlSettingsModal} from '@/components/common-components/v2/crawlSettingsModal';
interface Props {
  data: any;
}

export const formatCrawlRules = conditions => {
  return conditions?.filter(condition => condition?.value)?.map(condition => {
    const action = condition?.action === 'Exclude' ? 'Disallow' : 'Allow';
    const ruleValue = condition?.rule === 'Contain' ? `/*${condition?.value}` : `/${condition?.value}`;
    return `${action}: ${ruleValue}`;
  })?.join('\n');
};

const SiteCard: React.FC<Props> = observer(({data}) => {
  const {
    siteAuditorV2: {
      updateSelectedSiteSA,
      gscLoading,
      handleArchiveSite,
      recrawlSiteAuditProperty,
      reCrawlSiteListPage,
      siteAuditSelectedProperty,
      setGscOpenModalValue,
      setPropertyUrl,
    },
  } = useStore('');
  const {gsc: {googleAnalytics: {setGaDataModal, gaDataModal}}} = useStore('');
  const {
    settings: {
      customer: {setAuthModal},
    },
  } = useStore('');
  const {gsc: {criteria}} = useStore('');
  const siteProp = localStorage.getItem('lastPropertySiteAudit');
  const [showCrawlModal, setShowCrawlModal] = useState(false);
  const [gscConnectModal, setGscConnectModal] = useState(false);
  const [country, setCountry] = useState('US');
  const [siteProperty, setSiteProperty] = useState(criteria.property);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);

  const projectId = (siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || router.query?.id))?.toString();

  useEffect(() => {
    if (criteria.property) {
      setSiteProperty(criteria.property);
    }
  }, [criteria.property]);

  useEffect(() => {
    if (!gscConnectModal) {
      setSiteProperty(criteria.property);
      setCountry('US');
    }
  }, [gscConnectModal]);

  const handleRecrawl = id => {
    if (id) {
      if (isSAPublicPage()) {
        setAuthModal(true, false);
      } else {
        reCrawlSiteListPage(id);
      }
    }
  };

  const handleArchive = async () => {
    if (data.id == siteProp) {
      localStorage.removeItem('lastPropertySiteAudit');
    }
    setIsLoading(true);
    await handleArchiveSite(data?.id);
    setShowDeleteProjectModal(false);
    setIsLoading(false);
    notify.success('Success', 'Deleted successfully', 'Got it!');
  };

  const crawlSettingModal = () => {
    setShowCrawlModal(true);
  };
  const handleOpenGSCModal = isGaObject => {
    if (isSAPublicPage()) {
      setAuthModal(true, false);
    } else {
      setGscOpenModalValue(true, 'GSC Setting', true, projectId);
      setGaDataModal({
        ...gaDataModal,
        visible: true,
        siteUrl: isGaObject?.propertyUrl || '',
        gaConnected: isGaObject?.gaConnected,
        settingId: null,
        settingsEnabled: false,
        hasSiteGaCredentials: null,
        saProjectId: isGaObject?.id,
      });
    }
  };

  const menuForprocessingAndStuck = (
    <Menu>
      <Menu.Item key={'archive'} onClick={() => handleArchive()}>
        <span className={styles.projectArchive}>
          {'Delete'}
        </span>
      </Menu.Item>
    </Menu>
  );
  const menu = (
    <Menu>
      <Menu.Item key={'gscSettings'} onClick={() => handleOpenGSCModal(data)}>
        <span className={styles.crawlSetting}>
          {'GSC/GA Settings'}
        </span>
      </Menu.Item>
      <Menu.Item key={'reCrawl'} onClick={() => handleRecrawl(data?.id)}>
        <span className={styles.crawlSetting}>
          {'Recrawl'}
        </span>
      </Menu.Item>
      <Menu.Item key={'crawlSetting'} onClick={() => crawlSettingModal()}>
        <span className={styles.crawlSetting}>
          {'Crawl Settings'}
        </span>

      </Menu.Item>
      <Menu.Item key={'archive'} onClick={() => {
        setShowDeleteProjectModal(true);
      }}>
        <span className={styles.projectArchive}>
          {'Delete'}
        </span>
      </Menu.Item>
    </Menu>
  );

  const onCountryChange = value => {
    setCountry(value);
  };

  // const userAgentData = {
  //   google_chrome_desktop: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/60.0.3112.113 Safari/537.36',
  //   google_chrome_mobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75 Mobile/14E5239e Safari/602.1',
  //   googlebot_desktop: 'Mozilla/5.0 (compatible; Googlebot/2.1)',
  //   googlebot_mobile: 'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Googlebot/2.1)',
  //   bingbot_desktop: 'Mozilla/5.0 (compatible; bingbot/2.0)',
  //   bingbot_mobile: 'Mozilla/5.0 (iPhone; CPU iPhone OS 7_0 like Mac OS X) AppleWebKit/537.51.1 (KHTML, like Gecko) Version/7.0 Mobile/11A465 Safari/9537.53 (compatible; bingbot/2.0)',
  //   slurp: 'Mozilla/5.0 (compatible; Yahoo! Slurp)',
  //   yandexbot: 'Mozilla/5.0 (compatible; YandexBot/3.0)',
  //   baiduspider: 'Baiduspider+',
  //   screaming_frog: 'Screaming Frog SEO Spider/2.11',
  //   duckduckgo: 'Mozilla/5.0 (compatible; DuckDuckGo-Favicons-Bot/1.0)',
  //   searchatlas: 'SearchAtlas Bot (https://www.searchatlas.com)',
  // };
  const handleViewAudit = data => {
    if (data?.id) {
      updateSelectedSiteSA(data?.id);
      setPropertyUrl(data.propertyUrl);
      if (!router?.pathname?.includes('public')) {
        router.push(`/site-audit/overview/?id=${data?.id}`);
      } else {
        router?.push(`/site-audit/overview-public/?id=${data?.id}&public_hash=${data?.publicShareHash}`);
      }
    }
  };
  const getViewAuditLink = data => {
    if (data?.id) {
      if (!router?.pathname?.includes('public')) {
        return `/site-audit/overview/?id=${data?.id}`;
      } else {
        return `/site-audit/overview-public/?id=${data?.id}&public_hash=${data?.publicShareHash}`;
      }
    }
  };
  const checkAuditState = item => {
    if (item?.status == 'processing' && item?.isFirstCrawl) {
      return <div style={{alignItems: 'center', width: '100%'}}>

        <div style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
          <Processing>{'Estimated time left:'} </Processing>
          <Estimatetime>{item?.estimatedTimeleft || '-'}</Estimatetime>
        </div>

        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <ProcessingBar >
            <InnerBar style={{background: '#7F4EAD', maxWidth: `${getPercentage(item?.progressBar, 100)}%`}}> </InnerBar>
          </ProcessingBar>
          <div style={{marginTop: 15}}>
            <Dropdown trigger={['click']} overlay={menuForprocessingAndStuck} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />
            </Dropdown>
          </div>
        </div>
      </div>;
    } else if (item?.status == 'stuck' && !item?.isFirstCrawl) {
      return <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Crawl>{'Last crawl'}</Crawl>
          <LastCrawlMessage>{item?.lastCrawled} &nbsp;&nbsp;   <Tooltip title={
            <>
              <TooltipStuckMessagge> This site will start recrawling soon. You can still view the data from the latest crawl. </TooltipStuckMessagge>
            </>
          } placement='bottomLeft' overlayInnerStyle={{borderRadius: '5px', width: '256px', height: '68px'}}> <FontAwesomeIcon style={{fontSize: 14, color: '#A3A4A4'}} icon={faPause} /></Tooltip></LastCrawlMessage>

        </div>
        <div style={{width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'space-between'}}>
          <RecrawlButtonFailed
            onClick={() => handleViewAudit(data)}
            color='textGrayLight' textColor='#121212' >{'View Audit'}</RecrawlButtonFailed>
          <div style={{marginTop: 8}}>
            <Dropdown trigger={['click']} overlay={menu} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />

            </Dropdown>
          </div>
        </div>
      </div>;
    } else if (item?.status == 'stuck' && item?.isFirstCrawl) {
      return <div>
        <StuckMessage>{`We’re preprocessing the site data and will soon start the audit.`}</StuckMessage>


        <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <ProcessingBar >
            <InnerBar style={{background: '#FF8536', maxWidth: `15px`}}> </InnerBar>
          </ProcessingBar>
          <div style={{marginTop: 15}}>
            <Dropdown trigger={['click']} overlay={menuForprocessingAndStuck} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />
            </Dropdown>
          </div>
        </div>
      </div>;
    } else if (item?.status == 'failed' && item?.isFirstCrawl) {
      const errorString = "Crawl failed. We'll recrawl this site soon";
      return <div>
        <div className={styles.lastCrawlContainer}>
          <Crawl>{'Last crawl'}</Crawl>
          <LastCrawlMessage>{item?.lastCrawled || ''} &nbsp;&nbsp;   <Tooltip title={
            <>
              <ErrorWrapper >
                {item?.crawlErrorMessage ? `${item?.crawlErrorMessage}` : <span title={errorString.length > 37 ? errorString : ''}>{`${truncate(errorString, 35)}`}</span>}<a style={{textDecoration: 'underline'}} href={'https://searchatlas.com/blog/whitelist-monitoring-on-cloudflare/'} target='_blank' rel='noreferrer' >How to fix</a>.

              </ ErrorWrapper> </>
          } placement='bottomLeft' overlayInnerStyle={{borderRadius: '5px', width: '240px'}}> <FontAwesomeIcon style={{fontSize: 14, color: '#F44343'}} icon={faBan} /></Tooltip></LastCrawlMessage>

        </div>
        <div className={styles.viewAuditContainer}>
          <RecrawlButtonFailed
            onClick={() => handleViewAudit(data)}
            color='textGrayLight' textColor='#121212' >{'View Audit'}</RecrawlButtonFailed>
          <div style={{marginTop: 8}}>
            <Dropdown trigger={['click']} overlay={menu} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />

            </Dropdown>
          </div>
        </div>
      </div>;
    } else if (item?.status == 'failed' && !item?.isFirstCrawl) {
      return <div>
        <MainErrorMessage>
          <ErrorMessage >
            {item?.crawlErrorMessage ? `${item?.crawlErrorMessage}` : 'This site is blocking our crawler.'}
          </ErrorMessage>
        </MainErrorMessage>
        <div className={styles.viewAuditContainer}>
          <RecrawlButtonFailed
            onClick={() => {
              recrawlSiteAuditProperty(data?.id);
            }}
            color='textGrayLight' textColor='#121212' >{'Recrawl'}</RecrawlButtonFailed>
          <div style={{marginTop: 8}}>
            <Dropdown trigger={['click']} overlay={menu} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />
            </Dropdown>
          </div>
        </div>
      </div>;
    } else if (item?.status == 'processing' && !item?.isFirstCrawl) {
      return <div>
        <div className={styles.lastCrawlContainer}>
          <Crawl>{'Last crawl'}</Crawl>
          <LastCrawlMessage>{item?.lastCrawled} &nbsp;&nbsp;   <Tooltip title={
            <>
              <div>
                <TooltipMessage>This site is being recrawled. You can still view the data from the latest crawl.</TooltipMessage>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <TooltipEstiMateTime>Estimated time left:</TooltipEstiMateTime>
                  <TooltipEstiMateTime>{item?.estimatedTimeleft || '-'}</TooltipEstiMateTime>
                </div>
                <TooltipProcessingBar >
                  <InnerBar style={{background: '#7F4EAD', maxWidth: `${getPercentage(item?.progressBar, 100)}%`}}> </InnerBar>
                </TooltipProcessingBar>
              </div>
            </>
          } placement='bottomLeft' overlayInnerStyle={{width: '276px', borderRadius: '5px'}}><LoadingOutlined style={{fontSize: '15px', marginRight: 'auto', color: ' #7F4EAD'}} /></Tooltip></LastCrawlMessage>

        </div>
        <div className={styles.viewAuditContainer}>
          <RecrawlButtonFailed
            onClick={() => handleViewAudit(data)}
            color='textGrayLight' textColor='#121212' >{'View Audit'}</RecrawlButtonFailed>
          <div style={{marginTop: 8}}>
            <Dropdown trigger={['click']} overlay={menu} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />

            </Dropdown>
          </div>
        </div>
      </div>;
    } else {
      return <div>
        <div className={styles.lastCrawlContainer}>
          <Crawl>{'Last crawl'}</Crawl>
          <LastCrawlMessage>{item?.lastCrawled}</LastCrawlMessage>
        </div>
        <div className={styles.viewAuditContainer}>
          <RecrawlButtonFailed
            onClick={() => handleViewAudit(data)}
            color='textGrayLight' textColor='#121212' >{'View Audit'}</RecrawlButtonFailed>
          <div style={{marginTop: 8}}>
            <Dropdown trigger={['click']} overlay={menu} placement='bottomLeft' className={styles.dropdown}>
              <MoreOutlined />
            </Dropdown>
          </div>
        </div>
      </div>;
    }
  };


  return (
    <>
      <CardWrapper className={styles.projectContainer} >
        <div className={styles.info} style={data?.isDeletingAudit ? {pointerEvents: 'none'} : {}}>
          <div className={styles.imageContainer}>
            {data?.status == 'failed' ? <>
              {data?.propertyUrl ? <NextImg className={styles.gscFavicon} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data?.propertyUrl && getDomain((!/^(https?:)?\/\//i.test(data?.propertyUrl)) ? 'https:' + data?.propertyUrl : data?.propertyUrl)}&size=64`} /> :
                <FontAwesomeIcon style={{fontSize: 55, color: '#A3A4A4'}} icon={faImageSlash} />}
            </> : <>
              <Link legacyBehavior href={getViewAuditLink(data)}
                passHref><a rel='noopener noreferrer'>
                  <div onClick={() => {
                    updateSelectedSiteSA(data?.id);
                  }}>
                    {data?.propertyUrl ? <NextImg className={styles.gscFavicon} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${data?.propertyUrl && getDomain((!/^(https?:)?\/\//i.test(data?.propertyUrl)) ? 'https:' + data?.propertyUrl : data?.propertyUrl)}&size=64`} /> :
                      <FontAwesomeIcon style={{fontSize: 55, color: '#A3A4A4'}} icon={faImageSlash} />}
                  </div>
                </a>
              </Link>
            </>}
          </div>
          <div className={styles.infoHeaderRight}>
            <div className={styles.headerTitle}>
              <div style={{display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                <Link legacyBehavior href={getViewAuditLink(data)} passHref><a rel='noopener noreferrer' >
                  <Domain onClick={() => {
                    updateSelectedSiteSA(data?.id);
                  }}>{getDomain(data?.propertyUrl)}
                  </Domain></a>
                </Link>
                {data?.gscConnected ? <GSCShow onClick={() => {
                  setGscOpenModalValue(true, 'GSC Setting', true, projectId);
                  setGaDataModal({
                    ...gaDataModal,
                    visible: true,
                    siteUrl: data?.propertyUrl || '',
                    gaConnected: data?.gaConnected,
                    settingId: null,
                    settingsEnabled: false,
                    hasSiteGaCredentials: null,
                    saProjectId: data?.id,
                  });
                }}>GSC</GSCShow> : ''}
              </div>
            </div>
            {data?.isDeletingAudit ? <div>
              <div style={{paddingBottom: 12}}>
                <Crawl>{'Deleting...'}</Crawl>
              </div>
              <div style={{width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'space-between'}}>
                <RecrawlButtonFailed
                  color='textGrayLight' textColor='#121212' >{'View Audit'}</RecrawlButtonFailed>
                <div style={{marginTop: 8}}>
                  <Dropdown trigger={['click']} visible={false} overlay={menu} placement='bottomLeft' className={styles.dropdown}>
                    <MoreOutlined />
                  </Dropdown>
                </div>
              </div>
            </div> : checkAuditState(data)}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.cardsContainer}>
            <div className={styles.cardBox}>
              <TitleContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <GraphTitle >{data?.isFirstCrawl ? 'Crawl Agent' : 'Site Health'}</GraphTitle>
                </div>
                <div style={{display: 'flex', marginTop: 6}}>
                  <Metrics onClick={() => handleViewAudit(data)}
                    style={{width: 100, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'pre'}}>{data?.isFirstCrawl ? <FirstValue >{titleCase(data?.crawlAgent)}</FirstValue> : <><FirstValue>{data?.siteHealthValues?.actual ?? '-'}</FirstValue><SecondValue>/{data?.siteHealthValues?.total ?? '-'}</SecondValue></>}</Metrics>
                </div>
                {(data?.status == 'processing' && !data?.isFirstCrawl) ? <StyledProgress style={{marginTop: 7, width: 100, height: 6}} trailColor='#E8E8E8' strokeColor={(data?.siteHealthValues?.actual == 0) ? '#E8E8E8' : data?.siteHealthValues?.color}
                  // @ts-ignore
                  percent={(data?.siteHealthValues?.actual == 0) ? 70 : getPercentage(data?.siteHealthValues?.actual, data?.siteHealthValues?.total)} showInfo={false} status={data?.status == 'processing' && 'active'} /> :
                  data?.isFirstCrawl == true ? '' : <Bar>
                    <InnerBar style={{background: `${data?.siteHealthValues?.color}`, maxWidth: `${getPercentage(data?.siteHealthValues?.actual, data?.siteHealthValues?.total)}%`}}></InnerBar>
                  </Bar>}
              </TitleContainer>

            </div>
            <div className={styles.cardBox}>
              <TitleContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <GraphTitle >{'Crawled Pages'}</GraphTitle>
                </div>
                <div style={{display: 'flex', marginTop: 6}}>
                  <Metrics onClick={() => {
                    updateSelectedSiteSA(data?.id);
                    router.push(routes.pageExplorer);
                  }}>
                    <FirstValue>{data?.crawledPagesValues?.actual ?? '-'}</FirstValue><SecondValue>/{data?.crawledPagesValues?.total ?? '-'}</SecondValue></Metrics>
                </div>
                {(data?.status == 'processing' && !data?.isFirstCrawl) ? <StyledProgress style={{marginTop: 7, width: 100, height: 6}} trailColor='#E8E8E8' strokeColor={(data?.crawledPagesValues?.actual == 0 && !data?.isFirstCrawl) ? '#E8E8E8' : data?.crawledPagesValues?.color}
                  // @ts-ignore
                  percent={(data?.crawledPagesValues?.actual == 0 && !data?.isFirstCrawl) ? 70 : getPercentage(data?.crawledPagesValues?.actual, data?.crawledPagesValues?.total)} showInfo={false} status={data?.status == 'processing' && 'active'} /> :
                  data?.isFirstCrawl == true ? '' : <Bar>
                    <InnerBar style={{background: `${data?.crawledPagesValues?.color}`, maxWidth: `${getPercentage(data?.crawledPagesValues?.actual, data?.crawledPagesValues?.total)}%`}}></InnerBar>
                  </Bar>}

              </TitleContainer>

            </div>
            <div className={styles.cardBox}>
              <TitleContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <GraphTitle >{data?.isFirstCrawl ? 'Crawl Speed' : 'Indexable Pages'}</GraphTitle>
                </div>
                <div style={{display: 'flex', marginTop: 6}}>
                  <Metrics onClick={() => {
                    updateSelectedSiteSA(data?.id);
                    router.push(`${routes.pageExplorer}?filterName=indexable&value=indexable`);
                  }}>
                    <FirstValue>{data?.isFirstCrawl ? `${!isNaN(parseFloat(data?.averageCrawlSpeed?.match(/\d/g)?.join(''))) ? parseFloat(data?.averageCrawlSpeed?.match(/\d/g)?.join('')) : ''} ` ?? '-' : data?.indexablePagesValues?.actual ?? '-'}</FirstValue>
                    <SecondValue>{!data?.isFirstCrawl && '/'}{(data?.isFirstCrawl && !isNaN(parseFloat(data?.averageCrawlSpeed?.match(/\d/g)?.join('')))) ? 'pages/s' : !data?.isFirstCrawl ? data?.indexablePagesValues?.total : '-'}</SecondValue></Metrics>
                </div>   {(data?.status == 'processing' && !data?.isFirstCrawl) ? <StyledProgress style={{marginTop: 7, width: 100, height: 6}} trailColor='#E8E8E8' strokeColor={(data?.indexablePagesValues?.actual == 0 && !data?.isFirstCrawl) ? '#E8E8E8' : data?.indexablePagesValues?.color}
                  // @ts-ignore
                  percent={(data?.indexablePagesValues?.actual == 0 && !data?.isFirstCrawl) ? 70 : getPercentage(data?.indexablePagesValues?.actual, data?.indexablePagesValues?.total)} showInfo={false} status={data?.status == 'processing' && 'active'} /> :
                  data?.isFirstCrawl == true ? '' : <Bar>
                    <InnerBar style={{background: `${data?.indexablePagesValues?.color}`, maxWidth: `${getPercentage(data?.indexablePagesValues?.actual, data?.indexablePagesValues?.total)}%`}}></InnerBar>
                  </Bar>}

              </TitleContainer>

            </div>
            <div className={styles.cardBox}>
              <TitleContainer>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <GraphTitle >{data?.isFirstCrawl ? 'Depth' : 'Core Web Vitals'}</GraphTitle>
                </div>
                <Metrics onClick={() => {
                  updateSelectedSiteSA(data?.id);
                  router.push(`/${routes.competitorResearcher.siteHealth}?webVitals=true`);
                }} style={{display: 'flex', marginTop: 6}}>
                  <div >{data?.isFirstCrawl ? <FirstValue>{data?.currentCrawlDepth ?? '-'}</FirstValue> : <> {!data?.coreWebVitalsValues?.hasData ? <NoData> No data </NoData> : <> <FirstValue>{data?.coreWebVitalsValues?.actual ?? '-'}</FirstValue><SecondValue>/{data?.coreWebVitalsValues?.total ?? '-'}</SecondValue> </>}</>}</div>
                </Metrics>
                {(data?.status == 'processing' && !data?.isFirstCrawl && data?.coreWebVitalsValues?.hasData) ? <StyledProgress style={{marginTop: 7, width: 100, height: 6}} trailColor='#E8E8E8' strokeColor={(data?.coreWebVitalsValues?.actual == 0 && !data?.isFirstCrawl) ? '#E8E8E8' : data?.coreWebVitalsValues?.color}
                  // @ts-ignore
                  percent={(data?.coreWebVitalsValues?.actual == 0 && !data?.isFirstCrawl) ? 70 : getPercentage(data?.coreWebVitalsValues?.actual, data?.coreWebVitalsValues?.total)} showInfo={false} status={data?.status == 'processing' && 'active'} /> :
                  data?.isFirstCrawl == true ? '' : <Bar>
                    <InnerBar style={{background: `${data?.coreWebVitalsValues?.color}`, maxWidth: `${getPercentage(data?.coreWebVitalsValues?.actual, data?.coreWebVitalsValues?.total)}%`}}></InnerBar>
                  </Bar>}

              </TitleContainer>

            </div>
          </div>
        </div>
      </CardWrapper>
      {showCrawlModal &&
        <CrawlSettingsModal
          showCrawlModal={showCrawlModal}
          setShowCrawlModal={setShowCrawlModal}
          data={data}
        />
      }
      { gscConnectModal &&
        <NewGscModal gscLoading={gscLoading} country={country} gscConnectModal={gscConnectModal} setGscConnectModal={setGscConnectModal} data={data} onCountryChange={onCountryChange} siteProperty={siteProperty} setSiteProperty={setSiteProperty} ottoPage={false} />
      }
      <DeleteConfirmModal
        isDeleting={isLoading}
        isVisible={showDeleteProjectModal}
        warningText={`Are you sure you want to delete?`}
        confirmButtonColor={'#ff6262'}
        onCancel={ () => {
          setShowDeleteProjectModal(false);
        }}
        onConfirm={ () => {
          handleArchive();
        }}
      />
    </>
  );
});

export default SiteCard;

export const getPercentage = (val1, val2) => {
  if (val1 && val2) {
    return val1 / val2 * 100;
  } else {
    return 0;
  }
};

const CardWrapper = styled.div`
  margin-bottom: 10px;
  height: auto;
  background: #F9F9FB;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
`;
const MainErrorMessage = styled.div`
  background: #f5eaea;
  border-radius: 4px;
  width: 103%;
  min-height: 40px;
  padding: 6px;
`;
const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #F44343;
`;
const TooltipMessage = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding-top: 6px;
  height: 30px;
  color: #FFFFFF;
  padding-left:10px;
  padding-right:19px;
`;

const TooltipEstiMateTime = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #E8E8E8;
  padding-right: 20px;
  padding-left:10px;
  padding-top: 15px;

`;

const Domain = styled.div`
  color: #2D6CCA;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  max-width: 165px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const LastCrawlMessage = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;

const Crawl = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;

const Processing = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;

// const ChatLink = styled.span`
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 15px;
//   color: #2D6CCA;
//   text-decoration: underline;
// `;

const RecrawlButtonFailed = styled(Button)`
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  height: 32px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px !important;
  margin-left: 0 !important;
`;
const Estimatetime = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;

const GSCShow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 18px;
  background: rgba(45, 108, 202, 0.1);
  border: 1px solid rgba(45, 108, 202, 0.5);
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #2D6CCA;
`;

const TitleContainer = styled.div`
  margin-bottom: 12px;
`;

const FirstValue = styled.span`
  margin-top: 6px;
  font-weight: 500; 
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

const SecondValue = styled.span`
  margin-top: 6px;  
  font-size: 14px;
  line-height: 17px;
  color: #A3A4A4;
`;

const Bar = styled.div`
  width: 100px;
  height: 6px;
  background: #E8E8E8;
  border-radius: 10px;
  margin-top: 16px;
`;

const ProcessingBar = styled.div`
  width: 170px;
  height: 6px;
  background: #E8E8E8;
  border-radius: 10px;
  margin-top: 26px;
`;

const TooltipProcessingBar = styled.div`
  width: 230px;
  height: 6px;
  background: #E8E8E8;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  
`;

const InnerBar = styled.div`
  height: 6px;
  border-radius: 3px;
  
`;

const GraphTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;

// const CenterContent = styled.p`
//   font-weight: 400;
//   font-size: 12px;
//   color: #4E5156;
//   text-overflow: ellipsis;
//   text-wrap: nowrap;
//   overflow: hidden;
// `;
const StyledProgress = styled(Progress)`
 .ant-progress-bg{
  height: 6px !important;

 }
`;

const StuckMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 191px;
  height: 30px;
  color: #4E5156;
`;

const ErrorWrapper = styled.div`
  padding-left: 10px;
  padding-bottom: 7px;
  padding-top: 7px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;

`;
const TooltipStuckMessagge = styled.div`
font-weight: 500;
font-size: 12px;
line-height: 15px;
width: 229px;
padding-top: 5px;
padding-left: 10px;
padding-right: 15px;
height: 45px;
color: #FFFFFF;
`;

const NoData = styled.span`
  margin-top: 6px;  
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  color: #A3A4A4;
`;

const Metrics = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledSelect = styled(Select)`
  @media screen and (max-width: 390px) {
    .ant-select-arrow {
      top: 26%;
    } 
  }
`;
