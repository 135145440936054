import {useEffect, useRef, useState} from 'react';
import {
  CommonInput,
} from '@/components/common-components/components';
import {
  Button, GrayButton,
} from '@/components/common-components/v2/Button';
import {observer} from 'mobx-react';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {H2Section} from './H2Section';
import styled from 'styled-components';
import {Checkbox, Input} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faXmark} from '@fortawesome/pro-solid-svg-icons';
import styles from '../style.module.scss';
import {Settings} from '../../settings';
import {Form, Spin, Tooltip} from 'antd';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {opaqueColor} from '@/utils/colors';
import {faStars} from '@fortawesome/pro-duotone-svg-icons';
import {PAGES_API} from '@/api/content-optimizer';
import {notification} from '@/utils/notification-v2';
import {convertToRaw} from 'draft-js';
// import { Questions } from './questions';

interface Props {
  pageId: any;
  selectedContentLength?: string;
  selectedToneOfVoice?: string;
  selectedLanguage?: string;
  selectedPointOfView?: string;
  tableCheck?: boolean;
  listCheck?: boolean;
  teaseCheck?: boolean;
  boostDettectionCheck?: boolean;
  addGlobalBackgroundCheck?: boolean;
  literaryDevicesCheck?: boolean;
  keyTakawaysCheck?: boolean;
  disableSkinnyParagraphCheck?: boolean;
  shortFaq?: boolean;
  longFaq?: boolean;
  customToneOfVoice?: string;
  shouldOverwriteContent?: boolean;
  conclusionCheck?: boolean;
  imagesCheck?: boolean;
  replaceTextInEditor?: any;
  globalBackgroundDesc?: string;
  setSelectedContentLength?: (value: string) => void;
  setSelectedToneOfVoice?: (value: string) => void;
  setSelectedPointOfView?: (value: string) => void;
  setSelectedLanguage?: (value: string) => void;
  setTableCheck?: (value: boolean) => void;
  setListCheck?: (value: boolean) => void;
  setImagesCheck?: (value: boolean) => void;
  setConclusionCheck?: (value: boolean) => void;
  setTeaseCheck?: (value: boolean) => void;
  setBoostDettectionCheck?: (value: boolean) => void;
  setAddGlobalBackgroundCheck?: (value: boolean) => void;
  setLiteraryDevicesCheck?: (value: boolean) => void;
  setKeyTakeawaysCheck?: (value: boolean) => void;
  setDisableSkinnyParagraphCheck?: (value: boolean) => void;
  setShowPageTitle?: (value: boolean) => void;
  setCustomToneOfVoice?: (value: string) => void;
  setShouldOverwriteContent?: (value: boolean) => void;
  setIsContentOverwritten?: (value: boolean) => void;
  setGlobalBackgroundDesc?: (value: string) => void;
}

export const MainSection = observer(({
  selectedContentLength,
  selectedToneOfVoice,
  selectedLanguage,
  selectedPointOfView,
  tableCheck,
  listCheck,
  teaseCheck,
  boostDettectionCheck,
  addGlobalBackgroundCheck,
  literaryDevicesCheck,
  keyTakawaysCheck,
  conclusionCheck,
  imagesCheck,
  disableSkinnyParagraphCheck,
  shouldOverwriteContent,
  globalBackgroundDesc,
  customToneOfVoice,
  replaceTextInEditor,
  setSelectedContentLength,
  setSelectedToneOfVoice,
  setSelectedLanguage,
  setSelectedPointOfView,
  setTableCheck,
  setListCheck,
  setTeaseCheck,
  setBoostDettectionCheck,
  setConclusionCheck,
  setImagesCheck,
  setAddGlobalBackgroundCheck,
  setLiteraryDevicesCheck,
  setKeyTakeawaysCheck,
  setDisableSkinnyParagraphCheck,
  setCustomToneOfVoice,
  setShouldOverwriteContent,
  setGlobalBackgroundDesc,
  setIsContentOverwritten,
}: Props) => {
  const {settings: {customer: {getCustomerQuota}}} = useStore('');
  const {
    contentOptimizer: {
      currentPage: {
        currentEditorState,
        analytics,
        content,
      }, aiOutline: {
        loadCompleteSeoArticle,
        setShowOutlinesInDrawer,
        loading,
        updateUserAiSettings,
        refetchOneClickDraftResult,
        getUserAiSettings,
      },
    },
  } = useStore('');

  const [showTermsToInclude, setShowTermsToInclude] = useState(true);
  const [form] = Form.useForm();
  const termsRef = useRef(null);

  const [usedTerms, setUsedTerms] = useState('');
  const [termsToInclude, setTermsToInclude] = useState<string[]>([]);
  const [longFaq, setLongFaq] = useState(false);
  const [shortFaq, setShortFaq] = useState(false);


  const [showError, setShowError] = useState(false);
  const [showMainHeadingMissingError, setShowMainHeadingMissingError] = useState(false);
  const [showFocusTopicMissingError, setShowFocusTopicMissingError] = useState(false);
  // const [totalH2Counter, setTotalH2Count] = useState(3);
  const [showRemainingTerms, setShowRemainingTerms] = useState(false);
  const [loadingAiHeadings, setLoadingAiHeadings] = useState(false);
  const [lastUsedToneOfVoice, setLastUsedToneOfVoice] = useState('');
  const [globalBackgroundError, setGlobalBackgroundError] = useState({
    message: 'Missing global background',
    error: false,
  });


  // chekcs if focus terms input is in focus or not
  const myFunction = () => {
    if (document.activeElement === termsRef?.current?.input) {
      if (!usedTerms || termsToInclude?.includes(usedTerms.trim())) return;
      const updatedTerms = [...(usedTerms?.split(',') ?? []), ...termsToInclude];
      setTermsToInclude(updatedTerms);
      setUsedTerms('');
      form.setFieldsValue({
        'termsToInclude': '',
      });
    }
  };

  // adds event hendler on enter key press
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();

        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [usedTerms]);
  // const [questionsState, setQuestionsState] = useState<any>({
  //   isEmpty: true,
  //   items: [{
  //     value: '',
  //     label: 'Question',
  //     placeholder: 'Question',
  //     fields: [

  //     ],
  //   },
  //   {
  //     value: '',
  //     label: 'Question',
  //     placeholder: 'Question',
  //     fields: [
  //     ],
  //   },
  //   {
  //     value: '',
  //     label: 'Question',
  //     placeholder: 'Question',
  //     fields: [
  //     ],
  //   },
  //   ],
  // });

  const [state, setState] = useState<any>({
    isEmpty: true,
    mainHeading: '',
    items: [{
      value: '',
      // label: 'Focus Topic (H2)',
      placeholder: 'H2',
      fields: [
        // {
        //   type: 'h3',
        //   label: 'H3',
        //   placeholder: 'Add a related question....',
        //   value: '',
        // },
      ],
    },
    {
      value: '',
      // label: 'Focus Topic (H2)',
      placeholder: 'H2',
      fields: [
      ],
    },
    {
      value: '',
      // label: 'Focus Topic (H2)',
      placeholder: 'H2',
      fields: [
      ],
    },
    ],
  });

  const isEmptyState = (currentInputValue?: string) => {
    let isEmpty = true;
    if (currentInputValue) {
      isEmpty = false;
      return false;
    }
    for (let i = 0; i < state?.items?.length; i++) {
      if (state.items[i].value) {
        isEmpty = false;
        return false;
      } else {
        for (let n = 0; n < state.items[i].fields.length; n++) {
          if (state.items[i].fields[n].value) {
            isEmpty = false;
            return false;
          }
        }
      }
    }

    return isEmpty;
  };

  const clearAllSettingsFields = () => {
    setSelectedContentLength('medium');
    setTableCheck(false);
    setListCheck(false);
    setTeaseCheck(false);
    setBoostDettectionCheck(false);
    setAddGlobalBackgroundCheck(false);
    setLiteraryDevicesCheck(false);
    setKeyTakeawaysCheck(false);
    setDisableSkinnyParagraphCheck(false);
    setCustomToneOfVoice('');
  };

  const resetFields = () => {
    getUserAiSettings();

    setState({
      isEmpty: true,
      mainHeading: '',
      items: [{
        value: '',
        // label: 'Focus Topic (H2)',
        placeholder: 'H2',
        fields: [],
      },
      {
        value: '',
        // label: 'Focus Topic (H2)',
        placeholder: 'H2',
        fields: [],
      },
      {
        value: '',
        // label: 'Focus Topic (H2)',
        placeholder: 'H2',
        fields: [],
      },
      ],
    });

    // setTotalH2Count(3);

    form?.setFieldsValue({
      title: '',
      termsToInclude: '',
    });

    setTermsToInclude([]);
    setUsedTerms('');

    clearAllSettingsFields();
  };


  const autoPopulateFieldsHandler = () => {
    const contentState = currentEditorState.getCurrentContent();
    let missingDataError = false;

    // checking if editor text is missing h1 tags
    if (!convertToRaw(contentState)?.blocks?.filter(block => block.text && (block.type === 'header-one'|| block.type === 'header-two'))?.length) {
      setShowMainHeadingMissingError(true);
      missingDataError = true;
    } else {
      setShowMainHeadingMissingError(false);
    }

    // checking if editor text is missing h2 tags
    if (!convertToRaw(contentState)?.blocks?.filter(block => block.text && (block.type === 'header-two' || block.type === 'header-one'))?.length) {
      setShowFocusTopicMissingError(true);
      missingDataError = true;
    } else {
      setShowFocusTopicMissingError(false);
    }

    if (missingDataError) return;

    const acceptableBlockTypes = ['header-one', 'header-two'];

    const fieldsMap = [];
    let mainHeading = '';

    // setting index of first h1 block, from where we will count all h2 until next h1
    let indexOfFirstHeading = convertToRaw(contentState)?.blocks?.findIndex(block => block.type === 'header-one' && block.text);
    if (indexOfFirstHeading === -1) indexOfFirstHeading = 0;

    // setting index of next h1 block, where we stop counting h2s
    let indexOfSecondHeading = convertToRaw(contentState)?.blocks?.length;
    for (let i = indexOfSecondHeading + 1; i < convertToRaw(contentState)?.blocks?.length; i++) {
      if (convertToRaw(contentState)?.blocks[i]?.type === 'header-one' && convertToRaw(contentState)?.blocks[i]?.text) {
        indexOfSecondHeading = i;
        break;
      }
    }

    // mapping first h1 block and all h2 blocks
    convertToRaw(contentState)?.blocks?.forEach((block, idx) => {
      if (acceptableBlockTypes.includes(block.type)) {
        if (idx >= indexOfFirstHeading && idx < indexOfSecondHeading) {
          if (block.type === 'header-one') {
            mainHeading = block.text;
          } else {
            fieldsMap.push(
              {
                value: block.text,
                label: 'H2',
                placeholder: 'H2',
                fields: [],
              },
            );
          }
        }
      }
    });

    // cleaning up missing h1 and h2 errors
    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }

    setState({
      mainHeading: mainHeading,
      items: fieldsMap,
    });
  };

  const antIcon = <LoadingOutlined style={{fontSize: 14, marginLeft: '10px', color: '#fff', marginTop: '5px'}} spin />;
  const antIconSmall = <LoadingOutlined style={{fontSize: 12, marginRight: '5px', color: '#fff', marginBottom: '4px'}} spin />;


  // Adds single field into single Focus Topic section
  const addNewField = (type: 'h3', index: number, value?: string) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[index]['fields'];

    fields.push({
      type: type,
      label: type === 'h3' ? 'H3' : '',
      placeholder: type === 'h3' ? 'H3' : '',
      value: value ? value : '',
    });

    items[index]['fields'] = fields;

    const newState = {
      ...curState,
      items: items,
    };

    setState(newState);
  };


  // Updates single field inside single Focus Topic section
  const updateSingleItemValue = (e, itemIndex: number, fieldIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];

    fields[fieldIndex].value = e.target.value;

    items[itemIndex]['fields'] = fields;

    const newState = {
      ...curState,
      fields: fields,
    };

    setState(newState);

    setState({
      ...newState,
      isEmpty: isEmptyState(e.target.value),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };


  // Removes single field from single Focus Topic section
  const removeSingleField = (itemIndex: number, fieldIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];

    fields.splice(fieldIndex, 1);

    items[itemIndex]['fields'] = fields;

    const newState = {
      ...curState,
      fields: fields,
    };

    setState(newState);
    setState({
      ...newState,
      isEmpty: isEmptyState(),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };

  // Updates value for single focus topic on field edit
  const updateFocusTopic = (e, index: number) => {
    const curState = {...state};
    const items = curState['items'];
    const topic = items[index];

    topic['value'] = e.target.value;

    items[index] = topic;

    setState({
      ...state,
      items: items,
    });
    setState({
      ...state,
      isEmpty: isEmptyState(e.target.value),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };


  // Generates new Focus Topic section
  const addNewTopicField = () => {
    const curState = {...state};
    const items = [...curState.items, {
      value: '',
      label: 'H2',
      placeholder: 'H2',
      fields: [],
    }];

    setState({
      ...state,
      items: items,
    });
  };
  // const updateTopicsCount = (total: number) => {
  //   if (!total) return;

  //   setTotalH2Count(total);

  //   const curState = {...state};
  //   let items = [...curState.items];

  //   if (total > items.length) {
  //     for (let i = 0; i <= total - items.length; i ++) {
  //       items.push({
  //         value: '',
  //         label: 'H2',
  //         placeholder: 'H2',
  //         fields: [],
  //       });
  //     }
  //   } else if (total < items.length) {
  //     items = items.slice(0, total);
  //   }


  //   setState({
  //     ...state,
  //     items: items,
  //   });
  // };

  // Removes single Focus Topic section
  const removeTopicHandler = (index: number) => {
    const curState = {...state};
    const items = [...curState.items];

    items.splice(index, 1);

    setState({
      ...state,
      items: items,
    });
  };


  // Reorders entire Focus Topic sections
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  // Trigers reorder after entire Focus Topic section drag finishes
  const onSectionDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index,
    );

    setState({
      ...state,
      items: items,
    });
  };

  // Updates state inside single Focus Topic section after reordering fields inside
  const onSingleFieldDragEnd = (index, newState) => {
    const curState = {...state};
    const items = curState['items'];
    items[index]['fields'] = newState;

    setState({
      ...state,
      items: items,
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    return ({
    // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '0px',
      margin: `0 0 0 0`,

      // styles we need to apply on draggables
      ...draggableStyle,
    });
  };


  // Series of validations to determen if submit should be disabled or not
  const disableButtonHandler = () => {
    // if main heading is missing
    if (!state.mainHeading) return true;
    if (loading) return true;


    // if there are less than 1 topics with value
    const topicValuesArr = state.items?.filter(item => item?.value);
    if (topicValuesArr.length < 1) return true;

    return false;
  };

  // Generates payload and fires API to generate ai outline
  const generateAiOutlineHandler = async () => {
    if (disableButtonHandler()) return;
    if (addGlobalBackgroundCheck && !globalBackgroundDesc) {
      setGlobalBackgroundError({...globalBackgroundError, error: true});
    } else if (addGlobalBackgroundCheck && globalBackgroundDesc.length > 5000) {
      setGlobalBackgroundError({...globalBackgroundError, message: `Global background char must be less then 5000`, error: true});
    }

    // olf validation, keep for now
    // if (state?.items?.filter(item => !item.value).length > 0) {
    //   setShowError(true);
    //   return;
    // }
    let showError = false;
    setGlobalBackgroundError({message: 'Missing global background', error: false});

    // state?.items?.map(item => {
    //   if (item?.fields?.filter(field => !field.value)?.length > 0) {
    //     showError = true;
    //   }
    // });
    // if (showError) {
    //   setShowError(true);
    //   return;
    // }

    // setShowError(false);

    const curState = {...state};
    const items = curState['items'];

    const formatItems = [];
    items?.map(item => {
      if (item.value) {
        formatItems.push({
          ...item,
          fields: item?.fields?.filter(field => field.value !== ''),
        });
      } else {
        if (item?.fields?.filter(field => field.value !== '')?.length > 0) {
          formatItems.push({
            ...item,
            fields: item?.fields?.filter(field => field.value !== ''),
          });
          showError = true;
        }
      }
    });


    setState({
      ...state,
      items: formatItems,
    });

    if (showError) {
      setShowError(true);
      return;
    }

    const prepPayload = {
      'title': state?.mainHeading as string,
      'headings': formatItems?.map(item => item.value),
      'use_table': tableCheck,
      'use_list': listCheck,
      // 'enable_literary_devices': literaryDevicesCheck,
      'tone_of_voice': selectedToneOfVoice == 'custom' ? customToneOfVoice ? customToneOfVoice : lastUsedToneOfVoice : selectedToneOfVoice,
      'point_of_view': selectedPointOfView,
      'use_global_background': addGlobalBackgroundCheck,
      ...(addGlobalBackgroundCheck && {'global_background': globalBackgroundDesc}),
      'disable_skinny_paragraph': disableSkinnyParagraphCheck,
      'use_tease': teaseCheck,
      'use_faq_short_answers': shortFaq,
      'use_faq_long_answers': longFaq,
      'enable_key_takeaways': keyTakawaysCheck,
      'length': selectedContentLength,
      'terms_to_include': termsToInclude,
      'disable_conclusion': conclusionCheck,
      'generate_images': imagesCheck,
    };

    try {
      setIsContentOverwritten(false);
      await updateUserAiSettings({
        language: selectedLanguage,
        toneOfVoice: selectedToneOfVoice == 'custom' ? customToneOfVoice ? customToneOfVoice : lastUsedToneOfVoice : selectedToneOfVoice,
        pointOfView: selectedPointOfView,
      });
      const response = await loadCompleteSeoArticle(prepPayload);
      if (shouldOverwriteContent) {
        const outlines = await refetchOneClickDraftResult(response.id);
        if (outlines?.results?.length) {
          let textToInject = '';
          const htmlTagRegex = /^<[^>]*>/;
          outlines?.results[0]?.split('\n\n').forEach((str, idx) => {
            if (idx == 0 && htmlTagRegex.test(str)) {
              textToInject += str;
            } else {
              textToInject += `<p>${str}</p>`;
            }
          });
          replaceTextInEditor(textToInject, 0, true);
        }
        setIsContentOverwritten(true);
      } else {
        await refetchOneClickDraftResult(response.id);
      }
      getCustomerQuota();
      setShowOutlinesInDrawer(true);
    } catch (e) {
      getCustomerQuota();
      setIsContentOverwritten(false);
      Promise.reject(e);
    }
  };

  const getListStyle = () => ({
    width: '100%',
  });

  const onTermRemove = (term: string) => {
    const updatedTerms = [...termsToInclude].filter(item => item !== term);
    setTermsToInclude(updatedTerms);
  };

  // const headingSuggestionsContent = (
  //   <>
  //     {showTitleEditor ?
  //       <div>
  //         <PopoverTitle> Provide an Article Title to get Main Heading suggestions </PopoverTitle>
  //         <InputStyled variant='light' placeholder='Article title...' value={articleTitle} onChange={e => setArticleTitle(e.target.value)}/>
  //         <Button color='blue'
  //           style={{marginTop: 10, marginLeft: 'auto'}}
  //           disabled={!articleTitle || headingSuggestionsLoading || isSaving}
  //           onClick={() => updateArticleTitleHandler()}>
  //                   Get Suggestions {headingSuggestionsLoading || isSaving ? <Spin indicator={antIcon} /> : null}
  //         </Button>
  //       </div> :
  //       <HeadingSuggestionsWrapper>
  //         <PopoverTitle>Select a Main Heading (H1) to continue:{headingSuggestionsLoading ? <Spin indicator={purpleIcon} /> : null}</PopoverTitle>
  //         {headingSuggestionsData}
  //         {currentH2Arr?.length ? <PopoverTitle>Select the Focus Topics (H2):</PopoverTitle> : null}
  //         {currentH2Arr?.length ? headingSuggestionsH2Data() : null}
  //         <Button color='blue'
  //           style={{marginTop: 10, marginLeft: 'auto'}}
  //           disabled={!selectedH1}
  //           onClick={() => onHeadingSuggestionsSubmit()}>
  //                   Populate Form
  //         </Button>
  //       </HeadingSuggestionsWrapper>
  //     }
  //   </>
  // );


  // const handleTooltipOpenChange= (open: boolean) => {
  //   if (!open) {
  //     setCurrentH2Arr([]);
  //     setSelectedH1('');
  //     setSelectedH2Arr([]);
  //   }
  // };


  // const checkPageTitle = (title: string) => {
  //   if (title) {
  //     const regexp = new RegExp('^New Document');
  //     return regexp.test(title);
  //   } else {
  //     return false;
  //   }
  // };

  const onImportTermsFromArticleClick = () => {
    const termsFromArticle = analytics?.focusTerms?.map(term => term.name);

    setTermsToInclude([...(termsFromArticle ?? []), ...(termsToInclude ?? [])]);
    setShowRemainingTerms(false);
  };

  const getAllHeadings = (): string[] => {
    const curState = {...state};
    const items = curState['items'];

    const formatItems = [];
    items?.map(item => {
      if (item.value) {
        formatItems.push({
          ...item,
          fields: item?.fields?.filter(field => field.value !== ''),
        });
      }
    });

    return formatItems?.map(item => item.value);
  };

  const autoPopulateH2Handler = (h2List?: string[]) => {
    if (!h2List) return;
    const items = [];

    for (let i = 0; i < h2List.length; i ++) {
      items.push({
        value: h2List[i],
        label: 'H2',
        placeholder: 'H2',
        fields: [],
      });
    }

    setState({
      ...state,
      items: items,
    });
  };

  const handleAiGenerateHeadings = async () => {
    if (!state?.mainHeading) return;
    setLoadingAiHeadings(true);
    try {
      const resp = await PAGES_API.generateAiHeadings(content.uuid, state.mainHeading, 6);
      autoPopulateH2Handler(resp.h2S);
      getCustomerQuota();
    } catch (e) {
      notification.error('Could not generate headings.', '');
    } finally {
      setLoadingAiHeadings(false);
    }
  };

  return (
    <div style={{display: 'flex'}}>

      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>


        <Wrapper >
          <DragDropContext onDragEnd={onSectionDragEnd}>
            <TopSectionWrapper>
              {/* <div><FontAwesomeIcon icon={faGripDotsVertical} style={{marginRight: 10}}/></div> */}
              <div style={{width: '100%', marginBottom: 25}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{fontSize: 12, color: '#4E5156'}}>Main heading</div>
                  <ImportHeadingsFromArticle onClick={() => autoPopulateFieldsHandler()}>Import headings from Article</ImportHeadingsFromArticle>
                </div>

                <Input
                  type='text'
                  variant='light'
                  prefix={'H1'}
                  // suffix={ <input type='number' value={totalH2Counter} className={styles.numberInput} defaultValue={5} min={1} max={20} pattern='[0-9]' style={{width: '50px', marginBottom: 0}} onChange={e => updateTopicsCount(+e.target?.value)}/>}
                  value={state.mainHeading}
                  onChange={e => {
                    if (showMainHeadingMissingError) {
                      setShowMainHeadingMissingError(false);
                    }
                    if (showFocusTopicMissingError) {
                      setShowFocusTopicMissingError(false);
                    }
                    setState({...state, mainHeading: e.target.value, isEmpty: isEmptyState(e.target.value)});
                  }}
                  className={'customLargeInput'} />

                {/* {(showError && state.mainHeading === '') && <ErrorMsg>Main Heading is required</ErrorMsg>} */}
              </div>
            </TopSectionWrapper>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5}}>
              <div style={{fontSize: 12, color: '#4E5156'}}>Sub headings</div>
              <Tooltip title={!state?.mainHeading ? 'Provide a Main Heading (H1) to AI Populate Sub Headings (H2s)' : null}>
                <AiPopulateBtn onClick={() => handleAiGenerateHeadings()} disabled={loadingAiHeadings} disabledStyle={!state?.mainHeading}>
                  {loadingAiHeadings ? <Spin indicator={antIconSmall} /> : <FontAwesomeIcon icon={faStars} style={{marginRight: 5, marginBottom: 4}}/>}
                  AI Populate
                </AiPopulateBtn>
              </Tooltip>

            </div>

            <Droppable droppableId='droppable'>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle()}
                >
                  {state.items.map((item, index) => (
                    <Draggable key={index} draggableId={`${item.label}-${index}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <H2Section
                            totalItems={state?.items?.length || 0}
                            totalTerms={item?.fields?.filter(item => item?.type == 'term').length}
                            totalQuestions={item?.fields?.filter(item => item?.type == 'question').length}
                            onSingleFieldDragEnd={onSingleFieldDragEnd}
                            updateItemTopic={updateFocusTopic}
                            currentItemState={item}
                            addField={addNewField}
                            updateItemValue={updateSingleItemValue}
                            removeExistingField={removeSingleField}
                            removeTopic={removeTopicHandler}
                            index={index}
                            showError={showError}/>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

          </DragDropContext>
          <AddMoreTopics onClick={() => addNewTopicField()}>+ Add new H2</AddMoreTopics>
        </Wrapper>
        {/* <Questions setState={setQuestionsState} state={questionsState}/> */}
        {showTermsToInclude ? <div className={styles.termsToIncludeContainer}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p style={{marginBottom: 0, color: '#4E5156', marginTop: 0, fontSize: 12}}>{`Terms to Include`}</p>
            <p
              style={{marginBottom: 0, color: '#13408B', marginLeft: 'auto', marginRight: 10, cursor: 'pointer', fontSize: 12}}
              onClick={() => onImportTermsFromArticleClick()}>
                Import from Article
            </p>
            <p style={{color: '#A3A4A4', marginTop: 0, marginBottom: 0, textDecoration: 'underline', cursor: 'pointer', fontSize: 12}} onClick={() => {
              setShowTermsToInclude(false);
              setTermsToInclude([]);
              setShowRemainingTerms(false);
              form.setFieldsValue({
                termsToInclude: '',
              });
            }}>
                Cancel
            </p>
          </div>
          <div style={{background: '#fff', borderRadius: 8, marginBottom: 20, border: '1px solid #E8E8E8'}}>
            <CommonInputStyled
              border={false}
              inputRef={termsRef}
              type='text'
              variant='white'
              value={usedTerms}
              placeholder='Enter term(s) or question(s), separated by commas, and hit enter'
              onChange={e => setUsedTerms(e.target.value)}
              className={styles.input} />
            <AddedTermsContainer>
              {termsToInclude?.slice(0, 8).map((item, idx) => {
                return <SingleAddedTerm key={idx}>{item}
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
                    onClick={() => onTermRemove(item)}
                  />
                </SingleAddedTerm>;
              })}
              {(termsToInclude?.length > 9 && !showRemainingTerms) && <SingleAddedTerm style={{cursor: 'pointer'}} onClick={() => setShowRemainingTerms(true)}>
                {`+${termsToInclude.length - 9} releated terms`}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
                />
              </SingleAddedTerm>}
              {showRemainingTerms && termsToInclude?.slice(9).map((item, idx) => {
                return <SingleAddedTerm key={idx}>{item}
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
                    onClick={() => onTermRemove(item)}
                  />
                </SingleAddedTerm>;
              })}
              {termsToInclude?.length > 0 && <SingleAddedTerm style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => {
                setTermsToInclude([]);
                setShowRemainingTerms(false);
                form.setFieldsValue({
                  termsToInclude: '',
                });
              }}>
                Clear All
              </SingleAddedTerm>}
            </AddedTermsContainer>
          </div>


        </div> : <div style={{cursor: 'pointer', color: '#2D6CCA', marginTop: 10, marginBottom: 20}} onClick={() => setShowTermsToInclude(true)}>+ Add terms to include</div>}

        <Settings
          passedTitle={state?.mainHeading}
          passedHeadings={getAllHeadings()}
          title='Awaken the True AI Potential with Custom Settings'
          tableCheck={tableCheck}
          setTableCheck={setTableCheck}
          listCheck={listCheck}
          setListCheck={setListCheck}
          teaseCheck={teaseCheck}
          setTeaseCheck={setTeaseCheck}
          conclusionCheck={conclusionCheck}
          imagesCheck={imagesCheck}
          setConclusionCheck={setConclusionCheck}
          setImagesCheck={setImagesCheck}
          boostDettectionCheck={boostDettectionCheck}
          setBoostDettectionCheck={setBoostDettectionCheck}
          addGlobalBackgroundCheck={addGlobalBackgroundCheck}
          setAddGlobalBackgroundCheck={setAddGlobalBackgroundCheck}
          literaryDevicesCheck={literaryDevicesCheck}
          setLiteraryDevicesCheck={setLiteraryDevicesCheck}
          keyTakawaysCheck={keyTakawaysCheck}
          setKeyTakeawaysCheck={setKeyTakeawaysCheck}
          disableSkinnyParagraphCheck={disableSkinnyParagraphCheck}
          setDisableSkinnyParagraphCheck={setDisableSkinnyParagraphCheck}
          selectedToneOfVoice={selectedToneOfVoice}
          setSelectedToneOfVoice={setSelectedToneOfVoice}
          selectedPointOfView={selectedPointOfView}
          setSelectedPointOfView={setSelectedPointOfView}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedContentLength={selectedContentLength}
          setSelectedContentLength={setSelectedContentLength}
          longFaq={longFaq}
          setLongFaq={setLongFaq}
          shortFaq={shortFaq}
          setShortFaq={setShortFaq}
          settingsArray={[
            'tone-of-voice',
            'point-of-view',
            'use_table',
            'use_list',
            'use_tease',
            'boost-dettection-chance',
            'use_global_background',
            // 'enable_literary_devices',
            'key-takeaways',
            'disable_skinny_paragraph',
            'provide-details',
            'h2-section-length',
            'use_faq',
            'number-of-pieces',
            'language',
            'disable_conclusion',
          ]}
          customToneOfVoice={customToneOfVoice}
          setCustomToneOfVoice={setCustomToneOfVoice}
          setLastUsedToneOfVoice={setLastUsedToneOfVoice}
          globalBackgroundDesc={globalBackgroundDesc}
          setGlobalBackgroundDesc={setGlobalBackgroundDesc}
          globalBackgroundError={globalBackgroundError}
          setGlobalBackgroundError={setGlobalBackgroundError}
        />
        <CheckboxStyled checked={shouldOverwriteContent} onChange={e => setShouldOverwriteContent(e.target.checked)}>Replace current article with AI Generated Content</CheckboxStyled>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25, marginBottom: 25}}>
          <Button
            onClick={() => generateAiOutlineHandler()}
            disabled={disableButtonHandler()}
            style={{width: '200px', display: 'flex', justifyContent: 'center'}}>
            {`Generate AI Content`}
            {/* {`Generate ${state.items?.length} ${state?.items?.length == 0 || state?.items?.length > 1 ? 'Outlines' : 'Outline'}`} */}
            {loading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon style={{marginLeft: 10}} icon={faArrowRight} />}
          </Button>

          <GrayButton color='transparent' variant='noBorder' style={{marginLeft: 0}} onClick={() => resetFields()}>
            <FontAwesomeIcon style={{marginRight: 10}} icon={faXmark} />
          Clear inputs
          </GrayButton>
        </div>
      </div>
    </div>
  );
});

const Wrapper = styled.div`
  // padding: 18px 30px;

`;

const TopSectionWrapper = styled.div`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 38px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
    .ant-input-suffix {
      padding: 7px 0;
    }
  }
`;
// const ErrorMsg = styled.div`
//   font-size: 10px;
//   color: #ff6262;
// `;
const ImportHeadingsFromArticle = styled.div`
  font-size: 12px;
  color: #2D6CCA;
  cursor: pointer;
`;

const CommonInputStyled = styled(CommonInput)<{border?: boolean; boxShadow?: boolean}>`
  color: #121212 !important;
  border-radius: 6px !important;
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  ${p => !p.border && 'border: 1px solid transparent !important'};
  padding-right: 8px !important;

  &:focus {
    box-shadow: none !important;
  }
 
`;
const AddedTermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`;
const SingleAddedTerm = styled.div`
  background-color: #F9F9FB;
  padding: 3px 10px;
  border-radius: 38px;
  border: 1px solid  #e8e8e8;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
`;
const CheckboxStyled = styled(Checkbox)`
  margin-left: 0px !important;
  margin-top: 10px !important;
  font-size: 12px;

  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

const AiPopulateBtn = styled(Button) <{disabledStyle?: boolean}>`
    border-radius: 4px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    background-color: ${p => p.disabledStyle ? opaqueColor('#243F68', 12) : opaqueColor('#243F68', 15)};
    font-size: 12px;
    color: #243F68;
    cursor: pointer;

    &:hover {
      background-color: ${p => p.disabledStyle ? `${opaqueColor('#243F68', 12)} !important` : '#243F68 !important'};
    }
    &:focus {
      background-color: ${p => p.disabledStyle ? `${opaqueColor('#243F68', 12)} !important` : '#243F68 !important'};
    }

    &:disabled {
      background-color: ${opaqueColor('#243F68', 12)};
      border-color: ${opaqueColor('#243F68', 15)};
    }
`;
const AddMoreTopics = styled.div`
    color: #2D6CCA;
    font-size: 12px;
    cursor: pointer;
`;
