import {Button, Form, Input, Modal} from 'antd';
import styled from 'styled-components';

export const AddEmailModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
  }
  .cross-icon {
    width: 19.01px;
    height: 20px;
    position: absolute;
    right: -29px;
    top: 0;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  color: #121212;
`;

export const AddEmailActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  background: #FFFFFF !important;
  border-radius: 10px;
  border: 1px solid #D2D2D2 !important;
  box-shadow: 0px 4px 8px 0px #0000000D !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.3px;
  text-align: left;
  color: #121212 !important;
  .left-sec {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const Img = styled.img`
  width: 18px;
  height: 18px;
`;

export const Actions = styled.div`
  margin-top: 23px;
`;

export const BackButton = styled.button`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-bottom: 5px;
  svg {
    width: 12.25px;
    height: 14px;
    margin-right: 7.75px;
  }
`;

export const ConfigrationTitle = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #121212;
  margin-bottom: 8px;
  margin-top: 17px;
`;

export const ConfigrationCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #4E5156;
  }
`;

export const CommonInput = styled(Input)<{ BorderTopRadius: boolean; borderBottomRadius: boolean }>`
  width: 350px;
  height: 38px;
  ${p => p.BorderTopRadius ? `
    border-radius: 8px 8px 0px 0px;
  ` : ''}
  ${p => p.borderBottomRadius && !p.BorderTopRadius ? `
    border-radius: 0px 0px 8px 8px;
  ` : ''}
  border: 0px !important;
  box-shadow: none !important;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  border: 0px;
  .ant-form-item-explain-error {
    font-size: 12px;
    margin-left: 5px;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    min-height: 20px;
  }
`;

export const FieldsContainer = styled.div<{ BorderTopRadius: boolean; borderBottomRadius: boolean }>`
  ${p => p.BorderTopRadius ? `
      border-radius: 8px 8px 0px 0px;
      border-top: 1px solid #E8E8E8 !important;
    ` : ''}
    ${p => p.borderBottomRadius && !p.BorderTopRadius ? `
      border-radius: 0px 0px 8px 8px;
    ` : ''}
    border: 1px solid #E8E8E8 !important;
    border-top: ${p => p.BorderTopRadius ? '1px solid #E8E8E8' : '0px'} !important;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 21px;
  margin-top: 56px;
  .cancel-button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }
`;

export const ConnectButton = styled(Button)`
  width: 155px;
  height: 38px;
  border-radius: 6px;
  background: #2D6CCA !important;
  color: #FFFFFF !important;
  border-color: #2D6CCA !important;
`;
