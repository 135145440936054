import {types, Instance, flow, cast} from 'mobx-state-tree';
import {reportsApi} from '@/api/gsc/index';
import {CriteriaType} from '@/store//gsc-store/criteria';
import {randomColors} from '@/utils/colors';
import {filterDataInMemory} from '@/utils/filter-data';
import {toJS} from 'mobx';
import {getSingleUrlParam} from '@/utils/url';


export enum TABS {
  impCur = 'impCur',
  clicksCur = 'clicksCur',
  cpcCur = 'cpcCur',
}

type ChartDataType = {
  value: number;
  name: string;
  itemStyle: {
    color: string;
  };
};


export const PageGroupStat = types.model({
  id: types.maybeNull(types.number),
  name: types.string,
  pages: types.optional(types.array(types.string), []),
  topKeywords: types.optional(types.array(types.string), []),
  keywordsCount: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  checked: types.maybeNull(types.boolean), // if true, row will be rendered in PageGroup chart
  color: types.maybeNull(types.string), // unique color for this row
});

export const FilteredDataModel = types.model({
  id: types.maybeNull(types.number),
  name: types.string,
  pages: types.optional(types.array(types.string), []),
  topKeywords: types.optional(types.array(types.string), []),
  keywordsCount: types.maybeNull(types.number),
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  checked: types.maybeNull(types.boolean), // if true, row will be rendered in PageGroup chart
  color: types.maybeNull(types.string), // unique color for this row
});

const FiltersModel = types.model({
  from: types.maybeNull(types.number),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.number),
});

export type PageGroupStatType = Instance<typeof PageGroupStat>;

export const PageGroupingStatsStore = types.model({
  id: types.maybeNull(types.number),
  pageGroupingStats: types.optional(types.array(PageGroupStat), []),
  filterKeywordTerm: types.optional(types.string, ''),
  filterKeywordColumns: types.optional(types.array(types.string), []),
  filterList: types.optional(types.array(FiltersModel), []),
  filterTableDataSize: types.maybeNull(types.number),
  sortField: types.optional(types.string, ''),
  sortDirection: types.optional(types.string, ''),
  loading: types.boolean,
  selectedTab: types.enumeration('TABS', Object.values(TABS)),
  filteredRenderData: types.optional(types.array(FilteredDataModel), []),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  isLoading: types.boolean,
  pageGroupingsTableSize: types.maybeNull(types.number),
}).views(self => ({
  get filteredPageGroupings() {
    return self.filterKeywordTerm ? toJS(self.filteredRenderData).filter(pageGrouping => pageGrouping?.name.toLowerCase().includes(self.filterKeywordTerm .toLowerCase())) : toJS(self.filteredRenderData);
  },
  piechartData(plan): ChartDataType[] {
    const chartData: ChartDataType[] = [];
    toJS(self.pageGroupingStats)?.filter(group => group.checked).map(group => {
      if (!plan && chartData.length == 3) return;
      chartData.push({value: group[self.selectedTab], name: group.name, itemStyle: {color: group.color}});
    });
    return chartData;
  },
  get getPageGroupingStats() {
    return toJS(self.pageGroupingStats);
  },
})).actions(self => {
  const reLoadPageGroupings = flow(function* (criteria: CriteriaType) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loading = true;
    const response: PageGroupStatType[] = yield reportsApi.getPageGroupCustomerPerformance(criteria, publicHash);
    const data = Array.isArray(response) ? response : [];
    const dataWithColors = data?.map( (group, index) => {
      group.checked = true;
      group.color = randomColors[index];
      return group;
    });
    self.pageGroupingStats.length = 0;
    self.pageGroupingStats = cast(dataWithColors);
    self.pageGroupingsTableSize = self.pageGroupingStats.length;
    self.loading = false;
  });
  const updatePageGroupings = flow(function* (criteria: CriteriaType) {
    const response: PageGroupStatType[] = yield reportsApi.getPageGroupCustomerPerformance(criteria);
    const data = Array.isArray(response) ? response : [];
    const checkedPageGroupingData = [...self.pageGroupingStats];
    const dataWithColors = data?.map( (group, index) => {
      checkedPageGroupingData?.find(item => item.id == group.id ? item.checked ? group.checked = item.checked : 'false' : '');
      group.color = randomColors[index];
      return group;
    });
    self.pageGroupingStats = cast(dataWithColors);
  });

  const handleFilterRenderData = (filterKeywordTerm, filters, pageNumber: number, pageSize: number, sortField, sortDirection) => {
    self.isLoading = true;
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.pageGroupingStats, self.filterKeywordTerm, self.filterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection);
    self.filterTableDataSize = dataFilteredSize;
    self.filteredRenderData.length = 0;
    const newArray = dataFiltered.map(item => {
      return {
        id: item.id,
        name: item.name,
        pages: item.pages.length > 0 ? [...item.pages] : [],
        topKeywords: item.topKeywords.length > 0 ? [...item.topKeywords] : [],
        keywordsCount: item.keywordsCount,
        clicksCur: item.clicksCur,
        clicksPrev: item.clicksPrev,
        impCur: item.impCur,
        impPrev: item.impPrev,
        posCur: item.posCur,
        cpcCur: item.cpcCur,
        cpcPrev: item.cpcPrev,
        checked: item.checked,
        color: item.color,
      };
    });
    self.filteredRenderData = cast(newArray);
    self.isLoading = false;
  };

  const onPaginationChange = async (pageNumber: number, pageSize: number) => {
    self.pageSize = pageSize;
    self.pageNumber = pageNumber;
    handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };

  // const toggleGroup checked
  const toggleGroupChecked = (groupName: string) => {
    self.pageGroupingStats.forEach( group => {
      if (group.name === groupName) {
        group.checked = !group.checked;
      }
    });
    handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortField} = column || {};
    self.sortField = sortField;
    self.sortDirection = order;
    handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };

  const setFilterKeywordTerm = (keywordTerm: string) => {
    self.filterKeywordTerm = keywordTerm;
  };

  const setSelectedTab = (tab: TABS) => {
    self.selectedTab = tab;
  };


  return {
    handleTableChange,
    reLoadPageGroupings,
    updatePageGroupings,
    setFilterKeywordTerm,
    setSelectedTab,
    toggleGroupChecked,
    onPaginationChange,
    handleFilterRenderData,
  };
});

export type PageGroupingStatsStoreType = Instance<typeof PageGroupingStatsStore>;

export function initPageGroupingStatsStore() {
  return PageGroupingStatsStore.create(
    {
      loading: true,
      sortField: '',
      sortDirection: 'descend',
      filterList: [],
      pageGroupingStats: [],
      filterKeywordTerm: '',
      selectedTab: TABS.impCur,
      filteredRenderData: [],
      pageSize: 7,
      pageNumber: 1,
      isLoading: true,
      pageGroupingsTableSize: 0,
    },
  );
}
