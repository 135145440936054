import {Dispatch, SetStateAction} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {Input} from '@/components/common-components/v2';

interface Props {
  removeTopic: Dispatch<SetStateAction <number>>;
  index: number;
  currentItemState: {
    value: string;
    label: string;
    placeholder: string;
    fields: [
      {
        type: string;
        label: string;
        placeholder: string;
        value: string;
      }
    ];
  };
  addField: (type: 'h3', index: number, value?: string) => void;
  removeExistingField: (itemIndex: number, fieldIndex: number) => void;
  updateItemValue: (e, itemIndex: number, fieldIndex: number) => void;
  updateItemTopic: (e, itemIndex: number) => void;
  onSingleFieldDragEnd: (index: number, newState) => void;
  totalItems: number;
  totalTerms: number;
  totalQuestions: number;
  showError: boolean;
}

export const H2Section = ({
  removeTopic,
  index,
  currentItemState,
  // addField,
  removeExistingField,
  updateItemValue,
  updateItemTopic,
  onSingleFieldDragEnd,
  totalItems,
  showError,
}: Props) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newState = reorder(
      currentItemState.fields,
      result.source.index,
      result.destination.index,
    );

    onSingleFieldDragEnd(index, newState);
  };


  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: '5px 10px',
    margin: `0`,
    // background: '#fff',

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = () => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: '5px 0',
    width: '100%',
  });

  return <Wrapper>
    <DragDropContext onDragEnd={onDragEnd}>
      <TopSectionWrapper>
        {/* <div><FontAwesomeIcon icon={faGripDotsVertical} style={{marginRight: 10}}/></div> */}
        <div style={{width: '100%'}}>
          {/* <div style={{fontSize: 12}}>Focus Topic (H2)</div> */}
          <Input
            type='text'
            variant='light'
            prefix={currentItemState.placeholder}
            suffix={ <div style={{display: 'flex'}}>
              <AddMoreItemsContainer>
                {/* <AddMoreTermsButton onClick={() => addField('h3', index)}>+ Add H3</AddMoreTermsButton> */}
              </AddMoreItemsContainer>
              {totalItems > 1 && <div className='trash-icon' onClick={() => removeTopic(index)}><FontAwesomeIcon icon={faTrash}/></div>}
            </div>}
            value={currentItemState.value}
            onChange={e => updateItemTopic(e, index)}
            className={'customLargeInput'} />
          {(showError && currentItemState.value === '' && currentItemState.fields?.filter(field => field.value)?.length > 0) && <ErrorMsg>This field is required.</ErrorMsg>}

        </div>
        {/* Show remove icon only if there is more than 1 topic */}

      </TopSectionWrapper>
      <Droppable droppableId='droppable'>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}>
            {currentItemState.fields.map((item, idx) => (
              <Draggable key={idx} draggableId={`${item.type}-${idx}`} index={idx}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}>
                    <SingleFieldWrapper>
                      <div>
                        <Input
                          type='text'
                          variant='light'
                          prefix={'H3'}
                          suffix={ totalItems > 1 && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>}
                          value={item.value}
                          onChange={e => updateItemValue(e, index, idx)}
                          className={'customLargeInput'} />
                        {/* <CommonInput
                          type='text'
                          variant='transparent'
                          placeholder={item.placeholder}
                          value={item.value}
                          onChange={e => updateItemValue(e, index, idx)}
                          className={'customSmallInput'} /> */}
                        {/* {(showError && item.value === '') && <ErrorMsg>This field can not be empty</ErrorMsg>} */}

                      </div>
                      {/* Show remove button only if there are at least 2 fields of same type */}
                      {/* {(totalTerms > 1 && item.type == 'term') && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>}
                          {(totalQuestions > 1 && item.type == 'question') && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>} */}
                    </SingleFieldWrapper>

                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>

  </Wrapper>;
};

const Wrapper = styled.div`
  // padding: 13px 16px;
  // background-color: #FAFAFA;
  // border: 1px solid #E8E8E8;
  // box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  // border-radius: 8px;
`;
const TopSectionWrapper = styled.div`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 38px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
  }

  .trash-icon {
    color: #a3a4a4;

    &:hover {
      color: #ff6262;
    }
  }

`;
const AddMoreItemsContainer = styled.div`
  display: flex;
`;

const SingleFieldWrapper = styled.div`
  padding: 5px 0 5px 10px;

  .trash-icon {
    color: #a3a4a4;

    &:hover {
      color: #ff6262;
    }
  }

  .customLargeInput {
    height: 38px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
  }
`;

const ErrorMsg = styled.div`
  font-size: 10px;
  color: #ff6262;
`;
