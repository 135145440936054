import {Button} from '@/components/common-components/v2';
import {faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal} from 'antd';
import {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void | Promise<void>;
  loading?: boolean;
}

const DisengageConfirmModal = ({visible, setVisible, loading, onConfirm}: Props) => {
  const {settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');

  return (
    <StyledModal
      onCancel={() => setVisible(false)}
      visible={visible}
      centered
      footer={null}
    >
      <CloseIcon onClick={() => setVisible(false)}>
        <FontAwesomeIcon width={20} height={20} icon={faXmark} />
      </CloseIcon>
      <div>
        <div style={{height: 34}}><CautionIcon icon={faTriangleExclamation} color='#FF8536' /></div>
        <div style={{display: 'flex', flexDirection: 'column', gap: 8, marginTop: 16, marginBottom: 31}}>
          <p className='heading'>{`Are you sure you want to Disengage ${whitelabelOtto}?`}</p>
          <p className='desc'>{`Disengaging ${whitelabelOtto} will remove all changes that are currently implemented.`}</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: 3}}>
          <StyledButton disabled={loading} loading={loading} onClick={onConfirm}>{`Yes, Disengage ${whitelabelOtto}`}</StyledButton>
          <StyledButton isLight bgColor='transparent' txtColor='#4E5156' onClick={() => !loading && setVisible(false)}>{`No, keep ${whitelabelOtto} Engaged`}</StyledButton>
        </div>
      </div>
    </StyledModal>
  );
};

export default DisengageConfirmModal;

const StyledModal = styled(Modal)`
  margin-top: auto;
  margin-bottom: auto;
  min-height: 240px;
  max-height: 280px !important;
  width: 85vw !important;
  max-width: 680px !important;
  p {
    margin-bottom: 0 !important;
    color: #121212;
  }
  .rc-dialog-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 30px 27px !important;
    min-height: 240px;
    max-height: 280px !important;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  .heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;

const StyledButton = styled(Button)<{bgColor?: string; txtColor?: string; isLight?: boolean; loading?: boolean}>`
  max-width: ${p => p?.loading || p?.isLight ? '' : '100%'};
  min-width: 177px;
  height: 44px;
  margin: 0 !important;
  border-radius: 8px;
  padding: 13px 30px !important;
  background-color: ${props => props?.bgColor || '#FF8536'} !important;
  color: ${props => props?.txtColor || '#fff'} !important;
  border-color: transparent !important;
  font-size: 14px;
  font-weight: ${p => p?.isLight ? '400' : '600'};
  line-height: ${p => p?.isLight ? '22px' : '16.94px'};

  &:hover, &:focus {
    background: ${p => p?.bgColor || '#FF8536'} !important;
    color: ${props => props?.txtColor || '#fff'} !important;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: -31px;
  top: 0;
  svg {
    height: 20px !important;
    color: #ffff;
    cursor: pointer;
  }
`;

const CautionIcon = styled(FontAwesomeIcon)`
  width: 34px !important;
  height: 34px !important;
`;
