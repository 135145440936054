import React, {useState, useEffect} from 'react';
import styles from '../style.module.scss';
import {useTranslation} from 'next-i18next';
import {Checkbox} from 'antd';
import {ApplyBtnWrapper, ApplyButton, CheckboxGropContainer, CustomRangeContainer, InputStyled} from '../styledComponents';

interface Props {
  filterId?: number;
  categories?: any[];
  options: any;
  filterLabel?: string;
  filterName?: string;
  onChange?: (filter:any)=>void;
  className?: string;
  filterField?: string;
  customFields?: any[];
  isDisable?:boolean;
  isClear?: boolean;
  onClear?: (data: any)=>void;
  absoluteUi?: boolean;
  allowSingleBox?: boolean;
  selectBetween?: boolean;
  isPageExplorer?: boolean;
  checkedValues?: any[];
  queryVal?: string;
}

const FilterKeywordPopUpCheckboxes: React.FC<Props> = ({queryVal, categories, checkedValues, allowSingleBox=false, selectBetween=false, options, filterField, filterId, customFields, filterLabel, filterName, onChange, isPageExplorer}) => {
  const selectedFilters = categories || [];
  const [filtersSelected, setFiltersSelected] = useState(checkedValues);
  const [query, setQuery] = useState('');
  const [checked, setChecked] = useState(false);
  const {t} = useTranslation('common');
  useEffect(() => {
    setFiltersSelected(checkedValues);
  }, [checkedValues?.length]);
  useEffect(() => {
    if (queryVal) {
      setQuery(queryVal);
    } else {
      setQuery('');
    }
  }, [queryVal]);

  const createOrUpdateFilter = e =>{
    e.preventDefault();
    emitOnChanges(filtersSelected, query);
    if (!checkedValues) {
      setFiltersSelected([]);
    }
  };
  useEffect(() => {
    if (selectedFilters?.length ===0) {
      setFiltersSelected(['']);
    }
  }, [selectedFilters?.length]);
  const emitOnChanges = (operator: any, query: string) => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        filterFields: operator,
        query: query,
        filterField: filterField,
        filterTypes: options,
        active: true,
        customFields: customFields,
        checked: checked,
        isUrl: filterLabel == 'url' && isPageExplorer ? true : false,
      });
    }
  };

  const disablingButton = () =>{
    if (query=='') {
      return true;
    } else {
      return false;
    }
  };

  const checkBoxChange = () => {
    setChecked(!checked);
  };
  function checkedOnChange(checkedValues) {
    if (allowSingleBox) {
      const checkPrev = filtersSelected?.find(item => filtersSelected?.length ? item == filtersSelected[0] : false);
      setFiltersSelected(checkedValues?.length ? checkedValues?.filter(z => z !== checkPrev) : []);
    } else if (selectBetween) {
      const firstIndex = categories?.findIndex(z => z?.value == checkedValues[0]);
      const lastIndex = categories?.findIndex(z => z?.value == checkedValues[checkedValues?.length - 1]);
      if (firstIndex != -1 && lastIndex != -1) {
        setFiltersSelected(categories?.slice(firstIndex, lastIndex+1)?.map(z => z?.value));
      } else {
        setFiltersSelected(checkedValues);
      }
    } else {
      setFiltersSelected(checkedValues);
    }
  }
  return (
    <CustomRangeContainer>
      {isPageExplorer && (filterLabel == 'title' || filterLabel == 'meta_description' ) ?<>
        <Checkbox checked={checked} onChange={checkBoxChange}>Case Insensitive Filtering </Checkbox>
        <hr color=' #E8E8E8'/></>: ''}
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <div className={styles.filterPopupOptionsOther}>
          <InputStyled
            value={query}
            onChange={e=>setQuery(e.target.value)}
          />
        </div>
      </div>
      <div style={{color: '#a3a4a4', fontSize: '12px', fontWeight: 600}}>SEARCH IN FIELDS:</div>
      <CheckboxGropContainer style={{marginTop: '-4px'}}>
        <Checkbox.Group className={styles.checkboxesStyle} options={options} value={filtersSelected} onChange={checkedOnChange} />
      </CheckboxGropContainer>
      <ApplyBtnWrapper>
        <ApplyButton
          disabled={disablingButton()}
          onClick={createOrUpdateFilter}
        >
          {t('apply-filter')}
        </ApplyButton>
      </ApplyBtnWrapper>
    </CustomRangeContainer>
  );
};

export default FilterKeywordPopUpCheckboxes;
