export const inboxTableFilter = (campaign:any=[]) => [
  {id: 1, name: 'exclude', header: 'From', filterField: 'search', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Contains', operator: 'contains'}]},
  {id: 4, name: 'radioButtonsFilter', header: 'Campaign', customCategoryType: 'campaign', filterTypes: campaign, type: undefined, active: false},
  {id: 5, name: 'radioButtonsFilter', header: 'Status', customCategoryType: 'status', filterTypes: [
    {label: 'Emailed', value: 'emailed'},
    {label: 'Replied', value: 'replied'},
  ], type: undefined, active: false},
  {id: 6, name: 'exclude', header: 'Subject', filterField: 'subject', text: '', type: undefined, active: false, customFields: [{label: 'Contains', operator: 'contains'}]},
  {id: 7, name: 'radioButtonsFilter', header: 'Milestone', customCategoryType: 'milestone', filterTypes: [
    {label: 'Idle', value: 'idle'},
    {label: 'Got a reply', value: 'got_a_reply'},
    {label: 'Negotiation', value: 'negotiation'},
    {label: 'Lost', value: 'lost'},
    {label: 'Won', value: 'won'},
  ], type: undefined, active: false},
];
