import React from 'react';
import {CancelButton, FooterContainer, UpdateButton} from './styles';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import FreezeWrapper from '@/components/dashboard/pages/otto-page-detail/Component/freezTooltip';
import {useRouter} from 'next/router';

const Footer = ({isEdit, onClose, setVisible, setIsEdit}) => {
  const {contentOptimizer: {pages: {loadingSettingsUpdate, createProjectLoading}}} = useStore('');
  const router = useRouter();

  return (
    <div>
      <FooterContainer>
        <CancelButton onClick={e => {
          e.preventDefault();
          setVisible(false);
          setIsEdit(false);
          onClose();
        }}>Cancel</CancelButton>
        <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
          <UpdateButton loading={loadingSettingsUpdate || createProjectLoading} htmlType='submit'>{isEdit ? 'Update settings' : 'Create settings'}</UpdateButton>
        </FreezeWrapper>
      </FooterContainer>
    </div>
  );
};

export default observer(Footer);
