import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '@/store/root-store';
import {faCircle, faSortDown, faArrowUpRightFromSquare as arrowRightSolid, faArrowUp, faSearch, faListCheck, faCheck, faPause} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import {Modal} from '@/components/common-components/components';
import {openUrl} from '@/utils/router';
import {Dropdown, Menu, Progress, Tooltip} from 'antd';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {StyledSpin} from '../../../../Utils/styledSpin';
import styled from 'styled-components';
import {CitationTable} from '@/components/dashboard/pages/local-seo-scan/local-citations/CitationBuilderTable';
import moment from 'moment';
import {FreezeBanner} from '../../../ottoHeaderV2';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';


interface GenerateGbpPostsModalProps {
  openCitationModal: boolean;
  setOpenCitationModal: (value: boolean) => void;
  openedBusinessInModal: any;
}

export const ViewCitationModal: React.FC<GenerateGbpPostsModalProps> = observer(({openCitationModal, setOpenCitationModal, openedBusinessInModal}) => {
  const {localSeoScan: {citationBusinessDetail, gettingCitationBusinessDetail}, ottoV2Store: {getOttoV2Project}} = useStore('');
  const [citationsData, setCitationsData] = useState([]);

  useEffect(() => {
    if (!gettingCitationBusinessDetail && citationBusinessDetail?.length) {
      setCitationsData(citationBusinessDetail || []);
    }
  }, [JSON.stringify(citationBusinessDetail)]);

  const citationColumns = [
    {
      title: 'AGGREGATOR / NETWORK',
      dataIndex: 'aggregator',
      key: 'aggregator',
      width: '200px',
      color: '#17181A',
      render: (_, record) => {
        return <div className='website'>{record?.citation}</div>;
      },
    },
    {
      title: 'WEBSITE',
      dataIndex: 'website',
      key: 'website',
      width: '130px',
      color: '#17181A',
      render: (_, record) => {
        return <div className='domain-authority'>{record?.website}</div>;
      },
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      color: '#17181A',
      render: (_, record) => {
        const date = moment(record?.date)?.isValid() ? moment(record?.date)?.format('MMM DD, YYYY') : '-';
        return <div className='domain-authority'>{date}</div>;
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      color: '#17181A',
      render: (_, record) => {
        return <div className={`status-tag ${record?.status !== 'Live' ? 'gray' : 'green'}`}>
          <FontAwesomeIcon icon={faCircle} color={record?.status === 'Live' ? '#1FAC47' : '#A3A4A4'} fontSize={8} /> {record?.status}
        </div>;
      },
    },
    {
      title: 'NOTES',
      dataIndex: 'notes',
      key: 'notes',
      color: '#17181A',
      render: (_, record) => {
        return (record?.notes || record?.note) ? <div className='notes'>Show
          <Dropdown
            trigger={['click']}
            placement='bottomRight'
            overlay={
              <Menu style={{width: '400px', padding: '10px', borderRadius: '6px', overflow: 'hidden'}}
              >{record?.notes || record?.note}</Menu>
            }>
            <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSortDown} color='#121212' fontSize={12} />
          </Dropdown>
        </div> : <div style={{textAlign: 'left'}}>-</div>;
      },
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'citation_url',
      color: '#17181A',
      render: (_, record) => {
        return record?.url ? <div className='citation-url' onClick={() => openUrl(record?.url, '_blank')}><FontAwesomeIcon icon={arrowRightSolid} color='#2D6CCA' fontSize={16} />View</div> : <div style={{textAlign: 'left'}}>-</div>;
      },
    },
  ];
  const extraStatusProgressColors = {
    'Submitting To Sites': '#F1AA3E',
    'Saved': '#F1AA3E',
    'On Hold': '#F44343',
  };

  const timeLineArr = [
    {
      status: 'Submitted',
      days: 'Days 1-7',
      icon: faArrowUp,
      completelyFilled: ['Completed', 'Complete', 'Live Listing Checks', 'Paid', 'On Hold', 'Finished'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Submitting To Sites', 'Saved'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: `Order has been paid and submitted for verification ${moment(openedBusinessInModal?.createdAt).isValid ? 'on ' + moment(openedBusinessInModal?.createdAt).format('MMM DD, YYYY') : ''}.`,
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
    {
      status: 'Quality Assurance',
      days: 'Days 8-14',
      icon: faSearch,
      completelyFilled: ['Completed', 'Complete', 'Live Listing Checks', 'On Hold', 'Finished'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Paid'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: 'Your submission has been verified successfully. We are now initiating the Quality Assurance process and performing a Live listing verification.',
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
    {
      status: 'Live Checks',
      days: 'Days 22-37',
      icon: faListCheck,
      completelyFilled: ['Finished', 'On Hold'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Live Listing Checks', 'Completed', 'Complete'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: 'Your submission has successfully passed the Quality Assurance check. We are now focusing on the final phase of live listing verification.',
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
    {
      status: ['On Hold'].includes(openedBusinessInModal?.campaignStatus) ? 'On Hold' : 'Completed',
      days: 'Day 42',
      icon: ['On Hold'].includes(openedBusinessInModal?.campaignStatus) ? faPause : faCheck,
      completelyFilled: ['Finished', 'On Hold'].includes(openedBusinessInModal?.campaignStatus),
      partiallyFilled: ['Finished', 'On Hold'].includes(openedBusinessInModal?.campaignStatus),
      tooltipText: ['On Hold'].includes(openedBusinessInModal?.campaignStatus) ? 'Your order has been placed on hold. Contact us for additional inquires.' : 'The process is now complete. We guarantee that 70% of your listings are live. If any listings are missing, they should go live within the next few days or weeks.',
      progressColor: extraStatusProgressColors[openedBusinessInModal?.campaignStatus] || '#2AC155',
    },
  ];

  return (
    <StyledModal
      width={'850px'}
      height={'91vh'}
      visible={openCitationModal}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark} /></div>}
      onClose={() => setOpenCitationModal(false)}>
      {getOttoV2Project?.isFrozen ? <FreezeBanner>
        <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
        <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
        </div>
      </FreezeBanner> : ''}
      <div className='wrapper'>
        <div className='header'>
          <div className='heading'>Data Aggregator Submissions</div>
          <div style={{display: 'flex', alignItems: 'center', gap: 6, marginBottom: 52}}>
            {timeLineArr?.map((item, idx) => (
              <TimelineBlock key={item?.status} isLast={idx !== timeLineArr?.length -1}>
                <div style={{borderRadius: 9999, background: item?.partiallyFilled || item?.completelyFilled ? item?.progressColor : '#A3A4A4', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 28, minHeight: 28, position: 'relative'}}>
                  <FontAwesomeIcon icon={item?.icon} color='#fff' fontSize={14} />
                  <div style={{position: 'absolute', left: 0, bottom: openedBusinessInModal?.campaignStatus === 'On Hold' ? -30 : -50, whiteSpace: 'nowrap'}}>
                    <Tooltip placement='bottom' overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 10}} title={item?.partiallyFilled || item?.completelyFilled ? item?.tooltipText : ''}>
                      <div>
                        <div style={{color: '#000', lineHeight: '20px'}}>{item?.status}</div>
                        {openedBusinessInModal?.campaignStatus === 'On Hold' ? '' : <div style={{color: '#4E5156', fontSize: 12}}>{item?.days}</div>}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <Progress percent={item?.completelyFilled ? 100 : item?.partiallyFilled ? 50 : 0} strokeColor={item?.progressColor} trailColor='rgba(163, 164, 164, 0.2)' type='line' />
              </TimelineBlock>
            ))}
          </div>
          <div className='description'>
            {gettingCitationBusinessDetail ?
              <StyledSpin fontSize={14} color={'#2D6CCA'} /> :
              citationsData?.filter(citation => citation?.status !== 'Omitted')?.length} of {gettingCitationBusinessDetail ?
              <StyledSpin fontSize={14} color={'#2D6CCA'} /> :
              citationsData?.length} citations found for <Domain onClick={() => openUrl(openedBusinessInModal?.websiteAddress, '_blank')}>{openedBusinessInModal?.businessName}</Domain>
          </div>
        </div>
        <div className='body-content'>
          <CitationTable
            dataSource={citationsData}
            columns={citationColumns}
            loading={gettingCitationBusinessDetail}
            loadingRows={10}
            pagination={false}
            scroll={{y: '63vh'}}
          />
        </div>
      </div>
    </StyledModal>
  );
});

const TimelineBlock = styled.div<{isLast?: boolean}>`
  display: flex;
  gap: 6px;
  align-items: center;
  ${p => p?.isLast ? 'flex-grow: 1;' : 'min-width: 60px;'}
  .ant-progress-text {
    display: none;
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
  .ant-progress-inner {
    border-radius: 0px !important;
    ${p => p?.isLast ? '' : `
      border-top-right-radius: 100px !important;
      border-bottom-right-radius: 100px !important;
    `}
  }
  .ant-progress-bg {
    height: 10px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

const StyledModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
      .wrapper {
        .header {
          margin-bottom: 20px;
          .heading {
            font-family: 'Inter', sans-serif;
            font-size: 24px;
            font-weight: 600;
            color: #121212;
          }
          .description {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: #4E5156;
          }
        }
      }
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.70 !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const Domain = styled.span`
  color: #2D6CCA;
  cursor: pointer;
`;
