import {Modal} from '@/components/common-components';
import {Button, GrayButton} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {faMoneyCheckDollar} from '@fortawesome/pro-duotone-svg-icons';
import {faLockKeyhole} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider} from 'antd';
import {observer} from 'mobx-react';
import styled from 'styled-components';

export const ScaTopUpQuotaModal = observer(() => {
  const {
    settings: {
      customer: {
        setShowScaTopUpModal,
        showTopUpConfirmationModal,
        getCustomerQuota,
        profile: {
          topUpAiQuota,
          topUpAiQuotaLoading,
        },
      },
    },
  } = useStore('');


  return <Modal
    style={{width: 320}}
    visible={showTopUpConfirmationModal}
    onClose={() => setShowScaTopUpModal(false)}>
    <FontAwesomeIcon icon={faMoneyCheckDollar} style={{color: '#1FAC47', fontSize: 35, marginBottom: 15}}/>
    <Title>Purchase 100 AI Quota</Title>
    <SubTitle>For $25 you’ll receive 100 AI Quota Points that can be used in all AI Tools</SubTitle>
    <Divider />
    <BtnContainer>
      <GrayButton
        variant='noBorder'
        style={{marginLeft: 0}}
        onClick={() => setShowScaTopUpModal(false)}>
                    Cancel
      </GrayButton>
      <Button
        color='green'
        disabled={topUpAiQuotaLoading}
        style={{display: 'flex', paddingLeft: 45, paddingRight: 45}}
        onClick={async () => {
          await topUpAiQuota();
          getCustomerQuota();
          setShowScaTopUpModal(false);
        }}>
        <FontAwesomeIcon icon={faLockKeyhole} style={{marginRight: 5, color: '#fff'}} />
                Pay $25
      </Button>

    </BtnContainer>

  </Modal>;
});

const BtnContainer = styled.div`
  display: flex;
`;
const Title = styled.div`
  color: #121212;
  font-size: 20px;
  font-weight: 700;
`;
const SubTitle = styled.div`
  color: #121212;
`;
