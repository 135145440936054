import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {CriteriaType} from '@/store/gsc-store/criteria';

class GscReportsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');

  public async getLowCtrByQueryDrawer({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, page) {
    try {
      const response = await this.axios.get(`${GscReportsApi.baseUrl}/keyword-historical-performance/`, {
        params: {
          property: property,
          period_start: currentPeriodStart,
          period_end: currentPeriodEnd,
          period1_start: previousPeriodStart,
          period1_end: previousPeriodEnd,
          period2_start: currentPeriodStart,
          period2_end: currentPeriodEnd,
          country_code: countryCode,
          keyword: page,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response?.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }


  public async getLowCtrByPageDrawer({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, page) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/page-historical-performance/`, {
      params: {
        property: property,
        period_start: currentPeriodStart,
        period_end: currentPeriodEnd,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page: page,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getWinnerLoserPagesReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/winner-losers-pages/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        // keyword: keyword,
        // page_size: pageSize,
        page_number: pageNumber,
        // keywords_report_type: keywordsReportType,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getNewPagesReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/new-pages/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getNewKeywordsReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/position-keyword-changes/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        keywords_report_type: 'new',
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getContentThinReport({property, countryCode}: CriteriaType) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/thin-content/`, {
      params: {
        property: property,
        country_code: countryCode,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getLongTailKeywordsReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/long-tail-keywords/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getStrikingKeywordsReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/striking-distance-keywords/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getAllKeywordsReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/keywords/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getAllPagesReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/pages/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getLowCtrPageReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/ctr-opportunities-by-page/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getWinnerQueriesDataReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/winner-losers-keywords/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        keywords_report_type: 'new',
        page_number: pageNumber,

      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getLowCtrQueryReport({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/ctr-opportunities/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getkeywordMetaReport({property, countryCode}: CriteriaType) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/top-queries-missing/`, {
      params: {
        property: property,
        country_code: countryCode,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getKeywordCannibalizationReport({property, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageSize, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/keyword-cannibalization/`, {
      params: {
        selected_property: property,
        period_start: currentPeriodStart,
        period_end: currentPeriodEnd,
        country_code: countryCode,
        page_size: pageSize,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getKeywordQuestions({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/keyword-questions/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getTopQueryPerPage({property, countryCode, pageNumber, pageSize}: CriteriaType) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/top-query-per-page/`, {
      params: {
        property: property,
        country_code: countryCode,
        page_number: pageNumber,
        page_size: pageSize,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async getTopQueryPerPageDrawer({property, countryCode}: CriteriaType, pageUrl) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/top-query-per-page/`, {
      params: {
        property: property,
        country_code: countryCode,
        page_url: pageUrl,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getSearchIntent({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, countryCode}: CriteriaType, pageNumber, searchIntent?:string) {
    const response = await this.axios.get(`${GscReportsApi.baseUrl}/search-intent/`, {
      params: {
        selected_property: property,
        period1_start: previousPeriodStart,
        period1_end: previousPeriodEnd,
        period2_start: currentPeriodStart,
        period2_end: currentPeriodEnd,
        country_code: countryCode,
        search_intent: searchIntent,
        page_number: pageNumber,
      },
      headers: {
        Accept: 'application/json',
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
}


export default GscReportsApi;
