import {useEffect, useState} from 'react';
import {Popover, Spin, Radio, RadioChangeEvent, Tooltip} from 'antd';
import {
  CommonInput,
} from '@/components/common-components/components';
import {
  Button,
  GrayButton,
} from '@/components/common-components/v2/Button';
import {observer} from 'mobx-react';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {TopicSection} from './TopicSection';
import styled from 'styled-components';
import {convertToRaw} from 'draft-js';
import {Input} from '@/components/common-components/v2';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {Checkbox} from '@/components/common-components/v2';
import {toJS} from 'mobx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import styles from '../style.module.scss';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';
import {isNil} from 'lodash';


export const MainSection = observer(() => {
  const {settings: {customer: {getCustomerQuota}}} = useStore('');
  const {
    contentOptimizer: {
      currentPage: {
        setIsExternalTitleEdit,
        currentEditorState,
        editTitle,
        isSaving,
        content: {
          uuid,
          title,
        },
      }, aiOutline: {
        loadAiOutlineV2,
        getHeadingSuggestions,
        headingSuggestionsLoading,
        setShowOutlinesInDrawer,
        loading,
        headingSuggestions,
        loadFirstDraftHistory,
        loadFirstDraftHistoryStandalone,
        aiTemplatesController,
        loadAiOutlineV2Standalone,
      },
    },
  } = useStore('');


  const [showError, setShowError] = useState(false);
  const [showMainHeadingMissingError, setShowMainHeadingMissingError] = useState(false);
  const [showFocusTopicMissingError, setShowFocusTopicMissingError] = useState(false);
  const [showTitleEditor, setShowTitleEditor] = useState(false);
  const [currentH2Arr, setCurrentH2Arr] = useState([]);
  const [selectedH1, setSelectedH1] = useState<string>('');
  const [selectedH2Arr, setSelectedH2Arr] = useState<string[]>([]);
  const [resultsCounter, setResultsCounter] = useState(1);
  const [showFocustopicsLimitErr, setShowFocusTopicsLimitErr] = useState<boolean>(false);

  useEffect(() => {
    if (title) setArticleTitle(title);
  }, [title]);
  const [articleTitle, setArticleTitle] = useState<string>('');

  const [state, setState] = useState<any>({
    isEmpty: true,
    mainHeading: '',
    items: [{
      value: '',
      label: 'Focus Topic (H2)',
      placeholder: 'Add a Focus Topic....',
      fields: [
        {
          type: 'question',
          label: 'Question',
          placeholder: 'Add a related question....',
          value: '',
        },
        {
          type: 'term',
          label: 'Topical Term',
          placeholder: 'Add a related focus term....',
          value: '',
        },
      ],
    },
    {
      value: '',
      label: 'Focus Topic (H2)',
      placeholder: 'Add a Focus Topic....',
      fields: [
        {
          type: 'question',
          label: 'Question',
          placeholder: 'Add a related question....',
          value: '',
        },
        {
          type: 'term',
          label: 'Topical Term',
          placeholder: 'Add a related focus term....',
          value: '',
        },
      ],
    }],
  });

  const isEmptyState = (currentInputValue?: string) => {
    let isEmpty = true;
    if (currentInputValue) {
      isEmpty = false;
      return false;
    }
    for (let i = 0; i < state?.items?.length; i++) {
      if (state.items[i].value) {
        isEmpty = false;
        return false;
      } else {
        for (let n = 0; n < state.items[i].fields.length; n++) {
          if (state.items[i].fields[n].value) {
            isEmpty = false;
            return false;
          }
        }
      }
    }

    return isEmpty;
  };

  useEffect(() => {
    if (!aiTemplatesController?.defaultForm) return;

    // methods for standalone tool and regular tool are different
    // in regular editor drawer we send page uuid, not the case with stanalone tool
    if (aiTemplatesController?.isStandaloneTool) loadFirstDraftHistoryStandalone();

    if (!aiTemplatesController?.isStandaloneTool) loadFirstDraftHistory(uuid);
  }, []);

  const resetFields = () => {
    setState({
      isEmpty: true,
      mainHeading: '',
      items: [{
        value: '',
        label: 'Focus Topic (H2)',
        placeholder: 'Add a Focus Topic....',
        fields: [
          {
            type: 'question',
            label: 'Question',
            placeholder: 'Add a related question....',
            value: '',
          },
          {
            type: 'term',
            label: 'Topical Term',
            placeholder: 'Add a related focus term....',
            value: '',
          },
        ],
      },
      {
        value: '',
        label: 'Focus Topic (H2)',
        placeholder: 'Add a Focus Topic....',
        fields: [
          {
            type: 'question',
            label: 'Question',
            placeholder: 'Add a related question....',
            value: '',
          },
          {
            type: 'term',
            label: 'Topical Term',
            placeholder: 'Add a related focus term....',
            value: '',
          },
        ],
      }],
    });

    setResultsCounter(1);
  };

  const autoPopulateFieldsHandler = () => {
    const contentState = currentEditorState.getCurrentContent();
    let missingDataError = false;

    // checking if editor text is missing h1 tags
    if (!convertToRaw(contentState)?.blocks?.filter(block => block.text && (block.type === 'header-one'))?.length) {
      setShowMainHeadingMissingError(true);
      missingDataError = true;
    } else {
      setShowMainHeadingMissingError(false);
    }

    // checking if editor text is missing h2 tags
    if (!convertToRaw(contentState)?.blocks?.filter(block => block.text && (block.type === 'header-two'))?.length) {
      setShowFocusTopicMissingError(true);
      missingDataError = true;
    } else {
      setShowFocusTopicMissingError(false);
    }

    if (missingDataError) return;

    const acceptableBlockTypes = ['header-one', 'header-two'];

    const fieldsMap = [];
    let mainHeading = '';

    // setting index of first h1 block, from where we will count all h2 until next h1
    let indexOfFirstHeading = convertToRaw(contentState)?.blocks?.findIndex(block => block.type === 'header-one' && block.text);
    if (indexOfFirstHeading === -1) indexOfFirstHeading = 0;

    // setting index of next h1 block, where we stop counting h2s
    let indexOfSecondHeading = convertToRaw(contentState)?.blocks?.length;
    for (let i = indexOfSecondHeading + 1; i < convertToRaw(contentState)?.blocks?.length; i++) {
      if (convertToRaw(contentState)?.blocks[i]?.type === 'header-one' && convertToRaw(contentState)?.blocks[i]?.text) {
        indexOfSecondHeading = i;
        break;
      }
    }

    // mapping first h1 block and all h2 blocks
    convertToRaw(contentState)?.blocks?.forEach((block, idx) => {
      if (acceptableBlockTypes.includes(block.type)) {
        if (idx >= indexOfFirstHeading && idx < indexOfSecondHeading) {
          if (block.type === 'header-one') {
            mainHeading = block.text;
          } else {
            if (fieldsMap?.length >= 10) return;
            fieldsMap.push(
              {
                value: block.text,
                label: 'Focus Topic (H2)',
                placeholder: 'Add a Focus Topic....',
                fields: [
                  {
                    type: 'question',
                    label: 'Question',
                    placeholder: 'Add a related question....',
                    value: '',
                  },
                  {
                    type: 'term',
                    label: 'Topical Term',
                    placeholder: 'Add a related focus term....',
                    value: '',
                  },
                ],
              },
            );
          }
        }
      }
    });

    // cleaning up missing h1 and h2 errors
    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }

    setState({
      mainHeading: mainHeading,
      items: fieldsMap,
    });
  };

  const antIcon = <LoadingOutlined style={{fontSize: 14, marginLeft: '10px', color: '#fff', marginTop: '5px'}} spin />;
  const purpleIcon = <LoadingOutlined style={{fontSize: 14, marginLeft: 'auto', color: '#7f4ead', marginTop: '5px'}} spin />;


  // Adds single field into single Focus Topic section
  const addNewField = (type: 'question' | 'term', index: number, value?: string) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[index]['fields'];

    fields.push({
      type: type === 'question' ? 'question' : 'term',
      label: type === 'question' ? 'Question' : 'Topical Term',
      placeholder: `Add a related ${ type === 'question' ? 'question' : 'focus term'}....`,
      value: value ? value : '',
    });

    items[index]['fields'] = fields;

    const newState = {
      ...curState,
      items: items,
    };

    setState(newState);
  };


  // Updates single field inside single Focus Topic section
  const updateSingleItemValue = (e, itemIndex: number, fieldIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];

    fields[fieldIndex].value = e.target.value;

    items[itemIndex]['fields'] = fields;

    const newState = {
      ...curState,
      fields: fields,
    };

    setState(newState);

    setState({
      ...newState,
      isEmpty: isEmptyState(e.target.value),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };


  // Removes single field from single Focus Topic section
  const removeSingleField = (itemIndex: number, fieldIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];

    fields.splice(fieldIndex, 1);

    items[itemIndex]['fields'] = fields;

    const newState = {
      ...curState,
      fields: fields,
    };

    setState(newState);
    setState({
      ...newState,
      isEmpty: isEmptyState(),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };

  // Updates value for single focus topic on field edit
  const updateFocusTopic = (e, index: number) => {
    const curState = {...state};
    const items = curState['items'];
    const topic = items[index];

    topic['value'] = e.target.value;

    items[index] = topic;

    setState({
      ...state,
      items: items,
    });
    setState({
      ...state,
      isEmpty: isEmptyState(e.target.value),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };


  // Generates new Focus Topic section
  const addNewTopic = () => {
    const curState = {...state};
    const items = [...curState.items];

    if (items?.length >= 10) return;


    items.push({
      value: '',
      label: 'Main Heading (H1)',
      placeholder: 'Add a Focus Topic....',
      fields: [
        {
          type: 'question',
          label: 'Question',
          placeholder: 'Add a related question....',
          value: '',
        },
        {
          type: 'term',
          label: 'Topical Term',
          placeholder: 'Add a related focus term....',
          value: '',
        },
      ],
    });

    setState({
      ...state,
      items: items,
    });
  };

  // Removes single Focus Topic section
  const removeTopicHandler = (index: number) => {
    const curState = {...state};
    const items = [...curState.items];

    items.splice(index, 1);

    setState({
      ...state,
      items: items,
    });
  };


  // Reorders entire Focus Topic sections
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  // Trigers reorder after entire Focus Topic section drag finishes
  const onSectionDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index,
    );

    setState({
      ...state,
      items: items,
    });
  };

  // Updates state inside single Focus Topic section after reordering fields inside
  const onSingleFieldDragEnd = (index, newState) => {
    const curState = {...state};
    const items = curState['items'];
    items[index]['fields'] = newState;

    setState({
      ...state,
      items: items,
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    return ({
    // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '8px 0',
      margin: `0 0 0 0`,

      // styles we need to apply on draggables
      ...draggableStyle,
    });
  };


  // Series of validations to determen if submit should be disabled or not
  const disableButtonHandler = () => {
    // if main heading is missing
    if (!state.mainHeading) return true;


    // if there are less than 1 topics with value
    const topicValuesArr = state.items?.filter(item => item?.value);
    if (topicValuesArr.length < 1) return true;

    // if no empty field exists
    // let isFalse = false;
    // state.items?.map(item => {
    //   if (item?.fields?.filter(field => !field.value).length > 0) {
    //     isFalse = true;
    //   }
    // });

    // if (isFalse) return true;

    // if any of the topics with value is missing at least 1 question and 1 topical term
    // let isFalse = false;
    // topicValuesArr?.forEach(item => {
    //   if (item?.fields?.filter(field => field.type == 'term' && field.value)?.length < 1) isFalse = true;
    //   if (item?.fields?.filter(field => field.type == 'question' && field.value)?.length < 1) isFalse = true;
    // })

    // if (isFalse) return true;

    return false;
  };

  // Generates payload and fires API to generate ai outline
  const generateAiOutlineHandler = async () => {
    if (disableButtonHandler()) return;

    // olf validation, keep for now
    // if (state?.items?.filter(item => !item.value).length > 0) {
    //   setShowError(true);
    //   return;
    // }
    let showError = false;

    // state?.items?.map(item => {
    //   if (item?.fields?.filter(field => !field.value)?.length > 0) {
    //     showError = true;
    //   }
    // });
    // if (showError) {
    //   setShowError(true);
    //   return;
    // }

    // setShowError(false);

    const curState = {...state};
    const items = curState['items'];

    const formatItems = [];
    items?.map(item => {
      if (item.value) {
        formatItems.push({
          ...item,
          fields: item?.fields?.filter(field => field.value !== ''),
        });
      } else {
        if (item?.fields?.filter(field => field.value !== '')?.length > 0) {
          formatItems.push({
            ...item,
            fields: item?.fields?.filter(field => field.value !== ''),
          });
          showError = true;
        }
      }
    });


    setState({
      ...state,
      items: formatItems,
    });

    if (showError) {
      setShowError(true);
      return;
    }

    const prepPayload = {
      'h1': state?.mainHeading,
      'should_reprocess': true,
      ...(resultsCounter && {'num_of_results': resultsCounter}),
      'content': formatItems?.map(item => {
        return {
          'h2': item.value,
          'questions': item?.fields?.filter(field => field.type === 'question')?.map(question => question.value),
          'focus_terms': item?.fields?.filter(field => field.type === 'term')?.map(term => term.value),
        };
      }),
    };

    try {
      if (!aiTemplatesController?.defaultForm) return;

      // methods for standalone tool and regular tool are different
      // in regular editor drawer we send page uuid, not the case with stanalone tool
      if (aiTemplatesController?.isStandaloneTool) {
        await loadAiOutlineV2Standalone(prepPayload);
        loadFirstDraftHistoryStandalone();
      }

      if (!aiTemplatesController?.isStandaloneTool) {
        await loadAiOutlineV2(prepPayload, uuid);
        loadFirstDraftHistory(uuid);
      }


      getCustomerQuota();
      setShowOutlinesInDrawer(true);
    } catch (e) {
      getCustomerQuota();
      Promise.reject(e);
    }
  };

  const getListStyle = () => ({
    width: '100%',
  });

  const usedTerms = [];
  state?.items?.forEach(item => {
    item.fields?.filter(field => field.type === 'term')?.forEach(field => usedTerms.push(field.value));
  });
  const usedQuestions = [];
  state?.items?.forEach(item => {
    item.fields?.filter(field => field.type === 'question')?.forEach(field => usedQuestions.push(field.value));
  });


  const generateHeadingSuggestionsHandler = async () => {
    try {
      await getHeadingSuggestions(uuid);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  // if article title is a generic one 'New Document 123' then set input article title as empty string and open new article title modal
  const checkForPageTitleHandler = async () => {
    const nameRegex = /^\d+$/;
    const titleCheck = title.split('New Document');
    if (titleCheck[1] && nameRegex.test(titleCheck[1].trim())) {
      setArticleTitle('');
      setShowTitleEditor(true);
    } else {
      setShowTitleEditor(false);
      generateHeadingSuggestionsHandler();
    }
  };

  // if article title is a generic one 'New Document 123' then set input article title as empty string and open new article title modal
  const updateArticleTitleHandler = async () => {
    if (!articleTitle) return;

    try {
      await editTitle(articleTitle);
      await getHeadingSuggestions(uuid);
      setIsExternalTitleEdit(true);
      setShowTitleEditor(false);
    } catch (e) {
      return Promise.reject(e);
    }
  };


  const getH2s = (h1: string) => {
    const h2sArr = toJS(headingSuggestions).find(heading => heading.h1 === h1);
    if (h2sArr && h2sArr.h2S) {
      setCurrentH2Arr(h2sArr.h2S);
    }
  };

  const onH1Change = (e: RadioChangeEvent) => {
    setSelectedH1(e.target.value);
    setSelectedH2Arr([]);
    getH2s(e.target.value);
  };
  const onH2Select = (e: CheckboxChangeEvent, h2: string) => {
    if (e.target.checked) {
      if (!selectedH2Arr.includes(h2)) {
        const newArr: string[] = selectedH2Arr;
        if (newArr?.length > 10) {
          setShowFocusTopicsLimitErr(true);
          return;
        }
        if (showFocustopicsLimitErr) setShowFocusTopicsLimitErr(false);
        newArr.push(h2);

        setSelectedH2Arr(newArr);
        const existingH2Arr = [...currentH2Arr];
        setCurrentH2Arr(existingH2Arr);
      }
    } else {
      if (selectedH2Arr.includes(h2)) {
        if (showFocustopicsLimitErr) setShowFocusTopicsLimitErr(false);
        const newArr: string[] = selectedH2Arr.filter(heading => heading !== h2);
        setSelectedH2Arr(newArr);
      }
    }
  };

  const setCounterInputValue = e => {
    if (e.target?.value) {
      setResultsCounter(e.target?.value);
    }
  };

  const generateResultsCunterErrorMsg = () => {
    let msg = '';
    if (isNil(resultsCounter)) {
      msg = 'Number of desired outlines is required.';
    }

    if (resultsCounter < 1 || resultsCounter > 3) {
      msg = 'Only values from 1 to 3 are allowed.';
    }

    return msg;
  };


  const headingSuggestionsData = (
    <HeadingSuggestionsContainer>
      {!headingSuggestionsLoading ? <Radio.Group onChange={onH1Change} value={selectedH1}>
        {headingSuggestions?.map((heading, idx) => {
          return <ItemWrapper key={idx}><Radio value={heading.h1}>{heading.h1}</Radio></ItemWrapper>;
        })}
      </Radio.Group> : null}
    </HeadingSuggestionsContainer>
  );


  const headingSuggestionsH2Data = () => {
    return (
      <HeadingSuggestionsContainer>
        {currentH2Arr?.map((heading, idx) => {
          return <ItemWrapper key={idx}>
            <CheckboxStyled
              checked={selectedH2Arr.includes(heading)}
              onChange={e => onH2Select(e, heading)}>
              {heading}
            </CheckboxStyled>
          </ItemWrapper>;
        })}
      </HeadingSuggestionsContainer>
    );
  };
  const onHeadingSuggestionsSubmit = () => {
    if (!selectedH1) return;

    const fieldsMap = [];
    let mainHeading = '';


    mainHeading = selectedH1;

    selectedH2Arr?.map(heading => {
      fieldsMap.push(
        {
          value: heading,
          label: 'Focus Topic (H2)',
          placeholder: 'Add a Focus Topic....',
          fields: [
            {
              type: 'question',
              label: 'Question',
              placeholder: 'Add a related question....',
              value: '',
            },
            {
              type: 'term',
              label: 'Topical Term',
              placeholder: 'Add a related focus term....',
              value: '',
            },
          ],
        },
      );
    });

    setState({
      mainHeading: mainHeading,
      items: fieldsMap,
    });

    setCurrentH2Arr([]);
    setSelectedH1('');
    setSelectedH2Arr([]);
  };

  const isStandaloneTool = aiTemplatesController?.isStandaloneTool;

  const headingSuggestionsContent = (
    <>
      {showTitleEditor ?
        <div>
          <PopoverTitle> Provide an Article Title to get Main Heading suggestions </PopoverTitle>
          <InputStyled variant='light' placeholder='Article title...' value={articleTitle} onChange={e => setArticleTitle(e.target.value)}/>
          {!isStandaloneTool ? <Button color='blue'
            style={{marginTop: 10, marginLeft: 'auto'}}
            disabled={!articleTitle || headingSuggestionsLoading || isSaving}
            onClick={() => updateArticleTitleHandler()}>
                    Get Suggestions {headingSuggestionsLoading || isSaving ? <Spin indicator={antIcon} /> : null}
          </Button> : null}
        </div> :
        <HeadingSuggestionsWrapper>
          <PopoverTitle>Select a Main Heading (H1) to continue:{headingSuggestionsLoading ? <Spin indicator={purpleIcon} /> : null}</PopoverTitle>
          {headingSuggestionsData}
          {currentH2Arr?.length ? <PopoverTitle>Select the Focus Topics (H2): </PopoverTitle> : null}
          {showFocustopicsLimitErr ? <div style={{fontSize: 12, color: '#ff5959'}}>Max of 10 focus topics allowed.</div> : null}
          {currentH2Arr?.length ? headingSuggestionsH2Data() : null}
          <Button color='blue'
            style={{marginTop: 10, marginLeft: 'auto'}}
            disabled={!selectedH1 || selectedH2Arr?.length > 10}
            onClick={() => onHeadingSuggestionsSubmit()}>
                    Populate Form
          </Button>
        </HeadingSuggestionsWrapper>
      }
    </>
  );


  const handleTooltipOpenChange= (open: boolean) => {
    if (!open) {
      setCurrentH2Arr([]);
      setSelectedH1('');
      setSelectedH2Arr([]);
    }
  };


  return (<div style={{display: 'flex', flexDirection: 'column'}}>
    <div style={{height: 'auto', marginBottom: 10}}>
      {state.isEmpty && !isStandaloneTool ? <div style={{display: 'flex', flexDirection: 'column'}}>
        <span onClick={() => autoPopulateFieldsHandler()} style={{color: '#2D6CCA', cursor: 'pointer'}}>
        &#x2022; Use existing content to populate the form.
        </span>

        <Popover onVisibleChange={handleTooltipOpenChange}
          content={headingSuggestionsContent}
          trigger='click'
          placement='bottom'
          overlayInnerStyle={
            {background: '#F9F9FB',
              boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.25)',
              borderRadius: 8,
              padding: 10,
              width: 430,
            }}>
          <span onClick={() => checkForPageTitleHandler()} style={{color: '#2D6CCA', cursor: 'pointer'}}>
            &#x2022; Get heading and topic suggestions
          </span>
        </Popover>

        {showMainHeadingMissingError ? <div style={{fontSize: 10, color: 'red'}}>A single H1 tag is required.</div> : null}
        {showFocusTopicMissingError ? <div style={{fontSize: 10, color: 'red'}}>One or more H2 tags are required.</div> : null}
      </div> : null}
    </div>


    <Wrapper >
      <DragDropContext onDragEnd={onSectionDragEnd}>
        <TopSectionWrapper>
          {/* <div><FontAwesomeIcon icon={faGripDotsVertical} style={{marginRight: 10}}/></div> */}
          <div style={{width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{fontSize: 12}}>Main Heading (H1)</div>
              <ResetFieldsBtn onClick={() => resetFields()}>Reset all fields</ResetFieldsBtn>
            </div>

            <CommonInput
              type='text'
              variant='transparent'
              placeholder={'Add a Main Heading...'}
              value={state.mainHeading}
              onChange={e => {
                if (showMainHeadingMissingError) {
                  setShowMainHeadingMissingError(false);
                }
                if (showFocusTopicMissingError) {
                  setShowFocusTopicMissingError(false);
                }
                setState({...state, mainHeading: e.target.value, isEmpty: isEmptyState(e.target.value)});
              }}
              className={'customLargeInput'} />

            {/* {(showError && state.mainHeading === '') && <ErrorMsg>Main Heading is required</ErrorMsg>} */}
          </div>
        </TopSectionWrapper>
        <Droppable droppableId='droppable'>
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle()}
            >
              {state.items.map((item, index) => (
                <Draggable key={index} draggableId={`${item.label}-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <TopicSection
                        totalItems={state?.items?.length || 0}
                        totalTerms={item?.fields?.filter(item => item?.type == 'term').length}
                        totalQuestions={item?.fields?.filter(item => item?.type == 'question').length}
                        onSingleFieldDragEnd={onSingleFieldDragEnd}
                        updateItemTopic={updateFocusTopic}
                        currentItemState={item}
                        addField={addNewField}
                        updateItemValue={updateSingleItemValue}
                        removeExistingField={removeSingleField}
                        removeTopic={removeTopicHandler}
                        index={index}
                        showError={showError}
                        usedTerms={usedTerms}
                        usedQuestions={usedQuestions}
                        isStandaloneTool={isStandaloneTool}/>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {state?.items?.length < 10 ?
        <AddMoreTopics onClick={() => addNewTopic()}>+ Focus Topic (H2)</AddMoreTopics> :
        <Tooltip title={'Maximum of 10 Focus Topics are allowed.'}>
          <AddMoreTopics disabled={true}>+ Focus Topic (H2)</AddMoreTopics>
        </Tooltip>}

    </Wrapper>
    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25}}>
      <GrayButton style={{marginLeft: 0}} onClick={() => resetFields()}>
        <FontAwesomeIcon style={{marginRight: 10}} icon={faXmark} />
        Clear inputs
      </GrayButton>

      <div style={{display: 'flex'}}>
        {(!resultsCounter || resultsCounter < 1 || resultsCounter > 3) ? <span style={{fontSize: 12, color: '#ff5959'}}>{generateResultsCunterErrorMsg()}</span> : null}
        <input type='number' value={resultsCounter} className={styles.numberInput} defaultValue={1} min={1} max={3} pattern='[0-9]' style={{width: '50px', marginBottom: 0}} onChange={e => setCounterInputValue(e)}/>
        <Button
          onClick={() => generateAiOutlineHandler()}
          disabled={disableButtonHandler() || loading || (!resultsCounter || resultsCounter < 1 || resultsCounter > 3)}
          style={{width: '200px', display: 'flex', justifyContent: 'center'}}>
          {`Generate AI Content`}
          {/* {`Generate ${state.items?.length} ${state?.items?.length == 0 || state?.items?.length > 1 ? 'Outlines' : 'Outline'}`} */}
          {loading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon style={{marginLeft: 10}} icon={faArrowRight} />}
        </Button>
      </div>
    </div>

  </div>
  );
});

const Wrapper = styled.div`
  background-color: #fff;
  padding: 18px 30px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
`;

const AddMoreTopics = styled.div<{disabled?: boolean}>`
  color:  ${p => p.disabled ? 'gray': '#2D6CCA'};
  cursor: ${p => p.disabled ? 'not-allowed': 'pointer'};
  margin-right: 20px;
  margin-top: 20px;
  font-size: 12px;
  width: fit-content;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 22px !important;
    padding-left: 0px !important;
    font-size: 18px !important;
    font-weight: 700;
    color: #121212 !important;

    &::placeholder {
      font-size: 18px !important;
      font-weight: 700 !important;
    }
  }
`;
// const ErrorMsg = styled.div`
//   font-size: 10px;
//   color: #ff6262;
// `;
const ResetFieldsBtn = styled.div`
  font-size: 12px;
  color: #A3A4A4;
  cursor: pointer;

  &:hover {
    color: #2D6CCA;
  }
`;

const InputStyled = styled(Input)`
  border-radius: 5px;

  &::placeholder {
    color: #4E5156;
  }
`;
const CheckboxStyled = styled(Checkbox)`
margin-left: 0px !important;
`;
const PopoverTitle = styled.div`
  font-size: 12;
  color: #4E5156;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;
const HeadingSuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
const HeadingSuggestionsWrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
const ItemWrapper = styled.div`
  padding: 10px;
  background: #fff;
  margin-bottom: 6px;
  border: 1px solid #E8E8E8;
border-radius: 8px;

.ant-radio-checked .ant-radio-inner {
  border-color:  #7f4ead;
}

.ant-radio-inner {
  // border-color:  #7f4ead;
  &::after {
    background-color: #7f4ead;
  }
}
`;
