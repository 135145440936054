import React from 'react';
import styles from '../style.module.scss';
import {Popover, Dropdown, Menu} from 'antd';
import FilterKeywordPopUp from './keywordsFilter';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import FilterMultipleCheckboxPopUp from './multipleCheckbox';
import FilterMultipleRadioPopUp from './multipleRadioButtons';
import FilterPopUp from './rangeInputFilter';
import {PopupItemsContainer, PopupItemStyled, SpecialFiltersContainer} from '../styledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faPlus} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import FilterKeywordPopUpSimple from './keywordsFilterSimple';
import FilterKeywordPopUpCheckboxes from './keywordsFilterCheckboxes';
import {Button} from '@/components/common-components/components';

interface Props {
  mainFilters?: any;
  openFilters?:() => void;
  onChange?: (w:any)=>void;
  openFiltersContainer?: boolean;
  horizontalFiltersList?: boolean;
  onFilter?: Function;
  showCheckboxIcons?: boolean;
  isIssuePage?: boolean;
  handleDelete?: (name, id) => void;
  handleDeleteAll?: (nameList, idList) => void;
  specialFilterHeading?: string;
  specialFilterKeys?: any[];
  simpleMoreBtn?: boolean;
}

const MoreFilters:React.FC<Props> = ({mainFilters, onChange, isIssuePage, handleDelete, handleDeleteAll, specialFilterHeading, specialFilterKeys, simpleMoreBtn = false}) => {
  const activeFilters = mainFilters?.filter(z => !mainFilters?.includes(z?.id) && z?.active);
  const filterName = mainFilters?.filter(z => !mainFilters?.includes(z?.id) && z?.active)?.map(items=> items.name);
  const filterId = mainFilters?.filter(z => !mainFilters?.includes(z?.id) && z?.active)?.map(items=> items.id);
  const getPopupContent = item => {
    return <Menu style={{borderRadius: '10px'}}>
      <div onClick={e => e.stopPropagation()}>{
        item.name.includes('exclude') ?
          <FilterKeywordPopUp
            filterId={item.id}
            onChange={onChange}
            type={item?.type}
            filterLabel={item.header}
            customFields={item?.customFields}
            filterName={item.name}
            queryVal={item?.query}
            filterField={item?.filterField}
          /> : item?.filterTypes?.length ? (
            item.name == 'checkboxes' ?
              <FilterMultipleCheckboxPopUp
                categories={item?.filterTypes}
                options={item?.filterTypes}
                className={styles.checkBoxFiltersStyled}
                onChange={onChange}
                checkedValues={item?.category}
                filterId={item.id}
                filterLabel={item.header}
                filterName={item.name}
                customCategoryType={item?.customCategoryType}
              /> : item?.name == 'search_checkboxes' ?
                <FilterKeywordPopUpCheckboxes
                  filterId={item.id}
                  categories={item?.filterTypes}
                  options={item?.filterTypes}
                  checkedValues={item?.operator}
                  onChange={onChange}
                  filterLabel={item.header}
                  filterName={item.name}
                  customFields={item?.customFields}
                  className={styles.popupClasses}
                  filterField={item?.filterField}
                  queryVal={item?.query}
                /> :
                item?.name == 'search_simple' ?
                  <FilterKeywordPopUpSimple
                    filterId={item.id}
                    type={item?.type}
                    onChange={onChange}
                    filterLabel={item.header}
                    filterName={item.name}
                    className={styles.popupClasses}
                    filterField={item?.filterField}
                    queryVal={item?.query}
                  /> : item?.name == 'radioButtonsFilter' ?
                    <FilterMultipleRadioPopUp
                      options={item?.filterTypes}
                      className={styles.checkBoxFiltersStyled}
                      onChange={onChange}
                      filterId={item.id}
                      filterLabel={item.header}
                      filterName={item.name}
                      key={item.header}
                      customCategoryType={item?.customCategoryType}
                    /> :
                    '') :
            <FilterPopUp
              filterId={item.id}
              onChange={onChange}
              // from={from}
              // setFrom={setFrom}
              // to={to}
              // setTo={setTo}
              filterLabel={item.header}
              initialFrom={item.from}
              initialTo={item.to}
              initialEqual={item?.equal}
              isDecimals={item?.isDecimals}
              isIssuePage = {isIssuePage}
              filterName={item.name}
              customOptions={item?.customOptions}
              customOptionsTop={item?.customOptionsTop}
              allowOneField={true}
            />
      }</div>
    </Menu>;
  };
  return (
    <>
      <Dropdown placement='bottomLeft' trigger={['click']} overlayStyle={{borderRadius: '10px', background: '#fff', width: '220px', boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.15)'}} overlay={
        <PopupItemsContainer>
          {filterName?.length ? <ClearFiltersBtn onClick={e=>{
            e.stopPropagation();
            handleDeleteAll(filterName, filterId);
          }}>Clear all</ClearFiltersBtn> : ''}
          {mainFilters?.filter(z => !specialFilterKeys?.includes(z?.id))?.map(item => (
            <Popover
              key={`more_ids_${item.id}`} placement='rightTop' overlayClassName={styles.OptionContainer}
              getPopupContainer={trigger => trigger}
              content={getPopupContent(item)}>
              <PopupItemStyled selected={item?.active}>
                {item.header}
                {item?.active ? <div
                  onClick={e=>{
                    e.stopPropagation();
                    handleDelete(item.name, item?.id);
                  }}
                  style={{marginLeft: '8px', paddingLeft: '7px'}}>
                  <FontAwesomeIcon icon={faXmark}/>
                </div> : ''}
              </PopupItemStyled>
            </Popover>
          ))
          }
          <SpecialFiltersContainer>
            {specialFilterHeading ? <div style={{color: '#AF8600', padding: '6px 15px'}}>{specialFilterHeading}</div> : ''}
            {specialFilterKeys?.length ? <>
              {mainFilters?.filter(z => specialFilterKeys?.includes(z?.id))?.map(item => (
                <Popover
                  key={`more_ids_${item.id}`} placement='rightTop' overlayClassName={styles.OptionContainer}
                  getPopupContainer={trigger => trigger}
                  content={getPopupContent(item)}>
                  <PopupItemStyled selected={item?.active} coloredHover={true}>
                    {item.header}
                    {item?.active ? <div
                      onClick={e=>{
                        e.stopPropagation();
                        handleDelete(item.name, item?.id);
                      }}
                      style={{marginLeft: '8px', paddingLeft: '7px'}}>
                      <FontAwesomeIcon icon={faXmark}/>
                    </div> : ''}
                  </PopupItemStyled>
                </Popover>
              ))}
            </> : ''}
          </SpecialFiltersContainer>
        </PopupItemsContainer>
      }>
        {!simpleMoreBtn ?
          <FilterTag isActive={activeFilters?.length} style={{display: 'flex', alignItems: 'center'}}>
            <FilterTagText isActive={activeFilters?.length}>{'More filters'}{activeFilters?.length ? <CountFilterWrapper isActive={activeFilters}>{activeFilters?.length}</CountFilterWrapper> : <></>}<FontAwesomeIcon icon={faCaretDown} style={{marginLeft: '8px'}} color={activeFilters?.length ? '#4E5156' : '#A3A4A4'}/></FilterTagText>
          </FilterTag> :
          <Button buttonType='transparent' style={{color: '#121212', padding: 0}} size='xs'>
            <FontAwesomeIcon icon={faPlus}/> More filters
          </Button>
        }
      </Dropdown>
    </>
  );
};

export default MoreFilters;

const CountFilterWrapper = styled.div<{isActive: boolean}>`
width: 17px;
height: 17px;
border-radius: 50%;
background: ${p=> p?.isActive ? '#a177c8' : '#A3A4A4'};
display: flex;
color: #fff;
justify-content: center;
margin-left: 7px;
display: flex;
justify-content: center;
align-items: center;
`;
const ClearFiltersBtn = styled.div`
font-weight: 400;
font-size: 14px;
line-height: 26px;
color: #2D6CCA;
margin-left: 15px;
cursor: pointer;
&:hover {
  color: #578cdc;
}
`;
const FilterTagText = styled.div<{isActive: boolean}>`
  font-weight: 400;
  font-size: 14px;
  width: fit-content;
  box-sizing: content-box;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${p => p.isActive ? '#121212' : '#A3A4A4'};
`;
const FilterTag = styled.div<{isActive?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 28px;
  background: ${p=> p?.isActive ? 'rgba(127,78,173,0.17);' : '#FFFFFF'};
  border: ${p=> p.isActive ? '1px solid #dfd4ea' : '1px solid #CDCDCD'};
  border-radius: 6px;
  cursor: pointer;
`;
