import {types} from 'mobx-state-tree';

export const SummaryStatsModel = types.model({
  averageContentScore: types.maybeNull(types.number),
  focustermsUsed: types.maybeNull(types.number),
  keywordsOptimized: types.maybeNull(types.number),
  totalArticles: types.maybeNull(types.number),
  optimizedPagesCount: types.maybeNull(types.number),
  pagesNeedWorkCount: types.maybeNull(types.number),
  notBegunCount: types.maybeNull(types.number),
  inProgressCount: types.maybeNull(types.number),
  completedCount: types.maybeNull(types.number),
  archivedCount: types.maybeNull(types.number),
  needsReviewCount: types.maybeNull(types.number),
});
