import React, {useState, useEffect, useRef} from 'react';
import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import {Checkbox} from 'antd';
import classNames from 'classnames';

interface Props {
  styleClass?: string;
  options: any;
  filterLabel?: string;
  categories?: any[];
  checkedValues?: any[];
  add?:boolean;
  filtersLength?:number;
  filterId?: number;
  filterName?: string;
  onClose?: ()=>void;
  removeItem?: ()=>void;
  onChange?: (filter:any)=>void;
  className?: string;
  absoluteUi?: boolean;
  isClear?: boolean;
  allowSingleBox?: boolean;
  selectBetween?: boolean;
  onClear?: ()=>void;
  customCategoryType?: string;
  clearBtnStyles?: any;
  isSA?: boolean;
}

const FilterMultipleCheckboxPopUp: React.FC<Props> = ({allowSingleBox=false, selectBetween=false, absoluteUi=false, checkedValues, categories, filterId, filterLabel, options, filterName, onClose, onChange, className, isClear = false, onClear, customCategoryType, clearBtnStyles = {}, isSA}) => {
  const popupRef = useRef<HTMLInputElement>();
  const selectedFilters = categories || [];
  const [filtersSelected, setFiltersSelected] = useState(checkedValues);
  const {t} = useTranslation('common');

  useEffect(() => {
    setFiltersSelected(checkedValues);
  }, [checkedValues?.length]);

  function checkedOnChange(checkedValues) {
    if (allowSingleBox) {
      const checkPrev = filtersSelected?.find(item => filtersSelected?.length ? item == filtersSelected[0] : false);
      setFiltersSelected(checkedValues?.length ? checkedValues?.filter(z => z !== checkPrev) : []);
    } else if (selectBetween) {
      const firstIndex = categories?.findIndex(z => z?.value == checkedValues[0]);
      const lastIndex = categories?.findIndex(z => z?.value == checkedValues[checkedValues?.length - 1]);
      if (firstIndex != -1 && lastIndex != -1) {
        setFiltersSelected(categories?.slice(firstIndex, lastIndex+1)?.map(z => z?.value));
      } else {
        setFiltersSelected(checkedValues);
      }
    } else {
      setFiltersSelected(checkedValues);
    }
  }
  const createOrUpdateFilter = e =>{
    emitOnChanges(e);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const emitOnChanges = selected => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        active: true,
        filterTypes: categories,
        category: selected,
        customCategoryType: customCategoryType,
      });
    }
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const disablingButton = () =>{
    if (filtersSelected?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (selectedFilters?.length ===0) {
      setFiltersSelected(['']);
    }
  }, [selectedFilters?.length]);

  const selectAllFilters = () => {
    setFiltersSelected(options?.map(data => data?.value));
  };

  return (
    <div
      ref={popupRef}
      style={absoluteUi ? {position: 'absolute'} : {}}
      onSubmit={() => createOrUpdateFilter(filtersSelected)}
      onClick={e=> e.stopPropagation() }
      // style={(add || width< 1000) ? {right: '-145%', left: 'unset'}:{}}
      className={`${styles.filterPopup} ${className}`}>
      <div className={styles.filterPopupOptions}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          {filterLabel == 'segment_names' ? <div style={{color: '#2d6cca', cursor: 'pointer', textAlign: 'start', textTransform: 'capitalize'}} className= 'ant-picke' onClick={() => selectAllFilters()}>Select all</div> : ''}
          {isClear ? <div
            style={{color: '#2d6cca', cursor: 'pointer', textAlign: 'end', textTransform: 'capitalize', ...(clearBtnStyles ? clearBtnStyles : {})}}
            className= 'ant-picke'
            onClick={onClear}>Clear</div> : ''}
        </div>
        <Checkbox.Group className={classNames(styles.checkboxesStyle, isSA ? styles.saCheckboxesStyle : '')} options={options} value={filtersSelected} onChange={checkedOnChange} />
      </div>
      <div className={styles.filterPopupButtons}>
        <button
          className={styles.filterPopupButtonCancel}
          onClick={onCancel}
        >
          {t('cancel')}
        </button>
        <button
          disabled={disablingButton()}
          onClick={() => createOrUpdateFilter(filtersSelected)}
          className={`${styles.filterPopupButtonApply} ${disablingButton() && styles.disabled}`}
        >
          {t('apply-filter')}
        </button>
      </div>
    </div>
  );
};

export default FilterMultipleCheckboxPopUp;
