import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import type {AllProjectsApiResponse, CreateProjectApiResponse} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {BannerVariant} from '@/components/common-components/components/banner';
import {cast, flow, getParent, Instance, types} from 'mobx-state-tree';
import numeral from 'numeral';
import {BackLinkAnalyzerStoreType} from '.';
import {FREE_PLAN_PROJECT_DOMAIN, FREE_PLAN_PROJECT_ID} from '@/constants';
import {getDomain} from '@/utils/url';
import {notification} from '@/utils/notification-v2';

const COLORS = ['#FFCB7C', '#9E73C9', '#F1C6F3', '#3DA6BD', '#478593'];

const WebsiteDetailsModel = types.model({
  id: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  refdomainCount: types.maybeNull(types.number),
  keywordCount: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  ot: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
}).views(self => ({
  get formattedValue() {
    return {
      hostname: getDomain(self.hostname),
      ot: self.ot ? numeral(self.ot).format('0,0') : '-',
      ok: self.keywordCount ? numeral(self.keywordCount).format('0,0') : '-',
      rd: self.refdomainCount ? numeral(self.refdomainCount).format('0,0') : '-',
      color: self.color,
    };
  },
  get domainName() {
    return getDomain(self.hostname);
  },
}));

const ProjectModel = types.model({
  pk: types.maybeNull(types.number),
  primaryWebsite: WebsiteDetailsModel,
  competitorWebsites: types.array(WebsiteDetailsModel),
});

const CompetitorsModel = types.model({
  domain: types.maybeNull(types.string),
  customerHasProcessed: types.optional(types.boolean, false),
  refdomainsCount: types.maybeNull(types.number),
});

export const ProjectStore = types.model({
  currentProject: types.maybeNull(types.reference(ProjectModel, {
    set(value) {
      return value.pk;
    },
    get(identifier, parent) {
      return parent.projects.find(project => project.pk === identifier) || null;
    },
  })),
  projects: types.array(ProjectModel),
  loading: types.boolean,
  showBacklinkCompetitorsModal: types.boolean,
  projectCompetitors: types.optional(types.boolean, false),
  createNewProject: types.boolean,
  loadingNewProject: types.boolean,
  competitorsList: types.maybeNull(types.array(CompetitorsModel)),
  availableQuota: types.maybeNull(types.number),
  enoughQuota: types.boolean,
  checkQuotaLoading: types.boolean,
  showNotEnoughPointsError: types.boolean,
})
  .views(self => ({
    get getCreateNewProject() {
      return self.createNewProject;
    },
    get competitorsHostnames() {
      if (self.currentProject) {
        const data = self.currentProject?.competitorWebsites.reduce((acc, curr, idx) => {
          acc[`competitor${idx + 1}`] = curr.hostname;
          return acc;
        }, {});

        return data;
      } else {
        return {};
      }
    },
    get isCheckQuotaLoading() {
      return self.checkQuotaLoading;
    },
    get competitorsDomains() {
      if (self.currentProject) {
        const data = self.competitorsList?.length>0 && self.competitorsList?.reduce((acc, curr, idx) => {
          acc[`competitor${idx + 1}`] = curr.domain;
          return acc;
        }, {});

        return data;
      } else {
        return {};
      }
    },
    get totalSpendingQuotaAttempt() {
      if (self.enoughQuota) {
        let data = 0;
        self.competitorsList?.map(competitor => {
          if (competitor.customerHasProcessed && competitor.refdomainsCount) {
            data += competitor.refdomainsCount;
          }
        });

        return data;
      } else {
        return {};
      }
    },
    get getHighestMetrics() {
      const competitorsData = self.currentProject?.competitorWebsites?.map(competitor => competitor.formattedValue) || [];
      const primaryWebsiteData = self.currentProject?.primaryWebsite?.formattedValue;
      const totalData = [primaryWebsiteData, ...competitorsData];
      const maxOT = Math.max(...(totalData?.map(item=>+(item?.ot ? item?.ot?.replaceAll(',', '') : '')) ?? []));
      const maxOK = Math.max(...(totalData?.map(item=>+(item?.ok ? item?.ok?.replaceAll(',', '') : '')) ?? []));
      const maxRD = Math.max(...(totalData?.map(item=>+(item?.rd ? item?.rd?.replaceAll(',', '') : '')) ?? []));
      const highestOT = numeral(maxOT).format('0,0');
      const highestOK = numeral(maxOK).format('0,0');
      const highestRD = numeral(maxRD).format('0,0');
      return {highestOT, highestOK, highestRD};
    },
    get scores() {
      const competitorsData = self.currentProject?.competitorWebsites.map(competitor => competitor.formattedValue) || [];
      const primaryWebsiteData = self.currentProject?.primaryWebsite.formattedValue;
      return [primaryWebsiteData, ...competitorsData];
    },
    get getCompetitorList() {
      return self.currentProject?.competitorWebsites.map(competitor => competitor.hostname) || [];
    },
    get projectKeys(): string[] {
      return [...(self.currentProject?.competitorWebsites || []), self.currentProject?.primaryWebsite]
        .map(item => item.domainName);
    },
    get competitorsForModalAdditionalInfo() {
      if (self.currentProject) {
        const data = self.competitorsList?.length>0 && self.competitorsList?.reduce((acc, curr, idx) => {
          acc[`competitor${idx + 1}`] = {
            domain: curr.domain,
            customerHasProcessed: curr.customerHasProcessed,
            refDomainCount: curr.refdomainsCount,
          };

          return acc;
        }, {});

        return data;
      } else {
        return {};
      }
    },
    get competitorsForCreateProject() {
      if (self.currentProject) {
        const data = self.competitorsList?.length>0 && self.competitorsList?.reduce((acc, curr) => {
          acc[`blProject`] = {
            domain: curr.domain,
            customerHasProcessed: curr.customerHasProcessed,
            refDomainCount: curr.refdomainsCount,
          };

          return acc;
        }, {});

        return data;
      } else {
        return {};
      }
    },
    get currentProjectColorScheme(): Record<string, string> {
      return [...(self.currentProject?.competitorWebsites || []), self.currentProject?.primaryWebsite]
        .reduce((acc, curr) => {
          if (curr?.hostname) {
            acc[curr?.hostname] = curr?.color;
          }
          return acc;
        }, {});
    },
  }))
  .actions(self => {
    const loadProjects = flow(function* (primaryWebsite: string, isSubscriber: boolean = true) {
      self.loading = true;
      try {
        const primaryWebsiteDomain = getDomain(isSubscriber ? primaryWebsite : FREE_PLAN_PROJECT_DOMAIN);
        let data;
        if (isSubscriber) {
          data = <AllProjectsApiResponse[]>(yield BacklinkAnalyzerClient.getAllProjects());
        } else {
          data = <AllProjectsApiResponse[]>[(yield BacklinkAnalyzerClient.getProject(FREE_PLAN_PROJECT_ID))];
        }
        if (data.isCancel) return;
        const richData = data.map(project => {
          const richCompetitors = project?.competitorWebsites?.map((competitor, idx) => ({...competitor, color: COLORS[idx + 1]}));

          return {
            ...project,
            primaryWebsite: {
              ...project.primaryWebsite,
              color: COLORS[0],
            },
            competitorWebsites: richCompetitors,
          };
        });

        self.projects = cast(richData);

        const primaryProject = self.projects.find(project => project.primaryWebsite.hostname === primaryWebsiteDomain);

        if (primaryProject?.primaryWebsite.hostname === primaryWebsiteDomain) {
          self.currentProject = primaryProject;
        } else if (self.projects.length) {
          self.currentProject = self.projects[0];
        } else {
          const data = <AllProjectsApiResponse[]>[(yield BacklinkAnalyzerClient.getProject(FREE_PLAN_PROJECT_ID))];
          const richData = data.map(project => {
            const richCompetitors = project?.competitorWebsites?.map((competitor, idx) => ({...competitor, color: COLORS[idx + 1]}));
            return {
              ...project,
              primaryWebsite: {
                ...project.primaryWebsite,
                color: COLORS[0],
              },
              competitorWebsites: richCompetitors,
            };
          });
          self.projects = cast(richData);
          self.currentProject = self.projects[0];
        }
      } catch (e) {
        const parent = getParent<BackLinkAnalyzerStoreType>(self);
        parent.setBanner(true, 'We’re currently facing some technical issues.', 'Our developers are working hard to fix the issues. Thank you for your patience.', BannerVariant.ERROR);
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const setCreateNewProject = flow(function* (primaryWebsite: string, competitorWebsites: string[]) {
      self.loadingNewProject = true;
      try {
        const createdProject = <CreateProjectApiResponse>(
          yield BacklinkAnalyzerClient.createProject({primaryWebsite: primaryWebsite, competitorWebsites: competitorWebsites})
        );
        const richPrimary = {
          ...createdProject?.primaryWebsite,
          color: COLORS[0],
        };
        const richCompetitors = createdProject?.competitorWebsites?.map((competitor, idx) => ({...competitor, color: COLORS[idx + 1]}));
        const richProject = {
          ...createdProject,
          primaryWebsite: richPrimary,
          competitorWebsites: richCompetitors,
        };
        self.projects = cast([...self.projects, richProject]);
        self.currentProject = ProjectModel.create(richProject);
        notification.success('Success', 'Backlink project successfully created');
      } catch (e) {
        if (e?.response?.data?.detail) {
          notification.error('Error', e?.response?.data?.detail);
        }
        return e;
      } finally {
        self.loadingNewProject = false;
      }
    });

    const loadCompetitorsQuota = flow(function* (competitorWebsites: string[]) {
      self.checkQuotaLoading = true;
      try {
        const data = yield BacklinkAnalyzerClient.getRefdomainsQuota(competitorWebsites);
        self.competitorsList = cast(data?.websites);
        self.availableQuota = cast(data?.availableQuota);
        self.enoughQuota = cast(data?.hasEnoughQuota);
        self.showBacklinkCompetitorsModal = true;
        return data;
      } catch (e) {
        if (e?.response?.status === 500) {
          notification.error('Sorry', 'There was a server error. Please refresh the page');
        }
        return e;
      } finally {
        self.checkQuotaLoading = false;
      }
    });

    const editProject = flow(function* (competitorWebsites: string[]) {
      const attemptQuota = yield loadCompetitorsQuota(competitorWebsites);
      if (attemptQuota?.hasEnoughQuota) {
        self.showNotEnoughPointsError = false;
        self.showBacklinkCompetitorsModal = false;
        try {
          self.loading = true;
          const parent = getParent<BackLinkAnalyzerStoreType>(self);
          const payload = competitorWebsites.map(website => (website));
          const data = <AllProjectsApiResponse>(
            yield BacklinkAnalyzerClient.editProject(self.currentProject?.pk, {
              primaryWebsite: self.currentProject?.primaryWebsite.hostname,
              competitorWebsites: payload,
            })
          );
          const richCompetitors = data?.competitorWebsites?.map((competitor, idx) => ({...competitor, color: COLORS[idx + 1]}));

          const richProject = {
            ...data,
            primaryWebsite: {
              ...data.primaryWebsite,
              color: COLORS[0],
            },
            competitorWebsites: richCompetitors,
          };
          yield loadCompetitorsQuota(competitorWebsites);

          const prevData = self.projects.filter(project => project.pk !== data.pk);
          self.projects = cast([...prevData, richProject]);
          if (data?.pk && data?.primaryWebsite?.hostname) {
            parent.loadBacklinkAnalyzerData(data.pk, data.primaryWebsite.hostname);
          }
          return data;
        } catch (e) {
          if (e.response) {
            if (e.response.status === 400) {
              notification.error('Error while processing', 'Invalid hostname is provided', false);
            } else {
              notification.error('Backlink data failed processing', 'Our developers are working hard to fix the issues. Thank you for your patience.', true);
            }
          }
        } finally {
          self.loading = false;
        }
      } else {
        self.showNotEnoughPointsError = true;
      }
    });

    const handleCloseCompetitorsModal = () => {
      self.showBacklinkCompetitorsModal = false;
      self.enoughQuota = true;
    };

    const setCurrentProject = (hostname: string, isSubscriber: boolean = true) => {
      if (isSubscriber) {
        self.currentProject = self.projects.find(project => project.primaryWebsite.hostname === hostname);
      } else {
        self.currentProject = self.projects[0];
      }
    };
    const projectCompetitorsModal = value =>{
      self.projectCompetitors = value;
    };
    const setNewProject = (value: boolean) =>{
      self.createNewProject = value;
    };
    const setEnoughQuota = (value: boolean) =>{
      self.enoughQuota = value;
    };
    return {
      setEnoughQuota,
      loadProjects,
      loadCompetitorsQuota,
      editProject,
      setCreateNewProject,
      setCurrentProject,
      projectCompetitorsModal,
      handleCloseCompetitorsModal,
      setNewProject,
    };
  });

export type ProjectStoreInstance = Instance<typeof ProjectStore>;

export const initProjectStore = () => {
  return {
    loading: false,
    showBacklinkCompetitorsModal: false,
    projects: [],
    currentProject: null,
    competitorsList: [],
    availableQuota: null,
    enoughQuota: true,
    loadingNewProject: false,
    createNewProject: false,
    checkQuotaLoading: false,
    showNotEnoughPointsError: false,
  };
};
