import Flag from '@/components/common-components/components/flag';
import {SORTED_COUNTRY_CODES_LIST} from '@/constants/countryCodes';
import {Globe} from 'react-feather';

export const countryFlag = (countryCode, width = '16px', borderRadius = '1px', hideFlag = false, useIsWorldWide=false, globeColor='black') => {
  return useIsWorldWide ?
    (countryCode ? <Flag style={{width, height: '16px', objectFit: 'contain', borderRadius, ...(hideFlag && {display: 'none'})}} code={countryCode} /> : <Globe style={{height: '16px', width, color: globeColor}} />) :
    <Flag style={{width, height: '16px', objectFit: 'contain', borderRadius, ...(hideFlag && {display: 'none'})}} code={countryCode} />;
};

export const countriesList= ['DZ', 'AO', 'AZ', 'AR', 'AU', 'AT', 'BH', 'BD', 'AM', 'BE',
  'BO', 'BR', 'BG', 'MM', 'BY', 'KH', 'CM', 'CA', 'LK', 'CL', 'TW', 'CO', 'CR', 'HR',
  'CY', 'CZ', 'DK', 'EC', 'SV', 'EE', 'FI', 'FR', 'DE', 'GH', 'GR', 'GT', 'HK', 'HU',
  'IN', 'ID', 'IE', 'IL', 'IT', 'CI', 'JP', 'KZ', 'JO', 'KE', 'KR', 'LV', 'LT', 'MY',
  'MT', 'MX', 'MA', 'NL', 'NZ', 'NI', 'NG', 'NO', 'PK', 'PY', 'PE', 'PH', 'PL', 'PT',
  'RO', 'SA', 'SN', 'RS', 'SG', 'SK', 'VN', 'SI', 'ZA', 'ES', 'SE', 'CH', 'TH', 'AE',
  'TN', 'TR', 'UA', 'MK', 'EG', 'GB', 'US', 'BF', 'UY', 'VE',
];

export const seAvailableCountries = [
  'AR', 'AU', 'BE', 'BR', 'CA', 'DK', 'DE', 'FI', 'FR', 'HK', 'HU', 'IE', 'IL',
  'IN', 'IT', 'JP', 'MX', 'NL', 'NO', 'PL', 'RU', 'SG', 'ES', 'SE', 'CH', 'TR',
  'US', 'UK', 'GB', 'RO'];

export const getCountryNameFromCountryCode = (countryCode: string) => {
  const countryName = SORTED_COUNTRY_CODES_LIST?.find(item => item?.countryCode == countryCode?.toUpperCase())?.countryName;
  return countryName || 'United States';
};

export const getCountryCodeFromLocation = (location: string, getSpecificCountryByName: any) => {
  const locationList = location?.split(',');
  const countryName = locationList?.[locationList?.length - 1]?.trim();
  const country = getSpecificCountryByName(countryName);
  const countryCode = country ? country.code : null;

  return countryCode;
};
