import {observer} from 'mobx-react-lite';
import React, {Fragment} from 'react';
import {UpdateProfileSection} from './UpdateProfile';
import {UpdatePasswordSection} from './UpdatePassword';
// import {DeleteAccountSection} from './DeleteAccount';

export const YourProfile: React.FC = observer(() => {
  return (
    <Fragment>
      <UpdateProfileSection />
      <UpdatePasswordSection />
      {/* <DeleteAccountSection /> */}
    </Fragment>
  );
});
