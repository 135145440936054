import React from 'react';
import {observer} from 'mobx-react';
import EChartsReact from 'echarts-for-react';
import {formatLargeNumber} from '@/utils/number';
import {NoDataWrapper} from '../StyledComponent';

interface Props {
  chartData?: any;
  loading?: boolean;
}
export const CartegoryBarChart: React.FC<Props> = observer(({chartData, loading}) => {
  const tabletData = chartData?.filter(item=> item?.device == 'TABLET');
  const desktopData = chartData?.filter(item=> item?.device == 'DESKTOP');
  const mobileData = chartData?.filter(item=> item?.device == 'MOBILE');

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      show: true,
      icon: 'circle',
      left: 0,
      top: 10,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          color: '#E8E8E8',
        },
      },
      max: function(value) {
        return value.max + (value.max/4);
      },
      axisLabel: {
        formatter: value=> {
          return formatLargeNumber(value);
        },
      },
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisLine: {
        show: false,
      },
    },
    series: [
      {
        name: 'Web',
        type: 'bar',
        data: loading ? [350, 300, 250, 200, 150] : desktopData?.map(item=> item?.clicks || item?.impressions) || [],
        itemStyle: {
          color: loading ? '#a3a4a4' : '#2D6CCA',
          borderRadius: [3, 3, 0, 0],
        },
        label: {
          show: true,
          position: 'insideBottom',
          color: '#4E5156',
          align: 'left',
          distance: 200,
          fontSize: 10,
          formatter: (value: any)=> {
            return `W: ${formatLargeNumber(value?.value, 1)?.toString()?.replace('.0', '')}`;
          },
        },
      },
      {
        name: 'Mobile',
        type: 'bar',
        data: loading ? [300, 250, 200, 150, 100] : mobileData?.map(item=> item?.clicks || item?.impressions) || [],
        itemStyle: {
          color: loading ? '#a3a4a4' : '#34AEF3',
          borderRadius: [3, 3, 0, 0],
        },
        label: {
          show: true,
          position: 'insideBottom',
          align: 'center',
          color: '#4E5156',
          distance: 185,
          fontSize: 10,
          formatter: (value: any)=> {
            return `M: ${formatLargeNumber(value?.value, 1)?.toString()?.replace('.0', '')}`;
          },
        },
      },
      {
        name: 'Tablet',
        type: 'bar',
        data: loading ? [250, 200, 150, 100, 50] : tabletData?.map(item=> item?.clicks || item?.impressions) || [],
        itemStyle: {
          color: loading ? '#a3a4a4' : '#78EFFF',
          borderRadius: [3, 3, 0, 0],
        },
        label: {
          show: true,
          position: 'insideBottom',
          distance: 170,
          align: 'right',
          color: '#4E5156',
          fontSize: 10,
          formatter: (value: any)=> {
            return `T: ${formatLargeNumber(value?.value, 1)?.toString()?.replace('.0', '')}`;
          },
        },
      },
    ],
  };

  return (
    (loading || chartData?.length) ? <div>
      <EChartsReact option={option}/>
    </div> : <NoDataWrapper>No Data</NoDataWrapper>
  );
});
