import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {notification} from '@/utils/notifications';

export interface VariantDTO {
  name: string;
  description?: string;
  unit_price: string;
}
export interface ProductDTO {
  id?: number;
  name: string;
  type: string;
  description: string;
  category: string;
  price?: number;
  variants?: VariantDTO[];
}


class WhiteLabelOrderBuilderApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');
  private static readonly ssoBaseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/sso');
  private static readonly keywordBaseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');

  public async getWhiteLabelStripeKeys() {
    try {
      const response = await this.axios.get(`${WhiteLabelOrderBuilderApi.baseUrl}/whitelabel-stripe-credentials/`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createWhiteLabelStripeKeys(secretKey: string, publishableKey: string) {
    try {
      const response = await this.axios.patch(
        `${WhiteLabelOrderBuilderApi.baseUrl}/whitelabel-stripe-credentials/`,
        {
          secret_key: secretKey,
          publishable_key: publishableKey,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response;
    } catch (error) {
      notification.error(error?.response?.data?.nonFieldErrors?.length ? error?.response?.data?.nonFieldErrors[0] : '');
    }
  }

  public async updateWhiteLabelStripeKeys(id: number, secretKey: string, publishableKey: string) {
    try {
      const response = await this.axios.patch(
        `${WhiteLabelOrderBuilderApi.baseUrl}/whitelabel-stripe-credentials/${id}/`,
        {
          secret_key: secretKey,
          publishable_key: publishableKey,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response;
    } catch (error) {
      notification.error(error?.response?.data?.nonFieldErrors?.length ? error?.response?.data?.nonFieldErrors[0] : '');
    }
  }

  public async createWhiteLabelProductCategory(name: string, description: string) {
    try {
      const response = await this.axios.post(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-product-categories/`,
        {
          'name': name,
          'description': description,
        },
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getWhiteLabelProductCategories() {
    try {
      const response = await this.axios.get(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-product-categories/`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateWhiteLabelProductCategory(id: number, name: string, description: string) {
    try {
      const response = await this.axios.patch(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-product-categories/${id}/`,
        {
          'name': name,
          'description': description,
        },
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async deleteWhiteLabelProductCategory(categoryId: number) {
    try {
      const response = await this.axios.delete(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-product-categories/${categoryId}/`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getWhiteLabelProducts() {
    try {
      const response = await this.axios.get(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-products/`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createWhiteLabelProduct({name, type, description, price, variants, category}: ProductDTO) {
    try {
      const singleVariantParams = {
        name,
        type,
        description,
        price,
        category,
      };
      const multiVariantParams = {
        name,
        type,
        description,
        category,
        variants,
      };
      const response = await this.axios.post(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-products/`,
        type === 'single_variant' ? singleVariantParams : multiVariantParams,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteWhiteLabelProduct(productId: number) {
    try {
      const response = await this.axios.delete(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-products/${productId}/`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async updateWhiteLabelProduct({id, name, type, description, price, variants, category}: ProductDTO) {
    try {
      const singleVariantParams = {
        id,
        name,
        type,
        description,
        price,
        category,
      };
      const multiVariantParams = {
        id,
        name,
        type,
        description,
        category,
        variants,
      };
      const response = await this.axios.patch(`${WhiteLabelOrderBuilderApi.baseUrl}/product/whitelabel-products/${id}/`,
        type === 'single_variant' ? singleVariantParams : multiVariantParams,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default WhiteLabelOrderBuilderApi;
