import {types, flow} from 'mobx-state-tree';
import {whiteLabelOrderBuilderApi} from '@/api/account';
import {notification as notify} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {ProductDTO} from '@/api/account/customer/white-label-order-builder-api';

const StripCredentialsModel = types.model({
  id: types.maybeNull(types.number),
  customer: types.maybeNull(types.number),
  publishableKey: types.maybeNull(types.string),
});

const CategoriesModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
});

const VariantsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  unitPrice: types.maybeNull(types.string),
});

const ProductCategoriesModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

const ProductsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  category: types.maybeNull(CategoriesModel),
  type: types.maybeNull(types.string),
  variants: types.maybeNull(types.array(VariantsModel)),
  price: types.maybeNull(types.string),
});

const ProductsResponseModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(ProductsModel)),
});

export const WhiteLabelOrderBuilderModel = types.model({
  stripCredentials: types.maybeNull(StripCredentialsModel),
  productCategories: types.maybeNull(types.array(ProductCategoriesModel)),
  products: types.maybeNull(ProductsResponseModel),
  loadingStripeKeys: types.boolean,
  loadingCategories: types.boolean,
  loadingCategoriesList: types.boolean,
  loadingProductsList: types.boolean,
  loadingProducts: types.boolean,
}).views(self => ({
  get getLoadingStripeKeys() {
    return self.loadingStripeKeys;
  },
})).actions(self => {
  const getWhiteLabelStripeKeys = flow(function* () {
    try {
      const response = yield whiteLabelOrderBuilderApi.getWhiteLabelStripeKeys();
      if (response?.isCancel) return;
      self.stripCredentials = response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('', errorMessage);
    }
  });

  const createWhiteLabelStripeKeys = flow(function* (publishableKey: string, secretKey: string) {
    self.loadingStripeKeys = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.createWhiteLabelStripeKeys(secretKey, publishableKey);
      if (response?.isCancel) return;
      notify.success('Stripe keys created successfully', '', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Stripe keys update failed', errorMessage);
    } finally {
      self.loadingStripeKeys = false;
    }
  });

  const updateWhiteLabelStripeKeys = flow(function* (publishableKey: string, secretKey: string) {
    self.loadingStripeKeys = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.updateWhiteLabelStripeKeys(self?.stripCredentials?.id, secretKey, publishableKey);
      if (response?.isCancel) return;
      notify.success('Stripe keys updated successfully', '', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Stripe keys update failed', errorMessage);
    } finally {
      self.loadingStripeKeys = false;
    }
  });

  const createWhiteLabelProductCategory = flow(function* (name: string, description: string) {
    self.loadingCategories = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.createWhiteLabelProductCategory(name, description);
      if (response?.isCancel) return;
      notify.success('Created Successfully', 'Product Category created successfully', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Create failed', errorMessage);
    } finally {
      self.loadingCategories = false;
    }
  });

  const getWhiteLabelProductCategories = flow(function* () {
    self.loadingCategoriesList = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.getWhiteLabelProductCategories();
      if (response?.isCancel) return;
      self.productCategories = response;
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('', errorMessage);
    } finally {
      self.loadingCategoriesList = false;
    }
  });

  const updateWhiteLabelProductCategory = flow(function* (id: number, name: string, description: string) {
    self.loadingCategories = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.updateWhiteLabelProductCategory(id, name, description);
      if (response?.isCancel) return;
      notify.success('Updated Successfully', 'Product Category updated successfully', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Update failed', errorMessage);
    } finally {
      self.loadingCategories = false;
    }
  });

  const deleteWhiteLabelProductCategory = flow(function* (categoryId: number) {
    self.loadingCategories = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.deleteWhiteLabelProductCategory(categoryId);
      if (response?.isCancel) return;
      notify.success('Deleted Successfully', 'Product Category deleted successfully', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Delete failed', errorMessage);
    } finally {
      self.loadingCategories = false;
    }
  });

  const getWhiteLabelProducts = flow(function* () {
    self.loadingProductsList = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.getWhiteLabelProducts();
      if (response?.isCancel) return;
      self.products = response;
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('', errorMessage);
    } finally {
      self.loadingProductsList = false;
    }
  });

  const createWhiteLabelProduct = flow(function* (data: ProductDTO) {
    self.loadingProducts = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.createWhiteLabelProduct(data);
      if (response?.isCancel) return;
      notify.success('Created Successfully', 'Product created successfully', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Create failed', errorMessage);
    } finally {
      self.loadingProducts = false;
    }
  });

  const deleteWhiteLabelProduct = flow(function* (productId: number) {
    self.loadingProducts = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.deleteWhiteLabelProduct(productId);
      if (response?.isCancel) return;
      notify.success('Deleted Successfully', 'Product deleted successfully', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Delete failed', errorMessage);
    } finally {
      self.loadingProducts = false;
    }
  });


  const updateWhiteLabelProduct = flow(function* (data: ProductDTO) {
    self.loadingProducts = true;
    try {
      const response = yield whiteLabelOrderBuilderApi.updateWhiteLabelProduct(data);
      if (response?.isCancel) return;
      notify.success('Updated Successfully', 'Product updated successfully', 'Got it!');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as any;
      notify.error('Update failed', errorMessage);
    } finally {
      self.loadingProducts = false;
    }
  });

  return {
    getWhiteLabelStripeKeys,
    createWhiteLabelStripeKeys,
    updateWhiteLabelStripeKeys,
    createWhiteLabelProductCategory,
    getWhiteLabelProductCategories,
    updateWhiteLabelProductCategory,
    deleteWhiteLabelProductCategory,
    getWhiteLabelProducts,
    createWhiteLabelProduct,
    deleteWhiteLabelProduct,
    updateWhiteLabelProduct,
  };
});

