import {types, Instance} from 'mobx-state-tree';
import {AnchorTextDistributionStore, initAnchorTextDistributionStore} from './anchor-text-distribution';
import {RefdomainStore, initRefdomainStore} from './refdomains';
import {ProjectStore, initProjectStore} from './projects';
import {OutreachTargetsStore, initOutreachTargetsStore} from './outreach-targets';
import {initTopicalRelevanceStore, TopicalRelevanceStore} from './topical-relevance';
import {initToxicBacklinksStore, ToxicBacklinksStore} from './toxicBacklinks';
import {BannerVariant} from '@/components/common-components/components/banner';
import {BacklinkVelocityStore, initBacklinkVelocityStore} from './backlink-velocity';
import {BlEmailNotificationSettings, initBlEmailNotificationSettingsStore} from './backlink-notification-settings';

export type TableFilterType<T> = {
  keyword?: string;
  filterableBy?: Array<keyof T>;
  pageSize?: number;
  currentPage?: number;
  filterSelectBy?: string[];
  filterCategories?: string[];
  filterTopCategories?: string[];
  category?:any;
  filters?:any;
  sortField?:string;
  sortDirection?:string;
};

export const BackLinkAnalyzerStore = types.model({
  loading: types.boolean,
  refdomainStore: RefdomainStore,
  projectStore: ProjectStore,
  anchorDistribution: AnchorTextDistributionStore,
  outreachTargets: OutreachTargetsStore,
  topicalStore: TopicalRelevanceStore,
  toxicBacklinksStore: ToxicBacklinksStore,
  backlinkVelocityStore: BacklinkVelocityStore,
  notificationSettings: BlEmailNotificationSettings,
  banner: types.model({
    show: types.boolean,
    title: types.maybeNull(types.string),
    subtitle: types.maybeNull(types.string),
    variant: types.enumeration<BannerVariant>('BannerVariant', Object.values(BannerVariant)),
  }),
})
  .actions(self => {
    const setLoading = (loading: boolean) => {
      self.loading = loading;
    };

    const loadBacklinkAnalyzerData = (id: number, hostname: string) => {
      self.refdomainStore.loadRefdomains(id, '-domain_rating', 1, '');
      self.anchorDistribution.loadDistribution(id, hostname);
      self.outreachTargets.loadOutreachTargets(id);
      self.topicalStore.loadCategories(id, '-domain_rating', 1, '');
      self.toxicBacklinksStore.loadToxicBacklinksData(id);
      self.backlinkVelocityStore.loadBacklinkVelocity(id);
      setLoading(false);
    };

    const setBanner = (show: boolean, title: string, subtitle: string, variant: BannerVariant) => {
      self.banner = {
        show,
        title,
        subtitle,
        variant,
      };
    };

    return {
      loadBacklinkAnalyzerData,
      setLoading,
      setBanner,
    };
  });

export const initBackLinkAnalyzerStore = () => {
  return {
    refdomainStore: initRefdomainStore(),
    projectStore: initProjectStore(),
    loading: true,
    anchorDistribution: initAnchorTextDistributionStore(),
    outreachTargets: initOutreachTargetsStore(),
    topicalStore: initTopicalRelevanceStore(),
    toxicBacklinksStore: initToxicBacklinksStore(),
    backlinkVelocityStore: initBacklinkVelocityStore(),
    notificationSettings: initBlEmailNotificationSettingsStore(),
    banner: {
      show: false,
      title: '',
      subtitle: '',
      variant: BannerVariant.ERROR,
    },
  };
};

export type BackLinkAnalyzerStoreType = Instance<typeof BackLinkAnalyzerStore>;
