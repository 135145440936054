import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Confetti from 'react-confetti';
import {useStore} from '@/store/root-store';

interface Props {
  colors?: Array<string>;
  duration?: number;
}

export const ConfettiAnimation = observer(({colors, duration}: Props) => {
  const {settings: {customer: {setShowConfettiAnimation}}} = useStore('');

  const defaultColors = ['#F1AA3E', '#FF8536', '#F44343', '#2AC155', '#BFE15E',
    '#7F4EAD', '#B98CE4', '#00D1ED', '#F58069', '#78EFFF',
    '#E15E83'];

  useEffect(() => {
    const animationDuration = setTimeout(() => {
      setShowConfettiAnimation(false);
    }, duration || 5000);
    return () => {
      clearTimeout(animationDuration);
    };
  }, []);

  return (
    <Confetti
      width={720}
      height={800}
      colors={colors || defaultColors}
      style={{margin: 'auto'}}
    />
  );
});
