import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {StyledIssuesTable} from '../../../../style';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import moment from 'moment';
import styled from 'styled-components';
import {ViewCitationModal} from './viewCitationModal';
import {faArrowUp, faCheck, faListCheck, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import {Progress, Tooltip} from 'antd';
import {faPause} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';


interface Props {
  componentIssueType: string;
}

export const AggregatorNetwork = observer(({componentIssueType}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
  },
  localSeoScan: {
    loadCitationBusinessDetail,
  }} = useStore('');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openCitationModal, setOpenCitationModal] = useState(false);
  const [openedBusinessInModal, setOpenedBusinessInModal] = useState(null);
  const [pageSize, setPageSize] = useState(getOttoTablePageSize(componentIssueType));
  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    setTableData(filteredData);
  }, [ottoUrls]);


  const onCitationView = async record => {
    setOpenedBusinessInModal(record);
    setOpenCitationModal(true);
    await loadCitationBusinessDetail(record?.id);
  };

  const getStatusConf = (status: string) => {
    switch (status) {
      case 'Saved':
      case 'Submitting To Sites':
        return {
          label: 'Submitting',
          icon: faArrowUp,
          strokeColor: '#F1AA3E',
          percentage: 30,
        };
      case 'Paid':
        return {
          label: 'Quality assurance',
          icon: faMagnifyingGlass,
          strokeColor: '#2AC155',
          percentage: 50,
        };
      case 'On Hold':
        return {
          label: 'On hold',
          icon: faPause,
          strokeColor: '#F44343',
          percentage: 100,
        };
      case 'Live Listing Checks':
      case 'Completed':
      case 'Complete':
        return {
          label: 'Live checks',
          icon: faListCheck,
          strokeColor: '#2AC155',
          percentage: 75,
        };
      case 'Finished':
        return {
          label: 'Completed',
          icon: faCheck,
          strokeColor: '#2AC155',
          percentage: 100,
        };
      default:
        return {
          label: 'Submitting',
          icon: faArrowUp,
          strokeColor: '#F1AA3E',
          percentage: 30,
        };
    }
  };

  const getTooltipTitle = (status: string, createdAt?: string) => {
    switch (status) {
      case 'Saved':
      case 'Submitting To Sites':
        return `Order has been paid and submitted for verification ${moment(createdAt).isValid ? 'on ' + moment(createdAt).format('MMM DD, YYYY') : ''}.`;
      case 'Paid':
        return 'Your submission has been verified successfully. We are now initiating the Quality Assurance process and performing a Live listing verification.';
      case 'On Hold':
        return 'Your order has been placed on hold. Contact us for additional inquires.';
      case 'Live Listing Checks':
      case 'Completed':
      case 'Complete':
        return 'Your submission has successfully passed the Quality Assurance check. We are now focusing on the final phase of live listing verification.';
      case 'Finished':
        return 'The process is now complete. We guarantee that 70% of your listings are live. If any listings are missing, they should go live within the next few days or weeks.';
      default:
        return `Order has been paid and submitted for verification ${moment(createdAt).isValid ? 'on ' + moment(createdAt).format('MMM DD, YYYY') : ''}.`;
    }
  };

  const columns = [
    {
      title: (<div className='column-title'>
        <span>BUSINESS</span>
      </div>),
      dataIndex: 'business',
      key: 'business',
      render: (_, record) => {
        const business = getOttoV2Project?.connectedData?.gbpDetailsV2.find(business=>business?.locationId===record?.gbLocationId);
        return (
          <>
            {
              business?.locationId ?
                <TextContainer>
                  <div>
                    {business?.businessName}
                  </div>
                  <div className='address'>
                    {business?.businessAddress}
                  </div>
                </TextContainer> :
                <TextContainer>-</TextContainer>
            }
          </>);
      },
    },
    {
      title: (<div className='column-title'>
        <span>STATUS</span>
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'left' as 'left',
      render: (_, data) => {
        const statusData = getStatusConf(data?.campaignStatus);
        return (
          <Tooltip overlayStyle={{maxWidth: 370}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} placement={'left'} title={getTooltipTitle(data?.campaignStatus, data?.createdAt)}>
            <StatusDiv>
              <div className='label-icon'>
                <FontAwesomeIcon icon={statusData?.icon} color={statusData?.strokeColor} />
                <div style={{whiteSpace: 'nowrap'}}>{statusData?.label}</div>
              </div>
              <StyledProgress percent={statusData?.percentage} size='small' strokeColor={statusData?.strokeColor} />
            </StatusDiv>
          </Tooltip>
        );
      },
    },
    {
      title: <div className='column-title'>DATA AGGREGATOR</div>,
      dataIndex: 'data-aggregator',
      key: 'data-aggregator',
      render: (_, record) => {
        return (<TextContainer>{record?.aggregators.join(',')?? '-'}</TextContainer>);
      },
    },
    {
      title: <div className='column-title'>CITATIONS</div>,
      dataIndex: 'citations',
      key: 'citations',
      render: (_, record) => {
        return (<TextContainer>{record?.citations ?? '-'}</TextContainer>);
      },
    },
    {
      title: <div className='column-title'>AMOUNT</div>,
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => {
        return (<TextContainer>{record?.amountPaid ? `$${record?.amountPaid}` : '-'}</TextContainer>);
      },
    },
    {
      title: (<div className='column-title'>SUBMITTED ON</div>),
      dataIndex: 'submitted-on',
      key: 'submitted-on',
      width: 158,
      render: (_, record) => {
        return (<TextContainer>{record?.createdAt ? moment(record?.createdAt).format('MMM DD, YYYY') : '-'}</TextContainer>);
      },
    },
    {
      title: '',
      dataIndex: 'view',
      key: 'view',
      width: 44,
      render: (_, record) => {
        return (<StyledButton onClick={() => onCitationView(record)}>View citations</StyledButton>);
      },
    },
  ];
  return (
    <>
      <StyledIssuesTable
        loading={ottoUrlLoader && ottoIssueType === componentIssueType}
        columns={columns}
        onChange={pagination => {
          const pageSize = pagination.pageSize;
          saveOttoTablePageSize(componentIssueType, pageSize);
          setPageSize(pageSize);
        }}
        dataSource={tableData}
        pagination={tableData.length ? {
          pageSize: pageSize,
          showSizeChanger: true,
          showPrevNextJumpers: true,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} results shown`,
          showLessItems: true,
        } : false}
        scroll={{x: 'auto'}}
      />
      <ViewCitationModal openCitationModal={openCitationModal} setOpenCitationModal={setOpenCitationModal} openedBusinessInModal={openedBusinessInModal} />
    </>
  );
});

const TextContainer = styled.div`
  margin-top: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #121212;
  .address {
    margin-top: 5px;
    font-size: 12px;
    line-height: 14.52px;
    color: #4E5156;
  }
`;

const StatusDiv = styled.div`
  .label-icon {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const StyledProgress = styled(Progress)`
width: 130px;
.ant-progress-text {
  display: none !important;
}
.ant-progress-inner {
  background-color: #E8E8E8 !important;
}
.ant-progress-outer {
  padding-right: 0px !important;
}
`;

const StyledButton = styled.div`
  white-space: nowrap;
  border-radius: 4px;
  padding: 5px 10px;
  border: 1px solid #E8E8E8;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: '#121212';
  cursor: pointer;
  &:hover {
    background-color: grey;
    color: white;
  }
`;
