import {cast} from 'mobx-state-tree';
import numeral from 'numeral';

export const getPrimaryWebsite = property=>{
  const positionHistograms = property?.reports?.dailySerpHistogramsAndClicks?.positionHistograms?.filter(item => !item.isProjected);
  const positionHistogramsOneMonth = positionHistograms;
  const dailySerpHistogramsAndClicks = positionHistograms?.length ? positionHistograms[positionHistograms.length-1] : null;
  const nonProjectedKeywords = property?.reports?.keywordCountHistoricalChart?.filter(data => !data?.isProjected);
  const data = {
    activeCountryCodes: property.activeCountryCodes ?? [],
    dr: property.domainRating ? property.domainRating: 0,
    hostname: property?.url,
    keywordData: cast(property?.reports?.keywordCountHistoricalChart?.map(data => {
      return {date: data.date, kwCount: data.kwCount};
    })),
    nonProjectedKeywordData: cast(nonProjectedKeywords?.map(data => {
      if (!data?.isProjected) {
        return {date: data.date, kwCount: data.kwCount};
      }
    })),
    impressionData: cast(positionHistogramsOneMonth?.map(data => {
      return {date: data?.date, impressions: data?.impressions};
    })),
    clicksData: cast(positionHistogramsOneMonth?.map(data => {
      return {date: data?.date, clicks: data?.clicks};
    })),
    trafficData: cast(positionHistogramsOneMonth?.map(data => {
      return {date: data?.date, trafficValue: data?.trafficValue};
    })),
    refdomainCount: property?.reports?.totalImpressions,
    reportsForCountry: property?.reports?.reportsForCountry,
    ot: property?.reports?.totalTraffic,
    keywordCount: property?.reports?.totalKeywords?.current,
    countryCodesAndTrafficValue: property?.trafficValue,
    positionHistogramData: cast(positionHistogramsOneMonth?.map(data => {
      return {
        date: data?.date,
        serp1: data?.serp1,
        serp2To3: data?.serp2To3,
        serp4To10: data?.serp4To10,
        serp11To20: data?.serp11To20,
        serp2150: data?.serp2150,
        serp51100: data?.serp51100,
      };
    })),
    trafficvalue: property?.reports?.trafficValue,
    topPages: property?.reports?.topPages?.map(value => value?.url),
    url: property?.url,
    id: property.id,
    avgPosition: property?.avgPosition,
    impressionsHistogramsPctChange: property?.reports?.dailySerpHistogramsAndClicks?.impressionsHistogramsPctChange,
    organicTrafficHistogramsPctChange: property?.reports?.dailySerpHistogramsAndClicks?.organicTrafficHistogramsPctChange,
    screenshotImage: property?.favicon,
    keywordPosition: [
      {
        name: '# 1-3',
        value: numeral(dailySerpHistogramsAndClicks?.serp1To3).format('0a'),
      },
      {
        name: '# 4-10',
        value: numeral(dailySerpHistogramsAndClicks?.serp4To10).format('0a'),
      },
      {
        name: '# 11-20',
        value: numeral(dailySerpHistogramsAndClicks?.serp11To20).format('0a'),
      },
      {
        name: '# 20+',
        value: numeral(dailySerpHistogramsAndClicks?.serp21).format('0a'),
      },
    ],
    activeForCurrentCustomer: property?.activeForCurrentCustomer,
  };
  return data;
};
