import {useState, useEffect} from 'react';
import styles from './style.module.scss';
import {faDownload} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Button,
} from '@/components/common-components/v2/Button';
import {CopyOutlined} from '@ant-design/icons';

import {Divider, Tooltip} from 'antd';
import {faCheck, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {SCA_PAGE_CONTENT_IDEA_COPY, SCA_PAGE_AI_OUTLINE_DOWNLOAD} from '@/constants/events';

import {MixPanel} from '@/utils/mixpanel';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import reactHtmlParser from 'html-react-parser';
import {debounce} from 'lodash';
import {ContentState, EditorState, Modifier, SelectionState} from 'draft-js';
import {useCallback} from 'react';
import {copyToClipboardWithHtmlAndText} from '@/utils/clipboard';

interface Props {
  aiOutline: any;
  htmlToDraft: any;
  outlineId: number;
}

export const SingleAiOutline = ({aiOutline, htmlToDraft, outlineId}: Props) => {
  const {contentOptimizer: {currentPage}} = useStore('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [convertedText, setConvertedText] = useState(null);
  const [convertedHeading, setConvertedHeading] = useState(null);
  const [textForInjection, setTextForInjection] = useState(null);
  const [added, setIsAdded] = useState(false);

  const {t} = useTranslation('landing-page-optimizer');


  useEffect(() => {
    // if parsed response comes in as an array of html objects
    // check if first item is h1 and if so extract it to custom heading for printing purposes
    // then check if rest of nodes start with break, and if so remove it.
    // if this is all false, just push all parsed text as one text state
    let formattedHeading = [];
    let formattedParagraph = [];
    if (reactHtmlParser(aiOutline) && Array.isArray(reactHtmlParser(aiOutline)) && reactHtmlParser(aiOutline)[0] && reactHtmlParser(aiOutline)[1] && reactHtmlParser(aiOutline)[0]?.type == 'h1') {
      formattedHeading = (reactHtmlParser(aiOutline) as any[]).slice(0, 1);
      formattedParagraph = (reactHtmlParser(aiOutline) as any[]).slice(1);
      setConvertedHeading(formattedHeading);
      if (formattedParagraph[0]?.type == 'br') {
        formattedParagraph = formattedParagraph.slice(1);
        setConvertedText(formattedParagraph);
      }
    } else {
      const newText = reactHtmlParser(aiOutline);
      setConvertedText(newText);
    }

    // eslint-disable-next-line
    setTextForInjection(aiOutline?.replace(/<br\s*[\/]?>/gi, '\n'));
  }, [aiOutline]);


  const downloadFileToTxt = () => {
    const element = document.createElement('a');
    const file = new Blob([aiOutline.replace(/(<([^>]+)>)/ig, '\n')], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    const breakFileName = currentPage.content?.title?.split(' ')?.length;
    const fileName = breakFileName && breakFileName > 4 ? currentPage.content?.title?.split(' ').slice(0, 4).join('-') : currentPage.content?.title?.split(' ').slice(0, breakFileName).join('-');
    element.download = `${fileName}`;
    document.body.appendChild(element);
    element.click();
  };

  const debouncedChange = debounce((state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations = false) => {
    // we are then calling the edit content method which will fire the API call and send new state to the BE
    currentPage.editContent(state, isNewDecorations);
  }, 2000);


  const debouncedUpdate = useCallback(
    (state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations) => debouncedChange(state, focusTerms, focusTermsClassNames, isNewDecorations),
    // eslint-disable-next-line
    []
  );

  const insertText = async (aiOutline: any) => {
    // NEW FLOW start
    const {contentBlocks, entityMap} = htmlToDraft(aiOutline);

    const selection = currentPage.currentEditorState.getSelection();

    const newSelection = new SelectionState({
      anchorKey: selection.getAnchorKey(),
      anchorOffset: selection.getEndOffset(),
      focusKey: selection.getAnchorKey(),
      focusOffset: selection.getEndOffset(),
    });


    const fragment = ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap();

    const nonStyledState = Modifier.replaceWithFragment(
      currentPage.currentEditorState.getCurrentContent(),
      newSelection,
      fragment,
    );

    // WE are calculating nodes to toggle blue background on new Ai generated nodes
    let firstNewBlockId: number;
    const currentBlock = selection.getAnchorKey();
    const nextBlock = currentPage.currentEditorState.getCurrentContent().getBlockAfter(selection.getAnchorKey())?.getKey();

    const existingNodes = document.querySelectorAll('[data-block="true"]');
    existingNodes?.forEach((elem, id) => {
      if (elem.getAttribute('data-offset-key') && elem.getAttribute('data-offset-key') == `${currentBlock}-0-0`) {
        firstNewBlockId = id + 1;
      }
    });


    const updatedState = EditorState.push(currentPage.currentEditorState, nonStyledState, 'insert-fragment');
    currentPage.setCurrentEditorState(updatedState);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('[data-block="true"]');
      if (newTextElems.length) {
        for (let i = firstNewBlockId; i <= newTextElems.length; i++) {
          if (newTextElems[i]?.getAttribute('data-offset-key') && newTextElems[i]?.getAttribute('data-offset-key') != `${nextBlock}-0-0` && newTextElems[i]?.getAttribute('data-offset-key') != `${currentBlock}-0-0`) {
            newTextElems[i].classList.add('newTextBlock');
          } else {
            return;
          }
        }
      }
    }, 500);

    debouncedUpdate(updatedState, currentPage.analytics?.focusTerms, currentPage.focusTermsClassNames, false);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('.newTextBlock');
      if (newTextElems.length) {
        newTextElems.forEach(elem => {
          elem.classList.remove('newTextBlock');
        });
      }
    }, 3500);
    // NEW FLOW END

    currentPage.setShowAiBanner(false);
  };

  const countWords = s => {
    s = s.replace(/(^\s*)|(\s*$)/gi, '');// exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' ');// 2 or more space to 1
    s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
    return s.split(' ').filter(function(str) {
      return str != '';
    }).length;
    // return s.split(' ').filter(String).length; - this can also be used
  };

  return (
    <div className={styles.ContentIdeaContainer}>
      <div className={styles.ideaOrder}>{outlineId + 1}</div>
      {convertedHeading ? <>
        <h2 style={{lineHeight: '35px'}}>
          {convertedHeading}
        </h2>
        <p className={styles.ContentIdea}>
          {convertedText}
        </p>
      </> : <>
        <p className={styles.ContentIdea}>
          {convertedText}
        </p>
      </>
      }
      <Divider className={styles.popoverDivider} />
      <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'default'}}>

        <div className={styles.controlButtons}>
          <Button
            color={added ? 'green' : 'purple'}
            variant='ghost'
            className={styles.addToArticleBtn}
            onClick={() => {
              insertText(`<p></p>` + textForInjection);
              setIsAdded(true);
            }}>
            <span>
              {added ? <span>
                <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}}/>
                Added
              </span> :
                <span>
                  <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>
                Add to article
                </span>}
            </span>
          </Button>
          <Tooltip
            title={copySuccess ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{` Copied to clipboard`}</span> : t('copy-to-clipboard')}
            trigger='hover'
            placement='bottom'
            overlayClassName={styles.copyTooltip}
            onVisibleChange={visible => {
              if (!visible) {
                setTimeout(() => {
                  setCopySuccess(false);
                }, 300);
              }
            }}>
            <Button
              color='white'
              className={styles.copyButton}
              onClick={() => {
                copyToClipboardWithHtmlAndText(`<p></p>${aiOutline}`, aiOutline.replace(/<[^>]*>/g, ''));
                setCopySuccess(true);


                MixPanel.track(SCA_PAGE_CONTENT_IDEA_COPY, {
                  'description': 'Content idea copied',
                });
              }}>
              <span>
                <CopyOutlined style={{marginRight: '5px'}} />
                Copy
              </span>
            </Button>
          </Tooltip>
          <Tooltip
            title={'Download'}
            trigger='hover'
            placement='bottom'
            overlayClassName={styles.copyTooltip}
          >
            <FontAwesomeIcon icon={faDownload} className={styles.downloadButton} onClick={() => {
              downloadFileToTxt();

              MixPanel.track(SCA_PAGE_AI_OUTLINE_DOWNLOAD, {
                'description': 'Content idea downloaded',
              });
            }} />
          </Tooltip>
          <span>{`${countWords(aiOutline)} words`}</span>
        </div>
      </div>
    </div>
  );
};
