import {types, flow} from 'mobx-state-tree';
import {stripeApi} from '@/api/account';
import {notification} from '@/utils/notification-v2';

export const Stripe = types.model({
  tokenId: types.string,
}).views(self => ({

  get getTokenId() {
    return self.tokenId;
  },

})).actions(self => {
  const createPaymentMethod = flow(function* (tokenId: string) {
    try {
      self.tokenId = tokenId;
      yield stripeApi.createPaymentMethod(self.tokenId);
    } catch (e) {
      notification.error('Error Payment Method', 'Error occured while adding new payment method.');
    }
  });

  const updatePaymentMethod = flow(function* (paymentMethodId, paymentData) {
    try {
      yield stripeApi.updatePaymentMethod(paymentMethodId, paymentData);
    } catch (e) {
      notification.error('Error Payment Method Update', 'Error occured while updating the payment method.');
    }
  });
  return {
    createPaymentMethod, updatePaymentMethod,
  };
});

export function initStripe() {
  return Stripe.create({
    tokenId: '',
  });
}
