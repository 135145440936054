import {faBolt} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';

interface Props {
  loading: boolean;
  variant?: 'top' | 'center';
}

export const EmptyStateComponentV3 = observer(({
  loading,
  variant,
}: Props) => {
  const {contentOptimizer: {aiOutline: {
    creatingAiArticleSuccessStatus,
  }}} = useStore('');

  return <>
    {!loading && !creatingAiArticleSuccessStatus && <Wrapper isTop={variant == 'top'}>
      <FontAwesomeIcon icon={faBolt} color={'#4E5156'} fontSize={30} style={{marginRight: 10}}/>
      <Description isTop={variant == 'top'}>Fill the input fields and generate unique content.</Description>
    </Wrapper>}</>;
});

const Wrapper = styled.div<{isTop?: boolean}>`
  width: ${p => p.isTop ? 'calc(100% + 40px)' : `320px`};
  height: 72px;
  border-radius: ${p => p.isTop ? '16px 16px 0 0' : `16px`};
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: ${p => p.isTop ? '30px' : `20px`};
  padding-right: 20px;
  margin: ${p => p.isTop ? '-40px -20px 0 -20px' : `60% auto`};
`;

const Description = styled.div<{isTop?: boolean}>`
  color: #4E5156;
  width: ${p => p.isTop ? '100%' : `220px`};
  
`;
