import {types, flow, cast, getParent, Instance} from 'mobx-state-tree';
import {WatchlistClient} from '@/api/gsc';
import {Watchlist as WatchlistType, MAIN_WATCHLIST} from '@/api/gsc/watchlist/watchlist.types';
import {BannerVariant} from '@/components/common-components/components/banner';
import {toJS} from 'mobx';
import {getTokenFromCookies} from '@/api/common-utils';

const Watchlist = types.model({
  id: types.maybeNull(types.number),
  keywords: types.maybeNull(types.array(types.string)),
  pageGroups: types.maybeNull( types.array(types.number)),
  pages: types.maybeNull(types.array(types.string)),
  name: types.maybeNull(types.string),
});

export const WatchlistsStore = types
  .model({
    content: types.array(Watchlist),
    loading: types.optional(types.boolean, false),
  })
  .views(self => ({
    /**
     * Returns the main watchlist (current UI does not support multiple watchlists).
     *
     * @get mainWatchlist
     */
    get mainWatchlist() {
      return self.content.find(item => item.name === MAIN_WATCHLIST);
    },
  }))
  .views(self => ({
    /**
     * Checks if the given entity is in main watchlist.
     *
     * @param {string | number} payload
     * @param {'page_group' | 'page'} entityType
     * @return {boolean}
     */
    isEntityInMainWatchlist(payload: string | number, entityType: 'page_group' | 'page' | 'keyword'): boolean {
      if (toJS(self.mainWatchlist)) {
        if (entityType === 'page_group') {
          return toJS(self.mainWatchlist)?.pageGroups.includes(payload as number);
        }
        if (entityType === 'page') {
          return toJS(self.mainWatchlist)?.pages.includes(payload as string);
        }
        if (entityType === 'keyword') {
          return toJS(self.mainWatchlist)?.keywords.includes(payload as string);
        }
      } else {
        return false;
      }
    },
  }))
  .actions(self => {
    /**
     * Fetches all? of the watchlists and sets them to the content.
     *
     * @method fetchWatchlist
     */
    const fetchWatchlists = flow(function* () {
      const parent = getParent(self) as any;
      const siteProperty = parent.criteria.property;
      const countryCode = parent.criteria.countryCode;

      if (getTokenFromCookies()) {
        try {
          self.loading = true;
          // https://github.com/microsoft/TypeScript/issues/32523
          const data: WatchlistType[] = yield WatchlistClient.get({siteProperty, countryCode});
          const checkValue: any = data;
          if (checkValue.isCancel) return;
          self.content = cast(data);
          self.loading = false;
        } catch (e) {
          const parent = getParent(self) as any;
          parent.setBanner(true, 'Erro fetrching Watch Lists', 'The Watch Lists data did not load properly. To fix the issue:', BannerVariant.ERROR);
          self.loading = false;
          throw e;
        }
      }
    });

    const addKeywordToMainWatchlist = flow(function* (payloadKw: string) {
      self.loading = true;
      const parent = getParent(self) as any;
      const siteProperty = parent.criteria.property;

      let keywords: string[] = [];
      let pages: string[] = [];
      let pageGroups: number[] = [];

      if (self.mainWatchlist) {
        const doesExistKw = self.isEntityInMainWatchlist(payloadKw, 'keyword');

        if (doesExistKw) {
          keywords = self.mainWatchlist.keywords.filter(keyword => keyword !== payloadKw);
        } else {
          keywords = [...self.mainWatchlist.keywords, payloadKw];
        }

        pages = [...self.mainWatchlist.pages];
        pageGroups = [...self.mainWatchlist.pageGroups];

        const data: WatchlistType = yield WatchlistClient.update({
          id: self.mainWatchlist.id,
          keywords,
          pages,
          pageGroups,
          siteProperty,
        });

        const previousValue = self.content.filter(watchlist => watchlist.name !== MAIN_WATCHLIST);

        self.content = cast([...previousValue, data]);
      } else {
        const data: WatchlistType = yield WatchlistClient.create({
          name: MAIN_WATCHLIST,
          keywords: [payloadKw],
          siteProperty,
        });
        self.content = cast([...self.content, data]);
      }

      self.loading = false;
    });

    /**
     * Adds the page group to the main watchlist if it exists, if not, it initializes watchlist with the pageGroup provided.
     *
     * @method addPageGroupToMainWatchlist
     */
    const addPageGroupToMainWatchlist = flow(function* (payload: number) {
      try {
        self.loading = true;
        const parent = getParent(self) as any;
        const siteProperty = parent.criteria.property;

        let pageGroups: number[] = [];
        let pages: string[] = [];
        let keywords: string[] = [];

        if (self.mainWatchlist) {
          const doesExist = self.isEntityInMainWatchlist(payload, 'page_group');

          if (doesExist) {
            pageGroups = self.mainWatchlist.pageGroups.filter(id => id !== payload);
          } else {
            pageGroups = [...self.mainWatchlist.pageGroups, payload];
          }

          pages = [...self.mainWatchlist.pages];
          keywords = [...self.mainWatchlist.keywords];

          const data: WatchlistType = yield WatchlistClient.update({
            id: self.mainWatchlist.id,
            pages,
            keywords,
            pageGroups,
            siteProperty,
          });
          const previousValue = self.content.filter(watchlist => watchlist.name !== MAIN_WATCHLIST);

          self.content = cast([...previousValue, data]);
        } else {
          const data: WatchlistType = yield WatchlistClient.create({
            name: MAIN_WATCHLIST,
            pageGroups: [payload],
            siteProperty,
          });
          self.content = cast([...self.content, data]);
        }
        // parent.coreReports.pageGroupingStatsStore.updatePageGroupings(parent.criteria);
        self.loading = false;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    });

    /**
     * Adds the page to the main watchlist if it exists, if not, it initializes watchlist with the page provided.
     *
     * @method addPageToMainWatchlist
     */
    const addPageToMainWatchlist = flow(function* (payload: string) {
      try {
        self.loading = true;
        let pages: string[] = [];
        let pageGroups: number[] = [];
        let keywords: string[] = [];
        const parent = getParent(self) as any;
        const siteProperty = parent.criteria.property;

        if (self.mainWatchlist) {
          const doesExist = self.isEntityInMainWatchlist(payload, 'page');

          if (doesExist) {
            pages = self.mainWatchlist.pages.filter(page => page !== payload);
          } else {
            pages = [...self.mainWatchlist.pages, payload];
          }

          pageGroups = [...self.mainWatchlist.pageGroups];
          keywords = [...self.mainWatchlist.keywords];

          const data: WatchlistType = yield WatchlistClient.update({
            id: self.mainWatchlist.id,
            pages,
            siteProperty,
            keywords,
            pageGroups,
          });

          const previousValue = self.content.filter(watchlist => watchlist.name !== MAIN_WATCHLIST);

          self.content = cast([...previousValue, data]);
        } else {
          const data: WatchlistType = yield WatchlistClient.create({
            name: MAIN_WATCHLIST,
            pages: [payload],
            siteProperty,
          });
          self.content = cast([...self.content, data]);
        }

        self.loading = false;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    });

    return {
      fetchWatchlists,
      addPageToMainWatchlist,
      addKeywordToMainWatchlist,
      addPageGroupToMainWatchlist,
    };
  });

export type WatchlistsStoreInstance = Instance<typeof WatchlistsStore>;

export const initWatchlistsStore = () => {
  return WatchlistsStore.create({
    content: [],
    loading: false,
  });
};
