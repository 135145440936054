import {cast, flow, getParent, Instance, types} from 'mobx-state-tree';
import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import {
  DisavowResponseItem,
  TaskStatus,
  ToxicBacklinksData,
  ToxicityType,
  WhitelistResponseItem,
} from '@/api/backlink-analyzer/backlink-analyzer.model';
import type {BackLinkAnalyzerStoreType} from '.';
import {BannerVariant} from '@/components/common-components/components/banner';
import {sanitizeUrl} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {notification as newNotification} from '@/utils/notification-v2';
import {
  BACKLINK_API_TIME_INTERVAL,
  BACKLINK_API_API_POLL_INTERVAL,
} from '@/constants';

const aggregatedEmpty = {
  nonToxic: {},
  possiblyToxic: {},
  toxic: {},
};
const ToxicBacklinkModel = types.model({
  toxicity: types.enumeration<ToxicityType>('ToxicityType', Object.values(ToxicityType)),
  color: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  urlTo: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  domainTo: types.maybeNull(types.string),
  anchorText: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
  confidence: types.maybeNull(types.number),
  toxicityReason: types.maybeNull(types.string),
});

const AggregatedItemModel = types.model({
  amount: types.maybeNull(types.number),
  percentage: types.maybeNull(types.number),
});

const AggregatedModel = types.model({
  nonToxic: types.maybeNull(AggregatedItemModel),
  possiblyToxic: types.maybeNull(AggregatedItemModel),
  toxic: types.maybeNull(AggregatedItemModel),
});

const ToxicBacklinkTableParams = types.model({
  isOpen: types.boolean,
  search: types.maybeNull(types.string),
  toxicityFilters: types.array(types.enumeration<ToxicityType>('ToxicityType', Object.values(ToxicityType))),
  toxicScoreFilter: types.array(types.number),
  currentPage: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  sorter: types.model({
    field: types.maybeNull(types.string),
    order: types.maybeNull(types.string),
  }),
  selectedUrls: types.array(types.string),
});

const DisavowTableParams = types.model({
  search: types.maybeNull(types.string),
  typeFilters: types.array(types.string),
  currentPage: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  selectedUrls: types.array(types.string),
});

const WhitelistTableParams = types.model({
  search: types.maybeNull(types.string),
  typeFilters: types.array(types.string),
  currentPage: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  selectedUrls: types.array(types.string),
});

const DisavowItemModel = types.model({
  url: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const WhitelistItemModel = types.model({
  url: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const ToxicBacklinksStore = types.model({
  loading: types.boolean,
  toxicBacklinks: types.array(ToxicBacklinkModel),
  aggregated: AggregatedModel,
  disavow: types.array(DisavowItemModel),
  whitelist: types.array(WhitelistItemModel),
  toxicBacklinksTableParams: ToxicBacklinkTableParams,
  disavowTableParams: DisavowTableParams,
  whitelistTableParams: WhitelistTableParams,
  tasksComplete: types.optional(types.boolean, false),
  reTry: types.maybeNull(types.number),
}).views(self => ({
  get filteredToxicBacklinks() {
    let filteredToxicBacklinks = [...self.toxicBacklinks];

    const {search, toxicityFilters, toxicScoreFilter, currentPage, pageSize, sorter} = self.toxicBacklinksTableParams;

    if (search) {
      filteredToxicBacklinks = filteredToxicBacklinks.filter(toxicBacklink => {
        return toxicBacklink.anchorText.toLowerCase().includes(search.toLowerCase()) || sanitizeUrl(toxicBacklink.url).includes(search.toLowerCase());
      });
    }

    if (toxicityFilters.length) {
      filteredToxicBacklinks = filteredToxicBacklinks.filter(toxicBacklink => toxicityFilters.includes(toxicBacklink.toxicity));
    }

    if (toxicScoreFilter.length) {
      const [min, max] = toxicScoreFilter;
      filteredToxicBacklinks = filteredToxicBacklinks.filter(toxicBacklink => (toxicBacklink.score-min)*(toxicBacklink.score-max) <= 0);
    }

    if (sorter.field && sorter.order) {
      filteredToxicBacklinks.sort( (a, b) => {
        const _dir = sorter.order === 'ascend' ? 1 : -1;
        if (a[sorter.field] > b[sorter.field]) return 1 * _dir;
        if (a[sorter.field] < b[sorter.field] * _dir) return -1 * _dir;
        return 0;
      });
    }

    const startIdx = currentPage * pageSize - pageSize;
    const endIdx = startIdx + pageSize;
    const response = filteredToxicBacklinks.slice(startIdx, endIdx);

    return {
      data: response,
      length: filteredToxicBacklinks.length,
    }
    ;
  },
  get filteredDisavow() {
    let filteredDisavow = [...self.disavow];

    const {search, typeFilters, currentPage, pageSize} = self.disavowTableParams;

    if (search) {
      filteredDisavow = filteredDisavow.filter(item => {
        return item.url.includes(search.toLowerCase()) || sanitizeUrl(item.url).includes(search.toLowerCase());
      });
    }

    if (typeFilters.length) {
      filteredDisavow = filteredDisavow.filter(item => typeFilters.includes(item.type));
    }

    const startIdx = currentPage * pageSize - pageSize;
    const endIdx = startIdx + pageSize;
    const response = filteredDisavow.slice(startIdx, endIdx);

    return {
      data: response,
      length: filteredDisavow.length,
    };
  },
  get filteredWhitelist() {
    let filteredWhitelist = [...self.whitelist];

    const {search, typeFilters, currentPage, pageSize} = self.whitelistTableParams;

    if (search) {
      filteredWhitelist = filteredWhitelist.filter(item => {
        return item.url.includes(search.toLowerCase()) || sanitizeUrl(item.url).includes(search.toLowerCase());
      });
    }

    if (typeFilters.length) {
      filteredWhitelist = filteredWhitelist.filter(item => typeFilters.includes(item.type));
    }

    const startIdx = currentPage * pageSize - pageSize;
    const endIdx = startIdx + pageSize;
    const response = filteredWhitelist.slice(startIdx, endIdx);

    return {
      data: response,
      length: filteredWhitelist.length,
    };
  },
})).actions(self => {
  const loadToxicBacklinksData = flow(function* (pk: number) {
    self.loading = true;
    const parentBL = getParent<BackLinkAnalyzerStoreType>(self);
    try {
      const toxicBackLinks = <ToxicBacklinksData>(yield BacklinkAnalyzerClient.getToxicBacklinks(pk));
      self.tasksComplete = toxicBackLinks.ahrefs == TaskStatus.SUCCESS;
      self.reTry = cast(self.reTry + 1);
      if (!self.tasksComplete && self.reTry >= BACKLINK_API_TIME_INTERVAL) {
        parentBL.setBanner(true, 'Toxic Backlinks not actualized.', 'The Toxic Backlinks data did not load properly. Please refresh your page to fix the issue.', BannerVariant.ERROR);
        self.reTry = 0;
        return toxicBackLinks;
      } else if (!self.tasksComplete) {
        yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
        return loadToxicBacklinksData(pk);
      } else {
        self.reTry = 0;
      }
      const disavow: DisavowResponseItem[] = yield BacklinkAnalyzerClient.getDisavow(pk);
      const whitelist: WhitelistResponseItem[] = yield BacklinkAnalyzerClient.getWhitelist(pk);
      self.toxicBacklinks = cast(toxicBackLinks?.toxicBacklinks);
      self.aggregated = cast(toxicBackLinks ? toxicBackLinks.aggregated : aggregatedEmpty );
      self.disavow = cast(disavow || []);
      self.whitelist = cast(whitelist || []);
    } catch (e) {
      parentBL.setBanner(true, 'Toxic Backlinks not actualized.', 'The Toxic Backlinks data did not load properly. Please refresh your page to fix the issue.', BannerVariant.ERROR);
      Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  const setIsOpen = (bool: boolean)=>{
    self.toxicBacklinksTableParams.isOpen = bool;
  };

  const moveToxicBacklinksToDisavow = flow(function* (pk: number, selectedUrls: string[]) {
    try {
      const disavowUrls = [...self.disavow.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const alreadyMoved = selectedUrls.filter(x => disavowUrls.includes(x)).length;
      const disavow: DisavowResponseItem[] = yield BacklinkAnalyzerClient.postToDisavow(pk,
        [
          ...disavowUrls,
          ...selectedUrls.filter(x => !disavowUrls.includes(x)),
        ]);
      self.disavow = cast(disavow);
      if (alreadyMoved === 0) {
        notification.success(`Successfully moved ${selectedUrls.length} URL(s) to disavow`);
      } else if (alreadyMoved === selectedUrls.length) {
        notification.success(`all URL(s) are already in disavow list`);
      } else {
        notification.success(`Successfully moved ${selectedUrls.length - alreadyMoved} URL(s) to disavow and ${alreadyMoved} were already moved`);
      }

      self.toxicBacklinksTableParams.selectedUrls = cast([]);
    } catch (e) {
      Promise.reject(e);
    }
  });

  const moveToxicBacklinksToWhitelist = flow(function* (pk: number, selectedUrls: string[]) {
    try {
      const whitelistUrls = [...self.whitelist.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const whitelist: WhitelistResponseItem[] = yield BacklinkAnalyzerClient.postToWhitelist(pk,
        [
          ...whitelistUrls,
          ...selectedUrls.filter(x => !whitelistUrls.includes(x)),
        ]);
      self.whitelist = cast(whitelist);
      whitelist.length ? notification.success(`Successfully moved ${selectedUrls.length} URL(s) to whitelist`):
        newNotification.error('Error', 'Input valid urls please');

      self.toxicBacklinksTableParams.selectedUrls = cast([]);
    } catch (e) {
      if (e) {
        newNotification.error('Error', 'Refresh the page');
      }
    }
  });

  const cleanSelectedUrls = () =>{
    self.toxicBacklinksTableParams.selectedUrls = cast([]);
  };
  const cleanDisawovedSelectedUrls = () =>{
    self.disavowTableParams.selectedUrls = cast([]);
  };

  const moveDisavowToWhitelist = flow(function* (pk: number, selectedUrls: string[]) {
    try {
      const disavowUrls = [...self.disavow.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const whitelistUrls = [...self.whitelist.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const disavow: DisavowResponseItem[] = yield BacklinkAnalyzerClient.postToDisavow(pk,
        [
          ...disavowUrls.filter(x => !selectedUrls.includes(x)),
        ]);
      const whitelist: WhitelistResponseItem[] = yield BacklinkAnalyzerClient.postToWhitelist(pk,
        [
          ...whitelistUrls,
          ...selectedUrls.filter(x => !whitelistUrls.includes(x)),
        ]);
      self.disavow = cast(disavow);
      self.whitelist = cast(whitelist);
      notification.success(`Successfully moved ${selectedUrls.length} URL(s) to whitelist`);
      self.disavowTableParams.selectedUrls = cast([]);
    } catch (e) {
      Promise.reject(e);
    }
  });

  const moveWhitelistToDisavow = flow(function* (pk: number, selectedUrls: string[]) {
    try {
      const whitelistUrls = [...self.whitelist.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const disavowUrls = [...self.disavow.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const whitelist: WhitelistResponseItem[] = yield BacklinkAnalyzerClient.postToWhitelist(pk,
        [
          ...whitelistUrls.filter(x => !selectedUrls.includes(x)),
        ]);
      const disavow: DisavowResponseItem[] = yield BacklinkAnalyzerClient.postToDisavow(pk,
        [
          ...disavowUrls,
          ...selectedUrls.filter(x => !disavowUrls.includes(x)),
        ]);
      self.whitelist = cast(whitelist);
      self.disavow = cast(disavow);
      notification.success(`Successfully moved ${selectedUrls.length} URL(s) to disavow`);
      self.whitelistTableParams.selectedUrls = cast([]);
    } catch (e) {
      Promise.reject(e);
    }
  });

  const removeFromDisavow = flow(function* (pk: number, selectedUrls: string[]) {
    try {
      const disavowUrls = [...self.disavow.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const disavow: DisavowResponseItem[] = yield BacklinkAnalyzerClient.postToDisavow(pk,
        [
          ...disavowUrls.filter(x => !selectedUrls.includes(x)),
        ]);
      self.disavow = cast(disavow);
      self.disavowTableParams.selectedUrls = cast([]);
      self.whitelistTableParams.selectedUrls = cast([]);
    } catch (e) {
      Promise.reject(e);
    }
  });

  const removeFromWhitelist = flow(function* (pk: number, selectedUrls: string[]) {
    try {
      const whitelistUrls = [...self.whitelist.map(x => x.type === 'Domain' ? `domain:${x.url}` : x.url)];
      const whitelist: WhitelistResponseItem[] = yield BacklinkAnalyzerClient.postToWhitelist(pk,
        [
          ...whitelistUrls.filter(x => !selectedUrls.includes(x)),
        ]);
      self.whitelist = cast(whitelist);
      self.whitelistTableParams.selectedUrls = cast([]);
    } catch (e) {
      Promise.reject(e);
    }
  });

  const setToxicBacklinksSearch = (search: string) => {
    self.toxicBacklinksTableParams.search = search;
    self.toxicBacklinksTableParams.currentPage = 1;
  };

  const setDisavowSearch = (search: string) => {
    self.disavowTableParams.search = search;
    self.disavowTableParams.currentPage = 1;
  };

  const setWhitelistSearch = (search: string) => {
    self.whitelistTableParams.search = search;
    self.whitelistTableParams.currentPage = 1;
  };

  const setToxicBacklinksToxicityFilters = (filters: ToxicityType[]) => {
    self.toxicBacklinksTableParams.toxicityFilters = cast(filters);
    self.toxicBacklinksTableParams.currentPage = 1;
  };

  const setDisavowTypeFilters = (filters: string[]) => {
    self.disavowTableParams.typeFilters = cast(filters);
    self.disavowTableParams.currentPage = 1;
  };

  const setWhitelistTypeFilters = (filters: string[]) => {
    self.whitelistTableParams.typeFilters = cast(filters);
    self.whitelistTableParams.currentPage = 1;
  };

  const setToxicBacklinksToxicScoreFilter = filters => {
    const [filter] = filters;
    self.toxicBacklinksTableParams.toxicScoreFilter = cast([+filter.from || 0, +filter.to || 100]);
    self.toxicBacklinksTableParams.currentPage = 1;
  };

  const setToxicBacklinksCurrentPage = (currentPage: number) => {
    self.toxicBacklinksTableParams.currentPage = currentPage;
  };

  const setDisavowCurrentPage = (currentPage: number, pageSize:number) => {
    self.disavowTableParams.currentPage = currentPage;
    self.disavowTableParams.pageSize = pageSize;
  };

  const setWhitelistCurrentPage = (currentPage: number, pageSize:number) => {
    self.whitelistTableParams.currentPage = currentPage;
    self.whitelistTableParams.pageSize = pageSize;
  };

  const setToxicBacklinksSorter = (sorter: any) => {
    const {field, order} = sorter;
    self.toxicBacklinksTableParams.sorter = {field: field, order};
  };

  const toggleToxicBacklinkSelect = (url: string) => {
    const index = self.toxicBacklinksTableParams.selectedUrls.indexOf(url);
    index === -1 ? self.toxicBacklinksTableParams.selectedUrls.push(url) : self.toxicBacklinksTableParams.selectedUrls.splice(index, 1);
  };

  const toggleDisavowSelect = (url: string) => {
    const index = self.disavowTableParams.selectedUrls.indexOf(url);
    index === -1 ? self.disavowTableParams.selectedUrls.push(url) : self.disavowTableParams.selectedUrls.splice(index, 1);
  };

  const toggleWhitelistSelect = (url: string) => {
    const index = self.whitelistTableParams.selectedUrls.indexOf(url);
    index === -1 ? self.whitelistTableParams.selectedUrls.push(url) : self.whitelistTableParams.selectedUrls.splice(index, 1);
  };

  const getToxicBacklinksCSV = flow(function* (pk: number, selectedUrls?: string[]) {
    try {
      const data = yield BacklinkAnalyzerClient.getToxicBacklinksCSV(pk, selectedUrls);
      return data;
    } catch (e) {
      Promise.reject(e);
    }
  });

  const getDisavowCSV = flow(function* (pk: number, selectedUrls?: string[]) {
    try {
      const urls = selectedUrls ? selectedUrls.map(url => self.disavow.find(x => x.url === url)) : [];
      const data = yield BacklinkAnalyzerClient.getDisavowCSV(pk, urls);
      return data;
    } catch (e) {
      Promise.reject(e);
    }
  });

  const getWhitelistCSV = flow(function* (pk: number, selectedUrls?: string[]) {
    try {
      const urls = selectedUrls ? selectedUrls.map(url => self.whitelist.find(x => x.url === url)) : [];
      const data = yield BacklinkAnalyzerClient.getWhitelistCSV(pk, urls);
      return data;
    } catch (e) {
      Promise.reject(e);
    }
  });

  return {
    loadToxicBacklinksData,
    setToxicBacklinksSearch,
    setDisavowSearch,
    cleanSelectedUrls,
    cleanDisawovedSelectedUrls,
    setIsOpen,
    setWhitelistSearch,
    setToxicBacklinksToxicityFilters,
    setDisavowTypeFilters,
    setWhitelistTypeFilters,
    setToxicBacklinksToxicScoreFilter,
    setToxicBacklinksCurrentPage,
    setDisavowCurrentPage,
    setWhitelistCurrentPage,
    setToxicBacklinksSorter,
    toggleToxicBacklinkSelect,
    toggleDisavowSelect,
    toggleWhitelistSelect,
    moveToxicBacklinksToDisavow,
    moveToxicBacklinksToWhitelist,
    moveDisavowToWhitelist,
    moveWhitelistToDisavow,
    removeFromDisavow,
    removeFromWhitelist,
    getToxicBacklinksCSV,
    getDisavowCSV,
    getWhitelistCSV,
  };
});

export type ToxicBacklinksStoreInstance = Instance<typeof ToxicBacklinksStore>;

export const initToxicBacklinksStore = () => {
  return {
    loading: true,
    toxicBacklinks: [],
    aggregated: aggregatedEmpty,
    disavow: [],
    whitelist: [],
    toxicBacklinksTableParams: {
      isOpen: false,
      search: '',
      toxicityFilters: [],
      toxicScoreFilter: [],
      sorter: {
        field: null,
        order: null,
      },
      currentPage: 1,
      pageSize: 10,
      selectedUrls: [],
    },
    disavowTableParams: {
      search: '',
      typeFilters: [],
      currentPage: 1,
      pageSize: 10,
      selectedUrls: [],
    },
    whitelistTableParams: {
      search: '',
      typeFilters: [],
      currentPage: 1,
      pageSize: 10,
      selectedUrls: [],
    },
  };
};
