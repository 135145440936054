import React, {useState, useEffect} from 'react';
import styles from '../style.module.scss';
import {useTranslation} from 'next-i18next';
import {Checkbox} from 'antd';
import {ApplyBtnWrapper, ApplyButton, CustomRangeContainer, InputStyled} from '../styledComponents';

interface Props {
  type?: string;
  filterId?: number;
  filterLabel?: string;
  filterName?: string;
  onChange?: (filter:any)=>void;
  className?: string;
  filterField?: string;
  headerField?: any;
  isDisable?:boolean;
  isClear?: boolean;
  onClear?: (data: any)=>void;
  absoluteUi?: boolean;
  isPageExplorer?: boolean;
  queryVal?: string;
}

const FilterKeywordPopUpSimple: React.FC<Props> = ({queryVal, filterField, filterId, filterLabel, filterName, onChange, isPageExplorer}) => {
  const [query, setQuery] = useState('');
  const [checked, setChecked] = useState(false);
  const {t} = useTranslation('common');

  useEffect(() => {
    if (queryVal) {
      setQuery(queryVal);
    } else {
      setQuery('');
    }
  }, [queryVal]);

  const createOrUpdateFilter = e =>{
    e.preventDefault();
    emitOnChanges('', query);
  };

  const emitOnChanges = (operator: string, query: string) => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        operator: operator,
        query: query,
        filterField: filterField,
        active: true,
        checked: checked,
        isUrl: filterLabel == 'url' && isPageExplorer ? true : false,
      });
    }
  };

  const disablingButton = () =>{
    if (query=='') {
      return true;
    } else {
      return false;
    }
  };


  const checkBoxChange = () => {
    setChecked(!checked);
  };
  return (
    <CustomRangeContainer>
      {isPageExplorer && (filterLabel == 'title' || filterLabel == 'meta_description' ) ?<>
        <Checkbox checked={checked} onChange={checkBoxChange}>Case Insensitive Filtering </Checkbox>
        <hr color=' #E8E8E8'/></>: ''}
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <div className={styles.filterPopupOptionsOther}>
          <InputStyled
            value={query}
            onChange={e=>setQuery(e.target.value)}
          />
        </div>
      </div>
      <ApplyBtnWrapper>
        <ApplyButton
          disabled={disablingButton()}
          onClick={createOrUpdateFilter}
        >
          {t('apply-filter')}
        </ApplyButton>
      </ApplyBtnWrapper>
    </CustomRangeContainer>
  );
};

export default FilterKeywordPopUpSimple;
