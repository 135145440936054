import {BaseApi} from '@/api/base-api';
import {getAuthorizationHeader} from '@/api/common-utils';
import {notification} from '@/utils/notification-v2';


export class ContentQualityApi extends BaseApi {
  public async updateKeywords(property: string, page: string, keyword: string, id?: number) {
    const payloadData = id ? {
      keyword: keyword,
      property: property,
      page: page,
      id: id,
    } : {
      keyword: keyword,
      property: property,
      page: page,
    };
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: '/content-quality/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: payloadData,
        // cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getContentQuality(property: string, page: string) {
    try {
      const response = await this.axios.get(`/content-quality/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          property: property,
          page: page,
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      notification.error('Error', 'Keyword Processing Falied');
      return Promise.reject(e);
    }
  }
}
