import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import React from 'react';

interface CrawlBudgetBannerProps {
  bannerStyle?: React.CSSProperties;
  pagesCount?: number;
  onButtonClick: React.MouseEventHandler<HTMLDivElement>;
}

export const CrawlBudgetBanner: React.FC<CrawlBudgetBannerProps> = (({bannerStyle, onButtonClick, pagesCount}) => {
  return (
    <StyledBannerWrapper style={bannerStyle}>
      <div className='banner-text-wrapper'>
        <FontAwesomeIcon icon={faCircleInfo} color='#FF8536' fontSize={14}/>
        <div className='banner-text'>We found {pagesCount} pages in your sitemap. To ensure full coverage, we recommend adjusting the
          <div className='button-to-crawl' onClick={onButtonClick}>&apos;Pages to Crawl&apos; setting</div>
          to match or exceed the total number of pages in your sitemap.</div>
      </div>
    </StyledBannerWrapper>
  );
});
const StyledBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(241, 170, 62, 0.15);
  padding: 10px 0px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  margin-bottom: 10px;
  width: 100%;
  .banner-text-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .banner-text {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      .button-to-crawl {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #34AEF3;
        text-decoration: underline;
        padding: 0px 4px;
        cursor: pointer;
      }
    }
  }
`;
