import React, {
  ReactNode,
  DetailedHTMLProps,
  AnchorHTMLAttributes,
} from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import styles from './style.module.scss';

export interface ButtonLinkProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  size?: 'xs' | 'sm' | 'lg';
  loading?: boolean;
  disabled?: boolean;
  outline?: boolean;
  buttonType?:
    | 'default'
    | 'primary'
    | 'success'
    | 'error'
    | 'purple'
    | 'transparent'
    | 'green-opaque';
  children?: ReactNode;
  href: string;
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  size,
  className,
  buttonType = 'default',
  disabled = false,
  href,
  children,
  ...props
}) => {
  const classes = classnames(
    className,
    styles.button,
    'common-component-button',
    'button',
    {
      [styles.disabled]: disabled,
      [styles.buttonSizeLg]: size === 'lg',
      [styles.buttonSizeSm]: size === 'sm',
      [styles.buttonSizeXs]: size === 'xs',
      [styles.buttonTypePrimary]: buttonType === 'primary',
      [styles.buttonTypeSuccess]: buttonType === 'success',
      [styles.buttonTypeError]: buttonType === 'error',
      [styles.buttonTypePurple]: buttonType === 'purple',
      [styles.buttonTypeTransparent]: buttonType === 'transparent',
      [styles.buttonTypeGreenOpaque]: buttonType === 'green-opaque',
    },
  );

  return (
    <Link legacyBehavior href={href} passHref>
      <a className={classes} {...props}>
        {children}
      </a>
    </Link>
  );
};

export interface ButtonListProps {
  children: ReactNode;
}

export const ButtonList: React.FC<ButtonListProps> = ({children}) => {
  return <div className={styles.buttonList}>{children}</div>;
};

export default ButtonLink;
