import React, {useState, useEffect} from 'react';
import {Modal} from '@/components/common-components/components/modal';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {LoginSection} from '@/components/common-components/components/authentication-modal/login';
import {RegisterSection} from '@/components/common-components/components/authentication-modal/register';
import {
  RegistrationModalSection,
} from '@/components/common-components/components/authentication-modal/registration-modal';
import {ForgotPasswordSection} from '@/components/common-components/components/authentication-modal/forgot-password';
import {profileApi} from '@/api/account';

interface Props {
  isCheckoutPage?: boolean;
}

const LoginModal: React.FC<Props> = observer(({isCheckoutPage=false}) => {
  const {
    settings: {
      customer: {showAuthModal, setAuthModal, showModalWithLogin, profile, loadProfile, isFreeTrail},
    },
  } = useStore('');
  const [isRegisterPage, setIsRegisterPage] = useState(true);
  const [varificationModal, setVarificationModal] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const checkVerifyStatus = localStorage.getItem('isEmailVerified');
  useEffect(() => {
    if (checkVerifyStatus == 'FALSE') {
      setVarificationModal(true);
    }
  }, []);

  const getLogo = async () => {
    const logos = await profileApi.getDomainLogos();
    logos && profile.setSignInLogoInStore(`${logos?.signinLogoUrl || logos?.logoUrl}`);
  };

  useEffect(() => {
    if (showAuthModal) {
      getLogo();
    }
    if (showModalWithLogin) {
      setIsRegisterPage(false);
    } else {
      setIsRegisterPage(true);
    }
  }, [showAuthModal]);

  const modelContent = () => {
    if (isRegisterPage) {
      return <RegisterSection isCheckoutPage={isCheckoutPage} isFreeTrail={isFreeTrail} varificationModal={async () => {
        setAuthModal(false, false);
        localStorage.setItem('isEmailVerified', 'FALSE');
        if (!profile?.emailAddress) {
          await loadProfile();
        }
        if (isCheckoutPage) {
          localStorage.setItem('verifyingFromCheckoutPage', 'TRUE');
        }
        setVarificationModal(true);
      }} openRegisterPage={value => setIsRegisterPage(value)}/>;
    } else if (forgetPassword) {
      return <ForgotPasswordSection openRegisterPage={value => {
        setIsRegisterPage(value);
        setForgetPassword(false);
      }} />;
    }
    return <LoginSection onForgetPassword={value => setForgetPassword(value)} isCheckoutPage={isCheckoutPage} openRegisterPage={value => setIsRegisterPage(value)}/>;
  };

  return (
    <>
      {varificationModal ? <RegistrationModalSection /> : ''}
      <Modal visible={showAuthModal} onClose={() => setAuthModal(false, false, null, false)}>
        <div> {modelContent()} </div>
      </Modal>
    </>
  );
});

export default LoginModal;
