import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import {cast, flow, types} from 'mobx-state-tree';
import {toJS} from 'mobx';

const MailTypes = types.model({
  mailType: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
});

const Projects = types.model({
  projectId: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  mailTypes: types.maybeNull(types.array(MailTypes)),
});

export const SettingsNotification = types.model({
  userId: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
  projects: types.maybeNull(types.array(Projects)),
});

export const BlEmailNotificationSettings = types.model({
  loading: types.boolean,
  settings: types.maybeNull(types.array(SettingsNotification)),
})
  .views(self => ({
    get getSettings() {
      return toJS(self.settings);
    },
  }))
  .actions(self => {
    const loadSettingsNotification = flow(function* () {
      self.loading = true;
      try {
        const response = yield BacklinkAnalyzerClient.getNotificationSettings();
        self.settings = cast(response.data);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        self.loading = false;
      }
    });

    const editNotificationsSettings = flow(function* (projectsIds: number[], mailType: string, activate: boolean, userId?: number) {
      self.loading = true;
      try {
        const response = yield BacklinkAnalyzerClient.postNotificationSettings(projectsIds, mailType, activate, userId);
        self.settings = cast(response.data);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        self.loading = false;
      }
    });

    return {
      loadSettingsNotification,
      editNotificationsSettings,
    };
  });

export const initBlEmailNotificationSettingsStore = () => {
  return {
    loading: true,
  };
};
