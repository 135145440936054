import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import styled from 'styled-components';
import {DeployStatusFilter} from '../../../deployStatusFilter';
import {ComingSoonTag} from '../../../../style';
import {GBPReviewsTableHeader} from './gbpReviewsTableHeader';
// import {ComingSoonTag, OptionContent, RefreshWrapper, StyledRatingSelect, UrlFilterInput} from '../../../../style';
// import {QuotaTracker} from '@/components/common-components/v2/quotaTracker';
// import FreezeWrapper from '../../../freezTooltip';

interface Props {
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: any;
  setSearchText: (value: string) => void;
  searchText: string;
  setIsOpenSearch: (value: boolean) => void;
  isOpenSearch: boolean;
  setIsTableOpen: (value: string) => void;
  isTableOpen: string;
  setIsActiveFilter: (value: string) => void;
  isActiveFilter: string;
  componentIssueType: any;
}

export const TableHeader = observer(({setPageChanged, setIssueTable, issueTable, setSearchText, searchText, setIsActiveFilter, isActiveFilter, componentIssueType}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    // setStatusKeyValue,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    selectedCategory,
    selectedIssue,
    activeReviewsTab,
    activeReviewsKey,
    selectedReviewFilters,
    setSelectedReviewFilters,
    setReplyKeyValue,
  },
  } = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
  const category = selectedIssue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : selectedIssue;
  const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === category || issueObj.label === category)?.subGroups?.find(categoryObj => categoryObj?.group === selectedCategory);
  const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === 'unanswered_reviews');

  const [ottoUrls, setOttoUrls] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRatings, setSelectedRatings] = useState([]);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);


  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === 'unanswered_reviews');

  const deployStatusClick = async (activeKey: any, issueType: string, page: number, pageSize: number, isSitewide: boolean) => {
    setSelectedReviewFilters({
      star_rating__in: selectedReviewFilters?.star_rating__in,
      is_replied: activeKey !== 'all' ? activeKey == 'deployed' ? 'true' : 'false' : null,
      reply__status__in: selectedReviewFilters?.reply__status__in,
    });

    setIsActiveFilter(activeKey);
    const params = {
      uuid,
      otto_project: getOttoV2Project?.id,
      issue_type: issueType,
      page_size: pageSize,
      page: 1,
      search: searchText,
      is_loading: false,
    };
    // setStatusKeyValue(activeKey);
    // params['is_replied'] = activeKey,
    if (selectedReviewFilters?.star_rating__in) {
      params['star_rating__in'] = selectedReviewFilters?.star_rating__in;
    }
    if (activeKey !== 'all') {
      if (activeKey === 'deployed') {
        setReplyKeyValue('true');
        params['is_replied'] = true;
      } else {
        setReplyKeyValue('false');
        params['is_replied'] = false;
      }
    }
    if (selectedReviewFilters?.reply__status__in) {
      params['reply__status__in'] = selectedReviewFilters?.reply__status__in;
    }
    await loadIssueTableData(params, isSitewide);
  };

  useEffect(() => {
    activeReviewsTab && deployStatusClick(activeReviewsTab, 'unanswered_reviews', 1, 10, false);
  }, [activeReviewsKey]);

  const render = () => {
    return (
      <>
        <HeaderContainer className={'flex-wrap'}>
          <div style={{flexWrap: 'wrap', display: 'flex', flexDirection: 'row', flex: 1}} className='issues-collapse-header'>
            <DeployStatusFilter newFilters={true} data={url} isActiveFilter={isActiveFilter} selectedRatings={selectedRatings} isSitewide={itemObject?.isSitewide} onClick={deployStatusClick}/>
            <GBPReviewsTableHeader
              componentIssueType={componentIssueType}
              setPageChanged={setPageChanged}
              setIssueTable={setIssueTable}
              issueTable={issueTable}
              setSearchText={setSearchText}
              searchText={searchText}
              setIsActiveFilter={setIsActiveFilter}
              isActiveFilter={isActiveFilter}
              postCreatedAt={''}
              isResponsiveHeader
            />
          </div>

        </HeaderContainer>
      </>
    );
  };

  return (
    <>{!itemObject?.isComingSoon ? render() : <ComingSoonTag>Coming soon</ComingSoonTag>}</>
  );
});


export const HeaderContainer = styled.div`
  padding: 0px 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.flex-wrap {
    flex-wrap: wrap;
  }
  .inner-fields-wrap {
    flex-wrap: wrap;
    justify-content: end;
    margin-left: auto;
  }
  @media screen and (max-width: 1260px) {
    flex-wrap: wrap;
    row-gap: 8px;
    .issues-collapse-header {
      min-height: 46px;
    }
    .issues-btn-wrapper {
      margin-left: auto;
    }
  }

  .issues-collapse-header {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  div:nth-child(1) {
    .anticon-right {
      display: none;
    }
  }
  .issues-collapse-header {
    .chevron-up-icon {
      display: block;
    }
  }
`;

