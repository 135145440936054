// import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Divider, Dropdown} from 'antd';
import {ReactNode, useEffect, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {faCaretDown, faPause, faPlay} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';

interface Props {
  campaignId?: number;
  campaignStatus?: string;
  customLabel?: ReactNode | string;
  isMinified?: boolean;
  isInOTTO?: boolean;
}

export const StatusSelector = observer(({campaignStatus, campaignId, isInOTTO}: Props) => {
  const {
    campaigns: {toogleStatus},
    ottoV2Store: {getOttoV2Project},
  } = useStore('');

  const statusesSetup = {
    'ACTIVE': {
      apiKey: 'active',
      label: 'Active',
      icon: faPlay,
      iconColor: '#2AC155',
    },
    'PAUSED': {
      apiKey: 'paused',
      label: 'Paused',
      icon: faPause,
      iconColor: '#F1AA3E',
    },
    // 'ARCHIVED': {
    //   apiKey: 'archived',
    //   label: 'Archived',
    //   icon: faBoxArchive,
    //   iconColor: '#4E5156',
    // },
  };

  const [selectedStatus, setSelectedStatus] = useState(campaignStatus);

  useEffect(() => {
    if (campaignStatus) setSelectedStatus(campaignStatus);
  }, [campaignStatus]);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const statusChangeHandler = async (status: string) => {
    setDropdownVisible(false);
    setSelectedStatus(status);
    if (isInOTTO) {
      const response = await toogleStatus(campaignId, 'campaigns', getOttoV2Project?.id);
      if (response?.code === 'ERR_BAD_REQUEST') {
        status === 'ACTIVE' && setSelectedStatus('PAUSED');
      }
    } else {
      const response = await toogleStatus(campaignId, 'campaigns');
      if (response?.code === 'ERR_BAD_REQUEST') {
        status === 'ACTIVE' && setSelectedStatus('PAUSED');
      }
    }
  };

  const menuRender = () => {
    return <>
      {Object.keys(statusesSetup).map((item, idx) => {
        return <>
          <SingleStatusOption key={idx} onClick={() => statusChangeHandler(item)}>
            <FontAwesomeIcon icon={statusesSetup[item].icon} color={statusesSetup[item].iconColor} fontSize={14}/>
            <SingleStatusLabel>{statusesSetup[item].label}</SingleStatusLabel>
          </SingleStatusOption>
          {idx < Object.keys(statusesSetup).length-1 ? <Divider style={{marginTop: 0, marginBottom: 0}}/> : null}
        </>;
      })}
    </>;
  };


  return <Wrapper>
    <Dropdown
      visible={dropdownVisible}
      overlay={
        <> <Menu> {menuRender()} </Menu></>
      }
      trigger={['click']}
      placement='bottomLeft'>
      <FreezeWrapper removeTooltip={campaignStatus === 'ACTIVE' || !isInOTTO}>
        <CurrentStatus color={statusesSetup[selectedStatus]?.iconColor} onClick={() => setDropdownVisible(!dropdownVisible)}>
          <FontAwesomeIcon icon={statusesSetup[selectedStatus]?.icon} color={statusesSetup[selectedStatus]?.iconColor} fontSize={14} />
          <CurrentStatusLabel>{statusesSetup[selectedStatus]?.label}</CurrentStatusLabel>
          <FontAwesomeIcon icon={faCaretDown} fontSize={10} style={{marginLeft: '10px'}} />
        </CurrentStatus>
      </FreezeWrapper>
    </Dropdown>
  </Wrapper>;
});

const Wrapper = styled.div`

`;
const CurrentStatus = styled.div<{color?: string}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${p => `${p.color}12`};
  padding: 4px 12px;
  border-radius: 6px;
  width: fit-content;
`;
const CurrentStatusLabel = styled.div`
  margin-left: 5px;
`;

const Menu = styled.div`
  background-color: white;
  z-index: 1;
  border-radius: 5px;
  box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.25);
`;
const SingleStatusOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 8px; 
`;
const SingleStatusLabel = styled.div`
  color: #121212;
  margin-left: 8px;
`;
