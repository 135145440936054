import {useEffect, useState} from 'react';
import styled from 'styled-components';


/**
 * This is the description of the interface
 *
 * @interface ProgressBarProps
 * @member {boolean} trigger loading boolean that will start/end progress bar
 * @member {number} maxWaitTime is used to set custom max waiting time before progress turns permanently red
 *
 */
interface ProgressBarProps {
  trigger: boolean;
  maxWaitTime?: number;
}


/**
 * Progress bar component.
 *
 * @param {boolean} trigger loading boolean that will start/end progress bar
 * @param {number} maxWaitTime is used to set custom max waiting time before progress turns permanently red
 *
 * @return {any} progress bar
 */
export const ProgressBar = ({trigger, maxWaitTime = 60}: ProgressBarProps) => {
  const [progress, setProgress] = useState<number>(0);
  const [transitionTime, setTransitionTime] = useState<number>(7);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);

  let interval = 1000;
  let itteration = 0;

  function loop(shouldRepeat: boolean) {
    // if boolean that triggers the bar to appear is false, we are filling the progress bar to 100% and hiding it
    if (!shouldRepeat) {
      setProgress(100);
      setTimeout(() => {
        setVisible(false);
      }, 1400);
      return;
    }

    // each iteration is 1s long
    // if we've been iterating for less than 7 seconds check if progress bar has already started to increas
    // if it still hasnt started to increase we start it and let it add up to 70%
    if (itteration < 7 && maxWaitTime >= 7) {
      if (progress == 0) {
        setProgress(70);
        itteration++;
      }
    // if we've been iterating for more than 7s and the triggering boolean is still true, we slow down the progress bar
    // now we are adding 1% every 2 seconds, so that remaining 30% of progres take exactly the remaining time to maxWaiting time.
    // calculation: maxWaitTime - 7 = total remaining loading time in seconds
    // total remaining time * 1000 = total remaining loading time in seconds
    // total remaining miliseconds / 30 = exact interval to add each percent
    } else if (itteration >= 7 && itteration < (maxWaitTime - 7)) {
      setTransitionTime(1);
      setProgress(curr => curr + 1);
      interval = interval !== (((maxWaitTime - 7) * 1000) / 30) ? (((maxWaitTime - 7) * 1000) / 30) : interval;
      itteration++;
    // if we iterate for longer than max waiting time set progress bar to 100% in 1s and color it red
    } else if (itteration >= ((maxWaitTime - 7))) {
      if (progress < 100) {
        setTransitionTime(1);
        setProgress(100);
      }
      if (!error) {
        setError(true);
      }
      return;
    }

    setTimeout(loop, interval);
  }

  useEffect(() => {
    if (trigger) {
      setVisible(true);
      loop(trigger);
    }
  }, [trigger]);


  return <Wrapper visible={visible}>
    <InnerBar visible={visible} progress={progress} isError={error} transition={transitionTime}>

    </InnerBar>
  </Wrapper>;
};

const Wrapper = styled.div<{visible?: boolean}>`
  position: absolute;
  top: 0;
  legt: 0;
  width: 100%;
  height: ${p => p.visible ? '5px' : '0px'};
  background-color: #A3A4A426;
`;
const InnerBar = styled.div<{visible?: boolean; progress: number; isError?: boolean; transition: number}>`
  height: ${p => p.visible ? '5px' : '0px'};
  width: ${p => p.progress}%;
  border-radius: 0 5px 5px 0;
  transition: width ${p => p.transition}s ease;
  background: ${p => !p.isError ? 'repeating-linear-gradient(to right, #B98CE4 0%, #7F4EAD 50%, #B98CE4 100%)' : '#ff5959'};
`;
