import React, {useEffect, useState} from 'react';
import {withTheme} from '@rjsf/core';
import {Theme as AntDTheme} from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import {notification} from '@/utils/notification-v2';
import {Col, Form, Row, Select, Button, Input, Radio, TimePicker, Skeleton, Tooltip} from 'antd';
import {CaretDownOutlined} from '@ant-design/icons';
import moment from 'moment';
import {debounce, isArray} from 'lodash';
import {OTTO_V2_API} from '@/api/otto-v2';
import {ImagesModal} from './formFields/imagesModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/pro-solid-svg-icons';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {StyledSpin} from '../../../Utils/styledSpin';
import {ButtonStyled, ButtonsWrapper, CloseButton, FormStyledContainer, LoaderOverlay, StyledDivider, StyledSpace} from './styles';
import {IncidentsModal} from './formFields/incidentsModal';
import {useStore} from '@/store/root-store';

export const ModalForms = ({updateData, attributes, selectedAttributes, services, selectedField, categories, setIsModalOpen, modalSuggestion, selectedValue, setModalLoader, modalLoader, incidents, primaryCategory, setServices, setCategories, selectedLocation}) => {
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const ThemedForm = withTheme(AntDTheme);
  const {Option} = Select;
  const [multiForm] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [hideSuggestion, setHideSuggestion] = useState(true);
  const [loader, setLoader] = useState(false);
  const [images, setImages] = useState({ADDITIONAL: selectedValue?.ADDITIONAL?.value ? selectedValue?.ADDITIONAL?.value : selectedValue?.ADDITIONAL, COVER: selectedValue?.COVER?.value ? selectedValue?.COVER?.value : selectedValue?.COVER, PROFILE: selectedValue?.PROFILE?.value ? selectedValue?.PROFILE?.value : selectedValue?.PROFILE});
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [options, setOptions] = useState([]);
  const {settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');

  const [searchParam, setSearchParam] = useState('');

  useEffect(() => {
    if (!options?.length) {
      if (selectedField == 'services') {
        setOptions(services);
      } else if (selectedField == 'categories') {
        setOptions(categories);
      }
    }
  }, [services, categories]);

  useEffect(() => {
    multiForm.resetFields();
    openModal(selectedField);
    if (modalSuggestion?.value?.length) {
      let suggestions;
      switch (selectedField) {
        case 'categories':
          suggestions = selectedValue?.length ? modalSuggestion.value.filter(item => !selectedValue?.some(i => i.key == item.key)): modalSuggestion.value;
          break;
        case 'services':
          suggestions = selectedValue?.length ? modalSuggestion?.value?.filter(item => {
            return !selectedValue.some(val => {
              let match = false;
              if (item.displayName) {
                match = item.displayName === val.displayName;
              }
              if (item.serviceTypeId && val.serviceTypeId) {
                match = match || (item.serviceTypeId.label === val.serviceTypeId.label);
              }
              return match;
            });
          }) : modalSuggestion?.value;
          break;
        default:
          suggestions = selectedValue?.length ? modalSuggestion.value.filter(item => !selectedValue?.includes(item)): modalSuggestion.value;
      }
      if (suggestions?.length == 0) {
        setHideSuggestion(true);
      } else {
        setHideSuggestion(true);
        setFilteredSuggestions(suggestions);
      }
    }
  }, []);

  const openModal = (type: string) => {
    switch (type) {
      case 'services':
        // eslint-disable-next-line no-case-declarations
        const value = [];
        selectedValue?.forEach(service => {
          if (Object.keys(service).includes('displayName')) {
            value.push({
              type: 'custom',
              displayName: service.displayName,
            });
          } else if (Object.keys(service).includes('serviceTypeId')) {
            value.push({
              type: 'standard',
              serviceTypeId: service.serviceTypeId,
            });
          }
        });
        multiForm.setFieldsValue({
          services: value?.length ? value : [{type: null}],
        });
        break;
      case 'categories':
        multiForm.setFieldsValue({
          [type]: selectedValue?.length ? selectedValue?.map(cat => ({value: cat})) : [{value: null}],
        });
        break;
      case 'phone_numbers':
        multiForm.setFieldsValue({
          [type]: selectedValue?.length ? selectedValue?.map(number => ({value: typeof(number) == 'object' ? number.value : number})) : [{value: null}],
        });
        break;
      case 'address_lines':
        multiForm.setFieldsValue({
          [type]: selectedValue?.length ? selectedValue?.map(line => ({value: typeof(line) == 'object' ? line.value : line})) : [{value: null}],
        });
        break;
      case 'open_hours':
        weekDays.forEach(day => {
          const data = selectedValue?.[day.toLowerCase()].intervals;
          multiForm.setFieldsValue({
            [day]: data?.length ? data.map(item => ({
              open: item.open ? moment(item.open, 'HH:mm:ss') : null,
              close: item.close ? item.close == '24' ? moment('24:00:00', 'HH:mm:ss') : moment(item.close, 'HH:mm:ss') : null,
              type: item.close == '24' ? '24hr' : 'custom',
            })) : [{
              type: 'closed',
            }],
          });
        });
        break;
    }
    setShowForm(true);
  };

  const [formData, setFormData] = useState(selectedAttributes);

  useEffect(() => {
    setFormData(selectedAttributes);
  }, [selectedAttributes]);

  const attributesSubmitHandler = values => {
    try {
      setModalLoader(true);
      setFormData(values?.formData);
      const data = values.formData;
      const properties = Object.keys(values.schema.properties);

      const fields = [];
      Object.entries(data)?.forEach(item => {
        if (properties.includes(item[0])) {
          const value: any = item?.[1] ?? null;
          let isNull = value == null || value == undefined;
          if (!isNull) {
            if ((typeof(value) == 'string') || isArray(value)) {
              isNull = value?.length == 0;
            } else if (typeof value == 'object') {
              isNull = Object.keys(value)?.length == 0;
            }
          }
          if (!isNull) {
            fields.push({
              fieldName: item[0],
              fieldType: 'ATTRIBUTE',
              value: value,
            });
          }
        }
      });
      selectedValue?.forEach(item => {
        if (!fields.some(data => data.fieldName == item.fieldName)) {
          fields.push({
            fieldName: item.fieldName,
            fieldType: 'ATTRIBUTE',
            value: null,
          });
        }
      });
      updateData(fields);
    } catch (error) {
      return Promise.reject;
    }
  };

  const mediaSubmitHandler = async (values: any) => {
    setModalLoader(true);
    try {
      setModalLoader(true);
      const fields = ['ADDITIONAL', 'PROFILE', 'COVER'];
      const mediaData = values;
      const payload = [];
      const error = [];
      fields?.forEach(field => {
        if (field == 'ADDITIONAL') {
          payload.push({
            fieldName: field,
            fieldType: 'MEDIA',
            value: mediaData && mediaData[field] ? mediaData[field].filter(item => !(item?.format == 'VIDEO')) : [],
          });
        } else {
          payload.push({
            fieldName: field,
            fieldType: 'MEDIA',
            value: mediaData && mediaData[field] ? mediaData[field] : null,
          });
        }
      });
      if (error?.length) {
        notification.error('Image Extension Incorrect', error.join(', '));
      } else {
        await updateData(payload);
      }
    } catch (error) {
      return Promise.reject;
    }
  };

  const servicesSubmitHandler = async (values: any) => {
    try {
      setModalLoader(true);
      const serviceValues = [];
      values.services?.forEach(service => {
        if (service.type === 'custom') {
          const category = selectedValue?.find(x => x?.displayName === service.displayName)?.categoryName;
          serviceValues.push({
            displayName: service.displayName,
            categoryName: category || primaryCategory?.key,
          });
        } else if (service.type === 'standard') {
          let value;
          if (typeof(service.serviceTypeId) === 'object') {
            value = service.serviceTypeId;
          } else {
            const key = options?.find(x => x.key == service?.serviceTypeId);
            if (key) {
              value = key;
            } else {
              const key = modalSuggestion.value?.find(x => x.key == service.serviceTypeId);
              value = key ? key : service.serviceTypeId;
            }
          }
          serviceValues.push({
            serviceTypeId: value,
          });
        }
      });
      const field = [
        {
          fieldName: 'services',
          fieldType: 'FIELD',
          value: serviceValues,
        },
      ];
      await updateData(field);
    } catch (error) {
      return Promise.reject;
    } finally {
      setModalLoader(false);
    }
  };

  const handleModalSave = async (value: any) => {
    setModalLoader(true);
    let valueKeys = value?.[selectedField].map(item => {
      switch (selectedField) {
        case 'categories':
          if (typeof(item.value) == 'object') {
            return item.value;
          } else {
            const key = options.find(x => x?.key == item?.value);
            if (key) {
              return key;
            } else {
              const key = modalSuggestion?.value?.find(x => x.label == item.value);
              return key ? key : item.value;
            }
          }
        default:
          return item.value;
      }
    });
    try {
      if (selectedField=='categories' && valueKeys.some(item => typeof item === 'string')) {
        const keys = valueKeys.map(item =>
          typeof item === 'object' && item !== null ? item.key : item,
        ).join(',');
        const categoryResponse = await OTTO_V2_API.getMetadataCategories({key: keys});
        valueKeys = categoryResponse?.categories;
      }
      const fields = [
        {
          fieldName: selectedField,
          fieldType: 'FIELD',
          value: valueKeys,
        },
      ];
      await updateData(fields);
    } catch {
      return Promise.reject;
    } finally {
      setModalLoader(false);
    }
  };


  const handleOpenHours = values => {
    try {
      setModalLoader(true);
      const data = selectedValue ? selectedValue : {};
      weekDays?.forEach(day => {
        if (!data[day.toLowerCase()]) data[day.toLowerCase()] = {intervals: []};
        data[day.toLowerCase()].intervals = values[day]?.map(item => {
          if (item.type == 'custom') {
            if (item.open && item.close) {
              return ({
                open: moment(item.open).format('HH:mm:ss'),
                close: moment(item.close).format('HH:mm:ss'),
              });
            }
          } else if (item.type == '24hr') {
            return ({
              open: '00:00:00',
              close: '24',
            });
          }
        }).filter(item => item);
      });
      const field = [
        {
          fieldName: 'open_hours',
          fieldType: 'FIELD',
          value: data,
        },
      ];
      updateData(field);
    } catch {
      return Promise.reject;
    }
  };

  const handleAdd = () => {
    const fields = multiForm.getFieldValue(selectedField) || [];
    multiForm.setFieldsValue({
      [selectedField]: [...fields, {value: null}],
    });
  };

  const handleRemove = index => {
    const fields = multiForm.getFieldValue(selectedField) || [];
    if (fields.length > 1) {
      multiForm.setFieldsValue({
        [selectedField]: fields.filter((_, i) => i !== index),
      });
    }
  };
  const handleAddIntervals = key => {
    const fields = multiForm.getFieldValue(key) || [];
    multiForm.setFieldsValue({
      [key]: [...fields, {type: 'custom'}],
    });
  };

  const handleRemoveIntervals = (key, index) => {
    const fields = multiForm.getFieldValue(key) || [];
    multiForm.setFieldsValue({
      [key]: fields.filter((_, i) => i !== index),
    });
  };

  const radioChangeHandler = (val, day, index) => {
    const formValues = multiForm.getFieldsValue();
    const updatedValues = {
      ...formValues,
      [day]: formValues[day]?.map((item, i) => {
        if (i !== index) {
          return item;
        } else {
          return {
            type: val.target.value,
            close: null,
            open: null,
          };
        }
      }),
    };
    multiForm.setFieldsValue(updatedValues);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const onSearch = debounce( async value => {
    setSearchParam(value);
    const searchParam = value ? {search: value, location: selectedLocation} : {location: selectedLocation};
    if (selectedField == 'services') {
      const servicesResponse = await OTTO_V2_API.geMetadataServices(searchParam);
      setOptions(prevState => [...prevState, servicesResponse?.services]?.flat());
      await setServices(servicesResponse?.services);
      setLoader(false);
    } else if (selectedField == 'categories') {
      setLoader(true);
      const categoryResponse = await OTTO_V2_API.getMetadataCategories(searchParam);
      setOptions(prevState => [...prevState, categoryResponse?.categories]?.flat());
      await setCategories(categoryResponse?.categories);
      setLoader(false);
    }
  }, 1000);

  const resetSearch = async () => {
    if (searchParam) {
      const searchParamApi = {location: selectedLocation};
      if (selectedField == 'services') {
        const servicesResponse = await OTTO_V2_API.geMetadataServices(searchParamApi);
        await setServices(servicesResponse?.services);
      } else if (selectedField == 'categories') {
        const categoryResponse = await OTTO_V2_API.getMetadataCategories(searchParamApi);
        await setCategories(categoryResponse?.categories);
      }
      setSearchParam('');
    }
  };

  const acceptSuggestions = () => {
    const updatedData = JSON.parse(JSON.stringify(selectedValue));
    filteredSuggestions?.forEach(item => {
      updatedData.push(item);
    });
    if (selectedField !== 'services') {
      multiForm.setFieldsValue({
        [selectedField]: updatedData?.length ? updatedData?.map(cat => ({value: cat?.label ? cat?.label : cat})) : {value: null},
      });
    } else {
      const values = updatedData?.map(service => {
        if (Object.keys(service).includes('displayName')) {
          return ({
            type: 'custom',
            displayName: service.displayName,
          });
        } else if (Object.keys(service).includes('serviceTypeId')) {
          return ({
            type: 'standard',
            serviceTypeId: service.serviceTypeId,
          });
        }
      });
      multiForm.setFieldsValue({
        [selectedField]: values,
      });
    }
    setHideSuggestion(true);
  };

  const updateType = (value, index) => {
    const path = ['services', index, 'type'].join('.');
    multiForm.setFieldsValue({
      [path]: value,
    });
  };

  const getOptions = (categories, index) => {
    const values = multiForm.getFieldValue(selectedField);
    const updatedSelectedValues = values?.map(selectedItem => {
      if (selectedField == 'categories') {
        if (typeof selectedItem?.value === 'string') {
          return {key: selectedItem?.value};
        }
        return selectedItem.value;
      } else if (selectedField == 'services') {
        if (Object.keys(selectedItem).includes('serviceTypeId')) {
          const value = selectedItem.serviceTypeId;
          if (typeof value === 'string') {
            return {key: value};
          }
          return value;
        }
      }
    })?.filter(item => item);
    return categories
      ?.filter(category => {
        return !updatedSelectedValues?.some((selectedItem, selectedIdx) => {
          return selectedIdx !== index && selectedItem?.key === category.key;
        });
      })
      ?.map(category => (
        <Option key={category.key} value={category.key}>
          {category.label}
        </Option>
      ));
  };

  const getSuggestionsValue = () => {
    const labels = [];
    filteredSuggestions?.forEach(item => {
      if (typeof(item) == 'string') {
        labels.push(item);
      } else {
        if (Object.keys(item).includes('displayName')) {
          labels.push(item.displayName);
        } else if (Object.keys(item).includes('label')) {
          labels.push(item.label);
        } else if (Object.keys(item).includes('serviceTypeId')) {
          labels.push(item.serviceTypeId?.label);
        }
      }
    });
    return labels?.join(', ');
  };

  const validateNotOnlySpaces = (_, value) => {
    if (value && !/\S/.test(value)) {
      return Promise.reject(new Error('Input cannot be only spaces.'));
    }
    return Promise.resolve();
  };

  const getLabel = (propertyPath, schema) => {
    const formattedPath = propertyPath
      ?.replace(/^\./, '')
      ?.replace(/~1/g, '/')
      ?.replace(/\.(\d+)$/, '');
    const pathParts = formattedPath?.split('/').filter(Boolean);

    if (pathParts?.length < 2) {
      return propertyPath;
    }

    const secondPart = pathParts[1];

    const findMatchingProperty = (schema, part) => {
      if (schema.properties) {
        for (const [key, value] of Object.entries(schema.properties)) {
          if (key?.includes(part)) {
            return (value as any)?.title ?? key;
          }
        }
      }
      return null;
    };
    const matchingTitle = findMatchingProperty(schema, secondPart);
    return matchingTitle ?? secondPart;
  };

  const transformErrors = (errors, schema) => {
    return errors.map(error => {
      const fieldName = error.property;
      const fieldLabel = getLabel(fieldName, schema);
      if (error.items && error.items.format === 'uri') {
        error.stack = `Please enter a valid URL for ${fieldLabel} ${+fieldName.slice(-1)+1}.`;
      }
      if (error.name === 'required') {
        error.message = `The ${fieldLabel} ${+fieldName.slice(-1)+1} field is required.`;
      } else if (error.name === 'format') {
        error.message = `Please enter a valid value for ${fieldLabel} ${+fieldName.slice(-1)+1}.`;
      } else if (error.name === 'type') {
        error.stack = `${fieldLabel} ${+fieldName.slice(-1)+1} must be valid.`;
      }
      return error;
    });
  };

  const sanitizeName = name => name.replace(/\//g, '_');

  const CustomSelectWidget = props => {
    const {onChange, onBlur, onFocus, schema, formData, name, uiSchema} = props;
    const {allowClear} = uiSchema['ui:options'] || {};

    const isMultiSelect = schema.type === 'array';

    return (
      <div style={{display: 'flex'}}>
        <Form.Item
          style={{flexGrow: 1}}
          label={schema.title}
          rules={schema.required ? [{required: true, message: 'Please select a value'}] : []}
        >
          <Select
            mode={isMultiSelect ? 'multiple' : undefined}
            value={formData}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            style={{width: '100%'}}
            allowClear={allowClear}
          >
            {attributes?.definitions && attributes.definitions[name.replace('/', '_')] &&
              attributes.definitions[name.replace('/', '_')].enum.map((option, index) => (
                <Select.Option key={option} value={option}>
                  {attributes.definitions[name.replace('/', '_')].enumNames[index]}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </div>
    );
  };

  const generateUiSchema = schema => {
    const uiSchema = {};
    Object.keys(schema?.properties).forEach(key => {
      if (schema?.definitions[sanitizeName(key)]) {
        uiSchema[key] = {
          'ui:widget': 'select',
          'ui:options': {
            schemaKey: key,
            allowClear: true,
          },
          'ui:field': 'customSelect',
        };
      }
    });
    return uiSchema;
  };

  const uischema = generateUiSchema(attributes);

  return (
    <> {
      showForm ? (
        <div>
          {modalLoader && (
            <LoaderOverlay>
              <StyledSpin fontSize={50} color='#2D6CCA' />
            </LoaderOverlay>
          )}
          {selectedField == 'attributes' && (
            <div onKeyDown={handleKeyDown}>
              {
                Object.keys(attributes?.properties)?.length ?
                  <ThemedForm
                    validator={validator}
                    schema={attributes}
                    formData={formData}
                    onSubmit={attributesSubmitHandler}
                    transformErrors={errors => transformErrors(errors, attributes)}
                    uiSchema={uischema}
                    fields={{customSelect: CustomSelectWidget}}
                  /> : <div style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}><h2>No attributes available for this business.</h2></div>
              }
            </div>
          )}
          {selectedField == 'images' && (
            <div onKeyDown={handleKeyDown}>
              <ImagesModal images={images} setImages={setImages} updateImages={mediaSubmitHandler} setModalLoader={setModalLoader} />
            </div>
          )}
          {selectedField == 'incidents' && (
            <div onKeyDown={handleKeyDown}>
              <IncidentsModal incidents={incidents}/>
            </div>
          )}
          {(selectedField == 'categories' || selectedField == 'phone_numbers' || selectedField == 'address_lines' || selectedField == 'open_hours' || selectedField == 'services') && showForm && (
            <>
              <FormStyledContainer>
                {selectedField == 'categories' && <p>Help customers find your business by industry.</p>}
                {!hideSuggestion && <div className='suggestion-box'>
                  <div className='icon-div'>
                    {faInfo && <FontAwesomeIcon width={12} color={'#2AC155'} icon={faInfo}/>}
                  </div>
                  <div>
                    <div>Based on your business info, {whitelabelOtto} suggests the following {selectedField.replaceAll('_', ' ')}:</div>
                    <div className='category-list'>{getSuggestionsValue()}</div>
                    <div>
                      <Button className='accept-button' onClick={acceptSuggestions}>Accept suggestion</Button>
                      <Button className='refuse-button' onClick={() => setHideSuggestion(true)}>Refuse suggestion</Button>
                    </div>
                  </div>
                </div>}
                <Form form={multiForm} onFinish={selectedField == 'open_hours' ? handleOpenHours : selectedField == 'services' ? servicesSubmitHandler : handleModalSave} layout='vertical'>
                  {selectedField == 'services' && (
                    <>
                      {/* <div className='modal-height category-modal-height'> */}
                      <div className='modal-height'>
                        <Form.List name='services'>
                          {fields => (
                            <>
                              {fields.map(({key, name, ...restField}, index) => (
                                <StyledSpace key={key} align='baseline'>
                                  <div style={{width: '100%'}}>
                                    <div style={{display: 'flex'}}>
                                      <Tooltip title={primaryCategory ? '' : 'No Primary Category Selected'} placement='bottom'>
                                        <Form.Item style={{width: 'calc(100% - 30px)'}}
                                          {...restField}
                                          name={[name, 'type']}
                                          label={'Service Type'}
                                          rules={[{required: true, message: 'Please select a type'}]}
                                        >
                                          <Select disabled={!primaryCategory} onChange={value => updateType(value, index)}>
                                            <Select.Option value='custom'>Custom Service Item</Select.Option>
                                            <Select.Option value='standard' disabled={!services?.length}>Standard Service Item</Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Tooltip>
                                      {index > 0 && (
                                        <FontAwesomeIcon className='delete-icon' icon={faTrash} onClick={() => handleRemove(index)}/>
                                      )}
                                    </div>
                                    {multiForm.getFieldValue(['services', name, 'type']) == 'custom' ? (<div>
                                      <Form.Item style={{width: 'calc(100% - 30px)'}}
                                        {...restField}
                                        name={[name, 'displayName']}
                                        label={'Display Name'}
                                        rules={[
                                          {required: true, message: 'Please enter service name'},
                                          {
                                            validator: validateNotOnlySpaces,
                                          },
                                        ]}
                                      >
                                        <Input/>
                                      </Form.Item>
                                    </div>) : multiForm.getFieldValue(['services', name, 'type']) == 'standard' ? (
                                      <div>
                                        <Form.Item style={{width: 'calc(100% - 30px)'}}
                                          {...restField}
                                          name={[name, 'serviceTypeId']}
                                          label={'Service Type Id'}
                                          rules={[{required: true, message: 'Please select service'}]}
                                        >
                                          <Select
                                            allowClear
                                            onSearch={onSearch}
                                            showSearch
                                            onSelect={() => resetSearch()}
                                            notFoundContent={loader ? (
                                              <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                <RingLoaderV2 fontSize={20} />
                                              </div>
                                            ) : (
                                              <div style={{padding: '8px', textAlign: 'center'}}>No data found</div>
                                            )}
                                            placeholder='Enter more than 3 characters to search'
                                            filterOption={false}
                                          >
                                            {getOptions(services, index)}
                                          </Select>
                                        </Form.Item>

                                      </div>) : null}
                                  </div>
                                  <div className='buttons-container'>
                                    {index == fields.length - 1 && primaryCategory && (
                                      <Button className='add-button' type='dashed' onClick={handleAdd} block>
                                      + Add Another Service
                                      </Button>
                                    )}
                                  </div>
                                  <StyledDivider/>
                                </StyledSpace>
                              ))}
                              {fields.length == 0 && (
                                <Button className='add-button' type='dashed' onClick={handleAdd} block>
                                + Add Service
                                </Button>
                              )}
                            </>
                          )}
                        </Form.List>
                      </div>
                    </>
                  )}
                  {selectedField == 'categories' && (
                    <>
                      {/* <div className='modal-height category-modal-height'> */}
                      <div className='modal-height'>
                        <div>
                          <Form.List name='categories'>
                            {fields => (
                              <>
                                {fields.map(({key, name, ...restField}, index) => (
                                  <StyledSpace key={key} align='baseline'>
                                    <div style={{width: '100%', display: 'flex'}}>
                                      <Tooltip title={categories?.length ? '' : 'Categories not available for this business'}>
                                        <Form.Item
                                          style={{width: 'calc(100% - 30px)'}}
                                          {...restField}
                                          name={[name, 'value']}
                                          rules={[{required: true, message: 'Please select a category'}]}
                                          label={index === 0 ? 'Primary category' : 'Additional Category'}
                                        >
                                          <Select showSearch allowClear onSearch={onSearch} suffixIcon={<CaretDownOutlined color='black'/>}
                                            disabled={!categories?.length && !searchParam}
                                            notFoundContent={loader ? (
                                              <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                <RingLoaderV2 fontSize={20} />
                                              </div>
                                            ) : (
                                              <div style={{padding: '8px', textAlign: 'center'}}>No data found</div>
                                            )}
                                            placeholder='Enter more than 3 characters to search'
                                            filterOption={false}
                                            onSelect={() => resetSearch()}>
                                            {getOptions(categories, index)}
                                          </Select>
                                        </Form.Item>

                                      </Tooltip>
                                      {index > 0 && (
                                        <FontAwesomeIcon className='delete-icon' icon={faTrash} onClick={() => handleRemove(index)}/>
                                      )}
                                    </div>
                                    <div className='buttons-container'>
                                      {index == fields.length - 1 && (
                                        <Button className='add-button' type='dashed' onClick={handleAdd} block>
                                        + Add Another Category
                                        </Button>
                                      )}
                                    </div>
                                  </StyledSpace>
                                ))}
                                {fields.length == 0 && (
                                  <Button className='add-button' type='dashed' onClick={handleAdd} block>
                                  + Add Category
                                  </Button>
                                )}

                              </>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </>
                  )}
                  {selectedField == 'phone_numbers' && (
                    <div>
                      <Form.List name='phone_numbers'>
                        {fields => (
                          <>
                            {fields.map(({key, name, ...restField}, index) => (
                              <StyledSpace key={key} align='baseline'>
                                <div style={{width: '100%', display: 'flex'}}>
                                  <Form.Item style={{width: 'calc(100% - 30px)'}}
                                    {...restField}
                                    name={[name, 'value']}
                                    label={'Phone Number ' + (index + 1)}
                                    rules={[
                                      {
                                        pattern: /^[+\d#()*\s-]+$/,
                                        message: 'Phone number can only contain numbers, +, #, (), and *',
                                      },
                                      {
                                        validator: validateNotOnlySpaces,
                                      },
                                    ]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  {index > 0 && (
                                    <FontAwesomeIcon className='delete-icon' icon={faTrash} onClick={() => handleRemove(index)}/>
                                  )}

                                </div>
                                <div className='buttons-container'>
                                  {index == fields.length - 1 && (
                                    <Button className='add-button' type='dashed' onClick={handleAdd} block>
                                    + Add Another Number
                                    </Button>
                                  )}
                                </div>
                              </StyledSpace>
                            ))}
                            {fields.length == 0 && (
                              <Button className='add-button' type='dashed' onClick={handleAdd} block>
                              + Add Number
                              </Button>
                            )}
                          </>
                        )}
                      </Form.List>
                    </div>
                  )}
                  {selectedField == 'address_lines' && (
                    <Form.List name='address_lines'>
                      {fields => (
                        <>
                          {fields.map(({key, name, ...restField}, index) => (
                            <StyledSpace key={key} align='baseline' >
                              <div style={{width: '100%', display: 'flex'}}>
                                <Form.Item style={{width: 'calc(100% - 30px)'}}
                                  {...restField}
                                  name={[name, 'value']}
                                  label={'Address Lines ' + (index + 1)}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Address Line is required',
                                    },
                                    {
                                      validator: validateNotOnlySpaces,
                                    },
                                  ]}
                                >
                                  <Input/>
                                </Form.Item>
                                {index > 0 && (
                                  <FontAwesomeIcon className='delete-icon' icon={faTrash} onClick={() => handleRemove(index)}/>
                                )}
                              </div>
                              <div className='buttons-container'>
                                {fields.length < 2 && (
                                  <Button className='add-button' type='dashed' onClick={handleAdd} block>
                                  + Add Another Line
                                  </Button>
                                )}
                              </div>
                            </StyledSpace>
                          ))}
                          {fields.length == 0 && (
                            <Button className='add-button' type='dashed' onClick={handleAdd} block>
                            + Add Line
                            </Button>
                          )}
                        </>
                      )}
                    </Form.List>
                  )}
                  {selectedField == 'open_hours' && (
                    <>
                      <div className='modal-height'>
                        {weekDays.map(day => (
                          <>
                            <div className='day-title'> {day}</div>
                            <Form.List name={day} key={day}>
                              {fields => (
                                <>
                                  {fields.map(({key, name, ...restField}, index) => (
                                    <>
                                      <Row key={key} gutter={20}>
                                        <Col lg={7}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'type']}
                                            label={index == 0 && 'Input Method'}
                                          >
                                            <Radio.Group onChange={val => radioChangeHandler(val, day, index)}>
                                              <Radio value={'custom'}>Custom</Radio>
                                              <Radio value={'24hr'} disabled={(index !== 0 || fields.length > 1)}>24 Hours</Radio>
                                              <Radio value={'closed'} disabled={(index !== 0 || fields.length > 1)}>Closed</Radio>
                                            </Radio.Group>
                                          </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'open']}
                                            label={index == 0 && 'Open Time'}
                                            rules={[{required: multiForm.getFieldValue([day, name, 'type']) == 'custom', message: 'Please enter open time'}]}
                                          >
                                            <TimePicker disabled={multiForm.getFieldValue([day, name, 'type']) !== 'custom'}
                                              allowClear={false}
                                              format='HH:mm'
                                              showNow={false}/>
                                          </Form.Item>
                                        </Col>
                                        <Col lg={8}>
                                          <Form.Item
                                            {...restField}
                                            name={[name, 'close']}
                                            label={index == 0 && 'Close Time'}
                                            rules={[{required: multiForm.getFieldValue([day, name, 'type']) == 'custom', message: 'Please enter close time'}]}
                                          >
                                            <TimePicker disabled={multiForm.getFieldValue([day, name, 'type']) !== 'custom'}
                                              allowClear={false}
                                              format='HH:mm'
                                              showNow={false}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col lg={1}>
                                          {index > 0 && <FontAwesomeIcon className='delete-icon' style={{marginTop: 10}} icon={faTrash} onClick={() => handleRemoveIntervals(day, index)}/>}
                                        </Col>
                                        {index == fields.length - 1 && (
                                          <Button className='add-button' type='dashed' onClick={() => handleAddIntervals(day)} block
                                            disabled={multiForm.getFieldValue([day, name, 'type']) !== 'custom'}>
                                          + Add Another Interval
                                          </Button>
                                        )}
                                      </Row>
                                    </>
                                  ))}
                                  {fields?.length == 0 && (
                                    <Button className='add-button' type='dashed' onClick={() => handleAddIntervals(day)} block>
                                    + Add Interval
                                    </Button>
                                  )}
                                </>
                              )}
                            </Form.List>
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  <Form.Item style={{marginBottom: '0'}}>
                    <ButtonsWrapper>
                      <ButtonStyled type='primary' htmlType='submit'>
                        Save Changes
                      </ButtonStyled>
                      <CloseButton onClick={() => {
                        setIsModalOpen(false);
                        multiForm.resetFields();
                      }}>
                        Cancel
                      </CloseButton>
                    </ButtonsWrapper>
                  </Form.Item>
                </Form>
              </FormStyledContainer>
            </>
          )}
        </div>
      ) : <Skeleton/>
    }
    </>
  );
};
