import {Divider} from 'antd';
import {CSSProperties} from 'react';

export enum dividerType {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface Props {
  type: dividerType;
  width?: string;
  height?: string;
  color?: string;
  marginLeft?: string;
  marginRight?: string;
  style?: CSSProperties;
}

export const CommonDivider = ({
  type,
  width = '1px',
  height = '63px',
  color = '#4E5156',
  marginLeft = '8px',
  marginRight = '8px',
  style,
}: Props) => {
  return (
    <>
      {type === dividerType.VERTICAL ?
        <Divider type={'vertical'} style={{borderLeftWidth: width, height: height, borderLeftColor: color, marginLeft: marginLeft, marginRight: marginRight, ...style}} /> :
        <Divider type={'horizontal'} style={{width: width, borderTopWidth: height, borderTopColor: color, marginLeft: marginLeft, marginRight: marginRight, ...style}} />
      }
    </>
  );
};
