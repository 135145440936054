import React, {useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import styles from '../styles.module.scss';
import {iconReport} from '@/utils/icons';
import {faStar, faArrowUp, faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {PaginationStyled} from '../../../local-seo-scan/seo-projects-list-v2/styled';
import {DeltaForSingleValue} from '@/components/common-components/components/delta';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {formatLargeNumber, formatNumber} from '@/utils/number';
import {useStore} from '@/store/root-store';
import {CartegoryBarChart} from '../charts/cartegoryBarChart';
import _ from 'lodash';
import ReactEcharts from 'echarts-for-react';
import {EChartOption} from 'echarts';
import {renderToString} from 'react-dom/server';
import moment from 'moment';
import {getDateDifference} from '@/components/dashboard/gsc/gsc/utils';
import numeral from 'numeral';
import chartStyles from './styles-chart.module.scss';
import GrayRingLoader from '@/components/common-components/components/skeleton/gray-ring-loader';
import {displayEmoji} from '@/components/dashboard/gsc/gsc/GoogleChange/GoogleChange';
import {Popover} from 'antd';
import gscStyles from '@/components/dashboard/gsc/styles.module.scss';

const dateRangeObj = {
  last_1_month: 'One month',
  last_3_months: 'Three month',
  last_6_months: 'Six month',
  last_year: 'One year',
};

type CustomSeries = {
  data: {
  value: number;
  changeValue: number;
  dateStr: string;
  };
} & EChartOption.Series;

export const Keyword = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, isConfig, loadingGSCDetail}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p style={{display: 'flex'}}>{iconReport()}<div style={{marginLeft: '10px'}}>Keywords</div></p>
    {!loadingGSCDetail ? <div style={{display: 'flex', height: 32}}>
      <TitleBlack>{formatLargeNumber(getReportGSCDetailsData?.topMetrics?.totalKeywords, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
      {getReportGSCDetailsData?.topMetrics?.totalKeywordsDelta ? <DeltaForSingleValue format={getReportGSCDetailsData?.topMetrics?.totalKeywordsDelta}/> : ''}
    </div> : <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>}
  </div>;
});

export const AvgPosition = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, isConfig, loadingGSCDetail}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p style={{display: 'flex'}}>{iconReport()}<div style={{marginLeft: '10px'}}>Average Position</div></p>
    {!loadingGSCDetail ? <div style={{display: 'flex', height: 32}}>
      <TitleBlack >{formatLargeNumber(getReportGSCDetailsData?.topMetrics?.avgPosition, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
      {getReportGSCDetailsData?.topMetrics?.avgPositionDelta ? <DeltaForSingleValue format={getReportGSCDetailsData?.topMetrics?.avgPositionDelta}/> : ''}
    </div> : <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>}
  </div>;
});

export const ClickOrgTrafic = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, isConfig, loadingGSCDetail}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p style={{display: 'flex'}}>{iconReport()}<div style={{marginLeft: '10px'}}>Click (Organic Traffic)</div></p>
    {!loadingGSCDetail ? <div style={{display: 'flex', height: 32}}>
      <TitleBlack >{formatLargeNumber(getReportGSCDetailsData?.topMetrics?.traffic, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
      {getReportGSCDetailsData?.topMetrics?.trafficDelta ? <DeltaForSingleValue format={getReportGSCDetailsData?.topMetrics?.trafficDelta}/> : ''}
    </div> : <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>}
  </div>;
});

export const Impression = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, isConfig, loadingGSCDetail}}} = useStore('');

  return <div className={`${styles.mainInner} mainInner`} style={{width: isConfig ? '250px' : '285px'}}>
    <p style={{display: 'flex'}}>{iconReport()}<div style={{marginLeft: '10px'}}>Impressions</div></p>
    {!loadingGSCDetail ? <div style={{display: 'flex', height: 32}}>
      <TitleBlack >{formatLargeNumber(getReportGSCDetailsData?.topMetrics?.impressions, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack>
      {getReportGSCDetailsData?.topMetrics?.impressionsDelta ? <DeltaForSingleValue format={getReportGSCDetailsData?.topMetrics?.impressionsDelta}/> : ''}
    </div> : <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>}
  </div>;
});

export const HighestImpressionTable = observer(() => {
  const {reportBuilder: {details: {isConfig, topKeywordsLoading, loadingGSCDetail, singleProjectData, getTopKeywordsParams, setTopKeywordsParams, getReportGSCDetailsData}}} = useStore('');
  const range = dateRangeObj[`${singleProjectData?.dateRange}`];
  const rangeObj = {startDate: singleProjectData?.dateRangeStart, endDate: singleProjectData?.dateRangeEnd};
  const numberOfPastMonths = range == 'One month' ? 'oneMonth' : range == 'Three month' ? 'threeMonth' : range == 'Six month' ? 'sixMonth' : 'oneYear';
  const [activeSort, setActiveSort] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const columns = [
    {
      title: 'KEYWORD',
      dataIndex: 'kw',
      key: 'kw',
      align: 'left' as 'left',
      fixed: 'left' as 'left',
      width: 250,
      ellipsis: true,
      render: (date, record) => {
        return (
          <FlexWrapper>
            <div style={{marginRight: '10px'}}>{record?.isWatchlisted ? <FontAwesomeIcon icon={faStar} color='#F1AA3E' fontSize='12px'/> : ''}</div>
            <div>{_.capitalize(record?.kw) || '-'}</div>
          </FlexWrapper>
        );
      },
    },
    {
      title: <div>IMPRESSIONS</div>,
      dataIndex: 'impCur',
      key: 'impCur',
      align: 'left' as 'left',
      sortFieldName: 'imp_cur',
      sorter: true,
      sortOrder: activeSort?.includes('imp_cur') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper>
            &nbsp;&nbsp;<div>{formatLargeNumber(record?.impCur, 1)?.toString()?.replace('.0', '') || '-'}</div>&nbsp;
            {record?.impressionsDelta ? <DeltaForSingleValue format={record?.impressionsDelta}/> : ''}
          </FlexWrapper>
        );
      },
    },
    {
      title: <div>POSITION</div>,
      dataIndex: 'posCur',
      key: 'posCur',
      align: 'left' as 'left',
      sortFieldName: 'pos_cur',
      sorter: true,
      sortOrder: (activeSort == 'pos_cur' || activeSort == '-pos_cur') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      width: 100,
      render: (_, data) => {
        const posCur = formatLargeNumber(data?.posCur, 1)?.toString()?.replace('.0', '');
        return (
          <FlexWrapper>
            <div>{(posCur == '0' || !posCur) ? <div style={{color: '#a3a4a4', fontSize: '12px'}}>NR</div> : posCur}</div>&nbsp;
            {data?.positionDelta? <DeltaForSingleValue format={data?.positionDelta}/> : ''}
          </FlexWrapper>
        );
      },
    },
    {
      title: <div>AVERAGE<br/>POSITION</div>,
      dataIndex: 'avgPosCur',
      key: 'avgPosCur',
      align: 'left' as 'left',
      sortFieldName: 'avg_pos_cur',
      sorter: true,
      sortOrder: (activeSort == 'avg_pos_cur' || activeSort == '-avg_pos_cur') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      width: 100,
      render: (_, record) => {
        const avgPosCur = formatLargeNumber(record?.avgPosCur, 1)?.toString()?.replace('.0', '');
        return (
          <FlexWrapper>
            <div>{(avgPosCur == '0' || !avgPosCur) ? <div style={{color: '#a3a4a4', fontSize: '12px'}}>NR</div> : avgPosCur}</div>&nbsp;
            {record?.avgPostionDelta ? <DeltaForSingleValue format={record?.avgPostionDelta}/> : ''}
          </FlexWrapper>
        );
      },
    },
    {
      title: <div>CLICKS</div>,
      dataIndex: 'clicksCur',
      key: 'clicksCur',
      sortFieldName: 'clicks_cur',
      sorter: true,
      sortOrder: activeSort?.includes('clicks_cur') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      align: 'left' as 'left',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper>
            <div>{formatLargeNumber(record?.clicksCur, 1)?.toString()?.replace('.0', '') || '-'}</div>&nbsp;
            {record?.clicksDelta ? <DeltaForSingleValue format={record?.clicksDelta}/> : ''}
          </FlexWrapper>
        );
      },
    },
  ];

  const handleTableChange = async (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
    setActiveSort(sorterKey);
    setTopKeywordsParams(singleProjectData?.reportsData.gsc?.gscPropertyUrl, singleProjectData?.location, singleProjectData?.dateRangeStart ? rangeObj : numberOfPastMonths, {...getTopKeywordsParams, page: page, page_size: pageSize, ordering: sorterKey});
  };
  const handlePaginationChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    setTopKeywordsParams(singleProjectData?.reportsData.gsc?.gscPropertyUrl, singleProjectData?.location, singleProjectData?.dateRangeStart ? rangeObj : numberOfPastMonths, {...getTopKeywordsParams, page: page, page_size: pageSize, ordering: activeSort});
  };

  return <>
    <div style={{padding: '10px'}}><Title>{iconReport()}Top Keywords with Highest Impressions</Title></div>
    <TableStyled
      style={{height: '420px', background: 'white'}}
      columns={columns as any}
      dataSource={getReportGSCDetailsData?.topKeywords?.results?.sort((a, b)=> Number(b?.isWatchlisted) - Number(a?.isWatchlisted)) || []}
      pagination={false}
      loading={loadingGSCDetail || topKeywordsLoading}
      loadingRows={10}
      onChange={handleTableChange}
      sticky={true}
    />
    {!isConfig && <Pagination
      className='hideInPdf'
      onChange={handlePaginationChange}
      total={getReportGSCDetailsData?.topKeywords?.count}
      pageSize={getTopKeywordsParams['page_size']}
      current={getTopKeywordsParams['page']}
      defaultPageSize={20}
    />}
  </>;
});


export const HClickByWeek = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, isConfig, loadingGSCDetail}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? <ChartsWrapper className={isConfig && 'no-border'}>
    <div><Title>{iconReport()}Highest Clicks by Days of the Week</Title></div>
    <CartegoryBarChart loading={loadingGSCDetail} chartData={getReportGSCDetailsData?.trafficByWeekday}/>
  </ChartsWrapper> : null;
});

export const HImpressionByWeek = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, isConfig, loadingGSCDetail}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? <ChartsWrapper className={isConfig && 'no-border'}>
    <div><Title>{iconReport()}Highest Impressions by Days of the Week</Title></div>
    <CartegoryBarChart loading={loadingGSCDetail} chartData={getReportGSCDetailsData?.impressionsByWeekday}/>
  </ChartsWrapper> : null;
});

export const DeviceCatgICCTR = observer(() => {
  const {reportBuilder: {details: {getReportGSCDetailsData, loadingGSCDetail}}} = useStore('');

  const deviceCategoryCol = [
    {
      title: <div>DEVICE</div>,
      dataIndex: 'device',
      key: 'device',
      align: 'left' as 'left',
      width: '50%',
      render: (data, record) => {
        return (
          <div>{ _.capitalize(record?.device) || 'mobile'}</div>
        );
      },
    },
    {
      title: <div>AVG. POSITION</div>,
      dataIndex: 'position',
      key: 'position',
      align: 'left' as 'left',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper>
            <div>{formatLargeNumber(record?.position, 1)?.toString()?.replace('.0', '') || '-'}</div>&nbsp;
            {record?.positionDelta ? <DeltaForSingleValue format={record?.positionDelta}/> : ''}
          </FlexWrapper>
        );
      },
    },
    {
      title: <div>IMPRESSIONS</div>,
      dataIndex: 'impressions',
      key: 'impressions',
      align: 'left' as 'left',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper>
            <div>{formatLargeNumber(record?.impressions, 1)?.toString()?.replace('.0', '') || '-'}</div>&nbsp;
            {record?.impressionsDelta ? <DeltaForSingleValue format={record?.impressionsDelta}/> : ''}
          </FlexWrapper>
        );
      },
    },
    {
      title: <div>CLICKS</div>,
      dataIndex: 'clicks',
      key: 'clicks',
      align: 'left' as 'left',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper>
            <div>{formatLargeNumber(record?.clicks, 1)?.toString()?.replace('.0', '') || '-'}</div>&nbsp;
            {record?.clicksDelta ? <DeltaForSingleValue format={record?.clicksDelta}/> : ''}
          </FlexWrapper>

        );
      },
    },
    {
      title: <div>SITE CTR</div>,
      dataIndex: 'ctr',
      key: 'ctr',
      align: 'left' as 'left',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper>
            <div>{record?.ctr || '-'}%</div>&nbsp;
            {record?.ctrDelta ? <DeltaForSingleValue format={record?.ctrDelta} isTwoDecimals isPercent/> : ''}
          </FlexWrapper>
        );
      },
    },
  ];

  return <>
    <div style={{padding: '20px'}}><Title>{iconReport()}Device Category Impressions, Clicks and CTR</Title></div>
    <TableStyled
      style={{height: '258px', background: 'white', padding: '10px'}}
      columns={deviceCategoryCol}
      dataSource={getReportGSCDetailsData?.metricsByDevice}
      pagination={false}
      loading={loadingGSCDetail}
    />
  </>;
});

export const GscRankChange = observer(() => {
  const {
    reportBuilder: {details: {getReportGSCDetailsData, loadingGSCDetail}},
    gsc: {coreReports: {loadEmojis, emojiSerpHistogram}, sitePropertyEvents: {events}},
    settings: {customer: {profile: {isWhitelabel}}},
  } = useStore('');

  useEffect(() => {
    loadEmojis();
  }, []);

  const difference = getDateDifference('2023-05-05', '2024-05-05');

  const [emojiDataWithXPositions, setEmojiDataWithXPositions] = useState([]);
  const [eventDataWithXPositions, setEventDataWithXPositions] = useState([]);

  // const interval = () => {
  //   if (difference >= 24) {
  //     return 89;
  //   }
  //   if (difference <= 1) {
  //     return 0;
  //   }
  //   if (difference < 12) {
  //     return 23;
  //   }
  //   if (difference >= 12) {
  //     return 58;
  //   }
  // };
  const echartsRef = useRef(null) as any;

  const getSiteEventXPositions = () => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    const positions = echartsInstance?._chartsViews[0]?._data?._layout?.points?.filter((x, i) => i % 2 === 0);
    const dataPoints = echartsInstance?._chartsViews[0]?._data?._idList;
    const eventDataWithXPositions = events?.map(event => {
      const date = event.date;
      const index = dataPoints ? dataPoints.indexOf(date) : -1;
      return ({
        ...event,
        posX: index === -1 ? null : positions[index],
      });
    });
    setEventDataWithXPositions(eventDataWithXPositions);
  };

  const getEmojiDataXPositions = () => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    const positions = echartsInstance?._chartsViews[0]?._data?._layout?.points?.filter((x, i) => i % 2 === 0);
    const dataPoints = echartsInstance?._chartsViews[0]?._data?._idList;
    const emojiDataWithXPositions = emojiSerpHistogram.filter(x => x.tags?.includes('Major Update')).map(emoji => {
      const date = emoji.occuredAt?.slice(0, 10);
      const index = dataPoints ? dataPoints.indexOf(date) : -1;
      return ({
        ...emoji,
        tags: emoji?.tags?.filter(item => item !== undefined && item !== ''),
        posX: index === -1 ? null : positions[index],
      });
    });
    setEmojiDataWithXPositions(emojiDataWithXPositions);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', () => getEmojiDataXPositions());
      window.removeEventListener('resize', () => getSiteEventXPositions());
    };
  }, []);

  useEffect(() => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    echartsInstance?.on('rendered', () => {
      if (emojiSerpHistogram.length && !loadingGSCDetail) {
        getEmojiDataXPositions();
        window.addEventListener('resize', () => setTimeout(() => getEmojiDataXPositions(), 500));
      }
      if (events.length && !loadingGSCDetail) {
        getSiteEventXPositions();
        window.addEventListener('resize', () => setTimeout(() => {
          getSiteEventXPositions();
        }, 500));
      }
      echartsInstance.off('rendered');
    });
  }, [loadingGSCDetail, emojiSerpHistogram.length, events.length]);

  const toolTipFormater: EChartOption.Tooltip.Formatter = (params: CustomSeries[]) => {
    const value = params[0]?.data?.value;
    const dateStr = params[0]?.data?.dateStr;
    const changeValue = params[0]?.data?.changeValue;
    // if (changeValue === 0) return;
    const element = (
      <span className={chartStyles.areaTooltip}>
        {value !=0 && (
          <>
            <p className={chartStyles.heading}>
              {'Google Rank Change: '}
              <span style={{color: changeValue > 0 ? '#2AC155' :
                changeValue < 0 ? '#F44343': ''}} className={`${chartStyles.subHeading} `}>
                {changeValue > 0 ?
                  <FontAwesomeIcon icon={faArrowUp} color='#2AC155' /> :
                  changeValue < 0 ?
                    <FontAwesomeIcon icon={faArrowDown} color='#F44343' />:
                    ''} {formatNumber(changeValue < 0 ? changeValue*-1 : changeValue, 1)?.toString()?.replace('.0', '')} </span><span style={{fontWeight: 400, color: '#4E5156'}}> <br /> {dateStr}
              </span>
            </p>


          </>
        )}
      </span>
    );
    return renderToString(element);
  };

  const xAxisDataOriginal = getReportGSCDetailsData?.googleRankChangeChart?.map(c => c.date);
  let xAxisData = getReportGSCDetailsData?.googleRankChangeChart?.map(c => c.date);
  const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf('day').subtract(idx, 'days').format('DD MMM YYYY'));
  const newDateArr = lastThirtyDays?.reverse();
  const newArr = xAxisData?.concat(newDateArr);

  if (xAxisData?.length <= 1) {
    xAxisData = newArr;
  }

  const getGraphData = changeItem=>{
    return changeItem?.positionChangeCumulative;
  };

  const dataSeries = getReportGSCDetailsData?.googleRankChangeChart?.map((changeItem, changeIndex, changeArray) => {
    return {
      value: getGraphData(changeItem),
      changeValue: changeIndex ?
        changeItem.positionChangeCumulative -
          changeArray[changeIndex - 1]?.positionChangeCumulative :
        0,
      dateStr: moment(changeItem?.date).format('DD MMM YYYY'),
    };
  });

  const defaultSeriesData = xAxisData?.map(date => {
    return {
      value: 0,
      changeValue: 0,
      dateStr: moment(date).format('DD MMM YYYY'),
    };
  });

  defaultSeriesData?.unshift(dataSeries[0]);

  const chartOptions = {
    tooltip: {
      show: true,
      trigger: 'axis',
      backgroundColor: '#fff',
      extraCssText:
        'box-shadow: 0px 4px 10px rgba(0, 14, 88, 0.1) !important;border-radius: 10px; padding: 10px;',
      formatter: toolTipFormater,
      textStyle: {fontWeight: 'bold', fontSize: 16},
    },
    xAxis: {
      axisLine: {show: false},
      axisTick: {show: false, alignWithLabel: true},
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisLabel: {
        margin: 25,
        formatter: function(_: string, idx: number) {
          const value = dataSeries[idx]?.dateStr;
          let momentDate = null;
          xAxisDataOriginal.length <= 1 ? momentDate = moment(_) : momentDate = moment(value);

          if (xAxisDataOriginal?.length <= 1) {
            return momentDate.format('D MMMM');
          }

          if (momentDate.get('date') === 1 && difference < 1) {
            return momentDate.format('MMM');
          }
          if (difference < 1) {
            return momentDate.format('D');
          }
          if (difference < 12) {
            return momentDate.format('MMMM');
          }
          if (difference >= 12) {
            return momentDate.format('DD MMM');
          }
        },
      },
    },
    yAxis: {
      show: true,
      type: 'value',
      axisLine: {show: false},
      axisTick: {show: false, alignWithLabel: true},
      splitLine: {lineStyle: {type: 'dashed'}},
      axisLabel: {
        formatter: function(value: number) {
          return numeral(value).format('0.[0][a]').toUpperCase();
        },
      },
    },
    grid: {top: 10,
      bottom: 130,
      left: 40,
      right: 25},
    series: [
      {
        data: xAxisDataOriginal?.length <=1 ? defaultSeriesData : dataSeries,
        type: 'line',
        symbol: 'none',
        areaStyle: {
          opacity: 0.05,
        },

        lineStyle: {width: 0.5},

      },
    ],
    visualMap: [{
      show: false,
      pieces: [
        {
          // eslint-disable-next-line no-unsafe-optional-chaining
          gt: dataSeries?.length > 0 ? Math.min(...dataSeries?.map(val=> val.value)) : dataSeries,
          lte: 0.001,
          color: 'red',
        },
        {
          gt: 0.001,
          // eslint-disable-next-line no-unsafe-optional-chaining
          lte: dataSeries?.length > 0 ? Math.max(...dataSeries?.map(val=> val.value)) : dataSeries,
          color: 'green',
        },
      ],
    }],
  };

  return <>
    <div style={{padding: '20px'}}><Title>{iconReport()}GSC Rank Change</Title></div>
    {loadingGSCDetail ? <GrayRingLoader margin='7% auto' /> : getReportGSCDetailsData?.googleRankChangeChart ? (
      <>
        <ReactEcharts style={{height: '270px'}}
          ref={echartsRef}
          notMerge
          option={chartOptions}
          lazyUpdate
        />
        <div className={gscStyles.gscEmojiContainer} style={{position: 'absolute', bottom: 120}}>
          {emojiDataWithXPositions
            .filter(x => x.tags?.includes('Major Update') && x.posX !== null)
            .concat(eventDataWithXPositions.filter(x => x.isSitewide && x.posX !== null))
            .map((emoji, i) => {
              return (
                <Popover
                  key={i}
                  overlayClassName={gscStyles.customPopover}
                  placement='bottom'
                  content={
                    <div>
                      {emoji?.occuredAt ?
                        <div className={gscStyles.customPopoverTitle}>{emoji.occuredAt.slice(0, 10)}</div> :
                        <div className={gscStyles.customPopoverTitle}>{emoji.date}</div>
                      }
                      <br/>
                      <div className={gscStyles.customPopoverTitle}>
                        {!isWhitelabel && displayEmoji(emoji.icon) ? <span className={gscStyles.iconSpace} style={{verticalAlign: 'top'}}>{displayEmoji(emoji.icon)}</span> : <img className={gscStyles.iconSpace} style={{verticalAlign: 'top'}} width={15} src={emoji.icon} /> }
                        {emoji.title}</div>
                      <div className={gscStyles.customPopoverDescription}>{emoji.description || emoji.name}</div>
                    </div>
                  }
                >
                  <div style={{cursor: 'pointer', position: 'absolute', left: emoji.posX}} className={`${gscStyles.iconWrapper} ${gscStyles.iconWrapperLight}`}>
                    {!isWhitelabel && displayEmoji(emoji.icon) ? <span style={{color: '#000', cursor: 'pointer'}}>{displayEmoji(emoji.icon)}</span> : <img width={8} height={8} src={emoji.icon}/> }
                  </div>
                </Popover>
              );
            })
          }
        </div>
      </>
    ) : <DivStyle>No Data</DivStyle>}
  </>;
});

const Title = styled.h2`
font-size: 18px;
  img {
    margin-top: 0px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
`;

const DivStyle = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    height: 77%;
    width: 100%;
    justify-content: center;
        background: white;
    border-radius: 8px;
    color: #a3a4a4;

`;


const ChartsWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
  background-color: white;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  border-radius: 16px;
  // margin-top: 20px;
  h2{
    font-size: 16px;
    font-weight: 600;
  }
  svg{
    margin-right: 10px;
  }
`;

const FlexWrapper = styled.div<{align?: string; justifyContent?: string; flexDirection?: string}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};
  flex-direction: ${p=> p?.flexDirection};
`;

const TitleBlack = styled.div<{fontSize?: string}>`
font-weight: 600;
font-size: ${p=> p?.fontSize ? p?.fontSize : '24px'};
line-height: 22px;
color: #121212;
margin-right: 10px;
`;

const Pagination = styled(PaginationStyled)`
background: white !important;
margin-top: 0px;
`;
