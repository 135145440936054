import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Collapse, Dropdown, Form, Menu, Select, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faChevronDown, faChevronUp, faCircleCheck, faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import {
  PublishButton,
  Label,
  StyledForm,
  StyledInput,
  StyledSelect,
  StyledTextArea,
  KGBanner,
  KGFields,
  KGExpand,
  StyledCollapse,
  CollapseWrapper,
  StyledPhoneInput,
  Heading,
  Description,
  LeftSection,
  StyledCheckbox,
  SurpriseColor,
  ColorPickerContainer,
  ColorPickerWrapper,
  StyledPurpleCheckbox,
} from '../styles';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import TextArea from 'antd/lib/input/TextArea';
import {snakeToCamel} from '@/utils/string';
import {debounce} from 'lodash';
import {notification} from '@/utils/notification-v2';
import ColorPicker from '../colorPicker';
import {urlValidationRegexV3} from '@/constants/regex';
import {apiError} from '@/utils/api';
import {showErrorMessages} from '../LandingPageGeneratorModal';

const {Option} = Select;
const kgFields = ['business_name', 'street_address', 'address_locality', 'address_administrative_area', 'address_country', 'postal_code', 'authorship_first_name', 'authorship_last_name', 'email_address', 'phone_number'];
const kgLabels = {
  business_name: 'Business name',
  street_address: 'Street address',
  address_locality: 'Locality',
  address_administrative_area: 'Administrative area',
  address_country: 'Country',
  postal_code: 'Postal code',
  authorship_first_name: 'Author first name',
  authorship_last_name: 'Author last name',
  email_address: 'Email address',
  phone_number: 'Phone number',
};

interface Props {
  setOpenModal: (value: boolean) => void;
  setGenerating: (value: boolean) => void;
  generating: boolean;
  setRecord?: (val: any) => void;
  record?: any;
  setActiveStep?: Dispatch<SetStateAction<number>>;
  setCanRebuild?: Dispatch<SetStateAction<boolean>>;
  setPercentage: any;
  handleAnimate?: any;
}

export const CreateSection = observer(({setOpenModal, generating, setGenerating, setActiveStep, setRecord, handleAnimate, setPercentage, record}: Props) => {
  const [form] = Form.useForm();
  const [knowledgeGraphFields, setKnowledgeGraphFields] = useState([]);
  const [cityList, setCityList] = useState('');
  const [cityListError, setCityListError] = useState(false);
  const [color, setColor] = useState<string | null>(null);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [remainingFields, setRemainingFields] = useState([]);
  const [activeKeys, setActiveKeys] = useState(remainingFields?.length ? ['1'] : []);
  const {ottoV2Store: {
    ottoKnowledgeGraphFieldsMeta,
    knowledgeGraph,
    getOttoV2Project,
    updateKnowledgeGraph,
    generateLandingPage,
    getCitySuggestions,
    getKnowledgeGraphData,
    getLandingPageDetail,
    loadIssueTableData,
  },
  settings: {customer: {getCustomerQuota}},
  } = useStore('');

  const getCitiesSuggestions = useCallback(async pageName => {
    const res = await getCitySuggestions({page_name: pageName});
    if (res) setCitySuggestions(res);
  }, []);

  useEffect(() => {
    getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
    if (record?.city?.length) setCityList(record?.city);
    if (record?.color) setColor(record?.color);
  }, []);

  const debouncedFn = debounce(pageName => {
    if (pageName && pageName?.trim()) getCitiesSuggestions(pageName);
  }, 800);

  useEffect(() => {
    const sections = toJS(ottoKnowledgeGraphFieldsMeta?.value) || [];
    const arr = [];
    sections?.forEach(item => {
      item?.value?.forEach(inner => {
        if (kgFields.includes(inner?.name)) {
          arr.push(
            {...inner, label: kgLabels[inner?.name], rules: [{required: 'true', message: ''}]},
          );
        }
      });
    });
    setKnowledgeGraphFields(arr);
  }, [ottoKnowledgeGraphFieldsMeta]);

  useEffect(() => {
    const remainingFields = kgFields?.filter(fieldName => !knowledgeGraph[snakeToCamel(fieldName)]);
    setRemainingFields(remainingFields);
    setActiveKeys(remainingFields?.length ? ['1'] : []);
  }, [knowledgeGraph]);

  const fields = (placeholder, defaultValue, fieldType, fieldName, options, form, style) => {
    const fieldsObj = {
      'text': <StyledInput autoComplete='off' height='30px' br='8px' placeholder={placeholder} style={style ? style : {}}/>,
      'text_area': <TextArea autoComplete='off' className='text-area' placeholder={placeholder} style={style ? style : {}}/>,
      'number': <StyledInput autoComplete='off' height='30px' br='8px' placeholder={placeholder} type='number' min={fieldName === 'number_of_employees' ? 0 : null} style={style ? style : {}}/>,
      'phone': <StyledPhoneInput inputClass='support-phone-input' country='us' value={defaultValue} width={style?.width ? style.width : ''}/>,
      'email': <StyledInput autoComplete='off' height='30px' br='8px' className='email-input' placeholder={placeholder} type='email' style={style ? style : {}}/>,
      'url': <StyledInput autoComplete='off' height='30px' br='8px' className='email-input' placeholder={placeholder} style={style ? style : {}}/>,
      'dropdown': <StyledSelect
        onChange={value => {
          const obj = {};
          obj[fieldName] = value;
          form.setFieldsValue(obj);
        }}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
        placeholder={placeholder}
        showSearch
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
    };
    return fieldsObj[fieldType];
  };

  const menu = () => {
    return (
      <Menu>
        {citySuggestions?.map(i => (
          <Menu.Item onClick={() => {
            const arr = cityList?.split(', ')?.filter(i => i);
            const newArr = arr?.includes(i) ? arr?.filter(el => el !== i) : [...arr, i];
            if (!newArr?.length) {
              setCityListError(true);
            } else setCityListError(false);
            setCityList(prev => {
              const arr = prev?.split(', ')?.filter(i => i);
              const newArr = arr?.includes(i) ? arr?.filter(el => el !== i) : [...arr, i];
              return newArr?.join(', ');
            });
          }} key={i}>{i}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const onCreate = async (values: any) => {
    try {
      if (!cityList?.trim()) {
        setCityListError(true);
        return;
      } else setCityListError(false);
      const knowledgeGraphPayload = {};
      kgFields.forEach(item => {
        const value = values[item];
        if (value) knowledgeGraphPayload[item] = value;
      });
      handleAnimate();
      setGenerating(true);
      await updateKnowledgeGraph(getOttoV2Project?.knowledgeGraphId, knowledgeGraphPayload);
      const payload = {
        otto_project: getOttoV2Project?.id,
        page_name: values?.page_name,
        is_ai_generated_images: values?.is_ai_generated_images,
        example_site: values?.example_site,
        services: values?.services?.split(',')?.map(item => item?.trim()),
        cities: cityList?.split(',')?.map(item => item?.trim()),
        additional_info: values?.additional_info,
        wordpress_url: null,
        color,
      };
      const res: any = await generateLandingPage(payload);
      const response: any = await getLandingPageDetail(res?.[0]?.uuid);
      if (response?.status === 'Generated') {
        setRecord(response || {});
        getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
        setPercentage(0);
        if (response?.cleanedHtml) notification.success('Success', 'Landing Page generated successfully');
        getCustomerQuota();
        setTimeout(() => {
          setGenerating(false);
          setActiveStep(1);
          loadIssueTableData({page: 1, page_size: 10, issue_type: 'ai_landing_page_builder', uuid: getOttoV2Project?.uuid}, false, true);
          showErrorMessages(response);
        }, 2000);
      }
    } catch (error) {
      const errorMessage = apiError(error, 'Something went wrong') as string;
      const message = errorMessage === 'Network Error' ? 'Something went wrong' : errorMessage;
      notification.error('', message);
      setGenerating(false);
      setPercentage(100);
    }
  };

  return (
    <LeftSection>
      <Heading>AI Landing Page Builder</Heading>
      <Description>AI-powered tool to create customized local landing pages from your business data in seconds.</Description>
      <StyledForm
        form={form}
        initialValues={{
          page_name: record?.pageName,
          services: record?.services?.join(', '),
          additional_info: record?.additionalInfo,
          is_ai_generated_images: record?.isAiGeneratedImages ?? true,
          example_site: record?.exampleSite,
        }}
        onFinish={onCreate}
        onFieldsChange={() => {
          const remaining = kgFields?.filter(fieldName => !form.getFieldsValue()[fieldName]?.trim());
          if (remaining?.length != remainingFields?.length) setRemainingFields(remaining);
        }}
        onFinishFailed={() => {
          if (!cityList?.trim()) {
            setCityListError(true);
            return;
          } else setCityListError(false);
        }}
      >
        <div style={{display: 'flex', flexDirection: 'column', maxHeight: '100%', minHeight: '100%', justifyContent: 'space-between', position: 'relative'}}>
          {generating && <div style={{position: 'absolute', background: 'rgba(255, 255, 255, 0.45)', top: 0, left: 0, zIndex: 2, width: '100%', height: '100%'}}></div>}
          <div className='wrapper'>
            <Label>Page name</Label>
            <Form.Item initialValue='' name='page_name' rules={[{required: true, message: 'Page name is required'}, {whitespace: true, message: 'Page name is required'}]}>
              <StyledInput autoComplete='off' onChange={e => debouncedFn(e?.target?.value)} placeholder='Please enter the name of the Landing Page' />
            </Form.Item>
            <Label>City</Label>
            <Form.Item name='city' valuePropName='value'>
              <Dropdown disabled={!citySuggestions?.length} trigger={['click']} overlay={menu}>
                <div style={{position: 'relative'}}>
                  <StyledInput autoComplete='off' style={{border: cityListError ? '1px solid #ff4d4f' : '', paddingRight: citySuggestions?.length ? '30px' : ''}} placeholder='Enter the city names separated by commas' value={cityList} onChange={e => {
                    if (!e?.target?.value.trim()) {
                      setCityListError(true);
                    } else setCityListError(false);
                    setCityList(e?.target?.value);
                  }} />
                  {!!citySuggestions?.length && <FontAwesomeIcon style={{position: 'absolute', top: '16px', right: '15px'}} icon={faCaretDown} color='#000000' />}
                </div>
              </Dropdown>
              {cityListError && <div style={{color: '#ff4d4f', marginTop: 4, lineHeight: '16px'}}>At least 1 city is required</div>}
            </Form.Item>
            <Label>What are the services you offer?</Label>
            <Form.Item name='services' rules={[{required: true, message: 'Services are required'}, {whitespace: true, message: 'Services are required'}]}>
              <StyledInput autoComplete='off' placeholder='Enter the services separated by commas' />
            </Form.Item>
            <Tooltip overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title='AI Generating Images may take up to 5 minutes' placement='topLeft'>
              <Form.Item style={{minHeight: 26, maxWidth: 'fit-content'}} name='is_ai_generated_images' valuePropName='checked'>
                <StyledPurpleCheckbox style={{fontWeight: 500}}>
                  Generate Images with AI
                </StyledPurpleCheckbox>
              </Form.Item>
            </Tooltip>
            <div style={{marginBottom: 15}}>
              <Label>Choose a main color</Label>
              <ColorPickerContainer>
                <SurpriseColor selected={!color}>
                  <div style={{display: 'flex', gap: '7px', alignItems: 'center', height: 22}}>
                    <StyledCheckbox checked={!color} onChange={e => e?.target?.checked ? setColor(null) : ''} /> Surprise me
                  </div>
                </SurpriseColor>
                <ColorPickerWrapper selected={!!color} style={{display: 'flex', gap: '7px', height: 22}}>
                  <ColorPicker color={color || '#A3A4A4'} setColor={setColor} onChange={val => setColor(val)} /> <div>Custom{color ? <span className='color'>: {color}</span> : ''}</div>
                </ColorPickerWrapper>
              </ColorPickerContainer>
            </div>
            <Label>Example website (optional)</Label>
            <div id='example_site_container'>
              <Tooltip overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title={`Please provide a website for the AI to emulate its design and aesthetic. This won't be an exact replication but rather a source of inspiration.`}>
                <Form.Item name='example_site' rules={[{pattern: urlValidationRegexV3, message: 'Please enter a valid url'}]}>
                  <StyledInput autoComplete='off' placeholder='' id='example_site_input' />
                </Form.Item>
              </Tooltip>
            </div>
            <Label>Additional info (optional)</Label>
            <Form.Item name='additional_info'>
              <StyledTextArea autoComplete='off' placeholder={`Please provide any specific requests or details you'd like the AI to consider. Remember, the quality of your landing page depends on how well you describe your needs!`} />
            </Form.Item>
            <CollapseWrapper>
              <StyledCollapse activeKey={activeKeys} onChange={keys => setActiveKeys(keys as string[])} expandIconPosition='right' expandIcon={props => {
                return <KGExpand>{props?.isActive ? 'Hide' : 'Show'} fields <FontAwesomeIcon style={{marginLeft: 7}} color='#2D6CCA' fontSize={12} icon={props?.isActive ? faChevronUp : faChevronDown} /></KGExpand>;
              }}>
                <Collapse.Panel forceRender header={<Label style={{marginBottom: 0}}>Knowledge graph</Label>} key='1'>
                  <KGFields>
                    <KGBanner completed={!remainingFields?.length}>
                      <div className='inner'>
                        <FontAwesomeIcon icon={!remainingFields?.length ? faCircleCheck : faCircleExclamation} color={!remainingFields?.length ? '#2AC155' : '#F44343'} fontSize={14} />
                        <span style={{lineHeight: 0}}>{remainingFields?.length ? `${remainingFields?.length} required field${remainingFields?.length > 1 ? 's' : ''} must be completed.` : 'All required fields are completed.'}</span>
                      </div>
                    </KGBanner>
                    <div style={{padding: '10px 12px'}}>
                      {knowledgeGraphFields?.map((item, idx) =>
                        <div id={`kg-${item?.type}`} key={item} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 8, gap: 16}}>
                          <p style={{fontSize: 12, margin: 0, color: '#4E5156', minWidth: '126px', marginTop: 4}}>{item?.label}</p>
                          <Form.Item
                            style={{marginBottom: 0, alignItems: 'center', width: '-webkit-fill-available', alignSelf: 'center', flexGrow: 1}}
                            name={item.name}
                            key={idx}
                            initialValue={knowledgeGraph[snakeToCamel(item.name)]}
                            rules={
                              item.type === 'url' ? [
                                {type: 'url', message: 'URL must be valid'},
                              ] : [...(item?.rules || []), {whitespace: true, message: ''}]
                            }
                          >
                            {fields(item.label,
                              knowledgeGraph[snakeToCamel(item.name)],
                              item.type,
                              item.name, item?.options, form, {
                                borderRadius: '8px',
                                border: item?.type === 'text_area' ? '1px solid #D2D2D2' : '',
                                width: item?.type === 'phone' ? 'inherit' : item?.type === 'dropdown' ? '-webkit-fill-available' : '',
                                height: item?.type === 'text_area' || item?.type === 'geolocation_search' ? '30px' : '',
                              })}
                          </Form.Item>
                        </div>,
                      )}
                    </div>
                  </KGFields>
                </Collapse.Panel>
              </StyledCollapse>
              {!activeKeys?.length && <KGBanner completed={!remainingFields?.length} rounded>
                <div className='inner'>
                  <FontAwesomeIcon icon={!remainingFields?.length ? faCircleCheck : faCircleExclamation} color={!remainingFields?.length ? '#2AC155' : '#F44343'} fontSize={14} />
                  <span style={{lineHeight: 0}}>{remainingFields?.length ? `${remainingFields?.length} required field${remainingFields?.length > 1 ? 's' : ''} must be completed.` : 'All required fields are completed.'}</span>
                </div>
              </KGBanner>}
            </CollapseWrapper>
          </div>
          <div style={{display: 'flex', gap: 40, marginTop: 60, alignItems: 'center'}}>
            <PublishButton htmlType='submit' disabled={generating} loading={generating} width='206px' height='44px' br='8px'>{generating ? 'Generating...' : 'Generate Landing Page'}</PublishButton>
            <span onClick={() => setOpenModal(false)} style={{color: '#4E5156', cursor: 'pointer', fontSize: 14}}>Cancel</span>
          </div>
        </div>
      </StyledForm>
    </LeftSection>
  );
});
