import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import Cookies from 'js-cookie';

export const useLeadCapture = () => {
  const router = useRouter();
  const [leadCaptureData, setLeadCaptureData] = useState({});
  const gtmCookie = Cookies.get('__utmz');

  useEffect(() => {
    function loadLeadCaptureData(cookie: string) {
      // 1. parse out gtm params from cookie
      const gtm = {
        utmgclid: null,
        utmcsr: null,
        utmcmd: null,
        utmccn: null,
        utmcct: null,
        utmctr: null,
      };
      const pairs = cookie?.split('.').slice(4).join('.').split('|');
      pairs?.forEach(pair => {
        const [name, value] = pair.split('=');
        gtm[name] = value;
      });

      // 2. transform gtm params into lead capture data
      const leadCapture = {};
      const leadCaptureFields = {
        utmcsr: 'ga_source',
        utmcmd: 'ga_medium',
        utmccn: 'ga_campaign',
        utmcct: 'ga_content',
        utmctr: 'ga_term',
      };
      Object.keys(gtm).forEach(gtmParam => {
        if (gtmParam === 'utmgclid' && gtm[gtmParam]) {
          delete leadCaptureFields['utmcsr'];
          delete leadCaptureFields['utmcmd'];
          leadCapture['ga_source'] = 'google';
          leadCapture['ga_medium'] = 'cpc';
        } else if (gtm[gtmParam] && leadCaptureFields[gtmParam]) {
          leadCapture[leadCaptureFields[gtmParam]] = gtm[gtmParam];
        }
      });

      // 3. set lead capture data into the component state
      setLeadCaptureData({
        landing_page: router.pathname,
        conversion_page: router.pathname,
        ...leadCapture,
      });
    }

    // Load lead capture data from gtm cookies
    loadLeadCaptureData(gtmCookie);
  }, [gtmCookie]);

  return leadCaptureData;
};
