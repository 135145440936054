import {
  Input as AntdInput,
  InputProps as AntdInputProps,
} from 'antd';
import styled from 'styled-components';
import {COLORS} from './colors';

interface InputProps extends AntdInputProps {
  variant?: 'light' | 'dark' | 'gray' | 'bordered';
  hasError?: boolean;
}

export const Input = ({variant = 'light', ...props}: InputProps) => {
  return (
    <InputStyled variant={variant} {...props}></InputStyled>
  );
};
const variantColors = {
  light: {
    color: COLORS.black,
    background: COLORS.white,
    borderColor: COLORS.white,
  },
  dark: {
    color: COLORS.white,
    background: COLORS.grayDark,
    borderColor: COLORS.grayDark,
  },
  gray: {
    color: COLORS.black,
    background: COLORS.tableGray,
    borderColor: COLORS.tableGray,
  },
  white: {
    color: COLORS.black,
    background: COLORS.white,
    borderColor: COLORS.white,
  },
  bordered: {
    color: COLORS.black,
    background: COLORS.white,
    borderColor: COLORS.textGrayLight,
  },
};


const InputStyled = styled(AntdInput)<InputProps>`
  background:${ p => variantColors[p.variant].background || variantColors['white'].background} !important;
  border-radius: ${ p => p.variant === 'dark' ? '5px' : 0};
  border-color:${ p => variantColors[p.variant].borderColor || variantColors['white'].borderColor} !important;
  text-overflow: unset !important;

  ${p => p.hasError && `border-color: ${COLORS.red};`}
  padding: 10px 15px;
  color: ${ p => variantColors[p.variant].color || variantColors['white'].color} !important;
  :focus {
    background:${ p => variantColors[p.variant].background || variantColors['white'].background};
    border-radius: ${ p => p.variant === 'dark' ? '5px' : '5px'};
    border-color:${ p => variantColors[p.variant].borderColor || variantColors['white'].borderColor};
    ${p => p.hasError && `border-color: ${COLORS.red};`}
    padding: 10px 15px;
    color: ${ p => variantColors[p.variant].color || variantColors['white'].color};
  }
  :active {
    background:${ p => variantColors[p.variant].background || variantColors['white'].background};
    border-radius: ${ p => p.variant === 'dark' ? '5px' : '5px'};
    border-color:${ p => variantColors[p.variant].borderColor || variantColors['white'].borderColor};
    ${p => p.hasError && `border-color: ${COLORS.red};`}
    padding: 10px 15px;
    color: ${ p => variantColors[p.variant].color || variantColors['white'].color};
  }
  :focus-visible {
    background:${ p => variantColors[p.variant].background || variantColors['white'].background};
    border-radius: ${ p => p.variant === 'dark' ? '5px' : '5px'};
    border-color:${ p => variantColors[p.variant].borderColor || variantColors['white'].borderColor};
    ${p => p.hasError && `border-color: ${COLORS.red};`}
    padding: 10px 15px;
    color: ${ p => variantColors[p.variant].color || variantColors['white'].color};
  }
`;
