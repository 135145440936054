import {AllProjectsApiResponse} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {PROJECTS_API} from '@/api/projects';
import {useErrorNotification} from '@/utils/notification-v2';
import {cast, flow, types} from 'mobx-state-tree';

const ProjectsStatusesModel = types.model({

});

const ProjectsSaModel = types.model({
  id: types.maybeNull(types.number),
  diff: types.maybeNull(types.number),
  health: types.maybeNull(types.number),
});
const ProjectsSeModel = types.model({
  id: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  authority: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicTrafficTrend: types.maybeNull(types.array(types.number)),
  organicKeywordsTrend: types.maybeNull(types.array(types.number)),
});
const ProjectsKrtModel = types.model({
  id: types.maybeNull(types.number),
  visibility: types.maybeNull(types.number),
  trackedKeywords: types.maybeNull(types.number),
});
const ProjectsOttoModel = types.model({
  daysLeft: types.maybeNull(types.number),
  totalTasks: types.maybeNull(types.number),
  completedTasks: types.maybeNull(types.number),
});
const ProjectsDataModel = types.model({
  sa: types.maybeNull(ProjectsSaModel),
  se: types.maybeNull(ProjectsSeModel),
  krt: types.maybeNull(ProjectsKrtModel),
  otto: types.maybeNull(ProjectsOttoModel),
  saId: types.maybeNull(types.number),
  ottoId: types.maybeNull(types.number),
  seId: types.maybeNull(types.number),
  krtId: types.maybeNull(types.number),
});

const ProjectsResultsModel = types.model({
  id: types.maybeNull(types.number),
  domainUrl: types.maybeNull(types.string),
  customReportId: types.maybeNull(types.number),
  statuses: types.maybeNull(ProjectsStatusesModel),
  competitors: types.maybeNull(types.array(types.string)),
  keywords: types.maybeNull(types.array(types.string)),
  countryCode: types.maybeNull(types.string),
  data: types.maybeNull(ProjectsDataModel),
});

const ProjectsModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(ProjectsResultsModel)),
});

export const HomeProjectsStore = types.model({
  projects: types.maybeNull(ProjectsModel),
  loadingHomePageProjects: types.boolean,
  removingProject: types.array(types.number),
}).actions(self => {
  const deleteProject = flow(function* (projectId: number) {
    try {
      self.removingProject = cast([projectId]);
      const resp: AllProjectsApiResponse = yield PROJECTS_API.deleteProject(projectId);
      return resp;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Could not delete project.',
        desc: 'Try again later.',
        permanent: false,
        handleStatuses: [{
          statuses: [401, 403],
          msg: 'Please sign up to edit this article.',
          permanent: false,
          showDetails: false,
        }],
      });
      return Promise.reject(e);
    } finally {
      self.removingProject = cast([]);
    }
  });


  return {
    deleteProject,
  };
});

export const initHomeProjectsStore = () => {
  return HomeProjectsStore.create({
    projects: {
      count: 0,
      results: [],
    },
    loadingHomePageProjects: false,
    removingProject: [],
  });
};
