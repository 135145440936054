import {useEffect, useState} from 'react';
import {SubscriptionType} from '@/store/settings/customer/index';
import {routes} from '@/utils/const';
import {hasProductAccess} from '@/utils/subscription';
import {
  faLink,
  faPencil,
  faChartLine,
  // faMagnifyingGlass,
  faListAlt,
  // faMapMarkerAlt,
  faCheckDouble,
  // faChartSimple as faChartSimpleDuotone,
  faArrowsRotate,
  faShuffle,
  faHome,
  faGrid2,
  faArrowUpArrowDown,
  faMagnifyingGlassChart,
  faMegaphone,
  // faObjectsColumn,
  faFileChartColumn as faFileChartColumnDuotone,
  faChartSimpleHorizontal as faDuotoneChartSimpleHorizontal,
  faMicrochipAi,
} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {ThunderboltOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import Menu, {ItemsGroup} from '.';
import {
  faList,
  faChartSimple,
  faFile,
  faKeySkeleton as faKeySkeletonRegular,
  faMagnifyingGlass as faMagnifyingGlassRegular,
  faLinkSimple,
  // faLeaf,
  faCalendar,
  faFileChartColumn,
  // faNewspaper,
  // faImageLandscape,
  faHighlighter,
  faPenToSquare,
  faGrid2Plus,
  faLightbulb,
  faChartSimpleHorizontal,
  faArrowUpRightDots,
  faFileMagnifyingGlass,
  faSitemap,
  faBug,
  faChartScatterBubble,
  faEnvelopeOpenText,
  faChartGantt,
  faArrowRightArrowLeft,
  faDiagramVenn,
  faChartLineUp,
  faFileCircleExclamation,
  faFolderTree,
  faGlobe,
  faRectangleAd,
  faNotebook,
  faLayerGroup,
  faListOl,
  faFileLines,
  faPenNib,
  faClipboardList,
  faBoltAuto,
  faMagnifyingGlass,
  faListUl,
  faMapLocationDot,
  faChartTreeMap,
  faMemoPad,
  faCalendarClock,
} from '@fortawesome/pro-regular-svg-icons';
import {faBullseyeArrow, faInbox, faWandMagicSparkles} from '@fortawesome/pro-solid-svg-icons';
import {useRouter} from 'next/router';
import {getTokenFromCookies} from '@/api/common-utils';
import {getSingleUrlParam} from '@/utils/url';
import {GROWTH_PLAN_ID, PRO_PLAN_ID} from '@/constants';
import {faFacebookSquare} from '@fortawesome/free-brands-svg-icons';
import {faLocationDot} from '@fortawesome/pro-duotone-svg-icons';

type ItemsBarProps = {
  handleSideBarItems?: (items) => void;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
};

export const MenuItemsBar = observer(({handleSideBarItems, isSmallScreen, isMediumScreen}: ItemsBarProps) => {
  const {settings: {customer: {profile: {whitelabelOtto, subscription, isLinkgraph, plan}}}, inbox: {getUnreadEmailCount, emailUnreadCount}, siteAuditorV2: {getAuditSitesList}} = useStore('');
  const {competitorResearcherV2: {competitorResearcherUrl, getCompetitorResearcherId}, reportBuilder: {getReportsProjectsList, reportBuilderIdAndDomain}} = useStore('');
  const router = useRouter();
  const checkPublicPages = router.pathname?.includes('public') && !getTokenFromCookies();
  const reportBuilderId = reportBuilderIdAndDomain?.split('-')[0] || '';
  const reportBuilderDomain = reportBuilderIdAndDomain?.split('-')[1] || '';
  const getSiteExplorerDomain = getSingleUrlParam('domain');
  const fromRouterResearcherId = router.query?.id?.length ? Number(router.query?.id[0]) : getCompetitorResearcherId;
  const getMenuItems = (subscription: SubscriptionType) => {
    return [
      {
        title: 'Home',
        key: 'home',
        color: '#F1AA3E',
        path: '/home',
        icon: <Icon onClick={() => {}} icon={faHome} color='#A3A4A4' />,
        items: [],
      },
      {
        title: 'Content',
        key: 'content',
        color: '#1FAC47',
        icon: <Icon onClick={() => {}} icon={faPencil} color='#A3A4A4' />,
        items: [
          {
            title: 'Content Genius',
            key: `/${routes.landingPageOptimizer}`,
            path: `/${routes.landingPageOptimizer}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faPenToSquare} color='#1FAC47'/>,
          },
          {
            title: 'Onpage Audit',
            key: `/${routes.onPageAudit}`,
            path: `/${routes.onPageAudit}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          {
            title: 'Topical Maps',
            key: `/${routes.topicalMaps}`,
            path: `/${routes.topicalMaps}`,
            color: '#1FAC47',
            isNew: true,
            icon: <Icon onClick={() => {}} icon={faChartTreeMap} color='#A3A4A4' />,
          },
          {
            title: 'Content Planner',
            key: '/content/content-planner',
            path: '/content/content-planner',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faGrid2Plus} color='#A3A4A4'/>,
          },
          {
            title: 'Meta Generator',
            key: `/${routes.contentIdeas}`,
            path: `/${routes.contentIdeas}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faLightbulb} color='#A3A4A4'/>,
          },
          {
            title: 'AI Content Templates',
            key: `/${routes.aiContentGenerator}`,
            path: `/${routes.aiContentGenerator}`,
            color: '#1FAC47',
            isBeta: false,
            icon: <Icon onClick={() => {}} icon={faLightbulb} color='#A3A4A4'/>,
          },
          {
            title: 'Content Rewriting',
            key: '/content/rewriting-tool',
            path: '/content/rewriting-tool',
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faArrowsRotate} color='#A3A4A4'/>,
          },
          {
            title: 'Content Brief',
            key: '/content/content-brief',
            path: '/content/content-brief',
            hidden: true,
            isAlpha: true,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faArrowsRotate} color='#A3A4A4'/>,
          },
          // {
          //   title: 'Focus Terms',
          //   key: `/${routes.focusTerms}`,
          //   path: `/${routes.focusTerms}`,
          //   color: '#1FAC47',
          //   icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4' />,
          // },
        ],
      },
      {
        title: 'Site Explorer',
        key: `/${routes.competitorResearcher.default}`,
        path: `/${routes.competitorResearcher.default}`,
        color: '#7F4EAD',
        icon: <Icon onClick={() => {}} icon={faMagnifyingGlassChart} color='#A3A4A4' />,
        items: [
          {
            title: 'Sites list',
            key: `/${routes.competitorResearcher.default}`,
            path: `/${routes.competitorResearcher.default}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: `/${routes.competitorResearcher.detail}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.detail}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faChartSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Keywords',
            key: `/${routes.competitorResearcher.keywords}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.keywords}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Pages',
            key: `/${routes.competitorResearcher.pages}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.pages}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
          },
          {
            title: 'Subdomains',
            key: `/${routes.competitorResearcher.subdomains}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.subdomains}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faGlobe} color='#A3A4A4'/>,
          },
          {
            title: 'Google Ads',
            key: `/${routes.competitorResearcher.googlePage}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.googlePage}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faRectangleAd} color='#A3A4A4'/>,
          },
          {
            title: 'Facebook Ads',
            key: `${routes.competitorResearcher.facebookAds}/${fromRouterResearcherId}`,
            path: `${routes.competitorResearcher.facebookAds}/${fromRouterResearcherId}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFacebookSquare} color='#A3A4A4'/>,
            isNew: true,
          },
          {
            title: 'Position Changes',
            key: `/${routes.competitorResearcher.positionChanges}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.positionChanges}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faArrowUpArrowDown} color='#A3A4A4'/>,
          },
          {
            title: 'Backlinks',
            key: `/${routes.competitorResearcher.backlinks}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.backlinks}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faLinkSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Competitors',
            key: `/${routes.competitorResearcher.competitors}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            path: `/${routes.competitorResearcher.competitors}/${fromRouterResearcherId}?domain=${getSiteExplorerDomain || competitorResearcherUrl || ''}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faArrowUpRightDots} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Keywords',
        key: 'research',
        color: '#FF8536',
        icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4' />,
        items: [
          {
            title: 'Keyword Research',
            key: `/research/keyword-researcher`,
            path: `/research/keyword-researcher`,
            color: '#FF8536',
            isBeta: true,
            hidden: !router?.pathname?.includes('public') && !hasProductAccess(subscription, 'keyword-researcher'),
            icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Magic Tool',
            key: `/research/keyword-magic-tool`,
            path: `/research/keyword-magic-tool`,
            color: '#FF8536',
            isBeta: true,
            icon: <Icon onClick={() => {}} icon={faWandMagicSparkles} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Rank Tracker',
            key: `${routes.keywordRankTracker}`,
            path: `${routes.keywordRankTracker}`,
            color: '#FF8536',
            icon: <Icon onClick={() => {}} icon={faShuffle} color='#A3A4A4'/>,
          },
          {
            title: 'Keyword Gap',
            key: `${routes.keywordGapAnalysis}`,
            path: `${routes.keywordGapAnalysis}`,
            color: '#FF8536',
            icon: <Icon onClick={() => {}} icon={faChartGantt} color='#A3A4A4'/>,
          },
        ],
      },
      {
        title: 'Site Audit',
        key: 'site-audit',
        color: '#7F4EAD',
        icon: <ThunderboltOutlined style={{color: '#A3A4A4'}} />,
        items: [
          {
            title: 'Audits list',
            key: '/site-audit-list',
            path: '/site-audit/list',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: '/site-audit/overview',
            path: '/site-audit/overview',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faChartSimple} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Page Explorer',
            key: '/site_audit/page_explorer',
            path: '/site-audit/page-explorer',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faFileMagnifyingGlass} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Content Velocity',
            key: '/site_audit/content-velocity',
            path: '/site-audit/content-velocity',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faCalendarClock} color='#A3A4A4'/>,
            hidden: !isLinkgraph,
            isAlpha: true,
          },
          {
            title: 'Domain-level',
            key: '/site_audit/sitewide_data',
            path: '/site-audit/sitewide-data',
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faSitemap} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Issues',
            key: `${routes.saIssuesPage}`,
            path: `${routes.saIssuesPage}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => { }} icon={faBug} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Page Pruning',
            key: 'page pruning',
            path: '/site-audit/pruning-page',
            color: '#7F4EAD',
            isBeta: false,
            icon: <Icon onClick={() => { }} icon={faFileCircleExclamation} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Site Visualization',
            key: 'siteVisualization',
            path: '/site-audit/site-visualization',
            isBeta: true,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faChartScatterBubble} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Email Alerts',
            key: 'email-alerts',
            path: '/site-audit/email-alerts',
            color: '#7F4EAD',
            hidden: checkPublicPages,
            icon: <Icon onClick={() => {}} icon={faEnvelopeOpenText} color='#A3A4A4'/>,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
          {
            title: 'Live Alerts Monitoring',
            key: `${routes.saAlertsPage}`,
            path: `${routes.saAlertsPage}`,
            color: '#7F4EAD',
            icon: <Icon onClick={() => {}} icon={faEnvelopeOpenText} color='#A3A4A4' />,
            commingSoon: !(getAuditSitesList?.length || (router?.query?.id && router?.pathname?.includes('site-audit'))),
            disabledMsg: 'You need to create a project.',
          },
        ],
      },
      {
        title: <div>GSC Performance</div>,
        key: 'gsc',
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faChartLine} color='#A3A4A4' size='sm' />,
        items: [
          {
            title: 'Sites list',
            key: '/gsc/sites-list',
            path: '/gsc/sites-list',
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: '/gsc/overview',
            path: '/gsc',
            hidden: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
          },
          {
            title: 'Overview',
            key: 'gsc/overview',
            path: `/${routes.overview}`,
            hidden: !hasProductAccess(subscription, 'gsc'),
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faChartSimpleHorizontal} color='#A3A4A4'/>,
          },
          // TO DO: Remove afte new pages is tested out fully
          // {
          //   title: 'Top Pages',
          //   key: 'gsc/top-pages',
          //   path: `/${routes.topPages}`,
          //   hidden: !hasProductAccess(subscription, 'gsc'),
          //   color: '#2D6CCA',
          //   icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
          // },
          {
            title: 'Pages',
            key: 'gsc/pages',
            path: `/${routes.topPagesNew}`,
            hidden: false,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
            isAlpha: false,
          },
          // {
          //   title: 'Top Keywords',
          //   key: 'gsc/top-keywords',
          //   path: `/${routes.topKeywords}`,
          //   hidden: !hasProductAccess(subscription, 'gsc'),
          //   color: '#2D6CCA',
          //   icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
          // },
          {
            title: 'Keywords',
            key: 'gsc/top-keywords',
            path: `/${routes.keywords}`,
            hidden: !hasProductAccess(subscription, 'gsc'),
            isAlpha: false,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faKeySkeletonRegular} color='#A3A4A4'/>,
          },
          {
            title: 'Backlinks',
            key: 'gsc/backlinks',
            path: `/${routes.backlinks}`,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faLinkSimple} color='#A3A4A4'/>,
          },
          {
            title: 'Site Structure',
            key: `/${routes.siteStructure}`,
            path: `/${routes.siteStructure}`,
            hidden: !isLinkgraph,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFolderTree} color='#A3A4A4'/>,
          },
          {
            title: 'Competitor Discovery',
            key: 'gsc/competitor-descovery',
            path: `/${routes.competitorDiscovery}`,
            hidden: !isLinkgraph,
            isAlpha: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faArrowUpRightDots} color='#A3A4A4'/>,
          },
          {
            title: 'Site Events',
            key: 'site events',
            path: '/gsc/site-events',
            isBeta: false,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faCalendar} color='#A3A4A4'/>,
          },
          {
            title: 'Reports',
            key: '/gsc/reports',
            path: '/gsc/reports',
            hidden: !isLinkgraph,
            isAlpha: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faFileChartColumn} color='#A3A4A4'/>,
          },
          {
            title: 'Additional Info',
            key: '/gsc/additional-info',
            path: '/gsc/additional-info',
            isBeta: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faGrid2} color='#A3A4A4'/>,
          },
        ],
      },
      // {
      //   title: 'Site Audit old',
      //   key: 'site-audit-old',
      //   isAlpha: true,
      //   hidden: !isLinkgraph,
      //   icon: <Icon onClick={() => {}} icon={faListCheck} color='#A3A4A4' />,
      //   items: [
      //     {
      //       title: 'Page Explorer',
      //       key: 'site-audit-overview',
      //       path: '/site-audit-old',
      //       hidden: false,
      //       icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
      //     },
      //     {
      //       title: 'Issues',
      //       key: 'issues',
      //       path: '/site-audit-old/issues',
      //       hidden: false,
      //       icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
      //     },
      //     {
      //       title: 'Reports',
      //       key: '/site-audit-old/reports',
      //       path: '/site-audit-old/reports/internal-pages-report',
      //       hidden: false,
      //       isBeta: true,
      //       icon: <Icon onClick={() => {}} icon={faCalendar} color='#A3A4A4'/>,
      //     },
      //     {
      //       title: 'Page Pruning',
      //       key: 'page pruning',
      //       path: '/site-audit-old/pruning-page',
      //       hidden: false,
      //       isBeta: true,
      //       icon: <Icon onClick={() => {}} icon={faLeaf} color='#A3A4A4'/>,
      //     },
      //   ],
      // },
      {
        title: 'Digital PR',
        key: 'digital-pr',
        color: '#2D6CCA',
        icon: <Icon onClick={() => {}} icon={faMegaphone} color='#A3A4A4'/>,
        items: [
          // {
          //   title: 'HARO',
          //   key: `/${routes.haro}`,
          //   path: `/${routes.haro}`,
          //   color: '#2D6CCA',
          //   isBeta: true,
          //   icon: <Icon onClick={() => {}} icon={faNewspaper} color='#2D6CCA' />,
          // },
          {
            title: 'PR Outreach',
            key: `/${routes.digitalPr}`,
            path: `/${routes.digitalPr}`,
            isBeta: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
          },
          {
            title: 'Journalist Details',
            key: `/${routes.journalistDetails}`,
            path: `/${routes.journalistDetails}`,
            hidden: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
          },
          {
            title: 'Media Outlets',
            key: `/${routes.mediaOutletsDetail}`,
            path: `/${routes.mediaOutletsDetail}`,
            hidden: true,
            color: '#2D6CCA',
            icon: <Icon onClick={() => {}} icon={faNotebook} color='#2D6CCA'/>,
          },
          {
            title: 'Campaigns',
            key: '/outreach/campaigns',
            path: '/outreach/campaigns',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faBullseyeArrow} color='#2D6CCA' />,
          },
          {
            title: 'Inbox',
            key: '/outreach/inbox',
            path: '/outreach/inbox',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faInbox} color='#2D6CCA' />,
            unreadEmails: emailUnreadCount,
          },
          {
            title: 'Templates',
            key: '/outreach/templates',
            path: '/outreach/templates',
            color: '#2D6CCA',
            isBeta: true,
            icon: <FontAwesomeIcon icon={faMemoPad} color='#2D6CCA' />,
          },
          {
            title: 'PR Distribution',
            key: '/outreach/press-release',
            path: '/outreach/press-release',
            color: '#2D6CCA',
            hidden: !isLinkgraph,
            isAlpha: true,
            icon: <FontAwesomeIcon icon={faFileLines} color='#2D6CCA' />,
          },
        ],
      },
      // {
      //   title: 'Report Builder',
      //   key: '/report-builder',
      //   color: '#ffffff',
      //   isBeta: true,
      //   icon: <Icon onClick={() => {}} icon={faFileChartColumnDuotone} color='#A3A4A4'/>,
      //   items: [
      //     {
      //       title: 'Reports List',
      //       key: `/${routes.reportBuilder.list}`,
      //       path: `/${routes.reportBuilder.list}`,
      //       color: '#ffffff',
      //       icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4' />,
      //     },
      //     {
      //       title: 'Full Report',
      //       key: `/${routes.reportBuilder.bulkExport}?report_id=${reportBuilderId}&domain=${reportBuilderDomain}`,
      //       path: `/${routes.reportBuilder.bulkExport}?report_id=${reportBuilderId}&domain=${reportBuilderDomain}`,
      //       color: '#ffffff',
      //       commingSoon: !reportBuilderId && getReportsProjectsList?.length==0,
      //       disabledMsg: 'You need to create a project.',
      //       icon: <Icon onClick={() => {}} icon={faLayerGroup} color='#A3A4A4' />,
      //     },
      //     {
      //       title: 'Individual Reports',
      //       key: `/${routes.reportBuilder.detail}?report_id=${reportBuilderId}`,
      //       path: `/${routes.reportBuilder.detail}?report_id=${reportBuilderId}`,
      //       color: '#ffffff',
      //       commingSoon: !reportBuilderId && getReportsProjectsList?.length==0,
      //       disabledMsg: 'You need to create a project.',
      //       icon: <Icon onClick={() => {}} icon={faListOl} color='#A3A4A4' />,
      //     },
      //   ],
      // },
      {
        title: 'Report Builder',
        key: routes.reportBuilder.history,
        color: '#ffffff',
        icon: <Icon onClick={() => {}} icon={faFileChartColumnDuotone} color='#A3A4A4'/>,
        path: '/report-builder',
        isBeta: true,
        items: [
          {
            title: 'Reports List',
            key: `/${routes.reportBuilder.list}`,
            path: `/${routes.reportBuilder.list}`,
            color: '#ffffff',
            icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4' />,
          },
          {
            title: 'Full Report',
            key: `/${routes.reportBuilder.bulkExport}?report_id=${reportBuilderId}&domain=${reportBuilderDomain}`,
            path: `/${routes.reportBuilder.bulkExport}?report_id=${reportBuilderId}&domain=${reportBuilderDomain}`,
            color: '#ffffff',
            commingSoon: !router?.query?.['report_id'] && (getReportsProjectsList?.length==0 || !getReportsProjectsList),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faLayerGroup} color='#A3A4A4' />,
          },
          {
            title: 'Individual Reports',
            key: `/${routes.reportBuilder.detail}?report_id=${reportBuilderId}`,
            path: `/${routes.reportBuilder.detail}?report_id=${reportBuilderId}`,
            color: '#ffffff',
            commingSoon: !router?.query?.['report_id'] && (getReportsProjectsList?.length==0 || !getReportsProjectsList),
            disabledMsg: 'You need to create a project.',
            icon: <Icon onClick={() => {}} icon={faListOl} color='#A3A4A4' />,
          },
        ],
      },
      // {
      //   title: 'Other Tools',
      //   key: 'other-tools',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faObjectsColumn} color='#A3A4A4' />,
      //   items: [
      //     {
      //       title: 'Focus Terms',
      //       key: 'focus-terms',
      //       path: `/${routes.focusTerms}`,
      //       color: '#019197',
      //       icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4' />,
      //     },
      // {
      //   title: 'Local Search Results',
      //   key: 'local-search-results',
      //   path: '/local-search-results',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faMapMarkerAlt} color='#A3A4A4' />,
      // },
      // {
      //   title: 'Schema Creator',
      //   key: 'schema',
      //   path: '/schema',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faListAlt} color='#A3A4A4' />,
      // },
      // {
      //   title: 'Bulk DA Checker',
      //   key: 'bulk-da-checker',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faCheckDouble} color='#A3A4A4' />,
      //   path: '/bulk-da-checker',
      // },
      //   ],
      // },
      {
        title: 'Bulk DA Checker',
        key: '/bulk-da-checker',
        color: '#ffffff',
        icon: <Icon onClick={() => {}} icon={faCheckDouble} color='#A3A4A4' />,
        path: '/bulk-da-checker',
      },
      {
        title: 'Schema Creator',
        key: '/schema',
        path: '/schema',
        color: '#ffffff',
        hidden: !isLinkgraph,
        isAlpha: true,
        icon: <Icon onClick={() => {}} icon={faListAlt} color='#A3A4A4' />,
      },
      // {
      //   title: 'Local Search Results',
      //   key: '/local-search-results',
      //   path: '/local-search-results',
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faMapMarkerAlt} color='#A3A4A4' />,
      // },
      {
        title: 'Backlinks',
        key: 'backlink',
        color: '#34AEF3',
        icon: <Icon onClick={() => {}} icon={faLink} color='#A3A4A4' />,
        items: [{
          title: 'Backlink Research',
          key: `${routes.backlinkResearcher}`,
          path: `${routes.backlinkResearcher}`,
          // hidden: !isLinkgraph,
          // isAlpha: true,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
        },
        {
          title: 'Projects List',
          key: `/${routes.backlinkAnalyzer}`,
          path: `/${routes.backlinkAnalyzer}`,
          color: '#34AEF3',
          hidden: !isLinkgraph,
          isAlpha: true,
          icon: <Icon onClick={() => {}} icon={faList} color='#A3A4A4'/>,
        },
        {
          title: 'Link Gap Analysis',
          key: `${routes.backlinkGapAnalysis}`,
          path: `${routes.backlinkGapAnalysis}`,
          hidden: false,
          isBeta: false,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faChartGantt} color='#A3A4A4'/>,
        },
        {
          title: 'Compare Backlinks',
          key: `${routes.backlinkProfileAnalysis}`,
          path: `${routes.backlinkProfileAnalysis}`,
          hidden: false,
          isBeta: false,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faArrowRightArrowLeft} color='#A3A4A4'/>,
        },
        {
          title: 'Topical Relevance',
          key: `/${routes.topicalRelevance}`,
          path: `/${routes.topicalRelevance}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faDiagramVenn} color='#A3A4A4'/>,
          commingSoon: true,
        },
        {
          title: 'Backlink Velocity',
          key: `/${routes.backlinkVelocity}`,
          path: `/${routes.backlinkVelocity}`,
          color: '#34AEF3',
          icon: <Icon onClick={() => {}} icon={faChartLineUp} color='#A3A4A4'/>,
          commingSoon: true,
        },
        ],
      },
      {
        title: 'Bulk URL Analyzer',
        key: '/bulk-url-analyzer',
        color: '#ffffff',
        icon: <Icon onClick={() => {}} icon={faDuotoneChartSimpleHorizontal} color='#A3A4A4' />,
        path: '/bulk-url-analyzer',
        hidden: ![GROWTH_PLAN_ID, PRO_PLAN_ID].includes(plan),
        isBeta: true,
      },
      {
        title: `${whitelabelOtto} v1.0`,
        key: routes.ottopage,
        color: '#1FAC47',
        icon: <Icon onClick={() => {}} icon={faMicrochipAi} color='#fff'/>,
        path: '/otto-page',
      },
      ...(
        isSmallScreen ? [
          {
            title: 'Local SEO',
            key: `${routes.localSeo}`,
            path: `${routes.localSeo}`,
            color: '#1FAC47',
            icon: <Icon onClick={() => {}} icon={faLocationDot} color='#A3A4A4'/>,
            items: [
              {
                title: 'My businesses',
                key: `${routes.localSeoProjectsList}`,
                path: `${routes.localSeoProjectsList}`,
                color: '#1FAC47',
                icon: <Icon onClick={() => {}} icon={faListUl} color='#A3A4A4'/>,
              },
              {
                title: 'Local SEO Heatmap',
                key: `${routes.localSeo}`,
                path: `${routes.localSeo}`,
                color: '#1FAC47',
                icon: <Icon onClick={() => {}} icon={faMapLocationDot} color='#A3A4A4'/>,
              },
              {
                title: 'Local Listings',
                key: `''`,
                path: `''`,
                commingSoon: true,
                color: '#1F2125',
                icon: <Icon onClick={() => {}} icon={faClipboardList} color='#A3A4A4'/>,
              },
              {
                title: 'Local Citations',
                key: `${routes.localCitations}`,
                path: `${routes.localCitations}`,
                color: '#1F2125',
                icon: <Icon onClick={() => {}} icon={faHighlighter} color='#A3A4A4'/>,
                isBeta: true,
              },
              {
                title: 'GBP Automations',
                key: `''`,
                path: `''`,
                commingSoon: true,
                color: '#1F2125',
                icon: <Icon onClick={() => {}} icon={faBoltAuto} color='#A3A4A4'/>,
              },
              {
                title: 'Local SERPs',
                key: '/local-search-results',
                path: '/local-search-results',
                color: '#1FAC47',
                icon: <Icon onClick={() => {}} icon={faMagnifyingGlass} color='#A3A4A4' />,
              },
              {
                title: 'AI GBP Writer',
                key: `${routes.aiGmbWriter}`,
                path: `${routes.aiGmbWriter}`,
                color: '#1FAC47',
                hidden: !isLinkgraph,
                isAlpha: true,
                icon: <Icon onClick={() => {}} icon={faPenNib} color='#A3A4A4'/>,
              },
            ],
          },
        ] : []
      ),
      ...(isMediumScreen ? [
        {
          title: `${whitelabelOtto} SEO V2`,
          key: routes.ottoPageV2,
          color: '#2D6CCA',
          icon: <Icon onClick={() => {}} icon={faMicrochipAi} color='#fff'/>,
          path: '/otto-page-v2',
        },
      ] : []
      ),
      // {
      //   title: 'Competitors',
      //   key: 'competitors',
      //   color: '#019197',
      //   icon: <Icon onClick={() => {}} icon={faChartSimpleDuotone} color='#A3A4A4' />,
      //   items: [
      //     {
      //       title: 'Competitor Research',
      //       key: `/${routes.competitorExplorer.default}`,
      //       path: `/${routes.competitorExplorer.default}`,
      //       color: '#019197',
      //       icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
      //     },
      //   ],
      // },
      // {
      //   title: 'Organic Landscapes',
      //   key: '/gsc/organic-search',
      //   path: '/gsc/organic-search',
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faImageLandscape} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'PPC Landscapes',
      //   key: '/gsc/ppc-search',
      //   path: '/gsc/ppc-search',
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faImageLandscape} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'Anchor Text',
      //   key: `/${routes.anchorText}`,
      //   path: `/${routes.anchorText}`,
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faFile} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'Toxic Backlinks',
      //   key: `/${routes.toxicBacklink}`,
      //   path: `/${routes.toxicBacklink}`,
      //   hidden: !isLinkgraph,
      //   isAlpha: true,
      //   color: '#ffffff',
      //   icon: <Icon onClick={() => {}} icon={faLeaf} color='#A3A4A4'/>,
      // },
      // {
      //   title: 'HARO',
      //   key: `/${routes.haro}`,
      //   path: `/${routes.haro}`,
      //   color: '#019197',
      //   isBeta: true,
      //   icon: <Icon onClick={() => {}} icon={faChartSimpleDuotone} color='#A3A4A4' />,
      // items: [
      //   {
      //     title: 'Submited Pitches',
      //     key: `/reporter/submited-pitches`,
      //     path: `/reporter/submited-pitches`,
      //     color: '#019197',
      //     icon: <Icon onClick={() => {}} icon={faMagnifyingGlassRegular} color='#A3A4A4'/>,
      //   },
      // ],
      // },
    ];
  };

  const [menuItems, setMenuItems] = useState<any>(getMenuItems(subscription));
  const [isListEmpty, setIsListEmpty] = useState(true);

  useEffect(()=> {
    setMenuItems(getMenuItems(subscription));
  }, [subscription, router?.pathname, fromRouterResearcherId, whitelabelOtto]);

  useEffect(() => {
    const loadInboxData = async () => {
      if (router && (router.pathname.includes('digital-pr') || router.pathname.includes('haro') || router.pathname.includes('outreach'))) {
        await getUnreadEmailCount();
        const updatedMenuItems = getMenuItems(subscription)?.map(i => {
          if (i?.key === 'digital-pr') {
            return {
              ...i,
              items: i?.items?.map(value => {
                if (value?.key === '/outreach/inbox') {
                  const unreadCount = emailUnreadCount;
                  return {...value, unreadEmails: unreadCount};
                }
                return value;
              }),
            };
          }
          return i;
        });
        setMenuItems(updatedMenuItems);
      }
    };

    loadInboxData();
  }, [router?.pathname, emailUnreadCount]);

  useEffect(() => {
    if (router?.pathname.includes('/report-builder')) {
      if ((!getReportsProjectsList?.length) == isListEmpty) {
        setMenuItems(getMenuItems(subscription));
        setIsListEmpty(getReportsProjectsList?.length == 0);
      }
    }
  }, [router?.pathname, getReportsProjectsList]);

  useEffect(() => {
    if (router?.pathname.includes('/site-audit')) {
      setMenuItems(getMenuItems(subscription));
    }
  }, [router?.pathname, getAuditSitesList]);

  return <Menu groups={menuItems as ItemsGroup[]} reportBuilderId={reportBuilderId} isSmallScreen={isSmallScreen} handleSideBarItems={handleSideBarItems}/>;
});

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px !important;
`;
