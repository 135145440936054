import {observer} from 'mobx-react';
import {
  BodyWrapper,
  ButtonStyled,
  EmailPresetFieldsWrapper,
  EmailPresetSectionWrapper,
  EmailPresetHeader,
  FooterWrapper,
  MenuStyled,
  // SelectStyled,
  // SenderWrappper,
  StyledInput,
  SubjectWrapper} from '../../styledComponents';
import {Divider, Dropdown, Menu, Popover, Radio, Button, Input} from 'antd';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {faEye, faCirclePause, faReplyClock} from '@fortawesome/pro-duotone-svg-icons';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import 'draft-js/dist/Draft.css';
// import {EditorComponent} from '@/components/common-components/components/outreach/editor/editorComponent';
import {useEffect} from 'react';
import EditorComponentV2 from '@/components/common-components/components/outreach/editor/templateEditor';
import {useState} from 'react';
import styled from 'styled-components';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
interface Props {
  subjectValue?: string;
  setSubjectValue?: (value: string) => void;
  setSender?: (value: number) => void;
  setExistingTemplateEditorState?: (value: EditorState) => void;
  setExistingTemplateId?: (value: number) => void;
  editorState?: string;
  onEditorV2Change?: (value: string) => void;
  setFollowUpEmailsArray?: (value: any) => void;
  followUpEmailsArray?: any;
  condition?: string;
  setCondition?: (value: string) => void;
  body?: string;
  setBody?: (value: string) => void;
  subject?: string;
  setSubject?: (value: string) => void;
  nameVal?: string;
  setName?: (value: string) => void;
  addEmail?: (any) => void;
  setFollowUpEmails?: (value: boolean) => void;
  followUpEmails?: boolean;
  setFollowUpErrors?: (value: boolean) => void;
  setNoOfFollowUpEmails?: (any) => void;
  noOfFollowUpEmails?: any;
  isEdit?: boolean;
  sender?: number;
}

export const FollowUpEmailsSection = observer(({
  setSender,
  setExistingTemplateEditorState,
  setExistingTemplateId,
  followUpEmailsArray,
  setFollowUpEmailsArray,
  condition,
  setCondition,
  body,
  setBody,
  subject,
  setSubject,
  addEmail,
  nameVal,
  setName,
  setFollowUpEmails,
  followUpEmails,
  setFollowUpErrors,
  noOfFollowUpEmails,
  setNoOfFollowUpEmails,
  isEdit,
  sender,
}: Props) => {
  // const {Option}= Select;
  const {campaigns: {
    campaignsCustomerEmails,
    existingTemplates,
    showErrors,
    setShowErrors,
    setCurrentEditorState}} = useStore('');

  // const onChange = value => {
  //   setSender(value);
  // };

  const handleUseTemplateBtnClick = (body, subject, id, name, emailNo) => {
    const {contentBlocks, entityMap} = convertFromHTML(body);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    const editorState = EditorState.createWithContent(contentState);
    setSubject(subject);
    setExistingTemplateEditorState(editorState);
    setExistingTemplateId(id);
    setCurrentEditorState(editorState);
    setBody(body);
    setName(name);
    setFollowUpEmailsArray(prev => {
      const updatedArr = prev?.map(item => {
        if (item?.id === followUpEmailsArray[emailNo - 1]?.id) {
          return {...item, name, subject, body};
        }
        return item;
      });
      return updatedArr;
    });
  };

  useEffect(() => {
    setSender(sender ? sender : campaignsCustomerEmails?.results?.length ? campaignsCustomerEmails?.results[0]?.id : 0);
  }, []);

  // const [noOfFollowUpEmails, setNoOfFollowUpEmails] = useState([1]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState({});
  const addFollowUpEmail = () => {
    if (noOfFollowUpEmails.length < 3) {
      setNoOfFollowUpEmails([...noOfFollowUpEmails, noOfFollowUpEmails.length + 1]);
    }
  };

  const handlePopoverVisibleChange = (visible, index) => {
    const newPopoverVisible = {};
    if (visible) {
      newPopoverVisible[index] = true;
    }
    setPopoverVisible(newPopoverVisible);
  };

  const handleChange = (index, newValue, key) => {
    followUpEmailsArray[index] ? setFollowUpEmailsArray(prevArray => {
      const updatedItem = {
        ...prevArray[index],
        [key]: key === 'send_after_hours' ? +newValue : newValue,
      };
      const newArray = [...prevArray];
      newArray[index] = updatedItem;
      return newArray;
    }) : (key == 'send_after_hours' ? setCondition(newValue) : key == 'name' ? setName(newValue) : key == 'subject' ? setSubject(newValue) : setBody(newValue));
  };

  const deleteFollowUpEmail = emailNo => {
    setNoOfFollowUpEmails(noOfFollowUpEmails.filter(e => e!=noOfFollowUpEmails.length));
    setFollowUpEmailsArray(followUpEmailsArray?.filter((e, index) => index!=emailNo-1));
    resetFields();
    setFollowUpErrors(false);
    setShowErrors(false);
  };

  const resetFields = () => {
    setName('');
    setCondition('3');
    setBody('');
    setSubject('');
  };

  useEffect(() => {
    if (!isEdit) {
      setFollowUpErrors(true);
    }
  }, []);

  return (
    <>
      <div style={{display: 'flex', marginTop: 10}}>
        <div onClick={() => {
          setFollowUpEmails(false);
          setNoOfFollowUpEmails([1]);
          setFollowUpEmailsArray([]);
          setShowErrors(false);
          resetFields();
        }} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${followUpEmails ? 'rgba(255, 255, 255, 1)' :'rgba(45, 108, 202, 0.15)'}`, borderRadius: 8, width: 335, padding: 9, marginRight: 12}}>
          <StyledRadioButton onClick={() => setFollowUpEmails(false)} checked={!followUpEmails}/>
          <FontAwesomeIcon icon={faCirclePause} color={`${!followUpEmails ? 'rgba(45, 108, 202, 1)' : 'rgba(163, 164, 164, 1)'}`} fontSize='28px'/>
          <div style={{marginLeft: 5}}>
            <div style={{color: 'rgba(18, 18, 18, 1)', fontWeight: 500, fontSize: 14}}>Don’t send Follow-Up Emails</div>
            <div style={{color: 'rgba(78, 81, 86, 1)', fontWeight: 400, fontSize: 12}}>Opt-out of follow-up communications.</div>
          </div>
        </div>
        <div onClick={() => setFollowUpEmails(true)} style={{cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${!followUpEmails ? 'rgba(255, 255, 255, 1)' :'rgba(45, 108, 202, 0.15)'}`, borderRadius: 8, width: 335, padding: 9}}>
          <StyledRadioButton onClick={() => setFollowUpEmails(true)} checked={followUpEmails}/>
          <FontAwesomeIcon icon={faReplyClock} color={`${followUpEmails ? 'rgba(45, 108, 202, 1)' : 'rgba(163, 164, 164, 1)'}`} fontSize='28px'/>
          <div style={{marginLeft: 5}}>
            <div style={{color: 'rgba(18, 18, 18, 1)', fontWeight: 500, fontSize: 14}}>Send up to 3 Follow-Up Emails</div>
            <div style={{color: 'rgba(78, 81, 86, 1)', fontWeight: 400, fontSize: 12}}>Ensure no conversation is lost.</div>
          </div>
        </div>
      </div>
      {noOfFollowUpEmails?.map(emailNo => (
        <AddFollowUpEmail
          key={emailNo}
          showErrors={showErrors}
          existingTemplates={existingTemplates}
          handleUseTemplateBtnClick={handleUseTemplateBtnClick}
          followUpEmails={followUpEmails}
          addFollowUpEmail={addFollowUpEmail}
          emailNo={emailNo}
          noOfFollowUpEmails={noOfFollowUpEmails}
          subject={subject}
          body={body}
          addEmail={addEmail}
          followUpEmailsArray={followUpEmailsArray}
          condition={condition}
          nameVal={nameVal}
          handleChange={handleChange}
          setShowErrors={setShowErrors}
          deleteFollowUpEmail={deleteFollowUpEmail}
          setFollowUpErrors={setFollowUpErrors}
          setDropdownVisible={setDropdownVisible}
          dropdownVisible={dropdownVisible}
          setPopoverVisible={setPopoverVisible}
          popoverVisible={popoverVisible}
          handlePopoverVisibleChange={handlePopoverVisibleChange}
        />
      ))}
    </>);
});

const AddFollowUpEmail = ({handlePopoverVisibleChange, setPopoverVisible, popoverVisible, setDropdownVisible, dropdownVisible, setFollowUpErrors, deleteFollowUpEmail, setShowErrors, handleChange, nameVal, condition, followUpEmailsArray, addEmail, subject, body,
  noOfFollowUpEmails, emailNo, followUpEmails, showErrors, existingTemplates, handleUseTemplateBtnClick, addFollowUpEmail}) => {
  return followUpEmails && <EmailPresetSectionWrapper>
    <EmailPresetHeader>Follow-Up Email #{emailNo}{emailNo != 1 &&
      <span onClick={() => deleteFollowUpEmail(emailNo)}
        style={{color: 'rgba(78, 81, 86, 1)', fontSize: 12, fontWeight: 400, float: 'right', cursor: 'pointer'}}><FontAwesomeIcon icon={faTrash} color='rgba(78, 81, 86, 1)'/> Delete</span>}</EmailPresetHeader>
    <EmailPresetFieldsWrapper>
      {/* <SenderWrappper>
      <div style={{color: '#121212', fontSize: '12px', marginRight: '35px'}}>Condition</div>
      <SelectStyled
        defaultValue={campaignsCustomerEmails?.results?.length ? campaignsCustomerEmails?.results[0]?.id : 'Select Sender'}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000'/>}
        onChange={onChange}>
        {campaignsCustomerEmails?.results?.map((item, idx) => <Option key={idx} value={item?.id}>
          {item?.emailAddress}
        </Option>)}
      </SelectStyled>
    </SenderWrappper> */}
      <>
        <SubjectWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '20px', marginBottom: showErrors && !condition ? '20px' : ''}}>Condition</div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>{emailNo == 1 ? 'Didn’t receive a reply in' : `Since Follow-Up Email ${emailNo - 1}, didn’t receive a reply in`}
              <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
                <InputStyled
                  value={followUpEmailsArray[emailNo - 1]?.send_after_hours ?? condition}
                  isError={showErrors && (followUpEmailsArray[emailNo - 1] ? (!followUpEmailsArray[emailNo - 1]?.send_after_hours || followUpEmailsArray[emailNo - 1]?.send_after_hours==0) : (!condition || condition==0))}
                  placeholder='Days'
                  type='number'
                  width='100%'
                  height='38px'
                  min={0}
                  max={99}
                  onChange={e => +e?.target?.value <= 99 && +e?.target?.value >= 0 && handleChange(emailNo-1, e?.target?.value, 'send_after_hours')}
                />
                {(followUpEmailsArray[emailNo - 1]?.send_after_hours >= 1 || condition) && <ConditionText rightPosition={`${(followUpEmailsArray[emailNo - 1]?.send_after_hours ?? +condition) < 9 ? '40%' : '30%'}`}>day{(followUpEmailsArray[emailNo - 1]?.send_after_hours ?? +condition) > 1 ? 's' : ''}</ConditionText>}
              </div>
            </div>
            {showErrors && (followUpEmailsArray[emailNo - 1] ? (!followUpEmailsArray[emailNo - 1]?.send_after_hours || followUpEmailsArray[emailNo - 1]?.send_after_hours==0) : (!condition || condition==0)) && <div style={{fontSize: 12, color: 'red'}}>Please enter days between 1-99</div>}
          </div>
        </SubjectWrapper>
        <SubjectWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '40px', marginBottom: showErrors && !nameVal ? '20px' : ''}}>Name</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <StyledInput
              value={followUpEmailsArray[emailNo - 1]?.name ?? nameVal}
              isError={showErrors && (!followUpEmailsArray[emailNo - 1]?.name && !nameVal)}
              width='100%'
              height='38px'
              borderRadius='8px'
              placeholder='Name'
              onChange={e => handleChange(emailNo-1, e?.target?.value, 'name')}
            />
            {showErrors && (!followUpEmailsArray[emailNo - 1]?.name && !nameVal) && <div style={{color: 'red'}}>Please enter name</div>}
          </div>
        </SubjectWrapper>
        <SubjectWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '31px', marginBottom: showErrors && !subject ? '20px' : ''}}>Subject</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <StyledInput
              value={followUpEmailsArray[emailNo - 1]?.subject ?? subject}
              placeholder='Email subject'
              width='100%'
              height='38px'
              borderRadius='8px'
              isError={showErrors && (!followUpEmailsArray[emailNo - 1]?.subject && !subject)}
              onChange={e => handleChange(emailNo-1, e?.target?.value, 'subject')}
            />
            {showErrors && (!followUpEmailsArray[emailNo - 1]?.subject && !subject) && <div style={{color: 'red'}}>Please enter subject</div>}
          </div>
        </SubjectWrapper>
        <BodyWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '45px'}}>Body</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <EditorComponentV2
              editorError={showErrors && ((!followUpEmailsArray[emailNo - 1]?.body || followUpEmailsArray[emailNo - 1]?.body=='<p><br></p>') && (!body || body=='<p><br></p>'))}
              value={followUpEmailsArray[emailNo - 1]?.body ?? body}
              onChange={content => handleChange(emailNo-1, content, 'body')}
              style={{width: '100%', borderRadius: '16px', height: '130px'}}
            />
            {showErrors && ((!followUpEmailsArray[emailNo - 1]?.body || followUpEmailsArray[emailNo - 1]?.body=='<p><br></p>') && (!body || body=='<p><br></p>')) && <div style={{marginTop: 40, marginBottom: -40, color: 'red'}}>Please enter body</div>}
          </div>
        </BodyWrapper>
      </>
      <FooterWrapper>
        <div>
          <Dropdown
            trigger={['hover']}
            placement='bottomLeft'
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
            overlay={
              <MenuStyled>
                {existingTemplates?.results?.map((item, idx) => <>
                  <Popover
                    overlayStyle={{maxWidth: '600px', borderRadius: 40}}
                    overlayInnerStyle={{padding: 10}}
                    zIndex={21}
                    trigger='hover'
                    placement='rightTop'
                    visible={popoverVisible[idx] || false}
                    onVisibleChange={visible => handlePopoverVisibleChange(visible, idx)}
                    content={
                      <div>
                        <div style={{fontWeight: '600', marginBottom: 5}}>{item?.name}</div>
                        <div style={{marginBottom: 5}}>{item?.subject}</div>
                        <div dangerouslySetInnerHTML={{__html: item?.body}}></div>
                        <ButtonStyled
                          style={{
                            width: 137,
                            height: 28,
                            borderRadius: 6,
                            display: 'flex',
                            justifyContent: 'center'}}
                          onClick={() => {
                            handleUseTemplateBtnClick(item?.body, item?.subject, item?.id, item?.name, emailNo);
                            setDropdownVisible(false);
                            setPopoverVisible({});
                          }}>
                      Use this template
                        </ButtonStyled>
                      </div>
                    }>
                    <Menu.Item key={idx} style={{backgroundColor: 'white'}}>
                      {item?.name}&nbsp;<FontAwesomeIcon icon={faEye}/>
                    </Menu.Item>
                  </Popover>
                  <Divider style={{margin: 0}} /></>)}
              </MenuStyled>
            }>
            <div style={{color: '#2D6CCA', cursor: 'pointer'}}>View existing templates&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown}/></div>
          </Dropdown>
        </div>
        {emailNo < 3 && emailNo==noOfFollowUpEmails[noOfFollowUpEmails?.length-1] && <StyledButton onClick={() => {
          addFollowUpEmail();
          addEmail();
          setShowErrors(false);
          setFollowUpErrors(true);
        }}>+ Add Follow-Up Email #{emailNo + 1}</StyledButton>}
      </FooterWrapper>
    </EmailPresetFieldsWrapper>
  </EmailPresetSectionWrapper>;
};

const StyledRadioButton = styled(Radio)`
.ant-radio-checked .ant-radio-inner::after {
    background-color: #fff !important;
    border: 1px solid #2D6CCA;
  }

.ant-radio-checked .ant-radio-inner {
  background-color: #2D6CCA !important;
}
`;

const StyledButton = styled(Button)`
  background-color: rgba(45, 108, 202, 0.15);
  border-radius: 6px;
  color: rgba(45, 108, 202, 1);
`;
const InputStyled = styled(Input)<{isError: boolean}>`
  width: 95px !important;
  background: #FFFFFF !important;
  border: ${p=> p.isError ? '1px solid red !important' : '1px solid #d9d9d9 !important;'};
  border-radius: 6px;
  height: 31px !important;
  font-size: 12px;
  padding-left: 11px !important;
  @media only screen and (max-width: 450px) {
    width: 100% !important;
  }
  &::-webkit-inner-spin-button {
    opacity: 1;
    margin-right: -10px;
  }
  &::-webkit-outer-spin-button {
   opacity: 1;
  }
  &:focus {
    box-shadow: none !important;
    border-radius: 6px !important;
    padding-left: 11px !important;
  }
`;
const ConditionText = styled.div<{rightPosition: string}>`
  position: absolute;
  pointer-events: none;
  font-size: 12px;
  top: 21%;
  right: ${p => p?.rightPosition};
  @media only screen and (max-width: 450px) {
    right: 52% !important;
  }
`;
