export const mapKey = 'AIzaSyBeBsBzLYbb6-GEY_pMFjwcYpTXTQTO0YU';

export function calculateZoomLevel(map, bounds, width, height) {
  const ZOOM_OFFSET = 2; // Adjust as needed

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const lngDiff = Math.abs(ne.lng() - sw.lng());
  const latDiff = Math.abs(ne.lat() - sw.lat());

  // Ensure the denominator is positive and greater than zero
  const zoomWidth = lngDiff > 0 ? Math.log2((width - ZOOM_OFFSET) / lngDiff) : 0;
  const zoomHeight = latDiff > 0 ? Math.log2((height - ZOOM_OFFSET) / latDiff) : 0;

  return isNaN(zoomWidth) || isNaN(zoomHeight) ? 12 : Math.min(zoomWidth, zoomHeight);
}

export const handleTilesLoaded = (map, report) => {
  if (map && report?.length) {
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    report.forEach(marker => {
      const lat = marker?.location?.lat;
      const lng = marker?.location?.lon;

      if (!isNaN(lat) && !isNaN(lng)) {
        // @ts-ignore
        bounds.extend(new window.google.maps.LatLng(lat, lng));
      }
    });

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      map.setCenter(bounds.getCenter());
      map.setZoom(12);
    } else {
      const center = bounds.getCenter();
      const zoom = calculateZoomLevel(map, bounds, map.getDiv().offsetWidth, map.getDiv().offsetHeight);

      map.setCenter(center);
      map.setZoom(parseInt(`${zoom}`));
    }
  }
};

export function getLatestGridShape(data) {
  for (const entry of data) {
    if (entry.gridShape) {
      return entry.gridShape;
    }
  }

  return null;
}

export function findBoundingBox(coordinates) {
  const minLon = Math.min(...coordinates.map(coord => coord.lon));
  const maxLon = Math.max(...coordinates.map(coord => coord.lon));
  const minLat = Math.min(...coordinates.map(coord => coord.lat));
  const maxLat = Math.max(...coordinates.map(coord => coord.lat));
  return [minLon, minLat, maxLon, maxLat];
}

export function findDiagonalCoordinates(coordinates) {
  const [minLon, minLat, maxLon, maxLat] = findBoundingBox(coordinates);
  const diagonalCoords = [
    {'lon': minLon, 'lat': minLat},
    {'lon': maxLon, 'lat': maxLat},
  ];
  return diagonalCoords;
}

function toRadians(degrees) {
  return degrees * Math.PI / 180;
}

function toDegrees(radians) {
  return radians * 180 / Math.PI;
}

export function midpoint(lat1, lon1, lat2, lon2) {
  const radLat1 = toRadians(lat1);
  const radLon1 = toRadians(lon1);
  const radLat2 = toRadians(lat2);
  const radLon2 = toRadians(lon2);

  const Bx = Math.cos(radLat2) * Math.cos(radLon2 - radLon1);
  const By = Math.cos(radLat2) * Math.sin(radLon2 - radLon1);
  const radLatMid = Math.atan2(Math.sin(radLat1) + Math.sin(radLat2), Math.sqrt((Math.cos(radLat1) + Bx) ** 2 + By ** 2));
  const radLonMid = radLon1 + Math.atan2(By, Math.cos(radLat1) + Bx);

  const midLat = toDegrees(radLatMid);
  const midLon = toDegrees(radLonMid);

  return {lat: midLat, lon: midLon};
}

export function getKeywordFromMaps(data) {
  if (data && data.maps && Array.isArray(data.maps)) {
    for (const mapItem of data.maps) {
      if (mapItem && mapItem.keyword) {
        return mapItem.keyword;
      }
    }
  }
  return null;
}

export const getProjectedNextMonthQuotaConsumption = (daysInMonth: number, refreshFrequencyDays: number, quotaPerRefresh: number) => {
  const refreshesPerMonth = Math.floor(daysInMonth / refreshFrequencyDays);
  const projectedQuota = refreshesPerMonth * quotaPerRefresh;

  return projectedQuota;
};

export const getQuotaPerRefresh = (shape: string, grid: number): number => {
  switch (shape) {
    case 'square':
      switch (grid) {
        case 3:
          return 9;
        case 4:
          return 16;
        case 5:
          return 25;
        case 7:
          return 49;
        case 9:
          return 81;
        case 11:
          return 121;
        case 13:
          return 169;
        case 15:
          return 225;
        default:
          return 9;
      }

    case 'circle':
      switch (grid) {
        case 1:
          return 7;
        case 2:
          return 20;
        case 3:
          return 39;
        case 4:
          return 64;
        case 5:
          return 95;
        case 6:
          return 132;
        case 7:
          return 175;
        default:
          return 7;
      }

    default:
      return 9;
  }
};
