
import {faClose} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {notification} from 'antd';
import classnames from 'classnames';
import React, {Fragment} from 'react';
import styles from './styles.module.scss';
import {faCircleCheck, faClockRotateLeft} from '@fortawesome/pro-solid-svg-icons';


export const newNotification = (message, delay, state) => {
  const key = `key${Date.now()}`;
  return notification.open({
    message:
      <Fragment>
        <div className={styles.notificationContent}>
          <span className={styles.title}>{message}</span>
        </div>
      </Fragment>,
    placement: 'topRight',
    duration: delay,
    key,
    className: classnames(styles.notification, styles.errorNotification),
    icon: <div><FontAwesomeIcon color={state == 'rollback' ? '#FFA500' : '#1FAC47'} icon={state == 'rollback' ? faClockRotateLeft : faCircleCheck} /></div>,
    closeIcon: <div className={styles.icon}><FontAwesomeIcon fontSize={16} color='white' icon={faClose} /></div>,
  });
};

