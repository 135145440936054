import {competitorDescoveryApi} from '@/api/gsc';
import {formatSorters} from '@/utils/filters';
import {toJS} from 'mobx';
import {flow, Instance, types, cast, getParent} from 'mobx-state-tree';

const HistoricalCompetitorsArrModel = types.model({
  competitorDomain: types.maybeNull(types.string),
  competitorOrganicVisibility: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
});
const CompetitorsReportArrModel = types.model({
  competitorDomain: types.maybeNull(types.string),
  currentCompetitorPosition: types.maybeNull(types.number),
  currentCompetitorTopLink: types.maybeNull(types.string),
  currentCompetitorTitle: types.maybeNull(types.string),
  currentCompetitorDisplayedLink: types.maybeNull(types.string),
  currentCompetitorSnippet: types.maybeNull(types.string),
  currentKeywords1Count: types.maybeNull(types.number),
  currentKeywords25Count: types.maybeNull(types.number),
  currentKeywords610Count: types.maybeNull(types.number),
  currentKeywords110Count: types.maybeNull(types.number),
  currentKeywords1120Count: types.maybeNull(types.number),
  currentKeywords2130Count: types.maybeNull(types.number),
  currentKeywords3140Count: types.maybeNull(types.number),
  currentKeywords4150Count: types.maybeNull(types.number),
  currentKeywords150Count: types.maybeNull(types.number),
  currentCompetitorOrganicVisibility: types.maybeNull(types.number),
  currentCompetitorTrafficIndex: types.maybeNull(types.number),
  previousCompetitorPosition: types.maybeNull(types.number),
  previousCompetitorTopLink: types.maybeNull(types.string),
  previousCompetitorTitle: types.maybeNull(types.string),
  previousCompetitorDisplayedLink: types.maybeNull(types.string),
  previousCompetitorSnippet: types.maybeNull(types.string),
  previousKeywords1Count: types.maybeNull(types.number),
  previousKeywords25Count: types.maybeNull(types.number),
  previousKeywords610Count: types.maybeNull(types.number),
  previousKeywords110Count: types.maybeNull(types.number),
  previousKeywords1120Count: types.maybeNull(types.number),
  previousKeywords2130Count: types.maybeNull(types.number),
  previousKeywords3140Count: types.maybeNull(types.number),
  previousKeywords4150Count: types.maybeNull(types.number),
  previousKeywords150Count: types.maybeNull(types.number),
  previousCompetitorOrganicVisibility: types.maybeNull(types.number),
  previousCompetitorTrafficIndex: types.maybeNull(types.number),
  isTracked: types.maybeNull(types.boolean),
  date: types.maybeNull(types.string),
  showInGraph: types.maybeNull(types.boolean),
  recentCompetitorOrganicVisibility: types.maybeNull(types.number),
});

export type CompetitorsReportType = Instance<typeof CompetitorsReportArrModel>;

const CompetitorTrendsModel = types.model({
  totalCount: types.maybeNull(types.number),
  competitorsData: types.maybeNull(types.array(HistoricalCompetitorsArrModel)),
});

const CompetitorsReportModel = types.model({
  taskStatus: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(CompetitorsReportArrModel)),
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
});

const CompetitorsReportCompetitorModel = types.model({
  competitor: types.maybeNull(types.string),
  keywordCount: types.maybeNull(types.number),
});

const CompetitorsKeywordUsageArrModel = types.model({
  keyword: types.maybeNull(types.string),
  competitors: types.maybeNull(types.array(CompetitorsReportCompetitorModel)),
});

const competitorKeywordUsageModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(CompetitorsKeywordUsageArrModel)),
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
});

const SharedKeywordsCompetitorsArrModel = types.model({
  sets: types.maybeNull(types.array(types.string)),
  size: types.maybeNull(types.number),
});

const CompetitorTrendsSharedKeywordsModel = types.model({
  totalCount: types.maybeNull(types.number),
  competitorsData: types.maybeNull(types.array(SharedKeywordsCompetitorsArrModel)),
});

const SingleTrackedCompetitorModel = types.model({
  competitorDomain: types.maybeNull(types.string),
  competitorOrganicVisibility: types.maybeNull(types.number),
  competitorTrafficIndex: types.maybeNull(types.number),
  latestDate: types.maybeNull(types.string),
});

const TrackedCompetitorsModel = types.model({
  results: types.maybeNull(types.array(SingleTrackedCompetitorModel)),
  totalCount: types.maybeNull(types.number),
});

const OrganicCompetitorsModel = types.model({
  competitor: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
});

const SingleOrganicCompetitorKeywordModel = types.model({
  keyword: types.maybeNull(types.string),
  kd: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  ppcd: types.maybeNull(types.number),
  topCompetitors: types.maybeNull(types.array(OrganicCompetitorsModel)),
  yourPosition: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const organicCompetitorKeywordModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(SingleOrganicCompetitorKeywordModel)),
});

const HistoricalDailyMetricsModel = types.model({
  date: types.maybeNull(types.string),
  competitorPosition: types.maybeNull(types.number),
  competitorDomain: types.maybeNull(types.string),
  competitorTopLink: types.maybeNull(types.string),
  competitorTitle: types.maybeNull(types.string),
  competitorDisplayedLink: types.maybeNull(types.string),
  competitorSnippet: types.maybeNull(types.string),
  keywords1Count: types.maybeNull(types.number),
  keywords25Count: types.maybeNull(types.number),
  keywords610Count: types.maybeNull(types.number),
  keywords110Count: types.maybeNull(types.number),
  keywords1120Count: types.maybeNull(types.number),
  keywords2130Count: types.maybeNull(types.number),
  keywords3140Count: types.maybeNull(types.number),
  keywords4150Count: types.maybeNull(types.number),
  keywords150Count: types.maybeNull(types.number),
  competitorOrganicVisibility: types.maybeNull(types.number),
  competitorTrafficIndex: types.maybeNull(types.number),
});

const HistoricalResultsModel = types.model({
  dailyMetrics: types.maybeNull(types.array(HistoricalDailyMetricsModel)),
  currentCompetitorOrganicVisibility: types.maybeNull(types.number),
  previousCompetitorOrganicVisibility: types.maybeNull(types.number),
  currentCompetitorTrafficIndex: types.maybeNull(types.number),
  previousCompetitorTrafficIndex: types.maybeNull(types.number),
  currentKeywords110Count: types.maybeNull(types.number),
  previousKeywords110Count: types.maybeNull(types.number),
});

const competitorHistoricalDataModel = types.model({
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  results: HistoricalResultsModel,
});

export const CompetitorDescoveryStore = types.model({
  competitorsReport: types.maybeNull(CompetitorsReportModel),
  competitorsReportPageNumber: types.maybeNull(types.number),
  competitorsReportPageSize: types.maybeNull(types.number),
  competitorsReportLoading: types.boolean,
  competitorsReportSortField: types.maybeNull(types.string),
  competitorsReportSortDirection: types.maybeNull(types.string),
  competitorsReportSearchTerm: types.maybeNull(types.string),
  competitorsReportInitLoading: types.boolean,
  competitorsReportOverviewTableData: types.maybeNull(types.array(CompetitorsReportArrModel)),
  competitorTrends: types.maybeNull(CompetitorTrendsModel),
  competitorTrendsFormatData: types.maybeNull(types.array(HistoricalCompetitorsArrModel)),
  competitorTrendsLoading: types.boolean,
  competitorTrendsSharedKeywordsLoading: types.boolean,
  competitorTrendsInitLoading: types.boolean,
  competitroTrendsDateType: types.string,
  competitorTrendsSharedKeywords: types.maybeNull(CompetitorTrendsSharedKeywordsModel),
  competitorTrendsSharedKeywordsInitLoading: types.boolean,
  competitorKeywordUsage: types.maybeNull(competitorKeywordUsageModel),
  competitorKeywordUsageLoading: types.boolean,
  competitorKeywordUsageInitLoading: types.boolean,
  competitorsKeywordUsagePageNumber: types.maybeNull(types.number),
  competitorsKeywordUsagePageSize: types.maybeNull(types.number),
  competitorsKeywordUsageSortField: types.maybeNull(types.string),
  competitorsKeywordUsageSortDirection: types.maybeNull(types.string),
  competitorsKeywordUsageSearchTerm: types.maybeNull(types.string),
  trackedCompetitors: types.maybeNull(TrackedCompetitorsModel),
  trackedCompetitorsLoading: types.maybeNull(types.boolean),
  addTrackedCompetitorLoading: types.maybeNull(types.boolean),
  removeTrackedCompetitorsLoading: types.maybeNull(types.boolean),
  removingCompetitorUrl: types.maybeNull(types.string),
  trackedCompetitorsInitLoading: types.maybeNull(types.boolean),
  trackedCompetitorsSortField: types.maybeNull(types.string),
  trackedCompetitorsSortDirection: types.maybeNull(types.string),
  trackedCompetitorsSearchTerm: types.maybeNull(types.string),
  activeTrendChartType: types.maybeNull(types.string),
  activeCompetitorsArray: types.maybeNull(types.array(types.string)),

  organicCompetitorsKeyword: types.maybeNull(organicCompetitorKeywordModel),
  organicCompetitorsKeywordLoading: types.boolean,
  organicCompetitorsKeywordInitLoading: types.boolean,
  organicCompetitorsKeywordPageNumber: types.maybeNull(types.number),
  organicCompetitorsKeywordPageSize: types.maybeNull(types.number),
  organicCompetitorsKeywordSortField: types.maybeNull(types.string),
  organicCompetitorsKeywordSortDirection: types.maybeNull(types.string),
  organicCompetitorsKeywordSearchTerm: types.maybeNull(types.string),

  historicalDrawerVisible: types.boolean,
  competitorsHistoricalData: types.maybeNull(competitorHistoricalDataModel),
  competitorsHistoricalDataLoading: types.boolean,
  competitorsHistoricalDataInitLoading: types.boolean,
  competitorsHistoricalDataPageNumber: types.maybeNull(types.number),
  competitorsHistoricalDataPageSize: types.maybeNull(types.number),
  competitorsHistoricalDataSortField: types.maybeNull(types.string),
  competitorsHistoricalDataSortDirection: types.maybeNull(types.string),
  competitorsHistoricalDataSearchTerm: types.maybeNull(types.string),
  historicalCompetitor: types.maybeNull(types.string),
}).views(self => ({
  get getLineChartData() {
    const competitorsList = [];
    const dataArr = [];

    const sortedArr = [...(toJS(self.competitorTrends)?.competitorsData?.sort((a, b) => {
      return +new Date(a.date) - +new Date(b.date);
    }) || [])];

    sortedArr?.forEach(item => {
      if (!competitorsList.includes(item.competitorDomain)) {
        competitorsList.push(item.competitorDomain);
      }
    });

    for (let i = 0; i < competitorsList.length; i++) {
      const datesArr = sortedArr?.filter(item => item.competitorDomain === competitorsList[i])?.map(item => item.date);
      const valuesArr = sortedArr?.filter(item => item.competitorDomain === competitorsList[i])?.map(item => item.competitorOrganicVisibility);
      const color = sortedArr?.find(item => item.competitorDomain === competitorsList[i])?.color;
      dataArr.push({
        competitor: competitorsList[i],
        color: color,
        dates: datesArr,
        values: valuesArr,
      });
    }

    return dataArr;
  },
})).actions(self => {
  const loadCompetitorTrends = flow(function* (url: string, criteria: string, dateChoice: string) {
    const parentStore = getParent(self) as any;

    self.competitorTrendsLoading = true;
    try {
      const response = yield competitorDescoveryApi.getCompetitorTrends({
        url,
        criteria,
        dateChoice,
        periodStart: toJS(parentStore)?.criteria?.currentPeriodStart,
        periodEnd: toJS(parentStore)?.criteria?.currentPeriodEnd,
        ...(toJS(self.activeCompetitorsArray)?.length && {competitorsArr: toJS(self.activeCompetitorsArray)}),
      });
      if (response.isCancel) return;

      self.competitorTrends = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.competitorTrendsLoading = false;
      self.competitorTrendsInitLoading = false;
    }
  });

  const loadCompetitorTrendsSharedKeywords = flow(function* (url: string, criteria: string, dateChoice: string) {
    const parentStore = getParent(self) as any;


    self.competitorTrendsSharedKeywordsLoading = true;
    try {
      const response = yield competitorDescoveryApi.getCompetitorTrends({
        url,
        criteria,
        dateChoice,
        periodStart: toJS(parentStore)?.criteria?.currentPeriodStart,
        periodEnd: toJS(parentStore)?.criteria?.currentPeriodEnd,
        ...(toJS(self.activeCompetitorsArray)?.length && {competitorsArr: toJS(self.activeCompetitorsArray)}),
      });
      if (response.isCancel) return;

      self.competitorTrendsSharedKeywords = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.competitorTrendsSharedKeywordsLoading = false;
      self.competitorTrendsSharedKeywordsInitLoading = false;
    }
  });

  const loadCompetitorsReport = flow(function* (url: string) {
    const sorters = {
      currentCompetitorPosition: 'current_competitor_position',
      competitorDomain: 'competitor_domain',
      currentCompetitorTopLink: 'current_competitor_top_link',
      currentCompetitorTitle: 'current_competitor_title',
      currentCompetitorDisplayedLink: 'current_competitor_displayed_link',
      currentCompetitorSnippet: 'current_competitor_snippet',
      currentCompetitorOrganicVisibility: 'current_competitor_organic_visibility',
      currentCompetitorTrafficIndex: 'current_competitor_traffic_index',
      currentKeywords1Count: 'current_keywords_1_count',
      currentKeywords25Count: 'current_keywords_2_5_count',
      currentKeywords610Count: 'current_keywords_6_10_count',
      currentKeywords110Count: 'current_keywords_1_10_count',
      currentKeywords1120Count: 'current_keywords_11_20_count',
      currentKeywords2130Count: 'current_keywords_21_30_count',
      currentKeywords3140Count: 'current_keywords_31_40_count',
      currentKeywords4150Count: 'current_keywords_41_50_count',
      currentKeywords150Count: 'current_keywords_1_50_count',
      recentCompetitorOrganicVisibility: 'recent_competitor_organic_visibility',
      isTracked: 'is_tracked',
    };


    const formtatedSorters = formatSorters(sorters, self.competitorsReportSortField, self.competitorsReportSortDirection);

    const parentStore = getParent(self) as any;

    self.competitorsReportLoading = true;
    try {
      const response = yield competitorDescoveryApi.getCompetitorsReport(
        {
          url,
          pageNumber: self.competitorsReportPageNumber,
          pageSize: self.competitorsReportPageSize,
          orderBy: formtatedSorters,
          p1Start: toJS(parentStore)?.criteria?.previousPeriodStart,
          p1End: toJS(parentStore)?.criteria?.previousPeriodEnd,
          p2Start: toJS(parentStore)?.criteria?.currentPeriodStart,
          p2End: toJS(parentStore)?.criteria?.currentPeriodEnd,
          search: self.competitorsReportSearchTerm,
        });
      if (response.isCancel) return;

      self.competitorsReport = response;

      if (!self.activeCompetitorsArray.length) {
        self.activeCompetitorsArray = response?.results?.map(item => item.competitorDomain);
      }

      self.competitorsReportLoading = false;
      self.competitorsReportInitLoading = false;
    } catch (e) {
      self.competitorsReportLoading = false;
      self.competitorsReportInitLoading = false;
      return Promise.reject(e);
    }
  });

  const loadCompetitorsKeywordUsage = flow(function* (url: string, competitors?: string[]) {
    const sorters = {
      keyword: 'keyword',
    };


    const formtatedSorters = formatSorters(sorters, self.competitorsReportSortField, self.competitorsReportSortDirection);

    const parentStore = getParent(self) as any;

    self.competitorKeywordUsageLoading = true;
    try {
      const response = yield competitorDescoveryApi.getCompetitorsKeywordUsage(
        {
          url,
          pageNumber: self.competitorsKeywordUsagePageNumber,
          pageSize: self.competitorsKeywordUsagePageSize,
          orderBy: formtatedSorters,
          periodStart: toJS(parentStore)?.criteria?.currentPeriodStart,
          periodEnd: toJS(parentStore)?.criteria?.currentPeriodEnd,
          ...(competitors && {competitors: competitors}),
          ...(!competitors && {getTrackedCompetitors: true}),
        });
      if (response.isCancel) return;

      self.competitorKeywordUsage = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.competitorKeywordUsageLoading = false;
      self.competitorKeywordUsageInitLoading = false;
    }
  });

  const loadTopOrganicCompetitorsKeyword = flow(function* (property: string) {
    const sorters = {
      sv: 'sv',
      cpc: 'cpc',
      keyword: 'keyword',
      kd: 'kd',
      yourPosition: 'your_position',
    };


    const formtatedSorters = formatSorters(sorters, self.organicCompetitorsKeywordSortField, self.organicCompetitorsKeywordSortDirection);

    self.organicCompetitorsKeywordLoading = true;

    try {
      const response = yield competitorDescoveryApi.getTopOrganicCompetitorKeyword(
        {
          property,
          pageNumber: self.organicCompetitorsKeywordPageNumber,
          pageSize: self.organicCompetitorsKeywordPageSize,
          orderBy: formtatedSorters,
          search: self.organicCompetitorsKeywordSearchTerm,
        });
      if (response.isCancel) return;

      self.organicCompetitorsKeyword = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.organicCompetitorsKeywordLoading = false;
      self.organicCompetitorsKeywordInitLoading = false;
    }
  });

  const getHistoricalCompetitorData = flow(function* (
    property: string,
    p1Start: string,
    p1End: string,
    p2Start: string,
    p2End: string,
  ) {
    const sorters = {
      date: 'date',
      competitorOrganicVisibility: 'competitor_organic_visibility',
      competitorTrafficIndex: 'competitor_traffic_index',
      keywords110Count: 'keywords_1_10_count',
      keywords1Count: 'keywords_1_count',
      keywords25Count: 'keywords_2_5_count',
      keywords1120Count: 'keywords_11_20_count',
    };


    const formtatedSorters = formatSorters(sorters, self.competitorsHistoricalDataSortField, self.competitorsHistoricalDataSortDirection);

    self.competitorsHistoricalDataLoading = true;

    try {
      const response = yield competitorDescoveryApi.getHistoricaCompetitorData(
        {
          property,
          p1Start,
          p1End,
          p2Start,
          p2End,
          competitor: self.historicalCompetitor,
          pageNumber: self.competitorsHistoricalDataPageNumber,
          pageSize: self.competitorsHistoricalDataPageSize,
          orderBy: formtatedSorters,
          search: self.competitorsHistoricalDataSearchTerm,
        });
      if (response.isCancel) return;

      self.competitorsHistoricalData = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.competitorsHistoricalDataLoading = false;
      self.competitorsHistoricalDataInitLoading = false;
    }
  });


  const setCompetitorReportsPageNumber = (value: number) => self.competitorsReportPageNumber = value;
  const setCompetitorReportsPageSize = (value: number) => self.competitorsReportPageSize = value;
  const setCompetitorKeywordusagePageNumber = (value: number) => self.competitorsKeywordUsagePageNumber = value;
  const setCompetitorKeywordusagePageSize = (value: number) => self.competitorsKeywordUsagePageSize = value;
  const setCompetitorReportsInitLoading = (value: boolean) => self.competitorsReportInitLoading = value;
  const setCompetitorReportsSearchTerm = (term: string) => self.competitorsReportSearchTerm = term;
  const setCompetitorTrendsInitLoading = (value: boolean) => self.competitorTrendsInitLoading = value;
  const setCompetitorTrendsSharedKeywordsInitLoading = (value: boolean) => self.competitorTrendsSharedKeywordsInitLoading = value;
  const setCompetitorKeywordUsageInitLoading = (value: boolean) => self.competitorKeywordUsageInitLoading = value;
  const setCompetitorReportsSortField = (value: string) => self.competitorsReportSortField = value;
  const setCompetitorReportsSortDirection = (value: string) => self.competitorsReportSortDirection = value;
  const setCompetitorKeywordUsageSortField = (value: string) => self.competitorsReportSortField = value;
  const setCompetitorKeywordUsageSortDirection = (value: string) => self.competitorsReportSortDirection = value;
  const setCompetitorTrendsFormatData = (value: any[]) => self.competitorTrendsFormatData = cast(value);
  const setCompetitorsReportOverviewTableData = (value: any[]) => self.competitorsReportOverviewTableData = cast(value);
  const setCompetitroTrendsDateType = (value: string) => self.competitroTrendsDateType = value;
  const setTrackedCompetitorsSortField = (value: string) => self.trackedCompetitorsSortField = value;
  const setTrackedCompetitorsSortDirection = (value: string) => self.trackedCompetitorsSortDirection = value;
  const setTrackedCompetitorsSearchTerm = (term: string) => self.trackedCompetitorsSearchTerm = term;
  const setTrackedCompetitorsLoading = (value: boolean) => self.trackedCompetitorsLoading = value;
  const setTrackedCompetitorsInitLoading = (value: boolean) => self.trackedCompetitorsInitLoading = value;
  const setActiveCompetitorsArray = (competitors: string[]) => self.activeCompetitorsArray = cast(competitors);
  const setActiveTrendChartType = (type: string) => self.activeTrendChartType = type;


  const setOrganicCompetitorsKeywordSortField = (value: string) => self.organicCompetitorsKeywordSortField = value;
  const setOrganicCompetitorsKeywordSortDirection = (value: string) => self.organicCompetitorsKeywordSortDirection = value;
  const setOrganicCompetitorsKeywordSearchTerm = (term: string) => self.organicCompetitorsKeywordSearchTerm = term;
  const setOrganicCompetitorsKeywordLoading = (value: boolean) => self.organicCompetitorsKeywordLoading = value;
  const setOrganicCompetitorsKeywordInitLoading = (value: boolean) => self.organicCompetitorsKeywordInitLoading = value;
  const setOrganicCompetitorsKeywordPageNumber = (value: number) => self.organicCompetitorsKeywordPageNumber = value;
  const setOrganicCompetitorsKeywordPageSize = (value: number) => self.organicCompetitorsKeywordPageSize = value;


  const setCompetitorsHistoricalDataSortField = (value: string) => self.competitorsHistoricalDataSortField = value;
  const setCompetitorsHistoricalDataSortDirection = (value: string) => self.competitorsHistoricalDataSortDirection = value;
  const setCompetitorsHistoricalDataSearchTerm = (term: string) => self.competitorsHistoricalDataSearchTerm = term;
  const setCompetitorsHistoricalDataLoading = (value: boolean) => self.competitorsHistoricalDataLoading = value;
  const setCompetitorsHistoricalDataInitLoading = (value: boolean) => self.competitorsHistoricalDataInitLoading = value;
  const setCompetitorsHistoricalDataPageNumber = (value: number) => self.competitorsHistoricalDataPageNumber = value;
  const setCompetitorsHistoricalDataPageSize = (value: number) => self.competitorsHistoricalDataPageSize = value;
  const setHistoricalCompetitor = (value: string) => self.historicalCompetitor = value;

  const setHistoricalDrawerVisible = (value: boolean) => self.historicalDrawerVisible = value;

  const loadTrackedCompetitors = flow(function* (url: string) {
    self.trackedCompetitorsLoading = true;

    const sorters = {
      competitorDomain: 'competitor_domain',
      competitorOrganicVisibility: 'competitor_organic_visibility',
      competitorTrafficIndex: 'competitor_traffic_index',
      latestDate: 'latest_date',
    };

    const formtatedSorters = formatSorters(sorters, self.trackedCompetitorsSortField, self.trackedCompetitorsSortDirection);

    try {
      const response = yield competitorDescoveryApi.getTrackedCompetitors(
        {
          url,
          orderBy: formtatedSorters,
          search: self.trackedCompetitorsSearchTerm,
        });
      if (response.isCancel) return;

      self.trackedCompetitors = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.trackedCompetitorsLoading = false;
      self.trackedCompetitorsInitLoading = false;
    }
  });

  const addTrackedCompetitor = flow(function* (url: string, competitor: string) {
    self.addTrackedCompetitorLoading = true;

    try {
      const response = yield competitorDescoveryApi.addTrackedCompetitor({url, competitor});
      if (response.isCancel) return;

      loadTrackedCompetitors(url);
      loadCompetitorsReport(url);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.addTrackedCompetitorLoading = false;
      self.trackedCompetitorsInitLoading = false;
    }
  });

  const removeTrackedCompetitor = flow(function* (url: string, competitor: string) {
    self.removeTrackedCompetitorsLoading = true;
    self.removingCompetitorUrl = competitor;

    try {
      const response = yield competitorDescoveryApi.removeTrackedCompetitor({url, competitor});
      if (response.isCancel) return;

      loadTrackedCompetitors(url);
      loadCompetitorsReport(url);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.removeTrackedCompetitorsLoading = false;
      self.trackedCompetitorsInitLoading = false;
      self.removingCompetitorUrl = null;
    }
  });


  return {
    loadCompetitorTrends,
    loadCompetitorsReport,
    setCompetitorReportsPageNumber,
    setCompetitorReportsPageSize,
    setCompetitorReportsInitLoading,
    setCompetitorReportsSortField,
    setCompetitorReportsSortDirection,
    setCompetitorTrendsFormatData,
    setCompetitorsReportOverviewTableData,
    setCompetitorTrendsInitLoading,
    setCompetitroTrendsDateType,
    loadCompetitorsKeywordUsage,
    setCompetitorKeywordUsageInitLoading,
    setCompetitorKeywordusagePageNumber,
    setCompetitorKeywordusagePageSize,
    setCompetitorKeywordUsageSortField,
    setCompetitorKeywordUsageSortDirection,
    setCompetitorTrendsSharedKeywordsInitLoading,
    loadCompetitorTrendsSharedKeywords,
    loadTrackedCompetitors,
    addTrackedCompetitor,
    removeTrackedCompetitor,
    setTrackedCompetitorsSortField,
    setTrackedCompetitorsSortDirection,
    setTrackedCompetitorsSearchTerm,
    setCompetitorReportsSearchTerm,
    setTrackedCompetitorsInitLoading,
    setTrackedCompetitorsLoading,
    setActiveCompetitorsArray,
    setActiveTrendChartType,
    loadTopOrganicCompetitorsKeyword,
    setOrganicCompetitorsKeywordSortField,
    setOrganicCompetitorsKeywordSortDirection,
    setOrganicCompetitorsKeywordSearchTerm,
    setOrganicCompetitorsKeywordLoading,
    setOrganicCompetitorsKeywordInitLoading,
    setOrganicCompetitorsKeywordPageNumber,
    setOrganicCompetitorsKeywordPageSize,
    setHistoricalDrawerVisible,
    setCompetitorsHistoricalDataSortField,
    setCompetitorsHistoricalDataSortDirection,
    setCompetitorsHistoricalDataSearchTerm,
    setCompetitorsHistoricalDataLoading,
    setCompetitorsHistoricalDataInitLoading,
    setCompetitorsHistoricalDataPageNumber,
    setCompetitorsHistoricalDataPageSize,
    getHistoricalCompetitorData,
    setHistoricalCompetitor,
  };
});

export function initCompetitorDescoveryStore() {
  return CompetitorDescoveryStore.create({
    competitorTrends: null,
    competitorsReport: null,
    competitorKeywordUsage: null,
    organicCompetitorsKeywordSortField: 'sv',
    organicCompetitorsKeywordSortDirection: 'descend',
    competitorTrendsLoading: false,
    competitorTrendsInitLoading: false,
    competitorsReportLoading: false,
    competitorsReportInitLoading: false,
    competitorsReportPageSize: 10,
    competitorsReportPageNumber: 1,
    competitorsKeywordUsagePageSize: 10,
    competitorsKeywordUsagePageNumber: 1,
    competitroTrendsDateType: 'last_crawled',
    competitorKeywordUsageLoading: false,
    competitorKeywordUsageInitLoading: false,
    competitorTrendsSharedKeywordsLoading: false,
    competitorTrendsSharedKeywordsInitLoading: false,
    trackedCompetitorsLoading: false,
    trackedCompetitorsInitLoading: false,
    trackedCompetitorsSearchTerm: '',
    trackedCompetitors: null,
    activeTrendChartType: 'organic_visibility',
    organicCompetitorsKeyword: null,
    organicCompetitorsKeywordLoading: false,
    organicCompetitorsKeywordInitLoading: false,
    organicCompetitorsKeywordPageNumber: 1,
    organicCompetitorsKeywordPageSize: 10,
    organicCompetitorsKeywordSearchTerm: '',
    historicalDrawerVisible: false,
    competitorsHistoricalData: null,
    competitorsHistoricalDataLoading: false,
    competitorsHistoricalDataInitLoading: false,
    competitorsHistoricalDataPageNumber: 1,
    competitorsHistoricalDataPageSize: 10,
    competitorsHistoricalDataSearchTerm: '',
    historicalCompetitor: '',
  });
}
