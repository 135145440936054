import React from 'react';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import {AddEmailModalStyled} from './styles';
import {Form} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  currentStep: string;
  setCurrentStep: (value: string) => void;
}

const AddEmailModal = ({visible, setVisible, currentStep, setCurrentStep}: Props) => {
  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    setCurrentStep('stepOne');
    setVisible(false);
  };

  const steps = {
    stepOne: <StepOne setCurrentStep={setCurrentStep} />,
    stepTwo: <StepTwo setCurrentStep={setCurrentStep} onClose={onClose} form={form} />,
  };

  return (
    <AddEmailModalStyled
      visible={visible}
      footer={false}
      closable={false}
      onCancel={onClose}
      width={500}
    >
      <FontAwesomeIcon icon={faXmark} onClick={onClose} className='cross-icon' color='#FFFFFF'/>
      {steps[currentStep]}
    </AddEmailModalStyled>
  );
};

export default AddEmailModal;
