
import {useEffect, useRef, useState, useCallback} from 'react';
import {renderToString} from 'react-dom/server';
import moment from 'moment';
import ReactEcharts from 'echarts-for-react';
import {GoogleRankChangeType} from '@/store/gsc-store/report';
import {observer} from 'mobx-react-lite';
import numeral from 'numeral';
import {Empty, Popover, Spin, Tooltip} from 'antd';
import {NextImg} from '@/utils/nextImg';

import {
  Typography,
  CardWrapper,
} from '@/components/common-components/components';
// import {VideoPopOver} from '@/components/common-components/components/popover';
import styles from '@/components/dashboard/gsc/styles.module.scss';
import chartStyles from './styles-chart.module.scss';
import {CriteriaType} from '@/store/gsc-store/criteria';
import {getDateDifference} from '../utils';
import {EChartOption} from 'echarts';
import classnames from 'classnames';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {useStore} from '@/store/root-store';
import {getHubSpotLink} from '@/utils/legacy';
import {LoadingOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faArrowUp} from '@fortawesome/pro-solid-svg-icons';
import {formatNumber} from '@/utils/number';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {RingLoaderSmall} from '@/components/common-components/components/skeleton/ring-loader-small';

type CustomSeries = {
  data: {
  value: number;
  changeValue: number;
  dateStr: string;
  };
} & EChartOption.Series;

interface GoogleChangeProps {
  criteria: CriteriaType;
  data: GoogleRankChangeType[];
  loading: boolean;
  isGoogleRankLoading?: boolean;
}

export const displayEmoji = icon => {
  switch (icon) {
    case '/img/smile.svg':
      return <img src='/img/+1.svg' />;
    case '/img/frown.svg':
      return <img src='/img/+2.svg' />;
    case '/img/linkbuilding.svg':
      return <img src='/img/+3.svg' />;
    case '/img/linkgraph-icon.svg':
      return <img src='/img/+4.svg' />;
    case '/img/speed.svg':
      return <img src='/img/+5.svg' />;
    case '/img/bug.svg':
      return <img src='/img/+6.svg' />;
    case '/img/notification.svg':
      return <img src='/img/+7.svg' />;
    case '/img/metadata.svg':
      return <img src='/img/+8.svg' />;
    default:
      return;
  }
};


// TODO: Refactor formating and default export;
export default observer<GoogleChangeProps>(({data, isGoogleRankLoading, loading, criteria}) => {
  const difference = getDateDifference(criteria.currentPeriodStart, criteria.currentPeriodEnd);
  const antIcon = <LoadingOutlined style={{fontSize: 100, color: '#E1E1E1'}} spin />;

  const interval = () => {
    if (difference >= 24) {
      return 89;
    }
    if (difference <= 1) {
      return 0;
    }
    if (difference < 12) {
      return 25;
    }
    if (difference >= 12) {
      return 58;
    }
  };
  const [emojiSerpHistogramData, setEmojiSerpHistogramData] = useState([]);
  const [emojiDataWithXPositions, setEmojiDataWithXPositions] = useState([]);
  const [eventDataWithXPositions, setEventDataWithXPositions] = useState([]);

  const {
    gsc: {
      sitePropertyEvents: {events},
      coreReports: {emojiSerpHistogram},
      gscExport: {isSelecting, selectSection, selectedSections},
    },
  } = useStore('');
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');

  const echartsRef = useRef(null) as any;

  const getSiteEventXPositions = () => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    const positions = echartsInstance?._chartsViews[0]?._data?._layout?.points?.filter((x, i) => i % 2 === 0);
    const dataPoints = echartsInstance?._chartsViews[0]?._data?._idList;
    const eventDataWithXPositions = events?.map(event => {
      const date = event.date;
      const index = dataPoints ? dataPoints.indexOf(date) : -1;
      return ({
        ...event,
        posX: index === -1 ? null : positions[index],
      });
    });
    setEventDataWithXPositions(eventDataWithXPositions);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', () => getEmojiDataXPositions());
      window.removeEventListener('resize', () => getSiteEventXPositions());
    };
  }, []);

  useEffect(() => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    setEmojiSerpHistogramData([...(emojiSerpHistogram?.map(value => {
      return {
        ...value,
        tags: value.tags.map(value => value),
      };
    }) ?? [])]);
    echartsInstance?.on('finished', () => {
      if (emojiSerpHistogram.length && !loading) {
        getEmojiDataXPositions();
        window.addEventListener('resize', () => setTimeout(() => getEmojiDataXPositions(), 500));
      }
      if (events.length && !loading) {
        getSiteEventXPositions();
        window.addEventListener('resize', () => setTimeout(() => {
          getSiteEventXPositions();
        }, 500));
      }
      echartsInstance.off('finished');
    });
  }, [loading, emojiSerpHistogram.length, events.length]);


  const getEmojiDataXPositions = useCallback(() => {
    const echartsInstance = echartsRef?.current?.getEchartsInstance();
    const positions = echartsInstance?._chartsViews[0]?._data?._layout?.points?.filter((x, i) => i % 2 === 0);
    const dataPoints = echartsInstance?._chartsViews[0]?._data?._idList;
    const emojiDataWithXPositions = emojiSerpHistogramData.filter(x => x.tags?.includes('Major Update')).map(emoji => {
      const date = emoji.occuredAt?.slice(0, 10);
      const index = dataPoints ? dataPoints.indexOf(date) : -1;
      return ({
        ...emoji,
        tags: emoji?.tags?.filter(item => item !== undefined && item !== ''),
        posX: index === -1 ? null : positions[index],
      });
    });
    setEmojiDataWithXPositions(emojiDataWithXPositions);
  }, [emojiSerpHistogramData]);

  const toolTipFormater: EChartOption.Tooltip.Formatter = (params: CustomSeries[]) => {
    const value = params[0]?.data?.value;
    const dateStr = params[0]?.data?.dateStr;
    const changeValue = params[0]?.data?.changeValue;
    // if (changeValue === 0) return;
    const element = (
      <span className={chartStyles.areaTooltip}>
        {value !=0 && (
          <>
            <p className={chartStyles.heading}>
              {'Google Rank Change: '}
              <span style={{color: changeValue > 0 ? '#2AC155' :
                changeValue < 0 ? '#F44343': ''}} className={`${chartStyles.subHeading} `}>
                {changeValue > 0 ?
                  <FontAwesomeIcon icon={faArrowUp} color='#2AC155' /> :
                  changeValue < 0 ?
                    <FontAwesomeIcon icon={faArrowDown} color='#F44343' />:
                    ''} {formatNumber(changeValue < 0 ? changeValue*-1 : changeValue, 1)?.toString()?.replace('.0', '')} </span><span style={{fontWeight: 400, color: '#4E5156'}}> <br /> {dateStr}
              </span>
            </p>


          </>
        )}
      </span>
    );
    return renderToString(element);
  };

  const xAxisDataOriginal = data.map(c => c.date);
  let xAxisData = data.map(c => c.date);
  const lastThirtyDays = [...new Array(30)].map((i, idx) => moment().startOf('day').subtract(idx, 'days').format('DD MMM YYYY'));
  const newDateArr = lastThirtyDays.reverse();
  const newArr = xAxisData.concat(newDateArr);

  if (xAxisData.length <= 1) {
    xAxisData = newArr;
  }

  const noDataBanner = () => {
    return (
      <div className={chartStyles.LowDataBannerGoogleChange}>
        <span>
          <NextImg src='/icons/faq1.svg' />
        </span>
        <span>
          <span>Get your website on the first page of Google for a higher traffic and revenue.</span>
          {!isWhitelabel && <a href={getHubSpotLink()} rel='noreferrer' target='_blank'>Schedule a free consultation</a>}
        </span>
      </div>
    );
  };

  const getGraphData = changeItem=>{
    return changeItem.positionChangeCumulative;
  };

  const dataSeries = data.map((changeItem, changeIndex, changeArray) => {
    return {
      value: getGraphData(changeItem),
      changeValue: changeIndex ?
        changeItem.positionChangeCumulative -
          changeArray[changeIndex - 1].positionChangeCumulative :
        0,
      dateStr: moment(changeItem.date).format('DD MMM YYYY'),
    };
  });

  const defaultSeriesData = xAxisData.map(date => {
    return {
      value: 0,
      changeValue: 0,
      dateStr: moment(date).format('DD MMM YYYY'),
    };
  });

  defaultSeriesData.unshift(dataSeries[0]);

  const chartOptions: EChartOption<EChartOption.Series> = {
    tooltip: {
      show: true,
      trigger: 'axis',
      backgroundColor: '#fff',
      extraCssText:
        'box-shadow: 0px 4px 10px rgba(0, 14, 88, 0.1) !important;border-radius: 10px; padding: 10px;',
      formatter: toolTipFormater,
      textStyle: {fontWeight: 'bold', fontSize: 16},
    },
    xAxis: {
      axisLine: {show: false},
      axisTick: {show: false, alignWithLabel: true},
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisLabel: {
        interval: xAxisDataOriginal.length <= 1 ? 1 : interval(),
        margin: 25,
        formatter: function(_: string, idx: number) {
          const value = dataSeries[idx]?.dateStr;
          let momentDate = null;
          xAxisDataOriginal.length <= 1 ? momentDate = moment(_) : momentDate = moment(value);

          if (xAxisDataOriginal.length <= 1) {
            return momentDate.format('D MMMM');
          }

          if (momentDate.get('date') === 1 && difference < 1) {
            return momentDate.format('MMM');
          }
          if (difference < 1) {
            return momentDate.format('D');
          }
          if (difference < 12) {
            return momentDate.format('MMMM');
          }
          if (difference >= 12) {
            return momentDate.format('MMM YY');
          }
        },
      },
    },
    yAxis: {
      show: true,
      type: 'value',
      axisLine: {show: false},
      axisTick: {show: false, alignWithLabel: true},
      splitLine: {lineStyle: {type: 'dashed'}},
      axisLabel: {
        formatter: function(value: number) {
          return numeral(value).format('0.[0][a]').toUpperCase();
        },
      },
    },
    grid: {top: 10,
      bottom: 130,
      left: 40,
      right: 25},
    series: [
      {
        data: xAxisDataOriginal.length <=1 ? defaultSeriesData : dataSeries,
        type: 'line',
        symbol: 'none',
        areaStyle: {
          opacity: 0.05,
        },

        lineStyle: {width: 0.5},

      },
    ],
    visualMap: [{
      show: false,
      inRange: {
        color: ['red', 'green'],
      },
    }],
  };

  return (
    <CardWrapper
      theme='themeLight'
      className={classnames(styles.cardLight, styles.topPagesCard)}>
      <div
        className={classnames(styles.topHeading, styles.topPagesCardHeading, styles.lightHeading)}>
        {isSelecting && <Checkbox checked={selectedSections.includes('googleChange')} onChange={e=>{
          selectSection(e.target.checked, 'googleChange');
        }}></Checkbox>}
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography tag='h4'>{'Google Rank Change'}</Typography>
            <div>
              <Tooltip title='Total daily position changes across all keywords where your web pages rank.'>
                <FontAwesomeIcon icon={faCircleInfo} fontSize={15} style={{marginBottom: -2, marginLeft: 10, display: 'inline-block', color: '#fff', cursor: 'pointer'}}/>
              </Tooltip>
            </div>
          </div>
          {loading ? <Tooltip title='Data is being loaded'>
            <div>
              <RingLoaderSmall height='1px' />
            </div>
          </Tooltip> : ''}
        </div>
      </div>
      {loading ? (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '385px'}}>
          <Spin indicator={antIcon} />
        </div>
      ) : (
        <div className={styles.chartsNoWidth}>
          {!isGoogleRankLoading ? <div className={styles.overankTable}>
            <ReactEcharts
              ref={echartsRef}
              notMerge
              option={chartOptions}
              lazyUpdate />
            <div className={styles.gscEmojiContainer} style={{position: 'absolute', bottom: 79}}>
              {emojiDataWithXPositions
                .filter(x => x.tags?.includes('Major Update') && x.posX !== null)
                .concat(eventDataWithXPositions.filter(x => x.isSitewide && x.posX !== null))
                .map((emoji, i) => {
                  return (
                    <Popover
                      key={i}
                      overlayClassName={styles.customPopover}
                      placement='bottom'
                      content={
                        <div>
                          {emoji?.occuredAt ?
                            <div className={styles.customPopoverTitle}>{emoji.occuredAt.slice(0, 10)}</div> :
                            <div className={styles.customPopoverTitle}>{emoji.date}</div>
                          }
                          <br/>
                          <div className={styles.customPopoverTitle}>
                            {!isWhitelabel && displayEmoji(emoji.icon) ? <span className={styles.iconSpace} style={{verticalAlign: 'top'}}>{displayEmoji(emoji.icon)}</span> : <NextImg height={22} width={22} className={styles.iconSpace} style={{verticalAlign: 'top'}} src={emoji.icon} />}
                            {emoji.title}</div>
                          <div className={styles.customPopoverDescription}>{emoji.description || emoji.name}</div>
                        </div>
                      }
                    >
                      <div style={{cursor: 'pointer', position: 'absolute', left: emoji.posX}} className={`${styles.iconWrapper} ${styles.iconWrapperLight}`}>
                        {!isWhitelabel && displayEmoji(emoji.icon) ? <span style={{color: '#000', cursor: 'pointer'}}>{displayEmoji(emoji.icon)}</span> : <NextImg width={8} height={8} src={emoji.icon}/> }
                      </div>
                    </Popover>
                  );
                })
              }
            </div>
          </div> : <Empty description='DATA IS LOADING...' style={{color: '#4e5156', height: '220px', paddingTop: '50px'}}/>}
        </div>
      )}
      {(xAxisDataOriginal.length < 1 && !loading) ? noDataBanner() : null}
    </CardWrapper>
  );
});
