import {BaseApi} from '@/api/base-api';
import {getAuthorizationHeader, getTokenFromCookies, getAuthHeader, getApiUrl} from '@/api/common-utils';
import {GetAllPagesResponse} from '@/components/dashboard/pages/landing-page-optimizer/types';
import {AxiosResponse} from 'axios';
import {EditPagePermissionPayload, PagePermissionsApiResponse} from './content-optimizer.model';
import {isNil} from 'lodash';

interface AiSettingsProps {
  language?: string;
  toneOfVoice?: string;
  typeOfContent?: string;
  pointOfView?: string;
}

interface TopUpProps {
  units?: number;
}

export class PagesApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');
  private static readonly baseUrlCluster: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');

  public async getPages(query: {[key: string]: string | number | boolean}) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'GET',
        url: 'pages/',
        params: {
          ...query,
          ...(!isNil(query.id) && {project: query.id}),
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getPagesSummary() {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: 'pages/summary-stats/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deletePage(uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'DELETE',
        url: `pages/${uuid}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async exportToCSV(payload:any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/export-data-to-csv2/`,
        data: {'uuids': payload},
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createPage(params?: any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/`,
        data: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async syncWP(params?: any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: '/pages/bulk-wp-sync/',
        data: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createBulkPages(params?: any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `content-fragments/full-seo-article-writer-bulk/`,
        data: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async generateOutlineWithoutContent(payload?: any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `https://ca.searchatlas.com/api/pages/`,
        data: payload,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // POST at https://ca.searchatlas.com/api/content-audit/bulk-create/
  // gsheets_url: url of the google sheets file
  // OR
  // file: uploaded file

  public async createAuditBulkPages(params?: any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `content-audit/bulk-create/`,
        data: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getPageByUuid(uuid: string) {
    try {
      const resp: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/pages/${uuid}/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return resp?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getHeroImagebyUuid(uuid: string) {
    try {
      const resp: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/pages/${uuid}/generate-hero-image/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return resp?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getRegernatedImagebyUuid(uuid: string, img: any) {
    try {
      const resp: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/pages/${uuid}/replace-image/`,
        data: img,
        headers: getAuthHeader(),
      });

      return resp?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  // TO DO: Check if used and delete
  public async getPageKwSuggestionsByUuid(uuid: string) {
    try {
      const resp: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/pages/${uuid}/keyword-suggestions/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      const {data} = resp;
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getPageKwSuggestionsByUuidV2(uuid: string) {
    try {
      const resp: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/pages/${uuid}/v2/keyword-suggestions/`,
        headers: getAuthHeader(),
      });

      const {data} = resp;
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async editTitle(uuid: string, title: string, version: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `pages/${uuid}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          title,
          version,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateHeroImage(uuid: string, heroImage: string|null, heroImageAltText: string, version: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `pages/${uuid}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          hero_image: heroImage,
          hero_image_alt_text: heroImageAltText,
          version,
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async editMeta(uuid: string, poyload: {metaTitle?: string; metaDesc?: string}) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `pages/${uuid}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: poyload,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async bulkUpdate(uuids?: any, dueDate?:string, status?:string, assignedToUsers?:string[], project?:string, restoreArticles?: boolean) {
    const updateData = {
      uuids: typeof(uuids)=='string' ? [uuids] : uuids,
      data: {
      },
    };
    if (dueDate) {
      updateData.data['due_date']= dueDate;
    }
    if (project) {
      updateData.data['project_name']= project;
    }
    if (status) {
      updateData.data['status']= status;
    }

    if (assignedToUsers) {
      updateData.data['assigned_to_users']= assignedToUsers;
    }

    if (restoreArticles) {
      updateData.data['was_deleted']= false;
    }

    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `/pages/bulk-update/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: updateData,
      },
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async deletebulkUpdate(uuids?: any) {
    const updateData = {
      uuids: typeof(uuids)=='string' ? [uuids] : uuids,

    };


    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'DELETE',
        url: `/pages/bulk-update/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: updateData,
      },
      );

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async editPageContent(uuid: string, editorState: string, version: string) {
    try {
      const headers = {};
      const token = getTokenFromCookies();
      if (token) {
        headers['Authorization'] = getAuthorizationHeader();
      }
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `pages/${uuid}/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        data: {
          editor_state: editorState,
          version,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async editPageStatus(uuid: string, status: string) {
    try {
      const headers = {};
      const token = getTokenFromCookies();
      if (token) {
        headers['Authorization'] = getAuthorizationHeader();
      }
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `pages/${uuid}/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        data: {
          status: status,
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getKeywords(uuid: string, shouldRescore?: boolean) {
    const url = shouldRescore ? `pages/${uuid}/keywords-data/?should_rescore=true` : `pages/${uuid}/keywords-data/`;
    try {
      const response: AxiosResponse = await this.axios({
        method: 'GET',
        url: url,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return response?.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async editKeywords(uuid: string, kw: any) {
    const kwLocation = kw.locationId && kw.location ? {
      location: kw.location,
      location_id: kw.locationId,
    } :{
      country_code: kw.countryCode || '',
    };

    const payload = kw.id ? {
      target_keyword: {
        keyword: kw.keyword,
        ...kwLocation,
        edited_id: kw.id,
      },
    } : {target_keyword: {
      keyword: kw.keyword,
      ...kwLocation,
    }};

    try {
      const response: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/edit-keywords-data/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: payload,
      });

      return response.data;
    } catch (err) {
      return;
    }
  }

  public async deleteKeywords(uuid: string, kw: any) {
    const kwLocation = kw.locationId && kw.location ? {
      location: kw.location,
      location_id: kw.locationId,
    } :{
      country_code: kw.countryCode || '',
    };

    try {
      const response: AxiosResponse = await this.axios({
        method: 'DELETE',
        url: `pages/${uuid}/edit-keywords-data/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: {
          target_keyword: {
            keyword: kw.keyword,
            ...kwLocation,
          },
        },
      });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async editPageLocation(uuid: string, countryCode?: string, location?: string, locationId?: number) {
    const pageLocation = location && locationId ? {
      location: location,
      location_id: locationId,
    } : {
      country_code: countryCode || '',
    };
    try {
      const response: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/edit-keywords-data/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: {
          ...pageLocation,
        },
      });

      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }


  public async analysis(uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/analysis/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getBulkSeoData() {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/metadata-batch/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getBulkSeoDetail(id:number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/metadata-batch/${id}/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createBulkSeoData(formData:any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/metadata-batch/`,
        data: formData,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getBulkSeoDataPublic(id:number, hash?:string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/metadata-batch/${id}`,
        params: {
          hash: hash,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,


      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getTopicalMapData(params) {
    try {
      const response = await this.axios.get(`${PagesApi.baseUrlCluster}/topical-authority-map/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSingleTopicMapData(id) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/topical-authority-map/${id}/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createTopicalMapData(formData:any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/topical-authority-map/`,
        data: formData,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteTopicalMapData(id: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'DELETE',
        url: `/topical-authority-map/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async refetchPage(uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/recrawl-page/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async analysisByUrl(uuid: string, url: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/analysis/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          url,
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async articleStatus(uuid: string, status: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/${status}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          status,
        },
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async uploadFromUrl(uuid: string, url: string, shouldOverwriteContent: boolean = true, renderWithbrowser: boolean = false) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/import-from-url/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: {
          url,
          'should_override_content': shouldOverwriteContent,
          ...(renderWithbrowser && {'should_use_headless_browser': true}),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getViewers(uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/content-optimizer/pages/${uuid}/viewers/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getPagePermissions(uuid: string) {
    try {
      const {data}: AxiosResponse<PagePermissionsApiResponse> = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/permissions/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async editPagePermissions(uuid: string, payload: EditPagePermissionPayload) {
    try {
      // TODO: Add this to base api as optional request transformer.
      // TODO TODO: this fails when we want to send a boolean as false, needs to be updated
      // const parsedPayload = Object.keys(payload).reduce((acc, curr) => {
      //   const snakeCaseKey = snakeCase(curr);
      //   if (payload[curr]) {
      //     acc[snakeCaseKey] = payload[curr];
      //     return acc;
      //   }
      //   return acc;
      // }, {});

      const {data}: AxiosResponse<PagePermissionsApiResponse> = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/permissions/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
        data: payload,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async exportToGoogledoc(uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/export-to-google-doc/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async exportToGoogleSheet(uuid: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `content-ideas/${uuid}/export-to-google-sheets/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getContentIdeas(keywords: string, countryCode: string, isPublic?: boolean) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: 'content-ideas/',
        data: {
          keyword: keywords,
          language: countryCode,
        },
        headers: {...(!isPublic && {
          Authorization: getAuthorizationHeader(),
        })},
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getContentIdeasDetails(keywords: string, countryCode: string, id: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `content-ideas/${id}/`,
        params: {
          keyword: keywords,
          language: countryCode,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadContentIdeasDetailsPublic(keywords: string, countryCode: string, id: string, hash: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `content-ideas/${id}/`,
        params: {
          keyword: keywords,
          language: countryCode,
          hash: hash,
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getAiTempaltes() {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `ai-templates/config/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /* AI OUTLINES ENDPOINTS START */
  public async getOneClickDraft(topic: string, termsToInclude: string[], length: string) {
    const payload = {
      'content_topic': topic,
      'terms_to_include': termsToInclude,
      'length': length,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/content-fragments/one-click-content-generator/`,
        data: payload,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async loadOneClickDraftResults(id: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/content-fragments/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async generateAiHeadings(uuid, topic: string, numberOfHeadings: number = 6) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/content-fragments/heading-suggestions/`,
        params: {
          'uuid': uuid,
          'content_topic': topic,
          'number_of_headings': numberOfHeadings,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async generateAiOutlines(uuid, topic: string, contentType: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `/content-fragments/outline-suggestion/`,
        params: {
          'uuid': uuid,
          'content_topic': topic,
          'content_type': contentType,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getAiOutline(uuid: string, topic?: string, termsToInclude?: string, resultsCounter?: number) {
    const payload = {
      ...(topic && {'content_topic': topic}),
      ...(termsToInclude && {'terms_to_include': termsToInclude}),
      ...(resultsCounter && {'num_of_results': resultsCounter}),
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/ai-content-outline/`,
        params: payload,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async loadAiGeneratedContentStatus(key: string, pageSize: Number, currentPage: Number): Promise<any> {
    const endpoint = 'content-fragments/bulk-ai-writer-statuses/';
    const fullUrl = `${endpoint}`;
    const param ={page_size: pageSize, page_number: currentPage};
    if (key !== '0') param['last_n_hours'] = key;

    try {
      const {data} = await this.axios({
        method: 'GET',
        url: fullUrl,
        params: param,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAiWriterHistory(uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `content-fragments/page-history/`,
        params: {uuid},
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getAiOutlineHistory(uuid: string) {
    const payload = {
      'should_get_history': true,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/ai-content-outline/`,
        params: payload,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  // used to generate outlines from standalone tool, doesnt requeire UUID
  public async getAiOutlineStandalone(topic?: string, termsToInclude?: string, resultsCounter?: number) {
    const payload = {
      ...(topic && {'content_topic': topic}),
      ...(termsToInclude && {'terms_to_include': termsToInclude}),
      ...(resultsCounter && {'num_of_results': resultsCounter}),
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `ai-content-generator/`,
        params: payload,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  // used to get AI Outline history from standalone tool, doesnt require UUID
  public async getAiOutlineHistoryStandalone() {
    const payload = {
      'should_get_history': true,
    };
    const token = getTokenFromCookies();
    if (token) {
      try {
        const {data}: AxiosResponse = await this.axios({
          method: 'GET',
          url: `ai-content-generator/`,
          params: payload,
          headers: getAuthHeader(),
          cancelToken: this.cancelToken,
        });

        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    }
  }
  /* AI OUTLINES ENDPOINTS END */

  public async getAiOutlineFromTemplates(templateType, inputs, resultsCounter) {
    const payload = {
      type: templateType,
      inputs: inputs,
      n: resultsCounter,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `ai-templates/`,
        data: payload,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getAiTemplatesHistory(templateType) {
    const payload = {
      type: templateType,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `ai-templates/history/`,
        params: payload,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCompleteSeoArticle(props) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/content-fragments/full-seo-article-writer/`,
        data: props,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getToneOfVoiceSuggestions(title: string, headings: string[]) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/content-fragments/auto-match-voice/`,
        data: {
          title, headings,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /* FIRST DRAFT GENERATOR ENDPOINTS START */
  public async getAiOutlineV2(props, uuid) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/v2/ai-content-outline/`,
        data: props,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  // to be used only in standalone tool, doesnt require UUID
  public async getAiOutlineV2Standalone(props) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `first-draft-generator/`,
        data: props,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getFitstDraftHistory(uuid) {
    const payload = {
      'should_get_history': true,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/v2/ai-content-outline/`,
        data: payload,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  // used to get history from standalone tool, doesnt require UUID
  public async getFitstDraftHistoryStandalone() {
    const payload = {
      'should_get_history': true,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `first-draft-generator/`,
        data: payload,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /* FIRST DRAFT GENERATOR ENDPOINTS END */

  public async getHeadingSuggestions(uuid) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/heading-suggestions/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getUserAiSettings() {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `user-ai-settings/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async updateUserAiSettings({language, toneOfVoice, pointOfView}: AiSettingsProps) {
    const payload = {
      ...(language && {'default_language': language}),
      ...(!isNil(toneOfVoice) && {'tone_of_voice': toneOfVoice}),
      ...(!isNil(pointOfView) && {'point_of_view': pointOfView}),
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'PUT',
        url: `user-ai-settings/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: payload,
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async addTopUpQuote({units}: TopUpProps) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `${PagesApi.baseUrl}/customer/premium-ai-quota-top-up/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: {units: units},
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getLlmApiKeys() {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `customer-llm-api-keys/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateLlmApiKeys(payload: any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `${PagesApi.baseUrlCluster}/customer-llm-api-keys/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: payload,
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async updateContentProjectAiSettings({payload, projectId}:any) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'PATCH',
        url: `${PagesApi.baseUrlCluster}/pages/projects/${projectId || 0}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: payload,
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createArticleFromCi(title: string, content: string, id: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `content-ideas/${id}/create-article/`,
        data: {
          title: title,
          content: content,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createContentFragment(keyword: string, isReplace?: boolean) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `content-fragment/`,
        data: {
          keyword: keyword,
          ...(isReplace && {type: 'rewrite_content'}),
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async getSEOMetaTagsRepolling(id) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `metadata-ideas/${id}/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSEOMetaTagsRepollingPublic(id, hash: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `metadata-ideas/${id}/`,
        params: {
          hash: hash,
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSEOMetaTags(companyInfo, isPublic?: boolean) {
    const payload = {
      ...(companyInfo.companyName && {company_name: companyInfo.companyName}),
      ...(companyInfo.companyUrl && {webpage: companyInfo.companyUrl}),
      title: companyInfo.companyTitle,
      keyword: companyInfo.companyTargetKeywords.replaceAll('\n', ','),
    };

    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: 'metadata-ideas/',
        data: payload,
        headers: {...(!isPublic && {
          Authorization: getAuthorizationHeader(),
        })},
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
 * Get list of all Projects
 */
  public async getProjects() {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'GET',
        url: 'pages/projects/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Get a single Project by name
   * @param {string} name represents name of the project
   */
  public async createProjectByName(name: string) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'POST',
        url: 'pages/projects/',
        data: {
          name: name,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Adding single article to a project
   * @param {number} id of the project
   * @param {string} uuid uuid of the article we are adding
   */
  public async addPageToProject(id: number, uuid: string) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/add-to-project/`,
        data: {
          id: id,
          uuid: uuid,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Removing single project
   * @param {number} id of the project
   */
  public async deleteProject(id: number) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'DELETE',
        url: `pages/delete-project/`,
        data: {
          id: id,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Filter pages by project
   * @param {number} id of the project
   * @param {number} currentPage of the project
   * @param {number} pageSize of the project
   * @param {string} ordering of the project
   * @param {string} search of the project
   * @param {string} status of the project
   */

  public async filterPagesByProject(ordering, search, status, id: number, currentPage: number, pageSize: number) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'GET',
        url: `pages/`,
        params: {
          ordering: ordering,
          search: search,
          status: status,
          project: id,
          page: currentPage,
          page_size: pageSize,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * Rename single project
   * @param {number} id of the project
   * @param {string} name new name for the chosen project
   */
  public async renameProject(id: number, name: string) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'POST',
        url: `pages/projects/`,
        data: {
          id: id,
          name: name,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async exportArticleAsHtml(uuid: number) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'GET',
        url: `pages/${uuid}/export-as-html`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getAiGeneratedImage(prompt: string, aspectRatio: string, isRealistic: boolean, returnRandomChoice: boolean, imagesEngine: string) {
    const payload = {
      prompt,
      aspect_ratio: aspectRatio,
      is_realistic: isRealistic,
      return_random_choice: returnRandomChoice,
      images_engine: imagesEngine,
    };
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `/ai-templates/image-generation/`,
        data: payload,
        headers: getAuthHeader(),
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async addFocusTerms(payload: any, uuid: string) {
    try {
      const {data}: AxiosResponse = await this.axios({
        method: 'POST',
        url: `pages/${uuid}/add-focus-terms/`,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        data: payload,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
