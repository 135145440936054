import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

interface OrdersProps {
  page: number;
  pageSize: number;
  ordering?: string;
  search?: string;
}
interface OrderDetailsProps {
  orderId: string;
  placementId?: string;
}
class OrdersApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');

  public async getOrders({page, pageSize, ordering, search}: OrdersProps) {
    const response = await this.axios.get(`${OrdersApi.baseUrl}/orders/`, {
      headers: {Authorization: getAuthorizationHeader()},
      params: {
        'page': page,
        'page_size': pageSize,
        ...(ordering && {'ordering': ordering}),
        ...(search && {'search': search}),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getOrderDetails({orderId}: OrderDetailsProps) {
    const response = await this.axios.get(`${OrdersApi.baseUrl}/orders/${orderId}/`, {
      headers: {Authorization: getAuthorizationHeader()},
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
  public async getCategoryDistribution() {
    const response = await this.axios.get(`${OrdersApi.baseUrl}/orders/category-distribution/`, {
      headers: {Authorization: getAuthorizationHeader()},
      cancelToken: this.cancelToken,
    });
    return response.data;
  }


  public async payForOrder({orderId}: OrderDetailsProps) {
    const response = await this.axios.post(`${OrdersApi.baseUrl}/orders/${orderId}/pay-for-order/`, {}, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }

  public async approveOnsite({orderId, placementId}: OrderDetailsProps) {
    const response = await this.axios.patch(`${OrdersApi.baseUrl}/orders/${orderId}/approve-onsite/`,
      {
        'onsitecontent_id': placementId,
      },
      {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
    return response.data;
  }

  public async orderAgain({orderId}: OrderDetailsProps) {
    const response = await this.axios.get(`${OrdersApi.baseUrl}/orders/${orderId}/duplicate/`, {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      cancelToken: this.cancelToken,
    });
    return response.data;
  }
}

export default OrdersApi;
