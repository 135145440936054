import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class ProjectsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer/projects/projects');

  public async getProjectsForHomePage() {
    try {
      const response = await this.axios.get(`${ProjectsApi.baseUrl}`,
        {headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // for home v2
  public async deleteProject(id: number) {
    try {
      const response = await this.axios.delete(`${ProjectsApi.baseUrl}/${id}/`,
        {headers: {Authorization: await getAuthorizationHeader()},
          cancelToken: this.cancelToken},
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getProjectsData(params) {
    try {
      const response = await this.axios.get(`${ProjectsApi.baseUrl}/`, {
        headers: {Authorization: await getAuthorizationHeader()},
        params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }

  public async patchProjectKeyword(id, keywords) {
    try {
      const response = await this.axios.patch(`${ProjectsApi.baseUrl}/${id}/`, {keywords}, {
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return e;
    }
  }

  public async connectProject(projectId, payload) {
    try {
      const response = await this.axios.post(`${ProjectsApi.baseUrl}/${projectId}/connect/`, payload, {
        headers: {Authorization: await getAuthorizationHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
export const PROJECTS_API = new ProjectsApi();
