export const HOME_FREE_CONSULTATION_CLICKED = 'HOME_FREE_CONSULTATION_CLICKED';

export const BACKLINK_CREATE_NEW_PROJECT_CLICKED = 'BACKLINK_CREATE_NEW_PROJECT_CLICKED';
export const BACKLINK_UPDATE_PROJECT_CLICKED = 'BACKLINK_UPDATE_PROJECT_CLICKED';
export const BACKLINK_CHANGE_COMPETITOR_PROJECT_CLICKED = 'BACKLINK_CHANGE_COMPETITOR_PROJECT_CLICKED';
export const BACKLINK_TOPICAL_RELEVANCE_EXPORT_ALL = 'BACKLINK_TOPICAL_RELEVANCE_EXPORT_ALL';
export const BACKLINK_TOPICAL_CHANGE_COMPETITOR_PROJECT_CLICKED = 'BACKLINK_TOPICAL_CHANGE_COMPETITOR_PROJECT_CLICKED';
export const BACKLINK_TOXIC_EXPORT_ALL_BACKLINKS = 'BACKLINK_TOXIC_EXPORT_ALL_BACKLINKS';
export const BACKLINK_TOXIC_EXPORT_ALL_DISAVOW = 'BACKLINK_TOXIC_EXPORT_ALL_DISAVOW';
export const BACKLINK_TOXIC_EXPORT_ALL_WHITELIST = 'BACKLINK_TOXIC_EXPORT_ALL_WHITELIST';
export const BACKLINK_VELOCITY_SHOW_LOG_SCALE = 'BACKLINK_VELOCITY_SHOW_LOG_SCALE';

export const HOME_UPGRADE_CTA_CLICKED = 'HOME_UPGRADE_CTA_CLICKED';
export const CP_OPENED_FROM_KE = 'CP_OPENED_FROM_KE';
export const GSC_UPGRADE_CTA_CLICKED = 'GSC_UPGRADE_CTA_CLICKED';
export const BL_UPGRADE_CTA_CLICKED = 'BL_UPGRADE_CTA_CLICKED';
export const SCA_UPGRADE_CTA_CLICKED = 'SCA_UPGRADE_CTA_CLICKED';
export const CR_UPGRADE_CTA_CLICKED = 'CR_UPGRADE_CTA_CLICKED';

export const SA_ISSUE_ANALYZER_PAGE_OPEN = 'SA_ISSUE_ANALYZER_PAGE_OPEN';
export const SA_REPORTS_ANALYZER_PAGE_OPEN = 'SA_REPORTS_ANALYZER_PAGE_OPEN';
export const SA_PAGE_PRUNING_PAGE_OPEN = 'SA_PAGE_PRUNING_PAGE_OPEN';
export const SA_PAGE_DETAIL_DRAWER_OPEN = 'SA_PAGE_DETAIL_DRAWER_OPEN';


export const GSC_INSIGHTS_GSC_AUTHORIZE_CLICKED = 'GSC_INSIGHTS_GSC_AUTHORIZE_CLICKED';
export const PROJECTS_GSC_AUTHORIZE_CLICKED = 'PROJECTS_GSC_AUTHORIZE_CLICKED';
export const SETTINGS_GSC_AUTHORIZE_CLICKED = 'SETTINGS_GSC_AUTHORIZE_CLICKED';

export const SCA_CREATE_PAGE = 'SCA_CREATE_PAGE';
export const SCA_PAGE_ADD_KEYWORD = 'SCA_PAGE_ADD_KEYWORD';
export const SCA_PAGE_ADD_KEYWORD_SUGGESTION = 'SCA_PAGE_ADD_KEYWORD_SUGGESTION';
export const SCA_PAGE_UPDATE_KEYWORD_LOCATION = 'SCA_PAGE_UPDATE_KEYWORD_LOCATION';
export const SCA_PAGE_EXPORT_TO_DOCS = 'SCA_PAGE_EXPORT_TO_DOCS';
export const SCA_PAGE_TECHNICAL_TAB_OPEN = 'SCA_PAGE_TECHNICAL_TAB_OPEN';
export const SCA_PAGE_CONTENT_IDEAS_GENERATE = 'SCA_PAGE_CONTENT_IDEAS_GENERATE';
export const SCA_PAGE_AI_OUTLINE_GENERATE = 'SCA_PAGE_AI_OUTLINE_GENERATE';
export const SCA_PAGE_AI_ONE_CLICK_GENERATE = 'SCA_PAGE_AI_ONE_CLICK_GENERATE';
export const SCA_PAGE_CONTENT_IDEA_COPY = 'SCA_PAGE_CONTENT_IDEA_COPY';
export const SCA_PAGE_CONTENT_IDEA_DOWNLOAD = 'SCA_PAGE_CONTENT_IDEA_DOWNLOAD';
export const SCA_PAGE_AI_OUTLINE_DOWNLOAD = 'SCA_PAGE_AI_OUTLINE_DOWNLOAD';
export const SCA_PAGE_SHARED = 'SCA_PAGE_SHARED';
export const SCA_PAGE_URL_UPDATE = 'SCA_PAGE_URL_UPDATE';
export const SCA_PAGE_INTERNAL_LINKS_SHOW_BROKEN = 'SCA_PAGE_INTERNAL_LINKS_SHOW_BROKEN';
export const SCA_PAGE_EXTERNAL_LINKS_SHOW_BROKEN = 'SCA_PAGE_EXTERNAL_LINKS_SHOW_BROKEN';
export const SCA_PAGE_IMAGES_SHOW_MISSING_ALT = 'SCA_PAGE_IMAGES_SHOW_MISSING_ALT';
export const SCA_PAGE_FOCUS_TERMS_TAB_OPEN = 'SCA_PAGE_FOCUS_TERMS_TAB_OPEN';
export const SCA_PAGE_QUESTIONS_TAB_OPEN = 'SCA_PAGE_QUESTIONS_TAB_OPEN';
export const SCA_PAGE_LINK_SUGGESTIONS_TAB_OPEN = 'SCA_PAGE_LINK_SUGGESTIONS_TAB_OPEN';

export const FOCUS_TERMS_SEARCH = 'FOCUS_TERMS_SEARCH';
export const KEYWORD_EXPLORER_SEARCH = 'KEYWORD_EXPLORER_SEARCH';

export const KEYWORD_RESEARCH_FIND_KEYWORD_CLICK = 'KEYWORD_RESEARCH_FIND_KEYWORD_CLICK';
export const KEYWORD_RESEARCH_VIEW_DETAIL_CLICK = 'KEYWORD_RESEARCH_VIEW_DETAIL_CLICK';
export const KEYWORD_RESEARCH_VIEW_LIST_CLICK = 'KEYWORD_RESEARCH_VIEW_LIST_CLICK';

export const KEYWORD_RESEARCH_VIEW_DETAIL = 'KEYWORD_RESEARCH_VIEW_DETAIL';
export const KEYWORD_RESEARCH_OVERVIEW_PAGE_OPEN = 'KEYWORD_RESEARCH_OVERVIEW_PAGE_OPEN';
export const KEYWORD_RESEARCH_OVERVIEW_EXPORT_CSV = 'KEYWORD_RESEARCH_OVERVIEW_EXPORT_CSV';
export const KEYWORD_RESEARCH_DETAIL_PAGE_OPEN = 'KEYWORD_RESEARCH_DETAIL_PAGE_OPEN';

export const KEYWORD_LISTS_CREATE = 'KEYWORD_LISTS_CREATE';
export const KEYWORD_LISTS_UPDATE = 'KEYWORD_LISTS_UPDATE';
export const KEYWORD_LISTS_UPDATE_FROM_DETAIL_PAGE = 'KEYWORD_LISTS_UPDATE_FROM_DETAIL_PAGE';
export const KEYWORD_LISTS_UPDATE_FROM_OVERVIEW_PAGE = 'KEYWORD_LISTS_UPDATE_FROM_OVERVIEW_PAGE';
export const KEYWORD_LISTS_DELETE = 'KEYWORD_LISTS_DELETE';


export const COMPETITOR_EXPLORER_SEARCH = 'COMPETITOR_EXPLORER_SEARCH';

export const GSC_INSIGHTS_VIEW_PAGE_INSIGHTS_DATA = 'GSC_INSIGHTS_VIEW_PAGE_INSIGHTS_DATA';
export const GSC_INSIGHTS_VIEW_PAGE_HISTORICAL_DATA = 'GSC_INSIGHTS_VIEW_PAGE_HISTORICAL_DATA';
export const GSC_INSIGHTS_VIEW_KEYWORD_HISTORICAL_DATA = 'GSC_INSIGHTS_VIEW_KEYWORD_HISTORICAL_DATA';
export const GSC_INSIGHTS_VIEW_KEYWORD_SERP_DETAILS_DATA = 'GSC_INSIGHTS_VIEW_KEYWORD_SERP_DETAILS_DATA';
export const GSC_INSIGHTS_UPDATE_DATE_RANGE = 'GSC_INSIGHTS_UPDATE_DATE_RANGE';
export const GSC_INSIGHTS_SEARCH_FOR_KEYWORD = 'GSC_INSIGHTS_SEARCH_FOR_KEYWORD';
export const GSC_INSIGHTS_SEARCH_FOR_KEYWORD_PAGE_HISTORICAL = 'GSC_INSIGHTS_SEARCH_FOR_KEYWORD_PAGE_HISTORICAL';
export const GSC_INSIGHTS_SEARCH_FOR_PAGE = 'GSC_INSIGHTS_SEARCH_FOR_PAGE';
export const GSC_PAGES_AND_KEYWORDS_TABLE_TABS_SWITCH = 'GSC_PAGES_AND_KEYWORDS_TABLE_TABS_SWITCH';
export const GSC_PAGES_AND_KEYWORDS_TABLE_EXPORT_TO_CSV = 'GSC_PAGES_AND_KEYWORDS_TABLE_EXPORT_TO_CSV';
export const GSC_PROJECT_CHANGED = 'GSC_PROJECT_CHANGED';
export const GSC_CREATING_SITE_EVENT = 'GSC_CREATING_SITE_EVENT';
export const GSC_EDITING_SITE_EVENT = 'GSC_EDITING_SITE_EVENT';
export const GSC_CREATING_PAGE_GROUPINGS = 'GSC_CREATING_PAGE_GROUPINGS';
export const GSC_ADDING_PAGES_TO_GROUPING = 'GSC_ADDING_PAGES_TO_GROUPING';

export const CI_GENERATE_BLOG_IDEAS_CLICKED = 'CI_GENERATE_BLOG_IDEAS_CLICKED';
export const CI_BLOG_IDEAS_CSV_EXPORT_CLICKED = 'CI_BLOG_IDEAS_CSV_EXPORT_CLICKED';
export const CI_COPY_ARTICLE_TO_CLIPBOARD = 'CI_COPY_ARTICLE_TO_CLIPBOARD';
export const CI_EDIT_ARTICLE = 'CI_EDIT_ARTICLE';
export const CI_DOWNLOAD_ARTICLE = 'CI_DOWNLOAD_ARTICLE';
export const CI_GENERATE_SEO_METATAGS_IDEAS_CLICKED = 'CI_GENERATE_SEO_METATAGS_IDEAS_CLICKED';
export const CI_POPULAR_TEMPLATES_TAB_SWITCH = 'CI_POPULAR_TEMPLATES_TAB_SWITCH';

// Mixpanel for Pruning Page
export const PRUNING_PAGE_GRAPH_TRAFFIC_TAB = 'PRUNING_PAGE_GRAPH_TRAFFIC_TAB';
export const PRUNING_PAGE_GRAPH_IMPRESSIONS_TAB = 'PRUNING_PAGE_GRAPH_IMPRESSIONS_TAB';
export const PRUNING_PAGE_EXPORT_ALL = 'PRUNING_PAGE_EXPORT_ALL';
export const PRUNING_PAGE_EXPORT_TABLE_TO_CVS = 'PRUNING_PAGE_EXPORT_TABLE_TO_CVS';
export const PRUNING_PAGE_OPENING_PAGE_GROUPS_DROPDOWN = 'PRUNING_PAGE_OPENING_PAGE_GROUPS_DROPDOWN';
export const PRUNING_PAGE_OPENING_HISTORICAL_DATA_SIDEDRAWER = 'PRUNING_PAGE_OPENING_HISTORICAL_DATA_SIDEDRAWER';
export const PRUNING_PAGE_OPENING_PAGE_INSIGHTS_SIDEDRAWER = 'PRUNING_PAGE_OPENING_PAGE_INSIGHTS_SIDEDRAWER';
export const PRUNING_PAGE_ADDING_PAGE_TO_WATCHLIST = 'PRUNING_PAGE_ADDING_PAGE_TO_WATCHLIST';


// Mixpanle for PPC Landscapes
export const PPC_LANDSCAPE_HISTOGRAM_KEYWORDS_TAB = 'PPC_LANDSCAPE_HISTOGRAM_KEYWORDS_TAB';
export const PPC_LANDSCAPE_HISTOGRAM_IMPRESSIONS_TAB = 'PPC_LANDSCAPE_HISTOGRAM_IMPRESSIONS_TAB';
export const PPC_LANDSCAPE_HISTOGRAM_TRAFFIC_TAB = 'PPC_LANDSCAPE_HISTOGRAM_TRAFFIC_TAB';
export const PPC_LANDSCAPE_TOP_PPC_COMPETITORS_SHARED_KW_TAB = 'PPC_LANDSCAPE_TOP_PPC_COMPETITORS_SHARED_KW_TAB';
export const PPC_LANDSCAPE_TOP_PPC_COMPETITORS_OVERVIEW_TAB = 'PPC_LANDSCAPE_TOP_PPC_COMPETITORS_OVERVIEW_TAB';
export const PPC_LANDSCAPE_TOP_PAGES_URL = 'PPC_LANDSCAPE_TOP_PAGES_URL';

// Mixpanle for Organic Landscapes
export const ORGANIC_LANDSCAPE_HISTOGRAM_KEYWORDS_TAB = 'ORGANIC_LANDSCAPE_HISTOGRAM_KEYWORDS_TAB';
export const ORGANIC_LANDSCAPE_HISTOGRAM_IMPRESSIONS_TAB = 'ORGANIC_LANDSCAPE_HISTOGRAM_IMPRESSIONS_TAB';
export const ORGANIC_LANDSCAPE_HISTOGRAM_TRAFFIC_TAB = 'ORGANIC_LANDSCAPE_HISTOGRAM_TRAFFIC_TAB';
export const ORGANIC_LANDSCAPE_TOP_ORGANIC_COMPETITORS_SHARED_KW_TAB = 'ORGANIC_LANDSCAPE_TOP_ORGANIC_COMPETITORS_SHARED_KW_TAB';
export const ORGANIC_LANDSCAPE_TOP_ORGANIC_COMPETITORS_OVERVIEW_TAB = 'ORGANIC_LANDSCAPE_TOP_ORGANIC_COMPETITORS_OVERVIEW_TAB';
export const ORGANIC_LANDSCAPE_TOP_PAGES_URL = 'ORGANIC_LANDSCAPE_TOP_PAGES_URL';


// Mixpanel for settings
export const SETTINGS_PROFILE_TAB = 'SETTINGS_PROFILE_TAB';
export const SETTINGS_API_KEY_TAB = 'SETTINGS_API_KEY_TAB';


export const SETTINGS_ACCOUNT_DETAILS_UPDATE = 'SETTINGS_ACCOUNT_DETAILS_UPDATE';
export const SETTINGS_PASSWORD_UPDATE = 'SETTINGS_PASSWORD_UPDATE';

export const SETTINGS_GSC_INTEGRATIONS_TAB = 'SETTINGS_GSC_INTEGRATIONS_TAB';
export const SETTINGS_DISCONNECT_ACCOUNT = 'SETTINGS_DISCONNECT_ACCOUNT';
export const SETTINGS_DISCONNECT_GA_ACCOUNT = 'SETTINGS_DISCONNECT_GA_ACCOUNT';
export const SETTINGS_REFETCHING_SITES = 'SETTINGS_REFETCHING_SITES';

export const SETTINGS_EMAIL_UPDATES_TAB = 'SETTINGS_EMAIL_UPDATES_TAB';
export const SETTINGS_GSC_EMAIL_UPDATES_TAB = 'SETTINGS_GSC_EMAIL_UPDATES_TAB';
export const SETTINGS_BL_EMAIL_UPDATES_TAB = 'SETTINGS_BL_EMAIL_UPDATES_TAB';
export const SETTINGS_GLOBAL_EMAIL_UPDATE = 'SETTINGS_GLOBAL_EMAIL_UPDATE';
export const SETTINGS_USER_EMAIL_UPDATE = 'SETTINGS_USER_EMAIL_UPDATE';
export const SETTINGS_PROJECT_EMAIL_UPDATE = 'SETTINGS_PROJECT_EMAIL_UPDATE';


export const SETTINGS_TEAM_MEMBERS_TAB = 'SETTINGS_TEAM_MEMBERS_TAB';
export const SETTINGS_ADDING_NEW_TEAM_MEMBER = 'SETTINGS_ADDING_NEW_TEAM_MEMBER';
export const SETTINGS_CHANGING_TEAM_MEMBER_ROLE = 'SETTINGS_CHANGING_TEAM_MEMBER_ROLE';
export const SETTINGS_ACTIVATING_USER = 'SETTINGS_ACTIVATING_USER';
export const SETTINGS_DEACTIVATING_USER = 'SETTINGS_DEACTIVATING_USER';

// Mixpanel when page is opened
export const DASHBOARD_PAGE_IS_OPENED = 'DASHBOARD_PAGE_IS_OPENED';


export const MY_ORDERS_ORDER_BUILDER_BUTTON_CLICKED = 'MY_ORDERS_ORDER_BUILDER_BUTTON_CLICKED';

// Mixpanel for DA onboarding flow
export const DOMAIN_ANALYZER_STARTED = 'DOMAIN_ANALYZER_STARTED';
export const DOMAIN_ANALYZER_COMPLETED = 'DOMAIN_ANALYZER_COMPLETED';
export const DOMAIN_ANALYZER_REGISTRATION_STARTED = 'DOMAIN_ANALYZER_REGISTRATION_STARTED';
export const DOMAIN_ANALYZER_REGISTRATION_CONFIRMED = 'DOMAIN_ANALYZER_REGISTRATION_CONFIRMED';

