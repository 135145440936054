import {Button} from '@/components/common-components';
import {faFaceGrinStars} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
interface ComingSoonProps {
  setShowComingSoon: (value: boolean) => void;
  setSelectedField?: (value: any) => void;
  comingSoonCount?: number;
  scrollToSection: () => void;
}
export const ComingSoonBanner = observer(({setShowComingSoon, setSelectedField, comingSoonCount, scrollToSection}: ComingSoonProps) => {
  const onClickComingSoon = () => {
    setSelectedField('');
    setShowComingSoon(true);
    scrollToSection();
  };
  return (
    <ComingSoonWrapper>
      <div className='emoji'>
        <FontAwesomeIcon icon={faFaceGrinStars} color='#7F4EAD' fontSize={38} />
      </div>
      <div className='content-wrapper'>
        <div className='heading'>{comingSoonCount} Features Coming Soon!</div>
        <div className='description'>{`Check out upcoming SEO features designed to boost visibility, enhance local engagement, and drive your brand's growth!`}</div>
        <Button className='whats-coming-btn' buttonType='purple' onClick={onClickComingSoon}>What’s Coming Soon?</Button>
      </div>
    </ComingSoonWrapper>
  );
});

const ComingSoonWrapper = styled.div`
background: rgba(127, 78, 173, 10%);
border-radius: 12px;
padding: 24px 22px;
margin-right: 20px;
  .content-wrapper {
    padding-top: 10px;
    .heading {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #7F4EAD;
    }
    .description {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #4E5156;
      max-width: 314px;
    }
    .whats-coming-btn {
      margin-top: 13px;
      border-radius: 6px;
      :hover {
        span {
          color: #7F4EAD;
        }
      }
    }
  }
`;
