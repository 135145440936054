import {DIGITAL_PR_API} from '@/api/digital-pr';
import {getSingleUrlParam} from '@/utils/url';
import {flow, types, cast} from 'mobx-state-tree';
import {toJS} from 'mobx';
import moment from 'moment';

const JOURNALIST_FILTER = [
  {id: 1, name: 'search_checkboxes', header: 'Search', filterField: 'search', text: '', type: undefined, active: false, isSearch: true, filterTypes: [
    {label: 'Name', value: 'name'},
    {label: 'Description', value: 'description'},
    {label: 'Biography', value: 'biography'},
    {label: 'Covers', value: 'covers'},
    {label: 'Does not cover', value: 'does_not_cover'},
    {label: 'Contact preferences', value: 'contact_preferences'},
    {label: 'Email', value: 'email'},
    {label: 'Title', value: 'title'},
  ], filterFields: ['name', 'description', 'biography', 'covers', 'does_not_cover', 'contact_preferences', 'email', 'title']},
  {id: 2, name: 'followers', header: 'Followers', from: undefined, to: undefined, type: undefined, active: false},
  {id: 3, name: 'search_simple', header: 'Title', filterField: 'title', text: '', active: false},
  {id: 4, name: 'search_simple', header: 'Media Outlet', filterField: 'media_outlet', text: '', active: false},
  {id: 5, name: 'checkboxes', header: 'Categories', customCategoryType: 'category', filterTypes: [
    {label: 'Afghanistan', value: 'Afghanistan'},
    {label: 'Africa', value: 'Africa'},
    {label: 'Arts and Entertainment', value: 'Arts and Entertainment'},
    {label: 'Australia', value: 'Australia'},
    {label: 'Bangladesh', value: 'Bangladesh'},
    {label: 'Beauty', value: 'Beauty'},
    {label: 'Belgium', value: 'Belgium'},
    {label: 'Brazil', value: 'Brazil'},
    {label: 'Business and Finance', value: 'Business and Finance'},
    {label: 'Canada', value: 'Canada'},
    {label: 'Chile', value: 'Chile'},
    {label: 'China', value: 'China'},
    {label: 'Colombia', value: 'Colombia'},
    {label: 'Crime and Justice', value: 'Crime and Justice'},
    {label: 'Education', value: 'Education'},
    {label: 'Egypt', value: 'Egypt'},
    {label: 'Energy', value: 'Energy'},
    {label: 'Environment', value: 'Environment'},
    {label: 'Ethiopia', value: 'Ethiopia'},
    {label: 'Fashion', value: 'Fashion'},
    {label: 'Food and Dining', value: 'Food and Dining'},
    {label: 'France', value: 'France'},
    {label: 'Germany', value: 'Germany'},
    {label: 'Health', value: 'Health'},
    {label: 'India', value: 'India'},
    {label: 'Indonesia', value: 'Indonesia'},
    {label: 'Ireland', value: 'Ireland'},
    {label: 'Israel', value: 'Israel'},
    {label: 'Italy', value: 'Italy'},
    {label: 'Japan', value: 'Japan'},
    {label: 'Kenya', value: 'Kenya'},
    {label: 'Malawi', value: 'Malawi'},
    {label: 'Media', value: 'Media'},
    {label: 'Metro Atlanta', value: 'Metro Atlanta'},
    {label: 'Metro Baltimore', value: 'Metro Baltimore'},
    {label: 'Metro Boston', value: 'Metro Boston'},
    {label: 'Metro Chicago', value: 'Metro Chicago'},
    {label: 'Metro D.C.', value: 'Metro D.C.'},
    {label: 'Metro Dallas', value: 'Metro Dallas'},
    {label: 'Metro Denver', value: 'Metro Denver'},
    {label: 'Metro Detroit', value: 'Metro Detroit'},
    {label: 'Metro Houston', value: 'Metro Houston'},
    {label: 'Metro Las Vegas', value: 'Metro Las Vegas'},
    {label: 'Metro Los Angeles', value: 'Metro Los Angeles'},
    {label: 'Metro Miami', value: 'Metro Miami'},
    {label: 'Metro Minneapolis–St. Paul', value: 'Metro Minneapolis–St. Paul'},
    {label: 'Metro New Orleans', value: 'Metro New Orleans'},
    {label: 'Metro New York', value: 'Metro New York'},
    {label: 'Metro Philadelphia', value: 'Metro Philadelphia'},
    {label: 'Metro Phoenix', value: 'Metro Phoenix'},
    {label: 'Metro Pittsburgh', value: 'Metro Pittsburgh'},
    {label: 'Metro San Diego', value: 'Metro San Diego'},
    {label: 'Metro San Francisco', value: 'Metro San Francisco'},
    {label: 'Metro Seattle', value: 'Metro Seattle'},
    {label: 'Metro St. Louis', value: 'Metro St. Louis'},
    {label: 'Mexico', value: 'Mexico'},
    {label: 'Middle East', value: 'Middle East'},
    {label: 'Military', value: 'Military'},
    {label: 'New Zealand', value: 'New Zealand'},
    {label: 'Nigeria', value: 'Nigeria'},
    {label: 'Opinion and Editorial', value: 'Opinion and Editorial'},
    {label: 'Pakistan', value: 'Pakistan'},
    {label: 'Peru', value: 'Peru'},
    {label: 'Philippines', value: 'Philippines'},
    {label: 'Politics', value: 'Politics'},
    {label: 'Real Estate', value: 'Real Estate'},
    {label: 'Religion', value: 'Religion'},
    {label: 'Russia', value: 'Russia'},
    {label: 'Rwanda', value: 'Rwanda'},
    {label: 'Science', value: 'Science'},
    {label: 'Singapore', value: 'Singapore'},
    {label: 'South Africa', value: 'South Africa'},
    {label: 'Southeast Asia', value: 'Southeast Asia'},
    {label: 'Spain', value: 'Spain'},
    {label: 'Sports', value: 'Sports'},
    {label: 'Tanzania', value: 'Tanzania'},
    {label: 'Technology', value: 'Technology'},
    {label: 'Transportation', value: 'Transportation'},
    {label: 'Travel', value: 'Travel'},
    {label: 'Turkey', value: 'Turkey'},
    {label: 'U.S.', value: 'U.S.'},
    {label: 'U.S. Regional', value: 'U.S. Regional'},
    {label: 'Uganda', value: 'Uganda'},
    {label: 'United Kingdom', value: 'United Kingdom'},
    {label: 'Weather', value: 'Weather'},
    {label: 'World', value: 'World'},
    {label: 'Zambia', value: 'Zambia'},
  ], type: undefined, active: false},
];

const MEDIA_OUTLETS_FILTER = [
  {id: 1, name: 'search_checkboxes', header: 'Search', filterField: 'search', text: '', type: undefined, active: false, isSearch: true, filterTypes: [
    {label: 'Name', value: 'name'},
    {label: 'Description', value: 'description'},
  ], filterFields: ['name', 'description']},
  {id: 3, name: 'search_simple', header: 'Language', filterField: 'language', text: '', active: false},
  {id: 9, name: 'checkboxes', header: 'Type', customCategoryType: 'type', allowSingleBox: true, filterTypes: [
    {label: 'Blog', value: 'Blog'},
    {label: 'Broadcaster', value: 'Broadcaster'},
    {label: 'Corporate/Government Newsroom', value: 'Corporate/Government Newsroom'},
    {label: 'Financial/Market news', value: 'Financial/Market news'},
    {label: 'Journal', value: 'Journal'},
    {label: 'Magazine', value: 'Magazine'},
    {label: 'Media Company', value: 'Media Company'},
    {label: 'Newsletter (Digital)', value: 'Newsletter (Digital)'},
    {label: 'Newsletter (Print)', value: 'Newsletter (Print)'},
    {label: 'Newspaper', value: 'Newspaper'},
    {label: 'Newswire/News Agency', value: 'Newswire/News Agency'},
    {label: 'Non-profit', value: 'Non-profit'},
    {label: 'Online/Digital', value: 'Online/Digital'},
    {label: 'OTT/Streaming', value: 'OTT/Streaming'},
    {label: 'Picture Agency', value: 'Picture Agency'},
    {label: 'Podcast', value: 'Podcast'},
    {label: 'Podcast Network', value: 'Podcast Network'},
    {label: 'Press release/News aggregator', value: 'Press release/News aggregator'},
    {label: 'Radio', value: 'Radio'},
    {label: 'Radio Program', value: 'Radio Program'},
    {label: 'Research Company/Group', value: 'Research Company/Group'},
    {label: 'Television', value: 'Television'},
    {label: 'Television Program', value: 'Television Program'},
  ], type: undefined, active: false},
];

const titleModel = types.model({
  label: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  mediaId: types.maybeNull(types.number),
});
const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  allowSingleBox: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  filterFields: types.maybeNull(types.array(types.string)),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});
const resultsModel = types.model({
  followers: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.array(titleModel)),
});
const mediaResultsModel = types.model({
  language: types.maybeNull(types.union(types.string, types.frozen({}))),
  scope: types.maybeNull(types.union(types.string, types.frozen({}))),
  country: types.maybeNull(types.union(types.string, types.frozen({}))),
  publicationType: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
});
const journalistsModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  // previous: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  results: types.maybeNull(types.array(resultsModel)),
});
const mediaListModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  // previous: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  results: types.maybeNull(types.array(mediaResultsModel)),
});
const mediaArticlesModel = types.model({
  content: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  publicationLabel: types.maybeNull(types.string),
  publicationUrl: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
const socialMediaModel = types.model({
  iconClass: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
const mediaOutletsDetailsModel = types.model({
  articles: types.maybeNull(types.array(mediaArticlesModel)),
  country: types.maybeNull(types.union(types.string, types.frozen({}))),
  description: types.maybeNull(types.string),
  descriptionSource: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
  language: types.maybeNull(types.union(types.string, types.frozen({}))),
  name: types.maybeNull(types.string),
  publicationType: types.maybeNull(types.string),
  scope: types.maybeNull(types.union(types.string, types.frozen({}))),
  website: types.maybeNull(types.string),
  socialMedia: types.maybeNull(types.array(socialMediaModel)),
  journalists: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    imageUrl: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
  }))),
});
const allMediaModel = types.model({
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
});
const employmentHistoryModel = types.model({
  createdAt: types.maybeNull(types.string),
  degree: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  emails: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  gradeLevel: types.maybeNull(types.string),
  current: types.maybeNull(types.boolean),
  id: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
  kind: types.maybeNull(types.string),
  major: types.maybeNull(types.string),
  organizationId: types.maybeNull(types.string),
  organizationName: types.maybeNull(types.string),
  rawAddress: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  Id: types.maybeNull(types.string),
});
const organizationModel = types.model({
  alexaRanking: types.maybeNull(types.number),
  angellistUrl: types.maybeNull(types.string),
  blogUrl: types.maybeNull(types.string),
  crunchbaseUrl: types.maybeNull(types.string),
  facebookUrl: types.maybeNull(types.string),
  foundedYear: types.maybeNull(types.number),
  id: types.maybeNull(types.string),
  languages: types.maybeNull(types.array(types.string)),
  linkedinUid: types.maybeNull(types.string),
  linkedinUrl: types.maybeNull(types.string),
  logoUrl: types.maybeNull(types.string),
  marketCap: types.maybeNull(types.string),
  revenue: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  personaCounts: types.maybeNull(types.frozen({})),
  primaryPhone: types.maybeNull(types.frozen({})),
  phone: types.maybeNull(types.string),
  primaryDomain: types.maybeNull(types.string),
  publiclyTradedExchange: types.maybeNull(types.string),
  publiclyTradedSymbol: types.maybeNull(types.string),
  twitterUrl: types.maybeNull(types.string),
  websiteUrl: types.maybeNull(types.string),
});
const apolloDataModel = types.model({
  city: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  emailStatus: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  githubUrl: types.maybeNull(types.string),
  facebookUrl: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  headline: types.maybeNull(types.string),
  // intentStrength: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  linkedinUrl: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  organizationId: types.maybeNull(types.string),
  photoUrl: types.maybeNull(types.string),
  seniority: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  twitterUrl: types.maybeNull(types.string),
  revealedForCurrentTeam: types.maybeNull(types.boolean),
  showIntent: types.maybeNull(types.boolean),
  employmentHistory: types.maybeNull(types.array(employmentHistoryModel)),
  organization: types.maybeNull(organizationModel),
});
const journalistDetailsModel = types.model({
  allMedia: types.maybeNull(types.array(allMediaModel)),
  articles: types.maybeNull(types.array(mediaArticlesModel)),
  biography: types.maybeNull(types.string),
  apolloDataUpdatedAt: types.maybeNull(types.string),
  apolloData: types.maybeNull(apolloDataModel),
  // categories: [],
  contactPreferences: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  followers: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  imageUrl: types.maybeNull(types.string),
  interview: types.maybeNull(types.frozen([])),
  location: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  socialMedia: types.maybeNull(types.array(socialMediaModel)),
  title: types.maybeNull(types.array(titleModel)),
  updatedAt: types.maybeNull(types.string),
});
const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});
const twitterFeedsDetailsModel = types.model({
  avatarUrl: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  handle: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
});
export const digitalPrStore = types.model({
  loadingList: types.maybeNull(types.boolean),
  journalistsDataList: types.maybeNull(journalistsModel),
  mediaDataList: types.maybeNull(mediaListModel),
  mediaOutletsDetails: types.maybeNull(mediaOutletsDetailsModel),
  journalistDetails: types.maybeNull(journalistDetailsModel),
  twitterFeedsDetails: types.maybeNull(types.array(twitterFeedsDetailsModel)),
  loadingMediaDetails: types.maybeNull(types.boolean),
  loadingJournalistDetails: types.maybeNull(types.boolean),
  loadingTwitterFeeds: types.maybeNull(types.boolean),
  loadingViewMore: types.maybeNull(types.boolean),
  mediaOutletsParams: types.maybeNull(paramsModel),
  mediaOutletsFilters: types.array(filterListModel),
  journalistFilter: types.array(filterListModel),
  journalistParams: types.maybeNull(paramsModel),
  twitterPageNumber: types.maybeNull(types.number),
  checkResponseLength: types.maybeNull(types.number),
  params: types.maybeNull(paramsModel),
}).views(self=> ({
  get getJournalistsData() {
    return toJS(self.journalistsDataList);
  },
  get getMediaDataList() {
    return toJS(self.mediaDataList);
  },
  get getMediaOutletsFilters() {
    return toJS(self.mediaOutletsFilters);
  },
  get getJournalistFilter() {
    return toJS(self.journalistFilter);
  },
  get getMediaOutletsDetails() {
    return toJS(self.mediaOutletsDetails);
  },
  get getJournalistDetails() {
    return toJS(self.journalistDetails);
  },
  get getTwitterFeedsDetails() {
    return toJS(self.twitterFeedsDetails);
  },
})).actions(self => {
  const loadJournalists = flow(function* (noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingList = true;
    }
    const filterList = toJS(self.journalistFilter);
    const formattedFilters = {
      search: filterList?.find(z => z?.name == 'search_checkboxes')?.query,
      fields: (filterList?.find(z => z?.name == 'search_checkboxes')?.filterFields as any[])?.join(','),
      followers_from: filterList?.find(z => z?.name == 'followers')?.from,
      followers_to: filterList?.find(z => z?.name == 'followers')?.to || null,
      title: filterList?.find(z => z?.name == 'search_simple' && z?.filterField == 'title')?.query,
      media_outlet: filterList?.find(z => z?.name == 'search_simple' && z?.filterField == 'media_outlet')?.query,
      category: (filterList?.find(z => z?.name == 'checkboxes')?.category as any[])?.join(','),
    };
    const payload = {
      ...self.journalistParams,
      ...formattedFilters,
    };
    try {
      const response = yield DIGITAL_PR_API.loadJournalists(payload, publicHash);
      self.journalistsDataList = cast(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });
  const loadMediaList = flow(function* (noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingList = true;
    }
    const filterList = toJS(self.mediaOutletsFilters);
    const formattedFilters = {
      search: filterList?.find(z => z?.name == 'search_checkboxes')?.query,
      fields: (filterList?.find(z => z?.name == 'search_checkboxes')?.filterFields as any[])?.join(','),
      language: filterList?.find(z => z?.name == 'search_simple')?.query,
      type: (filterList?.find(z => z?.name == 'checkboxes')?.category as any[])?.join(','),
    };
    const payload = {
      ...self.mediaOutletsParams,
      ...formattedFilters,
    };
    try {
      const response = yield DIGITAL_PR_API.loadMediaList(payload, publicHash);
      self.mediaDataList = cast(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });
  const loadJournalistDetails = flow(function* (id, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingJournalistDetails = true;
    }
    try {
      const response = yield DIGITAL_PR_API.loadJournalistDetails(id, publicHash);
      self.journalistDetails = cast(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingJournalistDetails = false;
    }
  });
  const loadTwitterFeeds = flow(function* (id, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingTwitterFeeds = true;
    }
    const payload ={
      page: self.twitterPageNumber,
    };
    try {
      const response = yield DIGITAL_PR_API.loadTwitterFeeds(id, payload, publicHash);
      if (response) {
        self.checkResponseLength = response?.length;
        if (self.twitterPageNumber == 1) {
          self.twitterFeedsDetails = cast(response);
        } else {
          if (response?.length > 4 && self.twitterFeedsDetails?.length) {
            const isNotValidDate = moment(response[4]?.date).isAfter(self.twitterFeedsDetails[self.twitterFeedsDetails?.length-1]?.date);
            if (isNotValidDate) {
              self.checkResponseLength = null;
            } else {
              const newList = toJS(self.twitterFeedsDetails) || [];
              self.twitterFeedsDetails = cast([...newList, ...response]);
            }
          } else {
            const newList = toJS(self.twitterFeedsDetails) || [];
            self.twitterFeedsDetails = cast([...newList, ...response]);
          }
        }
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingTwitterFeeds = false;
      self.loadingViewMore = false;
    }
  });
  const loadMediaDetails = flow(function* (id, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingMediaDetails = true;
    }
    try {
      const response = yield DIGITAL_PR_API.loadMediaDetails(id, publicHash);
      self.mediaOutletsDetails = cast(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingMediaDetails = false;
    }
  });
  const setMediaOutletsParams = value => {
    if (value?.search !== undefined) {
      self.mediaOutletsParams.search = value?.search;
    }
    self.mediaOutletsParams.ordering = value?.ordering;

    if (value?.type) {
      self.mediaOutletsParams.type = value?.type;
    }
    if (value?.page) {
      self.mediaOutletsParams.page = value?.page;
    }
    if (value?.pageSize) {
      self.mediaOutletsParams.page_size = value?.pageSize;
    }
    loadMediaList();
  };
  const updateMediaOutletFilter = (filters, noApiCall?: boolean) => {
    self.mediaOutletsParams.page = 1;
    self.mediaOutletsFilters = cast(filters);
    if (!noApiCall) {
      loadMediaList();
    }
  };
  const setJournalistParams = value => {
    if (value?.search !== undefined) {
      self.journalistParams.search = value?.search;
    }
    self.journalistParams.ordering = value?.ordering;

    if (value?.type) {
      self.journalistParams.type = value?.type;
    }
    if (value?.page) {
      self.journalistParams.page = value?.page;
    }
    if (value?.pageSize) {
      self.journalistParams.page_size = value?.pageSize;
    }
    loadJournalists();
  };
  const updateJournalistFilter = (filters, noApiCall?: boolean) => {
    self.journalistParams.page = 1;
    self.journalistFilter = cast(filters);
    if (!noApiCall) {
      loadJournalists();
    }
  };
  const setTwitterParams = value => {
    self.loadingViewMore = true;
    if (value?.size) {
      self.twitterPageNumber = value?.size;
    }
    loadTwitterFeeds(value?.id, true);
  };
  const restJournalistData = ()=> {
    self.twitterFeedsDetails = null;
    self.journalistDetails = null;
    self.twitterPageNumber = 1;
  };
  const restMediaOutletsData = ()=> {
    self.mediaOutletsDetails = null;
  };
  return {
    loadJournalists,
    restJournalistData,
    updateJournalistFilter,
    updateMediaOutletFilter,
    loadTwitterFeeds,
    loadMediaList,
    loadJournalistDetails,
    loadMediaDetails,
    setMediaOutletsParams,
    setJournalistParams,
    setTwitterParams,
    restMediaOutletsData,
  };
});

export const initDigitalPrStore = () => {
  return digitalPrStore.create({
    loadingList: false,
    loadingMediaDetails: false,
    loadingJournalistDetails: false,
    loadingViewMore: false,
    twitterPageNumber: 1,
    twitterFeedsDetails: null,
    params: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    journalistParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    mediaOutletsParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    mediaOutletsFilters: MEDIA_OUTLETS_FILTER,
    journalistFilter: JOURNALIST_FILTER,
  });
};
