import {siteStructureApi} from '@/api/gsc';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {cast, flow, types} from 'mobx-state-tree';
import {CriteriaType} from '@/store/gsc-store/criteria';
import {findNestedObj} from '@/utils/arrays';


const tableRowModel = types.model({
  clicksCur: types.maybeNull(types.number),
  clicksDelta: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpcCur: types.maybeNull(types.number),
  trafficValueCur: types.maybeNull(types.number),
  trafficValuePrev: types.maybeNull(types.number),
  trafficValueDelta: types.maybeNull(types.number),
  subPagesCount: types.maybeNull(types.number),
  subDirectoriesCount: types.maybeNull(types.number),
  cpcPrev: types.maybeNull(types.number),
  ctrDelta: types.maybeNull(types.number),
  currentCtr: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  parentClicksCur: types.maybeNull(types.number),
  impressionDelta: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  keywordsCountDelta: types.maybeNull(types.number),
  pageDirectory: types.maybeNull(types.string),
  pageReferringCount: types.maybeNull(types.number),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  positionDelta: types.maybeNull(types.number),
  previousCtr: types.maybeNull(types.number),
  serp13: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  topKeywordByImpressions: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  key: types.maybeNull(types.string),
  children: types.maybeNull(types.array(types.frozen({}))),
  count: types.maybeNull(types.number),
});

const paramsModel = types.model({
  page: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  parentDirectory: types.maybeNull(types.string),
});
export const SiteStructureStore = types.model({
  tableLoading: types.boolean,
  tableData: types.maybeNull(types.array(tableRowModel)),
  tableRowParams: types.maybeNull(paramsModel),
  expandedRows: types.maybeNull(types.array(types.string)),
}).views(self => ({
  get getAccountsDataV2() {
    return toJS([]);
  },
  get getTableData() {
    return toJS(self.tableData);
  },
  get getExpandedRows() {
    return toJS(self.expandedRows);
  },
})).actions(self => {
  const updateTreeData = (list: any[], key: string, children: any[]): any[] =>
    list?.map(node => {
      if (node?.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node?.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  const loadSiteStructureData = flow(function* (criteria: CriteriaType, parentUrl='', resetFirst=false, publicHash = null) {
    if (resetFirst) {
      self.tableData = null;
    }
    if (!parentUrl) {
      self.tableLoading = true;
    }
    const payload = {
      selected_property: criteria?.property,
      period1_end: criteria?.previousPeriodEnd,
      period1_start: criteria?.previousPeriodStart,
      period2_end: criteria?.currentPeriodEnd,
      period2_start: criteria?.currentPeriodStart,
      country_code: criteria?.countryCode,
      page_size: self.tableRowParams.pageSize ?? 100,
      page_number: self.tableRowParams.page ?? 1,
      ...(publicHash && {site_share_hash: publicHash}),
    };
    const prevData = toJS(self.tableData)?.length ? toJS(self.tableData) : [];
    try {
      const response = yield siteStructureApi.loadSiteStructure(payload, parentUrl);
      let key = '';
      if (!parentUrl) {
        const formatResults = prevData?.concat(response?.sitesStructureReport?.results)?.map((item, idx) => {
          return {
            ...item,
            key: `${key ? `${key}-` : ''}${idx}`,
            children: item?.children || [],
            count: response?.sitesStructureReport?.count,
            parentClicksCur: item?.clicksCur,
          };
        });
        self.tableData = cast(formatResults || []);
      } else {
        const findObj = findNestedObj(prevData, 'url', parentUrl);
        key = findObj?.key;
        const formatResults = findObj?.children?.concat(response?.sitesStructureReport?.results)?.map((item, idx) => {
          return {
            ...item,
            key: `${key ? `${key}-` : ''}${idx}`,
            children: item?.children || [],
            count: response?.sitesStructureReport?.count,
            parentClicksCur: findObj?.parentClicksCur,
          };
        });
        self.tableData = cast(updateTreeData(prevData, key, formatResults) || []);
      }
    } catch (e) {
      self.tableData = cast([]);
      notification.warning('We’re bringing you a better experience', 'You may experience slower loading times until the dashboard update is completed. Thank you for your patience.');
      return Promise.reject(e);
    } finally {
      self.tableLoading = false;
    }
  });

  const setRowParams = (data, noApiCall=false) => {
    self.tableRowParams = data;
    if (!noApiCall) {
      return loadSiteStructureData(data?.criteria, data?.parentDirectory, false, data?.publicHash);
    }
  };

  const findAndUpdateNestedObj = (entireObj, keyToFind, valToFind) => {
    const newObj = JSON.parse(JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        nestedValue = {...nestedValue, children: []};
      }
      return nestedValue;
    }));
    self.tableData = cast(newObj);
  };
  const setExpandedRows = data => {
    self.expandedRows = data;
  };

  return {
    loadSiteStructureData,
    setRowParams,
    findAndUpdateNestedObj,
    setExpandedRows,
  };
});

export const initSiteStructureStore = () => {
  return {
    tableLoading: true,
    tableRowParams: {
      page: 1,
      pageSize: 100,
      parentDirectory: '',
    },
  };
};
