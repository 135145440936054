import {Button} from '@/components/common-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faLock} from '@fortawesome/pro-solid-svg-icons';

import styled from 'styled-components';

interface Props {
  text?: string;
  small?: boolean;
  isLock?: boolean;
  disabled?: boolean;
  action?: () => any;
  loading?: boolean;
}

const CommonButton = ({text, action, small = false, isLock, disabled = false, loading = false}: Props) => {
  return (
    <ButtonWrapper>
      <Button loading={loading} disabled={disabled} className={`next-button ${small ? 'small-button' : ''}`} onClick={() => action && action()}>
        {isLock && <FontAwesomeIcon color='#ffffff' icon={faLock} className='icon-left'/>} {text || ' Confirm & Continue'} {!isLock && <FontAwesomeIcon color='#ffffff' icon={faArrowRight} className='icon-right'/>}
      </Button>
    </ButtonWrapper>
  );
};

export default CommonButton;

const ButtonWrapper = styled.div`
  margin-top: 20px;

  .next-button {
    width: 228px;
    height: 44px;
    border-radius: 8px;
    background-color: #18A640;
    color: #ffffff;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    :hover{
      background-color: #18A640;
    }
    :active {
      outline: none;
      border: none;
    };
    :focus {
      outline: none;
      border: none;
    }
    .icon-left {
      align-items: center;
      margin-right: 10px;
    }
    .icon-right {
      align-items: center;
      margin-left: 20px;
    }

    &.small-button {
      width: 142px;
    }
  }
`;
