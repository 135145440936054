import {useEffect, useState} from 'react';
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import styled from 'styled-components';

export interface SkeletonHorizontalLoaderProps {
  width?: string | number;
  height?: string | number;
  skeletonHeight?: string | number;
  align?: string;
  loadPercentage?: number;
  marginTop?: string;
  skeletonMarginTop?: string;
  skeletonMarginBottom?: string;
  skeletonMarginRight?: string;
  skeletonMarginLeft?: string;
  margin?: string;
  lightLoader?: boolean;
  containerClassName?: string;
}

export const SkeletonHorizontalLoader: React.FC<SkeletonHorizontalLoaderProps> = (
  {
    width = '70px',
    height = '300px',
    align = 'left',
    loadPercentage = 100,
    marginTop,
    margin,
  },
) => {
  return (
    <div className={styles.container} style={{height: height, justifyContent: align, marginTop: marginTop, margin: margin}}>
      <div className={styles.loadingBackground} style={{width: width}}>
        <div className={styles.loadingBar} style={{width: `${loadPercentage}%`}}></div>
      </div>
    </div>
  );
};

export const SkeletonHorizontalLoaderGray: React.FC<SkeletonHorizontalLoaderProps> = (
  {
    width = '70px',
    height = '300px',
    skeletonHeight = '5px',
    align = 'center',
    loadPercentage = 100,
    marginTop,
    skeletonMarginTop = '0px',
    skeletonMarginBottom = '0px',
    skeletonMarginRight = '0px',
    skeletonMarginLeft = '0px',
    margin,
    lightLoader = false,
    containerClassName = '',
  },
) => {
  return (
    <div className={classNames(styles.container, containerClassName)} style={{height: height, justifyContent: align, marginTop: marginTop, margin: margin, width: width}}>
      <div className={classNames(styles.loadingBackground, lightLoader && styles.lightLoadingBackground)} style={{margin: `${skeletonMarginTop} ${skeletonMarginRight} ${skeletonMarginBottom} ${skeletonMarginLeft}`, width: width}}>
        <div className={classNames(styles.grayLoadingBar, lightLoader && styles.grayLightLoadingBar) } style={{height: skeletonHeight, width: `${loadPercentage}%`}}></div>
      </div>
    </div>
  );
};

export interface AutoFillHorizontalLoaderProps {
  height?: number;
  width?: number;
  fillTime?: number;
  mode?: 'light' | 'dark';
  strokeColor?: 'purple';
  containerHeight?: number;
}

export const AutoFillHorizontalLoader = ({
  height = 6,
  width = 350,
  fillTime = 60,
  mode = 'dark',
  strokeColor = 'purple',
  containerHeight = 30,
}: AutoFillHorizontalLoaderProps) => {
  const BackgroundColorMap = {
    dark: '#4E5156',
  };

  const strokeColorMap = {
    purple: '#7F4EAD',
  };

  const [progress, setProgress] = useState<number>(0);
  let timer;
  let itteration = 0;

  const setProgressFill = () => {
    timer = !timer && setInterval(() => {
      if (itteration < fillTime * 0.9) {
        itteration++;
        setProgress(prevState => prevState + 1);
      }
    }, 1000);

    if (itteration >= fillTime * 0.9) clearInterval(timer);
  };

  useEffect(() => {
    setProgressFill();

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <MainWrapper height={containerHeight} width={width}>
    <AutoFillContainer height={height} width={width} color={BackgroundColorMap[mode]}>
      <Stroke width={width} fillTime={fillTime} progress={progress} height={height} color={strokeColorMap[strokeColor] || '#7F4EAD'}></Stroke>
    </AutoFillContainer>
  </MainWrapper>;
};

const MainWrapper = styled.div<{height: number;width: number}>`
  display: flex;
  align-items: center;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
`;
const AutoFillContainer = styled.div<{height: number; width: number; color: string}>`
  position: relative;
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  border-radius: 42px;
  background-color: ${p => p.color};
`;

const Stroke = styled.div<{height: number; width: number; color: string; fillTime: number; progress: number}>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${p => ((p.width * 0.9) / p.fillTime) * p.progress}px;
  height: ${p => p.height}px;
  background-color: ${p => p.color};
  border-radius: 42px;
`;

