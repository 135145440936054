import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import {
  ButtonStyled,
  ButtonsWrapper,
  ModalStyled,
  Heading,
  Description,
  StyledInput,
  FormItemForCreate,
  FormItem,
  FormItemForBody} from '../styledComponents';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
import {Divider, Form} from 'antd';
import {notification} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import EditorComponentV2 from '@/components/common-components/components/outreach/editor/templateEditor';
import FreezeWrapper from '../../otto-page-detail/Component/freezTooltip';
import {useRouter} from 'next/router';

export const CreateTemplateModal = observer(() => {
  const [form] = Form.useForm();
  const {
    campaigns: {
      createTempModalVisible,
      setCreateTempModalVisible,
      createTemplate,
      updateModalValues,
      setUpdateModalValues,
      getExistingTemplates,
      updateTemplate,
    },
  } = useStore('');
  const router = useRouter();
  const [editorError, setEditorError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tamplateEditorState, setTamplateEditorState] = useState('');

  const htmlToPlainText = htmlBody => {
    const doc = new DOMParser().parseFromString(htmlBody, 'text/html');
    const plainText = doc.body.textContent || '';
    return !!plainText;
  };

  const hasImage = htmlBody => {
    const doc = new DOMParser().parseFromString(htmlBody, 'text/html');
    const imageElements = doc.querySelectorAll('img');
    return imageElements.length > 0;
  };

  const onchange = value => {
    setTamplateEditorState(value);
    if (createTempModalVisible) {
      const htmlBody = value;
      if (!htmlToPlainText(htmlBody)) setEditorError(true);
      if (hasImage(htmlBody)) setEditorError(false);
      else setEditorError(false);
    } else {
      setEditorError(false);
    }
  };

  useEffect(() => {
    const {isUpdate, name, subject, body} = updateModalValues;

    if (isUpdate) {
      form.setFieldsValue({
        templateName: name,
        subjectValue: subject,
      });
      setTamplateEditorState(body);
    }
    if (!createTempModalVisible) {
      setTamplateEditorState('');
    } else {
      setEditorError(false);
    }
  }, [updateModalValues, createTempModalVisible]);

  const closeModal = () => {
    form.resetFields();
    setUpdateModalValues({
      id: null,
      name: '',
      subject: '',
      body: '',
      isUpdate: false,
    });
    setTamplateEditorState('');
    setEditorError(false);
    setCreateTempModalVisible(false);
  };

  const handleCreateTemplate = async payload => {
    setLoading(true);
    try {
      if (editorError) return;
      await createTemplate(payload);
      await getExistingTemplates();
      notification.success('', 'Template created successfully.');
      closeModal();
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateTemplate = async (payload, id) => {
    setLoading(true);
    try {
      if (editorError) return;
      await updateTemplate(payload, id);
      await getExistingTemplates();
      notification.success('', 'Template updated successfully.');
      closeModal();
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateUpdateTemplate = async values => {
    try {
      const payload = {
        name: values.templateName,
        subject: values.subjectValue,
        body: tamplateEditorState,
      };
      if (updateModalValues.isUpdate) {
        await handleUpdateTemplate(payload, updateModalValues.id);
      } else {
        await handleCreateTemplate(payload);
      }
    } catch (error) {
      notification.error('', 'Something went wrong', false, 'OK');
    }
  };

  const handleBodyValidation = () => {
    const htmlBody = tamplateEditorState;
    setEditorError(!htmlToPlainText(htmlBody));
    if (hasImage(htmlBody)) setEditorError(false);
  };

  return (
    <ModalStyled
      width={735}
      closable={true}
      closeIcon={<FontAwesomeIcon icon={faXmark} />}
      mask={true}
      style={{margin: '10px 0 0 0'}}
      visible={createTempModalVisible}
      onClose={closeModal}
    >
      <Heading>Create an email template</Heading>
      <Description>
        Create email templates for various professional and personal
        communication needs.
      </Description>

      <Form form={form} onFinish={handleCreateUpdateTemplate}>
        <FormItem
          name='templateName'
          label='Name'
          colon={false}
          style={{marginTop: '30px'}}
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
          ]}
        >
          <StyledInput
            placeholder='Template name'
            width='100%'
            height='38px'
            borderRadius='8px'
          />
        </FormItem>

        <Form.Item
          name='subjectValue'
          label='Subject'
          colon={false}
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
          ]}
        >
          <StyledInput
            placeholder='Email subject'
            width='100%'
            height='38px'
            borderRadius='8px'
          />
        </Form.Item>
        <FormItemForBody
          label={(
            <span>
              <span style={{color: '#ff4d4f', marginRight: 4}}>*</span>
                Body
            </span>
          )}
          validateStatus={editorError ? 'error' : 'success' }
          help={editorError ? 'This field is required!' : ''}
          colon={false}
        >
          <EditorComponentV2
            value={tamplateEditorState}
            onChange={onchange}
            editorError={editorError}
            style={{height: '420px', marginBottom: '40px', borderRadius: '16px'}}
            isEmailTemplate
          />
        </FormItemForBody>

        <Divider />
        <ButtonsWrapper style={{padding: '10px 0 25px 0'}} key='wrapper'>
          <ButtonStyled isCancel={true} onClick={closeModal}>
            Cancel
          </ButtonStyled>

          <FormItemForCreate>
            <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
              <ButtonStyled loading={loading} type='primary' htmlType='submit' onClick={handleBodyValidation}>
                {updateModalValues.isUpdate ? `Update template` : `Create template`}
              </ButtonStyled>
            </FreezeWrapper>
          </FormItemForCreate>
        </ButtonsWrapper>
      </Form>
    </ModalStyled>
  );
});
