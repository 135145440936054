import React, {useState} from 'react';
import {Globe} from 'react-feather';
import {Select as AntdSelect, SelectProps, Tooltip} from 'antd';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {countryFlag} from '@/utils/countries';
import {COLORS} from '@/components/common-components/v2/colors';
import {debounce} from 'lodash';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styles from './style.module.scss';
import {toJS} from 'mobx';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';

const {Option} = AntdSelect;

interface Props extends SelectProps<any>{
  variant?: 'light' | 'dark' | 'default' | 'grey';
  width?: string;
  height?: string;
  padding?: string;
  borderRadius?: string;
  marginLeft?: string;
  setSelectedCountryCode?: (value: string) => void;
  isKrtLocationSelect?: boolean;
  excludeWorldwide?: boolean;
  placeholderTextColorChange?: boolean;
  isFilter?: boolean;
  setSerpLocationId?: (value)=> void;
  isKnowledgeGraph?: boolean;
  dropDownBottomValue?:number;
  smallDropdown?: boolean;
  tooltipPosition?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'leftTop' | 'leftBottom' | 'rightTop' | 'rightBottom';
  }

export const LocationSelect = observer(({variant = 'light', setSelectedCountryCode, isKrtLocationSelect = false, excludeWorldwide = false, placeholderTextColorChange = false, isFilter = false, setSerpLocationId, isKnowledgeGraph = false, dropDownBottomValue, ...props}: Props) => {
  const [searchInputQuery, setSearchInputQuery] = useState('');
  // const [locationData, setLocationData] = useState();
  const [defaultLocations] = useState([
    {
      countryCode: 'US',
      location: 'United States',
      locationId: 2840,
    },
    {
      countryCode: 'CA',
      location: 'Canada',
      locationId: 2124,
    },
    {
      countryCode: 'AU',
      location: 'Australia',
      locationId: 2036,
    },
    {
      countryCode: 'GB',
      location: 'United Kingdom',
      locationId: 2826,
    },
    {
      countryCode: 'DE',
      location: 'Germany',
      locationId: 2276,
    },
  ]);

  // NEW FLOW START

  const {
    countries: {countriesLoading},
    contentOptimizer: {
      currentPage,
    }} = useStore('');


  const locationDebouncerChange = debounce( async (country: string) => {
    try {
      await currentPage.updateCountriesList(country);
    } catch (err) {
      return Promise.reject(err);
    }
  }, 500);

  const searchForLocation = (location?: string) => {
    if (location && location !== '') {
      locationDebouncerChange(location);
    }
  };

  // NEW FLOW END

  return ( !countriesLoading ?
    <StyledSelect
      dropDownBottomValue={dropDownBottomValue}
      {...props}
      placeholderTextColorChange = {placeholderTextColorChange}
      {...(!isFilter && {
        defaultValue: {
          label: <OptionContent isWidthDynamic={props?.dropdownMatchSelectWidth == false} isFilter={isFilter} className='select-option' variant={variant}>{countryFlag(('US').toUpperCase())} <span className={styles.locationName}>{'United States'}</span></OptionContent>,
          value: 2840,
        },
      })}
      placeholder={!isFilter ? 'Select location' : 'Location'}
      variant={variant}
      showSearch
      className={styles.selectedItemContainer}
      suffixIcon={isKnowledgeGraph ? <FontAwesomeIcon color='#000' width={30} icon={faCaretDown}/> : props?.suffixIcon || <FontAwesomeIcon color='#000' width={30} icon={faCaretDown}/>}
      dropdownStyle={{backgroundColor: variantColors[variant].backgroundColor}}
      dropdownClassName={styles.dropdownContainer}
      filterOption={false}
      labelInValue={true}
      // onSelect={value => locationSelectedHandler(value)}
      onSearch={value => {
        setSearchInputQuery(value);
        if (value) {
          searchForLocation(value);
        } else {
          currentPage.clearCountries();
        }
      }}
    >
      {isKrtLocationSelect ?
        <Option value='' style={{backgroundColor: variantColors[variant].backgroundColor, pointerEvents: 'none'}}>
          <div style={{
            backgroundColor: 'rgba(45, 108, 202, 0.2)',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            pointerEvents: 'none',
            whiteSpace: 'normal',
            borderRadius: '5px',
            gap: '7px',
          }}>
            <FontAwesomeIcon fontSize={20} icon={faCircleInfo} color='#2d6cca'/>
            <div style={{
              color: '#000',
              fontFamily: 'Inter',
              fontSize: '12px',
              lineHeight: '15px',
              fontWeight: 400,
            }}>For maximum ranking accuracy, please specify the most precise location (city, county, state or country).</div>
          </div>
        </Option> : null}
      {!searchInputQuery ? <Option value={''} style={{fontSize: 12, backgroundColor: variantColors[variant].backgroundColor, color: variantColors[variant].color, pointerEvents: 'none'}}>{(isKrtLocationSelect || isFilter) ? 'Start typing to search for locations.' : 'Select location'}</Option> : null}
      {(!searchInputQuery && !excludeWorldwide) ? <Option style={{backgroundColor: variantColors[variant].backgroundColor}} country={'Worldwide'} value={''}>
        <OptionContent isWidthDynamic={props?.dropdownMatchSelectWidth == false} isFilter={isFilter} className='select-option' style={{display: 'flex', alignItems: 'center'}} onClick={() => {
          if (typeof setSelectedCountryCode === 'function') {
            setSelectedCountryCode('');
          }
        }} variant={variant}><Globe style={{width: '16px', marginRight: 5}}/> <span className={styles.locationName}>{'Worldwide'}</span></OptionContent>
      </Option> : null}

      {!searchInputQuery ? defaultLocations.map(item => {
        return <Option style={{backgroundColor: variantColors[variant].backgroundColor}} key={item.locationId} country={item.location} value={item.location} >
          <Tooltip title={item.location} mouseEnterDelay={.6} placement={props.tooltipPosition ?? 'right'}>
            {item?.location ? <OptionContent isWidthDynamic={props?.dropdownMatchSelectWidth == false} isFilter={isFilter} className='select-option' onClick={() => {
              if (typeof setSelectedCountryCode === 'function') {
                setSelectedCountryCode(item.countryCode);
                isKnowledgeGraph && typeof setSerpLocationId == 'function' && setSerpLocationId(item.locationId);
              }
            }} variant={variant}>{countryFlag((item.countryCode).toUpperCase())} <span className={styles.locationName}>{item.location}</span></OptionContent> : null}
          </Tooltip>
        </Option>;
      }) : null}


      {(searchInputQuery && currentPage?.countries?.length) ? toJS(currentPage.countries).map((item, idx) => {
        return <Option style={{backgroundColor: variantColors[variant].backgroundColor}} key={idx} country={item.location} value={item.location}>
          <Tooltip title={item.location} mouseEnterDelay={.6} placement={props.tooltipPosition ?? 'right'}>
            {item?.location ? <OptionContent isWidthDynamic={props?.dropdownMatchSelectWidth == false} isFilter={isFilter} className='select-option' onClick={() => {
              if (typeof setSelectedCountryCode === 'function') {
                setSelectedCountryCode(item.countryCode);
                isKnowledgeGraph && setSerpLocationId(item.locationId);
              }
            }} variant={variant}>{countryFlag((item?.countryCode || '').toUpperCase())} <span className={styles.locationName}>{item.location}</span></OptionContent> : null}
          </Tooltip>
        </Option>;
      }) : null}

    </StyledSelect> :
    null);
});
const variantColors = {
  light: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    listItemsBackgroundColor: COLORS.white,
    borderColor: COLORS.grayDark,

  },

  default: {
    color: COLORS.white,
    backgroundColor: 'transparent',
    listItemsBackgroundColor: 'transparent',
    borderColor: 'transparent',

  },
  grey: {
    color: COLORS.black,
    backgroundColor: COLORS.tableGray,
    listItemsBackgroundColor: COLORS.tableGray,
    borderColor: COLORS.textGrayLight,
  },
  dark: {
    color: COLORS.white,
    backgroundColor: COLORS.grayDark,
    listItemsBackgroundColor: COLORS.grayDark,
    borderColor: COLORS.grayDark,
  }};


const StyledSelect = styled(AntdSelect)<Props>`
  .ant-select-dropdown {
    bottom: ${ p => p.dropDownBottomValue ? `${p.dropDownBottomValue}px` : 'unset'} !important;
  }
  color:${ p => variantColors[p.variant].color};
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
    /* width: ${p => p.width ? p.width : '168px'} !important; */
  .ant-select-selector {
    border: ${p => p?.style?.border == 'unset' && 'unset !important'};
    max-width:180px !important;
    min-width: ${p => p.width ? p.width : '168px'} !important;
    width: ${p => p.width ? p.width : '168px'} !important;
    height: ${p => p.height ? p.height : '38px'} !important;
    margin-left: ${p => p.marginLeft ? p.marginLeft : '6px'} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${ p => variantColors[p.variant].borderColor} !important;
    padding: ${p => p.padding ? p.padding : '6px 15px'} !important;
    cursor: pointer !important;
    line-height: unset !important;
    @media only screen and (max-width: 443px) {
      width: 100% !important;
    }
  }
  .ant-select-selection-search-input {
    margin-top: -5px !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }
 .ant-select-arrow {
  color:${ p => variantColors[p.variant].color};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${ p => variantColors[p.variant].borderColor} !important
  }
  .ant-select-selection-item {
    
    .locationName {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      width: 100px;
    }
  }
  .ant-select-selection-placeholder {
    color: ${ p => p.placeholderTextColorChange && `#4E5156 !important`}
  }
`;


const OptionContent = styled.div<{variant: string; isFilter?: boolean; isWidthDynamic: boolean}>`
  background-color: ${ p => variantColors[p.variant].listItemsBackgroundColor} !important;
  border-color: ${ p => variantColors[p.variant].borderColor} !important;
  color:${ p => variantColors[p.variant].color} !important;
  max-width: 500px;
  min-width: ${p => p.isWidthDynamic ? 'unset' : p?.isFilter ? '150px' : '300px'};
  height: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  line-height: 25px;
  font-weight: 400;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${p => p.isWidthDynamic ? '90%' : '100px'};
  vertical-align: middle;

  .locationName {
    display: inline-block !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 100px;
  }

  img {
    margin-top: 0px;
    margin-right: 5px;
  }
    &:active,focus{
      background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
      border-color: ${ p => variantColors[p.variant].borderColor} !important;
      color:${ p => variantColors[p.variant].color} !important;
    }
`;
