import {observer} from 'mobx-react';
import {SingleAiOutline} from './SingleAiOutline';

interface Props {
  outlines?: string[];
}

export const FirstDraftResults = observer(({outlines}: Props) => {
  let htmlToDraft = null;
  if (typeof window === 'object') {
    htmlToDraft = require('html-to-draftjs').default;
  }

  return <>
    {outlines?.map((aiOutline, idx) => {
      return <SingleAiOutline key={idx} outlineId={idx} aiOutline={aiOutline} htmlToDraft={htmlToDraft}/>;
    })}
  </>;
});
