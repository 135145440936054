import {observer} from 'mobx-react';
import React, {useEffect} from 'react';
import {Col, Empty, Row} from 'antd';
import {CampaignHeader} from '../../header';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faGear, faPause} from '@fortawesome/pro-solid-svg-icons';
import {Button, SkeletonHorizontalLoaderGray, SkeletonRingLoader} from '@/components/common-components/components';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {
  CampaignSettings,
  HeroSection,
  OverviewWrapper,
  SettingsText,
  Box,
  HistoryCardWrapper,
  HistoryRow,
  ColHeading,
  OutletTextWrapper,
  Paused,
  ConfigureOutreach,
  OpportunitiesWrapper} from '../styledComponents';
import {OpportunityChart} from './chartSection';
import {useRouter} from 'next/router';
import {formatLargeNumber, handleNumberFormat} from '@/utils/number';
import {CreateCampaignModal} from '../../createCampaignModal';
import FreezeWrapper from '../../../otto-page-detail/Component/freezTooltip';

interface OpportunityHeaderProps {
  isInOTTO?: boolean;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
}

export const OpportunityHeader: React.FC<OpportunityHeaderProps> = observer(({isInOTTO, campaignsId, setCampaignsId}) => {
  const {campaigns: {
    getOpportunityCount,
    setShowConfigureModal,
    chartData,
    toogleStatus,
    toogleStatusLoading,
    chartDataLoading,
    setCreateModalVisible,
    getCampaignBlockList,
    getGlobalBlockList,
    getFolders,
    getCampaignSettings,
    campaignSettings,
    campaignRepollingLoading,
  },
  } = useStore('');
  const router = useRouter();
  const id = isInOTTO ? campaignsId : router?.query?.id ? router?.query?.id[0] : '0';

  useEffect(() => {
    getCampaignBlockList(id);
    getGlobalBlockList();
    getFolders();
    getCampaignSettings(+id);
  }, [id]);

  function getDateDifference(dateString: string) {
    const targetDate = new Date(dateString);
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate.getTime() - targetDate.getTime();

    // Convert milliseconds to seconds, minutes, hours, and days
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    let result;

    if (days > 0) {
      result = `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      result = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      result = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else {
      result = `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
    return result;
  }
  const HeroSectionOTTOStyle = {
    backgroundColor: 'transparent',
    color: '#000000',
    padding: '0px 40px',
  };


  return <HeroSection minHeight={isInOTTO ? '550px' : '630px'} style={isInOTTO ? HeroSectionOTTOStyle : {}}>
    <Row justify='space-between'>
      <CampaignHeader
        pageHeading={chartData?.name}
        campaignSettings={<FreezeWrapper placement='topRight' removeTooltip={chartData?.isActive || !isInOTTO}>
          <CampaignSettings className={isInOTTO ? 'otto': ''} onClick={() => setCreateModalVisible(true, true)}><FontAwesomeIcon icon={faGear}/>{' '}<SettingsText>Edit campaign</SettingsText></CampaignSettings></FreezeWrapper>}
        detailOverview={<OverviewWrapper className={isInOTTO ? 'otto': ''}>
          <Box>
            <div>{('Outreach opportunities').toUpperCase()}</div>
            {chartDataLoading || campaignRepollingLoading==(+id) ?
              <>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={53}/>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={93}/>
              </> :
              <h3>{formatLargeNumber(campaignSettings?.outreachOpportunities) ?? '-'}</h3>}
          </Box>
          <Box>
            <div>{('Emailed').toUpperCase()}</div>
            {chartDataLoading || campaignRepollingLoading==(+id) ?
              <>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={53}/>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={93}/>
              </> :
              <h3>{formatLargeNumber(chartData?.sentEmails)}</h3>}
          </Box>
          <Box>
            <div> {('Replied').toUpperCase()}</div>
            {chartDataLoading || campaignRepollingLoading==(+id) ?
              <>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={53}/>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={93}/>
              </> :
              <h3>{formatLargeNumber(chartData?.repliedEmails)}</h3>}
          </Box>
          <Box>
            <div>{('Response rate').toUpperCase()}</div>
            {chartDataLoading || campaignRepollingLoading==(+id) ?
              <>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={53}/>
                <SkeletonHorizontalLoaderGray skeletonHeight={8} height={21} width={93}/>
              </> :
              <h3>{handleNumberFormat(chartData?.emailResponseRate ? chartData?.emailResponseRate : 0)}{chartData?.emailResponseRate > 0 ? '%' : ''}</h3>}
          </Box>
        </OverviewWrapper>}
        historyCard={<HistoryCardWrapper className={isInOTTO ? 'otto': ''}>
          <HistoryRow gutter={24}>
            <Col style={{paddingRight: 0, marginRight: '-40px'}} sm={6}>
              <ColHeading className={isInOTTO ? 'otto': ''}>Outreach status</ColHeading>
              <OutletTextWrapper>
                {chartData?.isActive ?
                  <div className={isInOTTO ? 'otto': ''}><Paused>Running</Paused><FontAwesomeIcon style={{marginLeft: '6px'}} icon={faCircle} color='#2AC155' fontSize={10}/>&nbsp;{getDateDifference(chartData?.createdAt)}</div> :
                  <div className={isInOTTO ? 'otto': ''}><Paused>Paused</Paused><FontAwesomeIcon style={{marginLeft: '6px'}} icon={faCircle} color='#FF8536' fontSize={10}/></div>}
                <FreezeWrapper removeTooltip={chartData?.isActive || !isInOTTO}><ConfigureOutreach onClick={() => setShowConfigureModal(true)}>Configure outreach</ConfigureOutreach></FreezeWrapper>
                {chartData?.isActive ?
                  <div>
                    <Button
                      size='sm'
                      style={{
                        backgroundColor: 'rgba(255, 133, 54)',
                        padding: '7px 15px 8px 15px',
                        fontSize: '14px'}}
                      loading={toogleStatusLoading}
                      onClick={() => toogleStatus(Number(id), 'opportunity')}>{!isInOTTO ?
                        <><FontAwesomeIcon color='#FF8536' icon={faPause}/>&nbsp;&nbsp;</>: <></>
                      }Pause outreach</Button>
                  </div> :
                  <div>
                    <FreezeWrapper removeTooltip={chartData?.isActive || !isInOTTO}>
                      <Button
                        size='sm'
                        buttonType='green-dark'
                        style={{
                          backgroundColor: isInOTTO ? '#2ac155' : '',
                          fontSize: '14px'}}
                        loading={toogleStatusLoading}
                        onClick={() => toogleStatus(Number(id), 'opportunity')}> {!isInOTTO ? <FontAwesomeIcon color='#2ac155' icon={faPlay}/> : <></>} Start outreach</Button>
                    </FreezeWrapper></div>}
              </OutletTextWrapper>
            </Col>
            <Col sm={18}>
              <ColHeading className={isInOTTO ? 'otto': ''}>History</ColHeading>
              {chartDataLoading || campaignRepollingLoading==(+id) ? <SkeletonRingLoader/> : chartData?.emailTrend?.length ? <OpportunityChart isInOTTO={isInOTTO}/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{color: '#A3A4A4'}}/>}
            </Col>
          </HistoryRow>
        </HistoryCardWrapper>}
        opportunities={!isInOTTO ? <OpportunitiesWrapper>{getOpportunityCount} connect opportunities found</OpportunitiesWrapper>: <></>}
        pageSubHeading=''
        isBackIcon={true}
        showCreateBtn={false}
        showManage={false}
        isInOTTO={isInOTTO}
        setCampaignsId={setCampaignsId}
      />
    </Row>
    <CreateCampaignModal isInOTTO={isInOTTO}/>
  </HeroSection>;
});
