import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import ReactEcharts from 'echarts-for-react';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {Checkbox} from 'antd';
import styled from 'styled-components';
import moment from 'moment';

interface EmailData {
  date: string;
  emailReplied: number;
  emailSent: number;
}
interface OpportunityChartProps {
  isInOTTO?: boolean;
}

export const OpportunityChart = observer(({isInOTTO}: OpportunityChartProps) => {
  const {campaigns: {chartData}} = useStore('');
  const [datesList, setDatesList] = useState([]);
  const [emailSentList, setEmailSentList] = useState([]);
  const [emailRepliedList, setEmailRepliedList] = useState([]);

  const [activeSeries, setActiveSeries] = useState({
    sent: true,
    replied: true,
  });

  const chartSeriesNames = [
    {
      name: 'Emails sent',
      keyName: 'sent',
      color: isInOTTO ? '#121212' : '#4E5156',
    },
    {
      name: 'Replies received',
      keyName: 'replied',
      color: isInOTTO ? '#2D6CCA' : '#34AEF3',
    },
  ];

  const hideAndShowSeries = key => {
    setActiveSeries({...activeSeries, [key]: activeSeries[key] == false});
  };

  const getSeries = () => {
    return [
      (activeSeries.replied && {
        name: 'Replies received',
        type: 'bar',
        stack: 'Ad',
        emphasis: {
          focus: 'series',
        },
        data: emailRepliedList,
        itemStyle: {
          color: isInOTTO ? '#2D6CCA' : '#34AEF3',
          ...(!activeSeries.sent && activeSeries.replied && {borderRadius: [4, 4, 0, 0]}),
        },
        barWidth: 18,
      }),
      (activeSeries.sent && {
        name: 'Emails Sent',
        type: 'bar',
        stack: 'Ad',
        emphasis: {
          focus: 'series',
        },
        data: emailSentList,
        itemStyle: {
          color: isInOTTO ? '#D6E0E7':'#4E5156',
          borderRadius: [4, 4, 0, 0],
        },
        barWidth: 18,
      }),
    ];
  };

  function extractDay(dateString: string): string | null {
    if (dateString) {
      const date = moment(dateString, 'YYYY-MM-DD');
      const formattedDate = date.format('DD MMM YYYY');

      return formattedDate;
    } else {
      return null;
    }
  }

  function extractData(emailData: EmailData[]): { dates: string[]; emailSent: number[]; emailReplied: number[] } {
    const dates: string[] = [];
    const emailSent: number[] = [];
    const emailReplied: number[] = [];

    emailData.forEach(entry => {
      const day = extractDay(entry.date);
      dates.push(day);
      emailSent.push(entry.emailSent);
      emailReplied.push(entry.emailReplied);
    });

    return {dates, emailSent, emailReplied};
  }

  useEffect(() => {
    if (chartData) {
      const extractedData = extractData(toJS(chartData?.emailTrend));
      setDatesList(extractedData.dates);
      setEmailSentList(extractedData.emailSent);
      setEmailRepliedList(extractedData.emailReplied);
    }
  }, [chartData]);

  const chartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      show: false,
    },
    grid: {
      top: '10%',
      left: '1%',
      right: '0%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: datesList,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: isInOTTO ? 'gray' : 'white',
        },
      },
    ],
    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dotted',
            width: 0.5,
          },
        },
        axisLabel: {
          show: false,
        },
        type: 'value',
      },
    ],
    series: getSeries(),
  };

  return <div>
    {chartSeriesNames?.map(item => <CheckboxStyled className={isInOTTO ? 'otto': ''} onClick={() => hideAndShowSeries(item?.keyName)} key={item?.keyName} color={item?.color} defaultChecked={true} checked={activeSeries[item.keyName]}>{item?.name}</CheckboxStyled>)}
    <ReactEcharts
      style={{width: '100%', height: 150}}
      option={chartOptions}
      notMerge={true}
      lazyUpdate={true}/>
  </div>;
});

const CheckboxStyled = styled(Checkbox)<{color}>`
  color: white;
  &.otto {
    color: #121212;
  }
  .ant-checkbox-inner {
      background-color: #4E5156;
      border-color: #4E5156;
      border-radius: 4px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
      background-color:${p => p.color};
      border-color: ${p => p.color};
      border-radius: 4px;
  }
`;
