import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {notification} from '@/utils/notifications';
import {useTranslation} from 'next-i18next';
import {profileApi} from '@/api/account';
import {MixPanel} from '@/utils/mixpanel';
import {DOMAIN_ANALYZER_REGISTRATION_CONFIRMED} from '@/constants/events';

interface Props {
  email: string;
  onBadRequestError?: (error) => void;
  checkVerifiedStatus?: () => void;
  notVerified?: boolean;
}

export const ConfirmEmailModal:React.FC<Props> = ({email='', onBadRequestError, checkVerifiedStatus, notVerified=false}) => {
  const {t} = useTranslation('common');
  const [code, setCode] = useState('');
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);
  const [resendCode, setResendCode] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown(countDown => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setResendCode(true);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const sendActivationCode = async () => {
    try {
      const {success} = await profileApi.resendVerificationEmail(email);
      if (success) {
        notification.success(t('check-inbox-verification'));
        setRunTimer(true);
        setResendCode(false);
      }
    } catch (error) {
      const status = error.response?.status || 200;
      if (status === 400 || status === 409) {
        onBadRequestError(error);
      } else {
        notification.error('Something went wrong. Try again later.');
      }
    }
  };

  const verifyActivationCode = async () => {
    if (code !== '') {
      setLoader(true);
      try {
        const {success, response} = await profileApi.verifyVerificationEmail(email, code);
        if (success) {
          MixPanel.track(DOMAIN_ANALYZER_REGISTRATION_CONFIRMED, {
            'user_email': email,
          });
          checkVerifiedStatus();
        }
        if (!response.data.success) {
          notification.error('Invalid code, Please try again.');
        }
      } catch (error) {
        const status = error.response?.status || 200;
        if (status === 400 || status === 409) {
          onBadRequestError(error);
        } else {
          notification.error('Something went wrong. Try again later.');
        }
      } finally {
        setLoader(false);
      }
    } else {
      notification.error('Verification code is required');
    }
  };

  const seconds = String(countDown % 60).padStart(2, '0');
  const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');
  return (
    <MainContainer style={{borderRadius: '8px'}}>
      <LargeText>Confirm your email</LargeText>
      <DescriptionWrapper style={{marginBottom: '2px'}}>A verification code has been sent to you at&nbsp;<b>{email}</b></DescriptionWrapper>
      <DescriptionWrapper>Enter the code below:</DescriptionWrapper>
      <CodeAreaStyled placeholder='6 digit code' value={code} onChange={e => e.target?.value.length<=6 && setCode(e.target?.value)}/>
      {resendCode ? <ResendText onClick={sendActivationCode}>Resend code</ResendText> : <DescriptionWrapper>You can resend the code in {minutes}:{seconds}</DescriptionWrapper>}
      <StyledButton onClick={verifyActivationCode} loading={loader} color='purple'>Confirm email&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRight} color='#fff'/></StyledButton>
      {notVerified ? <NotVerified>{'Email was not verified.'}<br />{'Please check your inbox and try again.'}</NotVerified> : ''}
    </MainContainer>
  );
};

const MainContainer = styled.div`
width: 585px !important;
`;
const LargeText = styled.div`
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: #121212;
margin-top: 28px;
margin-bottom: 7px;
`;
const ResendText = styled.div`
  color: #2D6CCA;
  cursor: pointer;
  font-weight: 400;
display: flex;
font-size: 14px;
line-height: 22px;
margin-bottom: 21px;
`;
const DescriptionWrapper = styled.div`
font-weight: 400;
display: flex;
font-size: 14px;
line-height: 22px;
color: #4E5156;
margin-bottom: 21px;
`;
const CodeAreaStyled = styled.input`
 width: 135px;
height: 44px;
left: 453px;
top: 475px;
background: #FFFFFF;
border: 1px solid #CDCDCD;
box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.02);
padding-left: 13px;
border-radius: 8px;
margin-bottom: 11px;
outline: none;
&::placeholder{
  color: #A3A4A4;
}
`;
const StyledButton = styled(Button)`
width: 270px;
height: 44px;
box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.02);
border-radius: 8px;
margin-top: 4px;
display: flex;
justify-content: center;
margin-left: 0;
`;

const NotVerified = styled.div`
  color: #f44343;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
`;
