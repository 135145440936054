import {memo} from 'react';
import {IFocusTermToClassNameMap} from '@/store/content-optimizer/currentPage';
import {DraftDecorator, ContentBlock, ContentState} from 'draft-js';
import {StrategyCallbackType} from './types';
import {highlightKeywordRegex} from '@/constants/regex';
import {useStore} from '@/store/root-store';

const highhlightingStrategyHandler = (
  focusClassNames: IFocusTermToClassNameMap,
) => {
  return (
    contentBlock: ContentBlock,
    callback: StrategyCallbackType,
    // eslint-disable-next-line
    contentState?: ContentState,
  ) => findWithRegex(focusClassNames, contentBlock, callback);
};

const findWithRegex = (
  focusClasses: IFocusTermToClassNameMap,
  contentBlock: ContentBlock,
  callback: StrategyCallbackType,
) => {
  const text = contentBlock.getText().toLocaleLowerCase();

  Object.keys(focusClasses).forEach(keyword => {
    const regex = highlightKeywordRegex(keyword);

    let matchArr: RegExpExecArray;
    let start: number;
    while ((matchArr = regex.exec(text)) !== null) {
      const spacePrefix = matchArr[1];
      const match = matchArr[2];
      const spaceSuffix = matchArr[3];
      start = matchArr.index + spacePrefix.length;
      const length = match.length;
      callback(start, start + length);
      if (length) {
        regex.lastIndex -= spaceSuffix.length;
      }
    }
  });
};

const HighlightFocusTerm = memo(
  ({highlights, decoratedText, children}: any) => {
    const {contentOptimizer: {currentPage}} = useStore('');
    const searchKey = decoratedText.toLocaleLowerCase();
    if (currentPage?.highlights) {
      return <span className={highlights?.[searchKey]}>{children}</span>;
    }
    return <span>{children}</span>;
  },
);

export const createHighlightDecorator = (
  focusClasNames: IFocusTermToClassNameMap,
): DraftDecorator => {
  return {
    strategy: highhlightingStrategyHandler(focusClasNames),
    component: HighlightFocusTerm,
    props: {
      highlights: focusClasNames,
    },
  };
};
