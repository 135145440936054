import {siteAuditorApi} from '@/api/account';
import {cast, flow, types, Instance} from 'mobx-state-tree';

export const ResultsData = types.model({
  position: types.maybeNull(types.number),
  avgSpeedIndex: types.maybeNull(types.number),
  mobileSpeedIndex: types.maybeNull(types.number),
  desktopSpeedIndex: types.maybeNull(types.number),
  pagegroups: types.maybeNull(types.string),
  statusCode: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  clicks: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  prunable: types.maybeNull(types.boolean),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  indexability: types.maybeNull(types.boolean),
  indexable: types.maybeNull(types.boolean),
});

export type PruningPageData = Instance<typeof ResultsData>;

export const Pages = types.model({
  contentScore: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  issueCount: types.maybeNull(types.number),
  robotsTag: types.maybeNull(types.boolean),
  technicalScore: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  clicks: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  // position: types.maybeNull(types.number),
  avgSpeedIndex: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
  statusCode: types.maybeNull(types.number),
  prunable: types.maybeNull(types.boolean),
  clickThroughRate: types.maybeNull(types.number),
  health: types.maybeNull(types.number),
  indexable: types.maybeNull(types.boolean),
  speedIndex: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
});

export const Overview = types.model({
  totalPrunablePages: types.maybeNull(types.number),
  totalPrunablePagesPct: types.maybeNull(types.number),
  slowPages: types.maybeNull(types.number),
  slowestPageValue: types.maybeNull(types.number),
  lowImpressions: types.maybeNull(types.number),
  lowTechnicalScore: types.maybeNull(types.number),
  lowestImpressionsValue: types.maybeNull(types.number),
  lowestWordCount: types.maybeNull(types.string),
  lowestWordCountValue: types.maybeNull(types.number),
});

export const PruningPageOverview = types.model({
  pages: types.maybeNull(types.array(Pages)),
  overview: types.maybeNull(Overview),
});

export const Pruning = types.model({
  pruningData: types.maybeNull(types.array(ResultsData)),
  totalCount: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  overview: types.maybeNull(PruningPageOverview),
  loading: types.boolean,
  loadingOverview: types.boolean,
})
  .views(self => ({
    get getPruningData() {
      return self.pruningData;
    },
    get getPruningOverview() {
      return self.overview;
    },
  }))
  .actions(self => {
    const loadPruningData = flow(function* (audit) {
      self.loading = true;
      try {
        const response = yield siteAuditorApi.getPruningPage(audit);
        self.pruningData = cast(response?.results);
        self.totalCount = cast(response?.count);
        self.pageSize = cast(response?.pageSize);
        self.totalPages = cast(response?.totalPages);
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const loadPruningOverview = flow(function* (auditId: number) {
      self.loadingOverview = true;
      self.overview = null;

      try {
        const response = yield siteAuditorApi.getPruningPageOverview(auditId);
        self.overview = cast(response);
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.loadingOverview = false;
      }
    });

    return {
      loadPruningData,
      loadPruningOverview,
    };
  });

export const initPruning = () => {
  return Pruning.create({
    loading: true,
    loadingOverview: true,
  });
};
