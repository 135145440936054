import {notification} from '@/utils/notifications';

export const copyToClipboardWithHtmlAndText = (htmlContent, plainTextContent) => {
  // Create a data transfer object
  const clipboardData = new DataTransfer();

  // Add the HTML representation to the clipboard
  clipboardData.setData('text/html', htmlContent);

  // Add the plain text representation to the clipboard
  clipboardData.setData('text/plain', plainTextContent);

  // Use the Clipboard API to write to the clipboard
  // eslint-disable-next-line
  navigator.clipboard.write([new ClipboardItem({
    'text/html': new Blob([htmlContent], {type: 'text/html'}),
    'text/plain': new Blob([plainTextContent], {type: 'text/plain'}),
  })]);
};

export const copyToClipBoard = (textToCopy: string) => {
  navigator.clipboard.writeText(textToCopy).then(function() {
    notification.success('Copied!');
  }, function(err) {
    notification.error('Error', err);
  });
};
