import styles from '@/components/layoutV2/header/style.module.scss';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';

import {CaretDownOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import {SelectWithSearchSiteAudit} from '@/components/common-components';
import {copyAndModifyQueryParams, getDomain, getSingleUrlParam, isNavbarRemoveOnPublicPages, updateParams} from '@/utils/url';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBan, faImageSlash, faPause} from '@fortawesome/pro-solid-svg-icons';
import {truncate} from '@/utils/string';
import {NextImg} from '@/utils/nextImg';
import {openLiveChat} from '@/utils/intercom';

interface Props {
  isDockedSider?: boolean;
  setDropDownSa?: (value)=>void;
  isDropDownSa?: boolean;

}

export const checkSaState = item => {
  const getPercentage = (val1, val2) => {
    if (val1 && val2) {
      return val1 / val2 * 100;
    } else {
      return 0;
    }
  };
  if (item?.status == 'processing' && !item?.isFirstCrawl) {
    return <div style={{display: 'flex', position: 'relative', width: '100%'}}>
      <Crawl>Estimated Time: &nbsp;&nbsp;{item.estimatedTimeleft ? item.estimatedTimeleft: '-' }</Crawl>
      <div style={{position: 'absolute', top: '-12px', right: '2px'}}>
        <LoadingOutlined style={{fontSize: '15px', marginRight: 'auto', color: ' #7F4EAD'}}/>
      </div>
    </div>;
  } else if (item?.status == 'processing' && item?.isFirstCrawl) {
    return <div style={{display: 'flex', position: 'relative', width: '100%'}}>
      <Crawl>Last crawl: &nbsp;&nbsp;{item.lastCrawled}</Crawl>
      <div style={{position: 'absolute', top: '-13px', right: '2px'}}>
        <Tooltip color='#121212' title={
          <>
            <div>
              <TooltipMessage>This site is being recrawled. You can still view the data from the latest crawl.</TooltipMessage>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TooltipEstiMateTime>Estimated time left:</TooltipEstiMateTime>
                <TooltipEstiMateTime>{item?.estimatedTimeleft || '-'}</TooltipEstiMateTime>
              </div>
              <TooltipProcessingBar >
                <InnerBar style={{background: '#7F4EAD', maxWidth: `${getPercentage(item?.progressBar, 100 )}%`}}> </InnerBar>
              </TooltipProcessingBar>
            </div>
          </>
        } placement='bottomLeft' overlayInnerStyle={{width: '276px', borderRadius: '5px'}}><LoadingOutlined style={{fontSize: '15px', marginRight: 'auto', color: ' #7F4EAD'}}/></Tooltip>
      </div>
    </div>;
  } else if (item?.status == 'failed') {
    const errorString= "Crawl failed. We'll recrawl this site soon";
    return <div style={{display: 'flex', position: 'relative', width: '100%'}}>
      <Crawl>Last crawl: &nbsp;&nbsp;{item.lastCrawled}</Crawl>
      <div style={{position: 'absolute', top: '-13px', right: 0}}>
        <Tooltip color='#121212' title={
          <>
            <div style={{paddingLeft: '10px', paddingBottom: '7px', paddingTop: '7px', fontSize: '12px', fontWeight: 500, lineHeight: '15px'}} >
              { item?.crawlErrorMessage ? `${truncate(item?.crawlErrorMessage, 40)}` : <span title={errorString.length>37 ? errorString : ''}>{`${truncate(errorString, 35)}`}</span>} <br/>
              Learn how to grant access to your website <a href='https://linkgraph.com/blog/whitelist-monitoring-on-cloudflare/' target='_blank' rel='noreferrer' style={{textDecoration: 'underline'}}>here</a>. Contact us  &nbsp;
              <ChatLink onClick={() => openLiveChat()}> via chat</ChatLink>
            </div> </>
        } placement='bottomLeft' overlayInnerStyle={{borderRadius: '5px', width: '240px'}}> <FontAwesomeIcon style={{fontSize: 14, color: '#F44343'}} icon={faBan} /></Tooltip>
      </div>
    </div>;
  } else if (item?.status == 'stuck') {
    return <div style={{display: 'flex', position: 'relative', width: '100%'}}>
      <Crawl>Last crawl: &nbsp;&nbsp;{item.lastCrawled}</Crawl>
      <div style={{position: 'absolute', top: '-13px', right: '1px'}}>
        <Tooltip color='#121212' title={
          <>
            <TooltipStuckMessagge> This site will start recrawling soon. You can still view the data from the latest crawl. </TooltipStuckMessagge>
          </>
        } placement='bottomLeft' overlayInnerStyle={{borderRadius: '5px', width: '256px', height: '68px'}}> <FontAwesomeIcon style={{fontSize: 14, color: '#A3A4A4'}} icon={faPause} /></Tooltip>
      </div>
    </div>;
  } else {
    return <div>
      <Crawl>Last crawl: &nbsp;&nbsp;{item.lastCrawled}</Crawl>
    </div>;
  }
};

export const optionRenderSa = (option: any) => {
  const antIcon = <LoadingOutlined style={{fontSize: 24, color: '#F1AA3E'}} spin />;
  // const addDefaultSrc = e => {
  //   e.target.src = '/icons/image-slash-solid.svg';
  // };
  return (
    <div style={{display: 'flex', width: '100%'}}>

      <div style={{marginRight: 8}}>
        {option?.propertyUrl ? <NextImg style={{height: 35, width: 35}}
          // onError={e => addDefaultSrc(e)}
          src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${option?.propertyUrl && getDomain((!/^(https?:)?\/\//i.test(option?.propertyUrl)) ? 'https:' + option?.propertyUrl : option?.propertyUrl)}&size=64`}/> :
          <div style={{width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <FontAwesomeIcon icon={faImageSlash} style={{fontSize: '20px', color: '#4E5156'}}/>
          </div>
        }
      </div>
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <div style={{width: '100%'}}>
          <div style={{fontWeight: 500, fontSize: '14px', color: '#FFFFFF'}}>{(option?.propertyUrl)}{option?.gscConnected ? <GscTag>{'GSC'}</GscTag> : ''}</div>
          {/* <div style={{display:'flex', width: '100%', justifyContent: 'space-between'}}> */}
          {/* <div>{option.status == 'failed' ? <span style={{color: '#F44343'}}>Failed</span> : (option.processingStatus == 'processing' || option?.processingStatus == null ? 'Procesing can take up to 10 minutes.' : option?.lastCrawled ?? '-')}</div> */}
          {checkSaState(option)}
          {/* </div> */}
        </div>
        {option.status == 'processing' || option?.status == null && <span style={{paddingTop: '5px'}}> <Spin indicator={antIcon} /></span>}
      </div>

    </div>
  );
};

export const RenderSaProjectSelect: React.FC<Props> = observer(({isDockedSider, isDropDownSa, setDropDownSa}) => {
  const {t} = useTranslation('common');
  const router = useRouter();
  const {
    siteAuditorV2: {
      getAuditSitesListBasic,
      setHealthState,
      resetPageExplorerParams,
      setParamsBasic,
      // loadingSaDropdownList,
      setAuditedSiteDetailRepolling,
      siteAuditSelectedProperty,
      updateSelectedSiteSA,
      setSpecificIDResponse,
      setAuditedListRepollingBasic,
      loadAuditedSitesListBasic,
      getSiteAuditSpacificData,
    },
  } = useStore('');
  const id = getSingleUrlParam('id');
  const [projectState, setProjectState] = useState('');
  const popupRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        setDropDownSa(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupRef]);

  useEffect(() => {
    const audit = getSingleUrlParam('audit');
    if (!id && !audit && siteAuditSelectedProperty) {
      router.push(`${copyAndModifyQueryParams([{key: 'id', value: siteAuditSelectedProperty}])}`, undefined, {shallow: true});
    }
  }, [id, siteAuditSelectedProperty]);

  useEffect(() => {
    // siteAuditSelectedProperty is empty when user first login, so condition pass once only when user have data in getAuditSitesListBasic
    if (!siteAuditSelectedProperty && getAuditSitesListBasic?.length && !id) {
      updateSelectedSiteSA(getAuditSitesListBasic[0]?.id);
      setSpecificIDResponse(getAuditSitesListBasic[0]?.id);
    }
  }, [getAuditSitesListBasic?.length, id]);

  useEffect(() => {
    const getSiteId = getSingleUrlParam('audit');
    const getUrlId = getSingleUrlParam('id');
    const localStorageId = localStorage.getItem('lastPropertySiteAudit');
    if (getUrlId) {
      updateSelectedSiteSA(getUrlId);
      setSpecificIDResponse(getUrlId);
    } else if (getSiteId) {
      updateSelectedSiteSA(getSiteId);
      setSpecificIDResponse(getSiteId);
    } else if (localStorageId && getAuditSitesListBasic?.some(z => `${z?.id}` == localStorageId)) {
      updateSelectedSiteSA(localStorageId);
      setSpecificIDResponse(localStorageId);
    } else if (getAuditSitesListBasic?.length) {
      updateSelectedSiteSA(getAuditSitesListBasic[0]?.id);
      setSpecificIDResponse(getAuditSitesListBasic[0]?.id);
    }
  }, [router.query]);

  const onPropertyChange = (value: string) => {
    const findHash = getAuditSitesListBasic?.find(z => `${z?.id}` == value)?.publicShareHash;
    setAuditedSiteDetailRepolling(false);
    if (value) {
      if ('domain' in router.query) {
        delete router.query['domain'];
      }
      if (!('id' in Object.keys(router.query))) {
        router.query['id']= value;
      }
      const oldParams = {
        ...router.query,
        public_hash: findHash,
      };
      const params = updateParams(isNavbarRemoveOnPublicPages() ? oldParams : {...router.query}, 'id', value);
      updateSelectedSiteSA(value);
      resetPageExplorerParams();
      router.push(
        `${location.pathname}?${params}`,
        undefined,
        {shallow: true},
      );
    }
  };
  const valueId = getSiteAuditSpacificData?.propertyUrl;
  // const valueId = getDomain(getAuditSitesListBasic?.find(item => item?.id == siteAuditSelectedProperty)?.propertyUrl) || '';

  const siteHealthData = getAuditSitesListBasic?.find(item => item?.id == siteAuditSelectedProperty)?.siteHealthValues;

  useEffect(() => {
    if (valueId) {
      setProjectState(valueId);
      setHealthState(siteHealthData);
    }
  }, [siteAuditSelectedProperty, getAuditSitesListBasic?.length]);


  useEffect(() => {
    if (!getAuditSitesListBasic?.length || !valueId) {
      loadAuditedSitesListBasic();
    }
  }, [getAuditSitesListBasic?.length, valueId]);

  useEffect(() => {
    setAuditedListRepollingBasic(true);
    return () => {
      setParamsBasic({search: '', noApiCall: true});
      setAuditedListRepollingBasic(false);
    };
  }, []);

  // const addDefaultSrc = e => {
  //   e.target.src = '/icons/image-slash-solid.svg';
  // };

  const [timer, setTimer] = useState(null);

  function onSearchHandler(value) {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setParamsBasic({search: value, page_size: 100});
      }, 600),
    );
  }

  const projectToShow = valueId ? valueId : projectState;
  return (
    <>
      <Wrapper isDropDownSa={isDropDownSa} style={{background: `${isDropDownSa ? 'rgba(84, 86, 90, 0.17)': ''}`, borderRadius: `${isDropDownSa ? '5px': ''}`}} ref={popupRef} onClick={()=>{
        setDropDownSa(!isDropDownSa);
        setParamsBasic({search: ''});
      }} className={styles['header__project-menu']}>
        <AbsoluteDiv>
          <NextImg
          // onError={e => addDefaultSrc(e)}
            src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${getDomain(projectToShow ? (!/^(https?:)?\/\//i.test(projectToShow)) ?'https:' + projectToShow : projectToShow : '')}&size=64`} />
        </AbsoluteDiv>{isDockedSider || isDropDownSa ?<div>
          <HeadingSpan className={!isDockedSider ? styles.close : styles.open}>
            {'SA Project'}
          </HeadingSpan>
          <SelectWrapper className={`${styles['header__project-menu-close']} ${!isDockedSider ? styles.opened : styles.closed}`}>
            <SelectWithSearchSiteAudit
              search={true}
              open={isDropDownSa}
              onOptionChange={onPropertyChange}
              data={getAuditSitesListBasic}
              selectedValue={projectToShow}
              optionRender={optionRenderSa}
              optionLabelProp='display'
              placeholder={valueId ? valueId : t('select-project')}
              handleSearch={onSearchHandler}
            />
          </SelectWrapper>
          <ArrowIcon className={!isDockedSider ? styles.close : styles.open}>
            <CaretDownOutlined />
          </ArrowIcon></div>:''}
      </Wrapper>
    </>
  );
});

const GscTag = styled.span`
  width: auto;
  height: auto;
  background: rgba(52, 174, 243, 0.15);
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #34AEF3;
  padding: 2px 8px;
  margin-left: 5px;
`;
const TooltipMessage = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding-top: 6px;
  height: 30px;
  color: #FFFFFF;
  padding-left:10px;
  padding-right:19px;
`;
const TooltipEstiMateTime = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #E8E8E8;
  padding-right: 20px;
  padding-left:10px;
  padding-top: 15px;
`;
const Crawl = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
`;

const TooltipStuckMessagge = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  width: 229px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 15px;
  height: 45px;
  color: #FFFFFF;
`;
const TooltipProcessingBar = styled.div`
  width: 230px;
  height: 6px;
  background: #E8E8E8;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  
`;
const InnerBar = styled.div`
  height: 6px;
  border-radius: 3px;
`;
const ChatLink = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #2D6CCA;
  text-decoration: underline;
`;

const Wrapper = styled.div<{isDropDownSa: boolean}>`
  position: relative;
  height: 50px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: ${p => p.isDropDownSa ? '1px solid #4e5156 !important' : ''};
  background: rgba(84, 86, 90, 0.17);
  &:hover {
    border: 1px solid #4E5156 !important;
    border-radius: 5px;
  }
  @media screen and (max-width: 1050px) {
    margin-right: 0 !important;
  }
`;

const AbsoluteDiv = styled.div`
  width: 40px;
  padding-right: 10px;
  img {
    height: 28px; 
    width: 28px;
  }
`;

const HeadingSpan = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A3A4A4;
  margin-top: 5px;
`;

const SelectWrapper = styled.div`
  border: 1px solid transparent;
  margin-top: -4px;
`;

const ArrowIcon = styled.div`
  position: absolute;
  right: 8px;
  bottom: 3px;
`;
