import {types, flow, cast, Instance} from 'mobx-state-tree';
import {ORGANIC_SEARCH_API, ORGANIC_COMPETITORS_API} from '@/api/organic-search';
import {organicCompetitorsModel, topCompetitorsKeywordsModel} from './organic-competitors';
import {toJS} from 'mobx';
import {filterList} from '../gsc-store/keyword-search-table/keywordCannibalization';
import {filterDataInMemory} from '@/utils/filter-data';

export const topPagesModel = types.model({
  key: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  fullUrl: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
});

export const positionHistogramModel = types.model({
  date: types.maybeNull(types.string),
  serp1To3: types.maybeNull(types.number),
  serp1: types.maybeNull(types.number),
  serp4To10: types.maybeNull(types.number),
  serp1To10: types.maybeNull(types.number),
  serp11To20: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  clicks: types.maybeNull(types.number),
  clicksR30: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  impressionsR30: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  trafficValueR30: types.maybeNull(types.number),
  ctr: types.maybeNull(types.number),
  ctrR30: types.maybeNull(types.number),
  isProjected: types.maybeNull(types.boolean),
  serp2To3: types.maybeNull(types.number),
  serp100: types.maybeNull(types.number),
  serp2150: types.maybeNull(types.number),
  serp51100: types.maybeNull(types.number),
});

export const dailySerpHistogramsAndClicksModel = types.model({
  positionHistograms: types.array(types.maybeNull(positionHistogramModel)),
});

export type DailySerpHistogramItemType = Instance<typeof dailySerpHistogramsAndClicksModel>;


export const summaryStatModel = types.model({
  title: types.maybeNull(types.string),
  currentFormatted: types.maybeNull(types.string),
  current: types.maybeNull(types.number),
  previous: types.maybeNull(types.number),
});

export const taskStatusesModel = types.model({
  gscKeywordsTaskStatus: types.maybeNull(types.string),
  svmetricsProcessingTaskStatus: types.maybeNull(types.string),
  serpsProcessingTaskStatus: types.maybeNull(types.string),
  landscapeProcessingTaskStatus: types.maybeNull(types.string),
});

const getKeysAndValues = data => {
  const formatedData = [];
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      formatedData.push({
        key: key || '',
        value: value || 0,
      });
    }
  }
  return formatedData;
};

export const OrganicSearchReports = types.model({
  loading: types.boolean,
  topOrganicPages: types.array(topPagesModel),
  dailySerpHistogramsAndClicks: types.maybeNull(dailySerpHistogramsAndClicksModel),
  positionHistogramsPctChange: types.maybeNull(types.array(types.model({
    key: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
  searchImpressions: summaryStatModel,
  organicTraffic: summaryStatModel,
  organicKeywords: summaryStatModel,
  organicValue: summaryStatModel,
  organicCompetitorsLoading: types.boolean,
  organicCompetitorsSitePropertyIsNotSet: types.boolean,
  organicCompetitors: organicCompetitorsModel,
  topOrganicPageNumber: types.maybeNull(types.number),
  topOrganicPageDatasize: types.maybeNull(types.number),
  topOrganicPageSize: types.maybeNull(types.number),
  topOrganicSortField: types.maybeNull(types.string),
  topOrganicSortDirection: types.maybeNull(types.string),
  topOrganicSearchTerm: types.maybeNull(types.string),
  topOrganicFilterList: types.array(filterList),
  displaytopOrganicCompetitorsData: types.maybeNull(types.array(topCompetitorsKeywordsModel)),
  showProcessingBanner: types.boolean,
  processingIsActive: types.boolean,
  processingPercentage: types.maybeNull(types.number),
  taskStatuses: types.maybeNull(taskStatusesModel),
  paginationLoading: types.boolean,
}).views(self => ({
  get getDailySerpHistogramsAndClicks() {
    return toJS(self.dailySerpHistogramsAndClicks);
  },
  get getPositionHistogramsPctChange() {
    return toJS(self.positionHistogramsPctChange);
  },
})).actions(self => {
  const handleFilterTopOrganicCompetitors = (filterKeywordTerm, filters, pageNumber, pageSize, sortField, sortDirection) => {
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(toJS(self.organicCompetitors.topCompetitorsKeywords.results), filterKeywordTerm, ['keyword'], filters, pageNumber, pageSize, sortField, sortDirection);
    self.displaytopOrganicCompetitorsData.length = 0;
    self.topOrganicPageDatasize = dataFilteredSize;
    const newArray = dataFiltered.map(item => {
      return {
        keyword: item.keyword,
        position: item.position,
        keywordSv: item.keywordSv,
        estTraffic: item.estTraffic,
        keywordCpc: item.keywordCpc,
        topCompetitors: item.topCompetitors,
        competitorDomain: item.competitorDomain,
        competitorPosition: item.competitorPosition,
        estTrafficValueUsd: item.estTrafficValueUsd,
      };
    });

    self.displaytopOrganicCompetitorsData = cast(newArray);
  };

  const handleOnTopOganicTableChange = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortField} = column || {};
    self.topOrganicSortField = sortField;
    self.topOrganicSortDirection = order;
    handleFilterTopOrganicCompetitors('',
      self.topOrganicFilterList,
      self.topOrganicPageNumber,
      self.topOrganicPageSize,
      self.topOrganicSortField,
      self.topOrganicSortDirection,
    );
  };

  const handleInitialData = ()=>{
    handleFilterTopOrganicCompetitors(
      self.topOrganicSearchTerm,
      self.topOrganicFilterList,
      self.topOrganicPageNumber,
      self.topOrganicPageSize,
      self.topOrganicSortField,
      self.topOrganicSortDirection,
    );
  };

  const onFilterOrganicChange = (filters: any) => {
    self.topOrganicPageNumber = 1;
    self.topOrganicFilterList = cast(filters);
  };

  const onKeywordSearchTerm = (searchTerm: string) => {
    self.topOrganicSearchTerm = searchTerm;
  };

  const handleKeywordTermChange = async (searchedTerm: string) =>{
    onKeywordSearchTerm(searchedTerm);
    handleInitialData();
  };

  const loadOrganicSearchReports = flow(function* (property: string, previousPeriodStart: string, previousPeriodEnd: string, currentPeriodStart: string, currentPeriodEnd: string) {
    self.loading = true;
    try {
      const response = yield ORGANIC_SEARCH_API.getOrganicSearchReports(property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd);
      if (response) {
        self.topOrganicPages = cast(response.topOrganicPages);
        self.dailySerpHistogramsAndClicks = cast(response.dailySerpHistogramsAndClicks);
        self.searchImpressions = response.searchImpressions;
        self.organicTraffic = response.organicTraffic;
        self.organicKeywords = response.organicKeywords;
        self.organicValue = response.organicValue;
        self.positionHistogramsPctChange = cast(getKeysAndValues(response?.dailySerpHistogramsAndClicks.positionHistogramsPctChange) || []);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  const resetTaskStatuses = () => {
    self.taskStatuses.gscKeywordsTaskStatus = '';
    self.taskStatuses.svmetricsProcessingTaskStatus = '';
    self.taskStatuses.serpsProcessingTaskStatus = '';
    self.taskStatuses.landscapeProcessingTaskStatus = '';
  };

  const loadOrganicCompetitors = flow(function* (url: string) {
    self.organicCompetitorsLoading = true;
    try {
      const response = yield ORGANIC_COMPETITORS_API.getOrganicCompetitors(url);
      if (response) {
        // if taskStatuses returns null, without any object inside, it means that the site property has never been processed before
        if (response.taskStatuses === null) {
          // Determines should processing banner be visible
          self.showProcessingBanner = true;
          // Determine if processing has started, used to show second step of processing banner (loading process)
          self.processingIsActive = false;
          // Set processing percentage to starting point, so next site property start processing from zero
          self.processingPercentage = response.processingCompletion;
          // Reset taskStatuses to default values, so that they don't show up as finished when new site property starts processing
          resetTaskStatuses();
        } else {
          if (!!response?.taskStatuses && !Object.keys(response?.taskStatuses).every(key => (response?.taskStatuses[key] === 'SUCCESS' || response?.taskStatuses[key] === 'FAILURE'))) {
            // Second step is active, processing is active
            self.processingIsActive = true;
            self.processingPercentage = response.processingCompletion;
            self.taskStatuses = response.taskStatuses;
            // If task status is not FAILURE or SUCCESS, poll the API every 10 secs
            yield new Promise(r => setTimeout(r, 10000));
            return loadOrganicCompetitors(url);
          } else {
            self.processingPercentage = response.processingCompletion;
            if (response.site) {
              self.organicCompetitors.site = cast(response.site);
            }
            if (response.competitors) {
              self.organicCompetitors.competitors = cast(response.competitors);
            }
            if (response.topCompetitorsKeywords) {
              self.organicCompetitors.topCompetitorsKeywords = cast(response.topCompetitorsKeywords);
            }
            if (response.topTwoCompetitorKeywordsOverlap) {
              self.organicCompetitors.topTwoCompetitorKeywordsOverlap = cast(response.topTwoCompetitorKeywordsOverlap);
            }
            self.taskStatuses = response.taskStatuses;
            self.showProcessingBanner = false;
            self.processingIsActive = false;
            self.organicCompetitorsSitePropertyIsNotSet = false;
          }
        }
      }
    } catch (e) {
      self.organicCompetitorsSitePropertyIsNotSet = true;
      return Promise.reject(e);
    } finally {
      self.organicCompetitorsLoading = false;
    }
  });

  // API for processing new site properties
  const processOrganicSiteProperty = flow(function* (url: string) {
    try {
      yield ORGANIC_COMPETITORS_API.processSiteProperty(url);
      yield new Promise(r => setTimeout(r, 1000));
      loadOrganicCompetitors(url);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.organicCompetitorsLoading = false;
    }
  });

  const handleTopCompetitorsKeywordsPagination = flow(function* (url: string, pageNumber: number) {
    self.paginationLoading = true;
    try {
      const response = yield ORGANIC_COMPETITORS_API.getOrganicCompetitors(url, pageNumber);
      if (response) {
        self.organicCompetitors.topCompetitorsKeywords = cast(response.topCompetitorsKeywords);
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.paginationLoading = false;
    }
  });

  return {
    loadOrganicSearchReports,
    handleKeywordTermChange,
    handleInitialData,
    loadOrganicCompetitors,
    onFilterOrganicChange,
    handleOnTopOganicTableChange,
    onKeywordSearchTerm,
    processOrganicSiteProperty,
    handleTopCompetitorsKeywordsPagination,
  };
});

export function initOrganicSearchReportsStore() {
  return OrganicSearchReports.create({
    loading: true,
    topOrganicPages: [],
    dailySerpHistogramsAndClicks: {},
    searchImpressions: {},
    organicTraffic: {},
    organicKeywords: {},
    organicValue: {},
    organicCompetitorsSitePropertyIsNotSet: false,
    paginationLoading: false,
    organicCompetitors: {
      site: {},
      topCompetitorsKeywords: {},
      topTwoCompetitorKeywordsOverlap: [],
      competitors: [],
    },
    organicCompetitorsLoading: true,
    topOrganicPageNumber: 1,
    topOrganicPageSize: 50,
    topOrganicFilterList: [
      {id: 1, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 2, name: 'estTraffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'keywordSv', header: 'Search Volume', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'keywordCpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
    ],
    topOrganicSortDirection: 'descend',
    topOrganicSortField: '',
    displaytopOrganicCompetitorsData: [],
    showProcessingBanner: false,
    processingIsActive: false,
    processingPercentage: 0,
    taskStatuses: {
      gscKeywordsTaskStatus: '',
      svmetricsProcessingTaskStatus: '',
      serpsProcessingTaskStatus: '',
      landscapeProcessingTaskStatus: '',
    },
  });
}
