import React, {useState, useEffect} from 'react';
import {useTranslation} from 'next-i18next';
import {ApplyBtnWrapper, ApplyButton, CustomRangeContainer, RadioGropContainer, RadioGroupStyled} from '../styledComponents';

interface Props {
  styleClass?: string;
  options: any;
  filterLabel?: string;
  categories?: string[];
  checkedValues?: string[];
  filterId?: number;
  filterName?: string;
  removeItem?: () => void;
  onChange?: (filter: any) => void;
  className?: string;
  isClear?: boolean;
  isColumnFilter?: boolean;
  onClear?: ()=>void;
  initialValue?: string;
  absoluteUi?: boolean;
  customCategoryType?: string;
  columnAligned?: boolean;
}

const FilterMultipleRadioPopUp: React.FC<Props> = ({isColumnFilter = false, customCategoryType, filterLabel, filterId, filterName, options, onChange, initialValue, columnAligned = false}) => {
  const [filtersSelected, setFiltersSelected] = useState(null);
  const {t} = useTranslation('common');

  useEffect(() => {
    if (initialValue) {
      setFiltersSelected(initialValue);
    } else if (!isColumnFilter) {
      const value = options?.length ? options[0]?.value : '';
      setFiltersSelected(value);
    }
  }, []);

  function checkedOnChange(e) {
    setFiltersSelected(e?.target?.value);
  }
  const disablingButton = () =>{
    if (filtersSelected==null) {
      return true;
    } else {
      return false;
    }
  };
  const createOrUpdateFilter = e => {
    e.preventDefault();
    const appliedFilter = {
      active: true,
      customFilterValue: '',
      filterTypes: options,
      header: filterLabel,
      id: filterId,
      name: filterName,
      type: filtersSelected,
      customCategoryType: customCategoryType,
    };
    onChange(isColumnFilter ? filtersSelected : appliedFilter);
  };

  return (
    <CustomRangeContainer>
      <RadioGropContainer columnAligned={columnAligned}>
        <RadioGroupStyled options={options} value={filtersSelected} onChange={checkedOnChange} />
      </RadioGropContainer>
      <ApplyBtnWrapper>
        <ApplyButton
          onClick={createOrUpdateFilter}
          disabled={disablingButton()}
        >
          {t('apply-filter')}
        </ApplyButton>
      </ApplyBtnWrapper>
    </CustomRangeContainer>
  );
};

export default FilterMultipleRadioPopUp;
