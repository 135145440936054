import {BaseApi} from '@/api/base-api';
import {getAuthHeader, getAuthorizationHeader} from '@/api/common-utils';

export class FocusTermsApi extends BaseApi {
  public async queryTerms(query: string, country: string, language: string) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: '/focus-terms/',
        data: {
          query, country, language,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async searchTerms() {
    try {
      const response = await this.axios.get(`/focus-terms/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeTerm(uuid: string, term: string) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: `/pages/${uuid}/exclude-focus-term/`,
        data: {
          focus_term: term,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        // cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async bulkRemoveTerms(uuid: string, terms: string[]) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: `/pages/${uuid}/bulk-exclude-focus-terms/`,
        data: {
          focus_terms: terms,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        // cancelToken: this.cancelToken,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getTaSuggesions(terms, uuid) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: `/pages/${uuid}/terms-insertion/`,
        data: {
          focus_terms: terms,
        },
        headers: getAuthHeader(),
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getLinkSuggesions(links, uuid) {
    try {
      const {data} = await this.axios({
        method: 'POST',
        url: `/pages/${uuid}/links-insertion/`,
        data: {
          links,
        },
        headers: getAuthHeader(),
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
