import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
// import {Collapse, Dropdown, Switch, Divider, Tooltip} from 'antd';
import {Collapse, Switch, Tooltip, Row, Col} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
// import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import {TableStyled} from '@/components/dashboard/pages/home-page-main/components/table-styled';
import {toJS} from 'mobx';
import {faSquareQuestion, faChartLineUp} from '@fortawesome/pro-duotone-svg-icons';
import {Button} from '@/components/common-components/v2/Button';
import moment from 'moment';


export const OttoEmailUpdatesUpdates = observer(() => {
  const {ottoStore: {loadOttoEmailSettings, getOttoEmailSetting, loadUpdateOttoEmailList, updateOttoEmailList, tabelLoading, loadToggleProjectCreation}} = useStore('');
  const {settings: {customer: {profile: {team}}}} = useStore('');
  const [btnLoading, setBtnLoading]= useState(false);
  const [toggleLoading, setToggleLoading]= useState(false);
  const loadData = async ()=> {
    await loadOttoEmailSettings();
  };
  useEffect(()=> {
    loadData();
  }, []);
  useEffect(()=>{
    const newTeam = [];
    toJS(team)?.map(item => {
      newTeam?.push({
        recipient: item?.email,
        isActive: false,
      });
    });
  }, [toJS(team)?.length]);

  const handleChange = (checked, record) => {
    const data = getOttoEmailSetting?.growthReport?.map(z => {
      return {
        ...z,
        shouldSendEmails: z?.id == record?.id ? checked : z?.shouldSendEmails,
      };
    });
    updateOttoEmailList(data);
  };

  // const handleChangeAll = enabled => {
  //   const data = getOttoEmailSetting?.growthReport?.map(z => {
  //     return {
  //       ...z,
  //       shouldSendEmails: enabled,
  //     };
  //   });
  // from store
  //   updateOttoEmailList(data);
  // };
  const projectCreation =async () => {
    setToggleLoading(true);
    await loadToggleProjectCreation();
    setToggleLoading(false);
  };

  const emailTableColumns: any = [
    {
      title: <ColumnTitle>EMAIL ALERT</ColumnTitle>,
      key: 'emailType',
      dataIndex: 'emailType',
      width: 400,
      align: 'left' as 'left',
      render: (_, data) => {
        return <FlexWrapper ><Switch
          style={{backgroundColor: '#0C872F'}}
          checked={data?.shouldSendEmails}
          onChange={checked => handleChange(checked, data)}
        />
        <TableStr>{data?.domain || '-'}</TableStr>&nbsp;&nbsp;&nbsp;
        </FlexWrapper>;
      },
    },
    {
      title: <ColumnTitle>FREQUENCY</ColumnTitle>,
      key: 'freq',
      dataIndex: 'freq',
      width: 120,
      align: 'left' as 'left',
      render: () => {
        return <div style ={{marginRight: '20px'}}>
          {'Every Monday'}
        </div>;
      },
    },
    {
      title: <ColumnTitle>LAST SENT</ColumnTitle>,
      key: 'lastSent',
      dataIndex: 'lastSent',
      width: 100,
      align: 'center',
      render: (_, data) => {
        return <div style ={{marginRight: '20px'}}>{data?.lastEmailSentAt && moment(data?.lastEmailSentAt).format('MMM DD, YYYY') || '-'}</div>;
      },
    },
  ];

  const updateSettingsData = async () => {
    setBtnLoading(true);
    if (getOttoEmailSetting?.growthReport?.length) {
      await Promise.all(getOttoEmailSetting.growthReport.map(z => loadUpdateOttoEmailList(z.id, z.shouldSendEmails)))
        .then(() => loadOttoEmailSettings(true));
    }
    setBtnLoading(false);
  };

  return (
    <CollapseWrapper
      ghost
      expandIcon={({isActive}) => isActive ? <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown} /> }
      bordered={false}
      defaultActiveKey={1}
      className='site-collapse-custom-collapse'
      expandIconPosition='right'
    >
      <Collapse.Panel header={<HeadingWrapper><FontAwesomeIcon icon={faChartLineUp} color='#0C872F'/>&nbsp;&nbsp;Weekly SEO Tasks</HeadingWrapper>} key={'1'} showArrow={true}>
        <Flex marginBottom={'7px'}>
          {/* <Flex> */}
          {/* <StyledWrapper> */}
          {/* <ProjectSelector selectedId={getInitialId()} onProjectChange={onProjectChange}/> */}
          {/* </StyledWrapper> */}
          {/* </Flex> */}
          {/* <StyledWrapper marginLeft={'70px'}> */}
          {/* <BulkSideWrapper>
              Active emails: {tabelLoading ? '-' : getOttoEmailSetting?.growthReport?.filter(z => z?.shouldSendEmails)?.length}
            </BulkSideWrapper>
            <BulkSideWrapper>
              Inactive: {tabelLoading ? '-' : getOttoEmailSetting?.growthReport?.filter(z => !z?.shouldSendEmails)?.length}
            </BulkSideWrapper> */}
          {/* <Dropdown placement='bottomLeft' trigger={['click']} overlayStyle={{borderRadius: '8px', background: '#ffff', width: '245px', padding: '5px 0 5px 0'}} overlay={
              <>
                <DisableBtnStyled onClick={() => handleChangeAll(true)}>Enable all alerts for all projects</DisableBtnStyled>
                <Divider/>
                <RemoveBtnStyled onClick={() => handleChangeAll(false)}>Disable all alerts for all projects</RemoveBtnStyled>
              </>
            } >
              <div style={{color: '#2D6CCA', cursor: 'pointer'}}>Bulk actions <FontAwesomeIcon icon={faCaretDown} color='#2D6CCA'/></div>
            </Dropdown> */}
          {/* </StyledWrapper> */}
        </Flex>
        <AlertForNewProjects>
          <Col xl={4} md={2} xs={24}><Switch
            style={{backgroundColor: '#0C872F'}}
            checked={!!getOttoEmailSetting?.projectCreation}
            onChange={()=> projectCreation()}
            loading={toggleLoading}
          /></Col>
          <Col xl={16} md={18} xs={24} style= {{paddingLeft: '4px'}}>
            <BlackText color={'#121212'}>{'Receive email alerts on Project Creation (for all newly created projects)'}</BlackText></Col>
          <Col xl={4} md={4} xs={24}><BlackText color={'#4E5156'}><Tooltip title={'Weekly SEO Tasks emails are being sent to all team members of this account.'}><FontAwesomeIcon icon={faSquareQuestion} color='#4E5156' cursor='pointer'/></Tooltip> Recipients</BlackText></Col>
        </AlertForNewProjects>
        <TableStyled
          loading={tabelLoading}
          columns={emailTableColumns}
          isNewEmailSetting={true}
          dataSource={getOttoEmailSetting?.growthReport}
          pagination={false}
          bordered={false}
        />
        <ButtonStyled loading={btnLoading} color='green' onClick={updateSettingsData}>Update Tasks settings</ButtonStyled>
      </Collapse.Panel>
    </CollapseWrapper>
  );
});

const CollapseWrapper = styled(Collapse)`
  background: linear-gradient(148.29deg, rgba(12, 135, 47, 0.09) 6.67%, rgba(12, 135, 47, 0) 27.09%), #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  margin-top: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
`;
const HeadingWrapper = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #121212;
  margin: 20px 10px 14px 20px;
`;
const Flex = styled.span<{marginBottom?: string}>`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: ${p=> p?.marginBottom};
`;

const ColumnTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #121212;
`;

const TableStr = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
  margin-left: 17px;
  &::first-letter {
    text-transform: capitalize;
  }
`;
// const DisableBtnStyled = styled.div`
// color: #121212;
// margin-bottom: -22px;
// line-height: 22px;
// width: fit-content;
// display: flex;
// justify-content: center;
// cursor: pointer;
// padding: 8px 26px 8px 14px;
// &:hover {
//   background: #E8E8E8;
// }
// `;
// const RemoveBtnStyled = styled.div`
// color: #F44343;
// margin-top: -22px;
// line-height: 22px;
// display: flex;
// padding: 8px 20px 8px 14px;
// justify-content: center;
// cursor: pointer;
// &:hover {
//   background: #E8E8E8;
// }
// `;
// const StyledWrapper = styled.div<{marginLeft?: string}>`
//   margin-right: 10px;
//   margin-left: ${p=> p?.marginLeft ? p?.marginLeft : '10px'};
//   display: flex;
// `;
// const BulkSideWrapper = styled.div`
// margin-right: 17px;
// `;
const FlexWrapper = styled.div`
display: flex;
align-items: center;
`;
const ButtonStyled = styled(Button)`
height: 32px;
margin-left: 10px;
margin-top: 30px;
`;
const AlertForNewProjects = styled(Row)`
background: #2D6CCA26;
border-radius: 10px;
padding: 25px;
margin: 0 10px 10px 10px;
`;
const BlackText = styled.div<{color}>`
color: ${p=> p?.color};
`;
