import {observer} from 'mobx-react';
import {SingleAiOutline} from './SingleAiOutline';
import {useStore} from '@/store/root-store';

interface Props {
  insertText: (outlineParam: any, id: number, shouldReplace?: boolean) => void;
}

export const AiWritterResults = observer(({insertText}: Props) => {
  const {contentOptimizer: {currentPage, aiOutline}} = useStore('');


  return <>
    {aiOutline?.aiWriterOutlines?.find(item => item.uuid == currentPage?.content?.uuid)?.outlines?.map((singleOutline, idx) => {
      return idx == 0 ? <SingleAiOutline key={idx}
        outlineId={idx}
        aiOutline={singleOutline}
        insertToEditor={insertText}/> : <></>;
    })}
  </>;
});
