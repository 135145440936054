import {types, Instance, flow, cast} from 'mobx-state-tree';
import {ordersApi} from '@/api/account';
import {filterDataInMemory} from '@/utils/filter-data';
import {formatSorters} from '@/utils/filters';
import {notification as notify} from '@/utils/notification-v2';
import {toJS} from 'mobx';

export enum TABS {
  DigitalPr = 'digital_pr',
  OnSiteContent = 'onsite_content',
  Infographics = 'infographics',
}

export enum PLACEMENT_STATUS {
  Completed = 'completed',
  PublisherLinking = 'publisher-linking',
  PendingAccountManagerApproval = 'pending-account-manager-approval',
  ArticleInReview = 'article-in-review',
  Matching = 'matching',
  Paused = 'paused',
  ArticleRequested = 'article-requested',
  ArticlePendingClaiming = 'article-pending-claiming',
  ArticleBeingWritten = 'article-being-written',
  ArticlePendingCustomerApproval = 'article-pending-customer-approval',
  ArticlePendingSubmission = 'article-pending-submission',
  PublisherSubmitted = 'publisher-submitted',
  PublisherRejected = 'publisher-rejected',
  ArticleWaitingGeneration = 'article-waiting-generation'
}

export enum ONSITE_CONTENT_STATUS {
  EditorApproved = 'approved',
  EditorRejected = 'rejected',
  EditorReviewing = 'reviewing',
  WriterInWork = 'inwork',
  WriterCompleted = 'completed',
}

export const Placement = types.model({
  actualAnchorText: types.maybeNull(types.string),
  actualLandingPage: types.maybeNull(types.string),
  articleTitle: types.maybeNull(types.string),
  clientFacingArticleTopic: types.maybeNull(types.string),
  clientName: types.maybeNull(types.string),
  linkingUrl: types.maybeNull(types.string),
  linkingAtDate: types.maybeNull(types.string),
  publicationCf: types.maybeNull(types.number),
  publicationDa: types.maybeNull(types.number),
  publicationDr: types.maybeNull(types.number),
  publicationMozSs: types.maybeNull(types.number),
  publicationOt: types.maybeNull(types.number),
  publicationTf: types.maybeNull(types.number),
  requiredCompletionDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  targetLandingPage: types.maybeNull(types.string),
  targetAnchorText: types.maybeNull(types.string),
  targetDaRange: types.maybeNull(types.string),
  targetDaRangeMax: types.maybeNull(types.number),
  targetDaRangeMin: types.maybeNull(types.number),
  targetDrRange: types.maybeNull(types.string),
  targetDrRangeMax: types.maybeNull(types.number),
  targetDrRangeMin: types.maybeNull(types.number),
  targetOtRange: types.maybeNull(types.string),
  targetOtRangeMax: types.maybeNull(types.number),
  targetOtRangeMin: types.maybeNull(types.number),
  maxMozSpamScore: types.maybeNull(types.number),
  minCitationFlow: types.maybeNull(types.number),
  minOt: types.maybeNull(types.number),
  minTrustflow: types.maybeNull(types.number),
});

export const PlacementFiltered = types.model({
  actualAnchorText: types.maybeNull(types.string),
  actualLandingPage: types.maybeNull(types.string),
  articleTitle: types.maybeNull(types.string),
  clientFacingArticleTopic: types.maybeNull(types.string),
  clientName: types.maybeNull(types.string),
  linkingUrl: types.maybeNull(types.string),
  linkingAtDate: types.maybeNull(types.string),
  publicationCf: types.maybeNull(types.number),
  publicationDa: types.maybeNull(types.number),
  publicationDr: types.maybeNull(types.number),
  publicationMozSs: types.maybeNull(types.number),
  publicationOt: types.maybeNull(types.number),
  publicationTf: types.maybeNull(types.number),
  requiredCompletionDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  targetLandingPage: types.maybeNull(types.string),
  targetAnchorText: types.maybeNull(types.string),
  targetDaRange: types.maybeNull(types.string),
  targetDaRangeMax: types.maybeNull(types.number),
  targetDaRangeMin: types.maybeNull(types.number),
  targetDrRange: types.maybeNull(types.string),
  targetDrRangeMax: types.maybeNull(types.number),
  targetDrRangeMin: types.maybeNull(types.number),
  targetOtRange: types.maybeNull(types.string),
  targetOtRangeMax: types.maybeNull(types.number),
  targetOtRangeMin: types.maybeNull(types.number),
  maxMozSpamScore: types.maybeNull(types.number),
  minCitationFlow: types.maybeNull(types.number),
  minOt: types.maybeNull(types.number),
  minTrustflow: types.maybeNull(types.number),
});

export type PlacementType = Instance<typeof Placement>;
export type PlacementTypeFilter = Instance<typeof PlacementFiltered>;

export const OnSiteContent = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  clientUrl: types.maybeNull(types.string),
  editorStatus: types.maybeNull(types.enumeration('ONSITE_CONTENT_STATUS', Object.values(ONSITE_CONTENT_STATUS))),
  isCustomerApproved: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  requiredCompletionDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  targetWordCount: types.maybeNull(types.number),
  googledocsLink: types.maybeNull(types.string),
});

export const OnSiteContentFiltered = types.model({
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  clientUrl: types.maybeNull(types.string),
  editorStatus: types.maybeNull(types.enumeration('ONSITE_CONTENT_STATUS', Object.values(ONSITE_CONTENT_STATUS))),
  isCustomerApproved: types.maybeNull(types.boolean),
  createdAt: types.maybeNull(types.string),
  requiredCompletionDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  targetWordCount: types.maybeNull(types.number),
  googledocsLink: types.maybeNull(types.string),
});

export type OnSiteContentType = Instance<typeof OnSiteContent>;
export type OnSiteContentTypeFilter = Instance<typeof OnSiteContentFiltered>;


export const FilterDataModel = types.model({
  slug: types.maybeNull(types.string),

  id: types.number,
  description: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  priority: types.maybeNull(types.number),
  completionPercentageCached: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  placementColumns: types.maybeNull(types.array(types.string)),
  progressInfoCached: types.optional(types.model({
    publisherLinking: types.maybeNull(types.number),
  }), {}),
  placements: types.optional(types.array(Placement), []),
  onsitecontent: types.optional(types.array(OnSiteContent), []),
  maxMozSpamScore: types.maybeNull(types.number),
  minCitationFlow: types.maybeNull(types.number),
  minOt: types.maybeNull(types.number),
  minTrustflow: types.maybeNull(types.number),
});

export const OrderCategoriesModel = types.model({
  color: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

export const Order = types.model({
  slug: types.maybeNull(types.string),
  id: types.number,
  description: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  priority: types.maybeNull(types.number),
  completionPercentageCached: types.maybeNull(types.number),
  completionPercentagePosted: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  progressInfoCached: types.optional(types.model({
    publisherLinking: types.maybeNull(types.number),
  }), {}),
  placements: types.maybeNull(types.array(Placement)),
  placementColumns: types.maybeNull(types.array(types.string)),
  onsitecontent: types.optional(types.array(OnSiteContent), []),
  maxMozSpamScore: types.maybeNull(types.number),
  minCitationFlow: types.maybeNull(types.number),
  minOt: types.maybeNull(types.number),
  minTrustflow: types.maybeNull(types.number),
  items: types.maybeNull(types.number),
  categories: types.maybeNull(types.array(OrderCategoriesModel)),
  totalCost: types.maybeNull(types.number),
  paidVia: types.maybeNull(types.string),
});
export type OrderType = Instance<typeof Order>;

export const Orders = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.maybeNull(types.array(Order)),
});

export const OrderTab = types.model({
  name: types.enumeration('TABS', Object.values(TABS)),
  label: types.string,
  ordersCount: types.optional(types.maybeNull(types.number), 0),
});

export type OrderTabType = Instance<typeof OrderTab>;

const FiltersModel = types.model({
  from: types.maybeNull(types.number),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.number),
});
const CategoryDistributionModel = types.model({
  color: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
});

const OrderDetailsCategoriesModel = types.model({
  color: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
});
const OrderDetailsItemsModel = types.model({
  id: types.maybeNull(types.number),
  assetUrl: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  paid: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  publicationDa: types.maybeNull(types.number),
  targetDaRangeMax: types.maybeNull(types.number),
  targetDrRange: types.maybeNull(types.string),
  publicationDr: types.maybeNull(types.number),
  targetDrRangeMax: types.maybeNull(types.number),
  publicationOt: types.maybeNull(types.number),
  minOt: types.maybeNull(types.number),
  maxMozSpamScore: types.maybeNull(types.number),
  publicationMozSs: types.maybeNull(types.number),
  publicationTf: types.maybeNull(types.number),
  minTrustflow: types.maybeNull(types.number),
  publicationCf: types.maybeNull(types.number),
  minCitationFlow: types.maybeNull(types.number),
  targetDaRange: types.maybeNull(types.string),
  targetDaRangeMin: types.maybeNull(types.number),
  targetDrRangeMin: types.maybeNull(types.number),
  targetOtRange: types.maybeNull(types.string),
  targetOtRangeMax: types.maybeNull(types.number),
  targetOtRangeMin: types.maybeNull(types.number),
  articleTitle: types.maybeNull(types.string),
  linkingUrl: types.maybeNull(types.string),
  actualAnchorText: types.maybeNull(types.string),
  targetAnchorText: types.maybeNull(types.string),
  isAuthorityBuilding: types.maybeNull(types.boolean),
  actualLandingPage: types.maybeNull(types.string),
  targetLandingPage: types.maybeNull(types.string),
});

export type OrderDetailsItemType = Instance<typeof OrderDetailsItemsModel>;

const OrderDetailsModel = types.model({
  campaignEndDate: types.maybeNull(types.string),
  campaignStartDate: types.maybeNull(types.string),
  categories: types.maybeNull(types.array(OrderDetailsCategoriesModel)),
  createdAt: types.maybeNull(types.string),
  hasPaidColumn: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  items: types.maybeNull(types.array(OrderDetailsItemsModel)),
  itemCategories: types.maybeNull(types.array(types.string)),
  status: types.maybeNull(types.string),
  totalCost: types.maybeNull(types.number),
  totalPaid: types.maybeNull(types.number),
  placementColumns: types.maybeNull(types.array(types.string)),
  hasPendingInvoices: types.maybeNull(types.boolean),
  description: types.maybeNull(types.string),
  paidVia: types.maybeNull(types.string),
});

export const OrdersStore = types.model({
  loading: types.boolean,
  selectedOrderId: types.optional(types.number, 0),
  ordersData: Orders,
  categoryDistribution: types.maybeNull(types.array(CategoryDistributionModel)),
  pageSize: types.maybeNull(types.number),
  pageNumber: types.maybeNull(types.number),
  filterKeywordTerm: types.maybeNull(types.string),
  sortField: types.optional(types.string, ''),
  sortDirection: types.optional(types.string, ''),
  filterList: types.optional(types.array(FiltersModel), []),
  filterTableDataSize: types.maybeNull(types.number),
  filteredRenderData: types.optional(types.array(FilterDataModel), []),
  tableSize: types.maybeNull(types.number),
  filterKeywordColumns: types.maybeNull(types.array(types.string)),

  placementList: types.array(Placement),
  filterTableDataSizePlacement: types.maybeNull(types.number),
  filteredRenderDataPlacement: types.optional(types.array(PlacementFiltered), []),
  pageSizePlacement: types.maybeNull(types.number),
  pageNumberPlacement: types.maybeNull(types.number),
  filterKeywordTermPlacement: types.optional(types.string, ''),
  filterKeywordColumnsPlacement: types.optional(types.array(types.string), []),
  sortFieldPlacement: types.optional(types.string, ''),
  sortDirectionPlacement: types.optional(types.string, ''),
  filterListPlacement: types.optional(types.array(FiltersModel), []),
  tableSizePlacement: types.maybeNull(types.number),

  onsitecontentList: types.array(OnSiteContent),
  filterTableDataSizeOnsiteContent: types.maybeNull(types.number),
  filteredRenderDataOnsiteContent: types.optional(types.array(OnSiteContentFiltered), []),
  pageSizeOnsiteContent: types.maybeNull(types.number),
  pageNumberOnsiteContent: types.maybeNull(types.number),
  filterKeywordTermOnsiteContent: types.optional(types.string, ''),
  filterKeywordColumnsOnsiteContent: types.optional(types.array(types.string), []),
  sortFieldOnsiteContent: types.optional(types.string, ''),
  sortDirectionOnsiteContent: types.optional(types.string, ''),
  filterListContent: types.optional(types.array(FiltersModel), []),
  tableSizeOnsiteContent: types.maybeNull(types.number),
  orderTableInit: types.maybeNull(types.boolean),
  oderDetailsLoading: types.maybeNull(types.boolean),
  orderPayingLoading: types.maybeNull(types.boolean),
  orderAgainLoading: types.maybeNull(types.boolean),
  oderDetailsInitLoading: types.maybeNull(types.boolean),
  orderDetails: types.maybeNull(OrderDetailsModel),
  parentPage: types.maybeNull(types.string),
})
  .views( self => ({
    get getOrderList() {
      return [...(toJS(self.ordersData.results) || [])];
    },
    get getOrdersCount() {
      return self.ordersData.count;
    },
    get getPlacementList() {
      return self.placementList;
    },
    get getFilteredData() {
      return self.filteredRenderData;
    },
    get getFilteredDataPlacement() {
      return self.filteredRenderDataPlacement;
    },
    get getOnsiteContentList() {
      return self.onsitecontentList;
    },
    get getFilteredDataOnsiteContent() {
      return self.filteredRenderDataOnsiteContent;
    },
    get isOrderPayingLoading() {
      return self.orderPayingLoading;
    },
    get selectedOrder(): OrderType {
      if (self.selectedOrderId) {
        return self.ordersData.results.find(order => order.id === self.selectedOrderId);
      } else {
        return Order.create({id: 0, placements: [], onsitecontent: []});
      }
    },
  }))
  .actions(self => {
    const loadOrders = flow(function* () {
      const sorters = {
        id: 'id',
        createdAt: 'created_at',
        impCur: 'current_impressions',
        totalCost: 'total_cost',
        items: 'items',
      };

      const formtatedSorters = formatSorters(sorters, self.sortField, self.sortDirection);

      try {
        self.loading = true;
        const data = yield ordersApi.getOrders(
          {
            page: self.pageNumber,
            pageSize: self.pageSize,
            ordering: formtatedSorters,
            ...(self.filterKeywordTerm && {'search': self.filterKeywordTerm}),
          },
        );
        self.ordersData = cast(data);
        if (self.orderTableInit) self.orderTableInit = false;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        self.loading = false;
      }
    });


    const loadOrderDetails = flow(function* (orderId: string) {
      self.oderDetailsLoading = true;

      try {
        const data = yield ordersApi.getOrderDetails({orderId});
        if (data.isCancel) return;

        if (data) self.orderDetails = cast(data);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.oderDetailsLoading = false;
        self.oderDetailsInitLoading = false;
      }
    });

    const approveOnsite = flow(function* (orderId: number, placementId: number) {
      self.oderDetailsLoading = true;
      try {
        const data = yield ordersApi.approveOnsite({orderId: `${orderId}`, placementId: `${placementId}`});
        if (data.isCancel) return;

        loadOrderDetails(`${orderId}`);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.oderDetailsLoading = false;
        self.oderDetailsInitLoading = false;
      }
    });

    const setOrderDetailsInitLoading = (value: boolean) => self.oderDetailsInitLoading = value;

    const payForOrder = flow(function* (orderId: string) {
      self.orderPayingLoading = true;
      try {
        const response = yield ordersApi.payForOrder({orderId});
        if (response.isCancel) return;

        if (response.success) {
          notify.success('Congratulations!', 'You have successfully paid for the order.');
          self.oderDetailsInitLoading = true;
          loadOrderDetails(orderId);
        }
        if (!response.success) notify.error('Order payment failed.', `${response.details}`);
        return response;
      } catch (error) {
        if (!orderId) {
          notify.error('Order not found.', '');
        }
        return Promise.reject(error);
      } finally {
        self.orderPayingLoading = false;
      }
    });

    const orderAgain = flow(function* (orderId: string) {
      self.orderAgainLoading = true;

      try {
        const response = yield ordersApi.orderAgain({orderId});
        if (response.isCancel) return;

        notify.success('Congratulations!', 'You have successfully created a new order.');
        loadOrders();
      } catch (e) {
        notify.error('Creating a new order has failed.', '');
        return Promise.reject(e);
      } finally {
        self.orderAgainLoading = false;
      }
    });


    const getCategoryDistribution = flow(function* () {
      try {
        const data = yield ordersApi.getCategoryDistribution();
        if (data.isCancel) return;

        self.categoryDistribution = cast(data);
      } catch (e) {
        return Promise.reject(e);
      }
    });


    const setFilterKeywordTerm = (searchTerm: string) => {
      self.filterKeywordTerm = searchTerm;
    };

    const setSortField = (value: string) => self.sortField = value;
    const setSortDirection = (value: string) => self.sortDirection = value;
    const setPageSize = (value: number) => self.pageSize = value;
    const setPageNumber = (value: number) => self.pageNumber = value;
    const setOrderTableInit = (value: boolean) => self.orderTableInit = value;
    const clearOrderTableValues = () => {
      self.sortField = '';
      self.sortDirection = '';
      self.pageSize = 10;
      self.pageNumber = 1;
      self.orderTableInit = true;
    };

    const setPlacementFilterData = (placement: PlacementTypeFilter[], onsitecontent: OnSiteContentTypeFilter[]) => {
      const newArrPlacement = placement.map(item => {
        return {
          ...item,
        };
      });
      const newArrOnsite = onsitecontent.map(item => {
        return {
          ...item,
        };
      });
      self.placementList = cast(newArrPlacement);
      self.onsitecontentList = cast(newArrOnsite);
      self.tableSizeOnsiteContent = self.onsitecontentList.length;
      self.tableSizePlacement = self.placementList.length;
    };

    const handleFilterRenderDataPlacement = (filterKeywordTermPlacement, filters, pageNumberPlacement: number, pageSizePlacement: number, sortFieldPlacement, sortDirectionPlacement) => {
      self.loading = true;
      const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.placementList, self.filterKeywordTermPlacement, self.filterKeywordColumnsPlacement, filters, pageNumberPlacement, pageSizePlacement, sortFieldPlacement, sortDirectionPlacement);
      self.filterTableDataSizePlacement = dataFilteredSize;
      self.filteredRenderDataPlacement.length = 0;
      const newArray = dataFiltered.map(item => {
        return {
          ...item,
        };
      });
      self.filteredRenderDataPlacement = cast(newArray);
      self.loading = false;
    };

    const handleTableChangePlacement = (pagination, filters, sorter) => {
      const {column, order} = sorter;
      const {sortField} = column || {};
      self.sortFieldPlacement = sortField;
      self.sortDirectionPlacement = order;
      handleFilterRenderDataPlacement(self.filterKeywordTermPlacement, self.filterListPlacement, self.pageNumberPlacement, self.pageSizePlacement, self.sortFieldPlacement, self.sortDirectionPlacement);
    };

    const setFilterKeywordTermPlacements = (searchTerm: string) => {
      if (self.filterKeywordTermPlacement !== searchTerm) {
        self.filterKeywordTermPlacement = searchTerm;
        self.pageNumberPlacement = 1;
        handleFilterRenderDataPlacement(searchTerm, self.filterListPlacement, 1, self.pageSizePlacement, self.sortFieldPlacement, self.sortDirectionPlacement);
      }
    };

    const onPaginationChangePlacement = async (pageNumberPlacement: number, pageSizePlacement: number) => {
      self.pageSizePlacement = pageSizePlacement;
      self.pageNumberPlacement = pageNumberPlacement;
      handleFilterRenderDataPlacement(self.filterKeywordTermPlacement, self.filterListPlacement, self.pageNumberPlacement, self.pageSizePlacement, self.sortFieldPlacement, self.sortDirectionPlacement);
    };

    const handleFilterRenderDataOnsiteContent = (filterKeywordTermOnsiteContent, filters, pageNumberOnsiteContent: number, pageSizeOnsiteContent: number, sortFieldOnsiteContent, sortDirectionOnsiteContent) => {
      self.loading = true;
      const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.onsitecontentList, self.filterKeywordTermOnsiteContent, self.filterKeywordColumnsOnsiteContent, filters, pageNumberOnsiteContent, pageSizeOnsiteContent, sortFieldOnsiteContent, sortDirectionOnsiteContent);
      self.filterTableDataSizeOnsiteContent = dataFilteredSize;
      self.filteredRenderDataOnsiteContent.length = 0;
      const newArray = dataFiltered.map(item => {
        return {
          ...item,
        };
      });
      self.filteredRenderDataOnsiteContent = cast(newArray);
      self.loading = false;
    };

    const setFilterKeywordTermOnsiteContent = (searchTerm: string) => {
      if (self.filterKeywordTermOnsiteContent !== searchTerm) {
        self.filterKeywordTermOnsiteContent = searchTerm;
        self.pageNumberOnsiteContent = 1;
        handleFilterRenderDataOnsiteContent(searchTerm, self.filterListContent, 1, self.pageSizeOnsiteContent, self.sortFieldOnsiteContent, self.sortDirectionOnsiteContent);
      }
    };

    const handleTableChangeOnsiteContent= (pagination, filters, sorter) => {
      const {column, order} = sorter;
      const {sortField} = column || {};
      self.sortFieldOnsiteContent = sortField;
      self.sortDirectionOnsiteContent = order;
      handleFilterRenderDataOnsiteContent(self.filterKeywordTermOnsiteContent, self.filterListContent, self.pageNumberOnsiteContent, self.pageSizeOnsiteContent, self.sortFieldOnsiteContent, self.sortDirectionOnsiteContent);
    };

    const onPaginationChangeOnsiteContent = async (pageNumberOnsiteContent: number, pageSizeOnsiteContent: number) => {
      self.pageSizeOnsiteContent = pageSizeOnsiteContent;
      self.pageNumberOnsiteContent = pageNumberOnsiteContent;
      handleFilterRenderDataOnsiteContent(self.filterKeywordTermOnsiteContent, self.filterListContent, self.pageNumberOnsiteContent, self.pageSizeOnsiteContent, self.sortFieldOnsiteContent, self.sortDirectionOnsiteContent);
    };

    const setSelectedOrderById = (selectedOrderId?: number) => {
      self.selectedOrderId = selectedOrderId ? selectedOrderId : 0;
    };

    const clearOrderDetails = () => self.orderDetails = null;

    const setParentPage = (page?: string) => self.parentPage = page;

    return {
      loadOrders,
      setPlacementFilterData,
      handleTableChangePlacement,
      setFilterKeywordTerm,
      setFilterKeywordTermPlacements,
      onPaginationChangePlacement,
      setSelectedOrderById,
      // handleFilterRenderData,
      handleFilterRenderDataPlacement,
      setFilterKeywordTermOnsiteContent,
      handleTableChangeOnsiteContent,
      onPaginationChangeOnsiteContent,
      handleFilterRenderDataOnsiteContent,
      setSortField,
      setSortDirection,
      setPageNumber,
      setPageSize,
      setOrderTableInit,
      clearOrderTableValues,
      getCategoryDistribution,
      loadOrderDetails,
      setOrderDetailsInitLoading,
      payForOrder,
      orderAgain,
      clearOrderDetails,
      approveOnsite,
      setParentPage,
    };
  });

export type OrdersStoreInstance = Instance<typeof OrdersStore>;

export function initOrdersStore() {
  return OrdersStore.create({
    loading: false,
    ordersData: {},
    placementList: [],
    onsitecontentList: [],
    selectedOrderId: 0,
    sortField: '',
    sortDirection: 'descend',
    filterList: [],
    pageSize: 10,
    filterKeywordColumns: ['description'],
    filterKeywordColumnsPlacement: ['articleTitle', 'actualAnchorText', 'clientName'],
    filterKeywordColumnsOnsiteContent: ['title', 'clientUrl'],
    pageNumber: 1,
    filterKeywordTerm: '',
    filteredRenderData: [],
    tableSize: 0,
    sortFieldPlacement: '',
    sortDirectionPlacement: 'descend',
    filterListPlacement: [],
    pageSizePlacement: 10,
    pageNumberPlacement: 1,
    filterKeywordTermPlacement: '',
    filteredRenderDataPlacement: [],
    tableSizePlacement: 0,
    sortFieldOnsiteContent: '',
    sortDirectionOnsiteContent: 'descend',
    filterListContent: [],
    pageSizeOnsiteContent: 10,
    pageNumberOnsiteContent: 1,
    filterKeywordTermOnsiteContent: '',
    filteredRenderDataOnsiteContent: [],
    tableSizeOnsiteContent: 0,
    orderTableInit: true,
    oderDetailsLoading: false,
    orderPayingLoading: false,
    orderAgainLoading: false,
    oderDetailsInitLoading: true,
    orderDetails: null,
    parentPage: '',
  });
}
