import {Tooltip, Spin, Dropdown, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortDown, faCheckCircle} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {PopUpWrapper, PurpleCheckbox, StatusMenu, StatusWrapper, StyledIssuesCollapse, StyledIssuesTable} from '../../../../style';
import {canDeploy, getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {showNotificationMess} from '../../../../Constants/functions';
import {StyledMenu} from '../../../../Utils/styledMenu';
import {TableTopBar} from '../../../tableTopBar';
import {newNotification} from '@/utils/notification-v3';
import {BulkActionBar} from '../../../bulkActionBar';
import FreezeWrapper from '../../../freezTooltip';

let ReactJson = null;
if (typeof document !== 'undefined') {
  import('react-json-view').then(module => {
    ReactJson = module.default;
  });
}

interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
}

const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
export const OrganizationSchema = observer(({componentIssueType, setPageChanged, issueTable, setIssueTable}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
  }, settings: {customer: {profile: {whitelabelOtto}}},
  } = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (filteredData.length && selectAll) {
      const pageArray = filteredData.map((_, index) => index);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoSection = async (toDeploy: boolean) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueType: url?.issueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(url?.issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(`Changes Deployed Successfully`, 2, 'deploy');
      } else {
        newNotification(`Changes Rolled Back`, 2, 'rollback');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
    }
  };

  const deployOttoUrl = async (id, toDeploy, issueType) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }

    setUrlId(id);
    try {
      const data = {toDeploy, issueType, uuid};
      data['ottoUrls'] = [id];
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setUrlId(-1);
    }
  };


  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  const nlpMenuData = data => {
    const schemaValue = data?.recommendedValue;
    return <StatusMenu style={{padding: 0, width: '520px'}}><PopUpWrapper style={{width: '510px'}}>
      <div className='container'>
        {typeof document !== 'undefined' &&
        Object.keys(schemaValue).length ?
          <ReactJson collapseStringsAfterLength={250} src={schemaValue} displayDataTypes={false} /> :
          <></>
        }
      </div>
    </PopUpWrapper></StatusMenu>;
  };

  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };

  const columns = [
    {
      title: (<div className='column-title'>
        <Dropdown overlay={<StyledMenu disabled={filteredData?.length == 0} selectedIssueType={url?.issueType} deployOttoSection={deployOttoSection} currentProject={currentProject}/>} trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
          <div>
            <span>STATUS</span>
            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12}/>
          </div>
        </Dropdown>
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (_, record, index) => {
        return (
          <FreezeWrapper removeTooltip={record?.isActive}>
            <Tooltip title={canDeploy(record, record?.issueType) ? '' : 'Empty fields cannot be deployed.'}>
              <StatusWrapper isDisabled={!canDeploy(record, record?.issueType)} status={record?.isActive} onClick={() => (canDeploy(record, record?.issueType)) && deployOttoUrl(index, !record?.isActive, record?.issueType)}>
                {urlId === index ? <Spin indicator={antUrlIcon} /> :
                  <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isActive ? `#2AC155` : '#A3A4A4'} />
                }
                <span>{record?.isActive ? 'Deployed' : 'Deploy'}</span>
              </StatusWrapper>
            </Tooltip>
          </FreezeWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>{`${whitelabelOtto} SUGGESTED FIX`} </div>,
      dataIndex: 'proposedFix',
      key: 'proposedFix',
      className: 'greenish-column',
      render: (id, record, index) => {
        return (
          <div style={{width: '100%', maxWidth: '700px', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px'}}>
            {showBulkBanner && <PurpleCheckbox checked={selectedPages.includes(index)} onClick={()=>selectUnselectPages(index)}/>}
            <Dropdown overlay={()=> nlpMenuData(record)} trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span>View Schema</span>
                <FontAwesomeIcon icon={faSortDown} color='#272222' fontSize={12} style={{marginLeft: 10}} />
              </div>
            </Dropdown>
          </div>
        );
      },
    },
  ];
  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map((_, index) => index));
  };


  return (
    <StyledIssuesCollapse
      ghost
      expandIconPosition='right'
      defaultActiveKey={[`${isTableOpen}`]}
      activeKey={[`${isTableOpen}`]}
      onChange={() => {
        if (isTableOpen === 'close') {
          setIsTableOpen('open');
          setIsOpenSearch(false);
        } else {
          setIsTableOpen('close');
          setIsOpenSearch(false);
        }
      }}
    >
      <TableTopBar
        componentIssueType={componentIssueType}
        setPageChanged={setPageChanged}
        setIssueTable={setIssueTable}
        issueTable={issueTable}
        setSearchText={setSearchText}
        searchText={searchText}
        setIsOpenSearch={setIsOpenSearch}
        isOpenSearch={isOpenSearch}
        setIsTableOpen={setIsTableOpen}
        isTableOpen={isTableOpen}
        setIsActiveFilter={setIsActiveFilter}
        isActiveFilter={isActiveFilter}
        setShowBulkBanner={setShowBulkBanner}
      />
      {showBulkBanner ? <BulkActionBar
        count={url?.issueTable?.count}
        setShowBulkBanner={setShowBulkBanner}
        selectedPages={selectedPages}
        setSelectedPages={setSelectedPages}
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        selectAllOnPage={selectAllOnPage}
        issueType={url?.issueType}
        currentProject={currentProject}
        setPageChanged={setPageChanged}
      /> : <></>}
      <Collapse.Panel key='open' header={<></>}>
        <StyledIssuesTable
          loading={ottoUrlLoader && ottoIssueType === componentIssueType}
          columns={columns.filter(column => showBulkBanner ? column.key !== 'status' : true)}
          dataSource={filteredData}
          pagination={false}
          scroll={{x: 850}}
        />
      </Collapse.Panel>
    </StyledIssuesCollapse>
  );
});
