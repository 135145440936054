import React, {useEffect, useRef, useState} from 'react';
import {Container, Label, SectionTitle} from './styles';
import {LinksToInclude} from '../../../page-editor/Drawers/AiWriterSideDrawer/formViews/new-ai-writer/Components/linksToInclude';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';
import {Tooltip} from 'antd';
import useIntersectionObserver from './useIntersectionObserver';

const LinksToIncludeSection = ({linksToIncludeState, setLinksToIncludeState, externalLinksToIncludeState, setExternalLinksToIncludeState, selectedSection, setActiveOption}) => {
  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });
  const [errors, setErrors] = useState({internal: [], external: []});

  const isVisible = !!blockEntry?.isIntersecting;
  useEffect(() => {
    if (isVisible) {
      setActiveOption('links_to_include');
    }
  }, [isVisible]);

  return (
    <Container id='links_to_include' ref={blockref}>
      <div className={selectedSection === 'links_to_include' ? 'selected-bg' : ''} id='container'>
        <div style={{marginBottom: '12px'}}>
          <SectionTitle>LINKS TO INCLUDE</SectionTitle>
          <Label style={{position: 'absolute'}}>Global internal links <Tooltip title='Global internal links will be included in all AI generated articles in this project, unless excluded or overwritten at article level settings.'>
            <FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' />
          </Tooltip>
          </Label>
          <LinksToInclude errors={errors} setErrors={setErrors} calledFromCa isInternalLink state={linksToIncludeState} setState={setLinksToIncludeState} calledFromHero />
        </div>
        <div>
          <Label style={{position: 'absolute'}}>Global external links <Tooltip title='Global external links will be included in all AI generated articles in this project, unless excluded or overwritten at article level settings.'>
            <FontAwesomeIcon icon={faCircleInfo} color='#A3A4A4' />
          </Tooltip>
          </Label>
          <LinksToInclude errors={errors} setErrors={setErrors} calledFromCa isExternalLink state={externalLinksToIncludeState} setState={setExternalLinksToIncludeState} calledFromHero />
        </div>
      </div>
    </Container>
  );
};

export default LinksToIncludeSection;
