import {
  Radio as AntdRadio,
  RadioProps as AntdRadioProps,
} from 'antd';
import styled from 'styled-components';
import {COLORS} from './colors';


interface RadioProps extends AntdRadioProps {
  checked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  disabled?: boolean;
}

export const RadioButton = ({...props}: RadioProps) => {
  return (
    <RadioStyled {...props}>{props.label}</RadioStyled>
  );
};

const RadioStyled = styled(AntdRadio)`
.ant-radio-checked .ant-radio-inner{
  border-color: ${COLORS.purple};
  background-color: ${COLORS.purple};
}

.ant-radio-inner:after {
  background: ${COLORS.white} !important;
}
.ant-radio-inner {
  border-radius:50%; 
  border-color: ${COLORS.white};
  background-color: ${COLORS.white};

}
`;


