import {BaseApi} from '@/api/base-api';
import {getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {GetAllPagesResponse} from '@/components/dashboard/pages/landing-page-optimizer/types';
import {AxiosResponse} from 'axios';

export class KeywordsApi extends BaseApi {
  public async getOverviewPage() {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'GET',
        url: 'target-keywords/',
        // params: query,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async findTargetKeyword(payLoad) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'POST',
        url: 'target-keywords/',
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        data: payLoad,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getTargetKeywordDetail(id) {
    try {
      const headers = {};
      const token = getTokenFromCookies();
      if (token) {
        headers['Authorization'] = getAuthorizationHeader();
      }
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'GET',
        url: `target-keywords/${id}/`,
        headers: headers,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async saveTargetKeyword(id) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'POST',
        url: `target-keywords/${id}/save/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeTargetKeyword(id) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'POST',
        url: `target-keywords/${id}/remove/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getNewArticleDetail(id) {
    try {
      const {data}: AxiosResponse<GetAllPagesResponse> = await this.axios({
        method: 'GET',
        url: `target-keywords/${id}/create-article/`,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
