import {Button, Modal} from '@/components/common-components';
import {Input} from '@/components/common-components/v2';
import {faUserPlus} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {faArrowRight, faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {Form, Select as AntdSelect} from 'antd';
import {useStore} from '@/store/root-store';
import {notification} from '@/utils/notification-v2';


interface Props {
  showModal?: boolean;
  setShowModal?: any;

}

interface FormModel {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  plan: string;
  additionalTeamMembers: any;
}

export const CreateUserModal = observer(({showModal, setShowModal}: Props) => {
  const {settings: {customer: {creatNewUser}}} = useStore('');
  const {Option} = AntdSelect;
  const [form] = Form.useForm<FormModel>();
  const modeOptions = [{name: 'Free', value: 11}, {name: 'Starter', value: 10}, {name: 'Growth', value: 1}, {name: 'Pro', value: 8}];

  const handleFinish = async (values: FormModel)=>{
    if ((typeof(values?.plan)!=='string')) {
      const teamMembersEmails=values?.additionalTeamMembers?.split(',');
      await creatNewUser({...values, additionalTeamMembers: teamMembersEmails});
      form.resetFields();
      setShowModal(false);
      notification.success('Account created successfully', '', '');
    }
  };


  return <ModalStyled
    visible={showModal}
    onClose={() => setShowModal(false)}>
    <Content>
      <FormStyled
        initialValues={{plan: 'free'}}
        form={form}
        name='create-user'
        onFinish={handleFinish}
      >
        <FontAwesomeIcon icon={faUserPlus} style={{color: '#1FAC47'}} fontSize={30} />
        <Title>Create user account</Title>
        <InputContainer>
          <Label>First name</Label>
          <Form.Item name='firstName'>
            <StyledInput />
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Label>Last name</Label>
          <Form.Item name='lastName'>
            <StyledInput />
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Label>Phone number</Label>
          <Form.Item name='phone_number'>
            <StyledInput placeholder='Optional' />
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Label>Email</Label>
          <Form.Item name='userEmail'>
            <StyledInput/>
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Label>Password</Label>
          <Form.Item name='userPassword'>
            <StyledInput type='password' autoComplete='new-password'/>
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Label>Default plan</Label>
          <Form.Item name='plan'>
            <StyledSelect
              value={11}
              dropdownStyle={{backgroundColor: '#F9F9FB', color: '#121212'}}
              defaultValue={11}
              suffixIcon={<FontAwesomeIcon color='#121212' icon={faCaretDown} />}
              style={{minWidth: 130}} onChange={() => { }}>
              {modeOptions?.map((item, index) => {
                return <Option key={index} style={{backgroundColor: '#F9F9FB'}} value={item?.value}> <OptionContent >{item?.name}</OptionContent></Option>;
              },
              )}
            </StyledSelect>
          </Form.Item>
        </InputContainer>
        <InputContainer>
          <Label>Team members</Label>
          <Form.Item name='additionalTeamMembers'>
            <StyledInput placeholder='Divide email by commas' />
          </Form.Item>
        </InputContainer>
        <Example><span>Example: </span>john@mail.com, marry@mail.com</Example>
        <Divider />
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Cancel>Cancel</Cancel>
          <Button
            buttonType='green'
            type='submit'
            color='green'>Create account <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 15}}/></Button>
        </div>
      </FormStyled>
    </Content>

  </ModalStyled>;
});

const ModalStyled = styled(Modal)`
   margin-top: auto;
   margin-bottom: auto;
   width: 425px !important;
   padding-bottom: 20px;
`;
const FormStyled = styled(Form)`
  .ant-form-item {
    margin-bottom: 10px;
  }
 `;

const Content = styled.div`
  
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
  margin: 15px 0px;
`;
const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #121212;
  margin-top: -5px;
`;

const InputContainer = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;

`;

const StyledInput = styled(Input)`
  background: #F9F9FB !important;
  border: 1px solid #E8E8E8 !important;
  border-radius: 5px;
  width: 255px;
  height: 38px;
  margin-top: 0px;
  `;

const StyledSelect = styled(AntdSelect)`
    color: #121212;
  .ant-select-selector {
    width: 255px !important;
    height: 38px !important;
    background-color: #F9F9FB !important;
    border: 1px solid #E8E8E8 !important;
    border-radius: 5px !important;
    padding: 3px 15px !important;
    cursor: pointer !important;
    line-height: unset !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }

 .ant-select-arrow {
  color: #121212;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:#F9F9FB !important;
    border-color: #E8E8E8 !important;
    color: #121212;
  }
`;

const OptionContent = styled.div`
  background-color:#F9F9FB !important;
  border-color: #E8E8E8 !important;
  max-width: 255px;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color:#121212;
  font-weight:400;
`;

const Example = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #4E5156;
  margin-left: 110px;
  margin-top: 8px;
  span{
    font-style: italic;
    color: #A3A4A4;
  }
`;
const Divider = styled.div`
  height: 1px;
  background: #E8E8E8;
  margin-top: 27px;
  margin-bottom: 19px;
`;

const Cancel =styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  color: #4E5156;
`;
