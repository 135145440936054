import React, {LabelHTMLAttributes, ReactNode} from 'react';
import styles from './styles.module.scss';

export type LabelProps = LabelHTMLAttributes<{
  children?: ReactNode;
  id?: any;
}>;

export const Label: React.FC<LabelProps> = ({
  id,
  children,
  htmlFor,
}) => {
  return (
    <label id={id} className={styles.label} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
