import React from 'react';
import {Checkbox, Radio, Select, Tooltip} from 'antd';
import {toJS} from 'mobx';
import {StyledSelect, FooterSection, StyledRadioGroup} from '../../../../style';
import {ChannelsContainer, PublishingBanner} from './pressReleaseStyles';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {faHourglassStart} from '@fortawesome/pro-duotone-svg-icons';

const DistributionChannels = ({selectedDistributionChannels, record, selectedPressCategory, setSelectedPressCategory, updateStackProviders, publishing, dynamicPr, setDynamicPr}) => {
  const {ottoV2Store: {
    distributionChannels,
    categories,
    distributionAgainstUrl,
  },
  } = useStore('');

  const selectedChannels = selectedDistributionChannels?.filter(item => item?.pressId == record?.id);

  let totalCredits = 0;

  for (let i = 0; i < selectedChannels.length; i++) {
    if (selectedChannels[i]?.creditsCost) {
      totalCredits += selectedChannels[i].creditsCost;
    }
  }

  const sortedChannels = toJS(distributionChannels?.results)
    ?.filter(site => !site?.isComingSoon) ?? [];

  const comingSoon = toJS(distributionChannels?.results)
    ?.filter(site => site?.isComingSoon) ?? [];

  const commingSoonObj = {displayName: 'Coming soon', isComingSoon: true, id: -1, creditsCost: null, linksApprox: '', icon: null};
  const tableData = [...sortedChannels, ...[commingSoonObj, ...comingSoon]];

  return (
    <ChannelsContainer>
      <div style={{padding: '0px 25px'}}>
        {publishing ? <PublishingBanner>
          <FontAwesomeIcon icon={faHourglassStart} color='#FF8536' />
          Content is being published to the Distribution Channels.
        </PublishingBanner> : ''}
        <div>
          <div className='checkboxes-heading'>Category</div>
          <StyledSelect
            width='261px'
            height='38px'
            arrowMargin='-20px'
            placeholder='Select category'
            value={selectedPressCategory}
            onChange={value => setSelectedPressCategory(value)}
            // style={{marginLeft: '15px', marginTop: '0px'}}
            suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212' style={{marginRight: '8px'}} />}
          >
            {toJS(categories)?.map(item => (
              <Select.Option value={item?.value} key={item?.value}>{item?.text}</Select.Option>
            ))}
          </StyledSelect>
        </div>
        <div className='sites-list' style={{maxWidth: '100%'}}>
          <table style={{width: '100%'}} cellSpacing={15} cellPadding={5}>
            <tr className='head-row'>
              <th>Distribution channels</th>
              <th></th>
              <th className='links-col'>Hits</th>
              <th className='cost-col'>Cost</th>
            </tr>
            {toJS(tableData)?.map((site, index) => (
              <>{<tr key={index} className='data-row'>
                <td className='checkbox-row '>
                  {site?.id !== -1 && <Checkbox
                    style={{textTransform: 'capitalize', marginRight: '11px'}}
                    onChange={e =>
                      updateStackProviders(site, e.target.checked, record?.id)
                    }
                    checked={
                      site?.displayName == 'Newsmax' ? false:
                        selectedChannels?.some(channel => channel?.channelId === site?.id) ||
                        !!distributionAgainstUrl?.results?.some(
                          item => site?.id === item?.distribution,
                        )
                    }
                    disabled={
                      site?.displayName === 'Newsmax' ||
                      site?.isComingSoon ||
                      !!distributionAgainstUrl?.results?.some(
                        item => site?.id === item?.distribution,
                      ) || record?.status === 'Published' || publishing
                    }
                  />}

                  <span className={site?.id === -1 ? 'comming-soon-display-name' : ''}>
                    {site?.id !== -1 && <img src={site?.icon} width={20} height={20} style={{marginRight: '8px'}} />} {site?.displayName} <span className='unavailable-text'>{site?.displayName === 'Newsmax' ? '(Temporarily unavailable)' : ''}</span>
                  </span>
                </td>
                <td></td>
                <td className='links-col'>{site?.id !== -1 ? site?.linksApprox || '-' : ''}</td>
                <td className='cost-col'>{site?.id !== -1 ? `${site?.creditsCost} credits` : ''}</td>
              </tr>}
              </>
            ))}
          </table>
        </div>
        <div className='checkboxes-heading' style={{marginTop: '19px'}}>Content</div>
        <StyledRadioGroup defaultValue={false} onChange={e => setDynamicPr(e.target.value)} value={dynamicPr}>
          <Radio value={false}>Publish a single article to all channels</Radio>
          <Tooltip
            overlayInnerStyle={{
              width: '500px',
            }}
            title='This feature creates unique content variants of the original press release (PR) for each channel, which are then submitted to each news outlet independently. Since each PR will be seen as unique, this helps to improve search engine results pages (SERPs)'>
            <Radio value={true}>Dynamic Press Release - content variations for the channels (3 credits)</Radio>
          </Tooltip>
        </StyledRadioGroup>
      </div>

      <FooterSection style={{display: 'flex', justifyContent: 'flex-end', marginRight: '10px'}}>
        <div>
          <div className='credits'>
            <div>
              <div className='label'>Publication:</div>
              <div className='label'>Content variations:</div>
              <div className='label'>Editorial review:</div>
            </div>
            <div>
              <div className='value'>{totalCredits} credits</div>
              <div className='value'>{dynamicPr ? '3' : '0'} credits</div>
              <div className='value'>10 credits</div>
            </div>
          </div>
          <div className='divider'/>
          <div className='credits'>
            <div>
              <div className='label'>Total:</div>
            </div>
            <div>
              <div className='value'>{totalCredits + 10 + (dynamicPr ? 3 : 0)} credits</div>
            </div>
          </div>
        </div>
      </FooterSection>
    </ChannelsContainer>
  );
};

export default DistributionChannels;
