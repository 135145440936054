import styled from 'styled-components';
import {Button, Input} from 'antd';

export const Label = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #121212;
`;

export const KGTab = styled.div<{showErrorIndicator?: boolean}>`
position: relative;
padding-left: 3px;
  ${p => p?.showErrorIndicator ? `
    padding-right: 12px;
    &::before {
      content: '';
      width: 7px;
      border-radius: 999px;
      height: 7px;
      background-color: #ff4d4f;
      position: absolute;
      top: 3px;
      right: 100%;
    }
  ` : ''}
`;

export const GenerateContentContainer = styled.div`
  #address_administrative_area {
    margin-bottom: 0 !important;
  }
  .row{
    margin-bottom: 6px;
  }
  .body {
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    width: 100%;
    height: 32px;
    background-color: #ffffff;

    .domain-input-wrapper {
      display: flex;
      align-items: center;
      .domain {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: left;
        color: #A3A4A4;
        padding-left: 10px;
      }
      .slug {
        padding: 5px 0px;

        &.side-gutter {
          padding: 5px 11px;
        }
      }
    }
  }
  .formItem {
    margin-bottom: 0px !important;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  height: 32px;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
`;

export const StyledTextArea = styled(Input.TextArea)`
  width: 100%;
  height: 100px !important;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
`;

export const KnowledgeGraphBanner = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  margin: 10px 0px;
  padding: 6px 10px;
  background-color: ${p => p?.backgroundColor};
  .left {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #121212;
    svg {
      margin-right: 6px;
    }
  }
  svg {
    width: 14px;
    height: 14px;
  }
`;

export const HideandShowButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #2D6CCA;
  cursor: pointer;
`;

export const Desc = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
`;

export const KnowledgeGraphContainer = styled.div`
  /* height: 352px; */
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  box-shadow: 0px 3px 2px 0px #00000005;
  overflow: hidden;
  
  .kg-tabs {
    .ant-tabs-nav-more {
      display: none;
    }
    .ant-tabs-tab-btn:last-child {
      margin-right: 8px !important;
    }
  }

  .modal-tabs {
    .ant-tabs-nav {
      margin: 0 0 8px 0 !important;
      &::before {
        border-bottom: 1px solid #D9D9D9 !important;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab:nth-child(1) {
            margin-left: 13px;
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-family: Inter;
              font-size: 12px;
              font-weight: 500;
              line-height: 14.52px;
              color: #121212;
            }
          }
          .ant-tabs-ink-bar {
            background-color: #2D6CCA !important;
            height: 5px !important;
            border-radius: 4px 4px 0px 0px !important;
          }
          .ant-tabs-tab {
            margin: 0 0 0 20px !important;
            .ant-tabs-tab-btn {
              font-family: Inter;
              font-size: 12px;
              font-weight: 400;
              line-height: 14.52px;
              color: #4E5156;
            }
            &:hover {
              color: #4E5156 !important;
            }
          }
        }
      }
    }
    .ant-tabs-content-holder {
      .ant-tabs-content {
        .ant-tabs-tabpane {
          .input-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            .label {
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-weight: 400;
              color: #4E5156;
            }
            .ant-col {
              .text-area {
                height: 75px;
                border: 1px solid #D2D2D2;
                border-radius: 8px;
                resize: none;
                &:hover {
                  border: 1px solid #D2D2D2 !important;
                }
              }
              .email-input {
                height: 38px;
                border: 1px solid #D2D2D2;
                border-radius: 8px;
              }
              .react-tel-input {
                .support-phone-input {
                  width: 100% !important;
                  height: 38px !important;
                  border-radius: 8px;

                }
                .flag-dropdown {
                  border-radius: 8px 0 0 8px !important;
                }
              }  
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
 }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;

export const ContinueButton = styled(Button)`
  width: 142.25px;
  height: 44px;
  border-radius: 8px;
  background-color: #2D6CCA;
  color: #FFFFFF;
  &:hover, &:focus {
    background-color: #2D6CCA;
    color: #FFFFFF;
  }
`;

export const CancelButton = styled.button`
  background-color: transparent;
  border: 0px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #4E5156;
  cursor: pointer;
`;

export const ViewContentContainer = styled.div<{background: string; height: string}>`
  display: flex;
  gap: 14px;
  width: 100%;
  height: ${p => p.height};
  padding: 20px 18px;
  border-radius: 12px;
  background-color: ${p => p.background};
  box-shadow: 0px 1px 0px 0px rgba(231, 231, 231, 1);
  .title {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;
    color: #121212;
  }
  .desc {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #121212;
  }
  svg {
    width: 21px;
    height: 28px;
  }
  .view-content {
    width: 120px;
    height: 32px;
    margin-top: 24px;
    border-radius: 6px;
    background-color: #18923B;
    border: 0px;
    color: #FFFFFF;
  }
`;

export const Accept = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 23px;
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2D6CCA;
    border-color: #2D6CCA;
  }
`;

export const ChannelsContainer = styled.div`
  .check-buttons {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
    div {
      cursor: pointer;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #2D6CCA;
    }
  }
  .checkboxes-heading {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    margin-bottom: 10px;
  }
  .info-banner {
    padding: 8px 14px;
    border-radius: 8px;
    background-color: rgba(45, 108, 202, 0.15);
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    div {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #121212;
      line-height: 16.94px;
    }
    a {
      font-size: 14px;
      margin-left: auto;
      font-weight: 500;
      line-height: 16.94px;
      color: #2D6CCA;
      border-bottom: 1px solid #2D6CCA;
    }
    .link-placeholder {
      margin-left: auto;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      color: #2D6CCA;
      border-bottom: 1px solid #2D6CCA;
      cursor: 'not-allowed';
    }
  }
  .head-row {
    th {
      text-align: left;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
      color: #121212;
    }
    .links-col, .cost-col {
      text-align: right !important;
    }
  }
  .data-row {
    margin-bottom: 5px;
    .links-col, .cost-col {
      text-align: right;
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.73px;
      color: #4E5156;
    }
    .checkbox-row {
      max-width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .ant-checkbox-checked .ant-checkbox-inner::after {
        display: block !important;
      }
      .ant-checkbox-inner {
      border-radius: 4px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2D6CCA;
        border-color: #2D6CCA;
      }
    }
  }
  .comming-soon-display-name {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #121212;
  }
  .unavailable-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #4E5156;
  }
`;

export const PublishingBanner = styled.div<{background?: string}>`
  height: 35px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${p => p?.background ? p?.background : 'rgba(255, 133, 54, 0.15)'};
  border-radius: 8px;
  padding: 0px 12px;
  margin-bottom: 13px;
  svg {
    width: 12px;
    height: 16px;
  }
`;


export const Links = styled.div`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #121212;
`;
