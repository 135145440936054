import React, {useState, useEffect, useRef} from 'react';
import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import {Radio} from 'antd';
import Button from './../button-component/';

interface Props {
  styleClass?: string;
  options: any;
  filterLabel?: string;
  categories?: string[];
  checkedValues?: string[];
  add?: boolean;
  filtersLength?: number;
  filterId?: number;
  filterName?: string;
  onClose?: () => void;
  removeItem?: () => void;
  onChange?: (filter: any) => void;
  className?: string;
  isClear?: boolean;
  isColumnFilter?: boolean;
  onClear?: ()=>void;
  initialValue?: string;
  absoluteUi?: boolean;
}

const FilterMultipleRadioPopUp: React.FC<Props> = ({absoluteUi=false, isColumnFilter = false, filterLabel, filterId, filterName, options, onClose, onChange, className, isClear, onClear, initialValue}) => {
  const popupRef = useRef<HTMLInputElement>();
  const [filtersSelected, setFiltersSelected] = useState(null);
  const {t} = useTranslation('common');

  useEffect(() => {
    if (initialValue) {
      setFiltersSelected(initialValue);
    } else if (!isColumnFilter) {
      const value = options?.length ? options[0]?.value : '';
      setFiltersSelected(value);
    }
  }, []);

  function checkedOnChange(e) {
    setFiltersSelected(e?.target?.value);
  }
  const disablingButton = () =>{
    if (filtersSelected==null) {
      return true;
    } else {
      return false;
    }
  };
  const createOrUpdateFilter = () => {
    const appliedFilter = {
      active: true,
      customFilterValue: '',
      filterTypes: options,
      header: filterLabel,
      id: filterId,
      name: filterName,
      type: filtersSelected,
    };
    onChange(isColumnFilter ? filtersSelected : appliedFilter);
    onClose();
  };

  const onCancel = () => {
    onClose();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target))) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);


  return (
    <div
      ref={popupRef}
      style={absoluteUi ? {position: 'absolute'} : {}}
      onSubmit={() => createOrUpdateFilter()}
      onClick={e => e.stopPropagation()}
      // style={((add && filtersLength < 4 )|| width< 1000) ? {right: '-130%', left: 'unset'} : {}}
      className={`${styles.filterPopup} ${className}`}>
      {isClear ? <div className={styles.clearBtn} onClick={onClear}>Clear</div> : ''}
      <div className={styles.filterPopupOptions}>
        <Radio.Group className={styles.checkboxesStyle} options={options} value={filtersSelected} onChange={checkedOnChange} />
      </div>
      <div className={styles.filterPopupButtons}>
        <Button
          onClick={onCancel}
          color='gray'
        >
          {t('cancel')}
        </Button>
        <button
          onClick={createOrUpdateFilter}
          className={styles.filterPopupButtonApply}
          disabled={disablingButton()}
          style={disablingButton() ? {cursor: 'not-allowed', opacity: '0.7'}:{}}
        >
          {t('apply-filter')}
        </button>
      </div>
    </div>
  );
};

export default FilterMultipleRadioPopUp;
