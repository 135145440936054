import mixpanel from 'mixpanel-browser';

if (process.env.NODE_ENV === 'production') {
  mixpanel.init(process.env.MIXPANEL_LINKGRAPH_TOKEN, {debug: true});
}
const IMPERSONATE = 'impersonate';

function checkImpersonateUser() {
  if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
    return localStorage.getItem(IMPERSONATE) != '1';
  }
  return false;
}

const actions = {
  identify: id => {
    if (checkImpersonateUser()) mixpanel.identify(id);
  },
  alias: id => {
    if (checkImpersonateUser()) mixpanel.alias(id);
  },
  track: (name, props?: any) => {
    if (checkImpersonateUser()) mixpanel.track(name, props);
  },
  reset: () => {
    if (checkImpersonateUser()) mixpanel.reset();
  },
  register: obj => {
    if (checkImpersonateUser()) mixpanel.register(obj);
  },
  people: {
    set: props => {
      if (checkImpersonateUser()) mixpanel.people.set(props);
    },
  },
};

export const MixPanel = actions;
