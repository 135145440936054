import {useRef} from 'react';
import {ColorButton, HiddenColorPicker} from './styles';

const ColorPicker = ({onChange, color, setColor}) => {
  const colorPickerRef = useRef(null);

  const handleClick = () => {
    colorPickerRef.current.click();
  };

  const handleChange = event => {
    const newColor = event.target.value;
    setColor(newColor);
    if (onChange) {
      onChange(newColor);
    }
  };

  return (
    <div style={{position: 'relative', display: 'inline-block'}}>
      <HiddenColorPicker
        type='color'
        ref={colorPickerRef}
        value={color}
        onChange={handleChange}
      />
      <ColorButton type='button' color={color} onClick={handleClick} />
    </div>
  );
};

export default ColorPicker;
