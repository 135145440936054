import {types, flow, cast} from 'mobx-state-tree';
import {LOCAL_SEO_SCAN_API} from '@/api/local-seo';
import {toJS} from 'mobx';
import {apiError} from '@/utils/api';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import html2canvas from 'html2canvas';
import {getLatestGridShape} from '@/utils/maps';

const PositionModel = types.model({
  type: types.maybeNull(types.string),
  coordinates: types.maybeNull(types.array(types.maybeNull(types.number))),
});

const GmbKwBreakModel = types.model({
  gmbAveragePosition: types.maybeNull(types.union(types.number, types.string)),
  gmbBestPosition: types.maybeNull(types.union(types.number, types.string)),
  gmbCenterPosition: types.maybeNull(types.union(types.number, types.string)),
  gmbWorstPosition: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
});

const DetailModel = types.model({
  businessName: types.maybeNull(types.string),
  gridSize: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  radiusMiles: types.maybeNull(types.number),
  center: types.maybeNull(PositionModel),
  dataCid: types.maybeNull(types.string),
  googleUrl: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  location: types.maybeNull(PositionModel),
  address: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  keywordBreakdown: types.maybeNull(types.array(GmbKwBreakModel)),
});

const LocationModel = types.model({
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
});

const businessSuggestionsModel = types.model({
  address: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
  dataCid: types.maybeNull(types.string),
  extensions: types.maybeNull(types.array(types.string)),
  gpsCoordinates: types.maybeNull(LocationModel),
  position: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  reviews: types.maybeNull(types.number),
  sponsored: types.maybeNull(types.boolean),
  title: types.maybeNull(types.string),
  webResultsLink: types.maybeNull(types.string),
  isPlaceId: types.maybeNull(types.boolean),
});

const latestSnapshotModel = types.model({
  date: types.maybeNull(types.string),
  gmbAveragePosition: types.maybeNull(types.union(types.string, types.number)),
  gmbBestPosition: types.maybeNull(types.union(types.string, types.number)),
  gmbCenterPosition: types.maybeNull(types.union(types.string, types.number)),
  gmbWorstPosition: types.maybeNull(types.union(types.string, types.number)),
});

const gridsModel = types.model({
  gridCoords: types.maybeNull(types.array(types.model({
    lon: types.maybeNull(types.number),
    lat: types.maybeNull(types.number),
  }))),
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  // status: null
  updateFrequencyDays: types.maybeNull(types.number),
  latestSnapshot: types.maybeNull(latestSnapshotModel),
  historicalSnapshot: types.maybeNull(types.array(latestSnapshotModel)),
});

const formatedGridModel = types.model({
  id: types.maybeNull(types.string),
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
});

const serpsModel = types.model({
  address: types.maybeNull(types.string),
  allCategories: types.maybeNull(types.array(types.maybeNull(types.string))),
  category: types.maybeNull(types.string),
  dataCid: types.maybeNull(types.string),
  dataId: types.maybeNull(types.string),
  googleUrl: types.maybeNull(types.string),
  gpsCoordinates: types.maybeNull(types.model({
    longitude: types.maybeNull(types.number),
    latitude: types.maybeNull(types.number),
  })),
  link: types.maybeNull(types.string),
  // opening_hours
  phone: types.maybeNull(types.string),
  placeId: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  price: types.maybeNull(types.union(types.string, types.number)),
  priceParsed: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  reviews: types.maybeNull(types.number),
  thumbnail: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  webResultsLink: types.maybeNull(types.string),
});

const locationReportModel = types.model({
  date: types.maybeNull(types.string),
  gmbPosition: types.maybeNull(types.number),
  gridShape: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  location: types.maybeNull(types.model({
    lon: types.maybeNull(types.number),
    lat: types.maybeNull(types.number),
  })),
  serps: types.maybeNull(types.array(serpsModel)),
});
const locationArrayModel = types.model({
  data: types.maybeNull(types.array(locationReportModel)),
  date: types.maybeNull(types.string),
});

const snapshotModel = types.model({
  date: types.maybeNull(types.string),
  gmbAveragePosition: types.maybeNull(types.number),
  gmbBestPosition: types.maybeNull(types.number),
  gmbCenterPosition: types.maybeNull(types.number),
  gmbWorstPosition: types.maybeNull(types.number),
});

const LocationObj = types.model({
  lat: types.maybeNull(types.number),
  lon: types.maybeNull(types.number),
});

const heatmapKeywordDetail = types.model({
  id: types.number,
  address: types.maybeNull(types.string),
  businessDate: types.maybeNull(types.string),
  businessName: types.maybeNull(types.string),
  gmbAveragePosition: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  lastProcessedAt: types.maybeNull(types.string),
  center: types.maybeNull(types.model({
    coordinates: types.maybeNull(types.array(types.number)),
  })),
  positionLegends: types.maybeNull(types.frozen()),
  snapshot: types.maybeNull(snapshotModel),
  radiusMiles: types.maybeNull(types.number),
  recrawlTime: types.maybeNull(types.string),
  updateFrequencyDays: types.maybeNull(types.number),
});

const perDayObj = types.model({
  name: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

const OpeningHoursObj = types.model({
  current: types.maybeNull(types.string),
  perDay: types.maybeNull(types.array(perDayObj)),
});

const GpsCoordinatesObj = types.model({
  latitude: types.maybeNull(types.number),
  longitude: types.maybeNull(types.number),
});

const CompetitorBusinessObj = types.model({
  phone: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  address: types.maybeNull(types.string),
  data_id: types.maybeNull(types.string),
  reviews: types.maybeNull(types.number),
  category: types.maybeNull(types.string),
  dataCid: types.maybeNull(types.string),
  placeId: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  thumbnail: types.maybeNull(types.string),
  extensions: types.maybeNull(types.array(types.string)),
  googleUrl: types.maybeNull(types.string),
  openingHours: types.maybeNull(OpeningHoursObj),
  allCategories: types.maybeNull(types.array(types.string)),
  gpsCoordinates: types.maybeNull(GpsCoordinatesObj),
  location: types.maybeNull(LocationObj),
  webResultsLink: types.maybeNull(types.string),
});

const CompareDataDetail = types.model({
  competitorBusiness: types.maybeNull(CompetitorBusinessObj),
  competitorBusinessReport: types.maybeNull(types.array(CompetitorBusinessObj)),
  competitorBusinessPositionLegends: types.maybeNull(types.frozen()),
  competitorBusinessDate: types.maybeNull(types.string),
  business: types.maybeNull(CompetitorBusinessObj),
  businessReport: types.maybeNull(types.array(CompetitorBusinessObj)),
  businessPositionLegends: types.maybeNull(types.frozen()),
  businessDate: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  center: types.maybeNull(LocationObj),
  gridShape: types.maybeNull(types.string),
  gridDimensions: types.maybeNull(types.number),
  radiusMiles: types.maybeNull(types.number),
  lastProcessedAt: types.maybeNull(types.string),
});

const locationsReportV2Model = types.model({
  results: types.maybeNull(types.array(locationReportModel)),
  gridShape: types.maybeNull(types.string),
  gridDimensions: types.maybeNull(types.number),
  radiusMiles: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
});

export const LocalSeoScanDetailStore = types.model({
  id: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  detailRepolling: types.boolean,
  loadingDetail: types.boolean,
  loadingDetailV2: types.boolean,
  isExporting: types.boolean,
  isExportingCompare: types.boolean,
  loadingSuggestions: types.boolean,
  businessSuggestions: types.maybeNull(types.array(businessSuggestionsModel)),
  gmbDetail: types.maybeNull(DetailModel),
  gridsData: types.maybeNull(types.array(gridsModel)),
  formatedGridsArray: types.maybeNull(types.array(formatedGridModel)),
  locationsReport: types.maybeNull(types.array(locationArrayModel)),
  locationsReportV2: types.maybeNull(locationsReportV2Model),
  compareCompetitorData: types.maybeNull(CompareDataDetail),
  locationReportObj: types.maybeNull(types.model({
    gridShape: types.maybeNull(types.string),
    gridSize: types.maybeNull(types.string),
    radiusMiles: types.maybeNull(types.number),
    status: types.maybeNull(types.string),
  })),
  selectedDetailData: types.maybeNull(locationArrayModel),
  selectedDetailDataBefore: types.maybeNull(locationArrayModel),
  selectedDetailDataAfter: types.maybeNull(locationArrayModel),
  selectedDateId: types.maybeNull(types.union(types.number, types.string)),
  heatmapKeywordDataLoading: types.boolean,
  refreshNowDataLoading: types.boolean,
  loadingGMBDetail: types.maybeNull(types.boolean),
  loadingCompareData: types.boolean,
  heatmapKeywordData: types.maybeNull(heatmapKeywordDetail),
  loadingCreateBusinessGbp: types.boolean,
  loadingAvailableDates: types.boolean,
  addKeywordClicked: types.boolean,
  availableDatesData: types.maybeNull(types.array(types.model({
    date: types.maybeNull(types.string),
  }))),
  loadingBusinessAddress: types.boolean,
}).views( self => ({
  get getGMBDetail() {
    return toJS(self.gmbDetail);
  },
  get getbusinessSuggestions() {
    return toJS(self.businessSuggestions);
  },
  get getGridsData() {
    return toJS(self.gridsData);
  },
  get getFormatedGridsArray() {
    return toJS(self.formatedGridsArray);
  },
  get getLocationsReport() {
    return toJS(self.locationsReport);
  },
  get getLocationsReportV2() {
    return toJS(self.locationsReportV2);
  },
  get getLocationReportObj() {
    return toJS(self.locationReportObj);
  },
  get getSelectedDetailData() {
    return toJS(self.selectedDetailData);
  },
  get getSelectedDetailDataBefore() {
    return toJS(self.selectedDetailDataBefore);
  },
  get getSelectedDetailDataAfter() {
    return toJS(self.selectedDetailDataAfter);
  },
})).actions(self => {
  const loadGMBDetail = flow(function* (id, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingGMBDetail = true;
    }
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadGMBDetailData(id, publicHash);
      self.gmbDetail = cast(response);
      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingGMBDetail = false;
    }
  });
  const loadCompareData = flow(function* (businessId, gridId, dataCid, selectedDate) {
    self.loadingCompareData = true;
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadCompareData(businessId, gridId, dataCid, publicHash, selectedDate);
      self.compareCompetitorData = cast(response);
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
    } finally {
      self.loadingCompareData = false;
    }
  });
  const loadBusinessSuggestions = flow(function* (data) {
    try {
      self.loadingSuggestions = true;
      const response = yield LOCAL_SEO_SCAN_API.loadBusinessSuggestionData(data);
      if (response?.isCancel) return;
      self.businessSuggestions = cast(response.results);
    } catch (e) {
      self.loadingSuggestions = false;
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.loadingSuggestions = false;
    }
  });
  const loadPlaceIDSuggestions = flow(function* (id) {
    self.loadingSuggestions = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.postBusinessV3ToAdd(id);
      const formattedResponse = [{
        isPlaceId: true,
        address: response?.payload?.address,
        dataCid: response?.payload?.placeId,
        gpsCoordinates: response?.payload?.location?.coordinates?.length ? {
          latitude: response.payload.location.coordinates[0],
          longitude: response.payload.location.coordinates[1],
        } : {
          latitude: null,
          longitude: null,
        },
        title: response?.payload?.businessName,
        category: '',
        extensions: null,
        position: null,
        rating: null,
        reviews: null,
        sponsored: false,
        webResultsLink: null,
      }];

      // if (self.businessSuggestions) {
      //   formattedResponse.unshift(...self.businessSuggestions);
      // }
      self.businessSuggestions = cast(formattedResponse);
    } catch (e) {
      self.businessSuggestions = null;
      const errorMessage = apiError(e) as string;
      if (e?.response?.status !== 400) {
        useErrorNotification({
          e,
          msg: errorMessage,
          handleStatuses: [
            {
              statuses: [400],
              msg: '',
              showDetails: false,
            },
          ],
        });
      }
      return Promise.reject(e);
    } finally {
      self.loadingSuggestions = false;
    }
  });

  const loadAvailableDates = flow(function* (id, gridId, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingAvailableDates = true;
    }
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadAvailableDates(id, gridId, publicHash);
      const sortedData = response?.availableDates?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      self.availableDatesData = sortedData;
      return sortedData;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      self.loadingAvailableDates = false;
    } finally {
      self.loadingAvailableDates = false;
    }
  });

  const loadLocationReport = flow(function* (id, gridId, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingDetail = true;
    }
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadLocationsReport(id, gridId, publicHash);
      if (response?.status == 'PENDING' && self.detailRepolling) {
        yield new Promise(r => setTimeout(r, 3000));
        return loadLocationReport(id, gridId, true);
      } else {
        self.locationsReport = cast(response?.results?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()));
        self.locationReportObj = cast(response);
        self.loadingDetail = false;
        return response;
      }
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      self.loadingDetail = false;
    }
  });

  const loadLocationReportV2 = flow(function* (id, gridId, noLoading?: boolean, date?: string) {
    if (!noLoading) {
      self.loadingDetailV2 = true;
    }
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadLocationsReport(id, gridId, publicHash, date);
      if (response?.status == 'PENDING' && self.detailRepolling) {
        yield new Promise(r => setTimeout(r, 3000));
        return loadLocationReportV2(id, gridId, true, date);
      } else {
        loadGMBDetail(id, true);
        self.locationsReportV2 = cast(response);
        const gridShape = getLatestGridShape(response?.results);
        self.locationsReportV2.gridShape = gridShape;
        self.loadingDetailV2 = false;
        return response;
      }
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      self.loadingDetailV2 = false;
    }
  });

  const deleteDateGrid = flow(function* (businessId, date, id) {
    try {
      const res = yield LOCAL_SEO_SCAN_API.deleteDateGrid(businessId, date, id);
      return res;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
    }
  });

  const getHeatmapKeywordData = flow(function* (businessId, gridId, bDate) {
    self.heatmapKeywordDataLoading = true;
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield LOCAL_SEO_SCAN_API.getHeatmapKeywordData(businessId, gridId, publicHash, bDate);
      if (response?.isCancel) return;
      self.heatmapKeywordData = response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.heatmapKeywordDataLoading = false;
    }
  });

  const refreshNowData = flow(function* (businessId, gridId) {
    self.refreshNowDataLoading = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.refreshNowApi(businessId, gridId);
      if (response?.isCancel) return;
      if (response?.message) {
        notification.info(response?.message, '');
      }
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.refreshNowDataLoading = false;
    }
  });

  const setDetailRepolling = val => {
    self.detailRepolling = val;
  };
  const updateFormatedGridsArray = data => {
    const greatplaces = [];
    if (data?.length) {
      let id = 0;
      data?.forEach(item => item?.gridCoords?.forEach(z => {
        id++,
        greatplaces?.push({
          id: `${id}`,
          lat: z?.lat,
          lng: z?.lon,
        });
      }));
    }
    self.formatedGridsArray = cast(greatplaces);
  };
  const loadGridsData = flow(function* (data) {
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadGridData(data);
      self.gridsData = cast(response?.results || []);
      updateFormatedGridsArray(response?.results);
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const createBusinessGBP = flow(function* (data) {
    self.loadingCreateBusinessGbp = true;
    try {
      yield LOCAL_SEO_SCAN_API.createBusinessFromGBP(data);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingCreateBusinessGbp = false;
    }
  });

  const loadBusinessAddress = flow(function* (placeId) {
    self.loadingBusinessAddress = true;
    try {
      yield LOCAL_SEO_SCAN_API.loadBusinessAddress(placeId);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingBusinessAddress = false;
    }
  });

  const setFormatedGridsArray = data => {
    self.formatedGridsArray = cast(data);
  };
  const setSelectedDetailData = data => {
    self.selectedDetailData = cast(data);
  };
  const setSelectedDetailDataBefore = data => {
    self.selectedDetailDataBefore = cast(data);
  };
  const setSelectedDetailDataAfter = data => {
    self.selectedDetailDataAfter = cast(data);
  };
  const setSelectedDateId = id => {
    self.selectedDateId = id;
  };
  const setIsExporting = id => {
    self.isExporting = id;
  };

  const setisExportingCompare = id => {
    self.isExportingCompare = id;
  };

  const exportSingleNode = flow(function* (node) {
    let JSpdf = null;
    if (typeof window !== 'undefined') {
      import('jspdf').then(module => {
        JSpdf = module.default;
      });
    }
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    yield html2canvas(node, {useCORS: true})
      .then(canvas => {
        canvas.style.display = 'none';
        const image = canvas.toDataURL();
        const pdf = new JSpdf({
          orientation: 'l',
          unit: 'pt',
          format: [canvas.width, canvas.height],
        });
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = Number(pdf.internal.pageSize.getHeight());
        pdf.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST', 0, 'FAST', true);
        pdf.save(`Local-seo-${date}.pdf`);
      });
    setIsExporting(false);
    setisExportingCompare(false);
  });

  const makeLocationsReportV2Null = () => {
    self.locationsReportV2 = null;
  };

  const setAddKeywordClicked = value => {
    self.addKeywordClicked = value;
  };

  return {
    loadGMBDetail,
    deleteDateGrid,
    setSelectedDateId,
    setSelectedDetailData,
    setSelectedDetailDataBefore,
    setSelectedDetailDataAfter,
    setFormatedGridsArray,
    loadGridsData,
    loadBusinessSuggestions,
    loadAvailableDates,
    loadLocationReport,
    loadLocationReportV2,
    getHeatmapKeywordData,
    refreshNowData,
    setDetailRepolling,
    exportSingleNode,
    setIsExporting,
    setisExportingCompare,
    loadCompareData,
    createBusinessGBP,
    loadPlaceIDSuggestions,
    makeLocationsReportV2Null,
    setAddKeywordClicked,
    loadBusinessAddress,
  };
});

export const initLocalSeoScanDetailStore = () => {
  return LocalSeoScanDetailStore.create({
    id: null,
    status: null,
    detailRepolling: false,
    loadingSuggestions: false,
    loadingDetail: false,
    loadingDetailV2: false,
    isExporting: false,
    isExportingCompare: false,
    businessSuggestions: null,
    heatmapKeywordDataLoading: false,
    refreshNowDataLoading: false,
    loadingCompareData: false,
    loadingCreateBusinessGbp: false,
    loadingAvailableDates: false,
    addKeywordClicked: false,
    loadingBusinessAddress: false,
  });
};
