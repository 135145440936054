import React, {PropsWithChildren} from 'react';
import classnames from 'classnames';
import {ProfitContainer} from './profit-container';
import {ArrowDownOutlined} from '@ant-design/icons';

export type LossProps = PropsWithChildren<{
  useChevron?: boolean;
  useCaret?: boolean;
  className?: string;
  currentValue?: number;
  previousValue?: number;
  showTooltip?: boolean;
  secondTooltip?: any;
  tooltipPlacement?:any;
  valueWithoutFormate?:boolean;

}>;

export const Loss: React.FC<LossProps> = ({useChevron, valueWithoutFormate, useCaret, className, tooltipPlacement='bottom', children, currentValue, previousValue, showTooltip, secondTooltip}) => {
  return (
    <ProfitContainer valueWithoutFormate={valueWithoutFormate} isGain={false} showTooltip={showTooltip} tooltipPlacement={tooltipPlacement} currentValue={currentValue} previousValue={previousValue} secondTooltip={secondTooltip}>
      {children == 0 ?
        <div>
          <span style={{color: 'transparent', fontSize: '12px'}} className='text'>{children}</span>
        </div> :
        <span className={classnames('loss', className)}>
          <span className='icon' style={{paddingLeft: '3px', marginTop: 3, marginRight: 3}}>
            {useChevron ? (
              <ArrowDownOutlined color='#F44343' style={{fontSize: 10, marginBottom: 10}}/>
            ) : useCaret ?
              <><ArrowDownOutlined color='#F44343' style={{fontSize: 10, marginBottom: 10}}/>&nbsp;&nbsp;</> :
              <ArrowDownOutlined color='#F44343' style={{fontSize: 10, marginBottom: 10}}/>
            }
          </span>
          <span style={{color: '#F44343', fontSize: '12px', marginTop: '3px'}} className='text'>{children}</span>
        </span>}
    </ProfitContainer>
  );
};
