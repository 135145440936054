import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import {GetTopicalRelevanceData, TaskStatus} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {cast, flow, Instance, types, getParent} from 'mobx-state-tree';
import {RefdomainModel} from './refdomains';
import type {GetCompetitorRefdomainResponse, GetRefdomainResponse} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {BackLinkAnalyzerStoreType} from '.';
import {BannerVariant} from '@/components/common-components/components/banner';
import {
  BACKLINK_API_API_POLL_INTERVAL,
} from '@/constants';
import {categories} from '@/constants/categories';
import {CATEGORIES_COLORS, TOPICAL_RELEVANCE_COLORS} from '@/constants/colors';
import {convertDom} from '@/api/common-utils';

const categoryModel = types.model({
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  score: types.maybeNull(types.number),
});

export const TopicalRelevanceChart = types.model({
  category: types.string,
  score: types.number,
});

const websiteCategoriesModel = types.model({
  categories: types.array(categoryModel),
  topCategory: categoryModel,
  website: types.maybeNull(types.string),
  totalRD: types.maybeNull(types.string),
  color: types.optional(types.string, ''),
}).views(self => ({
  get formattedValue() {
    return {
      hostname: convertDom(self.website),
      totalRd: self.totalRD,
      topCategory: self.topCategory.name,
      color: self.color,
    };
  },
}));

export const RefDomainContent = types.model({
  next: types.maybeNull(types.string),
  pageSize: types.number,
  previous: types.maybeNull(types.string),
  totalPages: types.number,
  refdomainsCount: types.number,
  websiteId: types.number,
  data: types.array(RefdomainModel),
});

export type RefDomainContentType = Instance<typeof RefDomainContent>;

export const TopicalRelevanceStore = types.model({
  refDomainContentCached: types.array(RefDomainContent),
  refDomainContent: types.maybeNull(types.late(() => types.reference(RefDomainContent, {
    set(value) {
      return value.websiteId;
    },
    get(identifier, parent) {
      return parent.refDomainContentCached.find( (content: RefDomainContentType) => content.websiteId === identifier) || {
        websiteId: 0,
        data: [],
      } as RefDomainContentType;
    },
  }))),
  content: types.array(websiteCategoriesModel),
  selectedProject: types.maybeNull(types.string),
  selectedUrls: types.array(types.string),
  loading: types.optional(types.boolean, false),
  daChart: types.array(TopicalRelevanceChart),
  tasksComplete: types.optional(types.boolean, false),
})
  .views(self => ({
    get getAllDomains() {
      return self.content.map(item => convertDom(item.website));
    },
    get allCategories() {
      return categories.map(category => ({
        label: category,
        value: category.toLowerCase(),
      }));
    },
  }))
  .actions(self => {
    const setBannerError = ()=>{
      const parent = getParent<BackLinkAnalyzerStoreType>(self);
      parent.setBanner(true, 'Topical Relevance not actualized.', 'The Topical Relevance data did not load properly. Please refresh your page to fix the issue.', BannerVariant.ERROR);
      self.loading = false;
    };

    const getTopicalRelevanceData = flow(function* (projectPk) {
      try {
        const response = <GetTopicalRelevanceData>(yield BacklinkAnalyzerClient.getTopicalRelevance(projectPk));
        self.tasksComplete = response.status == TaskStatus.SUCCESS;

        if (!self.tasksComplete) {
          yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
          return response;
        } else {
          return response;
        }
      } catch (e) {
        setBannerError();
        return Promise.reject(e);
      }
    });


    const loadCategories = flow(function* (projectPk:number, ordering?:string, page?:number, keyword?:string, filters?:any, categories?:string[]) {
      const parent = getParent<BackLinkAnalyzerStoreType>(self);
      self.loading = true;
      try {
        const dat = <GetRefdomainResponse>(yield BacklinkAnalyzerClient.getRefdomains(projectPk, ordering, page, keyword, filters, categories));
        self.tasksComplete = dat.backlinks == TaskStatus.SUCCESS;

        if (!self.tasksComplete) {
          yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
          return loadCategories(projectPk, ordering, page, keyword, filters, categories);
        } else {
          const newContent = !dat? null: RefDomainContent.create({
            next: dat.next,
            pageSize: dat.pageSize,
            previous: dat.previous,
            totalPages: dat.totalPages,
            refdomainsCount: dat.refdomainsCount,
            websiteId: projectPk,
            data: dat.content,
          });
          const index = self.refDomainContentCached.findIndex(x => x.websiteId === newContent.websiteId);

          if (index === -1) {
            self.refDomainContentCached.push(newContent);
          } else {
            self.refDomainContentCached[index] = newContent;
          }
          const projectStore = parent.projectStore;
          self.selectedProject = projectStore.currentProject?.primaryWebsite.domainName;
          self.refDomainContent = newContent;
          const response = yield getTopicalRelevanceData(projectPk);
          const data = response?.website;
          const content = Object.keys(data ? data : {}).reduce((acc, n, i)=>{
            const color = TOPICAL_RELEVANCE_COLORS[i];
            const categoryColor = CATEGORIES_COLORS[i];
            const categoryDistribution = {
              website: n,
              categories: data[n].categories.map(category =>{
                return {
                  categoryColor,
                  ...category,
                };
              }),
            };
            const parent = getParent<BackLinkAnalyzerStoreType>(self);
            const Rd = parent.projectStore;
            const competitorsData = Rd.currentProject?.competitorWebsites.map(competitor => competitor.formattedValue) || [];
            const primaryWebsiteData = Rd.currentProject?.primaryWebsite.formattedValue;
            const a = [primaryWebsiteData, ...competitorsData];

            return [...acc, {
              categories: data[n].categories,
              topCategory: data[n].topCategory,
              website: n,
              totalRD: a[i]?.rd,
              categoryDistribution,
              color,
            }];
          }, []);

          self.content = cast(content);
          self.loading = false;
        }
      } catch (e) {
        setBannerError();
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const updateRefdomains = flow(function* (id: number, competitorPk, ordering?:string, page?:number, keyword?:string, categories?:string[]) {
      self.loading = true;
      try {
        const data = <GetCompetitorRefdomainResponse>(yield BacklinkAnalyzerClient.getCompetitorRefdomains(id, competitorPk, ordering='-domain_rating', page=1, keyword='', categories));
        self.tasksComplete = data.backlinks == TaskStatus.SUCCESS;

        if (!self.tasksComplete) {
          yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
          return updateRefdomains(id, competitorPk, ordering, page, keyword, categories);
        } else {
          const newContent = RefDomainContent.create({
            next: data.next,
            pageSize: data.pageSize,
            previous: data.previous,
            totalPages: data.totalPages,
            refdomainsCount: data.refdomainsCount,
            websiteId: competitorPk,
            data: data.content,
          });

          const index = self.refDomainContentCached.findIndex(x => x.websiteId === newContent.websiteId);

          if (index === -1) {
            self.refDomainContentCached.push(newContent);
          } else {
            self.refDomainContentCached[index] = newContent;
          }
          self.refDomainContent = newContent;
          self.loading = false;
        }
      } catch (e) {
        self.loading = false;
        throw e;
      }
    });
    const exportToCSV = flow(function* (pk: number, selectedUrls?: string[]) {
      const data = yield BacklinkAnalyzerClient.getTopicalCSV(pk, selectedUrls);
      return data;
    });

    const selectCurrentProject = (project:string) =>{
      self.selectedProject = project;
    };

    const toggleSelect = (url: string) => {
      const index = self.selectedUrls.indexOf(url);
      index === -1 ? self.selectedUrls.push(url) : self.selectedUrls.splice(index, 1);
    };

    return {
      loadCategories,
      updateRefdomains,
      selectCurrentProject,
      toggleSelect,
      exportToCSV,
    };
  });

export type TopicalRelevanceStoreInstance = Instance<typeof TopicalRelevanceStore>;

export const initTopicalRelevanceStore = () =>{
  return {
    refDomainContentCached: [],
    refDomainContent: null,
    content: [],
    selectedProject: '',
    selectedUrls: [],
    loading: true,
    daChart: [],
    tasksComplete: false,
  };
};
