import {BaseApi} from '@/api/base-api';

class CountriesApi extends BaseApi {
  private static readonly baseUrl: string = `https://restcountries.com/v3.1`;

  public async getAll() {
    const queryParam = {
      'fields': 'name,flags,code,languages,cca2,currencies',
    };
    try {
      const response = await this.axios.get(`${CountriesApi.baseUrl}/all/`, {
        params: queryParam,
        headers: {},
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default CountriesApi;
