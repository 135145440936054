import {types} from 'mobx-state-tree';

// const PageViewerUserModel = types.model({
//   id: types.number,
//   name: types.string,
//   isStaff: types.boolean,
//   isMe: types.boolean,
// });

// const PageViewerModel = types.model({
//   user: PageViewerUserModel,
//   isEditor: types.boolean,
// });

// const SerpKeywordVolumeModel = types.model({
//   language: types.string,
//   cmp: types.maybeNull(types.number),
//   cpc: types.maybeNull(types.number),
//   sv: types.maybeNull(types.number),
//   fetchedAt: types.maybeNull(types.string),
//   fetchingData: types.maybeNull(types.boolean),
// });

// const SerpKeywordModel = types.model({
//   volume: types.maybeNull(SerpKeywordVolumeModel),
//   q: types.string,
//   latestSerpResultsAt: types.maybeNull(types.string),
// });


export const DialogModel = types.model({
  isOpen: types.optional(types.boolean, false),
  uuid: types.maybeNull(types.string),
});
