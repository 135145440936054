import styled from 'styled-components';

export const BtnStyled = styled.div<{color: string; rightAlign: string}>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 20px;
  font-size: 12px;
  color: ${p => p?.color};
  border-radius: 6px;
  top: 4px;
  right: ${p => p?.rightAlign};
  cursor: pointer;
`;

export const FoldersWrapper = styled.div`
  margin: 10px 0 10px 0;
`;

export const FoldersRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #121212;
  width: 100%;
  height: 35px;
  border-radius: 8px;
`;

export const FolderName = styled.div`
  margin-left: 14px;
`;

export const FolderValueWrapper = styled.div`
  display: flex;
`;

export const FolderValue = styled.div`
  margin-right: 70px;
`;
