import {PAGES_API} from '@/api/content-optimizer';
import {cast, flow, getRoot, Instance, types} from 'mobx-state-tree';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {BACKLINK_RESEARCHER_API_POLL_INTERVAL} from '@/constants';
import {apiError} from '@/utils/api';
import {convertKeysToSnakeCase} from '@/utils/string';

export const seoMetatagsModel = types.model({
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

export const csv = types.model({
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
});

export const bulkSeoData = types.model({
  companyName: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  processedRowcount: types.maybeNull(types.number),
  processingEndedAt: types.maybeNull(types.string),
  processingStartedAt: types.maybeNull(types.string),
  rowcount: types.maybeNull(types.number),
  processingStatus: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
});
const topicalMapResults = types.model({
  id: types.maybeNull(types.number),
  clustersCount: types.maybeNull(types.number),
  contentPage: types.maybeNull(types.string),
  contentTopic: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  formattedData: types.maybeNull(types.union(types.array(types.frozen({})), types.frozen({}))),
  googleSpreadsheetUrl: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  languageCode: types.maybeNull(types.string),
  ottoProject: types.maybeNull(types.string),
  processingEndedAt: types.maybeNull(types.string),
  processingStartedAt: types.maybeNull(types.string),
  project: types.maybeNull(types.number),
  taskStatus: types.maybeNull(types.string),
  titlesCount: types.maybeNull(types.number),
});
export const topicalMapData = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  results: types.maybeNull(types.array(topicalMapResults)),
});

export const results = types.model({
  description: types.maybeNull(types.string),
  h1: types.maybeNull(types.string),
  title: types.maybeNull(types.string),

});

export const bulkSeoDataDetail = types.model({
  companyName: types.maybeNull(types.string),
  keywords: types.maybeNull(types.string),
  pageUrl: types.maybeNull(types.string),
  results: types.maybeNull(types.array(results)),
  title: types.maybeNull(types.string),

});

const paramsModel = types.model({
  page: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
});

export const ContentIdeas = types.model({
  id: types.maybeNull(types.number),
  wordsArray: types.maybeNull(types.array(types.string)),
  csvModel: types.maybeNull(types.array(csv)),
  cpc: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  metadataIdeas: types.maybeNull(types.array(types.string)),
  loading: types.boolean,
  shouldRepoll: types.boolean,
  noRepolling: types.boolean,
  keyword: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  loadingContentIdeas: types.boolean,
  bulkSeoDataLoading: types.maybeNull(types.boolean),
  bulkSeoDetailLoading: types.maybeNull(types.boolean),
  bulkSeoDataDetail: types.maybeNull(types.array(bulkSeoDataDetail)),
  bulkSeoData: types.maybeNull(types.array(bulkSeoData)),
  topicalMapDataLoading: types.boolean,
  loadingSingleTopicalMap: types.boolean,
  singleTopicalMapData: types.maybeNull(topicalMapResults),
  topicalMapData: types.maybeNull(topicalMapData),
  topicalMapParams: paramsModel,
  createTopicalMapDataLoading: types.maybeNull(types.boolean),
  drawerId: types.maybeNull(types.number),
  drawerKeyword: types.maybeNull(types.string),
  contentQuotaError: types.maybeNull(types.string),
  detailListApiCallCheck: types.maybeNull(types.boolean),
  createBulkSeoDataLoading: types.maybeNull(types.boolean),
  activeTab: types.string,
  publicShareHash: types.maybeNull(types.string),
  publicShareHashValue: types.maybeNull(types.string),

})
  .views(self => ({
    get wordsArr() {
      return [...self.wordsArray];
    },
    get getArticleId() {
      return self.id;
    },
    get getArticleKeyword() {
      return self.keyword;
    },
    get getTopicalMapDataList() {
      return toJS(self.topicalMapData);
    },
    get getSingleTopicalMapData() {
      return toJS(self.singleTopicalMapData);
    },
    get getTopicalParams() {
      return self.topicalMapParams;
    },
    get getTopicalMapParams() {
      return self.topicalMapParams;
    },
  }))
  .actions(self => {
    const setTopicalMapData = data => {
      self.topicalMapData = data;
    };
    const setTopicalMapParams = (params: {page: number; pageSize: number}) => {
      self.topicalMapParams = params;
    };
    const loadContentIdeasDetails = flow(function* (keyword: string, countryCode: string, id: string) {
      try {
        const data = yield PAGES_API.getContentIdeasDetails(keyword, countryCode, id);
        self.shouldRepoll = data.shouldRepoll;
        if (data?.contentIdeas?.length > 0) {
          self.keyword = data?.keyword;
          self.wordsArray = cast(data?.contentIdeas);
          self.csvModel = cast(data?.contentIdeas?.map(item => ({
            title: item?.split('\n')[0],
            text: item?.replace(/^([^\n]+)$/ig, '').replace('\n', ''),
          })));
          self.publicShareHash=data?.publicShareHash;

          self.id = data?.id;
          self.loadingContentIdeas = false;
        }
        if (self.shouldRepoll) {
          yield new Promise(r => setTimeout(r, 2000));
          return loadContentIdeasDetails(keyword, countryCode, id);
        } else {
          // self.keyword = cast(data?.keyword);
          // self.wordsArray = cast(data?.contentIdeas);
          self.cpc = data?.cpc;
          self.sv = data?.sv;
          self.loading = false;
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });
    const loadContentIdeasDetailsPublic = flow(function* (keyword: string, countryCode: string, id: string, hash: string) {
      try {
        const data = yield PAGES_API.loadContentIdeasDetailsPublic(keyword, countryCode, id, hash);
        self.shouldRepoll = data.shouldRepoll;
        if (data?.contentIdeas?.length > 0) {
          self.keyword = data?.keyword;
          self.wordsArray = cast(data?.contentIdeas);
          self.csvModel = cast(data?.contentIdeas?.map(item => ({
            title: item.split('\n')[0],
            text: item.split('\n')[1],
          })));
          self.id = data?.id;
          self.loadingContentIdeas = false;
        }
        if (self.shouldRepoll) {
          yield new Promise(r => setTimeout(r, 2000));
          return loadContentIdeasDetailsPublic(keyword, countryCode, id, hash);
        } else {
          // self.keyword = cast(data?.keyword);
          // self.wordsArray = cast(data?.contentIdeas);
          self.cpc = data?.cpc;
          self.sv = data?.sv;
          self.loading = false;
        }
      } catch (e) {
        if (e.status === 406) {
          notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
        }
      }
    });
    const loadContentIdeasData = flow(function* (keyword: string, countryCode: string, isPublic?: boolean) {
      self.loading = true;
      self.loadingContentIdeas = true;
      try {
        if (!isPublic) {
          const response = yield PAGES_API.getContentIdeas(keyword, countryCode, isPublic);
          if (response.id) {
            yield loadContentIdeasDetails(keyword, countryCode, response.id);
          }
        }
      } catch (e) {
        self.loadingContentIdeas = false;
        self.loading = false;
        const {status} = e.response;
        if (status === 429) {
          const rootStore = getRoot(self) as any;
          rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
          const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
          const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
          notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
        } else if (status === 406) {
          notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
        } else {
          const errorMessage = apiError(e) as string;
          notification.error('', errorMessage);
        }
      }
    });

    const createArticle = flow(function* (keyword: string, content: string, id: number) {
      try {
        const response = yield PAGES_API.createArticleFromCi(keyword, content, id);
        if (response) {
          self.url = response?.url;
        }
      } catch (e) {
        if (e?.response?.status == 429) {
          notification.error('', e?.data?.message);
        }
        return Promise.reject(e);
      }
    });

    const objectsEqual = (o1, o2) =>
      typeof o1 === 'object' && o1 !== null && Object?.keys(o1)?.length > 0 ?
        Object?.keys(o1)?.length === Object?.keys(o2)?.length &&
        Object?.keys(o1)?.every(p => objectsEqual(o1[p], o2[p])) :
        o1 === o2;

    const arraysEqual = (a1, a2) =>
      a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]));
    const getBulkSeoData = flow(function* () {
      self.bulkSeoDataLoading = true;
      try {
        const response = yield PAGES_API.getBulkSeoData();
        if (!response?.isCancel) {
          const check = arraysEqual(response, toJS(self.bulkSeoData));
          if (!check) {
            self.bulkSeoData = response;
          }
          const metricsStatus = response?.filter(item => !['FAILURE', 'SUCCESS'].includes(item?.processingStatus));
          if (metricsStatus?.length && self.detailListApiCallCheck) {
            yield new Promise(r => setTimeout(r, BACKLINK_RESEARCHER_API_POLL_INTERVAL));
            return getBulkSeoData();
          } else {
            self.bulkSeoData = response;
            self.bulkSeoDetailLoading = false;
          }
        }
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.bulkSeoDataLoading = false;
      }
    });

    const getBulkSeoDetail = flow(function* (id: number, hash?:string, isPublic?:boolean) {
      self.bulkSeoDetailLoading = true;
      try {
        if (isPublic && hash) {
          const response =yield PAGES_API.getBulkSeoDataPublic(id, hash);
          self.bulkSeoDataDetail = response;
        } else {
          const response = yield PAGES_API.getBulkSeoDetail(id);
          self.bulkSeoDataDetail = response;
        }
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.bulkSeoDetailLoading = false;
      }
    });


    const createBulkSeoData = flow(function* (formData) {
      self.createBulkSeoDataLoading = true;
      try {
        const response = yield PAGES_API.createBulkSeoData(formData);
        self.bulkSeoData = response;
      } catch (e) {
        if (e?.response?.status == 429) {
          self.contentQuotaError = e?.response?.data?.message;
        } else if (e?.response?.status == 400) {
          if (e?.response?.data?.file) {
            self.contentQuotaError = e?.response?.data?.file[0];
          } else {
            self.contentQuotaError = e?.response?.data?.nonFieldErrors[0];
          }
        } else if (e?.response?.status == 500) {
          self.contentQuotaError = 'Server Eroor 500';
        }
        return e?.response;
      } finally {
        self.createBulkSeoDataLoading = false;
      }
    });

    const getTopicalMapData = flow(function* (stopLoading?: boolean) {
      if (!stopLoading) {
        self.topicalMapDataLoading = true;
      }
      try {
        const params = convertKeysToSnakeCase(self.topicalMapParams);
        const response = yield PAGES_API.getTopicalMapData(params);
        if (response?.isCancel) return;
        const metricsStatus = response?.results?.filter(item => ['PENDING'].includes(item?.taskStatus));
        const aiGenerationStatusIsPending = !metricsStatus.length ? Array.isArray(response?.results) ? response?.results?.some(topicMap =>
          Array.isArray(topicMap?.formattedData) && topicMap?.formattedData?.some(data =>
            data?.keywords?.some(keyword =>
              keyword?.titles?.some(title => (!title?.pageUuid && title?.aiGenerationStatus === 'PENDING')),
            ),
          ),
        ): false : false;
        if (metricsStatus?.length || aiGenerationStatusIsPending) {
          self.topicalMapData = cast(response);
          self.topicalMapDataLoading = false;
          yield new Promise(r => setTimeout(r, BACKLINK_RESEARCHER_API_POLL_INTERVAL));
          getTopicalMapData(true);
        } else {
          self.topicalMapData = cast(response);
          self.topicalMapDataLoading = false;
        }
      } catch (e) {
        self.topicalMapDataLoading = false;
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      }
    });

    const loadSingleTopicMapData = flow(function* (id: number, stopLoading?: boolean) {
      if (!stopLoading) {
        self.loadingSingleTopicalMap = true;
      }
      try {
        const response = yield PAGES_API.getSingleTopicMapData(id);
        if (response?.isCancel) return;
        self.singleTopicalMapData = cast(response);
        self.loadingSingleTopicalMap = false;
      } catch (e) {
        self.loadingSingleTopicalMap = false;
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      }
    });

    const createTopicalMapData = flow(function* (formData) {
      self.createTopicalMapDataLoading = true;
      try {
        const response = yield PAGES_API.createTopicalMapData(formData);
        self.createTopicalMapDataLoading = false;
        return response;
      } catch (e) {
        if (e?.response?.status == 429) {
          self.contentQuotaError = e?.response?.data?.message;
        } else if (e?.response?.status == 400) {
          self.contentQuotaError = e?.response?.data?.nonFieldErrors?.[0];
        } else if (e?.response?.status == 500) {
          self.contentQuotaError = 'Server Eroor 500';
        }
        return e?.response;
      } finally {
        self.createTopicalMapDataLoading = false;
      }
    });
    const deleteTopicalMapData = flow(function* (id: number) {
      self.createTopicalMapDataLoading = true;
      try {
        const response = yield PAGES_API.deleteTopicalMapData(id);
        if (response.taskStatus === 'SUCCESS') {
          getTopicalMapData();
        }
      } catch (e) {
        if (e?.response?.status == 500) {
          self.contentQuotaError = 'Server Eroor 500';
        }
        return e?.response;
      } finally {
        self.createTopicalMapDataLoading = false;
      }
    });

    const seoRepollingMetaTagsData = flow(function* (id) {
      self.loading = true;
      try {
        const response = yield PAGES_API.getSEOMetaTagsRepolling(id);
        if (response.shouldRepoll) {
          yield new Promise(r => setTimeout(r, 2000));
          return seoRepollingMetaTagsData(response?.id);
        } else {
          self.metadataIdeas = response?.metadataIdeas;
          self.id = response?.id;
          self.publicShareHash = response?.publicShareHash;
          self.loading = false;
        }
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const seoRepollingMetaTagsPublicData = flow(function* (id, hash: string) {
      self.loading = true;
      try {
        const response = yield PAGES_API.getSEOMetaTagsRepollingPublic(id, hash);
        if (response.shouldRepoll) {
          yield new Promise(r => setTimeout(r, 2000));
          return seoRepollingMetaTagsPublicData(response?.id, hash);
        } else {
          self.metadataIdeas = response?.metadataIdeas;
          self.id = response?.id;
          self.publicShareHash = response?.publicShareHash;
          self.loading = false;
        }
      } catch (e) {
        Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const stopRepolling = () => {
      self.noRepolling = true;
    };
    const setDrawerId = (value: number) => {
      self.drawerId = value;
    };
    const setDrawerPublicHash = (value: string) => {
      self.publicShareHashValue = value;
    };
    const setDrawerKeyword = (value: string) => {
      self.drawerKeyword = value;
    };
    const setContentError = (value: string) => {
      self.contentQuotaError = value;
    };
    const setDetailApiCallCheck = value => {
      self.detailListApiCallCheck = value;
    };
    const loadSEOMetaTagsData = flow(function* (companyInfo, isPublic?: boolean) {
      self.loading = true;
      try {
        const response = yield PAGES_API.getSEOMetaTags(companyInfo, isPublic);
        if (response.shouldRepoll) {
          yield new Promise(r => setTimeout(r, 2000));
          if (!isPublic) {
            return seoRepollingMetaTagsData(response?.id);
          }
        } else {
          self.metadataIdeas = response?.metadataIdeas;
          self.id = response?.id;
          self.publicShareHash = response?.publicShareHash;

          self.loading = false;
        }
      } catch (e) {
        const statusCode = e?.response?.status;
        if (statusCode === 429) {
          notification.error('Reached maximum quota', 'You have hit the limit by generating 5 AI content fragments. Please upgrade your plan to add more.', true);
        }
        return Promise.reject(e);
      } finally {
        self.loading = false;
      }
    });

    const clearContentIdeas = () => {
      self.wordsArray.length = 0;
    };

    const setActiveTab = (tab: string) => self.activeTab = tab;

    return {
      loadContentIdeasDetailsPublic,
      seoRepollingMetaTagsPublicData,
      createBulkSeoData,
      stopRepolling,
      loadContentIdeasData,
      loadSEOMetaTagsData,
      clearContentIdeas,
      createArticle,
      getBulkSeoData,
      getBulkSeoDetail,
      getTopicalMapData,
      loadSingleTopicMapData,
      createTopicalMapData,
      setTopicalMapData,
      deleteTopicalMapData,
      setDrawerKeyword,
      setDrawerId,
      setDrawerPublicHash,
      setDetailApiCallCheck,
      setContentError,
      setActiveTab,
      setTopicalMapParams,
    };
  });

export const initContentIdeas = () => {
  return ContentIdeas.create({
    loading: false,
    loadingContentIdeas: false,
    shouldRepoll: true,
    wordsArray: [],
    cpc: null,
    sv: null,
    metadataIdeas: [],
    keyword: '',
    noRepolling: false,
    activeTab: '1',
    loadingSingleTopicalMap: false,
    topicalMapDataLoading: false,
    topicalMapParams: {
      page: 1,
      pageSize: 10,
    },
  });
};

export type ContentIdeasStore = Instance<typeof ContentIdeas>;
