import {SORTED_COUNTRY_CODES_LIST} from '@/constants/countryCodes';

export const DEFAULT_PARAMS = {
  page_size: 100,
  page: 1,
  ordering: '',
  search: '',
  type: '',
};
export const DEFAULT_PARAMS_POSITION = {
  page_size: 100,
  page: 1,
  ordering: '',
  search: '',
  type: '',
};
const keywordCustomFields = [
  {label: 'Contains', operator: 'contains'},
  {label: 'Word match', operator: 'word_match'},
  {label: 'Exact match', operator: 'exact_match'},
  {label: 'Begins with', operator: 'begins_with'},
  {label: 'Ends with', operator: 'ends_with'},
  {label: 'Does not contain', operator: 'does_not_contain'},
];
const urlCustomFields = [
  {label: 'Contains', operator: 'contains'},
  {label: 'Exact match', operator: 'exact_match'},
  {label: 'Begins with', operator: 'begins_with'},
  {label: 'Ends with', operator: 'ends_with'},
  {label: 'Does not contain', operator: 'does_not_contain'},
];
export const ORGANIC_KEYWORD_FILTER = [
  {id: 1, name: 'exclude', header: 'Keyword', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'checkboxes', header: 'Intent', customCategoryType: 'search_intent', filterTypes: [
    {label: 'Informational', value: 'informational'},
    {label: 'Navigational', value: 'navigational'},
    {label: 'Commercial', value: 'commercial'},
    {label: 'Transactional', value: 'transactional'},
  ], type: undefined, active: false},
  {id: 3, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false,
    customOptionsTop: [
      {name: 'Top 3', min: '0', max: '4'},
      {name: 'Top 10', min: '0', max: '11'},
      {name: 'Top 20', min: '0', max: '21'},
      {name: 'Top 50', min: '0', max: '51'},
    ], customOptions: [
      {name: '#1', equal: '1'},
      {name: '#4-10', min: '4', max: '11'},
      {name: '#11-20', min: '11', max: '21'},
      {name: '#21-50', min: '21', max: '51'},
      {name: '#51-100', min: '51', max: '101'},
    ]},
  {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 5, name: 'search_volume', header: 'Volume', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 7, name: 'difficulty', header: 'KD', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: 'Very Hard', min: '85', max: '100', showPercent: true, info: 'It will take a lot of on-page SEO, link-building, and content promotion efforts.'},
    {name: 'Hard', min: '70', max: '84', showPercent: true, info: 'You will need lots of high-quality ref. domains and optimized content.'},
    {name: 'Difficult', min: '50', max: '69', showPercent: true, info: 'You will need lots of high-quality ref. domains and optimized content.'},
    {name: 'Possible', min: '30', max: '49', showPercent: true, info: 'You will need well-structured and unique content.'},
    {name: 'Easy', min: '15', max: '29', showPercent: true, info: 'You will need well-structured and unique content.'},
    {name: 'Low', min: '0', max: '14', showPercent: true, info: 'You will need well-structured and unique content.'},
  ]},
  {id: 8, name: 'exclude', header: 'Url', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: urlCustomFields},
  {id: 9, name: 'checkboxes', header: 'SERP Features', customCategoryType: 'serp_feature', filterTypes: [
    {label: 'Knowledge Panel', value: 'knowledge_panel'},
    {label: 'Local Pack', value: 'local_pack'},
    {label: 'Top Stories', value: 'top_stories'},
    {label: 'Image Pack', value: 'image_pack'},
    {label: 'Sitelinks', value: 'sitelinks'},
    {label: 'Reviews', value: 'reviews'},
    {label: 'Videos', value: 'videos'},
    {label: 'Featured Video', value: 'featured_video'},
    {label: 'Featured Snippet', value: 'featured_snippet'},
    {label: 'Image', value: 'image'},
    {label: 'Video Carousel', value: 'video_carousel'},
    {label: 'People also ask', value: 'people_also_ask'},
    {label: 'FAQ', value: 'faq'},
    {label: 'AI Overview', value: 'ai_overview'},
  ], type: undefined, active: false},
  {id: 10, name: 'radioButtonsFilter', header: 'Positions Type', customCategoryType: 'positions_type', filterTypes: [
    {label: 'All', value: 'all'},
    {label: 'Organic', value: 'organic'},
    {label: 'SERP Features', value: 'serpFeatures'},
  ], type: undefined, active: false},
];

export const PAID_KEYWORD_FILTER = [
  {id: 1, name: 'exclude', header: 'Keyword', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  // {id: 2, name: 'checkboxes', header: 'Intent', customCategoryType: 'search_intent', filterTypes: [
  //   {label: 'Informational', value: 'informational'},
  //   {label: 'Navigational', value: 'navigational'},
  //   {label: 'Commercial', value: 'commercial'},
  //   {label: 'Transactional', value: 'transactional'},
  // ], type: undefined, active: false},
  {id: 3, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false,
    customOptionsTop: [
      {name: 'Top 3', min: '0', max: '4'},
      {name: 'Top 10', min: '0', max: '11'},
      {name: 'Top 20', min: '0', max: '21'},
      {name: 'Top 50', min: '0', max: '51'},
    ], customOptions: [
      {name: '#1', equal: '1'},
      {name: '#4-10', min: '4', max: '11'},
      {name: '#11-20', min: '11', max: '21'},
      {name: '#21-50', min: '21', max: '51'},
      {name: '#51-100', min: '51', max: '101'},
    ]},
  {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 5, name: 'search_volume', header: 'Volume', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 7, name: 'difficulty', header: 'KD', from: undefined, to: undefined, type: undefined, active: false},
  {id: 8, name: 'cmp', header: 'CMP', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 9, name: 'exclude', header: 'Url', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: urlCustomFields},
  {id: 10, name: 'traffic_cost', header: 'Costs', from: undefined, to: undefined, type: undefined, active: false},
  {id: 11, name: 'results', header: 'Results', from: undefined, to: undefined, type: undefined, active: false},
];

export const ORGANIC_POSITIONS_FILTER = [
  {id: 1, name: 'exclude', header: 'Keyword', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'checkboxes', header: 'Intent', customCategoryType: 'search_intent', filterTypes: [
    {label: 'Informational', value: 'informational'},
    {label: 'Navigational', value: 'navigational'},
    {label: 'Commercial', value: 'commercial'},
    {label: 'Transactional', value: 'transactional'},
  ], type: undefined, active: false},
  {id: 3, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false, customOptionsTop: [
    {name: 'Top 3', min: '0', max: '4'},
    {name: 'Top 10', min: '0', max: '11'},
    {name: 'Top 20', min: '0', max: '21'},
    {name: 'Top 50', min: '0', max: '51'},
  ], customOptions: [
    {name: '#1', equal: '1'},
    {name: '#4-10', min: '4', max: '11'},
    {name: '#11-20', min: '11', max: '21'},
    {name: '#21-50', min: '21', max: '51'},
    {name: '#51-100', min: '51', max: '101'},
  ]},
  {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 5, name: 'search_volume', header: 'Volume', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 7, name: 'difficulty', header: 'KD', from: undefined, to: undefined, type: undefined, active: false},
  {id: 8, name: 'exclude', header: 'Url', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: urlCustomFields},
  {id: 9, name: 'checkboxes', header: 'SERP Features', customCategoryType: 'serp_feature', filterTypes: [
    {label: 'Knowledge Panel', value: 'knowledge_panel'},
    {label: 'Local Pack', value: 'local_pack'},
    {label: 'Top Stories', value: 'top_stories'},
    {label: 'Image Pack', value: 'image_pack'},
    {label: 'Sitelinks', value: 'sitelinks'},
    {label: 'Reviews', value: 'reviews'},
    {label: 'Videos', value: 'videos'},
    {label: 'Featured Video', value: 'featured_video'},
    {label: 'Featured Snippet', value: 'featured_snippet'},
    {label: 'Image', value: 'image'},
    {label: 'Video Carousel', value: 'video_carousel'},
    {label: 'People also ask', value: 'people_also_ask'},
    {label: 'FAQ', value: 'faq'},
  ], type: undefined, active: false},
];

export const PAID_POSITIONS_FILTER = [
  {id: 1, name: 'exclude', header: 'Keyword', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'checkboxes', header: 'Intent', customCategoryType: 'search_intent', filterTypes: [
    {label: 'Informational', value: 'informational'},
    {label: 'Navigational', value: 'navigational'},
    {label: 'Commercial', value: 'commercial'},
    {label: 'Transactional', value: 'transactional'},
  ], type: undefined, active: false},
  {id: 3, name: 'position', header: 'Position', from: undefined, to: undefined, type: undefined, active: false, customOptionsTop: [
    {name: 'Top 3', min: '0', max: '4'},
    {name: 'Top 10', min: '0', max: '11'},
    {name: 'Top 20', min: '0', max: '21'},
    {name: 'Top 50', min: '0', max: '51'},
  ], customOptions: [
    {name: '#1', equal: '1'},
    {name: '#4-10', min: '4', max: '11'},
    {name: '#11-20', min: '11', max: '21'},
    {name: '#21-50', min: '21', max: '51'},
    {name: '#51-100', min: '51', max: '101'},
  ]},
  {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 5, name: 'search_volume', header: 'Volume', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 7, name: 'difficulty', header: 'KD', from: undefined, to: undefined, type: undefined, active: false},
  {id: 8, name: 'cmp', header: 'CMP', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 9, name: 'exclude', header: 'Url', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: urlCustomFields},
];

export const ORGANIC_PAGES_FILTER = [
  {id: 1, name: 'exclude', header: 'Page URL', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
  {id: 3, name: 'traffic_pct', header: 'Traffic %', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
];

export const PAID_PAGES_FILTER = [
  {id: 1, name: 'exclude', header: 'Page URL', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
  {id: 3, name: 'traffic_pct', header: 'Traffic %', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
];

export const ADS_COPY_FILTER = [
  {id: 1, name: 'exclude', header: 'Title', filterField: 'title', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'exclude', header: 'Description', filterField: 'description', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 3, name: 'exclude', header: 'URL', filterField: 'url', text: '', type: undefined, active: false, isSearch: true, customFields: urlCustomFields},
];

export const ADS_HISTORY_FILTER = [
  {id: 1, name: 'exclude', header: 'Keyword', filterField: 'keyword', text: '', type: undefined, active: false, isSearch: true, customFields: keywordCustomFields},
  {id: 2, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false, isDecimals: true},
  {id: 3, name: 'search_volume', header: 'Search volume', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
  {id: 4, name: 'traffic', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'}]},
];

const languages = [
  {language: 'English', code: 'en'},
  {language: 'Afrikaans', code: 'af'},
  {language: 'Albanian', code: 'sq'},
  {language: 'Amharic', code: 'am'},
  {language: 'Arabic', code: 'ar'},
  {language: 'Armenian', code: 'hy'},
  {language: 'Azerbaijani', code: 'az'},
  {language: 'Basque', code: 'eu'},
  {language: 'Belarusian', code: 'be'},
  {language: 'Bengali', code: 'bn'},
  {language: 'Bulgarian', code: 'bg'},
  {language: 'Catalan', code: 'ca'},
  {language: 'Chinese', code: 'zh'},
  {language: 'Corsican', code: 'co'},
  {language: 'Croatian', code: 'hr'},
  {language: 'Czech', code: 'cs'},
  {language: 'Danish', code: 'da'},
  {language: 'Dutch', code: 'nl'},
  {language: 'Esperanto', code: 'eo'},
  {language: 'Estonian', code: 'et'},
  {language: 'Finnish', code: 'fi'},
  {language: 'French', code: 'fr'},
  {language: 'Galician', code: 'gl'},
  {language: 'Georgian', code: 'ka'},
  {language: 'German', code: 'de'},
  {language: 'Gujarati', code: 'gu'},
  {language: 'Haitian', code: 'ht'},
  {language: 'Hausa', code: 'ha'},
  {language: 'Hebrew', code: 'he'},
  {language: 'Hindi', code: 'hi'},
  {language: 'Hungarian', code: 'hu'},
  {language: 'Icelandic', code: 'is'},
  {language: 'Igbo', code: 'ig'},
  {language: 'Indonesian', code: 'id'},
  {language: 'Irish', code: 'ga'},
  {language: 'Italian', code: 'it'},
  {language: 'Japanese', code: 'ja'},
  {language: 'Javanese', code: 'jv'},
  {language: 'Kannada', code: 'kn'},
  {language: 'Kazakh', code: 'kk'},
  {language: 'Kinyarwanda', code: 'rw'},
  {language: 'Korean', code: 'ko'},
  {language: 'Kurdish', code: 'ku'},
  {language: 'Lao', code: 'lo'},
  {language: 'Latin', code: 'la'},
  {language: 'Latvian', code: 'lv'},
  {language: 'Lithuanian', code: 'lt'},
  {language: 'Luxembourgish', code: 'lb'},
  {language: 'Macedonian', code: 'mk'},
  {language: 'Malagasy', code: 'mg'},
  {language: 'Malayalam', code: 'ml'},
  {language: 'Maltese', code: 'mt'},
  {language: 'Maori', code: 'mi'},
  {language: 'Marathi', code: 'mr'},
  {language: 'Mongolian', code: 'mn'},
  {language: 'Norwegian', code: 'no'},
  {language: 'Persian', code: 'fa'},
  {language: 'Polish', code: 'pl'},
  {language: 'Portuguese', code: 'pt'},
  {language: 'Romanian', code: 'ro'},
  {language: 'Russian', code: 'ru'},
  {language: 'Samoan', code: 'sm'},
  {language: 'Serbian', code: 'sr'},
  {language: 'Shona', code: 'sn'},
  {language: 'Sindhi', code: 'sd'},
  {language: 'Sinhala', code: 'si'},
  {language: 'Slovak', code: 'sk'},
  {language: 'Slovenian', code: 'sl'},
  {language: 'Somali', code: 'so'},
  {language: 'Spanish', code: 'es'},
  {language: 'Sundanese', code: 'su'},
  {language: 'Swedish', code: 'sv'},
  {language: 'Tajik', code: 'tg'},
  {language: 'Tamil', code: 'ta'},
  {language: 'Tatar', code: 'tt'},
  {language: 'Telugu', code: 'te'},
  {language: 'Thai', code: 'th'},
  {language: 'Turkish', code: 'tr'},
  {language: 'Turkmen', code: 'tk'},
  {language: 'Ukrainian', code: 'uk'},
  {language: 'Urdu', code: 'ur'},
  {language: 'Uzbek', code: 'uz'},
  {language: 'Vietnamese', code: 'vi'},
  {language: 'Welsh', code: 'cy'},
  {language: 'Xhosa', code: 'xh'},
  {language: 'Yiddish', code: 'yi'},
  {language: 'Yoruba', code: 'yo'},
  {language: 'Zulu', code: 'zu'},
];

export const BACKLINKS_FILTER = [
  {id: 1, name: 'exclude', header: 'Page title or URL', filterField: 'backlink', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Contains', operator: 'contains'}]},
  {id: 2, name: 'radioButtonsFilter', header: 'Link placement', customCategoryType: 'position', filterTypes: [
    {label: 'Content', value: 'content'},
    {label: 'Header', value: 'header'},
    {label: 'Footer', value: 'footer'},
    {label: 'Sitewide', value: 'sitewide'},
  ], type: undefined, active: false},
  {id: 3, name: 'radioButtonsFilter', header: 'Ref. page platform', customCategoryType: 'platform', filterTypes: [
    {label: 'Ecommerce', value: 'ecommerce'},
    {label: 'Wiki', value: 'wiki'},
    {label: 'Blog', value: 'blog'},
    {label: 'Message board', value: 'mb'},
    {label: 'CMS', value: 'cms'},
  ], type: undefined, active: false},
  {id: 4, name: 'radioButtonsFilter', header: 'Ref. page language', customCategoryType: 'lang', filterTypes: languages?.map(z => {
    return {
      label: z?.language,
      value: z?.code,
    };
  }), type: undefined, active: false},
  {id: 5, name: 'exclude', header: 'Ref. domains', filterField: 'refdomain', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Equals to', operator: 'equals'}]},
  {id: 6, name: 'exclude', header: 'Anchors', filterField: 'anchor', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Contains', operator: 'contains'}, {label: 'Equals to', operator: 'equals'}]},
  {id: 7, name: 'radioButtonsFilter', header: 'Type', customCategoryType: 'type', filterTypes: [
    {label: 'Text', value: 'text'},
    {label: 'Image', value: 'image'},
    {label: 'Form', value: 'form'},
    {label: 'Frame', value: 'frame'},
  ], type: undefined, active: false},
  {id: 8, name: 'exclude', header: 'IP', filterField: 'ip', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Equals to', operator: 'equals'}]},
  {id: 9, name: 'exclude', header: 'Target url', filterField: 'targetPage', text: '', type: 'targetPage', active: false, isSearch: true, customFields: [{label: 'Equals', operator: 'equals'}]},
];
export const REF_DOMAINS_FILTER = [
  {id: 1, name: 'authority', header: 'Domain authority', from: undefined, to: undefined, type: undefined, active: false, maxLimit: 100},
  {id: 2, name: 'exclude', header: 'Referring domain', filterField: 'refdomain', text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Contains', operator: 'contains'},
    {label: 'Does not contain', operator: 'does_not_contain'},
  ]},
  {id: 3, name: 'radioButtonsFilter', header: 'Category', customCategoryType: 'category', filterTypes: [
    {label: 'Business & Industrial', value: 'Business & Industrial'},
  ], type: undefined, active: false},
  {id: 4, name: 'exclude', header: 'IP', filterField: 'ip', text: '', type: undefined, active: false, isSearch: true, customFields: [{label: 'Equals to', operator: 'equals'}]},
  {id: 6, name: 'exclude', header: 'Target url', filterField: 'targetPage', text: '', type: 'targetPage', active: false, isSearch: true, customFields: [{label: 'Equals', operator: 'equals'}]},
  {id: 5, name: 'radioButtonsFilter', header: 'Country', customCategoryType: 'country', filterTypes: SORTED_COUNTRY_CODES_LIST?.map(z => {
    return {
      label: z?.countryName,
      value: z?.countryCode,
    };
  }), type: undefined, active: false},
];
export const ANCHORS_FILTER = [
  {id: 1, name: 'exclude', header: 'Filter by Anchor', filterField: 'anchor', text: '', type: undefined, active: false, isSearch: true, customFields: [
    {label: 'Contains', operator: 'contains'},
    {label: 'Exact match', operator: 'exact_match'},
    {label: 'Does not contain', operator: 'does_not_contain'},
  ]},
];
export const OUT_DOMAINS_FILTER = [
  {id: 1, name: 'authority', header: 'Authority Score', from: undefined, to: undefined, type: undefined, active: false, maxLimit: 100},
  // {id: 2, name: 'exclude', header: 'Domain', filterField: 'domain', text: '', type: undefined, active: false, isSearch: true, customFields: [
  //   {label: 'Contains', operator: 'contains'},
  //   {label: 'Equals', operator: 'equals'},
  //   {label: 'Does not contain', operator: 'does_not_contain'},
  // ]},
  {id: 3, name: 'radioButtonsFilter', header: 'Category', customCategoryType: 'categories', filterTypes: [
    {label: 'Business & Industrial', value: 'Business & Industrial'},
  ], type: undefined, active: false},
];
