import React, {useState} from 'react';
import classnames from 'classnames';
import {Col, Form, Row} from 'antd';
import {MailOutlined, LoadingOutlined} from '@ant-design/icons';

import {CommonInput, Label, Button, Captcha} from '@/components/common-components';
import {AccountHeader} from '../components';
import {profileApi} from '@/api/account';
import {notification} from '@/utils/notifications';


import css from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import Router from 'next/router';

interface Props {
  openRegisterPage?: (value) => void;
  className?: string;
  isEmailPage?: boolean;
  icon?: string;
}


export const ForgotPasswordSection: React.FC<Props> = ({openRegisterPage, isEmailPage, className, icon}) => {
  const [loading, setLoading] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const {t} = useTranslation('common');
  const onForgotPassword = async () => {
    try {
      setLoading(true);
      setResponseError('');
      const {success, response} = await profileApi.forgotPassword(emailAddress, captchaToken);
      if (success) {
        notification.success('Email sent with your new password!');
        if (!isEmailPage) {
          openRegisterPage(false);
        }
      } else {
        setResponseError(response ? response : 'Error trying to recover password');
      }
    } finally {
      setLoading(false);
      setCaptchaToken(''); // reset token
    }
  };

  return (
    <div className={className}>
      <AccountHeader
        titleText={t('reset-password')}
        subtitleText={t('recieve-email-instructions')}
        icon={icon}
      />

      <Form className={css.form} onFinish={onForgotPassword}>
        <Label className={classnames(css.label, {[css.errorInput]: responseError})}>{t('email-address')}</Label>
        <Form.Item name='emailAddress'>
          <CommonInput
            inputType='email'
            placeholder={t('email-address')}
            value={emailAddress}
            onChange={e => {
              setEmailAddress(e.target.value);
              setResponseError('');
            }}
            className={classnames(css.inputOverrides, {[css.errorInput]: responseError})}
            iconClasses={classnames(css.inputIcon, 'emailIcon')}
            icon={<MailOutlined/>}
          />
          <div className={css.errorInput} hidden={!responseError && responseError.length > 0}>
            <p>{responseError}</p>
          </div>
          <Row justify='center'>
            <Col>
              <Captcha
                currentToken={captchaToken}
                onChange={token => setCaptchaToken(token)}
              />
            </Col>
          </Row>
        </Form.Item>

        <div>
          <Button
            type='submit' size='lg'
            disabled={!captchaToken || loading}
            buttonType='transparent-with-border'
            className={classnames(css.forgotBtn, 'customForgotBtnStyle')}
            style={{width: '100%'}}
          >{t('reset-password')}
            <LoadingOutlined className={css.loading} hidden={!loading}/>
          </Button>
        </div>
        <div style={{display: 'flex', marginTop: '15px'}}>
          {t('already-have-account')}&nbsp;<div style={{color: 'rgb(24, 144, 255)', cursor: 'pointer'}} onClick={isEmailPage ? () => {
            Router.push('/login');
          } : () => openRegisterPage(false)}>{t('log-in')}</div>
        </div>
      </Form>
    </div>
  );
};

