import React, {useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import styled from 'styled-components';

const CustomTextarea: React.FC<any> = ({...props}) => {
  const mainWrapperRef = useRef(null);
  const textAreaRef = useRef(null);
  const numberAreaRef = useRef(null);
  const [showCounting, setShowCounting] = useState(false);

  const inputChanged = objTxt=> {
    setShowCounting(objTxt.value.length !== 0);
    const objRownr = numberAreaRef.current;
    const cntline = countLines(objTxt.value);
    const tmpArr = objRownr.value.split('\n');
    const cntlineOld = parseInt(tmpArr[tmpArr.length - 1], 10);
    if (cntline != cntlineOld) {
      objRownr.cols = cntline.toString().length;
      populateRownr(objRownr, cntline);
    }
    if (props?.autoSize && objTxt.scrollHeight < 500) {
      objTxt.style.height = '38px';
      objRownr.style.height = '38px';
      objTxt.style.height = objTxt.scrollHeight + 'px';
      objRownr.style.height = objTxt.scrollHeight + 'px';
    }
  };
  const populateRownr =(obj, cntline)=> {
    let tmpstr = '';
    for ( let i = 1; i <= cntline; i++) {
      tmpstr = tmpstr + i.toString() + '.\n';
    }
    obj.value = tmpstr;
  };
  const countLines = txt=> {
    if (txt == '') {
      return 0;
    }
    return txt.split('\n').length;
  };
  const scrollChanged = objTxt=> {
    const objRownr = numberAreaRef.current;
    scrollsync(objTxt, objRownr);
  };
  const scrollsync =(obj1, obj2)=> {
    obj2.scrollTop = obj1.scrollTop;
  };
  useEffect(()=>{
    inputChanged(textAreaRef.current);
  });
  const ele = document.getElementById('message');
  ele?.addEventListener('keydown', function(e) {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
    }
  });
  useEffect(() => {
    if (props?.focus && textAreaRef?.current) {
      textAreaRef?.current.focus();
    }
  }, [props?.focus, textAreaRef?.current]);
  return (

    <>
      <div className={classnames(
        {
          [styles.light]: props.variant === 'light',
          [styles.white]: props.variant === 'white',
          [styles.dark]: props.variant === 'dark',
          [styles.gray]: props.variant === 'gray',
          [styles.transparent]: props.variant === 'transparent',
          [styles.custom]: props.variant === 'custom',
          [styles.customLinks]: props.variant === 'customLinks',
          // [styles.greenBoxShadow]: props.boxShadow === 'green',
        },
        styles.customTextarea,
      )} style={props?.margin ? {margin: props.margin, border: props?.focus ? `3px solid ${props?.focus}99` : ''} : {border: props?.focus ? `3px solid ${props?.focus}99` : ''}} ref={mainWrapperRef}>
        {<textarea id='message'
          ref={numberAreaRef} style={{
            paddingTop: props?.paddingToNumber,
            minHeight: props?.minHeight,
            maxHeight: props?.maxHeight,
            width: props?.hideNumbers ? '0px' : props?.orderListWidth,
            display: !showCounting ? 'none' : (props?.hideNumbers ? 'none' : 'block')}}
          className={`${styles.rownr} number-textarea`}
          rows={props?.rows ? props?.rows : 8}
          cols={3}
          value={1}
          readOnly = {true} >
        </textarea>}
        <span>
          <TextAreaStyled
            autoFocus={props.focus ? true : false}
            shadowColor={props.focus}
            id='message'
            {...props}
            style={{
              paddingTop: props?.paddingToText,
              paddingLeft: !showCounting ? '15px' : props?.hideNumbers ? '10px' : props?.orderListWidth ? props?.orderListWidth : '45px',
              minHeight: props?.minHeight,
              maxHeight: props?.maxHeight,
              maxWidth: props.isKeyWordv2? props?.maxWidth : '',
              width: props?.width,
              resize: props.isKeyWordv2 ? 'vertical': 'none',
              marginLeft: props.isKeyWordv2 ? '5px': ' '}}
            className={styles.txt}
            rows={props?.rows ? props?.rows : 8}
            cols={props?.cols ? props?.cols : 30}
            autoComplete='off' autoCorrect='off' autoCapitalize='off' spellCheck='false'
            ref={textAreaRef}
            onScroll={() => {
              scrollChanged(textAreaRef.current);
            }}
            onInput={()=>{
              inputChanged(textAreaRef.current);
            }}
            onFocus={() => {
              // sets box shadow to main wrapper, if one is set as a prop
              if (props.boxShadow && mainWrapperRef && mainWrapperRef?.current) {
                mainWrapperRef.current.style.boxShadow = props.boxShadow;
              }
            }}
            onBlur={() => {
              // removes box shadow from main wrapper
              if (mainWrapperRef && mainWrapperRef?.current) {
                mainWrapperRef.current.style.boxShadow = '0 0 0 0 transparent';
              }
            }}
          >
            {props.value}
          </TextAreaStyled>
        </span>
      </div>
    </>

  );
};

export {CustomTextarea};

const TextAreaStyled = styled.textarea<{shadowColor?: string; isHorizontalScroll?: boolean}>`
  ${p => p?.isHorizontalScroll ? `
    max-width: 405px;
    overflow-x: auto !important;
    white-space: nowrap;  
  ` : ''}

  &:focus {
    box-shadow: 0 0 0 2px ${p => p?.shadowColor ? p.shadowColor : 'rgb(24 144 255 / 20%)'};
  }
`;
