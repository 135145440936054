
import {useStore} from '@/store/root-store';
import {TemplatesList} from './List';
import {AiForm} from './Forms/aiForm';
import styled from 'styled-components';
import {Results} from './Results';
import styles from '../style.module.scss';
import {observer} from 'mobx-react';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {useTranslation} from 'next-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {isContentGeneratorPublic} from '@/utils/url';

interface Props {
  showPageTitle?: boolean;
  setShowPageTitle?: (value: boolean) => void;
  htmlToDraft: any;
  settingsModalVisible: boolean;
  setSettingsModalVisible: (value: boolean) => any;
  templatesList: any[];
  setTemplatesList: (value: any[]) => any;
  initialTemplatesList: any[];
  setInitialTemplatesList: (value: any[]) => any;
  searchQuery: string;
  setSearchQuery: (value: string) => any;
  selectedCategories: string[];
  setSelectedCategories: (value: string[]) => any;
}
/**
 * List and results wrapper +
 * Controller that switches between forms and templates list
 * Settings modal props are passed since we need them in main Drawer component to be able to close on modal close
 *
 * @param {boolean} showPageTitle used for default forms
 * @param setShowPageTitle method to set show page title boolean from defualt forms
 * @param settingsModalVisible boolean that decides if settings modal should appear or not
 * @param setSettingsModalVisible method to set settings modal to appear or dissapear
 * @param templatesList list of template
 * @param setTemplatesList sets slist of templates
 * @param initialTemplatesList initial list of templates that only updates once
 * @param setInitialTemplatesList sets initial list of templates
 * @param {string} searchQuery value of the search input
 * @param setSearchQuery sets value of the search input
 * @param {string[]} selectedCategories list of selected categories
 * @param setSelectedCategories sets list of selected categories
 */
export const AiTemplates = observer((
  {
    showPageTitle,
    setShowPageTitle,
    htmlToDraft,
    settingsModalVisible,
    setSettingsModalVisible,
    templatesList,
    setTemplatesList,
    initialTemplatesList,
    setInitialTemplatesList,
    searchQuery,
    setSearchQuery,
    selectedCategories,
    setSelectedCategories,
  }: Props) => {
  const {contentOptimizer: {aiOutline}} = useStore('');

  const {settings: {customer: {setShowScaTopUpModal, profile: {quotaUtilization}}}} = useStore('');

  const {t} = useTranslation('common');

  const onDrawerCloseIconClick = () => {
    aiOutline.setIsOpenAiContentToolsDrawer(false);
  };

  const switchDrawers = () => {
    aiOutline.setIsOpenAiContentToolsDrawer(false);
    aiOutline.setIsOpenAiWriterDrawer(true);
  };

  return <Wrapper>
    <TemplatesContainer>
      <div className={styles.contentIdeasDrawerContent}>
        <div className={styles.contentIdeasTitle}>
          {!aiOutline.aiTemplatesController?.showForm && <div onClick={() => switchDrawers()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 10, cursor: 'pointer', fontSize: 16}}/>
          </div>}
            AI Content Generator
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
          {!isContentGeneratorPublic() ? <QuotaInfoDetail consumedData={quotaUtilization?.ca?.allowedAiContentGeneration?.consumed}
            totalData= {quotaUtilization?.ca?.allowedAiContentGeneration?.total} heading={t('ai-quota')}
            description={t('ai-outlines-quota-desc')}
            ctaLabel={t('top-up-quota')}
            showCta={quotaUtilization?.ca?.allowedAiContentGeneration?.consumed >= quotaUtilization?.ca?.allowedAiContentGeneration?.total}
            isCenter={true}
            customCta={() => {
              setShowScaTopUpModal(true);
            }}
          /> : <div style={{color: '#435156'}}>Quota: 3 uses refreshed every 3 days</div>}
          {!aiOutline?.aiTemplatesController?.isStandaloneTool ? <CloseIconContainer onClick={() => onDrawerCloseIconClick()}>
            <FontAwesomeIcon icon={faXmark}
              fontSize={24}
              style={
                {
                  display: aiOutline.isOpenAiContentToolsDrawer ? 'block' : 'none',
                  color: '#a3a4a4',
                }}/>
          </CloseIconContainer> : null}

        </div>

      </div>
      {/* renders tenoplates list and forms  */}
      {aiOutline?.aiTemplatesController?.showForm ?
        <AiForm showPageTitle={showPageTitle}
          setShowPageTitle={setShowPageTitle}/> :
        <TemplatesList setSettingsModalVisible={setSettingsModalVisible}
          settingsModalVisible={settingsModalVisible}
          templatesList={templatesList}
          setTemplatesList={setTemplatesList}
          initialTemplatesList={initialTemplatesList}
          setInitialTemplatesList={setInitialTemplatesList}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}/>}
    </TemplatesContainer>

    {/* Only render results if template form is opened, so results are not visible when looking at template list */}
    {aiOutline.aiTemplatesController?.showForm ? <ResultsContainer>
      {/* renders results and history tabs */}
      <Results htmlToDraft={htmlToDraft} />
    </ResultsContainer> : null}

  </Wrapper>;
});

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ResultsContainer = styled.div`
  padding: 25px 25px 25px 0;
  width: 6000px;
`;
const TemplatesContainer = styled.div`
  padding: 25px;
  width: 6000px;
`;
const CloseIconContainer = styled.div`
  width: 30px;
  height: auto;
  cursor: pointer;
  margin-left: 20px;
`;
