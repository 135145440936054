import styles from '@/components/layoutV2/header/style.module.scss';
import {
  DatePicker,
  Tooltip,
} from 'antd';
import {ChevronDown, ChevronUp} from 'react-feather';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import {GSC_INSIGHTS_UPDATE_DATE_RANGE} from '@/constants/events';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import {NextImg} from '@/utils/nextImg';

import {MixPanel} from '@/utils/mixpanel';
import {HOME_UPGRADE_CTA_CLICKED} from '@/constants/events';
import {Button} from '@/components/common-components';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {toJS} from 'mobx';
const {RangePicker} = DatePicker;
interface Props {
  initialDateRange?: string;
  setDropDownSa?: (value)=>void;
  isDropDownSa?: boolean;
}

export const RenderDateRangePicker: React.FC<Props> = observer(({setDropDownSa}) => {
  const {t} = useTranslation('common');
  const {
    plans: {showSidebarPaymentDrawer},
  } = useStore('');
  const [defaultDate, setDefaultDate] = useState([
    moment().subtract(1, 'years').subtract(1, 'days'),
    moment().subtract(1, 'days'),
  ]);

  const {gsc,
    navBar: {isDockedSider},
    settings: {customer: {profile}}} = useStore('');
  const [dateRange, setDateRange] = useState('');
  const [showDateRange, setShowDateRange] = useState('');
  const router = useRouter();
  const initialDateRange = gsc?.criteria?.periodStatus || '1 year';
  useEffect(() => {
    setDateRange(initialDateRange);
    if (initialDateRange?.includes('month') || initialDateRange?.includes('year')) {
      setShowDateRange('Last ' + initialDateRange);
    }
  }, [initialDateRange]);

  useEffect(() => {
    if (gsc.criteria.currentPeriodStart) {
      setDateRangeVisible(false);
      setDefaultDate([
        moment(gsc.criteria.currentPeriodStart),
        moment(gsc.criteria.currentPeriodEnd),
      ]);
    }
  }, [gsc.criteria.currentPeriodStart, gsc.criteria.currentPeriodEnd]);

  const [isPickerOpen, setPickerOpen] = useState(false);

  const {plan} = profile;
  const ActionsRangePicker = useRef(null);
  const [dateRangeTooltip, setDateRangeTooltip] = useState(false);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  const clickHandler = function(e) {
    const path = e?.path || (e?.composedPath && e?.composedPath());
    const isInPath = path?.includes(ActionsRangePicker.current);
    if (!isInPath) {
      setDateRangeVisible(false);
      setDateRangeTooltip(false);
    }
  };
  useEffect(() => {
    if (router?.pathname?.includes('/reports')) {
      gsc?.coreReports?.setIsLoadingCoreReports(false);
    }
  }, [router?.pathname]);

  const renderTooltip = () =>{
    return (
      <>
        {!gsc?.isBannerVisible ?
          <>
            <p> {t('select-custom-dates')}</p>
            <Button
              className={styles.upgradeButton}
              onClick={() => {
                showSidebarPaymentDrawer();

                MixPanel.track(HOME_UPGRADE_CTA_CLICKED);
              }}
            >
              {t('upgrade-to-pro')}
            </Button>
          </> :
          <span>Please wait until data import is completed</span>
        }
      </>
    );
  };

  const dateRangePickerClickHandler = event => {
    event.stopPropagation();
    setDropDownSa(false);
    if (plan) {
      setDropDownSa(false);
      setDateRangeVisible(true);
    } else {
      setDateRangeTooltip(true);
    }
  };

  const handleSetDates = value => {
    if (showDateRange) {
      setShowDateRange(value);
    } else {
      setTimeout(() => {
        setShowDateRange(value);
      }, 500);
    }
  };

  return (
    <div className={styles.ranger} style={{border: `${dateRangeVisible ? '1px solid #4e5156': ''}`, background: `${dateRangeVisible ? 'rgba(84, 86, 90, 0.17)': ''}`, borderRadius: `${dateRangeVisible ? '5px': ''}`}}>
      <div className={`${styles.rangerButton} ${isPickerOpen && plan ? styles.rangerButtonOpen : ''}`}
        ref={ActionsRangePicker}
      >
        <div onClick={e => dateRangePickerClickHandler(e)} style={{display: 'flex'}}><NextImg src='/icons/calendarline.svg' width={25} height={40} />
          <div style={{margin: '3px 0 0 13px'}}>
            <Tooltip placement='bottom' title={renderTooltip} trigger='click'
              visible={!plan && dateRangeTooltip}
              overlayClassName={styles.upgradeTooltip}
            >
              {isDockedSider || dateRangeVisible ? <>
                <RangeHeading className={!isDockedSider ? styles.close : styles.open}>
                  {t('date-range')}
                </RangeHeading>
                <DateRangeInText onClick={e => dateRangePickerClickHandler(e)}>{toJS(gsc?.coreReports?.loading) ? '' : showDateRange}</DateRangeInText>

                <RangePicker
                  picker={'date'}
                  open={dateRangeVisible}
                  className={`${styles.pickerHeader} ${!isDockedSider ? styles.closed : styles.opened}`}
                  style={{visibility: showDateRange ? 'hidden' : 'visible'}}
                  dropdownClassName={`${!isDockedSider ? styles.closed : styles.opened} ${dateRange === '1 month' ?
                    styles.customPickerOneMonth :
                    dateRange === '3 months' ? styles.customPickerThreeMonths :
                      dateRange === '6 months' ? styles.customPickerSixMonths :
                        dateRange === '1 year' ? styles.customPickerOneYear :
                          styles.customPicker}`}
                  format={'DD MMM YYYY'}
                  suffixIcon={isPickerOpen ? <ChevronUp/>:<ChevronDown/>}
                  allowClear={false}
                  ranges={{
                    'Last 1 month': [
                      moment().subtract(1, 'months').subtract(1, 'days'),
                      moment().subtract(1, 'days'),
                    ],
                    'Last 3 months': [
                      moment().subtract(3, 'months').subtract(1, 'days'),
                      moment().subtract(1, 'days'),
                    ],
                    'Last 6 months': [
                      moment().subtract(6, 'months').subtract(1, 'days'),
                      moment().subtract(1, 'days'),
                    ],
                    'Last 1 year': [
                      moment().subtract(1, 'years').subtract(1, 'days'),
                      moment().subtract(1, 'days'),
                    ],
                  }}
                  value={[
                    defaultDate[0],
                    defaultDate[1],
                  ]}
                  separator={'-'}
                  allowEmpty={[false, false]}
                  onCalendarChange={(dates: Array<moment.Moment>, [currentPeriodStart, currentPeriodEnd]: [string, string]) => {
                    if (currentPeriodStart && currentPeriodEnd) {
                      const dateRange = dates && dates[1].diff(dates[0], 'days');
                      let periodStatus = '';
                      if ([28, 29, 30, 31].includes(dateRange)) {
                        setDateRange('1 month');
                        periodStatus = '1 month';
                        handleSetDates('Last 1 Month');
                      }
                      if ([87, 88, 89, 90, 91, 92].includes(dateRange)) {
                        setDateRange('3 months');
                        periodStatus = '3 months';
                        handleSetDates('Last 3 Month');
                      }
                      if ([180, 181, 182, 183, 184].includes(dateRange)) {
                        setDateRange('6 months');
                        periodStatus = '6 months';
                        handleSetDates('Last 6 Month');
                      }
                      if ([364, 365, 366, 367].includes(dateRange)) {
                        setDateRange('1 year');
                        periodStatus = '1 year';
                        handleSetDates('Last 1 Year');
                      }
                      const diff = moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') + 1;
                      const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'days').subtract(diff, 'days');
                      const previousPeriodEnd = moment(currentPeriodStart).subtract(1, 'days');
                      if (!periodStatus) {
                        setShowDateRange('');
                      }
                      gsc.criteria.updateCriteria({
                        ...gsc.criteria,
                        previousPeriodStart: previousPeriodStart.format('YYYY-MM-DD'),
                        previousPeriodEnd: previousPeriodEnd.format('YYYY-MM-DD'),
                        currentPeriodStart: moment(currentPeriodStart).format('YYYY-MM-DD'),
                        currentPeriodEnd: moment(currentPeriodEnd).format('YYYY-MM-DD'),
                        periodStatus,
                      });

                      MixPanel.track(GSC_INSIGHTS_UPDATE_DATE_RANGE);
                    }
                  }}
                  disabledDate={currentDate => currentDate >= moment().subtract(0, 'days') || currentDate <= moment().subtract(1, 'years').subtract(2, 'days')}
                  onOpenChange={(open: boolean) => {
                    setPickerOpen(open);
                  }}
                />
              </> : ''}
            </Tooltip>
          </div>
        </div>
        <ArrowIcon className={!isDockedSider ? styles.close : styles.open} onClick={e => dateRangePickerClickHandler(e)}>
          <CaretDownOutlined />
        </ArrowIcon>
        <span>{toJS(gsc?.coreReports?.loading) && <LoadingOutlined className={styles.loading} hidden={!gsc.coreReports.loading || router.asPath.includes('gsc/reports')}/>}</span>
      </div>

    </div>
  );
});

const DateRangeInText=styled.div`
  letter-spacing: -0.02em;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  position: absolute;
`;

const RangeHeading = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A3A4A4;
`;

const ArrowIcon = styled.span`
  position: absolute;
  top: 5px;
  left: 120px;
  color: #fff;
`;

