import React, {useEffect} from 'react';
import {Accept, ViewContentContainer} from './pressReleaseStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileCheck, faHourglassStart} from '@fortawesome/pro-duotone-svg-icons';
import {Button, Checkbox} from 'antd';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';

const ReviewContent = ({contentLink, acceptContent, setAcceptContent, generating, id, activeTab, visible, isStandalone}) => {
  const {ottoV2Store: {
    getPressRelease,
    editableUrl,
    gettingPressRelease,
    editableUrlRepolling,
  },
  } = useStore('');

  useEffect(() => {
    const load = async () => {
      await getPressRelease(id);
    };
    if (activeTab == 1 && visible && isStandalone) {
      load();
    }
  }, [activeTab, visible, isStandalone]);

  return (
    <div>
      <ViewContentContainer background={(generating || editableUrlRepolling) ? 'rgba(255, 133, 54, 0.15)' : 'rgba(42, 193, 85, 0.1)'} height={(generating || gettingPressRelease) ? '' : '126px'}>
        {(generating || editableUrlRepolling) ? <FontAwesomeIcon icon={faHourglassStart} fontSize={16} color='#FF8536' /> : <FontAwesomeIcon icon={faFileCheck} color='#18923B' />}
        <div>
          <div className='title'>{(generating || editableUrlRepolling) ? 'Content Generating...' : 'Content Successfully Generated'}</div>
          <div className='desc'>{(generating || editableUrlRepolling) ? '' : 'You can to review the AI generated content and modify it as necessary.'}</div>
          {(!generating && !editableUrlRepolling) ? <Button
            className='view-content'
            loading={gettingPressRelease}
            disabled={gettingPressRelease}
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'fit-content'}}
            onClick={(isStandalone ? editableUrl : contentLink) ? () => window.open((isStandalone ? editableUrl : contentLink), '_blank', 'noopener,noreferrer') : () => {}}>
              View Content
          </Button> : ''}
        </div>
      </ViewContentContainer>
      {(!generating && !editableUrlRepolling) ? <Accept>
        <Checkbox checked={acceptContent} onChange={e => setAcceptContent(e.target.checked)} />
        I reviewed & accept the content.
      </Accept> : ''}
    </div>
  );
};

export default observer(ReviewContent);
