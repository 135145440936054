import {EChartOption} from 'echarts';
import numeral from 'numeral';
import {Instance, types} from 'mobx-state-tree';

export const TrendChart = types.model({
  date: types.string,
  volume: types.maybeNull(types.number),
});

export const KeywordDetails = types
  .model({
    keyword: types.string,
    url: types.maybeNull(types.string),
    trend: types.maybeNull(types.array(TrendChart)),
    histSv: types.maybeNull(types.array(types.number)),
    sv: types.maybeNull(types.number),
    kd: types.maybeNull(types.number),
    position: types.maybeNull(types.number),
    traffic: types.maybeNull(types.number),
    cpc: types.maybeNull(types.number),
    ppcd: types.maybeNull(types.number),
    ppc: types.maybeNull(types.number),
    tasksComplete: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get trendSeries() {
      const series: EChartOption.Series[] = [{
        type: 'bar',
        itemStyle: {
          color: '#7f4ead',
        },
        data: self.histSv,
      }];

      return series;
    },
    get formattedSv() {
      return self.sv ? numeral(self.sv).format('0,0') : '-';
    },
    get formattedTraffic() {
      return self.traffic ? numeral(self.traffic).format('0,0') : '-';
    },
    get formattedCpc() {
      return self.cpc ? numeral(self.cpc).format('$0.00') : '-';
    },
    get formattedKd() {
      return self.kd ? numeral(self.kd).format('0,0') : '-';
    },
    get formattedPpcd() {
      return self.ppcd ? numeral(self.ppcd).format('0,0') : '-';
    },
  }));

export type KeywordDetailsInstance = Instance<typeof KeywordDetails>;
