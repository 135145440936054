import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import {BannerVariant} from '@/components/common-components/components/banner';
import {cast, flow, Instance, types, getParent} from 'mobx-state-tree';
import {BackLinkAnalyzerStoreType} from '.';
import numeral from 'numeral';
import {SORT_DIRECTION, filterDataInMemory} from '@/utils/filter-data';

export interface BacklinkVelocityDataItem {
    domain: string;
    dr: number;
    keywordCoiunt: number;
    ot: number;
    rank: number;
    refdomainCount: number;
    trafficValue: number;
}

const velocityModel = types.model({
  count: types.number,
  date: types.string,
});

const websiteVelocityModel = types.model({
  backlinks: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  dr: types.maybeNull(types.number),
  drColor: types.maybeNull(types.string),
  summary: types.model({
    ['1M']: types.maybeNull(types.number),
    ['1Y']: types.maybeNull(types.number),
    ['3M']: types.maybeNull(types.number),
  }),
  totalOk: types.maybeNull(types.number),
  totalOt: types.maybeNull(types.number),
  totalRd: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.number),
  ur: types.maybeNull(types.number),
  urColor: types.maybeNull(types.string),
  velocity: types.array(velocityModel),
});

export type websiteVelocityModelType = Instance<typeof websiteVelocityModel>;

export const BacklinkVelocityStore = types.model({
  content: types.array(websiteVelocityModel),
  selectedProject: types.maybeNull(types.string),
  loading: types.optional(types.boolean, false),
})
  .views(self => ({
    getBacklinkVelocityTableData(sortField: string, sortDirection: SORT_DIRECTION) {
      const {dataFiltered} = filterDataInMemory<websiteVelocityModelType>(self.content, '', [], [], 1, self.content.length, sortField, sortDirection);
      return dataFiltered.map(item => ({
        url: item.domain,
        ur: {value: item.ur, color: item.urColor},
        dr: {value: item.dr, color: item.drColor},
        totalRd: numeral(item.totalRd).format('0.[0][a]').toUpperCase(),
        backlinks: numeral(item.backlinks).format('0.[0][a]').toUpperCase(),
        totalOk: numeral(item.totalOk).format('0.[0][a]').toUpperCase(),
        totalOt: numeral(item.totalOt).format('0.[0][a]').toUpperCase(),
        trafficValue: numeral(item.trafficValue).format('0.[0][a]').toUpperCase(),
      }));
    },

    get xAxisData() {
      return self.content.map(item =>{
        return item.velocity.map(d =>d.date);
      },
      );
    },
    get daChartLegendData() {
      return self.content.map(item => ({
        name: item.domain,
        icon: 'circle',
      }));
    },
  }))
  .actions(self => {
    const loadBacklinkVelocity = flow(function* (projectPk:number) {
      self.loading = true;
      try {
        const data = yield BacklinkAnalyzerClient.getBacklinkVelocity(projectPk);
        self.content = cast(data.results);
      } catch (error) {
        const parent = getParent<BackLinkAnalyzerStoreType>(self);
        parent.setBanner(true, 'Backlink velocity not actualized.', 'The Backlink velocity data did not load properly. Please refresh your page to fix the issue.', BannerVariant.ERROR);
        return Promise.reject(error);
      } finally {
        self.loading = false;
      }
    });

    const selectCurrentProject = (project:string) =>{
      self.selectedProject = project;
    };

    return {
      loadBacklinkVelocity,
      selectCurrentProject,
    };
  });

export const initBacklinkVelocityStore = () => {
  return {
    content: [],
    selectedProject: null,
    loading: true,
  };
};
