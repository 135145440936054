import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LoadingOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
// import {faCircleExclamation, faCircleCheck, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Spin} from 'antd';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {InputContainer, StyledLoader, StyledInput, StyledTextArea, ValueDiv, CheckIcon, CrossIcon} from '../styles';
// import {SuggestionButton} from '../styles';

const StringField = observer(({field, handleSave, value}) => {
  const [isEditing, setIsEditing] = useState(false);
  // const [suggestion, setSuggestion] = useState('');
  const [currentValue, setCurrentValue] = useState<any>();
  const [valueArray, setValueArray] = useState([]);
  // const [iconDetails, setIconDetails] = useState<any>({
  //   color: '#F44343',
  //   icon: faCircleExclamation,
  // });
  const [loader, setLoader] = useState(false);
  const [isUrl, setIsUrl] = useState(false);

  useEffect(() => {
    setIsUrl((field.fieldType == 'ATTRIBUTE' || field.fieldName == 'website_uri'));
    if (value?.length) {
      setValueArray(value.filter(item => item.fieldName == field.fieldName));
    }
  }, [value]);

  useEffect(() => {
    valueArray?.length && setValues();
  }, [valueArray]);

  const setValues = () => {
    // setSuggestion(valueArray?.find(item => item.source == 'OTTO_AI' && item.eventType !== 'LOCK_FIXED')?.value);
    const OTTOObj = valueArray?.find(item => item.source == 'OTTO');
    if (OTTOObj) {
      setCurrentValue(OTTOObj);
    } else {
      setCurrentValue(valueArray?.find(item => item.source == 'GBP' && item.eventType !== 'LOCK_INCIDENT'));
    }
  };

  // useEffect(() => {
  //   if (currentValue?.value) {
  //     if (currentValue?.value?.toString() == suggestion?.toString() || !suggestion?.toString()) {
  //       setIconDetails({
  //         color: '#2AC155',
  //         icon: faCircleCheck,
  //       });
  //     } else {
  //       setIconDetails({
  //         color: '#FF8536',
  //         icon: faTriangleExclamation,
  //       });
  //     }
  //   } else {
  //     setIconDetails({
  //       color: '#F44343',
  //       icon: faCircleExclamation,
  //     });
  //   }
  // }, [currentValue?.value]);

  const handleSaveClick = async () => {
    setLoader(true);
    let value = currentValue?.value;
    if (field?.fieldType == 'ATTRIBUTE') {
      if (value?.includes('?')) {
        value = value?.slice(0, value?.indexOf('?'));
      }
    }
    if (field.fieldType == 'ATTRIBUTE') {
      value = value ? value : null;
    }
    if (field?.fieldName == 'website_uri' && !value?.includes('https://') && value) {
      value = 'https://' + value;
    }
    const checking = await handleSave([{
      fieldName: field.fieldName,
      value: field.label == 'Booking link' ? [value] : value,
      fieldType: field.fieldType,
    }]);
    setIsEditing(false);
    setLoader(false);
    !checking && setValues();
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setValues();
  };

  // const tooltipSuggestion = (
  //   <div>
  //     <div>You have a possible Suggestion
  //       <div>{suggestion}</div>
  //     </div>
  //     <div style={{marginTop: 5}}>
  //       <FreezeWrapper>
  //         <SuggestionButton onClick={() => {
  //           setIsEditing(true);
  //           setCurrentValue(prev => ({...prev, value: suggestion}));
  //         }}>Accept OTTO Suggestion</SuggestionButton>
  //       </FreezeWrapper>
  //     </div>
  //   </div>
  // );

  const openLink = () => {
    if (isUrl) {
      window.open(currentValue?.value, '_blank');
    }
  };

  return (
    <>
      {isEditing ? (
        <InputContainer className='input-actions'>
          {field.fieldName !== 'business_description' ? <StyledInput
            value={currentValue?.value}
            maxLength={field.fieldName == 'business_name' && 150}
            onChange={e => setCurrentValue(prev => ({...prev, value: e.target.value}))}
          /> : <StyledTextArea
            rows={4}
            autoSize={{minRows: 1, maxRows: 4}}
            value={currentValue?.value}
            onChange={e => setCurrentValue(prev => ({...prev, value: e.target.value}))}
          />}
          <div style={{display: 'flex'}}>
            <Tooltip title='Save'>
              {loader ? <StyledLoader>
                <Spin indicator={<LoadingOutlined spin />} style={{color: 'white', fontSize: 10}} size='small' />
              </StyledLoader> : <CheckIcon color='white' onClick={() => handleSaveClick()} />}
            </Tooltip>
            <Tooltip title='Cancel'>
              <CrossIcon color='white' onClick={handleCancelClick} />
            </Tooltip>
          </div>
        </InputContainer>
      ) : (
        <div style={{display: 'flex', width: '100%'}}>
          {/* <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: (suggestion && (suggestion !== currentValue?.value)) && 391}} title={(suggestion && (suggestion !== currentValue?.value)) ? tooltipSuggestion : 'No possible Suggestions'}> */}
          {/* <FontAwesomeIcon
            style={{marginRight: 6}}
            color={iconDetails.color}
            icon={iconDetails.icon}
          /> */}
          {/* </Tooltip> */}
          {currentValue?.value ? (
            <>
              <Tooltip title={isUrl ? currentValue?.value : ''}>
                <ValueDiv isURL={isUrl}><span className={isUrl && 'click-link'} onClick={openLink}>{currentValue?.value}</span></ValueDiv>
              </Tooltip>
              <div className='edit-icon' style={{marginTop: -3}}>
                <FreezeWrapper>
                  <Tooltip title='Edit'>
                    <div onClick={() => setIsEditing(true)}>
                      <FontAwesomeIcon
                        style={{marginRight: 6, marginTop: 5}}
                        icon={faPen}
                        color='#2D6CCA'
                      />
                       Edit
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </>
          ) : (
            <FreezeWrapper>
              <div
                style={{color: '#2D6CCA', cursor: 'pointer', width: '90%'}}
                onClick={() => setIsEditing(true)}
              >
                {`Add ${field.label}`}
              </div>
              <div className='edit-icon' style={{marginTop: -3}}>
                <FreezeWrapper>
                  <Tooltip title='Edit'>
                    <div onClick={() => setIsEditing(true)}>
                      <FontAwesomeIcon
                        style={{marginRight: 6, marginTop: 5}}
                        icon={faPen}
                        color='#2D6CCA'
                      />
                       Edit
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </FreezeWrapper>
          )}
        </div>
      )}
    </>
  );
});

export default StringField;
