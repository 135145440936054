
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import {setCountryCodeToLocalStorage} from '@/api/common-utils';
import {useRouter} from 'next/router';
import {CountryDropdown} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-regular-svg-icons';
import styles from '@/components/layoutV2/header/style.module.scss';
import {toJS} from 'mobx';

interface Props {
  isDocked?: boolean;
}
export const RenderCountrySelect = observer(({isDocked}:Props) => {
  const {navBar, gsc} = useStore('');
  const {t} = useTranslation('common');
  const router = useRouter();

  const appendParams = (value, countryCode?: string) => {
    let params = '';
    if ('sp' in router.query) {
      delete router.query['sp'];
    }
    if ('country_code' in router.query) {
      delete router.query['country_code'];
    }
    router.query['sp'] = value;
    router.query['country_code'] = countryCode;
    Object.keys(router.query).forEach(param => {
      if (param !== 'slug') {
        if (!params) {
          params = `?&${param}=${router.query[param]}`;
        } else {
          params += `?&${param}=${router.query[param]}`;
        }
      }
    });
    return params;
  };

  const onPropertyChange = value => {
    router.push(
      `${location.pathname}${appendParams(gsc.sitePropertyEvents.siteEventCriteria.property, value)}`,
      undefined,
      {shallow: true},
    );
    gsc.sitePropertyEvents.siteEventCriteriaUpdate({
      ...gsc.sitePropertyEvents.siteEventCriteria,
      countryCode: value,
    });
    setCountryCodeToLocalStorage(value.value);
    // setCountry(value.value);
  };
  const country = gsc.sitePropertyEvents.siteEventCriteria.countryCode;
  const currentProperty = navBar.getSiteProperty(gsc.sitePropertyEvents.siteEventCriteria.property);
  const availableCountryData = toJS(currentProperty?.availableCountryCodes)?.filter(code => currentProperty?.activeCountryCodes?.includes(code));

  return (
    <>
      <div className={isDocked ? styles.drop : styles.dropDockedSidebar}>
        {isDocked && <span style={{marginLeft: '21px', color: '#a3a4a4'}}>{t('Location')}</span> }

        <CountryDropdown
          isDocked={isDocked}
          aligned={true}
          countriesToInclude={availableCountryData}
          // defaultValue={criteria?.countryCode?.toUpperCase}
          variant='default'
          isReport={true}
          suffixIcon={<FontAwesomeIcon color='#fff' icon={ faCaretDown}/>}
          width={'150px'}
          className={'selectInput'}
          dropdownClassName= {isDocked ? styles.countrydropdown : styles.countrydropdownout}
          dropdownStyle={{backgroundColor: '#2D2F34', color: '#fff'}}
          value={country}
          onChange={onPropertyChange}
          filterOption={(input, option) =>
            option?.country?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </div>
    </>
  );
});
