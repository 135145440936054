import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import styled from 'styled-components';
import styles from '../AiFirstDraftGenerator/style.module.scss';
import {useTranslation} from 'next-i18next';
import Tabs, {TabPane} from 'rc-tabs';
import {TermsTable} from './TermsTable';
import {QuestionsTable} from './QuestionsTable';

interface Props {
  addItem: (value?: string) => void;
  removeItem: (value?: string) => void;
  usedTerms?: string[];
}

export const SuggestionsPopup = observer(({addItem, removeItem, usedTerms}: Props) => {
  const {
    contentOptimizer: {
      currentPage,
    },
  } = useStore('');

  const [activeKey, setActiveKey] = useState('1');

  const onChangeTab = key => {
    setActiveKey(key);
  };

  const {t} = useTranslation('landing-page-optimizer');

  return <Wrapper>
    <CommonTabsStyled
      className={styles.customFocusTermsTabs}
      // style={currentPage.focusTermsExpanded ? {marginTop: '50px'} : {}}
      tabBarGutter={30}
      activeKey={activeKey}
      tabBarStyle={{'padding': '0 !important'}}
      defaultActiveKey={'1'}
      onChange={key => onChangeTab(key)}
    >
      <TabsStyled
        tab={
          <div style={{display: 'flex'}}>
            <span style={{fontSize: currentPage.focusTermsExpanded ? '12px' : '14px', fontWeight: 500}}>
              {t('topical-terms')}
            </span>
            <span className={styles.popoverInfoContainer}>
              {currentPage?.analytics?.focusTerms?.length ? <span className={styles.focusTermsTabTitleValue}>
                {currentPage?.analytics?.focusTerms?.length}
              </span> : null}
            </span>
          </div>}
        key='1'
        style={{'outline': 'none'}}>
        <TermsTable addItem={addItem} removeItem={removeItem} usedTerms={usedTerms}/>
      </TabsStyled>
      <TabsStyled
        tab={
          <div style={{display: 'flex'}}>
            <span style={{fontSize: currentPage.focusTermsExpanded ? '12px' : '14px', fontWeight: 500}}>
              Questions
            </span>
            {currentPage?.analytics?.questions?.length ? <span className={styles.popoverInfoContainer}>
              <span className={styles.focusTermsTabTitleValue}>{currentPage.analytics.questions.length}</span>
            </span> : null}
          </div>}
        key='2'
        style={{'outline': 'none'}}>
        <QuestionsTable addItem={addItem} removeItem={removeItem} usedQuestions={usedTerms} />
      </TabsStyled>
    </CommonTabsStyled>

  </Wrapper>;
});

const Wrapper = styled.div`
  width: 420px;
`;

const CommonTabsStyled = styled(Tabs)`
border: none;
  .rc-tabs-nav {
    height: 40px !important;
  }

  .rc-tabs-tab {
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }

  .rc-tabs-tab-active {
    font-size: 14px;
    font-weight: 500;
    color:  #121212;
  }
`;


const TabsStyled = styled(TabPane)`
  .ant-collapse {
    // For Internet Explorer
    scrollbar-face-color: rgba(138,138,138,0.32);
    scrollbar-track-color: rgba(138,138,138,0.12);

    // For Google Chrome
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
  }
`;
