import {Modal} from '@/components/common-components';
import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {Radio, Space, Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';

interface Props {
  totalRows?: number;
  visible: boolean;
  setVisible: (val) => void;
  handleSubmit?: (data) => void;
  allowCustomRows?: boolean;
}
export const ExportModal:React.FC<Props> = observer(({allowCustomRows=true, totalRows, visible, handleSubmit, setVisible}) => {
  const {competitorResearcherV2: {overview: {isExporting}}} = useStore('');
  const [radioButtonValue, setRadioButtonValue] = useState(1);
  const [customRows, setCustomRows] = useState('');
  const [filetype, setFileType] = useState('csv');
  const [error, setError] = useState('');
  const inputRef = useRef(null);
  const totalRowsCount = totalRows <= 30000 ? totalRows : 30000;
  useEffect(() => {
    if (radioButtonValue == 2 && (!customRows || Number(customRows) <= 0)) {
      inputRef?.current?.focus();
      setError('This field is required.');
    } else {
      setError('');
    }
  }, [customRows, radioButtonValue]);

  useEffect(()=> {
    setCustomRows('');
    setRadioButtonValue(1);
  }, [visible]);

  const onChangeRadioButton = useCallback(
    e => setRadioButtonValue(e.target?.value),
    [],
  );
  const antIconWt = <LoadingOutlined style={{fontSize: 16, color: 'white'}} spin />;
  return (
    <Modal
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <PopoverContent >
        <div style={{fontSize: '15px', fontWeight: '600'}}> Export Data</div>
        <Radio.Group onChange={e => onChangeRadioButton(e)} value={radioButtonValue} style={{paddingTop: '5px'}}>
          <Space direction='vertical'>
            <Radio value={1}>{totalRows <= 30000 ? `All Rows ${totalRowsCount}` : `Top ${totalRowsCount} rows`}</Radio>
            {allowCustomRows && <Radio value={2} >
              <span className='customWrap' style={{position: 'relative'}}>Custom <input className='customInput' type='number' placeholder={`Up to ${totalRowsCount}`}
                value={customRows}
                ref={inputRef}
                min={1}
                onChange={e => Number(e.target.value) <= totalRowsCount && setCustomRows(e.target.value)}
              />
              <span style={{color: 'red', position: 'absolute', top: '27px', left: '62px'}}>{error}</span>
              </span></Radio>}
          </Space>
        </Radio.Group>
        <div className='explabel'>EXPORT TO:</div>
        <Radio.Group
          value={filetype}
          onChange={e => setFileType(e.target.value)}>
          <Space direction='vertical'>
            <Radio value={'csv'}>CSV file</Radio>
            <Radio value={'xls'}>XLS file</Radio>
          </Space>
        </Radio.Group>
        <div style={{paddingTop: '15px', display: 'flex', justifyContent: 'flex-end'}}>
          <Button
            disabled={false}
            color='tableGray'
            variant='ghost'
            textColor='#121212'
            style={{border: '1px solid #CDCDCD'}}
            onClick={()=> setVisible(false)}
          > <div>Cancel</div>
          </Button>
          <Button
            disabled={!!error}
            style={{backgroundColor: '#7F4EAD', color: '#ffff', marginRight: '10px', justifyContent: 'center', lineHeight: '14px'}}
            onClick={() => {
              const data = {
                rows: radioButtonValue == 1 ? totalRowsCount : customRows,
                type: filetype,
              };
              handleSubmit(data);
            }}
          > <div>{isExporting ?<Spin indicator={antIconWt} /> : 'Export'}</div>
          </Button>
        </div>
      </PopoverContent>
    </Modal>
  );
});

const PopoverContent = styled.div`

  .explabel {
    color: rgb(163, 164, 164);
    font-size: 12px;
    margin: 15px 0 0 0;
  }

  .customWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 10px;

    .customInput {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #E8E8E8;
      background: #F9F9FB;
      color: black;
      padding: 4px 8px;
      line-height: 17px;
      &:focus-visible {
        outline: none;
      }
    }
  }  
`;
