import {cast, flow, getParent, Instance, types} from 'mobx-state-tree';
import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import randomcolor from 'randomcolor';
import type {OutreachTargetItem, OutreachTargetItemResponse} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {TaskStatus} from '@/api/backlink-analyzer/backlink-analyzer.model';
import type {BackLinkAnalyzerStoreType, TableFilterType} from '.';
import {BannerVariant} from '@/components/common-components/components/banner';
import {BACKLINK_API_API_POLL_INTERVAL} from '@/constants';


const OutreachTargetModel = types.model({
  ahrefsRank: types.number,
  domainRating: types.number,
  ahrefsTop: types.number,
  domainFrom: types.string,
  citationFlow: types.number,
  trustFlow: types.number,
  competitorsCount: types.number,
  competitors: types.array(types.string),
  topicalTrustFlowTopic: types.array(types.string),
  topicaltrustflowValue: types.number,
}).views(self => ({
  get formattedCategoryTopic() {
    return self.topicalTrustFlowTopic.map(category => {
      const background = randomcolor({format: 'rgba', seed: category, alpha: 0.3, luminosity: 'light'});
      const border = randomcolor({format: 'hex', seed: category, luminosity: 'light'});
      const topicaltrustValue = self.topicaltrustflowValue;
      return {
        background,
        border,
        category,
        topicaltrustValue,
      };
    }).filter(({category}) => category !== '');
  },
  get category() {
    return self.topicalTrustFlowTopic.map(item => item);
  },
}));

export type OutreachTargetItemModel = Instance<typeof OutreachTargetModel>;

export const OutreachTargetsStore = types.model({
  loading: types.boolean,
  data: types.array(OutreachTargetModel),
  tasksComplete: types.optional(types.boolean, false),
  reTry: types.optional(types.number, 0),
}).views(self => ({
  suggestedOutreachTable({
    currentPage,
    pageSize,
    filters = [],
    keyword = '',
    filterCategories,
    filterableBy = ['domainFrom'],
    category,
    sortField,
    sortDirection,
  }: TableFilterType<OutreachTargetItem>) {
    let filtered = self.data.filter(item => {
      return item && filterableBy.some(target => (item[target] as string).toLowerCase().includes(keyword.toLowerCase()));
    });

    if (category.category1?.length) {
      filtered = filtered.filter(item => category?.category1.every(target => item.topicalTrustFlowTopic.includes(target)));
    }

    if (filters && filters.length) {
      filtered = filtered.filter( k => {
        const shouldBeExcluded = filters.filter( f => {
          const itemValue = k[f.name];
          if (f.from === '' && f.to === '') return false;
          if (isNaN(itemValue)) return false;
          let isValid = true;
          if (f.from !== '') {
            const from = parseFloat(f.from);
            if (itemValue < from) isValid = false;
          }
          if (f.to !== '') {
            const to = parseFloat(f.to);
            if (itemValue > to) isValid = false;
          }
          return !isValid;
        }).length > 0;
        return !shouldBeExcluded;
      });
    }

    if (sortField && sortDirection) {
      filtered.sort( (a, b) => {
        const _dir = sortDirection === 'ascend' ? 1 : -1;
        if (a[sortField] > b[sortField]) return 1 * _dir;
        if (a[sortField] < b[sortField] * _dir) return -1 * _dir;
        return 0;
      });
    }

    if (filterCategories?.length) {
      filtered = filtered.filter(backlink => {
        return filterCategories.some(filter => {
          return backlink.competitors.some(competitor=> {
            return competitor.split('.')[0] === filter;
          });
        });
      });
    }

    const startIdx = (currentPage * pageSize) - pageSize;
    const endIdx = startIdx + pageSize;
    const response = filtered.slice(startIdx, endIdx);

    return {
      data: response,
      length: filtered.length,
    };
  },
})).actions(self => {
  const loadOutreachTargets = flow(function* (pk: number) {
    self.loading = true;
    try {
      const data: OutreachTargetItemResponse = (yield BacklinkAnalyzerClient.getOutreachTargets(pk));
      self.tasksComplete = data.status == TaskStatus.SUCCESS;

      if (!self.tasksComplete) {
        yield new Promise(r => setTimeout(r, BACKLINK_API_API_POLL_INTERVAL));
        return loadOutreachTargets(pk);
      } else {
        const outreachTargets = data.results.map(item => ({
          ...item,
          topicalTrustFlowTopic: item.topicaltrustflowTopic0.split('/'),
          topicaltrustflowValue: item.topicaltrustflowValue0,
        }));
        self.data = cast(outreachTargets);
      }
    } catch (e) {
      const parent = getParent<BackLinkAnalyzerStoreType>(self);
      parent.setBanner(true, 'Outreach Targets not actualized.', 'The Outreach Targets data did not load properly. Please refresh your page to fix the issue.', BannerVariant.ERROR);
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });

  return {loadOutreachTargets};
});

export const initOutreachTargetsStore = () => {
  return {
    loading: true,
    data: [],
  };
};
