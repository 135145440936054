import {PAGES_API} from '@/api/content-optimizer';
import {flow, types, cast, Instance, getRoot} from 'mobx-state-tree';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';

export const AiMetaDataModel = types.model({
  metaTitle: types.string,
  metaDesc: types.string,
});

export type MetaIdeasType = Instance<typeof AiMetaDataModel>;

export const AiMetaData = types.model({
  metaData: types.maybeNull(types.array(AiMetaDataModel)),
  loading: types.boolean,
  shouldRepoll: types.boolean,
}).actions(self => {
  const loadAiMetaData = flow(function* (pageTitle: string, targetKeywords: string[]) {
    self.loading = true;
    const payload = {
      companyTitle: pageTitle,
      companyTargetKeywords: targetKeywords.join(',').replaceAll('\n', ','),
    };
    try {
      const response = yield PAGES_API.getSEOMetaTags(payload);
      self.shouldRepoll = response.shouldRepoll;
      if (response?.metadataIdeas?.length > 0) {
        self.metaData = cast(response?.metadataIdeas?.map(item => ({
          metaTitle: item.split('\n')[0],
          metaDesc: item.split('\n')[1],
        })));
      }
      if (self.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadAiMetaData(pageTitle, targetKeywords);
      }
      self.loading = false;
      return toJS(self.metaData);
    } catch (e) {
      self.loading = false;
      const {status, data} = e.response;
      if (status === 429) {
        const rootStore = getRoot(self) as any;
        rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
        const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
        const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
        notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
      } else {
        if (data?.keyword) {
          notification.error('Error loading meta data Ideas', 'Kindly add at least one target keyword');
        } else {
          notification.error('Error loading Content Ideas', 'The Content Ideas data did not load properly. To fix the issue:');
        }
      }

      Promise.reject(e);
    }
  });

  const clearMetaData = () => {
    self.metaData.length = 0;
  };

  const setLoading = (value: boolean) => self.loading = value;

  return {
    loadAiMetaData,
    clearMetaData,
    setLoading,
  };
});


export const initAiMetaDataStore = () => {
  return AiMetaData.create({
    metaData: [],
    loading: false,
    shouldRepoll: false,
  });
};
