import React, {useEffect, useRef} from 'react';
import {ClearAll, Container, Input, Label, Pill, SectionTitle, StyledFormItem, Terms} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import useIntersectionObserver from './useIntersectionObserver';

const TermsToExclude = ({form, termsToExclude, setTermsToExclude, terms, setTerms, selectedSection, setActiveOption}) => {
  const blockref = useRef(null);
  const blockEntry = useIntersectionObserver(blockref, {
    threshold: [0.3, 0.5, 0.7, 0.9, 1.0],
  });

  const isVisible = !!blockEntry?.isIntersecting;
  useEffect(() => {
    if (isVisible) {
      setActiveOption('terms_to_exclude');
    }
  }, [isVisible]);

  const onTermsChange = e => {
    const value = e.target.value;
    setTerms(value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (terms) {
        setTermsToExclude(prev => {
          const result = [...prev, ...terms.split(',')];
          const obj = {};
          obj['excluded_focusterms'] = result;
          form.setFieldsValue(obj);
          return result;
        });
        setTerms('');
      }
    }
  };

  return (
    <Container id='terms_to_exclude' ref={blockref}>
      <div className={selectedSection === 'terms_to_exclude' ? 'selected-bg' : ''} id='container'>
        <SectionTitle>TERMS TO EXCLUDE</SectionTitle>
        <Label>AI will try to write without using these terms</Label>
        <StyledFormItem
          name='excluded_focusterms'
          initialValue={[]}
        >
          <Input
            value={terms}
            onChange={onTermsChange}
            onKeyDown={handleKeyDown}
            placeholder='Enter term(s) and phrase(s), separated by commas, and hit enter.'
          />
          {termsToExclude?.length ?
            <Terms>
              {termsToExclude.map((item, index) => (
                <Pill key={index}>{item}{' '}
                  <FontAwesomeIcon
                    icon={faXmark}
                    color='#A3A4A4'
                    onClick={() => {
                      setTermsToExclude(prev => {
                        const result = prev?.filter(i => i !== item);
                        const obj = {};
                        obj['excluded_focusterms'] = result;
                        form.setFieldsValue(obj);
                        return result;
                      });
                    }} />
                </Pill>
              ))}
              <ClearAll onClick={() => {
                const obj = {};
                obj['excluded_focusterms'] = [];
                form.setFieldsValue(obj);
                setTermsToExclude([]);
              }}>Clear all</ClearAll>
            </Terms> :
            ''}
        </StyledFormItem>
      </div>
    </Container>
  );
};

export default TermsToExclude;
