import {Button} from '@/components/common-components/v2/Button';
import {Input, Menu, Progress, Slider, Switch} from 'antd';
import styled from 'styled-components';

export const OTTOSettingsWrapper = styled.div`
  .gradient-divider-wrapper {
    position: relative;
    margin: 20px 0;
  }

  .gradient-divider {
    position: relative;
    border: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 80%);
    height: 1px;
  }

  .gradient-divider::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0) 100%);
  }
`;

export const SectionTitle = styled.div`
  font-weight: 500;
  color: #121212;
`;

export const SectionDescription = styled.div`
  width: 316px;
  font-size: 12px;
  color: #4E5156;
`;

export const DropdownContainer = styled.div<{borderRadius: string; noBorderBottom?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 305px;
  height: 32px;
  border-radius: ${p => p?.borderRadius};
  padding: 10px;
  border: 1px solid #D8D8D8;
  ${p => p?.noBorderBottom ? 'border-bottom: none;' : ''}
  background: #FFFFFF;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Title = styled.div<{disabled?: boolean; sectionTitle?: boolean}>`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #121212;
  color: ${p => p?.sectionTitle ? '#121212' : (p?.disabled ? 'rgba(78, 81, 86, 0.4)' : '#4E5156')};
`;

export const AIQuotaAllowanceBanner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 500px;
  height: 48px;
  padding: 8px 10px 8px 10px;
  border-radius: 12px;
  background: #2D6CCA1F;
  margin-top: 8px;
`;

export const AIQuotaAllowanceBannerText = styled.div`
  font-size: 12px;
  color: #121212;
`;

export const SliderStyled = styled(Slider)<{value: number; disabled?: boolean}>`
  margin: 0 !important;
  opacity: ${p => p?.disabled ? '50%' : '100%'};
  .ant-slider-track {
    height: 6px !important;
    background-color: #2D6CCA !important;
    border-top-left-radius: 23px !important;
    border-bottom-left-radius: 23px !important;
  }
  .ant-slider-track:hover {
    background-color: #2D6CCA !important;
  }
  .ant-slider-rail {
    height: 6px !important;
    background-color: #E8E8E8 !important;
    border-top-right-radius: 23px !important;
    border-bottom-right-radius: 23px !important;
  }
  .ant-slider-handle {
    margin-left: ${p => p?.value >= 4500 ? '-7px' : '7px'};
    width: 16px !important;
    height: 16px !important;
    border: 2px solid #2D6CCA !important;
  }
  .ant-slider-handle:hover {
    border: 2px solid #2D6CCA !important;
    border-color: #2D6CCA !important;
  }
`;

export const RoundInput = styled(Input) <{width?: string; crawlFrequency?: string; borderRadius?: string; noBorderTop?: boolean; height?: string}>`
  ${p => p?.width && `width: ${p?.width};`}
  border-radius: ${p => p?.borderRadius};
  font-size: 12px;
  height: ${p => p?.height ? p?.height : '25px'};
  padding: 5px;
  ${p => p?.noBorderTop ? 'border-top: none !important;' : ''}
  border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : '1px solid #D8D8D8'};
    box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    &:focus {
      border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};
    }
    &:hover {
      border: ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? '1px solid #FF0000' : ''};
      box-shadow:   ${p => p.crawlFrequency == '' || p.crawlFrequency == '0' ? ' 0 0 0 1px #FF0000' : ''};

    }
`;

export const ProgressStyled = styled(Progress)`
  width: 188px;
  .ant-progress-inner {
    background-color: #E8E8E8;
  }
`;

export const GradientProgressContainer = styled.div`
  width: 275px;
  display: flex;
  align-items: center;
  gap: 35px;
`;

export const AutoDeployTitle = styled.div`
  font-size: 12px;
  color: #000000;
  line-height: 12px;
  margin-left: 273px;
`;

export const GradientProgressWrapper = styled.div`
  margin-left: -21px;
  display: flex;
  align-items: center;
  gap: 13px;
  font-size: 12px;
  color: #4E5156;
`;

export const MenuStyled = styled(Menu)`
  border-radius: 8px;
  max-height: 295px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
          
  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }
          
  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 31px;
  margin-top: 30px;
  .cancel-button {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
    background-color: transparent;
    border: 0px;
  }
`;

export const UpdateAiSettings = styled(Button)`
  width: fit-content;
  height: 44px;
  border-radius: 8px;
  background-color: #2D6CCA !important;
  color: #fff !important;
  &:hover {
    background-color: #2D6CCA !important;
  }
  &:focus {
    background-color: #2D6CCA !important;
  }
`;

export const SwitchStyled = styled(Switch)`
  min-width: 32px !important;
  height: 18px !important;
  .ant-switch-handle {
    width: 14px !important;
    height: 14px !important;
  }
  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 15px - 2px) !important;
  }
  &.ant-switch-checked {
    background: #18923B !important;
  }
`;
