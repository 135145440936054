import {types, Instance, flow, cast, getRoot} from 'mobx-state-tree';
import {CriteriaType} from '@/store/gsc-store/criteria';
import {reportsApi} from '@/api/gsc/index';
import {PageGroupingStatsStore, initPageGroupingStatsStore} from './page-grouping';
import {filterDataInMemory} from '@/utils/filter-data';
import {notification} from '@/utils/notification-v2';
import {getTokenFromCookies} from '@/api/common-utils';
import {apiError} from '@/utils/api';
import {getSingleUrlParam} from '@/utils/url';

export enum SORT_DIRECTION {
  ASCEND = 'ascend',
  DESCEND = 'descend',
}

export enum TABS {
  traffic = 'traffic',
  impressions = 'impressions',
}

export type FilterPagesTableProps = {
  filterKeywordTerm: string;
  filterKeywordColumns: string[];
  filters: {
    name: string;
    header: string;
    from: string;
    to: string;
  }[];
  pageNumber: number;
  pageSize: number;
  sortField: string;
  sortDirection: SORT_DIRECTION;
};

export const Stat = types.model({
  title: types.string,
  currentFormatted: types.string,
  current: types.maybeNull(types.number),
  previous: types.maybeNull(types.number),
  action: types.optional(types.maybeNull(types.string), ''),
});

export type StatType = Instance<typeof Stat>;

export const Stats = types.model({
  clicks: Stat,
  totalImpressions: Stat,
  avgRankChanges: Stat,
  totalKeywords: Stat,
}).actions(self => ({
  setStats(
    clicks: StatType,
    totalImpressions: StatType,
    avgRankChanges: StatType,
    totalKeywords: StatType,
  ) {
    self.clicks = clicks;
    self.totalImpressions = totalImpressions;
    self.avgRankChanges = avgRankChanges;
    self.totalKeywords = totalKeywords;
  },
}));

export type StatsType = Instance<typeof Stats>;

const dailySerpHistogramsAndClicksItem = types.model(
  {
    date: types.string,
    clicks: types.maybeNull(types.number),
    clicksR30: types.maybeNull(types.number),
    impressions: types.maybeNull(types.number),
    impressionsR30: types.maybeNull(types.number),
    isFresh: types.maybeNull(types.boolean),
    ctr: types.maybeNull(types.number),
    ctrR30: types.maybeNull(types.number),
    serp1: types.maybeNull(types.number),
    serp1To3: types.maybeNull(types.number),
    serp1To10: types.maybeNull(types.number),
    serp2To3: types.maybeNull(types.number),
    serp4To10: types.maybeNull(types.number),
    serp11To20: types.maybeNull(types.number),
    serp21: types.maybeNull(types.number),
    serp100: types.maybeNull(types.number),
    serp2150: types.maybeNull(types.number),
    serp51100: types.maybeNull(types.number),
    isProjected: types.maybeNull(types.boolean),
    trafficValue: types.maybeNull(types.number),
    trafficValueR30: types.maybeNull(types.number),
  },
);

export type DailySerpHistogramsAndClicksItemType = Instance<typeof dailySerpHistogramsAndClicksItem>;

const TopPage = types.model({
  key: types.number,
  url: types.string,
  fullUrl: types.string,
  clicks: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
});

export type TopPageType = Instance<typeof TopPage>;

const SerpChange = types.model({
  rising: types.optional(types.maybeNull(types.number), 0),
  falling: types.optional(types.maybeNull(types.number), 0),
  static: types.optional(types.maybeNull(types.number), 0),
});

export type SerpChangeType = Instance<typeof SerpChange>;

const SerpChangeByPeriods = types.model({
  serp20: SerpChange,
  serp11To20: SerpChange,
  serp1To10: SerpChange,
});

export type SerpChangeByPeriodsType = Instance<typeof SerpChangeByPeriods>;

const GoogleRankChange = types.model(
  {
    date: types.string,
    positionChangeCumulative: types.maybeNull(types.number),
    positionsFallen: types.maybeNull(types.number),
    positionsNet: types.maybeNull(types.number),
    positionsRisen: types.maybeNull(types.number),
  },
);

export type GoogleRankChangeType = Instance<typeof GoogleRankChange>;

const KeywordsTable = types.model({
  clicksCur: types.optional(types.maybeNull(types.number), -1),
  clicksPrev: types.optional(types.maybeNull(types.number), -1),
  cpc: types.optional(types.maybeNull(types.number), -1),
  impCur: types.optional(types.maybeNull(types.number), -1),
  impPrev: types.optional(types.maybeNull(types.number), -1),
  kg: types.array(types.model({
    id: types.number,
    name: types.string,
  })),
  page: types.string,
  pagesNum: types.maybeNull(types.number),
  kw: types.string,
  posCur: types.optional(types.maybeNull(types.number), -1),
  posPrev: types.optional(types.maybeNull(types.number), -1),
  vol: types.optional(types.maybeNull(types.number), -1),
  opportunityScore: types.optional(types.maybeNull(types.number), -1),
  value: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.string),
  isWatchlisted: types.maybeNull(types.boolean),
}).views( self => ({
  get pagePath(): string {
    return self.page ? new URL(self.page).pathname : '';
  },
}));

export type KeywordsTableType = Instance<typeof KeywordsTable>;

const KeywordHistoricalTable = types.model({
  date: types.optional(types.maybeNull(types.number), -1),
  clicksCur: types.optional(types.maybeNull(types.number), -1),
  clicksPrev: types.optional(types.maybeNull(types.number), -1),
  impCur: types.optional(types.maybeNull(types.number), -1),
  impPrev: types.optional(types.maybeNull(types.number), -1),
  posCur: types.optional(types.maybeNull(types.number), -1),
  posPrev: types.optional(types.maybeNull(types.number), -1),
});

export type KeywordHistoricalTableType = Instance<typeof KeywordHistoricalTable>;

const PagesTable = types.model({
  clicksCur: types.optional(types.maybeNull(types.number), -1),
  clicksPrev: types.optional(types.maybeNull(types.number), -1),
  impCur: types.optional(types.maybeNull(types.number), -1),
  impPrev: types.optional(types.maybeNull(types.number), -1),
  keywordsCount: types.optional(types.maybeNull(types.number), -1),
  opportunityScore: types.optional(types.maybeNull(types.number), -1),
  page: types.string,
  posCur: types.optional(types.maybeNull(types.number), -1),
  posPrev: types.optional(types.maybeNull(types.number), -1),
  topKeyword: types.string,
  url: types.string,
  serp13: types.maybeNull(types.number),
  serp410: types.maybeNull(types.number),
  serp1120: types.maybeNull(types.number),
  serp21: types.maybeNull(types.number),
  users: types.maybeNull(types.number),
  sessions: types.maybeNull(types.number),
  bounces: types.maybeNull(types.number),
  sessionDuration: types.maybeNull(types.number),
  goalCompletionsAll: types.maybeNull(types.number),
  uniquePurchases: types.maybeNull(types.number),
  avgSessionDuration: types.maybeNull(types.number),
  newUsers: types.optional(types.maybeNull(types.number), null),
  avgTimeOnPage: types.optional(types.maybeNull(types.number), null),
  pageViews: types.optional(types.maybeNull(types.number), null),
  totalEvents: types.optional(types.maybeNull(types.number), null),
  transactionRevenue: types.optional(types.maybeNull(types.number), null),
  bounceRate: types.optional(types.maybeNull(types.number), null),
  goalConversionRateAll: types.optional(types.maybeNull(types.number), null),
});

export type PagesTableType = Instance<typeof PagesTable>;

const StatsByPosition = types.model({
  position: types.maybeNull(types.union(types.string, types.number)),
  ctr: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  clicks: types.maybeNull(types.number),
});

const StatsByPositionFiltered = types.model({
  position: types.maybeNull(types.union(types.string, types.number)),
  ctr: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  clicks: types.maybeNull(types.number),
});

const FiltersModel = types.model({
  from: types.maybeNull(types.number),
  header: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.number),
});

export type FilterModelType = Instance<typeof FiltersModel>;

export type StatsByPositionType = Instance<typeof StatsByPosition>;

const KeywordCount = types.model({
  kwCount: types.optional(types.maybeNull(types.number), 0),
  isProjected: types.maybeNull(types.boolean),
  date: types.string,
});

export type KeywordCountType = Instance<typeof KeywordCount>;

const EmojiDataModel = types.model({
  occuredAt: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  tags: types.optional(types.array(types.string), []),
  icon: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

const positionHistogramsPctChange = types.model({
  key: types.maybeNull(types.string),
  value: types.maybeNull(types.union(types.number, types.string)),
  toolTipData: types.maybeNull(types.model({
    endingDate: types.maybeNull(types.string),
    endingValue: types.maybeNull(types.number),
    startingDate: types.maybeNull(types.string),
    startingValue: types.maybeNull(types.number),
  })),
});
const keywordsData = types.model({
  declinedKw: types.maybeNull(types.number),
  improvedKw: types.maybeNull(types.number),
  serps: types.maybeNull(types.model({
    serp1: types.maybeNull(types.number),
    serp23: types.maybeNull(types.number),
    serp410: types.maybeNull(types.number),
    serp1120: types.maybeNull(types.number),
    serp2150: types.maybeNull(types.number),
    serp51100: types.maybeNull(types.number),

  })),
});

const paidMetricesTopAds = types.model({
  averageAdCount: types.maybeNull(types.number),
  averagePos: types.maybeNull(types.number),
  body: types.maybeNull(types.string),
  extendedBody: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});
const paidMetricesHistoricalData = types.model({
  budget: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  keywordCount: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
});
const paidMetrices = types.model({
  adsCount: types.maybeNull(types.number),
  budget: types.maybeNull(types.number),
  historicalData: types.maybeNull(types.array(paidMetricesHistoricalData)),
  keywordCount: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  topAds: types.maybeNull(types.array(paidMetricesTopAds)),
});

const TotalKeywordsModel = types.model({
  current: types.maybeNull(types.number),
  currentFormatted: types.maybeNull(types.string),
  kwDelta: types.maybeNull(types.number),
  previous: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  topThree: types.maybeNull(types.number),
});

export const pagesByTrafficData = types.model({
  key: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  pct: types.maybeNull(types.number),
});

export type PagesByTrafficType = Instance<typeof pagesByTrafficData>;

export const CoreReports = types.model({
  stats: Stats,
  dailySerpHistogramsAndClicks: types.optional(types.array(dailySerpHistogramsAndClicksItem), []),
  dailySerpHistogramsAndClicksPctChange: types.optional(types.array(positionHistogramsPctChange), []),
  emojiSerpHistogram: types.optional(types.array(EmojiDataModel), []),
  topPages: types.optional(types.array(TopPage), []),
  pagesByTraffic: types.array(pagesByTrafficData),
  serpChangeByPeriods: SerpChangeByPeriods,
  googleRankChanges: types.optional(types.array(GoogleRankChange), []),
  dailySearchVisibility: types.maybeNull(types.array(types.model({
    date: types.maybeNull(types.string),
    visibility: types.maybeNull(types.number),
  }))),
  dailyVolatility: types.maybeNull(types.array(types.model({
    date: types.maybeNull(types.string),
    volatility: types.maybeNull(types.number),
  }))),
  isDailyVisibilityLoading: types.maybeNull(types.boolean),
  isDailyVolatilityLoading: types.maybeNull(types.boolean),
  isGoogleRankLoading: types.maybeNull(types.boolean),
  isHistogramLoading: types.maybeNull(types.boolean),
  isOrganicMetricsLoading: types.maybeNull(types.boolean),
  keywordData: types.maybeNull(keywordsData),
  paidMetrices: types.maybeNull(paidMetrices),
  backlinkData: types.maybeNull(types.model({
    avgDomainRateDelta: types.maybeNull(types.number),
    curAvgDomainRate: types.maybeNull(types.number),
    curTotalBacklinks: types.maybeNull(types.number),
    curTotalRefdomains: types.maybeNull(types.number),
    prevAvgDomainRate: types.maybeNull(types.number),
    prevTotalRefdomains: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    totalBacklinksDelta: types.maybeNull(types.number),
    prevTotalBacklinks: types.maybeNull(types.number),
    totalRefdomainsDelta: types.maybeNull(types.number),
  })),
  totalKeywords: types.maybeNull(TotalKeywordsModel),
  totalTrafficValue: types.maybeNull(types.number),
  metricsloading: types.maybeNull(types.boolean),
  statsByPosition: types.optional(types.array(StatsByPosition), []),
  selectedTab: types.enumeration('TABS', Object.values(TABS)),
  filterTableDataSizeStats: types.maybeNull(types.number),
  filteredRenderDataStats: types.optional(types.array(StatsByPositionFiltered), []),
  pageSizeStats: types.maybeNull(types.number),
  pageNumberStats: types.maybeNull(types.number),
  filterKeywordTermStats: types.optional(types.string, ''),
  filterKeywordColumnsStats: types.optional(types.array(types.string), []),
  sortField: types.optional(types.string, ''),
  sortDirection: types.optional(types.string, ''),
  filterListStats: types.optional(types.array(FiltersModel), []),
  tableSizeStats: types.maybeNull(types.number),

  keywordsCountHistory: types.optional(types.array(KeywordCount), []),
  pagesTable: types.optional(types.array(PagesTable), []),
  loading: types.boolean,
  loadingBanner: types.boolean,
  isEmptyReport: types.boolean,
  isProcessing: types.maybeNull(types.boolean),
  loadingSerp: types.boolean,
  pageGroupingStatsStore: PageGroupingStatsStore,
  showBanner: types.boolean,
  isLatestDataFetched: types.boolean,
  isReprocessingActive: types.boolean,
  saLastFetchedAt: types.maybeNull(types.string),
  generatingPublicHashToken: types.boolean,
}).actions(self => {
  // Function to actualize latest gsc data
  const actualizeLatestGscData = flow(function* (property) {
    self.isReprocessingActive = true;
    try {
      const response = yield reportsApi.reprocessGscData(property);
      if (response) {
        if (response.saRecentdataTaskStatus !== 'SUCCESS') {
          yield new Promise(r => setTimeout(r, 10000));
          return actualizeLatestGscData(property);
        } else {
          self.isLatestDataFetched = true;
          self.isReprocessingActive = false;

          const rootStore = getRoot(self) as any;
          const activeProperty = rootStore?.navBar?.getSiteProperty(rootStore?.gsc?.criteria?.property);

          // If response last fetched at is not the same as one in Sites API then reload GSC data
          if (activeProperty?.saHistoricalLastFetchedAt !== response.saLastFetchedAt) {
            self.saLastFetchedAt = response?.saLastFetchedAt;
            rootStore?.gsc?.loadGSCData(rootStore?.gsc?.criteria, true);
            notification.success('Success!', 'Latest data has been fetched successfully.');
          // If both dates are the same, it means that latest data is already fetched, just show the info notification
          } else {
            notification.info('Already fetched!', 'GSC data is already up do date.');
          }
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  });

  const handleFilterRenderDataStatsByPostion = (filterKeywordTermPlacement, filters, pageNumberStats: number, pageSizeStats: number, sortField, sortDirection) => {
    self.loadingSerp = true;
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(self.statsByPosition, self.filterKeywordTermStats, self.filterKeywordColumnsStats, filters, pageNumberStats, pageSizeStats, sortField, sortDirection);
    self.filterTableDataSizeStats = dataFilteredSize;
    self.filteredRenderDataStats.length = 0;
    const newArray = dataFiltered.map(item => {
      return {
        position: item.position,
        clicks: item.clicks,
        ctr: item.ctr,
        impressions: item.impressions,
      };
    });
    self.filteredRenderDataStats = cast(newArray);
    self.loadingSerp = false;
  };

  const handleTableChangeStatsByPosition = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortField} = column || {};
    self.sortField = sortField;
    self.sortDirection = order;
    handleFilterRenderDataStatsByPostion(self.filterKeywordTermStats, self.filterListStats, self.pageNumberStats, self.pageSizeStats, self.sortField, self.sortDirection);
  };

  const onPaginationChangeStatsByPostion = async (pageNumberStats: number, pageSizeStats: number) => {
    self.pageSizeStats = pageSizeStats;
    self.pageNumberStats = pageNumberStats;
    handleFilterRenderDataStatsByPostion(self.filterKeywordTermStats, self.filterListStats, self.pageNumberStats, self.pageSizeStats, self.sortField, self.sortDirection);
  };

  const setPagesTable = data => {
    self.pagesTable = data;
  };

  const matchKeys = (pctValue, TooltipValues) => {
    switch (pctValue) {
      case 'serp1PctChange':
        return {endingValue: TooltipValues?.serp1EndingKwCount, startingValue: TooltipValues?.serp1StartingKwCount, startingDate: TooltipValues?.startingDate, endingDate: TooltipValues?.endingDate};
      case 'serp2To3PctChange':
        return {endingValue: TooltipValues?.serp23EndingKwCount, startingValue: TooltipValues?.serp23StartingKwCount, startingDate: TooltipValues?.startingDate, endingDate: TooltipValues?.endingDate};
      case 'serp4To10PctChange':
        return {endingValue: TooltipValues?.serp410EndingKwCount, startingValue: TooltipValues?.serp410StartingKwCount, startingDate: TooltipValues?.startingDate, endingDate: TooltipValues?.endingDate};
      case 'serp11To20PctChange':
        return {endingValue: TooltipValues?.serp1120EndingKwCount, startingValue: TooltipValues?.serp1120StartingKwCount, startingDate: TooltipValues?.startingDate, endingDate: TooltipValues?.endingDate};
      case 'serp2150PctChange':
        return {endingValue: TooltipValues?.serp2150EndingKwCount, startingValue: TooltipValues?.serp2150StartingKwCount, startingDate: TooltipValues?.startingDate, endingDate: TooltipValues?.endingDate};
      case 'serp51100PctChange':
        return {endingValue: TooltipValues?.serp51100EndingKwCount, startingValue: TooltipValues?.serp51100StartingKwCount, startingDate: TooltipValues?.startingDate, endingDate: TooltipValues?.endingDate};
      default:
        return {};
    }
  };

  const getKeysAndValues = (data, obj) => {
    const formatedData = [];
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        formatedData.push({
          key: key,
          value: value,
          toolTipData: matchKeys(key, obj),
        });
      }
    }

    return formatedData;
  };
  const setFromResponse = (data: any) => {
    if (!data?.isCancel) {
      self.stats.setStats(
        data?.clicks,
        data?.totalImpressions,
        data?.avgRankChanges,
        data?.totalKeywords,
      );
      self.totalKeywords = data?.totalKeywords;
      const getLegendsData = getKeysAndValues(data?.dailySerpHistogramsAndClicks?.positionHistogramsPctChange, data?.dailySerpHistogramsAndClicks?.serpKwCountTooltip);
      self.isEmptyReport = data?.isEmptyReport;
      self.isProcessing = data?.isProcessing;
      // self.serpChangeByPeriods = data.serpChangeByPeriods;
      self.topPages = data?.topPages || [];
      self.pagesByTraffic = data?.pagesByTraffic || [];
      self.totalTrafficValue = data?.dailySerpHistogramsAndClicks?.totalTrafficValue;
      self.dailySerpHistogramsAndClicks = data?.dailySerpHistogramsAndClicks.positionHistograms || [];
      self.dailySerpHistogramsAndClicksPctChange = cast(getLegendsData || []);
      self.googleRankChanges = data?.googleRankChangeChart || [];
      self.statsByPosition = data?.trafficByPosition?.ctrByPosition|| [];
      self.keywordsCountHistory = data?.keywordCountHistoricalChart || [];
      self.dailySearchVisibility = data?.dailySearchVisibility || [];
      self.dailyVolatility = data?.dailyVolatility || [];
      self.isDailyVisibilityLoading = data?.isDailyVisibilityLoading;
      self.isDailyVolatilityLoading = data?.isDailyVolatilityLoading;
      self.isGoogleRankLoading = data?.isGoogleRankLoading;
      self.isHistogramLoading = data?.isHistogramLoading;
      self.isOrganicMetricsLoading = data?.isOrganicMetricsLoading;
      self.keywordData=data?.keywordData;
      self.backlinkData=data?.backlinkData;
      // self.paidMetrices=data?.paidMetrics;
    }
  };
  const setEmojis = data => {
    if (data && !data.isCancel) {
      self.emojiSerpHistogram = cast(data);
    }
  };

  const doLoadCoreReports = flow(function* (criteria: CriteriaType) {
    const publicHash = getSingleUrlParam('public_hash');
    let data = null;
    const token = getTokenFromCookies();
    if ((token || publicHash) && criteria?.currentPeriodStart) {
      try {
        const response = yield reportsApi.getCoreReports(criteria, publicHash);
        if (response) {
          data = response;
        }
      } catch (e) {
        notification.warning('We’re bringing you a better experience', 'You may experience slower loading times until the dashboard update is completed. Thank you for your patience.');
      }
    }
    return data;
  });
  const getPaidMetrices = flow(function* (criteria: CriteriaType) {
    const publicHash = getSingleUrlParam('public_hash');
    const data = null;
    const token = getTokenFromCookies();
    if ((token || publicHash) && criteria?.currentPeriodStart) {
      self.metricsloading = true;

      try {
        const response = yield reportsApi.getPaidMetrices(criteria);
        if (response) {
          self.paidMetrices=response?.paidMetrics;
        }
      } catch (e) {
        notification.warning('We’re bringing you a better experience', 'You may experience slower loading times until the dashboard update is completed. Thank you for your patience.');
      } finally {
        self.metricsloading = false;
      }
    }

    return data;
  });

  const getPublicHashToken = flow(function* (criteria) {
    self.generatingPublicHashToken = true;
    try {
      const response = yield reportsApi.getPublicHashToken(criteria);
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    } finally {
      self.generatingPublicHashToken = false;
    }
  });

  const doLoadEmojis = flow(function* (domain, countryCode) {
    let data = [];
    try {
      const response = yield reportsApi.getEmojis(domain, countryCode);
      if (response) {
        data = response;
      }
    } catch (e) {
      return Promise.reject(e);
    }
    return data;
  });

  const loadCoreReports = flow(function* (criteria: CriteriaType) {
    self.loading = true;
    self.isEmptyReport = false;
    const response = yield doLoadCoreReports(criteria);
    if (response?.isCancel) return;
    if (response) {
      setFromResponse(response);
    }
    self.loading = false;
    self.loadingBanner = false;
  });
  const loadEmojis = flow(function* (domain?:any, countryCode?:any) {
    setEmojis(yield doLoadEmojis(domain, countryCode));
  });

  const setShowBanner = () => {
    self.showBanner = true;
  };

  const hideShowBanner = () => {
    self.showBanner = false;
  };

  const setSelectedTab = (tab: TABS) => {
    self.selectedTab = tab;
  };

  // Use this for organic and PPC pages the turn of loader on date range in navnar, since it is initially true
  const setIsLoadingCoreReports = (loading: boolean) => {
    self.loading = loading;
  };

  const setLastFetchedAt = () => {
    self.saLastFetchedAt = null;
  };

  return {
    getPaidMetrices,
    handleFilterRenderDataStatsByPostion,
    handleTableChangeStatsByPosition,
    onPaginationChangeStatsByPostion,
    setFromResponse,
    loadCoreReports,
    setPagesTable,
    loadEmojis,
    setShowBanner,
    hideShowBanner,
    setSelectedTab,
    setIsLoadingCoreReports,
    actualizeLatestGscData,
    setLastFetchedAt,
    getPublicHashToken,
  };
}).views( self => ({

  get getSelectedTab() {
    return self.selectedTab;
  },

  get getStatsByPosition() {
    return [...self.statsByPosition];
  },
  get getStatsByPositionFiltered() {
    return self.filteredRenderDataStats;
  },
  // filter field pagesTable
  // TODO: chec and cleanup this method.
  filterPagesTableData({filterKeywordTerm, filterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection}: FilterPagesTableProps) {
    // filtering data using filters in header.
    let dataFiltered = self.pagesTable.filter( d => d);

    // sorting data if defined
    if (sortField && sortDirection) {
      dataFiltered.sort( (a, b) => {
        const _dir = sortDirection === SORT_DIRECTION.ASCEND ? 1 : -1;
        if (a[sortField] > b[sortField]) return 1 * _dir;
        if (a[sortField] < b[sortField] * _dir) return -1 * _dir;
        return 0;
      });
    }

    // filtering by input term
    if (filterKeywordTerm) {
      dataFiltered = dataFiltered.filter( k => {
        const includeInResult = filterKeywordColumns.filter( col => {
          return k[col] && k[col].toLowerCase().includes(filterKeywordTerm.toLowerCase());
        }).length > 0;
        return includeInResult;
      });
    }
    if (filters && filters.length) {
      dataFiltered = dataFiltered.filter( k => {
        const shouldBeExcluded = filters.filter( f => {
          const itemValue = k[f.name];
          if (f.from === '' && f.to === '') return false;
          if (isNaN(itemValue)) return false;
          let isValid = true;
          if (f.from !== '') {
            const from = parseFloat(f.from);
            if (itemValue < from) isValid = false;
          }
          if (f.to !== '') {
            const to = parseFloat(f.to);
            if (itemValue > to) isValid = false;
          }
          return !isValid;
        }).length > 0;
        return !shouldBeExcluded;
      });
    }
    // Slice using pagination
    const startPaginationIndex = (pageNumber * pageSize) - pageSize;
    const endPaginationIndex = startPaginationIndex + pageSize;
    const dataFilteredSize = dataFiltered.length;
    dataFiltered = dataFiltered.slice(startPaginationIndex, endPaginationIndex);
    return {pagesData: dataFiltered, pagesDataSize: dataFilteredSize};
  },
}));

export type CoreReportsType = Instance<typeof CoreReports>;

export function initCoreReports() {
  return CoreReports.create(
    {
      metricsloading: false,
      loading: true,
      loadingBanner: true,
      isEmptyReport: false,
      isProcessing: false,
      isLatestDataFetched: false,
      isReprocessingActive: false,
      loadingSerp: true,
      stats: Stats.create({
        clicks: Stat.create(
          {
            title: 'ORGANIC TRAFFIC',
            currentFormatted: '',
            current: null,
            previous: null,
          },
        ),
        totalImpressions: Stat.create(
          {
            title: 'IMPRESSIONS',
            currentFormatted: '',
            current: 1,
            previous: 1,
          },
        ),
        avgRankChanges: Stat.create(
          {
            title: 'RANK CHANGE',
            currentFormatted: '',
            current: 1,
            previous: 1,
          },
        ),
        totalKeywords: Stat.create(
          {
            title: 'TOTAL KEYWORDS',
            currentFormatted: '',
            current: 1,
            previous: 1,
          },
        ),
      }),
      topPages: [],
      pagesByTraffic: [],
      dailySearchVisibility: [],
      isDailyVisibilityLoading: false,
      isDailyVolatilityLoading: false,
      isGoogleRankLoading: false,
      isHistogramLoading: false,
      isOrganicMetricsLoading: false,
      dailyVolatility: [],
      serpChangeByPeriods: {
        serp20: SerpChange.create({
          rising: 0,
          falling: 0,
          static: 0,
        }),
        serp11To20: SerpChange.create({
          rising: 0,
          falling: 0,
          static: 0,
        }),
        serp1To10: SerpChange.create({
          rising: 0,
          falling: 0,
          static: 0,
        }),
      },
      googleRankChanges: [],
      pageGroupingStatsStore: initPageGroupingStatsStore(),
      sortField: '',
      sortDirection: 'descend',
      showBanner: false,
      filterListStats: [],
      pageSizeStats: 7,
      pageNumberStats: 1,
      filterKeywordTermStats: '',
      filteredRenderDataStats: [],
      tableSizeStats: 0,
      emojiSerpHistogram: [],
      selectedTab: TABS.traffic,
      generatingPublicHashToken: false,
    },
  );
}
