import React from 'react';
import {observer} from 'mobx-react-lite';
import styles from '@/components/dashboard/home/content/index.module.scss';
import {CampaignTable} from '../campaignTable';
import styled from 'styled-components';

interface PageContentProps {
  isInOTTO?: boolean;
  campaignsId?: string;
  setCampaignsId?: (value: string) => void;
  componentIssueType?: string;
}

export const PageContent: React.FC<PageContentProps> = observer(({isInOTTO, campaignsId, setCampaignsId, componentIssueType}) => {
  return (
    <ContentWrapper className={styles.contentContainer} isInOTTO={isInOTTO}>
      <CampaignTable componentIssueType={componentIssueType} isInOTTO={isInOTTO} campaignsId={campaignsId} setCampaignsId={setCampaignsId}/>
    </ContentWrapper>
  );
});

const ContentWrapper = styled.div<{isInOTTO}>`
  margin-top:${p=>p.isInOTTO ? '0px' : '-70px'};
  padding:${p=>p.isInOTTO ? '0px' : ''};
  border:${p=>p.isInOTTO ? '1px solid #E8E8E8' : ''};
  padding:${p=>p.isInOTTO ? '0px' : ''};
  border-radius:${p=>p.isInOTTO ? '12px' : ''};
  box-shadow:${p=>p.isInOTTO ? '0px 3px 2px 0px #00000005' : ''};
  background:${p=>p.isInOTTO ? '#FFFFFF' : 'transparent'};
`;
