import {KEYWORD_EXPLORER_API} from '@/api/KeywordExplorer';
import {cast, flow, Instance, types, getRoot} from 'mobx-state-tree';
import {notification} from '@/utils/notification-v2';
import {PAGES_API} from '@/api/content-optimizer';
import {apiError} from '@/utils/api';

const ContentIdeaItemKeyword = types.model({
  keyword: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  isSelected: types.boolean,
  histSv: types.maybeNull(types.array(types.number)),
  linkedProject: types.frozen({}),
  isRankingFor: types.maybeNull(types.boolean),
  rankingPosition: types.maybeNull(types.number),
});

export type ContentIdeaItemKeywordType = Instance<typeof ContentIdeaItemKeyword>;


const ContentIdeaItem = types.model({
  index: types.maybeNull(types.number),
  parentKeyword: types.maybeNull(types.string),
  searchIntent: types.maybeNull(types.optional(types.frozen({}), types.array(types.string))),
  kd: types.maybeNull(types.number),
  keywordCount: types.maybeNull(types.number),
  kdLabel: types.maybeNull(types.string),
  totalSv: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  totalTrafficvalue: types.maybeNull(types.number),
  isStarred: types.maybeNull(types.boolean),
  isArchived: types.maybeNull(types.boolean),
  scaPageUuid: types.maybeNull(types.string),
  rankingPotential: types.maybeNull(types.string),
  clusterKeywordCount: types.maybeNull(types.number),
  additionalKeywords: types.maybeNull(types.array(ContentIdeaItemKeyword)),
  wordsArray: types.optional(types.array(types.string), []),
  loadingWordsArray: types.optional(types.boolean, false),
  suggestionsGenerated: types.optional(types.boolean, false),
});
const ContentIdeaTopItem = types.model({
  index: types.maybeNull(types.number),
  parentKeyword: types.maybeNull(types.string),
  searchIntent: types.maybeNull(types.optional(types.frozen({}), types.array(types.string))),
  kd: types.maybeNull(types.number),
  keywordCount: types.maybeNull(types.number),
  kdLabel: types.maybeNull(types.string),
  totalSv: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  totalTrafficvalue: types.maybeNull(types.number),
  isStarred: types.maybeNull(types.boolean),
  isArchived: types.maybeNull(types.boolean),
  scaPageUuid: types.maybeNull(types.string),
  rankingPotential: types.maybeNull(types.string),
  clusterKeywordCount: types.maybeNull(types.number),
  additionalKeywords: types.maybeNull(types.array(ContentIdeaItemKeyword)),
  wordsArray: types.optional(types.array(types.string), []),
  loadingWordsArray: types.optional(types.boolean, false),
  suggestionsGenerated: types.optional(types.boolean, false),
});

export type ContentIdeaItemType = Instance<typeof ContentIdeaItem>;

const ContentIdea = types.model({
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  processingCompletedAt: types.maybeNull(types.string),
  searchIntentTaskStatus: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  results: types.maybeNull(types.array(ContentIdeaItem)),
  topSuggestions: types.maybeNull(types.array(ContentIdeaTopItem)),
}).views(self => ({
  get clusterCount() {
    return self.results.length;
  },
}));

const ContentIdeasListItem = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  customerId: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  clusterCount: types.maybeNull(types.number),
  processingCompletedAt: types.maybeNull(types.string),
  processingStartedAt: types.maybeNull(types.string),
  searchedOn: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
});

export const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const clusterTablePaginationModel = types.model({
  current: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
});

export type ContentIdeasListItemType = Instance<typeof ContentIdeasListItem>;

export const ContentPlannerStore = types.model({
  loadingContentIdeasList: types.boolean,
  contentIdeasList: types.maybeNull(types.array(ContentIdeasListItem)),
  loadingContentIdea: types.boolean,
  loadingContentIdeaPublic: types.boolean,
  loadingStarIdea: types.boolean,
  contentIdea: types.maybeNull(ContentIdea),
  contentPlannerListRepolling: types.maybeNull(types.boolean),
  total: types.maybeNull(types.number),
  params: types.maybeNull(paramsModel),
  maxLookupReached: types.maybeNull(types.boolean),
  repollingContentIdeas: types.boolean,
  isSearchIntentRepolling: types.boolean,
  isSearchIntentLoading: types.maybeNull(types.boolean),
  clusterTablePagination: types.maybeNull(clusterTablePaginationModel),
}).actions(self => {
  const setPagination = value => {
    self.clusterTablePagination = value;
  };

  const getContentIdeasList = flow(function* () {
    try {
      const data = yield KEYWORD_EXPLORER_API.getContentIdeasList(self.params);
      if (data.isCancel) return;
      self.total = data?.count;
      self.contentIdeasList = cast(data?.results);

      if (data?.results?.find(x => x.processingStatus === 'PENDING') && self.contentPlannerListRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        return getContentIdeasListWithRePolling();
      }
      self.loadingContentIdeasList = false;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('Error while getting content ideas', errorMessage);
      self.loadingContentIdeasList = false;
    }
  });

  const getContentIdeasListWithRePolling = flow(function* () {
    self.loadingContentIdeasList = false;
    try {
      const data = yield KEYWORD_EXPLORER_API.getContentIdeasList(self.params);

      if (data.isCancel) return;
      self.total = data?.count;
      self.contentIdeasList = cast(data?.results);
      if (data?.results?.find(x => x.processingStatus === 'PENDING') && self.contentPlannerListRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        return getContentIdeasListWithRePolling();
      }
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('Error while refreshing content ideas', errorMessage);
    }
  });

  const createContentIdea = flow(function* (keyword: string, countryCode: string, url, listAPICall=true) {
    try {
      const countryCodeVal = countryCode ? countryCode.toLowerCase() : countryCode;
      const response = yield KEYWORD_EXPLORER_API.createContentIdea(keyword, countryCodeVal, url);
      if (response.isCancel) return;
      if (listAPICall) {
        getContentIdeasListWithRePolling();
      } else {
        return response;
      }
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('Failed to create content idea', errorMessage);
    }
  });


  const createContentIdeaPublic = flow(function* (keyword: string, countryCode: string) {
    try {
      const countryCodeVal = countryCode ? countryCode.toLowerCase() : countryCode;
      const response = yield KEYWORD_EXPLORER_API.createContentIdeaPublic(keyword, countryCodeVal);
      if (response.isCancel) return;
      self.maxLookupReached = false;
      return response;
    } catch (e) {
      if (e?.response?.status == 406) {
        notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
        self.maxLookupReached = true;
      }
      return Promise.resolve(e);
    }
  });


  const archiveIdea = flow(function* (id:any) {
    try {
      const response = yield KEYWORD_EXPLORER_API.archiveIdea(id);
      if (response.isCancel) return;
      getContentIdeasList();
    } catch (e) {
      return Promise.resolve(e);
    }
  });

  const archiveClusterIdea = flow(function* (id:any, data) {
    try {
      const response = yield KEYWORD_EXPLORER_API.archiveClusterIdea(id, data);
      const selectedClusterIndex = self.contentIdea.results.findIndex(e=>e.id == data.id);
      self.contentIdea.results[selectedClusterIndex].isArchived = !self.contentIdea.results[selectedClusterIndex].isArchived;


      if (response.isCancel) return;
    } catch (e) {
      return Promise.resolve(e);
    }
  });
  const starClusterIdea = flow(function* (id:any, data) {
    try {
      const response = yield KEYWORD_EXPLORER_API.starClusterIdea(id, data);

      const selectedClusterIndex = self.contentIdea.results.findIndex(e=>e.id == data.id);
      self.contentIdea.results[selectedClusterIndex].isStarred = !self.contentIdea.results[selectedClusterIndex].isStarred;
      if (response.isCancel) return;
    } catch (e) {
      return Promise.resolve(e);
    }
  });

  const refetchContentIdea = flow(function* (id:any, hash?: string) {
    try {
      const response = yield KEYWORD_EXPLORER_API.refetchContentIdea(id);
      if (response.isCancel) return;
      getContentIdea(id, hash);
    } catch (e) {
      return Promise.resolve(e);
    }
  });

  const getContentIdea = flow(function* (id: any, hash: string, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingContentIdea = true;
    }
    // self.contentIdea = cast({
    //   id: null,
    //   keyword: '',
    //   countryCode: '',
    //   results: [],
    // });
    try {
      const data = yield KEYWORD_EXPLORER_API.getContentIdea(id, hash);
      if (data.isCancel) return;
      self.contentIdea = cast(data);
      if ((data?.searchIntentTaskStatus == 'PENDING' || data?.processingStatus == 'PENDING') && self.isSearchIntentRepolling) {
        if (data?.searchIntentTaskStatus == 'PENDING') {
          self.isSearchIntentLoading = true;
          self.loadingContentIdea = false;
        }
        yield new Promise(r => setTimeout(r, 2000));
        return getContentIdea(id, hash, true);
      } else {
        self.loadingContentIdea = false;
        self.isSearchIntentLoading = false;
      }
    } catch (e) {
      self.loadingContentIdea = false;
      if (e?.response?.status == 404) {
        return e?.response?.status;
      } else {
        return Promise.resolve(e);
      }
    }
  });

  const getContentIdeaWithRepoll = flow(function* (id: any, hash: string) {
    self.loadingContentIdea = true;
    self.loadingContentIdeaPublic = true;
    self.contentIdea = cast({
      id: null,
      keyword: '',
      countryCode: '',
      results: [],
    });
    try {
      const data = yield KEYWORD_EXPLORER_API.getContentIdea(id, hash);
      if (data.isCancel) return;

      if (data?.processingStatus !== 'SUCCESS' && self.repollingContentIdeas) {
        yield new Promise(r => setTimeout(r, 2000));
        getContentIdeaWithRepoll(id, hash);
      } else {
        self.contentIdea = cast(data);
        self.loadingContentIdeaPublic = false;
      }
    } catch (e) {
      if (e?.response?.status == 404) {
        return e?.response?.status;
      } else {
        return Promise.resolve(e);
      }
    } finally {
      self.loadingContentIdea = false;
    }
  });


  const getandUpdateCluster = flow(function* (id: any, clusterId) {
    self.loadingContentIdea = true;
    try {
      const data = yield KEYWORD_EXPLORER_API.getContentIdea(id);
      if (data.isCancel) return;
      const selectedClusterIndex = self.contentIdea.results.findIndex(e=>e.id == clusterId);
      const updatedCluster = data.results.find(e=>e.id == clusterId);
      self.contentIdea.results[selectedClusterIndex].scaPageUuid = updatedCluster?.scaPageUuid;
    } catch (e) {
      return Promise.resolve(e);
    } finally {
      self.loadingContentIdea = false;
    }
  });

  const createBulkClusterArticle = flow(function* (contentIdeaId, clusters, payload) {
    try {
      const response = yield KEYWORD_EXPLORER_API.createClusterArticlesBulk(payload);
      const bulkuuidPayload = clusters?.filter(item => !item?.scaPageUuid)?.map((item, index) => {
        return {cluster_id: item?.id, uuid: response?.uuids[index]};
      });
      yield KEYWORD_EXPLORER_API.bulkClusteruuid(contentIdeaId, bulkuuidPayload);
      yield getContentIdea(contentIdeaId);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const setSearchIntentRepolling = value => {
    self.isSearchIntentRepolling = value;
  };

  const postScaUuid = flow(function* (id: number, payLoad: any) {
    try {
      const res = yield KEYWORD_EXPLORER_API.postContentIdeaScaPage(id, payLoad);
      setPagination( {
        current: 1,
        pageSize: 10,
      });
      return res;
    } catch (e) {
      return Promise.resolve(e);
    }
  });

  const setRepolling = value => {
    self.contentPlannerListRepolling = value;
  };

  const setParams = value => {
    self.params.search = value?.search;
    if (value?.type) {
      self.params.type = value?.type;
    }
    if (value?.page) {
      self.params.page = value?.page;
    }
    if (value?.pageSize) {
      self.params.page_size = value?.pageSize;
    }
    if (value?.ordering) {
      self.params.ordering = value?.ordering;
    }
    self.loadingContentIdeasList = true;
    getContentIdeasList();
  };

  const getContentIdeasData = flow(function* (keyword: string, countryCode: string, clusterId: number, id: string) {
    const selectedIndex = self.contentIdea.results.findIndex(e=>e.id == clusterId);
    self.contentIdea.results[selectedIndex]['loadingWordsArray'] = true;
    self.contentIdea.results[selectedIndex]['suggestionsGenerated'] = true;
    try {
      const response = yield PAGES_API.getContentIdeasDetails(keyword, countryCode, id);
      if (response?.contentIdeas?.length > 0) {
        const selectedClusterIndex = self.contentIdea.results.findIndex(e=>e.id == clusterId && e?.loadingWordsArray);
        self.contentIdea.results[selectedClusterIndex]['wordsArray'] = cast(response?.contentIdeas);
      }
      if (response.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return getContentIdeasData(keyword, countryCode, clusterId, id);
      } else {
        self.contentIdea.results[selectedIndex]['loadingWordsArray'] = false;
      }
    } catch (e) {
      const {status} = e.response;
      if (status === 429) {
        self.contentIdea.results[selectedIndex]['loadingWordsArray'] = false;
        const rootStore = getRoot(self) as any;
        rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
        const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
        const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
        notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
      } else {
        notification.error('Error loading Content Ideas', 'The Content Ideas data did not load properly. To fix the issue:');
      }
      Promise.reject(e);
    }
  });

  const loadContentIdeasData = flow(function* (keyword: string, countryCode: string, clusterId: number) {
    const selectedIndex = self.contentIdea.results.findIndex(e=>e.id == clusterId);
    self.contentIdea.results[selectedIndex]['loadingWordsArray'] = true;
    self.contentIdea.results[selectedIndex]['suggestionsGenerated'] = true;
    try {
      const response = yield PAGES_API.getContentIdeas(keyword, countryCode);
      if (response?.contentIdeas?.length > 0) {
        const selectedClusterIndex = self.contentIdea.results.findIndex(e=>e.id == clusterId && e?.loadingWordsArray);
        self.contentIdea.results[selectedClusterIndex]['wordsArray'] = cast(response?.contentIdeas);
      }
      if (response.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return getContentIdeasData(keyword, countryCode, clusterId, String(response?.id));
      } else {
        self.contentIdea.results[selectedIndex]['loadingWordsArray'] = false;
      }
    } catch (e) {
      const {status} = e.response;
      if (status === 429) {
        self.contentIdea.results[selectedIndex]['loadingWordsArray'] = false;
        const rootStore = getRoot(self) as any;
        rootStore?.settings?.customer?.profile?.matchAiConsumedTotalQuota();
        const totalAiQuota = rootStore?.settings?.customer?.profile?.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
        const quotaTitle = totalAiQuota ? `You've consumed all quota points ${totalAiQuota}/${totalAiQuota}.` : `You've consumed all quota points.`;
        notification.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
      } else {
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      }
    }
  });

  const setRepollingContentIdeas = value => {
    self.repollingContentIdeas = value;
  };

  const refetchContentIdeas = flow(function* (id:any) {
    try {
      yield KEYWORD_EXPLORER_API.refetchContentIdeas(id);
      getContentIdeasList();
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
    }
  });

  return {
    loadContentIdeasData,
    starClusterIdea,
    getContentIdeasList,
    refetchContentIdea,
    createContentIdea,
    getContentIdea,
    getandUpdateCluster,
    setRepolling,
    setSearchIntentRepolling,
    setParams,
    postScaUuid,
    archiveIdea,
    archiveClusterIdea,
    getContentIdeaWithRepoll,
    setRepollingContentIdeas,
    createContentIdeaPublic,
    createBulkClusterArticle,
    setPagination,
    refetchContentIdeas,
  };
});

export const initContentPlannerStore = () => {
  return ContentPlannerStore.create({
    loadingContentIdeasList: true,
    loadingStarIdea: false,
    contentIdeasList: [],
    loadingContentIdea: true,
    repollingContentIdeas: true,
    loadingContentIdeaPublic: false,
    clusterTablePagination: {
      current: 1,
      pageSize: 10,
    },
    contentPlannerListRepolling: true,
    isSearchIntentRepolling: true,
    isSearchIntentLoading: false,
    contentIdea: {
      id: null,
      keyword: '',
      countryCode: '',
      results: [],
    },
    params: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    maxLookupReached: false,
  });
};

