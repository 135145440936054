import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class OrganicCompetitorsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v1');

  public async getOrganicCompetitors(url: string, pageNumber?: number) {
    try {
      const response = await this.axios.get(`${OrganicCompetitorsApi.baseUrl}/organic_landscape`, {
        params: {
          url: url,
          page: pageNumber,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async processSiteProperty(property) {
    try {
      const response = await this.axios.post(`${OrganicCompetitorsApi.baseUrl}/organic_landscape`, {site_property: property}, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default OrganicCompetitorsApi;
