import React, {useEffect, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {Input, Spin} from 'antd';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {getSingleUrlParam} from '@/utils/url';
import {FieldWrapper} from '../../Modals/addPostModal';
import {notification} from '@/utils/notification-v2';

const {TextArea} = Input;

interface AddPostModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  editing?: boolean;
  setEditing?: (value: boolean) => void;
  data?: Record<string, any>;
}

export const AddQAModal: React.FC<AddPostModalProps> = ({openModal, setOpenModal, editing, data, setEditing}) => {
  const {ottoV2Store: {
    loadIssueTableData,
    ottoQAAiGeneratedAnswer,
    addQuestionAnswer,
    updateGBPOwnersAnswer,
  }} = useStore('');

  const [answer, setAnswer] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [generatingAiAnswer, setGeneratingAiAnswer] = useState(false);
  const initialErrorState = {
    'question': {
      message: 'Please provide question containing atleast 10 characters',
      state: false,
    },
    'answer': {
      message: 'Please provide answer',
      state: false,
    },
  };
  const [errorStates, setErrorStates] = useState(initialErrorState);
  const [question, setQuestion] = useState('');
  const [addingQA, setAddingQA] = useState(false);
  const uuid = getSingleUrlParam('uuid');

  useEffect(() => {
    if (generatedContent || question) {
      setErrorStates({...errorStates, question: {...errorStates['question'], state: false}});
    }
  }, [generatedContent, question]);

  // useEffect use to set data for editing post
  useEffect(() => {
    if (editing) {
      if (data?.ownersAnswer?.content) {
        setAnswer(data?.ownersAnswer?.content);
      }
      if (data?.content) {
        setQuestion(data?.content);
      }
    } else {
      resetStates();
    }
  }, [editing]);

  const resetStates = () => {
    setEditing && setEditing(false);
    setAnswer('');
    setGeneratedContent('');
    setGeneratedContent(null);
    setGeneratingAiAnswer(false);
    setErrorStates(initialErrorState);
    setQuestion('');
    setAddingQA(false);
  };


  const generateAiAnswer = async () => {
    if (generatingAiAnswer) return;
    setGeneratingAiAnswer(true);
    try {
      const response = await ottoQAAiGeneratedAnswer(1, {prompt: question});
      setAnswer(response);
      setErrorStates({...errorStates, answer: {...errorStates['answer'], state: false}});
      setGeneratingAiAnswer(false);
    } catch (err) {
      setGeneratingAiAnswer(false);
    }
  };

  const AddQA = async () => {
    if (!question || question.length < 11 || !answer) {
      setErrorStates({...errorStates,
        question: {...errorStates['question'], state: (!question || question.length < 11) ? true : false},
        answer: {...errorStates['answer'], state: !answer ? true : false},
      });
      return;
    }
    try {
      setAddingQA(true);

      const payload = {
        content: question,
        owners_answer: {
          content: answer,
        },
      };

      let response;
      if (editing) {
        response = await updateGBPOwnersAnswer(data?.id, {
          content: answer,
        });
      } else {
        response = await addQuestionAnswer(payload);
        await updateGBPOwnersAnswer(response?.id, {
          content: answer,
        });
      }
      setGeneratedContent(response);
      const params = {
        uuid,
        issue_type: 'qanda_suggestions',
        page_size: 10,
        page: 1,
        is_loading: false,
      };
      // isSitewide is related to alt image text
      notification.success('Success', `Q&A ${editing ? 'edited' : 'created'} successfully`);
      await loadIssueTableData(params, false);
      setAddingQA(false);
      setOpenModal(false);
      resetStates();
    } catch (err) {
      notification.error('', 'Something went wrong. Please try again');
      setAddingQA(false);
    }
  };

  return (
    <StyledTopicalMapsModal
      width={'560px'}
      closable={true}
      onClose={() => {
        setOpenModal(false);
        resetStates();
      }}
      closeIcon={<div><FontAwesomeIcon color={'#A3A4A4'} fontSize={22} icon={faXmark}/></div>}
      visible={openModal}
    >
      <StyledModalHeader>
        <div className='modal-heading'>{editing ? 'Edit': 'Add'} Q&A</div>
      </StyledModalHeader>
      <FieldWrapper>
        <div className='label' style={{marginTop: 12}}>Question</div>
        <div className='field-container'>
          <div className='body' style={{borderColor: errorStates['question'].state ? '#f96262': '', padding: 0}}>
            <StyledTextArea bordered={false} rows={2}
              placeholder='Write question here...'
              autoSize={{minRows: 4, maxRows: 4}}
              value={question}
              onChange={e => setQuestion(e?.target?.value)}
            />
          </div>
          {
            errorStates['question'].state ? <div className='error-message'>{errorStates['question'].message}</div> : <></>
          }
        </div>
      </FieldWrapper>
      <FieldWrapper>
        <div className='label' style={{marginTop: 12}}>Answer</div>
        <div className='field-container'>
          <div className='body' style={{borderColor: errorStates['answer'].state ? '#f96262': '', padding: 0}}>
            <StyledTextArea bordered={false} rows={2}
              placeholder='Write answer here...'
              autoSize={{minRows: 4, maxRows: 4}}
              value={answer}
              onChange={e => {
                if (e?.target?.value) {
                  setErrorStates({...errorStates, answer: {...errorStates['answer'], state: false}});
                }
                setAnswer(e?.target?.value);
              }}
            />
          </div>
          {
            errorStates['answer'].state ? <div className='error-message'>{errorStates['answer'].message}</div> : <></>
          }
          {
            question.length > 10 ?
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div onClick={generateAiAnswer} style={{color: '#2d6cca', cursor: 'pointer'}}>Answer the question with AI</div>
                {
                  generatingAiAnswer ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: '#2d6cca', marginLeft: 5}} spin />} /> : <></>
                }
              </div>: <></>
          }
        </div>
      </FieldWrapper>
      <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          setOpenModal(false);
          resetStates();
        }}>
            Cancel
        </div>
        <ButtonComponent
          color='blue'
          style='solid'
          type='button'
          className='update-button'
          onClick={AddQA}
          disabled={addingQA}
        >
          Create
          {
            addingQA ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>
          }
        </ButtonComponent>
      </StyledModalFooter>
    </StyledTopicalMapsModal>
  );
};

const StyledTopicalMapsModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
      font-size: 21px;
      right: 6px;
      top: 6px;
      font-weight: 700;
      color: #A3A4A4 !important;
      opacity: 1 !important;
      &:hover {
        opacity: 0.5 !important;
      }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 8px;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;

const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;

const StyledTextArea = styled(TextArea)`
  height: 100%;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
