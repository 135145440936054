import {observer} from 'mobx-react';
import {
  BodyWrapper,
  ButtonStyled,
  EmailPresetDescription,
  EmailPresetFieldsWrapper,
  EmailPresetSectionWrapper,
  FooterWrapper,
  MenuStyled,
  SelectStyled,
  SenderWrappper,
  StyledInput,
  SubjectWrapper} from '../../styledComponents';
import {Checkbox, Divider, Dropdown, Menu, Popover, Select} from 'antd';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {faEye} from '@fortawesome/pro-duotone-svg-icons';
import {EditorState, ContentState, convertFromHTML} from 'draft-js';
import 'draft-js/dist/Draft.css';
// import {EditorComponent} from '@/components/common-components/components/outreach/editor/editorComponent';
import {useEffect} from 'react';
import EditorComponentV2 from '@/components/common-components/components/outreach/editor/templateEditor';
import {useState} from 'react';
import styles from './styles.module.scss';
interface Props {
  subjectValue?: string;
  setSubjectValue?: (value: string) => void;
  useTemplateChecked?: boolean;
  setUseTemplateChecked?: (value: boolean) => void;
  setSender?: (value: number) => void;
  setExistingTemplateEditorState?: (value: EditorState) => void;
  setExistingTemplateId?: (value: number) => void;
  editorState?: string;
  onEditorV2Change?: (value: string) => void;
  sender?: number;
}

export const EmailPresetSection = observer(({
  subjectValue,
  setSubjectValue,
  useTemplateChecked,
  setUseTemplateChecked,
  setSender,
  setExistingTemplateEditorState,
  setExistingTemplateId,
  editorState,
  onEditorV2Change,
  sender,
}: Props) => {
  const {Option}= Select;
  const {campaigns: {
    campaignsCustomerEmails,
    existingTemplates,
    showErrors,
    setCurrentEditorState}} = useStore('');

  const onChange = value => {
    setSender(value);
  };

  const handleUseTemplateBtnClick = (body, subject, id) => {
    const {contentBlocks, entityMap} = convertFromHTML(body);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    const editorState = EditorState.createWithContent(contentState);
    setSubjectValue(subject);
    setExistingTemplateEditorState(editorState);
    setExistingTemplateId(id);
    setCurrentEditorState(editorState);
    onEditorV2Change(body);
  };

  useEffect(() => {
    setSender(sender ? sender : campaignsCustomerEmails?.results?.length ? campaignsCustomerEmails?.results[0]?.id : 0);
  }, []);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState({});

  const handlePopoverVisibleChange = (visible, index) => {
    const newPopoverVisible = {};
    if (visible) {
      newPopoverVisible[index] = true;
    }
    setPopoverVisible(newPopoverVisible);
  };

  const isSenderInCampaignEmails = sender && campaignsCustomerEmails?.results?.length && campaignsCustomerEmails?.results.find(email => email.id === sender);

  return <EmailPresetSectionWrapper>
    <EmailPresetDescription>The sender and template (subject with body) will be used for the scheduled outreach task.</EmailPresetDescription>
    <EmailPresetFieldsWrapper>
      <SenderWrappper>
        <div style={{color: '#121212', fontSize: '12px', marginRight: '35px'}}>Sender</div>
        <SelectStyled
          defaultValue={isSenderInCampaignEmails ? sender : campaignsCustomerEmails?.results?.length ? campaignsCustomerEmails?.results[0]?.id : 'Select Sender'}
          suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000'/>}
          onChange={onChange}
        >
          {campaignsCustomerEmails?.results?.map((item, idx) => <Option key={idx} value={item?.id}>
            {item?.emailAddress}
          </Option>)}
        </SelectStyled>
      </SenderWrappper>
      {!useTemplateChecked ? <>
        <SubjectWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '31px', marginBottom: showErrors && !subjectValue ? '20px' : ''}}>Subject</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <StyledInput
              value={subjectValue}
              placeholder='Email subject'
              width='100%'
              height='38px'
              borderRadius='8px'
              isError={showErrors && !subjectValue}
              onChange={e => setSubjectValue(e?.target?.value)}
            />
            {showErrors && !subjectValue && <div style={{color: 'red'}}>Please enter subject</div>}
          </div>
        </SubjectWrapper>
        <BodyWrapper>
          <div style={{color: '#121212', fontSize: '12px', marginRight: '45px'}}>Body</div>
          <div style={{display: 'flex', flexDirection: 'column', width: '89%'}}>
            <EditorComponentV2
              editorError={showErrors && (!editorState || editorState=='<p><br></p>')}
              value={editorState}
              onChange={onEditorV2Change}
              style={{width: '100%', borderRadius: '16px', height: '130px'}}
            />
            {showErrors && (!editorState || editorState=='<p><br></p>') && <div style={{marginTop: 40, marginBottom: -40, color: 'red'}}>Please enter body</div>}
          </div>
        </BodyWrapper>
      </> : null}
      <FooterWrapper useTemplateChecked={useTemplateChecked}>
        {!useTemplateChecked && <div>
          <Dropdown
            trigger={['hover']}
            placement='bottomLeft'
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
            overlay={
              <MenuStyled>
                {existingTemplates?.results?.map((item, idx) => <>
                  <Popover
                    overlayStyle={{maxWidth: '600px', borderRadius: 40}}
                    overlayInnerStyle={{padding: '10px', overflow: 'auto', maxHeight: '80vh'}}
                    zIndex={21}
                    trigger='hover'
                    placement='rightTop'
                    visible={popoverVisible[idx] || false}
                    onVisibleChange={visible => handlePopoverVisibleChange(visible, idx)}
                    overlayClassName={styles.rootClass}
                    content={
                      <div>
                        <div>
                          <div style={{fontWeight: '600', marginBottom: 5}}>{item?.name}</div>
                          <div style={{marginBottom: 5}}>{item?.subject}</div>
                          <div dangerouslySetInnerHTML={{__html: item?.body}}></div>
                        </div>
                        <ButtonStyled
                          style={{
                            width: 137,
                            height: 28,
                            borderRadius: 6,
                            display: 'flex',
                            justifyContent: 'center'}}
                          onClick={() => {
                            handleUseTemplateBtnClick(item?.body, item?.subject, item?.id);
                            setDropdownVisible(false);
                            setPopoverVisible({});
                          }}>
                            Use this template
                        </ButtonStyled>
                      </div>
                    }>
                    <Menu.Item key={idx} style={{backgroundColor: 'white'}}>
                      {item?.name}&nbsp;<FontAwesomeIcon icon={faEye}/>
                    </Menu.Item>
                  </Popover>
                  <Divider style={{margin: 0}} /></>)}
              </MenuStyled>
            }>
            <div style={{color: '#2D6CCA', cursor: 'pointer'}}>View existing templates&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown}/></div>
          </Dropdown>
        </div>
        }
        <Checkbox checked={useTemplateChecked} onChange={e => setUseTemplateChecked(e?.target?.checked)}>Don’t use a template</Checkbox>
      </FooterWrapper>
    </EmailPresetFieldsWrapper>
  </EmailPresetSectionWrapper>;
});
