import {faBolt} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {
  loading?: boolean;
}

export const EmptyStateComponent = ({loading}: Props) => {
  return <Wrapper>
    <FontAwesomeIcon icon={faBolt} fontSize={30} style={{marginRight: 10}}/>
    {loading ?
      <DescriptionWithLoading>Writing content</DescriptionWithLoading> :
      <Description>Fill the input fields and generate unique content.</Description>}
  </Wrapper>;
};

const Wrapper = styled.div`
  padding: 25px;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 60% auto;
`;
const DescriptionWithLoading = styled.div`
  color: #4E5156;
  width: 220px;

  &:after {
    content: ". . . ";
    overflow: hidden;
    display: inline-flex;
    vertical-align: bottom;
    animation: ellipsis steps(10, end) 1500ms infinite;
    margin-left: 3px;
    width: 0px;
    height: 22px;
  }

  @keyframes ellipsis {
      from {
        width: 0px;
      }
      to {
        width: 40px;
      }
    }
`;

const Description = styled.div`
  color: #4E5156;
  width: 220px;
`;
