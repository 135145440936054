import {observer} from 'mobx-react';
import {useState, useEffect} from 'react';
import styles from './style.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Button, GrayButton,
} from '@/components/common-components/v2/Button';

import {Divider, Tooltip} from 'antd';
import {faCheck, faCopy, faPlus, faRotateRight} from '@fortawesome/pro-regular-svg-icons';
import {SCA_PAGE_CONTENT_IDEA_COPY, SCA_CREATE_PAGE} from '@/constants/events';

import {MixPanel} from '@/utils/mixpanel';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import parse from 'html-react-parser';
import {CONTENT_ASSISTANT_API} from '@/api/ContentAssistant';
import {useRouter} from 'next/router';
import {routes} from '@/utils/const';
import {copyToClipboardWithHtmlAndText} from '@/utils/clipboard';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {notification} from '@/utils/notification-v2';
import {getEditorState} from '@/utils/editor-scripts';
import {convertHtmlToEditorState} from '@/utils/htmlToDraft';

interface Props {
  aiOutline: any;
  insertToEditor: any;
  outlineId: number;
}

export const SingleAiOutline = observer(({aiOutline, insertToEditor, outlineId}: Props) => {
  const {contentOptimizer: {
    currentPage: {content},
    currentPage,
    aiOutline: {
      aiTemplatesController,
      oneClickContentIdea,
      loading,
      setAiArticleAlreadyReplaced,
      aiArticleAlreadyReplaced,
      setDisabledArticleId,
      disabledArticleId,
      setCreatingAiArticleSuccessStatus,
      setCreatingAiArticleFailureStatus,
    }}} = useStore('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [convertedText, setConvertedText] = useState(null);
  const [textForInjection, setTextForInjection] = useState(null);
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');
  const {settings: {customer: {profile: {isAllowedPagesQuotaLimitReached, quotaUtilization}}}} = useStore('');
  const {plans: {showSidebarPaymentDrawer}} = useStore('');

  const router = useRouter();

  useEffect(() => {
    const handlePopstate = () => {
      // Change the state in your MobX store when the user clicks the back button.
      setAiArticleAlreadyReplaced(false);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const {t} = useTranslation('landing-page-optimizer');

  useEffect(() => {
    // Removing the leading space from string
    // const newLineIndex = aiOutline.paragraph?.search('\n');
    // const textWithoutLeadingSpace = aiOutline.paragraph?.slice(0, newLineIndex + 1) + aiOutline.paragraph?.slice(newLineIndex + 2);
    // const firstTitle = aiOutline.paragraph?.slice(0, newLineIndex + 1);
    // const result = textWithoutLeadingSpace.replace(firstTitle, '<span>' + firstTitle + '</span>');

    const newText = aiOutline?.cleanedHtml?.split('\n').map((str, id) => <p key={id}>{str}</p>);
    let textToInject = '';
    const htmlTagRegex = /^<[^>]*>/;
    aiOutline?.cleanedHtml?.split('\n\n').forEach((str, idx) => {
      if (idx == 0 && htmlTagRegex.test(str)) {
        textToInject += str;
      } else {
        textToInject += `<p>${str}</p>`;
      }
    });

    setConvertedText(newText);

    setTextForInjection(textToInject);
  }, [aiOutline]);


  // const downloadFileToTxt = () => {
  //   const element = document.createElement('a');
  //   const file = new Blob([aiOutline], {type: 'text/plain'});
  //   element.href = URL.createObjectURL(file);
  //   const breakFileName = currentPage.content?.title?.split(' ')?.length;
  //   const fileName = breakFileName && breakFileName > 4 ? currentPage.content?.title?.split(' ').slice(0, 4).join('-') : currentPage.content?.title?.split(' ').slice(0, breakFileName).join('-');
  //   element.download = `${fileName}`;
  //   document.body.appendChild(element);
  //   element.click();
  // };

  const countWords = s => {
    s = s.replace(/(^\s*)|(\s*$)/gi, '');// exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' ');// 2 or more space to 1
    s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
    return s.split(' ').filter(function(str) {
      return str != '';
    }).length;
    // return s.split(' ').filter(String).length; - this can also be used
  };


  const handleCreateContent = async () => {
    if (isWhitelabel && isAllowedPagesQuotaLimitReached) {
      notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
    } else if (!isAllowedPagesQuotaLimitReached) {
      const pagePayload = {};
      pagePayload['title'] = oneClickContentIdea ?? '';
      pagePayload['cleaned_html'] = textForInjection ?? '';
      pagePayload['hero_image'] = content.heroImage ?? '';

      const {uuid} = await CONTENT_ASSISTANT_API.createPage(pagePayload);

      MixPanel.track(SCA_CREATE_PAGE, {
        'pageId': uuid,
      });

      router.push(`/${routes.landingPageOptimizer}/${uuid}`, null, {shallow: true});
    } else {
      showSidebarPaymentDrawer(null, {title: 'You need more Content Genius articles quota to continue', subtitle: '', text: `Current limit: ${quotaUtilization?.ca?.allowedPages?.total} article(s)`});
    }
  };

  const replaceArticleClick = outlineId => {
    let editorState;
    if (aiOutline?.editorState) {
      editorState = getEditorState(aiOutline?.editorState, currentPage);
    } else {
      editorState = convertHtmlToEditorState(textForInjection);
    }
    setCreatingAiArticleSuccessStatus(false);
    setCreatingAiArticleFailureStatus(false);
    setAiArticleAlreadyReplaced(true);
    insertToEditor(editorState, outlineId, true);
    setDisabledArticleId(outlineId);
  };

  return (
    <div className={styles.ContentIdeaContainer}>
      <p className={styles.ContentIdea}>
        {/* {convertedText} */}
        {convertedText && parse(aiOutline?.cleanedHtml||'')}

      </p>
      <div className={styles.ideaOrder}>{outlineId + 1}</div>
      {loading || content.aiInProgress? <>
        <SkeletonHorizontalLoaderGray lightLoader width={165} height={10} skeletonHeight={10} skeletonMarginBottom='12px' skeletonMarginTop='11px'/>
        <SkeletonHorizontalLoaderGray lightLoader width={255} height={10} skeletonHeight={10} skeletonMarginTop='12px'/>
      </> :
        <>
          <Divider className={styles.popoverDivider} />
          <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'default'}}>

            <div className={styles.controlButtons}>
              {!aiTemplatesController?.isStandaloneTool ?
                <>
                  {aiArticleAlreadyReplaced && outlineId == disabledArticleId ?
                    <>
                      <Tooltip
                        trigger={'hover'}
                        title={'Article content was already replaced by AI Generated Content.'}
                        placement='top'
                        mouseLeaveDelay={0}
                        getPopupContainer={trigger => trigger}>
                        <div>
                          <Button
                            disabled={true}
                            color={aiOutline?.isAdded ? 'green' : 'purple'}
                            variant='ghost'
                            style={{padding: '5px 10px', marginLeft: 0, marginRight: 5, fontSize: 12}}
                            className={styles.addToArticleBtn}
                            onClick={() => replaceArticleClick(outlineId)}>
                            {aiOutline?.isAdded ? <span>
                              <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}}/>
                                Added
                            </span> :
                              <span>
                                <FontAwesomeIcon icon={faRotateRight} style={{marginRight: 10}}/>
                                Replace article with AI Content
                              </span>}
                          </Button>
                        </div>
                      </Tooltip>
                    </> : <Button
                      disabled={loading}
                      color={aiOutline?.isAdded ? 'green' : 'purple'}
                      variant='ghost'
                      style={{padding: '5px 10px', marginLeft: 0, marginRight: 5, fontSize: 12}}
                      className={styles.addToArticleBtn}
                      onClick={() => replaceArticleClick(outlineId)}>
                      {aiOutline?.isAdded ? <span>
                        <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}}/>
                          Added
                      </span> :
                        <span>
                          <FontAwesomeIcon icon={faRotateRight} style={{marginRight: 10}}/>
                          Replace article with AI Content
                        </span>}
                    </Button>}</> : null}
              <GrayButton onClick={() => handleCreateContent()} disabled={loading} style={{padding: '5px 10px', fontSize: 12, marginLeft: 5}}>
                <FontAwesomeIcon icon={faPlus} style={{marginRight: 3}} />
                Create new article
              </GrayButton>
              {/* {!aiTemplatesController?.isStandaloneTool ? <Button
              color={'purple'}
              variant='ghost'
              style={{padding: '5px 10px', marginLeft: 0, marginRight: 5, fontSize: 12}}
              className={styles.addToArticleBtn}
              onClick={() => {
                insertToEditor(`<p>` + textForInjection, outlineId, true);
              }}>
              <span>
                <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}}/>
                  Replace current article
              </span>
            </Button> : null} */}
              <Tooltip
                title={copySuccess ? <span><FontAwesomeIcon icon={faCheck} style={{color: '#2AC155'}} />{` Copied to clipboard`}</span> : t('copy-to-clipboard')}
                trigger='hover'
                placement='bottom'
                overlayClassName={styles.copyTooltip}
                getPopupContainer={trigger => trigger}
                onVisibleChange={visible => {
                  if (!visible) {
                    setTimeout(() => {
                      setCopySuccess(false);
                    }, 300);
                  }
                }}>
                <GrayButton
                  disabled={loading}
                  style={{padding: '5px 10px', fontSize: 12, marginLeft: 0, marginTop: '10px'}}
                  onClick={() => {
                    copyToClipboardWithHtmlAndText(`${aiOutline?.cleanedHtml}`, `${aiOutline?.cleanedHtml||''}`.replace(/<[^>]*>/g, ''));
                    setCopySuccess(true);

                    MixPanel.track(SCA_PAGE_CONTENT_IDEA_COPY, {
                      'description': 'Content idea copied',
                    });
                  }}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <FontAwesomeIcon icon={faCopy} style={{marginRight: 5}}/>
                    Copy content ({countWords(aiOutline?.cleanedHtml||'')} words)
                  </div>
                </GrayButton>

              </Tooltip>

              {/* <Button
              style={{marginLeft: 5}}
              disabled={rewritingOutline}
              color='white'
              className={styles.copyButton}
              onClick={() => rewriteOutline(aiOutline, outlineId)}>
              <span>
                <FontAwesomeIcon icon={faArrowsRotate} style={{marginRight: 5}} spin={rewritingOutlineId == outlineId && rewritingOutline}/>
                {rewritingOutlineId == outlineId && rewritingOutline ? 'Rewriting' : 'Rewrite'}
              </span>
            </Button> */}
              {/* <Tooltip
              title={'Download'}
              trigger='hover'
              placement='bottom'
              overlayClassName={styles.copyTooltip}
            >
              <FontAwesomeIcon icon={faDownload} className={styles.downloadButton} onClick={() => {
                downloadFileToTxt();

                MixPanel.track(SCA_PAGE_AI_OUTLINE_DOWNLOAD, {
                  'description': 'Content idea downloaded',
                });
              }} />
            </Tooltip> */}


              {/* <span style={{fontSize: 12}}>{`${countWords(aiOutline)} words`}</span> */}
            </div>
          </div>
        </>
      }
    </div>
  );
});
