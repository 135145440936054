import {observer} from 'mobx-react';
import {GrayCircle, PinPointWrapper, SpinnerWrapper} from './styled';
import {pinPoint} from '@/utils/icons';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

export const UniversalLoader = observer(({width, height, positionTop, positionLeft}: any) => {
  return (
    <GrayCircle width={width} height={height} positionTop={positionTop} positionLeft={positionLeft}>
      <PinPointWrapper>
        {pinPoint('#4E5156', '')}
        <SpinnerWrapper>
          <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: 'white'}} spin />} />
        </SpinnerWrapper>
      </PinPointWrapper>
    </GrayCircle>
  );
});
