import styles from '../../style.module.scss';
import {
  Table,
} from '@/components/common-components/components';
import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import {formatLargeNumber} from '@/utils/number';
import {Button} from '@/components/common-components/v2';
import styled from 'styled-components';

interface Props {
  index: number;
  addItem: (type: 'term' | 'question', index: number, value?: string) => void;
  usedTerms: string[];
}

export const TermsTable = ({index, addItem, usedTerms}: Props) => {
  const {
    contentOptimizer: {
      currentPage,
    },
  } = useStore('');

  const colums = [
    {
      title: 'TOPICAL TERM',
      dataIndex: 'name',
      key: 'name',
      sortFieldName: 'name',
      align: 'left' as 'left',
      // sortDirections: ['descend', 'ascend'],
      width: 200,
      render: (_, record) => {
        return (
          <div style={{maxWidth: 170, overflow: 'hidden', whiteSpace: 'pre-wrap', fontSize: 12, color: usedTerms?.includes(record.name) ? '#a3a4a4' : '#121212'}}>
            {record.name}
          </div>
        );
      },
    },


    // {
    //   title: <><div>RANKING</div><div>POTENTIAL</div></>,
    //   dataIndex: 'normalizedScore',
    //   key: 'normalizedScore',
    //   sortFieldName: 'normalizedScore',
    //   align: 'left' as 'left',
    //   // sortDirections: ['descend', 'ascend'],
    //   width: 70,
    //   render: (_, record) => {
    //     return (
    //       <div style={{fontSize: 12, color: '#121212'}}>
    //         {record.normalizedScore}
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'SV',
      dataIndex: 'sv',
      key: 'sv',
      sortFieldName: 'sv',
      align: 'center' as 'center',
      // sortDirections: ['descend', 'ascend'],
      width: 50,
      render: (_, record) => {
        return (
          <div style={{fontSize: 12, color: '#121212'}}>
            {`${formatLargeNumber(record.sv)}`}
          </div>
        );
      },
    },
    {
      title: 'CPC',
      dataIndex: 'cpc',
      key: 'cpc',
      sortFieldName: 'cpc',
      align: 'center' as 'center',
      // sortDirections: ['descend', 'ascend'],
      width: 50,
      render: (_, record) => {
        return (
          <div style={{fontSize: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#121212'}}>
            {`$${formatLargeNumber(record.cpc)}`}
            <VerticalProgress>
              <ProgressFill></ProgressFill>
            </VerticalProgress>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      sortFieldName: '',
      align: 'center' as 'center',
      // sortDirections: ['descend', 'ascend'],
      width: 80,
      render: (_, record) => {
        return (
          <div>
            <Button variant='ghost' color='blue' style={{fontSize: 12, padding: '5px 10px'}} onClick={() => addItem('term', index, record.name)}>+ Add</Button>
          </div>
        );
      },
    },
  ];

  return <div className={styles.tableOuterTerms}>
    <Table
      pagination={false}
      columns={colums}
      scroll={{x: 350, y: 300}}
      dataSource={toJS(currentPage?.analytics?.focusTerms)}
      // loading={initLoading}
      // loadingRows={pageSize}
      // onChange={handleTableChange}
    />
  </div>;
};

const VerticalProgress = styled.div`
  height: 12px;
  width: 3px;
  background-color: #A3A4A4;
  border-radius: 5px;
  position: relative;
  margin-bottom: 2px;
  margin-left: 2px;
`;

const ProgressFill = styled.div`
  height: 5px;
  width: 3px;
  background-color: #34AEF3;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
