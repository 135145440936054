import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {Editor} from 'draft-js';

export const EditorSectionWrapper = styled.div<{width?: string; height?: string; showFooter?: boolean; padding?: string}>`
  .DraftEditor-root {
    background-color: #fff !important;
    height: auto !important;
    max-height: auto !important;
    padding: ${p => p?.padding ? p?.padding : '10px 25px !important'};
    width: ${p => p?.width ? p?.width : '600px'} !important;
    ${p => p?.showFooter ? '' : 'border-radius: 0px 0px 8px 8px !important'};
    border: 1px solid #E0E0E0 !important;
    margin: 0 !important;
    min-height: ${p => p?.height ? p?.height : '80px'} !important;
  }

  .public-DraftEditor-content {
    min-height: ${p => p?.height ? p?.height : '80px'} !important;
  }
`;

export const StylingSections = styled.div`
  display: flex;
  background-color: #F9F9FB;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #E8E8E8;
  padding: 5px 10px;
`;

export const ButtonStyledV2 = styled(Button)<{width?: string}>`
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  width: ${p => p?.width ? p?.width : '187px'};
  height: 44px;
  font-weight: 600;
  background: #2D6CCA;

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
  &:disabled {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #E8E8E8;
  background: #F2F2F5;
`;

export const VariablesWrapper = styled.div`
  display: flex;
  gap: 6px;
  padding: 0 15px 10px 15px;
`;

export const Variables = styled.div`
  width: fit-content;
  height: 26px;
  background-color: white;
  border-radius: 38px;
  border: 1px solid #E8E8E8;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4E5156;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    border-color: #2D6CCA;
    background-color: rgba(45, 108, 202, 0.2);
  }
`;

export const EditorStyled = styled(Editor)`
  position: relative;
`;

export const ButtonStyled = styled(Button)`
  margin: 50px 0px 0px 0px !important;
  border-radius: 6px;
  background: #2D6CCA;

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), #2D6CCA !important;
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), #2D6CCA !important;
  }
  &:disabled {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)), #2D6CCA !important;
  }
`;
