import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styles from './style.module.scss';
import classnames from 'classnames';
import {RootStoreType, useStore} from '@/store/root-store';
import {notification as antdNotification} from 'antd';
import {useRouter} from 'next/router';
import {ProjectActiveModal} from '@/components/common-components/components/project-active-modal/project-active';
import {ConfettiAnimation} from '@/components/common-components/components/confetti-animation';
import firebase from 'firebase/app';
import 'firebase/database';
import {getDomain, getSingleUrlParam, isNavbarRemoveOnPublicPages, isSidebarRemoveOnPublicPages} from '@/utils/url';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MenuItemsBar} from '../publicMenu/menuItems';
import {useLocalStorage} from '@/utils/hooks/useLocalStorage';
import styled from 'styled-components';
import {NoSsr} from '@/components/common-components';
import {getTokenFromCookies} from '@/api/common-utils';
import {routes} from '@/utils/const';
import {faMicrochipAi} from '@fortawesome/pro-duotone-svg-icons';
import Link from 'next/link';
import {ScaTopUpQuotaModal} from '../header/headerComponents/scaTopUpQuotaModal';
import {isScaPublicPage} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/LeftSide/EditorSection/content-editor/utils';
import {linkgraphDomains} from '@/utils/router';
import {isPagesPublicPage} from '@/utils/url';
import {NextImg} from '@/utils/nextImg';

type HeaderProps = {
  store: RootStoreType;
  handleSideBarItems?: (items) => void;
};

const config = {
  apiKey: 'AIzaSyAAnv2XuzvhHREv_x9tPOKgtEhCgCpkRzo',
  authDomain: 'organic-ruler-207123.firebaseapp.com',
  databaseURL: 'https://organic-ruler-207123-default-rtdb.firebaseio.com',
  projectId: 'organic-ruler-207123',
  storageBucket: 'organic-ruler-207123.appspot.com',
  messagingSenderId: '88261415951',
  appId: '1:88261415951:web:db784e7f23f543a1b3ff7a',
  measurementId: 'G-8CMC6QJNNS',
};

!firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

const PublicHeader = observer(({store, handleSideBarItems}: HeaderProps) => {
  const router = useRouter();
  const {
    contentOptimizer: {currentPage: {isEditorScrolled}},
    navBar: {
      setIsDockedSider,
      isDockedSider,
    },
    settings: {
      customer: {setAuthModal, setShowScaTopUpModal, profile, showTopUpConfirmationModal},
    },
  } = useStore('');
  const {settings: {customer: {loadProfile}}} = useStore('');
  const token = getTokenFromCookies();
  useEffect(() => {
    if (token ) {
      loadProfile();
    }
  }, [token]);

  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);

  useEffect(() => {
    antdNotification.destroy();
  }, [router]);
  const [isDocked, setIsDocked] = useLocalStorage<boolean>('isDocked', true);

  useEffect(() => {
    setIsDockedSider(isDocked);
  }, [isDocked]);

  const {
    plans: {
      projectActivePopup,
    },
  } = useStore('');
  const {settings: {customer: {profile: {logo, hasCompletedRegistration, showConfettiAnimation}}}} = useStore('');
  const isBacklinkAnalyzer = router.pathname.match(/\/backlink.*/g);
  const showProjectsPicker = (
    !store.backLinkAnalyzer.projectStore.loading &&
    store.backLinkAnalyzer.projectStore.currentProject
  );
  const [customerLogo, setCustomerLogo] = useState(logo);

  useEffect(() => {
    setCustomerLogo(logo);
  }, [logo]);

  const getProjectFromUrl = () => {
    const backlinkProject = getSingleUrlParam('sp');
    if (backlinkProject) {
      store.backLinkAnalyzer.projectStore.setCurrentProject(backlinkProject, false);
      store.backLinkAnalyzer.refdomainStore.selectCurrentProject(backlinkProject);
    }
  };

  const pendingInvoice = store.invoices.getPendingInvoices.filter(item => item.status == 'PENDING' || item?.status?.toLowerCase() == 'failed');
  if (pendingInvoice.length > 0) {
    store.invoices.openModal();
  }
  useEffect(()=>{
    if (pendingInvoice?.length == 0) {
      store.invoices.closeModal();
    }
  }, []);

  useEffect(() => {
    if (showProjectsPicker && isBacklinkAnalyzer) {
      getProjectFromUrl();
    }
  }, [showProjectsPicker, isBacklinkAnalyzer]);

  // close sca top up modal on dismount
  useEffect(() => {
    return () => {
      setShowScaTopUpModal(false);
    };
  });

  // const addDefaultSrc = e => {
  //   e.target.src = '';
  // };
  return (<NoSsr>
    <WrapperMain style={{padding: isEditorScrolled ? '0' : '15px 15px 0 15px', background: '#24262a', height: isEditorScrolled ? '0' : 'auto', transition: 'all 0.2s ease'}}>
      <div className={styles.header} style={{display: isEditorScrolled ? 'none' : 'flex'}}>
        {isNavbarRemoveOnPublicPages() || isScaPublicPage() ? <div className={styles.logoWrapper}>
          <NextImg className={styles.hideimg}
            style={{maxHeight: '100px', maxWidth: '200px', height: '100%', padding: 5, overflow: 'hidden', marginLeft: '5px', display: 'flex', alignItems: 'center', transition: 'all 0.2s ease', width: '100%', objectFit: 'contain'}}
            onClick={() => setAuthModal(true, true)}
            src={isWhiteLabelDomain ? profile.logo : '/img/logo/searchAtlas_new_login.svg'}
            alt='logo'
            // onError={e => addDefaultSrc(e)}
          />
        </div> : <div
          style={isDockedSider ? {minWidth: '197px', maxWidth: '197px'} : {}}
          className={`${styles.logo} ${isDockedSider ? '' : styles.hideLogo}`}>
          <NextImg className={styles.imgStyle} src='/icons/collapsed.svg'
            height={18} width={18}
            onClick={() => setIsDocked(!isDocked)}
          />
          {/* We do not show anything if its WL without logo or if its WL with logo with generic logo */}
          {!customerLogo ?
            <div
              onClick={()=>setAuthModal(true, false)}
              style={{width: '100%', height: '100%'}}>
            </div> :
            <div className={styles.logoWrapper}>
              <NextImg className={styles.hideimg}
                style={{height: '100%', padding: 5, overflow: 'hidden', marginLeft: '5px', display: 'flex', alignItems: 'center', transition: 'all 0.2s ease', width: '100%', objectFit: 'contain'}}
                onClick={() => setAuthModal(true, true)}
                src={isWhiteLabelDomain ? customerLogo : '/img/logo/searchAtlas_new_login.svg'}
                alt='logo'
                // onError={e => addDefaultSrc(e)}
              />
            </div>
          }
        </div>
        }
        {isNavbarRemoveOnPublicPages() || isScaPublicPage() ? '' : <>
          {showTopUpConfirmationModal && <ScaTopUpQuotaModal />}
          {projectActivePopup && <ProjectActiveModal
            visible={projectActivePopup}
            closable={true}
          />}

          {hasCompletedRegistration && showConfettiAnimation &&
        <ConfettiAnimation />
          }

          {!isPagesPublicPage() && <UserMenuRight style={{display: 'flex', flexGrow: 1, position: 'sticky', alignItems: 'center'}}>
            <Link legacyBehavior href={`/${routes.ottopagePublic}`} passHref>
              <a rel='noreferrer' href='/otto-page-public'>
                <div className={classnames(styles.boxItemStyle, router.pathname == '/otto-page-public' ? styles.selected : '')}>
                  <FontAwesomeIcon icon={faMicrochipAi}/>{profile.whitelabelOtto} SEO Autopilot
                </div>
              </a>
            </Link>
          </UserMenuRight>}</>}
        {isSidebarRemoveOnPublicPages() ? '' : <MenuItemsBar handleSideBarItems={handleSideBarItems}/>}
      </div>
    </WrapperMain>
  </NoSsr>
  );
});

export default PublicHeader;

const WrapperMain = styled.div`
  padding: 15px 15px 0 15px;
  background: #24262a !important;
`;

const UserMenuRight = styled.div`
  display: flex;
  position: sticky; 
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;
