import {CONTENT_QUALITY_API} from '@/api/content-optimizer';
import {filterDataInMemory} from '@/utils/filter-data';
import {toJS} from 'mobx';
import {cast, flow, Instance, types} from 'mobx-state-tree';

export const TopCompetitor = types.model({
  url: types.maybeNull(types.string),
  hostname: types.maybeNull(types.string),
});
export const WordCountLimits = types.model({
  upper: types.maybeNull(types.number),
  lower: types.maybeNull(types.number),
});
export const RankingKeywords = types.model({
  id: types.maybeNull(types.number),
  toggled: types.maybeNull(types.boolean),
  keyword: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  globalSv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  usedInContent: types.maybeNull(types.boolean),
  usedInHeadings: types.maybeNull(types.array(types.string)),
  taskStatus: types.maybeNull(types.string),
});
export const ContentQuality = types.model({
  id: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
  wordCount: types.maybeNull(types.number),
  suggestedWordCountLimits: types.maybeNull(WordCountLimits),
  readability: types.maybeNull(types.string),
  focustermsTotalCount: types.maybeNull(types.number),
  focustermsUsedCount: types.maybeNull(types.number),
  questionsTotalCount: types.maybeNull(types.number),
  questionsUsedCount: types.maybeNull(types.number),
  topCompetitor: types.maybeNull(TopCompetitor),
  rankingKeywords: types.array(RankingKeywords),
  shouldRepoll: types.maybeNull(types.boolean),
});

export type ContentQualityType = Instance<typeof ContentQuality>;

export const ContentQualityStore = types.model({
  contentQuality: types.maybeNull(ContentQuality),
  loading: types.boolean,
  tableDataLoading: types.boolean,
  filterKeywordTerm: types.maybeNull(types.string),
  filterKeywordColumns: types.maybeNull(types.array(types.string)),
  tableDataSize: types.maybeNull(types.number),
  tableData: types.maybeNull(types.array(RankingKeywords)),
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  filterList: types.maybeNull(types.array(types.string)),
  sortField: types.maybeNull(types.string),
  sortDirection: types.maybeNull(types.string),
  property: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
}).actions(self => {
  const getContentQuality = flow(function* (property: string, page: string) {
    self.loading = true;
    self.property = property;
    self.page = page;
    try {
      const data = yield CONTENT_QUALITY_API.getContentQuality(property, page);


      if (data?.rankingKeywords?.length) {
        self.contentQuality.rankingKeywords = cast(data.rankingKeywords);
        self.loading = false;
      }

      if (data.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return getContentQuality(property, page);
      } else {
        self.contentQuality = data;
        self.loading = false;
      }
    } catch (e) {
      return Promise.resolve(e);
    } finally {
      self.loading = false;
    }
  });


  const handleFilterRenderData = (filterKeywordTerm, filters, pageNumber, pageSize, sortField, sortDirection) => {
    self.tableDataLoading = true;
    const {dataFiltered, dataFilteredSize} = filterDataInMemory(toJS(self.contentQuality.rankingKeywords), self.filterKeywordTerm, self.filterKeywordColumns, filters, pageNumber, pageSize, sortField, sortDirection);
    self.tableDataSize = dataFilteredSize;
    self.tableData.length = 0;
    const newArray = toJS(dataFiltered);
    self.tableData = cast([...newArray]);
    self.tableDataLoading = false;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const {column, order} = sorter;
    const {sortFieldName} = column || {};
    self.sortField = sortFieldName;
    self.sortDirection = order;
    handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, order);
  };

  const handlelKeywordTermChange = searchTerm => {
    if (self.filterKeywordTerm !== searchTerm) {
      self.filterKeywordTerm = searchTerm;
      self.pageNumber = 1;
      handleFilterRenderData(searchTerm, self.filterList, 1, self.pageSize, self.sortField, self.sortDirection);
    }
  };


  const handlePaginationChange = async (pageNumber: number, pageSize: number) => {
    self.pageNumber = pageNumber;
    self.pageSize = pageSize;
    handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
  };

  const handleOnFilterChange = filters => {
    self.pageNumber = 1;
    self.filterList = cast(filters);
    handleFilterRenderData(self.filterKeywordTerm, filters, 1, self.pageSize, self.sortField, self.sortDirection);
  };

  const handleOnKeywordToggle = flow(function* (checked: boolean, keyword: string, id: number | null) {
    try {
      yield CONTENT_QUALITY_API.updateKeywords(self.property, self.page, keyword, id);
      const data = yield CONTENT_QUALITY_API.getContentQuality(self.property, self.page);

      if (data?.rankingKeywords?.length) {
        self.contentQuality.rankingKeywords = cast(data.rankingKeywords);
        // handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
      }

      if (!data.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return getContentQuality(self.property, self.page);
      } else {
        self.contentQuality = data;
        // handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
        self.loading = false;
      }
    } catch (e) {
      return Promise.resolve(e);
    } finally {
      handleFilterRenderData(self.filterKeywordTerm, self.filterList, self.pageNumber, self.pageSize, self.sortField, self.sortDirection);
    }
  });

  return {
    getContentQuality,
    handleFilterRenderData,
    handlePaginationChange,
    handlelKeywordTermChange,
    handleTableChange,
    handleOnFilterChange,
    handleOnKeywordToggle,
  };
});

export const initContentQualityStore = () => {
  return ContentQualityStore.create({
    contentQuality: {
      id: null,
      url: null,
      score: null,
      wordCount: null,
      suggestedWordCountLimits: null,
      readability: null,
      focustermsTotalCount: null,
      focustermsUsedCount: null,
      questionsTotalCount: null,
      questionsUsedCount: null,
      topCompetitor: null,
      rankingKeywords: [],
      shouldRepoll: false,
    },
    loading: true,
    tableDataLoading: true,
    tableDataSize: 0,
    filterKeywordTerm: '',
    tableData: [],
    filterKeywordColumns: ['keyword'],
    pageNumber: 1,
    pageSize: 5,
    filterList: [],
    sortField: '',
    sortDirection: 'descend',
    property: null,
    page: null,
  });
};

