import React from 'react';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Button, Modal} from '@/components/common-components/components';

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onDelete: any;
  setDeleteItem?: any;
  deleteItem?: any;
}

export const DeleteConfirmationModal = observer(({openModal, setOpenModal, onDelete, setDeleteItem, deleteItem}: Props) => {
  return (
    <Wrapper>
      <Modal
        onClose={() => {
          setOpenModal(false);
          if (typeof setDeleteItem == 'function') {
            setDeleteItem(-1);
          }
        }}
        visible={openModal}
        className={'customConfirmationModal'}
      >
        <ModalWrapper>
          <p>Are you sure you want to delete this record? </p>
          <p className={'warningText'}>This action cannot be undone.</p>

          <DeleteButton
            buttonType='transparent' size='lg'
            loading={typeof setDeleteItem == 'function' ? deleteItem !== -1 : false}
            onClick={onDelete}>
          Delete
          </DeleteButton>
          <BackButton onClick={() => {
            setOpenModal(false);
            if (typeof setDeleteItem == 'function') {
              setDeleteItem(-1);
            }
          }} buttonType='transparent' size='lg'>
          Go Back
          </BackButton>

        </ModalWrapper>
      </Modal>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  .customConfirmationModal {
  position: relative;

  .rc-dialog-content {
   .rc-dialog-close {
      color: #fff;
      opacity: 0.8 !important;
      font-size: 40px;
      right: -50px;
      top: -14px;
    }
  }
}
`;

const ModalWrapper = styled.div`
  align-items: center;
  color: $color-gray-dark;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 17px;
  padding: 20px 0;

  .warningText {
    color: $color-secondary-text;
  }

`;

const DeleteButton = styled(Button)`
  background-color: #ff6262;
  border: 1px solid #ff6262;
  border-radius: 5px;
  color: #fff;
  font-weight: 400;
  width: 25%;
    &:hover{
      background: transparent;
      color: #323134; 
    }
`;

const BackButton = styled(Button)`
  background-color: #fff;
  border-color: #696777;
  margin-top: 8px;
  width: 25%;
  color: #696777;
`;
