import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faCircleCheck, faCircleExclamation, faTriangleExclamation} from '@fortawesome/pro-duotone-svg-icons';
import {Tooltip} from 'antd';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import FreezeWrapper from '../../../freezTooltip';
import {PopupContainer} from '../styles';
// import {SuggestionButton} from '../styles';

const PopupField = observer(({field, openModal, value}) => {
  const [valueArray, setValueArray] = useState([]);
  const [suggestion, setSuggestion] = useState<any>();
  const [currentValue, setCurrentValue] = useState<any>();

  // const [iconDetails, setIconDetails] = useState({
  //   color: '#F44343',
  //   icon: faCircleExclamation,
  // });

  useEffect(() => {
    if (value?.length) {
      setValueArray(value.filter(item => item.fieldName == field.fieldName));
    }
  }, [value]);

  useEffect(() => {
    valueArray?.length && setValues();
  }, [valueArray]);

  const setValues = async () => {
    setSuggestion(valueArray?.find(item => item.source == 'OTTO_AI' && item.eventType !== 'LOCK_FIXED'));
    const OTTOObj = valueArray?.find(item => item.source == 'OTTO');
    let value;
    if (OTTOObj) {
      value = OTTOObj;
    } else {
      value = valueArray?.find(item => item.source == 'GBP' && item.eventType !== 'LOCK_INCIDENT');
    }

    setCurrentValue(value);
  };

  // useEffect(() => {
  //   if (currentValue?.value?.length) {
  //     if (!suggestion || suggestion?.value?.length == 0 || compareSuggestions()) {
  //       setIconDetails({
  //         color: '#2AC155',
  //         icon: faCircleCheck,
  //       });
  //     } else {
  //       setIconDetails({
  //         color: '#FF8536',
  //         icon: faTriangleExclamation,
  //       });
  //     }
  //   } else {
  //     setIconDetails({
  //       color: '#F44343',
  //       icon: faCircleExclamation,
  //     });
  //   }
  // }, [currentValue?.value]);

  const extractValue = item => {
    if (item) {
      if (typeof(item) == 'string') {
        return item;
      } else {
        if (Object.keys(item)?.includes('label')) {
          return item.label;
        } else if (Object.keys(item)?.includes('displayName')) {
          return item.displayName;
        } else if (Object.keys(item)?.includes('serviceTypeId')) {
          return item.serviceTypeId?.label;
        } else if (Object.keys(item)?.includes('value')) {
          return item.value;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  // const compareSuggestions = () => {
  //   switch (field.fieldName) {
  //     case 'categories':
  //       return suggestion?.value?.every(item => currentValue?.value.some(cat => cat.key === item.key)) ?? false;
  //     case 'services':
  //       return !suggestion?.value?.some(item =>
  //         !currentValue?.value?.some(val => {
  //           const displayNameMatch = item.displayName && item.displayName === val.displayName;
  //           const serviceTypeIdMatch = item.serviceTypeId && val.serviceTypeId && item.serviceTypeId.label === val.serviceTypeId.label;
  //           return displayNameMatch || serviceTypeIdMatch;
  //         })) ?? false;
  //     default:
  //       return suggestion?.value?.some(val => currentValue?.value.includes(val));
  //   }
  // };

  // const tooltipSuggestion = (
  //   <div>
  //     <div>You have a possible Suggestion</div>
  //     <div style={{marginTop: 5}}>
  //       <FreezeWrapper>
  //         <SuggestionButton onClick={() => openModal(suggestion, currentValue?.value)}>View OTTO Suggestion</SuggestionButton>
  //       </FreezeWrapper>
  //     </div>
  //   </div>
  // );
  return (
    <>
      {/* <Tooltip title={suggestion?.value?.length && !compareSuggestions() ? tooltipSuggestion : 'No possible Suggestions'}
        overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: (suggestion && (suggestion !== currentValue?.value) && !compareSuggestions()) && 391}}> */}
      {/* <FontAwesomeIcon
        style={{marginRight: 6}}
        color={iconDetails.color}
        icon={iconDetails.icon}
      /> */}
      {/* </Tooltip> */}
      <PopupContainer style={{color: !currentValue?.value?.length && '#2D6CCA', width: 'calc(100% - 60px)', cursor: !currentValue?.value?.length && 'pointer'}}>
        <div onClick={() => !currentValue?.value?.length && openModal(suggestion, currentValue?.value)}>
          {currentValue?.value?.length ? (field.fieldName == 'categories' || field.fieldName == 'services') ? currentValue?.value?.map(item => extractValue(item))?.join(', ') :
            currentValue?.value?.map((item, idx) => (
              <div key={field.fieldName + idx}>{extractValue(item)}</div>
            )): `Add ${field.label}`}
        </div>
      </PopupContainer>
      <div className='edit-icon'>
        <FreezeWrapper>
          <Tooltip title='Edit'>
            <div onClick={() => openModal(suggestion, currentValue?.value)}>
              <FontAwesomeIcon
                style={{marginRight: 6}}
                icon={faPen}
                color='#2D6CCA'
              />
          Edit
            </div>
          </Tooltip>
        </FreezeWrapper>
      </div>
    </>
  );
});

export default PopupField;
