import {Dispatch, SetStateAction} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {
  CommonInput,
} from '@/components/common-components/components';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faGripDotsVertical} from '@fortawesome/pro-solid-svg-icons';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {Popover} from 'antd';
import {SuggestionsPopup} from './SuggestionsPopup/SuggestionsPopup';
import styles from '../style.module.scss';

interface Props {
  removeTopic: Dispatch<SetStateAction <number>>;
  index: number;
  currentItemState: {
    value: string;
    label: string;
    placeholder: string;
    fields: [
      {
        type: string;
        label: string;
        placeholder: string;
        value: string;
      }
    ];
  };
  addField: (type: 'question' | 'term', index: number, value?: string) => void;
  removeExistingField: (itemIndex: number, fieldIndex: number) => void;
  updateItemValue: (e, itemIndex: number, fieldIndex: number) => void;
  updateItemTopic: (e, itemIndex: number) => void;
  onSingleFieldDragEnd: (index: number, newState) => void;
  totalItems: number;
  totalTerms: number;
  totalQuestions: number;
  showError: boolean;
  usedTerms: string[];
  usedQuestions: string[];
  isStandaloneTool: boolean;
}

export const TopicSection = ({
  removeTopic,
  index,
  currentItemState,
  addField,
  removeExistingField,
  updateItemValue,
  updateItemTopic,
  onSingleFieldDragEnd,
  totalItems,
  showError,
  usedTerms,
  usedQuestions,
  isStandaloneTool,
}: Props) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };


  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newState = reorder(
      currentItemState.fields,
      result.source.index,
      result.destination.index,
    );

    onSingleFieldDragEnd(index, newState);
  };


  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: '5px 10px',
    margin: `0 0 8px 0`,
    background: '#fff',

    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = () => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 10,
    width: '100%',
  });

  return <Wrapper>
    <DragDropContext onDragEnd={onDragEnd}>
      <TopSectionWrapper>
        <div><FontAwesomeIcon icon={faGripDotsVertical} style={{marginRight: 10}}/></div>
        <div style={{width: '90%'}}>
          <div style={{fontSize: 12}}>Focus Topic (H2)</div>
          <CommonInput
            type='text'
            variant='transparent'
            placeholder={currentItemState.placeholder}
            value={currentItemState.value}
            onChange={e => updateItemTopic(e, index)}
            className={'customLargeInput'} />
          {(showError && currentItemState.value === '' && currentItemState.fields?.filter(field => field.value)?.length > 0) && <ErrorMsg>This field is required.</ErrorMsg>}
        </div>
        {/* Show remove icon only if there is more than 1 topic */}
        {totalItems > 1 && <div className='trash-icon' onClick={() => removeTopic(index)}><FontAwesomeIcon icon={faTrash}/></div>}
      </TopSectionWrapper>
      <Droppable droppableId='droppable'>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}>
            {currentItemState.fields.map((item, idx) => (
              <Draggable key={idx} draggableId={`${item.type}-${idx}`} index={idx}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}>
                    <SingleFieldWrapper>
                      <div><FontAwesomeIcon icon={faGripDotsVertical}/></div>
                      <div style={{width: '90%'}}>
                        <div style={{marginLeft: 10, fontSize: 12}}>{item.label}</div>
                        <CommonInput
                          type='text'
                          variant='transparent'
                          placeholder={item.placeholder}
                          value={item.value}
                          onChange={e => updateItemValue(e, index, idx)}
                          className={'customSmallInput'} />
                        {/* {(showError && item.value === '') && <ErrorMsg>This field can not be empty</ErrorMsg>} */}

                      </div>
                      {/* Show remove button only if there are at least 2 fields of same type */}
                      {/* {(totalTerms > 1 && item.type == 'term') && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>}
                          {(totalQuestions > 1 && item.type == 'question') && <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>} */}

                      <div className='trash-icon' onClick={() => removeExistingField(index, idx)}><FontAwesomeIcon icon={faTrash}/></div>
                    </SingleFieldWrapper>

                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <AddMoreItemsContainer>
        <AddMoreTermsButton onClick={() => addField('question', index)}>+ Question</AddMoreTermsButton>
        <AddMoreTermsButton onClick={() => addField('term', index)}>+ Topical Term</AddMoreTermsButton>
        {!isStandaloneTool ? <Popover
          autoAdjustOverflow={true}
          placement='topLeft'
          content={<SuggestionsPopup index={index} addItem={addField} usedTerms={usedTerms} usedQuestions={usedQuestions}/>}
          trigger='click'
          overlayClassName={styles.suggestionsPopover}>
          <AddMoreTermsButton style={{marginLeft: 'auto', textDecoration: 'underline'}}>Get Suggestions <FontAwesomeIcon icon={faCaretDown}/></AddMoreTermsButton>
        </Popover> : null}
      </AddMoreItemsContainer>
    </DragDropContext>

  </Wrapper>;
};

const Wrapper = styled.div`
  padding: 13px 16px;
  background-color: #FAFAFA;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
`;
const TopSectionWrapper = styled.div`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 22px !important;
    padding-left: 0px !important;
    color: #121212 !important;
    font-size: 16px !important;
    font-weight: 600;

    &::placeholder {
      font-size: 18px !important;
      font-weight: 700 !important;
    }
  }

  .trash-icon {
    display: none;

    &:hover {
      color: #ff6262;
    }
  }

  &:hover {
    .trash-icon {
      display: block;
    }
  }
`;
const AddMoreItemsContainer = styled.div`
  display: flex;
`;
const AddMoreTermsButton = styled.div`
  color:  #2D6CCA;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
`;
const SingleFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 5px 10px;

  .trash-icon {
    display: none;

    &:hover {
      color: #ff6262;
    }
  }

  &:hover {
    .trash-icon {
      display: block;
    }
  }

  .customSmallInput {
    height: 20px !important;
    color: #121212 !important;
  }
`;

const ErrorMsg = styled.div`
  font-size: 10px;
  color: #ff6262;
`;
