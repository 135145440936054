import {Tabs, TabPane} from '@/components/common-components/components';
import {observer} from 'mobx-react';
import {useState} from 'react';
import styles from './style.module.scss';
import styled from 'styled-components';
import {TemplatesResults} from './TemplatesResults/templatesResults';
import {ContentIdearesults} from './ContentIdeasResults/contentIdeaResults';
import {FirstDraftResults} from './FirstDraftResults/firstDraftResults';
import {useStore} from '@/store/root-store';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {EmptyStateComponent} from './emptyStateComponent';
import {toJS} from 'mobx';
import {TemplateOutlinesHistory} from './TemplateOutlinesHistory';
import {TemplatesImageResults} from './TemplatesImagersults/templatesImageResults';
import {TemplateImagesOutlinesHistory} from './TemplateImagesOutlinesHistory';
import {ContentIdeasHistory} from './ContentIdeasHistory/contentIdeasHistory';
import {FirstDraftHistoryResults} from './FirstDraftHistory/firstDraftHistoryResults';
import {isContentGeneratorPublic} from '@/utils/url';

interface Props {
  htmlToDraft: any;
}

/**
 * Shows tabs with results and historical resutls
 *
 * @param {any} htmlToDraft library used for injecting any of the results into the editor
 */
export const Results = observer(({htmlToDraft}: Props) => {
  const {
    contentOptimizer: {
      currentPage,
      aiOutline: {
        loading,
        historyLoading,
        templatesHistory,
        aiOutlineHistory,
        aiTemplatesController,
        loadAiTemplateOutlinesHistory,
        v2AiOutline,
        aiOutline,
        tempalteOutlines,
        loadAiOutlineHistory,
        firstDraftHistoryOutlines,
        loadFirstDraftHistory,
        loadFirstDraftHistoryStandalone,
        loadAiOutlineHistoryStandalone,
      },
    },
  } = useStore('');
  const [activeKey, setActiveKey] = useState('1');


  const antIcon = <LoadingOutlined style={{fontSize: 16, color: '#7f4ead'}} spin />;


  const onChangeTab = key => {
    setActiveKey(key);

    // only call history again when the tab is opened in case there are no results in tab
    if (!isContentGeneratorPublic() && key == '2') {
      if (aiTemplatesController?.defaultForm) {
        if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
          if (!aiTemplatesController?.isStandaloneTool) loadAiOutlineHistory(currentPage?.content?.uuid);
          if (aiTemplatesController?.isStandaloneTool) loadAiOutlineHistoryStandalone();
        } else {
          if (!aiTemplatesController?.isStandaloneTool) loadFirstDraftHistory(currentPage?.content?.uuid);
          if (aiTemplatesController?.isStandaloneTool) loadFirstDraftHistoryStandalone();
        }
      }

      if (!aiTemplatesController?.defaultForm) {
        loadAiTemplateOutlinesHistory(aiTemplatesController?.templateType);
      }
    }
  };

  // property 'lastGeneratedOutput' is set in mobx methods that generate content
  // there are 3 methods for 3 types of results,so we print diferent results page
  const renderResultsType = () => {
    let componentToUse = <></>;
    if (aiTemplatesController?.showForm) {
      if (aiTemplatesController?.defaultForm) {
        if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
          componentToUse = aiOutline?.length ? <ContentIdearesults htmlToDraft={toJS(htmlToDraft)}/> : <EmptyStateComponent loading={loading}/>;
        } else {
          componentToUse = v2AiOutline?.length ? <FirstDraftResults outlines={toJS(v2AiOutline)} /> : <EmptyStateComponent loading={loading}/>;
        }
      } else {
        if (aiTemplatesController?.templateType == 'ai_image_generation') {
          componentToUse = tempalteOutlines?.length ? <TemplatesImageResults outlines={toJS(tempalteOutlines)?.map(template => template.result)}/> : <EmptyStateComponent loading={loading}/>;
        } else {
          componentToUse = tempalteOutlines?.length ? <TemplatesResults outlines={toJS(tempalteOutlines)?.map(template => template.result)}/> : <EmptyStateComponent loading={loading}/>;
        }
      }
    }

    return <ResultsWrapper>
      {componentToUse}
    </ResultsWrapper>;
  };

  const getNumberOfResults = () => {
    let output = null;

    if (aiTemplatesController?.defaultForm) {
      if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
        output = aiOutline?.length ? aiOutline?.length : null;
      } else {
        output = v2AiOutline?.length ? v2AiOutline?.length : null;
      }
    } else {
      output = tempalteOutlines?.length ? tempalteOutlines?.length : null;
    }

    return output ? <NumResultsWrapper>{output}</NumResultsWrapper> : <></>;
  };
  const renderHistoryHandler = () => {
    let output = null;

    if (aiTemplatesController?.defaultForm) {
      if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
        output = <ContentIdeasHistory htmlToDraft={htmlToDraft}/>;
      } else {
        output = <FirstDraftHistoryResults outlines={toJS(firstDraftHistoryOutlines)} />;
      }
    }

    if (!aiTemplatesController?.defaultForm) {
      if (aiTemplatesController?.templateType == 'ai_image_generation') {
        output = <TemplateImagesOutlinesHistory outlines={toJS(templatesHistory)}/>;
      } else {
        output = <TemplateOutlinesHistory outlines={toJS(templatesHistory)}/>;
      }
    }

    return output;
  };
  const getNumberOfHistResults = () => {
    let output = null;

    if (aiTemplatesController?.defaultForm) {
      if (aiTemplatesController?.defaultFormType == 'ContentIdea') {
        output = aiOutlineHistory?.length ? aiOutlineHistory?.length : null;
      } else {
        output = firstDraftHistoryOutlines?.length ? firstDraftHistoryOutlines?.length : null;
      }
    }

    if (!aiTemplatesController?.defaultForm) {
      output = templatesHistory?.length ? templatesHistory?.length : null;
    }

    return output ? <NumResultsWrapper>{output}</NumResultsWrapper> : <></>;
  };

  return <Wrapper>
    <Tabs className={styles.tabsContainer}
      style={{marginTop: '-35px'}}
      tabBarGutter={30}
      activeKey={activeKey}
      tabBarStyle={{'padding': '0 !important'}}
      defaultActiveKey={'2'}
      onChange={key => onChangeTab(key)}>
      <TabPane tab={<div style={{display: 'flex'}}><span style={{marginRight: 5}}>New output</span> {loading ? <Spin indicator={antIcon} /> : getNumberOfResults()}</div>} key='1' style={{'outline': 'none'}}>
        {renderResultsType()}
      </TabPane>
      <TabPane tab={<div style={{display: 'flex'}}><span style={{marginRight: 5}}>History</span> {!isContentGeneratorPublic() && historyLoading ? <Spin indicator={antIcon} /> : getNumberOfHistResults()}</div>} key='2' style={{'outline': 'none'}}>
        {!isContentGeneratorPublic() && renderHistoryHandler()}
      </TabPane>
    </Tabs>
  </Wrapper>;
});

const Wrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 1px -2px 20px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 20px 15px;
  height: 100%;
  overflow-y: auto;
  width: 575px;
`;

const ResultsWrapper = styled.div`

`;
const NumResultsWrapper = styled.div`
  background: #7F4EAD;
  border-radius: 34px;
  color: #fff;
  padding: 0 10px;
  font-size: 12px;
`;
