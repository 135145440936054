import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {opaqueColor} from '@/utils/colors';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {isContentGeneratorPublic} from '@/utils/url';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {useTranslation} from 'next-i18next';
import {TemplatesSection} from './templatesSection/templatesSection';
import styles from '../style.module.scss';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {NextImg} from '@/utils/nextImg';

export const ToolsSection = observer(() => {
  const {settings: {customer: {setShowScaTopUpModal, profile: {quotaUtilization}}}} = useStore(null);
  const {contentOptimizer: {
    aiOutline: {
      isOpenAiWriterDrawer,
      setIsOpenAiWriterDrawer,
      aiTemplatesController,
      setAiWriter,
    },
  },
  } = useStore('');

  const {t} = useTranslation('common');

  const toolsBannersConfig = [
    // {
    //   iconPath: 'pen-icon-color.svg',
    //   title: '1-Click Draft',
    //   desc: 'Generate a full draft of an article from a keyword or topic. Ideal for when you need content fast.',
    //   backgroundColor: opaqueColor('#7F4EAD', 10),
    //   borderColor: opaqueColor('#7F4EAD', 30),
    //   bannerIcon: '1-click-banner-icon.svg',
    //   callBack: () => setAiWriter({
    //     showForm: true,
    //     templateUsed: '1-click-draft',
    //     templateIcon: 'pen-icon-color.svg',
    //     templateName: '1-Click Draft',
    //     templateDesc: 'Generate a full draft of an article from a keyword or topic. Ideal for when you need content fast.',
    //     isStandaloneTool: false,
    //   }),
    // },
    // {
    //   iconPath: 'hand-write-icon-color.svg',
    //   title: 'Complete SEO Article',
    //   desc: 'Generate an optimized draft from a headings outline. Customize the article based on your ranking goals.',
    //   backgroundColor: opaqueColor('#FF8536', 10),
    //   borderColor: opaqueColor('#FF8536', 30),
    //   bannerIcon: 'complete-seo-article-banner-image.svg',
    //   callBack: () => {
    //     setAiWriter({
    //       showForm: true,
    //       templateUsed: 'complete-seo-article',
    //       templateIcon: 'hand-write-icon-color.svg',
    //       templateName: 'Complete SEO Article',
    //       templateDesc: 'Generate an optimized draft from a headings outline. Customize the article based on your ranking goals.',
    //       isStandaloneTool: false,
    //     });
    //   },
    // },
    {
      iconPath: 'hand-write-icon-color.svg',
      title: 'Complete SEO Article',
      desc: 'Generate a full article from just a keyword (topic) or customize the article based on your ranking goals.',
      backgroundColor: opaqueColor('#7F4EAD', 10),
      borderColor: opaqueColor('#7F4EAD', 30),
      bannerIcon: '1-click-banner-icon.svg',
      disabled: false,
      callBack: function() {
        setAiWriter({
          showForm: true,
          templateUsed: 'new-ai-writer',
          templateIcon: 'hand-write-icon-color.svg',
          templateName: 'Complete SEO Article',
          templateDesc: 'Generate a full article from just a keyword (topic) or customize the article based on your ranking goals.',
          isStandaloneTool: false,
        });
      },
    },
  ];

  const onDrawerCloseIconClick = () => {
    setIsOpenAiWriterDrawer(false);
  };


  return <ContentContainer>
    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 30}}>
      <div>
        <div className={styles.contentIdeasTitle}>
        What are you working on?
        </div>
        {!isContentGeneratorPublic() ? <QuotaInfoDetail consumedData={quotaUtilization?.ca?.allowedAiContentGeneration?.consumed}
          totalData= {quotaUtilization?.ca?.allowedAiContentGeneration?.total} heading={t('ai-quota')}
          description={t('ai-outlines-quota-desc')}
          ctaLabel={t('top-up-quota')}
          showCta={quotaUtilization?.ca?.allowedAiContentGeneration?.consumed >= quotaUtilization?.ca?.allowedAiContentGeneration?.total}
          isCenter={true}
          customCta={() => {
            setShowScaTopUpModal(true);
          }}
        /> : <div style={{color: '#435156'}}>Quota: 3 uses refreshed every 3 days</div>}
      </div>

      {!aiTemplatesController?.isStandaloneTool ? <CloseIconContainer onClick={() => onDrawerCloseIconClick()}>
        <FontAwesomeIcon icon={faXmark}
          fontSize={24}
          style={
            {
              display: isOpenAiWriterDrawer ? 'block' : 'none',
              color: '#a3a4a4',
            }}/>
      </CloseIconContainer> : null}
    </div>


    {toolsBannersConfig?.map((item, idx) => {
      return !item.disabled ? <BannerWrapper
        key={idx}
        onClick={item.disabled ? () => {} : item.callBack}
        backgroundColor={item.backgroundColor}
        borderColor={item.borderColor}>
        <NextImg src={`/img/icon/${item.bannerIcon}`} style={{position: 'absolute', right: 0, top: 0}}/>
        <div className='left'>
          <NextImg src={`/img/icon/${item.iconPath}`} style={{marginRight: 10}}/>
        </div>
        <div className='right'>
          <div className='title'>
            {item.title}
            <FontAwesomeIcon icon={faArrowRight} className='leftArrow'/>
          </div>
          <div className='desc'>{item.desc}</div>
        </div>
      </BannerWrapper> : <></>;
    })}
    <TemplatesSection />
  </ContentContainer>;
});

const BannerWrapper = styled.div<{backgroundColor: string; borderColor: string}>`
  background-color: ${p => p.backgroundColor};
  border: 1px solid ${p => p.borderColor};
  border-radius: 15px;
  padding: 23px 19px;
  width: 100%;
  position: relative;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  transition: box-shadow .2s ease;

  .leftArrow {
    margin-left: 10px;
    display: none;
  }


  .right {
    max-width: 80%;
    .title {
      color: #121212;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .desc {
      font-size: 12px;
      color: #4E5156;
    }
  }

  &:hover {
    box-shadow: 0px 5px 12px rgba(0,0,0,0.15);

    .leftArrow {
      display: block;
    }
  

    .right {
      .title {
        text-decoration: underline;
      }
    }
  }

`;
const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const CloseIconContainer = styled.div`
  width: 30px;
  height: auto;
  cursor: pointer;
  margin-left: 20px;
`;
