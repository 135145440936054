import React from 'react';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {observer} from 'mobx-react-lite';
import styles from '../styles.module.scss';
import {useStore} from '@/store/root-store';
import {SourceMediumPagination} from './gaMatrix';

interface Props {
  data?: any;
  loading?: boolean;
  selectedRange?: string;
  selectedProject?: string;
  selectedCountry?: string;
  section?: any;
}

export const TopuserBySource:React.FC<Props> = observer(({data, loading, selectedProject, selectedRange, selectedCountry, section}) => {
  const {reportBuilder: {details: {setTopUsersParams, getTopUsersParams, topUsersLoading, isConfig}}} = useStore('');
  const columns =[
    {
      title: <div className={styles.tableText}>USER <br/>SOURCE</div>,
      dataIndex: 'source',
      key: 'source',
      sortFieldName: 'source',
      sorter: false,
      width: 100,
      align: 'left' as 'left',
      render: (_, record) => {
        return <div className={styles.tableText}>{record?.source || '20'}</div>;
      },
    },
    {
      title: <div className={styles.tableText}>USER <br/>MEDIUM</div>,
      dataIndex: 'medium',
      key: 'medium',
      sortFieldName: 'medium',
      sorter: false,
      width: 100,
      render: (_, record) => {
        return <div>{record?.medium || '-'}</div>;
      },
    },
    {
      title: <div className={styles.tableText}>TOTAL <br/>USERS</div>,
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      width: 100,
      sortFieldName: 'totalUsers',
      // sorter: true,
      // sortOrder: activeSort?.includes('totalUsers') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      render: (_, record) => {
        return <div>{record?.totalUsers || '-'}</div>;
      },
    },
    {
      title: <div className={styles.tableText}>ENGAGEMENT<br/>RATE</div>,
      dataIndex: 'engagementRate',
      key: 'engagementRate',
      sortFieldName: 'engagementRate',
      width: 100,
      // sorter: true,
      // sortOrder: activeSort?.includes('engagementRate') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      render: (_, record) => {
        return <div>{record?.engagementRate}</div>;
      },
    },
  ];

  const handleTableChange = async () => {
    setTopUsersParams(selectedProject, selectedCountry, selectedRange, {...getTopUsersParams}, false, section);
  };
  return (
    <div>
      <TableStyled
        style={{minWidth: isConfig ? '445px' : '545px', minHeight: '280px', height: '275px'}}
        columns={columns as any}
        pagination={false}
        dataSource={data?.results?.slice(0, 5)}
        loading={topUsersLoading || loading}
        onChange={handleTableChange}
      />
      {!isConfig && <SourceMediumPagination />}
    </div>
  );
});

