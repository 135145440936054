import {types, Instance} from 'mobx-state-tree';

export const svTopCountriesModel = types.model({
  countryCode: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});

export type SvTopCountries = Instance<typeof svTopCountriesModel>;

export const TargetKeywordsModel = types.model({
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  globalSv: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  kd: types.maybeNull(types. number),
  serpUpdatedAt: types.maybeNull(types.string),
  serpTaskStatus: types.maybeNull(types.string),
  serpUrls: types.maybeNull(types.array(types.string)),
  contentAnalysisProcessedAt: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  page: types.maybeNull(types.number),
  svTopCountries: types.maybeNull(types.array(svTopCountriesModel)),
  wasDeleted: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
});

export const FeTargetKeywordsModel = types.model({
  keyword: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  globalSv: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  kd: types.maybeNull(types. number),
  serpUpdatedAt: types.maybeNull(types.string),
  serpTaskStatus: types.maybeNull(types.string),
  serpUrls: types.maybeNull(types.array(types.string)),
  contentAnalysisProcessedAt: types.maybeNull(types.string),
  contentAnalysisTaskStatus: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  page: types.maybeNull(types.number),
  svTopCountries: types.maybeNull(types.array(svTopCountriesModel)),
  wasDeleted: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
});

export type TargetKeywordsType = Instance<typeof TargetKeywordsModel>;
export type FeTargetKeywordsType = Instance<typeof FeTargetKeywordsModel>;
