import {notification, useErrorNotification} from '@/utils/notification-v2';
import {types, cast, flow} from 'mobx-state-tree';
import {toJS} from 'mobx';
import {KEYWORD_RESEARCH_API_V2} from '@/api/KeywordResearcherV2';
import {getSingleUrlParam} from '@/utils/url';
import html2canvas from 'html2canvas';
import {apiError, genericError} from '@/utils/api';

const trendDataModel = types.model({
  date: types.maybeNull(types.string),
  volume: types.maybeNull(types.number),
});
const rankingDifficultyModel = types.model({
  difficulty: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

const totaladsModel = types.model({
  body: types.maybeNull(types.string),
  dateSearched: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalAds: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});
const metricsModel = types.model({
  ads: types.maybeNull(types.array(totaladsModel)),
  cmp: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  cpcTopofpageMax: types.maybeNull(types.number),
  cpcTopofpageMin: types.maybeNull(types.number),
  histSv: types.maybeNull(types.array(types.number)),
  isAccurate: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  replaceToDt: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
  trendData: types.maybeNull(types.array(trendDataModel)),
  mobileSearches: types.maybeNull(types.number),
  desktopSearches: types.maybeNull(types.number),
  rankingDifficulty: types.maybeNull(rankingDifficultyModel),
  adsCount: types.maybeNull(types.number),
  advetisers: types.maybeNull(types.number),
  paidClicksPercentage: types.maybeNull(types.number),
  topicalSearchVolume: types.maybeNull(types.number),
  trafficPotential: types.maybeNull(types.number),
});

const taskStatusModel = types.model({
  enhancedMetricsTaskStatus: types.maybeNull(types.string),
  kdTaskStatus: types.maybeNull(types.string),
  similarideasTaskStatus: types.maybeNull(types.string),
  svTaskStatus: types.maybeNull(types.string),
});

const keywordMainDetailsModel = types.model({
  countryCode: types.maybeNull(types.string),
  // enhancedKeywordMetrics: {}
  keyword: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  metrics: types.maybeNull(metricsModel),
  publicShareHash: types.maybeNull(types.string),
  relatedKeywordsFirstArr: types.maybeNull(types.array(metricsModel)),
  relatedKeywordsSecondArr: types.maybeNull(types.array(types.maybeNull(types.string))),
  // relatedKeywords: types.maybeNull(types.union(types.array(metricsModel), types.array(types.string))),
  taskStatus: types.maybeNull(taskStatusModel),
  locationFlag: types.maybeNull(types.string),
  searchIntent: types.maybeNull(types.string),
  searchIntentList: types.maybeNull(types.array(types.string)),
  lastUpdatedAt: types.maybeNull(types.string),
});

const serpOverviewDetailsListModel = types.model({
  blockPosition: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  columnName: types.maybeNull(types.string),
  data: types.maybeNull(types.frozen({})),
  type: types.maybeNull(types.string),
});

const topResultsModel = types.model({
  displayedLink: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  ot: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
});

const serpOverviewDetailsModel = types.model({
  actualizedOn: types.maybeNull(types.string),
  results: types.maybeNull(types.array(serpOverviewDetailsListModel)),
  serpFeatures: types.maybeNull(types.array(types.maybeNull(types.string))),
  topResult: types.maybeNull(topResultsModel),
  taskStatus: types.maybeNull(types.string),
});

const svTopCountriesModel = types.model({
  countryCode: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});

const serpAnalysisModel = types.model({
  difficulty: types.maybeNull(types.number),
  diversity: types.maybeNull(types.number),
  facebook: types.maybeNull(types.boolean),
  govEduDomains: types.maybeNull(types.number),
  homepages: types.maybeNull(types.number),
  keywordsInTitle: types.maybeNull(types.number),
  keywordsInUrl: types.maybeNull(types.number),
  linkedIn: types.maybeNull(types.boolean),
  monthlyClicks: types.maybeNull(types.number),
  monthlyCost: types.maybeNull(types.number),
  pinterest: types.maybeNull(types.boolean),
  tumblr: types.maybeNull(types.boolean),
  twitter: types.maybeNull(types.boolean),
  wikipedia: types.maybeNull(types.boolean),
  youtube: types.maybeNull(types.boolean),
});

const rankingsModel = types.model({
  rank: types.maybeNull(types.number),
  site: types.maybeNull(types.string),
});

const graphListModel = types.model({
  date: types.maybeNull(types.string),
  rankings: types.maybeNull(types.array(rankingsModel)),
});

const organicResultsModel = types.model({
  domainAppearances: types.maybeNull(types.number),
  domainName: types.maybeNull(types.string),
  domainStrength: types.maybeNull(types.number),
  estimatedOrganicMonthlyClicks: types.maybeNull(types.number),
  fullUrl: types.maybeNull(types.string),
  isAdvertiser: types.maybeNull(types.boolean),
  null: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  term: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  urlId: types.maybeNull(types.number),
  urlPositionChange: types.maybeNull(types.union(types.string, types.number)),
});
const organicRankingsModel = types.model({
  graph: types.maybeNull(types.array(graphListModel)),
  results: types.maybeNull(types.array(organicResultsModel)),
  serpAnalysis: types.maybeNull(serpAnalysisModel),
});

const adsObjectModel = types.model({
  body: types.maybeNull(types.string),
  extendedBody: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  searchDate: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const adHistoryModel = types.model({
  adCount: types.maybeNull(types.number),
  ads: types.maybeNull(types.array(adsObjectModel)),
  budget: types.maybeNull(types.number),
  coverage: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  topOfPage: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
});

const adsBuyRecommendationModel = types.model({
  cpc: types.maybeNull(types.number),
  cpm: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  matchingCompetitors: types.maybeNull(types.array(types.maybeNull(types.string))),
  searchVolume: types.maybeNull(types.number),
  weight: types.maybeNull(types.number),
  weightLabel: types.maybeNull(types.string),
});

const paidSerpByTermModel = types.model({
  body: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  dateSearched: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  totalAds: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const paidRankingsModel = types.model({
  adHistory: types.maybeNull(types.array(adHistoryModel)),
  adsBuyRecommendations: types.maybeNull(types.array(adsBuyRecommendationModel)),
  paidSerpsByTerm: types.maybeNull(types.array(paidSerpByTermModel)),
});

const organicPaidHistoryModel = types.model({
  organicRankings: types.maybeNull(organicRankingsModel),
  paidRankings: types.maybeNull(paidRankingsModel),
});

const singleKeywordModel = types.model({
  cpc: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  ppcd: types.maybeNull(types.number),
  public_share_hash: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
});

const keywordDetailsListModel = types.model({
  id: types.maybeNull(types.number),
  keywords: types.maybeNull(types.array(singleKeywordModel)),
  publicShareHash: types.maybeNull(types.string),
  user: types.maybeNull(types.number),
});

const termsMatchListModel = types.model({
  cmp: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  cpcTopofpageMax: types.maybeNull(types.number),
  cpcTopofpageMin: types.maybeNull(types.number),
  dt: types.maybeNull(types.string),
  histSv: types.maybeNull(types.array(types.maybeNull(types.number))),
  isAccurate: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  // locationId: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  kd: types.maybeNull(types.number),
  ppcd: types.maybeNull(types.number),
  serpsCount: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
  searchIntentLoading: types.maybeNull(types.boolean),
  lastUpdatedAt: types.maybeNull(types.string),
  searchIntentList: types.maybeNull(types.array(types.string)),
});

const termsMatchListModelV2 = types.model({
  cmp: types.maybeNull(types.number),
  countryCode: types.maybeNull(types.string),
  cpc: types.maybeNull(types.number),
  cpcTopofpageMax: types.maybeNull(types.number),
  cpcTopofpageMin: types.maybeNull(types.number),
  difficulty: types.maybeNull(types.number),
  histSv: types.maybeNull(types.array(types.maybeNull(types.number))),
  keyword: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
  sv: types.maybeNull(types.number),
  ppcd: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.array(types.maybeNull(types.string))),
  searchIntentLoading: types.maybeNull(types.boolean),
  processingStatus: types.maybeNull(types.string),
});

const termsMatchDataModel = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(termsMatchListModel)),
});

const termsMatchDataModelV2 = types.model({
  count: types.maybeNull(types.number),
  results: types.maybeNull(types.array(termsMatchListModelV2)),
  shouldRepoll: types.maybeNull(types.boolean),
});

const tokenAggregationListModel = types.model({
  word: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

const magicTableParamModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const treeListParamsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
});

const createListModal = types.model({
  title: types.maybeNull(types.string),
  buttonName: types.maybeNull(types.string),
  listName: types.maybeNull(types.string),
  selectedCountry: types.maybeNull(types.model({
    locationId: types.maybeNull(types.union(types.string, types.number)),
    countryCode: types.maybeNull(types.string),
    location: types.maybeNull(types.string),
  })),
  newKeyword: types.maybeNull(types.string),
  isRecord: types.maybeNull(types.model({
    id: types.maybeNull(types.union(types.string, types.number)),
  })),
});

const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});

export const KeywordDetails = types.model({
  loadingKeywordDetails: types.maybeNull(types.boolean),
  loadingKeywordMainDetails: types.maybeNull(types.boolean),
  loadingOrganicPaidHistoryDetails: types.maybeNull(types.boolean),
  loadingSerpOverviewDetails: types.maybeNull(types.boolean),
  loadingSvCountriesDetails: types.maybeNull(types.boolean),
  loadingTermsMatchDetails: types.maybeNull(types.boolean),
  loadingTermsMatchQuestionsDetails: types.maybeNull(types.boolean),
  magicTableLoading: types.maybeNull(types.boolean),
  magicTableLoadingV2: types.maybeNull(types.boolean),
  loadingMagicTree: types.maybeNull(types.boolean),
  loadingMagicTreeV2: types.maybeNull(types.boolean),
  loadingMagicTreeUrl: types.boolean,
  loadingMagicTreeUrlV2: types.boolean,
  keywordMainDetails: types.maybeNull(keywordMainDetailsModel),
  serpOverviewDetails: types.maybeNull(serpOverviewDetailsModel),
  svTopCountries: types.maybeNull(types.array(svTopCountriesModel)),
  organicPaidHistoryData: types.maybeNull(organicPaidHistoryModel),
  keywordDetailsList: types.maybeNull(keywordDetailsListModel),
  keSelectedKeyword: types.maybeNull(types.string),
  keSelectedId: types.maybeNull(types.string),
  termsMatchData: types.maybeNull(termsMatchDataModel),
  termsMatchDataV2: types.maybeNull(termsMatchDataModelV2),
  loadingRefetchData: types.maybeNull(types.boolean),
  termsMatchQuestionsData: types.maybeNull(termsMatchDataModel),
  termsMatchQuestionsDataV2: types.maybeNull(termsMatchDataModelV2),
  tokenAggregationList: types.maybeNull(types.array(tokenAggregationListModel)),
  magicTableData: types.maybeNull(termsMatchDataModel),
  magicTableDataV2: types.maybeNull(termsMatchDataModelV2),
  magicTableParams: types.maybeNull(magicTableParamModel),
  treeListParams: types.maybeNull(treeListParamsModel),
  keDetailsRepolling: types.maybeNull(types.boolean),
  createList: types.maybeNull(createListModal),
  keywordToShow: types.string,
  updatedKwParam: types.maybeNull(types.string),
  magicTableDataUrl: types.maybeNull(types.string),
  magicTableDataUrlV2: types.maybeNull(types.string),
  isExporting: types.boolean,
  termsMathRepolling: types.boolean,
  callForQuota: types.maybeNull(types.boolean),
  exportingSerpOverview: types.maybeNull(types.boolean),
  fetchingSearchIntent: types.maybeNull(types.boolean),
  kmtTermsSearchFilter: types.array(filterListModel),
}).views(self => ({
  get getSerpOverviewDetails() {
    return toJS(self.serpOverviewDetails);
  },
  get getSvTopCountries() {
    return toJS(self.svTopCountries);
  },
  get getKeywordDetailsData() {
    return toJS(self.keywordMainDetails);
  },
  get getOrganicPaidHistoryData() {
    return toJS(self.organicPaidHistoryData);
  },
  get getKeywordDetailList() {
    return toJS(self.keywordDetailsList);
  },
  get getTermsMatchData() {
    return toJS(self.termsMatchData);
  },
  get getTermsMatchDataV2() {
    return toJS(self.termsMatchDataV2);
  },
  get getTermsMatchQuestionsData() {
    return toJS(self.termsMatchQuestionsData);
  },
  get getTermsMatchQuestionsDataV2() {
    return toJS(self.termsMatchQuestionsDataV2);
  },
  get getTokenAggregationList() {
    return toJS(self.tokenAggregationList);
  },
  get getMagicTableData() {
    return toJS(self.magicTableData);
  },
  get getMagicTableDataV2() {
    return toJS(self.magicTableDataV2);
  },
  get createListData() {
    return toJS(self.createList);
  },
})).actions(self => {
  const formatFilters = filterList => {
    const newList = [];
    if (Array.isArray(filterList) && filterList?.length) {
      filterList?.map(item => {
        if (item?.name == 'exclude') {
          newList?.push(`${item?.filterField},${item?.type},${item?.query}`);
        } else if (item?.name == 'checkboxes') {
          item?.category?.map(c => {
            newList?.push(`${item?.customCategoryType},equals,${c}`);
          });
        } else if (item?.name == 'wordsInclude') {
          newList?.push(`${item?.filterField},${item?.type},${item?.query}`);
        } else if (item?.name == 'wordsExclude') {
          newList?.push(`${item?.filterField},${item?.type},${item?.query}`);
        } else if (item?.name == 'radioButtonsFilter') {
          newList?.push(`${item?.customCategoryType},equals,${item?.type}`);
        } else if (item?.name == 'sortFilters' && item?.value) {
          newList?.push(`${item?.type},${item?.operator},${item?.value}`);
        } else if (item?.name == 'secondSortFilters' && item?.value) {
          newList?.push(`${item?.type},${item?.operator},${item?.value}`);
        } else if (item?.name !== 'sortFilters' || item?.name !== 'secondSortFilters') {
          if (item?.equal) {
            newList?.push(`${item?.name},equals,${item?.equal}`);
          } else {
            if (+item?.from) {
              newList?.push(`${item?.name},gt,${item?.from}`);
            }
            if (item?.to) {
              newList?.push(`${item?.name},lt,${item?.to}`);
            }
          }
        }
      });
    } else if (filterList?.value) {
      if (filterList?.name == 'sortFilters') {
        newList?.push(`${filterList?.type},${filterList?.operator},${filterList?.value}`);
      }
    }
    return newList;
  };

  const loadKeywordMainDetails = flow(function* (listId, keyword, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingKeywordMainDetails = true;
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordMainDetails(listId, keyword, publicHash);
      if (response?.taskStatus?.similarideasTaskStatus == 'PENDING' && self.keDetailsRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        loadKeywordMainDetails(listId, keyword);
      } else {
        self.keywordMainDetails = cast({
          ...response,
          relatedKeywordsFirstArr: response?.relatedKeywords?.length ? response?.relatedKeywords : [],
          // relatedKeywordsSecondArr: response?.relatedKeywords?.length > 0 ? response?.relatedKeywords[1] : [],
        });
        self.loadingKeywordMainDetails = false;
      }
    } catch (err) {
      self.loadingKeywordMainDetails = false;

      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }

      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    }
  });

  const loadOrganicPaidHistoryDetails = flow(function* (listId, keyword) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingOrganicPaidHistoryDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordOrganicPaidHistoryDetails(listId, keyword, publicHash);
      if (response?.taskStatus == 'PENDING' && self.keDetailsRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        loadOrganicPaidHistoryDetails(listId, keyword);
      } else {
        self.organicPaidHistoryData = cast(response);
        self.loadingOrganicPaidHistoryDetails = false;
      }
    } catch (err) {
      self.loadingOrganicPaidHistoryDetails = false;
      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingOrganicPaidHistoryDetails = false;
    }
  });

  const loadSerpOverviewDetails = flow(function* (listId, keyword) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingSerpOverviewDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordSerpOverviewDetails(listId, keyword, publicHash);
      if (response?.taskStatus == 'PENDING' && self.keDetailsRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        loadSerpOverviewDetails(listId, keyword);
      } else {
        self.serpOverviewDetails = cast(response);
        self.loadingSerpOverviewDetails = false;
        if (!self.loadingKeywordMainDetails) {
          loadKeywordMainDetails(listId, keyword, true);
        }
      }
    } catch (err) {
      self.loadingSerpOverviewDetails = false;

      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }

      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        self.serpOverviewDetails = null;
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingSerpOverviewDetails = false;
    }
  });

  const loadOrganicPaidHistoryDetailsV2 = flow(function* (listId, keyword) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingOrganicPaidHistoryDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordOrganicPaidHistoryDetails(listId, keyword, publicHash);
      if (response?.taskStatus == null || response?.taskStatus == 'PENDING') {
        yield new Promise(r => setTimeout(r, 2000));
        loadOrganicPaidHistoryDetailsV2(listId, keyword);
      } else {
        self.organicPaidHistoryData = cast(response);
        self.loadingOrganicPaidHistoryDetails = false;
      }
    } catch (err) {
      self.loadingOrganicPaidHistoryDetails = false;
      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingOrganicPaidHistoryDetails = false;
    }
  });

  const loadSerpOverviewDetailsV2 = flow(function* (listId, keyword) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingSerpOverviewDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordSerpOverviewDetails(listId, keyword, publicHash);
      if (response?.taskStatus == null || response?.taskStatus == 'PENDING') {
        yield new Promise(r => setTimeout(r, 2000));
        loadSerpOverviewDetailsV2(listId, keyword);
      } else {
        self.serpOverviewDetails = cast(response);
        self.loadingSerpOverviewDetails = false;
        if (!self.loadingKeywordMainDetails) {
          loadKeywordMainDetails(listId, keyword, true);
        }
      }
    } catch (err) {
      self.loadingSerpOverviewDetails = false;

      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }

      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        self.serpOverviewDetails = null;
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingSerpOverviewDetails = false;
    }
  });

  const loadSvCountriesDetails = flow(function* (listId, keyword) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingSvCountriesDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordSvCountriesDetails(listId, keyword, publicHash);
      if (response?.taskStatus == 'PENDING' && self.keDetailsRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        loadSvCountriesDetails(listId, keyword);
      } else {
        self.svTopCountries = cast(response?.results);
        self.loadingSvCountriesDetails = false;
      }
    } catch (err) {
      self.loadingSvCountriesDetails = false;

      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }

      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingSvCountriesDetails = false;
    }
  });

  const loadKeywordDetailList = flow(function* (listId) {
    const publicHash = getSingleUrlParam('public_hash');
    // self.loadingKeywordDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getKeywordDetailsList(listId, publicHash);
      if (response) {
        self.keywordDetailsList = cast(response);
      }
      return response;
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      return Promise.reject(err);
    } finally {
      // self.loadingKeywordDetails = false;
    }
  });

  const loadTermsMatchDetails = flow(function* (keyword, locationId, countryCode) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingTermsMatchDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getTermsMatchDetails(keyword, locationId, countryCode, publicHash);
      if (response?.taskStatus == 'PENDING' && self.keDetailsRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        loadTermsMatchDetails(keyword, locationId, countryCode);
      } else {
        self.termsMatchData = cast(response);
        self.loadingTermsMatchDetails = false;
      }
    } catch (err) {
      self.loadingTermsMatchDetails = false;

      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingTermsMatchDetails = false;
    }
  });

  const loadTermsMatchMagicDetails = flow(function* (data, includeParam?: string, noLoading?: boolean) {
    const tableName = getSingleUrlParam('table');
    const publicHash = getSingleUrlParam('public_hash');
    const locationId = getSingleUrlParam('locationId');
    const countryCode = getSingleUrlParam('countryCode')?.toUpperCase();
    if (!noLoading) {
      self.magicTableLoading = true;
    }
    const payloadData = {keyword: data?.keyword, search: data?.search, listId: data?.listId};
    if (locationId) {
      payloadData['locationId'] = locationId;
    } else {
      payloadData['countryCode'] = countryCode;
    }
    // if (data?.search) {
    //   payloadData['search'] = data?.keyword;
    // }
    if (self.updatedKwParam !== includeParam) {
      self.updatedKwParam = includeParam;
    }
    try {
      const {response, exportLink} = yield KEYWORD_RESEARCH_API_V2.getTermsMatchMagicList(payloadData, self.magicTableParams, includeParam, tableName, publicHash);
      if (response && response?.results?.length>0) {
        self.magicTableData = cast(response);
      } else {
        self.magicTableData = {
          count: response?.count,
          results: null,
        };
      }
      self.magicTableDataUrl = exportLink;
      self.magicTableLoading = false;
      if (includeParam == self.updatedKwParam) {
        if (response?.results?.some(z => z?.processingStatus == 'PENDING') && self.termsMathRepolling && self.updatedKwParam == includeParam) {
          yield new Promise(r => setTimeout(r, 2000));
          return loadTermsMatchMagicDetails(data, self.updatedKwParam, noLoading);
        } else {
          self.callForQuota = !self.callForQuota;
        }
      }
    } catch (error) {
      genericError(error);
    } finally {
      self.magicTableLoading = false;
    }
  });

  const loadTermSearchMagicDetails = flow(function* (data, includeParam?: string, noLoading?: boolean) {
    const tableName = getSingleUrlParam('table');
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.magicTableLoadingV2 = true;
    }
    const filters = self.kmtTermsSearchFilter?.filter(z => z?.active);
    const payloadData = {...data, keyword: includeParam ? `${data?.keyword} ${includeParam?.replace(/,/g, ' ')}` : data?.keyword};
    try {
      const {response, exportLink} = yield KEYWORD_RESEARCH_API_V2.getTermSearchMagicList(payloadData, self.magicTableParams, tableName, publicHash, formatFilters(filters));
      self.magicTableDataV2 = cast(response);
      self.magicTableDataUrlV2 = exportLink;
      self.magicTableLoadingV2 = false;
      if (response?.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadTermSearchMagicDetails(data, self.updatedKwParam, noLoading);
      }
    } catch (error) {
      genericError(error);
    } finally {
      self.magicTableLoadingV2 = false;
    }
  });

  const loadTermAggregationKeywordsDetail = flow(function* (data, includeParameter?: string) {
    const payloadData = data;
    const tableName = getSingleUrlParam('table');
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingMagicTreeV2 = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getTermAggregationKeywordsDetail(payloadData, self.treeListParams, includeParameter, tableName, publicHash);
      if (response) {
        // self.tokenAggregationList = cast(response?.results);
        return response;
      }
    } catch (error) {
      genericError(error);
    } finally {
      self.loadingMagicTreeV2 = false;
      self.loadingMagicTreeUrlV2 = false;
    }
  });

  const loadTermsMatchDetailsV2 = flow(function* (keyword, countryCode) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingTermsMatchDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getTermsMatchDetailsV2(keyword, countryCode, publicHash);
      if (response?.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadTermsMatchDetailsV2(keyword, countryCode);
      } else {
        self.termsMatchDataV2 = cast(response);
        self.loadingTermsMatchDetails = false;
      }
    } catch (err) {
      self.loadingTermsMatchDetails = false;

      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingTermsMatchDetails = false;
    }
  });

  const loadTermSearchQuestionsDetails = flow(function* (keyword, countryCode) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingTermsMatchQuestionsDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getTermSearchQuestionsDetails(keyword, countryCode, publicHash);
      if (response?.shouldRepoll) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadTermSearchQuestionsDetails(keyword, countryCode);
      } else {
        self.termsMatchQuestionsDataV2 = cast(response);
        self.loadingTermsMatchQuestionsDetails = false;
      }
    } catch (err) {
      self.loadingTermsMatchQuestionsDetails = false;
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      return Promise.reject(err);
    } finally {
      // self.loadingTermsMatchQuestionsDetails = false;
    }
  });

  const loadSearchIntentListV2 = flow(function* (keyword: string, locationId: any, countryCode: any) {
    const findIndex = toJS(self.magicTableDataV2?.results)?.findIndex(z => z?.keyword == keyword);
    if (findIndex != -1) {
      self.magicTableDataV2.results[findIndex] && (self.magicTableDataV2.results[findIndex].searchIntentLoading = true);
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.refetchSearchIntentListV2(keyword.replace(/[^a-zA-Z0-9 ]/g, ''), locationId, countryCode);
      if (findIndex != -1) {
        self.magicTableDataV2.results[findIndex] && (self.magicTableDataV2.results[findIndex].searchIntent = response);
      }
    } catch (err) {
      const errorMessage = apiError(err) as string;
      useErrorNotification({
        msg: errorMessage || 'Failed to get search intent list',
      });
      if (findIndex != -1) {
        self.magicTableDataV2.results[findIndex] && (self.magicTableDataV2.results[findIndex].searchIntentLoading = false);
      }
    }
  });

  const loadKeywordDetailsV2 = (listId, keyword, countryCode?: any) => {
    self.loadingKeywordDetails = true;
    try {
      if (listId && keyword) {
        loadTermsMatchDetailsV2(keyword, countryCode);
        loadTermSearchQuestionsDetails(keyword, countryCode);
        loadKeywordMainDetails(listId, keyword);
        loadSvCountriesDetails(listId, keyword);
        loadOrganicPaidHistoryDetailsV2(listId, keyword);
        loadSerpOverviewDetailsV2(listId, keyword);
      }
    } catch (error) {
      genericError(error);
    } finally {
      self.loadingKeywordDetails = false;
    }
  };

  const setMagicTableParams = data => {
    self.magicTableParams = data;
  };

  const setMagicTableLoading = data => {
    self.magicTableLoading = data;
    self.loadingMagicTreeUrl = data;
  };

  const setTreeListParams = data => {
    self.treeListParams = data;
  };


  const keywordMainDetailsLoading = () => {
    self.loadingKeywordMainDetails = true;
    self.loadingSvCountriesDetails = true;
    self.loadingTermsMatchDetails = true;
    self.loadingTermsMatchQuestionsDetails = true;
    self.loadingOrganicPaidHistoryDetails = true;
    self.loadingSerpOverviewDetails = true;
  };

  const loadTermsMatchQuestionsDetails = flow(function* (keyword, locationId, countryCode) {
    const publicHash = getSingleUrlParam('public_hash');
    self.loadingTermsMatchQuestionsDetails = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getTermsMatchQuestionsDetails(keyword, locationId, countryCode, publicHash);
      if (response?.taskStatus == 'PENDING') {
        yield new Promise(r => setTimeout(r, 2000));
        loadTermsMatchQuestionsDetails(keyword, locationId, countryCode);
      } else {
        self.termsMatchQuestionsData = cast(response);
        self.loadingTermsMatchQuestionsDetails = false;
      }
    } catch (err) {
      self.loadingTermsMatchQuestionsDetails = false;
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', 'Quota limit exceeded');
        return null;
      }
      return Promise.reject(err);
    } finally {
      // self.loadingTermsMatchQuestionsDetails = false;
    }
  });

  const loadMagicKeywordsData = flow(function* (data, includeParameter?: string) {
    const payloadData = data;
    const tableName = getSingleUrlParam('table');
    const publicHash = getSingleUrlParam('public_hash');
    const locationId = getSingleUrlParam('locationId');
    const countryCode = getSingleUrlParam('countryCode')?.toUpperCase();
    if (locationId) {
      payloadData['locationId'] = locationId;
    } else {
      payloadData['countryCode'] = countryCode;
    }
    self.loadingMagicTree = true;
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.getMagicKeywordsDetail(payloadData, self.treeListParams, includeParameter, tableName, publicHash);
      if (response) {
        // self.tokenAggregationList = cast(response?.results);
        return response?.results;
      }
    } catch (error) {
      genericError(error);
    } finally {
      self.loadingMagicTree = false;
      self.loadingMagicTreeUrl = false;
    }
  });
  const setloadingforDetail = value=>{
    self.loadingKeywordMainDetails = value;
    self.loadingSvCountriesDetails = value;
    self.loadingOrganicPaidHistoryDetails = value;
    self.loadingSerpOverviewDetails = value;
    self.loadingTermsMatchDetails = value;
    self.loadingTermsMatchQuestionsDetails = value;
  };
  const loadKeywordDetails = (listId, keyword, locationId: any, countryCode?: any) => {
    self.loadingKeywordDetails = true;
    try {
      if (listId && keyword) {
        loadTermsMatchDetails(keyword, locationId, countryCode);
        loadKeywordMainDetails(listId, keyword);
        loadSvCountriesDetails(listId, keyword);
        loadOrganicPaidHistoryDetails(listId, keyword);
        loadTermsMatchQuestionsDetails(keyword, locationId, countryCode);
        loadSerpOverviewDetails(listId, keyword);
      }
    } catch (error) {
      genericError(error);
    } finally {
      self.loadingKeywordDetails = false;
    }
  };

  const setKESelectedKeyword = value => {
    self.keSelectedKeyword = value;
  };

  const setKESelectedId = value => {
    self.keSelectedId = value;
  };

  const resetKEv2Data = () => {
    self.keywordMainDetails = null;
    self.serpOverviewDetails = null;
    self.svTopCountries = cast([]);
    self.organicPaidHistoryData = null;
    self.keywordDetailsList = null;
    self.termsMatchData = null;
    self.termsMatchQuestionsData = null;
  };
  const getRefetchData = flow(function* (id, keyword, locationId: any, countryCode: any) {
    self.loadingRefetchData= true;
    try {
      yield KEYWORD_RESEARCH_API_V2.getRefetchDetails(id, keyword);
      loadKeywordDetails(id, keyword, locationId, countryCode);
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingRefetchData= false;
    }
  });

  const getRefetchDataV2 = flow(function* (id, keyword, countryCode: any) {
    self.loadingRefetchData= true;
    try {
      yield KEYWORD_RESEARCH_API_V2.getRefetchDetails(id, keyword);
      loadKeywordDetailsV2(id, keyword, countryCode);
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingRefetchData= false;
    }
  });

  const loadSearchIntentList = flow(function* (keyword: string, locationId: any, countryCode: any) {
    const findIndex = toJS(self.magicTableData?.results)?.findIndex(z => z?.keyword == keyword);
    if (findIndex != -1) {
      self.magicTableData.results[findIndex] && (self.magicTableData.results[findIndex].searchIntentLoading = true);
    }
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.refetchSearchIntentList(keyword.replace(/[^a-zA-Z0-9 ]/g, ''), locationId, countryCode);
      if (findIndex != -1) {
        self.magicTableData.results[findIndex] && (self.magicTableData.results[findIndex].searchIntentList = response);
      }
    } catch (err) {
      const errorMessage = apiError(err) as string;
      useErrorNotification({
        msg: errorMessage || 'Failed to get search intent list',
      });
      if (findIndex != -1) {
        self.magicTableData.results[findIndex] && (self.magicTableData.results[findIndex].searchIntentLoading = false);
      }
    }
  });

  const getAndUpdateSearchIntentList = flow(function* (keywords: string[], locationId: any, countryCode: any) {
    try {
      self.fetchingSearchIntent = true;
      keywords.forEach(keyword => {
        const findIndex = self.magicTableData?.results?.findIndex(z => z?.keyword.trim() === keyword);
        if (findIndex != -1) {
          self.magicTableData.results[findIndex] && (self.magicTableData.results[findIndex].searchIntentLoading = true);
        }
      });
      const response = yield KEYWORD_RESEARCH_API_V2.getAndUpdateSearchIntentList(
        {keywords: keywords.map(keyword => keyword.replace(/[^a-zA-Z0-9 ]/g, '')), location_id: Number(locationId), country_code: countryCode},
      );
      keywords.forEach(keyword => {
        const keywordData = response.find(z => z?.keyword == keyword.replace(/[^a-zA-Z0-9 ]/g, ''));
        const findIndex = self.magicTableData?.results?.findIndex(z => z?.keyword.trim() === keyword);
        if (findIndex > -1) {
          if (keywordData) {
            self.magicTableData.results[findIndex] && (self.magicTableData.results[findIndex].searchIntentList = keywordData.searchIntent);
          } else {
            self.magicTableData.results[findIndex].searchIntentLoading = false;
          }
        }
      });
    } catch (err) {
      const errorMessage = apiError(err) as string;
      if (errorMessage === 'Ensure this field has no more than 20 elements.') {
        notification.info("Request can't be proceed", "Search Intent for more than 20 keywords can't be fetched at once");
      } else {
        useErrorNotification({msg: errorMessage});
      }
      keywords.forEach(keyword => {
        const findIndex = self.magicTableData?.results?.findIndex(z => z?.keyword.trim() == keyword);
        if (findIndex != -1) {
          self.magicTableData.results[findIndex] && (self.magicTableData.results[findIndex].searchIntentLoading = false);
        }
      });
    } finally {
      self.fetchingSearchIntent = false;
    }
  });

  const kmtRefetchKeyword = flow(function* (keyword, locationId, countryCode, listApiData) {
    const data = {
      keyword: keyword,
      location_id: locationId,
      country_code: countryCode,
    };
    try {
      const response = yield KEYWORD_RESEARCH_API_V2.refetchKeyword(data);
      if (response) {
        loadTermsMatchMagicDetails(listApiData?.data, listApiData?.term, true);
      }
    } catch (err) {
      notification.error('', 'Failed to refetch keyword(s)');
    }
  });

  const setDetailsRepolling = value => {
    self.keDetailsRepolling = value;
  };

  const setCreateListModalData = data => {
    self.createList = data;
  };

  const setIsExporting = val => {
    self.isExporting = val;
  };


  const exportSingleNode = flow(function* (node) {
    let JSpdf = null;
    if (typeof window !== 'undefined') {
      import('jspdf').then(module => {
        JSpdf = module.default;
      });
    }
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    yield html2canvas(node)
      .then(canvas => {
        canvas.style.display = 'none';
        const image = canvas.toDataURL();
        const pdf = new JSpdf({
          orientation: 'p',
          unit: 'pt',
          format: [canvas.width, canvas.height],
        });
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = Number(pdf.internal.pageSize.getHeight());
        pdf.addImage(image, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST', 0, 'FAST', true);
        pdf.save(`Keyword-details-${date}.pdf`);
      });
    setIsExporting(false);
  });

  const setTermsMathRepolling = value => {
    self.termsMathRepolling = value;
  };


  const setRefreshKw = value => {
    const findIndex = toJS(self.magicTableData?.results)?.findIndex(z => z?.keyword == value);
    if (findIndex != -1) {
      self.magicTableData.results[findIndex].processingStatus = 'PENDING';
    }
  };

  const setKeywordToShow = val => {
    self.keywordToShow = val;
  };

  const exportSerpTable = flow(function* (listId, keyword) {
    try {
      self.exportingSerpOverview = true;
      const response = yield KEYWORD_RESEARCH_API_V2.exportSerpOverview(listId, keyword);
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'text/csv',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `SERP overview for ${keyword}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.exportingSerpOverview = false;
    }
  });

  const updateMagicTableFilter = (filters, payload) => {
    self.magicTableParams.page = 1;
    self.kmtTermsSearchFilter = cast(filters);
    if (payload?.keyword) {
      loadTermSearchMagicDetails(payload);
    }
  };

  return {
    loadKeywordDetails,
    loadSearchIntentList,
    getAndUpdateSearchIntentList,
    setTermsMathRepolling,
    exportSerpTable,
    setRefreshKw,
    resetKEv2Data,
    exportSingleNode,
    setMagicTableLoading,
    setIsExporting,
    setloadingforDetail,
    setKeywordToShow,
    loadTermsMatchMagicDetails,
    loadKeywordDetailList,
    setKESelectedKeyword,
    setKESelectedId,
    kmtRefetchKeyword,
    getRefetchData,
    loadMagicKeywordsData,
    setMagicTableParams,
    setTreeListParams,
    setDetailsRepolling,
    setCreateListModalData,
    keywordMainDetailsLoading,
    loadTermSearchMagicDetails,
    loadTermAggregationKeywordsDetail,
    loadKeywordDetailsV2,
    loadTermsMatchDetailsV2,
    loadTermSearchQuestionsDetails,
    loadSearchIntentListV2,
    updateMagicTableFilter,
    getRefetchDataV2,
    loadOrganicPaidHistoryDetailsV2,
    loadSerpOverviewDetailsV2,
  };
});

export function initKeywordDetails() {
  return KeywordDetails.create({
    loadingKeywordDetails: true,
    loadingKeywordMainDetails: true,
    loadingOrganicPaidHistoryDetails: true,
    loadingSerpOverviewDetails: true,
    loadingSvCountriesDetails: true,
    callForQuota: false,
    exportingSerpOverview: false,
    loadingTermsMatchDetails: true,
    loadingTermsMatchQuestionsDetails: true,
    keSelectedKeyword: '',
    keSelectedId: '',
    keywordToShow: '',
    termsMathRepolling: false,
    magicTableLoading: true,
    magicTableLoadingV2: true,
    isExporting: false,
    loadingMagicTree: true,
    loadingMagicTreeV2: true,
    loadingMagicTreeUrl: false,
    loadingMagicTreeUrlV2: false,
    loadingRefetchData: false,
    fetchingSearchIntent: false,
    magicTableParams: {
      page_size: 50,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    treeListParams: {
      page_size: 2000,
      page: 1,
    },
    createList: {
      title: 'Create Keyword List',
      buttonName: 'Create List',
      listName: '',
      selectedCountry: {
        locationId: 2840,
        countryCode: 'US',
      },
      newKeyword: '',
      isRecord: {},
    },
  });
}
