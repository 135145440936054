import {observer} from 'mobx-react';
import {SingleAiOutline} from './SingleAiOutline';
import {debounce} from 'lodash';
import {ContentState, EditorState, Modifier, SelectionState} from 'draft-js';
import {useStore} from '@/store/root-store';
import {useCallback} from 'react';

interface Props {
  htmlToDraft: any;
}

export const ContentIdearesults = observer(({htmlToDraft}: Props) => {
  const {contentOptimizer: {currentPage, aiOutline}} = useStore('');
  const {settings: {customer: {getCustomerQuota}}} = useStore('');

  const debouncedChange = debounce((state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations = false) => {
    // we are then calling the edit content method which will fire the API call and send new state to the BE
    currentPage.editContent(state, isNewDecorations);
  }, 2000);


  const debouncedUpdate = useCallback(
    (state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations) => debouncedChange(state, focusTerms, focusTermsClassNames, isNewDecorations),
    // eslint-disable-next-line
        []
  );

  const insertText = async (outlineParam: any, id: number) => {
    // NEW FLOW start
    const {contentBlocks, entityMap} = htmlToDraft(outlineParam);

    const selection = currentPage.currentEditorState.getSelection();

    const newSelection = new SelectionState({
      anchorKey: selection.getAnchorKey(),
      anchorOffset: selection.getEndOffset(),
      focusKey: selection.getAnchorKey(),
      focusOffset: selection.getEndOffset(),
    });


    const fragment = ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap();

    const nonStyledState = Modifier.replaceWithFragment(
      currentPage.currentEditorState.getCurrentContent(),
      newSelection,
      fragment,
    );

    // WE are calculating nodes to toggle blue background on new Ai generated nodes
    let firstNewBlockId: number;
    const currentBlock = selection.getAnchorKey();
    const nextBlock = currentPage.currentEditorState.getCurrentContent().getBlockAfter(selection.getAnchorKey())?.getKey();

    const existingNodes = document.querySelectorAll('[data-block="true"]');
    existingNodes?.forEach((elem, id) => {
      if (elem.getAttribute('data-offset-key') && elem.getAttribute('data-offset-key') == `${currentBlock}-0-0`) {
        firstNewBlockId = id + 1;
      }
    });


    const updatedState = EditorState.push(currentPage.currentEditorState, nonStyledState, 'insert-fragment');
    currentPage.setCurrentEditorState(updatedState);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('[data-block="true"]');
      if (newTextElems.length) {
        for (let i = firstNewBlockId; i <= newTextElems.length; i++) {
          if (newTextElems[i]?.getAttribute('data-offset-key') && newTextElems[i]?.getAttribute('data-offset-key') != `${nextBlock}-0-0` && newTextElems[i]?.getAttribute('data-offset-key') != `${currentBlock}-0-0`) {
            newTextElems[i].classList.add('newTextBlock');
          } else {
            return;
          }
        }
      }
    }, 500);

    debouncedUpdate(updatedState, currentPage.analytics?.focusTerms, currentPage.focusTermsClassNames, false);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('.newTextBlock');
      if (newTextElems.length) {
        newTextElems.forEach(elem => {
          elem.classList.remove('newTextBlock');
        });
      }
    }, 3500);
    // NEW FLOW END
    aiOutline?.updateSingleOutline(true, id);
  };

  const outlineRewriteHandler = async (value: string, outlineIndex: number) => {
    try {
      await aiOutline?.rewriteOutline(value, outlineIndex);
      getCustomerQuota();
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return <>
    {aiOutline?.aiOutline?.map((singleOutline, idx) => {
      return <SingleAiOutline key={idx}
        outlineId={idx}
        aiOutline={singleOutline}
        insertToEditor={insertText}
        rewriteOutline={outlineRewriteHandler}
        rewritingOutline={aiOutline?.rewritingContent}
        rewritingOutlineId={aiOutline?.rewritingId}/>;
    })}
  </>;
});
