import {BaseApi} from '@/api/base-api';
import {getApiUrl} from '@/api/common-utils';
import ReportsApi from '@/api/gsc/reports/reports-api';
import SitesApi from '@/api/gsc/sites/sites-api';
import IntegrationApi from '@/api/gsc/integration/integration-api';
import PageGroupsApi from '@/api/gsc/page-groups/page-groups-api';
import {WatchlistApi} from './watchlist/watchlist';
import SitePropertyEventsApi from './site-property-events';
import TopPagesApi from './top-pages';
import TopKeywordsApi from './top-keywords';
import BacklinksApi from './backlinks/backlinks-api';
import CompetitorDescoveryApi from './competitor-descovery/competitro-descovery-api';
import GscReportsApi from './gscReports';
import AnalyticsApi from './analytics/analytics-api';
import SiteStructureApi from './siteStructure/site-structure-api';

export const reportsApi = new ReportsApi();
export const sitesApi = new SitesApi();
export const integrationApi = new IntegrationApi();
export const pageGroupsApi = new PageGroupsApi();
export const WatchlistClient = new WatchlistApi(getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2'));
export const sitePropertyEventsApi = new SitePropertyEventsApi();
export const topPagesApi = new TopPagesApi();
export const topKeywordsApi = new TopKeywordsApi();
export const backlinksApi = new BacklinksApi();
export const competitorDescoveryApi = new CompetitorDescoveryApi();
export const gscReportsApi = new GscReportsApi();
export const analyticsApi = new AnalyticsApi();
export const siteStructureApi = new SiteStructureApi();

