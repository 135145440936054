import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'antd';
import {notification} from '@/utils/notification-v2';
import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {apiError} from '@/utils/api';
import {urlDomainRegex, urlSubDomainRegex} from '@/constants/regex';

const BlockListModal = ({blackListModalVisible, setBlackListModalVisible}) => {
  const {campaigns: {addGlobalBlockList, loadingAddGlobalBlockList, globalBlocklist}} = useStore('');

  const hiddenFileInput = useRef(null);
  const [blockList, setBlockList] = useState<any>('');
  const [showError, setShowError] = useState(false);

  function domainIsNotValid(blockList) {
    const domainsArray = blockList?.split('\n')?.map(it => it?.trim())?.filter(val => val);
    const filteredDomains = domainsArray?.filter(domain => {
      return domain?.match(urlDomainRegex) || domain?.match(urlSubDomainRegex);
    });
    return filteredDomains?.length !== domainsArray?.length;
  }

  useEffect(() => {
    const textValue = globalBlocklist?.join('\n');
    setBlockList(textValue || '');
  }, [blackListModalVisible]);

  const handleChange = event => {
    try {
      const file = event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = data => {
          setShowError(false);
          const contents = (data?.target?.result);
          setBlockList(contents);
        };
        reader.readAsText(file);
      }
    } catch (error) {
      notification.error('', 'Failed to read file data');
    }
  };


  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleBlockList = e => {
    if (showError && !domainIsNotValid(e?.target?.value)) setShowError(false);
    setBlockList(e.target.value.replaceAll(',', '\n'));
  };

  const updateGlobalBlockList = async () => {
    try {
      if (domainIsNotValid(blockList)) {
        setShowError(true);
        return;
      } else setShowError(false);
      const domains = blockList.split('\n')
        .map(keyword => keyword.trim())
        .filter(Boolean);

      const blockListCsv = domains.join(', ');
      const prePayload = {
        domains_blacklist: blockListCsv?.split(', '),
      };
      await addGlobalBlockList(prePayload);
      setBlackListModalVisible(false);
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notification.error('', errorMessage, false);
    }
  };

  return (
    <BlackListModal
      visible={blackListModalVisible}
      onCancel={() => {
        setShowError(false);
        setBlackListModalVisible(false);
      }}
      closable={false}
      footer={false}
      width={960}
    >
      <Title>Global domains blacklist</Title>
      <Desc>
        Add the domains you wish to exclude from search results to this global domain black list. Divide URL’s by commas.<br/>
        You also have the option to customize the black list for individual campaigns as needed.
      </Desc>
      <StyledTextArea>
        <CustomTextarea
          className='text-area'
          placeholder='e.g google.com, youtube.com, instagram.com'
          variant='custom'
          minHeight='400px'
          maxHeight='400px'
          value={blockList}
          onChange={handleBlockList}
        />
      </StyledTextArea>
      {showError && <div style={{color: 'red', marginTop: 8}}>Please enter valid domains.</div>}
      <Footer>
        <div className='import-from-csv' onClick={handleClick}>
          <img src='/icons/csvIcon.svg' width={14} height={14} alt='csv' />Import from CSV
        </div>
        <div className='update-cancel'>
          <button className='cancel-button' onClick={() => setBlackListModalVisible(false)}>Cancel</button>
          <UpdateButton onClick={updateGlobalBlockList} loading={loadingAddGlobalBlockList} disabled={!blockList || blockList.trim().length === 0}>
            Update list
          </UpdateButton>
        </div>
      </Footer>
      <input
        type='file'
        ref={hiddenFileInput}
        accept='.csv'
        onClick={ (event:any) => {
          event.target.value = null;
        }}
        onChange={e => handleChange(e)}
        style={{display: 'none'}}
      />
    </BlackListModal>
  );
};

export default observer(BlockListModal);

const BlackListModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-body {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  color: #121212;
`;

const Desc = styled.div`
  margin-top: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #4E5156;
`;

const StyledTextArea = styled.div`
  margin-top: 14px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .import-from-csv {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #2D6CCA;
    cursor: pointer;
  }
  .update-cancel {
    display: flex;
    align-items: center;
    gap: 40px;
  }
  .cancel-button {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }
`;

const UpdateButton = styled(Button)`
  width: 124px;
  height: 44px;
  border-radius: 6px;
  background-color: #2D6CCA;
  color: #FFFFFF;
  &:focus, &:hover {
    background-color: #2D6CCA;
    color: #FFFFFF;
  }
`;
