import React, {useState, useRef} from 'react';
import styles from './style.module.scss';
import {Form, Select, AutoComplete} from 'antd';
import {CommonInput} from '@/components/common-components';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {useTranslation} from 'next-i18next';
import {countryFlag} from '@/utils/countries';
import {BaseSelectRef} from 'rc-select';

const {Option} = Select;

type OptionValue = {
  value: string;
};
export const BillingDetail = observer(() => {
  const {invoices: {setCountry, setState}, countries: {filteredCountries, getStatesOfCountry}} = useStore('');
  const {plans: {country}} = useStore('');
  const {t} = useTranslation('common');
  const selectRef= useRef() as React.Ref<BaseSelectRef>;
  const [selectedState, setSelectedState] = useState(getStatesOfCountry(country));


  const handleCounrtyChange = value => {
    setCountry(value);

    setSelectedState(getStatesOfCountry(value));
  };

  const handleStateChange = value => {
    setState(value);
  };

  return (
    <>
      <div className={styles.billingDetail}>
        <p className={styles.billingDetailLabel}>{t('billing-details')}</p>
        <div className={styles.billingName}>
          <Form.Item name='firstname' label='First Name' rules={[{required: true, message: t('first-name-required')}]}>
            <CommonInput name='firstName' inputType='text' />
          </Form.Item>

          <Form.Item name='lastname' label='Last Name' rules={[{required: true, message: t('last-name-required')}]}>
            <CommonInput name='lastName' inputType='text' />
          </Form.Item>
        </div>

        <div className={styles.billingAddress}>
          <Form.Item name='address' label='Address' rules={[{required: true, message: t('address-required')}]}>
            <CommonInput name='address' inputType='textarea' />
          </Form.Item>
        </div>

        <div className={styles.selectAarea}>

          <Form.Item
            name='country'
            label='Country'
            className={styles.selectContainer}
            rules={[{required: false, message: t('enter-valid-country')}]}>
            <Select
              showSearch
              ref={selectRef}
              id={'country'}
              placeholder='Country'
              onChange={handleCounrtyChange}
              defaultValue={country}
              className={styles.selectInput}
              filterOption={(input, option) =>
                option.country.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >

              {
                filteredCountries().map(countries => {
                  return <Option key={countries.code} country={countries?.name} value={countries.code}>{countryFlag(countries.code)} {countries?.name}</Option>;
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            name='state'
            label={'State/Province'}
            className={styles.selectContainer}
            rules={[{required: false, message: t('enter-valid-state')}]}>
            <AutoComplete
              id={'state'}
              className={styles.selectInput}
              onChange={handleStateChange}
              options={
                selectedState?.map(state => {
                  return {value: state.name};
                })
              }
              placeholder={t('enter-state-province')}
              filterOption={(inputValue, option: OptionValue) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </Form.Item>
          <Form.Item className={styles.zipcodeArea} name='zipCode' label='Zip Code' rules={[{required: true, message: t('zip-code-required')}]}>
            <CommonInput name='zipCode' inputType='text'/>
          </Form.Item>
        </div>
      </div>
    </>
  );
});
