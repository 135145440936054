import {getApiUrl, getAuthorizationHeader, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';

export interface CampaignPaginationParams {
  pageNumber: number;
  pageSize: number;
}

export interface CampaignListResults {
  id: number;
  name: string | null;
  project: string | null;
  createdAt: string | null;
  isActive: boolean | null;
  targetKeywords: string[] | null;
  opportunities: number | null;
  sentEmails: number | null;
  repliedEmails: number | null;
  emailResponseRate: number | null;
}

export interface CampaignsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  totalPages: number;
  pageSize: number;
  results: CampaignListResults[];
}

export interface CampaignCustomerEmailResults {
  id: number;
  name: string | null;
  project: string | null;
  createdAt: string | null;
  isActive: boolean | null;
  targetKeywords: string[] | null;
  opportunities: number;
  sentEmails: number;
  repliedEmails: number;
  responseRate: number;
}

export interface CampaignsCustomerEmailsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  totalPages: number;
  pageSize: number;
  results: CampaignCustomerEmailResults[];
}

export interface keywordDetailPayload {
  keyword: string;
}

class CampaignApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v1/customer-outreach');
  public async getCampaigns(params) {
    try {
      const response: AxiosResponse<CampaignsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        params: params,
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getFullCampaignsList() {
    try {
      const response: AxiosResponse<CampaignListResults[]> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/list-campaigns/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCampaignsCustomerEmails({pageNumber, pageSize}: CampaignPaginationParams) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/customer-emails/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        params: {
          page_number: pageNumber,
          page_size: pageSize,
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateDailyLimit(payload) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.post(`${CampaignApi.baseUrl}/campaigns/update-daily-limit-by-email/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getKeywordSuggestions(params: any) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/keyword-suggestions/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        params: params,
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getFolders() {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/customer-projects/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createFolder(payload: any) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.post(`${CampaignApi.baseUrl}/campaigns/create-project/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateFolder(payload: any, id: number) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.patch(`${CampaignApi.baseUrl}/campaigns/update-project/${id}/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteFolder(id: number) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.delete(`${CampaignApi.baseUrl}/campaigns/destroy-project/?project_id=${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getExistingTemplates(search?: string) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/customer-templates/?search=${search}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async addNewMail() {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/start-oauth-flow/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getChartData(id: number) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/${id}/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async toogleStatus(id: number) {
    try {
      const response: AxiosResponse<CampaignsCustomerEmailsResponse> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/${id}/toggle-campaign/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postNewCampaign(payload) {
    try {
      const response = await this.axios.post(`${CampaignApi.baseUrl}/campaigns/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateCampaign(payload: any, id: number) {
    try {
      const response = await this.axios.put(`${CampaignApi.baseUrl}/campaigns/${id}/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteCampaign(id: number) {
    try {
      const response = await this.axios.delete(`${CampaignApi.baseUrl}/campaigns/${id}/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCampaignSettings(id: number) {
    try {
      const response = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/${id}/get-settings/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async createTemplate(data:any) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignApi.baseUrl}/campaigns/create-template/`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateTemplate(data:any, id: number) {
    try {
      const response: AxiosResponse<any> = await this.axios.put(`${CampaignApi.baseUrl}/campaigns/update-template/?template_id=${id}`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteTemplate(id: number) {
    try {
      const response: AxiosResponse<any> = await this.axios.delete(`${CampaignApi.baseUrl}/campaigns/destroy-template/?template_id=${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public getAddNewMailUrl() {
    const token = getTokenFromCookies();
    return `https://keyword.searchatlas.com/api/v1/customer-outreach/campaigns/start-oauth-flow/?jwt=${token}`;
  }
  public async editCampaign(payload, id) {
    try {
      const response = await this.axios.put(`${CampaignApi.baseUrl}/campaigns/${id}/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async addOpportunitiesFromCsv(id: number, payload: any) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignApi.baseUrl}/campaigns/${id}/import-domains/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async addGlobalBlockList(payload: any) {
    try {
      const response: AxiosResponse<any> = await this.axios.put(`${CampaignApi.baseUrl}/campaigns/update-global-blacklist/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getGlobalBlockList() {
    try {
      const response: AxiosResponse<any> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/global-blacklist/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async getCampaignBlockList(id) {
    try {
      const response: AxiosResponse<any> = await this.axios.get(`${CampaignApi.baseUrl}/campaigns/${id}/campaign-blacklist/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async updateCampaignBlockList(id, payload) {
    try {
      const response: AxiosResponse<any> = await this.axios.put(`${CampaignApi.baseUrl}/campaigns/${id}/update-campaign-blacklist/`, payload, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteConnectedEmail(linkedEmailId: number) {
    try {
      const response: AxiosResponse<any> = await this.axios.delete(`${CampaignApi.baseUrl}/campaigns/remove-linked-email/`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        params: {
          linked_email_id: linkedEmailId,
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const CAMPAIGN_API = new CampaignApi();
