import React, {useEffect, useState} from 'react';
import styles from '@/components/dashboard/billing/payment-method-modal/style.module.scss';
import {Modal} from '@/components/common-components/components';
import {Alert, Form} from 'antd';
import {useStore} from '@/store/root-store';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import {BillingDetail} from '@/components/dashboard/billing/payment-method-modal/billing-detail';
import {observer} from 'mobx-react';
import {Button} from '@/components/common-components/v2';
import {notification} from '@/utils/notifications';
import {NextImg} from '@/utils/nextImg';

interface props {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  getCustomerQuota?: any;
}

export interface CheckoutFormProp {
  setStripe: any;
  setElements: any;
}

export const PaymentMethodModal: React.FC<props> = observer(({isOpen, setIsOpen, getCustomerQuota}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const {invoices: {
    stopLoading,
    startLoading,
    getServerErrors,
    resetServerErrors,
    getUpdateModal,
    updatePrimaryPaymentMethod,
    updatePaymentModal,
    getCurrentPayment,
    createPaymentMethod,
  }, settings: {
    customer: {
      profile: {
        useTestStripeKey,
      },
    },
  },
  } = useStore('');

  const [stripePromise] = useState(() => loadStripe(useTestStripeKey ? process.env.STRIPE_TEST_PUBLIC_KEY : process.env.STRIPE_LIVE_PUBLIC_KEY));

  useEffect(() => {
    if (!getUpdateModal) {
      updatePaymentModal(true);
      setIsOpen(false);
    }
  }, [getUpdateModal, setIsOpen, updatePaymentModal]);

  const createOptions = () => {
    return {
      base: {
        'fontSize': '14px',
        'color': '#121212',
        'fontFamily': 'Inter',
        'letterSpacing': '0.025em',
        '::placeholder': {
          'color': '#A3A4A4',
          'fontSize': '14px',
        },
      },
      invalid: {
        color: '#F44343',
      },
    };
  };

  const handleSubmit = async event => {
    if (!stripe || !elements) {
      return;
    }
    startLoading();
    updatePaymentModal(true);
    const cardNumElement = elements.getElement(CardNumberElement);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumElement,
      // billing_details: {
      //   address: {
      //     country: null,
      //     line1: event.address,
      //     line2: null,
      //     postal_code: event.zipCode,
      //     state: null,
      //   },
      //   name: `${event.firstname} ${event.lastname}`,
      // },
    });
    if (result.error) {
      setErrorMessage(result.error.message);
      stopLoading();
    } else {
      setErrorMessage('');
      resetServerErrors();
      const payload = {
        source: 'stripe',
        first_name: event.firstname,
        last_name: event.lastname,
        address: event.address,
        stripe_payment_method: result.paymentMethod,
      };
      const response = await createPaymentMethod(payload);
      if (response?.id) {
        await updatePrimaryPaymentMethod(response?.id, {is_primary: true});
        notification.success('Payment Method successfully processed.');
        await getCurrentPayment();
        if (getCustomerQuota) await getCustomerQuota();
      } else if (getServerErrors.length) {
        notification.error(getServerErrors[0]);
        setTimeout(() => {
          resetServerErrors();
        }, 5000);
      }
      stopLoading();
    }
  };

  // const onFinishFailed = () => {
  //   setErrorMessage('Billing information Validation Failed');
  // };

  const errors = error => {
    return (
      <ul>
        {error.map((err, index) => (<li key={index}>{err}</li>))}
      </ul>
    );
  };

  const CheckoutForm: React.FC<CheckoutFormProp> = observer(({setStripe, setElements}) => {
    setStripe(useStripe());
    setElements(useElements());

    const {invoices: {isLoading}} = useStore('');

    return (
      <div className={styles.container}>
        <h4>Add new card</h4>
        <span className={styles.cardLabel}>Payment method</span>
        <div className={styles.cardDetail}>

          <label className={styles.cardNumber}>
            Card number
            <Form.Item name='cardNumber' rules={[{required: true, message: 'Required card number'}]}>
              <CardNumberElement options={
                {style: createOptions(), showIcon: true}} className={styles.cardNumberStyle}/>
            </Form.Item>
          </label>

          <div className={styles.cardInfo}>
            <label>
              Expiration date
              <Form.Item name='cardNumber' rules={[{required: true, message: 'Required expiration date'}]}>

                <CardExpiryElement options={{style: createOptions()}} className={styles.cardInputStyle}/>
              </Form.Item>

            </label>
            <label style={{fontWeight: 500}}>
              CVC
              <Form.Item name='cardNumber' rules={[{required: true, message: 'Required CVC'}]}>

                <CardCvcElement options={{style: createOptions()}} className={styles.cardInputStyle}/>
              </Form.Item>

            </label>

          </div>

          {/* Temp removed */}
          {/* <BillingDetail/> */}

          <div className={styles.cardLockInfo}>
            <NextImg width={18} height={18} src='/img/icon/lock.svg'/> <p>Your transactions are secured with SSL encryption.</p>
          </div>
          <div className={styles.cardFooter}>

            <Button
              color='textGrayDark' variant='ghost'
              className={styles.cardCancel}
              onClick={() => setIsOpen(false)}
            > Cancel</Button>
            <Button
              color='purple' variant='solid' textColor='white'
              className={styles.cardUpdate}
              loading={isLoading}
              disabled={isLoading}
              htmlType='submit'
            >
              Save card
            </Button>
          </div>


        </div>
      </div>
    );
  });

  return (
    <div>
      <Modal
        onClose={() => setIsOpen(false)}
        visible={isOpen}
        className={styles.paymentMethodModal}
      >
        <Form onFinish={handleSubmit} >
          {errorMessage != '' ? <Alert message={`${errorMessage}`} type='error'/> : ''}
          {getServerErrors.length > 0 && <Alert
            message={'Errors'}
            description={errors(getServerErrors)}
            closable
            type='error'/>
          }
          <Elements stripe={stripePromise}>
            <CheckoutForm setStripe={setStripe} setElements={setElements} />
          </Elements>
        </Form>
      </Modal>
    </div>
  );
});
