import {observer} from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import {EChartOption} from 'echarts';
import React from 'react';

export interface ProgressChartProps {
  progress: number;
  topLabel: string | number;
  topLabelColor?: string;
  topLabelSize?: number;
  topLabelPadding?: number[];

  bottomLabel?: string | number;
  bottomLabelColor?: string;
  bottomLabelSize?: number;
  bottomLabelPadding?: number[];

  height?: number;
  width?: number;

  progressBarColor?: string;
  progressBarBackgroundColor?: string;
}

export const ProgressChart: React.FC<ProgressChartProps> = observer(({
  progress,
  topLabel,
  bottomLabel,
  height = 135,
  width = 135,
  bottomLabelColor = 'black',
  topLabelSize = 24,
  topLabelPadding = [-15, 0, 0, 7],
  topLabelColor = '#77757d',
  bottomLabelSize = 12,
  bottomLabelPadding = [25, 0, 0, 0],
  progressBarColor = '#7f4ead',
  progressBarBackgroundColor = '#cfced0',
}) => {
  return (
    <ReactEcharts
      style={{height: `${height}px`, width: `${width}px`}}
      option={{
        series: [
          {
            name: 'Data',
            type: 'pie',
            radius: ['100%', '83%'],
            avoidLabelOverlap: false,
            startAngle: 270,
            cursor: 'normal',
            label: {
              position: 'center',
              fontSize: topLabelSize,
              itemStyle: {color: topLabelColor},
            },
            /*  hoverAnimation: false, */
            data: [
              {
                value: progress,
                name: topLabel,
                tooltip: {show: false} as EChartOption.Tooltip,
                itemStyle: {color: progressBarColor},
                emphasis: {
                  itemStyle: {color: progressBarColor},
                },
                label: {
                  color: topLabelColor,
                  fontSize: topLabelSize,
                  position: 'center',
                  padding: topLabelPadding,
                },
              },
              {
                value: 100 - progress,
                name: bottomLabel,
                label: {
                  show: false,
                  color: bottomLabelColor,
                  padding: bottomLabelPadding,
                  position: 'center',
                  fontSize: bottomLabelSize,
                },
                tooltip: {show: false} as EChartOption.Tooltip,
                itemStyle: {color: progressBarBackgroundColor},
                emphasis: {
                  itemStyle: {color: progressBarBackgroundColor},
                },
              },
            ],
          },
        ],
      } as EChartOption}
      notMerge={true}
      lazyUpdate={true}
    />
  );
});

export default ProgressChart;
