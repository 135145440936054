import React, {useEffect, useState} from 'react';
import COUNTRY_CODES from '@/constants/countryCodes';
import {Globe} from 'react-feather';
import {Select as AntdSelect, SelectProps, Tooltip} from 'antd';
import styled from 'styled-components';
import {COLORS} from './colors';
import {useTranslation} from 'next-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {countryFlag} from '@/utils/countries';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';

const {Option} = AntdSelect;

interface Props extends SelectProps<any>{
  variant?: 'light' | 'dark' | 'default' | 'grey' | 'lightGray' | 'disabled';
  countriesToInclude?: string[];
  countriesToExclude?: string[];
  isReport?: boolean;
  width?: string;
  showWorldWide?:boolean;
  showCountryCodes?:boolean;
  isDocked?:boolean;
  aligned?:boolean;
  isNewEmailSettings?: boolean;
  isProjectCreation?: boolean;
  showSearch?: boolean;
  isReportBuilder?: boolean;
  smallDropdown?: boolean;
  customIcon?: boolean;
  refresh?: boolean;
  onboardingV2Dropdown?: boolean;
}

export const OnboardingCountryDropdownV2 = observer(({
  countriesToInclude,
  showSearch,
  aligned,
  isProjectCreation,
  isDocked,
  showWorldWide=true,
  countriesToExclude,
  isReport = false,
  variant = 'light',
  showCountryCodes = false,
  smallDropdown = false,
  customIcon = false,
  defaultValue = 'US',
  ...props}: Props) => {
  const {countries: {filteredCountries, countriesLoading},
    settings: {customer: {profile: {address}}}} = useStore('');
  const {t} = useTranslation('common');
  const getCountriesList = () => {
    if (countriesToInclude?.length) {
      return countriesToInclude?.slice();
    } else if (countriesToExclude?.length) {
      return COUNTRY_CODES.filter(i => !countriesToExclude.includes(i));
    } else {
      return COUNTRY_CODES;
    }
  };

  const [rerender, setRerender] = useState(true);

  useEffect(() => {
    setRerender(false);
    setTimeout(() => setRerender(true), 0);
  }, [props.refresh]);

  if (!rerender) {
    return <></>;
  }

  const countriesList = filteredCountries(getCountriesList()).sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));

  return (!countriesLoading ?
    <StyledSelect
      defaultValue={defaultValue}
      variant={variant}
      value={props?.value?.toLowerCase() === 'uk' ? 'GB' : props.value}
      showSearch={isDocked || showSearch}
      suffixIcon={customIcon ? <FontAwesomeIcon icon={faCaretDown} style={{paddingLeft: 10}}/>:
        smallDropdown ? <FontAwesomeIcon icon={faCaretDown} style={{paddingLeft: 10}}/> : isDocked || props?.isNewEmailSettings || isProjectCreation && <FontAwesomeIcon color={`${variant=='lightGray' ? '#2D2F34': props?.isNewEmailSettings || isProjectCreation ? '#121212' : '#fff'}`} icon={faCaretDown}/>}
      dropdownStyle={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: ''}}
      filterOption={(input, option) =>
        option?.country?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      isDocked={isDocked}
      aligned={aligned}
      smallDropdown={smallDropdown}
      customIcon={customIcon}
      {...props}
    >
      {!smallDropdown && (!isReport && showWorldWide) ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark : COLORS.white}} value={''} country={'WorldWide'}><GlobeContainer variant={variant} ><StyledGlobe />{t('worldwide')}</GlobeContainer></Option>: ''}
      {!isReport ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} key={address?.defaultLocation ?? 'US'} country={address?.countryName ?? 'United States'} value={address?.defaultLocation ?? 'US'}>
        <Tooltip title={showCountryCodes ? address?.defaultLocation ?? 'US' : address?.countryName ?? 'United States'} mouseEnterDelay={.6} placement='right'>
          <OptionContent onboardingV2Dropdown={props.onboardingV2Dropdown} smallDropdown={smallDropdown} isNewEmailSettings={props?.isNewEmailSettings} variant={variant}>
            {countryFlag(address?.defaultLocation ?? 'US')}&nbsp; {smallDropdown ? address?.defaultLocation ?? 'US' : showCountryCodes ? address?.defaultLocation ?? 'US' : address?.countryName ?? 'United States'}
          </OptionContent>
        </Tooltip>
      </Option> : ''}
      {!smallDropdown && !isReport ? <Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} value={null} disabled><OptionContent smallDropdown={smallDropdown} variant={variant}><hr/></OptionContent></Option> : ''}
      {
        countriesList.map( item => {
          return (item.code !== (address?.defaultLocation ?? 'US') || isReport) ? (<Option style={{backgroundColor: variant === 'dark' ? COLORS.grayDark : variant === 'default' ? COLORS.grayDark: COLORS.white}} key={item.code} country={showCountryCodes ? item.code : item.name} value={item?.code}>
            <Tooltip title={isDocked && (showCountryCodes ? item?.code : item?.name)} mouseEnterDelay={.6} placement='right'>
              {item?.code ? <OptionContent onboardingV2Dropdown={props.onboardingV2Dropdown} smallDropdown={smallDropdown} isReportBuilder={props?.isReportBuilder} variant={variant}>{countryFlag(item?.code, '24px')} <span>{smallDropdown ? item?.code : showCountryCodes ? item?.code : item?.name}</span></OptionContent> :
                <OptionContent onboardingV2Dropdown={props.onboardingV2Dropdown} smallDropdown={smallDropdown} isDocked={isDocked} style={{display: 'flex', alignItems: 'center'}} variant={variant}><Globe style={{width: '24px', marginRight: 5}}/> <span>{'Worldwide'}</span></OptionContent>}
            </Tooltip>
          </Option>) : '';
        })
      }
    </StyledSelect> :
    null);
});
const variantColors = {
  light: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayDark,

  },

  default: {
    color: COLORS.white,
    backgroundColor: 'transparent',
    borderColor: 'transparent',

  },
  grey: {
    color: COLORS.black,
    backgroundColor: COLORS.white,
    borderColor: COLORS.textGrayLight,
  },
  lightGray: {
    color: COLORS.black,
    backgroundColor: COLORS.tableGray,
    borderColor: COLORS.textGrayLight,
  },
  dark: {
    color: COLORS.white,
    backgroundColor: COLORS.grayDark,
    borderColor: COLORS.grayDark,
  },
  disabled: {
    color: '#40404040',
    backgroundColor: '#f2f2f5',
    borderColor: '#f2f2f5',
  },
};

const StyledSelect = styled(AntdSelect)<Props>`
  color:${ p => variantColors[p.variant].color};
  .ant-select-selector {
    max-width: 180px !important;
    min-width: 76px !important;
    width: ${p => p?.smallDropdown ? '82px' : p.width ? p.width : '168px'} !important;
    margin-left:${p => p?.isReportBuilder ? '0px' : p.isDocked ? '6px' :p.aligned ? '5px' :'-12px'};
    margin-top:${p => p.isDocked ? '0px' :p.aligned ? '0px' : p?.isNewEmailSettings ? '0px' : '10px'};
    border-radius: 5px !important;
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${p => p.customIcon ? 'rgba(232, 232, 232, 1)' : p?.smallDropdown ? '#CDCDCD' : (p?.isNewEmailSettings || p?.isReportBuilder) ? '1px solid #E8E8E8' : variantColors[p.variant].borderColor} !important;
    padding: ${p=> p?.smallDropdown ? '' : p?.isNewEmailSettings ? '0px 10px' : '6px 15px'} !important;
    /* padding: 6px 15px !important; */
    height:${p=> p?.onboardingV2Dropdown ? '48px' : p?.smallDropdown ? '28px' : p?.isReportBuilder ? '38px' : p?.isNewEmailSettings ? '28px' : '44px'} !important;
    /* height: 44px !important; */
    cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'} !important;
    line-height: unset !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }

 .ant-select-arrow {
  color:${ p => variantColors[p.variant].color};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
    border-color: ${ p => variantColors[p.variant].borderColor} !important
  }
  .ant-select-selection-item {
    ${p => p.onboardingV2Dropdown ? 'padding-right: 0px !important;' : ''}
  }
`;

const GlobeContainer=styled.div<{variant: string}>`
  color:${ p => variantColors[p.variant].color};
  background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
  padding:0px;
  align-items: center;
  display: flex;
`;
const StyledGlobe=styled(Globe)`
  padding-right: 3px;
  width: 20px;
`;

const OptionContent = styled.div<{variant: string;isDocked?:boolean; isNewEmailSettings?: boolean; isReportBuilder?: boolean; smallDropdown?: boolean; onboardingV2Dropdown?: boolean}>`
  background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
  border-color: ${ p => variantColors[p.variant].borderColor} !important;
  color:${ p => variantColors[p.variant].color} !important;
  max-width: ${p=> p?.isReportBuilder ? '300px' : '140px'};
  height: 100%;
  ${p=> p?.isNewEmailSettings ? ` display: flex;
  align-items: center;` : ''}
  /* width: ${p => p.isDocked ? '40px' : 'unset'} !important; */
  line-height: ${p => p?.smallDropdown ? '20px' : ''};
  font-size: ${p => p?.smallDropdown ? '14px' : ''};
  ${p => p?.smallDropdown ? 'display: flex; align-items: center;' : ''};
  ${p => p.onboardingV2Dropdown ? 'gap: 5px;' : ''}

  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:active,focus{
      background-color: ${ p => variantColors[p.variant].backgroundColor} !important;
      border-color: ${ p => variantColors[p.variant].borderColor} !important;
      color:${ p => variantColors[p.variant].color} !important;
    }
`;
