import React from 'react';
import classnames from 'classnames';
import {useTranslation} from 'next-i18next';
import styles from './styles.module.scss';
// import {useRouter} from 'next/router';
// import {languages} from '@/components/layoutV2';
// import {Select} from 'antd';
// import {countryFlag} from '@/utils/countries';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {isCaPublicPage} from '@/utils/url';
import {NextImg} from '@/utils/nextImg';

interface Props {
  classname?: any;
  darkTheme: boolean;
}

export const PageFooter: React.FC<Props> = observer(({
  classname,
  darkTheme,
})=> {
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');
  const {t} = useTranslation('common');
  // const router = useRouter();
  // const {Option} = Select;

  // const handleLanguageChange = function(lang) {
  //   router.push(router.asPath, router.asPath, {locale: lang});
  // };

  return (isCaPublicPage() ? null :
    <div className={styles.mainDiv}>
      <div className={classnames(styles.container, classname, darkTheme ? styles.darkTheme: styles.lightTheme)}>
        <div className={styles.poweredContainer}>
          {!isWhitelabel && <><NextImg src={darkTheme ? '/img/footer-poweredby-white.svg': '/img/footer-poweredby-grey.svg'}/>
            <div className={styles.text} >{t('page-footer-powered')}</div></>}
        </div>
        {/* <div className={styles.languagesSwitcher}>
          {
            <Select
              id={'language'}
              onChange={handleLanguageChange}
              defaultValue={router.locale}
              className={styles.selectInput}
            >
              {
                router.locales.map(locale => {
                  const country = locale === 'en' ? 'US' : locale.toUpperCase();
                  return <Option key={locale} value={locale}><span>{countryFlag(country)}</span> {languages(locale)}</Option>;
                })
              }
            </Select>
          }
        </div> */}
      </div>
    </div>
  );
});
