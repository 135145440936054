import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Slider, Tooltip} from 'antd';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {Modal, Radio, Select} from 'antd';
import {faXmark, faLockKeyhole, faMoneySimpleFromBracket} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {faCalendar, faCircleDollar, faLayerGroup, faCircleInfo, faCircleThreeQuarters, faCloudArrowUp, faCreditCard, faFeather, faGift, faRotateRight} from '@fortawesome/pro-regular-svg-icons';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import {ChangePaymentMethodModal} from '@/components/dashboard/billing/change-payment-method';
import {PaymentMethodModal} from '@/components/dashboard/billing/payment-method-modal';
import {numberWithCommas} from '@/utils/number';
import {type QuotaList} from './topupModal';
import CustomNumberInput from '../CustomNumberInput';

type optionsType = 'quantity'
| 'additionally_included'
| 'current_quota'
| 'used_card'
| 'recurring_payment'
| 'price'
| 'AI_generation'
| 'hyperdrive'
|'allowances'
;

interface TopUpVisibilityProps {
  visibility: boolean;
  price: number;
  title: string;
  options: optionsType[];
  consumed: number | null;
  total: number | null;
  recurring: string;
  quotaKey: string;
  desc: string;
  nextPaymentAt?: string | null;
  buttonTxt: string;
  step?: number | null;
  localSeo?: boolean;
  overAllQuota?: number;
  multiplesOfStep?: boolean;
  unitIsMultipleOfStep?: boolean;
  recurringIsOptional?: boolean;
  recurringValue?: string;
  setRecurringValue?: Dispatch<SetStateAction<'no' | 'yes'>>;
  wholeCredits?: boolean;
  showSlider?: boolean;
  siteDesc?: string;
  multiQuotaList?: QuotaList[];
  isUnits?: boolean;
  showTotal?: boolean;
  quotaPoints?: number;
  showNextCharge?: boolean;
  minLimit?: number;
  maxLimit?: number;
  buttonLabel?: string;
  priceLabel?: string;
  showPriceOnButton?: boolean;
  showDiscount?: boolean;
}

interface Props {
  topUpBulkVisibility: TopUpVisibilityProps;
  setTopUpBulkVisibility: (value: any) => void;
}

export const TopupModalV2 = observer(({topUpBulkVisibility, setTopUpBulkVisibility}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {isUnits=true, showTotal=true, showNextCharge=true, showPriceOnButton = true} = topUpBulkVisibility;
  const {
    invoices: {getPaymentMethod},
    ottoV2Store: {ottoQuotaTopup, loadingOttoQuotaTopup, isTopUpHyperDrive, setTopupHyperDrive, findPriceByRange},
    settings: {customer: {getCustomerQuota, profile: {quotaUtilization}}},
    contentOptimizer: {aiOutline: {addTopUpQuote, loadingAiQuota}},
  } = useStore('');
  const [changePrimaryCard, setChangePrimaryCard] = useState(false);
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [addQuota, setAddQuota] = useState(topUpBulkVisibility.step || 1);
  const initialPrice = findPriceByRange(1, topUpBulkVisibility?.quotaKey)?.pricePerUnit;

  useEffect(() => {
    setAddQuota(topUpBulkVisibility.step || 1);
  }, [topUpBulkVisibility.step]);
  const unit = (topUpBulkVisibility.unitIsMultipleOfStep && topUpBulkVisibility.multiplesOfStep) ? addQuota / topUpBulkVisibility.step : addQuota;

  const getUnitOrOne = value => Math.max(1, Math.round(value));

  const quotaRange = topUpBulkVisibility?.quotaKey?.includes('quota_ca_allowed_otto_ai_suggestions') ? quotaUtilization?.ca?.allowedOttoV2Projects?.total : (topUpBulkVisibility.step ? getUnitOrOne(addQuota / topUpBulkVisibility.step) : addQuota);

  const priceObj = findPriceByRange(quotaRange, topUpBulkVisibility?.quotaKey);

  const getPrice = () => {
    const currentPricePerUnit = priceObj?.pricePerUnit;

    const totalPrice = currentPricePerUnit;

    const initialTotalPrice = initialPrice;

    const savedAmount = initialTotalPrice - totalPrice;
    const percentageSaved = (savedAmount / initialTotalPrice) * 100;

    return {
      currentPrice: totalPrice,
      percentageSaved: Math.floor(percentageSaved),
    };
  };

  const handleMultiQuotaChange = value => {
    const selectedQuota = topUpBulkVisibility?.multiQuotaList.find(item => item?.quotaKey === value);
    setTopUpBulkVisibility({...topUpBulkVisibility, quotaKey: selectedQuota?.quotaKey, price: selectedQuota?.pricePerUnit, quotaPoints: selectedQuota?.quotaPoints});
  };
  const currentPrice = getPrice()?.currentPrice ? getPrice()?.currentPrice * (topUpBulkVisibility.step || 1) : '';

  const sections = {
    allowances: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faLayerGroup} />
        <div className='txt' style={{whiteSpace: 'nowrap'}}>Monthly allowance</div>
        <StyledSelect
          onChange={handleMultiQuotaChange}
          value={topUpBulkVisibility?.quotaKey ? topUpBulkVisibility?.quotaKey : 'Select allowance'}
          getPopupContainer={node => node}
        >
          {topUpBulkVisibility?.multiQuotaList?.map((item, index) => (
            <Select.Option value={item.quotaKey} key={index}>
              {item?.quotaPoints} keywords
            </Select.Option>
          ))}
        </StyledSelect>
      </div>
    </SectionContainer>,
    additionally_included: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faGift} />
        <div className='txt'>Additionally included:</div>
        <div className='total-value'>+3 Cloud Stacks per month</div>
      </div>
    </SectionContainer>,
    current_quota: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faCircleThreeQuarters} />
        <div className='txt'>Current quota:</div>
        <div className='total-value'>{topUpBulkVisibility.consumed || 0} used of {topUpBulkVisibility.title == 'Cloud Stacks Quota' || topUpBulkVisibility.title == 'Full Article Generation Quota' ? topUpBulkVisibility?.overAllQuota || 0 : topUpBulkVisibility?.total || 0}</div>
      </div>
    </SectionContainer>,
    used_card: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faCreditCard} />
        <div className='txt'>Used card:</div>
        <div className='total-value'>Ending in {getPaymentMethod?.last4 || '-'}</div>
        <div className='edit' onClick={() => {
          setChangePrimaryCard(true);
          setTopUpBulkVisibility({...topUpBulkVisibility, visibility: false});
        }} ><FontAwesomeIcon icon={faPen} /> Edit</div>
      </div>
    </SectionContainer>,
    recurring_payment: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faRotateRight} />
        <div className='txt'>Recurring payment:</div>
        {topUpBulkVisibility.recurringIsOptional && typeof topUpBulkVisibility.setRecurringValue === 'function' ? (
          <RadioGroup
            options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
            value={topUpBulkVisibility.recurringValue}
            onChange={e => topUpBulkVisibility.setRecurringValue(e?.target?.value)}
          />
        ) : (
          <div className='total-value'>{topUpBulkVisibility.recurring === 'monthly' ? 'Yes' : 'No'}</div>
        )}
      </div>
    </SectionContainer>,
    price: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faCircleDollar} />
        <div className='txt'>Price:</div>
        <div className='total-value'>{`$${currentPrice || topUpBulkVisibility.price} ${topUpBulkVisibility.priceLabel || topUpBulkVisibility.desc}`}</div>
      </div>
      {topUpBulkVisibility?.showDiscount && getPrice()?.percentageSaved ? <div className='discount-container'>{Math.abs(getPrice()?.percentageSaved)}% discount applied</div> : ''}
    </SectionContainer>,
    AI_generation: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faFeather} />
        <div className='txt'>AI Generation:</div>
        <div className='total-value'>{priceObj?.extra['quotaCaAllowedOttoAiSuggestions'] ? (addQuota * priceObj?.extra['quotaCaAllowedOttoAiSuggestions'] + ' points') : '-'}</div>
      </div>
    </SectionContainer>,
    hyperdrive: <SectionContainer>
      <div className='section-container'>
        <StyledFontAwesomeIcon icon={faCloudArrowUp} />
        <div className='txt'>HyperDrive:</div>
        <div className='total-value'>{priceObj?.extra['quotaCaHyperdriveCredits'] ? Math.round(addQuota * priceObj?.extra['quotaCaHyperdriveCredits']) + ' credits' : '-'}</div>
      </div>
    </SectionContainer>,
  };

  const onClose = () => {
    setAddQuota(topUpBulkVisibility.step || 1);
    typeof topUpBulkVisibility.setRecurringValue === 'function' && topUpBulkVisibility.setRecurringValue('no');
    setTopUpBulkVisibility({...topUpBulkVisibility, visibility: false});
    setTopupHyperDrive(false);
  };

  const closeModal = () => {
    setChangePaymentMethod(false);
    setChangePrimaryCard(false);
    setTopUpBulkVisibility({...topUpBulkVisibility, visibility: true});
  };
  const closeNewCardModal = () => {
    setChangePaymentMethod(false);
    setChangePrimaryCard(false);
    setTopUpBulkVisibility({...topUpBulkVisibility, visibility: true});
  };

  const setChangePaymentMethodModal = () => {
    setChangePrimaryCard(false);
    setChangePaymentMethod(true);
  };

  const handleButtonDisabled = () => {
    if (topUpBulkVisibility.localSeo) {
      return addQuota % 1000 !== 0 || addQuota <= 0;
    } else if (topUpBulkVisibility?.multiplesOfStep) {
      return addQuota % topUpBulkVisibility?.step !== 0 || addQuota <= 0;
    } else return addQuota <= 0;
  };

  const getDisabledButtonTooltip = () => {
    if (topUpBulkVisibility?.multiplesOfStep && addQuota % topUpBulkVisibility?.step !== 0 && handleButtonDisabled()) return `Quantity must be multiple of ${topUpBulkVisibility.step}`;
  };

  const discount = (initialPrice * addQuota) - (getPrice()?.currentPrice * addQuota);
  return (
    <>
      <ModalStyle
        centered
        closable={false}
        footer={null}
        mask={true}
        visible={topUpBulkVisibility.visibility}
        onCancel={onClose}
        zIndex={9999}
      >
        <div className='container' style={{position: 'relative'}}>
          <IconWrapper>
            <FontAwesomeIcon icon={faMoneySimpleFromBracket} color='#A25AD1' />
          </IconWrapper>
          <PopoverTitle>{topUpBulkVisibility?.showSlider ? topUpBulkVisibility.title : `Top up: ${topUpBulkVisibility.title}`}</PopoverTitle>
          <Desc>{topUpBulkVisibility?.siteDesc}</Desc>
          {isUnits && <PriceSection isLocalSeo={topUpBulkVisibility.localSeo || topUpBulkVisibility.multiplesOfStep}>
            <CustomNumberInput
              value={addQuota}
              setValue={setAddQuota}
              step={topUpBulkVisibility.step}
              hasError={handleButtonDisabled()}
              min={topUpBulkVisibility.minLimit}
              max={topUpBulkVisibility.maxLimit}
              rootClassName='inputContainer'
            />
            <div>
              {topUpBulkVisibility?.buttonLabel || topUpBulkVisibility.buttonTxt}{addQuota > 1 ? 's' : ''}
            </div>
            {handleButtonDisabled() ? <div className='validation-message'>
              {(addQuota <= 0 || addQuota < topUpBulkVisibility?.step) ? `Minimum ${topUpBulkVisibility?.step || 1}` :
                (addQuota % topUpBulkVisibility?.step !== 0) ? `Must be multiple of ${topUpBulkVisibility?.step}` :
                  ''}
            </div> : <></>}
          </PriceSection>}
          {isTopUpHyperDrive ? <>
            <div className='pinkBanner'> <FontAwesomeIcon icon={faCircleInfo} />{`You don't have enough HyperDrive Credits.`}</div>
            <div className='blueBanner'> <FontAwesomeIcon icon={faCircleInfo} />{`1 Cloud Stack costs 20 HyperDrive Credits.`}</div>
          </> : !topUpBulkVisibility?.showSlider?
            <Divider /> : ''}
          {topUpBulkVisibility?.showSlider ?
            <StyledSlider
              marks={topUpBulkVisibility.step > 50 ? {} : {
                3: '3',
                9: '9',
                24: '24',
              }}
              max={(topUpBulkVisibility.step || 1) * 50}
              min={topUpBulkVisibility.step || 1}
              step={topUpBulkVisibility.step || 1}
              value={addQuota}
              onChange={value => setAddQuota(Number(value))}
            /> :
            ''}
          <div>
            {topUpBulkVisibility.options.map(option => sections[option])}
            {!topUpBulkVisibility?.showSlider && topUpBulkVisibility.recurring === 'monthly' && showNextCharge ? <SectionContainer>
              <div className='section-container'>
                <FontAwesomeIcon icon={faCalendar} />
                <div className='txt'>Next charge:</div>
                <div className='total-value'>{topUpBulkVisibility?.nextPaymentAt ? moment(topUpBulkVisibility?.nextPaymentAt).format('MMM DD, YYYY') : '-'}</div>
              </div>
            </SectionContainer> : ''}
            {showTotal && <SectionContainer>
              <div className='section-container'>
                <StyledFontAwesomeIcon icon={faCreditCard} />
                <div className='txt'>Total:</div>
                <div className='total-value'>
                  ${topUpBulkVisibility?.showSlider ? numberWithCommas(Number(((getPrice()?.currentPrice * addQuota) || 0)?.toFixed(2))): topUpBulkVisibility.localSeo ? numberWithCommas(addQuota * topUpBulkVisibility.price / 1000) || '-' : numberWithCommas(Number((unit * topUpBulkVisibility.price)?.toFixed(4))) || '-'} USD
                </div>
              </div>
              {topUpBulkVisibility?.showDiscount && discount ? <div className='saved-prices'>
                <span style={{position: 'relative', top: -'5px'}}>*</span>${Math.round(discount)} saved with discount
              </div> : ''}
            </SectionContainer>}
          </div>
          <PopoverFooterContainer>
            <Tooltip title={topUpBulkVisibility.localSeo && addQuota % 1000 !== 0 && 'Quantity must be multiple of 1000' || getDisabledButtonTooltip()}>
              <span className='button-wrapper'>
                <ButtonWrapper
                  style={{whiteSpace: 'normal'}}
                  color='green'
                  onClick={async () => {
                    if (topUpBulkVisibility.quotaKey === 'allowed_ai_premium_content_generation') {
                      await addTopUpQuote({units: addQuota});
                    } else {
                      await ottoQuotaTopup({
                        data: [{
                          ...(isUnits && {units: ((topUpBulkVisibility.unitIsMultipleOfStep && topUpBulkVisibility.multiplesOfStep && !topUpBulkVisibility?.wholeCredits) ? Math.round(addQuota / topUpBulkVisibility.step) : addQuota)}),
                          quota_key: topUpBulkVisibility?.showSlider ? priceObj?.quotaKey : topUpBulkVisibility.quotaKey,
                          quota_type: topUpBulkVisibility.recurring,
                        }],
                      });
                    }
                    await getCustomerQuota();
                    onClose();
                  }}
                  disabled={handleButtonDisabled()}
                  loading={loadingOttoQuotaTopup || loadingAiQuota}
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faLockKeyhole}
                    style={{
                      height: '16px',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />{' '}
                  <ButtonText>
                   Get {topUpBulkVisibility?.quotaPoints ? numberWithCommas(topUpBulkVisibility?.quotaPoints) : numberWithCommas(addQuota)} {topUpBulkVisibility.buttonTxt || ''}{addQuota > 1 && 's'}
                    {!showPriceOnButton ? '' : ` for $${topUpBulkVisibility.localSeo ? numberWithCommas(addQuota * topUpBulkVisibility.price / 1000) :
                      numberWithCommas(Number((unit * (currentPrice || topUpBulkVisibility.price))?.toFixed(4)))}`}{' '}
                  </ButtonText>
                </ButtonWrapper>
              </span>
            </Tooltip>
            <ButtonWrapperCancel
              color='white'
              onClick={async () => {
                onClose();
              }}
            >
              Cancel
            </ButtonWrapperCancel>
          </PopoverFooterContainer>
          <div style={{position: 'absolute', top: -26, right: -47}}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{height: '20px', color: '#FFFFFF', cursor: 'pointer'}}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      </ModalStyle>
      {changePrimaryCard && <ChangePaymentMethodModal isOpen={changePrimaryCard} handleChangePayment={setChangePaymentMethodModal} setIsOpen={closeModal} getCustomerQuota={getCustomerQuota} />}
      {changePaymentMethod && <PaymentMethodModal isOpen={changePaymentMethod} setIsOpen={closeNewCardModal} getCustomerQuota={getCustomerQuota} />}
    </>
  );
});

const ModalStyle = styled(Modal)`
width: 430px !important;
.ant-modal-content {
  border-radius: 12px !important;
}
  .pinkBanner{
  background-color: #ff00002e;
    padding: 7px;
    border-radius: 9px;
    margin: 20px 0 10px 0;
    svg{
    color: #ff0000b0;
    margin: 0 10px;
    }
  }
      .blueBanner{
  background-color: #011fa921;
    padding: 7px;
    border-radius: 9px;
    margin-bottom: 20px;
    svg{
    color: #455fd3;
    margin: 0 10px;
    }
  }
@media screen and (max-width: 768px) {
  .container {
    padding: 0 !important;
  }
}
`;

const ButtonText = styled.span`
    margin-left: 7px;
    margin-top: 2px;
`;

const RadioGroup = styled(Radio.Group)`
  .ant-radio-checked .ant-radio-inner{
    border-color: #2D6CCA;
    background-color: #2D6CCA;
  }
  .ant-radio-inner:after {
    background: #fff !important;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    margin-left: -7px;
  }
  .ant-radio-inner {
    border-radius:50%; 
    border-color: #CDCDCD;
    background-color: #F9F9FB;
  }
`;

const IconWrapper = styled.div`
display: flex;
svg {
  width: 56px;
  height: 42px;
  border-radius: 100%;
}
`;
const PopoverTitle = styled.div`
 margin-top: 16px;
 font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: left;
  color: #121212;
`;

const PopoverFooterContainer = styled.div`
  display: flex;
  margin-top: 6px;
  margin-bottom: 6px;
  flex-wrap: wrap;
  column-gap: 5px;
  @media screen and (max-width: 860px) {
    row-gap: 10px;
    .button-wrapper {
      display: inline-block;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;

const ButtonWrapper = styled(Button)`
min-width: 212px;
height: 44px;
background: linear-gradient(85.22deg, #7B42CA 0%, #A25AD1 100%) !important;
display: flex;
justify-content: center;
border-radius: 10px;
margin: 0px 0 0px 0px !important;
border: 1px solid transparent;
padding: 7px 0px;
color: white;
font-size: 14px;
font-weight: 600;
border:none;
align-items: center;
padding-left: 10px;
padding-right: 10px;
&:hover, &:focus{
  background: linear-gradient(85.22deg, #7B42CA 0%, #A25AD1 100%) !important;
  color: white;
  border:none;
}
.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
  opacity: 0.5;
  color: white  !important;
  border:none !important;
}
`;


const ButtonWrapperCancel = styled.button`
background-color: white;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 7px 20px;
    color: black;
    height: auto;
    border:none;
    cursor: pointer;
    &:hover{
      background-color: white;
      color: black;
      border:none;
    }
    &:focus {
      color: black;
    }
    @media screen and (max-width: 860px) {
      width: 100%;
      border: 1px solid #d1c5c5 !important;
    }
`;

const PriceSection = styled.div<{isLocalSeo?: boolean}>`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 11px;
  position: relative;
  .price {
    font-family: Inter;
    font-size: 42px;
    font-weight: 700;
    line-height: 50.83px;
    text-align: left;
    color: #121212;
  }
  .currency {
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #121212;
  }
  .per-quota {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #4E5156;
  }
  .validation-message {
    position: absolute;
    top: 34px;
    left: 0px;
    color: #ff4d4f;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
  }
  .inputContainer {
    ${p => p?.isLocalSeo ? `
      width: 90px;
      min-width: 90px;
    ` : 'width:  72px;'}
    &:hover {
      input {
        border-color: #40a9ff;
      }
    }
    input {
      padding-right: 11px !important;
      border-radius: 8px !important;
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #D9D9D9;
  margin: 18px 0px;
`;

const SectionContainer = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  .section-container {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 16px;
  .txt {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
  }
  .total-value {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    color: #121212;
  }
  .total {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #4E5156;
  }
  p {
    margin-bottom: 0px !important;
  }
  .edit {
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #2D6CCA;
    svg {
      color: #2D6CCA;
    }
  }
}
.discount-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 23px;
  border-radius: 20px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  background: #2AC15521;
  color: #18923B;
}

.saved-prices {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: right;
  color: #A3A4A4;
} 
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: #4E5156;
`;

const StyledSlider = styled(Slider)`
margin-top: 20px;
margin-bottom: 25px;
.ant-slider {
  margin: 0px;
}
.ant-slider-rail {
  background: #E2E8EE !important;
  height: 10px !important;
  border-radius: 23px !important;
}
.ant-slider-step {
  height: 10px !important;
}
.ant-slider-track {
  height: 10px !important;
  background: linear-gradient(85.22deg, #7B42CA 0%, #A25AD1 100%) !important;
  border-radius: 23px !important;
}
.ant-slider-handle {
  width: 22px;
  height: 22px;
  background: #6C2EA7;
  box-shadow: 0 0 0 5px rgba(127, 78, 173, 0.4) !important;
  border: 0px !important;
  margin-top: -4px !important;
  position: relative;
  background-image: url('/icons/sliderArrows.png');
  background-repeat: no-repeat;
  background-position: center;
}
.ant-slider-mark {
  display: none;
} 
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 4px;
  height: 12px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  cursor: pointer;
}
.ant-slider-dot-active {
  border: 0px;
  width: 2px;
  height: 12px;
  background-color: rgba(226, 232, 238, 0.30);
}
`;

const Desc = styled.div`
  margin-top: 8px;
  margin-bottom: 11px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #4E5156;
`;

const StyledSelect = styled(Select)`
  width: 225px;
  .ant-select-selector {
    border-radius: 8px !important;
  }
`;
