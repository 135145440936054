import styles from './style.module.scss';
import classnames from 'classnames';
import React from 'react';

export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input: React.FC<InputProps> = ({className, ...props}) => {
  return (
    <input
      {...props}
      className={classnames(
        styles.input,
        'common-component-input',
        className,
      )}
      value={props.value || ''}
    />
  );
};

export type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const Textarea: React.FC<TextareaProps> = ({className, children, ...props}) => {
  return (
    <textarea
      {...props}
      className={classnames(
        styles.textarea,
        'common-component-textarea',
        className,
      )}
    >{children}</textarea>
  );
};

export {Input, Textarea};
