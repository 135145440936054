import {isNil} from 'lodash';
import {checked, unChecked, greenCircleCheck, grayCirclePlus, blueOutlineCircle} from '@/components/dashboard/pages/otto-page-detail/Utils/helper-func';

export const changeFreddyScriptId = (surveyId: string) => {
  // if we do not have surveyId we stop the logic
  if (isNil(surveyId)) return;

  // if currently active survey is euqyal to passed survey, we stapped the logic to prevent having double survey event
  if (window['ffWidgetId'] === surveyId) return;

  // we remove old survey script
  const elem = document.querySelector(`script[src='https://freddyfeedback.com/widget/freddyfeedback.js']`);
  if (elem) elem.parentNode.removeChild(elem);

  // we inject new survey script and assign the id to it
  window['ffWidgetId'] = surveyId;
  const ffWidgetScript = document.createElement('script');
  ffWidgetScript.type = 'text/javascript';
  ffWidgetScript.src = 'https://freddyfeedback.com/widget/freddyfeedback.js';
  window['ffWidgetScript'] = ffWidgetScript;
  document.head.appendChild(ffWidgetScript);
};

const freddySurveyStorageKey = 'MEMOIZED_SURVEYS';

export const checkStorageForEvents = (userId: number, event: string) => {
  const existingSurveys = localStorage.getItem(freddySurveyStorageKey);

  if (!existingSurveys) return false;

  const parsedSurveys = JSON.parse(existingSurveys);

  const existingUser = parsedSurveys[userId];

  if (!existingUser) return false;

  const existingEvent = parsedSurveys[userId]?.includes(event);

  if (!existingEvent) return false;

  return true;
};

export const memoSurveyEventInLocalStorage = (userId: number, event: string) => {
  const saveNewServeyData = (userId: number, event: string) => {
    const prepData = JSON.stringify({
      [userId]: [event],
    });

    localStorage.setItem(freddySurveyStorageKey, prepData);
  };

  const existingSurveys = localStorage.getItem(freddySurveyStorageKey);

  if (!existingSurveys) {
    saveNewServeyData(userId, event);
    return;
  }

  const parsedSurveys = JSON.parse(existingSurveys);

  const updatedData = {
    ...parsedSurveys,
    [userId]: [...(parsedSurveys[userId]?.filter(existingEvent => existingEvent !== event) ?? []), event],
  };

  const strigifiedSurveys = JSON.stringify(updatedData);

  localStorage.setItem(freddySurveyStorageKey, strigifiedSurveys);
};

export const triggerFreddySurvey = async (userId: number, userEmail: string, survey: {id: string; event: string}) => {
  // to prevent page from breaking if there is no script injected at all
  // this is just double check
  // script is being initially injected in _app.tsx
  if (!window['freddyWidget']) return;

  // we are checking the localstorage if this event was already fired for this user by user id
  if (checkStorageForEvents(userId, survey.event)) return;

  // we are re-injecting survey script and assigning a new id
  changeFreddyScriptId(survey.id);

  // we are triggering the survey
  // it has to be in settimeout as window object property change will not be visible immediattely
  await Promise.resolve(setTimeout(() => {
    window['freddyWidget'].show({
      custom_fields: {
        email: userEmail,
      },
    });
  }, 1000));

  // we are memorizing current event into localstorage so it doesnt fire again
  memoSurveyEventInLocalStorage(userId, survey.event);
};


// here we will store all possible surveys
export const FREDDY_EVENT_SCA_ARTICLE_CREATED = {
  id: '1af0e7e8-b66c-469e-bab2-5247026f419f',
  event: 'FREDDY_ID_SCA_ARTICLE_CREATED',
};
export const FREDDY_EVENT_ONBOARDING_FINALIZED = {
  id: '488d9a31-dcfd-4d6c-a268-169dbdcb8115',
  event: 'FREDDY_ID_ONBOARDING_FINALIZED',
};
export const convertToHierarchy = (data: any, isInContentTopicMap: boolean) => {
  const hierarchy = {
    name: data?.contentTopic || data?.path,
    children: [],
  };

  let counter = 1;

  if (Array.isArray(data?.formattedData)) {
    data?.formattedData.forEach(item => {
      const selected = item?.keywords?.filter(keyword =>
        keyword?.titles?.every(title => title?.isSelected === true),
      );
      const category = {
        name: item?.name,
        isSelected: selected?.length === item?.keywords?.length,
        symbol: isInContentTopicMap ?
          selected?.length === item?.keywords?.length ? `image://${greenCircleCheck}` : `image://${blueOutlineCircle}` :
          selected?.length === item?.keywords?.length ? `image://${checked}` : `image://${unChecked}`,
        value: item?.name,
        itemStyle: {
          color: '#D7D7D7',
          borderColor: '#D7D7D7',
        },
        lineStyle: {
          ...(isInContentTopicMap && {width: 2}),
          color: '#D7D7D7',
        },
        children: [],
      };
      if (Array.isArray(item?.keywords)) {
        item?.keywords.forEach(keyword => {
          if (Array.isArray(keyword?.titles)) {
            if (keyword?.titles.length) {
              const selectedKeyword = keyword?.titles?.every(title => title?.isSelected === true);
              const keywordObj = {
                name: keyword?.name || '',
                isSelected: selectedKeyword,
                symbol: isInContentTopicMap ?
                  selectedKeyword ? `image://${greenCircleCheck}` : `image://${blueOutlineCircle}` :
                  selectedKeyword ? `image://${checked}` : `image://${unChecked}`,
                value: keyword?.name || '',
                itemStyle: {
                  color: '#D7D7D7',
                  borderColor: '#D7D7D7',
                },
                lineStyle: {
                  ...(isInContentTopicMap && {width: 2}),
                  color: '#D7D7D7',
                },
                children: [],
              };

              keyword?.titles.forEach(title => {
                const titleNode = {
                  name: `${keyword?.name|| ''}`,
                  id: data?.id,
                  aiGenerationStatus: title?.aiGenerationStatus,
                  isSelected: title?.isSelected ?? false,
                  titleUuid: title?.titleUuid,
                  pageUuid: title?.pageUuid,
                  wpPublishedAtUrl: title?.wpPublishedAtUrl,
                  titleName: `${title?.name || ''}`,
                  value: counter,
                  searchIntent: keyword?.searchIntent?.filter((value, index) => keyword?.searchIntent.indexOf(value) === index) ?? [],
                  symbol: isInContentTopicMap ?
                    title?.isSelected ? `image://${greenCircleCheck}` : `image://${grayCirclePlus}` :
                    title?.isSelected ? `image://${checked}` : `image://${unChecked}`,
                  itemStyle: {
                    color: title?.isSelected ? '#7F4EAD' : '#D7D7D7',
                    borderColor: title?.isSelected ? '#7F4EAD' : '#D7D7D7',
                  },
                  lineStyle: {
                    ...(isInContentTopicMap && {width: 2}),
                    color: title?.isSelected ? isInContentTopicMap ? '#A5DEB5' : '#7F4EAD' : '#D7D7D7',
                  },
                };
                keywordObj.children.push(titleNode);
                counter++;
              });

              category.children.push(keywordObj);
            }
          }
        });
      }
      if (category?.children?.length) {
        hierarchy.children.push(category);
      }
    });
  }
  return hierarchy;
};

export function generateScriptTag(uuid) {
  const scriptString = `
    var script = document.createElement("script");
    script.setAttribute("nowprocket", "");
    script.setAttribute("nitro-exclude", "");
    script.src = "https://dashboard.linkgraph.com/scripts/dynamic_optimization_alpha.js";
    script.dataset.uuid = "${uuid}";
    script.id = "sa-dynamic-optimization-loader";
    document.head.appendChild(script);
  `;

  const base64String = btoa(scriptString);

  const scriptTag = `
    <script nowprocket nitro-exclude type="text/javascript" id="sa-dynamic-optimization" 
            data-uuid="${uuid}" 
            src="data:text/javascript;base64,${base64String}">
    </script>
  `;

  return scriptTag;
}
