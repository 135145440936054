import {types, flow} from 'mobx-state-tree';
import {SITE_AUDITOR_API} from '@/api/SiteAuditor';

export const topKeywordsModel = types.model({
  trend: types.maybeNull(types.array(types.number)),
  keyword: types.maybeNull(types.string),
  sv: types.maybeNull(types.number),
});

export const siteModel = types.model({
  topKeywords: types.array(topKeywordsModel),
  totalKeywords: types.maybeNull(types.number),
  totalEstTraffic: types.maybeNull(types.number),
  totalEstTrafficValue: types.maybeNull(types.number),
});

export const topCompetitorsKeywordsModel = types.model({
  pageNumber: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  count: types.maybeNull(types.number),
  results: types.array(types.model({
    sv: types.maybeNull(types.number),
    cpc: types.maybeNull(types.number),
    trend: types.maybeNull(types.array(types.number)),
    domain: types.maybeNull(types.string),
    keyword: types.maybeNull(types.string),
    position: types.maybeNull(types.number),
    estTrafficValueUsd: types.maybeNull(types.number),
    topCompetitorsPositions: types.array(types.model({
      domain: types.maybeNull(types.string),
      position: types.maybeNull(types.number),
    })),
  })),
});

export const topTwoCompetitorKeywordsOverlapModel = types.model({
  sets: types.array(types.string),
  size: types.maybeNull(types.number),
});

export const sitelinksModel = types.model({
  link: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

export const adsModel = types.model({
  link: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  sitelinks: types.maybeNull(types.array(sitelinksModel)),
  description: types.maybeNull(types.string),
  trackingLink: types.maybeNull(types.string),
  blockPosition: types.maybeNull(types.string),
  displayedLink: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  trafficSharePct: types.maybeNull(types.number),
  keywordSv: types.maybeNull(types.number),
  keywordCpc: types.maybeNull(types.number),
  keywordHistSv: types.maybeNull(types.array(types.number)),
  estTraffic: types.maybeNull(types.number),
  estTrafficValueUsd: types.maybeNull(types.number),
});

export const competitorsModel = types.model({
  keywords: types.maybeNull(types.array(types.string)),
  domain: types.maybeNull(types.string),
  commonKeywords: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  totalEstTraffic: types.maybeNull(types.number),
  totalEstTrafficValue: types.maybeNull(types.number),
  ads: types.maybeNull(types.array(adsModel)),
});

export const ppcCompetitorsModel = types.model({
  site: types.maybeNull(siteModel),
  topCompetitorsKeywords: topCompetitorsKeywordsModel,
  topTwoCompetitorKeywordsOverlap: types.maybeNull(types.array(topTwoCompetitorKeywordsOverlapModel)),
  competitors: types.array(competitorsModel),
});

export const LabelValue = types.model({
  label: types.string,
  value: types.number,
});

export const ByLinkLocation = types.model({
  external: types.maybeNull(types.array(LabelValue)),
  internal: types.maybeNull(types.array(LabelValue)),
});

export const DepthItem = types.model({
  0: types.maybeNull(types.number),
  1: types.maybeNull(types.number),
  2: types.maybeNull(types.number),
  3: types.maybeNull(types.number),
  4: types.maybeNull(types.number),
  5: types.maybeNull(types.number),
  orphaned: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
});

export const IndexStatusItem = types.model({
  indexable: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
  nonIndexable: types.maybeNull(types.number),
});

export const StatusCodeItem = types.model({
  'label': types.maybeNull(types.string),
  '2Xx': types.maybeNull(types.number),
  '3Xx': types.maybeNull(types.number),
  '4Xx': types.maybeNull(types.number),
  '5Xx': types.maybeNull(types.number),
});

export const ByPageRank = types.model({
  depth: types.maybeNull(types.array(DepthItem)),
  indexStatus: types.maybeNull(types.array(IndexStatusItem)),
  statusCode: types.maybeNull(types.array(StatusCodeItem)),
});

export const ByStatusCode = types.model({
  internal: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
  external: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
});

export const ByType = types.model({
  external: types.maybeNull(types.array(LabelValue)),
  internal: types.maybeNull(types.array(LabelValue)),
});

export const Header = types.model({
  brokenExternal: types.maybeNull(types.number),
  brokenInternal: types.maybeNull(types.number),
  external: types.maybeNull(types.number),
  internal: types.maybeNull(types.number),
});

export const InternalAndExternalLinksByCrawlDepth = types.maybeNull(types.array(types.model({
  label: types.maybeNull(types.string),
  internal: types.maybeNull(types.number),
  external: types.maybeNull(types.number),
})));

export const InternalPagesByIncomingDofollowLinks = types.maybeNull(types.array(LabelValue));

export const LinksReport = types.model({
  byLinkLocation: types.maybeNull(ByLinkLocation),
  byPageRank: types.maybeNull(ByPageRank),
  byStatusCode: types.maybeNull(ByStatusCode),
  byType: types.maybeNull(ByType),
  header: types.maybeNull(Header),
  internalAndExternalLinksByCrawlDepth: types.maybeNull(InternalAndExternalLinksByCrawlDepth),
  internalPagesByIncomingDofollowLinks: types.maybeNull(InternalPagesByIncomingDofollowLinks),
  topExternalAnchors: types.maybeNull(types.array(LabelValue)),
  topInternalAnchors: types.maybeNull(types.array(LabelValue)),
  topPagesByInternalLinks: types.maybeNull(types.array(LabelValue)),
  topSubdomainsByInternalLinks: types.maybeNull(types.array(LabelValue)),
});

export const LinksReportStore = types.model({
  loading: types.boolean,
  linksReport: types.maybeNull(LinksReport),
}).actions(self => {
  const loadLinksReport = flow(function* (siteProperty: string) {
    self.loading = true;
    try {
      const response = yield SITE_AUDITOR_API.getLinksReport(siteProperty);
      self.linksReport = response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });


  return {loadLinksReport};
});

export function initLinksReportStore() {
  return LinksReportStore.create({
    loading: true,
    linksReport: {
      byLinkLocation: null,
      byPageRank: null,
      byStatusCode: {
        internal: [],
        external: [],
      },
      byType: null,
      header: null,
      internalAndExternalLinksByCrawlDepth: [],
      internalPagesByIncomingDofollowLinks: [],
      topExternalAnchors: [],
      topInternalAnchors: [],
      topPagesByInternalLinks: [],
      topSubdomainsByInternalLinks: [],
    },
  });
}
