import {Gain} from '../delta/gain';
import {Loss} from '../delta/loss';
import numeral from 'numeral';
import ReactEcharts from 'echarts-for-react';
import {graphic} from 'echarts';
import classnames from 'classnames';

import styles from './index.module.scss';
import React from 'react';


export interface InfoCardProps {
  title: string;
  value: number;
  difference?: number;
  chartData?: number[];
  valueFormat?: string;
  customClassName?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  value,
  difference,
  chartData,
  valueFormat = '0a',
  customClassName,
}) => {
  const formattedValue = numeral(value).format(valueFormat);
  const formattedDifference = numeral(Math.abs(difference)).format('0,0');

  return (
    <div className={classnames(customClassName, styles.infoCard)}>
      <div>
        <h4>{title}</h4>
        <span className={styles.value}>{formattedValue}</span>
        {difference && (difference > 0 ? (
          <Gain className={styles.differenceOverride}>
            {formattedDifference}
          </Gain>) : (
          <Loss useChevron={true} className={styles.differenceOverride}>
            {formattedDifference}
          </Loss>))}
        {chartData?.length && <ReactEcharts
          style={{
            maxHeight: '55px',
          }}
          option={{
            xAxis: {
              type: 'category',
              axisLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              axisTick: {
                show: false,
              },
            },
            series: [
              {
                type: 'line',
                data: [10, 11, 32, 7, 19, 20, 30],
                symbol: 'none',
                smooth: false,
                itemStyle: {
                  opacity: 0,
                },
                lineStyle: {
                  color: '#478593',
                  width: 1,
                },
                areaStyle: {
                  color: new graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#478593',
                  }, {
                    offset: 0.9,
                    color: '#ffe',
                  }]) as any,
                },
              },
            ],
            grid: {
              containLabel: false,
              top: '10%',
              bottom: '20%',
            },
          }}
        />}
      </div>
    </div>
  );
};
