

export const metaClickEvents = (eName, params) => {
  if (window['fbq']) {
    window['fbq']('track', eName, {...params});
  }
};

export const ottoWhitelabelName = ottoName => {
  if (ottoName == null || ottoName == undefined || ottoName?.trim() == '') {
    return 'OTTO';
  }
  return ottoName;
};

export const whitelabelBrandColor = (isWhitelabel, brandColor) => {
  if (isWhitelabel) {
    if (brandColor == null || brandColor == undefined || brandColor?.trim() == '') {
      return '#7f4ead';
    }
    return brandColor;
  }
};

export const convertToErrorString = obj => {
  let errorString = '';

  function traverse(obj) {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        errorString += `${obj[key].join(', ')}\n`;
      } else if (typeof obj[key] === 'object') {
        traverse(obj[key]);
      }
    }
  }

  traverse(obj);
  return errorString.trim();
};

export const getStandAloneLocations = (locations, searchValue) => {
  return locations?.filter(location => {
    if (searchValue) {
      if (location?.businessName && location?.usedInStandalone && location?.businessName?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
        return location;
      }
    } else {
      if (location?.businessName && location?.usedInStandalone) {
        return location;
      }
    }
  });
};

export const adjustLightness = (hex, amount) => {
  if (hex?.length === 4) {
    hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
  }
  let r = parseInt(hex?.slice(1, 3), 16);
  let g = parseInt(hex?.slice(3, 5), 16);
  let b = parseInt(hex?.slice(5, 7), 16);
  r = Math.max(0, Math.min(255, r + amount));
  g = Math.max(0, Math.min(255, g + amount));
  b = Math.max(0, Math.min(255, b + amount));
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

export const hexToRgba = (hex, alpha) => {
  let r = 0; let g = 0; let b = 0;
  if (hex?.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex?.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getLastUpdated = (lastUpdatedDate: string) => {
  let label;
  let difference;
  const now = new Date().getTime();
  const lastUpdatedAt = new Date(lastUpdatedDate).getTime();
  difference = (now - lastUpdatedAt) / 1000;

  if (difference < 60) {
    label = 'seconds ago';
  }

  if (difference >= 60 && difference < 3600) {
    difference = Math.round(difference / 60);
    if (difference < 2) {
      label = 'minute ago';
    } else {
      label = 'minutes ago';
    }
  }

  if (difference >= 3600 && difference < 86400) {
    difference = Math.round(difference / 3600);
    if (difference < 2) {
      label = 'hour ago';
    } else {
      label = 'hours ago';
    }
  }

  if (difference >= 86400) {
    difference = Math.round(difference / 86400);
    if (difference < 2) {
      label = 'day ago';
    } else {
      label = 'days ago';
    }
  }

  return difference ? `${Math.round(difference)} ${label} ` : '';
};
