import React, {useState, useRef, useEffect} from 'react';
import styles from '@/components/dashboard/backlinks/out_domains/style.module.scss';
import {TableStyled} from '../../../../components/dashboard/pages/home-page-main/components/table-styled';
import {observer} from 'mobx-react';
import {Drawer} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import {FiltersV2} from '@/components/common-components/v2/filtersV2';
// import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faLockKeyhole, faArrowTurnDownRight} from '@fortawesome/pro-duotone-svg-icons';
import {formattNumber, numberWithCommas} from '@/utils/number';
import {Tooltip} from 'antd';
import {useRouter} from 'next/router';
// import {faArrowUpFromBracket} from '@fortawesome/pro-regular-svg-icons';
import {getLocation} from '@/utils/string';
import moment from 'moment';
import {NewPagination} from '@/components/common-components/components/newPagination';
import {ExportModal} from '../../../../components/dashboard/pages/site-explorer-page/detail-page/components/exportModal';

export const OutDomainsDrawer: React.FC = observer(() => {
  const {
    competitorResearcherV2:
    {
      // setCompetitorResearcherId,
      overview: {
        outDomainsVisible,
        outDomainsDomain,
        exportRecords,
        getoutLinksData,
        // getBacklinksChartData,
        loadingOutLinksDetail,
        setOutLinksParams,
        allOutLinksParams,
        updateOutLinksFilter,
        setOutDomainsVisible}}} = useStore('');
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');

  const [exportVisible, setExportVisible] = useState(false);
  const [activeSort, setActiveSort] = useState('');
  const router = useRouter();
  const id = `${router?.query?.id || ''}`;
  const isPublic = router?.pathname?.includes('public');
  const tableRef = useRef(null);

  const handleTableChange = async (pagination, filters, sorter) => {
    if (!isPublic) {
      const {column, order} = sorter;
      const {sortFieldName} = column || {};
      const sorterKey = order ? `${order == 'descend' ? '-' : ''}${sortFieldName}` : '';
      setActiveSort(sorterKey);
      setOutLinksParams({
        ...allOutLinksParams,
      }, id ? id : null, 'outgoing_links');
    }
  };

  const handleExport = data => {
    exportRecords(id, 'outgoing_links', data, [], []);
  };

  const onClose = () => {
    setOutDomainsVisible(false, '');
  };

  const handlePagination = (page, pageSize) => {
    setOutLinksParams({
      ...allOutLinksParams,
      page: page,
      page_size: pageSize,
    }, id ? id : null, 'outgoing_links');
  };

  // const openExportModal = () => {
  //   if (isPublic) {
  //     setAuthModal(true, false);
  //   } else {
  //     setExportVisible(true);
  //   }
  // };

  const getTagsColor =tag => {
    if (tag == 'New') {
      return '#1FAC47';
    } else if (tag == 'noFollow') {
      return '#FF8536';
    } else if (tag == 'Lost') {
      return '#F44343';
    } else {
      return '#4E5156';
    }
  };

  const domainDetailColumns = [
    {
      title: 'AUTHORITY',
      key: 'pa',
      dataIndex: 'pa',
      align: 'left' as 'left',
      sortFieldName: 'page_ascore',
      width: 110,
      showSorterTooltip: {
        title: (
          <div>Authority Score is our proprietary metric used to measure overall quality of URL and influence on SEO. The score is based on the number of backlinks, referring domains, organic search traffic, and other data.</div>
        ),
      },
      sorter: true,
      sortOrder: activeSort?.includes('page_ascore') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      render: (_, data) => {
        if (data?.sourceTitle !== 'emptyRow') {
          return (
            <div>{data?.pageAscore || '-'}</div>
          );
        }
      },
    },
    {
      title: <Tooltip title='The title and URL of the page that points to an analyzed domain.'>SOURCE PAGE</Tooltip>,
      key: 'sorucepage',
      dataIndex: 'sorucepage',
      align: 'left' as 'left',
      width: 200,
      render: (_, data) => {
        if (data?.sourceTitle !== 'emptyRow') {
          return (
            <div>
              <EllipsisTitle>{data?.sourceTitle || '-'}</EllipsisTitle>
              <div style={{width: '300px'}}>
                <FontAwesomeIcon icon={faLockKeyhole} color='#a3a4a4' fontSize='12px'/>
                &nbsp;<AnchorText href={data?.sourceUrl} target='_blank' rel='noopener'>{data?.sourceUrl || ''} <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#a3a4a4' fontSize='12px'/></AnchorText>
              </div>
              <FlexWrapper align='center' justifyContent='flex-start'>
                {data?.redirect && <TagsWrapper>{'Redirect'}</TagsWrapper>}
                {data?.lang && <TagsWrapper>{data?.lang?.toUpperCase()}</TagsWrapper>}
                {data?.mobile && <TagsWrapper>{'Mobile friendly'}</TagsWrapper>}
              </FlexWrapper>
            </div>
          );
        }
      },
    },
    {

      title: <Tooltip title='The total number of links on the source URL pointing to other websites.'><div style={{marginInline: '8px'}}>EXT LINKS</div></Tooltip>,
      key: 'extLinks',
      dataIndex: 'extLinks',
      align: 'center' as 'center',
      width: 80,
      render: (_, data) => {
        if (data?.sourceTitle !== 'emptyRow') {
          return (
            <div>{formattNumber(numberWithCommas(data?.externalLinkNum)) || 0}</div>
          );
        }
      },
    },
    {
      title: <Tooltip title='The total number of links on the source URL pointing to other pages within the same website.'><div style={{marginInline: '8px'}}>INT LINKS</div></Tooltip>,
      key: 'extLinks',
      dataIndex: 'extLinks',
      align: 'center' as 'center',
      width: 80,
      rowSpan: 10,
      render: (_, data) => {
        if (data?.sourceTitle !== 'emptyRow') {
          return (
            <div>{formattNumber(numberWithCommas(data?.internalLinkNum)) || 0}</div>
          );
        }
      },
    },
    {
      title: <Tooltip title={`The clickable text of a backlink and URL or group of URLs this link points to. A backlink is considered new if less than a month has passed since ${isWhitelabel ? getLocation(location) : 'Linkgraph'} first discovered it. A lost backlink is displayed in the report for a few months after the date that
       ${isWhitelabel ? getLocation(location) : 'Linkgraph'} was first not able to find it.`}>
        <div>ANCHOR & <br/> TARGET URL</div></Tooltip>,
      key: 'extLinks',
      dataIndex: 'extLinks',
      align: 'left' as 'left',
      width: 200,
      render: (_, data) => {
        return (
          <>
            <EllipsisTitle>{data?.anchor || ''}</EllipsisTitle>
            <div style={{wordBreak: 'break-all', width: '190px'}}>
              {data?.responseCode !== 200 ? <><FontAwesomeIcon icon={faArrowTurnDownRight} color='#a3a4a4'/>&nbsp;<TagsWrapper>{data?.responseCode}</TagsWrapper></> : ''}<FontAwesomeIcon icon={faLockKeyhole} color='#a3a4a4' fontSize='12px'/>
            &nbsp;<AnchorText href={data?.targetUrl} target='_blank' rel='noopener'>{data?.targetUrl || ''} <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#a3a4a4' fontSize='12px'/></AnchorText>

            </div>
            <BreakWrapper>
              {data?.text && <TagsWrapper background={getTagsColor(data?.text && 'Text')}>{'Text'}</TagsWrapper>}
              {data?.redirect && <TagsWrapper background={getTagsColor(data?.redirect && 'Redirect')}>{'Redirct'}</TagsWrapper>}
              {data?.nofollow && <TagsWrapper background={getTagsColor(data?.nofollow && 'noFollow')}>{'Nofollow'}</TagsWrapper>}
              {data?.sidewide && <TagsWrapper background={getTagsColor(data?.sidewide && 'Sidewide')}>{'Sidewide'}</TagsWrapper>}
              {data?.sponsored && <TagsWrapper background={getTagsColor(data?.sponsored && 'Sponsored')}>{'sponsored'}</TagsWrapper>}
              {data?.newlink && <TagsWrapper background={getTagsColor(data?.newlink && 'New')}>{'New'}</TagsWrapper>}
              {data?.lostlink && <TagsWrapper background={getTagsColor(data?.lostlink && 'Lost')}>{'Lost'}</TagsWrapper>}
            </BreakWrapper>
          </>
        );
      },
    },
    {
      title: <span style={{paddingLeft: '5px'}}>{'FIRST SEEN'}</span>,
      key: 'firstSeen',
      dataIndex: 'firstSeen',
      sortFieldName: 'first_seen',
      sorter: true,
      showSorterTooltip: {
        title: (
          <div>{`The date that ${isWhitelabel ? getLocation(location) : 'Linkgraph'} first discovered this backlink. A date in green means that a backlink is new, i.e., less than a month has passed since it was first discovered by ${isWhitelabel ? getLocation(location) : 'Linkgraph'}.`}</div>
        ),
      },
      sortOrder: activeSort?.includes('first_seen') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      align: 'left' as 'left',
      width: 100,
      render: (_, data) => {
        return (
          <div style={{paddingLeft: '5px', color: data?.newlink ? '#1FAC47' : '#121212'}}>{moment(data?.firstSeen)?.format('DD MMM,  YYYY') || '-'}</div>
        );
      },
    },
    {
      title: <span style={{paddingLeft: '5px'}}>{'LAST SEEN'}</span>,
      key: 'lastSeen',
      dataIndex: 'lastSeen',
      sortFieldName: 'last_seen',
      sorter: true,
      showSorterTooltip: {
        title: (
          <div>{`The date that ${isWhitelabel ? getLocation(location) : 'Linkgraph'} last discovered this backlink. A date in red means that a backlink is lost.`}</div>
        ),
      },
      sortOrder: activeSort?.includes('last_seen') ? `${activeSort?.startsWith('-') ? 'descend' : 'ascend'}` : false,
      align: 'left' as 'left',
      width: 100,
      render: (_, data) => {
        return (
          <div style={{paddingLeft: '5px', color: data?.lostlink ? '#F44343' : '#121212'}}>{moment(data?.lastSeen)?.format('DD MMM,  YYYY') || '-'}</div>
        );
      },
    },
  ];

  useEffect(() => {
    updateOutLinksFilter(id);
  }, [outDomainsVisible == true && outDomainsDomain]);

  return (
    <Drawer
      // style={{overflow: 'auto'}}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={outDomainsVisible}
      width={'88%'}
      contentWrapperStyle={{maxWidth: '1400px'}}
      headerStyle={{background: '#FFFFFF'}}
      className={styles.upgradeDrawer + ' drawerCloseIcon'}
      closeIcon={<CloseOutlined className={'closeIcon'} style={outDomainsVisible ? {display: 'block'} : {display: 'none'}}/>}
      bodyStyle={{backgroundColor: '#FFFFFF', paddingTop: '11px'}}
    >
      <h1 style={{margin: 0}}>Outbound links to</h1>
      <AnchorText href={(!/^(https?:)?\/\//i.test(outDomainsDomain)) ? 'https://' + outDomainsDomain : outDomainsDomain} target='_blank' rel='noopener'>{outDomainsDomain || ''}&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize='12px'/></AnchorText>
      <WhiteCard width='100%' ref={tableRef}>
        <div style={{paddingLeft: '20px'}}>
          <FiltersV2
            // onChange={handleFilterChange}
            inMoreBtnIds={[7, 8, 9, 10, 11]}
            dataCountText={<div>Outbound links: <b>{numberWithCommas(getoutLinksData?.totalCount)}</b></div>}
            // exportButton={
            //   <Button
            //     color='textGrayDark'
            //     variant='ghost'
            //     style={{height: '28px', border: '1px solid #CDCDCD'}}
            //     onClick={openExportModal}
            //   ><FontAwesomeIcon icon={faArrowUpFromBracket} style={{fontSize: '12px'}}/>&nbsp;Export</Button>
            // }
          />
        </div>
        <StyledTable
          columns={domainDetailColumns as any[]}
          dataSource={getoutLinksData?.results}
          loading={loadingOutLinksDetail}
          loadingRows={20}
          pagination={false}
          hideSorters={isPublic}
          rowClassName={(_, index) => isPublic && index > 14 ? 'bluredRowStyle' : ''}
          onChange={handleTableChange}
          fixedTheader={true}
          // scroll={{x: 1500, y: 'auto'}}
        /><NewPagination
          onChange={handlePagination}
          total={getoutLinksData?.totalCount}
          pageSize={allOutLinksParams?.page_size}
          current={allOutLinksParams?.page}
        />
        <ExportModal visible={exportVisible} allowCustomRows={false} setVisible={setExportVisible} totalRows={getoutLinksData?.totalCount} handleSubmit={handleExport}/>
      </WhiteCard>
    </Drawer>
  );
});

export const AnchorText = styled.a`
  width: 200px;
  overflow: hidden;
  white-space: normal;
  font-size: 14px !important;
  color: #2D6CCA !important;
`;
export const EllipsisTitle = styled.div`
   width: auto;
   overflow: hidden;
   white-space: normal;
`;
export const FlexWrapper = styled.div<{align?: string; justifyContent?: string}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};
`;
export const TagsWrapper = styled.span<{background?: string}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 3px;
  margin-Right: 5px;
  width: fit-content;
  height: 19px;
  background: ${p=> p?.background ? `${p?.background}26` : '#E8E8E8'};
  border-radius: 6px;
  color: ${p=> p?.background ? p?.background : '#4E5156'};
  font-size: 12px;
`;
export const BreakWrapper = styled.div`
 width: 200px;
 display: flex;
 align-items: center;
 flex-wrap: wrap;
 overflow: hidden;
 gap: 5px;
`;
export const WhiteCard = styled.div<{width?: string; margin?: string; position?: string}>`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  width: ${p => p.width ?? '50%'};
  ${p => p?.margin && `margin: ${p?.margin}`};
  ${p => p?.position && `position: ${p?.position}`};
  margin-top: 33px;
`;
export const BlueText = styled.div<{color?: string; cursor?: string}>`
 color: ${p=> p?.color ? p?.color : '#2D6CCA'};
 line-height: 17px;
 font-size: 14px;
 cursor: ${p=> p?.cursor ? p?.cursor : 'pointer'};
`;
const StyledTable = styled(TableStyled)`
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table {
        .ant-table-container {
          .ant-table-content {
            height: 38em !important;
          }
        }
      }
    }
  }
`;
