/* eslint-disable no-undef */
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass, faTrash, faXmark, faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import {faArrowLeft, faArrowRight} from '@fortawesome/pro-solid-svg-icons';
import {ErrorDiv, StyledIssuesTable} from '../style';
import {Button, Empty, Input, Select, Spin, Tooltip} from 'antd';
import {faSquarePlus, faSquareCheck} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {truncate} from '@/utils/string';
import {urlWithHttp} from '@/constants/regex';
import {LoadingOutlined} from '@ant-design/icons';
import {getSingleUrlParam} from '@/utils/url';
import {notification as notificationV2} from '@/utils/notification-v2';
import {openUrl} from '@/utils/router';
import {getOttoTablePageSize} from '@/api/common-utils';


interface BulkPagesModalProps {
  openBulkPagesModal: boolean;
  setOpenBulkPagesModal: Dispatch<SetStateAction<boolean>>;
  addBulkPagesFrom: string;
  currentIssueType: string;
}
const initialErrorState = {
  'url': {
    message: 'Please add url.',
    state: false,
  },
  'keyword': {
    message: 'Please add keywords.',
    state: false,
  },
};
const initialStyleProperties = {
  'modalStyles': {
    left: '0px',
    borderRadius: '12px',
  },
  'gscListStyles': {
    right: '0px',
    showList: false,
  },
};

export const BulkPagesModal: React.FC<BulkPagesModalProps> = observer(({openBulkPagesModal, setOpenBulkPagesModal, addBulkPagesFrom, currentIssueType}) => {
  const {ottoV2Store: {
    loadIssueTableData,
    getBulkPages,
    getOttoUrls,
    addBulkPage,
    removeBulkPage,
    editBulkPage,
    getOttoV2Project,
    issueTypeArray,
    createBulkPages,
    creatingBulkPages,
    addRemoveAllPages,
  },
  siteAuditorV2: {setGscOpenModalValue, siteAuditSelectedProperty},
  gsc: {googleAnalytics: {setGaDataModal, gaDataModal}, criteria},
  } = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const [styleProperties, setStyleProperties] = useState(initialStyleProperties);
  const [errorStates, setErrorStates] = useState(initialErrorState);
  const [openAddPage, setOpenAddPage] = useState(false);
  const [topPages, setTopPages] = useState([]);
  const [editInTable, setEditInTable] = useState<any>(-1);
  const [dopdownList, setDopdownList] = useState([]);
  const [dropDownLength, setDropDownLength] = useState<any>(null);
  const [urlValue, setUrlValue] = useState('');
  const [keywords, setKeywords] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');

  const projectId = (siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || getOttoV2Project?.siteAuditId))?.toString();
  useEffect(() => {
    if (openBulkPagesModal && !searchedValue) {
      const topPagesArray = getOttoV2Project?.topPages || [];
      const updatedTopPagesArray = topPagesArray?.map(page => ({...page, addedToTable: false}));
      setTopPages(updatedTopPagesArray);
    } else if (searchedValue) {
      const topPagesArray = getOttoV2Project?.topPages || [];
      const updatedTopPagesArray = topPagesArray?.map(page => ({...page, addedToTable: false}));
      const result = updatedTopPagesArray?.filter(obj => obj?.url?.toLowerCase()?.includes(searchedValue?.toLowerCase()));
      setTopPages(result);
    } else {
      const topPagesArray = getOttoV2Project?.topPages || [];
      const updatedTopPagesArray = topPagesArray?.map(page => ({...page, addedToTable: false}));
      setTopPages(updatedTopPagesArray);
    }
  }, [openBulkPagesModal, topPages]);

  useEffect(() => {
    if (topPages && topPages.length > 0 && getBulkPages && getBulkPages.length > 0) {
      const updatedTopPages = topPages.map(topPage => {
        const foundBulkPage = getBulkPages.find(bulkPage => bulkPage.url === topPage.url);
        return foundBulkPage ? {...topPage, addedToTable: true} : {...topPage, addedToTable: false};
      });
      setTopPages(updatedTopPages);
      setDopdownList(updatedTopPages);
    }
  }, [getBulkPages, topPages]);

  const onEdit = index => {
    setEditInTable(`edit-record-${index}`);
  };
  const onSelectUrl = () => {
    setEditInTable(-1);
  };
  const onSearchUrl = (value: string) => {
    if (value) {
      const result = topPages?.filter(obj => obj?.url?.toLowerCase()?.includes(value.toLowerCase()));
      setDopdownList(result);
      setDropDownLength(result?.length);
    } else {
      const topPagesArray = getOttoV2Project?.topPages || [];
      const updatedTopPagesArray = topPagesArray?.map(page => ({...page, addedToTable: false}));
      setDopdownList(updatedTopPagesArray);
      setDropDownLength(updatedTopPagesArray?.length);
    }
  };

  const onRemoveAllDataFromTable = () => {
    addRemoveAllPages([], 'removeAll');
    setErrorMessage('');
  };

  const headerColumns = [
    {
      title: (
        <TitleWithButton>
        PAGE URL<div className='add-page-btn' onClick={() => {
            setOpenAddPage(true); setErrorMessage('');
          }}>+ Add Page</div>
        </TitleWithButton>),
      key: 'page_url',
      dataIndex: 'page_url',
      width: 670,
      render: () => {
        return (<></>);
      },
    },
    {
      title: 'TARGET KEYWORDS',
      key: 'target_keywords',
      dataIndex: 'target_keywords',
      width: 250,
      className: 'header-verticle-middle',
      render: () => {
        return (<></>);
      },
    },
    {
      title: <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faTrash} color='#A3A4A4' fontSize={16} onClick={onRemoveAllDataFromTable}/>
      </div>,
      key: 'delete',
      dataIndex: 'delete',
      width: 24,
      className: 'verticle-middle header-verticle-middle',
      render: () => {
        return (<></>);
      },
    },
  ];
  const tableColumns = [
    {
      title: '',
      key: 'page_url',
      dataIndex: 'page_url',
      width: '100%',
      onCell: (_, index) => ({
        colSpan: (editInTable !== -1 && editInTable === `edit-record-${index}`) ? 2 : 1,
      }),
      render: (_, record, index) => {
        return (
          (editInTable !== -1 && editInTable === `edit-record-${index}`) ? (
            <div>
              <StyledUrlSelect
                id={'this-dropdown'}
                showSearch
                defaultOpen
                placeholder='Search url'
                value={selectedValue}
                onChange={onSelectUrl}
                dropdownClassName='url-dropdown'
                dropdownStyle={{boxShadow: '0px 4px 11px 0px #00000047', borderRadius: '12px'}}
                onSearch={onSearchUrl}
                autoFocus
                onBlur={() => setEditInTable(-1)}
                dropdownRender={menu => (
                  <React.Fragment>
                    <SelectHeader>{dropDownLength ?? dopdownList?.length} matches found in Google Search Console</SelectHeader>
                    <MenuStyle>
                      {menu}
                    </MenuStyle>
                  </React.Fragment>
                )}
              >
                {dopdownList?.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={item?.url?.toLowerCase()}
                    disabled={getBulkPages?.some(obj => obj?.url === item?.url)}>
                    <UrlItem onClick={() => editBulkPage(record?.url, item)}>
                      <div className='url'>
                        <div className='left-text'>
                          {truncate(item?.url, 85)}
                        </div>
                        {getBulkPages?.some(obj => obj?.url === item?.url) ? (
                          <div className='already-exist'>Already added</div>) :
                          <></>}
                      </div>
                      <div>
                        <Tooltip title={item?.keywords?.join(', ')} placement='top'>
                          {truncate(item?.keywords?.join(', '), 30)}
                        </Tooltip>
                      </div>
                    </UrlItem>
                  </Select.Option>
                ))}
              </StyledUrlSelect>
              {errorMessage && (
                <div style={{color: 'red', textAlign: 'left', fontSize: '12px', lineHeight: '0px'}}>
                  <div>{errorMessage}</div>
                </div>
              )}
            </div>
          ) : (
            <PageUrlWrapper>
              <Tooltip title={record?.url} placement='top'>
                <span style={{display: 'inline-block', flexGrow: 1}} onClick={() => {
                  onEdit(index); setSelectedValue(record?.url);
                }}>{truncate(record?.url, 96)}</span>
              </Tooltip>
              <FontAwesomeIcon onClick={() => openUrl(record?.url, '_blank')} className='url-icon' fontSize={14} color='#A3A4A4' icon={faArrowUpRightFromSquare} />
            </PageUrlWrapper>
          )
        );
      },
    },

    {
      title: '',
      key: 'target_keywords',
      dataIndex: 'target_keywords',
      onCell: (_, index) => ({
        colSpan: (editInTable!== -1 && editInTable === `edit-record-${index}`) ? 0 : 1,
      }),
      width: 250,
      render: (_, record) => {
        const keywords = record?.keywords?.join(', ');
        return (
          <Tooltip title={keywords} placement='top'>
            <TragetKeywordWrapper>{truncate(keywords, 28)}</TragetKeywordWrapper>
          </Tooltip>
        );
      },
    },
    {
      title: '',
      key: 'delete',
      dataIndex: 'delete',
      width: 24,
      className: 'verticle-middle header-verticle-middle',
      render: (_, record, index) => {
        return (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faTrash} color='#A3A4A4' fontSize={16} onClick={() => {
              removeBulkPage(record);
              if (editInTable === `edit-record-${index}`) {
                setEditInTable(-1);
                setErrorMessage('');
              }
            }}/>
          </div>
        );
      },
    },
  ];
  const handleOpenGSCModal = isGaObject => {
    const connectedData = isGaObject?.connectedData;
    criteria.updateCriteria({
      ...criteria,
      property: connectedData?.gscDetails?.propertyUrl || '',
    });
    setGscOpenModalValue(true, 'GSC Setting', true, projectId, false);
    setGaDataModal({
      ...gaDataModal,
      visible: true,
      siteUrl: connectedData?.gscDetails?.propertyUrl || '',
      gaConnected: isGaObject?.isGscConnected,
      settingId: null,
      settingsEnabled: false,
      hasSiteGaCredentials: null,
      saProjectId: projectId,
      countryCode: connectedData?.gscDetails?.countryCode || '',
      fromOttoUuidParam: `?uuid=${uuid}`,
    });
  };
  const onCollapseList = () => {
    setStyleProperties({...styleProperties,
      modalStyles: {...styleProperties['modalStyles'], left: '0px', borderRadius: '12px'},
      gscListStyles: {...styleProperties['gscListStyles'], right: '0px', showList: false},
    });
  };
  const onExpandList = () => {
    if (!getOttoV2Project?.connectedData?.isGscConnected) {
      setOpenBulkPagesModal(false);
      handleOpenGSCModal(getOttoV2Project);
    } else {
      setStyleProperties({...styleProperties,
        modalStyles: {...styleProperties['modalStyles'], left: '-240px', borderRadius: '12px 0px 0px 12px'},
        gscListStyles: {...styleProperties['gscListStyles'], right: '-490px', showList: true},
      });
    }
  };
  const onAddRemoveItem = (method, page) => {
    if (method === 'add') {
      addBulkPage(page);
    } else if (method === 'remove') {
      removeBulkPage(page);
    }
  };
  const onSearchTopPages = (value: string) => {
    setSearchedValue(value);
    const topPagesArray = getOttoV2Project?.topPages || [];
    const updatedTopPagesArray = topPagesArray?.map(page => ({...page, addedToTable: false}));
    if (value) {
      const result = updatedTopPagesArray?.filter(obj => obj?.url?.toLowerCase()?.includes(value?.toLowerCase()));
      setTopPages(result);
    } else {
      setTopPages(updatedTopPagesArray);
    }
  };

  function hasValidSlashes(str) {
    const regex = /\/{2,}/;
    return !regex.test(str);
  }

  function isUrlValid(userInput) {
    // eslint-disable-next-line no-useless-escape
    const res = userInput.match(/^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
    if (res == null) {
      return false;
    } else {
      return true;
    }
  }

  const onAddPage = () => {
    setErrorMessage('');
    const updatedUrlValue = urlValue?.startsWith('/') ? urlValue?.slice(1) : urlValue;
    const updatedUrl = `${domainName}/${updatedUrlValue}`?.startsWith('http://') ? `${domainName}/${updatedUrlValue}` : `https://${domainName}/${updatedUrlValue}`;
    if (!hasValidSlashes(urlValue)) {
      setErrorStates({...errorStates,
        url: {...errorStates['url'], message: 'Enter a valid url.', state: !hasValidSlashes(urlValue)},
      });
      return;
    }
    if (updatedUrl.trim() && keywords.trim()) {
      const seperatedKeywords = keywords?.split(',').map(keyword => keyword.trim()).filter(keyword => keyword !== '');
      if (isUrlValid(updatedUrl) && (seperatedKeywords?.length <= 3)) {
        const record = {url: updatedUrl, keywords: seperatedKeywords, isSelected: false, addedToTable: true};
        addBulkPage(record);
        setErrorStates(initialErrorState);
        setUrlValue('');
        setKeywords('');
      } else {
        setErrorStates({...errorStates,
          url: {...errorStates['url'], message: 'Enter valid url.', state: !urlWithHttp.test(updatedUrl)},
          keyword: {...errorStates['keyword'], message: 'Max 3 keywords support.', state: seperatedKeywords?.length > 3},
        });
        return;
      }
    } else {
      setErrorStates({...errorStates,
        url: {...errorStates['url'], state: !updatedUrl.trim() ? true : false},
        keyword: {...errorStates['keyword'], state: !keywords.trim() ? true : false},
      });
      return;
    }
    setOpenAddPage(false);
  };
  const onCancelAddPage = () => {
    setUrlValue('');
    setKeywords('');
    setErrorStates(initialErrorState);
    setOpenAddPage(false);
  };
  const onCloseModal = () => {
    setOpenBulkPagesModal(false);
    setStyleProperties(initialStyleProperties);
    setErrorStates(initialErrorState);
    setDopdownList([]);
    setDropDownLength(null);
    setOpenAddPage(false);
    setTopPages([]);
    setEditInTable(-1);
    setUrlValue('');
    setKeywords('');
    addRemoveAllPages(topPages, 'removeAll');
  };

  const filteredOttoUrls = getOttoUrls ? getOttoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === currentIssueType);
  const onUpdatePages = async () => {
    const missingUrl = getBulkPages?.some((record, index) => `edit-record-${index}` == editInTable);
    if (missingUrl) {
      setErrorMessage('Please select a URL.');
      return;
    }
    const pagesArray = getBulkPages?.map(item => ({
      path: item?.url,
      target_keywords: item?.keywords?.join(', '),
    }));
    const payload = {
      pages: pagesArray,
    };

    try {
      if (['nlp_terms', 'nlp_faq'].includes(addBulkPagesFrom)) {
        await createBulkPages(payload);
      }
      onCloseModal();
      notificationV2.success('Success', 'Pages created successfully.', 'Close');
      const params = {
        uuid,
        issue_type: currentIssueType,
        page_size: getOttoTablePageSize(currentIssueType),
        page: url?.page,
        is_loading: false,
        otto_project: getOttoV2Project?.id,
      };
      await loadIssueTableData(params, false);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseLeave = () => {
    if (!isFocused) {
      setIsHovered(false);
    }
  };

  const addonStyle = {
    color: '#A3A4A4',
    transition: 'color 0.3s ease',
  };

  return (
    <StyledBulkPagesModal
      width={!styleProperties?.gscListStyles?.showList ? '1110px' : '50%'}
      closable={false}
      onClose={() => {}}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
      visible={openBulkPagesModal}
      style={{left: styleProperties['modalStyles'].left, transition: '0.5s'}}
      borderRadius={styleProperties['modalStyles'].borderRadius}
      closeIconRightPosition={styleProperties['gscListStyles'].showList}
    >
      <StyledModalHeader>
        <div className='modal-heading'>Add & Manage Pages
          {styleProperties['gscListStyles'].showList || !getOttoV2Project?.connectedData?.gscDetails?.propertyUrl ? <></> : <div className='view-button-wrapper' onClick={onExpandList}>View {topPages?.length} GSC Page{topPages?.length <= 1 ? '' : 's'} Suggestion{topPages?.length <= 1 ? '' : 's'}
            <FontAwesomeIcon icon={faArrowRight} color='#2D6CCA' fontSize={16}/>
          </div>}
        </div>
        <div className='modal-description'>{getBulkPages?.length} page{getBulkPages?.length <= 1 ? '' : 's'} included in this table can be optimized for {addBulkPagesFrom === 'nlp_terms' ? 'Semantic Analysis' : 'Knowledge-Based Trust'} module.</div>
      </StyledModalHeader>
      <BodyWrapper isHovered={isHovered} isFocused={isFocused}>
        <TableWithoutBody
          columns={headerColumns}
          dataSource={[]}
        />
        {openAddPage? <div className='add-page-wrapper'>
          <div className='add-page-inputs'>
            <div style={{width: '70%'}}>
              <Tooltip title={urlValue.trim() && urlValue} placement='top'>
                <div>
                  <Input
                    addonBefore={<span
                      style={addonStyle}
                    >
                      {domainName}/
                    </span>}
                    className='url-input'
                    value={urlValue}
                    onChange={e => setUrlValue(e.target.value)}
                    onFocus={() => setIsFocused(true)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={handleMouseLeave}
                    onBlur={() => {
                      setIsFocused(false);
                      setIsHovered(false);
                    }}
                  />
                </div>
              </Tooltip>
              {errorStates.url.state ? <ErrorDiv style={{width: '100%'}}>{errorStates.url.message}</ErrorDiv> : <div style={{width: '100%'}}>{''}</div>}
            </div>
            <div style={{width: '30%'}}>
              <Tooltip title={keywords.trim() && keywords} placement='top'>
                <Input
                  className='keywords-input'
                  placeholder='Up to 3 keywords, divided by commas'
                  value={keywords}
                  onChange={e => setKeywords(e?.target?.value)}
                />
              </Tooltip>
              {errorStates.keyword.state ? <ErrorDiv>{errorStates.keyword.message}</ErrorDiv> : <></>}
            </div>
          </div>
          <div className='add-page-btns'>
            {/* <div className='errors-wrapper'>

            </div> */}
            <div className='buttons-wrapper'>
              <Button className='add-button' onClick={onAddPage}>Add</Button>
              <div className='cancel-button' onClick={()=> {
                onCancelAddPage(); setErrorMessage('');
              }}>
                <FontAwesomeIcon icon={faXmark} color='#fff' fontSize={20}/>
              </div>
            </div>
          </div>
        </div> : <></>}
        <TableWithoutHeader
          columns={tableColumns}
          dataSource={getBulkPages}
          pagination={false}
          scroll={{y: 520}}
        />
      </BodyWrapper>
      <StyledModalFooter>
        <ButtonComponent
          color='blue'
          style='solid'
          type='button'
          className='update-button'
          onClick={onUpdatePages}
          disabled={!getBulkPages?.length || creatingBulkPages}
        >
          Update pages
          {creatingBulkPages ? <Spin indicator={<LoadingOutlined style={{fontSize: '18px', color: 'white', marginLeft: 5}} spin />} /> : <></>}
        </ButtonComponent>
        <div className='cancel-btn' onClick={onCloseModal}>
          Cancel
        </div>
      </StyledModalFooter>
      <GscListWrapper style={{right: styleProperties['gscListStyles'].right}}>
        <div className='close-btn' onClick={onCollapseList}>
          <FontAwesomeIcon icon={faArrowLeft} color='#4E5156' fontSize={16}/>{topPages?.length} Page{topPages?.length <= 1 ? '' : 's'} found in GSC
        </div>
        <div className='list-wrapper'>
          <div className='search'>
            <Input
              onChange={e => onSearchTopPages(e?.target?.value)}
              placeholder='Search...'
              value={searchedValue}
              prefix={<FontAwesomeIcon icon={faMagnifyingGlass} color='#A3A4A4'fontSize={14}/>}
            />
          </div>
          <div className='list-content'>
            <div className='header'>
              {(!topPages?.length || (topPages?.some(i => !i?.addedToTable))) ? (
                <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSquarePlus} color='#A3A4A4' fontSize={16} onClick={() => addRemoveAllPages(topPages)}/>
              ) :
                (
                  <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSquareCheck} color='#1FAC47' fontSize={16} onClick={onRemoveAllDataFromTable}/>
                )} URL
            </div>
            {topPages?.length ? <div className='list'>
              {topPages?.map((page, index) => (
                <div className='list-item' key={index}>
                  {page?.addedToTable ?
                    <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSquareCheck} color='#1FAC47' fontSize={16} onClick={() => onAddRemoveItem('remove', page)}/> :
                    <FontAwesomeIcon style={{cursor: 'pointer'}} icon={faSquarePlus} color='#A3A4A4' fontSize={16} onClick={() => onAddRemoveItem('add', page)}/>}
                  {page?.url}
                </div>
              ))}
            </div> : <div style={{marginTop: '20px'}}><Empty/></div>}
          </div>
        </div>
      </GscListWrapper>
    </StyledBulkPagesModal>
  );
});

const StyledBulkPagesModal = styled(Modal)<{borderRadius: string; closeIconRightPosition: boolean}>`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #ffffff;
      padding: 25px !important;
      box-shadow: 6px 0px 10px 0px #00000008;
      position: relative;
      border-radius: ${p => p?.borderRadius} !important;
    }
    .rc-dialog-close {
      font-size: 21px;
      right: ${p => p?.closeIconRightPosition ? '-520px' : '-34px'};
      top: 0px;
      font-weight: 700;
      transition: 0.5s;
      color: #fff !important;
        &:hover {
          opacity: 1 !important;
        }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .view-button-wrapper {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 12px;
      color: #2D6CCA;
      cursor: pointer;
    }
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const StyledModalFooter = styled.div<{backFooter?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  .cancel-btn {
    width: ${p => p?.backFooter ? '' : '47px'};
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;
const TitleWithButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: auto;
  .add-page-btn {
    width: 87px;
    border-radius: 8px;
    padding: 6px 10px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color: #2D6CCA;
    background-color: rgba(45, 108, 202, 15%);
    cursor: pointer;
  }
`;
const BodyWrapper = styled.div<{isFocused?: boolean; isHovered?:boolean}>`
  margin-bottom: 25px;
  .add-page-wrapper {
    margin-top: 10px;
    width: 100%;
    .add-page-inputs {
      display: flex;
      margin-bottom: 8px;
      align-Items: 'flex-start';
      width: 100%;
      .url-input {
        border-radius: 6px 0px 0px 6px;
        min-width: 70%;
        .ant-input-group-addon {
          padding-right: 0px !important;
          border: ${p => (p.isFocused || p.isHovered) && '1px solid #40a9ff'},
          border-right: none !important; 
          border-top: ${({isFocused, isHovered}) => (isFocused || isHovered) ? '1px solid #40a9ff' : '1px solid #d9d9d9'};
          border-left: ${({isFocused, isHovered}) => (isFocused || isHovered) ? '1px solid #40a9ff' : '1px solid #d9d9d9'};
          border-bottom: ${({isFocused, isHovered}) => (isFocused || isHovered) ? '1px solid #40a9ff' : '1px solid #d9d9d9'};
          border-radius: 6px 0px 0px 6px;
        }
        .ant-input {
          padding-left: 0px;
          border-left: none;
        }
        .ant-input:focus {
          border-left: none;
          box-shadow: none;
        }
      }
      .keywords-input {
        border-radius: 0px 6px 6px 0px;
      }
    }
    .add-page-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .errors-wrapper {
        display: flex;
        align-items: center;
      }
      .buttons-wrapper {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        .add-button {
          border-radius: 6px;
          background-color: #2D6CCA;
          font-family: 'Inter', sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          border: 1px solid transparent;
          &:hover {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), #2D6CCA;
            border: 1px solid transparent;
          }
          &:focus {
            border: 1px solid transparent;
          }
        }
        .cancel-button{
          height: 30px;
          width: 30px;
          background-color: #4E5156;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.18), rgba(0, 0, 0, 0.18)), #4E5156;
          }
        }
      }
    }
  }
`;
const TableWithoutHeader = styled(StyledIssuesTable)`
.ant-spin-nested-loading {
    height: 100%; 
    overflow: auto; 
  }
  .ant-table {
    .ant-table-container {
      .ant-table-header {
        background-color: red !important;
        display: none;
      }
      .ant-table-body {
        min-height: 500px;
        table {
          tbody {
            .ant-table-measure-row {
              display: none;
            }
          }
          .ant-table-tbody > tr > td {
            border-bottom: none;
            &.verticle-middle {
              vertical-align: middle !important;
            }
          }
          .ant-table-tbody > tr > td:nth-child(1){
            padding: 6px 0px !important;
          }
          .ant-table-tbody > tr > td:nth-child(2){
            padding-left: 0px !important;
          }
        }
        .ant-table-placeholder {
          height: 400px;
        }
      }
    }
  }
`;
const TableWithoutBody = styled(TableWithoutHeader)`
   .ant-table {
    .ant-table-container {
      .ant-table-content {
        table {
          .ant-table-tbody {
            display: none !important;
          }
          .ant-table-thead {
            tr {
              th {
                &.header-verticle-middle {
                  vertical-align: middle !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;
const PageUrlWrapper = styled.div`
  border: 1px solid #D2D2D2;
  border-right: none;
  border-radius: 6px 0px 0px 6px;
  padding: 7px 11px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .url-icon {
    display: none;
    cursor: pointer;
  }
  &:after {
    position: absolute; 
    content: '';
    top: 9px;
    right: 0px;
    width: 1px;
    height: 18px;
    background-color: #D2D2D2;
  }
  &:hover {
    .url-icon {
      display: inline-block;
    }
  }
`;
const TragetKeywordWrapper = styled.div`
   border: 1px solid #D2D2D2;
   border-left: none;
   border-radius: 0px 6px 6px 0px;
   padding: 7px 11px;
   position: relative;
   &:before {
    position: absolute; 
    content: '';
    top: 0px;
    left: -1px;
    width: 1px;
    height: 4px;
    background-color: #D2D2D2;
   }
   &:after {
    position: absolute; 
    content: '';
    bottom: 0px;
    left: -1px;
    width: 1px;
    height: 4px;
    background-color: #D2D2D2;
   }
`;
const GscListWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0px;
  overflow: hidden;
  background-color: #F9F9FB;
  width: 490px;
  border-radius: 0px 12px 12px 0px;
  padding: 30px 12px 25px 14px;
  box-shadow: 0px 3px 2px 0px #0000000D;
  border: 1px 1px 1px 0px solid #E8E8E8;
  transition: 0.5s;
  z-index: -1;
  .close-btn {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
    margin-bottom: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    width: fit-content;
  }
  .list-wrapper {
    .search {
      margin-bottom: 10px;
      .ant-input-affix-wrapper {
        border: 0px;
        background-color: #F9F9FB;
        input {
          background-color: #F9F9FB;          
        }
      }
    }
    .list-content {
      .header {
        padding: 8px 10px;
        border-radius: 8px;
        background-color: #EAEAEE;
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 600;
        color: #121212;
      }
      .list {
        padding-left: 10px;
        max-height: 574px;
        padding-bottom: 75px;
        overflow: hidden;
        overflow-y: auto;
        .list-item {
          padding: 6px 0px;
          box-shadow: 0px 1px 0px 0px #E7E7E7;
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: 'Inter', sans-serif;
          font-size: 12px;
          font-weight: 400;
          color: #2D6CCA;
        }
        &::-webkit-scrollbar {
          height: 5px !important;
          width: 5px !important;
        }
  
        &::-webkit-scrollbar-thumb {
          background: rgba(138, 138, 138, 0.32) !important;
        }
  
        &::-webkit-scrollbar-track {
          background: rgba(138, 138, 138, 0.12) !important;
        }
      }
    }
  }
`;
const StyledUrlSelect = styled(Select)`
  width: 95%;
  border: 2px solid #2D6CCA;
  border-radius: 12px;
  overflow: hidden;
  
`;

const MenuStyle = styled.div`
.rc-virtual-list{
    overflow-x: auto;
    .rc-virtual-list-holder{
      width: 1000px;
    }
      &::-webkit-scrollbar {
          height: 5px !important;
          width: 5px !important;
        }
  
        &::-webkit-scrollbar-thumb {
          background: rgba(138, 138, 138, 0.32) !important;
        }
  
        &::-webkit-scrollbar-track {
          background: rgba(138, 138, 138, 0.12) !important;
        }
  }
`;

const UrlItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .url {
    margin-right: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #2D6CCA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .already-exist{
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #121212;
      padding-right: 6px;
      padding-left: 6px;
      margin-right: 10px;
      background-color: #F2F2F5;
      border-radius: 6px;
    }
  }
`;
const SelectHeader = styled.div`
  margin: 8px 12px;
  border-bottom: 1px solid #D9D9D9;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #121212;
`;
