import numeral from 'numeral';
import {types, Instance, flow, cast, getParent, getRoot} from 'mobx-state-tree';
import isEmpty from 'lodash/isEmpty';
import {KeywordDetails, KeywordDetailsInstance} from './keyword-details';
import {KeywordSerpDetails} from './serp-details';
import {OVERVIEW_DETAIL_API} from '@/api/keyword-explorer';
import {Identifier, OverviewFilters} from './overview';
import {notification as antdNotification} from 'antd';
import {BannerVariant} from '@/components/common-components/components/banner';
import {TaskStatus} from '@/api/keyword-explorer/overview/overview-api';
import {KE_API_MAX_NUMBER_OF_POLLS, KE_API_POLL_INTERVAL} from '@/constants';
import {notification} from '@/utils/notification-v2';
import {searchVolumeFormatter} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/LeftSide/EditorSection/content-editor/utils';
import {getSingleUrlParam} from '@/utils/url';
import {getTokenFromCookies} from '@/api/common-utils';
import Router from 'next/router';

// KR Task statuses
const krTaskStatuses = ['autocompleteTaskStatus', 'kdTaskStatus', 'serpsTaskStatus', 'similarideasTaskStatus'];

// For now only one task status from SCA
const scaTaskStatus = 'scaTaskStatus';

const RawChartData = types.model({
  date: types.string,
  volume: types.number,
});

const GlobalVolumeData = types.model({
  countryCode: types.string,
  searchVolume: types.number,
  // Drop/Growth percentage
  // rate: types.number,
});

const TrafficShareData = types.model({
  domain: types.string,
  position: types.string,
  share: types.number,
  traffic: types.number,
});

const FocusedKeywordDetails = types
  .model({
    keyword: types.maybeNull(types.string),
    publicShareHash: types.maybeNull(types.string),
    difficulty: types.maybeNull(types.number),
    requiredBacklinks: types.maybeNull(types.number),
    searchVolume: types.maybeNull(types.number),
    searchVolumeData: types.array(RawChartData),
    trendsData: types.array(RawChartData),
    globalVolume: types.maybeNull(types.number),
    globalVolumeData: types.array(GlobalVolumeData),
    trafficShare: types.array(TrafficShareData),
    cpc: types.maybeNull(types.number),
    ads: types.maybeNull(types.number),
    totalResults: types.maybeNull(types.number),
  })
  .views(self => ({
    get formattedSearchVolume() {
      return numeral(self.searchVolume).format('0,0a').toUpperCase();
    },
    get formattedGlobalVolumeData() {
      return self.globalVolumeData?.slice()
        .sort((a, b) => a.searchVolume < b.searchVolume ? 1 : -1)
        .filter(record => record.countryCode != '')
        .map(datum => ({
          ...datum,
          searchVolume: searchVolumeFormatter(datum.searchVolume),
          // searchVolume: numeral(datum.searchVolume).format('0a').toUpperCase(),
        }));
    },
    get formattedGlobalValue() {
      return searchVolumeFormatter(self.globalVolume);
      // return numeral(self.globalVolume).format('0,0a').toUpperCase();
    },
  }));

export interface FormattedSerpOverviewUrl {
  title: string;
  page: string;
  position: number;
}

export const taskStatuses = types.model({
  autocompleteTaskStatus: types.maybeNull(types.string),
  kdTaskStatus: types.maybeNull(types.string),
  questionsTaskStatus: types.maybeNull(types.string),
  serpsTaskStatus: types.maybeNull(types.string),
  similarideasTaskStatus: types.maybeNull(types.string),
  similarideasKdTaskStatus: types.maybeNull(types.string),
});

export const keywordGroupingsModel = types.model({
  seed: types.maybeNull(types.string),
  keywords: types.array(types.model({
    seed: types.maybeNull(types.string),
    keywords: types.array(types.string),
  })),
});

export const focusTermsGridDataModel = types.model({
  contentScores: types.array(types.number),
  focusTermUsageBySites: types.array(types.model({
    name: types.maybeNull(types.string),
    values: types.array(types.number),
  })),
  sites: types.array(types.model({
    hostname: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    contentScore: types.maybeNull(types.number),
    readability: types.maybeNull(types.string),
    wordCount: types.maybeNull(types.number),
  })),
});
export const filterList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.boolean,
});
export const FocusedKeywordStore = types
  .model({
    relatedKeywordQuery: types.maybeNull(types.string),
    autocompleteQuery: types.maybeNull(types.string),
    questionsQuery: types.maybeNull(types.string),
    loading: types.optional(types.boolean, false),
    loadingDifficulty: types.boolean,
    fromListPage: types.optional(types.boolean, false),
    searchedKeyword: types.array(types.string),
    details: FocusedKeywordDetails,
    relatedKeywords: types.array(KeywordDetails),
    autocomplete: types.array(KeywordDetails),
    questions: types.array(KeywordDetails),
    serpOverview: types.array(KeywordSerpDetails),
    serpsFetchedAt: types.maybeNull(types.string),
    filters: OverviewFilters,
    tasksComplete: types.optional(types.boolean, false),
    reTry: types.optional(types.number, 0),
    taskStatuses: types.maybeNull(taskStatuses),
    errorNotification: types.boolean,
    loadingArray: types.array(types.string),
    isSerpDrawerOpened: types.boolean,
    selectedKeyword: types.maybeNull(types.string),
    keywordGroupings: types.array(keywordGroupingsModel),
    refetchKeyword: types.optional(types.model({
      keyword: types.maybeNull(types.string),
      siteProperty: types.maybeNull(types.string),
      countryCode: types.maybeNull(types.string),
      locationId: types.maybeNull(types.number),
      excludeKd: types.maybeNull(types.boolean),
    }), {}),
    isOpenDetailedOverviewDrawer: types.boolean,
    openDrawerFor: types.maybeNull(types.number),
    filteringKeywordsByKeywordGrouping: types.array(types.string),
    suggestedkeywordFilterList: types.array(filterList),
    suggestedSearchTerm: types.maybeNull(types.string),
    searchTerm: types.maybeNull(types.string),
    collapseActiveSubKey: types.maybeNull(types.number),
    focusTermsGridData: focusTermsGridDataModel,
    scaDataLoading: types.boolean,
    detailApiCall: types.boolean,
  })
  .views(self => ({
    getTwoLowestDr() {
      const filteredData = self.serpOverview.map(item => {
        return {
          page: item.page,
          dr: item.dr,
        };
      });

      const newDataArray = [];
      for (let i = 0; i < 10; i++) {
        const min = i * 10;
        const max = min === 90 ? min + 10 : min + 9;
        newDataArray.push({
          min: min,
          max: max,
          data: filteredData.filter(item => item.dr >= min && item.dr <= max),
        });
      }

      const sortedDataArray = newDataArray.filter(item => item.data.length > 0);
      const finalDataArray = sortedDataArray.filter((item, i) => i < 2);

      return finalDataArray;
    },
    get getSerpsFechedAt() {
      return self.serpsFetchedAt;
    },
    getRelatedKeywords(query: string) {
      const formattedQuery = query.toLowerCase();
      const filtered = self.relatedKeywords.filter(record => record.keyword.toLowerCase().includes(formattedQuery));

      const filteredData: KeywordDetailsInstance[] = filtered.reduce((acc, curr) => {
        const keys: Identifier[] = ['kd', 'cpc', 'position', 'traffic', 'sv', 'ppcd'];

        const conditions = keys.map(item => {
          const filter = self.filters.getFilters(item);
          if (!isEmpty(filter)) {
            return curr[item] >= filter.min && curr[item] <= filter.max;
          }
          return true;
        });

        const query = self.filters.query;

        if (query) {
          if (curr.keyword.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
            conditions.push(true);
          } else {
            conditions.push(false);
          }
        } else {
          conditions.push(true);
        }

        const isConditionMet = conditions.every(condition => condition === true);

        if (isConditionMet) {
          acc.push(curr);
        }

        return acc;
      }, []);


      const data = filteredData.map(item => {
        return {
          ...item,
          trend: item.trendSeries,
          cpc: item.formattedCpc,
          cpcCloned: item.formattedCpc != '-' ? parseFloat(item.formattedCpc.replaceAll('$', '')) : 0,
          ppcd: item.formattedPpcd,
          kd: item.formattedKd,
          sv: item.formattedSv ? numeral(item.formattedSv).format('0,0a').toUpperCase(): item.formattedSv,
          svCloned: item.formattedSv != '-' ? +(item.formattedSv.replaceAll(',', '')) : 0,
          traffic: item.formattedTraffic,
        };
      });

      return data;
    },


    getAutocomplete(query: string) {
      const formattedQuery = query.toLowerCase();
      const filtered = self.autocomplete.filter(record => record.keyword.toLowerCase().includes(formattedQuery));

      const data = filtered.map(item => ({
        ...item,
        trend: item.trendSeries,
        cpc: item.formattedCpc,
        cpcCloned: item.formattedCpc != '-' ? parseFloat(item.formattedCpc.replaceAll('$', '')) : 0,
        ppcd: item.formattedPpcd,
        kd: item.formattedKd,
        sv: item.formattedSv ? numeral(item.formattedSv).format('0,0a').toUpperCase(): item.formattedSv,
        svCloned: item.formattedSv != '-' ? +(item.formattedSv.replaceAll(',', '')) : 0,
        traffic: item.formattedTraffic,
      }));

      return data;
    },
    getQuestions(query: string) {
      const formattedQuery = query.toLowerCase();
      const filtered = self.questions.filter(record => record.keyword.toLowerCase().includes(formattedQuery));

      const data = filtered.map(item => ({
        ...item,
        trend: item.trendSeries,
        cpc: item.formattedCpc,
        cpcCloned: item.formattedCpc != '-' ? parseFloat(item.formattedCpc.replaceAll('$', '')) : 0,
        ppcd: item.formattedPpcd,
        kd: item.formattedKd,
        sv: item.formattedSv ? numeral(item.formattedSv).format('0,0a').toUpperCase(): item.formattedSv,
        svCloned: item.formattedSv != '-' ? +(item.formattedSv.replaceAll(',', '')) : 0,
        traffic: item.formattedTraffic,
      }));

      return data;
    },
    get serpOverviewData() {
      const data = self.serpOverview.map(record => ({
        url: {
          title: record.title,
          page: record.page,
          position: record.position,
        },
        dr: record.dr,
        ur: record.ur,
        domains: record.formattedDomains ? numeral(record.formattedDomains).format('0,0a').toUpperCase(): record.formattedDomains,
        domainsCloned: record.formattedDomains != '-' ? +(record.formattedDomains.replaceAll(',', '')) : 0,
        traffic: record.formattedTraffic ? numeral(record.formattedTraffic).format('0,0a').toUpperCase(): record.formattedTraffic,
        trafficCloned: record.formattedTraffic != '-' ? +(record.formattedTraffic.replaceAll(',', '')) : 0,
        keywords: record.fomattedKeywords,
        cs: record.cs,
        cl: record.formattedCl,
        psi: record.psi,
        wordCount: record.wordCount,
        contentScore: record.contentScore,
      }));

      return data;
    },
    get trafficShareList() {
      const data = self.details.trafficShare.map(value=> ({
        domain: value.domain,
        position: value.position,
        share: value.share,
        traffic: value.traffic,
      }));
      return data;
    },
  }))
  .actions(self => {
    const onsuggestedTableUpdateSearchTerm = (searchTerm: string) => {
      self.suggestedSearchTerm = searchTerm;
    };
    const onKeywordFilterChange = (filters: any) => {
      self.suggestedkeywordFilterList = cast(filters);
    };
    const clearFilterList = () => {
      self.suggestedkeywordFilterList = cast([
        {id: 1, name: 'kd', header: ('KD'), from: undefined, to: undefined, type: undefined, active: false},
        {id: 2, name: 'ppcd', header: ('PPCD'), from: undefined, to: undefined, type: undefined, active: false},
        {id: 3, name: 'cpcCloned', header: ('CPC'), from: undefined, to: undefined, type: undefined, active: false},
      ]);
    };
    const closeDetailedOverviewDrawer = () => {
      self.isOpenDetailedOverviewDrawer = false;
    };

    const openDetailedOverviewDrawer = () => {
      self.isOpenDetailedOverviewDrawer = true;
    };

    const setFilteringKeywords = filteringKeywords => {
      self.filteringKeywordsByKeywordGrouping = filteringKeywords;
    };

    const setBannerError = () => {
      const parent = getParent(self) as any;
      parent.setBanner(true, 'Could not get keyword details', 'Please reach out to support or your account manager.', BannerVariant.ERROR);
    };

    const setCollapseActiveSubKey = key => {
      self.collapseActiveSubKey = key;
    };

    const getDetailOverview = flow(function* (payload, countryCode, locationId, excludeKd, siteProperty?, publicShareHash?, refetchSerps?) {
      try {
        const token = getTokenFromCookies();
        const updatedPayload = payload.replaceAll('+', ' ');
        if (!token && publicShareHash) {
          const data = yield OVERVIEW_DETAIL_API.postKeywordDetail(updatedPayload, countryCode, locationId, excludeKd, siteProperty, publicShareHash, refetchSerps);
          return data;
        } else if (!token && !publicShareHash) {
          Router.push('/login');
        } else {
          const data = yield OVERVIEW_DETAIL_API.postKeywordDetail(updatedPayload, countryCode, locationId, excludeKd, siteProperty, publicShareHash, refetchSerps);
          return data;
        }
      } catch (e) {
        if (e?.response?.status === 429) {
          notification.warning('Keywords Quota Exceeded', e?.response?.data?.message);
        } else {
          notification.error('Error loading Keyword Details', 'The Keyword Details data did not load properly. To fix the issue:', true);
        }
        return Promise.reject(e);
      }
    });

    const refetchSerps = async () => {
      if (self?.refetchKeyword) {
        self.scaDataLoading = true;
        await changeKeyword(self?.refetchKeyword?.keyword, self?.refetchKeyword?.siteProperty, self?.refetchKeyword?.countryCode, self?.refetchKeyword?.locationId, self?.refetchKeyword?.excludeKd, true);
        antdNotification.success({message: 'SERPs fetched successfully.', placement: 'bottomRight'});
      }
    };

    const addToLoadingArray = (item: string) => {
      if (item) {
        const loadingArr = [...self.loadingArray];
        loadingArr.push(item);
        self.loadingArray = cast(loadingArr);
      }
    };

    const removeFromLoadingArray = (item: string) => {
      if (item) {
        const loadingArr = [...self.loadingArray];
        const filteredArr = loadingArr.filter(arrItem => arrItem !== item);
        self.loadingArray = cast(filteredArr);
      }
    };

    const startLoading = () => {
      self.loading = true;
    };
    const startLoadingDifficulty = () => {
      self.loadingDifficulty = true;
    };
    const comingFromListPage = val => {
      self.fromListPage = val;
    };
    const stopLoading = () => {
      self.loading = false;
    };
    const stopLoadingDifficulty = () => {
      self.loadingDifficulty = false;
    };
    const setSearchedKeywords = kw => {
      self.searchedKeyword = kw;
    };

    // const reset: () => {
    //   applySnapshot(self, initialState);
    // }

    const resetPreviousData = () => {
      self.details.keyword = null;
      self.details.publicShareHash = null;
      self.details.difficulty = 0;
      self.details.globalVolume = 0;
      self.details.searchVolumeData = cast([]);
      self.details.searchVolume = 0;
      self.details.trendsData = cast([]);
      self.details.trafficShare = cast([]);
      self.serpOverview = cast([]);
      self.questions = cast([]);
      self.autocomplete = cast([]);
      self.relatedKeywords = cast([]);
    };

    const startLoadingScaData = () => {
      self.scaDataLoading = true;
    };

    const setDetailApiCall = stopApi => {
      self.detailApiCall = stopApi;
    };

    const changeKeyword = flow(function* (keyword: string, siteProperty?: string, countryCode?: string, locationId?: number, excludeKd?: boolean, refetchSerps?: boolean) {
      const publicHash = getSingleUrlParam('public_hash');
      if (keyword) {
        self.refetchKeyword = {keyword: keyword, siteProperty: siteProperty, countryCode: countryCode, locationId: locationId, excludeKd: excludeKd};
        try {
          const response = yield getDetailOverview(keyword, countryCode, locationId, excludeKd, siteProperty, publicHash, refetchSerps);

          if (response?.results) {
            self.details.keyword = keyword;
            self.details.globalVolume = response.results?.globalVolume;
            self.details.publicShareHash = response.results?.publicShareHash;
            self.details.difficulty = response?.results?.difficulty;
            self.details.searchVolumeData = response.results?.searchVolumeData;
            self.details.searchVolume = response.results?.searchVolume;
            self.details.trendsData = response.results?.searchVolumeData;
            self.details.globalVolumeData = response.results?.topCountriesSearchVolume;
            self.details.trafficShare = response.results?.trafficShare;
            self.details.cpc = response.results?.cpc;
            self.details.ads = response.results?.ads;
            self.details.totalResults = response.results?.totalResults;
            self.serpOverview = cast(response.results?.serpOverview || []);
            self.serpsFetchedAt = response?.results?.serpsFetchedAt;
            self.questions = cast(response.results?.questions);
            self.autocomplete = cast(response.results?.autocomplete);
            self.relatedKeywords = cast(response.results?.relatedKeywords);
            self.tasksComplete = Object.keys(response.results?.taskStatus).every(key => response.results?.taskStatus[key] !== TaskStatus.PENDING && response.results?.taskStatus[key] !== null);
            self.taskStatuses = cast(response.results?.taskStatus);
            self.keywordGroupings = cast(response.results?.keywordGroupings ? response.results?.keywordGroupings : []);
            self.focusTermsGridData.contentScores = cast(response.results?.focusTermsGridData?.contentScores ? response.results?.focusTermsGridData.contentScores : []);
            self.focusTermsGridData.sites = cast(response.results?.focusTermsGridData?.sites ? response.results?.focusTermsGridData.sites : []);
            self.focusTermsGridData.focusTermUsageBySites = cast(response.results?.focusTermsGridData?.focusTermUsageBySites ? response.results?.focusTermsGridData.focusTermUsageBySites : []);

            if (response.results.difficulty) {
              stopLoadingDifficulty();
            }

            // Kill Loaders when KE task statuses are done
            if (krTaskStatuses.every(taskStatus => response.results?.taskStatus[taskStatus] !== TaskStatus.PENDING && response.results?.taskStatus[taskStatus] !== null)) {
              stopLoading();
              stopLoadingDifficulty();
            }

            // Kill loaders for data coming from SCA
            if (response.results?.taskStatus[scaTaskStatus] !== TaskStatus.PENDING && response.results?.taskStatus[scaTaskStatus] !== null) {
              self.scaDataLoading = false;
            }

            if (!self.tasksComplete && !self.detailApiCall) {
              self.reTry = cast(self.reTry + 1);
              if (self.reTry <= KE_API_MAX_NUMBER_OF_POLLS) {
                yield new Promise(r => setTimeout(r, KE_API_POLL_INTERVAL));
                return changeKeyword(keyword, siteProperty, countryCode, locationId, excludeKd);
              } else {
                self.reTry = 0;
                stopLoading();
                stopLoadingDifficulty();
                self.scaDataLoading = false;
                self.errorNotification = true;
                self.detailApiCall = false;
              }
            } else {
              self.reTry = 0;
              self.errorNotification = false;
              stopLoadingDifficulty();
              stopLoading();
              self.scaDataLoading = false;
            }
          } else {
            self.details.keyword = keyword;
          }
        } catch (e) {
          if (e?.response?.status && e?.response?.status === 429) {
            const rootStore = getRoot(self) as any;
            rootStore.gsc.keywordsHistoricalData.handleCloseDrawer();
            rootStore.plans.showSidebarPaymentDrawer(null, {title: 'You need more Keyword Researcher quota to continue', subtitle: '', text: `Current limit: ${rootStore.settings.customer.profile.quotaUtilization?.ca?.allowedFocusTerms?.total} lookups`});
          } else {
            return Promise.reject(e);
          }
          stopLoadingDifficulty();
          stopLoading();
          self.scaDataLoading = false;
        } finally {
          if (self.tasksComplete) {
            stopLoading();
            stopLoadingDifficulty();
            self.scaDataLoading = false;
          }
        }
      }
    });

    const setRelatedKeywordQuery = (query: string) =>{
      self.relatedKeywordQuery = query;
    };

    const handleOpenSerpDrawer = (selectedKw: string) => {
      const rootStore = getRoot(self) as any;
      if (rootStore.settings.customer.profile.quotaUtilization?.ke?.maxKeywordLookups?.consumed >= rootStore.settings.customer.profile.quotaUtilization?.ke?.maxKeywordLookups?.total) {
        rootStore.plans.showSidebarPaymentDrawer(null, {title: 'You need more Keyword Researcher quota to continue', subtitle: '', text: `Current limit: ${rootStore.settings.customer.profile.quotaUtilization?.ke?.maxKeywordLookups?.total} lookups`});
      } else {
        self.loading = true;
        self.loadingDifficulty = true;
        self.selectedKeyword = selectedKw ? selectedKw : '';
        self.isSerpDrawerOpened = true;
        changeKeyword(selectedKw);
      }
    };

    const handleCloseSerpDrawer = () => {
      self.isSerpDrawerOpened = false;
    };

    const setOpenDrawerFor = (value: number) => {
      self.openDrawerFor = value;
    };

    return {
      onsuggestedTableUpdateSearchTerm,
      clearFilterList,
      onKeywordFilterChange,
      changeKeyword,
      addToLoadingArray,
      removeFromLoadingArray,
      setRelatedKeywordQuery,
      startLoading, stopLoading, stopLoadingDifficulty, startLoadingDifficulty, resetPreviousData, comingFromListPage, setSearchedKeywords, setBannerError,
      handleOpenSerpDrawer,
      handleCloseSerpDrawer,
      refetchSerps,
      closeDetailedOverviewDrawer,
      openDetailedOverviewDrawer,
      setFilteringKeywords,
      setCollapseActiveSubKey,
      startLoadingScaData,
      setDetailApiCall,
      setOpenDrawerFor,
    };
  });

export type FocusedKeyword = Instance<typeof FocusedKeywordStore>;

export const initFocusedKeywordStore = () => {
  return {
    suggestedkeywordFilterList: [
      {id: 1, name: 'kd', header: ('KD'), from: undefined, to: undefined, type: undefined, active: false},
      {id: 2, name: 'ppcd', header: ('PPCD'), from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'cpcCloned', header: ('CPC'), from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'SV', header: ('SV'), from: undefined, to: undefined, type: undefined, active: false},
    ],
    searchTerm: '',
    suggestedSearchTerm: '',
    relatedKeywordQuery: '',
    autocompleteQuery: '',
    questionsQuery: '',
    loading: false,
    loadingDifficulty: false,
    fromListPage: false,
    detailApiCall: false,
    searchedKeyword: [],
    isSerpDrawerOpened: false,
    selectedKeyword: '',
    keywordGroupings: [],
    isOpenDetailedOverviewDrawer: false,
    filteringKeywordsByKeywordGrouping: [],
    activeCollapseSubKey: null,
    focusTermsGridData: {
      sites: [],
      focusTermUsageBySites: [],
      contentScores: [],
    },
    scaDataLoading: true,
    taskStatuses: {
      autocompleteTaskStatus: '',
      kdTaskStatus: '',
      questionsTaskStatus: '',
      serpsTaskStatus: '',
      similarideasTaskStatus: '',
      similarideasKdTaskStatus: '',
    },
    errorNotification: false,
    details: {
      keyword: '',
      difficulty: 0,
      requiredBacklinks: 0,
      searchVolume: 0,
      searchVolumeData: [],
      trendsData: [],
      globalVolume: 0,
      globalVolumeData: [],
      cpc: null,
      ads: null,
      totalResults: null,
    },
    relatedKeywords: [],
    autocomplete: [],
    questions: [],
    serpOverview: [],
    filters: {
      active: '' as Identifier,
      kd: null,
      sv: null,
      traffic: null,
      cpc: null,
      ppcd: null,
      query: '',
    },
    loadingArray: [],
  };
};
