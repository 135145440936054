import {observer} from 'mobx-react';
import styled from 'styled-components';
import {Skeleton} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWandMagicSparkles} from '@fortawesome/pro-duotone-svg-icons';
import {RightSection} from '../styles';
import {ProblemBanner} from '../ProblemBanner';

interface Props {
  percentage: number;
  generating?: boolean;
  rebuildPage?: () => void;
  canRebuild?: boolean;
}

export const PlaceholdersSection = observer(({percentage, generating, canRebuild, rebuildPage}: Props) => {
  return (
    <RightSection>
      <div style={{position: 'relative', width: '100%', height: '100%', paddingRight: 43, paddingLeft: 43}}>
        {!canRebuild ? (<FloatingDiv animate={generating} percentage={percentage} >
          <div className='txt'>
            <FontAwesomeIcon icon={faWandMagicSparkles} fontSize={22} color={generating ? '#7F4EAD' : '#A3A4A4'} />
            <div style={{lineHeight: '18px'}}>
              {generating ? `${(percentage || 0)}% of your landing page has been generated...` : 'Fill the input fields and generate a landing page for your business.'}
            </div>
          </div>
        </FloatingDiv>) : (
          <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
            <ProblemBanner rebuildPage={rebuildPage} />
          </div>
        )}
        <div style={{paddingTop: 23, width: '100%', height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <Skeleton.Button active={generating} block style={{width: '30%', height: 36, borderRadius: 12}} />
            <Skeleton.Button active={generating} block style={{height: 54, borderRadius: 12, width: '100%'}} />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', gap: 16, marginTop: 40, alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: 333, gap: 38}}>
              <Skeleton.Button active={generating} style={{width: '100%', height: 32, borderRadius: 12}} />
              <div style={{display: 'flex', flexDirection: 'column', gap: 13}}>
                <Skeleton.Button active={generating} style={{width: '70%', height: 16, borderRadius: 12}} />
                <Skeleton.Button active={generating} style={{width: '60%', height: 16, borderRadius: 12}} />
                <Skeleton.Button active={generating} style={{width: '90%', height: 16, borderRadius: 12}} />
                <Skeleton.Button active={generating} style={{width: '50%', height: 16, borderRadius: 12}} />
              </div>
            </div>
            <Skeleton.Button active={generating} style={{width: 354, height: 230, borderRadius: 12}} />
          </div>
          <div style={{marginTop: 130, display: 'flex', gap: 15}}>
            {[1, 2, 3].map((_, idx) => (
              <Skeleton.Button active={generating} block key={idx} style={{height: 152, flexGrow: 1, borderRadius: 12}} />
            ))}
          </div>
        </div>
      </div>
    </RightSection>
  );
});

const FloatingDiv = styled.div<{percentage?: number; animate?: boolean; generated?: boolean}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #D2D2D2;
  border-radius: 12px;
  width: 320px;
  height: 72px;
  padding: 1px;
  overflow: hidden;

  .txt {
    display: flex;
    align-items: center;
    padding-right: 30px;
    padding-left: 24px;
    gap: 17px;
    color: #4E5156;
    height: 99%;
    background-color: #fff;
    border-radius: 12px;
  }
  &::after {
    content: '';
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${({percentage}) => `conic-gradient(from 360deg, transparent 0%, transparent ${100 - (percentage || 0)}%, purple ${100 - ((percentage || 0) + 4)}%, purple 360deg)`};
    background-clip: padding-box;
  }
`;
