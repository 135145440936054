import React, {useState, useEffect, useCallback} from 'react';
import styles from '../style.module.scss';
// import {useTranslation} from 'next-i18next';
import {Checkbox} from 'antd';
// import {useRouter} from 'next/router';
import {CancelButton, ApplyButton, BothBtnWrapper, CustomRangeContainer, InputTextStyled, RadioStyled, TextBlackField} from '../styledComponents';
// import {copyAndModifyQueryParams} from '@/utils/url';

interface Props {
  type?: string;
  filterId?: number;
  filterLabel?: string;
  filterName?: string;
  onChange?: (filter:any)=>void;
  className?: string;
  filterField?: string;
  customFields?: any[];
  headerField?: any;
  isDisable?:boolean;
  isClear?: boolean;
  onClear?: (data: any)=>void;
  absoluteUi?: boolean;
  isPageExplorer?: boolean;
  queryVal?: string;
  popRef?: any;
}

const FilterIncludesKeywordPopUp: React.FC<Props> = ({type, queryVal, filterField, headerField, filterId, customFields, filterLabel, filterName, onChange, isPageExplorer}) => {
  const [value, setValue] = useState(type ? type : 'OR');
  const [operator, setOperator] = useState(headerField?.operator ||'contains');
  const [query, setQuery] = useState('');
  const [checked, setChecked] = useState(false);

  // const router = useRouter();

  // const {t} = useTranslation('common');
  useEffect(() => {
    if (type) {
      setOperator(type);
    }
  }, [type]);

  useEffect(() => {
    if (queryVal) {
      setQuery(queryVal);
    } else {
      setQuery('');
    }
  }, [queryVal]);

  const onChangeValue = useCallback(

    e => {
      if (headerField?.type == value) {
        setQuery('');
      }
      setValue(e.target.value);
    },
    [],
  );


  const createOrUpdateFilter = e =>{
    e.preventDefault();
    emitOnChanges(operator, query);
  };

  const emitOnChanges = (operator: string, query: string) => {
    if (typeof onChange === 'function') {
      onChange({
        id: filterId,
        header: filterLabel,
        name: filterName,
        type: value,
        operator: operator,
        query: query,
        filterField: filterField,
        active: true,
        customFields: customFields,
        checked: checked,
        isUrl: filterLabel == 'url' && isPageExplorer ? true : false,
      });
    }
  };

  const disablingButton = () =>{
    if (query=='') {
      return true;
    } else {
      return false;
    }
  };

  const filterPopupOptions = customFields?.length ? customFields : [
    {label: 'Contains', operator: 'contains'},
    {label: 'Doesn’t contain', operator: 'does_not_contain'},
    {label: 'Starts With', operator: 'starts_with'},
    {label: 'Ends With', operator: 'ends_with'},
  ];
  const checkBoxChange = () => {
    setChecked(!checked);
  };
  return (
    <CustomRangeContainer>
      {isPageExplorer && (filterLabel == 'title' || filterLabel == 'meta_description' ) ?<>
        <Checkbox checked={checked} onChange={checkBoxChange}>Case Insensitive Filtering </Checkbox>
        <hr color=' #E8E8E8'/></>: ''}
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {filterPopupOptions?.map(item=>{
            return (
              <div key={item.operator}>
                <RadioStyled
                  value={item.operator}
                  checked={value === item.operator}
                  onChange={e=>[onChangeValue(e), setOperator(item.operator)]}
                ><TextBlackField>{item.label}</TextBlackField></RadioStyled>
              </div>
            );
          }) }
        </div>
        <div className={styles.filterPopupOptionsOther}>
          <InputTextStyled
            value={query}
            onChange={e=>setQuery(e.target.value)}
            rows={3}
            placeholder='Type a keyword'
          />
          <TextBlackField>You can separate terms with commas</TextBlackField>
        </div>
      </div>
      <BothBtnWrapper>
        <ApplyButton
          disabled={disablingButton()}
          onClick={createOrUpdateFilter}
        >
          Apply
        </ApplyButton>
        <CancelButton
          border
          // disabled={disablingButton()}
          // onClick={()=> popRef.current}
        >
          Cancel
        </CancelButton>
      </BothBtnWrapper>
    </CustomRangeContainer>
  );
};

export default FilterIncludesKeywordPopUp;
