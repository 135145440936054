import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Heading, InputWithButton, ModalStyled, StyledInput} from '../styledComponents';
import {useStore} from '@/store/root-store';
import {faPlus, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {Divider, Spin} from 'antd';
import {faSquareInfo} from '@fortawesome/pro-duotone-svg-icons';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {BtnStyled, FolderName, FolderValue, FolderValueWrapper, FoldersRowStyled, FoldersWrapper} from './styledComponents';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import React, {useState} from 'react';
interface ManageFoldersModalProps {
  isInOTTO?: boolean;
}
export const ManageFoldersModal: React.FC<ManageFoldersModalProps> = observer(({isInOTTO}) => {
  const {campaigns: {
    foldersModalVisible,
    setFoldersModalVisible,
    createFolder,
    createFolderLoading,
    deleteFolder,
    newFolderCreate,
    setNewFolderCreate,
    setFolderUpdate,
    foldersLoading,
    campaigns,
    showError,
    setShowError,
    newFolderValue,
    setNewFolderValue,
    foldersData},
  ottoV2Store: {
    getOttoV2Project,
  },
  } = useStore('');

  const projectsData = foldersData?.results;

  const [deleteFolderLoading, setDeleteFolderLoading] = useState();

  const closeModal = () => {
    setFoldersModalVisible(false);
    setNewFolderCreate(false);
    setFolderUpdate(false);
    setNewFolderValue('');
    setShowError(false);
  };

  const handleAddFolderClick = () => {
    if (!newFolderValue) {
      setShowError(true);
      return;
    }
    const payload = {
      name: newFolderValue,
    };
    createFolder(payload);
  };

  const handleDeleteFolder = async id => {
    setDeleteFolderLoading(id);
    if (isInOTTO) {
      await deleteFolder(id, getOttoV2Project?.id);
    } else {
      await deleteFolder(id);
    }
    setDeleteFolderLoading(null);
  };


  return <ModalStyled
    width={560}
    closable={true}
    closeIcon={<FontAwesomeIcon icon={faXmark} />}
    mask={true}
    style={{marginTop: '20%'}}
    visible={foldersModalVisible}
    onClose={closeModal}
    zIndex={20}>
    <Heading>Manage folders</Heading>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#121212', fontSize: 12, fontWeight: 600, width: '100%', height: '37.415px', background: '#F7F7F7', borderRadius: '8px'}}>
      <div style={{marginLeft: 14}}>FOLDER</div>
    </div>
    {foldersLoading ? <RingLoaderV2 height={'200px'} fontSize={30}/> :
      <>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#121212', width: '100%', height: '37.415px', borderRadius: '8px'}}>
          <div style={{marginLeft: 14}}>All campaigns</div>
          <div style={{marginRight: 74}}><FontAwesomeIcon icon={faSquareInfo} color='#A3A4A4'/>&nbsp;&nbsp;{campaigns?.count}</div>
        </div>
        <Divider/>
        <FoldersWrapper>
          {projectsData?.map((item, idx) => <FoldersRowStyled key={idx}>
            <FolderName>{item?.name}</FolderName>
            <FolderValueWrapper>
              <FolderValue>
                {deleteFolderLoading == item?.id ? <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin />} /> :
                  <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => handleDeleteFolder(item?.id)} icon={faTrash} color='#DBDBDB' fontSize={18} />
                }</FolderValue>
            </FolderValueWrapper>
          </FoldersRowStyled>)}
        </FoldersWrapper>
      </>
    }
    {newFolderCreate ? <InputWithButton>
      <StyledInput
        value={newFolderValue}
        placeholder='Enter folder name'
        width='100%'
        height='28px'
        borderRadius='8px'
        style={{background: '#F9F9FB', paddingRight: '145px'}}
        onChange={e => {
          e?.target?.value && setShowError(false);
          setNewFolderValue(e?.target?.value);
        }}/>
      <BtnStyled
        color='#2D6CCA'
        rightAlign='75px'
        onClick={() => handleAddFolderClick()}>
        {createFolderLoading ? <RingLoaderV2 width={'fit-content'} fontSize={12}/> : 'Add Folder'}
      </BtnStyled>
      <BtnStyled color='#A3A4A4' rightAlign='2px' onClick={() => {
        setNewFolderCreate(false);
        setNewFolderValue('');
        setShowError(false);
      }}>Cancel</BtnStyled>
      {showError && !newFolderValue && <div style={{color: 'red'}}>Please enter folder name</div>}
    </InputWithButton> :
      <div onClick={() => setNewFolderCreate(true)} style={{color: '#2D6CCA', cursor: 'pointer', width: 'fit-content'}}>
        <FontAwesomeIcon icon={faPlus} fontSize={12}/>
        &nbsp;New folder
      </div>}
    <div style={{paddingBottom: 30}}></div>
  </ModalStyled>;
});
