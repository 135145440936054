import styles from './styles.module.scss';
import {Row, Col} from 'antd';
import {Button as V2Button} from '@/components/common-components/v2';
import {integrationApi} from '@/api/gsc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import classnames from 'classnames';

import {useRouter} from 'next/router';

interface Props {
  emailList?: string[];
  showButton?:boolean;
  className?:string;
}

export const AccountsFailedCredBanner = observer(({emailList, className, showButton=true}: Props) => {
  const {settings: {
    integration: {
      setShowFailedCredsOnPageBanner,
      showFailedCredsOnPageBanner,
    },
  },
  gsc: {setDrawerVisibility},
  } = useStore('');

  const router = useRouter();

  const renderEmailListSeparator = (idx, totalLength) => {
    if (totalLength > 2) {
      if (idx === totalLength - 2) {
        return <span style={{marginLeft: 5, marginRight: 5}}>and</span>;
      } else if (idx < totalLength - 2 && idx !== totalLength - 1) {
        return <span style={{marginRight: 5}}>,</span>;
      } else {
        return '.';
      }
    } else if (totalLength == 2) {
      if (idx === 0) {
        return <span style={{marginLeft: 5, marginRight: 5}}>and</span>;
      } else {
        return '.';
      }
    } else if (totalLength < 2) {
      return '.';
    }
  };


  return showFailedCredsOnPageBanner ? (<div className={classnames(styles.container, className)}>
    <Row gutter={24} className={styles.notificationBarRow}>
      <Col span={24}>
        <div>
          <div className={styles.notificationBarBlue}>
            <div>Authenticate again to fix this issue.</div>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', flexWrap: 'wrap', fontSize: 12, fontWeight: 400}}>
                <span style={{marginRight: 5}}>
                          We could not fetch GSC Projects data for
                </span>
                {emailList?.map((email, idx) => (<>
                  <span key={idx} style={{textDecoration: 'underline'}} onClick={async () => {
                    await router.push('?active_tab=gsc');
                    setDrawerVisibility(true);
                  }}>
                    {`${email}`}
                  </span>
                  {renderEmailListSeparator(idx, emailList.length)}
                </>
                ))}
              </div>

              {showButton && <div style={{marginLeft: 'auto', marginTop: -20, display: 'flex', alignItems: 'center'}}>
                <V2Button color='blue' variant='solid' onClick={() => {
                  location.href = integrationApi.getGoogleConnectUrl();
                }}><FontAwesomeIcon icon={faGoogle} style={{fontSize: 14, marginRight: 10}}/> Repeat Authorization</V2Button>
                <div style={{marginLeft: 10, cursor: 'pointer'}} onClick={() => setShowFailedCredsOnPageBanner(false)}><FontAwesomeIcon icon={faXmark} fontSize={20} /></div>
              </div>}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>) : <></>;
});
