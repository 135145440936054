import React from 'react';
import {BlockListSectionWrapper, StyledTextArea} from '../../styledComponents';
import {observer} from 'mobx-react';


const BlockListSection = ({blockList, setBlockList}) => {
  return (
    <BlockListSectionWrapper>
      <div className='label'>Blacklist</div>
      <StyledTextArea
        value={blockList}
        onChange={e => setBlockList(e.target.value)}
        className='text-area'
        placeholder='Divide URL’s by commas. E.g google.com, youtube.com, instagram.com'
      />
    </BlockListSectionWrapper>
  );
};

export default observer(BlockListSection);
