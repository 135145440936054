import {useStore} from '@/store/root-store';
import {toJS} from 'mobx';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {SingleSuggestion} from './SingleISugesstion';
import {
  Tabs as CommonTabs,
  TabPane as CommonTabPane,
} from '@/components/common-components/components';
import {useState} from 'react';
import {SingleExternalLink} from './SingleExternalLink';
import {Button} from '@/components/common-components/v2';
import styles from '../../../style.module.scss';
import {Divider} from 'antd';

interface Props {
  addItem: (value: {link: string; anchorText?: string}) => void;
  bulkAddExternalLink: (value: number)=> void;
  bulkAddInternalLink: (value: number)=> void;
}

export const LinksTable = observer(({addItem, bulkAddExternalLink, bulkAddInternalLink}: Props) => {
  const {
    contentOptimizer: {
      currentPage,
    },
  } = useStore('');

  const [activeKey, setActiveKey] = useState('1');
  const onChangeTab = key => {
    setActiveKey(key);
  };


  const [bulkAddInternalLinksNumber, setBulkAddInternalLinksNumber] = useState(5);
  const [bulkAddExternalLinksNumber, setBulkAddExternalLinksNumber] = useState(toJS(currentPage)?.analytics?.commonCitations?.length >= 5 ? 5 : toJS(currentPage)?.analytics?.commonCitations?.length || 0);

  const setBulkAddInternalInputValue = e => {
    if (e.target?.value) {
      setBulkAddInternalLinksNumber(e.target?.value);
    }
  };


  const setBulkAddExternalInputValue = e => {
    let value = parseInt(e.target.value);

    const commonCitationsLength = toJS(currentPage?.analytics?.commonCitations?.length) || 0;
    const maxInputValue = commonCitationsLength >= 5 ? 5 : commonCitationsLength;

    if (value > maxInputValue) {
      value = maxInputValue;
    } else if (value < 1) {
      value = 1;
    }

    setBulkAddExternalLinksNumber(value);
  };


  return <>
    <div>
      <CommonTabsStyled
        // style={currentPage.focusTermsExpanded ? {marginTop: '50px'} : {}}
        tabBarGutter={30}
        activeKey={activeKey}
        tabBarStyle={{'padding': '0 !important'}}
        defaultActiveKey={'1'}
        onChange={key => onChangeTab(key)}
      >


        <TabsStyled
          tab={
            <TabTitleContainer active={activeKey === '1'}>
              <div className={'tabTitle'} style={{color: '#121212', fontWeight: 500, fontSize: 14}}>
                Internal links
              </div>
              <span style={{fontSize: 12, color: '#A3A4A4', marginLeft: 5}}>{currentPage?.content?.internalLinkSuggestionsV2?.allLinks?.length}</span>
            </TabTitleContainer>
          }
          key='1'
          style={{'outline': 'none'}}>
          <DropdownsContainer>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{marginRight: 10}}>Quick add internal links</span>
              <InputStyled
                type='number'
                value={bulkAddInternalLinksNumber}
                className={styles.numberInput}
                defaultValue={5}
                min={toJS(currentPage?.content?.internalLinkSuggestionsV2?.allLinks?.length ? 1 : 0)}
                max={toJS(currentPage?.content?.internalLinkSuggestionsV2?.allLinks?.length || 0)}
                pattern='[0-9]'
                style={{width: '50px', marginBottom: 0}}
                onChange={e => setBulkAddInternalInputValue(e)}/>
              <Button variant='ghost' color='blue' style={{fontSize: 12, padding: '5px 10px'}} disabled={currentPage?.content?.internalLinkSuggestionsV2?.allLinks?.length ? false : true} onClick={() => bulkAddInternalLink(bulkAddInternalLinksNumber)}>+ Add</Button>
            </div>
            <Divider style={{marginTop: 5, marginBottom: 10}}/>
            {(toJS(currentPage)?.content?.internalLinkSuggestionsV2?.allLinks ?? [])?.length ?
              (toJS(currentPage)?.content?.internalLinkSuggestionsV2?.allLinks)?.map((suggestion, id) => (
                <SingleSuggestion addItem={addItem} key={`${id}-${suggestion.url}`} link={suggestion} />
              )) : null}
          </DropdownsContainer>
        </TabsStyled>
        <TabsStyled
          tab={
            <TabTitleContainer active={activeKey === '2'}>
              <div className={'tabTitle'} style={{color: '#121212', fontWeight: 500, fontSize: 14}}>
                External links
              </div>
              <span style={{fontSize: 12, color: '#A3A4A4', marginLeft: 5}}>{toJS(currentPage)?.analytics?.commonCitations?.length}</span>
            </TabTitleContainer>
          }
          key='2'
          style={{'outline': 'none'}}>
          <DropdownsContainer>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{marginRight: 10}}>Quick add external links</span>
              <InputStyled
                type='number'
                value={bulkAddExternalLinksNumber}
                className={styles.numberInput}
                defaultValue={toJS(currentPage)?.analytics?.commonCitations?.length >= 5 ? 5 : toJS(currentPage)?.analytics?.commonCitations?.length || 0}
                min={toJS(currentPage)?.analytics?.commonCitations?.length ? 1 : 0}
                max={toJS(currentPage)?.analytics?.commonCitations?.length >= 5 ? 5 : toJS(currentPage)?.analytics?.commonCitations?.length || 0}
                pattern='[0-9]'
                style={{width: '50px', marginBottom: 0}}
                onChange={e => setBulkAddExternalInputValue(e)}/>
              <Button variant='ghost' color='blue' style={{fontSize: 12, padding: '5px 10px'}} disabled={toJS(currentPage)?.analytics?.commonCitations?.length ? false : true} onClick={() => bulkAddExternalLink(bulkAddExternalLinksNumber)}>+ Add</Button>
            </div>
            <Divider style={{marginTop: 5, marginBottom: 10}}/>
            {(toJS(currentPage)?.analytics?.commonCitations ?? [])?.length ?
              (toJS(currentPage)?.analytics?.commonCitations)?.map((suggestion, id) => (
                <SingleExternalLink addItem={addItem} key={`${id}-${suggestion.url}`} link={suggestion} />
              )) : null}
          </DropdownsContainer>
        </TabsStyled>
      </CommonTabsStyled>
    </div>

  </>;
});
const InputStyled = styled.input`
  background-color: #fff !important;
  border: 1px solid #e8e8e8 !important;
`;

const DropdownsContainer = styled.div`
  max-height: 340px;
  overflow-y: auto;
  padding-right: 30px;

  // For Internet Explorer
  scrollbar-face-color: rgba(138,138,138,0.32);
  scrollbar-track-color: rgba(138,138,138,0.12);

  // For Google Chrome
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const CommonTabsStyled = styled(CommonTabs)`
  .rc-tabs-extra-content {
    background-color: #fff !important;
    border: 0px solid transparent;
    cursor: pointer;
    font-size: $font-size-12;
    height: 25px;
    margin-bottom: auto;
    margin-left: 5px;
    margin-top: auto;
    padding: 0 5px;
    transition: all 0.2s ease-in;
  }

  .focusTermsTabTitleValue {
    color: $color-gray-light;
    display: block;
    font-size: $font-size-12;
    font-weight: 400;
    margin-left: 5px;

    .ant-spin-spinning {
      height: 10px;
    }

    .customSpinner {
      font-size: 12px !important;
    }
  }

  .rc-tabs-content-holder {
    background-color: transparent !important;
    padding-top: 15px;
  }

  .rc-tabs-nav {
    border-bottom: 1px solid #E2E2E2;
    background-color: transparent !important;
    height: 35px !important;
  }
  .rc-tabs-nav-list {
    justify-content: space-between;

  }
  .rc-tabs-nav-operations {
    display: none !important;
  }

  .rc-tabs-tab {
    margin-left: 20px !important;


    &:first-child {
      margin-left: 0px !important;
    }
  }

  @media screen and (max-width: 1400px) {
    .rc-tabs-tab {
      margin-left: 0px !important;
      margin-right: 15px !important;
    }
 
  }

  .rc-tabs-tab-btn {
    padding: 5px 0 !important;
  }

  .rc-tabs-ink-bar {
    background-color: #F1AA3E !important;
    border-radius: 2px;
  }
`;
const TabsStyled = styled(CommonTabPane)`
  
`;

const TabTitleContainer = styled.div<{active: boolean}>`
  display: flex;

  .tabTitle {
    font-weight: 400;
    color: ${p => p.active ? '#fff' : '#A3A4A4'}
  }


  .tabExtraInfo {
    padding-bottom: 8px;

    .tabUsageContainer {
      display: flex;

      .usageValue {
        font-size: 18px;
        font-weight: 600;
        color: ${p => p.active ? '#fff' : '#A3A4A4'}
      }

      .totalValue {
        span {
          font-size: 12px;
          color: #A3A4A4;
        }
      }
    }
  }

`;


