import {BacklinkAnalyzerClient} from '@/api/backlink-analyzer';
import {AllProjectsApiResponse} from '@/api/backlink-analyzer/backlink-analyzer.model';
import {sitesApi} from '@/api/gsc';
import {useErrorNotification} from '@/utils/notification-v2';
import {getDomain} from '@/utils/url';
import {toJS} from 'mobx';
import {Instance, cast, flow, getParent, types} from 'mobx-state-tree';
import numeral from 'numeral';

const ModalModel = types.model({
  isOpen: types.optional(types.boolean, false),
  loading: types.optional(types.boolean, false),
  deleteOpen: types.optional(types.boolean, false),
}).actions(self => {
  const openModal = () => {
    self.isOpen = true;
  };
  const openDeleteModal = () => {
    self.deleteOpen = true;
  };

  const closeModal = () => {
    self.isOpen = false;
  };
  const closeDeleteModal = () => {
    self.deleteOpen = false;
  };

  const createProject = flow(function* (hostname: string) {
    const parent = getParent<HomeStoreType>(self);

    try {
      self.loading = true;
      const resp: AllProjectsApiResponse = yield BacklinkAnalyzerClient.createProject({primaryWebsite: {hostname}, competitorWebsites: []});
      const projects: AllProjectsApiResponse[] = yield BacklinkAnalyzerClient.getAllProjects();
      parent.setBlProjects(projects);
      self.loading = false;
      return resp;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });
  const deleteProject = flow(function* (projectPk: number) {
    const parent = getParent<HomeStoreType>(self);
    try {
      self.loading = true;
      const resp: AllProjectsApiResponse = yield BacklinkAnalyzerClient.deleteProject(projectPk);
      parent.removeBlProject(projectPk);
      self.loading = false;
      return resp;
    } catch (e) {
      useErrorNotification({
        e,
        msg: 'Could not delete project.',
        desc: 'Try again later.',
        permanent: false,
        handleStatuses: [{
          statuses: [401, 403],
          msg: 'Please sign up to edit this article.',
          permanent: false,
          showDetails: false,
        }],
      });
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });
  const archiveProject = flow(function* (projectPk: number) {
    try {
      self.loading = true;
      const resp: AllProjectsApiResponse = yield sitesApi.archiveSite(projectPk);
      self.loading = false;
      return resp;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loading = false;
    }
  });


  return {
    archiveProject,
    openModal,
    closeModal,
    openDeleteModal,
    closeDeleteModal,
    createProject,
    deleteProject,
  };
});

const AnchorText = types.model({
  label: types.string,
  total: types.maybeNull(types.number),
  percentage: types.number,
}).views(self => ({
  get totalFormat() {
    return self.total ? numeral(self.total).format('0,0') : '-';
  },
}));

const TopCategories = types.model({
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  score: types.maybeNull(types.number),
}).views(self => ({
  get scoreFormat() {
    return self.score ? numeral(self.score).format('0,0') : '-';
  },
}));

const Categories = types.model({
  name: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  score: types.maybeNull(types.number),
}).views(self => ({
  get scoreFormat() {
    return self.score ? numeral(self.score).format('0,0') : '-';
  },
}));

const CategoryData = types.model({
  categories: types.array(Categories),
  topCategory: TopCategories,
});

const ClicksData = types.model({
  date: types.maybeNull(types.string),
  clicks: types.maybeNull(types.number),
});

const AvgPositiondata = types.model({
  countryCode: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

const TrafficData = types.model({
  date: types.maybeNull(types.string),
  trafficValue: types.maybeNull(types.number),
});

const ImpressionData = types.model({
  date: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
});

const KeywordData = types.model({
  date: types.maybeNull(types.string),
  kwCount: types.maybeNull(types.number),
});

const KeywordPosition = types.model({
  name: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

const positionHistogramDataModel = types.model({
  date: types.maybeNull(types.string),
  serp1: types.maybeNull(types.number),
  serp2To3: types.maybeNull(types.number),
  serp4To10: types.maybeNull(types.number),
  serp11To20: types.maybeNull(types.number),
  serp2150: types.maybeNull(types.number),
  serp51100: types.maybeNull(types.number),
});

const WebsiteDetailsModel = types.model({
  id: types.maybeNull(types.number),
  hostname: types.string,
  refdomainCount: types.maybeNull(types.number),
  keywordCount: types.maybeNull(types.number),
  trafficvalue: types.maybeNull(types.number),
  activeCountryCodes: types.maybeNull(types.array(types.string)),
  keywordData: types.maybeNull(types.array(KeywordData)),
  nonProjectedKeywordData: types.maybeNull(types.array(KeywordData)),
  impressionData: types.maybeNull(types.array(ImpressionData)),
  trafficData: types.maybeNull(types.array(TrafficData)),
  clicksData: types.maybeNull(types.array(ClicksData)),
  avgPosition: types.maybeNull(types.array(AvgPositiondata)),
  impressionsHistogramsPctChange: types.maybeNull(types.number),
  organicTrafficHistogramsPctChange: types.maybeNull(types.number),
  screenshotImage: types.maybeNull(types.string),
  ot: types.maybeNull(types.number),
  dr: types.maybeNull(types.number),
  color: types.optional(types.string, ''),
  topPages: types.maybeNull(types.array(types.string)),
  countryCodesAndTrafficValue: types.maybeNull(types.array(types.model({
    countryCode: types.maybeNull(types.string),
    value: types.maybeNull(types.number),
  }))),
  anchorTextDistribution: types.maybeNull(types.array(AnchorText)),
  categoryData: types.maybeNull(CategoryData),
  url: types.maybeNull(types.string),
  reportsForCountry: types.maybeNull(types.string),
  toxicityPct: types.maybeNull(types.number),
  keywordPosition: types.maybeNull(types.array(KeywordPosition)),
  positionHistogramData: types.maybeNull(types.array(positionHistogramDataModel)),
  activeForCurrentCustomer: types.maybeNull(types.boolean),
}).views(self => ({
  get formattedValue() {
    return {
      hostname: getDomain(self.hostname),
      ot: self.ot ? numeral(self.ot).format('0,0') : '-',
      ok: self.keywordCount ? numeral(self.keywordCount).format('0,0') : '-',
      rd: self.refdomainCount ? numeral(self.refdomainCount).format('0,0') : '-',
      color: self.color,
    };
  },
  get domainName() {
    return getDomain(self.hostname);
  },
  get pieSeriesData() {
    const data = self.categoryData?.categories.map(category => {
      return {value: category.percentage, name: category.name, score: category.score};
    });

    return {
      name: '',
      type: 'pie',
      radius: ['50%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'left',
      },
      itemStyle: {
        borderRadius: 1,
        borderColor: '#2D2F34',
        borderWidth: 2,
      },
      labelLine: {
        show: false,
      },
      data,
    };
  },
}));

export const ProjectModel = types.model({
  pk: types.maybeNull(types.identifierNumber),
  isGscProperty: types.optional(types.boolean, false),
  primaryWebsite: WebsiteDetailsModel,
}).actions(self => {
  const setIsGscProperty = (isGscProperty: boolean) => {
    self.isGscProperty = isGscProperty;
  };

  const setStatsData = (statsData, url) => {
    self.primaryWebsite.keywordData = cast(statsData.keywordCountHistoricalChart?.map(data => {
      return {date: data.date, kwCount: data.kwCount};
    }));
    self.primaryWebsite.impressionData = cast(statsData.dailySerpHistogramsAndClicks?.map(data => {
      return {date: data.date, impressions: data.impressions};
    }));
    self.primaryWebsite.clicksData = cast(statsData.dailySerpHistogramsAndClicks?.map(data => {
      return {date: data.date, clicks: data.clicks};
    }));
    self.primaryWebsite.trafficData = cast(statsData.dailySerpHistogramsAndClicks?.map(data => {
      return {date: data.date, trafficValue: data.trafficValue};
    }));
    self.primaryWebsite.refdomainCount = statsData.totalImpressions;
    self.primaryWebsite.ot = statsData.totalTraffic;
    self.primaryWebsite.keywordCount = statsData.totalKeywords?.current;
    self.primaryWebsite.trafficvalue = statsData.trafficValue;
    self.primaryWebsite.topPages = statsData.topPages?.map(value => value.url);
    self.primaryWebsite.url = url;
    const dailySerpHistogramsAndClicks = statsData.dailySerpHistogramsAndClicks[statsData.dailySerpHistogramsAndClicks?.length-1];
    self.primaryWebsite.keywordPosition = cast([
      {
        name: '# 1-3',
        value: numeral(dailySerpHistogramsAndClicks?.serp1To3).format('0a'),
      },
      {
        name: '# 4-10',
        value: numeral(dailySerpHistogramsAndClicks?.serp4To10).format('0a'),
      },
      {
        name: '# 11-20',
        value: numeral(dailySerpHistogramsAndClicks?.serp11To20).format('0a'),
      },
      {
        name: '# 20+',
        value: numeral(dailySerpHistogramsAndClicks?.serp21).format('0a'),
      },
    ]);
  };

  return {
    setStatsData,
    setIsGscProperty,
  };
});

export const propertiesModel = types.model({
  countryBreakdownLastFetchedAt: types.maybeNull(types.string),
  countryBreakdownStatus: types.maybeNull(types.string),
  deviceBreakdownLastFetchedAt: types.maybeNull(types.string),
  deviceBreakdownStatus: types.maybeNull(types.string),
  evotLastFetchedAt: types.maybeNull(types.string),
  evotTaskStatus: types.maybeNull(types.string),
  favicon: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isProcessingComplete: types.maybeNull(types.boolean),
  monthlyCost: types.maybeNull(types.string),
  saDataLoadTimeLeft: types.maybeNull(types.number),
  saDataPoints: types.maybeNull(types.number),
  saHistoricalLastFetchedAt: types.maybeNull(types.string),
  saHistoricalStatus: types.maybeNull(types.string),
  saMostrecentDataDt: types.maybeNull(types.string),
  saOldestDataDt: types.maybeNull(types.string),
  saPctProgress: types.maybeNull(types.number),
  saTotalKwCount: types.maybeNull(types.number),
  searchAppearanceLastFetchedAt: types.maybeNull(types.string),
  searchAppearanceStatus: types.maybeNull(types.string),
  sitemapsLastFetchedAt: types.maybeNull(types.string),
  sitemapsStatus: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  trafficValue: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const emailSettingsV2Modal = types.model({
  emailType: types.maybeNull(types.string),
  freq: types.maybeNull(types.number),
  // lastSent: types.maybeNull(types.number),
  recipients: types.maybeNull(types.array(types.string)),
  emailEnabled: types.maybeNull(types.boolean),
  id: types.maybeNull(types.union(types.number, types.string)),
});

export const HomeStore = types.model({
  loading: types.optional(types.boolean, false),
  gscArchiveLoading: types.optional(types.boolean, false),
  blProjectLoading: types.optional(types.boolean, false),
  nextProject: types.optional(types.boolean, true),
  blProjects: types.array(ProjectModel),
  countryCode: types.array(types.string),
  users: types.maybeNull(types.array(types.model({
    id: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
  }))),
  count: types.maybeNull(types.number),
  modal: ModalModel,
  videoPlayerModal: types.boolean,
  manageColumnDrawer: types.boolean,
  emailSettingsV2: types.maybeNull(types.array(emailSettingsV2Modal)),
  tabelLoading: types.maybeNull(types.boolean),
  selectedCountryCode: types.maybeNull(types.string),
  isChild: types.maybeNull(types.boolean),
})
  .views(self => ({
    get allProjectsBl() {
      return self.blProjects.filter(site => site != undefined);
    },
    get getEmailSettingsV2() {
      return toJS(self.emailSettingsV2);
    },
  }))
  .actions(self => {
    const loadBLProjects = flow(function* () {
      try {
        self.blProjectLoading = true;
        const response: AllProjectsApiResponse[] = yield BacklinkAnalyzerClient.getAllProjects();
        const projects = Array.isArray(response) ? response : [];
        const result = projects?.map(project => {
          return {
            ...project,
            primaryWebsite: {
              ...project.primaryWebsite,
              anchorTextDistribution: project.primaryWebsite.anchorTextDistribution.data,
              categoryData: project.primaryWebsite.category.data,
              url: project.primaryWebsite.hostname,
            },
          };
        });
        self.blProjects = cast(result.filter(site => site != undefined));
      } catch (e) {
        self.blProjectLoading = false;
        self.blProjects = cast([]);
        Promise.reject(e);
      } finally {
        self.blProjectLoading = false;
      }
    });


    const filterProjectsBl = (search, sortOrder) => {
      return self.blProjects.filter(project => {
        return search ? getDomain(project.primaryWebsite.hostname).includes(search) : true;
      }).sort((a, b) => {
        if (sortOrder === '') {
          return 0;
        }
        const _dir = sortOrder === 'refdomainCount' ? 1 : -1;
        if (a.primaryWebsite.refdomainCount < b.primaryWebsite.refdomainCount) return 1 * _dir;
        if (a.primaryWebsite.refdomainCount > b.primaryWebsite.refdomainCount * _dir) return -1 * _dir;
        return 0;
      });
    };

    const removeBlProject = projectId => {
      const index = self.blProjects.findIndex(item => item.pk === projectId);
      self.blProjects.splice(index, 1);
    };

    const setBlProjects = (data: any[]) => self.blProjects = cast(data.filter(site => site != undefined));

    // Function for GSC properties

    const filterProjectsGSC = (data, search, sortOrder) => {
      return data?.filter(project => {
        return search ? getDomain(project.primaryWebsite.hostname).includes(search) : true;
      }).sort((a, b) => {
        if (sortOrder === '') {
          return 0;
        }
        const _dir = sortOrder === 'refdomainCount' ? 1 : -1;
        if (a.primaryWebsite.refdomainCount < b.primaryWebsite.refdomainCount) return 1 * _dir;
        if (a.primaryWebsite.refdomainCount > b.primaryWebsite.refdomainCount * _dir) return -1 * _dir;
        return 0;
      });
    };

    // const setGscProject = properties =>{
    //   const GscProperties = properties.sites?.map(property => {
    //     const data = {
    //       pk: property.id,
    //       isGscProperty: true,
    //       primaryWebsite: getPrimaryWebsite(property),
    //     };
    //     return data;
    //   });

    //   const gscList = [...GscProperties].filter(site => site !== undefined && site.length !== 0);
    //   self.gscProjects = cast(gscList);
    // };

    const setCountryCode = (value: string[]) => {
      self.countryCode = cast(value);
    };

    const loadEmailSettingV2 = flow(function* (id: number) {
      self.tabelLoading= true;
      try {
        const response = yield sitesApi.getEmailSettingv2(id, self.selectedCountryCode);
        const data = response?.map((item, idx) => {
          return {
            id: idx,
            emailEnabled: !!item?.recipients?.length,
            ...item,
          };
        });
        self.emailSettingsV2 = cast(data);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.tabelLoading= false;
      }
    });

    const loadUpdateEmailSettingsV2 = flow(function* (id: number) {
      self.tabelLoading= true;
      const formatedList = toJS(self.emailSettingsV2)?.map(item => {
        return {
          email_type: item?.emailType,
          recipients: item?.emailEnabled ? item?.recipients : [],
        };
      });
      try {
        const response = yield sitesApi.updateEmailSettingsV2(id, formatedList, self.selectedCountryCode);
        const data = response?.map((item, idx) => {
          return {
            id: idx,
            emailEnabled: !!item?.recipients?.length,
            ...item,
          };
        });
        self.emailSettingsV2 = cast(data);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        self.tabelLoading= false;
      }
    });

    const updateListEmailSetting = data => {
      self.emailSettingsV2 = cast(data);
    };

    const setVideoPlayerModal = value => {
      self.videoPlayerModal = value;
    };

    const setManageColumnDrawer = value => {
      self.manageColumnDrawer = value;
    };
    const getInialCountryCode = value => {
      self.selectedCountryCode = value;
    };
    const getCountyCode = async (id, value) => {
      self.selectedCountryCode= value;
      await loadEmailSettingV2(id);
    };

    const setIsChildAccount = (value: boolean) => {
      self.isChild = value;
    };

    return {
      loadEmailSettingV2,
      getCountyCode,
      getInialCountryCode,
      loadUpdateEmailSettingsV2,
      updateListEmailSetting,
      loadBLProjects,
      setManageColumnDrawer,
      setBlProjects,
      removeBlProject,
      filterProjectsGSC,
      filterProjectsBl,
      setCountryCode,
      setVideoPlayerModal,
      setIsChildAccount,
    };
  });

export const initHomeStore = () => {
  return {
    selectedCountryCode: '',
    loading: false,
    gscArchiveLoading: false,
    manageColumnDrawer: false,
    videoPlayerModal: false,
    blProjectLoading: true,
    nextProject: true,
    blProjects: [],
    gscProjects: [],
    countryCode: [],
    payloadSiteproperties: [],
    emailSettings: null,
    users: [],
    modal: {
      isOpen: false,
      loading: false,
    },
    parmas: {
      date: '',
      ordering: '',
      search: '',
    },
    isChild: false,
  };
};

export type HomeStoreType = Instance<typeof HomeStore>;
