import {cast, flow, Instance, types} from 'mobx-state-tree';
import {KeywordDetails} from './keyword-details';
import {OVERVIEW_DETAIL_API} from '@/api/competitor-explorer';
import {toJS} from 'mobx';
import {COMPETITOR_RESEARCHER_API_POLL_INTERVAL} from '@/constants';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';

const FilterValue = types.model({
  min: types.maybeNull(types.number),
  max: types.maybeNull(types.number),
});

export type Identifier = 'position' | 'kd' | 'sv' | 'traffic' | 'cpc' | 'ppc' | '';

export const OverviewFilters = types.model({
  active: types.union(
    types.literal('position'),
    types.literal('kd'),
    types.literal('sv'),
    types.literal('traffic'),
    types.literal('cpc'),
    types.literal('ppc'),
    types.literal(''),
  ),
  position: types.maybeNull(FilterValue),
  kd: types.maybeNull(FilterValue),
  sv: types.maybeNull(FilterValue),
  traffic: types.maybeNull(FilterValue),
  cpc: types.maybeNull(FilterValue),
  ppc: types.maybeNull(FilterValue),
  query: types.string,
});
export const countryDistributionModel = types.model({
  country: types.maybeNull(types.string),
  percent: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
});

export const historicalPositionModel = types.model({
  pos1: types.maybeNull(types.number),
  pos410: types.maybeNull(types.number),
  pos1120: types.maybeNull(types.number),
  pos2130: types.maybeNull(types.number),
  pos3140: types.maybeNull(types.number),
  pos4150: types.maybeNull(types.number),
  pos51100: types.maybeNull(types.number),
});

export const historicalRankDataModel = types.model({
  date: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  positions: types.maybeNull(historicalPositionModel),
  traffic: types.maybeNull(types.number),
});

export const historicalRankModel = types.model({
  organic: types.maybeNull(types.array(historicalRankDataModel)),
  paid: types.maybeNull(types.array(historicalRankDataModel)),
});

export const categoryDistributionModel = types.model({
  category: types.maybeNull(types.union(types.string, types.number)),
  keywordsCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  trafficIndexPct: types.maybeNull(types.number),
});

export const organicCompetitorsModel = types.model({
  commonKeywords: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  keywordsCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
});

export const allCategories = types.model({
  category: types.maybeNull(types.string),
  categoryId: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  impressionsTraffic: types.maybeNull(types.number),
});

export const monthlySearchesModel = types.model({
  month: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  year: types.maybeNull(types.number),
});

export const keywordInfoModel = types.model({
  categories: types.maybeNull(types.array(types.maybeNull(types.number))),
  competition: types.maybeNull(types.number),
  // competitionLevel: null
  cpc: types.maybeNull(types.number),
  // highTopOfPageBid: null
  keyword: types.maybeNull(types.string),
  lastUpdatedTime: types.maybeNull(types.string),
  // lowTopOfPageBid: null
  monthlySearches: types.maybeNull(types.array(monthlySearchesModel)),
  seType: types.maybeNull(types.string),
  searchVolume: types.maybeNull(types.number),
});


export const rankingKeywordsChartData = types.model({
  pos1: types.maybeNull(types.number),
  pos410: types.maybeNull(types.number),
  pos1120: types.maybeNull(types.number),
  pos2130: types.maybeNull(types.number),
  pos3140: types.maybeNull(types.number),
  pos4150: types.maybeNull(types.number),
  pos51100: types.maybeNull(types.number),
});

export const rankingKeywordsData = types.model({
  keywordInfo: types.maybeNull(keywordInfoModel),
  rank: types.maybeNull(types.number),
  rankingUrl: types.maybeNull(types.string),
  serpFeatures: types.maybeNull(types.array(types.string)),
  traffic: types.maybeNull(types.number),
  trafficIndexPct: types.maybeNull(types.number),
});

export const rankingKeywordsModel = types.model({
  chartData: types.maybeNull(rankingKeywordsChartData),
  data: types.maybeNull(types.array(rankingKeywordsData)),
});
export const relevantPagesModel = types.model({
  keywordsCount: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  trafficIndexPct: types.maybeNull(types.number),
});

export const subDomainsModel = types.model({
  keywordsCount: types.maybeNull(types.number),
  subdomain: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  trafficIndexPct: types.maybeNull(types.number),
});

export const researcherDetailDataModel = types.model({
  categoryDistribution: types.maybeNull(types.array(allCategories)),
  countryCode: types.maybeNull(types.string),
  countryDistribution: types.maybeNull(types.array(countryDistributionModel)),
  domainRating: types.maybeNull(types.number),
  historicalRank: types.maybeNull(historicalRankModel),
  id: types.maybeNull(types.number),
  initialProcessingStatus: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  mode: types.maybeNull(types.string),
  organicCompetitors: types.maybeNull(types.array(organicCompetitorsModel)),
  rankingKeywords: types.maybeNull(rankingKeywordsModel),
  relevantPages: types.maybeNull(types.array(relevantPagesModel)),
  screenshotUrl: types.maybeNull(types.string),
  subdomains: types.maybeNull(types.array(subDomainsModel)),
  title: types.maybeNull(types.string),
  totalOrganicCompetitorsCount: types.maybeNull(types.number),
  totalRankingKeywordsCount: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
  totalRelevantPagesCount: types.maybeNull(types.number),
  totalSubdomainsCount: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

export const targetPageDetailDataModel = types.model({
  countryCode: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  mode: types.maybeNull(types.string),
  initialProcessingStatus: types.maybeNull(types.string),
  rankingKeywords: types.maybeNull(rankingKeywordsModel),
  traffic: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

export const competitorResearchDetailModel = types.model({
  competitorResearch: types.maybeNull(researcherDetailDataModel),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  searchedOn: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  shouldShowHistogram: types.maybeNull(types.boolean),
});

export const targetPageResearchDetailModel = types.model({
  competitorResearch: types.maybeNull(targetPageDetailDataModel),
  customerId: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  searchedOn: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
});


export const OverviewStore = types
  .model({
    loadingDetail: types.boolean,
    loadingSubDomainsDetail: types.boolean,
    loadingRankingKeywordsDetail: types.boolean,
    loadingDrawerData: types.boolean,
    loadingRelevantPagesDetail: types.boolean,
    individualUrlDrawer: types.boolean,
    loadingOrganicCompetitorsDetail: types.boolean,
    loadingDetailTags: types.boolean,
    isTargetPage: types.boolean,
    mainLoader: types.boolean,
    data: types.array(KeywordDetails),
    filters: OverviewFilters,
    competitorResearchDetail: types.maybeNull(competitorResearchDetailModel),
    targetPageResearchDetail: types.maybeNull(targetPageResearchDetailModel),
    detailApiCallCheck: types.optional(types.boolean, false),
    allSubDomains: types.maybeNull(types.array(subDomainsModel)),
    countAllSubDomains: types.maybeNull(types.number),
    allRankingKeywords: types.maybeNull(rankingKeywordsModel),
    allRankingKeywordsCount: types.maybeNull(types.number),
    allRelevantPages: types.maybeNull(types.array(relevantPagesModel)),
    countAllRelevantPages: types.maybeNull(types.number),
    allOrganicCompetitors: types.maybeNull(types.array(organicCompetitorsModel)),
    countAllOrganicCompetitors: types.maybeNull(types.number),
    allCategories: types.maybeNull(types.array(allCategories)),
    countAllCategories: types.maybeNull(types.number),
    activeTab: types.maybeNull(types.string),
    individualUrlDrawerPage: types.maybeNull(types.string),
    individualUrlDrawerUrl: types.maybeNull(types.string),
    filteredCountRankingKeyword: types.maybeNull(types.number),
    isError: types.maybeNull(types.boolean),
    showExportModal: types.maybeNull(types.boolean),
    tabCount: types.maybeNull(types.number),
  }).actions(self => {
    const getCompetitorResearcherData = flow(function* (id) {
      self.loadingDetailTags = true;
      self.loadingDetail = true;
      self.isError = false;
      const publicHash = getSingleUrlParam('public_hash');
      try {
        const response = yield OVERVIEW_DETAIL_API.getCompetitorResearchDetail(id, publicHash);
        const metricsStatus = response?.competitorResearch?.initialProcessingStatus == 'PENDING';
        if (response?.competitorResearch?.mode === 'target_page' || response?.competitorResearch?.mode === 'subdomain') {
          self.isTargetPage = true;
        } else {
          self.isTargetPage = false;
        }
        self.mainLoader = false;

        if (metricsStatus && self.detailApiCallCheck) {
          yield new Promise(r => setTimeout(r, COMPETITOR_RESEARCHER_API_POLL_INTERVAL));
          return getCompetitorResearcherData(id);
        } else {
          if (response?.competitorResearch?.initialProcessingStatus === 'FAILURE') {
            notification?.error('Processing FAILED', `Fail to Process ${response?.competitorResearch?.mode} - ${response?.competitorResearch?.url}`);
          }

          if (response?.competitorResearch?.mode === 'target_page' || response?.competitorResearch?.mode === 'subdomain') {
            self.targetPageResearchDetail = cast(response);
          } else {
            self.competitorResearchDetail = cast(response);
          }

          self.loadingDetailTags = false;
          self.loadingDetail = false;
        }
      } catch (e) {
        if (e?.message == 'Request failed with status code 404') {
          self.isError = true;
        }
        return Promise.reject(e);
      }
    });

    const setTabCount = value=>{
      self.tabCount = value;
    };

    const setLoadingDetail = value =>{
      self.loadingDetailTags = value;
      self.loadingDetail = value;
    };

    const activeMainLoader = ()=>{
      self.loadingDetailTags = true;
      self.mainLoader = true;
    };
    const getCompetitorResearcherDetail = (id:any)=>{
      activeMainLoader();
      getCompetitorResearcherData(id);
    };

    const recrawlCompetitorResearcherDetail = flow(function* (id) {
      self.loadingDetailTags = true;
      self.loadingDetail = true;
      try {
        const response = yield OVERVIEW_DETAIL_API.recrawlCompetitorResearchDetail(id);
        if (response) {
          getCompetitorResearcherData(id);
        }
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const setDetailApiCall = value => {
      self.detailApiCallCheck = value;
    };

    const getAllSubDomains = flow(function* (id, pageNumber) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'subdomains',
        page: pageNumber,
      };
      if (pageNumber === 1) {
        self.loadingSubDomainsDetail = true;
        self.allSubDomains = [] as any;
      }
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getSubDomainsDetail(id, payLoad, publicHash);
          if (response) {
            self.countAllSubDomains = response?.totalResults;
            self.allSubDomains = cast((pageNumber === 1) ? response?.data : [...toJS(self.allSubDomains), ...response.data]);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingSubDomainsDetail = false;
        }
      }
    });

    const getAllRankingKeywordsIndividualURL = flow(function* (id, pageNumber) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'ranking_keywords',
        page: pageNumber,
      };
      // if (pageNumber === 1) {
      //   self.loadingRankingKeywordsDetail = true;
      // }
      if (self.targetPageResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getRankingKeywordsDetail(id, payLoad, publicHash);
          if (response?.data) {
            if (pageNumber == 1) {
              self.targetPageResearchDetail.competitorResearch.rankingKeywords.data = cast(response?.data);
            } else {
              self.targetPageResearchDetail.competitorResearch.rankingKeywords.data.push(...response.data);
            }
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingRankingKeywordsDetail = false;
        }
      }
    });

    const getAllRankingKeywords = flow(function* (id, pageNumber) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'ranking_keywords',
        page: pageNumber,
      };
      if (pageNumber === 1) {
        self.loadingRankingKeywordsDetail = true;
        self.allRankingKeywords = null;
      }
      self.filteredCountRankingKeyword = 0;
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getRankingKeywordsDetail(id, payLoad, publicHash);
          if (response) {
            self.allRankingKeywordsCount = response?.totalResults;
            self.allRankingKeywords = response?.data;
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingRankingKeywordsDetail = false;
        }
      }
    });

    const getSubDomainDetail = flow(function* (id, page) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        subdomain: page,
      };
      self.loadingDrawerData = true;
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getSubDomainDetailData(id, payLoad, publicHash);
          if (response) {
            self.allRankingKeywords = cast(response);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingDrawerData = false;
        }
      }
    });

    const getRelevantPageDetail = flow(function* (id, page) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        relevant_page: page,
      };
      self.loadingDrawerData = true;
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getRelevantPageDetailData(id, payLoad, publicHash);
          if (response) {
            self.allRankingKeywords = cast(response);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingDrawerData = false;
        }
      }
    });

    const getRankingKeywordsFilteredData = flow(function* (id, params) {
      const publicHash = getSingleUrlParam('public_hash');
      self.loadingRankingKeywordsDetail = true;
      self.allRankingKeywords = null;
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getRankingKeywordsFilteredDetail(id, params, publicHash);
          if (response) {
            self.allRankingKeywords = cast(response?.data);
            self.filteredCountRankingKeyword = response?.totalResults;
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingRankingKeywordsDetail = false;
        }
      }
    });

    const getKeywordsFilteredDataIndividualURL = flow(function* (id, params) {
      const publicHash = getSingleUrlParam('public_hash');
      self.loadingRankingKeywordsDetail = true;
      self.targetPageResearchDetail.competitorResearch.rankingKeywords.data = null;
      try {
        const response = yield OVERVIEW_DETAIL_API.getRankingKeywordsFilteredDetail(id, params, publicHash);
        if (response) {
          self.targetPageResearchDetail.competitorResearch.rankingKeywords.data = cast(response?.data?.data);
        }
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingRankingKeywordsDetail = false;
      }
    });


    const getAllRelevantPages = flow(function* (id, pageNumber) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'relevant_pages',
        page: pageNumber,
      };
      if (pageNumber === 1) {
        self.loadingRelevantPagesDetail = true;
        self.allRelevantPages = null;
      }
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getRelevantPagesDetail(id, payLoad, publicHash);
          if (response) {
            self.countAllRelevantPages = response?.totalResults;
            self.allRelevantPages = cast((pageNumber === 1) ? response?.data : [...toJS(self.allRelevantPages), ...response.data]);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingRelevantPagesDetail = false;
        }
      }
    });

    const getAllOrganicCompetitors = flow(function* (id, pageNumber) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'organic_competitors',
        page: pageNumber,
      };
      if (pageNumber === 1) {
        self.loadingOrganicCompetitorsDetail = true;
        self.allOrganicCompetitors = [] as any;
      }
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getOrganicCompetitorsDetail(id, payLoad, publicHash);
          if (response) {
            self.countAllOrganicCompetitors = response?.totalResults;
            self.allOrganicCompetitors = cast((pageNumber === 1) ? response?.data : [...toJS(self.allOrganicCompetitors), ...response.data]);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingOrganicCompetitorsDetail = false;
        }
      }
    });

    const getAllCategories = flow(function* (id, pageNumber) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        context: 'categories',
        page: pageNumber,
      };
      if (pageNumber === 1) {
        self.loadingOrganicCompetitorsDetail = true;
        self.allOrganicCompetitors = [] as any;
      }
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.getAllCategories(id, payLoad, publicHash);
          if (response) {
            self.countAllCategories = response?.totalResults;
            self.allCategories = cast((pageNumber === 1) ? response?.data : [...toJS(self.allCategories), ...response.data]);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingOrganicCompetitorsDetail = false;
        }
      }
    });

    const getIndividualCategoryData = flow(function* (id, categoryId) {
      const publicHash = getSingleUrlParam('public_hash');
      const payLoad = {
        category: categoryId,
      };
      self.loadingDrawerData = true;
      if (self.competitorResearchDetail?.competitorResearch?.initialProcessingStatus == 'SUCCESS') {
        try {
          const response = yield OVERVIEW_DETAIL_API.individualCategoryData(id, payLoad, publicHash);
          if (response) {
            self.allRankingKeywords = cast(response);
          }
        } catch (e) {
          return Promise.reject(e);
        } finally {
          self.loadingDrawerData = false;
        }
      }
    });

    const mapKeywords = (keywords: any) => {
      return JSON.parse(JSON.stringify(keywords))?.map(item => ({
        keyword: item?.keyword,
        histSv: item.histSv || [],
        url: item.url ? item.url : null,
        sv: item.sv ? item.sv : null,
        kd: item.kd ? item.kd : null,
        position: item.position ? item.position : null,
        traffic: item.traffic ? item.traffic : null,
        cpc: item.cpc ? item.cpc : null,
        ppc: item.ppc ? item.ppc : null,
        ppcd: item.ppcd ? item.ppcd : null,
      }));
    };

    const resetOverviewData = () => {
      self.data = mapKeywords([]);
      // @ts-ignore
      self.filters = {
        active: '' as Identifier,
        kd: null,
        sv: null,
        traffic: null,
        cpc: null,
        query: '',
      };
    };

    const setActiveTab = (key : string) => {
      self.activeTab = key;
    };

    const setIndividualUrlDrawer = (val, pageUrl, page) => {
      if (val) {
        self.loadingDrawerData = true;
      }
      self.individualUrlDrawer = val;
      self.individualUrlDrawerPage = page;
      self.individualUrlDrawerUrl = pageUrl;
    };

    const clearAllOrganicCompetitors = ()=>{
      self.allOrganicCompetitors = null;
      self.allRelevantPages = null;
      self.allRankingKeywords = null;
      self.allSubDomains = null;
      self.competitorResearchDetail = null;
      self.targetPageResearchDetail = null;
    };

    const setTargetPage = value=>{
      if (value == 'target_page' || value == 'subdomain') {
        self.isTargetPage = true;
      } else {
        self.isTargetPage = false;
      }
    };

    const setShowExportModal = value=>{
      self.showExportModal = value;
    };

    return {
      getCompetitorResearcherDetail,
      getKeywordsFilteredDataIndividualURL,
      activeMainLoader,
      recrawlCompetitorResearcherDetail,
      getAllSubDomains,
      getRankingKeywordsFilteredData,
      setDetailApiCall,
      getAllRankingKeywords,
      getAllRankingKeywordsIndividualURL,
      getAllRelevantPages,
      getSubDomainDetail,
      getRelevantPageDetail,
      getAllOrganicCompetitors,
      resetOverviewData,
      setActiveTab,
      clearAllOrganicCompetitors,
      setTabCount,
      setIndividualUrlDrawer,
      setTargetPage,
      getAllCategories,
      getIndividualCategoryData,
      setShowExportModal,
      setLoadingDetail,
    };
  });

export type OverviewStoreInstance = Instance<typeof OverviewStore>;

export const initOverviewStore = () => {
  return {
    loadingDetail: true,
    loadingDetailTags: true,
    loadingDrawerData: false,
    individualUrlDrawer: false,
    loadingSubDomainsDetail: true,
    loadingRankingKeywordsDetail: false,
    loadingRelevantPagesDetail: true,
    loadingOrganicCompetitorsDetail: true,
    isTargetPage: false,
    mainLoader: false,
    filters: {
      active: '' as Identifier,
      kd: null,
      sv: null,
      traffic: null,
      cpc: null,
      ppcd: null,
      query: '',
    },
    data: [],
    activeTab: 'overview',
    individualUrlDrawerUrl: '',
    individualUrlDrawerPage: '',
    filteredCountRankingKeyword: 0,
    isError: false,
    showExportModal: false,
    allRankingKeywordsCount: 0,
    countAllRelevantPages: 0,
    countAllSubDomains: 0,
    countAllCategories: 0,
    tabCount: 0,
  };
};
