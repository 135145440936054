import {getTokenFromCookies} from '@/api/common-utils';
import {routes} from '@/utils/const';
import {linkgraphDomain} from '@/utils/const';

export function getAbsoluteLegacyUrl(path: string, withToken: boolean = true) {
  const token = getTokenFromCookies();
  if (!withToken) {
    return `https://legacy.${linkgraphDomain}/${path}`;
  }

  return `https://legacy.${linkgraphDomain}/${path}?legacy_token=${token}`;
}

export function getHubSpotLink() {
  return 'https://searchatlas.com/consultation/';
}
export function getRequestPricelink() {
  return 'https://searchatlas.com/consultation/';
}
export function getGrowBusinessLink() {
  return 'https://linkgraph.com/learn-how-we-can-grow-your-business/';
}
export function getKnowledgeCenterLink(lang:string) {
  return `https://help.linkgraph.com/${lang}/`;
}
export function getKnowledgeAcademyLink() {
  return `https://searchatlas.com/academy/`;
}
export function getSearchAtlasKnowledgeAcademyLink() {
  return `https://searchatlas.com/academy/`;
}

export function getAppleAppLink() {
  return ' https://apps.apple.com/us/app/searchatlas-seo/id1571340382';
}

export function getAndroidAppLink() {
  return 'https://play.google.com/store/apps/details?id=linkgraph.com';
}

export function getChromeAppLink() {
  return 'https://chrome.google.com/webstore/detail/linkgraphs-searchatlas/ahnhkdjjonmjlhbbgaaoehdnhlgcgbab';
}

export function getFullAccessLink() {
  return routes.pricing;
}
export function goToCheckout() {
  return routes.checkout;
}

