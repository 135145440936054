import {Drawer} from 'antd';
import {useStore} from '@/store/root-store';
import styles from './style.module.scss';
import {observer} from 'mobx-react';
import {useState} from 'react';
import {AiTemplates} from './AiTemplates';
import {toJS} from 'mobx';

interface Props {
  htmlToDraft: any;
}

export const AiContentToolsDrawer = observer(({htmlToDraft}: Props) => {
  const {contentOptimizer: {aiOutline}} = useStore('');
  const [showContentGeneratorPageTitle, setShowContentGeneratorPageTitle] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  // will contain full list of all API templates
  const [templatesList, setTemplatesList] = useState([]);

  // will contain initial templates list, that will be unchanged, used for searching
  const [initialTemplatesList, setInitialTemplatesList] = useState([]);

  // will searched input query
  const [searchQuery, setSearchQuery] = useState('');

  // will contain all selected categories to filter out templates results
  const [selectedCategories, setSelectedCatetories] = useState([]);


  const handleModalVisibiltyChange = async visible => {
    if (visible) {
      if (aiOutline.aiTemplatesController?.isStandaloneTool) {
        // adding close on backdrop click
        const getClientCoord = e => {
          return {
            x: e.clientX,
            y: e.clientY,
          };
        };

        document.querySelector('.ant-drawer-mask')?.addEventListener('click', event => {
          const {x, y} = getClientCoord(event);
          const elementMouseIsOver = document.elementFromPoint(x, y);

          if (!elementMouseIsOver?.closest('.ant-drawer-content-wrapper')) {
            aiOutline.setIsOpenAiContentToolsDrawer(false);
          }
        });

        // returning so the rest of code doesnt execute
        return;
      }


      // every time drawer is closed it should set standalone boolean to false,
      // so it doesnt interfere when drawer is used inside sca editor
      // every tempalte click in standalone tool should set this boolean to true again
      aiOutline.setAiTemplate({
        ...aiOutline.aiTemplatesController,
        isStandaloneTool: false,
        isUseMask: true,
      });

      if (!aiOutline.aiTemplates?.templates?.length) await aiOutline.getAiTemplatesList();
      // get ai settings so we can prepopulate tone of voice in templates forms
      aiOutline.getUserAiSettings();
    } else {
      aiOutline.clearTemplates();
      aiOutline.clearAiOutline();
      aiOutline.clearTemplateOutlines();
      aiOutline.clearV2AiOutline();
      setShowContentGeneratorPageTitle(false);
      setSettingsModalVisible(false);
      setTemplatesList([]);
      setInitialTemplatesList([]);
      setSelectedCatetories([]);
      setSearchQuery('');
      aiOutline.setAiTemplate({
        ...toJS(aiOutline.aiTemplatesController ?? []),
        showForm: false,
        templateType: '',
        templateIcon: '',
        templateDesc: '',
        templateName: '',
        fieldsSetup: [],
        defaultForm: false,
        defaultFormType: null,
        isStandaloneTool: false,
      });
    }
  };

  const onClose = () => {
    aiOutline.setIsOpenAiContentToolsDrawer(false);
  };

  return (
    <>
      <Drawer
        visible={aiOutline.isOpenAiContentToolsDrawer}
        afterVisibleChange={handleModalVisibiltyChange}
        placement='right'
        onClose={onClose}
        destroyOnClose={true}
        mask={aiOutline?.aiTemplatesController?.isUseMask ? aiOutline?.aiTemplatesController?.isUseMask : aiOutline?.aiTemplatesController?.isStandaloneTool}
        style={{maxWidth: '100vw'}}
        // when form is not visible we are not showing results, so drawer is narrower
        width={aiOutline.aiTemplatesController?.showForm ? '1200px' : '600px'}
        closable={false}
        // bodyStyle={{backgroundColor: '#F2F2F5', paddingLeft: '0', paddingRight: '0'}}
        className={styles.ContentToolsDrawer}
      >
        <AiTemplates htmlToDraft={htmlToDraft}
          showPageTitle={showContentGeneratorPageTitle}
          setShowPageTitle={setShowContentGeneratorPageTitle}
          settingsModalVisible={settingsModalVisible}
          setSettingsModalVisible={setSettingsModalVisible}
          templatesList={templatesList}
          setTemplatesList={setTemplatesList}
          initialTemplatesList={initialTemplatesList}
          setInitialTemplatesList={setInitialTemplatesList}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCatetories}
        />
      </Drawer>
    </>
  );
});
