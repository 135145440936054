import {useRouter} from 'next/router';
import React, {useState, useRef, useMemo} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {RECAPTCHA_KEY} from '../../../../constants';


export type CaptchaProps = {
  currentToken: string;
  theme?: 'light' | 'dark';
  onChange?: (token: string) => void;
  size?: any;
};

export const Captcha: React.FC<CaptchaProps> = ({
  currentToken,
  theme = 'light',
  onChange,
  size='',
}) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const router = useRouter();

  const resetRecaptcha = () => {
    captchaRef.current.reset();
    setRecaptchaToken('');
  };

  const handleCaptchaChange = (token: string) => {
    setRecaptchaToken(token);
    if (onChange) {
      onChange(token);
    }
  };

  useMemo(() => {
    if (currentToken !== recaptchaToken) {
      resetRecaptcha();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);


  return (
    <>
      <ReCAPTCHA
        ref={captchaRef}
        theme={theme}
        hl={router.locale}
        sitekey={RECAPTCHA_KEY}
        onChange={handleCaptchaChange}
        size={size}
      />
    </>
  );
};
