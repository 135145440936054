import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input, Select} from 'antd';
import {faImages, faRotate, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {CaretDownOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {camelToSnakeCase} from '@/utils/string';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {notification} from '@/utils/notification-v2';

export const EditPostModal = observer(({postData, setPostData, setIsModalOpen, setRefresh, setOpenGbpLibraryModal, openModal, phoneNumbers}) => {
  const buttonOptions = [{value: null, label: 'None'}, {value: 'BOOK', label: 'Book'}, {value: 'ORDER', label: 'Order online'}, {value: 'SHOP', label: 'Buy'}, {value: 'LEARN_MORE', label: 'Learn more'}, {value: 'SIGN_UP', label: 'Sign up'}, {value: 'CALL', label: 'Call now'}];
  // const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(null);
  const {
    settings: {customer: {getCustomerQuota}},
    ottoV2Store: {getOttoGBPAiGenerateImage, getOttoGBPAiGenerateContent, editOttoGBPPost},
  } = useStore('');
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const aiGenerateImage = async () => {
    try {
      await setLoader('ai-image');
      const response = await getOttoGBPAiGenerateImage('', postData?.location);
      await setPostData(prevState => ({...prevState, imageUrl: response}));
      getCustomerQuota();
    } finally {
      await setLoader(null);
    }
  };

  const aiGenerateContent = async () => {
    try {
      await setLoader('ai-content');
      const response = await getOttoGBPAiGenerateContent(postData?.content, postData?.location);
      await setPostData(prevState => ({...prevState, content: response}));
      getCustomerQuota();
    } finally {
      await setLoader(null);
    }
  };

  const removeImage = () => {
    setPostData(prevState => ({...prevState, imageUrl: null}));
  };

  const updatePost = async () => {
    try {
      setLoader('update');
      const payload = {};
      Object.entries(postData)?.map(item => {
        const key = camelToSnakeCase(item[0]);
        payload[key] = item[1];
      });
      await editOttoGBPPost(postData?.id, payload);
      await openModal({id: postData?.id, type: 'POST', location: postData?.location}, 'POST');
      notification.success('Success', 'Post updated successfully!');
      await setRefresh(true);
    } finally {
      setLoader(null);
    }
  };

  const openMediaLibrary = () => {
    setIsModalOpen(false);
    setOpenGbpLibraryModal(true);
  };
  return (
    <>
      <PostEditContainer>
        <div className='image-section'>
          <FieldLabel>Image</FieldLabel>
          <div className='image-sub-section'>
            <div className='post-image-container'>
              {(loader == 'image-file') ? <RingLoaderV2 width='500px' height='500px' fontSize={50} /> : <>
                {postData?.imageUrl ? <img className='post-image' src={postData?.imageUrl}/> : <div>No Image</div>}
              </>
              }</div>
            <div className='image-actions'>
              <div className='action' style={{cursor: loader && 'not-allowed'}} onClick={() => !loader && openMediaLibrary()}>
                <FontAwesomeIcon className='action-icon' icon={faImages}/> Open media library
              </div>
              <div className='action' style={{cursor: loader && 'not-allowed'}} onClick={() => !loader && aiGenerateImage()}><FontAwesomeIcon className='action-icon' spin={loader == 'ai-image'} icon={faRotate} /> AI Generate</div>
              <div className='action' style={{cursor: loader && 'not-allowed'}} onClick={() => !loader && removeImage()}><FontAwesomeIcon className='action-icon' icon={faTrash}/> Remove</div>
            </div>
          </div>
        </div>
        <div className='content-section'>
          <FieldLabel>Post Content</FieldLabel>
          <AIGenerateSection style={{cursor: loader && 'not-allowed'}} onClick={() => (!loader && aiGenerateContent())}><FontAwesomeIcon spin={loader == 'ai-content'} icon={faRotate}/> AI Generate another version</AIGenerateSection>
        </div>
        <Input.TextArea rows={4} value={postData?.content} onChange={val => setPostData(prevState => ({...prevState, content: val.target.value}))}/>
        <FieldLabel>Add a button (optional)</FieldLabel>
        <Select className='button-select' value={postData?.callToActionType} onChange={val => setPostData(prevState => ({...prevState, callToActionType: val}))}
          suffixIcon={<CaretDownOutlined style={{marginTop: -3}} className={isActive ? 'ant-select-suffix' : ''} onClick={toggleClass}/>}
          options={buttonOptions}
        ></Select>
        {![null].includes(postData?.callToActionType) && <Input placeholder='Link for your button' disabled={['none', 'CALL'].includes(postData?.callToActionType)} value={postData?.callToActionType=='CALL' ? phoneNumbers?.length ? phoneNumbers[0] : 'No phone number available' :
          postData?.callToActionUrl} onChange={val => setPostData(prevState => ({...prevState, callToActionUrl: val.target.value}))}/>}
      </PostEditContainer>
      <ModalFooter>
        <StyledButton loading={loader == 'update'} disabled={loader} onClick={updatePost} isCancel={false}>Update Post</StyledButton>
        <StyledButton onClick={() => setIsModalOpen(false)} isCancel={true}>Cancel</StyledButton>
      </ModalFooter>
    </>
  );
});

const PostEditContainer = styled.div`
  .post-image-container {
    width: 211px;
    height: 136px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
  }

  .post-image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; 
  }
  .image-sub-section {
    display: flex;
  }
  .image-actions {
    color: #2D6CCA;
    margin-left: 16px;
    font-size: 12px;
  }
  .action {
    margin-bottom: 12px;
    cursor: pointer;
  }
  .action-icon {
    margin-right: 5px;
  }
  .image-section {
    margin-bottom: 40px;
  }
  .content-section {
    display: flex;
    justify-content: space-between;
  }
  textarea.ant-input {
    resize: none;
    height: 140px !important;
    max-height: 140px !important;
    overflow-y: auto;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    margin-bottom: 25px;
  }
    textarea.ant-input::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  textarea.ant-input::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  textarea.ant-input::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
  .button-select{
    width: 100%;
    }
  .ant-select {
  }
  .ant-select-selector,
  .ant-picker,
  .ant-input {
    height: 38px !important;
    border-radius: 8px !important;
    padding: 2px 11px !important;
    margin-bottom: 8px;
  }
  .ant-select-arrow .anticon > svg {
    color: black;
  }
`;

const StyledButton = styled(Button)<{isCancel: boolean}>`
  height: 48px;
  border-radius: 8px;
  font-weight: 500;
  color: ${p => !p.isCancel && 'white'};
  background-color: ${p => p.isCancel ? '#F2F2F5' : '#2D6CCA'};
  padding: 13px 20px 14px 20px;
  border: unset;

  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    background-color: ${p => p.isCancel ? '#F2F2F5' : '#2D6CCA'};
    border: unset;
    color: ${p => !p.isCancel ? 'white': 'black'} !important;
  }

  &:hover, &:focus, &:active {
    background-color: ${p => p.isCancel ? '#F2F2F5' : '#2D6CCA'};
    border: unset;
    color: ${p => !p.isCancel ? 'white': 'black'} !important;
  }
`;

const FieldLabel = styled.div`
  font-weight: 500;
  margin-bottom: 5px;
`;

const AIGenerateSection = styled.div`
  color: #2D6CCA;
  font-size: 12px;
  cursor: pointer;
`;

const ModalFooter = styled.div`
  display: flex;
  gap: 11px;
  margin-top: 15px;
`;
