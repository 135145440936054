import React from 'react';
import {CAMPAIGN_API} from '@/api/campaigns';
import {AddEmailActionButton, Title, Img, Actions} from './styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {faServer} from '@fortawesome/pro-duotone-svg-icons';

const StepOne = ({setCurrentStep}) => {
  return (
    <div>
      <Title>Add Email Account</Title>
      <Actions>
        <AddEmailActionButton onClick={() => window.open(CAMPAIGN_API.getAddNewMailUrl(), '_self')}>
          <div className='left-sec'>
            <Img src='/img/icon/google.svg' alt='google' />
            Continue with Gmail
          </div>
          <FontAwesomeIcon icon={faArrowRight} color='#A3A4A4' />
        </AddEmailActionButton>
        <AddEmailActionButton style={{marginTop: '10px'}} onClick={() => setCurrentStep('stepTwo')}>
          <div className='left-sec'>
            <FontAwesomeIcon icon={faServer} color='#2D6CCA' />
            Configure SMTP/IMAP
          </div>
          <FontAwesomeIcon icon={faArrowRight} color='#A3A4A4' />
        </AddEmailActionButton>
      </Actions>
    </div>
  );
};

export default StepOne;
